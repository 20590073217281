module.exports = {
  "displayFieldName" : "",
  "fieldAliases" : {
    "FID" : "FID",
    "Layer" : "Layer",
    "DocPath" : "DocPath"
  },
  "geometryType" : "esriGeometryPolygon",
  "spatialReference" : {
    "wkid" : 3765,
    "latestWkid" : 3765
  },
  "fields" : [
    {
      "name" : "FID",
      "type" : "esriFieldTypeOID",
      "alias" : "FID"
    },
    {
      "name" : "Layer",
      "type" : "esriFieldTypeString",
      "alias" : "Layer",
      "length" : 254
    },
    {
      "name" : "DocPath",
      "type" : "esriFieldTypeString",
      "alias" : "DocPath",
      "length" : 254
    }
  ],
  "features" : [
    {
      "attributes" : {
        "FID" : 0,
        "Layer" : "STARO_GP",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354007.33670945413,
              5010722.1610311093
            ],
            [
              354002.63215168094,
              5010720.3065517312
            ],
            [
              354044.70640371379,
              5010743.3988082241
            ],
            [
              354087.00223134313,
              5010686.6756494557
            ],
            [
              354115.77485622204,
              5010710.3565297453
            ],
            [
              354137.50694743166,
              5010685.2923805593
            ],
            [
              354128.44464280974,
              5010675.2288141223
            ],
            [
              354158.45891284855,
              5010655.5701834243
            ],
            [
              354106.11462738429,
              5010612.5456673792
            ],
            [
              354107.36540078133,
              5010606.3830376333
            ],
            [
              354112.39498431439,
              5010601.5144250691
            ],
            [
              354063.60897834558,
              5010566.2683693459
            ],
            [
              354040.13247351017,
              5010552.0390552189
            ],
            [
              354029.81443236937,
              5010566.2150505185
            ],
            [
              354019.40895631409,
              5010594.0356818112
            ],
            [
              353985.54964767554,
              5010572.1548400959
            ],
            [
              353898.30787939078,
              5010701.961107349
            ],
            [
              353881.05739677051,
              5010710.4679008974
            ],
            [
              353873.28524568287,
              5010714.705404615
            ],
            [
              353856.39500358124,
              5010724.2286779722
            ],
            [
              353838.8984085939,
              5010737.8561552083
            ],
            [
              353849.68593857664,
              5010748.9108846001
            ],
            [
              353829.89591115736,
              5010767.6971030282
            ],
            [
              353818.85871504404,
              5010798.2580996519
            ],
            [
              353778.86873520049,
              5010857.5682334043
            ],
            [
              353694.68885857693,
              5010979.6762787905
            ],
            [
              353670.1378582613,
              5010962.7383827483
            ],
            [
              353656.78680367459,
              5010979.0172876045
            ],
            [
              353667.13198198524,
              5010984.7717330921
            ],
            [
              353648.56868494779,
              5011014.4495427106
            ],
            [
              353683.34529837332,
              5011048.9330557473
            ],
            [
              353664.97094176098,
              5011070.4215842104
            ],
            [
              353642.48283627391,
              5011090.9634463452
            ],
            [
              353616.96171163913,
              5011113.6081946287
            ],
            [
              353601.19668237062,
              5011128.5677732779
            ],
            [
              353588.16304404504,
              5011146.7878830163
            ],
            [
              353604.91018400685,
              5011166.2585474756
            ],
            [
              353582.83935960667,
              5011190.8855388956
            ],
            [
              353577.20342948614,
              5011199.8583744112
            ],
            [
              353544.69551198283,
              5011250.6012411769
            ],
            [
              353531.22916256089,
              5011265.7916037552
            ],
            [
              353505.27935474773,
              5011302.0873019611
            ],
            [
              353472.80944802269,
              5011354.8759632083
            ],
            [
              353461.65180392686,
              5011378.9588109171
            ],
            [
              353448.17314937618,
              5011406.7254757574
            ],
            [
              353450.94110025797,
              5011408.7204078166
            ],
            [
              353440.69287427253,
              5011426.6470050719
            ],
            [
              353432.36656916788,
              5011456.1343868049
            ],
            [
              353485.10296452529,
              5011501.8801550772
            ],
            [
              353510.58607079723,
              5011477.1896338444
            ],
            [
              353518.87075001316,
              5011482.1515625948
            ],
            [
              353550.02648947062,
              5011432.1159811132
            ],
            [
              353544.15572431806,
              5011428.4733855613
            ],
            [
              353558.21554410842,
              5011410.2354352269
            ],
            [
              353572.43164973025,
              5011385.4135284778
            ],
            [
              353578.77544241672,
              5011377.7918115146
            ],
            [
              353642.99246275582,
              5011325.0945494641
            ],
            [
              353648.62981243397,
              5011334.5397031931
            ],
            [
              353665.97988747404,
              5011311.5354471337
            ],
            [
              353644.03541853325,
              5011251.2325962661
            ],
            [
              353620.58281724132,
              5011219.9491046695
            ],
            [
              353629.22645413276,
              5011207.5096004754
            ],
            [
              353620.68889003765,
              5011198.5669469824
            ],
            [
              353645.96397388849,
              5011181.0429145386
            ],
            [
              353649.13023503002,
              5011186.1000979152
            ],
            [
              353651.13321245473,
              5011183.6753085684
            ],
            [
              353655.84696425736,
              5011180.1768145831
            ],
            [
              353664.65550007869,
              5011176.6021587085
            ],
            [
              353674.79093994369,
              5011170.9563686894
            ],
            [
              353683.88364919298,
              5011164.306762429
            ],
            [
              353701.98016978567,
              5011146.2341363402
            ],
            [
              353720.03227648209,
              5011125.7748235529
            ],
            [
              353729.1933660694,
              5011104.4577446179
            ],
            [
              353740.8762977678,
              5011090.256283029
            ],
            [
              353753.15923402464,
              5011071.6097008344
            ],
            [
              353740.18872828881,
              5011065.8623691946
            ],
            [
              353802.3937003566,
              5010978.4130577007
            ],
            [
              353817.90281042195,
              5011004.7282257313
            ],
            [
              353824.62588493613,
              5010999.1459427169
            ],
            [
              353838.05440526357,
              5011005.3764521852
            ],
            [
              353849.68030380487,
              5010988.1063986681
            ],
            [
              353863.53251374146,
              5010998.7630170332
            ],
            [
              353872.9220172583,
              5010989.7203819938
            ],
            [
              353891.1898878045,
              5010958.8249968691
            ],
            [
              353902.80443260487,
              5010959.2910142476
            ],
            [
              353912.24469341739,
              5010952.9760239376
            ],
            [
              353916.57282332086,
              5010947.0972363446
            ],
            [
              353934.90276183985,
              5010923.2220430551
            ],
            [
              353943.10501133668,
              5010923.7515589548
            ],
            [
              353949.7392592192,
              5010913.3958670143
            ],
            [
              353952.42966652097,
              5010892.8813632904
            ],
            [
              354000.15014829388,
              5010867.9333271962
            ],
            [
              354002.93572635914,
              5010852.5331554301
            ],
            [
              353994.9795874182,
              5010846.8829586357
            ],
            [
              353970.22558915702,
              5010819.0344605651
            ],
            [
              353981.49118162901,
              5010800.7479046742
            ],
            [
              353978.0788881625,
              5010800.8114008168
            ],
            [
              353967.36115012149,
              5010793.5071852133
            ],
            [
              353961.73644075432,
              5010784.7439431222
            ],
            [
              353963.74576370284,
              5010782.6601135889
            ],
            [
              353966.09001964453,
              5010780.2289783116
            ],
            [
              353969.77373184077,
              5010776.4085866762
            ],
            [
              353978.71549888654,
              5010779.9940434648
            ],
            [
              353994.70760306675,
              5010758.8909522155
            ],
            [
              353998.27077035839,
              5010748.5924114287
            ],
            [
              353997.69481679442,
              5010735.9834164334
            ],
            [
              354007.33670945413,
              5010722.1610311093
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 1,
        "Layer" : "STARO_GP",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              361365.77891276492,
              5001949.0969786299
            ],
            [
              361380.92710066674,
              5001963.6807135213
            ],
            [
              361415.89043056261,
              5001930.7153295046
            ],
            [
              361397.45942541218,
              5001878.7063697958
            ],
            [
              361367.45287823555,
              5001899.9460132709
            ],
            [
              361354.7127821368,
              5001910.5237817708
            ],
            [
              361338.91966034821,
              5001930.8529667957
            ],
            [
              361354.70246541366,
              5001944.7786116172
            ],
            [
              361359.21672995586,
              5001944.0484271748
            ],
            [
              361365.77891276492,
              5001949.0969786299
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 2,
        "Layer" : "STARO_GP",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351936.14037018875,
              5012106.9018241074
            ],
            [
              351997.73272552091,
              5012154.4292211235
            ],
            [
              352013.74182805914,
              5012170.2168052057
            ],
            [
              352019.19165062852,
              5012174.7111948831
            ],
            [
              352034.24903868552,
              5012180.5588016119
            ],
            [
              352050.91037250235,
              5012190.206387734
            ],
            [
              352069.52324783109,
              5012201.7326508574
            ],
            [
              352102.93687800842,
              5012205.3236059304
            ],
            [
              352156.85108657682,
              5012132.3196156388
            ],
            [
              352181.84365288186,
              5012092.9902110817
            ],
            [
              352184.61108135688,
              5012095.6543242121
            ],
            [
              352217.4816968306,
              5012051.0496130483
            ],
            [
              352235.06158888288,
              5012061.0417197971
            ],
            [
              352243.82627273112,
              5012064.6804611888
            ],
            [
              352302.07826194243,
              5012069.5706518497
            ],
            [
              352305.00504148268,
              5012051.5931493137
            ],
            [
              352319.87081977475,
              5011998.502277799
            ],
            [
              352339.99350426084,
              5011969.8853836963
            ],
            [
              352308.16471090453,
              5011953.6409823569
            ],
            [
              352308.37237879989,
              5011906.3854578575
            ],
            [
              352318.92724179558,
              5011884.2174416622
            ],
            [
              352324.86396788532,
              5011881.9344567806
            ],
            [
              352353.45830708515,
              5011841.2112367181
            ],
            [
              352361.49766184535,
              5011835.0872545028
            ],
            [
              352367.02240729408,
              5011839.8725464642
            ],
            [
              352383.63676065538,
              5011856.4001135202
            ],
            [
              352391.97935425426,
              5011866.5641981903
            ],
            [
              352415.39712196827,
              5011898.1725488389
            ],
            [
              352444.18575139687,
              5011897.0936324643
            ],
            [
              352460.13550779509,
              5011907.1161352415
            ],
            [
              352485.16097116866,
              5011908.2797496989
            ],
            [
              352503.03156816709,
              5011904.6884137644
            ],
            [
              352538.3507761943,
              5011904.0310780508
            ],
            [
              352560.97026617237,
              5011892.7476882013
            ],
            [
              352607.57905187405,
              5011885.3627605923
            ],
            [
              352613.72650436067,
              5011883.4657702371
            ],
            [
              352617.79049421672,
              5011868.1827374566
            ],
            [
              352681.98693235341,
              5011842.0043543763
            ],
            [
              352691.72723145323,
              5011839.6505838996
            ],
            [
              352731.83563223836,
              5011833.4728914518
            ],
            [
              352818.89183303236,
              5011779.7130422732
            ],
            [
              352858.31290476973,
              5011755.5236083809
            ],
            [
              352920.00230394182,
              5011711.4689242812
            ],
            [
              353027.62638884847,
              5011599.8220983213
            ],
            [
              352990.17592947971,
              5011547.2058610348
            ],
            [
              353245.93084591842,
              5011313.4175312659
            ],
            [
              353313.23897325021,
              5011373.5762816016
            ],
            [
              353381.17777126096,
              5011311.26657518
            ],
            [
              353518.73336962768,
              5011117.7243376514
            ],
            [
              353497.49496485217,
              5011040.709121014
            ],
            [
              353450.72876494954,
              5011001.2627214761
            ],
            [
              353415.59920315165,
              5010976.3636335433
            ],
            [
              353394.19919054519,
              5010956.3195840195
            ],
            [
              353358.30091213353,
              5010920.6458133534
            ],
            [
              353345.51746875927,
              5010905.5520243505
            ],
            [
              353317.91286154988,
              5010918.5581656191
            ],
            [
              353295.69580710557,
              5010946.2279616538
            ],
            [
              353271.75105098839,
              5010942.1308083432
            ],
            [
              353248.44832079642,
              5010941.9965954637
            ],
            [
              353233.15182266739,
              5010944.5526047219
            ],
            [
              353178.59293577494,
              5010944.4321803469
            ],
            [
              353212.03564917977,
              5011061.9206829676
            ],
            [
              353200.68653160933,
              5011093.363106681
            ],
            [
              353099.86040664313,
              5011217.6287989179
            ],
            [
              353039.76243411057,
              5011286.3202421367
            ],
            [
              353025.44606537098,
              5011311.0038484624
            ],
            [
              353039.3657122025,
              5011326.0764925722
            ],
            [
              353010.64605070354,
              5011340.239151367
            ],
            [
              352989.93262988818,
              5011357.0920269452
            ],
            [
              352970.10776894906,
              5011377.0784488833
            ],
            [
              352961.68133033602,
              5011382.3457990782
            ],
            [
              352945.86955450912,
              5011387.75062746
            ],
            [
              352912.00292075676,
              5011369.6421393631
            ],
            [
              352899.40958702826,
              5011364.7659220751
            ],
            [
              352888.99359694991,
              5011354.7386088371
            ],
            [
              352864.30113875045,
              5011341.0021209102
            ],
            [
              352852.06461776391,
              5011324.7624252131
            ],
            [
              352842.82712513144,
              5011316.9845454218
            ],
            [
              352837.60856630199,
              5011311.4032420646
            ],
            [
              352821.58551115391,
              5011305.455163355
            ],
            [
              352789.43369495793,
              5011287.8825905677
            ],
            [
              352777.37682322512,
              5011281.2928593857
            ],
            [
              352744.92100505141,
              5011277.9219508963
            ],
            [
              352726.64665487927,
              5011273.1514483755
            ],
            [
              352665.50387776503,
              5011275.7130229166
            ],
            [
              352633.06917622802,
              5011273.4774040645
            ],
            [
              352585.2535081823,
              5011269.2566853473
            ],
            [
              352553.2918835475,
              5011261.9016807163
            ],
            [
              352548.11323352862,
              5011227.9275513263
            ],
            [
              352489.08334275236,
              5011231.8652694011
            ],
            [
              352454.71196438029,
              5011217.1731507545
            ],
            [
              352458.561886426,
              5011210.2873967905
            ],
            [
              352452.03813313931,
              5011195.6449329732
            ],
            [
              352433.6475707143,
              5011184.6303168032
            ],
            [
              352397.59298297594,
              5011171.1051992588
            ],
            [
              352384.60055975587,
              5011175.3218753999
            ],
            [
              352350.9897394735,
              5011201.5002254602
            ],
            [
              352311.69322376029,
              5011166.4574513594
            ],
            [
              352317.82620168949,
              5011160.0970521802
            ],
            [
              352326.03082853352,
              5011142.9091290785
            ],
            [
              352337.51447621937,
              5011123.0113066118
            ],
            [
              352386.20824728551,
              5011052.2606022079
            ],
            [
              352393.98211072729,
              5011042.4626161139
            ],
            [
              352398.87321298895,
              5011030.4469047775
            ],
            [
              352480.59917306644,
              5010932.9608322559
            ],
            [
              352521.24083467002,
              5010948.6719821338
            ],
            [
              352589.40490116697,
              5010977.4991517896
            ],
            [
              352616.29385734204,
              5010923.4667214481
            ],
            [
              352707.53821713681,
              5010806.7112494074
            ],
            [
              352750.63549150195,
              5010740.6075407853
            ],
            [
              352763.23938392889,
              5010746.051389982
            ],
            [
              352768.38399585552,
              5010747.6591853481
            ],
            [
              352787.59159315214,
              5010710.9599277033
            ],
            [
              352783.00450639223,
              5010708.7739156419
            ],
            [
              352787.20077694865,
              5010689.9570874376
            ],
            [
              352793.66939708713,
              5010671.0979380524
            ],
            [
              352810.92374932318,
              5010682.1337119332
            ],
            [
              352829.25089996104,
              5010689.7424712367
            ],
            [
              352832.48626747896,
              5010638.2312728176
            ],
            [
              352831.67307302827,
              5010613.9553623116
            ],
            [
              352841.87392062106,
              5010600.294859631
            ],
            [
              352875.36432798434,
              5010625.7883305261
            ],
            [
              352961.98960981122,
              5010517.4079457941
            ],
            [
              352978.67929335788,
              5010480.9661426274
            ],
            [
              352990.37808697164,
              5010473.8756509069
            ],
            [
              353016.13343155297,
              5010453.3278618576
            ],
            [
              353028.11346863158,
              5010461.3522897186
            ],
            [
              353049.89303006738,
              5010427.8015833739
            ],
            [
              353088.10471020424,
              5010381.7301343903
            ],
            [
              353114.84770506673,
              5010355.1159068123
            ],
            [
              353111.15135055792,
              5010345.4021841353
            ],
            [
              353128.88050430501,
              5010337.0998621713
            ],
            [
              353123.44511528529,
              5010325.9296211181
            ],
            [
              353140.27249097481,
              5010313.5204103524
            ],
            [
              353219.87911456224,
              5010288.9466175064
            ],
            [
              353202.93538809492,
              5010260.2320337631
            ],
            [
              353226.70519459457,
              5010251.2674879041
            ],
            [
              353246.50575737882,
              5010221.2086220914
            ],
            [
              353222.05732383614,
              5010149.7806879096
            ],
            [
              353195.72817364131,
              5010124.70375109
            ],
            [
              353220.63288158469,
              5010102.79729708
            ],
            [
              353239.06150610634,
              5010117.2996180998
            ],
            [
              353261.0225820241,
              5010099.846502658
            ],
            [
              353250.96047897887,
              5010074.8375232657
            ],
            [
              353244.11415773362,
              5010061.7691683481
            ],
            [
              353253.69846927753,
              5010044.5462986818
            ],
            [
              353118.40824991983,
              5009962.0392609071
            ],
            [
              352991.63535032753,
              5010003.1363340272
            ],
            [
              352921.50485697575,
              5010063.8221532423
            ],
            [
              352892.70509430568,
              5010090.0873973304
            ],
            [
              352870.44335635839,
              5010106.1715543494
            ],
            [
              352849.21025047486,
              5010118.3878391339
            ],
            [
              352804.95575306867,
              5010135.4310361408
            ],
            [
              352796.66137364629,
              5010139.2734684478
            ],
            [
              352802.30129382649,
              5010161.4364050189
            ],
            [
              352802.48642004124,
              5010186.1750382138
            ],
            [
              352799.56947193679,
              5010206.8477164591
            ],
            [
              352795.68931774911,
              5010220.1156664733
            ],
            [
              352784.0774394066,
              5010231.8780456139
            ],
            [
              352773.64485697658,
              5010250.9785362631
            ],
            [
              352764.53896096809,
              5010264.3437590953
            ],
            [
              352745.848750923,
              5010294.9318452757
            ],
            [
              352728.25945870945,
              5010266.3933649827
            ],
            [
              352723.3485732738,
              5010253.8387797074
            ],
            [
              352722.24226014287,
              5010238.7392000072
            ],
            [
              352720.21876027895,
              5010218.708238245
            ],
            [
              352717.3717030469,
              5010204.0156328538
            ],
            [
              352713.39839104691,
              5010197.4916828014
            ],
            [
              352703.39477819536,
              5010192.1795678549
            ],
            [
              352698.62195523537,
              5010187.0450643022
            ],
            [
              352446.6716078411,
              5010194.510157384
            ],
            [
              352419.21749806887,
              5010227.2681914466
            ],
            [
              352400.98611326521,
              5010278.0813388322
            ],
            [
              352378.38648841524,
              5010270.0895055356
            ],
            [
              352279.36592048313,
              5010386.6452119099
            ],
            [
              352263.27899566764,
              5010423.8138349224
            ],
            [
              352240.70438526064,
              5010509.1507843714
            ],
            [
              352160.33571960765,
              5010569.1741117602
            ],
            [
              352176.47834927746,
              5010585.9403963936
            ],
            [
              352095.38213488995,
              5010638.2330649253
            ],
            [
              352071.73738448316,
              5010643.6681318982
            ],
            [
              351992.59610330948,
              5010644.3082566708
            ],
            [
              351892.62315008062,
              5010629.3076183228
            ],
            [
              351877.20400091563,
              5010696.196149542
            ],
            [
              351975.94370195712,
              5010708.1632141517
            ],
            [
              351985.98840198049,
              5010755.4299121564
            ],
            [
              352048.98427361035,
              5010805.0413696282
            ],
            [
              352062.5645072654,
              5010796.0472373348
            ],
            [
              352068.88101266621,
              5010799.6760344785
            ],
            [
              352093.77682449674,
              5010816.695685856
            ],
            [
              352109.54603793519,
              5010791.0104141068
            ],
            [
              352152.35202608135,
              5010740.6789714368
            ],
            [
              352170.21122392517,
              5010715.3710706159
            ],
            [
              352183.36734472716,
              5010705.9685837161
            ],
            [
              352190.44758743705,
              5010698.504326121
            ],
            [
              352207.62881111843,
              5010681.5342751471
            ],
            [
              352238.01251060498,
              5010657.6583419302
            ],
            [
              352242.71744170319,
              5010664.2309544981
            ],
            [
              352244.43741292716,
              5010667.1127769612
            ],
            [
              352250.00491049414,
              5010699.2395347804
            ],
            [
              352249.74505706673,
              5010730.0475410335
            ],
            [
              352245.57378539268,
              5010798.5091750789
            ],
            [
              352216.42187817936,
              5010869.6319694575
            ],
            [
              352251.3021222665,
              5010953.0673051309
            ],
            [
              352200.30316038075,
              5010966.0878837127
            ],
            [
              352103.45602134441,
              5010977.8802583842
            ],
            [
              352051.50127508881,
              5010894.7626174651
            ],
            [
              352007.46223903203,
              5010904.0559595777
            ],
            [
              352015.08392100042,
              5010933.0523114381
            ],
            [
              352024.73364122439,
              5010959.0970941605
            ],
            [
              352065.9286592087,
              5011023.6832817178
            ],
            [
              352111.69829963607,
              5011057.6616695924
            ],
            [
              352129.28990923188,
              5011062.745657512
            ],
            [
              352158.15672062367,
              5011069.2848948427
            ],
            [
              352212.63602957036,
              5011086.5865119472
            ],
            [
              352227.62220025359,
              5011108.3693831163
            ],
            [
              352238.99796503951,
              5011115.2341053896
            ],
            [
              352251.22989494499,
              5011123.3316538595
            ],
            [
              352207.02322310273,
              5011195.0838641543
            ],
            [
              352167.11878402677,
              5011267.0068026632
            ],
            [
              352162.92333293811,
              5011265.4198651435
            ],
            [
              352063.90519577422,
              5011227.7178620826
            ],
            [
              352008.9629263907,
              5011207.9272988886
            ],
            [
              352001.53044093837,
              5011233.8737099264
            ],
            [
              352064.38535361964,
              5011259.1933176676
            ],
            [
              352093.96506673581,
              5011269.7790308306
            ],
            [
              352102.25614661817,
              5011279.7485227771
            ],
            [
              352100.36231856089,
              5011286.8704374032
            ],
            [
              352093.4418532594,
              5011296.1106747156
            ],
            [
              352081.43834994308,
              5011304.4331157338
            ],
            [
              352077.29275931389,
              5011299.4483707501
            ],
            [
              352071.29100692138,
              5011303.6095634326
            ],
            [
              352043.006673522,
              5011308.1854812121
            ],
            [
              352013.91662353993,
              5011323.9125145506
            ],
            [
              351987.78978275106,
              5011335.5349584073
            ],
            [
              351956.57979912258,
              5011346.2395535083
            ],
            [
              351938.48037053365,
              5011353.6630527014
            ],
            [
              351918.24227690819,
              5011355.0520675322
            ],
            [
              351902.13094195531,
              5011360.41378967
            ],
            [
              351890.05209946068,
              5011364.6881344942
            ],
            [
              351877.93558901799,
              5011366.9383999975
            ],
            [
              351858.89860935474,
              5011378.42885492
            ],
            [
              351838.08080515248,
              5011403.1134713339
            ],
            [
              351814.24328931252,
              5011428.8666217467
            ],
            [
              351808.29803589365,
              5011436.0639399169
            ],
            [
              351796.27579219383,
              5011443.3743508328
            ],
            [
              351784.42295352207,
              5011459.7930688327
            ],
            [
              351777.4271502209,
              5011464.9851577105
            ],
            [
              351768.63169053657,
              5011482.359313935
            ],
            [
              351757.71639903303,
              5011494.7110801386
            ],
            [
              351746.74460398144,
              5011504.0266705435
            ],
            [
              351733.97305477428,
              5011525.5243880069
            ],
            [
              351721.93187702511,
              5011531.8228212493
            ],
            [
              351699.5969350987,
              5011583.8699635556
            ],
            [
              351703.81787223119,
              5011592.9028717754
            ],
            [
              351693.18499722931,
              5011620.4350826843
            ],
            [
              351685.8835875779,
              5011669.888343554
            ],
            [
              351687.40500322188,
              5011697.1943585994
            ],
            [
              351686.20796552958,
              5011741.7614988694
            ],
            [
              351691.89382398961,
              5011775.0642987881
            ],
            [
              351707.78334578243,
              5011812.2267604163
            ],
            [
              351737.79641228938,
              5011846.0891912961
            ],
            [
              351771.55934410251,
              5011863.6836885922
            ],
            [
              351788.94735065213,
              5011872.4715366252
            ],
            [
              351798.30784300715,
              5011885.4582918258
            ],
            [
              351797.74700655433,
              5011909.7659350326
            ],
            [
              351787.86334741075,
              5011923.1108484566
            ],
            [
              351766.93256520358,
              5011941.7232621443
            ],
            [
              351752.07897505927,
              5011960.2226287443
            ],
            [
              351737.3948035221,
              5011987.8302484108
            ],
            [
              351737.84685511078,
              5012012.1189886732
            ],
            [
              351738.4119230478,
              5012042.4799699178
            ],
            [
              351725.8664355338,
              5012076.1220782092
            ],
            [
              351700.63968035131,
              5012090.1419175994
            ],
            [
              351681.43319277785,
              5012092.5241113314
            ],
            [
              351717.35811681743,
              5012171.8337397547
            ],
            [
              351777.98260476912,
              5012217.2749694297
            ],
            [
              351852.22852195374,
              5012278.3047228167
            ],
            [
              351918.19975387672,
              5012338.8321407763
            ],
            [
              351965.43113401177,
              5012372.3740270622
            ],
            [
              352005.76101098134,
              5012416.1682036854
            ],
            [
              352017.42751493648,
              5012427.6133234901
            ],
            [
              352041.37997099006,
              5012396.5284458278
            ],
            [
              352024.15013235615,
              5012376.9337054817
            ],
            [
              352005.00458178762,
              5012357.374619565
            ],
            [
              351984.34049319016,
              5012338.6097844522
            ],
            [
              351998.96330356592,
              5012321.1031145221
            ],
            [
              352008.31433708337,
              5012308.6734676231
            ],
            [
              352000.92019522592,
              5012302.6832342567
            ],
            [
              351950.10901888745,
              5012273.948092429
            ],
            [
              351901.36709646153,
              5012249.9610070232
            ],
            [
              351892.47265114944,
              5012204.5508893402
            ],
            [
              351904.62541801221,
              5012177.8984339535
            ],
            [
              351936.14037018875,
              5012106.9018241074
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 3,
        "Layer" : "STARO_GP",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352452.32924517052,
              5010914.674219681
            ],
            [
              352553.10526818922,
              5010788.3799145427
            ],
            [
              352632.99461423681,
              5010680.7915647728
            ],
            [
              352629.65477833344,
              5010530.450098074
            ],
            [
              352604.10428847844,
              5010513.3862820305
            ],
            [
              352368.98424375383,
              5010530.3233943172
            ],
            [
              352303.47163195192,
              5010606.0842380794
            ],
            [
              352475.24105553539,
              5010639.6108488934
            ],
            [
              352517.60914609634,
              5010676.0933536179
            ],
            [
              352491.01736544009,
              5010720.9844082827
            ],
            [
              352453.97533316776,
              5010768.6055779196
            ],
            [
              352433.42113046889,
              5010754.7373925168
            ],
            [
              352405.17855787353,
              5010862.3264309913
            ],
            [
              352452.32924517052,
              5010914.674219681
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 4,
        "Layer" : "STARO_GP",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358683.58861211472,
              5006261.35128849
            ],
            [
              358692.23878697091,
              5006243.2063224772
            ],
            [
              358702.89503088529,
              5006215.2501037987
            ],
            [
              358656.28895627888,
              5006181.6583957002
            ],
            [
              358623.70652489841,
              5006155.0940552764
            ],
            [
              358609.89065653324,
              5006133.3358998401
            ],
            [
              358571.7985149076,
              5006128.301179382
            ],
            [
              358511.33714632172,
              5006105.1769414153
            ],
            [
              358505.49169581506,
              5006117.0908295754
            ],
            [
              358511.22063437157,
              5006133.256257847
            ],
            [
              358500.06033979187,
              5006134.101909373
            ],
            [
              358504.41862354078,
              5006162.4170862297
            ],
            [
              358504.29749647435,
              5006189.3529015351
            ],
            [
              358502.29054314113,
              5006235.9727467373
            ],
            [
              358496.54486275237,
              5006236.0795860989
            ],
            [
              358507.53633775486,
              5006277.6719038347
            ],
            [
              358651.82817468379,
              5006270.5564403348
            ],
            [
              358676.13034532702,
              5006289.5671101622
            ],
            [
              358686.7817129179,
              5006278.5210573692
            ],
            [
              358683.58861211472,
              5006261.35128849
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 5,
        "Layer" : "STARO_GP",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359184.10281947855,
              5006352.8082298841
            ],
            [
              359219.45913529937,
              5006320.9304921245
            ],
            [
              359052.36589815811,
              5006138.4441657774
            ],
            [
              359024.66854922968,
              5006112.438691251
            ],
            [
              359007.32849433529,
              5006121.9382246807
            ],
            [
              358993.58957867284,
              5006123.8721926212
            ],
            [
              358973.25212004216,
              5006132.3072115174
            ],
            [
              358945.02141368465,
              5006131.8250276661
            ],
            [
              358950.78315627313,
              5006170.6593652498
            ],
            [
              358960.007220286,
              5006178.8803964769
            ],
            [
              358962.37696740573,
              5006179.8434078768
            ],
            [
              358981.42203515343,
              5006192.245982673
            ],
            [
              358996.06982567016,
              5006203.0517789945
            ],
            [
              359011.46418001218,
              5006217.8721199213
            ],
            [
              359029.22821313114,
              5006233.6555132996
            ],
            [
              359038.32192108262,
              5006246.080155177
            ],
            [
              359049.94700135046,
              5006256.9421540452
            ],
            [
              359068.41398629488,
              5006274.3909731898
            ],
            [
              359089.56153025798,
              5006291.4542539651
            ],
            [
              359109.32202705863,
              5006306.19339881
            ],
            [
              359136.55873728992,
              5006325.4933317164
            ],
            [
              359155.95219064452,
              5006338.5607973561
            ],
            [
              359184.10281947855,
              5006352.8082298841
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 6,
        "Layer" : "STARO_GP",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355165.14380150312,
              5009470.6614428991
            ],
            [
              355194.42329953326,
              5009445.2969121141
            ],
            [
              355212.4203781063,
              5009433.541815429
            ],
            [
              355254.95945755945,
              5009397.0620066402
            ],
            [
              355270.07361845276,
              5009383.9329670239
            ],
            [
              355271.0704225768,
              5009360.7169662537
            ],
            [
              355288.86306485237,
              5009357.1740114409
            ],
            [
              355433.29863982502,
              5009202.5373572335
            ],
            [
              355451.43077387573,
              5009175.2030188786
            ],
            [
              355458.14695815841,
              5009152.237495997
            ],
            [
              355458.82003405021,
              5009123.9258862734
            ],
            [
              355455.73693880817,
              5009107.765353634
            ],
            [
              355449.27089234191,
              5009084.2538693678
            ],
            [
              355441.70707837091,
              5009076.5172798857
            ],
            [
              355383.84895266814,
              5009007.6250302987
            ],
            [
              355374.40502619318,
              5008998.5333354026
            ],
            [
              355360.13256924361,
              5009015.6544921855
            ],
            [
              355346.03371794923,
              5009036.9209931698
            ],
            [
              355331.16114060086,
              5009054.2788117453
            ],
            [
              355305.95891106233,
              5009088.1508491477
            ],
            [
              355281.91006071179,
              5009122.7605654858
            ],
            [
              355269.38659546193,
              5009143.8704964938
            ],
            [
              355233.32336029189,
              5009191.8827291904
            ],
            [
              355203.35366718279,
              5009234.9533920689
            ],
            [
              355183.02480133926,
              5009265.3185319062
            ],
            [
              355142.33870018891,
              5009324.531050289
            ],
            [
              355122.78580837551,
              5009343.4025029261
            ],
            [
              355085.83937413752,
              5009379.0113705825
            ],
            [
              355045.1871507903,
              5009419.6237516515
            ],
            [
              355014.06415873731,
              5009461.9567446345
            ],
            [
              355005.54861754383,
              5009473.8822206417
            ],
            [
              354975.31543855875,
              5009511.5025283406
            ],
            [
              354943.6856051861,
              5009547.0125171496
            ],
            [
              354910.22049270914,
              5009585.972882608
            ],
            [
              354888.94148002641,
              5009606.1070304373
            ],
            [
              354856.68776070059,
              5009635.7965554837
            ],
            [
              354799.57333840645,
              5009690.0006299214
            ],
            [
              354778.38603147713,
              5009715.0676515782
            ],
            [
              354759.11805785814,
              5009737.3188047307
            ],
            [
              354765.91590212221,
              5009746.5712523861
            ],
            [
              354782.57712081278,
              5009751.7782660229
            ],
            [
              354805.87209460716,
              5009757.4135479536
            ],
            [
              354818.94456434378,
              5009747.791414733
            ],
            [
              354830.3714003035,
              5009738.7516370518
            ],
            [
              354832.31239361508,
              5009724.3713009171
            ],
            [
              354865.6985944772,
              5009767.8862010874
            ],
            [
              354866.94617643469,
              5009775.5867908699
            ],
            [
              354937.70977029827,
              5009705.6968590897
            ],
            [
              354939.92766413488,
              5009707.8322124658
            ],
            [
              354944.3904386877,
              5009713.5534469644
            ],
            [
              354964.7587784981,
              5009696.1244274303
            ],
            [
              354972.9113144789,
              5009705.0419393657
            ],
            [
              354979.61524073652,
              5009710.3388475906
            ],
            [
              355033.29873121547,
              5009638.0085043292
            ],
            [
              355074.31889647275,
              5009613.7328231186
            ],
            [
              355125.24862563954,
              5009495.6718019657
            ],
            [
              355140.48889719642,
              5009489.3212433299
            ],
            [
              355165.14380150312,
              5009470.6614428991
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 7,
        "Layer" : "STARO_GP",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359180.92869186803,
              5006092.8395591192
            ],
            [
              359236.08945887646,
              5006049.7280618781
            ],
            [
              359248.97727552184,
              5005998.1782904956
            ],
            [
              359336.16520562599,
              5005908.9263591524
            ],
            [
              359334.12423567142,
              5005892.245291925
            ],
            [
              359298.1827577643,
              5005851.9807628542
            ],
            [
              359281.00938031654,
              5005859.2183211837
            ],
            [
              359259.65956111648,
              5005859.0387563631
            ],
            [
              359252.08705701731,
              5005855.1439173473
            ],
            [
              359217.7207476123,
              5005899.5983045446
            ],
            [
              359066.84185607597,
              5005914.5106328372
            ],
            [
              359062.75113971386,
              5005911.7040884988
            ],
            [
              359043.58353605872,
              5005966.8297562422
            ],
            [
              359088.1760425652,
              5006006.933409987
            ],
            [
              359117.19678175042,
              5006047.3266334543
            ],
            [
              359125.97636393266,
              5006054.0815949161
            ],
            [
              359134.24352624948,
              5006064.3051892547
            ],
            [
              359142.73573021713,
              5006086.6315205041
            ],
            [
              359180.92869186803,
              5006092.8395591192
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 8,
        "Layer" : "STARO_GP",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354057.19582428836,
              5009252.9263366833
            ],
            [
              354212.68547134841,
              5009390.0380675299
            ],
            [
              354338.59621157107,
              5009259.447388079
            ],
            [
              354187.22422557493,
              5009113.7091762358
            ],
            [
              354057.19582428836,
              5009252.9263366833
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 9,
        "Layer" : "STARO_GP",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357110.42774375994,
              5007350.4198225802
            ],
            [
              357110.42774375872,
              5007350.4198225792
            ],
            [
              357162.27820246393,
              5007357.1250357497
            ],
            [
              357226.52435768239,
              5007251.9734454267
            ],
            [
              357267.76790798042,
              5007117.7165780105
            ],
            [
              357197.88589199673,
              5007046.9552968498
            ],
            [
              357142.28123281657,
              5007108.2370183328
            ],
            [
              357040.19241266139,
              5007149.1193958279
            ],
            [
              357013.73651536502,
              5007188.5951776691
            ],
            [
              356934.40180969896,
              5007244.6467984105
            ],
            [
              356918.1142273033,
              5007256.2146135624
            ],
            [
              356901.14507117029,
              5007264.241698131
            ],
            [
              356723.44009761995,
              5007441.8586410554
            ],
            [
              356691.75834711362,
              5007472.8927853471
            ],
            [
              356657.25718737399,
              5007512.9337692885
            ],
            [
              356605.5265979676,
              5007558.0708330628
            ],
            [
              356586.93391107343,
              5007578.0684508793
            ],
            [
              356579.98969250621,
              5007590.1368031641
            ],
            [
              356552.15744226746,
              5007635.4263749914
            ],
            [
              356494.19973721699,
              5007666.9492567368
            ],
            [
              356474.92259788985,
              5007668.7371801343
            ],
            [
              356550.10847398377,
              5007589.4986202158
            ],
            [
              356575.84058561519,
              5007559.7690417077
            ],
            [
              356599.33936737437,
              5007538.4383999547
            ],
            [
              356692.36067949317,
              5007438.4878554503
            ],
            [
              356706.44244490139,
              5007426.0825997796
            ],
            [
              356719.41230235848,
              5007382.9348818706
            ],
            [
              356694.5204897924,
              5007365.8574411189
            ],
            [
              356683.31200135057,
              5007358.5100315325
            ],
            [
              356663.47597391158,
              5007366.4348081267
            ],
            [
              356535.77520870027,
              5007364.5230846414
            ],
            [
              356495.14691210998,
              5007326.6304441299
            ],
            [
              356640.39338832669,
              5007149.6522515817
            ],
            [
              356657.88869311864,
              5007151.1969726495
            ],
            [
              356710.84968647495,
              5007181.379941592
            ],
            [
              356745.56434207835,
              5007203.175246303
            ],
            [
              356747.14540068718,
              5007187.5618729843
            ],
            [
              356788.22337260033,
              5007216.0957724415
            ],
            [
              356810.81629783596,
              5007170.3848943412
            ],
            [
              356868.42603289982,
              5007205.0022949483
            ],
            [
              356911.28435265023,
              5007165.0958504239
            ],
            [
              356984.67810437747,
              5007086.4344949247
            ],
            [
              357087.25092304253,
              5006928.2998089017
            ],
            [
              357128.29412580736,
              5006954.9642710295
            ],
            [
              357167.43682390987,
              5006879.433422789
            ],
            [
              357171.49463416444,
              5006854.8673363831
            ],
            [
              357200.62630015385,
              5006872.5027520284
            ],
            [
              357204.73667359765,
              5006870.0534139592
            ],
            [
              357214.95839863468,
              5006876.9820219278
            ],
            [
              357254.71080313233,
              5006811.8530944195
            ],
            [
              357332.04265674442,
              5006660.3296548408
            ],
            [
              357413.83077331772,
              5006486.3917609211
            ],
            [
              357421.54622308322,
              5006486.2482885206
            ],
            [
              357432.77852890367,
              5006483.6665210277
            ],
            [
              357448.45196277584,
              5006496.4259812105
            ],
            [
              357465.15804539766,
              5006500.8610762209
            ],
            [
              357453.92580951535,
              5006503.4428427424
            ],
            [
              357421.75771145371,
              5006529.5496013882
            ],
            [
              357441.7951533873,
              5006553.4990984295
            ],
            [
              357405.12173362559,
              5006592.7405590881
            ],
            [
              357410.6838043959,
              5006604.5015747705
            ],
            [
              357415.81770441221,
              5006625.1688871738
            ],
            [
              357437.84719735797,
              5006650.6207911521
            ],
            [
              357420.8232872691,
              5006661.022171299
            ],
            [
              357409.94387028593,
              5006682.5804805374
            ],
            [
              357410.95644396375,
              5006705.1041161623
            ],
            [
              357430.22202037397,
              5006687.5423963498
            ],
            [
              357471.53770780965,
              5006674.810961931
            ],
            [
              357473.82282238541,
              5006701.5621493049
            ],
            [
              357483.85713162919,
              5006698.4094392965
            ],
            [
              357512.85220270272,
              5006725.1584556689
            ],
            [
              357504.66454491677,
              5006731.8361919532
            ],
            [
              357535.27468094375,
              5006749.6568378676
            ],
            [
              357595.26586454338,
              5006814.982119415
            ],
            [
              357602.77177824505,
              5006803.5713097658
            ],
            [
              357636.51477637369,
              5006830.2320133345
            ],
            [
              357632.44852004322,
              5006835.0534236664
            ],
            [
              357667.15799015859,
              5006849.8317107921
            ],
            [
              357686.95082497096,
              5006828.7008676389
            ],
            [
              357699.08335663745,
              5006810.6785901822
            ],
            [
              357701.92956425226,
              5006804.1002290575
            ],
            [
              357702.35759285308,
              5006795.1939417152
            ],
            [
              357717.02954301221,
              5006786.0227696253
            ],
            [
              357729.33859109558,
              5006777.4887530375
            ],
            [
              357751.77906441799,
              5006739.1052426305
            ],
            [
              357763.8013890718,
              5006715.1528038979
            ],
            [
              357786.66297600837,
              5006731.3378492035
            ],
            [
              357800.01761320111,
              5006747.1065140441
            ],
            [
              357833.15199880739,
              5006709.1173615893
            ],
            [
              357836.24081858533,
              5006715.5853508664
            ],
            [
              357878.35616419715,
              5006668.3744434491
            ],
            [
              357876.21176865336,
              5006648.8379933229
            ],
            [
              357886.76232091553,
              5006641.5231544143
            ],
            [
              357899.63173092779,
              5006631.1990455985
            ],
            [
              357911.23306264984,
              5006614.3153487751
            ],
            [
              357888.14382997103,
              5006588.1074862154
            ],
            [
              357865.8935652356,
              5006555.3144311914
            ],
            [
              357878.71006478748,
              5006527.7742934655
            ],
            [
              357877.19227640046,
              5006510.0058717662
            ],
            [
              357899.61274843832,
              5006502.4703099756
            ],
            [
              357912.68065629766,
              5006502.8205187097
            ],
            [
              357923.34148411563,
              5006501.4358072802
            ],
            [
              357906.23843621375,
              5006475.652093742
            ],
            [
              357889.7208522826,
              5006452.5346173188
            ],
            [
              357885.48925123393,
              5006448.4607298933
            ],
            [
              357913.20705633203,
              5006438.4537724573
            ],
            [
              357942.79355873686,
              5006433.1578556085
            ],
            [
              357971.2923399987,
              5006433.2211250691
            ],
            [
              357959.76028836513,
              5006387.7574955812
            ],
            [
              357952.36073421256,
              5006341.0305930274
            ],
            [
              357915.71847268444,
              5006350.0170661127
            ],
            [
              357914.33481601399,
              5006336.066242341
            ],
            [
              357905.75025017461,
              5006321.3952940246
            ],
            [
              357893.08832918596,
              5006310.9527803017
            ],
            [
              357904.40689167654,
              5006281.0812383862
            ],
            [
              357910.08822755475,
              5006267.3314843085
            ],
            [
              357912.20860271173,
              5006253.6479460103
            ],
            [
              357910.87821842416,
              5006245.9607885219
            ],
            [
              357920.93457361817,
              5006243.994153643
            ],
            [
              357933.32269037276,
              5006271.6451899419
            ],
            [
              357950.4147634721,
              5006296.8359023472
            ],
            [
              357965.69119095785,
              5006288.246731028
            ],
            [
              357982.81432992872,
              5006283.1825825078
            ],
            [
              358001.70184494654,
              5006272.6618398186
            ],
            [
              358021.34733660828,
              5006280.0931728445
            ],
            [
              358035.64638049685,
              5006282.7933903588
            ],
            [
              358045.15332322015,
              5006283.209822909
            ],
            [
              358061.66088756477,
              5006276.9706548564
            ],
            [
              358072.21144207811,
              5006269.6558312951
            ],
            [
              358090.57371542871,
              5006253.8352252934
            ],
            [
              358109.98255936662,
              5006243.9827924464
            ],
            [
              358119.42333824706,
              5006240.8411379615
            ],
            [
              358144.2403782169,
              5006224.2869421216
            ],
            [
              358160.44462017505,
              5006231.2861106489
            ],
            [
              358158.64341269649,
              5006271.1070477217
            ],
            [
              358145.86423303233,
              5006318.2091464745
            ],
            [
              358129.70046765375,
              5006374.8657563748
            ],
            [
              358123.17402099905,
              5006407.021064329
            ],
            [
              358130.13248718489,
              5006430.0273008998
            ],
            [
              358144.75132034975,
              5006449.924983927
            ],
            [
              358163.07142976229,
              5006469.5244873874
            ],
            [
              358187.79065004078,
              5006489.8275580788
            ],
            [
              358211.21256350674,
              5006504.2225404279
            ],
            [
              358241.5375607863,
              5006523.61872904
            ],
            [
              358277.43627861398,
              5006585.4488233803
            ],
            [
              358324.49167248321,
              5006657.5399634242
            ],
            [
              358364.45037747693,
              5006720.0986439567
            ],
            [
              358422.56161309389,
              5006780.1197790811
            ],
            [
              358455.30504143989,
              5006816.8837410808
            ],
            [
              358461.14678516454,
              5006905.447452941
            ],
            [
              358495.4852289785,
              5006964.1309193904
            ],
            [
              358550.75035711989,
              5007030.7303356724
            ],
            [
              358593.45857805345,
              5007087.2306735199
            ],
            [
              358634.91742167663,
              5007135.2371360622
            ],
            [
              358722.31329014449,
              5007082.9000849985
            ],
            [
              358684.85938296502,
              5007051.5098268744
            ],
            [
              358648.18211719708,
              5007026.6834318116
            ],
            [
              358625.88093433768,
              5007008.7083063992
            ],
            [
              358606.45910151821,
              5006985.9338943465
            ],
            [
              358583.15642848244,
              5006946.0282534156
            ],
            [
              358572.17373346019,
              5006898.1816570759
            ],
            [
              358577.34772325022,
              5006860.4583129417
            ],
            [
              358585.69870814384,
              5006830.6419977462
            ],
            [
              358584.08165570389,
              5006807.53646849
            ],
            [
              358578.88152566081,
              5006783.3111054534
            ],
            [
              358573.74763379927,
              5006762.6438652575
            ],
            [
              358567.34951800719,
              5006737.8476084741
            ],
            [
              358560.42405436642,
              5006716.6204295475
            ],
            [
              358549.05545185483,
              5006683.3127360055
            ],
            [
              358532.95198219205,
              5006647.4257604294
            ],
            [
              358513.34262916894,
              5006614.5700368062
            ],
            [
              358501.13087822153,
              5006596.4072986804
            ],
            [
              358483.94562339131,
              5006572.1190684261
            ],
            [
              358467.70976334653,
              5006529.1158717265
            ],
            [
              358442.09930542816,
              5006492.812455385
            ],
            [
              358405.76187124138,
              5006454.3355733063
            ],
            [
              358382.0753176795,
              5006425.7082129177
            ],
            [
              358355.91545687226,
              5006391.7878456134
            ],
            [
              358335.39483593858,
              5006373.779595864
            ],
            [
              358321.55699279642,
              5006363.9521344835
            ],
            [
              358307.61188650061,
              5006353.8485620478
            ],
            [
              358339.62748623587,
              5006351.4735924993
            ],
            [
              358345.52935517993,
              5006349.5841680476
            ],
            [
              358337.383867367,
              5006326.6000170596
            ],
            [
              358339.32780430536,
              5006303.4282528916
            ],
            [
              358347.67884323525,
              5006273.6119303899
            ],
            [
              358348.73348111258,
              5006266.4736460419
            ],
            [
              358349.32819130819,
              5006236.4984454261
            ],
            [
              358348.94224810548,
              5006215.7428971911
            ],
            [
              358342.47807428631,
              5006187.3884852286
            ],
            [
              358333.606759777,
              5006157.2991946451
            ],
            [
              358320.52590905159,
              5006124.3220309373
            ],
            [
              358304.51063121908,
              5006093.1791071547
            ],
            [
              358296.7331364862,
              5006077.9715811601
            ],
            [
              358292.19953854935,
              5006055.612846747
            ],
            [
              358294.36995036382,
              5006015.0874147732
            ],
            [
              358318.14160933363,
              5005961.2719473438
            ],
            [
              358354.19487796887,
              5005978.6671885317
            ],
            [
              358380.6449742085,
              5005988.9286522605
            ],
            [
              358409.19863417331,
              5005996.5702952249
            ],
            [
              358417.4206005765,
              5005972.4663095642
            ],
            [
              358429.15101451386,
              5005958.3476114785
            ],
            [
              358427.66810845392,
              5005948.0519792428
            ],
            [
              358423.44334019761,
              5005929.2046895148
            ],
            [
              358417.56731677963,
              5005904.957189939
            ],
            [
              358411.89970794768,
              5005877.9642051049
            ],
            [
              358409.28429257352,
              5005853.0651039761
            ],
            [
              358424.10028846288,
              5005854.2470268523
            ],
            [
              358426.18157418742,
              5005842.5303459736
            ],
            [
              358429.80285840435,
              5005822.1441877652
            ],
            [
              358438.0097658718,
              5005800.4849475464
            ],
            [
              358452.73696279171,
              5005805.3727202164
            ],
            [
              358460.22701058711,
              5005791.4692138964
            ],
            [
              358480.52457116905,
              5005794.963013893
            ],
            [
              358496.01642384502,
              5005794.6749669863
            ],
            [
              358516.28999763972,
              5005796.8788120793
            ],
            [
              358553.74471868511,
              5005797.042634394
            ],
            [
              358587.38241707458,
              5005800.2883937154
            ],
            [
              358601.15298668615,
              5005800.032351193
            ],
            [
              358632.21021584579,
              5005745.9538713656
            ],
            [
              358673.16876927536,
              5005703.0393390069
            ],
            [
              358677.79848693247,
              5005697.3615598697
            ],
            [
              358693.57815696468,
              5005712.5528956316
            ],
            [
              358718.98503630667,
              5005736.1679267362
            ],
            [
              358744.83824533195,
              5005760.6348898793
            ],
            [
              358752.53623965377,
              5005757.9109595744
            ],
            [
              358750.98800475948,
              5005720.9483759813
            ],
            [
              358755.49781421595,
              5005708.8208121825
            ],
            [
              358775.10379576078,
              5005672.1140928967
            ],
            [
              358782.147598162,
              5005657.3586653573
            ],
            [
              358799.41539071879,
              5005636.8213817077
            ],
            [
              358819.49561690958,
              5005605.478498321
            ],
            [
              358866.62766791583,
              5005540.7544206018
            ],
            [
              358897.6580269612,
              5005519.5311484914
            ],
            [
              358922.26533881814,
              5005500.1478168666
            ],
            [
              358961.23216034129,
              5005465.8730066502
            ],
            [
              359001.13960689236,
              5005435.8820759999
            ],
            [
              359028.09048371215,
              5005426.7783228047
            ],
            [
              359060.64369380701,
              5005418.0005752975
            ],
            [
              359092.7320924708,
              5005417.4670414096
            ],
            [
              359107.84962396597,
              5005420.1968839671
            ],
            [
              359151.13845203625,
              5005433.1562555628
            ],
            [
              359209.57669453969,
              5005450.5654336037
            ],
            [
              359234.69571688579,
              5005458.7010285081
            ],
            [
              359222.34401838004,
              5005465.812781753
            ],
            [
              359206.27931873716,
              5005481.5962369218
            ],
            [
              359195.94477637374,
              5005504.5853290809
            ],
            [
              359185.93801235076,
              5005545.20378087
            ],
            [
              359190.17363830988,
              5005561.7182111805
            ],
            [
              359173.47873565176,
              5005566.7600422231
            ],
            [
              359202.39216222358,
              5005593.7508823806
            ],
            [
              359250.08960272098,
              5005635.4471223252
            ],
            [
              359261.9069394329,
              5005622.7535442784
            ],
            [
              359285.52875180152,
              5005609.0086890394
            ],
            [
              359302.66198289068,
              5005604.3888241602
            ],
            [
              359325.81988907797,
              5005599.6569437357
            ],
            [
              359325.4868547981,
              5005628.0518277762
            ],
            [
              359347.48165319365,
              5005630.2236739825
            ],
            [
              359363.85013586219,
              5005630.7795682363
            ],
            [
              359405.06581425545,
              5005624.8516590139
            ],
            [
              359425.92948121805,
              5005635.6471979013
            ],
            [
              359422.71869226248,
              5005648.1807007855
            ],
            [
              359433.94724735891,
              5005650.1225576028
            ],
            [
              359440.05977733817,
              5005654.7403783612
            ],
            [
              359474.47021144087,
              5005653.2403037865
            ],
            [
              359477.6184152987,
              5005660.4940331662
            ],
            [
              359485.11128020054,
              5005693.0447031399
            ],
            [
              359494.05359975243,
              5005710.9439423671
            ],
            [
              359506.36668606504,
              5005724.9093173938
            ],
            [
              359518.55039392581,
              5005708.7679561339
            ],
            [
              359539.02893306321,
              5005675.6972148791
            ],
            [
              359542.65672333707,
              5005659.9725748189
            ],
            [
              359509.16156497732,
              5005641.2394029154
            ],
            [
              359532.46061997494,
              5005597.793099422
            ],
            [
              359525.90967238991,
              5005592.7533141971
            ],
            [
              359540.40497024631,
              5005572.6016805749
            ],
            [
              359547.65661897411,
              5005569.0257790415
            ],
            [
              359553.11504375463,
              5005561.6120774224
            ],
            [
              359560.64504983154,
              5005549.8585063918
            ],
            [
              359565.89698239992,
              5005554.4923280356
            ],
            [
              359574.41566652304,
              5005549.6024731714
            ],
            [
              359584.05876490066,
              5005558.8860719362
            ],
            [
              359603.55008726235,
              5005542.1786878863
            ],
            [
              359599.58118141635,
              5005537.0909436215
            ],
            [
              359606.72890058096,
              5005527.9252748545
            ],
            [
              359613.71809385502,
              5005533.3870279901
            ],
            [
              359644.68442974909,
              5005508.7239198061
            ],
            [
              359657.4678151019,
              5005524.8312277002
            ],
            [
              359664.68748498365,
              5005519.5354589811
            ],
            [
              359670.64011834742,
              5005515.5535959443
            ],
            [
              359676.95114076859,
              5005507.6938818172
            ],
            [
              359697.34583916637,
              5005539.5745402193
            ],
            [
              359721.23648669344,
              5005527.9469507523
            ],
            [
              359715.96057328657,
              5005522.0231809001
            ],
            [
              359733.0698005865,
              5005516.1133763865
            ],
            [
              359739.14235054638,
              5005518.5812632032
            ],
            [
              359748.19529979496,
              5005519.2732248893
            ],
            [
              359758.88158131373,
              5005515.2033518981
            ],
            [
              359762.42014630773,
              5005520.299143224
            ],
            [
              359779.47340917093,
              5005511.3794250851
            ],
            [
              359797.41133468907,
              5005503.7337032296
            ],
            [
              359811.07792815444,
              5005497.8879083209
            ],
            [
              359824.25821955036,
              5005489.0402008966
            ],
            [
              359852.97947892296,
              5005467.8646782469
            ],
            [
              359936.14893610537,
              5005542.0213959143
            ],
            [
              359955.0181175308,
              5005515.0024376446
            ],
            [
              359973.09057756129,
              5005491.4393643187
            ],
            [
              359995.17191791441,
              5005448.6909862775
            ],
            [
              360003.4281365574,
              5005452.8387829065
            ],
            [
              360017.62898903777,
              5005452.5747545036
            ],
            [
              360032.6504611508,
              5005450.1447951095
            ],
            [
              360048.4927253896,
              5005445.5489498423
            ],
            [
              360059.68928154948,
              5005445.7708943579
            ],
            [
              360079.52434183238,
              5005447.5527861482
            ],
            [
              360038.2499791474,
              5005404.0166928982
            ],
            [
              360051.40230301477,
              5005386.11628915
            ],
            [
              360037.40210869728,
              5005372.913466813
            ],
            [
              360005.66926354909,
              5005344.1702416316
            ],
            [
              359998.17787924042,
              5005334.8466436267
            ],
            [
              360004.55285431113,
              5005330.4268198283
            ],
            [
              360024.73696341366,
              5005304.6738262717
            ],
            [
              360042.82533705578,
              5005281.9707520958
            ],
            [
              360047.79746097181,
              5005271.5551543403
            ],
            [
              360040.2349124882,
              5005267.1778865727
            ],
            [
              360017.11567489331,
              5005250.8326344639
            ],
            [
              360002.81089824956,
              5005245.5068922685
            ],
            [
              359983.44479984668,
              5005222.639837401
            ],
            [
              359967.17084817571,
              5005204.0166879082
            ],
            [
              359951.97341443965,
              5005196.986980428
            ],
            [
              359942.4356014583,
              5005216.5201804908
            ],
            [
              359925.85930018692,
              5005204.7847402915
            ],
            [
              359915.29158900533,
              5005192.0772617804
            ],
            [
              359887.02443266701,
              5005176.6879382115
            ],
            [
              359864.95772570447,
              5005170.646266222
            ],
            [
              359844.2459869801,
              5005168.0203764979
            ],
            [
              359828.2678966872,
              5005165.3065283811
            ],
            [
              359795.33903177147,
              5005153.8750534905
            ],
            [
              359787.9510177534,
              5005163.9841790041
            ],
            [
              359759.25627859455,
              5005195.056942991
            ],
            [
              359750.10740183672,
              5005189.205221667
            ],
            [
              359738.88685341977,
              5005187.6933188047
            ],
            [
              359725.08435180702,
              5005186.2293706173
            ],
            [
              359721.80959686625,
              5005195.3230240839
            ],
            [
              359715.18014566577,
              5005209.2104664873
            ],
            [
              359705.7848780987,
              5005213.2563282186
            ],
            [
              359699.57643010165,
              5005203.4787512692
            ],
            [
              359685.30496005929,
              5005223.0999724474
            ],
            [
              359670.19679341681,
              5005244.0271476517
            ],
            [
              359663.67791673267,
              5005240.7073215628
            ],
            [
              359654.74631020968,
              5005269.6921567386
            ],
            [
              359639.03992661455,
              5005281.5976850037
            ],
            [
              359628.02722359065,
              5005291.2653213739
            ],
            [
              359619.11025985068,
              5005297.8830860434
            ],
            [
              359610.15332443552,
              5005302.3509178907
            ],
            [
              359597.59243823594,
              5005275.0560609372
            ],
            [
              359587.21522918722,
              5005249.4411158245
            ],
            [
              359574.11208814435,
              5005216.1344711259
            ],
            [
              359566.96301701234,
              5005202.0730731115
            ],
            [
              359569.94341958518,
              5005200.2971498994
            ],
            [
              359575.86408213031,
              5005194.595373367
            ],
            [
              359578.39007580181,
              5005191.5374541618
            ],
            [
              359583.41017463634,
              5005183.7017924422
            ],
            [
              359593.3077326006,
              5005183.5177804604
            ],
            [
              359604.68017355032,
              5005193.1993534965
            ],
            [
              359608.79154106876,
              5005182.7997467685
            ],
            [
              359613.42926444695,
              5005177.5519871935
            ],
            [
              359615.93927040411,
              5005173.6341335913
            ],
            [
              359628.5147950821,
              5005178.5619185967
            ],
            [
              359641.07290498266,
              5005159.4026658311
            ],
            [
              359651.5366997284,
              5005166.5203394322
            ],
            [
              359659.91948869295,
              5005154.3208100162
            ],
            [
              359676.92614168447,
              5005166.0483089918
            ],
            [
              359689.98787618492,
              5005173.9779616501
            ],
            [
              359702.0771001387,
              5005175.903879066
            ],
            [
              359716.83280032611,
              5005176.2323332196
            ],
            [
              359716.66492452822,
              5005167.2026838437
            ],
            [
              359692.91690371156,
              5005163.3428941444
            ],
            [
              359671.15261858422,
              5005150.4134514527
            ],
            [
              359672.72209062945,
              5005142.2117808871
            ],
            [
              359684.83521135215,
              5005145.4276511427
            ],
            [
              359686.37270669237,
              5005135.5060155448
            ],
            [
              359694.15853388159,
              5005137.5119410148
            ],
            [
              359724.9437893937,
              5005149.4133899091
            ],
            [
              359726.54515128682,
              5005142.9316393891
            ],
            [
              359719.53198436071,
              5005136.1799301794
            ],
            [
              359702.25487577467,
              5005133.0601142887
            ],
            [
              359681.00087201112,
              5005124.4224868687
            ],
            [
              359682.57833835122,
              5005116.650783997
            ],
            [
              359688.05267325899,
              5005110.0970767317
            ],
            [
              359691.44734080549,
              5005107.4531732341
            ],
            [
              359685.87565776595,
              5005085.620076674
            ],
            [
              359676.80680528533,
              5005084.0681191348
            ],
            [
              359660.48630216444,
              5005086.0920995651
            ],
            [
              359663.77704881126,
              5005077.858428251
            ],
            [
              359688.20855047676,
              5005049.0155524043
            ],
            [
              359663.32994471159,
              5005013.5204771869
            ],
            [
              359647.59832737508,
              5005000.908985897
            ],
            [
              359630.07340693928,
              5004984.4596926421
            ],
            [
              359608.83406423323,
              5004953.4549786821
            ],
            [
              359596.31317395304,
              5004928.3099940773
            ],
            [
              359586.49555349321,
              5004932.7938174419
            ],
            [
              359579.72422584443,
              5004917.2660451122
            ],
            [
              359583.5088530684,
              5004903.0347819077
            ],
            [
              359582.44942238863,
              5004871.6562439706
            ],
            [
              359582.90263280022,
              5004841.2708644625
            ],
            [
              359586.52454151184,
              5004831.2131664027
            ],
            [
              359591.58320633136,
              5004821.6045037601
            ],
            [
              359601.79770250531,
              5004807.6184275793
            ],
            [
              359607.96733451245,
              5004806.552240002
            ],
            [
              359634.79441167472,
              5004764.1892207628
            ],
            [
              359642.91066452774,
              5004739.7760472465
            ],
            [
              359651.35245257331,
              5004707.2694417331
            ],
            [
              359654.55575517478,
              5004670.7267859764
            ],
            [
              359665.98450117459,
              5004619.6111673722
            ],
            [
              359678.69078970799,
              5004560.3843262047
            ],
            [
              359688.72708487423,
              5004511.1974850865
            ],
            [
              359696.84277877858,
              5004455.4533044863
            ],
            [
              359705.58678638103,
              5004428.5019435333
            ],
            [
              359561.9043010649,
              5004497.8455742709
            ],
            [
              359530.78690345504,
              5004482.7990161227
            ],
            [
              359506.23618419247,
              5004476.5696463166
            ],
            [
              359466.80100113334,
              5004467.7101589302
            ],
            [
              359438.78773972305,
              5004462.9986143941
            ],
            [
              359384.80755743431,
              5004449.2838474205
            ],
            [
              359321.77412314148,
              5004422.8406073311
            ],
            [
              359316.43725366495,
              5004417.4168163808
            ],
            [
              359392.76787874574,
              5004376.7555531021
            ],
            [
              359444.49465511838,
              5004342.6559981974
            ],
            [
              359458.65163112868,
              5004337.4511956498
            ],
            [
              359555.87264458835,
              5004265.1057701409
            ],
            [
              359605.89478782861,
              5004228.5382631365
            ],
            [
              359623.60285647825,
              5004215.5931995148
            ],
            [
              359715.00260278228,
              5004151.9746363228
            ],
            [
              359794.22239966068,
              5004090.7510651574
            ],
            [
              359828.5880736424,
              5004067.3499797545
            ],
            [
              359879.48566423071,
              5004030.3428702028
            ],
            [
              359921.83851725678,
              5003992.6795316311
            ],
            [
              359929.6799981583,
              5003985.7062986596
            ],
            [
              360013.09943684674,
              5003904.8372058999
            ],
            [
              360046.51870256552,
              5003930.7996196998
            ],
            [
              360048.37120265939,
              5003928.2334148837
            ],
            [
              360090.18640275823,
              5003962.900973293
            ],
            [
              360105.95515283779,
              5003982.3412140198
            ],
            [
              360108.40344320628,
              5003981.5885614194
            ],
            [
              360118.33353920386,
              5003978.9110391131
            ],
            [
              360130.85042873252,
              5003981.1713134423
            ],
            [
              360137.32577659254,
              5003980.5523895109
            ],
            [
              360143.73635619099,
              5003976.4444670957
            ],
            [
              360153.00085765246,
              5003964.8046760429
            ],
            [
              360154.32131822116,
              5003955.3069307404
            ],
            [
              360161.59196308028,
              5003970.6280777501
            ],
            [
              360164.32157425984,
              5003985.1217501676
            ],
            [
              360174.38276558352,
              5003978.1793334205
            ],
            [
              360293.0502479432,
              5003884.9246014962
            ],
            [
              360306.09831530921,
              5003886.6331217512
            ],
            [
              360433.16499321308,
              5003788.3435293753
            ],
            [
              360521.94729892194,
              5003719.6679931469
            ],
            [
              360586.27936941996,
              5003644.3324847426
            ],
            [
              360600.6164797126,
              5003645.3667013813
            ],
            [
              360656.13362312358,
              5003572.2817607215
            ],
            [
              360683.60147087264,
              5003544.4565712316
            ],
            [
              360712.13903564092,
              5003469.1360440552
            ],
            [
              360714.60650812055,
              5003426.8175326996
            ],
            [
              360699.44960623962,
              5003416.6936990041
            ],
            [
              360680.40125278983,
              5003407.2925080312
            ],
            [
              360697.6651270953,
              5003390.7129241936
            ],
            [
              360719.33810322947,
              5003366.2472142912
            ],
            [
              360736.74691981444,
              5003357.4691185569
            ],
            [
              360812.96006121126,
              5003255.5913389493
            ],
            [
              360797.70650270692,
              5003240.2664887318
            ],
            [
              360775.95915790211,
              5003225.7126453761
            ],
            [
              360750.47738514008,
              5003220.3331239484
            ],
            [
              360740.67016018362,
              5003217.913995699
            ],
            [
              360686.8867749226,
              5003195.5010721507
            ],
            [
              360678.32202016521,
              5003188.7388513023
            ],
            [
              360603.47397846467,
              5002989.8938212767
            ],
            [
              360596.18218364537,
              5002973.900989186
            ],
            [
              360598.86147290352,
              5002973.3135797102
            ],
            [
              360607.34735806152,
              5002966.7045348836
            ],
            [
              360617.42686079087,
              5002958.9906441355
            ],
            [
              360627.2366832564,
              5002936.7662194846
            ],
            [
              360626.52904360136,
              5002927.639967245
            ],
            [
              360552.21373334812,
              5002874.0054969564
            ],
            [
              360269.37466776656,
              5002712.846546771
            ],
            [
              360226.26567054942,
              5002766.8213418024
            ],
            [
              360249.98002471193,
              5002791.7573307576
            ],
            [
              360250.85898186377,
              5002824.6717156032
            ],
            [
              360209.37291115616,
              5002821.1241203696
            ],
            [
              360207.03308620659,
              5002830.8844956225
            ],
            [
              360245.67881663446,
              5002836.6441984046
            ],
            [
              360214.91607472749,
              5002867.4462298192
            ],
            [
              360190.5128989091,
              5002872.2184043527
            ],
            [
              360151.26837414474,
              5002892.3815443665
            ],
            [
              360113.03048683243,
              5002908.5672164969
            ],
            [
              360068.81110383576,
              5002912.9878805159
            ],
            [
              360025.89690090675,
              5002921.8459403608
            ],
            [
              359992.65289933298,
              5002935.4196441369
            ],
            [
              359980.0310194632,
              5002934.5745808091
            ],
            [
              359961.09448561998,
              5002923.4101787135
            ],
            [
              359935.28449314117,
              5002930.0079104519
            ],
            [
              359882.45160314062,
              5002955.4619843997
            ],
            [
              359837.91810407437,
              5002989.915237911
            ],
            [
              359826.17015151237,
              5002997.3312726608
            ],
            [
              359821.13039757212,
              5003016.8587401956
            ],
            [
              359802.70862789039,
              5003033.3959512897
            ],
            [
              359800.66402541735,
              5003078.4195729373
            ],
            [
              359792.02236610639,
              5003154.0077974508
            ],
            [
              359834.89721608022,
              5003193.5180633198
            ],
            [
              359847.50290919136,
              5003203.6810542699
            ],
            [
              359870.8121230168,
              5003219.7131847264
            ],
            [
              359899.45462677546,
              5003247.5693805693
            ],
            [
              359935.68139773625,
              5003286.0722146565
            ],
            [
              359972.58964277612,
              5003339.8594141062
            ],
            [
              359956.84858919587,
              5003346.963286438
            ],
            [
              359956.81734114746,
              5003347.0167673277
            ],
            [
              359965.0018372389,
              5003359.5884959185
            ],
            [
              359983.72938014369,
              5003358.3887761943
            ],
            [
              360005.63049420493,
              5003465.238445418
            ],
            [
              359997.36480148538,
              5003494.3483872162
            ],
            [
              359981.37163375254,
              5003489.8195837652
            ],
            [
              359975.21770709561,
              5003488.39061483
            ],
            [
              359971.43773850013,
              5003498.1777691105
            ],
            [
              359993.32647716271,
              5003506.3466353826
            ],
            [
              359982.04151944997,
              5003525.8605694128
            ],
            [
              359954.62188424752,
              5003548.5132705458
            ],
            [
              359930.3242069991,
              5003562.5585699528
            ],
            [
              359924.34460028802,
              5003561.8180691842
            ],
            [
              359899.65933528141,
              5003578.7422074778
            ],
            [
              359877.50891243096,
              5003594.4837207049
            ],
            [
              359866.80211874866,
              5003586.2933466043
            ],
            [
              359857.56784472021,
              5003593.3027985841
            ],
            [
              359864.60273773229,
              5003603.6087028217
            ],
            [
              359846.18099871738,
              5003620.1459431527
            ],
            [
              359834.59354657744,
              5003636.1962743877
            ],
            [
              359825.44086995599,
              5003636.0471248487
            ],
            [
              359821.55466546043,
              5003640.9454248399
            ],
            [
              359823.59785184806,
              5003651.4195850212
            ],
            [
              359811.88334753708,
              5003660.6344260471
            ],
            [
              359796.86252890521,
              5003685.7457172675
            ],
            [
              359786.09447509033,
              5003687.7452712599
            ],
            [
              359767.1856425539,
              5003697.4537573447
            ],
            [
              359763.18599612341,
              5003714.8026200505
            ],
            [
              359740.77692558669,
              5003729.9744228013
            ],
            [
              359730.2428250403,
              5003749.0450512087
            ],
            [
              359719.78131425503,
              5003748.1598611241
            ],
            [
              359712.87452752067,
              5003764.123162142
            ],
            [
              359694.32573367847,
              5003773.8249622313
            ],
            [
              359682.70021917712,
              5003826.5843096031
            ],
            [
              359691.13523132104,
              5003834.7049028184
            ],
            [
              359691.15425029682,
              5003841.3630806608
            ],
            [
              359668.20003346051,
              5003885.3358642124
            ],
            [
              359643.94395143265,
              5003898.0228463337
            ],
            [
              359611.72095857625,
              5003927.7725268472
            ],
            [
              359608.35759535414,
              5003940.5343692582
            ],
            [
              359598.16198924027,
              5003972.8029375989
            ],
            [
              359579.91776360787,
              5003987.2471235339
            ],
            [
              359560.03573400259,
              5004002.7318601841
            ],
            [
              359532.47234026442,
              5004011.8814805551
            ],
            [
              359505.29566583328,
              5004022.4634390222
            ],
            [
              359464.90413504536,
              5004039.0492194304
            ],
            [
              359425.1791828383,
              5004052.7435235372
            ],
            [
              359408.03362846404,
              5004071.6022408288
            ],
            [
              359397.29231476592,
              5004075.0408760812
            ],
            [
              359385.55105878151,
              5004082.81675296
            ],
            [
              359379.37105074682,
              5004099.1264865361
            ],
            [
              359367.11599653977,
              5004118.0162445363
            ],
            [
              359366.41854860779,
              5004118.2353115007
            ],
            [
              359363.69703740237,
              5004127.8487851555
            ],
            [
              359355.39418461075,
              5004146.8749591606
            ],
            [
              359353.44190873677,
              5004146.2385161342
            ],
            [
              359337.49462757132,
              5004152.0882976288
            ],
            [
              359313.76467699808,
              5004154.3288515238
            ],
            [
              359287.21451575233,
              5004159.8608019101
            ],
            [
              359268.79946017911,
              5004176.7578569297
            ],
            [
              359258.75737331767,
              5004198.1193371015
            ],
            [
              359237.93807254976,
              5004224.33993317
            ],
            [
              359225.40640039282,
              5004237.9155194741
            ],
            [
              359217.66782001243,
              5004241.955197881
            ],
            [
              359188.78001962084,
              5004262.7762955036
            ],
            [
              359173.20523603336,
              5004277.4612732045
            ],
            [
              359157.06418543082,
              5004300.434133919
            ],
            [
              359140.22202381131,
              5004305.0658330768
            ],
            [
              359124.3997959707,
              5004306.4395992281
            ],
            [
              359102.88364856743,
              5004311.5181045868
            ],
            [
              359089.97525584284,
              5004314.6371526681
            ],
            [
              359070.34632054641,
              5004324.3590786904
            ],
            [
              359060.10548101895,
              5004335.3460158026
            ],
            [
              359054.89859952772,
              5004345.8794944156
            ],
            [
              359026.24938908138,
              5004374.1232677829
            ],
            [
              359007.34722201078,
              5004384.191570661
            ],
            [
              358994.82264320942,
              5004395.4456889816
            ],
            [
              358974.28739394021,
              5004414.5415139031
            ],
            [
              358941.66421512101,
              5004442.1394098634
            ],
            [
              358870.66235617915,
              5004501.5564993694
            ],
            [
              358843.41993864544,
              5004527.9747388782
            ],
            [
              358821.16466659488,
              5004551.4211777011
            ],
            [
              358789.8470429086,
              5004599.3718709564
            ],
            [
              358764.69241816906,
              5004641.2264342448
            ],
            [
              358759.57255431946,
              5004656.4368145699
            ],
            [
              358752.33912167599,
              5004674.2057385892
            ],
            [
              358737.49980255577,
              5004697.6771490667
            ],
            [
              358721.71211260412,
              5004720.2835690212
            ],
            [
              358711.43114729592,
              5004729.1119822068
            ],
            [
              358697.50281186093,
              5004735.4889883157
            ],
            [
              358668.49913376902,
              5004744.6654650532
            ],
            [
              358646.81012099254,
              5004759.823996665
            ],
            [
              358623.50704984297,
              5004765.6555212708
            ],
            [
              358596.24344935437,
              5004771.5606362894
            ],
            [
              358582.64839944855,
              5004776.4919211501
            ],
            [
              358563.20948908897,
              5004785.6711471239
            ],
            [
              358537.24672016135,
              5004803.4283905802
            ],
            [
              358517.64445100218,
              5004814.5893935254
            ],
            [
              358504.50314354838,
              5004824.550637316
            ],
            [
              358491.90690511925,
              5004805.7108127074
            ],
            [
              358470.02584816964,
              5004815.4449884882
            ],
            [
              358458.40058091871,
              5004819.8387690773
            ],
            [
              358410.58486544393,
              5004856.655765295
            ],
            [
              358388.29447844304,
              5004872.1098154383
            ],
            [
              358366.12831430818,
              5004894.2458543405
            ],
            [
              358353.03312333237,
              5004909.5289621707
            ],
            [
              358344.1174996795,
              5004924.734407668
            ],
            [
              358343.62318931171,
              5004943.125384273
            ],
            [
              358335.62106534949,
              5004962.4915096397
            ],
            [
              358310.09573590377,
              5004983.8544759983
            ],
            [
              358288.65680347261,
              5005000.1282417635
            ],
            [
              358277.88298852713,
              5005005.3417722983
            ],
            [
              358259.46167050174,
              5005004.0131423725
            ],
            [
              358238.61021180014,
              5005006.9074309776
            ],
            [
              358201.96943870996,
              5005015.1084573772
            ],
            [
              358165.79913622566,
              5005030.350624105
            ],
            [
              358165.79913622438,
              5005030.350624104
            ],
            [
              358102.4041858936,
              5005057.0652629761
            ],
            [
              358020.26590712514,
              5005093.901759292
            ],
            [
              357961.79159056634,
              5005021.3792447625
            ],
            [
              357832.72347664757,
              5005113.9130797675
            ],
            [
              357874.3319853936,
              5005168.7223404022
            ],
            [
              357885.18660815601,
              5005186.5474004606
            ],
            [
              357622.48612440063,
              5005368.6956730746
            ],
            [
              357590.17354421515,
              5005328.7359537799
            ],
            [
              357517.70911975083,
              5005393.1773157893
            ],
            [
              357469.26153375983,
              5005455.6699078185
            ],
            [
              357446.24978312798,
              5005511.6806471087
            ],
            [
              357393.66083677678,
              5005621.7484398196
            ],
            [
              357416.50992981333,
              5005637.8482329492
            ],
            [
              357413.95105763059,
              5005661.9316877536
            ],
            [
              357405.52067654062,
              5005693.6354571441
            ],
            [
              357385.09574911423,
              5005727.0645107944
            ],
            [
              357270.50214074086,
              5005870.4058320802
            ],
            [
              357255.78131188819,
              5005887.2042155983
            ],
            [
              357206.35508601193,
              5005936.5054640453
            ],
            [
              357195.1538637795,
              5005951.4747427786
            ],
            [
              357186.04603923165,
              5005973.3752006283
            ],
            [
              357146.0908416374,
              5006042.0278609172
            ],
            [
              357147.06551260769,
              5006070.0790580427
            ],
            [
              357164.6054499108,
              5006087.4094589455
            ],
            [
              357124.11167351756,
              5006134.5566854281
            ],
            [
              357104.2511477751,
              5006162.995343375
            ],
            [
              357036.37359886424,
              5006240.7691076556
            ],
            [
              357016.48829087702,
              5006272.2612898992
            ],
            [
              356983.26677703211,
              5006313.1721548606
            ],
            [
              356934.54588464001,
              5006373.3859977378
            ],
            [
              356914.4932624927,
              5006415.8629272152
            ],
            [
              356881.79485082597,
              5006446.2740895357
            ],
            [
              356845.87779604353,
              5006489.7811601544
            ],
            [
              356819.83273802162,
              5006522.4260861501
            ],
            [
              356790.32486937422,
              5006558.1504824469
            ],
            [
              356767.81735592912,
              5006591.7346349852
            ],
            [
              356755.46887442243,
              5006621.566768935
            ],
            [
              356757.0493520266,
              5006634.6482206555
            ],
            [
              356753.04841751663,
              5006643.1853648126
            ],
            [
              356729.8505245187,
              5006687.0790256942
            ],
            [
              356665.15042983374,
              5006740.7991015585
            ],
            [
              356618.44177270826,
              5006787.3936418714
            ],
            [
              356595.47924841807,
              5006803.0485336976
            ],
            [
              356564.75655160588,
              5006832.1419943105
            ],
            [
              356516.56436320278,
              5006896.4206877341
            ],
            [
              356514.09075138596,
              5006909.5959313838
            ],
            [
              356482.10186218144,
              5006943.6930122701
            ],
            [
              356487.53361347853,
              5006951.98411009
            ],
            [
              356474.4237461653,
              5006968.2481422117
            ],
            [
              356449.00313853094,
              5006999.7848182265
            ],
            [
              356420.58037703385,
              5007055.0939910598
            ],
            [
              356378.82270732295,
              5007083.7799354801
            ],
            [
              356376.29867018241,
              5007094.2397142006
            ],
            [
              356285.47109467239,
              5007156.1422949499
            ],
            [
              356232.05935781711,
              5007183.394084841
            ],
            [
              356170.85085175128,
              5007220.6567582432
            ],
            [
              356124.32600830315,
              5007260.7178384028
            ],
            [
              356069.28335349442,
              5007302.4448631359
            ],
            [
              356023.54715771921,
              5007359.1718797395
            ],
            [
              356005.05578135775,
              5007374.2221679762
            ],
            [
              355957.65844818117,
              5007408.153188671
            ],
            [
              355913.54956966301,
              5007448.3612922868
            ],
            [
              355916.34294817573,
              5007452.3839598792
            ],
            [
              355902.28653267276,
              5007476.1875047823
            ],
            [
              355879.09599596105,
              5007541.3553439183
            ],
            [
              355845.49181978201,
              5007573.1359919487
            ],
            [
              355819.02636282676,
              5007583.1759383772
            ],
            [
              355739.22829795512,
              5007608.9240284925
            ],
            [
              355701.84723250539,
              5007663.7470707605
            ],
            [
              355653.9158871526,
              5007731.2280409904
            ],
            [
              355631.05313965381,
              5007768.7774061766
            ],
            [
              355593.8265907712,
              5007787.2478107465
            ],
            [
              355555.97801406163,
              5007830.2429576404
            ],
            [
              355582.33443761821,
              5007858.7277173866
            ],
            [
              355463.80543861754,
              5007970.6397272749
            ],
            [
              355408.97743753443,
              5008019.1965486975
            ],
            [
              355328.75610794412,
              5008099.8275106801
            ],
            [
              355229.98924222408,
              5008222.0918043107
            ],
            [
              355217.15694125689,
              5008238.6289273724
            ],
            [
              355182.53846420947,
              5008282.0239013219
            ],
            [
              355127.29287339881,
              5008356.8471724186
            ],
            [
              355123.09178375587,
              5008374.5819276785
            ],
            [
              355111.15699048952,
              5008390.6496432414
            ],
            [
              355093.15310461953,
              5008408.4520854279
            ],
            [
              355079.47076546488,
              5008417.5136617254
            ],
            [
              355048.08619810949,
              5008421.5141922031
            ],
            [
              355031.93180998805,
              5008429.9639040772
            ],
            [
              355001.56943579525,
              5008454.0708612744
            ],
            [
              354973.85741157847,
              5008474.5066157822
            ],
            [
              354924.59447921551,
              5008520.3689187672
            ],
            [
              354885.61947209231,
              5008568.6310518971
            ],
            [
              354873.58365514508,
              5008579.2678215774
            ],
            [
              354837.55197260546,
              5008615.2513582911
            ],
            [
              354821.6021818603,
              5008621.4935623333
            ],
            [
              354803.79144806421,
              5008601.4961615754
            ],
            [
              354818.18998107576,
              5008573.0544914147
            ],
            [
              354824.99083459884,
              5008548.9330279343
            ],
            [
              354828.29448276066,
              5008531.6676729405
            ],
            [
              354827.71900203382,
              5008504.7638946883
            ],
            [
              354805.45209574542,
              5008474.2694747085
            ],
            [
              354827.86384629935,
              5008415.1197811002
            ],
            [
              354831.05646384275,
              5008367.5232832422
            ],
            [
              354791.31368189043,
              5008350.153146835
            ],
            [
              354763.10495100688,
              5008343.8868379341
            ],
            [
              354750.5151751957,
              5008349.101101215
            ],
            [
              354727.21467889968,
              5008363.1165478136
            ],
            [
              354663.05165301164,
              5008421.3546189396
            ],
            [
              354630.35719171027,
              5008441.8831082266
            ],
            [
              354582.74089315668,
              5008464.047339594
            ],
            [
              354535.69009757112,
              5008493.2980641006
            ],
            [
              354510.13498103974,
              5008532.2558961408
            ],
            [
              354506.56361248018,
              5008559.4864183655
            ],
            [
              354509.50013225974,
              5008571.202902562
            ],
            [
              354564.47264674242,
              5008675.0091128619
            ],
            [
              354607.54257853836,
              5008773.8095778897
            ],
            [
              354608.01126564579,
              5008799.0048422916
            ],
            [
              354573.46178972215,
              5008843.0659976462
            ],
            [
              354624.45408265875,
              5008880.5998813258
            ],
            [
              354684.16633346345,
              5008920.4822808225
            ],
            [
              354719.19616219471,
              5008952.4275543401
            ],
            [
              354740.89697453874,
              5008974.2078504665
            ],
            [
              354775.33384538727,
              5008936.8176469114
            ],
            [
              354773.95341743395,
              5008973.1401475556
            ],
            [
              354779.69177368394,
              5008989.3318549544
            ],
            [
              354780.34683124622,
              5009000.1853195317
            ],
            [
              354797.16040412878,
              5009011.2268593581
            ],
            [
              354803.0293389443,
              5009026.0219410248
            ],
            [
              354799.1549188369,
              5009036.9596663099
            ],
            [
              354814.01951976615,
              5009056.6034281394
            ],
            [
              354829.74787996942,
              5009073.9674989842
            ],
            [
              354819.79155647143,
              5009074.6054408774
            ],
            [
              354794.56248230621,
              5009079.4462770224
            ],
            [
              354786.59467313107,
              5009089.5546659213
            ],
            [
              354770.1304913584,
              5009105.7066830499
            ],
            [
              354757.0641921268,
              5009134.0192758869
            ],
            [
              354749.61680371308,
              5009147.7398566734
            ],
            [
              354747.10116885311,
              5009158.6523072254
            ],
            [
              354731.60177869414,
              5009177.955489656
            ],
            [
              354705.3310981731,
              5009178.4442117484
            ],
            [
              354691.81863970886,
              5009182.7702229032
            ],
            [
              354676.1592783067,
              5009193.4744303869
            ],
            [
              354668.65288523625,
              5009204.0269658724
            ],
            [
              354667.96949480148,
              5009214.7303533116
            ],
            [
              354677.2640649018,
              5009227.2340321038
            ],
            [
              354687.27090891969,
              5009229.3115302501
            ],
            [
              354688.81498962181,
              5009239.2429712983
            ],
            [
              354697.16160704079,
              5009249.5005853586
            ],
            [
              354708.58623845188,
              5009254.720854016
            ],
            [
              354730.79058440612,
              5009279.2081871368
            ],
            [
              354742.85164344549,
              5009269.9291300047
            ],
            [
              354778.10882746999,
              5009314.0939181093
            ],
            [
              354784.04760633881,
              5009316.6998569565
            ],
            [
              354787.75528983545,
              5009321.1582377488
            ],
            [
              354815.46232567448,
              5009349.1650602762
            ],
            [
              354805.16098741471,
              5009375.8739456981
            ],
            [
              354788.73043017503,
              5009393.8362856247
            ],
            [
              354788.98302255728,
              5009407.4136205297
            ],
            [
              354797.45593911951,
              5009424.4598947885
            ],
            [
              354793.48891319538,
              5009430.4192276038
            ],
            [
              354819.89604588487,
              5009461.6193707157
            ],
            [
              354837.29981152696,
              5009447.2608320452
            ],
            [
              354853.89027153637,
              5009437.8975064382
            ],
            [
              354880.730071862,
              5009419.2887897864
            ],
            [
              354914.3841151807,
              5009468.375759148
            ],
            [
              354921.39966059994,
              5009489.2182014212
            ],
            [
              354931.78700603958,
              5009483.2186353309
            ],
            [
              354956.98485944734,
              5009452.2103108782
            ],
            [
              354988.21546832676,
              5009425.2040139372
            ],
            [
              355015.92713901284,
              5009374.5808645329
            ],
            [
              355037.18428962544,
              5009358.8626553146
            ],
            [
              355073.64693112852,
              5009328.0012347298
            ],
            [
              355100.21184179763,
              5009306.3069988079
            ],
            [
              355113.37003898254,
              5009289.9563098494
            ],
            [
              355153.07970871037,
              5009240.3497061962
            ],
            [
              355217.78953545511,
              5009162.2509005666
            ],
            [
              355282.42993117991,
              5009072.3473633975
            ],
            [
              355337.96764948143,
              5009003.761653956
            ],
            [
              355356.60816462454,
              5008974.3706912566
            ],
            [
              355422.37399218976,
              5008885.2673929995
            ],
            [
              355454.88059022959,
              5008831.583231193
            ],
            [
              355468.70873096242,
              5008802.1337906159
            ],
            [
              355485.5923854658,
              5008762.4333919669
            ],
            [
              355494.88089231256,
              5008734.8376384433
            ],
            [
              355516.98497085768,
              5008729.8770269426
            ],
            [
              355533.19312816259,
              5008728.6487768115
            ],
            [
              355565.9246922452,
              5008693.2873164518
            ],
            [
              355579.28618924721,
              5008671.4794780677
            ],
            [
              355654.11500260147,
              5008572.8987346319
            ],
            [
              355816.10719008144,
              5008393.0741276406
            ],
            [
              355863.69057313923,
              5008336.9703129753
            ],
            [
              355876.39162455528,
              5008322.6321699638
            ],
            [
              355918.39975866355,
              5008284.4998410689
            ],
            [
              355991.24573755043,
              5008202.3448295612
            ],
            [
              356161.44983178028,
              5008011.6975989603
            ],
            [
              356262.16475573211,
              5007900.8632173538
            ],
            [
              356271.36691375455,
              5007909.1364215882
            ],
            [
              356278.17791000963,
              5007915.0958290026
            ],
            [
              356277.90847098437,
              5007933.3591085067
            ],
            [
              356276.27415478457,
              5007943.7358665727
            ],
            [
              356271.5070701706,
              5007982.1669299938
            ],
            [
              356243.35327052308,
              5008007.6435381817
            ],
            [
              356229.01148361503,
              5008022.5169273047
            ],
            [
              356207.60071845405,
              5008050.3026159266
            ],
            [
              356264.37673216319,
              5008090.0233892566
            ],
            [
              356256.33887789305,
              5008116.3431138769
            ],
            [
              356303.70992242912,
              5008141.6321761291
            ],
            [
              356433.79773714748,
              5007959.5374384765
            ],
            [
              356467.25205702457,
              5007938.745571834
            ],
            [
              356489.55125911348,
              5007924.6866456429
            ],
            [
              356544.10284129909,
              5007879.5430783061
            ],
            [
              356604.22928426875,
              5007824.4414012143
            ],
            [
              356649.87841893005,
              5007779.4297323953
            ],
            [
              356880.05789154192,
              5007538.919638576
            ],
            [
              356845.85927430226,
              5007482.2140109334
            ],
            [
              356898.39158755529,
              5007433.7791362992
            ],
            [
              356932.22986059898,
              5007401.7089591483
            ],
            [
              356991.52022689389,
              5007333.572044529
            ],
            [
              357110.42774375994,
              5007350.4198225802
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 10,
        "Layer" : "STARO_GP",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354158.50739367498,
              5009987.9294792442
            ],
            [
              354157.93683143839,
              5009980.5730306441
            ],
            [
              354207.95907492237,
              5009917.4254086679
            ],
            [
              353906.30650583701,
              5009539.5984529229
            ],
            [
              353880.83633187675,
              5009539.4939664518
            ],
            [
              353683.24943297467,
              5009667.8221760914
            ],
            [
              353550.76175901946,
              5009764.5596279046
            ],
            [
              353515.1150929142,
              5009808.5998015394
            ],
            [
              353481.22589459684,
              5009853.7639745213
            ],
            [
              353447.62443418248,
              5009896.6761016734
            ],
            [
              353408.52117321681,
              5009922.3043363523
            ],
            [
              353392.66036825022,
              5009936.514557017
            ],
            [
              353313.69681224786,
              5010025.8685731869
            ],
            [
              353327.04116291879,
              5010112.772786404
            ],
            [
              353387.98445966613,
              5010197.326561979
            ],
            [
              353380.23292925063,
              5010221.4245273592
            ],
            [
              353374.31676272367,
              5010218.6051021209
            ],
            [
              353189.82887534023,
              5010444.8377048885
            ],
            [
              353217.82331505703,
              5010505.6836745963
            ],
            [
              353240.13331348816,
              5010585.8730225181
            ],
            [
              353266.23513735313,
              5010682.5871612132
            ],
            [
              353274.4857451602,
              5010747.2308518449
            ],
            [
              353356.83271718113,
              5010790.76770755
            ],
            [
              353445.59931617422,
              5010769.050597759
            ],
            [
              353481.41695631394,
              5010782.6848888714
            ],
            [
              353502.72396399308,
              5010762.3539622789
            ],
            [
              353519.21344582312,
              5010739.512557311
            ],
            [
              353533.29514633771,
              5010727.1165341325
            ],
            [
              353552.46655531449,
              5010708.5588077018
            ],
            [
              353562.85583407007,
              5010707.4987779846
            ],
            [
              353571.47856651823,
              5010704.7382035209
            ],
            [
              353598.65558346099,
              5010696.8654230796
            ],
            [
              353600.30920645589,
              5010692.5010739509
            ],
            [
              353617.26447308081,
              5010671.3844277216
            ],
            [
              353637.72051935311,
              5010651.9360968554
            ],
            [
              353647.20873996441,
              5010621.9929130217
            ],
            [
              353659.63681576075,
              5010613.9612472253
            ],
            [
              353667.76151804399,
              5010607.7430728236
            ],
            [
              353669.39902017225,
              5010602.5123170689
            ],
            [
              353670.11294540693,
              5010594.2652498744
            ],
            [
              353664.29936618888,
              5010584.8395617083
            ],
            [
              353643.23076074215,
              5010571.3641897896
            ],
            [
              353654.23723314813,
              5010556.8586107297
            ],
            [
              353671.31341788324,
              5010542.2400915986
            ],
            [
              353689.80147264182,
              5010510.2610303331
            ],
            [
              353703.54455459531,
              5010479.6703437781
            ],
            [
              353722.83244437777,
              5010460.4200154329
            ],
            [
              353739.12854901678,
              5010427.1817066232
            ],
            [
              353750.11890328623,
              5010411.8097284669
            ],
            [
              353750.69580204214,
              5010396.1981401527
            ],
            [
              353752.04312482063,
              5010375.3719321089
            ],
            [
              353756.49784993951,
              5010358.3881478375
            ],
            [
              353770.7084547659,
              5010352.9234500127
            ],
            [
              353789.32519186777,
              5010342.3820907651
            ],
            [
              353817.36128966982,
              5010334.0599861359
            ],
            [
              353816.02835637412,
              5010332.3513725605
            ],
            [
              353838.25887616357,
              5010315.0368441222
            ],
            [
              353853.28020512516,
              5010306.5235692374
            ],
            [
              353864.83306976309,
              5010298.0748313991
            ],
            [
              353881.34674687986,
              5010276.5331029957
            ],
            [
              353887.40846321796,
              5010275.986942566
            ],
            [
              353899.33851378568,
              5010264.4976936188
            ],
            [
              353903.96251171065,
              5010256.6112529747
            ],
            [
              353913.21863668488,
              5010241.271555475
            ],
            [
              353921.73657833209,
              5010232.8792903004
            ],
            [
              353941.05314818973,
              5010222.119314895
            ],
            [
              353963.03551354393,
              5010214.776607086
            ],
            [
              353968.30715004151,
              5010210.1719672028
            ],
            [
              354015.62584476318,
              5010165.9559191382
            ],
            [
              354023.686063979,
              5010156.2720950432
            ],
            [
              354031.69792178762,
              5010143.9890502756
            ],
            [
              354027.21729323565,
              5010136.2720160894
            ],
            [
              354026.56612462603,
              5010124.5834912052
            ],
            [
              354016.27999680868,
              5010107.873992742
            ],
            [
              354022.25849889504,
              5010100.9703775104
            ],
            [
              354033.7791222313,
              5010090.7888366468
            ],
            [
              354054.39638075139,
              5010080.0046721352
            ],
            [
              354060.73849081533,
              5010071.2195250895
            ],
            [
              354099.25871394668,
              5010043.6347554903
            ],
            [
              354158.50739367498,
              5009987.9294792442
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 11,
        "Layer" : "STARO_GP",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358678.75913344492,
              5006012.4505350729
            ],
            [
              358671.0233950853,
              5005965.4529451337
            ],
            [
              358661.47725716332,
              5005913.3463151976
            ],
            [
              358639.34038206906,
              5005876.0447981292
            ],
            [
              358610.36283394072,
              5005886.0118708033
            ],
            [
              358576.79826646409,
              5005926.0633500461
            ],
            [
              358580.58141157118,
              5005991.1339029996
            ],
            [
              358597.31877333269,
              5006014.8219720023
            ],
            [
              358606.25951646344,
              5006034.3694027336
            ],
            [
              358613.5332393854,
              5006056.5191798313
            ],
            [
              358630.27060402592,
              5006080.2072433699
            ],
            [
              358647.67419376952,
              5006093.5975157358
            ],
            [
              358694.51448519708,
              5006075.5841971003
            ],
            [
              358686.2240356183,
              5006044.8821527893
            ],
            [
              358678.75913344492,
              5006012.4505350729
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 12,
        "Layer" : "STARO_GP",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355920.07801055594,
              5008708.1371924728
            ],
            [
              355942.05160008563,
              5008688.2229306968
            ],
            [
              355957.11539443437,
              5008679.4221657133
            ],
            [
              355982.42848681536,
              5008664.9532854371
            ],
            [
              356006.44451654365,
              5008646.248218921
            ],
            [
              356028.0250742404,
              5008627.5885006236
            ],
            [
              356032.2194020602,
              5008623.8588392818
            ],
            [
              356057.20650188153,
              5008624.6112221126
            ],
            [
              356062.65255139855,
              5008622.6840966512
            ],
            [
              356076.53252422687,
              5008615.731219898
            ],
            [
              356103.42997212411,
              5008607.7001271453
            ],
            [
              356108.31243946619,
              5008608.2179394439
            ],
            [
              356122.49797518528,
              5008617.6917959908
            ],
            [
              356142.48943085643,
              5008611.8424635893
            ],
            [
              356152.76135892858,
              5008607.3911160957
            ],
            [
              356090.60044356954,
              5008474.653286553
            ],
            [
              356084.56810825894,
              5008477.8085420793
            ],
            [
              356043.47939127567,
              5008402.6850234689
            ],
            [
              356007.32414362545,
              5008396.2742134398
            ],
            [
              356002.12050362391,
              5008381.6442077458
            ],
            [
              355988.01940434886,
              5008381.9065022469
            ],
            [
              355965.06678498624,
              5008411.8676057477
            ],
            [
              355883.00402376259,
              5008514.0787119465
            ],
            [
              355896.01175957237,
              5008527.2613369403
            ],
            [
              355857.13085558376,
              5008567.5872246204
            ],
            [
              355869.36286485358,
              5008585.2566020712
            ],
            [
              355871.87654276576,
              5008612.0590892369
            ],
            [
              355808.75996054331,
              5008649.4796338184
            ],
            [
              355811.57071728777,
              5008656.1396787995
            ],
            [
              355694.80320158886,
              5008770.4073621985
            ],
            [
              355686.62062011991,
              5008763.847278065
            ],
            [
              355669.43670200778,
              5008778.9340116242
            ],
            [
              355663.31851688051,
              5008775.0204512179
            ],
            [
              355611.14527512901,
              5008822.9771837573
            ],
            [
              355612.23826247733,
              5008842.0688332859
            ],
            [
              355653.10382837366,
              5008908.431760639
            ],
            [
              355684.86055130331,
              5008882.3342252122
            ],
            [
              355699.49582853023,
              5008874.6784180645
            ],
            [
              355714.19345996453,
              5008870.3776350999
            ],
            [
              355724.88723137666,
              5008867.4937793342
            ],
            [
              355732.09742382402,
              5008867.1895115273
            ],
            [
              355743.68300851953,
              5008870.6872768104
            ],
            [
              355749.10871797492,
              5008875.5079707727
            ],
            [
              355760.37813383079,
              5008885.5517421924
            ],
            [
              355775.72795216693,
              5008894.2891698033
            ],
            [
              355799.75558516808,
              5008906.1462782891
            ],
            [
              355857.83049479738,
              5008884.3820677176
            ],
            [
              355869.12363336718,
              5008818.3737695413
            ],
            [
              355877.02169346384,
              5008819.1535508763
            ],
            [
              355889.1392879184,
              5008757.9801226733
            ],
            [
              355894.17991172842,
              5008760.3037334811
            ],
            [
              355906.28895562136,
              5008725.1744638998
            ],
            [
              355920.07801055594,
              5008708.1371924728
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 13,
        "Layer" : "STARO_GP",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356138.31739113043,
              5008376.6142816423
            ],
            [
              356171.60502160474,
              5008388.0139561836
            ],
            [
              356178.45195949153,
              5008392.3936806154
            ],
            [
              356191.24067826389,
              5008392.9069605712
            ],
            [
              356193.30092067347,
              5008402.6339696134
            ],
            [
              356223.65114135895,
              5008397.5623746412
            ],
            [
              356287.92731040868,
              5008377.587276265
            ],
            [
              356327.7546715709,
              5008308.0045086229
            ],
            [
              356322.31286883814,
              5008298.3404024262
            ],
            [
              356306.21105698374,
              5008281.362775268
            ],
            [
              356288.40512443573,
              5008273.8066091537
            ],
            [
              356279.45604965318,
              5008257.447131739
            ],
            [
              356276.01861498662,
              5008254.5063481247
            ],
            [
              356276.69886119448,
              5008230.4559884947
            ],
            [
              356244.13107088656,
              5008226.8407681072
            ],
            [
              356228.63536331634,
              5008222.2463139277
            ],
            [
              356215.44295641693,
              5008220.238175218
            ],
            [
              356199.51012085303,
              5008232.5533903269
            ],
            [
              356179.65235487762,
              5008235.9274788266
            ],
            [
              356175.99979143654,
              5008241.6292653708
            ],
            [
              356162.23052396212,
              5008249.0215903772
            ],
            [
              356146.19985719508,
              5008256.080380287
            ],
            [
              356141.06539642706,
              5008262.9364943337
            ],
            [
              356139.13927696005,
              5008280.6250269311
            ],
            [
              356141.49278029596,
              5008306.1213278687
            ],
            [
              356148.77271693404,
              5008333.7795525044
            ],
            [
              356138.66992384259,
              5008336.2210330293
            ],
            [
              356140.27901951905,
              5008362.1067795036
            ],
            [
              356138.79008161073,
              5008362.8856384978
            ],
            [
              356138.31739113043,
              5008376.6142816423
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 14,
        "Layer" : "STARO_GP",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360983.86356314883,
              5002931.8692313218
            ],
            [
              361185.91466212377,
              5002622.1939397994
            ],
            [
              361194.89004084101,
              5002636.27450448
            ],
            [
              361203.77727728692,
              5002645.607585012
            ],
            [
              361205.52280023199,
              5002654.2818924496
            ],
            [
              361224.54274767818,
              5002654.7199166287
            ],
            [
              361435.73140467197,
              5002497.6519272076
            ],
            [
              361520.88979597593,
              5002429.2902709497
            ],
            [
              361569.25351724314,
              5002431.5575755276
            ],
            [
              361569.79329018499,
              5002375.3496435601
            ],
            [
              361504.81465644791,
              5002374.1825891454
            ],
            [
              361462.43301962555,
              5002352.8076367723
            ],
            [
              361303.35624899546,
              5002181.3172732387
            ],
            [
              361279.74248484132,
              5002153.4971457152
            ],
            [
              361264.65417302662,
              5002127.8735278845
            ],
            [
              361251.45929122838,
              5002102.6856627716
            ],
            [
              361206.08296707278,
              5002070.5600982001
            ],
            [
              361170.94741362712,
              5002111.3991553271
            ],
            [
              361161.62839782069,
              5002117.224136902
            ],
            [
              361141.98556315945,
              5002100.1627857881
            ],
            [
              361128.67819944501,
              5002094.2873223592
            ],
            [
              361109.77028713928,
              5002116.7748370729
            ],
            [
              361088.50624553626,
              5002139.3061857354
            ],
            [
              360960.06449795485,
              5002260.7006002013
            ],
            [
              360918.62132159068,
              5002347.8072562683
            ],
            [
              360792.97976844833,
              5002409.9569351589
            ],
            [
              360742.04159630247,
              5002509.0940246852
            ],
            [
              360787.03691462986,
              5002596.8025932349
            ],
            [
              360897.9300340765,
              5002673.583896271
            ],
            [
              360825.07705745852,
              5002790.1845352175
            ],
            [
              360809.8888390467,
              5002860.6432869313
            ],
            [
              360890.21467865148,
              5002889.3373154281
            ],
            [
              360912.07360965095,
              5002898.8217293853
            ],
            [
              360917.30930849694,
              5002901.5503065996
            ],
            [
              360923.53114280466,
              5002906.6154985754
            ],
            [
              360934.01129052538,
              5002912.5434782989
            ],
            [
              360954.43914285331,
              5002921.112509435
            ],
            [
              360983.86356314883,
              5002931.8692313218
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 15,
        "Layer" : "STARO_GP",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              361806.97630988498,
              5001539.3218313241
            ],
            [
              361806.65250471327,
              5001539.0101261204
            ],
            [
              361765.59853937814,
              5001520.0737145962
            ],
            [
              361739.71483514208,
              5001513.246863272
            ],
            [
              361712.61420319846,
              5001502.9179505128
            ],
            [
              361682.5449755757,
              5001493.3093220219
            ],
            [
              361666.37622957525,
              5001530.148642296
            ],
            [
              361656.76994351618,
              5001543.671795846
            ],
            [
              361568.61466918292,
              5001471.2787490347
            ],
            [
              361526.55716788251,
              5001518.1310688034
            ],
            [
              361478.34944778687,
              5001647.2191729071
            ],
            [
              361536.93328927452,
              5001672.9119606176
            ],
            [
              361574.78703519475,
              5001688.9959320379
            ],
            [
              361625.68580834859,
              5001715.6998497155
            ],
            [
              361655.33522492572,
              5001774.924501583
            ],
            [
              361721.49339532811,
              5001825.0446028579
            ],
            [
              361785.0545566848,
              5001894.9627061319
            ],
            [
              361696.05308841739,
              5001998.3279048614
            ],
            [
              361706.66630848468,
              5002037.6302708816
            ],
            [
              361767.79300858412,
              5002082.9063712358
            ],
            [
              361826.38371732639,
              5002121.8045877581
            ],
            [
              361864.38561475021,
              5002093.6020016558
            ],
            [
              361870.97137127217,
              5002097.9790224601
            ],
            [
              362052.67734373687,
              5001812.5954296542
            ],
            [
              361967.86763123929,
              5001750.1471862849
            ],
            [
              362044.51312336448,
              5001631.5571492044
            ],
            [
              361936.76176448161,
              5001565.5652290853
            ],
            [
              361905.6897713049,
              5001563.8591631353
            ],
            [
              361806.97630988498,
              5001539.3218313241
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 16,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              361814.13566814741,
              5001741.9866796946
            ],
            [
              361676.54667606455,
              5001836.9858192625
            ],
            [
              361682.8938176501,
              5001846.7284391904
            ],
            [
              361717.11806779861,
              5001896.6277272152
            ],
            [
              361745.88456432841,
              5001918.279373223
            ],
            [
              361757.98355714971,
              5001905.7288728682
            ],
            [
              361790.55931210128,
              5001866.9139915584
            ],
            [
              361848.01998955593,
              5001839.9623812931
            ],
            [
              361846.64986301935,
              5001832.5923985969
            ],
            [
              361825.09701464325,
              5001800.9462002898
            ],
            [
              361816.26140566514,
              5001790.0172417667
            ],
            [
              361837.03574151878,
              5001779.7707094643
            ],
            [
              361825.52850077447,
              5001757.7983033787
            ],
            [
              361814.13566814741,
              5001741.9866796946
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 17,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              362109.51976399979,
              5001660.7405285807
            ],
            [
              362153.60140036949,
              5001692.0119375736
            ],
            [
              362195.91408792639,
              5001628.0798497982
            ],
            [
              362161.30456619704,
              5001604.9138715789
            ],
            [
              362109.51976399979,
              5001660.7405285807
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 18,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              362099.92923985282,
              5001590.5266189193
            ],
            [
              362119.27440757304,
              5001572.5691353073
            ],
            [
              362099.08320251846,
              5001544.9944945481
            ],
            [
              362098.92937885539,
              5001536.7159619126
            ],
            [
              362110.03007531894,
              5001520.9820192009
            ],
            [
              362057.31927550427,
              5001471.2376927901
            ],
            [
              362051.14549739577,
              5001473.4227546919
            ],
            [
              362021.96577491792,
              5001519.5127931237
            ],
            [
              361994.52987671789,
              5001547.9724032115
            ],
            [
              361997.31201470987,
              5001586.2222740948
            ],
            [
              362002.87346466753,
              5001606.8225163659
            ],
            [
              362003.1426601584,
              5001621.3100012131
            ],
            [
              361998.42725434579,
              5001646.2418968491
            ],
            [
              362001.91793967388,
              5001666.8806158407
            ],
            [
              362012.77165019809,
              5001693.5935634188
            ],
            [
              362025.19611853239,
              5001693.3626984684
            ],
            [
              362038.57902168232,
              5001688.9733282225
            ],
            [
              362045.46126381197,
              5001669.1770665217
            ],
            [
              362041.9898075377,
              5001649.5731912907
            ],
            [
              362064.19119413506,
              5001618.1052944008
            ],
            [
              362065.62736089894,
              5001583.9177460754
            ],
            [
              362057.05600737344,
              5001568.5493288375
            ],
            [
              362041.06394918327,
              5001544.0022047907
            ],
            [
              362072.77590520849,
              5001522.7093874924
            ],
            [
              362085.62338795158,
              5001545.2445919346
            ],
            [
              362076.53577510134,
              5001557.8355851099
            ],
            [
              362077.74417658505,
              5001567.1297213705
            ],
            [
              362093.5054966915,
              5001579.258993851
            ],
            [
              362099.92923985282,
              5001590.5266189193
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 19,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              361944.67087472387,
              5003032.3558477638
            ],
            [
              361992.45847751462,
              5003036.504103398
            ],
            [
              362050.08735624107,
              5003054.5208889162
            ],
            [
              362137.94331180543,
              5003054.7968607452
            ],
            [
              362148.33734093874,
              5003049.4663935862
            ],
            [
              362158.39966733707,
              5003035.7093086122
            ],
            [
              362166.55254153704,
              5003036.1006019264
            ],
            [
              362169.07519677718,
              5003025.7405508524
            ],
            [
              362180.30582389439,
              5003017.4211472124
            ],
            [
              362171.10209893802,
              5002989.2819368411
            ],
            [
              362160.6698237338,
              5002941.756189364
            ],
            [
              362112.90583890356,
              5002940.7351240264
            ],
            [
              362136.66663846921,
              5002986.1043515094
            ],
            [
              362144.83514726855,
              5003014.5842902698
            ],
            [
              362095.23294534074,
              5003017.4149761107
            ],
            [
              362047.32709755719,
              5003008.7613530867
            ],
            [
              361976.12145112379,
              5002979.5442003487
            ],
            [
              361945.57518116693,
              5002980.1119094715
            ],
            [
              361944.67087472387,
              5003032.3558477638
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 20,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              361187.2105934702,
              5002734.8760254662
            ],
            [
              361258.84336382098,
              5002760.7611584105
            ],
            [
              361313.23468923714,
              5002709.7073280532
            ],
            [
              361252.93590251851,
              5002679.2218146175
            ],
            [
              361187.2105934702,
              5002734.8760254662
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 21,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359781.73744410655,
              5005645.3804989699
            ],
            [
              359787.47161183605,
              5005666.6196480338
            ],
            [
              359805.56060272217,
              5005682.2926408835
            ],
            [
              359828.98708627617,
              5005697.8664400568
            ],
            [
              359855.80658360018,
              5005704.4830183433
            ],
            [
              359871.91810461978,
              5005709.5198939564
            ],
            [
              359877.71840005781,
              5005734.3154496336
            ],
            [
              359881.57422108023,
              5005750.2531251414
            ],
            [
              359897.98330534104,
              5005771.2937837867
            ],
            [
              359918.08296898875,
              5005799.3811045941
            ],
            [
              359934.42585819395,
              5005816.865347309
            ],
            [
              359963.65271197987,
              5005857.2346775681
            ],
            [
              359989.61874100351,
              5005913.6738971928
            ],
            [
              360021.61033218657,
              5005911.300256555
            ],
            [
              360044.04490947333,
              5005873.5280042402
            ],
            [
              360043.74734108133,
              5005857.5242255945
            ],
            [
              360036.36625479953,
              5005843.4309509424
            ],
            [
              360021.83552459494,
              5005827.6918165572
            ],
            [
              360008.9185727936,
              5005803.0285959905
            ],
            [
              360008.75325842883,
              5005794.1375906551
            ],
            [
              360015.44002431753,
              5005770.8886653315
            ],
            [
              360015.10939839244,
              5005753.1067037089
            ],
            [
              359968.22345977096,
              5005720.180993218
            ],
            [
              359932.3759738768,
              5005706.6169932354
            ],
            [
              359881.7333280358,
              5005663.0882378398
            ],
            [
              359852.57268429623,
              5005626.2752936464
            ],
            [
              359852.37431282381,
              5005615.6061017765
            ],
            [
              359781.73744410655,
              5005645.3804989699
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 22,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359735.59319095453,
              5005930.9079874568
            ],
            [
              359690.85626718338,
              5006013.5653538303
            ],
            [
              359744.72667223384,
              5006039.2458932335
            ],
            [
              359772.86237964244,
              5006020.9345633406
            ],
            [
              359800.3368805034,
              5005967.0592902247
            ],
            [
              359791.27581128123,
              5005958.3336863397
            ],
            [
              359735.59319095453,
              5005930.9079874568
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 23,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359404.29712452844,
              5005735.9497823091
            ],
            [
              359403.67515473365,
              5005798.2199611375
            ],
            [
              359464.69524494687,
              5005825.546417472
            ],
            [
              359490.03310594539,
              5005848.1999282027
            ],
            [
              359583.17707065895,
              5005880.2655611197
            ],
            [
              359596.41835677245,
              5005826.6548669403
            ],
            [
              359520.14638919831,
              5005840.5247700829
            ],
            [
              359485.35070293531,
              5005787.8072389457
            ],
            [
              359465.08577140496,
              5005750.8288614918
            ],
            [
              359459.35152288148,
              5005729.5896952618
            ],
            [
              359404.29712452844,
              5005735.9497823091
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 24,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359194.20034575934,
              5005643.1899611223
            ],
            [
              359174.5474791645,
              5005659.511946246
            ],
            [
              359138.12493442715,
              5005718.4307222618
            ],
            [
              359096.22043316992,
              5005783.0362431025
            ],
            [
              359083.41108060436,
              5005823.9637012081
            ],
            [
              359080.01435194776,
              5005855.9400750557
            ],
            [
              359089.14803321788,
              5005874.9181471644
            ],
            [
              359103.86755274964,
              5005893.7923567295
            ],
            [
              359137.52433349862,
              5005907.5246295193
            ],
            [
              359185.43936846679,
              5005901.8495804416
            ],
            [
              359200.93919612013,
              5005876.828684059
            ],
            [
              359211.42878532299,
              5005839.9335149284
            ],
            [
              359232.30684081203,
              5005803.6429706411
            ],
            [
              359250.30444748822,
              5005784.1604415979
            ],
            [
              359270.68119352288,
              5005763.8358009048
            ],
            [
              359280.00483438902,
              5005750.0993547942
            ],
            [
              359271.44669513457,
              5005719.1431576367
            ],
            [
              359260.81809755601,
              5005662.6948857615
            ],
            [
              359250.49819761043,
              5005635.8043085458
            ],
            [
              359228.77451665833,
              5005616.8138607973
            ],
            [
              359222.55683861853,
              5005622.7169587146
            ],
            [
              359234.8379525526,
              5005639.2430222211
            ],
            [
              359210.53061007714,
              5005662.8320548162
            ],
            [
              359194.20034575934,
              5005643.1899611223
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 25,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355981.9535619758,
              5008401.5447302582
            ],
            [
              355972.68584744557,
              5008418.0748702791
            ],
            [
              355922.25390324468,
              5008523.7524857186
            ],
            [
              355886.12133924622,
              5008603.6506420197
            ],
            [
              355856.97344660357,
              5008625.6778814811
            ],
            [
              355832.4870254143,
              5008697.8565606894
            ],
            [
              355785.11048027541,
              5008751.1076670615
            ],
            [
              355767.05670094205,
              5008791.7279394818
            ],
            [
              355731.04364851856,
              5008805.826044173
            ],
            [
              355716.17005341745,
              5008800.7314638384
            ],
            [
              355699.82848948642,
              5008788.9501259029
            ],
            [
              355686.04830177996,
              5008770.4070199179
            ],
            [
              355647.46856933052,
              5008718.7549074469
            ],
            [
              355599.74249263661,
              5008753.2130947588
            ],
            [
              355564.8976624838,
              5008757.8896885421
            ],
            [
              355578.62799168221,
              5008773.7480693394
            ],
            [
              355584.39974747639,
              5008795.1257602237
            ],
            [
              355583.6310386135,
              5008826.0248444779
            ],
            [
              355513.97100269067,
              5008916.6345045427
            ],
            [
              355505.07323149784,
              5008924.447797426
            ],
            [
              355489.07937175588,
              5008924.745324499
            ],
            [
              355475.80241384578,
              5008921.5160351088
            ],
            [
              355451.88774681359,
              5008907.3605860388
            ],
            [
              355438.89248531807,
              5008881.8778484538
            ],
            [
              355429.40271805209,
              5008895.2642615251
            ],
            [
              355430.35672504356,
              5008909.1516167987
            ],
            [
              355432.1870877585,
              5008932.7562739803
            ],
            [
              355424.83516003255,
              5008948.8839177787
            ],
            [
              355401.16904608969,
              5008985.4774281457
            ],
            [
              355377.80521886883,
              5009023.8579129167
            ],
            [
              355401.26349985908,
              5009037.0356602427
            ],
            [
              355413.88138324802,
              5009049.7962854356
            ],
            [
              355428.7052769892,
              5009064.6818833286
            ],
            [
              355453.11847259459,
              5009058.519264075
            ],
            [
              355485.69810699829,
              5009052.3511351123
            ],
            [
              355528.01315455622,
              5009046.0019000228
            ],
            [
              355544.71536841389,
              5009046.3864667164
            ],
            [
              355630.12929742289,
              5008984.7722195387
            ],
            [
              355649.77830430638,
              5008956.5965071898
            ],
            [
              355688.65240441129,
              5008914.8532914231
            ],
            [
              355730.56663030019,
              5008886.9586214107
            ],
            [
              355806.72410054744,
              5008887.4415096855
            ],
            [
              355828.63678655651,
              5008881.7943878965
            ],
            [
              355842.04776493285,
              5008870.0179305403
            ],
            [
              355854.00136486994,
              5008836.2626222735
            ],
            [
              355893.27938311332,
              5008749.6037296196
            ],
            [
              355904.26285912556,
              5008720.0580253983
            ],
            [
              355958.23387455341,
              5008634.173785761
            ],
            [
              356021.56561013917,
              5008579.2686846256
            ],
            [
              356042.37175052072,
              5008563.3351081572
            ],
            [
              356055.54964462674,
              5008563.0899792491
            ],
            [
              356048.29364273901,
              5008553.1573076388
            ],
            [
              356059.74907922716,
              5008546.7163644321
            ],
            [
              356063.19603219541,
              5008539.4855435891
            ],
            [
              356080.08694487839,
              5008541.7956069084
            ],
            [
              356087.60663708823,
              5008543.126825735
            ],
            [
              356091.76599411422,
              5008545.466363701
            ],
            [
              356102.73503715091,
              5008547.9320870889
            ],
            [
              356109.05775350751,
              5008562.4345701169
            ],
            [
              356128.59694367542,
              5008559.9519393174
            ],
            [
              356147.89924735902,
              5008561.3444015924
            ],
            [
              356163.46996626107,
              5008550.5459519187
            ],
            [
              356198.18014152942,
              5008532.3855901174
            ],
            [
              356231.26878878922,
              5008521.2613402717
            ],
            [
              356225.72027001088,
              5008505.6013098573
            ],
            [
              356218.19205319177,
              5008477.717890881
            ],
            [
              356188.34638115473,
              5008474.770140091
            ],
            [
              356161.7438424477,
              5008457.7503331117
            ],
            [
              356145.97771131777,
              5008458.043602326
            ],
            [
              356114.38023082842,
              5008455.1283799289
            ],
            [
              356077.75547916832,
              5008464.5669981632
            ],
            [
              355981.9535619758,
              5008401.5447302582
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 26,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355344.39210355509,
              5008839.1176570673
            ],
            [
              355363.32184426626,
              5008828.6103949994
            ],
            [
              355409.92601891205,
              5008796.0033399044
            ],
            [
              355374.22209769976,
              5008772.8891099859
            ],
            [
              355388.2894191136,
              5008747.0237573422
            ],
            [
              355395.61156741175,
              5008719.3447214672
            ],
            [
              355399.13093277998,
              5008699.1771656387
            ],
            [
              355408.01896525029,
              5008666.2591451351
            ],
            [
              355415.27171503339,
              5008660.7609841311
            ],
            [
              355430.39435000502,
              5008616.8093129201
            ],
            [
              355435.74231383012,
              5008612.7352819405
            ],
            [
              355451.95690956747,
              5008583.8467175523
            ],
            [
              355429.99645590544,
              5008568.7737108544
            ],
            [
              355438.55580591346,
              5008549.2982112672
            ],
            [
              355434.5303103021,
              5008546.0549504887
            ],
            [
              355434.35255362478,
              5008536.4988937266
            ],
            [
              355437.73312137934,
              5008519.5791467316
            ],
            [
              355404.59054626414,
              5008507.8946716189
            ],
            [
              355424.76173365169,
              5008483.3881235253
            ],
            [
              355455.51464049483,
              5008507.4837651681
            ],
            [
              355471.120062751,
              5008493.2991398908
            ],
            [
              355464.65940311429,
              5008482.8078571567
            ],
            [
              355479.42027967895,
              5008469.5679314462
            ],
            [
              355504.55860013614,
              5008473.777162103
            ],
            [
              355520.94603149703,
              5008475.4534756346
            ],
            [
              355537.43464305456,
              5008470.7122429973
            ],
            [
              355525.78388575616,
              5008454.5622194707
            ],
            [
              355519.42317768082,
              5008445.9933733772
            ],
            [
              355482.14225300233,
              5008428.9941752572
            ],
            [
              355477.70713833947,
              5008414.8874950027
            ],
            [
              355473.91996168304,
              5008390.7528350325
            ],
            [
              355467.02973081946,
              5008369.6064726179
            ],
            [
              355452.82841736474,
              5008381.0078301886
            ],
            [
              355437.65658864618,
              5008373.1326608071
            ],
            [
              355428.07747483806,
              5008364.3559806729
            ],
            [
              355418.10893246444,
              5008349.7417007377
            ],
            [
              355389.2261152828,
              5008356.4193903748
            ],
            [
              355388.18889773218,
              5008378.557908236
            ],
            [
              355356.14022957766,
              5008392.9233625811
            ],
            [
              355344.38690978126,
              5008406.0556363165
            ],
            [
              355348.30017282919,
              5008417.9740561964
            ],
            [
              355336.49539831653,
              5008428.3400814021
            ],
            [
              355330.80559616134,
              5008420.1442976426
            ],
            [
              355317.97525755019,
              5008424.9949312396
            ],
            [
              355313.55106413009,
              5008435.2236702144
            ],
            [
              355303.99265071441,
              5008458.8025624771
            ],
            [
              355337.24579215632,
              5008482.9630170427
            ],
            [
              355331.87888269703,
              5008492.1740709459
            ],
            [
              355319.63862739439,
              5008489.1659777574
            ],
            [
              355297.79909268446,
              5008481.1755749928
            ],
            [
              355296.46746931755,
              5008507.8403980918
            ],
            [
              355315.75945452886,
              5008552.6792365666
            ],
            [
              355327.31058831065,
              5008578.2916691778
            ],
            [
              355331.56690301507,
              5008608.6517705871
            ],
            [
              355327.3828481689,
              5008631.789695167
            ],
            [
              355325.96651186707,
              5008654.8760808352
            ],
            [
              355327.37884444074,
              5008701.1805093214
            ],
            [
              355347.00868254952,
              5008734.9984566998
            ],
            [
              355358.40737464913,
              5008764.0863114074
            ],
            [
              355350.54954739753,
              5008779.4249481112
            ],
            [
              355341.9774627047,
              5008785.5529228542
            ],
            [
              355315.29697964352,
              5008810.4657641025
            ],
            [
              355312.6440188876,
              5008813.7706593145
            ],
            [
              355344.39210355509,
              5008839.1176570673
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 27,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354088.13126690028,
              5010871.1012846204
            ],
            [
              354070.97490252246,
              5010869.4069539914
            ],
            [
              354048.93335846422,
              5010875.8579609571
            ],
            [
              354011.9367794607,
              5010890.6416903911
            ],
            [
              353982.9773536126,
              5010904.2690741308
            ],
            [
              353983.16463914333,
              5010914.3336598966
            ],
            [
              353961.0482552349,
              5010916.7588463649
            ],
            [
              353925.85962571454,
              5010920.4340774603
            ],
            [
              353925.52251096716,
              5010902.3177871061
            ],
            [
              353915.17164607998,
              5010887.408262914
            ],
            [
              353919.1745438021,
              5010914.65457875
            ],
            [
              353907.83904053486,
              5010912.6033951743
            ],
            [
              353893.88953152235,
              5010911.2836339055
            ],
            [
              353873.39222993486,
              5010918.7539974758
            ],
            [
              353864.58679848426,
              5010922.2806161055
            ],
            [
              353875.17065412126,
              5010945.6559598921
            ],
            [
              353862.29984052462,
              5010950.5179310469
            ],
            [
              353866.35613521852,
              5010964.8002380403
            ],
            [
              353853.98753103596,
              5010979.3304950092
            ],
            [
              353828.38516796281,
              5010988.8555982998
            ],
            [
              353838.70483987126,
              5011000.5869077388
            ],
            [
              353843.77730588638,
              5011002.5060980823
            ],
            [
              353870.24011074856,
              5011017.115799984
            ],
            [
              353882.09927367914,
              5011004.8134059794
            ],
            [
              353896.47816698736,
              5011019.6479718145
            ],
            [
              353903.63952025154,
              5011025.5555633213
            ],
            [
              353937.29675692174,
              5010993.7181832902
            ],
            [
              353972.05462296569,
              5010966.8943914631
            ],
            [
              354007.8756591581,
              5010943.0712476075
            ],
            [
              354042.0572946443,
              5010939.4147535572
            ],
            [
              354056.02414778643,
              5010932.107209377
            ],
            [
              354065.06836665544,
              5010930.9321202599
            ],
            [
              354076.7776968882,
              5010910.5780368317
            ],
            [
              354081.53177499509,
              5010895.3874967108
            ],
            [
              354088.13126690028,
              5010871.1012846204
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 28,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354060.61641454196,
              5010424.0953019587
            ],
            [
              354035.50226326036,
              5010402.0734009612
            ],
            [
              354007.78673402144,
              5010430.8245687159
            ],
            [
              353958.03558695252,
              5010479.4584928183
            ],
            [
              353932.28582881537,
              5010509.1466968115
            ],
            [
              353899.59252377501,
              5010532.1486781687
            ],
            [
              353875.15560490597,
              5010543.9022778813
            ],
            [
              353858.70934326824,
              5010550.0501258392
            ],
            [
              353851.02743944846,
              5010556.0348929456
            ],
            [
              353846.35761994735,
              5010566.8317846293
            ],
            [
              353844.77217011468,
              5010586.334025763
            ],
            [
              353828.61567786365,
              5010608.0546552353
            ],
            [
              353804.68680832209,
              5010630.8935680846
            ],
            [
              353774.75195549004,
              5010645.0814981405
            ],
            [
              353772.9491807138,
              5010652.9041850977
            ],
            [
              353762.65378565824,
              5010675.489362278
            ],
            [
              353746.46107366605,
              5010695.263417027
            ],
            [
              353720.69320812385,
              5010723.9783907495
            ],
            [
              353717.89853185759,
              5010730.8458799319
            ],
            [
              353709.47827983531,
              5010749.5016539944
            ],
            [
              353698.29957509605,
              5010776.971503241
            ],
            [
              353691.80879793369,
              5010794.6177740404
            ],
            [
              353668.13348944247,
              5010831.0829103785
            ],
            [
              353647.01733074855,
              5010848.027678973
            ],
            [
              353618.12878924515,
              5010866.0906819887
            ],
            [
              353609.58176246803,
              5010877.9333903538
            ],
            [
              353613.71245883446,
              5010890.5138451112
            ],
            [
              353625.09461263323,
              5010926.3266322231
            ],
            [
              353623.47295821493,
              5010943.8822477823
            ],
            [
              353629.62376567477,
              5010960.3196275504
            ],
            [
              353641.9574455386,
              5010942.5646777982
            ],
            [
              353675.13552730664,
              5010893.2653821344
            ],
            [
              353694.95661774318,
              5010850.8140143575
            ],
            [
              353732.93137929158,
              5010797.5309619941
            ],
            [
              353750.94496957696,
              5010770.9075330328
            ],
            [
              353784.32320749102,
              5010732.4621597333
            ],
            [
              353776.35023032466,
              5010725.9371905783
            ],
            [
              353804.72672796989,
              5010707.9548210325
            ],
            [
              353826.68537644105,
              5010726.7921034973
            ],
            [
              353839.04726614436,
              5010738.4320982527
            ],
            [
              353847.78326622094,
              5010731.6025998341
            ],
            [
              353856.51926621079,
              5010724.7731017629
            ],
            [
              353857.65126635344,
              5010717.7162002381
            ],
            [
              353856.05942134123,
              5010711.9392091604
            ],
            [
              353872.43381352007,
              5010694.5979265897
            ],
            [
              353891.84814527206,
              5010689.7790848892
            ],
            [
              353896.80274648033,
              5010692.5222145282
            ],
            [
              353900.50624483469,
              5010685.0519935898
            ],
            [
              353904.59394298779,
              5010686.5718108099
            ],
            [
              353910.85120059171,
              5010683.9165601209
            ],
            [
              353914.53485336032,
              5010680.5849653156
            ],
            [
              353929.22329682787,
              5010681.8577666804
            ],
            [
              353928.94351854327,
              5010668.1505977912
            ],
            [
              353935.96996491426,
              5010657.3005570499
            ],
            [
              353931.43191238417,
              5010648.8736751853
            ],
            [
              353922.4032803009,
              5010642.2256453894
            ],
            [
              353913.93032095995,
              5010625.1748332847
            ],
            [
              353927.19840760477,
              5010607.7194739478
            ],
            [
              353963.93502333999,
              5010585.2989019183
            ],
            [
              353966.55156113877,
              5010579.8159522545
            ],
            [
              353960.07564481098,
              5010572.6908236211
            ],
            [
              353955.46198880934,
              5010568.2480919203
            ],
            [
              353952.50851065858,
              5010555.6231567319
            ],
            [
              353956.9705427367,
              5010551.9172883267
            ],
            [
              353965.8776813171,
              5010543.6001837868
            ],
            [
              353971.1612997161,
              5010535.3505035415
            ],
            [
              353982.83659898548,
              5010529.698995593
            ],
            [
              353998.35442784434,
              5010535.750196211
            ],
            [
              354006.54106120468,
              5010537.4092858639
            ],
            [
              354012.86535456829,
              5010536.385925075
            ],
            [
              354012.6631910131,
              5010525.521163024
            ],
            [
              354012.51156908326,
              5010517.3726189397
            ],
            [
              354020.54650121299,
              5010510.8831665367
            ],
            [
              354027.69245904166,
              5010505.3159385473
            ],
            [
              354029.41994538059,
              5010500.7552710036
            ],
            [
              354025.67857619619,
              5010494.4848879175
            ],
            [
              354020.12532972667,
              5010488.2482735096
            ],
            [
              354030.74318660243,
              5010474.4650790123
            ],
            [
              354038.00707297045,
              5010475.235656837
            ],
            [
              354048.97863211489,
              5010480.4657142088
            ],
            [
              354059.967118398,
              5010486.6012488892
            ],
            [
              354066.34195095667,
              5010488.2940518307
            ],
            [
              354066.07240170246,
              5010473.8077046294
            ],
            [
              354061.40820954827,
              5010466.6488652155
            ],
            [
              354061.20604823,
              5010455.7841046462
            ],
            [
              354074.592064408,
              5010444.6665085964
            ],
            [
              354060.61641454196,
              5010424.0953019587
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 29,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353669.30807725858,
              5010568.6474022549
            ],
            [
              353684.59596916428,
              5010551.0211962508
            ],
            [
              353701.55385222915,
              5010529.8955923012
            ],
            [
              353707.81439651659,
              5010493.3615186149
            ],
            [
              353716.19660784764,
              5010477.5979754152
            ],
            [
              353728.14452041424,
              5010466.9706285316
            ],
            [
              353741.69791046547,
              5010449.3767040297
            ],
            [
              353748.34554053581,
              5010433.645493757
            ],
            [
              353755.09001916536,
              5010423.1149694808
            ],
            [
              353754.38037807867,
              5010384.9763793526
            ],
            [
              353759.39035368263,
              5010374.4781298172
            ],
            [
              353797.12988117145,
              5010351.2316980148
            ],
            [
              353800.27632842743,
              5010333.8314204989
            ],
            [
              353750.80727810226,
              5010286.1950517893
            ],
            [
              353742.07032347895,
              5010282.8892914085
            ],
            [
              353741.87678890326,
              5010272.4878622564
            ],
            [
              353736.73778649879,
              5010276.0518063409
            ],
            [
              353705.74265196634,
              5010288.7677123267
            ],
            [
              353696.8444170751,
              5010276.7940836707
            ],
            [
              353671.85180386814,
              5010239.1073063118
            ],
            [
              353663.05033589847,
              5010232.3343635108
            ],
            [
              353662.92131447583,
              5010225.4001124147
            ],
            [
              353645.35055578157,
              5010213.5878446735
            ],
            [
              353621.03538670583,
              5010212.306043203
            ],
            [
              353576.09976525814,
              5010221.8130029002
            ],
            [
              353488.0597777256,
              5010245.9953478118
            ],
            [
              353454.20846856217,
              5010291.7137388401
            ],
            [
              353454.36974817648,
              5010300.3816128569
            ],
            [
              353423.56820423371,
              5010323.4989688592
            ],
            [
              353415.12146587617,
              5010335.7953388253
            ],
            [
              353411.81373432005,
              5010344.5277607953
            ],
            [
              353406.80383182486,
              5010355.0260190545
            ],
            [
              353403.65738213633,
              5010372.4263166683
            ],
            [
              353395.21072408272,
              5010384.7226870712
            ],
            [
              353388.56300701265,
              5010400.4539689822
            ],
            [
              353388.8533146556,
              5010416.0561011191
            ],
            [
              353392.70940580306,
              5010436.7944326149
            ],
            [
              353403.82601179747,
              5010474.7394192172
            ],
            [
              353408.8037331443,
              5010462.5075936448
            ],
            [
              353439.60534804437,
              5010439.3902227785
            ],
            [
              353451.58552775037,
              5010430.4964324459
            ],
            [
              353475.54588660941,
              5010412.7088536443
            ],
            [
              353519.86864553712,
              5010370.2639953997
            ],
            [
              353519.70736420143,
              5010361.5961230733
            ],
            [
              353514.34256619494,
              5010353.0250713238
            ],
            [
              353517.55352873914,
              5010339.0919614015
            ],
            [
              353534.44692040159,
              5010314.4991757944
            ],
            [
              353554.77699022909,
              5010288.1083392762
            ],
            [
              353581.98064918484,
              5010258.1212308677
            ],
            [
              353599.09975443996,
              5010245.6635068245
            ],
            [
              353606.23130913911,
              5010255.9358481923
            ],
            [
              353601.60839700076,
              5010287.2369675012
            ],
            [
              353596.6952626608,
              5010302.9359084303
            ],
            [
              353617.76721494651,
              5010316.4172460008
            ],
            [
              353577.88116220926,
              5010410.8047083579
            ],
            [
              353557.16394674446,
              5010416.3927374501
            ],
            [
              353521.25567239471,
              5010444.8076091344
            ],
            [
              353502.49875645008,
              5010462.4983670097
            ],
            [
              353478.92548444401,
              5010501.0888217799
            ],
            [
              353465.66239968064,
              5010534.2848903174
            ],
            [
              353447.09902856959,
              5010562.3770937864
            ],
            [
              353432.10144249181,
              5010595.6054424997
            ],
            [
              353446.10644140717,
              5010602.2815561164
            ],
            [
              353458.05435944116,
              5010591.654196539
            ],
            [
              353481.98253139469,
              5010572.1330417767
            ],
            [
              353507.58061543707,
              5010549.1124896314
            ],
            [
              353526.8536411426,
              5010559.1589068873
            ],
            [
              353561.70479967422,
              5010567.1812912086
            ],
            [
              353587.91584391805,
              5010577.0986072859
            ],
            [
              353669.30807725858,
              5010568.6474022549
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 30,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353687.63455730537,
              5009605.1491687531
            ],
            [
              353666.89025881101,
              5009613.5696576051
            ],
            [
              353641.65308499226,
              5009639.7497906275
            ],
            [
              353616.74465474131,
              5009683.5997575726
            ],
            [
              353596.26932703605,
              5009706.4773974288
            ],
            [
              353604.75367465563,
              5009730.4232010841
            ],
            [
              353611.39175498829,
              5009741.5481045293
            ],
            [
              353622.70194213476,
              5009744.5514770979
            ],
            [
              353649.90500186553,
              5009737.6177132968
            ],
            [
              353665.97711195349,
              5009737.3186922036
            ],
            [
              353690.79262058652,
              5009767.3439807203
            ],
            [
              353710.70319844276,
              5009792.3598916614
            ],
            [
              353741.67199403525,
              5009830.7180704651
            ],
            [
              353756.19661456073,
              5009833.6616335912
            ],
            [
              353773.90578482574,
              5009834.9390486144
            ],
            [
              353800.86973904801,
              5009815.1544872923
            ],
            [
              353787.50400270935,
              5009788.0857213549
            ],
            [
              353756.2198937434,
              5009748.4950268706
            ],
            [
              353730.08614920889,
              5009726.4844987188
            ],
            [
              353723.47795854282,
              5009716.9659964293
            ],
            [
              353697.28444201878,
              5009691.742778155
            ],
            [
              353705.17106644454,
              5009683.5614998657
            ],
            [
              353716.06292771298,
              5009664.0759639787
            ],
            [
              353714.1568117615,
              5009648.0423293384
            ],
            [
              353687.63455730537,
              5009605.1491687531
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 31,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353404.48674963764,
              5009854.6674982831
            ],
            [
              353482.07443426858,
              5009790.5544216335
            ],
            [
              353453.37712746224,
              5009717.1704450101
            ],
            [
              353426.62242357718,
              5009748.1995357061
            ],
            [
              353388.4677871234,
              5009771.4061716739
            ],
            [
              353329.12053718272,
              5009778.9379887506
            ],
            [
              353386.53846624121,
              5009840.5392191261
            ],
            [
              353404.48674963764,
              5009854.6674982831
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 32,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352973.62018842081,
              5010611.9158374481
            ],
            [
              353003.07750367018,
              5010582.7464656169
            ],
            [
              353006.83745581849,
              5010564.9585974747
            ],
            [
              353002.44371582632,
              5010548.6853724252
            ],
            [
              353008.92999160249,
              5010530.8467751713
            ],
            [
              353019.86070939881,
              5010532.0063083926
            ],
            [
              353038.9451192717,
              5010531.6511993287
            ],
            [
              353071.00208018516,
              5010495.6189028891
            ],
            [
              353085.18572824454,
              5010451.7416397734
            ],
            [
              353101.49303202395,
              5010448.712361237
            ],
            [
              353114.09108148288,
              5010466.1959032221
            ],
            [
              353123.48121596785,
              5010457.8436375465
            ],
            [
              353152.86247925292,
              5010424.5869907271
            ],
            [
              353163.05801634473,
              5010386.2356416164
            ],
            [
              353177.59658501687,
              5010361.4326655976
            ],
            [
              353191.95769973326,
              5010327.0925291432
            ],
            [
              353213.64166813425,
              5010319.8745002858
            ],
            [
              353228.66181947285,
              5010320.9579486772
            ],
            [
              353239.87138549628,
              5010337.1043456038
            ],
            [
              353233.78498233069,
              5010303.1447304683
            ],
            [
              353231.73732215329,
              5010266.3841205845
            ],
            [
              353235.49728720385,
              5010248.5962127196
            ],
            [
              353220.42635757872,
              5010244.7879142202
            ],
            [
              353198.51431452553,
              5010239.7439257326
            ],
            [
              353165.9756827901,
              5010249.8897637986
            ],
            [
              353148.30518035538,
              5010252.9443966802
            ],
            [
              353138.48408655432,
              5010238.1350819152
            ],
            [
              353118.94345708983,
              5010213.9661453962
            ],
            [
              353106.56648061884,
              5010221.4597388199
            ],
            [
              353095.72885340784,
              5010232.1676488835
            ],
            [
              353081.6221995402,
              5010236.632480802
            ],
            [
              353062.05796689237,
              5010221.7226094184
            ],
            [
              353047.17595282517,
              5010212.4691151492
            ],
            [
              353040.26726129005,
              5010210.530316148
            ],
            [
              353020.17531918077,
              5010239.9439580599
            ],
            [
              353008.1071222332,
              5010234.7110501416
            ],
            [
              352998.61432119232,
              5010220.1226963857
            ],
            [
              352975.72918328043,
              5010234.963615532
            ],
            [
              352969.34247076215,
              5010241.5117027592
            ],
            [
              352952.95200401166,
              5010247.0413729381
            ],
            [
              352941.37894572213,
              5010254.7277842565
            ],
            [
              352934.22466398211,
              5010258.9482932035
            ],
            [
              352911.14033644047,
              5010300.7702230914
            ],
            [
              352900.03650867991,
              5010321.6208764557
            ],
            [
              352919.34908112261,
              5010333.5277997945
            ],
            [
              352907.33401760302,
              5010347.3805517666
            ],
            [
              352904.83585048094,
              5010359.6932462333
            ],
            [
              352926.92544834019,
              5010374.2743447386
            ],
            [
              352909.45774042682,
              5010388.2284995466
            ],
            [
              352870.83259101794,
              5010364.4147119457
            ],
            [
              352816.5393290309,
              5010333.0181472367
            ],
            [
              352808.55436998984,
              5010345.8356303973
            ],
            [
              352801.1472997407,
              5010360.0101181772
            ],
            [
              352804.51486157061,
              5010362.0422689002
            ],
            [
              352794.07487068389,
              5010377.3701252118
            ],
            [
              352782.75711960526,
              5010369.7436723877
            ],
            [
              352775.80203659117,
              5010378.6109664934
            ],
            [
              352772.07532501052,
              5010386.6074074972
            ],
            [
              352759.16063147929,
              5010377.7546591712
            ],
            [
              352759.3378758998,
              5010387.2804183913
            ],
            [
              352800.95340474771,
              5010418.9191992125
            ],
            [
              352861.39757166576,
              5010443.6394110965
            ],
            [
              352896.2374188996,
              5010483.8786650421
            ],
            [
              352923.10103205574,
              5010535.1696682712
            ],
            [
              352942.41353047377,
              5010547.0765253427
            ],
            [
              352934.61475694337,
              5010567.665397523
            ],
            [
              352941.68419765384,
              5010581.1630436024
            ],
            [
              352958.54924337013,
              5010608.1075520376
            ],
            [
              352973.62018842081,
              5010611.9158374481
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 33,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353004.43124090304,
              5010702.4745819317
            ],
            [
              352998.53916519257,
              5010711.0908712922
            ],
            [
              353009.97309889406,
              5010724.482604499
            ],
            [
              353028.56787377113,
              5010739.9518798236
            ],
            [
              353018.56930201262,
              5010760.4001307664
            ],
            [
              353005.48639180925,
              5010779.2867720062
            ],
            [
              352995.73242225469,
              5010772.5301288459
            ],
            [
              352992.32206393016,
              5010777.4533619257
            ],
            [
              352970.17170972348,
              5010815.2422932265
            ],
            [
              353025.18955965404,
              5010813.1802353915
            ],
            [
              353034.47754220234,
              5010809.8927293941
            ],
            [
              353032.18820984761,
              5010798.5146373408
            ],
            [
              353044.39839502244,
              5010784.7902882844
            ],
            [
              353055.64730830287,
              5010775.2367812293
            ],
            [
              353061.72347057232,
              5010766.8177538384
            ],
            [
              353066.74179706961,
              5010757.3801862421
            ],
            [
              353069.62537567999,
              5010744.8676132374
            ],
            [
              353060.24083094869,
              5010742.9657906061
            ],
            [
              353044.64493229863,
              5010742.217772793
            ],
            [
              353033.1642636265,
              5010739.3166746842
            ],
            [
              353026.77909430361,
              5010731.1295261625
            ],
            [
              353018.25917688367,
              5010719.8673710469
            ],
            [
              353004.43124090304,
              5010702.4745819317
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 34,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351718.81877259683,
              5011029.8400844662
            ],
            [
              351724.47669968352,
              5011022.2647437872
            ],
            [
              351748.68848906219,
              5011005.9598582583
            ],
            [
              351752.40420228959,
              5010992.3303978015
            ],
            [
              351728.65020741912,
              5011008.3645837503
            ],
            [
              351711.1745903609,
              5011022.0494755907
            ],
            [
              351718.81877259683,
              5011029.8400844662
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 35,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352823.96523150575,
              5011621.6787856566
            ],
            [
              352836.49882729002,
              5011639.8800747143
            ],
            [
              352848.50624445168,
              5011656.5657824418
            ],
            [
              352862.33798054309,
              5011641.5986022986
            ],
            [
              352873.7007759831,
              5011623.8570412518
            ],
            [
              352880.28377183975,
              5011611.0739393411
            ],
            [
              352880.15694370732,
              5011604.2590381261
            ],
            [
              352870.25309502851,
              5011595.6783333579
            ],
            [
              352863.28969460604,
              5011588.0167841641
            ],
            [
              352857.33648215974,
              5011582.2841924587
            ],
            [
              352827.66132937965,
              5011558.4891536776
            ],
            [
              352803.8892240795,
              5011590.0961272856
            ],
            [
              352792.09166482667,
              5011584.472299939
            ],
            [
              352776.61189299723,
              5011584.1312441099
            ],
            [
              352793.70945781464,
              5011590.6636740183
            ],
            [
              352803.45956602559,
              5011600.457626842
            ],
            [
              352809.05137293163,
              5011606.0745789232
            ],
            [
              352809.05137291801,
              5011606.0745789092
            ],
            [
              352823.96523150575,
              5011621.6787856566
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 36,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353513.08007657406,
              5011523.3312566532
            ],
            [
              353539.46114946302,
              5011561.3307218952
            ],
            [
              353554.12597297836,
              5011590.5671602543
            ],
            [
              353572.33014135266,
              5011603.0584584838
            ],
            [
              353582.66778173629,
              5011606.715145573
            ],
            [
              353595.40484548063,
              5011601.3460593522
            ],
            [
              353611.84841642069,
              5011588.209900423
            ],
            [
              353624.32291997992,
              5011568.7325396873
            ],
            [
              353618.52157391154,
              5011532.9160865583
            ],
            [
              353611.6757055776,
              5011509.9492635643
            ],
            [
              353598.58060960885,
              5011496.0798018901
            ],
            [
              353592.65992878046,
              5011453.8505515354
            ],
            [
              353597.31557440379,
              5011428.1036337763
            ],
            [
              353577.78448656684,
              5011413.2631432256
            ],
            [
              353542.23892901564,
              5011458.753319419
            ],
            [
              353536.23918310285,
              5011454.0651802942
            ],
            [
              353523.72231382405,
              5011474.3786892099
            ],
            [
              353538.00515191996,
              5011483.094005621
            ],
            [
              353525.5784563339,
              5011505.1364735244
            ],
            [
              353513.08007657406,
              5011523.3312566532
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 37,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352270.28335151123,
              5011994.2572932066
            ],
            [
              352267.88429068803,
              5012066.7170484737
            ],
            [
              352270.03907916078,
              5012115.3698176565
            ],
            [
              352271.40398767166,
              5012121.5871296506
            ],
            [
              352293.27785428637,
              5012088.7180282855
            ],
            [
              352301.83333744836,
              5012078.5705171563
            ],
            [
              352314.06549063016,
              5012064.6089593712
            ],
            [
              352319.89123997308,
              5012042.0268801767
            ],
            [
              352323.35876804852,
              5012026.9799275799
            ],
            [
              352329.02191551955,
              5011995.6611196585
            ],
            [
              352328.76639524469,
              5011981.9319804013
            ],
            [
              352319.885624332,
              5011974.6060843421
            ],
            [
              352292.57535459398,
              5011983.8541263333
            ],
            [
              352275.2087035593,
              5011990.4200065192
            ],
            [
              352270.28335151123,
              5011994.2572932066
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 38,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352519.05569038534,
              5011869.7678877413
            ],
            [
              352509.08884642815,
              5011871.2019027323
            ],
            [
              352479.39713473147,
              5011886.7369098794
            ],
            [
              352470.86480075883,
              5011898.1325554941
            ],
            [
              352467.37403995177,
              5011911.931412274
            ],
            [
              352470.10384609544,
              5011924.3659208817
            ],
            [
              352467.81538826606,
              5011935.645360643
            ],
            [
              352481.78410686797,
              5011947.8706970662
            ],
            [
              352503.38483435608,
              5011967.4452763107
            ],
            [
              352518.64877025929,
              5011982.1435996341
            ],
            [
              352527.55276603048,
              5011990.7176340884
            ],
            [
              352531.57779227622,
              5012005.6251291595
            ],
            [
              352530.51486654248,
              5012015.6331832334
            ],
            [
              352516.84812634526,
              5012019.6331588468
            ],
            [
              352505.65567483933,
              5012022.33856396
            ],
            [
              352497.26272103703,
              5012041.222794815
            ],
            [
              352487.69069723861,
              5012063.8745871084
            ],
            [
              352492.66258363693,
              5012062.5335313017
            ],
            [
              352525.93795698899,
              5012038.1920051966
            ],
            [
              352549.45547215961,
              5012026.5175140547
            ],
            [
              352560.34594324848,
              5012007.5868036719
            ],
            [
              352567.90818404779,
              5012011.191673507
            ],
            [
              352576.55665825465,
              5012006.0365766333
            ],
            [
              352585.2283611836,
              5012002.1295676799
            ],
            [
              352589.18369948189,
              5012013.2927422635
            ],
            [
              352589.43922076473,
              5012027.0218711086
            ],
            [
              352590.92027234513,
              5012039.4796707984
            ],
            [
              352604.30498466379,
              5012040.3758045267
            ],
            [
              352610.35010522621,
              5012034.6050726771
            ],
            [
              352623.36896226654,
              5012020.4271348035
            ],
            [
              352630.16762211884,
              5012016.982572387
            ],
            [
              352640.48652625975,
              5012012.3664926561
            ],
            [
              352656.76542216336,
              5011997.4248551028
            ],
            [
              352653.60858234053,
              5011984.6259285966
            ],
            [
              352638.08913338347,
              5011956.198484173
            ],
            [
              352626.47856426524,
              5011936.4380363896
            ],
            [
              352607.21276189812,
              5011908.080257114
            ],
            [
              352595.67188087787,
              5011892.064123746
            ],
            [
              352577.05648452305,
              5011898.653234438
            ],
            [
              352563.43620283011,
              5011905.1494352836
            ],
            [
              352558.51085291203,
              5011908.9866625918
            ],
            [
              352542.30005858414,
              5011910.5369415479
            ],
            [
              352526.01965488569,
              5011908.3428456811
            ],
            [
              352517.09243262059,
              5011898.5207211357
            ],
            [
              352519.05569038534,
              5011869.7678877413
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 39,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352220.47575659159,
              5012273.5651979866
            ],
            [
              352203.96656360797,
              5012269.3935447484
            ],
            [
              352189.33948212746,
              5012286.0885633649
            ],
            [
              352167.52394296782,
              5012317.8472139202
            ],
            [
              352179.72008012462,
              5012331.0570541872
            ],
            [
              352213.12729201169,
              5012360.294929211
            ],
            [
              352270.67676642159,
              5012402.5202282527
            ],
            [
              352299.35423589329,
              5012418.4092313563
            ],
            [
              352332.34483735065,
              5012425.2600989789
            ],
            [
              352383.3656181547,
              5012437.7472924292
            ],
            [
              352409.77205371193,
              5012411.8751238156
            ],
            [
              352334.05352517194,
              5012356.5511829881
            ],
            [
              352322.16303585347,
              5012359.7584287338
            ],
            [
              352291.74226847047,
              5012330.4650327489
            ],
            [
              352270.94772005419,
              5012336.8239932898
            ],
            [
              352238.61705721973,
              5012285.1713897455
            ],
            [
              352220.47575659159,
              5012273.5651979866
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 40,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352492.63773300214,
              5012289.4013635609
            ],
            [
              352506.24373714224,
              5012298.1059718393
            ],
            [
              352525.35052988544,
              5012281.3275898108
            ],
            [
              352529.55247219367,
              5012266.3195616528
            ],
            [
              352542.68624591135,
              5012249.6523540588
            ],
            [
              352542.57513344183,
              5012243.6825053263
            ],
            [
              352536.26877724001,
              5012225.8841302311
            ],
            [
              352522.8016653739,
              5012224.6417900044
            ],
            [
              352514.03658178402,
              5012235.255769101
            ],
            [
              352497.88851885725,
              5012250.4861421613
            ],
            [
              352484.89363487775,
              5012274.6156778531
            ],
            [
              352492.63773300214,
              5012289.4013635609
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 41,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352436.74083982769,
              5012496.4730701484
            ],
            [
              352485.46279504168,
              5012465.7065642038
            ],
            [
              352524.97520751308,
              5012421.6747101601
            ],
            [
              352580.71893187583,
              5012366.8898639353
            ],
            [
              352629.16309431783,
              5012321.1987987179
            ],
            [
              352661.34808943578,
              5012284.7682673745
            ],
            [
              352685.87232753931,
              5012238.0294357594
            ],
            [
              352684.15680948453,
              5012226.1175367525
            ],
            [
              352669.22426690353,
              5012226.395464438
            ],
            [
              352645.88768010342,
              5012256.6893849988
            ],
            [
              352620.86343186005,
              5012276.5638828212
            ],
            [
              352608.44516627339,
              5012251.4143608278
            ],
            [
              352589.61616057152,
              5012283.1174392933
            ],
            [
              352561.85540503444,
              5012316.4796461659
            ],
            [
              352513.55012597569,
              5012369.633044919
            ],
            [
              352469.72465337004,
              5012422.7030769158
            ],
            [
              352459.66073334293,
              5012443.7921359567
            ],
            [
              352436.74083982769,
              5012496.4730701484
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 42,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356986.17657234677,
              5007345.7696277555
            ],
            [
              356999.08517781692,
              5007352.9774410184
            ],
            [
              357021.43293737451,
              5007352.561817551
            ],
            [
              357057.57538746874,
              5007349.7616732102
            ],
            [
              357061.65407038131,
              5007340.110019722
            ],
            [
              357031.14755850303,
              5007334.7672883719
            ],
            [
              357001.66898673144,
              5007333.5975406095
            ],
            [
              356997.17004321649,
              5007332.5094501385
            ],
            [
              356986.17657234677,
              5007345.7696277555
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 43,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356948.50528576068,
              5007721.5617856979
            ],
            [
              356964.25033571286,
              5007709.5651734127
            ],
            [
              356982.16326653986,
              5007699.6562097073
            ],
            [
              357000.07627575664,
              5007689.747245566
            ],
            [
              357009.35708814015,
              5007673.6149290223
            ],
            [
              357008.07536732091,
              5007661.9350000927
            ],
            [
              357001.55178587622,
              5007654.6084992336
            ],
            [
              356989.68759110378,
              5007646.3173434446
            ],
            [
              356959.9103842458,
              5007647.9351491816
            ],
            [
              356938.82471775252,
              5007658.9671042068
            ],
            [
              356927.21768556733,
              5007664.502847678
            ],
            [
              356914.98166113667,
              5007693.4578865133
            ],
            [
              356913.07086807332,
              5007705.1971961344
            ],
            [
              356918.64894665539,
              5007718.9251333103
            ],
            [
              356937.9425926666,
              5007726.014178264
            ],
            [
              356948.50528576068,
              5007721.5617856979
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 44,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357951.64659783739,
              5006077.1704540374
            ],
            [
              357964.19646203,
              5006058.1791542536
            ],
            [
              357985.25950296037,
              5006055.4427125659
            ],
            [
              358003.8466130661,
              5006045.7181564411
            ],
            [
              358004.69236641628,
              5006028.1168288477
            ],
            [
              358010.11944512854,
              5006004.568503717
            ],
            [
              358012.24670921807,
              5005992.8052151827
            ],
            [
              358026.1216671819,
              5005981.9958856208
            ],
            [
              358034.19908777246,
              5005974.8114321493
            ],
            [
              358038.12674760516,
              5005933.7053902568
            ],
            [
              358038.34048172156,
              5005882.117067297
            ],
            [
              358031.80204514874,
              5005845.8951007137
            ],
            [
              358022.05087602139,
              5005826.146073835
            ],
            [
              358017.01185986283,
              5005807.4818258705
            ],
            [
              358008.56336702657,
              5005797.0270880796
            ],
            [
              358006.392811689,
              5005811.9738443913
            ],
            [
              358000.74045290711,
              5005861.341117952
            ],
            [
              357996.14998033427,
              5005925.5993072912
            ],
            [
              357963.62685361056,
              5005968.6139687169
            ],
            [
              357964.54084447859,
              5005996.6294826269
            ],
            [
              357958.80865363189,
              5006003.5074035684
            ],
            [
              357945.4679669129,
              5006011.5662209354
            ],
            [
              357942.29281202075,
              5006014.7979572592
            ],
            [
              357933.63043560128,
              5006019.3946908955
            ],
            [
              357927.50011537864,
              5006019.7108866069
            ],
            [
              357915.69408812147,
              5006024.8444087496
            ],
            [
              357926.06332007737,
              5006026.0617355602
            ],
            [
              357935.44404369645,
              5006025.887309229
            ],
            [
              357936.8563832778,
              5006038.7571803024
            ],
            [
              357944.30596970039,
              5006060.8937386815
            ],
            [
              357951.64659783739,
              5006077.1704540374
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 45,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358962.82410942373,
              5006166.7031602217
            ],
            [
              358978.66223566426,
              5006164.1404725807
            ],
            [
              358994.56360301882,
              5006164.9788869852
            ],
            [
              359019.68697155447,
              5006173.5844701668
            ],
            [
              359047.35297787853,
              5006196.8860291457
            ],
            [
              359123.27117233031,
              5006252.1790234633
            ],
            [
              359136.00146101223,
              5006265.5514121987
            ],
            [
              359143.10243984585,
              5006281.2966602985
            ],
            [
              359160.18025616976,
              5006284.3813769724
            ],
            [
              359169.4444197921,
              5006294.4159942316
            ],
            [
              359223.16135294858,
              5006376.2058931803
            ],
            [
              359241.7108405162,
              5006397.4087069668
            ],
            [
              359253.97734043095,
              5006385.8396916687
            ],
            [
              359272.21052744641,
              5006390.0370094627
            ],
            [
              359288.34377768566,
              5006403.3461634824
            ],
            [
              359300.65251643903,
              5006394.0444922987
            ],
            [
              359268.19628843846,
              5006357.222995772
            ],
            [
              359224.14406728186,
              5006307.007962415
            ],
            [
              359137.6798631362,
              5006233.7655683681
            ],
            [
              359115.3691241722,
              5006193.3531113463
            ],
            [
              359067.95610535197,
              5006145.4687116239
            ],
            [
              359045.18573161011,
              5006141.3557447316
            ],
            [
              359001.01108138746,
              5006145.5794256525
            ],
            [
              358969.25050772779,
              5006146.1699963771
            ],
            [
              358958.76772595197,
              5006131.6216579713
            ],
            [
              358948.66431634239,
              5006137.4800436357
            ],
            [
              358962.82410942373,
              5006166.7031602217
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 46,
        "Layer" : "B02209B-POLJOPRIVREDA_03",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359948.049634305,
              5006326.328892095
            ],
            [
              359961.32333870616,
              5006324.4193155682
            ],
            [
              359977.59863881575,
              5006304.9949160414
            ],
            [
              359977.36682665994,
              5006292.5284747565
            ],
            [
              359974.67130426131,
              5006281.770652975
            ],
            [
              359960.56608656107,
              5006283.6956896503
            ],
            [
              359951.63555686222,
              5006295.5010731239
            ],
            [
              359944.46077980247,
              5006312.2621213244
            ],
            [
              359948.049634305,
              5006326.328892095
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 47,
        "Layer" : "B02208B-POLJOPRIVREDA_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354775.95434564556,
              5010380.1744017964
            ],
            [
              354792.12730188342,
              5010382.7176709929
            ],
            [
              354825.24500076019,
              5010378.3091940479
            ],
            [
              354825.33223663666,
              5010362.8738859985
            ],
            [
              354815.81120915414,
              5010335.1148928739
            ],
            [
              354788.58398216817,
              5010319.7375437636
            ],
            [
              354759.80360160518,
              5010331.3933593398
            ],
            [
              354761.28993504378,
              5010356.7457290459
            ],
            [
              354775.95434564556,
              5010380.1744017964
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 48,
        "Layer" : "B03---BLOKOVI",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359909.48995331448,
              5003528.4969858816
            ],
            [
              359909.53819855803,
              5003528.9510661317
            ],
            [
              359909.66456106014,
              5003529.3898701845
            ],
            [
              359909.86520135746,
              5003529.8000651989
            ],
            [
              359910.13402309752,
              5003530.1691876072
            ],
            [
              359910.4628582669,
              5003530.4860218121
            ],
            [
              359910.8417153745,
              5003530.7409409676
            ],
            [
              359911.2590830393,
              5003530.9261994828
            ],
            [
              359911.7022797566,
              5003531.0361683723
            ],
            [
              359912.157839216,
              5003531.0675062872
            ],
            [
              359912.61191947549,
              5003531.0192610407
            ],
            [
              359913.05072353693,
              5003530.8928985437
            ],
            [
              359913.4609185587,
              5003530.69225825
            ],
            [
              359913.83004097396,
              5003530.4234365206
            ],
            [
              359914.14687518589,
              5003530.0946013629
            ],
            [
              359914.40179434768,
              5003529.7157442719
            ],
            [
              359914.58705286775,
              5003529.2983766226
            ],
            [
              359914.69702176331,
              5003528.8551799236
            ],
            [
              359914.72835968272,
              5003528.3996204818
            ],
            [
              359914.68011443975,
              5003527.9455402382
            ],
            [
              359914.55375194148,
              5003527.506736191
            ],
            [
              359914.35311164957,
              5003527.0965411812
            ],
            [
              359914.08428991615,
              5003526.7274187757
            ],
            [
              359913.75545475358,
              5003526.4105845699
            ],
            [
              359913.37659765239,
              5003526.1556654125
            ],
            [
              359912.95922999282,
              5003525.9704068927
            ],
            [
              359912.51603327872,
              5003525.8604379967
            ],
            [
              359912.0604738199,
              5003525.8291000761
            ],
            [
              359911.60639355954,
              5003525.8773453133
            ],
            [
              359911.16758949478,
              5003526.0037078075
            ],
            [
              359910.75739446731,
              5003526.2043480948
            ],
            [
              359910.3882720453,
              5003526.4731698232
            ],
            [
              359910.07143782644,
              5003526.8020049809
            ],
            [
              359909.81651865825,
              5003527.1808620747
            ],
            [
              359909.63126013271,
              5003527.5982297268
            ],
            [
              359909.52129123459,
              5003528.0414264333
            ],
            [
              359909.48995331448,
              5003528.4969858816
            ]
          ],
          [
            [
              359847.79508801474,
              5003558.7975388551
            ],
            [
              359957.80162452551,
              5003556.7528559947
            ],
            [
              359955.75696005992,
              5003446.7463396192
            ],
            [
              359845.75042211375,
              5003448.7909872932
            ],
            [
              359847.79508801474,
              5003558.7975388551
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 49,
        "Layer" : "PLOVNIPUT",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351127.16466145916,
              5011705.7328684833
            ],
            [
              351103.57717192493,
              5011815.2781359591
            ],
            [
              351200.8373298861,
              5011744.4790079482
            ],
            [
              351127.16466145916,
              5011705.7328684833
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 50,
        "Layer" : "PLOVNIPUT",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              361748.94638856564,
              4999849.6571590025
            ],
            [
              361834.32386633736,
              4999762.7901534438
            ],
            [
              361711.80136929889,
              4999776.5406908542
            ],
            [
              361748.94638856564,
              4999849.6571590025
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 51,
        "Layer" : "B02211A-SUME_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              363102.06510991248,
              5001736.381296074
            ],
            [
              363040.36391208402,
              5001785.9625561126
            ],
            [
              362991.71984387649,
              5001864.9745952096
            ],
            [
              363002.59453003551,
              5001910.8363003405
            ],
            [
              362931.79702131875,
              5001984.2517085373
            ],
            [
              362896.32393609895,
              5002016.9552580426
            ],
            [
              362816.32083698508,
              5002026.4530047551
            ],
            [
              362757.36013093294,
              5002089.6346451165
            ],
            [
              362665.16471387976,
              5002182.1975202039
            ],
            [
              362671.23291698057,
              5002219.6568578435
            ],
            [
              362454.29585824528,
              5002398.1300679082
            ],
            [
              362520.68244901131,
              5002501.5614375928
            ],
            [
              362496.96535504883,
              5002526.1557234926
            ],
            [
              362469.76542805455,
              5002507.8751415992
            ],
            [
              362396.12828731048,
              5002592.4389046598
            ],
            [
              362335.76720257819,
              5002668.7049400629
            ],
            [
              362256.26139018219,
              5002726.5406376077
            ],
            [
              362208.9267633463,
              5002781.0948202778
            ],
            [
              362157.06238660985,
              5002736.4354137564
            ],
            [
              362156.26465277415,
              5002693.5106036887
            ],
            [
              362148.35885056428,
              5002701.7087423429
            ],
            [
              362088.88781679008,
              5002681.344154452
            ],
            [
              362046.87370587775,
              5002733.1157389656
            ],
            [
              362061.34621874843,
              5002789.2049433179
            ],
            [
              362079.3513280038,
              5002890.8518744716
            ],
            [
              362088.1530643297,
              5002933.1522327866
            ],
            [
              362122.49078305793,
              5003015.013467201
            ],
            [
              362144.83514726855,
              5003014.5842902698
            ],
            [
              362136.66663846921,
              5002986.1043515094
            ],
            [
              362112.90583890356,
              5002940.7351240264
            ],
            [
              362160.6698237338,
              5002941.756189364
            ],
            [
              362169.72456419241,
              5002983.0063786879
            ],
            [
              362171.10209893802,
              5002989.2819368411
            ],
            [
              362181.30027727335,
              5003015.4796658568
            ],
            [
              362192.94611232309,
              5003014.8871450145
            ],
            [
              362197.60582492774,
              5003002.5812722314
            ],
            [
              362181.56263163761,
              5002973.5175776156
            ],
            [
              362176.18228478049,
              5002942.0930742295
            ],
            [
              362188.09499006224,
              5002899.5642770855
            ],
            [
              362195.6962442614,
              5002852.8848795835
            ],
            [
              362203.45455280488,
              5002814.6640274115
            ],
            [
              362233.86807048059,
              5002799.2911999589
            ],
            [
              362271.09962441603,
              5002752.061145165
            ],
            [
              362299.43593627913,
              5002738.8423380973
            ],
            [
              362310.56834136124,
              5002768.2505553802
            ],
            [
              362335.1433926202,
              5002780.4861122258
            ],
            [
              362386.68201828055,
              5002762.6052845782
            ],
            [
              362453.29129633092,
              5002758.1943115667
            ],
            [
              362465.06218208809,
              5002743.9959435323
            ],
            [
              362495.4743502881,
              5002710.0137110194
            ],
            [
              362617.27020853414,
              5002579.2175646583
            ],
            [
              362742.6684170544,
              5002432.9812494759
            ],
            [
              362887.83174285543,
              5002271.5703861751
            ],
            [
              363028.70360805356,
              5002107.4111062167
            ],
            [
              363266.21511527349,
              5001855.0004640678
            ],
            [
              363102.06510991248,
              5001736.381296074
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 52,
        "Layer" : "B02211A-SUME_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              361968.75646535604,
              5003285.4124642918
            ],
            [
              362000.5760609805,
              5003235.2854448063
            ],
            [
              362089.16856701113,
              5003107.7857597154
            ],
            [
              362126.74767692696,
              5003063.9383024145
            ],
            [
              362111.48567454144,
              5003054.7137524057
            ],
            [
              362047.98230123922,
              5003055.8939903984
            ],
            [
              362012.04075765482,
              5003084.6966334516
            ],
            [
              362003.44758323493,
              5003117.5531326905
            ],
            [
              361912.75062247802,
              5003189.7412332958
            ],
            [
              361761.59621030831,
              5003307.2713352488
            ],
            [
              361674.30869827117,
              5003373.7551260302
            ],
            [
              361568.0161484782,
              5003529.0395304235
            ],
            [
              361438.07099680172,
              5003681.8155091945
            ],
            [
              361424.4177105063,
              5003741.0342061389
            ],
            [
              361336.6919421159,
              5003783.9403444128
            ],
            [
              361299.27645317174,
              5003834.7560074609
            ],
            [
              361254.75609710417,
              5003820.8423018716
            ],
            [
              361161.67861422623,
              5003893.3304403499
            ],
            [
              361084.99340812082,
              5004053.9613846885
            ],
            [
              361138.3630578033,
              5004067.7105732914
            ],
            [
              361104.22605410236,
              5004136.1549335327
            ],
            [
              361032.33609700331,
              5004078.5261926018
            ],
            [
              360971.67883463268,
              5004163.70824672
            ],
            [
              360995.82473158283,
              5004192.7419899777
            ],
            [
              360955.51429653313,
              5004246.5598122077
            ],
            [
              360982.71952757327,
              5004281.4330946235
            ],
            [
              360968.90212007979,
              5004331.8102823142
            ],
            [
              360876.74731360265,
              5004295.1961034182
            ],
            [
              360827.42309926444,
              5004340.3367618509
            ],
            [
              360825.3499340798,
              5004387.5474058352
            ],
            [
              360808.0349037025,
              5004408.5070400992
            ],
            [
              360784.60121019906,
              5004417.7874128064
            ],
            [
              360773.2951175273,
              5004444.5318513969
            ],
            [
              360758.82026982028,
              5004459.542190413
            ],
            [
              360738.66505893297,
              5004486.4511341155
            ],
            [
              360703.32275782112,
              5004490.0564724291
            ],
            [
              360727.523487798,
              5004522.037362624
            ],
            [
              360731.6785942851,
              5004586.8217513347
            ],
            [
              360759.32219105604,
              5004645.2728955504
            ],
            [
              360727.97065307194,
              5004704.820850932
            ],
            [
              360731.41351980058,
              5004731.2911156844
            ],
            [
              360790.51839469787,
              5004736.0888399407
            ],
            [
              360826.73732944491,
              5004779.6392863179
            ],
            [
              360883.22117732739,
              5004802.175317673
            ],
            [
              360886.82842258218,
              5004837.4872659128
            ],
            [
              360893.33063623676,
              5004869.7971431818
            ],
            [
              360919.87843926047,
              5004869.3036002079
            ],
            [
              360960.57246782928,
              5004836.116317492
            ],
            [
              360995.53120501712,
              5004811.8804141432
            ],
            [
              361010.38956178963,
              5004817.500688347
            ],
            [
              361052.51489793003,
              5004754.2226354927
            ],
            [
              361111.39615389198,
              5004665.5928856954
            ],
            [
              361149.78712308471,
              5004605.3309215838
            ],
            [
              361192.01641914534,
              5004539.0439967737
            ],
            [
              361192.01641915267,
              5004539.0439967653
            ],
            [
              361224.26453305187,
              5004488.4244577959
            ],
            [
              361514.29555424658,
              5004032.8527922155
            ],
            [
              361547.45405610121,
              5004005.7901864052
            ],
            [
              361634.61348711059,
              5003847.7076772042
            ],
            [
              361767.54774574976,
              5003599.3977359952
            ],
            [
              361795.35677272384,
              5003554.3319304306
            ],
            [
              361860.6308297689,
              5003442.0099267038
            ],
            [
              361907.58113683236,
              5003381.7313983096
            ],
            [
              361932.10704158933,
              5003337.5948648388
            ],
            [
              361968.75646535604,
              5003285.4124642918
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 53,
        "Layer" : "B02211A-SUME_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360063.27687577665,
              5006061.0284077898
            ],
            [
              360083.94119406492,
              5006036.6113026189
            ],
            [
              360167.70776538557,
              5005942.0355247548
            ],
            [
              360676.37032482045,
              5005288.5562885301
            ],
            [
              360605.05921070708,
              5005310.2815291546
            ],
            [
              360557.45379677537,
              5005311.1666096868
            ],
            [
              360533.47437561781,
              5005302.0962834796
            ],
            [
              360504.68998083379,
              5005290.7361438815
            ],
            [
              360459.46493053064,
              5005291.5769661311
            ],
            [
              360435.83923033305,
              5005301.5324551594
            ],
            [
              360421.91129789088,
              5005320.823799558
            ],
            [
              360460.48190048308,
              5005346.276181492
            ],
            [
              360484.72677891323,
              5005369.615930642
            ],
            [
              360499.5832531075,
              5005400.2674075142
            ],
            [
              360490.59279849211,
              5005428.9831064744
            ],
            [
              360453.2158970696,
              5005467.7428209102
            ],
            [
              360286.91185375833,
              5005551.1634595338
            ],
            [
              360252.93884549598,
              5005579.7477463083
            ],
            [
              360158.7833708431,
              5005581.4983605137
            ],
            [
              360156.48595628439,
              5005558.2472058432
            ],
            [
              360155.90197201172,
              5005526.8378622951
            ],
            [
              360119.70287233993,
              5005532.208778603
            ],
            [
              360094.95855249843,
              5005559.3911688998
            ],
            [
              360042.30943859409,
              5005572.212594036
            ],
            [
              359967.64957459405,
              5005579.5648603402
            ],
            [
              359942.31169965729,
              5005574.5110940915
            ],
            [
              359933.52534496208,
              5005564.6139262784
            ],
            [
              359933.98165912967,
              5005553.6726275068
            ],
            [
              359813.37513856351,
              5005612.1461217422
            ],
            [
              359757.21231092029,
              5005641.947663758
            ],
            [
              359718.99411654874,
              5005668.7698447723
            ],
            [
              359678.12461106322,
              5005709.1806824198
            ],
            [
              359678.68184275221,
              5005739.1502901278
            ],
            [
              359659.33007895504,
              5005739.5101027051
            ],
            [
              359646.2870903649,
              5005766.8312398503
            ],
            [
              359619.61645890475,
              5005808.3326049335
            ],
            [
              359596.41835677245,
              5005826.6548669403
            ],
            [
              359583.17707065895,
              5005880.2655611197
            ],
            [
              359521.74562434788,
              5005859.1172439707
            ],
            [
              359510.45422383136,
              5005868.5757074859
            ],
            [
              359497.77934199659,
              5005911.9127118057
            ],
            [
              359511.90628874506,
              5005946.6699071731
            ],
            [
              359518.14794484503,
              5005992.3490294917
            ],
            [
              359497.23713084363,
              5006027.7577155838
            ],
            [
              359473.43088526133,
              5006052.4448457658
            ],
            [
              359438.74361863983,
              5006071.9466902837
            ],
            [
              359406.75164497184,
              5006091.3984216191
            ],
            [
              359388.23569260788,
              5006110.5995786441
            ],
            [
              359377.95554231998,
              5006137.7290627845
            ],
            [
              359396.92215132958,
              5006142.7640899541
            ],
            [
              359437.60073332785,
              5006155.4768576566
            ],
            [
              359448.78219700331,
              5006176.8196565816
            ],
            [
              359460.06380760117,
              5006203.5482052052
            ],
            [
              359444.4933763757,
              5006236.1637575589
            ],
            [
              359420.73721644003,
              5006263.543824859
            ],
            [
              359394.48605985974,
              5006301.7456912156
            ],
            [
              359386.80107234756,
              5006323.4393093456
            ],
            [
              359397.73217373883,
              5006331.3175103487
            ],
            [
              359401.4897993253,
              5006321.9856082853
            ],
            [
              359413.02179616509,
              5006362.1786645278
            ],
            [
              359408.18218084908,
              5006391.900837088
            ],
            [
              359378.68506655807,
              5006400.5308922473
            ],
            [
              359360.16913000698,
              5006419.7320674192
            ],
            [
              359379.88687491202,
              5006465.1606092229
            ],
            [
              359418.57138933596,
              5006515.6241492592
            ],
            [
              359416.32677813357,
              5006539.9103677217
            ],
            [
              359354.43710578856,
              5006546.4489447754
            ],
            [
              359351.34120032407,
              5006524.9557961486
            ],
            [
              359369.70690458862,
              5006497.6759431353
            ],
            [
              359269.43315132178,
              5006469.9083903627
            ],
            [
              359259.10294976976,
              5006494.3449743511
            ],
            [
              359237.94194325543,
              5006516.2891992331
            ],
            [
              359260.10447152838,
              5006548.2031189995
            ],
            [
              359211.54057124554,
              5006546.4123435291
            ],
            [
              359154.64054324798,
              5006531.3074082136
            ],
            [
              359119.45266016159,
              5006523.8801899478
            ],
            [
              359114.71320804633,
              5006558.9882326992
            ],
            [
              359072.24065185833,
              5006594.7979523558
            ],
            [
              359017.98582017375,
              5006576.94997596
            ],
            [
              358870.29080082051,
              5006463.8613349972
            ],
            [
              358807.34944363817,
              5006413.8488559658
            ],
            [
              358793.97339608945,
              5006419.4852105556
            ],
            [
              358791.82907730632,
              5006449.1573173953
            ],
            [
              358813.99177973781,
              5006481.0712799774
            ],
            [
              358849.22992081591,
              5006491.1914296709
            ],
            [
              358876.83299573354,
              5006525.6980718179
            ],
            [
              358907.48190521298,
              5006579.0048755109
            ],
            [
              358886.47111371887,
              5006609.0278973058
            ],
            [
              358841.65392539167,
              5006663.7380572073
            ],
            [
              358809.46146958676,
              5006672.4182718266
            ],
            [
              358768.78279433766,
              5006659.7055423921
            ],
            [
              358706.79285280558,
              5006660.8583048014
            ],
            [
              358663.3187099736,
              5006642.8098422475
            ],
            [
              358658.53824545845,
              5006820.6922928169
            ],
            [
              358707.40269658359,
              5006838.6404925417
            ],
            [
              358732.11035216553,
              5006862.4255444761
            ],
            [
              358779.97337101155,
              5006826.5155131426
            ],
            [
              358826.14250543359,
              5006844.5138288857
            ],
            [
              358863.57516785798,
              5006827.6546719018
            ],
            [
              358871.26013509498,
              5006805.9610095853
            ],
            [
              358895.41691582295,
              5006800.1241586506
            ],
            [
              358921.77369182016,
              5006851.6024227589
            ],
            [
              358866.66453848261,
              5006908.2205045437
            ],
            [
              358819.65943782876,
              5006930.837315185
            ],
            [
              358630.09273193555,
              5006885.8734846944
            ],
            [
              358593.21706695837,
              5006983.2991698664
            ],
            [
              358646.34199157282,
              5007033.2930644983
            ],
            [
              358672.71717903222,
              5007048.2156659653
            ],
            [
              358688.86052710464,
              5007087.0410453258
            ],
            [
              358697.03138978116,
              5007111.7871678341
            ],
            [
              358719.40028504661,
              5007117.867757543
            ],
            [
              358740.86607823509,
              5007123.5516720135
            ],
            [
              358762.93387849932,
              5007115.3040488958
            ],
            [
              358781.00812295271,
              5007130.381014281
            ],
            [
              358794.73573963525,
              5007166.8800330432
            ],
            [
              358796.72375051124,
              5007190.7609422039
            ],
            [
              358797.6055582813,
              5007225.515014003
            ],
            [
              358793.13295194809,
              5007325.1469852328
            ],
            [
              358803.26786289789,
              5007370.2525215391
            ],
            [
              358979.2781260807,
              5007242.153217583
            ],
            [
              359021.35550745524,
              5007194.2695334023
            ],
            [
              358911.50255897286,
              5007167.4927261332
            ],
            [
              358870.38416358846,
              5007108.2329399874
            ],
            [
              358866.66453848261,
              5006908.2205045437
            ],
            [
              358963.98058864533,
              5006807.2163902055
            ],
            [
              359113.93121199694,
              5006753.5473634228
            ],
            [
              359215.01234741835,
              5006811.7057633791
            ],
            [
              359264.52425920503,
              5006946.2056993218
            ],
            [
              359264.52425920503,
              5006946.2056993209
            ],
            [
              359285.45992518403,
              5006924.4846243514
            ],
            [
              359327.39166113338,
              5006880.9798126174
            ],
            [
              359327.39166113368,
              5006880.9798126202
            ],
            [
              359319.03228804126,
              5006845.5509176981
            ],
            [
              359347.10532332089,
              5006788.9018796962
            ],
            [
              359371.5341897647,
              5006750.773011636
            ],
            [
              359415.75811214483,
              5006740.7412790675
            ],
            [
              359428.51322015474,
              5006736.8306073491
            ],
            [
              359437.20921950846,
              5006734.1688861055
            ],
            [
              359453.10024101817,
              5006713.7353934655
            ],
            [
              359456.33427822619,
              5006703.402219682
            ],
            [
              359463.51951743593,
              5006699.0642579058
            ],
            [
              359481.58390799427,
              5006680.1881990265
            ],
            [
              359489.28468526091,
              5006664.76999088
            ],
            [
              359515.46702214633,
              5006649.6788409436
            ],
            [
              359514.44995109231,
              5006634.0809568269
            ],
            [
              359530.11406979442,
              5006616.3570076041
            ],
            [
              359539.72371709568,
              5006624.894595569
            ],
            [
              359541.79205466522,
              5006638.0453419583
            ],
            [
              359564.82839296845,
              5006629.6417015614
            ],
            [
              359572.36744461651,
              5006624.9906818559
            ],
            [
              359592.4347264251,
              5006577.4936741414
            ],
            [
              359631.81867988122,
              5006566.5689316671
            ],
            [
              359668.87529016176,
              5006485.1336058415
            ],
            [
              359719.79974807071,
              5006431.9767868593
            ],
            [
              359768.47195881634,
              5006409.3723742422
            ],
            [
              359768.47195886652,
              5006409.3723741816
            ],
            [
              359796.63419685961,
              5006376.0956061874
            ],
            [
              359814.15513131872,
              5006355.3927068571
            ],
            [
              359880.77209508902,
              5006276.6775052063
            ],
            [
              359916.94042750873,
              5006233.9406946953
            ],
            [
              360036.41249930317,
              5006092.7715509934
            ],
            [
              360063.27687577665,
              5006061.0284077898
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 54,
        "Layer" : "B02211A-SUME_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357712.10172189731,
              5007593.053498067
            ],
            [
              357780.07625534001,
              5007573.2073202645
            ],
            [
              357887.63558308763,
              5007554.3451757291
            ],
            [
              357881.28258843283,
              5007538.0558349555
            ],
            [
              357839.52039758675,
              5007547.2118460182
            ],
            [
              357798.99484033237,
              5007472.5517172143
            ],
            [
              357767.94351895753,
              5007456.370624559
            ],
            [
              357720.95577007841,
              5007485.1755411802
            ],
            [
              357685.4057588359,
              5007527.7332984805
            ],
            [
              357691.46217327652,
              5007552.7586433981
            ],
            [
              357675.52591195743,
              5007597.7446638765
            ],
            [
              357361.58269963687,
              5007519.8933260953
            ],
            [
              357376.53507306863,
              5007568.7272379324
            ],
            [
              357363.47412208229,
              5007621.5902391393
            ],
            [
              357381.54495638993,
              5007649.3180960836
            ],
            [
              357433.08305231616,
              5007588.7235710816
            ],
            [
              357448.0354323581,
              5007637.5574697508
            ],
            [
              357389.67330292705,
              5007708.8029878512
            ],
            [
              357370.24519686453,
              5007796.8643581755
            ],
            [
              357238.04690421891,
              5007862.4671573844
            ],
            [
              357213.80454763869,
              5007880.4581175484
            ],
            [
              357143.54335301433,
              5007878.2569460329
            ],
            [
              357158.36518549733,
              5007920.0773094492
            ],
            [
              357285.76142860588,
              5007973.8357927362
            ],
            [
              357248.32773718663,
              5008037.6761422157
            ],
            [
              357165.72330857202,
              5008126.9126631478
            ],
            [
              357024.03944917797,
              5008248.8202002766
            ],
            [
              356975.22829678719,
              5008267.2681082934
            ],
            [
              356927.33049826091,
              5008334.8111875579
            ],
            [
              356861.03563423647,
              5008357.0923251081
            ],
            [
              356785.70275950071,
              5008476.0047798846
            ],
            [
              356719.73418550141,
              5008515.8199748974
            ],
            [
              356678.72536065575,
              5008576.2189044487
            ],
            [
              356637.06437155738,
              5008601.5499622105
            ],
            [
              356563.04512566439,
              5008774.8193098372
            ],
            [
              356568.70780465554,
              5008890.4783129636
            ],
            [
              356514.44269950385,
              5008993.2200393137
            ],
            [
              356581.38994939573,
              5009006.0067310948
            ],
            [
              356624.35564235545,
              5009050.8115604306
            ],
            [
              356618.31461875461,
              5009103.544057806
            ],
            [
              356494.17983301228,
              5009225.1257196404
            ],
            [
              356439.00146637351,
              5009278.7723717345
            ],
            [
              356380.70489877084,
              5009353.5251644338
            ],
            [
              356330.58892281231,
              5009301.8373518102
            ],
            [
              356268.96514274692,
              5009197.7433531759
            ],
            [
              356271.56160312681,
              5009148.582886002
            ],
            [
              356245.62291875522,
              5009075.3971342444
            ],
            [
              356221.9024540215,
              5009121.4425714556
            ],
            [
              356219.42984732072,
              5009185.3514399119
            ],
            [
              356161.76581971993,
              5009216.3075864129
            ],
            [
              356043.24411646783,
              5009189.6595632164
            ],
            [
              355947.789992326,
              5009128.5771604441
            ],
            [
              355986.02773364825,
              5009087.2424053289
            ],
            [
              356034.44740802975,
              5009047.7534779096
            ],
            [
              356020.14728233317,
              5009033.9874697179
            ],
            [
              355932.33670746832,
              5009032.1129916646
            ],
            [
              355928.23978276987,
              5009000.6170385946
            ],
            [
              355866.69422023412,
              5009089.462372872
            ],
            [
              355765.36631603522,
              5009115.9035431892
            ],
            [
              355708.49178889446,
              5009078.3733771658
            ],
            [
              355731.57294684951,
              5009186.6925998107
            ],
            [
              355682.66404163541,
              5009258.9367236458
            ],
            [
              355591.99597279081,
              5009292.1956708413
            ],
            [
              355495.53486524819,
              5009202.7816763073
            ],
            [
              355496.74636159436,
              5009141.45937132
            ],
            [
              355458.99110612954,
              5009144.8607101254
            ],
            [
              355442.36470669566,
              5009188.8701876951
            ],
            [
              355448.6110538629,
              5009216.1279210337
            ],
            [
              355389.0501778223,
              5009237.29332228
            ],
            [
              355339.60956630006,
              5009261.7905350542
            ],
            [
              355313.6164606458,
              5009291.0641410742
            ],
            [
              355310.22423179593,
              5009316.6372715542
            ],
            [
              355297.41568636213,
              5009335.0418141326
            ],
            [
              355288.86306485138,
              5009357.1740114409
            ],
            [
              355277.66915996466,
              5009379.895821454
            ],
            [
              355331.46297478251,
              5009415.7816415653
            ],
            [
              355329.28133369423,
              5009443.7120537329
            ],
            [
              355310.62942625914,
              5009457.554082782
            ],
            [
              355331.25222962623,
              5009501.2543216767
            ],
            [
              355290.2071881711,
              5009566.0674210396
            ],
            [
              355235.08155814843,
              5009573.7865732843
            ],
            [
              355180.95182141179,
              5009635.0356836086
            ],
            [
              355145.11441087944,
              5009689.250915667
            ],
            [
              355109.0589965799,
              5009731.7565275077
            ],
            [
              355081.25045544136,
              5009767.4151414959
            ],
            [
              355071.92082652909,
              5009806.0767021161
            ],
            [
              355070.86904344865,
              5009839.5640517464
            ],
            [
              355023.06281810085,
              5009880.6148585761
            ],
            [
              354999.24979281652,
              5009860.9772702986
            ],
            [
              354953.0245837777,
              5009896.9784696652
            ],
            [
              354919.91305521765,
              5009917.6752526788
            ],
            [
              354895.23513842345,
              5009941.5618731147
            ],
            [
              354865.63416237949,
              5010060.9234651439
            ],
            [
              354940.17264030146,
              5010106.391556697
            ],
            [
              354892.07540673827,
              5010160.3781558676
            ],
            [
              354862.21900004783,
              5010175.994197676
            ],
            [
              354813.92144865886,
              5010280.6431714091
            ],
            [
              354763.72511377628,
              5010283.2505245199
            ],
            [
              354733.49519748037,
              5010278.792792025
            ],
            [
              354739.73182050587,
              5010343.9390907818
            ],
            [
              354708.82383033511,
              5010393.0425389502
            ],
            [
              354684.11480604758,
              5010415.2564134719
            ],
            [
              354635.15693555056,
              5010394.4132487131
            ],
            [
              354649.29791314556,
              5010434.3116203928
            ],
            [
              354639.93722189072,
              5010471.3004525555
            ],
            [
              354643.62809842773,
              5010489.6390617723
            ],
            [
              354616.90244218119,
              5010493.4831842193
            ],
            [
              354579.20395249361,
              5010537.6928073503
            ],
            [
              354533.13424200442,
              5010582.0582931777
            ],
            [
              354478.38218837051,
              5010609.8514280505
            ],
            [
              354455.86910714867,
              5010586.0892412709
            ],
            [
              354417.29921105289,
              5010583.4600914223
            ],
            [
              354376.22109505901,
              5010626.0593474619
            ],
            [
              354340.90614999295,
              5010618.3495085221
            ],
            [
              354314.70963008277,
              5010650.6314409673
            ],
            [
              354332.66605206527,
              5010715.55973366
            ],
            [
              354306.65630575741,
              5010757.878538684
            ],
            [
              354251.37490780122,
              5010757.233835062
            ],
            [
              354229.29843131523,
              5010740.9107003948
            ],
            [
              354214.35457345186,
              5010747.8823642423
            ],
            [
              354156.81428833457,
              5010805.8484664727
            ],
            [
              354103.45613679203,
              5010818.5551671321
            ],
            [
              354092.10994488094,
              5010838.8470765781
            ],
            [
              354104.17208147125,
              5010857.0299371853
            ],
            [
              354114.65335700021,
              5010880.2624294329
            ],
            [
              354130.00173255469,
              5010895.0373830711
            ],
            [
              354153.0677996375,
              5010874.527473622
            ],
            [
              354191.4820850105,
              5010868.792435715
            ],
            [
              354195.48427194549,
              5010903.8592920462
            ],
            [
              354234.58352865151,
              5010934.926207344
            ],
            [
              354281.06444852625,
              5011002.6704508057
            ],
            [
              354319.3922028417,
              5011082.2801982667
            ],
            [
              354322.56065857044,
              5011162.5441248594
            ],
            [
              354295.27465345076,
              5011136.277623523
            ],
            [
              354252.67124543322,
              5011096.9088931056
            ],
            [
              354214.77928702562,
              5011040.7186317695
            ],
            [
              354162.09942356782,
              5010999.8640131094
            ],
            [
              354140.88768022967,
              5010940.0164940991
            ],
            [
              354109.17037786904,
              5010945.6269201068
            ],
            [
              354098.68909776874,
              5010922.3944294974
            ],
            [
              354065.06836665544,
              5010930.9321202599
            ],
            [
              354059.51919092785,
              5010931.6531085595
            ],
            [
              354042.0572946443,
              5010939.4147535572
            ],
            [
              354029.01393308927,
              5010958.0812284369
            ],
            [
              353987.90745039907,
              5010971.4216274368
            ],
            [
              353988.99667081988,
              5010997.7498688996
            ],
            [
              354048.17751942953,
              5010989.4626385812
            ],
            [
              354096.84995001467,
              5011026.6357823545
            ],
            [
              354161.74777083256,
              5011094.0373226991
            ],
            [
              354164.01347474585,
              5011125.7896551415
            ],
            [
              354224.69794007781,
              5011236.7778646983
            ],
            [
              354196.57818373822,
              5011255.7085506339
            ],
            [
              354156.4272464735,
              5011258.1291108308
            ],
            [
              354135.00447093049,
              5011276.9350889204
            ],
            [
              354122.73119024787,
              5011337.4058185304
            ],
            [
              354032.39062375372,
              5011432.7971664211
            ],
            [
              353983.24581505015,
              5011401.9172342019
            ],
            [
              353930.92136424774,
              5011496.1843409725
            ],
            [
              353878.09243613057,
              5011537.3289879281
            ],
            [
              353825.97953681904,
              5011616.9485329166
            ],
            [
              353766.02480573993,
              5011725.1617332855
            ],
            [
              353732.32175851648,
              5011714.0752367526
            ],
            [
              353707.69925346883,
              5011650.9443176677
            ],
            [
              353676.41074427136,
              5011589.6109112781
            ],
            [
              353685.08644845808,
              5011515.819929881
            ],
            [
              353709.63996819727,
              5011485.2418269021
            ],
            [
              353742.75136644539,
              5011464.5448230272
            ],
            [
              353785.90840614209,
              5011443.6609607087
            ],
            [
              353782.18633724499,
              5011423.649431238
            ],
            [
              353699.08955759689,
              5011368.3002897706
            ],
            [
              353677.57321712392,
              5011382.0879045585
            ],
            [
              353681.48206933978,
              5011412.1363227945
            ],
            [
              353678.6939286791,
              5011442.3093709005
            ],
            [
              353665.67351081007,
              5011462.6324835857
            ],
            [
              353647.53695244394,
              5011478.0305866469
            ],
            [
              353620.8422096867,
              5011483.547599704
            ],
            [
              353608.7348184741,
              5011485.0337097971
            ],
            [
              353598.59080775717,
              5011496.0906030945
            ],
            [
              353612.20322171511,
              5011511.7189983204
            ],
            [
              353618.52157391154,
              5011532.9160865583
            ],
            [
              353624.32291997992,
              5011568.7325396873
            ],
            [
              353618.08566811855,
              5011578.4712198768
            ],
            [
              353636.27147267613,
              5011612.0721710576
            ],
            [
              353650.27690063632,
              5011656.1249726387
            ],
            [
              353678.84711316018,
              5011706.2026434839
            ],
            [
              353684.54515854368,
              5011786.9387362329
            ],
            [
              353671.31334733556,
              5011848.8645618567
            ],
            [
              353613.25460535445,
              5011853.9083245462
            ],
            [
              353566.75361709559,
              5011815.0533613535
            ],
            [
              353539.74474325863,
              5011787.8720864262
            ],
            [
              353519.71518947446,
              5011747.3207527325
            ],
            [
              353502.92263203656,
              5011751.2442845786
            ],
            [
              353480.91495900397,
              5011798.5962056974
            ],
            [
              353432.23430688918,
              5011836.8153028088
            ],
            [
              353393.80989233631,
              5011843.5486572254
            ],
            [
              353372.73772615293,
              5011876.4394067209
            ],
            [
              353351.37444331247,
              5011893.6880968725
            ],
            [
              353338.50821396004,
              5011914.3895722646
            ],
            [
              353358.44822213036,
              5011950.1279617213
            ],
            [
              353387.52981479344,
              5011959.2159574134
            ],
            [
              353415.27266565914,
              5011961.1069343761
            ],
            [
              353441.58735053887,
              5011950.9879369922
            ],
            [
              353448.47709059244,
              5011932.805007644
            ],
            [
              353485.04785541474,
              5011891.2002696702
            ],
            [
              353506.43352300249,
              5011875.1547817215
            ],
            [
              353540.19763342669,
              5011876.9337018738
            ],
            [
              353598.6965348451,
              5011913.1581395939
            ],
            [
              353572.91931129724,
              5011952.1546733864
            ],
            [
              353530.21517082397,
              5011987.8552402398
            ],
            [
              353501.6488044819,
              5012006.4416885907
            ],
            [
              353480.08398923272,
              5012012.8612768929
            ],
            [
              353465.78156703868,
              5012078.8848325778
            ],
            [
              353456.77795276709,
              5012109.332706322
            ],
            [
              353420.76632555487,
              5012145.5955860494
            ],
            [
              353485.72698396625,
              5012203.2471075952
            ],
            [
              353531.44053277723,
              5012211.7672230313
            ],
            [
              353563.08539284259,
              5012238.286723136
            ],
            [
              353593.16315924778,
              5012195.8895745212
            ],
            [
              353633.01514040929,
              5012185.1733390251
            ],
            [
              353702.74782365601,
              5012167.7221861966
            ],
            [
              353752.2285443471,
              5012147.9557991177
            ],
            [
              353860.58826950297,
              5012107.1060688216
            ],
            [
              353924.63944434823,
              5012085.1404034002
            ],
            [
              353989.2823973535,
              5012063.5426326264
            ],
            [
              354024.92077077861,
              5012057.8970904443
            ],
            [
              354060.9543743176,
              5012028.5362040121
            ],
            [
              354130.62018457829,
              5011783.7866042955
            ],
            [
              354196.44224673079,
              5011549.3657774758
            ],
            [
              354244.26786424033,
              5011489.9452170096
            ],
            [
              354295.08466837712,
              5011426.808303454
            ],
            [
              354352.16923101287,
              5011365.0171678877
            ],
            [
              354406.04493322346,
              5011276.4777027331
            ],
            [
              354566.3540442157,
              5011091.5356466286
            ],
            [
              354740.37722577929,
              5010794.6672947723
            ],
            [
              354831.69839226949,
              5010638.8815944092
            ],
            [
              354969.00176656642,
              5010505.4105843427
            ],
            [
              355075.90957911458,
              5010404.1055599665
            ],
            [
              355248.75692840334,
              5010240.3168625217
            ],
            [
              355398.17706343281,
              5010106.5261559198
            ],
            [
              355777.69725387712,
              5009803.4428346166
            ],
            [
              355891.14369196753,
              5009717.181659013
            ],
            [
              356194.03515328764,
              5009520.7969179554
            ],
            [
              356373.34648879123,
              5009562.5777993174
            ],
            [
              356479.35037409858,
              5009587.2774862777
            ],
            [
              356531.04037719156,
              5009454.1933940602
            ],
            [
              356573.98989838705,
              5009369.8597190641
            ],
            [
              356619.79681790312,
              5009210.6759642968
            ],
            [
              356636.01771849609,
              5009167.180349322
            ],
            [
              356690.09199249698,
              5009105.5188865289
            ],
            [
              356734.15564438404,
              5009055.2726578508
            ],
            [
              356794.69618056557,
              5008999.6021454036
            ],
            [
              356856.05358412681,
              5008911.0339774042
            ],
            [
              356926.48915638356,
              5008851.0900186189
            ],
            [
              357156.0525929571,
              5008580.1815086827
            ],
            [
              357318.40759533492,
              5008380.4742693547
            ],
            [
              357334.66711840598,
              5008281.8508646293
            ],
            [
              357415.81732186204,
              5008186.8437053729
            ],
            [
              357487.34301151865,
              5008116.1558348937
            ],
            [
              357565.61119682173,
              5008039.0727293063
            ],
            [
              357614.47614585661,
              5007994.8138419734
            ],
            [
              357641.20511934062,
              5007959.4331884813
            ],
            [
              357684.03046055586,
              5007927.5415417198
            ],
            [
              357712.10172189731,
              5007593.053498067
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 55,
        "Layer" : "B02211A-SUME_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355771.53529790335,
              5009361.1998736849
            ],
            [
              355721.27123474452,
              5009361.7126529766
            ],
            [
              355708.46991009329,
              5009377.5770374397
            ],
            [
              355702.33327239385,
              5009411.0552932806
            ],
            [
              355699.30996845796,
              5009452.922263396
            ],
            [
              355696.62403971539,
              5009496.9506568788
            ],
            [
              355662.90755919291,
              5009494.5655162456
            ],
            [
              355645.92048725369,
              5009489.8135785405
            ],
            [
              355544.94643385353,
              5009489.5804928606
            ],
            [
              355510.85024344077,
              5009518.9332900131
            ],
            [
              355704.74634957284,
              5009702.1816413132
            ],
            [
              355762.86580357648,
              5009648.0238892213
            ],
            [
              355793.53299216781,
              5009610.0585239846
            ],
            [
              355803.27881600615,
              5009550.1661572661
            ],
            [
              355801.69975560985,
              5009497.7222111067
            ],
            [
              355811.67868432862,
              5009452.521045859
            ],
            [
              355813.4525490916,
              5009434.3278651154
            ],
            [
              355811.27629981044,
              5009408.1838671276
            ],
            [
              355792.86337866809,
              5009372.2060003337
            ],
            [
              355771.53529790335,
              5009361.1998736849
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 56,
        "Layer" : "B02211A-SUME_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355537.09902095183,
              5009358.8452012539
            ],
            [
              355479.55147787364,
              5009297.1282194918
            ],
            [
              355429.36747907166,
              5009320.4960367791
            ],
            [
              355407.83794177469,
              5009349.0501399757
            ],
            [
              355384.59738722845,
              5009386.5513258651
            ],
            [
              355383.82001535821,
              5009445.6881898772
            ],
            [
              355388.74981469219,
              5009483.6037544329
            ],
            [
              355415.97507221974,
              5009508.9046524726
            ],
            [
              355432.63318015641,
              5009521.263871287
            ],
            [
              355443.97793217486,
              5009521.1578602185
            ],
            [
              355490.60912214959,
              5009433.3785166275
            ],
            [
              355529.64145164139,
              5009393.0561070861
            ],
            [
              355537.09902095183,
              5009358.8452012539
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 57,
        "Layer" : "B02211A-SUME_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354345.71996601665,
              5010769.8437873404
            ],
            [
              354402.37016487139,
              5010820.1734620435
            ],
            [
              354440.56474762276,
              5010846.011034091
            ],
            [
              354504.75110893958,
              5010886.7801144943
            ],
            [
              354538.26327562897,
              5010891.2949590757
            ],
            [
              354558.55293790216,
              5010830.1131895063
            ],
            [
              354582.85958967527,
              5010800.5433310876
            ],
            [
              354578.61973691377,
              5010756.9459766559
            ],
            [
              354526.77956891205,
              5010734.787895537
            ],
            [
              354489.66491679696,
              5010720.9198234892
            ],
            [
              354423.00363352167,
              5010685.3351275455
            ],
            [
              354364.14022223192,
              5010746.3782941662
            ],
            [
              354345.71996601665,
              5010769.8437873404
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 58,
        "Layer" : "B02211A-SUME_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353548.83672115486,
              5012075.1786810737
            ],
            [
              353563.51632264879,
              5012078.7722587949
            ],
            [
              353583.91200585634,
              5012077.7481740881
            ],
            [
              353616.74619209982,
              5012063.8180546891
            ],
            [
              353648.19450936408,
              5012033.1576016443
            ],
            [
              353626.65397406049,
              5011993.0763461646
            ],
            [
              353607.61284629029,
              5011977.1996499412
            ],
            [
              353564.45444686548,
              5011979.0769936237
            ],
            [
              353503.0125323761,
              5012026.1926386664
            ],
            [
              353500.86235016264,
              5012049.2187170703
            ],
            [
              353548.83672115486,
              5012075.1786810737
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 59,
        "Layer" : "B02211A-SUME_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356495.48165351636,
              5008270.2715944182
            ],
            [
              356524.23016996688,
              5008263.0773739414
            ],
            [
              356537.55588982283,
              5008262.8295069588
            ],
            [
              356540.10715078382,
              5008280.5406040447
            ],
            [
              356540.64373825322,
              5008309.3882488618
            ],
            [
              356548.66070646577,
              5008262.6229502177
            ],
            [
              356543.80599325913,
              5008240.5151041606
            ],
            [
              356549.31314066437,
              5008178.2577372165
            ],
            [
              356562.18483253004,
              5008153.6002894901
            ],
            [
              356581.59556128614,
              5008122.1618243326
            ],
            [
              356598.90921229095,
              5008097.421759896
            ],
            [
              356620.78849287471,
              5008079.2562538534
            ],
            [
              356633.94911093236,
              5008070.1321913237
            ],
            [
              356671.58153089887,
              5008062.7727492107
            ],
            [
              356689.30792631552,
              5008060.2232407024
            ],
            [
              356696.09460702335,
              5008066.7564836321
            ],
            [
              356709.66796680336,
              5008079.8228825042
            ],
            [
              356727.43548282085,
              5008079.4924047552
            ],
            [
              356740.67864666024,
              5008074.8064881731
            ],
            [
              356746.92875706678,
              5008052.4920157148
            ],
            [
              356762.10405277088,
              5008032.2314182026
            ],
            [
              356775.18211751623,
              5008018.6692185607
            ],
            [
              356785.99800902681,
              5008002.9293865208
            ],
            [
              356812.27796120901,
              5007982.4621625887
            ],
            [
              356827.28801161697,
              5007953.3253769949
            ],
            [
              356831.15213475679,
              5007922.1761088362
            ],
            [
              356828.27070747648,
              5007886.7126283115
            ],
            [
              356805.64850950678,
              5007864.9351751246
            ],
            [
              356782.94376616494,
              5007838.7196626561
            ],
            [
              356795.8154737454,
              5007814.0622443147
            ],
            [
              356808.89354745176,
              5007800.500140477
            ],
            [
              356803.79123139154,
              5007765.0779635571
            ],
            [
              356817.07566873595,
              5007762.6110881101
            ],
            [
              356863.83946579689,
              5007768.4007918173
            ],
            [
              356886.58547075337,
              5007796.8353303475
            ],
            [
              356894.486511319,
              5007863.282834366
            ],
            [
              356899.58883610112,
              5007898.7050004564
            ],
            [
              356912.87326876976,
              5007896.238120554
            ],
            [
              356943.34753129358,
              5007862.3740449613
            ],
            [
              356955.9303219131,
              5007822.1833613943
            ],
            [
              356966.58097272046,
              5007797.5672660628
            ],
            [
              357023.66537162446,
              5007760.9884833805
            ],
            [
              357070.30532270804,
              5007760.1210186323
            ],
            [
              357074.54091124266,
              5007748.9431841047
            ],
            [
              357069.22647339018,
              5007670.8310937202
            ],
            [
              357087.97687287955,
              5007603.8879368892
            ],
            [
              357074.4448154564,
              5007593.0404736241
            ],
            [
              357083.16347903135,
              5007583.9990560832
            ],
            [
              357107.63524946559,
              5007585.7637938429
            ],
            [
              357135.64084779657,
              5007538.6267449511
            ],
            [
              357150.40329792636,
              5007496.1757644778
            ],
            [
              357163.23374628875,
              5007469.2993594352
            ],
            [
              357197.53092364495,
              5007402.0670050718
            ],
            [
              357239.77017541236,
              5007403.5013129888
            ],
            [
              357255.27549525595,
              5007400.9930639211
            ],
            [
              357288.13590735855,
              5007375.9640311785
            ],
            [
              357316.55421788129,
              5007351.0175310932
            ],
            [
              357351.55283397977,
              5007321.5090709077
            ],
            [
              357373.43225950294,
              5007303.3436511634
            ],
            [
              357410.77557792497,
              5007280.4509697324
            ],
            [
              357463.83070558438,
              5007266.1454276713
            ],
            [
              357496.97983873775,
              5007256.6496778866
            ],
            [
              357505.53357573558,
              5007238.7320964187
            ],
            [
              357498.37550673593,
              5007212.2274668254
            ],
            [
              357473.36727235821,
              5007181.6151357191
            ],
            [
              357468.43010401202,
              5007155.069199509
            ],
            [
              357488.04721471376,
              5007134.7260783855
            ],
            [
              357536.78434733051,
              5007127.1602377938
            ],
            [
              357554.13914018782,
              5007104.6394044021
            ],
            [
              357541.87900353904,
              5007042.7126609227
            ],
            [
              357513.13039463415,
              5007049.906760714
            ],
            [
              357491.25111599237,
              5007068.0721541625
            ],
            [
              357464.97115922731,
              5007088.5391816441
            ],
            [
              357429.60112110787,
              5007098.0762187177
            ],
            [
              357394.06585543661,
              5007098.7370768711
            ],
            [
              357345.37012125546,
              5007108.5219385261
            ],
            [
              357330.48382726993,
              5007144.3158041304
            ],
            [
              357308.68692695984,
              5007166.9192659063
            ],
            [
              357236.3035342808,
              5007217.101373069
            ],
            [
              357230.38352989254,
              5007257.1680882862
            ],
            [
              357205.34541958891,
              5007270.1144654835
            ],
            [
              357140.3150261612,
              5007312.1187180215
            ],
            [
              357118.50999089173,
              5007302.444576269
            ],
            [
              357108.39979164803,
              5007302.6326041901
            ],
            [
              357059.61472889723,
              5007344.9358464377
            ],
            [
              357059.97421212966,
              5007349.5758237466
            ],
            [
              356999.08517781692,
              5007352.9774410184
            ],
            [
              356990.83468633838,
              5007362.8078831909
            ],
            [
              357019.57952578604,
              5007369.4226122415
            ],
            [
              356949.95222066931,
              5007430.2951304326
            ],
            [
              356931.20256513549,
              5007448.1199279297
            ],
            [
              356924.55168496346,
              5007475.2521767709
            ],
            [
              356884.68280298257,
              5007511.7401448544
            ],
            [
              356893.35184961202,
              5007570.2674370008
            ],
            [
              356856.79133556277,
              5007597.3998802621
            ],
            [
              356821.5033677395,
              5007598.0561908325
            ],
            [
              356802.06215719384,
              5007596.6541993627
            ],
            [
              356789.77705658268,
              5007600.4097459698
            ],
            [
              356784.81169725914,
              5007618.1370488219
            ],
            [
              356783.89981507848,
              5007664.0049226061
            ],
            [
              356773.70680031995,
              5007685.356429223
            ],
            [
              356758.05676499713,
              5007697.9919980112
            ],
            [
              356735.18519489036,
              5007701.9444465712
            ],
            [
              356725.02496894629,
              5007725.0588345546
            ],
            [
              356744.56454807043,
              5007731.7494504247
            ],
            [
              356753.74717367796,
              5007750.9771040129
            ],
            [
              356757.86623605154,
              5007782.64342927
            ],
            [
              356756.1345118428,
              5007784.439124926
            ],
            [
              356697.38477122533,
              5007757.3158586631
            ],
            [
              356651.9367161913,
              5007781.0866639437
            ],
            [
              356623.8374785991,
              5007788.6632387713
            ],
            [
              356618.90491655626,
              5007808.1535168299
            ],
            [
              356587.80142016144,
              5007844.0019484032
            ],
            [
              356577.93645224214,
              5007882.9824216897
            ],
            [
              356567.9401797863,
              5007914.9113017907
            ],
            [
              356521.05564870551,
              5007956.343822089
            ],
            [
              356487.63037693559,
              5007962.2559887795
            ],
            [
              356423.98080910713,
              5007956.3859018777
            ],
            [
              356394.11719408009,
              5007963.995312863
            ],
            [
              356439.09269614995,
              5008013.1549893431
            ],
            [
              356473.30492394773,
              5008049.5521589536
            ],
            [
              356479.1554383543,
              5008079.4228133531
            ],
            [
              356470.69416446792,
              5008098.9786523785
            ],
            [
              356485.03889661777,
              5008111.0563423159
            ],
            [
              356485.30121768703,
              5008125.1594557576
            ],
            [
              356496.24838591419,
              5008144.3542927336
            ],
            [
              356498.99646058405,
              5008197.2081698067
            ],
            [
              356494.35903425043,
              5008232.5643658126
            ],
            [
              356498.24860958924,
              5008251.8905607425
            ],
            [
              356495.48165351636,
              5008270.2715944182
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 60,
        "Layer" : "B02211A-SUME_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359654.173788385,
              5006259.1821640283
            ],
            [
              359674.84014136397,
              5006256.4979820512
            ],
            [
              359704.49679053831,
              5006242.1475818744
            ],
            [
              359750.26036376611,
              5006227.4976808829
            ],
            [
              359742.54508581472,
              5006183.9443428079
            ],
            [
              359728.26877601939,
              5006158.9117325991
            ],
            [
              359725.62577015744,
              5006140.5622329954
            ],
            [
              359718.42349155823,
              5006124.5973190945
            ],
            [
              359678.96445674723,
              5006106.9323884267
            ],
            [
              359651.43781649176,
              5006112.0439334875
            ],
            [
              359635.6301286064,
              5006128.4366210913
            ],
            [
              359626.8535749584,
              5006151.5981717696
            ],
            [
              359624.85180781462,
              5006167.7341479436
            ],
            [
              359636.82711240306,
              5006192.8096378762
            ],
            [
              359649.10166618478,
              5006233.9783149334
            ],
            [
              359654.173788385,
              5006259.1821640283
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 61,
        "Layer" : "B02211A-SUME_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359838.12139259727,
              5005821.4189233948
            ],
            [
              359872.86032572022,
              5005846.3891252447
            ],
            [
              359910.9130422853,
              5005805.1435851771
            ],
            [
              359949.03928480734,
              5005837.0500132972
            ],
            [
              359934.42585819395,
              5005816.865347309
            ],
            [
              359918.08296898875,
              5005799.3811045941
            ],
            [
              359890.77639848186,
              5005762.0526793264
            ],
            [
              359884.90436276811,
              5005763.9573332872
            ],
            [
              359867.44122024911,
              5005770.1363784363
            ],
            [
              359855.65398904058,
              5005766.4526732657
            ],
            [
              359819.37061415002,
              5005758.345824101
            ],
            [
              359805.86682198936,
              5005767.3783834362
            ],
            [
              359793.53875794396,
              5005787.1219539074
            ],
            [
              359781.17427252926,
              5005804.9148110105
            ],
            [
              359778.57200552773,
              5005822.5261554765
            ],
            [
              359777.92236474203,
              5005840.1011939459
            ],
            [
              359799.45353065181,
              5005842.6280700918
            ],
            [
              359818.97796447627,
              5005842.2650411865
            ],
            [
              359838.12139259727,
              5005821.4189233948
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 62,
        "Layer" : "B02211A-SUME_02",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359937.436076755,
              5005679.3205319261
            ],
            [
              359921.33203062491,
              5005697.1244193688
            ],
            [
              359932.3759738768,
              5005706.6169932354
            ],
            [
              359968.22345977096,
              5005720.180993218
            ],
            [
              360015.10939839244,
              5005753.1067037089
            ],
            [
              360015.43336834665,
              5005770.5306896186
            ],
            [
              360008.75325842883,
              5005794.1375906551
            ],
            [
              360008.90716173669,
              5005802.4148821738
            ],
            [
              360021.83552459494,
              5005827.6918165572
            ],
            [
              360036.36625479953,
              5005843.4309509434
            ],
            [
              360043.74734108133,
              5005857.5242255945
            ],
            [
              360044.04490947333,
              5005873.5280042402
            ],
            [
              360020.6362696531,
              5005913.0971643021
            ],
            [
              359989.61874100351,
              5005913.6738971928
            ],
            [
              359989.69522719161,
              5005948.0251843687
            ],
            [
              360018.50944741437,
              5005980.0221957164
            ],
            [
              360041.61650815175,
              5005996.9434215371
            ],
            [
              360079.53048611426,
              5005992.9851538716
            ],
            [
              360102.85579016159,
              5005963.2718798928
            ],
            [
              360126.1207780058,
              5005930.306523555
            ],
            [
              360130.94089055801,
              5005897.6840444114
            ],
            [
              360121.83436021325,
              5005816.5213590628
            ],
            [
              360123.29887658148,
              5005778.5391737539
            ],
            [
              360124.78340289247,
              5005741.6411056556
            ],
            [
              360113.24832159554,
              5005704.9850274129
            ],
            [
              360069.40563262819,
              5005681.9428226864
            ],
            [
              360038.38114604756,
              5005647.8179950332
            ],
            [
              360021.19019078609,
              5005671.8409772385
            ],
            [
              359993.62312060106,
              5005679.0449445099
            ],
            [
              359976.66588024952,
              5005677.8220251361
            ],
            [
              359937.436076755,
              5005679.3205319261
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 63,
        "Layer" : "B02212A-SUME_03",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360676.37032482045,
              5005288.5562885301
            ],
            [
              360677.98886867269,
              5005286.3966406025
            ],
            [
              360662.65322995262,
              5005308.2059405334
            ],
            [
              360645.59394642198,
              5005328.0946558416
            ],
            [
              360316.6086242756,
              5005750.7421948295
            ],
            [
              360167.7077653865,
              5005942.035524738
            ],
            [
              360083.94119407149,
              5006036.6113026226
            ],
            [
              359764.89282765368,
              5006413.5252264235
            ],
            [
              359793.35638380685,
              5006401.1078247493
            ],
            [
              359811.73623215145,
              5006390.0616106112
            ],
            [
              359841.73604970309,
              5006371.9326460157
            ],
            [
              359837.26132475591,
              5006355.3632650143
            ],
            [
              359947.60839878389,
              5006239.5188727742
            ],
            [
              359962.80362181045,
              5006235.0731761251
            ],
            [
              359999.90416877909,
              5006213.5675760144
            ],
            [
              360005.99503956101,
              5006167.6597215077
            ],
            [
              360106.44703916315,
              5006117.2219393486
            ],
            [
              360143.96029340971,
              5006117.9121187488
            ],
            [
              360145.78719570552,
              5006141.4692949979
            ],
            [
              360136.45507963578,
              5006162.4584521251
            ],
            [
              360135.42781853821,
              5006181.9055570168
            ],
            [
              360160.44525145571,
              5006182.8280834956
            ],
            [
              360182.55677503173,
              5006176.8660721835
            ],
            [
              360187.00584325864,
              5006192.0482014213
            ],
            [
              360193.98932737758,
              5006192.4744841512
            ],
            [
              360200.00682036934,
              5006188.5979383402
            ],
            [
              360206.95930282399,
              5006176.5553752016
            ],
            [
              360208.54110183619,
              5006165.5369617539
            ],
            [
              360221.95067061106,
              5006160.6580805182
            ],
            [
              360232.75169888203,
              5006155.6363088358
            ],
            [
              360256.35344684072,
              5006132.4422397958
            ],
            [
              360268.65145622107,
              5006129.8317481792
            ],
            [
              360288.72505565471,
              5006119.0428276211
            ],
            [
              360319.2600948718,
              5006118.4517433504
            ],
            [
              360339.52136002941,
              5006103.174076166
            ],
            [
              360372.47807276703,
              5006035.2641889257
            ],
            [
              360468.36180187995,
              5005993.3240470774
            ],
            [
              360519.63378362433,
              5005987.2875319859
            ],
            [
              360609.64066115773,
              5005987.9710892541
            ],
            [
              360629.96369451965,
              5005941.2613603799
            ],
            [
              360646.73752746725,
              5005927.9924253719
            ],
            [
              360682.80650578655,
              5005870.8536639297
            ],
            [
              360714.10132923949,
              5005796.8243674869
            ],
            [
              360777.40702693484,
              5005758.0368636837
            ],
            [
              360867.3468064901,
              5005732.3438857188
            ],
            [
              360869.2316855719,
              5005713.5511721037
            ],
            [
              360882.60603683448,
              5005687.8456493253
            ],
            [
              360892.29009817535,
              5005678.2431417014
            ],
            [
              360909.767190321,
              5005658.6137514291
            ],
            [
              360909.67855211854,
              5005653.846466586
            ],
            [
              360891.0842549231,
              5005634.1332724374
            ],
            [
              360867.88235060585,
              5005600.977053524
            ],
            [
              360845.27215449477,
              5005570.0126924682
            ],
            [
              360814.38963528676,
              5005508.9184447872
            ],
            [
              360714.30581623828,
              5005352.315876455
            ],
            [
              360682.58965688437,
              5005281.2797544748
            ],
            [
              360723.91689196153,
              5005229.4459311701
            ],
            [
              360788.47418406763,
              5005169.8396814968
            ],
            [
              360842.60848844144,
              5005127.0231952965
            ],
            [
              360910.93678422255,
              5005068.9787121359
            ],
            [
              361132.38379119599,
              5004891.9113567201
            ],
            [
              361136.70799071313,
              5004857.042055673
            ],
            [
              361176.11636649672,
              5004779.5802095057
            ],
            [
              361216.3713203306,
              5004698.3205381678
            ],
            [
              361228.57367007044,
              5004659.3287712364
            ],
            [
              361250.58777347946,
              5004613.197120687
            ],
            [
              361276.74430032069,
              5004546.472534148
            ],
            [
              361344.57774177013,
              5004421.0941296462
            ],
            [
              361375.62780512631,
              5004367.5419747289
            ],
            [
              361376.57610267861,
              5004341.6054629786
            ],
            [
              361398.06934545841,
              5004314.13450447
            ],
            [
              361440.30437609198,
              5004209.0777664566
            ],
            [
              361499.0928012401,
              5004108.9215526097
            ],
            [
              361511.7791776355,
              5004077.4998411015
            ],
            [
              361547.45405610121,
              5004005.7901864052
            ],
            [
              361516.72821107361,
              5004032.8075732905
            ],
            [
              361295.59269306646,
              5004376.4614233393
            ],
            [
              361010.38956178963,
              5004817.500688347
            ],
            [
              361015.99972975202,
              5004815.3359083692
            ],
            [
              360998.52782936109,
              5004842.7614297187
            ],
            [
              360974.34176692582,
              5004880.7261328315
            ],
            [
              360958.21772669681,
              5004906.0359378699
            ],
            [
              360931.07893905143,
              5004948.6355322916
            ],
            [
              360915.5965061576,
              5004969.3534269929
            ],
            [
              360903.23863872193,
              5004985.8426883211
            ],
            [
              360882.27069311024,
              5005013.8204913856
            ],
            [
              360845.66370287031,
              5005062.6656868914
            ],
            [
              360809.05671570619,
              5005111.5108927861
            ],
            [
              360784.6520593068,
              5005144.0743691539
            ],
            [
              360754.02329856384,
              5005184.9427646287
            ],
            [
              360723.6404242912,
              5005225.4830802754
            ],
            [
              360676.37032482045,
              5005288.5562885301
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 64,
        "Layer" : "B02212A-SUME_03",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359327.39166113368,
              5006880.9798126202
            ],
            [
              359327.39166130888,
              5006880.9798124367
            ],
            [
              359264.75498222047,
              5006945.9663206367
            ],
            [
              359281.60119034018,
              5006994.3053750563
            ],
            [
              359285.01297753496,
              5007003.9151704488
            ],
            [
              359283.66900439496,
              5007008.182868517
            ],
            [
              359289.61485469685,
              5006995.7395613203
            ],
            [
              359297.85087750311,
              5006988.9653585088
            ],
            [
              359309.94770608179,
              5006985.3496982288
            ],
            [
              359314.76730617549,
              5006985.9710509758
            ],
            [
              359320.87938940624,
              5006978.4140836466
            ],
            [
              359323.77839976264,
              5006974.1919013392
            ],
            [
              359328.3612850823,
              5006973.783434527
            ],
            [
              359331.94850625837,
              5006970.7936672494
            ],
            [
              359337.80279821,
              5006968.3296168204
            ],
            [
              359346.77977589279,
              5006941.1350781992
            ],
            [
              359327.39166113368,
              5006880.9798126202
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 65,
        "Layer" : "B02212A-SUME_03",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358979.27812607971,
              5007242.1532175848
            ],
            [
              358979.27812607965,
              5007242.153217583
            ],
            [
              358891.27299972269,
              5007306.2028531553
            ],
            [
              358803.06726417516,
              5007370.3985162461
            ],
            [
              358807.52178906748,
              5007397.2023771107
            ],
            [
              358813.82580135419,
              5007417.2407171866
            ],
            [
              358834.42600772687,
              5007440.5700789411
            ],
            [
              358846.66310712596,
              5007468.7304645898
            ],
            [
              358926.16370341572,
              5007438.2977197934
            ],
            [
              359007.51182698639,
              5007366.6028620088
            ],
            [
              359170.83593976538,
              5007253.1895880736
            ],
            [
              359219.65609155287,
              5007189.6453505931
            ],
            [
              359214.23305705626,
              5007169.1545655215
            ],
            [
              359130.57614414481,
              5007220.4417371973
            ],
            [
              359021.35550745524,
              5007194.2695334023
            ],
            [
              358979.27812607971,
              5007242.1532175848
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 66,
        "Layer" : "B02210A-SUME_01",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              362665.16471387941,
              5002182.1975202011
            ],
            [
              362757.36013092619,
              5002089.6346451165
            ],
            [
              362816.32083698612,
              5002026.453004756
            ],
            [
              362896.3239360989,
              5002016.9552580407
            ],
            [
              362931.79702132015,
              5001984.2517085206
            ],
            [
              363002.59453003557,
              5001910.8363003414
            ],
            [
              362991.71984387649,
              5001864.9745952096
            ],
            [
              363040.36391208059,
              5001785.9625561181
            ],
            [
              363102.06510991172,
              5001736.381296074
            ],
            [
              362991.59736571804,
              5001647.671024506
            ],
            [
              362960.85719600023,
              5001688.8389451969
            ],
            [
              362940.42333710479,
              5001679.8726192843
            ],
            [
              362899.90312365995,
              5001680.6255427245
            ],
            [
              362877.24939957651,
              5001719.9883254394
            ],
            [
              362863.68601132551,
              5001745.1632147217
            ],
            [
              362776.24199908669,
              5001821.5564946588
            ],
            [
              362748.33295645064,
              5001814.3306554453
            ],
            [
              362681.47972425108,
              5001880.2633221736
            ],
            [
              362629.37725894392,
              5001972.2647489524
            ],
            [
              362553.85117691517,
              5002018.8408196652
            ],
            [
              362543.3470441426,
              5002040.8435246982
            ],
            [
              362539.19243494951,
              5002068.9588972237
            ],
            [
              362482.19438107888,
              5002105.8446711963
            ],
            [
              362471.42967586627,
              5002113.8330518268
            ],
            [
              362443.84010069305,
              5002139.2686319668
            ],
            [
              362416.21339179418,
              5002181.3458109396
            ],
            [
              362379.30184938567,
              5002208.5122305574
            ],
            [
              362341.26602049719,
              5002259.0647611208
            ],
            [
              362317.0249052599,
              5002296.8994585508
            ],
            [
              362267.87713483517,
              5002336.7547833389
            ],
            [
              362253.04465224472,
              5002377.5300234566
            ],
            [
              362222.65641982283,
              5002420.1521220952
            ],
            [
              362196.13753328723,
              5002503.2018451532
            ],
            [
              362158.21747985465,
              5002559.9830337744
            ],
            [
              362149.67683486146,
              5002603.7567435857
            ],
            [
              362134.55514788441,
              5002628.9605950909
            ],
            [
              362114.38181065087,
              5002634.0085632754
            ],
            [
              362089.4189938723,
              5002681.5260447422
            ],
            [
              362148.35885056329,
              5002701.7087423429
            ],
            [
              362156.26465277444,
              5002693.5106036905
            ],
            [
              362157.06238661084,
              5002736.4354137583
            ],
            [
              362208.92676334555,
              5002781.0948202778
            ],
            [
              362256.26139018039,
              5002726.5406376058
            ],
            [
              362335.76720258419,
              5002668.7049400602
            ],
            [
              362396.12828731386,
              5002592.4389046635
            ],
            [
              362469.76542805351,
              5002507.8751415992
            ],
            [
              362496.96535504883,
              5002526.1557234926
            ],
            [
              362520.68244901131,
              5002501.5614375928
            ],
            [
              362454.29585824528,
              5002398.1300679082
            ],
            [
              362671.23291698057,
              5002219.6568578435
            ],
            [
              362665.16471387941,
              5002182.1975202011
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 67,
        "Layer" : "B02210A-SUME_01",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              362271.09962441603,
              5002752.061145165
            ],
            [
              362265.9216191782,
              5002758.6296983277
            ],
            [
              362238.60679855902,
              5002793.9014511062
            ],
            [
              362239.00266696332,
              5002815.2022129102
            ],
            [
              362232.70523909741,
              5002819.8755398151
            ],
            [
              362232.99304236524,
              5002835.3615109902
            ],
            [
              362242.65198092686,
              5002864.3422795394
            ],
            [
              362245.18659083097,
              5002902.567947072
            ],
            [
              362249.324371266,
              5002928.9174805395
            ],
            [
              362247.99773037655,
              5002960.1015612893
            ],
            [
              362267.03891103982,
              5002944.5943311928
            ],
            [
              362316.62691725243,
              5002900.485333357
            ],
            [
              362334.19674229698,
              5002882.6885362724
            ],
            [
              362341.11806560872,
              5002862.5122994268
            ],
            [
              362336.98029138829,
              5002836.1627721768
            ],
            [
              362310.15090803144,
              5002815.7024748633
            ],
            [
              362311.72037030204,
              5002802.0044373851
            ],
            [
              362299.56334320275,
              5002785.827745433
            ],
            [
              362271.09962441603,
              5002752.061145165
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 68,
        "Layer" : "B02210A-SUME_01",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358678.82291089924,
              5006220.8138065524
            ],
            [
              358545.34581252234,
              5006069.5341167971
            ],
            [
              358470.91911728261,
              5005970.8278866122
            ],
            [
              358383.56546045031,
              5005879.6148476908
            ],
            [
              358303.47754134564,
              5005839.0617889958
            ],
            [
              358290.82899647404,
              5005827.7532695392
            ],
            [
              358279.859243482,
              5005835.061074147
            ],
            [
              358286.66061110975,
              5005842.4823990623
            ],
            [
              358293.31498416228,
              5005865.8900682833
            ],
            [
              358304.19706300762,
              5005901.6508670785
            ],
            [
              358301.55744423199,
              5005903.0318876402
            ],
            [
              358303.69683145167,
              5005922.527638169
            ],
            [
              358305.23533418291,
              5005933.5987364892
            ],
            [
              358320.83561122179,
              5005936.4165901802
            ],
            [
              358315.70485508646,
              5005947.1677467301
            ],
            [
              358311.10889548011,
              5005962.7928184066
            ],
            [
              358306.31490793853,
              5005977.7698432421
            ],
            [
              358301.520920993,
              5005992.7468684549
            ],
            [
              358267.75545555953,
              5005979.4779452896
            ],
            [
              358265.10418093426,
              5005999.370069867
            ],
            [
              358261.90916213271,
              5006013.2331716595
            ],
            [
              358262.0855597367,
              5006022.7199250869
            ],
            [
              358253.05078533856,
              5006047.4757865937
            ],
            [
              358253.64263990015,
              5006056.0921019558
            ],
            [
              358281.14209231298,
              5006115.7232997455
            ],
            [
              358306.98200313916,
              5006174.2351519875
            ],
            [
              358317.27685872099,
              5006215.9092360931
            ],
            [
              358321.43726543442,
              5006234.8616327858
            ],
            [
              358322.03861018835,
              5006267.2010836918
            ],
            [
              358317.07147635758,
              5006307.2559612989
            ],
            [
              358319.00512607076,
              5006347.042069748
            ],
            [
              358334.83986240107,
              5006369.274505971
            ],
            [
              358363.76301451388,
              5006393.6348325787
            ],
            [
              358395.19005463237,
              5006425.0622508079
            ],
            [
              358419.52409476775,
              5006457.807251947
            ],
            [
              358447.12502164656,
              5006499.9365249788
            ],
            [
              358467.70976334653,
              5006529.1158717275
            ],
            [
              358483.94562339183,
              5006572.119068427
            ],
            [
              358489.28982740297,
              5006579.6721286224
            ],
            [
              358524.70254869747,
              5006627.7669386091
            ],
            [
              358554.15466757235,
              5006680.5723349331
            ],
            [
              358567.91717686091,
              5006710.5020785555
            ],
            [
              358580.6351760914,
              5006756.5048910258
            ],
            [
              358588.52171303518,
              5006797.8550580535
            ],
            [
              358586.13231693377,
              5006860.7376061808
            ],
            [
              358587.60906531598,
              5006940.146944724
            ],
            [
              358593.21706695744,
              5006983.2991698664
            ],
            [
              358630.09273193555,
              5006885.8734846944
            ],
            [
              358819.65943783935,
              5006930.8373151924
            ],
            [
              358863.47825403139,
              5006909.7536059599
            ],
            [
              358921.1346617784,
              5006850.3543129228
            ],
            [
              358895.41691582295,
              5006800.1241586506
            ],
            [
              358871.26013509399,
              5006805.9610095853
            ],
            [
              358863.57516785798,
              5006827.6546719018
            ],
            [
              358826.14250543405,
              5006844.5138288857
            ],
            [
              358779.97337101155,
              5006826.5155131426
            ],
            [
              358732.11035216553,
              5006862.4255444761
            ],
            [
              358707.40269658476,
              5006838.6404925417
            ],
            [
              358658.5382454592,
              5006820.6922928169
            ],
            [
              358663.31870997435,
              5006642.8098422475
            ],
            [
              358706.79285280785,
              5006660.8583048033
            ],
            [
              358768.78279433842,
              5006659.7055423921
            ],
            [
              358809.46146958909,
              5006672.4182718275
            ],
            [
              358841.65392538963,
              5006663.7380572073
            ],
            [
              358886.47111372207,
              5006609.0278973151
            ],
            [
              358907.48190521292,
              5006579.0048755091
            ],
            [
              358876.83299573744,
              5006525.6980718225
            ],
            [
              358849.22992081696,
              5006491.1914296728
            ],
            [
              358813.99177973781,
              5006481.0712799774
            ],
            [
              358791.82907730731,
              5006449.1573173953
            ],
            [
              358793.97339608968,
              5006419.4852105556
            ],
            [
              358807.47736578336,
              5006413.7949525565
            ],
            [
              358815.15587100241,
              5006372.1780887097
            ],
            [
              358805.26803302515,
              5006342.0722138733
            ],
            [
              358678.82291089924,
              5006220.8138065524
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 69,
        "Layer" : "B02210A-SUME_01",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357387.01493788295,
              5007506.0055859871
            ],
            [
              357380.73653015023,
              5007453.7109783506
            ],
            [
              357370.15051436779,
              5007444.8306794893
            ],
            [
              357319.21217751264,
              5007439.6798458332
            ],
            [
              357293.08651978016,
              5007429.5639618253
            ],
            [
              357255.53098901658,
              5007400.9517334625
            ],
            [
              357239.77017541236,
              5007403.5013129869
            ],
            [
              357197.53092364495,
              5007402.0670050718
            ],
            [
              357163.23374629527,
              5007469.2993594361
            ],
            [
              357150.40329792345,
              5007496.1757644741
            ],
            [
              357135.64084779291,
              5007538.6267449567
            ],
            [
              357107.63524946565,
              5007585.7637938438
            ],
            [
              357083.16347903135,
              5007583.9990560832
            ],
            [
              357074.4448154564,
              5007593.0404736241
            ],
            [
              357087.97687287856,
              5007603.8879368892
            ],
            [
              357069.22647338925,
              5007670.831093722
            ],
            [
              357074.54091124266,
              5007748.9431841047
            ],
            [
              357070.30532270804,
              5007760.1210186323
            ],
            [
              357023.66537162318,
              5007760.9884833805
            ],
            [
              356966.58097271965,
              5007797.56726606
            ],
            [
              356955.93032190669,
              5007822.183361399
            ],
            [
              356943.34753129352,
              5007862.3740449604
            ],
            [
              356912.87326876877,
              5007896.238120554
            ],
            [
              356899.58883610112,
              5007898.7050004564
            ],
            [
              356894.48651131889,
              5007863.2828343399
            ],
            [
              356886.58547075337,
              5007796.8353303475
            ],
            [
              356863.83946579689,
              5007768.4007918173
            ],
            [
              356817.07566873223,
              5007762.6110881101
            ],
            [
              356803.79123139032,
              5007765.077963558
            ],
            [
              356808.89354745095,
              5007800.5001404742
            ],
            [
              356795.81547374447,
              5007814.0622443175
            ],
            [
              356782.94376616419,
              5007838.7196626561
            ],
            [
              356805.64850950876,
              5007864.9351751255
            ],
            [
              356828.27070747752,
              5007886.7126283115
            ],
            [
              356831.15213475685,
              5007922.1761088381
            ],
            [
              356827.28801161895,
              5007953.325376994
            ],
            [
              356812.27796120703,
              5007982.4621625887
            ],
            [
              356785.99800903007,
              5008002.9293865208
            ],
            [
              356775.18211751111,
              5008018.669218557
            ],
            [
              356762.10405276116,
              5008032.2314182026
            ],
            [
              356746.92875706666,
              5008052.4920157129
            ],
            [
              356740.67864665849,
              5008074.806488174
            ],
            [
              356727.43548281986,
              5008079.4924047561
            ],
            [
              356709.66796680336,
              5008079.8228825042
            ],
            [
              356696.09460702335,
              5008066.7564836321
            ],
            [
              356689.30792631552,
              5008060.2232407024
            ],
            [
              356671.58153090911,
              5008062.7727492107
            ],
            [
              356633.9491109334,
              5008070.1321913237
            ],
            [
              356620.78849286499,
              5008079.2562538562
            ],
            [
              356598.90921229252,
              5008097.4217598978
            ],
            [
              356581.59556127782,
              5008122.1618243316
            ],
            [
              356562.18483253155,
              5008153.6002894891
            ],
            [
              356549.31314066437,
              5008178.2577372165
            ],
            [
              356543.80599326076,
              5008240.5151041644
            ],
            [
              356548.66070646583,
              5008262.6229502205
            ],
            [
              356540.64373825648,
              5008309.3882488841
            ],
            [
              356532.94004667638,
              5008358.3158607641
            ],
            [
              356555.70517598878,
              5008377.9179318845
            ],
            [
              356576.8320126633,
              5008372.8130898308
            ],
            [
              356599.66285084415,
              5008395.9478100929
            ],
            [
              356582.90421043843,
              5008445.7343063364
            ],
            [
              356567.52132853813,
              5008506.0969284866
            ],
            [
              356553.09802808386,
              5008554.6620931681
            ],
            [
              356530.18363245716,
              5008590.4274363201
            ],
            [
              356494.26127696311,
              5008624.0788658792
            ],
            [
              356401.07968285034,
              5008743.7383556068
            ],
            [
              356356.95974793518,
              5008780.3694255548
            ],
            [
              356294.89219982666,
              5008815.5438014939
            ],
            [
              356265.17036600743,
              5008855.4881062983
            ],
            [
              356217.6674013767,
              5008902.9252122818
            ],
            [
              356201.64445072151,
              5008908.5949031655
            ],
            [
              356177.98958613526,
              5008889.3391894186
            ],
            [
              356193.21342719422,
              5008840.7120631421
            ],
            [
              356221.34356759913,
              5008811.5404348252
            ],
            [
              356258.29775956372,
              5008775.0426447717
            ],
            [
              356273.88801720954,
              5008746.1043811385
            ],
            [
              356264.2648694563,
              5008710.4730329448
            ],
            [
              356197.77563392831,
              5008604.2787665436
            ],
            [
              356183.27764174255,
              5008595.5958613176
            ],
            [
              356137.07430759404,
              5008583.8149023671
            ],
            [
              356104.41905797756,
              5008582.827022111
            ],
            [
              356111.83545794961,
              5008594.0273909084
            ],
            [
              356110.62078041001,
              5008594.4825876523
            ],
            [
              356082.60035814048,
              5008605.0517048556
            ],
            [
              356072.17001734982,
              5008608.8720630175
            ],
            [
              356075.82659738307,
              5008615.1052475842
            ],
            [
              356076.16278176225,
              5008623.9749357561
            ],
            [
              356062.74706293625,
              5008627.7648967281
            ],
            [
              356049.43742173974,
              5008627.3603694495
            ],
            [
              356032.71708182612,
              5008627.7463564193
            ],
            [
              356014.86227640416,
              5008658.3355618538
            ],
            [
              356030.55384105939,
              5008688.2866559159
            ],
            [
              356041.18677676545,
              5008717.491843909
            ],
            [
              356034.1220846558,
              5008744.5059720995
            ],
            [
              356010.8377323561,
              5008758.3804652328
            ],
            [
              355984.45363443112,
              5008741.2294560727
            ],
            [
              355968.84017105924,
              5008715.477272572
            ],
            [
              355939.71898836974,
              5008731.9806140782
            ],
            [
              355927.16115274216,
              5008780.0990212783
            ],
            [
              355917.55925921135,
              5008806.3202669621
            ],
            [
              355900.40894237644,
              5008882.7178084822
            ],
            [
              355907.87303639366,
              5008915.1405902589
            ],
            [
              355864.97487775725,
              5008914.2248759195
            ],
            [
              355814.89944007824,
              5008896.3048621714
            ],
            [
              355806.72410054749,
              5008887.4415096864
            ],
            [
              355730.56663030019,
              5008886.9586214107
            ],
            [
              355693.10731066187,
              5008911.88847108
            ],
            [
              355672.38765573088,
              5008932.3184639579
            ],
            [
              355661.93294643174,
              5008943.5447866367
            ],
            [
              355649.77830430126,
              5008956.5965071972
            ],
            [
              355630.1292974258,
              5008984.7722195359
            ],
            [
              355544.71536842262,
              5009046.3864667164
            ],
            [
              355515.25737757556,
              5009065.2833268801
            ],
            [
              355507.01126260572,
              5009088.2417483367
            ],
            [
              355503.31981713593,
              5009110.6593877384
            ],
            [
              355496.74636159436,
              5009141.4593713228
            ],
            [
              355495.53486524767,
              5009202.7816763073
            ],
            [
              355591.99597279209,
              5009292.1956708431
            ],
            [
              355682.66404163546,
              5009258.9367236486
            ],
            [
              355731.57294684846,
              5009186.6925998079
            ],
            [
              355708.49178889341,
              5009078.3733771658
            ],
            [
              355765.36631603475,
              5009115.9035431892
            ],
            [
              355866.69422023615,
              5009089.462372872
            ],
            [
              355928.23978276993,
              5009000.6170385964
            ],
            [
              355932.33670746861,
              5009032.1129916646
            ],
            [
              356020.14728233317,
              5009033.9874697179
            ],
            [
              356034.44740802929,
              5009047.7534779096
            ],
            [
              356116.39143188187,
              5009052.0283815702
            ],
            [
              356116.39143188059,
              5009052.0283815702
            ],
            [
              356160.4246429542,
              5009073.9508216567
            ],
            [
              356188.51364284364,
              5009054.4769749185
            ],
            [
              356191.43171974964,
              5009067.5919799041
            ],
            [
              356230.3335127185,
              5009059.3843761496
            ],
            [
              356245.62291875575,
              5009075.3971342435
            ],
            [
              356271.56160312652,
              5009148.582886002
            ],
            [
              356268.96514274774,
              5009197.7433531797
            ],
            [
              356330.5889228133,
              5009301.8373518102
            ],
            [
              356380.70489877282,
              5009353.5251644347
            ],
            [
              356439.00146637199,
              5009278.7723717317
            ],
            [
              356494.17983306217,
              5009225.1257197233
            ],
            [
              356618.31461875263,
              5009103.544057807
            ],
            [
              356624.35564235674,
              5009050.8115604315
            ],
            [
              356581.38994939573,
              5009006.0067310948
            ],
            [
              356514.44269950385,
              5008993.2200393137
            ],
            [
              356568.70780465408,
              5008890.4783129636
            ],
            [
              356563.04512566264,
              5008774.8193098372
            ],
            [
              356637.06437155668,
              5008601.5499622105
            ],
            [
              356678.72536065645,
              5008576.2189044477
            ],
            [
              356719.73418550013,
              5008515.8199748984
            ],
            [
              356785.70275950077,
              5008476.0047798874
            ],
            [
              356861.03563423658,
              5008357.0923251109
            ],
            [
              356927.33049825724,
              5008334.8111875625
            ],
            [
              356975.22829678614,
              5008267.2681082934
            ],
            [
              357024.03944917879,
              5008248.8202002775
            ],
            [
              357165.72330856993,
              5008126.9126631459
            ],
            [
              357248.32773718395,
              5008037.6761422157
            ],
            [
              357285.76142860588,
              5007973.8357927362
            ],
            [
              357158.36518549739,
              5007920.0773094501
            ],
            [
              357143.54335301439,
              5007878.2569460347
            ],
            [
              357213.8045476377,
              5007880.4581175484
            ],
            [
              357238.04690422275,
              5007862.4671573807
            ],
            [
              357370.24519686628,
              5007796.8643581746
            ],
            [
              357389.67330292705,
              5007708.8029878512
            ],
            [
              357448.0354323581,
              5007637.5574697508
            ],
            [
              357433.08305231616,
              5007588.7235710816
            ],
            [
              357381.54495639092,
              5007649.3180960836
            ],
            [
              357363.47412208369,
              5007621.5902391365
            ],
            [
              357376.53507306782,
              5007568.7272379324
            ],
            [
              357361.5826996378,
              5007519.8933260953
            ],
            [
              357375.33014802326,
              5007512.3862796165
            ],
            [
              357361.58269963675,
              5007519.8933260925
            ],
            [
              357675.52591195743,
              5007597.7446638765
            ],
            [
              357691.46217327652,
              5007552.7586433981
            ],
            [
              357685.40575883671,
              5007527.7332984805
            ],
            [
              357720.95577007742,
              5007485.1755411802
            ],
            [
              357767.94351895753,
              5007456.370624559
            ],
            [
              357798.99484033237,
              5007472.5517172143
            ],
            [
              357839.5203975875,
              5007547.2118460182
            ],
            [
              357881.28258843283,
              5007538.0558349555
            ],
            [
              357887.63558308763,
              5007554.3451757291
            ],
            [
              357930.03960731352,
              5007546.1135000624
            ],
            [
              357968.27317710407,
              5007537.2158468356
            ],
            [
              358013.83236306644,
              5007531.6314024292
            ],
            [
              358060.8774560967,
              5007521.4210664006
            ],
            [
              358164.41061239492,
              5007503.1514123911
            ],
            [
              358246.91801305034,
              5007489.5029459577
            ],
            [
              358275.88778956252,
              5007480.6567776892
            ],
            [
              358286.81118594127,
              5007449.7352312384
            ],
            [
              358342.67651538254,
              5007426.1849635849
            ],
            [
              358474.20441639522,
              5007383.0534840487
            ],
            [
              358508.98685557063,
              5007413.4960192004
            ],
            [
              358547.62023903552,
              5007434.1704866271
            ],
            [
              358567.0611526522,
              5007387.5109825283
            ],
            [
              358573.59255558433,
              5007341.6452588867
            ],
            [
              358656.57134283561,
              5007356.3338963958
            ],
            [
              358672.69787168538,
              5007336.0008966383
            ],
            [
              358673.90107429342,
              5007297.6815132275
            ],
            [
              358687.19241184107,
              5007259.4480930222
            ],
            [
              358694.26174446446,
              5007209.3347358126
            ],
            [
              358691.40722633159,
              5007163.4044718491
            ],
            [
              358681.23777035385,
              5007149.9896184895
            ],
            [
              358691.93780761346,
              5007152.4490737319
            ],
            [
              358637.9620405428,
              5007138.2056173459
            ],
            [
              358593.45857804577,
              5007087.2306735106
            ],
            [
              358554.84745082154,
              5007045.718891331
            ],
            [
              358512.99917817221,
              5007002.3885213006
            ],
            [
              358496.98964926036,
              5006976.1357827382
            ],
            [
              358502.8913485689,
              5006968.8082491066
            ],
            [
              358494.83678733435,
              5006949.1714274827
            ],
            [
              358489.23534304969,
              5006932.3602331318
            ],
            [
              358473.58652249753,
              5006887.1912608687
            ],
            [
              358465.53609750659,
              5006852.4530770332
            ],
            [
              358454.1759114287,
              5006810.3759519644
            ],
            [
              358439.58450078074,
              5006765.1872509671
            ],
            [
              358423.17310194566,
              5006735.8905916484
            ],
            [
              358366.9291047111,
              5006669.8370031836
            ],
            [
              358349.65684361645,
              5006651.1284410655
            ],
            [
              358293.41386496834,
              5006584.5128960395
            ],
            [
              358280.98758973466,
              5006562.8238005238
            ],
            [
              358265.51172272972,
              5006570.1715778718
            ],
            [
              358237.34188205074,
              5006524.6318697101
            ],
            [
              358211.3654386049,
              5006507.5577253997
            ],
            [
              358208.37273310887,
              5006505.3292630175
            ],
            [
              358205.67983386072,
              5006503.8565368121
            ],
            [
              358181.80694195151,
              5006489.7389727486
            ],
            [
              358159.60060547356,
              5006470.676089569
            ],
            [
              358139.89361099672,
              5006450.199742049
            ],
            [
              358126.70087752992,
              5006431.8863714142
            ],
            [
              358119.10218192561,
              5006407.7719760528
            ],
            [
              358124.73305629747,
              5006376.7927775616
            ],
            [
              358141.10553566972,
              5006318.1868824642
            ],
            [
              358153.15107897326,
              5006271.9777858527
            ],
            [
              358155.9076465363,
              5006238.5460582962
            ],
            [
              358116.11917461274,
              5006245.708849851
            ],
            [
              358071.79372395616,
              5006260.1315927859
            ],
            [
              358038.15691357624,
              5006288.3886276288
            ],
            [
              358020.08730682969,
              5006278.9722768329
            ],
            [
              357966.92976682523,
              5006289.2027949654
            ],
            [
              357949.4727006391,
              5006294.9858910926
            ],
            [
              357952.3607342139,
              5006341.030593032
            ],
            [
              357956.99811463372,
              5006370.3148517907
            ],
            [
              357971.29233999964,
              5006433.2211250691
            ],
            [
              357941.90427368175,
              5006433.76760522
            ],
            [
              357918.15267114114,
              5006437.5685185865
            ],
            [
              357899.88457662315,
              5006447.0377955241
            ],
            [
              357899.11442092509,
              5006460.0392939914
            ],
            [
              357898.37317906442,
              5006455.8534484729
            ],
            [
              357904.24990550714,
              5006488.2152287113
            ],
            [
              357887.14460160065,
              5006493.0083362712
            ],
            [
              357914.11664644949,
              5006526.0038404558
            ],
            [
              357924.15244137408,
              5006575.8710794225
            ],
            [
              357942.13426263578,
              5006624.4530175747
            ],
            [
              357982.94300959544,
              5006676.0231512506
            ],
            [
              357982.60844208178,
              5006719.2576723201
            ],
            [
              357977.59433033748,
              5006755.7537489673
            ],
            [
              357944.69309034926,
              5006762.0535210352
            ],
            [
              357884.93729487178,
              5006732.4499320742
            ],
            [
              357862.89857153856,
              5006847.7626211904
            ],
            [
              357863.48986778565,
              5006879.5586224468
            ],
            [
              357892.71941430215,
              5007024.7969243862
            ],
            [
              357905.39681089204,
              5007135.8855542848
            ],
            [
              357913.99321589473,
              5007170.1832453255
            ],
            [
              357890.61856940395,
              5007197.1237260569
            ],
            [
              357854.82186501648,
              5007269.3551601255
            ],
            [
              357815.63416471775,
              5007301.8909651432
            ],
            [
              357736.07607986807,
              5007303.370573503
            ],
            [
              357690.40187159705,
              5007272.4130013222
            ],
            [
              357631.22148625186,
              5007228.4537513535
            ],
            [
              357612.4116379788,
              5007215.5506300479
            ],
            [
              357589.48032368853,
              5007266.3382027866
            ],
            [
              357584.52144402935,
              5007284.9845094811
            ],
            [
              357625.43390365021,
              5007345.1870112531
            ],
            [
              357620.6721421986,
              5007374.4319338547
            ],
            [
              357618.75933581038,
              5007414.2262409981
            ],
            [
              357616.99452420836,
              5007461.9695945829
            ],
            [
              357582.76568360144,
              5007475.8591340482
            ],
            [
              357508.90560764837,
              5007498.4374678591
            ],
            [
              357477.27946564672,
              5007509.6279509878
            ],
            [
              357418.88748534973,
              5007508.0633787923
            ],
            [
              357399.24276037351,
              5007505.2166437041
            ],
            [
              357387.01493788295,
              5007506.0055859871
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 70,
        "Layer" : "B02210A-SUME_01",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355073.26813802082,
              5009729.3207729245
            ],
            [
              355073.26813802082,
              5009729.3207729245
            ],
            [
              355092.57851187984,
              5009712.8443387002
            ],
            [
              355145.11441087944,
              5009689.250915667
            ],
            [
              355178.41117131931,
              5009638.8792066285
            ],
            [
              355235.08155814843,
              5009573.7865732843
            ],
            [
              355264.65973957622,
              5009569.6447885223
            ],
            [
              355272.1200246003,
              5009517.2609126056
            ],
            [
              355246.66312183923,
              5009464.3843197431
            ],
            [
              355251.23249992594,
              5009430.9553921428
            ],
            [
              355257.47743387037,
              5009407.869019974
            ],
            [
              355217.79985009943,
              5009387.8598985132
            ],
            [
              355186.07328368566,
              5009396.6008687457
            ],
            [
              355173.3903364322,
              5009432.4035723377
            ],
            [
              355150.06671948073,
              5009454.3257725071
            ],
            [
              355121.36616370745,
              5009471.1495643808
            ],
            [
              355140.48889719567,
              5009489.3212433299
            ],
            [
              355125.24862563924,
              5009495.6718019638
            ],
            [
              355108.1806998456,
              5009483.5524639273
            ],
            [
              355093.19463509432,
              5009489.4787619654
            ],
            [
              355086.15584115859,
              5009488.4872598778
            ],
            [
              355080.6285276842,
              5009475.3621462053
            ],
            [
              355072.37398419279,
              5009490.0380696282
            ],
            [
              355067.0131265815,
              5009534.4151957901
            ],
            [
              355046.07904723258,
              5009573.4562785896
            ],
            [
              355056.86840584636,
              5009600.4814770427
            ],
            [
              355040.32087957556,
              5009637.3802360073
            ],
            [
              355066.74340485566,
              5009668.0985949393
            ],
            [
              355073.26813802082,
              5009729.3207729245
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 71,
        "Layer" : "B02210A-SUME_01",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354093.03477872087,
              5010374.8647259045
            ],
            [
              354054.53888843581,
              5010418.7660885537
            ],
            [
              354074.592064408,
              5010444.6665085964
            ],
            [
              354061.9615908438,
              5010455.1565977205
            ],
            [
              354061.40820954827,
              5010466.6488652155
            ],
            [
              354066.07240170246,
              5010473.8077046294
            ],
            [
              354066.31487658789,
              5010486.8389993384
            ],
            [
              354059.967118398,
              5010486.6012488892
            ],
            [
              354043.49285257637,
              5010477.8506855639
            ],
            [
              354030.74318660243,
              5010474.4650790123
            ],
            [
              354020.12532972667,
              5010488.2482735096
            ],
            [
              354029.41994538059,
              5010500.7552710036
            ],
            [
              354027.69245904166,
              5010505.31593855
            ],
            [
              354012.55306586443,
              5010519.6027614372
            ],
            [
              354015.20749435719,
              5010536.0069334842
            ],
            [
              354039.14179481188,
              5010553.3616689267
            ],
            [
              354058.55236219341,
              5010552.6250543538
            ],
            [
              354077.37035971845,
              5010569.8903672174
            ],
            [
              354107.31522146694,
              5010576.626886785
            ],
            [
              354104.84300115192,
              5010588.7615995966
            ],
            [
              354095.71371387603,
              5010602.9674550444
            ],
            [
              354089.63607775356,
              5010622.8137238389
            ],
            [
              354104.86472338001,
              5010635.1583077488
            ],
            [
              354089.87739934155,
              5010652.5406975467
            ],
            [
              354088.04572981567,
              5010665.8429503823
            ],
            [
              354079.57022087876,
              5010676.4391878741
            ],
            [
              354059.45878699532,
              5010679.8473393647
            ],
            [
              354049.27580985782,
              5010672.2496767752
            ],
            [
              354038.71932937071,
              5010688.1947803637
            ],
            [
              354026.41631599289,
              5010693.4629163481
            ],
            [
              354020.56356153608,
              5010706.270728277
            ],
            [
              354000.53370913496,
              5010708.6743565053
            ],
            [
              353995.43699443934,
              5010708.7691950835
            ],
            [
              353999.78596941562,
              5010735.244718641
            ],
            [
              353998.4387232975,
              5010748.8810338844
            ],
            [
              353994.8941271379,
              5010759.1859813044
            ],
            [
              353990.1239265537,
              5010766.1839655712
            ],
            [
              353970.32423099241,
              5010777.867168434
            ],
            [
              353950.37032992975,
              5010774.028064793
            ],
            [
              353926.21241446392,
              5010796.6978537478
            ],
            [
              353916.18801980896,
              5010803.239713151
            ],
            [
              353918.0421168062,
              5010816.9842378143
            ],
            [
              353929.9917508653,
              5010836.0150212273
            ],
            [
              353911.82097640377,
              5010843.829040559
            ],
            [
              353905.03585089074,
              5010854.8720509
            ],
            [
              353910.27323836257,
              5010863.941650115
            ],
            [
              353889.96886161616,
              5010865.0746104419
            ],
            [
              353890.08431792882,
              5010871.2791866399
            ],
            [
              353899.53975820792,
              5010886.9849470174
            ],
            [
              353915.17164607998,
              5010887.408262914
            ],
            [
              353925.52251096716,
              5010902.3177871061
            ],
            [
              353927.39786343899,
              5010920.2734181928
            ],
            [
              353983.16463914333,
              5010914.3336598966
            ],
            [
              353983.1912907174,
              5010904.1684021372
            ],
            [
              354048.93335846422,
              5010875.8579609571
            ],
            [
              354070.97490252246,
              5010869.4069539914
            ],
            [
              354088.13126690028,
              5010871.1012846204
            ],
            [
              354076.77769688825,
              5010910.5780368317
            ],
            [
              354065.06836665916,
              5010930.9321202589
            ],
            [
              354098.68909776874,
              5010922.3944294974
            ],
            [
              354109.17037786904,
              5010945.6269201068
            ],
            [
              354140.88768022967,
              5010940.0164940991
            ],
            [
              354152.80509766255,
              5010973.6406965591
            ],
            [
              354162.09942356782,
              5010999.8640131094
            ],
            [
              354206.23497865692,
              5011034.0922967428
            ],
            [
              354214.77928702562,
              5011040.7186317695
            ],
            [
              354251.90244648495,
              5011095.7688357374
            ],
            [
              354322.56065857044,
              5011162.5441248594
            ],
            [
              354319.26215424127,
              5011082.0100772157
            ],
            [
              354283.45928355359,
              5011007.6447120924
            ],
            [
              354267.52058689058,
              5010982.9307699678
            ],
            [
              354234.58352865203,
              5010934.926207345
            ],
            [
              354195.48427194549,
              5010903.8592920462
            ],
            [
              354191.4820850105,
              5010868.792435715
            ],
            [
              354172.27494284709,
              5010871.659954505
            ],
            [
              354153.0677996375,
              5010874.527473622
            ],
            [
              354130.00173255469,
              5010895.0373830711
            ],
            [
              354113.50425387186,
              5010877.7153608697
            ],
            [
              354104.17208147125,
              5010857.0299371853
            ],
            [
              354092.10994488094,
              5010838.8470765781
            ],
            [
              354103.45613679203,
              5010818.5551671321
            ],
            [
              354156.81428833457,
              5010805.8484664727
            ],
            [
              354214.35457345186,
              5010747.8823642423
            ],
            [
              354221.82650244597,
              5010744.3965322422
            ],
            [
              354229.29843131447,
              5010740.9107003929
            ],
            [
              354251.37490780122,
              5010757.2338350592
            ],
            [
              354306.65630575741,
              5010757.878538684
            ],
            [
              354332.66605206527,
              5010715.5597336618
            ],
            [
              354314.70963008353,
              5010650.6314409683
            ],
            [
              354340.90614999295,
              5010618.3495085221
            ],
            [
              354376.22109505901,
              5010626.0593474619
            ],
            [
              354417.29921105388,
              5010583.4600914223
            ],
            [
              354455.86910714785,
              5010586.0892412709
            ],
            [
              354478.38218837051,
              5010609.8514280505
            ],
            [
              354533.13424199796,
              5010582.0582931787
            ],
            [
              354579.20395249099,
              5010537.692807354
            ],
            [
              354616.90244218119,
              5010493.4831842193
            ],
            [
              354643.62809842773,
              5010489.6390617713
            ],
            [
              354639.93722189026,
              5010471.3004525583
            ],
            [
              354649.29791314475,
              5010434.3116203928
            ],
            [
              354635.15693555056,
              5010394.4132487131
            ],
            [
              354684.11480604758,
              5010415.2564134719
            ],
            [
              354708.82383033435,
              5010393.042538953
            ],
            [
              354739.7318205068,
              5010343.9390907809
            ],
            [
              354733.49519747961,
              5010278.792792025
            ],
            [
              354763.72511377576,
              5010283.2505245199
            ],
            [
              354813.92144865758,
              5010280.6431714082
            ],
            [
              354862.21900004958,
              5010175.994197676
            ],
            [
              354892.07540673821,
              5010160.3781558666
            ],
            [
              354940.17264030146,
              5010106.391556697
            ],
            [
              354865.63416237949,
              5010060.9234651439
            ],
            [
              354834.18192503508,
              5010083.2123173308
            ],
            [
              354750.372224778,
              5009999.8880167073
            ],
            [
              354782.66000261862,
              5009946.6845600456
            ],
            [
              354819.91024986288,
              5009880.302098576
            ],
            [
              354856.09383142379,
              5009842.7788099675
            ],
            [
              354871.46816187183,
              5009807.2448245343
            ],
            [
              354902.99173745821,
              5009777.8191009304
            ],
            [
              354870.57484064571,
              5009738.5549327405
            ],
            [
              354855.26517083851,
              5009754.7241182737
            ],
            [
              354841.11617782823,
              5009734.8056605197
            ],
            [
              354826.84638871124,
              5009735.976990263
            ],
            [
              354802.67432866641,
              5009752.0345280506
            ],
            [
              354781.03336619562,
              5009747.941174929
            ],
            [
              354756.57274523057,
              5009734.5116527528
            ],
            [
              354735.32652755466,
              5009729.6687706551
            ],
            [
              354461.00710987032,
              5010002.9535728479
            ],
            [
              354435.72798654303,
              5010029.6483087614
            ],
            [
              354390.33009831025,
              5010073.0561562367
            ],
            [
              354223.94975584518,
              5010238.0843539946
            ],
            [
              354093.03477847291,
              5010374.8647261625
            ],
            [
              354093.03477845719,
              5010374.8647261756
            ],
            [
              354093.03477872087,
              5010374.8647259045
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 72,
        "Layer" : "B02210A-SUME_01",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354122.73119024787,
              5011337.4058185294
            ],
            [
              354135.00447093142,
              5011276.9350889185
            ],
            [
              354156.42724647443,
              5011258.129110829
            ],
            [
              354196.57818373822,
              5011255.7085506339
            ],
            [
              354224.69794007781,
              5011236.7778646983
            ],
            [
              354164.0134747469,
              5011125.7896551425
            ],
            [
              354161.74777083151,
              5011094.0373226982
            ],
            [
              354096.84995001339,
              5011026.6357823526
            ],
            [
              354048.17751942953,
              5010989.4626385802
            ],
            [
              353988.99667081865,
              5010997.7498689005
            ],
            [
              353987.90745039895,
              5010971.4216274349
            ],
            [
              354029.01393309206,
              5010958.0812284369
            ],
            [
              354042.05729464459,
              5010939.4147535609
            ],
            [
              354007.87565915758,
              5010943.0712476075
            ],
            [
              353972.05462296342,
              5010966.8943914715
            ],
            [
              353937.29675692372,
              5010993.7181833005
            ],
            [
              353903.63952025207,
              5011025.5555633213
            ],
            [
              353896.47816698841,
              5011019.6479718164
            ],
            [
              353882.09927368019,
              5011004.8134059794
            ],
            [
              353870.24011074979,
              5011017.115799984
            ],
            [
              353843.77730588557,
              5011002.5060980804
            ],
            [
              353838.70483987028,
              5011000.5869077379
            ],
            [
              353828.38516796386,
              5010988.8555983016
            ],
            [
              353836.95966176526,
              5010979.0165793346
            ],
            [
              353824.00155243609,
              5010973.5047343709
            ],
            [
              353824.886729013,
              5010971.4237226881
            ],
            [
              353824.00155243609,
              5010973.5047343709
            ],
            [
              353814.40860956098,
              5010987.5194904516
            ],
            [
              353804.15893908654,
              5010994.4154491685
            ],
            [
              353804.05056959559,
              5011004.43221201
            ],
            [
              353788.21316425182,
              5011029.0282318881
            ],
            [
              353784.31060512562,
              5011034.2012183079
            ],
            [
              353783.23880329868,
              5011035.6219312875
            ],
            [
              353764.40562323725,
              5011060.6267419746
            ],
            [
              353752.98314225808,
              5011068.218046423
            ],
            [
              353763.60033051827,
              5011075.9832092784
            ],
            [
              353740.64081664773,
              5011108.3726422088
            ],
            [
              353728.63851091982,
              5011102.9977397686
            ],
            [
              353712.3046357425,
              5011100.7836351199
            ],
            [
              353701.79759388679,
              5011106.0603322098
            ],
            [
              353686.11363866733,
              5011120.1001050994
            ],
            [
              353673.98477673944,
              5011122.3119119136
            ],
            [
              353676.43481389759,
              5011123.0478306767
            ],
            [
              353687.5088329633,
              5011118.4012317481
            ],
            [
              353688.57963792281,
              5011117.097354563
            ],
            [
              353687.50883296708,
              5011118.4012317387
            ],
            [
              353677.40915861237,
              5011124.0507849045
            ],
            [
              353671.34558270668,
              5011125.0162551524
            ],
            [
              353662.08439384756,
              5011134.73343198
            ],
            [
              353675.09623162175,
              5011151.4163959399
            ],
            [
              353684.96137429716,
              5011173.5271618916
            ],
            [
              353693.4943713565,
              5011176.7709035939
            ],
            [
              353669.8241404352,
              5011187.4068559026
            ],
            [
              353647.97806907346,
              5011190.3750931127
            ],
            [
              353643.7365333598,
              5011208.3495788798
            ],
            [
              353630.05564481398,
              5011208.3323006174
            ],
            [
              353633.7974278045,
              5011221.3470872212
            ],
            [
              353630.68348577025,
              5011218.7920379564
            ],
            [
              353636.91136983025,
              5011223.9021364413
            ],
            [
              353647.05232689151,
              5011238.1117501995
            ],
            [
              353642.34694330714,
              5011255.3658923404
            ],
            [
              353630.97050645604,
              5011262.9694694858
            ],
            [
              353613.44794396398,
              5011266.7606678251
            ],
            [
              353620.09063223115,
              5011272.5255379928
            ],
            [
              353609.72182664572,
              5011287.4748383379
            ],
            [
              353600.21394778526,
              5011284.9857229143
            ],
            [
              353594.3831204946,
              5011288.4972230634
            ],
            [
              353583.92675733729,
              5011291.4611247377
            ],
            [
              353574.75499968097,
              5011303.4498510947
            ],
            [
              353566.53532758023,
              5011313.3715960318
            ],
            [
              353552.54937078361,
              5011325.8721173396
            ],
            [
              353539.63177360658,
              5011327.12357728
            ],
            [
              353525.35478254705,
              5011314.7133963676
            ],
            [
              353521.79937728547,
              5011320.4314007368
            ],
            [
              353553.59627113654,
              5011346.8528334899
            ],
            [
              353556.82702075859,
              5011344.7045431333
            ],
            [
              353567.36616615107,
              5011330.0036664065
            ],
            [
              353570.09796944814,
              5011321.2858757637
            ],
            [
              353591.84219745983,
              5011340.4715572335
            ],
            [
              353599.07975127536,
              5011337.9617726952
            ],
            [
              353612.29818048596,
              5011350.6641367478
            ],
            [
              353578.63397524558,
              5011377.528005382
            ],
            [
              353596.68939088023,
              5011389.8381060278
            ],
            [
              353590.85740301601,
              5011397.0645126635
            ],
            [
              353579.76852953021,
              5011414.7706973506
            ],
            [
              353595.87805119733,
              5011427.0113470163
            ],
            [
              353592.73964636395,
              5011454.4191373754
            ],
            [
              353596.36127031501,
              5011480.250370916
            ],
            [
              353599.0576922773,
              5011495.5817025602
            ],
            [
              353608.7348184741,
              5011485.0337097971
            ],
            [
              353647.53695244394,
              5011478.0305866469
            ],
            [
              353665.67351081007,
              5011462.6324835857
            ],
            [
              353678.69392868032,
              5011442.3093708986
            ],
            [
              353681.48206933966,
              5011412.136322788
            ],
            [
              353677.57321712311,
              5011382.0879045585
            ],
            [
              353699.08955759689,
              5011368.3002897706
            ],
            [
              353782.18633724569,
              5011423.649431238
            ],
            [
              353785.90840614337,
              5011443.6609607087
            ],
            [
              353742.75136645552,
              5011464.5448230226
            ],
            [
              353709.6399681936,
              5011485.2418269049
            ],
            [
              353685.08644845808,
              5011515.8199298801
            ],
            [
              353676.41074427136,
              5011589.6109112781
            ],
            [
              353707.69925346755,
              5011650.9443176668
            ],
            [
              353732.32175851642,
              5011714.0752367517
            ],
            [
              353766.02480573993,
              5011725.1617332855
            ],
            [
              353825.9795368234,
              5011616.9485329119
            ],
            [
              353878.09243613057,
              5011537.3289879262
            ],
            [
              353930.92136424646,
              5011496.1843409734
            ],
            [
              353983.24581505015,
              5011401.9172342019
            ],
            [
              354032.39062375377,
              5011432.7971664229
            ],
            [
              354122.73119024787,
              5011337.4058185294
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 73,
        "Layer" : "B02210A-SUME_01",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353411.56575896934,
              5012478.5823844643
            ],
            [
              353460.14360118989,
              5012403.8886556178
            ],
            [
              353514.41511374101,
              5012319.622208057
            ],
            [
              353541.1684594049,
              5012275.7726741508
            ],
            [
              353565.04617083841,
              5012239.3395147445
            ],
            [
              353531.44053277723,
              5012211.7672230313
            ],
            [
              353485.72698396625,
              5012203.2471075952
            ],
            [
              353420.76632555359,
              5012145.5955860484
            ],
            [
              353456.77795276756,
              5012109.3327063201
            ],
            [
              353465.78156704205,
              5012078.8848325619
            ],
            [
              353480.08398923272,
              5012012.861276892
            ],
            [
              353501.64880448516,
              5012006.4416885907
            ],
            [
              353530.21517082019,
              5011987.855240237
            ],
            [
              353572.91931129718,
              5011952.1546733826
            ],
            [
              353598.69653484278,
              5011913.1581395939
            ],
            [
              353540.19763342768,
              5011876.9337018728
            ],
            [
              353506.43352300144,
              5011875.1547817206
            ],
            [
              353485.04785541358,
              5011891.2002696665
            ],
            [
              353448.47709059098,
              5011932.8050076468
            ],
            [
              353441.58735053887,
              5011950.9879369903
            ],
            [
              353415.27266565739,
              5011961.1069343779
            ],
            [
              353387.52981479518,
              5011959.2159574144
            ],
            [
              353358.44822213036,
              5011950.1279617194
            ],
            [
              353338.50821396004,
              5011914.3895722646
            ],
            [
              353351.37444331247,
              5011893.6880968725
            ],
            [
              353372.73772615514,
              5011876.4394067209
            ],
            [
              353393.80989233643,
              5011843.5486572282
            ],
            [
              353432.23430688615,
              5011836.8153028088
            ],
            [
              353480.91495900269,
              5011798.5962056974
            ],
            [
              353502.92263203656,
              5011751.2442845786
            ],
            [
              353519.71518947446,
              5011747.3207527325
            ],
            [
              353539.74474326038,
              5011787.8720864272
            ],
            [
              353566.75361709,
              5011815.0533613497
            ],
            [
              353613.2546053534,
              5011853.9083245443
            ],
            [
              353671.31334733655,
              5011848.8645618558
            ],
            [
              353684.54515854351,
              5011786.9387362357
            ],
            [
              353678.84711316013,
              5011706.202643482
            ],
            [
              353650.27690063743,
              5011656.1249726424
            ],
            [
              353636.27147267823,
              5011612.0721710613
            ],
            [
              353618.08566811855,
              5011578.4712198768
            ],
            [
              353611.8484164183,
              5011588.2099004267
            ],
            [
              353595.40484547935,
              5011601.3460593512
            ],
            [
              353582.66778173723,
              5011606.7151455721
            ],
            [
              353572.33014135499,
              5011603.0584584838
            ],
            [
              353554.12597297732,
              5011590.5671602534
            ],
            [
              353539.46114946023,
              5011561.3307218924
            ],
            [
              353513.08007657406,
              5011523.3312566532
            ],
            [
              353525.57845633203,
              5011505.13647353
            ],
            [
              353538.00515191996,
              5011483.094005621
            ],
            [
              353523.7223138251,
              5011474.3786892099
            ],
            [
              353536.23918310285,
              5011454.0651802942
            ],
            [
              353523.88815088623,
              5011460.1152018923
            ],
            [
              353521.22508006002,
              5011448.8602790311
            ],
            [
              353497.9756578558,
              5011424.8732098285
            ],
            [
              353489.70035601285,
              5011421.83782632
            ],
            [
              353487.11049166304,
              5011424.7464761827
            ],
            [
              353483.91504410701,
              5011428.3352503488
            ],
            [
              353477.93510570616,
              5011436.013031058
            ],
            [
              353470.98825742054,
              5011447.1785871228
            ],
            [
              353461.32145657303,
              5011447.5486408211
            ],
            [
              353445.98734927078,
              5011460.4023940442
            ],
            [
              353432.36656916584,
              5011456.1343868058
            ],
            [
              353351.25952678773,
              5011616.2421781998
            ],
            [
              353262.73458586488,
              5011718.9696061779
            ],
            [
              353174.93739672232,
              5011836.5617409507
            ],
            [
              353071.33880131837,
              5011922.9957261076
            ],
            [
              352998.5385226261,
              5012001.6427228833
            ],
            [
              352980.40073078172,
              5012024.6526697101
            ],
            [
              352955.12215664296,
              5012051.9177006474
            ],
            [
              352934.59622897289,
              5012057.4525126629
            ],
            [
              352924.72633001534,
              5012081.3391098948
            ],
            [
              352914.95230385714,
              5012110.3768002121
            ],
            [
              352888.39337799174,
              5012124.2683489369
            ],
            [
              352851.75348914351,
              5012150.7143275654
            ],
            [
              352855.6137290459,
              5012191.8649790259
            ],
            [
              352797.53202230489,
              5012259.859848585
            ],
            [
              352731.92049596069,
              5012318.5348364031
            ],
            [
              352675.54914000217,
              5012379.3359943768
            ],
            [
              352640.69054996711,
              5012357.9582002796
            ],
            [
              352626.02301735437,
              5012324.160423737
            ],
            [
              352591.66760553926,
              5012356.5634058695
            ],
            [
              352549.85771616496,
              5012397.2202169085
            ],
            [
              352485.46279504272,
              5012465.7065642038
            ],
            [
              352436.74083982693,
              5012496.4730701456
            ],
            [
              352417.92274391773,
              5012483.8766804617
            ],
            [
              352386.45847492543,
              5012541.9943243666
            ],
            [
              352378.25763229246,
              5012572.1636306774
            ],
            [
              352355.27347198915,
              5012615.1151565127
            ],
            [
              352339.44825829059,
              5012639.1729433062
            ],
            [
              352296.6788691901,
              5012690.8947657496
            ],
            [
              352303.06441774248,
              5012757.7291386509
            ],
            [
              352293.49667811493,
              5012777.9969709953
            ],
            [
              352339.60070300516,
              5012809.4025782831
            ],
            [
              352370.1598042213,
              5012771.567841447
            ],
            [
              352548.51537338004,
              5012947.8245530576
            ],
            [
              352698.97261297802,
              5013049.568436875
            ],
            [
              352744.82193404966,
              5013080.7286051111
            ],
            [
              352759.71339221677,
              5013090.8853610018
            ],
            [
              352792.46202644304,
              5013136.2846737383
            ],
            [
              352849.23285090952,
              5013221.345704603
            ],
            [
              352867.71534484328,
              5013247.2438467108
            ],
            [
              352890.46376084659,
              5013220.2211367153
            ],
            [
              352968.07835131674,
              5013099.2179562086
            ],
            [
              352985.18396542821,
              5013043.3583112722
            ],
            [
              353069.67943917966,
              5012945.1660594605
            ],
            [
              353177.26006847032,
              5012813.7453825762
            ],
            [
              353234.57469842245,
              5012731.2474510865
            ],
            [
              353258.12164458376,
              5012697.354295888
            ],
            [
              353278.14735097805,
              5012669.0078301765
            ],
            [
              353299.44086611597,
              5012638.8667816808
            ],
            [
              353335.70995228953,
              5012587.5277705928
            ],
            [
              353368.41348944505,
              5012541.2358198715
            ],
            [
              353411.56575896934,
              5012478.5823844643
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 74,
        "Layer" : "B02210A-SUME_01",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352474.86303193675,
              5011120.1764379591
            ],
            [
              352461.48352216539,
              5011141.9451740813
            ],
            [
              352434.82472878933,
              5011151.5977531495
            ],
            [
              352400.00117199356,
              5011172.0085805841
            ],
            [
              352415.99459861941,
              5011178.0081771426
            ],
            [
              352448.04181937425,
              5011193.2514302647
            ],
            [
              352458.561886426,
              5011210.2873967905
            ],
            [
              352456.44163200253,
              5011217.91250089
            ],
            [
              352489.08334275539,
              5011231.865269403
            ],
            [
              352512.20193575037,
              5011230.4364949986
            ],
            [
              352512.76093367656,
              5011253.960122318
            ],
            [
              352528.50398836849,
              5011256.0329845371
            ],
            [
              352645.29064695159,
              5011272.403929024
            ],
            [
              352670.69518423307,
              5011273.4365786333
            ],
            [
              352668.5147761759,
              5011294.9834265932
            ],
            [
              352701.24929502618,
              5011297.2777196737
            ],
            [
              352709.61880789354,
              5011276.612340196
            ],
            [
              352787.14571346162,
              5011289.8165296493
            ],
            [
              352828.00492881745,
              5011312.7578118267
            ],
            [
              352809.7635908302,
              5011212.0099590002
            ],
            [
              352895.33783422125,
              5011173.253008645
            ],
            [
              352937.61334757385,
              5011126.3824380236
            ],
            [
              352955.10183251515,
              5011106.7314700382
            ],
            [
              352993.38546666835,
              5011085.2069505733
            ],
            [
              353043.15294775396,
              5011041.1701447582
            ],
            [
              353091.55938879558,
              5011026.2836066531
            ],
            [
              353112.58920487744,
              5011000.9727450246
            ],
            [
              353105.99565852113,
              5010970.1608692873
            ],
            [
              353105.14847488859,
              5010924.6341477782
            ],
            [
              353117.67340502626,
              5010858.235459988
            ],
            [
              353108.51654247683,
              5010828.330610319
            ],
            [
              353113.54361668677,
              5010774.9609593973
            ],
            [
              353100.50379330746,
              5010767.4698964013
            ],
            [
              353064.23263379704,
              5010762.0989700044
            ],
            [
              353035.07353545987,
              5010812.8548442656
            ],
            [
              353028.6125998444,
              5010814.1548188282
            ],
            [
              352970.17170972348,
              5010815.2422932265
            ],
            [
              352935.88139332831,
              5010814.2983887484
            ],
            [
              352841.86186482181,
              5010819.0810986068
            ],
            [
              352832.02428203984,
              5010779.8331089169
            ],
            [
              352810.66858476616,
              5010774.1641273201
            ],
            [
              352794.11876050575,
              5010782.0550474804
            ],
            [
              352771.27379510488,
              5010777.9303699937
            ],
            [
              352764.80939259258,
              5010756.8185391221
            ],
            [
              352740.22141653561,
              5010740.5937261907
            ],
            [
              352693.25568230823,
              5010766.2995527433
            ],
            [
              352695.37713542342,
              5010789.0364364069
            ],
            [
              352662.23574777966,
              5010835.9118754361
            ],
            [
              352631.48652621248,
              5010885.1323656784
            ],
            [
              352701.49664477888,
              5010910.1825824007
            ],
            [
              352651.14862971316,
              5010957.0797128351
            ],
            [
              352650.93465850444,
              5010976.4156227354
            ],
            [
              352647.10061080602,
              5010982.6424678089
            ],
            [
              352644.78380648961,
              5011000.1224641167
            ],
            [
              352667.73997264676,
              5011009.4092906527
            ],
            [
              352659.68839882856,
              5011041.1854887428
            ],
            [
              352653.70145244006,
              5011069.3205645839
            ],
            [
              352630.43994766899,
              5011087.9303268585
            ],
            [
              352625.12421933067,
              5011080.9325732905
            ],
            [
              352606.62375978392,
              5011076.586134213
            ],
            [
              352577.19663380849,
              5011062.5186824305
            ],
            [
              352535.84165362414,
              5011075.1675079642
            ],
            [
              352531.40636873565,
              5011077.0749467043
            ],
            [
              352525.68780682952,
              5011079.5342716435
            ],
            [
              352511.899317995,
              5011085.4641498448
            ],
            [
              352511.899317995,
              5011085.4641498448
            ],
            [
              352488.47667456843,
              5011098.694470902
            ],
            [
              352474.86303193675,
              5011120.1764379591
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 75,
        "Layer" : "B02210A-SUME_01",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351845.8851273515,
              5011172.2453314364
            ],
            [
              351871.59805966058,
              5011174.4202967128
            ],
            [
              351894.86983200669,
              5011188.139136604
            ],
            [
              351924.1388920325,
              5011191.1324393358
            ],
            [
              351959.94822237315,
              5011212.5784297753
            ],
            [
              352004.0146444643,
              5011225.2014868427
            ],
            [
              352008.96292639244,
              5011207.9272988886
            ],
            [
              352051.17960183264,
              5011223.1340205893
            ],
            [
              352044.93233096943,
              5011165.003255357
            ],
            [
              352046.14288571244,
              5011134.9078931343
            ],
            [
              352054.51506366587,
              5011109.1017515846
            ],
            [
              352064.0520283464,
              5011098.3103525173
            ],
            [
              352075.27671300067,
              5011083.0650568632
            ],
            [
              352079.43819870957,
              5011068.8357156152
            ],
            [
              352075.71738155052,
              5011059.1755455928
            ],
            [
              352092.11758471391,
              5011037.5933395457
            ],
            [
              352074.56215214363,
              5011023.2683513146
            ],
            [
              352070.55215623783,
              5011030.8870220082
            ],
            [
              352054.38986105664,
              5011013.1345229261
            ],
            [
              352043.26862980291,
              5010997.1151665468
            ],
            [
              352033.65384607011,
              5010976.2184058847
            ],
            [
              352030.06675540353,
              5010954.3275394076
            ],
            [
              352006.62327186856,
              5010954.3375906115
            ],
            [
              351987.71577844088,
              5010959.9689123873
            ],
            [
              351968.16636941605,
              5010966.8885885216
            ],
            [
              351957.19781763823,
              5010970.7741658837
            ],
            [
              351930.61441742477,
              5010994.323826842
            ],
            [
              351935.5173503331,
              5011041.441074769
            ],
            [
              351913.88737582852,
              5011068.378451705
            ],
            [
              351930.11254448345,
              5011083.9974579904
            ],
            [
              351932.39245044009,
              5011111.3743289746
            ],
            [
              351918.46364352503,
              5011124.0164918825
            ],
            [
              351886.98408053728,
              5011144.9456427367
            ],
            [
              351845.8851273515,
              5011172.2453314364
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 76,
        "Layer" : "B03---BLOKOVI",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352389.64310281462,
              5010838.5421401355
            ],
            [
              352389.72843745322,
              5010839.3451395743
            ],
            [
              352389.9519150625,
              5010840.1211214438
            ],
            [
              352390.30674538732,
              5010840.8465079255
            ],
            [
              352390.78214708721,
              5010841.4992585136
            ],
            [
              352391.3636753207,
              5010842.0595397176
            ],
            [
              352392.03366064565,
              5010842.5103276763
            ],
            [
              352392.77174589678,
              5010842.8379254192
            ],
            [
              352393.55550472805,
              5010843.0323790656
            ],
            [
              352394.36112302018,
              5010843.0877802307
            ],
            [
              352395.16412247065,
              5010843.002445587
            ],
            [
              352395.94010434794,
              5010842.7789679794
            ],
            [
              352396.66549083526,
              5010842.4241376668
            ],
            [
              352397.31824143173,
              5010841.9487359878
            ],
            [
              352397.87852264289,
              5010841.3672077814
            ],
            [
              352398.32931060775,
              5010840.6972224889
            ],
            [
              352398.65690836409,
              5010839.9591372749
            ],
            [
              352398.85136201902,
              5010839.1753784809
            ],
            [
              352398.9067631978,
              5010838.3697602246
            ],
            [
              352398.82142856391,
              5010837.5667608064
            ],
            [
              352398.59795096627,
              5010836.7907789536
            ],
            [
              352398.24312065839,
              5010836.0653924868
            ],
            [
              352397.76771897945,
              5010835.4126419034
            ],
            [
              352397.18619076855,
              5010834.8523607003
            ],
            [
              352396.51620546222,
              5010834.4015727341
            ],
            [
              352395.77812022704,
              5010834.0739749735
            ],
            [
              352394.99436140712,
              5010833.8795213122
            ],
            [
              352394.18874311715,
              5010833.8241201211
            ],
            [
              352393.38574366114,
              5010833.9094547452
            ],
            [
              352392.60976177297,
              5010834.1329323351
            ],
            [
              352391.88437526854,
              5010834.4877626365
            ],
            [
              352391.23162465094,
              5010834.9631643053
            ],
            [
              352390.67134341859,
              5010835.5446925145
            ],
            [
              352390.22055543307,
              5010836.2146778144
            ],
            [
              352389.89295766124,
              5010836.9527630452
            ],
            [
              352389.69850399572,
              5010837.736521855
            ],
            [
              352389.64310281462,
              5010838.5421401355
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 77,
        "Layer" : "B03---BLOKOVI",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351702.47911736963,
              5011498.0507575003
            ],
            [
              351702.52737432532,
              5011498.5048474157
            ],
            [
              351702.6537500397,
              5011498.9436589535
            ],
            [
              351702.85440464813,
              5011499.3538590427
            ],
            [
              351703.1232413642,
              5011499.7229839666
            ],
            [
              351703.45209171838,
              5011500.0398180485
            ],
            [
              351703.83096375811,
              5011500.2947344454
            ],
            [
              351704.24834564916,
              5011500.4799876474
            ],
            [
              351704.6915554548,
              5011500.5899488386
            ],
            [
              351705.14712646668,
              5011500.6212768992
            ],
            [
              351705.60121639114,
              5011500.5730199395
            ],
            [
              351706.04002793715,
              5011500.4466442289
            ],
            [
              351706.45022803609,
              5011500.2459896263
            ],
            [
              351706.81935296574,
              5011499.9771529175
            ],
            [
              351707.13618705288,
              5011499.6483025774
            ],
            [
              351707.3911034562,
              5011499.2694305535
            ],
            [
              351707.57635666576,
              5011498.8520486783
            ],
            [
              351707.68631786195,
              5011498.4088388924
            ],
            [
              351707.71764592599,
              5011497.9532678965
            ],
            [
              351707.66938897106,
              5011497.4991779877
            ],
            [
              351707.54301326018,
              5011497.0603664573
            ],
            [
              351707.34235865698,
              5011496.6501663718
            ],
            [
              351707.07352194824,
              5011496.2810414489
            ],
            [
              351706.74467160075,
              5011495.9642073689
            ],
            [
              351706.3657995676,
              5011495.7092909683
            ],
            [
              351705.9484176804,
              5011495.5240377579
            ],
            [
              351705.50520787865,
              5011495.414076562
            ],
            [
              351705.04963686777,
              5011495.3827484967
            ],
            [
              351704.59554694174,
              5011495.4310054472
            ],
            [
              351704.15673539229,
              5011495.5573811559
            ],
            [
              351703.74653528811,
              5011495.7580357511
            ],
            [
              351703.3774103513,
              5011496.026872457
            ],
            [
              351703.060576257,
              5011496.355722798
            ],
            [
              351702.80565984797,
              5011496.7345948257
            ],
            [
              351702.6204066326,
              5011497.1519767046
            ],
            [
              351702.51044543384,
              5011497.5951864989
            ],
            [
              351702.47911736963,
              5011498.0507575003
            ]
          ],
          [
            [
              351640.78349139099,
              5011528.3534658952
            ],
            [
              351750.79259382741,
              5011526.306174783
            ],
            [
              351748.74532111903,
              5011416.2970924256
            ],
            [
              351638.73621720128,
              5011418.344348398
            ],
            [
              351640.78349139099,
              5011528.3534658952
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 78,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360281.77935741266,
              5004263.9140941324
            ],
            [
              360315.1166203614,
              5004339.1947206175
            ],
            [
              360397.80965248449,
              5004302.5749454908
            ],
            [
              360364.47240562207,
              5004227.2943335045
            ],
            [
              360281.77935741266,
              5004263.9140941324
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 79,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354321.66934040544,
              5011032.1897730678
            ],
            [
              354393.88526167109,
              5011071.7326589422
            ],
            [
              354437.32169046212,
              5010992.4060992887
            ],
            [
              354365.10578737158,
              5010952.8632016825
            ],
            [
              354321.66934040544,
              5011032.1897730678
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 80,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359811.15005047683,
              5004124.8294087136
            ],
            [
              359853.1357779036,
              5004195.6514600851
            ],
            [
              359930.93122719397,
              5004149.531663497
            ],
            [
              359888.94551880489,
              5004078.709622453
            ],
            [
              359811.15005047683,
              5004124.8294087136
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 81,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              361581.17819441133,
              5002931.3898851229
            ],
            [
              361614.51503906207,
              5003006.6703577284
            ],
            [
              361697.20790215139,
              5002970.0510420837
            ],
            [
              361663.8710735878,
              5002894.7705839826
            ],
            [
              361581.17819441133,
              5002931.3898851229
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 82,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              361616.61666985491,
              5002378.248587478
            ],
            [
              361618.14642273571,
              5002460.5659845751
            ],
            [
              361708.56909675454,
              5002458.8855959522
            ],
            [
              361707.03934475005,
              5002376.5682204962
            ],
            [
              361616.61666985491,
              5002378.248587478
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 83,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              361954.51222758897,
              5002357.1475940431
            ],
            [
              362010.06219872413,
              5002417.9150236621
            ],
            [
              362076.81301271747,
              5002356.8954020059
            ],
            [
              362021.26306316367,
              5002296.1279742522
            ],
            [
              361954.51222758897,
              5002357.1475940431
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 84,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359838.20333843835,
              5003721.6875038855
            ],
            [
              359882.55518101208,
              5003791.0523168957
            ],
            [
              359958.74990701309,
              5003742.3334294828
            ],
            [
              359914.39808412915,
              5003672.9686254887
            ],
            [
              359838.20333843835,
              5003721.6875038855
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 85,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358543.23112326389,
              5004969.5239504948
            ],
            [
              358566.73761998862,
              5005048.4293307941
            ],
            [
              358653.41231331008,
              5005022.608292426
            ],
            [
              358629.90582850453,
              5004943.7029301999
            ],
            [
              358543.23112326389,
              5004969.5239504948
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 86,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358736.05024625978,
              5005318.8029318834
            ],
            [
              358777.32939376641,
              5005390.0394286504
            ],
            [
              358855.58009625255,
              5005344.695784227
            ],
            [
              358814.30096757121,
              5005273.4592981543
            ],
            [
              358736.05024625978,
              5005318.8029318834
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 87,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357378.35543476656,
              5006121.0206865696
            ],
            [
              357445.9857280716,
              5006167.9760160493
            ],
            [
              357497.56445504125,
              5006093.686601813
            ],
            [
              357429.93418198917,
              5006046.7312646843
            ],
            [
              357378.35543476656,
              5006121.0206865696
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 88,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358089.04699793534,
              5006126.7763330974
            ],
            [
              358012.62080422236,
              5006157.3977532098
            ],
            [
              358046.25734373595,
              5006241.3491600389
            ],
            [
              358122.68352243828,
              5006210.7277243258
            ],
            [
              358089.04699793534,
              5006126.7763330974
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 89,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355782.60297815129,
              5008491.3302477067
            ],
            [
              355843.01675123605,
              5008547.2668023957
            ],
            [
              355904.46102567413,
              5008480.9044721611
            ],
            [
              355844.04727416037,
              5008424.9679157156
            ],
            [
              355782.60297815129,
              5008491.3302477067
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 90,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352698.78478653094,
              5012177.8102602102
            ],
            [
              352741.637090598,
              5012248.1133414069
            ],
            [
              352818.86247006297,
              5012201.0416421238
            ],
            [
              352776.01018526865,
              5012130.7385707423
            ],
            [
              352698.78478653094,
              5012177.8102602102
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 91,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352093.27987195767,
              5011625.7499929257
            ],
            [
              352043.69730436441,
              5011691.4800317688
            ],
            [
              352115.89938399737,
              5011745.9446729543
            ],
            [
              352165.48193081998,
              5011680.2146401536
            ],
            [
              352093.27987195767,
              5011625.7499929257
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 92,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352049.80060930835,
              5012211.0098631661
            ],
            [
              352030.53224929777,
              5012291.0573502714
            ],
            [
              352118.46151576185,
              5012312.2229311373
            ],
            [
              352137.72986602323,
              5012232.1754633412
            ],
            [
              352049.80060930835,
              5012211.0098631661
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 93,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355015.60524026968,
              5008908.126929217
            ],
            [
              355068.0547960875,
              5008971.5918524228
            ],
            [
              355137.76870829705,
              5008913.9779235171
            ],
            [
              355085.31917375029,
              5008850.5130042993
            ],
            [
              355015.60524026968,
              5008908.126929217
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 94,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356677.47704877978,
              5007094.6047613546
            ],
            [
              356729.92601658293,
              5007158.0696569663
            ],
            [
              356799.6398984777,
              5007100.4563739756
            ],
            [
              356747.19095194951,
              5007036.9914823584
            ],
            [
              356677.47704877978,
              5007094.6047613546
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 95,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358325.44676422706,
              5005133.5972994538
            ],
            [
              358304.81828054873,
              5005213.3035697378
            ],
            [
              358392.37273319735,
              5005235.9631970152
            ],
            [
              358413.00120644475,
              5005156.256945706
            ],
            [
              358325.44676422706,
              5005133.5972994538
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 96,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359367.89155017555,
              5007112.1830397081
            ],
            [
              359409.17099199112,
              5007183.4191892771
            ],
            [
              359487.42131309438,
              5007138.0752215628
            ],
            [
              359446.14189009898,
              5007066.839082689
            ],
            [
              359367.89155017555,
              5007112.1830397081
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 97,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353802.3597561366,
              5011477.1929506343
            ],
            [
              353862.77436125273,
              5011533.1292993464
            ],
            [
              353924.21840943774,
              5011466.76605515
            ],
            [
              353863.80382588116,
              5011410.8297046749
            ],
            [
              353802.3597561366,
              5011477.1929506343
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 98,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352854.43607876269,
              5012892.470993408
            ],
            [
              352909.85952428955,
              5012953.356775309
            ],
            [
              352976.74034397333,
              5012892.4761373885
            ],
            [
              352921.31691998738,
              5012831.5903574098
            ],
            [
              352854.43607876269,
              5012892.470993408
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 99,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358908.06610550918,
              5004512.0884978166
            ],
            [
              358952.41822783294,
              5004581.4533954905
            ],
            [
              359028.61304683425,
              5004532.7342007812
            ],
            [
              358984.26094420004,
              5004463.3693121225
            ],
            [
              358908.06610550918,
              5004512.0884978166
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 100,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360224.55089171656,
              5005960.7512746304
            ],
            [
              360231.43628971075,
              5006042.7947972361
            ],
            [
              360321.55812126701,
              5006035.2314280206
            ],
            [
              360314.67272695695,
              5005953.1879267469
            ],
            [
              360224.55089171656,
              5005960.7512746304
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 101,
        "Layer" : "AUTOCESTA",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352438.05231850786,
              5013094.880261153
            ],
            [
              352467.02551032172,
              5013196.9290369879
            ],
            [
              352568.59940686665,
              5013214.5796239283
            ],
            [
              352438.05231850786,
              5013094.880261153
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 102,
        "Layer" : "LOKALNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              362243.5865763984,
              5003197.2880404256
            ],
            [
              362317.19770711527,
              5003227.1348616323
            ],
            [
              362347.99712613708,
              5003147.6735918876
            ],
            [
              362243.5865763984,
              5003197.2880404256
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 103,
        "Layer" : "LOKALNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360964.09677164123,
              5005859.819952568
            ],
            [
              361013.01389124297,
              5005922.4026467502
            ],
            [
              361079.33994737879,
              5005868.8899748921
            ],
            [
              360964.09677164123,
              5005859.819952568
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 104,
        "Layer" : "LOKALNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359558.88812634064,
              5007053.1363419741
            ],
            [
              359607.80566320958,
              5007115.7191206422
            ],
            [
              359674.13180307212,
              5007062.20599976
            ],
            [
              359558.88812634064,
              5007053.1363419741
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 105,
        "Layer" : "LOKALNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358411.87809822353,
              5007624.4213321498
            ],
            [
              358460.79590260144,
              5007687.0042372467
            ],
            [
              358527.1221736641,
              5007633.4908272987
            ],
            [
              358411.87809822353,
              5007624.4213321498
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 106,
        "Layer" : "AUTOCESTA",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352065.0721875602,
              5012652.6643710695
            ],
            [
              352094.04528040742,
              5012754.7132951412
            ],
            [
              352195.61926503625,
              5012772.3640312366
            ],
            [
              352065.0721875602,
              5012652.6643710695
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 107,
        "Layer" : "NATEXT",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351698.18687805801,
              5011530.7774421424
            ],
            [
              351699.91254892224,
              5011623.503888716
            ],
            [
              351960.34634872788,
              5011618.6570938444
            ],
            [
              351958.62068081828,
              5011525.9307173891
            ],
            [
              351698.18687805801,
              5011530.7774421424
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 108,
        "Layer" : "NATEXT",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352350.45962036436,
              5011857.5509953434
            ],
            [
              352352.18537511554,
              5011950.2772641703
            ],
            [
              352612.61867569917,
              5011945.4302336862
            ],
            [
              352610.89292389737,
              5011852.7040349767
            ],
            [
              352350.45962036436,
              5011857.5509953434
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 109,
        "Layer" : "NATEXT",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353036.31274194148,
              5011494.5053225225
            ],
            [
              353038.03839459101,
              5011587.231408908
            ],
            [
              353298.47118276841,
              5011582.3846651753
            ],
            [
              353296.74553305929,
              5011489.6586489081
            ],
            [
              353036.31274194148,
              5011494.5053225225
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 110,
        "Layer" : "NATEXT",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353429.6950238655,
              5010721.1232245779
            ],
            [
              353431.42046572705,
              5010813.8492098525
            ],
            [
              353691.8529699145,
              5010809.0030581374
            ],
            [
              353690.12753098871,
              5010716.2771429894
            ],
            [
              353429.6950238655,
              5010721.1232245779
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 111,
        "Layer" : "NATEXT",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352752.59919980494,
              5010705.470745746
            ],
            [
              352754.32464170229,
              5010798.1969135143
            ],
            [
              353014.75765844173,
              5010793.3507617069
            ],
            [
              353013.03221948835,
              5010700.6246640664
            ],
            [
              352752.59919980494,
              5010705.470745746
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 112,
        "Layer" : "NATEXT",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354725.80538016342,
              5009180.563425296
            ],
            [
              354727.53039889707,
              5009273.289070989
            ],
            [
              354987.96194931975,
              5009268.4441076545
            ],
            [
              354986.2369335072,
              5009175.718532105
            ],
            [
              354725.80538016342,
              5009180.563425296
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 113,
        "Layer" : "NATEXT",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354878.32246517082,
              5008528.5542588085
            ],
            [
              354880.04730726959,
              5008621.2798674498
            ],
            [
              355140.47875362,
              5008616.4354002103
            ],
            [
              355138.75391444069,
              5008523.7098617209
            ],
            [
              354878.32246517082,
              5008528.5542588085
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 114,
        "Layer" : "NATEXT",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355936.51303780667,
              5008437.9546993682
            ],
            [
              355938.23784895201,
              5008530.6800234141
            ],
            [
              356198.66849598865,
              5008525.835643094
            ],
            [
              356196.94368774985,
              5008433.1103891982
            ],
            [
              355936.51303780667,
              5008437.9546993682
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 115,
        "Layer" : "NATEXT",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355599.28532923124,
              5008223.2338589989
            ],
            [
              355601.01008459291,
              5008315.9592752475
            ],
            [
              355861.44099057751,
              5008311.1150516095
            ],
            [
              355859.71623812657,
              5008218.3897055145
            ],
            [
              355599.28532923124,
              5008223.2338589989
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 116,
        "Layer" : "NATEXT",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356156.48849613854,
              5007280.020324843
            ],
            [
              356158.21299386874,
              5007372.7455967283
            ],
            [
              356418.6434943871,
              5007367.9020966673
            ],
            [
              356416.91899956134,
              5007275.176894947
            ],
            [
              356156.48849613854,
              5007280.020324843
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 117,
        "Layer" : "NATEXT",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357000.87324387644,
              5006448.7380532986
            ],
            [
              357002.59751236782,
              5006541.4631026806
            ],
            [
              357263.02738794865,
              5006536.6202464486
            ],
            [
              357261.30312235287,
              5006443.8952672454
            ],
            [
              357000.87324387644,
              5006448.7380532986
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 118,
        "Layer" : "NATEXT",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357785.73081278056,
              5006614.0856737038
            ],
            [
              357787.45512106101,
              5006706.8105105162
            ],
            [
              358047.88439961843,
              5006701.9675425254
            ],
            [
              358046.16009422491,
              5006609.2427758863
            ],
            [
              357785.73081278056,
              5006614.0856737038
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 119,
        "Layer" : "NATEXT",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358570.36804104847,
              5006730.0902859997
            ],
            [
              358572.09237584408,
              5006822.8149106111
            ],
            [
              358832.52105841495,
              5006817.9718681378
            ],
            [
              358830.79672649736,
              5006725.2473136997
            ],
            [
              358570.36804104847,
              5006730.0902859997
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 120,
        "Layer" : "NATEXT",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357714.51788174914,
              5005779.8862501532
            ],
            [
              357716.24196558609,
              5005872.6111112293
            ],
            [
              357976.67131228454,
              5005867.7687736088
            ],
            [
              357974.94723133376,
              5005775.0439827144
            ],
            [
              357714.51788174914,
              5005779.8862501532
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 121,
        "Layer" : "NATEXT",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357713.22917871439,
              5005366.2760204431
            ],
            [
              357714.95315105241,
              5005459.0008843839
            ],
            [
              357975.38250577974,
              5005454.1588599216
            ],
            [
              357973.65853632899,
              5005361.4340661708
            ],
            [
              357713.22917871439,
              5005366.2760204431
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 122,
        "Layer" : "NATEXT",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358886.37754148134,
              5005892.1391651314
            ],
            [
              358888.10164848721,
              5005984.8637096137
            ],
            [
              359148.53010599717,
              5005980.021306905
            ],
            [
              359146.80600186391,
              5005887.2968326034
            ],
            [
              358886.37754148134,
              5005892.1391651314
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 123,
        "Layer" : "NATEXT",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359206.48576499149,
              5005108.6249038074
            ],
            [
              359208.20965883636,
              5005201.3493667059
            ],
            [
              359468.63788719638,
              5005196.5075626783
            ],
            [
              359466.91399622202,
              5005103.7831699718
            ],
            [
              359206.48576499149,
              5005108.6249038074
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 124,
        "Layer" : "NATEXT",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360214.80961605191,
              5003381.2044852264
            ],
            [
              360216.53303805256,
              5003473.9286865732
            ],
            [
              360476.96053180081,
              5003469.0882077571
            ],
            [
              360475.23711265839,
              5003376.364076619
            ],
            [
              360214.80961605191,
              5003381.2044852264
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 125,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354018.72115443525,
              5010290.0531252613
            ],
            [
              354076.9931373939,
              5010348.2181262393
            ],
            [
              354140.88527863397,
              5010284.2084741509
            ],
            [
              354082.61331731029,
              5010226.0434730332
            ],
            [
              354018.72115443525,
              5010290.0531252613
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 126,
        "Layer" : "NATEXT",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358602.37577019614,
              5006116.4941062145
            ],
            [
              358604.09993939701,
              5006209.218725903
            ],
            [
              358864.52860813675,
              5006204.3761485172
            ],
            [
              358862.80444181204,
              5006111.6515990086
            ],
            [
              358602.37577019614,
              5006116.4941062145
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 127,
        "Layer" : "NATEXT",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358008.46799009986,
              5006346.4245340377
            ],
            [
              358010.19222487794,
              5006439.1493124347
            ],
            [
              358270.62133936503,
              5006434.3065508753
            ],
            [
              358268.89710747096,
              5006341.5818426581
            ],
            [
              358008.46799009986,
              5006346.4245340377
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 128,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357318.59807735932,
              5006988.9762043301
            ],
            [
              357371.04692750756,
              5007052.4409982394
            ],
            [
              357440.76069768472,
              5006994.8278444866
            ],
            [
              357388.31186881213,
              5006931.3630545717
            ],
            [
              357318.59807735932,
              5006988.9762043301
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 129,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357253.35299199226,
              5006364.9567412948
            ],
            [
              357305.80173738493,
              5006428.4216428176
            ],
            [
              357375.51562576724,
              5006370.8086041361
            ],
            [
              357323.06690165238,
              5006307.3437066134
            ],
            [
              357253.35299199226,
              5006364.9567412948
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 130,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356897.98611160758,
              5006695.3598688301
            ],
            [
              356950.43497221154,
              5006758.8247850686
            ],
            [
              357020.14887676603,
              5006701.2116198344
            ],
            [
              356967.70003743889,
              5006637.7467075856
            ],
            [
              356897.98611160758,
              5006695.3598688301
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 131,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354633.47340072563,
              5008841.1827723766
            ],
            [
              354675.63340718095,
              5008911.9026525514
            ],
            [
              354736.56244951912,
              5008875.5795005318
            ],
            [
              354694.40245802823,
              5008804.8596283663
            ],
            [
              354633.47340072563,
              5008841.1827723766
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 132,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360679.55061140546,
              5002874.7733366583
            ],
            [
              360731.99818967166,
              5002938.2381425351
            ],
            [
              360801.71197298198,
              5002880.6263859048
            ],
            [
              360749.26441600226,
              5002817.1615840383
            ],
            [
              360679.55061140546,
              5002874.7733366583
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 133,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354219.50907418184,
              5009932.5232380666
            ],
            [
              354271.95894022129,
              5009995.9881505752
            ],
            [
              354341.67284068669,
              5009938.3738809023
            ],
            [
              354289.22299591528,
              5009874.908972376
            ],
            [
              354219.50907418184,
              5009932.5232380666
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 134,
        "Layer" : "NATEXT",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358366.71636723948,
              5006445.156408689
            ],
            [
              358368.44062646112,
              5006537.8810899183
            ],
            [
              358628.86946804455,
              5006533.0382597037
            ],
            [
              358627.14521170204,
              5006440.3136486504
            ],
            [
              358366.71636723948,
              5006445.156408689
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 135,
        "Layer" : "AGEO_Spojnica",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352447.40721563017,
              5012454.2384581361
            ],
            [
              352446.88877558423,
              5012447.4057031423
            ],
            [
              352445.59483959945,
              5012440.676583522
            ],
            [
              352443.54227609007,
              5012434.138823594
            ],
            [
              352440.75784333685,
              5012427.8776530242
            ],
            [
              352437.27784064162,
              5012421.9746957095
            ],
            [
              352433.14763512311,
              5012416.5069057113
            ],
            [
              352428.42107028043,
              5012411.5455640163
            ],
            [
              352423.15976406791,
              5012407.1553492965
            ],
            [
              352417.43230561371,
              5012403.393494728
            ],
            [
              352411.31336105516,
              5012400.3090418559
            ],
            [
              352404.88270015322,
              5012397.9422012661
            ],
            [
              352398.22415637999,
              5012396.3238283843
            ],
            [
              352391.42453401408,
              5012395.4750212152
            ],
            [
              352384.57247651956,
              5012395.4068453014
            ],
            [
              352377.75731094513,
              5012396.1201894693
            ],
            [
              352371.067883407,
              5012397.605754232
            ],
            [
              352364.59140084224,
              5012399.8441730216
            ],
            [
              352358.41229413421,
              5012402.8062646668
            ],
            [
              352352.61111742537,
              5012406.4534137966
            ],
            [
              352347.26349796704,
              5012410.7380742645
            ],
            [
              352342.43915020337,
              5012415.6043889783
            ],
            [
              352338.20096693217,
              5012420.9889180902
            ],
            [
              352334.60419939761,
              5012426.8214660119
            ],
            [
              352331.69573700597,
              5012433.0259965397
            ],
            [
              352329.51349605137,
              5012439.5216241041
            ],
            [
              352328.08592540602,
              5012446.2236682205
            ],
            [
              352327.43163565738,
              5012453.0447574602
            ],
            [
              352327.55915648007,
              5012459.8959684428
            ],
            [
              352328.4668254446,
              5012466.6879851129
            ],
            [
              352330.14280968375,
              5012473.3322631009
            ],
            [
              352332.5652601592,
              5012479.7421840373
            ],
            [
              352335.7025964944,
              5012485.8341847705
            ],
            [
              352339.51391867193,
              5012491.528846723
            ],
            [
              352343.94954023539,
              5012496.7519312538
            ],
            [
              352348.95163603121,
              5012501.4353474583
            ],
            [
              352354.45499604265,
              5012505.5180398477
            ],
            [
              352360.38787551253,
              5012508.9467843017
            ],
            [
              352366.67293024139,
              5012511.6768819075
            ],
            [
              352373.22822489712,
              5012513.6727417037
            ],
            [
              352379.96830116253,
              5012514.908344646
            ],
            [
              352386.80529182061,
              5012515.3675828008
            ],
            [
              352393.65006623528,
              5012515.0444693435
            ],
            [
              352400.41339230892,
              5012513.9432166005
            ],
            [
              352407.00709975878,
              5012512.0781811271
            ],
            [
              352413.34522954823,
              5012509.4736765521
            ],
            [
              352419.34515449434,
              5012506.1636566101
            ],
            [
              352424.9286564418,
              5012502.191272499
            ],
            [
              352430.02294594568,
              5012497.6083103307
            ],
            [
              352434.56161119899,
              5012492.4745160351
            ],
            [
              352438.48548380361,
              5012486.8568164632
            ],
            [
              352441.74341012532,
              5012480.828446907
            ],
            [
              352444.29291814839,
              5012474.4679963542
            ],
            [
              352446.10077117005,
              5012467.8583829701
            ],
            [
              352447.14340108418,
              5012461.0857731383
            ],
            [
              352447.40721563017,
              5012454.2384581361
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 136,
        "Layer" : "0",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359589.86359974102,
              5004279.0433066972
            ],
            [
              359589.34533335286,
              5004272.2106804969
            ],
            [
              359588.05158457597,
              5004265.4816690339
            ],
            [
              359585.99921938329,
              5004258.9439952206
            ],
            [
              359583.21499347052,
              5004252.6828875951
            ],
            [
              359579.7352034431,
              5004246.7799692396
            ],
            [
              359575.6052136473,
              5004241.3121937001
            ],
            [
              359570.87886476837,
              5004236.3508417783
            ],
            [
              359565.61777194752,
              5004231.9605922829
            ],
            [
              359559.89052152995,
              5004228.1986788418
            ],
            [
              359553.77177694038,
              5004225.1141437637
            ],
            [
              359547.3413053343,
              5004222.7471987093
            ],
            [
              359540.6829377141,
              5004221.128700464
            ],
            [
              359533.88347606262,
              5004220.279748668
            ],
            [
              359527.03156174935,
              5004220.2114107544
            ],
            [
              359520.21651995549,
              5004220.9245776534
            ],
            [
              359513.52719518385,
              5004222.4099521935
            ],
            [
              359507.05079303129,
              5004224.6481702859
            ],
            [
              359500.87174333452,
              5004227.6100533726
            ],
            [
              359495.07059949002,
              5004231.2569888067
            ],
            [
              359489.72298832313,
              5004235.5414332272
            ],
            [
              359484.89862416923,
              5004240.4075323585
            ],
            [
              359480.66040003952,
              5004245.7918491596
            ],
            [
              359477.06356771063,
              5004251.6241908083
            ],
            [
              359474.15501743479,
              5004257.8285237988
            ],
            [
              359471.97266665037,
              5004264.3239651266
            ],
            [
              359470.54496566299,
              5004271.0258367443
            ],
            [
              359469.89052675833,
              5004277.8467694661
            ],
            [
              359470.01788155606,
              5004284.6978419563
            ],
            [
              359470.92536979023,
              5004291.4897399601
            ],
            [
              359472.60116095206,
              5004298.1339206565
            ],
            [
              359475.02340851759,
              5004304.5437669475
            ],
            [
              359478.16053475632,
              5004310.6357166497
            ],
            [
              359481.97164239077,
              5004316.3303518537
            ],
            [
              359486.40704776143,
              5004321.5534342658
            ],
            [
              359491.40892853081,
              5004326.2368730111
            ],
            [
              359496.91207748832,
              5004330.3196123065
            ],
            [
              359502.84475262515,
              5004333.7484274134
            ],
            [
              359509.1296124084,
              5004336.4786185082
            ],
            [
              359515.68472404528,
              5004338.4745934037
            ],
            [
              359522.42463160591,
              5004339.7103315582
            ],
            [
              359529.2614700704,
              5004340.169723276
            ],
            [
              359536.10611078964,
              5004339.8467797292
            ],
            [
              359542.86932340619,
              5004338.7457110286
            ],
            [
              359549.46293911739,
              5004336.8808713295
            ],
            [
              359555.80100008316,
              5004334.2765717134
            ],
            [
              359561.80088001781,
              5004330.9667632375
            ],
            [
              359567.38436135132,
              5004326.9945943458
            ],
            [
              359572.47865490883,
              5004322.411848343
            ],
            [
              359577.01734883006,
              5004317.2782683428
            ],
            [
              359580.94127434323,
              5004311.6607784042
            ],
            [
              359584.19927712489,
              5004305.6326110782
            ],
            [
              359586.74888416164,
              5004299.2723527262
            ],
            [
              359588.55685746222,
              5004292.6629190035
            ],
            [
              359589.5996273504,
              5004285.8904739488
            ],
            [
              359589.86359974102,
              5004279.0433066972
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 137,
        "Layer" : "0",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353631.92381335323,
              5011078.5440284712
            ],
            [
              353631.40540249745,
              5011071.7112947796
            ],
            [
              353630.1114979405,
              5011064.9821929932
            ],
            [
              353628.05896768795,
              5011058.4444472035
            ],
            [
              353625.27456958621,
              5011052.1832868876
            ],
            [
              353621.79460248566,
              5011046.2803358128
            ],
            [
              353617.66443304002,
              5011040.8125479501
            ],
            [
              353612.93790427916,
              5011035.8512042658
            ],
            [
              353607.67663368682,
              5011031.4609834598
            ],
            [
              353601.94920992723,
              5011027.6991187846
            ],
            [
              353595.83029868384,
              5011024.6146519147
            ],
            [
              353589.39966928546,
              5011022.2477936205
            ],
            [
              353582.74115479132,
              5011020.6293995585
            ],
            [
              353575.94155910041,
              5011019.7805680111
            ],
            [
              353569.08952532755,
              5011019.7123648366
            ],
            [
              353562.27438021277,
              5011020.425679219
            ],
            [
              353555.58496960555,
              5011021.9112120559
            ],
            [
              353549.10850022116,
              5011024.1495971978
            ],
            [
              353542.92940277309,
              5011027.1116539109
            ],
            [
              353537.12823128124,
              5011030.7587672835
            ],
            [
              353531.78061293048,
              5011035.043391631
            ],
            [
              353526.95626214996,
              5011039.9096703399
            ],
            [
              353522.71807177615,
              5011045.2941640168
            ],
            [
              353519.12129314605,
              5011051.1266775457
            ],
            [
              353516.21281581209,
              5011057.3311751727
            ],
            [
              353514.03055626003,
              5011063.8267717492
            ],
            [
              353512.60296360828,
              5011070.528787205
            ],
            [
              353511.9486487286,
              5011077.3498504786
            ],
            [
              353512.07614162454,
              5011084.2010385273
            ],
            [
              353512.98378022976,
              5011090.9930355987
            ],
            [
              353514.65973207459,
              5011097.6372975716
            ],
            [
              353517.08214853966,
              5011104.0472062947
            ],
            [
              353520.21944969503,
              5011110.1391987698
            ],
            [
              353524.03073597996,
              5011115.8338565286
            ],
            [
              353528.46632140887,
              5011121.0569409849
            ],
            [
              353533.46838129457,
              5011125.7403612332
            ],
            [
              353538.97170609218,
              5011129.8230617335
            ],
            [
              353544.90455150133,
              5011133.2518182574
            ],
            [
              353551.18957376917,
              5011135.9819317404
            ],
            [
              353557.74483798456,
              5011137.9778110106
            ],
            [
              353564.48488622817,
              5011139.2134367693
            ],
            [
              353571.3218516467,
              5011139.6727007851
            ],
            [
              353578.16660393606,
              5011139.3496158998
            ],
            [
              353584.9299112836,
              5011138.2483940627
            ],
            [
              353591.52360365249,
              5011136.3833914269
            ],
            [
              353597.86172220361,
              5011133.7789211981
            ],
            [
              353603.86163990019,
              5011130.4689366585
            ],
            [
              353609.44513867859,
              5011126.4965885384
            ],
            [
              353614.53942913946,
              5011121.9136624979
            ],
            [
              353619.07809946028,
              5011116.7799039818
            ],
            [
              353623.00198117777,
              5011111.1622393783
            ],
            [
              353626.25992053887,
              5011105.1339035211
            ],
            [
              353628.80944535794,
              5011098.773484963
            ],
            [
              353630.61731871444,
              5011092.1639014538
            ],
            [
              353631.65997223568,
              5011085.3913189825
            ],
            [
              353631.92381335323,
              5011078.5440284712
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 138,
        "Layer" : "0",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357231.78755310812,
              5006792.8925675591
            ],
            [
              357231.26923305757,
              5006786.059898505
            ],
            [
              357229.97542608174,
              5006779.3308505835
            ],
            [
              357227.92299891397,
              5006772.793147184
            ],
            [
              357225.13870805694,
              5006766.5320172282
            ],
            [
              357221.65885096363,
              5006760.6290840898
            ],
            [
              357217.52879285364,
              5006755.1613015113
            ],
            [
              357212.80237530399,
              5006750.199950383
            ],
            [
              357207.54121434997,
              5006745.8097095061
            ],
            [
              357201.81389722461,
              5006742.0478123883
            ],
            [
              357195.69508822466,
              5006738.9633011334
            ],
            [
              357189.2645553422,
              5006736.596387093
            ],
            [
              357182.60613038146,
              5006734.9779266408
            ],
            [
              357175.80661607173,
              5006734.1290189316
            ],
            [
              357168.95465446747,
              5006734.060730814
            ],
            [
              357162.13957136829,
              5006734.7739525801
            ],
            [
              357155.45021181495,
              5006736.2593863374
            ],
            [
              357148.97378185712,
              5006738.4976672251
            ],
            [
              357142.79471169459,
              5006741.4596158685
            ],
            [
              357136.99355499173,
              5006745.1066187657
            ],
            [
              357131.64593873994,
              5006749.391131673
            ],
            [
              357126.82157734066,
              5006754.2572994251
            ],
            [
              357122.58336376975,
              5006759.6416840833
            ],
            [
              357118.98654966737,
              5006765.474091948
            ],
            [
              357116.0780250461,
              5006771.6784886401
            ],
            [
              357113.89570701122,
              5006778.1739903344
            ],
            [
              357112.46804544056,
              5006784.87591819
            ],
            [
              357111.8136521058,
              5006791.6969022909
            ],
            [
              357111.94105803379,
              5006798.5480206292
            ],
            [
              357112.84860229079,
              5006805.3399583548
            ],
            [
              357114.52445363783,
              5006811.9841721253
            ],
            [
              357116.94676476711,
              5006818.394044417
            ],
            [
              357120.08395711816,
              5006824.4860127028
            ],
            [
              357123.89513255336,
              5006830.180658835
            ],
            [
              357128.33060652699,
              5006835.4037443735
            ],
            [
              357133.33255580981,
              5006840.0871784035
            ],
            [
              357138.83577229618,
              5006844.1699052081
            ],
            [
              357144.76851309778,
              5006847.5987002058
            ],
            [
              357151.05343582627,
              5006850.3288638378
            ],
            [
              357157.6086068665,
              5006852.3248042772
            ],
            [
              357164.34856951592,
              5006853.5605014246
            ],
            [
              357171.18545803562,
              5006854.0198461218
            ],
            [
              357178.03014312405,
              5006853.6968501573
            ],
            [
              357184.79339384573,
              5006852.5957243238
            ],
            [
              357191.38704090117,
              5006850.7308235234
            ],
            [
              357197.72512604174,
              5006848.1264596274
            ],
            [
              357203.72502266592,
              5006844.8165845331
            ],
            [
              357209.30851298658,
              5006840.8443475571
            ],
            [
              357214.40280771174,
              5006836.2615328887
            ],
            [
              357218.94149496633,
              5006831.127884537
            ],
            [
              357222.86540606496,
              5006825.5103274528
            ],
            [
              357226.12338687154,
              5006819.4820950655
            ],
            [
              357228.67296465969,
              5006813.1217745738
            ],
            [
              357230.48090181994,
              5006806.5122824591
            ],
            [
              357231.52362914541,
              5006799.7397835078
            ],
            [
              357231.78755310812,
              5006792.8925675591
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 139,
        "Layer" : "0",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355516.75611489738,
              5008842.4681404503
            ],
            [
              355516.23775142751,
              5008835.6354406001
            ],
            [
              355514.94389780599,
              5008828.9063670374
            ],
            [
              355512.89142137306,
              5008822.3686434757
            ],
            [
              355510.10707927449,
              5008816.1074991124
            ],
            [
              355506.6271696302,
              5008810.2045574989
            ],
            [
              355502.49705834518,
              5008804.7367724869
            ],
            [
              355497.77058769058,
              5008799.7754250048
            ],
            [
              355492.50937439356,
              5008795.3851938052
            ],
            [
              355486.78200637008,
              5008791.6233122684
            ],
            [
              355480.66314857843,
              5008788.5388222933
            ],
            [
              355474.23256964952,
              5008786.1719349585
            ],
            [
              355467.57410198514,
              5008784.553506285
            ],
            [
              355460.77454887255,
              5008783.7046350138
            ],
            [
              355453.92255287268,
              5008783.6363875223
            ],
            [
              355447.10744023317,
              5008784.3496535718
            ],
            [
              355440.41805637983,
              5008785.8351346878
            ],
            [
              355433.94160767953,
              5008788.0734653976
            ],
            [
              355427.76252457575,
              5008791.0354656745
            ],
            [
              355421.9613609009,
              5008794.6825213423
            ],
            [
              355416.61374373833,
              5008798.9670874719
            ],
            [
              355411.78938750125,
              5008803.8333082022
            ],
            [
              355407.551185099,
              5008809.2177449074
            ],
            [
              355403.95438802388,
              5008815.0502032032
            ],
            [
              355401.04588606919,
              5008821.2546480615
            ],
            [
              355398.86359604198,
              5008827.7501950171
            ],
            [
              355397.43596745661,
              5008834.4521646528
            ],
            [
              355396.78161165508,
              5008841.2731864993
            ],
            [
              355396.90905917337,
              5008848.1243380588
            ],
            [
              355397.81664853694,
              5008854.9163040472
            ],
            [
              355399.49254791799,
              5008861.5605407553
            ],
            [
              355401.91490938241,
              5008867.9704303583
            ],
            [
              355405.05215371517,
              5008874.062410106
            ],
            [
              355408.86338209844,
              5008879.7570617003
            ],
            [
              355413.29890929861,
              5008884.9801466279
            ],
            [
              355418.30091139075,
              5008889.6635739887
            ],
            [
              355423.80417958117,
              5008893.7462881394
            ],
            [
              355429.73697030911,
              5008897.1750646774
            ],
            [
              355436.02194053202,
              5008899.9052042775
            ],
            [
              355442.57715602004,
              5008901.9011154287
            ],
            [
              355449.31715948711,
              5008903.1367784105
            ],
            [
              355456.15408466529,
              5008903.5960845165
            ],
            [
              355462.99880177289,
              5008903.2730460279
            ],
            [
              355469.76207945874,
              5008902.1718743006
            ],
            [
              355476.35574807,
              5008900.3069248265
            ],
            [
              355482.69384907867,
              5008897.7025101241
            ],
            [
              355488.69375567645,
              5008894.3925827434
            ],
            [
              355494.27724994515,
              5008890.4202926839
            ],
            [
              355499.37154254172,
              5008885.8374248315
            ],
            [
              355503.91022161819,
              5008880.7037238814
            ],
            [
              355507.8341185968,
              5008875.0861154748
            ],
            [
              355511.092079525,
              5008869.057833707
            ],
            [
              355513.64163193636,
              5008862.6974664275
            ],
            [
              355515.44953855011,
              5008856.0879307166
            ],
            [
              355516.49223056028,
              5008849.3153919401
            ],
            [
              355516.75611489738,
              5008842.4681404503
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 140,
        "Layer" : "0",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357449.86678937916,
              5006381.3891655048
            ],
            [
              357449.34847784584,
              5006374.5565001257
            ],
            [
              357448.05467975192,
              5006367.8274548873
            ],
            [
              357446.00226171326,
              5006361.2897531381
            ],
            [
              357443.21798011527,
              5006355.0286237877
            ],
            [
              357439.73813228775,
              5006349.125690192
            ],
            [
              357435.60808333167,
              5006343.657906102
            ],
            [
              357430.88167470362,
              5006338.6965524275
            ],
            [
              357425.62052232417,
              5006334.306308005
            ],
            [
              357419.89321331278,
              5006330.544406388
            ],
            [
              357413.77441186074,
              5006327.4598897398
            ],
            [
              357407.34388586256,
              5006325.0929694772
            ],
            [
              357400.68546703114,
              5006323.4745020624
            ],
            [
              357393.88595801726,
              5006322.6255867332
            ],
            [
              357387.03400080692,
              5006322.5572904451
            ],
            [
              357380.21892114059,
              5006323.2705035936
            ],
            [
              357373.52956401458,
              5006324.7559283935
            ],
            [
              357367.05313544918,
              5006326.9942001095
            ],
            [
              357360.87406562344,
              5006329.9561394807
            ],
            [
              357355.07290819829,
              5006333.603133128
            ],
            [
              357349.72529017396,
              5006337.887636926
            ],
            [
              357344.90092597611,
              5006342.7537958352
            ],
            [
              357340.66270861699,
              5006348.138172023
            ],
            [
              357337.06588978501,
              5006353.9705719063
            ],
            [
              357334.15735955501,
              5006360.1749612056
            ],
            [
              357331.97503510577,
              5006366.6704561971
            ],
            [
              357330.54736639891,
              5006373.3723781258
            ],
            [
              357329.89296529756,
              5006380.193357151
            ],
            [
              357330.02036293142,
              5006387.0444713291
            ],
            [
              357330.92789847514,
              5006393.8364058705
            ],
            [
              357332.60374080174,
              5006400.480617472
            ],
            [
              357335.02604272164,
              5006406.890488632
            ],
            [
              357338.16322579578,
              5006412.9824568452
            ],
            [
              357341.97439200489,
              5006418.6771039646
            ],
            [
              357346.40985692682,
              5006423.9001915343
            ],
            [
              357351.41179744719,
              5006428.5836286154
            ],
            [
              357356.91500557616,
              5006432.666359446
            ],
            [
              357362.84773853444,
              5006436.0951593993
            ],
            [
              357369.13265403442,
              5006438.8253288493
            ],
            [
              357375.68781855807,
              5006440.8212758889
            ],
            [
              357382.42777548492,
              5006442.0569803417
            ],
            [
              357389.26465915242,
              5006442.5163329476
            ],
            [
              357396.10934032156,
              5006442.1933453931
            ],
            [
              357402.872588108,
              5006441.0922283614
            ],
            [
              357409.46623325039,
              5006439.2273366377
            ],
            [
              357415.80431752553,
              5006436.6229819786
            ],
            [
              357421.80421434221,
              5006433.3131161621
            ],
            [
              357427.38770591194,
              5006429.3408883773
            ],
            [
              357432.48200292635,
              5006424.7580827009
            ],
            [
              357437.02069347975,
              5006419.6244430225
            ],
            [
              357440.94460884406,
              5006414.0068941768
            ],
            [
              357444.20259482751,
              5006407.9786694841
            ],
            [
              357446.75217863737,
              5006401.6183560565
            ],
            [
              357448.56012258347,
              5006395.0088702617
            ],
            [
              357449.60285737319,
              5006388.2363768257
            ],
            [
              357449.86678937916,
              5006381.3891655048
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 141,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353281.37713850575,
              5010669.5464909272
            ],
            [
              353299.51688452181,
              5010749.8569543799
            ],
            [
              353387.73501138639,
              5010729.9310952891
            ],
            [
              353369.59527475951,
              5010649.6206513308
            ],
            [
              353281.37713850575,
              5010669.5464909272
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 142,
        "Layer" : "B03---BLOKOVI",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351856.62128418166,
              5011335.8519601701
            ],
            [
              351856.66954090068,
              5011336.3060499066
            ],
            [
              351856.79591635056,
              5011336.7448613076
            ],
            [
              351856.99657067604,
              5011337.1550613102
            ],
            [
              351857.26540709712,
              5011337.5241861986
            ],
            [
              351857.59425715479,
              5011337.8410202926
            ],
            [
              351857.97312890535,
              5011338.0959367566
            ],
            [
              351858.39051052317,
              5011338.2811900759
            ],
            [
              351858.83372007922,
              5011338.3911514254
            ],
            [
              351859.2892908731,
              5011338.4224796863
            ],
            [
              351859.7433806184,
              5011338.3742229668
            ],
            [
              351860.18219202833,
              5011338.247847517
            ],
            [
              351860.59239203966,
              5011338.0471931985
            ],
            [
              351860.96151693206,
              5011337.7783567859
            ],
            [
              351861.2783510341,
              5011337.449506741
            ],
            [
              351861.53326750256,
              5011337.0706350058
            ],
            [
              351861.71852082794,
              5011336.6532534044
            ],
            [
              351861.82848218444,
              5011336.2100438671
            ],
            [
              351861.85981045041,
              5011335.7544730911
            ],
            [
              351861.81155373162,
              5011335.3003833639
            ],
            [
              351861.68517828546,
              5011334.8615719657
            ],
            [
              351861.48452396534,
              5011334.4513719669
            ],
            [
              351861.21568755101,
              5011334.0822470831
            ],
            [
              351860.88683750026,
              5011333.7654129853
            ],
            [
              351860.50796575617,
              5011333.5104965223
            ],
            [
              351860.090584144,
              5011333.3252431983
            ],
            [
              351859.6473745908,
              5011333.2152818404
            ],
            [
              351859.19180379761,
              5011333.183953573
            ],
            [
              351858.73771405092,
              5011333.2322102878
            ],
            [
              351858.29890263692,
              5011333.3585857302
            ],
            [
              351857.88870262023,
              5011333.5592400432
            ],
            [
              351857.51957772148,
              5011333.8280764539
            ],
            [
              351857.2027436124,
              5011334.1569265006
            ],
            [
              351856.94782713754,
              5011334.5357982367
            ],
            [
              351856.7625738075,
              5011334.9531798437
            ],
            [
              351856.65261244733,
              5011335.3963893848
            ],
            [
              351856.62128418166,
              5011335.8519601701
            ]
          ],
          [
            [
              351794.92567134154,
              5011366.1546258582
            ],
            [
              351904.93472574092,
              5011364.1073877383
            ],
            [
              351902.88750602264,
              5011254.0983534204
            ],
            [
              351792.87845014274,
              5011256.1455563996
            ],
            [
              351794.92567134154,
              5011366.1546258582
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 143,
        "Layer" : "B03---BLOKOVI",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355024.07435792423,
              5008350.3486070512
            ],
            [
              355024.12261024205,
              5008350.8026930466
            ],
            [
              355024.24898070894,
              5008351.2415015288
            ],
            [
              355024.44962961943,
              5008351.6516995225
            ],
            [
              355024.71846035926,
              5008352.0208233707
            ],
            [
              355025.04730464122,
              5008352.3376574283
            ],
            [
              355025.42617069773,
              5008352.5925748572
            ],
            [
              355025.84354687535,
              5008352.7778301192
            ],
            [
              355026.28675141087,
              5008352.8877943261
            ],
            [
              355026.74231775763,
              5008352.9191262713
            ],
            [
              355027.19640376203,
              5008352.8708739504
            ],
            [
              355027.63521225256,
              5008352.744503486
            ],
            [
              355028.04541025375,
              5008352.5438545821
            ],
            [
              355028.41453410831,
              5008352.2750238525
            ],
            [
              355028.73136817367,
              5008351.9461795827
            ],
            [
              355028.98628560913,
              5008351.5673135407
            ],
            [
              355029.17154087528,
              5008351.1499373792
            ],
            [
              355029.28150508803,
              5008350.7067328626
            ],
            [
              355029.31283703831,
              5008350.2511665327
            ],
            [
              355029.26458472136,
              5008349.7970805448
            ],
            [
              355029.1382142576,
              5008349.3582720682
            ],
            [
              355028.93756535329,
              5008348.94807408
            ],
            [
              355028.66873461934,
              5008348.5789502328
            ],
            [
              355028.33989034541,
              5008348.262116177
            ],
            [
              355027.96102429536,
              5008348.0071987435
            ],
            [
              355027.54364812264,
              5008347.8219434759
            ],
            [
              355027.10044358956,
              5008347.7119792635
            ],
            [
              355026.64487724414,
              5008347.6806473136
            ],
            [
              355026.19079123758,
              5008347.7288996251
            ],
            [
              355025.75198274344,
              5008347.855270084
            ],
            [
              355025.34178473643,
              5008348.055918985
            ],
            [
              355024.9726608764,
              5008348.3247497138
            ],
            [
              355024.65582680376,
              5008348.6535939807
            ],
            [
              355024.40090936207,
              5008349.0324600255
            ],
            [
              355024.21565409057,
              5008349.4498361927
            ],
            [
              355024.10568987427,
              5008349.8930407148
            ],
            [
              355024.07435792423,
              5008350.3486070512
            ]
          ],
          [
            [
              354962.37904626079,
              5008380.6504571987
            ],
            [
              355072.38711043046,
              5008378.6041968865
            ],
            [
              355070.34086851316,
              5008268.5961528169
            ],
            [
              354960.33280288073,
              5008270.6423779735
            ],
            [
              354962.37904626079,
              5008380.6504571987
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 144,
        "Layer" : "B03---BLOKOVI",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355781.68698417785,
              5007528.1236434868
            ],
            [
              355781.73523530044,
              5007528.5777286021
            ],
            [
              355781.86160443735,
              5007529.0165364277
            ],
            [
              355782.06225192331,
              5007529.4267340014
            ],
            [
              355782.33108118846,
              5007529.7958576847
            ],
            [
              355782.65992398903,
              5007530.1126918383
            ],
            [
              355783.03878860368,
              5007530.3676096164
            ],
            [
              355783.45616342244,
              5007530.5528654763
            ],
            [
              355783.89936672285,
              5007530.6628305055
            ],
            [
              355784.35493199551,
              5007530.6941634724
            ],
            [
              355784.80901712115,
              5007530.6459123483
            ],
            [
              355785.24782495329,
              5007530.5195432138
            ],
            [
              355785.65802253695,
              5007530.3188957339
            ],
            [
              355786.02714622702,
              5007530.0500664795
            ],
            [
              355786.34398038738,
              5007529.7212236905
            ],
            [
              355786.59889817249,
              5007529.3423590912
            ],
            [
              355786.78415403463,
              5007528.9249842903
            ],
            [
              355786.89411906974,
              5007528.4817810068
            ],
            [
              355786.92545204464,
              5007528.0262157517
            ],
            [
              355786.87720092194,
              5007527.5721306419
            ],
            [
              355786.75083178835,
              5007527.1333228238
            ],
            [
              355786.55018430803,
              5007526.7231252529
            ],
            [
              355786.28135504987,
              5007526.3540015714
            ],
            [
              355785.95251225616,
              5007526.037167416
            ],
            [
              355785.57364764815,
              5007525.782249636
            ],
            [
              355785.15627283481,
              5007525.5969937751
            ],
            [
              355784.71306953725,
              5007525.4870287385
            ],
            [
              355784.2575042657,
              5007525.4556957623
            ],
            [
              355783.8034191386,
              5007525.5039468789
            ],
            [
              355783.36461130221,
              5007525.6303160088
            ],
            [
              355782.9544137136,
              5007525.8309634859
            ],
            [
              355782.58529001736,
              5007526.0997927384
            ],
            [
              355782.26845584955,
              5007526.4286355255
            ],
            [
              355782.01353805786,
              5007526.8075001286
            ],
            [
              355781.82828219089,
              5007527.2248749342
            ],
            [
              355781.71831715241,
              5007527.6680782232
            ],
            [
              355781.68698417785,
              5007528.1236434868
            ]
          ],
          [
            [
              355719.99173502804,
              5007558.4252791842
            ],
            [
              355829.99956299603,
              5007556.3792873137
            ],
            [
              355827.95358952013,
              5007446.3714794517
            ],
            [
              355717.94576009456,
              5007448.4174361639
            ],
            [
              355719.99173502804,
              5007558.4252791842
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 145,
        "Layer" : "B03---BLOKOVI",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356366.89982542652,
              5006966.4057261422
            ],
            [
              356366.94807572238,
              5006966.8598105684
            ],
            [
              356367.07444392535,
              5006967.2986178556
            ],
            [
              356367.27509039908,
              5006967.7088150652
            ],
            [
              356367.54391860258,
              5006968.0779385632
            ],
            [
              356367.87276032689,
              5006968.3947727215
            ],
            [
              356368.25162388169,
              5006968.6496906886
            ],
            [
              356368.66899768921,
              5006968.8349469164
            ],
            [
              356369.11220005777,
              5006968.9449124858
            ],
            [
              356369.56776450766,
              5006968.9762461483
            ],
            [
              356370.02184894285,
              5006968.9279958513
            ],
            [
              356370.46065623901,
              5006968.8016276499
            ],
            [
              356370.87085345661,
              5006968.6009811834
            ],
            [
              356371.23997696216,
              5006968.3321529878
            ],
            [
              356371.55681112484,
              5006968.0033112774
            ],
            [
              356371.81172909983,
              5006967.6244477378
            ],
            [
              356371.99698533217,
              5006967.2070739446
            ],
            [
              356372.10695090855,
              5006966.7638715962
            ],
            [
              356372.13828457723,
              5006966.3083071643
            ],
            [
              356372.09003428125,
              5006965.8542227428
            ],
            [
              356371.96366608149,
              5006965.4154154621
            ],
            [
              356371.76301961386,
              5006965.0052182572
            ],
            [
              356371.4941914166,
              5006964.636094762
            ],
            [
              356371.16534969927,
              5006964.3192606028
            ],
            [
              356370.7864861511,
              5006964.0643426329
            ],
            [
              356370.36911234813,
              5006963.8790864004
            ],
            [
              356369.9259099827,
              5006963.7691208245
            ],
            [
              356369.47034553386,
              5006963.7377871545
            ],
            [
              356369.0162610978,
              5006963.7860374451
            ],
            [
              356368.57745379774,
              5006963.9124056408
            ],
            [
              356368.16725657432,
              5006964.1130521027
            ],
            [
              356367.79813306278,
              5006964.3818802973
            ],
            [
              356367.48129889247,
              5006964.7107220069
            ],
            [
              356367.22638091125,
              5006965.0895855511
            ],
            [
              356367.0411246735,
              5006965.5069593424
            ],
            [
              356366.93115909415,
              5006965.9501617029
            ],
            [
              356366.89982542652,
              5006966.4057261422
            ]
          ],
          [
            [
              356305.2046311099,
              5006996.7072093142
            ],
            [
              356415.2122760468,
              5006994.6614013286
            ],
            [
              356413.16648645658,
              5006884.6537765032
            ],
            [
              356303.15884006507,
              5006886.6995493257
            ],
            [
              356305.2046311099,
              5006996.7072093142
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 146,
        "Layer" : "B03---BLOKOVI",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356836.65495486581,
              5006310.6004762724
            ],
            [
              356836.70320422872,
              5006311.0545601733
            ],
            [
              356836.82957142085,
              5006311.4933671057
            ],
            [
              356837.03021683736,
              5006311.903564143
            ],
            [
              356837.29904397094,
              5006312.272687654
            ],
            [
              356837.62788464228,
              5006312.5895220088
            ],
            [
              356838.00674719503,
              5006312.8444403559
            ],
            [
              356838.42412008153,
              5006313.0296971314
            ],
            [
              356838.8673216388,
              5006313.1396633983
            ],
            [
              356839.32288540952,
              5006313.1709978906
            ],
            [
              356839.7769693209,
              5006313.1227485267
            ],
            [
              356840.21577626263,
              5006312.9963813368
            ],
            [
              356840.62597330712,
              5006312.7957359245
            ],
            [
              356840.99509682501,
              5006312.5269088009
            ],
            [
              356841.31193118641,
              5006312.1980681429
            ],
            [
              356841.56684953981,
              5006311.8192056054
            ],
            [
              356841.75210631907,
              5006311.401832737
            ],
            [
              356841.86207259219,
              5006310.9586311961
            ],
            [
              356841.89340709004,
              5006310.5030674441
            ],
            [
              356841.84515772836,
              5006310.0489835497
            ],
            [
              356841.71879053937,
              5006309.610176621
            ],
            [
              356841.51814512833,
              5006309.1999795884
            ],
            [
              356841.2493180022,
              5006308.8308560792
            ],
            [
              356840.92047733738,
              5006308.5140217235
            ],
            [
              356840.54161479074,
              5006308.2591033736
            ],
            [
              356840.12424190965,
              5006308.0738465963
            ],
            [
              356839.6810403553,
              5006307.963880321
            ],
            [
              356839.22547658515,
              5006307.932545823
            ],
            [
              356838.77139267308,
              5006307.9807951804
            ],
            [
              356838.33258572739,
              5006308.1071623666
            ],
            [
              356837.92238867719,
              5006308.3078077715
            ],
            [
              356837.55326515256,
              5006308.5766348904
            ],
            [
              356837.23643078393,
              5006308.9054755494
            ],
            [
              356836.98151242459,
              5006309.2843380924
            ],
            [
              356836.79625564028,
              5006309.7017109664
            ],
            [
              356836.68628936267,
              5006310.1449125102
            ],
            [
              356836.65495486581,
              5006310.6004762724
            ]
          ],
          [
            [
              356774.95978637651,
              5006340.9018003261
            ],
            [
              356884.96728585556,
              5006338.8562054764
            ],
            [
              356882.92170940217,
              5006228.8487261133
            ],
            [
              356772.91420847044,
              5006230.8942857943
            ],
            [
              356774.95978637651,
              5006340.9018003261
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 147,
        "Layer" : "B03---BLOKOVI",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358150.23522384936,
              5004949.0350683434
            ],
            [
              358150.28347122431,
              5004949.4891507104
            ],
            [
              358150.40983619192,
              5004949.9279564787
            ],
            [
              358150.61047921551,
              5004950.3381527523
            ],
            [
              358150.87930385867,
              5004950.7072759261
            ],
            [
              358151.20814202022,
              5004951.0241103815
            ],
            [
              358151.58700211899,
              5004951.279029266
            ],
            [
              358152.00437268172,
              5004951.4642869933
            ],
            [
              358152.44757211604,
              5004951.5742546041
            ],
            [
              358152.90313402959,
              5004951.6055907886
            ],
            [
              358153.35721640493,
              5004951.5573434131
            ],
            [
              358153.79602217983,
              5004951.4309784463
            ],
            [
              358154.20621846168,
              5004951.2303354293
            ],
            [
              358154.57534164359,
              5004950.9615107942
            ],
            [
              358154.89217610739,
              5004950.6326726479
            ],
            [
              358155.14709499653,
              5004950.2538125645
            ],
            [
              358155.3323527305,
              5004949.8364420189
            ],
            [
              358155.44232034712,
              5004949.3932426004
            ],
            [
              358155.47365653544,
              5004948.9376807036
            ],
            [
              358155.42540916137,
              5004948.483598345
            ],
            [
              358155.29904419702,
              5004948.0447925851
            ],
            [
              358155.09840117994,
              5004947.6345963161
            ],
            [
              358154.82957654278,
              5004947.2654731423
            ],
            [
              358154.50073838833,
              5004946.948638685
            ],
            [
              358154.12187829614,
              5004946.6937197996
            ],
            [
              358153.70450773794,
              5004946.5084620649
            ],
            [
              358153.26130830735,
              5004946.3984944485
            ],
            [
              358152.80574639444,
              5004946.3671582593
            ],
            [
              358152.35166401771,
              5004946.4154056273
            ],
            [
              358151.91285823908,
              5004946.5417705877
            ],
            [
              358151.50266195199,
              5004946.7424136009
            ],
            [
              358151.13353876281,
              5004947.0112382332
            ],
            [
              358150.81670429284,
              5004947.3400763832
            ],
            [
              358150.56178539683,
              5004947.7189364666
            ],
            [
              358150.37652765837,
              5004948.1363070179
            ],
            [
              358150.26656003826,
              5004948.5795064401
            ],
            [
              358150.23522384936,
              5004949.0350683434
            ]
          ],
          [
            [
              358088.54016960569,
              5004979.336031951
            ],
            [
              358198.54725877865,
              5004977.2908820752
            ],
            [
              358196.50212729961,
              5004867.2838130267
            ],
            [
              358086.49503668206,
              5004869.3289277256
            ],
            [
              358088.54016960569,
              5004979.336031951
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 148,
        "Layer" : "B03---BLOKOVI",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360087.38622969209,
              5002811.645610434
            ],
            [
              360087.43447396316,
              5002812.0996905528
            ],
            [
              360087.5608354854,
              5002812.5384946447
            ],
            [
              360087.76147482422,
              5002812.9486898687
            ],
            [
              360088.03029565694,
              5002813.3178126486
            ],
            [
              360088.3591299968,
              5002813.6346473796
            ],
            [
              360088.73798637983,
              5002813.8895671954
            ],
            [
              360089.15535344469,
              5002814.0748264855
            ],
            [
              360089.59854970657,
              5002814.1847962448
            ],
            [
              360090.05410886853,
              5002814.2161350958
            ],
            [
              360090.50818899716,
              5002814.1678908216
            ],
            [
              360090.94699309819,
              5002814.0415293025
            ],
            [
              360091.35718832898,
              5002813.8408899708
            ],
            [
              360091.726311117,
              5002813.5720691467
            ],
            [
              360092.04314585397,
              5002813.2432348207
            ],
            [
              360092.29806567595,
              5002812.8643784514
            ],
            [
              360092.48332497332,
              5002812.4470114028
            ],
            [
              360092.59329473745,
              5002812.0038151601
            ],
            [
              360092.62463359203,
              5002811.5482560135
            ],
            [
              360092.57638932159,
              5002811.0941759031
            ],
            [
              360092.45002780325,
              5002810.6553718168
            ],
            [
              360092.24938847002,
              5002810.2451765975
            ],
            [
              360091.98056764383,
              5002809.8760538185
            ],
            [
              360091.65173331049,
              5002809.5592190865
            ],
            [
              360091.27287693421,
              5002809.3042992679
            ],
            [
              360090.855509874,
              5002809.1190399723
            ],
            [
              360090.41231361526,
              5002809.0090702074
            ],
            [
              360089.95675445418,
              5002808.9777313517
            ],
            [
              360089.50267432461,
              5002809.0259756176
            ],
            [
              360089.06387021992,
              5002809.152337132
            ],
            [
              360088.65367498301,
              5002809.35297646
            ],
            [
              360088.28455218853,
              5002809.6217972804
            ],
            [
              360087.96771744505,
              5002809.9506316092
            ],
            [
              360087.71279761632,
              5002810.3294879794
            ],
            [
              360087.5275383144,
              5002810.7468550308
            ],
            [
              360087.41756854643,
              5002811.1900512828
            ],
            [
              360087.38622969209,
              5002811.645610434
            ]
          ],
          [
            [
              360025.69133261847,
              5002841.9460191904
            ],
            [
              360135.69781729247,
              5002839.9015669655
            ],
            [
              360133.65338345809,
              5002729.8951024255
            ],
            [
              360023.64689735026,
              5002731.9395194696
            ],
            [
              360025.69133261847,
              5002841.9460191904
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 149,
        "Layer" : "AGEO_Spojnica",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352935.66461948137,
              5012033.1992861098
            ],
            [
              352935.14618857001,
              5012026.3665401274
            ],
            [
              352933.85226268839,
              5012019.6374284234
            ],
            [
              352931.79971011775,
              5012013.0996752046
            ],
            [
              352929.01528899733,
              5012006.8385100542
            ],
            [
              352925.53529847634,
              5012000.9355567982
            ],
            [
              352921.40510551532,
              5011995.4677694403
            ],
            [
              352916.67855344916,
              5011990.5064289393
            ],
            [
              352911.41726006678,
              5011986.1162139522
            ],
            [
              352905.68981432787,
              5011982.3543576524
            ],
            [
              352899.57088220521,
              5011979.2699016109
            ],
            [
              352893.14023329696,
              5011976.9030564576
            ],
            [
              352886.48170091771,
              5011975.2846776731
            ],
            [
              352879.68208920007,
              5011974.4358633384
            ],
            [
              352872.83004146715,
              5011974.367679094
            ],
            [
              352866.01488464134,
              5011975.0810138751
            ],
            [
              352859.3254647245,
              5011976.5665683132
            ],
            [
              352852.84898855415,
              5011978.8049759781
            ],
            [
              352846.66988693073,
              5011981.7670558384
            ],
            [
              352840.86871392949,
              5011985.4141926831
            ],
            [
              352835.5210967554,
              5011989.6988405259
            ],
            [
              352830.69674982148,
              5011994.5651424332
            ],
            [
              352826.45856591401,
              5011999.9496587273
            ],
            [
              352822.86179628677,
              5012005.7821939867
            ],
            [
              352819.95333037333,
              5012011.9867121754
            ],
            [
              352817.77108451159,
              5012018.4823278775
            ],
            [
              352816.34350764105,
              5012025.1843607761
            ],
            [
              352815.68921042798,
              5012032.0054395786
            ],
            [
              352815.81672264537,
              5012038.8566410411
            ],
            [
              352816.72438197531,
              5012045.6486492353
            ],
            [
              352818.40035567683,
              5012052.2929198984
            ],
            [
              352820.82279484713,
              5012058.7028347608
            ],
            [
              352823.96011925943,
              5012064.7948307479
            ],
            [
              352827.77142905112,
              5012070.4894893449
            ],
            [
              352832.20703792764,
              5012075.7125719525
            ],
            [
              352837.20912089868,
              5012080.3959876942
            ],
            [
              352842.71246811672,
              5012084.4786810875
            ],
            [
              352848.6453349904,
              5012087.9074279899
            ],
            [
              352854.93037748494,
              5012090.637529471
            ],
            [
              352861.48566042818,
              5012092.6333945123
            ],
            [
              352868.2257256548,
              5012093.8690039981
            ],
            [
              352875.0627060915,
              5012094.3282499108
            ],
            [
              352881.90747123631,
              5012094.0051453263
            ],
            [
              352888.67078911193,
              5012092.9039024571
            ],
            [
              352895.26448954205,
              5012091.0388777275
            ],
            [
              352901.60261358233,
              5012088.4343846254
            ],
            [
              352907.60253412562,
              5012085.1243767357
            ],
            [
              352913.18603307172,
              5012081.1520050988
            ],
            [
              352918.28032101673,
              5012076.5690556699
            ],
            [
              352922.81898617226,
              5012071.435274207
            ],
            [
              352926.7428601436,
              5012065.8175873961
            ],
            [
              352930.00078927708,
              5012059.7892303588
            ],
            [
              352932.55030152126,
              5012053.4287919272
            ],
            [
              352934.35816011764,
              5012046.8191900989
            ],
            [
              352935.4007968877,
              5012040.0465911152
            ],
            [
              352935.66461948137,
              5012033.1992861098
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 150,
        "Layer" : "B03---BLOKOVI",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359551.68843700294,
              5003859.5136764226
            ],
            [
              359660.59499999561,
              5003857.4893430546
            ],
            [
              359658.57068465365,
              5003748.5827998267
            ],
            [
              359549.66412025335,
              5003750.6070987135
            ],
            [
              359551.68843700294,
              5003859.5136764226
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 151,
        "Layer" : "B03---BLOKOVI",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357897.21067463385,
              5005076.4861425301
            ],
            [
              358006.11775298562,
              5005074.46141202
            ],
            [
              358004.09304050572,
              5004965.5543534225
            ],
            [
              357895.18596073723,
              5004967.5790494597
            ],
            [
              357897.21067463385,
              5005076.4861425301
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 152,
        "Layer" : "B03---BLOKOVI",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357244.53425800405,
              5005695.1663401909
            ],
            [
              357353.4415386116,
              5005693.1414091066
            ],
            [
              357351.41662555927,
              5005584.2341482509
            ],
            [
              357242.50934353116,
              5005586.2590448624
            ],
            [
              357244.53425800405,
              5005695.1663401909
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 153,
        "Layer" : "B03---BLOKOVI",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356505.49080421851,
              5006786.0309012588
            ],
            [
              356614.39831098827,
              5006784.0056194849
            ],
            [
              356612.37304724538,
              5006675.0981324585
            ],
            [
              356503.46553905023,
              5006677.1233797679
            ],
            [
              356505.49080421851,
              5006786.0309012588
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 154,
        "Layer" : "B03---BLOKOVI",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355795.3074332595,
              5007438.9690866191
            ],
            [
              355904.21516014269,
              5007436.9435930178
            ],
            [
              355902.18968457246,
              5007328.0358858751
            ],
            [
              355793.28195626085,
              5007330.0613450119
            ],
            [
              355795.3074332595,
              5007438.9690866191
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 155,
        "Layer" : "B03---BLOKOVI",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354813.27728780132,
              5008574.2225005133
            ],
            [
              354922.18531728419,
              5008572.1966404431
            ],
            [
              354920.15947524545,
              5008463.2886306914
            ],
            [
              354811.25144432875,
              5008465.3144563064
            ],
            [
              354813.27728780132,
              5008574.2225005133
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 156,
        "Layer" : "B03---BLOKOVI",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352151.78284062661,
              5010503.8921923125
            ],
            [
              352260.69169815717,
              5010501.8657020256
            ],
            [
              352258.66522590513,
              5010392.9568642117
            ],
            [
              352149.75636692526,
              5010394.9833200565
            ],
            [
              352151.78284062661,
              5010503.8921923125
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 157,
        "Layer" : "B03---BLOKOVI",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351629.02286642988,
              5010631.5309919985
            ],
            [
              351737.93188841332,
              5010629.5044574384
            ],
            [
              351735.90537189139,
              5010520.5954551678
            ],
            [
              351626.99634845549,
              5010522.6219552867
            ],
            [
              351629.02286642988,
              5010631.5309919985
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 158,
        "Layer" : "B03---BLOKOVI",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351554.01384326472,
              5011775.848042638
            ],
            [
              351662.92288045445,
              5011773.8211454935
            ],
            [
              351660.89600134391,
              5011664.9121280136
            ],
            [
              351551.98696270114,
              5011666.9389907224
            ],
            [
              351554.01384326472,
              5011775.848042638
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 159,
        "Layer" : "B03---BLOKOVI",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351685.86005335307,
              5011871.4094471103
            ],
            [
              351794.7690481231,
              5011869.3825207138
            ],
            [
              351792.74213976425,
              5011760.4735456537
            ],
            [
              351683.83314354171,
              5011762.5004376108
            ],
            [
              351685.86005335307,
              5011871.4094471103
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 160,
        "Layer" : "B03---BLOKOVI",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351634.50559217,
              5012088.1450411649
            ],
            [
              351743.41460157186,
              5012086.1180458283
            ],
            [
              351741.38762426976,
              5011977.2090561343
            ],
            [
              351632.47861341579,
              5011979.2360170381
            ],
            [
              351634.50559217,
              5012088.1450411649
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 161,
        "Layer" : "B03---BLOKOVI",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356173.922504291,
              5007175.7315759612
            ],
            [
              356282.8301132202,
              5007173.7061684271
            ],
            [
              356280.8047237164,
              5007064.7985792393
            ],
            [
              356171.89711336017,
              5007066.8239523135
            ],
            [
              356173.922504291,
              5007175.7315759612
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 162,
        "Layer" : "B00011-OBUHVAT_PROSTORNOG_PLANA",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351673.42364942544,
              5012112.3365675751
            ],
            [
              351674.52056739706,
              5012115.4787234236
            ],
            [
              351681.94967008912,
              5012136.7635897743
            ],
            [
              351687.51253077603,
              5012153.7299026391
            ],
            [
              351696.56902523496,
              5012178.8078084281
            ],
            [
              351712.35497632361,
              5012191.266240079
            ],
            [
              351732.78909930895,
              5012207.641193728
            ],
            [
              351751.94608413085,
              5012223.0756331189
            ],
            [
              351770.57428950019,
              5012238.4018743224
            ],
            [
              351789.57278834324,
              5012254.1142693702
            ],
            [
              351797.34704646893,
              5012260.4569526985
            ],
            [
              351813.68550379522,
              5012273.8873962974
            ],
            [
              351829.49928329181,
              5012286.7527834149
            ],
            [
              351843.50114239851,
              5012298.0026516235
            ],
            [
              351853.58063919033,
              5012305.743933633
            ],
            [
              351862.74812699709,
              5012313.415843985
            ],
            [
              351869.08374622959,
              5012320.4818536909
            ],
            [
              351875.6045796079,
              5012327.9137811549
            ],
            [
              351890.51105712005,
              5012339.9089206308
            ],
            [
              351899.45087694359,
              5012347.189292198
            ],
            [
              351904.12154351716,
              5012350.9227741528
            ],
            [
              351909.90368678112,
              5012355.5464939773
            ],
            [
              351913.09023462387,
              5012357.8782033147
            ],
            [
              351926.15370356332,
              5012365.296717409
            ],
            [
              351936.1347330666,
              5012374.0684188912
            ],
            [
              351941.44813188474,
              5012378.660791018
            ],
            [
              351945.86381507659,
              5012382.4475828325
            ],
            [
              351948.07794896117,
              5012384.7490614075
            ],
            [
              351956.43885694764,
              5012393.3666921891
            ],
            [
              351959.27334158687,
              5012396.3363253446
            ],
            [
              351969.39652737521,
              5012406.7259062333
            ],
            [
              351980.03216502268,
              5012417.7853923971
            ],
            [
              351986.43998331513,
              5012424.3473579669
            ],
            [
              351990.50267091597,
              5012429.5096739475
            ],
            [
              351994.68338787864,
              5012435.0069795121
            ],
            [
              352005.73118926358,
              5012447.8584659537
            ],
            [
              352019.48618416314,
              5012463.6629293095
            ],
            [
              352024.87542818993,
              5012469.6878687451
            ],
            [
              352034.08057883306,
              5012479.9870931571
            ],
            [
              352042.84904952563,
              5012489.9335422991
            ],
            [
              352047.88559012016,
              5012496.7222597441
            ],
            [
              352058.89297435101,
              5012511.2654436138
            ],
            [
              352062.91123885015,
              5012515.6474158335
            ],
            [
              352083.19761590438,
              5012531.7308146954
            ],
            [
              352090.43544127577,
              5012536.8994604982
            ],
            [
              352104.06190864061,
              5012546.7468590159
            ],
            [
              352135.97795473557,
              5012570.4886858361
            ],
            [
              352142.67774800974,
              5012563.4882550221
            ],
            [
              352166.24875397579,
              5012582.4766156925
            ],
            [
              352171.89335415495,
              5012574.1763187433
            ],
            [
              352182.03100751684,
              5012578.6360905441
            ],
            [
              352184.19053843094,
              5012586.6972865276
            ],
            [
              352189.60912213905,
              5012606.1729350705
            ],
            [
              352195.42328740784,
              5012627.3421098255
            ],
            [
              352197.81140905904,
              5012636.0798188979
            ],
            [
              352202.73321803665,
              5012653.4312534034
            ],
            [
              352205.1940830035,
              5012660.1298742313
            ],
            [
              352219.47358820034,
              5012682.1946881507
            ],
            [
              352224.35952558555,
              5012688.3694503056
            ],
            [
              352225.79020746535,
              5012689.3956745267
            ],
            [
              352224.81189686962,
              5012691.0168197853
            ],
            [
              352264.86485182837,
              5012721.7030547764
            ],
            [
              352264.49197782908,
              5012728.3971199235
            ],
            [
              352265.49843862763,
              5012736.8350318233
            ],
            [
              352267.1552871748,
              5012751.8791238647
            ],
            [
              352264.18679975584,
              5012762.0297471695
            ],
            [
              352253.68975706393,
              5012780.2677615779
            ],
            [
              352273.58030849585,
              5012800.6874517342
            ],
            [
              352280.78610224073,
              5012808.0990870381
            ],
            [
              352290.97070029849,
              5012818.629456454
            ],
            [
              352300.06676702143,
              5012827.7888337234
            ],
            [
              352334.23339405697,
              5012863.3366975561
            ],
            [
              352365.01722040639,
              5012825.5461324314
            ],
            [
              352373.31848067266,
              5012815.2882525604
            ],
            [
              352381.13635504671,
              5012822.9276047135
            ],
            [
              352391.87119002274,
              5012833.5184922963
            ],
            [
              352411.34341926221,
              5012852.6596623799
            ],
            [
              352427.84432585252,
              5012868.7967337184
            ],
            [
              352444.27691041649,
              5012885.0548919244
            ],
            [
              352460.4851449714,
              5012901.3777906168
            ],
            [
              352472.97246466338,
              5012914.4088149583
            ],
            [
              352483.07810294762,
              5012924.6408176916
            ],
            [
              352500.61193095008,
              5012942.4887653245
            ],
            [
              352509.36780716392,
              5012951.2727930211
            ],
            [
              352528.94062898454,
              5012971.0902590267
            ],
            [
              352554.51995842106,
              5012988.0755325388
            ],
            [
              352581.95778539422,
              5013006.0516912676
            ],
            [
              352597.45736254478,
              5013016.5648757676
            ],
            [
              352607.13385121181,
              5013023.0760344714
            ],
            [
              352614.87628474011,
              5013028.7128081946
            ],
            [
              352635.68862456159,
              5013042.7256012773
            ],
            [
              352655.61320715677,
              5013056.4827290485
            ],
            [
              352670.05625987053,
              5013066.111953917
            ],
            [
              352675.18903204397,
              5013069.8385543935
            ],
            [
              352681.71026079927,
              5013074.2549094968
            ],
            [
              352688.04214863695,
              5013078.7442441797
            ],
            [
              352697.81191310624,
              5013085.250569135
            ],
            [
              352704.59394950944,
              5013089.9974263804
            ],
            [
              352719.96329678869,
              5013100.1795272175
            ],
            [
              352731.30205953697,
              5013107.8264319478
            ],
            [
              352739.62111259747,
              5013119.1256236723
            ],
            [
              352753.29583755258,
              5013137.8754917644
            ],
            [
              352759.6227815068,
              5013146.9260199135
            ],
            [
              352766.31234194501,
              5013156.4610289494
            ],
            [
              352772.53520168626,
              5013165.2187250657
            ],
            [
              352784.45494955109,
              5013182.0468524611
            ],
            [
              352798.38191099756,
              5013201.5511072185
            ],
            [
              352811.50922082958,
              5013220.2276056325
            ],
            [
              352824.61684074689,
              5013238.7341925092
            ],
            [
              352836.71841362916,
              5013255.7357113361
            ],
            [
              352866.1122084005,
              5013295.7430496477
            ],
            [
              352914.67218850838,
              5013238.058922722
            ],
            [
              352995.56317759625,
              5013111.9477386912
            ],
            [
              353012.03389458993,
              5013058.1613895269
            ],
            [
              353177.93304848549,
              5012865.3698986769
            ],
            [
              353282.69821111875,
              5012714.5722725485
            ],
            [
              353423.53875981033,
              5012515.2120095203
            ],
            [
              353510.7851640124,
              5012381.0611448446
            ],
            [
              353612.75845910871,
              5012220.9806686649
            ],
            [
              354009.63207439793,
              5012088.3816508055
            ],
            [
              354081.16726678528,
              5012067.3369026948
            ],
            [
              354223.65344101359,
              5011563.4159273282
            ],
            [
              354594.92489035812,
              5011102.133164621
            ],
            [
              354855.40317357157,
              5010657.7801464964
            ],
            [
              354951.22205351311,
              5010567.6371835852
            ],
            [
              354990.20663260616,
              5010526.6568256477
            ],
            [
              355147.98199810943,
              5010377.1501003429
            ],
            [
              355364.99615831458,
              5010171.5093613667
            ],
            [
              355743.61274709034,
              5009868.5410948396
            ],
            [
              355884.90814914781,
              5009757.1052042162
            ],
            [
              356004.87636030465,
              5009678.6955560613
            ],
            [
              356199.62298763217,
              5009552.9100292558
            ],
            [
              356498.24421067216,
              5009622.4909643
            ],
            [
              356662.20931215241,
              5009182.8250885168
            ],
            [
              356791.95237115165,
              5009034.8777392237
            ],
            [
              356867.67807649268,
              5008948.0852732845
            ],
            [
              356884.30730365007,
              5008929.4971377933
            ],
            [
              356891.82510608126,
              5008921.9794007074
            ],
            [
              357008.7650557704,
              5008812.4231989421
            ],
            [
              357276.95631092385,
              5008477.031385906
            ],
            [
              357347.6361142901,
              5008387.6543248612
            ],
            [
              357362.91450035031,
              5008294.9820663175
            ],
            [
              357437.81135302468,
              5008207.2960519195
            ],
            [
              357554.37492348836,
              5008092.0978550715
            ],
            [
              357665.38382056478,
              5007983.3117105374
            ],
            [
              357716.28204040957,
              5007934.7156103225
            ],
            [
              357743.48433216586,
              5007609.9146120399
            ],
            [
              357778.30758230406,
              5007603.6837910209
            ],
            [
              357813.92785244499,
              5007597.4232088979
            ],
            [
              357843.24363584345,
              5007592.3336772891
            ],
            [
              357867.56638692989,
              5007588.0727104954
            ],
            [
              357914.01871386851,
              5007579.7038252922
            ],
            [
              357942.18151398236,
              5007574.4672297947
            ],
            [
              357966.56928482308,
              5007570.3307952303
            ],
            [
              357991.47832549119,
              5007565.724415821
            ],
            [
              358018.89002485521,
              5007561.2097980296
            ],
            [
              358038.50451508042,
              5007557.7321484108
            ],
            [
              358058.21436237957,
              5007554.6279375833
            ],
            [
              358095.4115083614,
              5007547.4938023956
            ],
            [
              358151.21624042495,
              5007537.5888382923
            ],
            [
              358176.83238658385,
              5007531.7956380956
            ],
            [
              358182.48812406452,
              5007531.0600249395
            ],
            [
              358206.2992809717,
              5007526.3925627284
            ],
            [
              358223.60315562884,
              5007524.0501544569
            ],
            [
              358253.91448849184,
              5007518.7414335785
            ],
            [
              358299.48453438835,
              5007504.8262276556
            ],
            [
              358311.79416033585,
              5007467.7234806661
            ],
            [
              358322.86469756084,
              5007464.9220763547
            ],
            [
              358343.28270016913,
              5007457.9448701879
            ],
            [
              358361.69249990443,
              5007451.153975673
            ],
            [
              358403.05292945897,
              5007438.540873955
            ],
            [
              358431.75564402575,
              5007427.5666964203
            ],
            [
              358464.81190611317,
              5007414.7098578708
            ],
            [
              358486.09213226225,
              5007433.3348867586
            ],
            [
              358488.69516810833,
              5007438.4221155066
            ],
            [
              358519.7642456374,
              5007456.3878827542
            ],
            [
              358525.28867085936,
              5007458.9962598737
            ],
            [
              358552.29425279441,
              5007466.7152349018
            ],
            [
              358583.314417988,
              5007478.3940571332
            ],
            [
              358599.50435898517,
              5007484.583319257
            ],
            [
              358605.38533740217,
              5007486.7711087307
            ],
            [
              358628.59650713188,
              5007489.4956035474
            ],
            [
              358638.14086588722,
              5007496.6481422251
            ],
            [
              358657.21241042425,
              5007503.2010523248
            ],
            [
              358659.43329724873,
              5007504.3877475047
            ],
            [
              358692.33438786381,
              5007519.3512385404
            ],
            [
              358693.96571055014,
              5007520.0746504981
            ],
            [
              358708.89943556645,
              5007538.7118460387
            ],
            [
              358743.3589437335,
              5007539.5180330575
            ],
            [
              358766.17481804208,
              5007536.3979463419
            ],
            [
              358782.00808518234,
              5007537.6244297568
            ],
            [
              358826.82637700805,
              5007536.4195665438
            ],
            [
              358839.568657392,
              5007517.7832196997
            ],
            [
              358849.09805017785,
              5007502.9849889223
            ],
            [
              358876.32021067577,
              5007485.1964379391
            ],
            [
              358923.74492575537,
              5007480.2876048107
            ],
            [
              358950.2915691103,
              5007469.34723874
            ],
            [
              358965.1033837648,
              5007459.7702505291
            ],
            [
              358983.86022816936,
              5007449.9763433561
            ],
            [
              359000.39943364466,
              5007413.3048899462
            ],
            [
              359016.31404186407,
              5007395.2975364095
            ],
            [
              359043.4776895679,
              5007387.6181483995
            ],
            [
              359072.55462034681,
              5007372.9008237785
            ],
            [
              359086.02533101203,
              5007348.5283642178
            ],
            [
              359106.35355159926,
              5007337.4870462231
            ],
            [
              359117.75959750224,
              5007324.6904841494
            ],
            [
              359140.49865199882,
              5007317.6312318239
            ],
            [
              359169.5622714464,
              5007297.7204447631
            ],
            [
              359174.76043468638,
              5007283.6298313187
            ],
            [
              359202.6460522188,
              5007273.768463769
            ],
            [
              359233.48852127179,
              5007245.9209488872
            ],
            [
              359244.83724888501,
              5007210.7918837769
            ],
            [
              359247.10097676172,
              5007170.6097055078
            ],
            [
              359246.05425380642,
              5007167.9757718975
            ],
            [
              359252.47524136194,
              5007154.4668935789
            ],
            [
              359265.37697705906,
              5007139.9615764972
            ],
            [
              359287.77446715691,
              5007118.7284160443
            ],
            [
              359306.56422149477,
              5007086.8346445281
            ],
            [
              359302.57496301376,
              5007062.8199963588
            ],
            [
              359303.59157828783,
              5007038.2629999425
            ],
            [
              359310.5199548924,
              5007020.9435748709
            ],
            [
              359321.0363280662,
              5007013.3423277615
            ],
            [
              359351.74541362777,
              5006998.1487340145
            ],
            [
              359384.28911023616,
              5006965.7103006961
            ],
            [
              359389.0540582352,
              5006952.3363607293
            ],
            [
              359402.20080590545,
              5006928.0845918125
            ],
            [
              359405.42240850843,
              5006910.0330741368
            ],
            [
              359405.44952188461,
              5006909.0798464566
            ],
            [
              359448.87798652664,
              5006929.0294256341
            ],
            [
              359453.73093680164,
              5006855.1255745348
            ],
            [
              359443.71054703469,
              5006834.1416106327
            ],
            [
              359443.02462525427,
              5006830.8345500892
            ],
            [
              359443.10738450853,
              5006811.7905964321
            ],
            [
              359440.88373283023,
              5006788.1573742386
            ],
            [
              359437.13600956212,
              5006762.9486645227
            ],
            [
              359433.04275636078,
              5006754.6457799813
            ],
            [
              359437.64842896181,
              5006753.0156696532
            ],
            [
              359448.36585062952,
              5006748.4569468591
            ],
            [
              359475.56723008869,
              5006732.3535106704
            ],
            [
              359499.61155758041,
              5006704.3959892662
            ],
            [
              359501.00141302973,
              5006696.2723537479
            ],
            [
              359509.55651276984,
              5006687.0411201771
            ],
            [
              359516.8412565465,
              5006681.4631002853
            ],
            [
              359530.04766971228,
              5006676.7059046384
            ],
            [
              359548.57861955737,
              5006667.9021810098
            ],
            [
              359565.3794154856,
              5006665.1213157168
            ],
            [
              359582.16333817539,
              5006654.1599623263
            ],
            [
              359602.68265188631,
              5006635.0991526954
            ],
            [
              359611.32843642565,
              5006619.194595024
            ],
            [
              359612.66995913541,
              5006606.9599122992
            ],
            [
              359618.41765651951,
              5006607.2222714284
            ],
            [
              359634.48992216674,
              5006597.4300176343
            ],
            [
              359647.1773118811,
              5006592.9820994958
            ],
            [
              359663.42020250601,
              5006578.845220319
            ],
            [
              359669.28163092269,
              5006554.5271078534
            ],
            [
              359672.29898036906,
              5006537.2863762584
            ],
            [
              359672.95652153448,
              5006531.1478940258
            ],
            [
              359676.88667086815,
              5006525.4517393475
            ],
            [
              359696.07172678976,
              5006498.700233629
            ],
            [
              359698.23416963418,
              5006491.79017842
            ],
            [
              359711.37599731056,
              5006485.6467732452
            ],
            [
              359731.97869772272,
              5006465.4400769919
            ],
            [
              359739.9018742441,
              5006455.0848809937
            ],
            [
              359748.99271237117,
              5006451.4297563974
            ],
            [
              359754.01833242236,
              5006443.2471622247
            ],
            [
              359768.90004596714,
              5006449.5559931789
            ],
            [
              359814.09282556345,
              5006430.2861574022
            ],
            [
              359817.93429473712,
              5006420.5625376049
            ],
            [
              359820.13053931663,
              5006419.0343827847
            ],
            [
              359824.71036762261,
              5006417.1828351263
            ],
            [
              359844.04576982924,
              5006415.6136676054
            ],
            [
              359865.23545479932,
              5006396.362798227
            ],
            [
              359868.90931022691,
              5006383.8624235839
            ],
            [
              359883.38547045988,
              5006381.7713793432
            ],
            [
              359887.63108709938,
              5006378.8889993085
            ],
            [
              359907.44609456876,
              5006399.6940196306
            ],
            [
              359938.28988305695,
              5006378.7904236577
            ],
            [
              359966.38582483405,
              5006392.4838222805
            ],
            [
              360002.99791752745,
              5006370.7857894264
            ],
            [
              360006.40689636354,
              5006356.0761921247
            ],
            [
              360019.23234836751,
              5006348.5376443723
            ],
            [
              360042.3261566076,
              5006344.3886578288
            ],
            [
              360055.68280290213,
              5006318.5602079928
            ],
            [
              360056.83224535262,
              5006306.4415186457
            ],
            [
              360058.07229775348,
              5006306.2169847125
            ],
            [
              360080.37116628286,
              5006305.1472889734
            ],
            [
              360094.70104547823,
              5006305.6849647332
            ],
            [
              360117.12567244202,
              5006302.4047403689
            ],
            [
              360140.32144167129,
              5006279.2350039547
            ],
            [
              360141.09388833883,
              5006263.3364778114
            ],
            [
              360147.29607917601,
              5006255.3674504636
            ],
            [
              360156.32268737815,
              5006238.6399636604
            ],
            [
              360157.51400858106,
              5006238.3539609509
            ],
            [
              360176.96871207643,
              5006239.6584586632
            ],
            [
              360193.31949555589,
              5006223.6601134939
            ],
            [
              360198.19209340605,
              5006222.8059834028
            ],
            [
              360214.01583825453,
              5006217.2381771132
            ],
            [
              360227.67144213739,
              5006203.187325866
            ],
            [
              360234.09916344855,
              5006189.3566757282
            ],
            [
              360249.98518468009,
              5006180.3615919612
            ],
            [
              360269.22945381061,
              5006164.0798009941
            ],
            [
              360272.89234002546,
              5006159.6070516659
            ],
            [
              360286.55543794652,
              5006156.7067923434
            ],
            [
              360295.21591344249,
              5006148.3225708622
            ],
            [
              360298.31179294805,
              5006147.9759311005
            ],
            [
              360323.14439040731,
              5006148.5579495057
            ],
            [
              360337.79728248424,
              5006144.3435692592
            ],
            [
              360348.85058082663,
              5006142.5405545617
            ],
            [
              360351.8427329323,
              5006141.0966280932
            ],
            [
              360352.64066985546,
              5006141.4248274965
            ],
            [
              360373.55971964257,
              5006142.3406205056
            ],
            [
              360386.72213251778,
              5006138.2365937466
            ],
            [
              360398.69825654855,
              5006131.7945996029
            ],
            [
              360405.43987164117,
              5006127.4423008431
            ],
            [
              360416.26245222433,
              5006117.8289639428
            ],
            [
              360440.26913842798,
              5006119.0125639224
            ],
            [
              360453.32900732837,
              5006111.6327259541
            ],
            [
              360453.95103211812,
              5006111.3618858811
            ],
            [
              360457.92262127844,
              5006110.8202177128
            ],
            [
              360468.30632147775,
              5006106.4389624028
            ],
            [
              360479.67169129913,
              5006101.136266307
            ],
            [
              360488.43170671497,
              5006095.2283903379
            ],
            [
              360496.86882135499,
              5006086.7024486167
            ],
            [
              360509.76630629116,
              5006075.8938205
            ],
            [
              360510.44332675217,
              5006073.1498341784
            ],
            [
              360514.77649794664,
              5006068.7496035574
            ],
            [
              360527.49773928121,
              5006069.7132345829
            ],
            [
              360544.00166110508,
              5006054.0459582126
            ],
            [
              360565.83455404575,
              5006059.0699202865
            ],
            [
              360596.69194524508,
              5006047.063602983
            ],
            [
              360606.93036448146,
              5006042.5812472282
            ],
            [
              360620.54830692633,
              5006028.7296664566
            ],
            [
              360639.22947471339,
              5006020.7917504748
            ],
            [
              360661.8812194778,
              5006002.7478429666
            ],
            [
              360679.64358239085,
              5005989.1607033992
            ],
            [
              360703.10712492437,
              5005971.9665299496
            ],
            [
              360712.87591624091,
              5005966.3524850728
            ],
            [
              360724.56904732413,
              5005961.850678782
            ],
            [
              360737.18795051024,
              5005956.0344100362
            ],
            [
              360750.17741279537,
              5005949.9469665624
            ],
            [
              360778.12395627686,
              5005925.5400106832
            ],
            [
              360787.20140364853,
              5005914.292869023
            ],
            [
              360793.63523497112,
              5005909.5464550601
            ],
            [
              360822.67523028876,
              5005895.1513065286
            ],
            [
              360824.63707680255,
              5005893.835463875
            ],
            [
              360826.04812266276,
              5005895.7004667232
            ],
            [
              360868.79187963624,
              5005872.0325590279
            ],
            [
              360879.37535259692,
              5005867.3268360384
            ],
            [
              360891.33740830637,
              5005879.0662726006
            ],
            [
              360915.9514890727,
              5005862.4431450972
            ],
            [
              360922.88852432504,
              5005856.4414127013
            ],
            [
              360932.65193136287,
              5005848.9181915307
            ],
            [
              360941.38999730226,
              5005841.8675982496
            ],
            [
              360944.08712920581,
              5005839.3635161333
            ],
            [
              360966.35332641983,
              5005831.832434169
            ],
            [
              360970.35848037846,
              5005813.431540966
            ],
            [
              361003.83276821539,
              5005799.8152640937
            ],
            [
              360978.10066684935,
              5005759.059582564
            ],
            [
              360992.09984898404,
              5005744.5852208091
            ],
            [
              360967.06685215147,
              5005697.5912640151
            ],
            [
              360960.42578581179,
              5005685.6394766429
            ],
            [
              360951.16858445917,
              5005670.1743817749
            ],
            [
              360944.61186764983,
              5005660.5273204949
            ],
            [
              360935.86792202521,
              5005645.8427398624
            ],
            [
              360930.35535663593,
              5005637.9300536746
            ],
            [
              360924.79520250903,
              5005628.9284172673
            ],
            [
              360702.22126717545,
              5005282.8347815285
            ],
            [
              361150.83543161227,
              5004909.1363261528
            ],
            [
              361354.14704095147,
              5004464.395879453
            ],
            [
              361412.7320723047,
              5004329.0774397561
            ],
            [
              361501.16295204894,
              5004161.678953697
            ],
            [
              361789.94697871257,
              5003622.2700748583
            ],
            [
              361920.46518220479,
              5003419.8763942616
            ],
            [
              362121.15351718955,
              5003114.4460616559
            ],
            [
              362148.61743249628,
              5003086.1159386765
            ],
            [
              362187.7146058096,
              5003051.1661186786
            ],
            [
              362281.20091826934,
              5002971.1776460931
            ],
            [
              362391.90357051668,
              5002879.2869227817
            ],
            [
              362796.81372207898,
              5002418.544396515
            ],
            [
              363311.34921423701,
              5001841.3879841026
            ],
            [
              363274.70637505344,
              5001824.6177156437
            ],
            [
              363249.62504727149,
              5001811.786492059
            ],
            [
              363128.40390679665,
              5001697.9114570925
            ],
            [
              362974.85280926613,
              5001601.787480589
            ],
            [
              362839.60950366006,
              5001513.3187366035
            ],
            [
              362798.54332714085,
              5001444.6792306267
            ],
            [
              362750.17893944634,
              5001368.7449068101
            ],
            [
              362654.78063881729,
              5001294.7291665254
            ],
            [
              362601.69511589117,
              5001292.4003710318
            ],
            [
              362574.37124735181,
              5001262.2196974307
            ],
            [
              362533.42520140525,
              5001183.9952266738
            ],
            [
              362499.93795082724,
              5001112.0357870776
            ],
            [
              362489.31694372458,
              5001082.0419355519
            ],
            [
              360828.66668884916,
              4997627.5899741314
            ],
            [
              360813.48612002959,
              4997596.0957817268
            ],
            [
              360689.19285582431,
              4997652.676498537
            ],
            [
              359976.41605246166,
              4998066.510204033
            ],
            [
              359967.23995015625,
              4998194.8955355557
            ],
            [
              359771.28248794406,
              4998429.43779789
            ],
            [
              359596.63150822447,
              4998929.9441612205
            ],
            [
              359577.13344453147,
              4998985.0294886846
            ],
            [
              359270.22753780388,
              4999657.4697141843
            ],
            [
              358295.91932111088,
              5001657.4828488128
            ],
            [
              358118.62791905581,
              5002054.0541894417
            ],
            [
              357704.52826895477,
              5002780.0634539127
            ],
            [
              357073.35939101025,
              5003945.2531855758
            ],
            [
              356790.65448437235,
              5004541.0564777087
            ],
            [
              356410.23397440132,
              5004996.540543939
            ],
            [
              355781.96095283347,
              5005638.4410872888
            ],
            [
              353694.37880824978,
              5007686.9001877438
            ],
            [
              352029.77930097998,
              5009320.7471323675
            ],
            [
              351843.37463641568,
              5009552.0609578295
            ],
            [
              351577.27396584995,
              5009849.542015817
            ],
            [
              351246.7532128506,
              5010155.8885339908
            ],
            [
              350777.35193885927,
              5010687.8667861745
            ],
            [
              350794.36410230811,
              5010829.9304414932
            ],
            [
              351306.6479982888,
              5010973.4869105443
            ],
            [
              351346.11828650656,
              5011004.9078113623
            ],
            [
              351356.47279207624,
              5011056.7228920516
            ],
            [
              351332.23865395592,
              5011112.7840891546
            ],
            [
              351186.32067815808,
              5011352.9532584678
            ],
            [
              351116.67776122951,
              5011451.1754753841
            ],
            [
              351038.11440710491,
              5011540.5572825987
            ],
            [
              351052.62862562871,
              5011811.7035405021
            ],
            [
              351093.64040354692,
              5012138.4193436187
            ],
            [
              351268.14025300671,
              5012138.7326580361
            ],
            [
              351673.42364942544,
              5012112.3365675751
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 163,
        "Layer" : "MORE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357946.23338617716,
              5005027.6835623821
            ],
            [
              357966.73019326542,
              5005016.1025328077
            ],
            [
              357950.13528019568,
              5004994.8686869638
            ],
            [
              357944.10730801133,
              5004988.3278010534
            ],
            [
              357922.76189431344,
              5004960.9354314273
            ],
            [
              357926.14894841146,
              5004958.1440668656
            ],
            [
              357931.1187554003,
              5004964.3168662265
            ],
            [
              357931.94265294599,
              5004965.1099863611
            ],
            [
              357932.86583870609,
              5004965.8001615293
            ],
            [
              357933.99491636595,
              5004966.688636031
            ],
            [
              357935.64277704601,
              5004968.2748395037
            ],
            [
              357937.29439352197,
              5004970.0630646702
            ],
            [
              357939.25306833937,
              5004972.0476728138
            ],
            [
              357941.96514059923,
              5004975.1756528476
            ],
            [
              357955.70966991381,
              5004990.8862819374
            ],
            [
              357959.84424582525,
              5004995.6598964483
            ],
            [
              357973.22262726392,
              5005012.5516657848
            ],
            [
              357981.10239990376,
              5005022.9145342931
            ],
            [
              357982.65660584375,
              5005024.9066969957
            ],
            [
              357983.4805689964,
              5005025.6997800991
            ],
            [
              357984.91864947154,
              5005026.8856645711
            ],
            [
              357987.42361399235,
              5005029.3150262078
            ],
            [
              357988.86357270885,
              5005030.6019392582
            ],
            [
              357990.00022826646,
              5005031.8944555279
            ],
            [
              357991.65553505073,
              5005033.8847385487
            ],
            [
              358017.51069193013,
              5005068.1066690311
            ],
            [
              358022.0795395879,
              5005069.0322565669
            ],
            [
              358025.83374774706,
              5005069.6698343232
            ],
            [
              358029.57863149472,
              5005069.8023033943
            ],
            [
              358034.32902178908,
              5005069.6129237572
            ],
            [
              358038.47267526673,
              5005069.4348597219
            ],
            [
              358042.10706809693,
              5005069.0641365089
            ],
            [
              358045.23025644856,
              5005068.3997622961
            ],
            [
              358048.75228017819,
              5005067.4248182364
            ],
            [
              358051.97093517933,
              5005066.4555142242
            ],
            [
              358055.58466904098,
              5005064.9735832065
            ],
            [
              358058.39134278573,
              5005063.6077555856
            ],
            [
              358061.29167055956,
              5005061.8359328164
            ],
            [
              358065.45466174983,
              5005059.1724065216
            ],
            [
              358061.56172653864,
              5005056.5163865946
            ],
            [
              358063.72792391729,
              5005053.343501023
            ],
            [
              358067.5216365229,
              5005056.1024291478
            ],
            [
              358071.20266650274,
              5005052.8003174374
            ],
            [
              358075.3836333325,
              5005049.1857919255
            ],
            [
              358081.06434247753,
              5005044.6339195427
            ],
            [
              358085.55236752349,
              5005041.2157770842
            ],
            [
              358091.04214316345,
              5005037.2737645367
            ],
            [
              358095.13321035716,
              5005034.2671850901
            ],
            [
              358099.42842428829,
              5005031.3579094196
            ],
            [
              358105.65988396417,
              5005027.3552914159
            ],
            [
              358112.95458166586,
              5005022.5712868245
            ],
            [
              358119.05666216841,
              5005018.9210463241
            ],
            [
              358116.68920464284,
              5005005.8283708282
            ],
            [
              358122.5085745856,
              5005003.2949427394
            ],
            [
              358123.86840133864,
              5005011.1516541149
            ],
            [
              358127.8489783727,
              5005007.6419186266
            ],
            [
              358130.17714851297,
              5005005.648817407
            ],
            [
              358135.86543587118,
              5005001.5010239584
            ],
            [
              358140.55941802199,
              5004998.2811450427
            ],
            [
              358144.85838750127,
              5004995.5738917897
            ],
            [
              358149.55988185771,
              5004992.7580917431
            ],
            [
              358154.06299666158,
              5004990.1480712704
            ],
            [
              358158.27394530509,
              5004988.1498278677
            ],
            [
              358162.6890392949,
              5004986.2488171514
            ],
            [
              358167.60782516416,
              5004984.2373788282
            ],
            [
              358171.92745230766,
              5004982.6412979141
            ],
            [
              358175.64792074397,
              5004981.4605743801
            ],
            [
              358182.02695110039,
              5004979.7061608145
            ],
            [
              358181.95183053025,
              5004975.6654807571
            ],
            [
              358197.9518234579,
              5004976.681706693
            ],
            [
              358198.45043858065,
              5004987.1817929856
            ],
            [
              358206.12737988593,
              5004986.6348519148
            ],
            [
              358206.19630917575,
              5004979.4589178124
            ],
            [
              358225.25142808142,
              5004983.6575304801
            ],
            [
              358237.32874978171,
              5004985.858238413
            ],
            [
              358243.82621906971,
              5004987.1521539167
            ],
            [
              358247.58230288996,
              5004987.8907606993
            ],
            [
              358251.14000655338,
              5004988.8351113657
            ],
            [
              358254.79881159874,
              5004989.777617977
            ],
            [
              358259.57354695303,
              5004990.9014695305
            ],
            [
              358263.22859544982,
              5004991.6419190448
            ],
            [
              358266.16648255347,
              5004991.8904552432
            ],
            [
              358268.49199858721,
              5004991.8472213149
            ],
            [
              358271.98014703998,
              5004991.6882767575
            ],
            [
              358277.13681525941,
              5004991.5924085667
            ],
            [
              358281.07262966497,
              5004991.1150191976
            ],
            [
              358284.39996197808,
              5004990.547914302
            ],
            [
              358286.91459932958,
              5004989.7937914366
            ],
            [
              358288.52483215136,
              5004989.3596727746
            ],
            [
              358290.73428901104,
              5004988.5101601314
            ],
            [
              358293.34251546132,
              5004987.3501147265
            ],
            [
              358296.95249133394,
              5004985.6661640909
            ],
            [
              358301.36007196183,
              5004983.3611111883
            ],
            [
              358304.6610452708,
              5004981.3797505731
            ],
            [
              358308.45256466087,
              5004978.5808694074
            ],
            [
              358311.24421219103,
              5004976.4068864761
            ],
            [
              358313.13339906756,
              5004974.6538994508
            ],
            [
              358315.21125350666,
              5004972.3046392826
            ],
            [
              358281.35966200626,
              5004941.4059154876
            ],
            [
              358266.30532758916,
              5004942.2920641089
            ],
            [
              358266.23584213213,
              5004938.5544706089
            ],
            [
              358284.0182828643,
              5004937.5165050672
            ],
            [
              358317.81343984284,
              5004969.857187435
            ],
            [
              358340.53818761214,
              5004944.1385324644
            ],
            [
              358342.91034217592,
              5004941.1639480675
            ],
            [
              358343.89143380412,
              5004939.5288716312
            ],
            [
              358345.06345891941,
              5004937.2839361401
            ],
            [
              358345.53898132668,
              5004935.6582942838
            ],
            [
              358345.99941309041,
              5004933.2244967138
            ],
            [
              358346.15473072336,
              5004930.6953080874
            ],
            [
              358346.11153709219,
              5004928.3719368111
            ],
            [
              358345.8325416976,
              5004926.2769738147
            ],
            [
              358345.29323419364,
              5004924.4680717541
            ],
            [
              358344.55534849811,
              5004922.8649527309
            ],
            [
              358343.6115718417,
              5004921.0635701064
            ],
            [
              358342.36067099025,
              5004919.0658058906
            ],
            [
              358340.60795600148,
              5004917.2794621307
            ],
            [
              358338.75789604214,
              5004915.6970193796
            ],
            [
              358337.22247312067,
              5004914.7150722193
            ],
            [
              358335.48666002933,
              5004913.8378783539
            ],
            [
              358293.36343075428,
              5004887.606086649
            ],
            [
              358221.58169293706,
              5004893.585504937
            ],
            [
              358164.05844937469,
              5004898.0870152777
            ],
            [
              358148.70080800646,
              5004898.9788365113
            ],
            [
              358139.10087331414,
              5004899.4604622833
            ],
            [
              358132.8300835811,
              5004899.4759780793
            ],
            [
              358130.598088992,
              5004899.1132543543
            ],
            [
              358128.66570726625,
              5004898.5428691506
            ],
            [
              358126.42063344346,
              5004897.4730508206
            ],
            [
              358124.47510624136,
              5004896.1955722347
            ],
            [
              358122.61941125797,
              5004894.3100782074
            ],
            [
              358121.15879600612,
              5004891.911992969
            ],
            [
              358120.30290733179,
              5004889.4016018612
            ],
            [
              358120.0462444619,
              5004886.4758877996
            ],
            [
              358120.30078578828,
              5004883.8438249538
            ],
            [
              358120.9522181722,
              5004880.8001651941
            ],
            [
              358122.01368769904,
              5004878.052037443
            ],
            [
              358123.77923957194,
              5004875.0887288544
            ],
            [
              358125.96052809595,
              5004872.7239656998
            ],
            [
              358128.55373258545,
              5004870.7557986779
            ],
            [
              358131.4559389663,
              5004869.0850064605
            ],
            [
              358135.00503000879,
              5004867.6659201654
            ],
            [
              358141.43482738774,
              5004865.3232376715
            ],
            [
              358147.16624321736,
              5004863.4988205368
            ],
            [
              358153.60355251725,
              5004861.5602169055
            ],
            [
              358159.74319328048,
              5004859.9303020965
            ],
            [
              358169.21385706699,
              5004857.9353063479
            ],
            [
              358176.57059514616,
              5004856.4848563531
            ],
            [
              358183.9216335651,
              5004854.7313930709
            ],
            [
              358189.16256842605,
              5004853.7244958002
            ],
            [
              358202.24287683336,
              5004851.7495586732
            ],
            [
              358207.28717754729,
              5004851.0494718989
            ],
            [
              358213.0392498172,
              5004850.3362271758
            ],
            [
              358218.5947543722,
              5004849.9297916321
            ],
            [
              358225.06023197371,
              5004849.5064390665
            ],
            [
              358232.03503523854,
              5004849.275709155
            ],
            [
              358238.51178041106,
              5004849.4584567538
            ],
            [
              358243.77518385166,
              5004849.6637612171
            ],
            [
              358251.37168173655,
              5004850.2299100608
            ],
            [
              358258.01800054061,
              5004851.0281143803
            ],
            [
              358261.96883891436,
              5004851.3588482067
            ],
            [
              358265.82421106362,
              5004851.9945471399
            ],
            [
              358270.69447891059,
              5004852.8134697648
            ],
            [
              358275.06293241336,
              5004853.8438127851
            ],
            [
              358278.41273442446,
              5004854.4889105717
            ],
            [
              358280.44809417654,
              5004855.158444765
            ],
            [
              358282.38423070102,
              5004855.9308514735
            ],
            [
              358284.32224540942,
              5004856.8042866923
            ],
            [
              358287.89309436816,
              5004858.4557671882
            ],
            [
              358292.88887653622,
              5004860.5860387348
            ],
            [
              358300.11658519931,
              5004863.0789997065
            ],
            [
              358306.53166582133,
              5004865.3849766124
            ],
            [
              358310.15573020186,
              5004866.761782282
            ],
            [
              358315.6476254671,
              5004868.3775488539
            ],
            [
              358321.94282511662,
              5004869.6752266027
            ],
            [
              358329.14799817378,
              5004870.9560227552
            ],
            [
              358334.92830360163,
              5004871.757991109
            ],
            [
              358340.0962387764,
              5004872.2682246966
            ],
            [
              358342.92920242669,
              5004872.3166211629
            ],
            [
              358361.91015929263,
              5004872.5336587885
            ],
            [
              358367.16417119297,
              5004872.2338914182
            ],
            [
              358371.11319704721,
              5004872.4636307061
            ],
            [
              358373.13165449403,
              5004872.2240147693
            ],
            [
              358375.34667877981,
              5004871.6775542693
            ],
            [
              358378.26202912896,
              5004870.7138916347
            ],
            [
              358380.76727706281,
              5004869.4547339976
            ],
            [
              358382.86805519892,
              5004868.2030602284
            ],
            [
              358385.15795497026,
              5004866.2404977987
            ],
            [
              358387.34105384647,
              5004863.9767662231
            ],
            [
              358389.31826181803,
              5004861.514771075
            ],
            [
              358390.78232226311,
              5004858.658133205
            ],
            [
              358391.93744600465,
              5004855.5040484965
            ],
            [
              358392.68997884868,
              5004852.4585116599
            ],
            [
              358393.04374276713,
              5004849.7235429026
            ],
            [
              358392.79083616397,
              5004846.9998525716
            ],
            [
              358392.34692970902,
              5004844.8860223992
            ],
            [
              358391.80192255159,
              5004842.774071754
            ],
            [
              358391.56333670631,
              5004840.9811213762
            ],
            [
              358391.08750441868,
              5004837.1499845348
            ],
            [
              358391.01614194951,
              5004833.3113638759
            ],
            [
              358391.25378181419,
              5004829.7701533362
            ],
            [
              358391.70489092427,
              5004826.8312836224
            ],
            [
              358392.26836788707,
              5004824.4965987168
            ],
            [
              358393.32420315273,
              5004821.4454233805
            ],
            [
              358394.7808181041,
              5004818.1847060611
            ],
            [
              358396.53885704401,
              5004814.8173217149
            ],
            [
              358398.10589607939,
              5004812.0597975319
            ],
            [
              358400.18044937943,
              5004809.393901797
            ],
            [
              358402.46277173195,
              5004807.0272982111
            ],
            [
              358406.65124852723,
              5004803.8168220455
            ],
            [
              358412.18795235496,
              5004799.7076858012
            ],
            [
              358418.48471932224,
              5004795.6496150158
            ],
            [
              358444.09127960599,
              5004780.2179708136
            ],
            [
              358459.59640703927,
              5004770.9361478966
            ],
            [
              358466.50172140374,
              5004766.9677928528
            ],
            [
              358469.6594759234,
              5004765.1754767001
            ],
            [
              358474.17942418542,
              5004763.4746480063
            ],
            [
              358476.2895916386,
              5004762.7280464806
            ],
            [
              358479.51763301011,
              5004762.2638177909
            ],
            [
              358484.67436444608,
              5004762.1679518782
            ],
            [
              358487.91555156355,
              5004762.4108513668
            ],
            [
              358490.65680279379,
              5004762.9661994623
            ],
            [
              358493.81379066367,
              5004764.1201279843
            ],
            [
              358496.56630951533,
              5004765.281575745
            ],
            [
              358499.02116012882,
              5004766.7517118482
            ],
            [
              358501.17452059244,
              5004768.328516148
            ],
            [
              358503.12943535438,
              5004770.1111008199
            ],
            [
              358505.49069660233,
              5004771.9871591264
            ],
            [
              358507.95493735472,
              5004773.9624018893
            ],
            [
              358510.10454146127,
              5004775.3371491926
            ],
            [
              358513.47506449098,
              5004777.0934169367
            ],
            [
              358516.23133890313,
              5004778.4568858985
            ],
            [
              358519.44328484737,
              5004779.7435956988
            ],
            [
              358523.92034766352,
              5004781.1761376727
            ],
            [
              358527.9779174393,
              5004781.8080423633
            ],
            [
              358532.03548782802,
              5004782.439982567
            ],
            [
              358536.18483491108,
              5004782.5649353135
            ],
            [
              358539.82485665096,
              5004782.4972654935
            ],
            [
              358543.45917825319,
              5004782.1265471019
            ],
            [
              358548.33472800796,
              5004781.2940185359
            ],
            [
              358554.58673047257,
              5004780.2683271272
            ],
            [
              358565.36994684831,
              5004778.1478713611
            ],
            [
              358577.26352517394,
              5004775.9057457605
            ],
            [
              358583.90866209025,
              5004774.2664369745
            ],
            [
              358589.24505792029,
              5004772.954612853
            ],
            [
              358588.96360833856,
              5004763.2589012291
            ],
            [
              358601.34085059358,
              5004762.0144368187
            ],
            [
              358614.915470857,
              5004752.2632621853
            ],
            [
              358625.34116537956,
              5004752.6757531762
            ],
            [
              358633.53117900726,
              5004752.5234974548
            ],
            [
              358640.00040459371,
              5004752.3021685593
            ],
            [
              358647.07066634035,
              5004751.7665117187
            ],
            [
              358651.86981110647,
              5004751.2411892107
            ],
            [
              358655.09972900565,
              5004750.8779892866
            ],
            [
              358658.22291241965,
              5004750.213619167
            ],
            [
              358660.94162700762,
              5004749.5567682963
            ],
            [
              358679.77807176515,
              5004745.366647128
            ],
            [
              358699.24736498139,
              5004740.5200251946
            ],
            [
              358700.85766178544,
              5004740.085871459
            ],
            [
              358701.8594110845,
              5004739.5620030835
            ],
            [
              358702.95468279457,
              5004738.6321783867
            ],
            [
              358703.95267577993,
              5004737.9062532214
            ],
            [
              358704.84950254794,
              5004737.1822443297
            ],
            [
              358721.67968336475,
              5004715.6668302938
            ],
            [
              358725.3231513311,
              5004710.3444094202
            ],
            [
              358726.30987741431,
              5004709.0124206366
            ],
            [
              358726.39595418231,
              5004708.20238508
            ],
            [
              358726.38656477368,
              5004707.6973141907
            ],
            [
              358726.17490787653,
              5004707.1960035078
            ],
            [
              358720.73905034486,
              5004703.153959604
            ],
            [
              358718.86113186,
              5004705.5130423522
            ],
            [
              358716.80699618644,
              5004703.8333663065
            ],
            [
              358719.95991820114,
              5004699.3284646925
            ],
            [
              358728.78319757781,
              5004706.035924945
            ],
            [
              358730.94751653465,
              5004702.7620548857
            ],
            [
              358733.11190176272,
              5004699.4881836362
            ],
            [
              358737.13160349493,
              5004692.6429970097
            ],
            [
              358740.26574737433,
              5004687.1279895585
            ],
            [
              358745.18316718272,
              5004678.5799960867
            ],
            [
              358749.18221234897,
              5004670.6236398425
            ],
            [
              358752.38553212344,
              5004663.3894484388
            ],
            [
              358753.62861146568,
              5004659.5263606021
            ],
            [
              358754.38872338313,
              5004656.8849041676
            ],
            [
              358754.55336661555,
              5004654.8608262818
            ],
            [
              358754.21626240626,
              5004653.0481669931
            ],
            [
              358753.58893570845,
              5004651.9482749673
            ],
            [
              358752.97663206531,
              5004651.6565034138
            ],
            [
              358746.80319851235,
              5004651.4681139085
            ],
            [
              358746.75249497528,
              5004648.7406672826
            ],
            [
              358756.97598416795,
              5004649.1569202151
            ],
            [
              358758.1442541912,
              5004646.7099671783
            ],
            [
              358759.51472544408,
              5004644.2592552174
            ],
            [
              358760.98636356846,
              5004641.8066625753
            ],
            [
              358763.37895289954,
              5004638.555210758
            ],
            [
              358765.54897224967,
              5004635.5843898281
            ],
            [
              358767.7170472354,
              5004632.5125417868
            ],
            [
              358769.48447539698,
              5004629.6502328692
            ],
            [
              358771.04970302427,
              5004626.7917184513
            ],
            [
              358771.63008184638,
              5004625.3661852581
            ],
            [
              358772.01570569567,
              5004624.3485257486
            ],
            [
              358772.80586381158,
              5004623.3233103864
            ],
            [
              358777.06156999181,
              5004618.2926641041
            ],
            [
              358779.02751076681,
              5004615.2246095026
            ],
            [
              358780.20517022425,
              5004613.2827275516
            ],
            [
              358781.27227357012,
              5004610.8376554921
            ],
            [
              358781.84701941215,
              5004609.1091083102
            ],
            [
              358782.12597459828,
              5004607.7902419455
            ],
            [
              358782.10531796335,
              5004606.6790719442
            ],
            [
              358781.89077005879,
              5004605.3876515254
            ],
            [
              358774.63302685221,
              5004601.2784122052
            ],
            [
              358776.29935463442,
              5004598.4179829648
            ],
            [
              358782.93728308706,
              5004601.8314082753
            ],
            [
              358784.20665396116,
              5004599.3825761275
            ],
            [
              358786.27557225735,
              5004596.4135995433
            ],
            [
              358789.33859620785,
              5004592.5167152192
            ],
            [
              358791.80816203082,
              5004589.3382352283
            ],
            [
              358794.68219585397,
              5004586.15220071
            ],
            [
              358796.66309279465,
              5004583.8922325168
            ],
            [
              358799.54276095255,
              5004581.0092832018
            ],
            [
              358801.72585909354,
              5004578.7455561701
            ],
            [
              358803.20313116716,
              5004576.5960136848
            ],
            [
              358804.77768204123,
              5004574.2425717954
            ],
            [
              358806.24556492426,
              5004571.5879585706
            ],
            [
              358808.04338032176,
              5004567.8432789268
            ],
            [
              358809.10485025495,
              5004565.0951576345
            ],
            [
              358809.48490684421,
              5004563.7744474988
            ],
            [
              358809.956674179,
              5004561.9467516141
            ],
            [
              358810.42844152392,
              5004560.119055734
            ],
            [
              358810.89638642146,
              5004558.0893043503
            ],
            [
              358811.27644303575,
              5004556.7685942324
            ],
            [
              358812.26129070908,
              5004555.3355423203
            ],
            [
              358821.28585196793,
              5004545.6689665113
            ],
            [
              358822.57400209195,
              5004544.2303118883
            ],
            [
              358823.46901645686,
              5004543.4052386163
            ],
            [
              358824.27413189883,
              5004543.1881806692
            ],
            [
              358825.08300287352,
              5004543.1731439224
            ],
            [
              358826.69517743768,
              5004542.8400196331
            ],
            [
              358827.70061595523,
              5004542.5181744518
            ],
            [
              358828.50204144599,
              5004542.0990585517
            ],
            [
              358829.60106948373,
              5004541.3712915536
            ],
            [
              358830.6982850128,
              5004540.5424594274
            ],
            [
              358833.57983068662,
              5004537.7605035864
            ],
            [
              358849.53897026594,
              5004523.1295853443
            ],
            [
              358861.6547893071,
              5004511.0813395306
            ],
            [
              358868.1218953997,
              5004505.3022504915
            ],
            [
              358870.11781515134,
              5004503.8504034542
            ],
            [
              358871.61567810213,
              5004502.8120682016
            ],
            [
              358873.81574333273,
              5004501.4574894551
            ],
            [
              358875.81541923532,
              5004500.207699161
            ],
            [
              358878.32066425908,
              5004498.9485105211
            ],
            [
              358875.35837028339,
              5004497.3867441239
            ],
            [
              358874.37922239018,
              5004499.1228438923
            ],
            [
              358870.5144686086,
              5004497.9820714137
            ],
            [
              358873.85269298236,
              5004492.5643018326
            ],
            [
              358880.89320831018,
              5004495.8691454129
            ],
            [
              358881.57656614814,
              5004494.5427618185
            ],
            [
              358882.16633574531,
              5004493.6223710468
            ],
            [
              358883.1605732282,
              5004492.6944258148
            ],
            [
              358884.35701185797,
              5004491.7627217341
            ],
            [
              358885.75565163087,
              5004490.8272588169
            ],
            [
              358887.05694633035,
              5004490.0956964865
            ],
            [
              358888.76083214598,
              5004489.2556224251
            ],
            [
              358890.35979497014,
              5004488.215372338
            ],
            [
              358891.55623358721,
              5004487.2836682964
            ],
            [
              358892.85377277219,
              5004486.3500848375
            ],
            [
              358894.94898186455,
              5004484.7953645512
            ],
            [
              358896.34386611631,
              5004483.6578805363
            ],
            [
              358898.13007366488,
              5004481.8057502825
            ],
            [
              358899.96931684751,
              5004479.9972470021
            ],
            [
              358904.23635695688,
              5004475.5727021014
            ],
            [
              358906.31084358162,
              5004472.9068135545
            ],
            [
              358907.5933601291,
              5004471.1651100721
            ],
            [
              358908.87399860559,
              5004469.3223782619
            ],
            [
              358909.97121405805,
              5004468.4935465558
            ],
            [
              358911.16953073413,
              5004467.6628709761
            ],
            [
              358912.07386851136,
              5004467.3429055093
            ],
            [
              358912.88086120924,
              5004467.2268406032
            ],
            [
              358914.28895689483,
              5004466.7964827912
            ],
            [
              358915.99653186748,
              5004466.158431286
            ],
            [
              358917.10125893907,
              5004465.7336774217
            ],
            [
              358918.69841054193,
              5004464.5924334945
            ],
            [
              358920.49776326743,
              5004463.4474307494
            ],
            [
              358922.19782658509,
              5004462.4053014619
            ],
            [
              358924.4989922152,
              5004461.0488437591
            ],
            [
              358928.30177615531,
              5004458.8560723225
            ],
            [
              358931.79562483798,
              5004456.3658897113
            ],
            [
              358935.18830669235,
              5004453.8775877925
            ],
            [
              358940.70912493014,
              5004449.1458849618
            ],
            [
              358945.98354751657,
              5004444.5005232794
            ],
            [
              358949.96412087308,
              5004440.9908027546
            ],
            [
              358953.5402913483,
              5004437.4885644047
            ],
            [
              358955.42196587002,
              5004435.331505849
            ],
            [
              358957.59379758633,
              5004432.4617174137
            ],
            [
              358959.3668599751,
              5004429.9024605453
            ],
            [
              358960.05209597648,
              5004428.6771057127
            ],
            [
              358960.84225424711,
              5004427.651891361
            ],
            [
              358961.92625893123,
              5004426.1159694782
            ],
            [
              358962.41117190081,
              5004424.9954017922
            ],
            [
              358959.81766891322,
              5004421.5068268506
            ],
            [
              358962.00458946545,
              5004419.4451573044
            ],
            [
              358963.97264423914,
              5004421.9348332547
            ],
            [
              358965.89187604183,
              5004421.7981281104
            ],
            [
              358967.10145901836,
              5004421.573516164
            ],
            [
              358968.10877529968,
              5004421.3526997911
            ],
            [
              358969.11240171205,
              5004420.9298255416
            ],
            [
              358970.61589800235,
              5004420.1945403852
            ],
            [
              358971.81984744366,
              5004419.6668790122
            ],
            [
              358974.53674904857,
              5004418.9090373479
            ],
            [
              358976.67781895114,
              5004417.6822496373
            ],
            [
              358977.97535800643,
              5004416.7486666748
            ],
            [
              358979.26726354368,
              5004415.5120342933
            ],
            [
              358980.14725532831,
              5004413.8788417792
            ],
            [
              358980.6302240027,
              5004412.6572470842
            ],
            [
              358980.8062018429,
              5004411.2392682545
            ],
            [
              358981.3753141893,
              5004409.2076374479
            ],
            [
              358982.2589958586,
              5004407.7765028318
            ],
            [
              358983.25323328702,
              5004406.8485581176
            ],
            [
              358984.05271493638,
              5004406.3284511743
            ],
            [
              358985.25854240748,
              5004405.9018181916
            ],
            [
              358987.46980805637,
              5004405.153340579
            ],
            [
              358988.87227001024,
              5004404.4199337
            ],
            [
              358990.07058590726,
              5004403.5892229974
            ],
            [
              358991.35873599892,
              5004402.1505696112
            ],
            [
              358992.63937453605,
              5004400.3078385079
            ],
            [
              358993.81703514338,
              5004398.3659940325
            ],
            [
              358997.04289171094,
              5004392.3439724017
            ],
            [
              358998.70734277467,
              5004389.3825532403
            ],
            [
              359000.67697286396,
              5004386.5164887086
            ],
            [
              359001.86777854705,
              5004385.2817714494
            ],
            [
              359003.25890659855,
              5004383.9422315694
            ],
            [
              359004.9552805656,
              5004382.698080604
            ],
            [
              359006.55236578692,
              5004381.5568385879
            ],
            [
              359008.15515008016,
              5004380.718609212
            ],
            [
              359010.35890476668,
              5004379.5660896404
            ],
            [
              359012.3642138057,
              5004378.61934995
            ],
            [
              359014.87509176455,
              5004377.6632120879
            ],
            [
              359017.08266810392,
              5004376.7127124118
            ],
            [
              359018.78467550856,
              5004375.7716109212
            ],
            [
              359021.38726505265,
              5004374.3085232424
            ],
            [
              359024.43161895638,
              5004372.555362693
            ],
            [
              359034.64540868613,
              5004367.0097849164
            ],
            [
              359035.74450202967,
              5004366.2819821453
            ],
            [
              359036.33990451734,
              5004365.6646058466
            ],
            [
              359036.73116231139,
              5004364.9499967862
            ],
            [
              359037.48564240988,
              5004362.0054937946
            ],
            [
              359037.68033195881,
              5004361.597657348
            ],
            [
              359038.08480032621,
              5004361.5901385751
            ],
            [
              359038.49671394954,
              5004361.9866987122
            ],
            [
              359039.62773004902,
              5004362.9761638977
            ],
            [
              359040.34113346663,
              5004363.266056343
            ],
            [
              359040.94780288631,
              5004363.2547787949
            ],
            [
              359041.44961635181,
              5004363.0433595683
            ],
            [
              359041.94955177512,
              5004362.73091204
            ],
            [
              359042.3445649941,
              5004362.2183240624
            ],
            [
              359038.65736716223,
              5004354.3037966928
            ],
            [
              359045.14587818133,
              5004348.4711712766
            ],
            [
              359042.97374465287,
              5004345.8841904132
            ],
            [
              359047.05541842751,
              5004342.3725926401
            ],
            [
              359048.2950464561,
              5004343.7642561514
            ],
            [
              359056.36299242458,
              5004337.0459528156
            ],
            [
              359054.49228325096,
              5004334.3523410689
            ],
            [
              359060.36210816813,
              5004329.0896064946
            ],
            [
              359056.10760966624,
              5004323.3077033935
            ],
            [
              359062.61978270829,
              5004319.9530159812
            ],
            [
              359066.06102775485,
              5004323.5212073196
            ],
            [
              359069.29094223265,
              5004323.1580118602
            ],
            [
              359073.3222818714,
              5004322.3757013213
            ],
            [
              359075.64028108638,
              5004321.9283944108
            ],
            [
              359077.04831003881,
              5004321.49803863
            ],
            [
              359078.15115874348,
              5004320.9722571429
            ],
            [
              359079.65472102544,
              5004320.236971424
            ],
            [
              359083.55672664446,
              5004317.9412948778
            ],
            [
              359092.68081105361,
              5004313.62859252
            ],
            [
              359099.8052862378,
              5004310.5656775208
            ],
            [
              359104.02749347762,
              5004309.1735110609
            ],
            [
              359108.85455885972,
              5004307.6690732138
            ],
            [
              359112.07696150983,
              5004306.9018003596
            ],
            [
              359114.49237185234,
              5004306.2505919766
            ],
            [
              359116.40596878767,
              5004305.8108026991
            ],
            [
              359118.62286789005,
              5004305.3654108588
            ],
            [
              359120.83795454464,
              5004304.8189539798
            ],
            [
              359123.05109756981,
              5004304.1715055965
            ],
            [
              359133.97606728092,
              5004300.7465201681
            ],
            [
              359141.71659976436,
              5004298.1773986761
            ],
            [
              359145.42767130671,
              5004296.4916156195
            ],
            [
              359148.02838221187,
              5004294.9275008105
            ],
            [
              359150.22462486732,
              5004293.3709047129
            ],
            [
              359151.3143292137,
              5004292.138032143
            ],
            [
              359151.70001922024,
              5004291.1203373633
            ],
            [
              359151.87780858512,
              5004289.8033532668
            ],
            [
              359151.75041910919,
              5004288.3910145257
            ],
            [
              359151.40198418061,
              5004285.9722596435
            ],
            [
              359150.92622606939,
              5004282.141164775
            ],
            [
              359150.87177015911,
              5004279.2117002886
            ],
            [
              359151.42586085742,
              5004276.3719511479
            ],
            [
              359152.19154198823,
              5004274.033549116
            ],
            [
              359153.26434624213,
              5004271.8915287908
            ],
            [
              359154.54122951051,
              5004269.8467780352
            ],
            [
              359156.42290399264,
              5004267.689721657
            ],
            [
              359158.30271898984,
              5004266.0057766009
            ],
            [
              359158.61163532536,
              5004265.7290482316
            ],
            [
              359160.20690846461,
              5004264.4867777703
            ],
            [
              359162.61480716034,
              5004263.4314922439
            ],
            [
              359164.9252951243,
              5004262.580143854
            ],
            [
              359167.24523806525,
              5004262.2338645002
            ],
            [
              359169.668159494,
              5004261.9867340624
            ],
            [
              359173.16183778777,
              5004261.9617461879
            ],
            [
              359176.29816222598,
              5004262.004508296
            ],
            [
              359178.61810509203,
              5004261.658228959
            ],
            [
              359180.33131251327,
              5004261.3232282167
            ],
            [
              359182.14193064155,
              5004260.7842903677
            ],
            [
              359183.74847043731,
              5004260.148118509
            ],
            [
              359185.25008812739,
              5004259.3118062569
            ],
            [
              359187.14302925119,
              5004257.7608486153
            ],
            [
              359188.93299253652,
              5004256.1107776221
            ],
            [
              359189.79083982768,
              5004254.9527348075
            ],
            [
              359190.70229969634,
              5004253.3495024005
            ],
            [
              359191.27892400924,
              5004251.7219505934
            ],
            [
              359191.76008121134,
              5004250.3993273824
            ],
            [
              359192.12692760519,
              5004248.3714935565
            ],
            [
              359192.40588384913,
              5004247.0526290545
            ],
            [
              359193.08924269542,
              5004245.7262826916
            ],
            [
              359194.9690391128,
              5004243.4681984261
            ],
            [
              359195.76476450701,
              5004242.7460356727
            ],
            [
              359197.06793657196,
              5004242.1155031985
            ],
            [
              359198.57525385747,
              5004241.5822390597
            ],
            [
              359199.28302348475,
              5004241.5690823868
            ],
            [
              359199.79422550433,
              5004241.8627336472
            ],
            [
              359200.10691565991,
              5004242.3621658115
            ],
            [
              359200.42155007477,
              5004242.9626249997
            ],
            [
              359200.6462844747,
              5004244.1710632322
            ],
            [
              359201.50961084227,
              5004247.0855268063
            ],
            [
              359202.1313020626,
              5004247.8823689111
            ],
            [
              359202.7454825723,
              5004248.2751690606
            ],
            [
              359203.55998603516,
              5004248.5631822571
            ],
            [
              359204.16665517155,
              5004248.5519049205
            ],
            [
              359205.47546055721,
              5004248.2244216735
            ],
            [
              359208.09877093177,
              5004247.8725031735
            ],
            [
              359210.51599180832,
              5004247.3222893411
            ],
            [
              359212.02894238877,
              5004247.0920744203
            ],
            [
              359213.34338110877,
              5004247.0676404079
            ],
            [
              359214.4574968752,
              5004247.147993343
            ],
            [
              359215.27012230194,
              5004247.3349782778
            ],
            [
              359215.68022382521,
              5004247.6305088671
            ],
            [
              359216.08838108898,
              5004247.8250124333
            ],
            [
              359216.39174877276,
              5004247.8193731494
            ],
            [
              359217.32504750148,
              5004246.8184090378
            ],
            [
              359223.77149530768,
              5004239.9281268204
            ],
            [
              359223.04300406389,
              5004238.8301161146
            ],
            [
              359222.72280257539,
              5004237.9266065182
            ],
            [
              359222.39509039273,
              5004236.6190549927
            ],
            [
              359222.37631233205,
              5004235.6088791005
            ],
            [
              359222.55597987818,
              5004234.3929235525
            ],
            [
              359222.94348172896,
              5004233.4762585294
            ],
            [
              359223.63629551255,
              5004232.6549812555
            ],
            [
              359225.4299471628,
              5004231.2068972234
            ],
            [
              359228.62424907548,
              5004228.9244136121
            ],
            [
              359230.42353472108,
              5004227.7794143641
            ],
            [
              359231.81848406064,
              5004226.6418957375
            ],
            [
              359237.13364819001,
              5004222.1525510205
            ],
            [
              359238.62218731444,
              5004220.609111188
            ],
            [
              359239.11454499332,
              5004219.8925878331
            ],
            [
              359239.40289064433,
              5004219.0788292233
            ],
            [
              359239.98890437215,
              5004217.9563833922
            ],
            [
              359240.47381697252,
              5004216.8357813675
            ],
            [
              359241.26390964375,
              5004215.8106051078
            ],
            [
              359242.15698012355,
              5004214.8845422184
            ],
            [
              359243.85335352988,
              5004213.6403929554
            ],
            [
              359245.14901413897,
              5004212.6057832446
            ],
            [
              359246.04208461783,
              5004211.6797203748
            ],
            [
              359246.72550974629,
              5004210.3533730591
            ],
            [
              359247.50809043896,
              5004208.9240838597
            ],
            [
              359248.47597275273,
              5004206.5819239533
            ],
            [
              359249.36159883201,
              5004205.2518179808
            ],
            [
              359250.95680551959,
              5004204.0095493579
            ],
            [
              359252.45848864497,
              5004203.1732007489
            ],
            [
              359253.66619342525,
              5004202.8475970533
            ],
            [
              359254.77648747165,
              5004202.7259304114
            ],
            [
              359256.29507054895,
              5004202.7987293685
            ],
            [
              359257.71812088561,
              5004203.1764936149
            ],
            [
              359258.63372464484,
              5004203.462627532
            ],
            [
              359264.59338192729,
              5004197.5919206301
            ],
            [
              359260.01278483344,
              5004194.674856564
            ],
            [
              359258.98481399025,
              5004193.7845035931
            ],
            [
              359258.45858949021,
              5004192.6826974684
            ],
            [
              359258.12893327052,
              5004191.2741190232
            ],
            [
              359258.10640002467,
              5004190.0619222913
            ],
            [
              359258.38535710186,
              5004188.7430936266
            ],
            [
              359260.23510901403,
              5004184.868735957
            ],
            [
              359261.20493569743,
              5004182.6276032124
            ],
            [
              359261.98000584234,
              5004180.7942722542
            ],
            [
              359262.65209754446,
              5004178.8617923958
            ],
            [
              359263.22684476519,
              5004177.1332483497
            ],
            [
              359263.61816828715,
              5004176.418603207
            ],
            [
              359264.3203044862,
              5004176.1023974931
            ],
            [
              359264.82392947644,
              5004175.9920081478
            ],
            [
              359265.33325335599,
              5004176.1846312089
            ],
            [
              359265.94555568573,
              5004176.4764031796
            ],
            [
              359266.35753507481,
              5004176.8729620464
            ],
            [
              359266.57287992502,
              5004177.5762944398
            ],
            [
              359266.69651358837,
              5004178.7866118141
            ],
            [
              359266.50933523983,
              5004179.5985253025
            ],
            [
              359266.11988905497,
              5004180.4141631452
            ],
            [
              359265.62565334531,
              5004181.0296581499
            ],
            [
              359265.43653009966,
              5004181.7405090965
            ],
            [
              359265.44591937988,
              5004182.2456147484
            ],
            [
              359265.86353142053,
              5004182.9451872474
            ],
            [
              359266.37473329069,
              5004183.2388385395
            ],
            [
              359266.6780346795,
              5004183.2332005445
            ],
            [
              359266.97389168659,
              5004182.8235194432
            ],
            [
              359267.06184755324,
              5004182.1145135248
            ],
            [
              359267.34449371567,
              5004180.9977070708
            ],
            [
              359267.8312843857,
              5004179.9781334242
            ],
            [
              359268.62513241905,
              5004179.1549782678
            ],
            [
              359269.42280201783,
              5004178.5338428188
            ],
            [
              359270.12493820139,
              5004178.2176371068
            ],
            [
              359270.73160722299,
              5004178.2063598996
            ],
            [
              359272.44669225608,
              5004177.9723878987
            ],
            [
              359272.94662751228,
              5004177.6599408491
            ],
            [
              359273.24248386099,
              5004177.2502242252
            ],
            [
              359273.33607303625,
              5004176.8442674903
            ],
            [
              359273.22746195103,
              5004176.4421049487
            ],
            [
              359272.81736055703,
              5004176.1465743259
            ],
            [
              359272.3099140132,
              5004176.0549439676
            ],
            [
              359271.70324499469,
              5004176.0662211729
            ],
            [
              359269.47883608378,
              5004176.1075701034
            ],
            [
              359268.76918861177,
              5004176.0196984001
            ],
            [
              359268.15688628826,
              5004175.7279264312
            ],
            [
              359267.84600802563,
              5004175.3295237711
            ],
            [
              359267.73176298215,
              5004174.6242765142
            ],
            [
              359267.72049638844,
              5004174.018178165
            ],
            [
              359267.91143072123,
              5004173.4083211571
            ],
            [
              359269.40184791753,
              5004171.9659099085
            ],
            [
              359269.80061647017,
              5004171.6553434236
            ],
            [
              359270.50463064609,
              5004171.4401659658
            ],
            [
              359271.51388978033,
              5004171.3203419903
            ],
            [
              359273.53609775659,
              5004171.2827517465
            ],
            [
              359275.96465179842,
              5004171.3386711953
            ],
            [
              359277.9943703679,
              5004171.7051228154
            ],
            [
              359278.90440700488,
              5004171.6882064035
            ],
            [
              359279.70952162048,
              5004171.4711496234
            ],
            [
              359280.30867934664,
              5004171.0557950279
            ],
            [
              359281.40213969193,
              5004170.0249797786
            ],
            [
              359281.98996458092,
              5004169.0035280967
            ],
            [
              359282.16969858407,
              5004167.7875715606
            ],
            [
              359282.15092072909,
              5004166.7773958407
            ],
            [
              359282.03479838622,
              5004165.9711559135
            ],
            [
              359281.81187604513,
              5004164.8637473788
            ],
            [
              359281.79873148666,
              5004164.1566208191
            ],
            [
              359282.95010334201,
              5004160.8005256727
            ],
            [
              359283.54550577368,
              5004160.1831501732
            ],
            [
              359284.3524983669,
              5004160.0671216529
            ],
            [
              359285.16518983361,
              5004160.2541055251
            ],
            [
              359285.68014695891,
              5004160.7497777613
            ],
            [
              359286.1014486418,
              5004161.6514079394
            ],
            [
              359286.42547208705,
              5004162.7569726743
            ],
            [
              359286.75693923206,
              5004164.2665449558
            ],
            [
              359287.08284000756,
              5004165.4731023805
            ],
            [
              359287.49475310609,
              5004165.8696624693
            ],
            [
              359288.10517740372,
              5004166.0604062071
            ],
            [
              359289.22304821556,
              5004166.3427803162
            ],
            [
              359290.83891069208,
              5004166.211715945
            ],
            [
              359291.94551491836,
              5004165.8879917441
            ],
            [
              359292.74311823491,
              5004165.266857598
            ],
            [
              359293.23742018588,
              5004164.6513614357
            ],
            [
              359293.91514520446,
              5004163.0219309358
            ],
            [
              359294.69967088138,
              5004161.6937045744
            ],
            [
              359295.39054046018,
              5004160.7714006174
            ],
            [
              359296.09267660818,
              5004160.4551949669
            ],
            [
              359296.89966851141,
              5004160.3391309138
            ],
            [
              359298.01190600608,
              5004160.3184558842
            ],
            [
              359299.98758167174,
              5004160.7653620467
            ],
            [
              359300.69535109447,
              5004160.7522055479
            ],
            [
              359301.39560924977,
              5004160.3349716747
            ],
            [
              359301.68207700993,
              5004159.4201850342
            ],
            [
              359301.66893246345,
              5004158.7130585173
            ],
            [
              359301.65015529684,
              5004157.7029183889
            ],
            [
              359300.79051964782,
              5004154.9905131236
            ],
            [
              359300.04331727821,
              5004152.8823255831
            ],
            [
              359299.5189709501,
              5004151.8815477546
            ],
            [
              359298.79805765493,
              5004151.1876132488
            ],
            [
              359298.07526571868,
              5004150.3926149774
            ],
            [
              359297.75506453373,
              5004149.4891054947
            ],
            [
              359297.84302112187,
              5004148.7801351836
            ],
            [
              359298.13881123147,
              5004148.3704198413
            ],
            [
              359298.73984693171,
              5004148.05609354
            ],
            [
              359299.34651590377,
              5004148.0448163841
            ],
            [
              359300.35765293991,
              5004148.0260207169
            ],
            [
              359301.98290393926,
              5004148.4000264984
            ],
            [
              359303.81981065683,
              5004149.2753424672
            ],
            [
              359304.94143670134,
              5004149.7597375335
            ],
            [
              359305.44888318941,
              5004149.8513679244
            ],
            [
              359305.85328499478,
              5004149.8438506406
            ],
            [
              359306.45432068012,
              5004149.5295243403
            ],
            [
              359307.85678122455,
              5004148.7960836506
            ],
            [
              359308.46345018077,
              5004148.7848064937
            ],
            [
              359309.07199711393,
              5004148.874557564
            ],
            [
              359309.88837755995,
              5004149.2635635957
            ],
            [
              359310.4052126109,
              5004149.860264034
            ],
            [
              359310.82088096096,
              5004150.4588450268
            ],
            [
              359310.8321475109,
              5004151.0649432996
            ],
            [
              359310.64496911998,
              5004151.876856667
            ],
            [
              359310.6693795459,
              5004153.1900459081
            ],
            [
              359310.88472498098,
              5004153.8934137644
            ],
            [
              359311.39968205791,
              5004154.389085969
            ],
            [
              359312.41833031591,
              5004154.7743676454
            ],
            [
              359313.42940106097,
              5004154.7555731889
            ],
            [
              359314.03419202712,
              5004154.6432677992
            ],
            [
              359314.53224926582,
              5004154.2297926005
            ],
            [
              359316.43270141224,
              5004153.082913477
            ],
            [
              359318.4398872765,
              5004152.2372041754
            ],
            [
              359321.45633212634,
              5004151.2716709878
            ],
            [
              359324.2742651038,
              5004150.5119185932
            ],
            [
              359326.08676128997,
              5004150.0740453107
            ],
            [
              359328.00411254709,
              5004149.8362780819
            ],
            [
              359329.41965127614,
              5004149.809965129
            ],
            [
              359330.9401126522,
              5004149.9838280594
            ],
            [
              359333.56335592078,
              5004149.6319116736
            ],
            [
              359335.47882981849,
              5004149.2931517707
            ],
            [
              359337.39430370746,
              5004148.9543918716
            ],
            [
              359339.60744563729,
              5004148.3069449039
            ],
            [
              359341.91799829231,
              5004147.4555609571
            ],
            [
              359344.02252916817,
              5004146.4059515409
            ],
            [
              359346.72628326842,
              5004144.9409509003
            ],
            [
              359356.4817861559,
              5004138.8361303806
            ],
            [
              359359.57860981853,
              5004136.7575511653
            ],
            [
              359361.67569497606,
              5004135.303827798
            ],
            [
              359364.45795079385,
              5004132.624788411
            ],
            [
              359368.22317717149,
              5004128.4117080197
            ],
            [
              359370.98108826537,
              5004124.4194429582
            ],
            [
              359372.54625046876,
              5004121.5609015282
            ],
            [
              359373.22585416556,
              5004120.0325352307
            ],
            [
              359373.50668872759,
              5004118.8146997336
            ],
            [
              359373.6826006737,
              5004117.3966883691
            ],
            [
              359373.63002417563,
              5004114.568253953
            ],
            [
              359373.70671367389,
              5004113.2531501055
            ],
            [
              359373.9948938411,
              5004112.1630799659
            ],
            [
              359374.78317480662,
              5004111.0368749071
            ],
            [
              359375.47404436191,
              5004110.1145712668
            ],
            [
              359376.25850314624,
              5004108.7863110388
            ],
            [
              359376.63668290508,
              5004107.3645753749
            ],
            [
              359376.90430682438,
              5004105.4396147663
            ],
            [
              359376.87050742557,
              5004103.6213202849
            ],
            [
              359376.43230631226,
              5004101.8105430277
            ],
            [
              359376.01469457272,
              5004101.1109706564
            ],
            [
              359360.62893896695,
              5004100.4875074802
            ],
            [
              359360.85337508103,
              5004096.2391822385
            ],
            [
              359375.6362170942,
              5004097.0759435426
            ],
            [
              359375.71109561034,
              5004095.6598458048
            ],
            [
              359376.09484239656,
              5004094.5411604671
            ],
            [
              359377.37735846179,
              5004092.7994252406
            ],
            [
              359378.86589822586,
              5004091.2560221329
            ],
            [
              359380.85618355795,
              5004089.50113058
            ],
            [
              359382.64990190748,
              5004088.053082264
            ],
            [
              359385.24685671827,
              5004086.2869492415
            ],
            [
              359387.33636516909,
              5004084.4291857844
            ],
            [
              359388.72749284282,
              5004083.0896486221
            ],
            [
              359389.91266440786,
              5004081.551849206
            ],
            [
              359391.68572785781,
              5004078.9926328994
            ],
            [
              359395.18867873558,
              5004074.8280461822
            ],
            [
              359396.87003023771,
              5004072.7757791746
            ],
            [
              359395.62289340579,
              5004070.9800388236
            ],
            [
              359446.69248944899,
              5004041.3920217752
            ],
            [
              359449.88109049125,
              5004038.8064580774
            ],
            [
              359451.48575150763,
              5004038.06925957
            ],
            [
              359452.90316768351,
              5004038.1439752737
            ],
            [
              359455.73048889992,
              5004037.8893294744
            ],
            [
              359460.26739295904,
              5004037.0976255098
            ],
            [
              359464.39225237997,
              5004035.9093995262
            ],
            [
              359468.51530006301,
              5004034.6201441595
            ],
            [
              359472.13459046511,
              5004033.4412803603
            ],
            [
              359475.04617867753,
              5004032.2756071147
            ],
            [
              359478.45763571077,
              5004030.7974884752
            ],
            [
              359481.76423643285,
              5004029.1191927977
            ],
            [
              359485.57077287714,
              5004027.128486054
            ],
            [
              359496.9616395848,
              5004021.3072330169
            ],
            [
              359506.77289671433,
              5004015.8701830795
            ],
            [
              359509.48221765814,
              5004014.7082698941
            ],
            [
              359511.89199262235,
              5004013.7540153628
            ],
            [
              359522.32675187808,
              5004009.2148726098
            ],
            [
              359531.2486277952,
              5004004.9059425332
            ],
            [
              359548.01776201016,
              5003998.3290351257
            ],
            [
              359554.14041705435,
              5003995.7900324063
            ],
            [
              359557.22239815438,
              5003994.6529368497
            ],
            [
              359561.24615552375,
              5003993.466555452
            ],
            [
              359566.57315089385,
              5003991.6496775439
            ],
            [
              359570.28603039024,
              5003990.0648937374
            ],
            [
              359574.4970331699,
              5003988.0666340822
            ],
            [
              359577.40298754681,
              5003986.5979126692
            ],
            [
              359579.30343880673,
              5003985.4510353534
            ],
            [
              359581.70194711786,
              5003983.8906834153
            ],
            [
              359585.78737342369,
              5003980.5811162079
            ],
            [
              359594.23154985154,
              5003972.3400703929
            ],
            [
              359597.20480583521,
              5003969.0511765964
            ],
            [
              359597.89191948186,
              5003967.9268175559
            ],
            [
              359598.48997000634,
              5003966.4357653549
            ],
            [
              359599.05908463598,
              5003964.4041744629
            ],
            [
              359600.5024922872,
              5003960.4363475181
            ],
            [
              359603.85551104002,
              5003950.3699883111
            ],
            [
              359606.25747927424,
              5003943.5548983552
            ],
            [
              359608.48165713815,
              5003938.0567906611
            ],
            [
              359609.72662015253,
              5003934.2947787233
            ],
            [
              359610.60654637252,
              5003932.6615916658
            ],
            [
              359611.09897103399,
              5003931.9451038018
            ],
            [
              359611.99016341328,
              5003930.9180145087
            ],
            [
              359612.69744224084,
              5003930.0192240318
            ],
            [
              359613.97244794032,
              5003927.8734491402
            ],
            [
              359614.94603047258,
              5003925.8343401607
            ],
            [
              359615.32608798018,
              5003924.5135982875
            ],
            [
              359608.12252908008,
              5003917.8770915633
            ],
            [
              359610.10154851386,
              5003915.5161399655
            ],
            [
              359617.13410548505,
              5003921.9380225148
            ],
            [
              359635.26590582408,
              5003907.0496534202
            ],
            [
              359639.05178632069,
              5003903.9477461018
            ],
            [
              359641.5476391358,
              5003902.1834595026
            ],
            [
              359651.04426130973,
              5003896.1459920742
            ],
            [
              359656.43855791003,
              5003892.5089438809
            ],
            [
              359659.93233708368,
              5003890.0187735418
            ],
            [
              359662.42443468183,
              5003888.0524664419
            ],
            [
              359664.81167678867,
              5003885.8860179009
            ],
            [
              359667.1932858603,
              5003883.4165205639
            ],
            [
              359671.10341742152,
              5003879.4260564931
            ],
            [
              359677.75018759532,
              5003872.4310392058
            ],
            [
              359680.71211086673,
              5003868.5360151324
            ],
            [
              359681.59961504169,
              5003867.306939818
            ],
            [
              359682.48141929036,
              5003865.774781392
            ],
            [
              359683.75266935019,
              5003863.4269509399
            ],
            [
              359695.12827789801,
              5003841.172129266
            ],
            [
              359694.35737948428,
              5003839.1864435636
            ],
            [
              359692.37898185488,
              5003832.3256896939
            ],
            [
              359691.9190293905,
              5003829.4854372814
            ],
            [
              359684.23614703858,
              5003822.3906324478
            ],
            [
              359674.38800079777,
              5003817.6646906445
            ],
            [
              359662.89673588501,
              5003810.3065391304
            ],
            [
              359656.7513948619,
              5003805.1928998455
            ],
            [
              359653.38122355641,
              5003800.4248217614
            ],
            [
              359651.96466915053,
              5003789.6969790114
            ],
            [
              359653.77092164027,
              5003774.1161960103
            ],
            [
              359659.34251134581,
              5003756.3703536233
            ],
            [
              359670.1092585107,
              5003728.5928460611
            ],
            [
              359684.43850861507,
              5003712.4331581881
            ],
            [
              359696.37587466504,
              5003704.8463686118
            ],
            [
              359714.4630156224,
              5003698.1166080497
            ],
            [
              359732.3942294282,
              5003697.8291844632
            ],
            [
              359742.51171497494,
              5003699.6495803799
            ],
            [
              359750.57614203793,
              5003701.5027877046
            ],
            [
              359754.48601884209,
              5003702.4012783449
            ],
            [
              359767.74198235595,
              5003690.1126040192
            ],
            [
              359766.19335950108,
              5003688.4235310797
            ],
            [
              359768.87827024999,
              5003685.9483982883
            ],
            [
              359770.63097155682,
              5003687.7347409558
            ],
            [
              359811.84547217633,
              5003650.7470328752
            ],
            [
              359809.88806055911,
              5003648.1055842768
            ],
            [
              359812.9774387356,
              5003645.6229342073
            ],
            [
              359814.63654995133,
              5003647.8152328068
            ],
            [
              359827.37405456579,
              5003636.5638934355
            ],
            [
              359850.14875351952,
              5003614.5668491032
            ],
            [
              359847.88485247927,
              5003612.486854828
            ],
            [
              359850.07177227264,
              5003610.4251957573
            ],
            [
              359851.92557306663,
              5003612.20965923
            ],
            [
              359864.40490982501,
              5003580.1049400531
            ],
            [
              359870.61997070356,
              5003591.8011683375
            ],
            [
              359873.26010875736,
              5003592.3584027356
            ],
            [
              359873.96975483728,
              5003592.446275495
            ],
            [
              359874.47907748964,
              5003592.6388990842
            ],
            [
              359874.99027795246,
              5003592.9325507367
            ],
            [
              359875.50335622678,
              5003593.3272304507
            ],
            [
              359878.36634810275,
              5003594.9918730529
            ],
            [
              359879.07787132397,
              5003595.180738328
            ],
            [
              359879.88298490294,
              5003594.9636832792
            ],
            [
              359880.48777506116,
              5003594.851379062
            ],
            [
              359881.18809964129,
              5003594.4341813521
            ],
            [
              359929.1260747023,
              5003561.510005651
            ],
            [
              359933.12552817009,
              5003566.2509283032
            ],
            [
              359976.07060155337,
              5003535.5752649549
            ],
            [
              359974.10443613277,
              5003533.1865837146
            ],
            [
              359976.79310131021,
              5003530.9134741668
            ],
            [
              359978.55143346672,
              5003533.0028651105
            ],
            [
              359987.22979628871,
              5003526.4753434947
            ],
            [
              359994.63416554901,
              5003505.8243978266
            ],
            [
              359935.38096658845,
              5003475.8061563643
            ],
            [
              359939.69278116553,
              5003468.349307701
            ],
            [
              359996.79289814463,
              5003494.9009020515
            ],
            [
              360004.28334847064,
              5003473.4399253875
            ],
            [
              360004.66153017821,
              5003472.0181930168
            ],
            [
              360004.73822200246,
              5003470.7030917164
            ],
            [
              360003.68484612083,
              5003462.9544755258
            ],
            [
              359997.20086810697,
              5003419.6668811953
            ],
            [
              359993.17438368406,
              5003390.8788862154
            ],
            [
              359980.76048517035,
              5003359.6827766625
            ],
            [
              359952.67422745301,
              5003361.4174106494
            ],
            [
              359943.36613978801,
              5003432.1902212407
            ],
            [
              359937.790074169,
              5003431.4854286248
            ],
            [
              359941.01392408204,
              5003403.1582772909
            ],
            [
              359944.93145967223,
              5003374.4880636558
            ],
            [
              359945.59763594635,
              5003366.7957868399
            ],
            [
              359945.41217963869,
              5003362.7832348635
            ],
            [
              359941.99076516129,
              5003352.842768061
            ],
            [
              359943.77697341138,
              5003350.9906493146
            ],
            [
              359920.92109645653,
              5003329.3863632809
            ],
            [
              359924.29124662798,
              5003325.6858851966
            ],
            [
              359946.3444540418,
              5003347.1181430258
            ],
            [
              359947.67578769469,
              5003348.0028582895
            ],
            [
              359949.20368932543,
              5003348.5807667933
            ],
            [
              359950.32343506371,
              5003348.9641710697
            ],
            [
              359951.84389264038,
              5003349.1380017065
            ],
            [
              359953.05716208956,
              5003349.115451497
            ],
            [
              359954.36784323532,
              5003348.8890004316
            ],
            [
              359955.3714681025,
              5003348.4661305323
            ],
            [
              359956.90321547986,
              5003347.7996811932
            ],
            [
              359970.94418108702,
              5003341.3745833272
            ],
            [
              359956.20911721193,
              5003317.6257961998
            ],
            [
              359949.73558959021,
              5003306.7315336736
            ],
            [
              359942.97190478421,
              5003296.5500687044
            ],
            [
              359938.08635961928,
              5003289.4662562683
            ],
            [
              359930.92467060027,
              5003280.7418707246
            ],
            [
              359926.89687499241,
              5003276.2694323286
            ],
            [
              359921.32421472209,
              5003270.3099758597
            ],
            [
              359916.26657494728,
              5003264.846155108
            ],
            [
              359912.45224488928,
              5003260.9760554759
            ],
            [
              359908.95060357213,
              5003257.6054233331
            ],
            [
              359902.41316989402,
              5003251.6952153435
            ],
            [
              359878.76907994039,
              5003231.2171202591
            ],
            [
              359855.06231274578,
              5003209.684553802
            ],
            [
              359854.44625754608,
              5003209.190759996
            ],
            [
              359853.94068981271,
              5003209.2001564298
            ],
            [
              359853.33777651918,
              5003209.4134525126
            ],
            [
              359846.18896649528,
              5003211.1631494546
            ],
            [
              359845.17783100333,
              5003211.1819423316
            ],
            [
              359844.26222962438,
              5003210.8958062511
            ],
            [
              359843.4439738043,
              5003210.4057705151
            ],
            [
              359842.82228548644,
              5003209.6088924697
            ],
            [
              359842.50014399242,
              5003208.6043922221
            ],
            [
              359842.47949129494,
              5003207.4931900138
            ],
            [
              359842.66479459481,
              5003206.5802858705
            ],
            [
              359843.25456534809,
              5003205.659864339
            ],
            [
              359851.56677610817,
              5003196.9468018757
            ],
            [
              359829.67907272716,
              5003178.4570668191
            ],
            [
              359828.86081625795,
              5003177.9669954497
            ],
            [
              359827.43025996321,
              5003177.1851863312
            ],
            [
              359826.30863698485,
              5003176.7007888183
            ],
            [
              359825.28435893072,
              5003176.0124561088
            ],
            [
              359823.32945994224,
              5003174.2298691645
            ],
            [
              359822.60291731165,
              5003173.2328849519
            ],
            [
              359822.08045231987,
              5003172.3331352165
            ],
            [
              359821.45876405161,
              5003171.5362570556
            ],
            [
              359803.4200240486,
              5003155.9095419869
            ],
            [
              359740.87722975278,
              5003204.8944765367
            ],
            [
              359737.88678690529,
              5003201.8174589602
            ],
            [
              359778.1741285334,
              5003167.6308741663
            ],
            [
              359777.1310761327,
              5003165.9324025391
            ],
            [
              359776.79585847718,
              5003164.2207752438
            ],
            [
              359776.76581881224,
              5003162.6045034183
            ],
            [
              359777.02969301102,
              5003160.477525115
            ],
            [
              359777.60444450757,
              5003158.7489853427
            ],
            [
              359778.77647642005,
              5003156.5040652566
            ],
            [
              359782.39555088367,
              5003149.8684897013
            ],
            [
              359784.92304194102,
              5003144.3647883851
            ],
            [
              359788.00838688004,
              5003136.223365725
            ],
            [
              359789.16352179722,
              5003133.0692995768
            ],
            [
              359789.53419510421,
              5003131.243489855
            ],
            [
              359789.58648180123,
              5003128.6152003091
            ],
            [
              359789.34854305879,
              5003126.6996390708
            ],
            [
              359788.46710364823,
              5003124.108006007
            ],
            [
              359787.20495378284,
              5003121.5041461177
            ],
            [
              359785.83982594364,
              5003118.8011371298
            ],
            [
              359784.38486251119,
              5003116.7061046157
            ],
            [
              359782.83630813,
              5003115.0169923957
            ],
            [
              359781.08736519353,
              5003113.432703048
            ],
            [
              359777.69252223446,
              5003110.363201079
            ],
            [
              359774.28822599404,
              5003106.7886308776
            ],
            [
              359771.49066485051,
              5003103.2028196212
            ],
            [
              359769.00954571302,
              5003100.3184258081
            ],
            [
              359767.04901445535,
              5003098.232789387
            ],
            [
              359764.96673119714,
              5003095.0378650166
            ],
            [
              359762.97396635456,
              5003091.6778981993
            ],
            [
              359760.67258483288,
              5003087.5775499437
            ],
            [
              359759.28492784867,
              5003083.6623455603
            ],
            [
              359758.20244953927,
              5003079.8425322352
            ],
            [
              359757.64062469901,
              5003076.8214396052
            ],
            [
              359757.66662789107,
              5003072.7789340969
            ],
            [
              359757.92487034522,
              5003070.3489069771
            ],
            [
              359758.59509034065,
              5003068.3154394943
            ],
            [
              359759.37961854157,
              5003066.9871818786
            ],
            [
              359761.35676531302,
              5003064.5252081007
            ],
            [
              359764.03604830365,
              5003061.747031372
            ],
            [
              359766.82581923291,
              5003059.4720806452
            ],
            [
              359768.52970355656,
              5003058.6319802916
            ],
            [
              359770.2410984927,
              5003058.1959923329
            ],
            [
              359772.25954903319,
              5003057.9563880442
            ],
            [
              359775.39023493166,
              5003057.6960767955
            ],
            [
              359781.29745308345,
              5003057.4153735572
            ],
            [
              359787.0513805208,
              5003056.8031902807
            ],
            [
              359790.47973559337,
              5003056.2342288671
            ],
            [
              359792.69481993845,
              5003055.6877811095
            ],
            [
              359793.9942372932,
              5003054.8552335883
            ],
            [
              359796.48446172738,
              5003052.7879407434
            ],
            [
              359799.56257975131,
              5003049.6991984546
            ],
            [
              359801.34684647445,
              5003047.7460536491
            ],
            [
              359805.58573315135,
              5003041.8063300159
            ],
            [
              359807.25957890664,
              5003039.3499936881
            ],
            [
              359807.74824985687,
              5003038.4314513365
            ],
            [
              359808.12261203112,
              5003036.8076636838
            ],
            [
              359808.40532773157,
              5003035.6908586845
            ],
            [
              359810.04371669044,
              5003036.7719591726
            ],
            [
              359813.61238334223,
              5003032.8657029038
            ],
            [
              359814.50357789197,
              5003031.8386165425
            ],
            [
              359814.78817124903,
              5003030.8228396494
            ],
            [
              359814.6326258168,
              5003027.8952595713
            ],
            [
              359813.93507351336,
              5003022.171308917
            ],
            [
              359814.07344152895,
              5003018.7329869885
            ],
            [
              359814.45162532554,
              5003017.3112543179
            ],
            [
              359815.23421036411,
              5003015.8820057204
            ],
            [
              359816.01873863972,
              5003014.55374844
            ],
            [
              359816.9061786168,
              5003013.3246415034
            ],
            [
              359818.49582099507,
              5003011.7793305125
            ],
            [
              359821.68630401668,
              5003009.2948072245
            ],
            [
              359825.3767225531,
              5003006.4978391398
            ],
            [
              359828.56720554963,
              5003004.0133159515
            ],
            [
              359830.55373990472,
              5003002.0564478915
            ],
            [
              359831.54040255601,
              5003000.7244339781
            ],
            [
              359832.51962223469,
              5002998.9883775953
            ],
            [
              359833.3995523394,
              5002997.3551939763
            ],
            [
              359834.0735272328,
              5002995.523747704
            ],
            [
              359835.32983050431,
              5002992.3678377755
            ],
            [
              359836.48525214213,
              5002989.097834439
            ],
            [
              359844.90527788602,
              5002970.4319661045
            ],
            [
              359849.11408464453,
              5002962.8759670928
            ],
            [
              359849.49977997824,
              5002961.8582701534
            ],
            [
              359850.09518692869,
              5002961.2408942804
            ],
            [
              359850.59505966998,
              5002960.9284845451
            ],
            [
              359851.30283198104,
              5002960.9153304622
            ],
            [
              359860.09390243847,
              5002960.4487917414
            ],
            [
              359863.5203985236,
              5002959.7787995841
            ],
            [
              359865.63432785467,
              5002959.2342297137
            ],
            [
              359868.74994583469,
              5002958.1658322802
            ],
            [
              359870.25539300253,
              5002957.5315431934
            ],
            [
              359871.75138706091,
              5002956.392183247
            ],
            [
              359873.33721749258,
              5002954.6448436072
            ],
            [
              359875.11968365399,
              5002952.5906944387
            ],
            [
              359876.02214775869,
              5002952.1697034342
            ],
            [
              359876.72998626932,
              5002952.1565481415
            ],
            [
              359877.43963615986,
              5002952.2444227133
            ],
            [
              359878.05193979637,
              5002952.5361979874
            ],
            [
              359878.66605481465,
              5002952.9290031241
            ],
            [
              359879.68282747094,
              5002953.213225726
            ],
            [
              359880.69577992585,
              5002953.2954633664
            ],
            [
              359881.60394250625,
              5002953.1775214905
            ],
            [
              359882.60938574286,
              5002952.8557156799
            ],
            [
              359883.20666962542,
              5002952.339332981
            ],
            [
              359883.99676903622,
              5002951.3141557882
            ],
            [
              359884.58091147186,
              5002950.0906792907
            ],
            [
              359885.56952358416,
              5002948.8596856697
            ],
            [
              359886.66292556457,
              5002947.8288715687
            ],
            [
              359887.86124925507,
              5002946.9981989916
            ],
            [
              359889.3685733311,
              5002946.4649030818
            ],
            [
              359890.37589352246,
              5002946.2440903932
            ],
            [
              359891.18476660579,
              5002946.2290573716
            ],
            [
              359892.39998698427,
              5002946.3075358532
            ],
            [
              359893.3099609153,
              5002946.2906238567
            ],
            [
              359894.93340555095,
              5002946.5636069272
            ],
            [
              359895.11050012411,
              5002936.6219285484
            ],
            [
              359898.43965031265,
              5002936.1558729215
            ],
            [
              359896.8572959798,
              5002932.6484844545
            ],
            [
              359901.57007421984,
              5002930.438811888
            ],
            [
              359904.83970452467,
              5002937.6537302239
            ],
            [
              359910.2489910259,
              5002934.8248384884
            ],
            [
              359953.71239759913,
              5002924.3022436304
            ],
            [
              359953.77379534533,
              5002916.7222994389
            ],
            [
              359956.58429878723,
              5002915.5585097205
            ],
            [
              359986.4403974489,
              5002921.9761251025
            ],
            [
              359999.43540790817,
              5002916.8514642958
            ],
            [
              360002.65031426301,
              5002915.6801588731
            ],
            [
              360009.06698486698,
              5002912.6304190122
            ],
            [
              360014.17853277159,
              5002910.1101813586
            ],
            [
              360015.98728250572,
              5002909.4702914022
            ],
            [
              360018.30904481391,
              5002909.2250143979
            ],
            [
              360019.32394072093,
              5002909.4082794962
            ],
            [
              360020.65527977055,
              5002910.2929656915
            ],
            [
              360021.2826027589,
              5002911.3928985689
            ],
            [
              360021.50545557303,
              5002912.5003129896
            ],
            [
              360021.52422913915,
              5002913.5104567418
            ],
            [
              360021.54675795027,
              5002914.7226576786
            ],
            [
              360021.70230319147,
              5002917.6502524586
            ],
            [
              360022.03007767868,
              5002918.9578083204
            ],
            [
              360022.85772469785,
              5002919.9529546537
            ],
            [
              360023.78647187416,
              5002920.9461864857
            ],
            [
              360025.32195710094,
              5002921.9281775905
            ],
            [
              360027.25426949403,
              5002922.4985752348
            ],
            [
              360029.48438395013,
              5002922.7602477511
            ],
            [
              360031.70880059031,
              5002922.7189068059
            ],
            [
              360033.52130479546,
              5002922.2810384547
            ],
            [
              360035.62209176057,
              5002921.029375596
            ],
            [
              360037.51316792675,
              5002919.3774274122
            ],
            [
              360038.98663256405,
              5002917.0258673178
            ],
            [
              360039.95459316083,
              5002914.6837020582
            ],
            [
              360040.52740480792,
              5002912.8541269768
            ],
            [
              360040.4037751953,
              5002911.6438050717
            ],
            [
              360040.28014624625,
              5002910.4335187022
            ],
            [
              360039.76143392501,
              5002909.7357857246
            ],
            [
              360038.94317441265,
              5002909.2457468016
            ],
            [
              360037.30853234214,
              5002908.3666619966
            ],
            [
              360034.96605208149,
              5002907.5007323716
            ],
            [
              360033.84073554631,
              5002906.8143086834
            ],
            [
              360033.32202322397,
              5002906.1165756891
            ],
            [
              360033.30512663827,
              5002905.2074250178
            ],
            [
              360033.5840233395,
              5002903.8885941915
            ],
            [
              360034.27677631419,
              5002903.0673181573
            ],
            [
              360036.07989310351,
              5002902.1243425189
            ],
            [
              360038.59641400498,
              5002901.4712631619
            ],
            [
              360041.31513650803,
              5002900.8144258931
            ],
            [
              360043.9421449473,
              5002900.6645394424
            ],
            [
              360047.18333427428,
              5002900.9074567975
            ],
            [
              360051.29379402933,
              5002901.1293486496
            ],
            [
              360056.45978567429,
              5002901.5385849271
            ],
            [
              360060.0062211664,
              5002901.8768927362
            ],
            [
              360062.33924756397,
              5002902.2377162129
            ],
            [
              360064.26974901167,
              5002902.7071196353
            ],
            [
              360066.4080835866,
              5002903.4757797914
            ],
            [
              360068.34233892808,
              5002904.1471692836
            ],
            [
              360070.27847187751,
              5002904.9195873393
            ],
            [
              360072.41868403769,
              5002905.7892760457
            ],
            [
              360074.35481696815,
              5002906.5616940847
            ],
            [
              360075.77974810346,
              5002907.040493506
            ],
            [
              360077.09794557869,
              5002907.2180861617
            ],
            [
              360078.51348927018,
              5002907.1917782435
            ],
            [
              360080.44023539609,
              5002907.4591244729
            ],
            [
              360082.06173524668,
              5002907.6310802391
            ],
            [
              360083.47727891809,
              5002907.6047723228
            ],
            [
              360085.09884566761,
              5002907.7767623933
            ],
            [
              360086.8195686746,
              5002907.8458106248
            ],
            [
              360088.23705619638,
              5002907.9205300463
            ],
            [
              360089.14515199309,
              5002907.8025895935
            ],
            [
              360089.84916923754,
              5002907.5874140309
            ],
            [
              360108.62925532041,
              5002902.9654239612
            ],
            [
              360104.50382068771,
              5002893.2401481401
            ],
            [
              360099.1505808704,
              5002893.6427928638
            ],
            [
              360098.88830289844,
              5002890.4140275232
            ],
            [
              360108.77464619413,
              5002889.0177062834
            ],
            [
              360118.45579979121,
              5002903.7933254242
            ],
            [
              360132.96883524308,
              5002900.9972984986
            ],
            [
              360133.98150902049,
              5002895.6227903878
            ],
            [
              360151.01198045636,
              5002894.2913078656
            ],
            [
              360152.6710938919,
              5002896.4835847057
            ],
            [
              360159.19609464216,
              5002888.3793008039
            ],
            [
              360162.27603952133,
              5002885.3915757239
            ],
            [
              360164.47236068972,
              5002883.8349832669
            ],
            [
              360166.67612538103,
              5002882.6824707175
            ],
            [
              360168.79005363386,
              5002882.1379373427
            ],
            [
              360170.70741250057,
              5002881.9002119843
            ],
            [
              360174.04031399946,
              5002881.6361432895
            ],
            [
              360179.38604354439,
              5002880.8294557407
            ],
            [
              360183.72633058578,
              5002880.3445735257
            ],
            [
              360187.8605952271,
              5002879.6614289135
            ],
            [
              360192.19893896225,
              5002879.075554952
            ],
            [
              360195.9250458887,
              5002878.197904638
            ],
            [
              360199.89418512984,
              5002876.8710300559
            ],
            [
              360203.41426964459,
              5002875.795117382
            ],
            [
              360207.02613375994,
              5002874.2122174054
            ],
            [
              360210.73715540598,
              5002872.5264467439
            ],
            [
              360215.5398324299,
              5002869.7087967265
            ],
            [
              360220.82923978043,
              5002865.8716088738
            ],
            [
              360227.0117243487,
              5002861.1083947625
            ],
            [
              360232.49394577363,
              5002856.7623421727
            ],
            [
              360236.57001147437,
              5002852.9477044996
            ],
            [
              360238.95376565744,
              5002850.7104011541
            ],
            [
              360241.7228981604,
              5002847.3242353695
            ],
            [
              360244.09513806709,
              5002844.3496995
            ],
            [
              360245.87760297587,
              5002842.2955531217
            ],
            [
              360246.85863845731,
              5002840.6604837803
            ],
            [
              360207.19104122004,
              5002834.2230845615
            ],
            [
              360207.81433156086,
              5002829.6642152322
            ],
            [
              360250.05434084183,
              5002836.3626851095
            ],
            [
              360251.86274568719,
              5002830.2660177005
            ],
            [
              360252.79128143174,
              5002825.8025039174
            ],
            [
              360253.1544489066,
              5002823.5726437364
            ],
            [
              360253.11314715183,
              5002821.3503005318
            ],
            [
              360252.98381957127,
              5002819.8369305395
            ],
            [
              360252.44827832642,
              5002818.2300820649
            ],
            [
              360251.9126701756,
              5002816.6231992822
            ],
            [
              360250.72344947775,
              5002813.7392030703
            ],
            [
              360249.04375245451,
              5002810.4357536025
            ],
            [
              360246.54385274352,
              5002806.5412379345
            ],
            [
              360244.25560742593,
              5002803.148034675
            ],
            [
              360240.43563272612,
              5002798.97486185
            ],
            [
              360238.79160528927,
              5002797.5907054683
            ],
            [
              360237.7729562383,
              5002797.2054541726
            ],
            [
              360237.16628546856,
              5002797.2167289704
            ],
            [
              360233.73979278206,
              5002797.8867187658
            ],
            [
              360242.28325295699,
              5002789.542799389
            ],
            [
              360240.01378161862,
              5002787.1597746266
            ],
            [
              360232.17246010894,
              5002795.187454693
            ],
            [
              360232.09548840416,
              5002791.0457825195
            ],
            [
              360231.66285934584,
              5002789.5380847361
            ],
            [
              360229.68542536936,
              5002786.5432874896
            ],
            [
              360227.77994537033,
              5002783.9931402039
            ],
            [
              360225.71079790627,
              5002781.5053289328
            ],
            [
              360224.57602875313,
              5002780.3137993179
            ],
            [
              360222.72792222042,
              5002778.8323720973
            ],
            [
              360220.78240383422,
              5002777.5548820803
            ],
            [
              360218.63468392327,
              5002776.2811498884
            ],
            [
              360215.67051577056,
              5002774.6183729153
            ],
            [
              360211.78142145067,
              5002772.1643489907
            ],
            [
              360207.99148469692,
              5002769.6074543269
            ],
            [
              360197.32686200558,
              5002783.548608494
            ],
            [
              360193.93763982231,
              5002780.7821753779
            ],
            [
              360204.59475374542,
              5002766.4369780822
            ],
            [
              360201.52016378706,
              5002764.2709714891
            ],
            [
              360199.05405663769,
              5002762.1947554275
            ],
            [
              360195.23783707799,
              5002758.2236386789
            ],
            [
              360191.21002944373,
              5002753.751208039
            ],
            [
              360186.91081970744,
              5002748.9289793922
            ],
            [
              360184.62820660876,
              5002745.838825251
            ],
            [
              360183.06650616758,
              5002743.4426106522
            ],
            [
              360181.81374009169,
              5002741.3438093914
            ],
            [
              360179.92989806348,
              5002737.9430894153
            ],
            [
              360178.1603647325,
              5002735.2476180037
            ],
            [
              360176.29717333562,
              5002732.9580698144
            ],
            [
              360174.85159292683,
              5002731.3681334872
            ],
            [
              360173.30866555829,
              5002729.9820620725
            ],
            [
              360171.67208142602,
              5002729.0019849744
            ],
            [
              360170.24902850017,
              5002728.6242134972
            ],
            [
              360168.93458594347,
              5002728.6486417605
            ],
            [
              360146.0521937352,
              5002738.269535969
            ],
            [
              360144.78253208642,
              5002735.2616196163
            ],
            [
              360173.53827688895,
              5002723.3651603023
            ],
            [
              360173.62060466397,
              5002722.3531380761
            ],
            [
              360174.10733486916,
              5002721.3335646912
            ],
            [
              360175.49478428421,
              5002719.7920058081
            ],
            [
              360177.49259345263,
              5002718.4412306016
            ],
            [
              360179.09907423495,
              5002717.8050654652
            ],
            [
              360181.50698474882,
              5002716.7498235293
            ],
            [
              360183.11346551456,
              5002716.1136584058
            ],
            [
              360184.51398961473,
              5002715.2791940114
            ],
            [
              360186.01749270078,
              5002714.5439147986
            ],
            [
              360188.5171167985,
              5002712.9816868305
            ],
            [
              360191.91920511913,
              5002710.9985044384
            ],
            [
              360194.32711490366,
              5002709.9432270546
            ],
            [
              360197.1413059546,
              5002708.9814981539
            ],
            [
              360199.75517169497,
              5002708.124484539
            ],
            [
              360205.29371630907,
              5002706.8089703312
            ],
            [
              360212.1373823568,
              5002704.96391962
            ],
            [
              360219.34225484799,
              5002702.8339917902
            ],
            [
              360223.36227774346,
              5002701.4456351697
            ],
            [
              360227.47964624123,
              5002699.8533426188
            ],
            [
              360230.89487582596,
              5002698.5772892162
            ],
            [
              360236.50636003591,
              5002695.7446106086
            ],
            [
              360242.21525673126,
              5002692.7080304455
            ],
            [
              360245.62297664094,
              5002691.027898605
            ],
            [
              360247.82674146531,
              5002689.8753875624
            ],
            [
              360249.43141067104,
              5002689.1381929331
            ],
            [
              360250.63912017416,
              5002688.8125936845
            ],
            [
              360252.15013192018,
              5002688.4813577523
            ],
            [
              360253.45518771792,
              5002687.9518581042
            ],
            [
              360255.25454950839,
              5002686.8068626877
            ],
            [
              360256.84788844036,
              5002685.4635692583
            ],
            [
              360259.03100278211,
              5002683.1998866973
            ],
            [
              360259.91851403372,
              5002681.970774075
            ],
            [
              360260.30789859261,
              5002681.1551369391
            ],
            [
              360260.49514791282,
              5002680.3432563599
            ],
            [
              360260.88265492494,
              5002679.4265907053
            ],
            [
              360262.27003792737,
              5002677.8850336149
            ],
            [
              360263.96266697109,
              5002676.4388671489
            ],
            [
              360265.76202874479,
              5002675.2938718265
            ],
            [
              360266.96779506322,
              5002674.8672808902
            ],
            [
              360268.28035970003,
              5002674.7418243205
            ],
            [
              360269.39072284452,
              5002674.620125534
            ],
            [
              360270.89610253641,
              5002673.985839664
            ],
            [
              360272.19177186204,
              5002672.9512685817
            ],
            [
              360273.38446289435,
              5002671.8175478801
            ],
            [
              360274.37307489367,
              5002670.5865564523
            ],
            [
              360275.55068127601,
              5002668.6447154684
            ],
            [
              360276.68552478071,
              5002666.8339887643
            ],
            [
              360277.67038235517,
              5002665.4009758644
            ],
            [
              360278.86307339481,
              5002664.2672552075
            ],
            [
              360284.0456831113,
              5002660.1289013298
            ],
            [
              360286.84116636094,
              5002658.1569933305
            ],
            [
              360288.44576920202,
              5002657.419800167
            ],
            [
              360290.45484190242,
              5002656.6751257684
            ],
            [
              360291.85912079812,
              5002656.0427189013
            ],
            [
              360292.55562827422,
              5002655.4234654717
            ],
            [
              360293.14728058683,
              5002654.6040694397
            ],
            [
              360294.22572939278,
              5002652.7650992479
            ],
            [
              360296.37505192769,
              5002648.6831171624
            ],
            [
              360297.15764179756,
              5002647.2538633933
            ],
            [
              360297.94592988578,
              5002646.1276583951
            ],
            [
              360298.6386829472,
              5002645.3063835092
            ],
            [
              360301.93216033996,
              5002642.9210028071
            ],
            [
              360303.13417149248,
              5002642.2923550112
            ],
            [
              360304.24453453539,
              5002642.170656343
            ],
            [
              360305.25755192054,
              5002642.2528935727
            ],
            [
              360306.47458139661,
              5002642.4323672419
            ],
            [
              360307.49135319254,
              5002642.7166259466
            ],
            [
              360308.40132604237,
              5002642.6997147799
            ],
            [
              360309.00424227439,
              5002642.4864187809
            ],
            [
              360309.40495703969,
              5002642.2768805455
            ],
            [
              360309.58650821476,
              5002641.1619513473
            ],
            [
              360309.56961234595,
              5002640.252801395
            ],
            [
              360309.41031519079,
              5002637.1231874507
            ],
            [
              360309.68545762735,
              5002635.602300887
            ],
            [
              360310.17037727445,
              5002634.481733826
            ],
            [
              360310.95859912236,
              5002633.3555301307
            ],
            [
              360311.75996174564,
              5002632.9364193771
            ],
            [
              360312.96954862034,
              5002632.7118488932
            ],
            [
              360314.78768300643,
              5002632.5769968741
            ],
            [
              360318.63366269565,
              5002632.7076135706
            ],
            [
              360322.87841396901,
              5002632.5277003096
            ],
            [
              360324.85645011644,
              5002632.1278867684
            ],
            [
              360326.16526013665,
              5002631.8004089128
            ],
            [
              360326.96856651508,
              5002631.4823254542
            ],
            [
              360327.56578452123,
              5002630.9659807459
            ],
            [
              360328.25478248857,
              5002629.9426489621
            ],
            [
              360329.14041687851,
              5002628.6125437608
            ],
            [
              360330.23194088164,
              5002627.4807035103
            ],
            [
              360332.13240253989,
              5002626.3337942529
            ],
            [
              360333.33260299632,
              5002625.6041523777
            ],
            [
              360335.54575382796,
              5002624.9567134734
            ],
            [
              360336.75158555631,
              5002624.5300860349
            ],
            [
              360337.25340102537,
              5002624.3186689597
            ],
            [
              360337.34699293488,
              5002623.9127471643
            ],
            [
              360337.33572905359,
              5002623.3066472383
            ],
            [
              360337.62971047236,
              5002622.7959024217
            ],
            [
              360338.22699472582,
              5002622.2795565147
            ],
            [
              360338.8223351934,
              5002621.6621833416
            ],
            [
              360339.11068533518,
              5002620.8484241059
            ],
            [
              360339.28472709557,
              5002619.3294165591
            ],
            [
              360339.95870746003,
              5002617.4979989901
            ],
            [
              360340.6383191059,
              5002615.9695958467
            ],
            [
              360341.12699317647,
              5002615.0510503771
            ],
            [
              360341.71676797094,
              5002614.1306260331
            ],
            [
              360342.61172246822,
              5002613.3055935726
            ],
            [
              360344.70499988127,
              5002611.6498908149
            ],
            [
              360352.09593229479,
              5002605.6057850216
            ],
            [
              360356.28255332721,
              5002602.2943784781
            ],
            [
              360361.07765551272,
              5002599.0726917628
            ],
            [
              360364.67818917282,
              5002596.8836967777
            ],
            [
              360366.98312052019,
              5002595.729306641
            ],
            [
              360368.98092840816,
              5002594.3784973715
            ],
            [
              360371.87369142141,
              5002592.2026913185
            ],
            [
              360375.26639167458,
              5002589.7144042766
            ],
            [
              360378.55799186154,
              5002587.2280316874
            ],
            [
              360381.54816675925,
              5002584.8482886879
            ],
            [
              360385.83394391113,
              5002581.4339410309
            ],
            [
              360390.11227853829,
              5002577.6155493651
            ],
            [
              360393.79701771028,
              5002574.5155617269
            ],
            [
              360398.73844789836,
              5002570.6266193781
            ],
            [
              360405.13064287911,
              5002566.2636626335
            ],
            [
              360412.43287729059,
              5002561.8838294055
            ],
            [
              360417.63995752978,
              5002559.058705491
            ],
            [
              360419.44682762894,
              5002558.3177541262
            ],
            [
              360420.95408439502,
              5002557.7844975181
            ],
            [
              360422.66924068832,
              5002557.5505318604
            ],
            [
              360425.08834684134,
              5002557.1013572654
            ],
            [
              360430.84041915851,
              5002556.3881516447
            ],
            [
              360432.85512246768,
              5002555.9464924885
            ],
            [
              360434.26503350813,
              5002555.6171717113
            ],
            [
              360435.47086497745,
              5002555.1905446397
            ],
            [
              360436.67481959093,
              5002554.6629246352
            ],
            [
              360438.27379008027,
              5002553.6226824019
            ],
            [
              360440.57121235359,
              5002552.0642500008
            ],
            [
              360443.4639743751,
              5002549.8884092215
            ],
            [
              360445.65647466463,
              5002548.1298000161
            ],
            [
              360448.56432102132,
              5002546.762079197
            ],
            [
              360453.28084922855,
              5002544.7544724159
            ],
            [
              360456.59497580357,
              5002543.480264714
            ],
            [
              360459.97916410811,
              5002542.4347870126
            ],
            [
              360462.29535807215,
              5002541.886461731
            ],
            [
              360465.11893363047,
              5002541.4298061989
            ],
            [
              360466.93143538432,
              5002540.991904879
            ],
            [
              360468.63526147773,
              5002540.1518408731
            ],
            [
              360469.83733849035,
              5002539.5231925137
            ],
            [
              360471.42880016181,
              5002538.0789076816
            ],
            [
              360473.90401870612,
              5002535.2034900095
            ],
            [
              360477.25354102143,
              5002530.391834015
            ],
            [
              360479.92908411083,
              5002527.411665828
            ],
            [
              360482.21524186264,
              5002525.2470985828
            ],
            [
              360484.80470165936,
              5002523.0768948318
            ],
            [
              360486.99720182462,
              5002521.3182860091
            ],
            [
              360488.30037948728,
              5002520.6877589021
            ],
            [
              360490.20647313836,
              5002519.8439361285
            ],
            [
              360492.21178995841,
              5002518.897206068
            ],
            [
              360495.31620621338,
              5002517.222713707
            ],
            [
              360498.52728871669,
              5002515.8493935876
            ],
            [
              360502.74018987914,
              5002513.9521746533
            ],
            [
              360515.62866824603,
              5002507.6997969178
            ],
            [
              360527.17196114809,
              5002502.9379966808
            ],
            [
              360529.38323347067,
              5002502.1895304937
            ],
            [
              360530.38867524039,
              5002501.8677266147
            ],
            [
              360531.19761309278,
              5002501.8526934413
            ],
            [
              360531.70687076804,
              5002502.0453205956
            ],
            [
              360532.11697152251,
              5002502.3408538019
            ],
            [
              360532.62441795104,
              5002502.4324512808
            ],
            [
              360533.63361404539,
              5002502.3126687538
            ],
            [
              360541.02032330976,
              5002502.4785497496
            ],
            [
              360542.5370320449,
              5002502.4503634702
            ],
            [
              360543.34402611997,
              5002502.3343030876
            ],
            [
              360544.34758969228,
              5002501.9114352474
            ],
            [
              360545.04597443389,
              5002501.3932110239
            ],
            [
              360546.94080416299,
              5002499.9432889428
            ],
            [
              360551.53558083018,
              5002496.8263915572
            ],
            [
              360556.74453633843,
              5002494.1022626748
            ],
            [
              360560.14849912602,
              5002492.2200774793
            ],
            [
              360561.55277722614,
              5002491.5876717865
            ],
            [
              360562.66126265645,
              5002491.3649807954
            ],
            [
              360564.37259705079,
              5002490.928959934
            ],
            [
              360566.38730032317,
              5002490.4873368749
            ],
            [
              360568.70161682362,
              5002489.8380192174
            ],
            [
              360570.81178825116,
              5002489.0914320406
            ],
            [
              360572.61490403529,
              5002488.1484955875
            ],
            [
              360573.71393638005,
              5002487.4206992351
            ],
            [
              360575.80540175858,
              5002485.6639687
            ],
            [
              360576.9999029396,
              5002484.6312437747
            ],
            [
              360579.27435946482,
              5002483.1316092638
            ],
            [
              360583.07528231596,
              5002480.8378651012
            ],
            [
              360589.08935396158,
              5002477.8966479795
            ],
            [
              360592.19745760923,
              5002476.4241790092
            ],
            [
              360594.70834411727,
              5002475.4680896355
            ],
            [
              360596.42162211292,
              5002475.1330960831
            ],
            [
              360598.14046565228,
              5002475.1011535022
            ],
            [
              360599.66092845489,
              5002475.2749886587
            ],
            [
              360601.08210183901,
              5002475.5517322989
            ],
            [
              360602.40405139775,
              5002475.9313476551
            ],
            [
              360603.62858903082,
              5002476.5148999123
            ],
            [
              360606.08155110659,
              5002477.8840232585
            ],
            [
              360611.69142541953,
              5002480.4070967603
            ],
            [
              360613.42347624275,
              5002481.0822807821
            ],
            [
              360614.43830325594,
              5002481.2655125298
            ],
            [
              360615.54866535292,
              5002481.1438144939
            ],
            [
              360617.16453132307,
              5002481.0127578331
            ],
            [
              360618.37036224507,
              5002480.5861312514
            ],
            [
              360619.46946137247,
              5002479.8583693244
            ],
            [
              360621.47102323297,
              5002478.7096185582
            ],
            [
              360622.67685414234,
              5002478.2829919904
            ],
            [
              360623.98754066817,
              5002478.0565434257
            ],
            [
              360625.09971397219,
              5002478.0358750504
            ],
            [
              360626.41603163473,
              5002478.1124406606
            ],
            [
              360627.83532808174,
              5002478.2881913893
            ],
            [
              360629.76019331237,
              5002478.4545111926
            ],
            [
              360635.93919215334,
              5002478.9459907999
            ],
            [
              360638.8751962143,
              5002479.0935198208
            ],
            [
              360640.79630706547,
              5002479.057818328
            ],
            [
              360642.00776971428,
              5002478.9342414783
            ],
            [
              360643.21728927776,
              5002478.7096729856
            ],
            [
              360644.62532204197,
              5002478.2793243164
            ],
            [
              360646.94151486811,
              5002477.7309997659
            ],
            [
              360649.66023338627,
              5002477.0741670262
            ],
            [
              360652.35053171561,
              5002476.1861598231
            ],
            [
              360654.76782578445,
              5002475.6359920027
            ],
            [
              360656.47728291369,
              5002475.0989784664
            ],
            [
              360657.88719245564,
              5002474.7696226994
            ],
            [
              360659.90377202904,
              5002474.4289927231
            ],
            [
              360662.62624476611,
              5002473.9741813103
            ],
            [
              360664.33764559723,
              5002473.5381949665
            ],
            [
              360666.25500133238,
              5002473.3004367007
            ],
            [
              360669.5897765017,
              5002473.1374363014
            ],
            [
              360671.91528969124,
              5002473.09421953
            ],
            [
              360674.03296981181,
              5002472.7517107492
            ],
            [
              360675.03659929201,
              5002472.3288419247
            ],
            [
              360675.53278256144,
              5002471.8143756166
            ],
            [
              360676.02708834404,
              5002471.1988809127
            ],
            [
              360677.02314294304,
              5002470.371970796
            ],
            [
              360677.72340428975,
              5002469.9547397019
            ],
            [
              360678.62586754578,
              5002469.5337864859
            ],
            [
              360681.23034412455,
              5002468.1717049796
            ],
            [
              360684.83657398372,
              5002466.2857973762
            ],
            [
              360686.43929789978,
              5002465.4475775668
            ],
            [
              360687.33431811194,
              5002464.6225450942
            ],
            [
              360688.02707082697,
              5002463.8012715057
            ],
            [
              360688.41270111635,
              5002462.7836143728
            ],
            [
              360688.39580574149,
              5002461.8744654115
            ],
            [
              360688.27968722972,
              5002461.0682236683
            ],
            [
              360687.75722249405,
              5002460.1684700642
            ],
            [
              360687.0306790325,
              5002459.1714457134
            ],
            [
              360686.30789053655,
              5002458.3764781477
            ],
            [
              360685.98769330367,
              5002457.4729656521
            ],
            [
              360685.87532910722,
              5002456.8687451649
            ],
            [
              360685.85843374807,
              5002455.9595961943
            ],
            [
              360686.25317933667,
              5002449.990272874
            ],
            [
              360687.01143146929,
              5002447.247828464
            ],
            [
              360688.27511982311,
              5002444.4959556563
            ],
            [
              360690.04256699153,
              5002441.6336934408
            ],
            [
              360691.62658506207,
              5002439.7853307109
            ],
            [
              360693.22367790155,
              5002438.6440969286
            ],
            [
              360695.37937409262,
              5002437.6154787382
            ],
            [
              360697.88831622893,
              5002436.5583627708
            ],
            [
              360699.29822563933,
              5002436.229007205
            ],
            [
              360700.30561004329,
              5002436.0081953052
            ],
            [
              360701.71927373629,
              5002435.8808609918
            ],
            [
              360705.15514853812,
              5002435.7159466203
            ],
            [
              360707.57807406998,
              5002435.4688288104
            ],
            [
              360708.7876596842,
              5002435.2442592811
            ],
            [
              360709.58902162529,
              5002434.8251494803
            ],
            [
              360710.27989689144,
              5002433.902847616
            ],
            [
              360712.35440017103,
              5002431.236970433
            ],
            [
              360713.53770443122,
              5002429.5981811481
            ],
            [
              360714.73602650489,
              5002428.767512382
            ],
            [
              360715.64036652853,
              5002428.4475521734
            ],
            [
              360716.64775088982,
              5002428.2267403081
            ],
            [
              360718.3609620725,
              5002427.8917483473
            ],
            [
              360720.0798712598,
              5002427.8598047802
            ],
            [
              360723.92578009184,
              5002427.9904248593
            ],
            [
              360726.35433725716,
              5002428.0463567395
            ],
            [
              360728.87648578378,
              5002427.6963317972
            ],
            [
              360730.89494180138,
              5002427.4566950193
            ],
            [
              360732.10452733835,
              5002427.2321255375
            ],
            [
              360733.310292334,
              5002426.8055360541
            ],
            [
              360734.41314483416,
              5002426.2797602369
            ],
            [
              360735.91101438401,
              5002425.2414338607
            ],
            [
              360738.50422719336,
              5002423.2732536951
            ],
            [
              360739.80177299137,
              5002422.3397133527
            ],
            [
              360740.88960768626,
              5002421.0058171162
            ],
            [
              360741.67219749891,
              5002419.5765657146
            ],
            [
              360742.34242320625,
              5002417.543093916
            ],
            [
              360743.39083632984,
              5002414.0878859945
            ],
            [
              360744.6114151674,
              5002409.0126795955
            ],
            [
              360745.38585175975,
              5002405.8037570911
            ],
            [
              360747.28979679657,
              5002399.4021710688
            ],
            [
              360748.04422802024,
              5002396.4576717289
            ],
            [
              360748.22014803503,
              5002395.0396944806
            ],
            [
              360748.29684491543,
              5002393.7246244205
            ],
            [
              360748.07023932494,
              5002392.4151552375
            ],
            [
              360747.9372258131,
              5002390.6997648021
            ],
            [
              360747.50084424211,
              5002388.9900120106
            ],
            [
              360747.37346247229,
              5002387.5776711758
            ],
            [
              360747.64304085897,
              5002385.7537714448
            ],
            [
              360748.22906131472,
              5002384.631327359
            ],
            [
              360749.12589210912,
              5002383.9072893197
            ],
            [
              360749.72693027084,
              5002383.5929656671
            ],
            [
              360750.33172269084,
              5002383.4806632409
            ],
            [
              360750.73424830864,
              5002383.3721551867
            ],
            [
              360751.13308525895,
              5002383.0615891442
            ],
            [
              360751.62926855305,
              5002382.5471230801
            ],
            [
              360752.73205481115,
              5002382.0213486645
            ],
            [
              360753.73568483448,
              5002381.5985157397
            ],
            [
              360754.74487966299,
              5002381.4786981093
            ],
            [
              360756.36081060977,
              5002381.3476052089
            ],
            [
              360757.26702802908,
              5002381.1286735423
            ],
            [
              360758.16385946813,
              5002380.4046710609
            ],
            [
              360758.85480103508,
              5002379.4823682047
            ],
            [
              360759.44075524056,
              5002378.3599253995
            ],
            [
              360759.81331106677,
              5002376.635139782
            ],
            [
              360760.59965527692,
              5002375.4079097752
            ],
            [
              360762.48509772745,
              5002373.4528829567
            ],
            [
              360764.47915060737,
              5002371.9000908593
            ],
            [
              360765.7823274965,
              5002371.2695648344
            ],
            [
              360766.8870574357,
              5002370.8448176207
            ],
            [
              360769.51030632277,
              5002370.4929143433
            ],
            [
              360773.95162166463,
              5002370.0061973967
            ],
            [
              360778.35504027188,
              5002369.3418398742
            ],
            [
              360780.07019425282,
              5002369.1078398693
            ],
            [
              360780.97453416081,
              5002368.7878798787
            ],
            [
              360781.77220801706,
              5002368.1667478839
            ],
            [
              360782.65777600702,
              5002366.8366463436
            ],
            [
              360785.3856102241,
              5002361.2281499784
            ],
            [
              360786.8553212547,
              5002358.6745761903
            ],
            [
              360787.74470976583,
              5002357.5464946637
            ],
            [
              360788.7445185617,
              5002356.921606346
            ],
            [
              360789.95034969918,
              5002356.4950159527
            ],
            [
              360791.26103500731,
              5002356.2685324261
            ],
            [
              360793.1727590831,
              5002355.7277608775
            ],
            [
              360795.18746053189,
              5002355.2861033706
            ],
            [
              360797.49795525678,
              5002354.4347671
            ],
            [
              360800.91130302963,
              5002353.0576908952
            ],
            [
              360804.92950936122,
              5002351.5683111381
            ],
            [
              360809.35392854124,
              5002350.1724103009
            ],
            [
              360812.77290790243,
              5002349.0983837368
            ],
            [
              360816.48962289322,
              5002347.7156345062
            ],
            [
              360820.20064057148,
              5002346.0298725329
            ],
            [
              360823.60091358644,
              5002343.9456678405
            ],
            [
              360829.5044301204,
              5002340.5013000295
            ],
            [
              360833.7004331699,
              5002337.6949364953
            ],
            [
              360838.15345214104,
              5002334.4250486027
            ],
            [
              360840.64931901527,
              5002332.6608052021
            ],
            [
              360841.75029453228,
              5002332.0340370387
            ],
            [
              360842.65651175758,
              5002331.8151055565
            ],
            [
              360843.46162840346,
              5002331.5980528621
            ],
            [
              360844.47088923765,
              5002331.4782341812
            ],
            [
              360847.18772903405,
              5002330.7203746289
            ],
            [
              360850.81266346056,
              5002329.8446119688
            ],
            [
              360854.33280929987,
              5002328.7687057182
            ],
            [
              360857.15074959834,
              5002328.0089674238
            ],
            [
              360859.15981970046,
              5002327.2642961945
            ],
            [
              360860.76254380593,
              5002326.4261129973
            ],
            [
              360862.46080240275,
              5002325.2829647511
            ],
            [
              360864.05226316577,
              5002323.8386825649
            ],
            [
              360865.43964600348,
              5002322.2971651526
            ],
            [
              360867.41492542956,
              5002319.7341614403
            ],
            [
              360869.59435035533,
              5002317.2684628414
            ],
            [
              360871.4797262905,
              5002315.3134381957
            ],
            [
              360873.36704657663,
              5002313.4594762167
            ],
            [
              360876.06511444022,
              5002311.6914755888
            ],
            [
              360878.26143366849,
              5002310.1348900376
            ],
            [
              360879.86040351231,
              5002309.0946858218
            ],
            [
              360881.2646806484,
              5002308.4622814637
            ],
            [
              360882.3656560813,
              5002307.8355134521
            ],
            [
              360883.46475407365,
              5002307.1077171033
            ],
            [
              360884.65550051478,
              5002305.8729725815
            ],
            [
              360885.44566581852,
              5002304.8477986334
            ],
            [
              360886.42482527159,
              5002303.1117410557
            ],
            [
              360888.02332143154,
              5002300.273558707
            ],
            [
              360889.88060606259,
              5002296.8033211641
            ],
            [
              360891.24921590439,
              5002294.2515916647
            ],
            [
              360892.12170944415,
              5002292.2143622134
            ],
            [
              360892.99038253905,
              5002289.9751128638
            ],
            [
              360894.14928288991,
              5002287.0230986848
            ],
            [
              360895.02546507487,
              5002285.1879271735
            ],
            [
              360896.10766818427,
              5002283.5509824986
            ],
            [
              360897.87317261199,
              5002280.5877312729
            ],
            [
              360899.85039584414,
              5002278.1257551489
            ],
            [
              360901.82755283144,
              5002275.6637802813
            ],
            [
              360903.31415886688,
              5002274.0193561269
            ],
            [
              360904.91125127731,
              5002272.8781238161
            ],
            [
              360907.00828111527,
              5002271.4244469814
            ],
            [
              360909.80751574266,
              5002269.6545668561
            ],
            [
              360913.40992320236,
              5002267.5666071605
            ],
            [
              360916.31582371664,
              5002266.0978990216
            ],
            [
              360918.42042814189,
              5002265.0482632918
            ],
            [
              360920.32269960258,
              5002264.0024231058
            ],
            [
              360921.82438927767,
              5002263.1661178907
            ],
            [
              360923.32225784706,
              5002262.1277572513
            ],
            [
              360925.96226591803,
              5002260.1812828174
            ],
            [
              360930.35483861127,
              5002257.0681496141
            ],
            [
              360933.84863635566,
              5002254.5779911717
            ],
            [
              360936.03738086164,
              5002252.6173652504
            ],
            [
              360938.62496221828,
              5002250.3461736338
            ],
            [
              360940.0123444263,
              5002248.8046213174
            ],
            [
              360941.39597245835,
              5002247.0610478986
            ],
            [
              360943.45927886671,
              5002243.789073539
            ],
            [
              360945.32588394423,
              5002240.8239441644
            ],
            [
              360946.60090244113,
              5002238.6781718107
            ],
            [
              360947.78045198548,
              5002236.837327661
            ],
            [
              360949.55916273169,
              5002234.5811675349
            ],
            [
              360951.54382874473,
              5002232.5232710224
            ],
            [
              360953.42739421362,
              5002230.4672532892
            ],
            [
              360955.90824346122,
              5002227.8948912891
            ],
            [
              360957.19264774298,
              5002226.2541895732
            ],
            [
              360959.48684821179,
              5002223.0031994684
            ],
            [
              360960.76186676783,
              5002220.8574272702
            ],
            [
              360962.41882813483,
              5002217.4919767082
            ],
            [
              360963.97462207143,
              5002214.1283706278
            ],
            [
              360964.93695397628,
              5002211.4831997603
            ],
            [
              360966.40478795109,
              5002208.8285996281
            ],
            [
              360967.18362332293,
              5002207.1972930124
            ],
            [
              360968.06543730991,
              5002205.6651715077
            ],
            [
              360968.95107104577,
              5002204.3350343434
            ],
            [
              360970.03133131238,
              5002202.5970987258
            ],
            [
              360970.90758000856,
              5002200.7619266035
            ],
            [
              360971.98596288153,
              5002198.9229626916
            ],
            [
              360974.0511469699,
              5002195.7520172251
            ],
            [
              360977.50927868782,
              5002191.3425705153
            ],
            [
              360979.78786072746,
              5002188.7739676945
            ],
            [
              360981.57595730986,
              5002187.0228785155
            ],
            [
              360985.55655261985,
              5002183.5131852413
            ],
            [
              360989.88836579118,
              5002180.0049637202
            ],
            [
              360993.37465522072,
              5002177.1107643293
            ],
            [
              360995.37246179074,
              5002175.759995495
            ],
            [
              360998.25777887221,
              5002173.1800824795
            ],
            [
              361001.72905108641,
              5002169.4777633818
            ],
            [
              361005.6965065627,
              5002165.2609789856
            ],
            [
              361007.18311201979,
              5002163.6165202707
            ],
            [
              361009.77444755909,
              5002161.5473508677
            ],
            [
              361013.15775902249,
              5002158.5540022627
            ],
            [
              361015.05071089807,
              5002157.0030912822
            ],
            [
              361017.34813047404,
              5002155.4446286159
            ],
            [
              361020.44309122983,
              5002153.2650373671
            ],
            [
              361023.73468842503,
              5002150.7786736349
            ],
            [
              361027.80887524772,
              5002146.8630248196
            ],
            [
              361030.39082511252,
              5002144.2887852443
            ],
            [
              361032.17892165919,
              5002142.5376965553
            ],
            [
              361034.65226211329,
              5002139.5612581251
            ],
            [
              361037.86540942785,
              5002135.2748825355
            ],
            [
              361040.83680988353,
              5002131.884971696
            ],
            [
              361044.80426537123,
              5002127.6681882152
            ],
            [
              361049.38402105455,
              5002123.7431450617
            ],
            [
              361052.76733245887,
              5002120.7497971728
            ],
            [
              361054.66403839045,
              5002119.4009076273
            ],
            [
              361056.36792886438,
              5002118.5608459199
            ],
            [
              361057.77220563375,
              5002117.92844267
            ],
            [
              361058.87311459915,
              5002117.3016768238
            ],
            [
              361059.86923519935,
              5002116.4747674242
            ],
            [
              361060.75480279344,
              5002115.1446320741
            ],
            [
              361061.14237667236,
              5002114.227967957
            ],
            [
              361061.22095193894,
              5002113.0139274402
            ],
            [
              361061.27512327326,
              5002110.4866257692
            ],
            [
              361061.42101023498,
              5002107.4524105415
            ],
            [
              361061.79544447683,
              5002105.8286550166
            ],
            [
              361062.57615750021,
              5002104.298377376
            ],
            [
              361063.56664665305,
              5002103.1684186943
            ],
            [
              361064.77053285606,
              5002102.6407669382
            ],
            [
              361065.67681589402,
              5002102.4218349718
            ],
            [
              361066.89015456138,
              5002102.3992878944
            ],
            [
              361068.30569425045,
              5002102.3729833821
            ],
            [
              361069.31676563161,
              5002102.3541949717
            ],
            [
              361070.52635019476,
              5002102.1296268553
            ],
            [
              361071.22848856641,
              5002101.8134252774
            ],
            [
              361072.02797335823,
              5002101.2933239434
            ],
            [
              361073.02033920825,
              5002100.2643580521
            ],
            [
              361073.71309202205,
              5002099.4430861203
            ],
            [
              361074.99749714124,
              5002097.8024209831
            ],
            [
              361075.88306476787,
              5002096.4722857382
            ],
            [
              361076.9709003325,
              5002095.138427374
            ],
            [
              361078.16546720784,
              5002094.105704084
            ],
            [
              361080.18061980471,
              5002092.5685195709
            ],
            [
              361081.676611492,
              5002091.4291674076
            ],
            [
              361082.36936430959,
              5002090.6078955121
            ],
            [
              361083.1557750621,
              5002089.3806660818
            ],
            [
              361084.31648762024,
              5002086.5296837203
            ],
            [
              361084.88561413018,
              5002084.4980933089
            ],
            [
              361084.76386519201,
              5002083.3888030555
            ],
            [
              361084.44554602762,
              5002082.5862839986
            ],
            [
              361083.92308354721,
              5002081.6865663873
            ],
            [
              361083.09356414375,
              5002080.5903928047
            ],
            [
              361081.65174464748,
              5002079.2024789322
            ],
            [
              361080.92144970741,
              5002078.0034694122
            ],
            [
              361080.3858463178,
              5002076.3965893313
            ],
            [
              361080.24532636872,
              5002074.2771583628
            ],
            [
              361080.60655424703,
              5002071.9462772785
            ],
            [
              361081.57264033513,
              5002069.5030932007
            ],
            [
              361082.26539320586,
              5002068.6818213528
            ],
            [
              361083.16410116554,
              5002068.0588131212
            ],
            [
              361084.97660027485,
              5002067.6209150953
            ],
            [
              361086.89207790844,
              5002067.2822021814
            ],
            [
              361088.4068406602,
              5002067.1529908245
            ],
            [
              361090.12950210675,
              5002067.323070135
            ],
            [
              361092.1592197952,
              5002067.6895698775
            ],
            [
              361094.18518274027,
              5002067.8540130584
            ],
            [
              361095.09515354183,
              5002067.8371034581
            ],
            [
              361096.09871604393,
              5002067.4142375691
            ],
            [
              361096.59302261117,
              5002066.7987797325
            ],
            [
              361096.88325014361,
              5002066.0860154955
            ],
            [
              361097.16214921809,
              5002064.7671894226
            ],
            [
              361097.13774612366,
              5002063.4539639363
            ],
            [
              361096.99722626148,
              5002061.3345330665
            ],
            [
              361096.45036011742,
              5002059.1215544259
            ],
            [
              361096.11139333475,
              5002057.2079019798
            ],
            [
              361096.20504826732,
              5002055.1076879101
            ],
            [
              361096.58880195272,
              5002053.9890041659
            ],
            [
              361097.37702391198,
              5002052.8628043756
            ],
            [
              361098.77198074356,
              5002051.7252943777
            ],
            [
              361100.67800592334,
              5002050.8814769452
            ],
            [
              361102.38752775913,
              5002050.3444649884
            ],
            [
              361103.89853638929,
              5002050.0132326949
            ],
            [
              361105.01077442319,
              5002049.9925645078
            ],
            [
              361106.12113509345,
              5002049.870868044
            ],
            [
              361106.72398404696,
              5002049.6575748138
            ],
            [
              361107.42618864478,
              5002049.3413721444
            ],
            [
              361108.81920249743,
              5002048.102870685
            ],
            [
              361110.50050095952,
              5002046.0506135356
            ],
            [
              361111.28309149743,
              5002044.6213645218
            ],
            [
              361111.66878881032,
              5002043.6037078816
            ],
            [
              361111.7511177116,
              5002042.5916530378
            ],
            [
              361111.61435203865,
              5002040.6742432602
            ],
            [
              361111.04120702232,
              5002037.0470820442
            ],
            [
              361109.87245708599,
              5002028.5805256413
            ],
            [
              361109.83303794538,
              5002026.4592161374
            ],
            [
              361110.404041462,
              5002024.528618712
            ],
            [
              361110.98630844092,
              5002023.2041554125
            ],
            [
              361112.07032322354,
              5002021.6682420941
            ],
            [
              361113.75168808084,
              5002019.6159838634
            ],
            [
              361114.93686962273,
              5002018.0781906461
            ],
            [
              361115.62211446872,
              5002016.8528769556
            ],
            [
              361116.19687272998,
              5002015.1243361272
            ],
            [
              361116.96369831188,
              5002011.9942984255
            ],
            [
              361117.79483033513,
              5002007.7347345268
            ],
            [
              361118.46881178999,
              5002005.9032867644
            ],
            [
              361119.35256922798,
              5002004.4721579533
            ],
            [
              361122.61976370693,
              5002000.6725709643
            ],
            [
              361124.50708432245,
              5001998.818612067
            ],
            [
              361126.50489017559,
              5001997.4678092552
            ],
            [
              361128.51208208333,
              5001996.6221122686
            ],
            [
              361130.82257546711,
              5001995.7707792372
            ],
            [
              361132.92899050447,
              5001994.8221753202
            ],
            [
              361134.62537218462,
              5001993.5780368866
            ],
            [
              361135.90790024138,
              5001991.8363441676
            ],
            [
              361137.38136655482,
              5001989.4847955545
            ],
            [
              361138.74622434116,
              5001986.7310836492
            ],
            [
              361139.51192097721,
              5001984.3926870897
            ],
            [
              361140.08292534231,
              5001982.4621254373
            ],
            [
              361140.64454367995,
              5001980.0264579924
            ],
            [
              361141.31033669371,
              5001976.2171615195
            ],
            [
              361142.81176294078,
              5001969.924128213
            ],
            [
              361144.03791137651,
              5001965.1519442322
            ],
            [
              361145.18555165845,
              5001961.5938703064
            ],
            [
              361145.85765597207,
              5001959.6613945113
            ],
            [
              361146.64031304332,
              5001958.232144611
            ],
            [
              361148.32724319602,
              5001956.4829373835
            ],
            [
              361149.92064777884,
              5001955.1396851409
            ],
            [
              361151.61884059961,
              5001953.996576461
            ],
            [
              361153.7271329569,
              5001953.1490011178
            ],
            [
              361155.63885500043,
              5001952.6081968527
            ],
            [
              361157.54682369251,
              5001951.8654071372
            ],
            [
              361159.04844684334,
              5001951.0291050924
            ],
            [
              361160.54450425133,
              5001949.8897170387
            ],
            [
              361161.23538057989,
              5001948.967452893
            ],
            [
              361161.71648036607,
              5001947.6448342837
            ],
            [
              361162.10217791062,
              5001946.6271778997
            ],
            [
              361162.3109485,
              5001944.8334534569
            ],
            [
              361162.54861764441,
              5001941.2922535185
            ],
            [
              361162.92680655699,
              5001939.8705196371
            ],
            [
              361163.31806919136,
              5001939.1559137395
            ],
            [
              361163.91535238439,
              5001938.6395347267
            ],
            [
              361164.71671453572,
              5001938.2204621742
            ],
            [
              361165.92066755379,
              5001937.6928454563
            ],
            [
              361167.22947504476,
              5001937.3653710568
            ],
            [
              361168.64125968301,
              5001937.1370107131
            ],
            [
              361170.97434447409,
              5001937.4978739079
            ],
            [
              361172.59959336265,
              5001937.8718902422
            ],
            [
              361174.12374110054,
              5001938.2477497058
            ],
            [
              361175.54303435696,
              5001938.4234668557
            ],
            [
              361176.95676319965,
              5001938.2961690752
            ],
            [
              361178.06518009899,
              5001938.0734459748
            ],
            [
              361179.27101039659,
              5001937.6468575317
            ],
            [
              361180.17159566836,
              5001937.1248780778
            ],
            [
              361181.86797732621,
              5001935.8807400987
            ],
            [
              361184.35445845855,
              5001933.6114311572
            ],
            [
              361186.23426979966,
              5001931.353359947
            ],
            [
              361188.1008762673,
              5001928.3882343611
            ],
            [
              361188.78424330457,
              5001927.0618573176
            ],
            [
              361188.96961658594,
              5001926.1489510862
            ],
            [
              361188.95084599685,
              5001925.138810697
            ],
            [
              361189.24288481678,
              5001924.5270762565
            ],
            [
              361189.64172167535,
              5001924.2165111434
            ],
            [
              361190.34385996818,
              5001923.9003100349
            ],
            [
              361190.83628867596,
              5001923.1837887838
            ],
            [
              361192.18425287172,
              5001919.5209299056
            ],
            [
              361194.50199171371,
              5001913.6169104194
            ],
            [
              361196.44147940009,
              5001907.9507332724
            ],
            [
              361198.92206726858,
              5001899.9216464171
            ],
            [
              361199.77391455596,
              5001896.7732511768
            ],
            [
              361200.55650551413,
              5001895.3440028727
            ],
            [
              361201.54317474068,
              5001894.0120255128
            ],
            [
              361202.33333989134,
              5001892.9868179196
            ],
            [
              361203.1271934174,
              5001892.1636680569
            ],
            [
              361204.32363777969,
              5001891.2319741007
            ],
            [
              361205.22234574478,
              5001890.6089665554
            ],
            [
              361206.31393566594,
              5001889.4771303022
            ],
            [
              361207.90351936821,
              5001887.9318233319
            ],
            [
              361210.27763761213,
              5001885.0582951512
            ],
            [
              361213.37970886793,
              5001881.0036997311
            ],
            [
              361215.66204563301,
              5001878.6371216811
            ],
            [
              361216.85285872372,
              5001877.4023786066
            ],
            [
              361217.95758783043,
              5001876.9776335414
            ],
            [
              361218.96490482864,
              5001876.756824851
            ],
            [
              361219.77377484081,
              5001876.7417944157
            ],
            [
              361220.98523567338,
              5001876.6182199009
            ],
            [
              361222.19481985428,
              5001876.3936526822
            ],
            [
              361223.09915906267,
              5001876.0736943996
            ],
            [
              361223.8893248784,
              5001875.0485224482
            ],
            [
              361225.35528304958,
              5001872.292897502
            ],
            [
              361226.23334326083,
              5001870.5587215172
            ],
            [
              361226.92421901098,
              5001869.6364221731
            ],
            [
              361227.62072602031,
              5001869.0171720199
            ],
            [
              361228.72545576358,
              5001868.5924625322
            ],
            [
              361230.43115638796,
              5001867.8533964334
            ],
            [
              361232.33161628857,
              5001866.7065299274
            ],
            [
              361233.43259140843,
              5001866.0797639703
            ],
            [
              361234.83123666822,
              5001865.144312893
            ],
            [
              361236.81778056367,
              5001863.1874484587
            ],
            [
              361238.40743049519,
              5001861.6421405179
            ],
            [
              361239.30238447804,
              5001860.8171121692
            ],
            [
              361240.00076878181,
              5001860.2988902964
            ],
            [
              361240.70666097855,
              5001860.184710308
            ],
            [
              361241.7177981197,
              5001860.1659213901
            ],
            [
              361243.03404769924,
              5001860.242526005
            ],
            [
              361244.6537305059,
              5001860.3134566834
            ],
            [
              361246.06739284151,
              5001860.1861604834
            ],
            [
              361246.77140838216,
              5001859.9709877949
            ],
            [
              361247.47166931536,
              5001859.5537586361
            ],
            [
              361248.57452170271,
              5001859.0280209668
            ],
            [
              361251.38307463046,
              5001857.7632172974
            ],
            [
              361253.19181918068,
              5001857.123299662
            ],
            [
              361254.60172687721,
              5001856.7939470075
            ],
            [
              361256.2232206788,
              5001856.9659071658
            ],
            [
              361257.64626816049,
              5001857.3436810784
            ],
            [
              361258.56568998593,
              5001857.8318409091
            ],
            [
              361259.28847588837,
              5001858.6268084226
            ],
            [
              361260.11048560677,
              5001859.3189045852
            ],
            [
              361261.13845018757,
              5001860.2092643883
            ],
            [
              361262.06162595341,
              5001860.8994451333
            ],
            [
              361263.08395928139,
              5001861.486755765
            ],
            [
              361265.13432343322,
              5001862.9644249445
            ],
            [
              361267.18093297124,
              5001864.2400376434
            ],
            [
              361268.00676288299,
              5001865.1341534778
            ],
            [
              361268.83815779787,
              5001866.3313196879
            ],
            [
              361269.6733066655,
              5001867.7305068159
            ],
            [
              361271.4635422033,
              5001871.5371768614
            ],
            [
              361272.94102427969,
              5001874.8443784388
            ],
            [
              361273.46905160224,
              5001876.0471817888
            ],
            [
              361274.19371482439,
              5001876.9431774588
            ],
            [
              361274.91281303356,
              5001877.5361227412
            ],
            [
              361275.69359775452,
              5001878.2031414406
            ],
            [
              361277.53237511351,
              5001879.1794621684
            ],
            [
              361279.68196239788,
              5001880.5542243011
            ],
            [
              361282.14054855116,
              5001882.226398374
            ],
            [
              361283.47563534876,
              5001883.3131773118
            ],
            [
              361284.91369858693,
              5001884.4990347466
            ],
            [
              361286.15143889171,
              5001885.7897132346
            ],
            [
              361287.39105584874,
              5001887.1813844014
            ],
            [
              361288.01461862033,
              5001888.0792586636
            ],
            [
              361288.53889134293,
              5001889.080005493
            ],
            [
              361288.96025321691,
              5001889.981637056
            ],
            [
              361289.48271554388,
              5001890.8813899616
            ],
            [
              361289.797279444,
              5001891.4818522688
            ],
            [
              361290.41708759737,
              5001892.17767007
            ],
            [
              361291.65858187695,
              5001893.6703694183
            ],
            [
              361292.28777593718,
              5001894.8712927913
            ],
            [
              361292.90570743912,
              5001895.4661178924
            ],
            [
              361293.72583983763,
              5001896.0571856806
            ],
            [
              361294.97108809062,
              5001897.7519059116
            ],
            [
              361295.80060573888,
              5001898.8480437277
            ],
            [
              361296.62636941986,
              5001899.7421606332
            ],
            [
              361297.74799209408,
              5001900.2265629778
            ],
            [
              361298.86579454335,
              5001900.5089456486
            ],
            [
              361300.59408613987,
              5001900.9820748148
            ],
            [
              361302.22121103032,
              5001901.4570838669
            ],
            [
              361303.95513389789,
              5001902.2332621478
            ],
            [
              361304.87261275476,
              5001902.6204303419
            ],
            [
              361305.59164469148,
              5001903.2133767493
            ],
            [
              361306.00925195141,
              5001903.9129518103
            ],
            [
              361306.33320178086,
              5001905.0185196372
            ],
            [
              361306.75831764675,
              5001906.1221720343
            ],
            [
              361307.20971265523,
              5001908.6400418105
            ],
            [
              361308.069263432,
              5001911.3524178388
            ],
            [
              361308.49431372614,
              5001912.4561069869
            ],
            [
              361309.42124344729,
              5001913.3483084003
            ],
            [
              361310.54467716528,
              5001913.9337401344
            ],
            [
              361312.47892424412,
              5001914.6051683296
            ],
            [
              361315.0217168745,
              5001915.3663159991
            ],
            [
              361317.25175663672,
              5001915.6280308682
            ],
            [
              361318.66736156325,
              5001915.6017259154
            ],
            [
              361319.77577868308,
              5001915.3790385155
            ],
            [
              361320.98723914771,
              5001915.2554639028
            ],
            [
              361323.01132401556,
              5001915.3189152004
            ],
            [
              361326.62231758289,
              5001915.6725974884
            ],
            [
              361329.04899310059,
              5001915.6275046915
            ],
            [
              361330.45890042983,
              5001915.298151874
            ],
            [
              361332.36311359651,
              5001914.3533063233
            ],
            [
              361334.05379704147,
              5001912.8061211482
            ],
            [
              361335.63211812422,
              5001910.6547519919
            ],
            [
              361338.39756339375,
              5001907.0665824655
            ],
            [
              361339.88604597276,
              5001905.5231546685
            ],
            [
              361341.77524256968,
              5001903.7701901393
            ],
            [
              361345.26340809016,
              5001900.9770247238
            ],
            [
              361346.75564463594,
              5001899.6356178625
            ],
            [
              361348.82458321791,
              5001896.6666985257
            ],
            [
              361350.48335696751,
              5001893.4022827623
            ],
            [
              361351.24342249747,
              5001890.7608387675
            ],
            [
              361351.72458849568,
              5001889.4382197382
            ],
            [
              361352.07473710558,
              5001887.5525014475
            ],
            [
              361352.63072450669,
              5001884.8137865802
            ],
            [
              361353.17920401058,
              5001881.67102997
            ],
            [
              361353.43752208917,
              5001879.2410356849
            ],
            [
              361353.59467284259,
              5001876.812885751
            ],
            [
              361353.50645064237,
              5001872.0651642187
            ],
            [
              361353.36379328428,
              5001864.3879800923
            ],
            [
              361353.43110621005,
              5001862.5678069666
            ],
            [
              361353.80554063426,
              5001860.944017549
            ],
            [
              361354.77906976134,
              5001858.9049145225
            ],
            [
              361355.6590732644,
              5001857.2717662016
            ],
            [
              361356.02743690345,
              5001855.2642062847
            ],
            [
              361356.3867886847,
              5001852.8322990919
            ],
            [
              361356.12827608472,
              5001849.8056011358
            ],
            [
              361355.36794904602,
              5001846.9903184855
            ],
            [
              361354.30800842174,
              5001844.3826938486
            ],
            [
              361352.21634962305,
              5001840.6826887
            ],
            [
              361349.39815059857,
              5001835.9856597455
            ],
            [
              361348.03678603657,
              5001833.4846991478
            ],
            [
              361346.88895104866,
              5001831.5860781968
            ],
            [
              361346.14739443461,
              5001829.780935362
            ],
            [
              361345.71664764016,
              5001828.374233908
            ],
            [
              361345.15852162096,
              5001825.5551938554
            ],
            [
              361344.58350197953,
              5001821.8270065263
            ],
            [
              361343.90932548529,
              5001818.2017247966
            ],
            [
              361343.16766084009,
              5001811.0550635811
            ],
            [
              361342.60584663524,
              5001808.0339658288
            ],
            [
              361341.95225154294,
              5001805.5198534392
            ],
            [
              361340.57023966807,
              5001801.9077245025
            ],
            [
              361339.71444374573,
              5001799.3973693363
            ],
            [
              361339.36602664046,
              5001796.9786137016
            ],
            [
              361338.99890561134,
              5001793.5497168759
            ],
            [
              361338.94634829537,
              5001790.7212823546
            ],
            [
              361339.0997457768,
              5001788.0911114709
            ],
            [
              361339.24751209508,
              5001785.1578914942
            ],
            [
              361339.20997110865,
              5001783.1375760492
            ],
            [
              361339.17243013257,
              5001781.1172605995
            ],
            [
              361338.93456557783,
              5001779.2017306034
            ],
            [
              361338.48336000071,
              5001776.2089354983
            ],
            [
              361338.12750085985,
              5001773.3861012952
            ],
            [
              361338.18730648357,
              5001771.1618863344
            ],
            [
              361338.45494416216,
              5001769.2369632358
            ],
            [
              361338.93235675606,
              5001767.7123234402
            ],
            [
              361339.51274706691,
              5001766.2868332444
            ],
            [
              361341.67896714056,
              5001763.1140163746
            ],
            [
              361344.12039899023,
              5001758.4203170184
            ],
            [
              361345.09955990996,
              5001756.6842633113
            ],
            [
              361345.37852622708,
              5001755.3654372087
            ],
            [
              361345.34849318076,
              5001753.749163555
            ],
            [
              361345.28842777089,
              5001750.5166517841
            ],
            [
              361344.5037003184,
              5001746.3881797902
            ],
            [
              361343.52609081269,
              5001742.7685361998
            ],
            [
              361341.93189244898,
              5001739.5893080663
            ],
            [
              361340.99188997026,
              5001737.9899786431
            ],
            [
              361339.33842080383,
              5001736.100752838
            ],
            [
              361337.68126335961,
              5001734.0094693601
            ],
            [
              361336.63070854056,
              5001731.9069142193
            ],
            [
              361335.78429812077,
              5001729.9016288361
            ],
            [
              361335.4453334125,
              5001727.987977392
            ],
            [
              361335.71672520012,
              5001726.2650751956
            ],
            [
              361336.48993156722,
              5001724.3307578592
            ],
            [
              361337.96527546231,
              5001722.0802044393
            ],
            [
              361339.63162490068,
              5001719.2198299449
            ],
            [
              361341.00023792026,
              5001716.6681416109
            ],
            [
              361341.86522603704,
              5001714.2268745191
            ],
            [
              361342.14787946589,
              5001713.1100350153
            ],
            [
              361341.92503138335,
              5001712.0026240582
            ],
            [
              361341.69085481967,
              5001710.2891161423
            ],
            [
              361341.63642093207,
              5001707.3596534198
            ],
            [
              361341.58386432577,
              5001704.5312189059
            ],
            [
              361341.65312160883,
              5001702.812072725
            ],
            [
              361342.36811196938,
              5001700.291370227
            ],
            [
              361343.43523481797,
              5001697.846347223
            ],
            [
              361344.50993162894,
              5001695.8053647643
            ],
            [
              361345.29815419851,
              5001694.6791666066
            ],
            [
              361346.59569903888,
              5001693.7455951273
            ],
            [
              361350.40037256596,
              5001691.6538857017
            ],
            [
              361359.61244288564,
              5001686.6322879987
            ],
            [
              361367.91635327268,
              5001681.7285930244
            ],
            [
              361375.11935543065,
              5001677.4516976783
            ],
            [
              361381.21575074998,
              5001673.4984829836
            ],
            [
              361388.24711249938,
              5001668.6729295207
            ],
            [
              361393.94091700058,
              5001664.8282589074
            ],
            [
              361400.52411068039,
              5001659.8555107117
            ],
            [
              361404.81370512705,
              5001656.6432398735
            ],
            [
              361407.69520261808,
              5001653.8613146711
            ],
            [
              361410.07119869115,
              5001651.0888179652
            ],
            [
              361413.32900945778,
              5001646.7841676725
            ],
            [
              361416.94548360503,
              5001640.6453639772
            ],
            [
              361418.62866024295,
              5001638.6941388464
            ],
            [
              361420.22769521311,
              5001637.653902621
            ],
            [
              361421.48099839187,
              5001637.2070241934
            ],
            [
              361422.38533796242,
              5001636.8871022649
            ],
            [
              361422.67556611431,
              5001636.1743390635
            ],
            [
              361422.8552427438,
              5001634.9583858158
            ],
            [
              361423.21647325222,
              5001632.6275075069
            ],
            [
              361423.83796640416,
              5001627.9676270187
            ],
            [
              361424.63907070208,
              5001622.0918300776
            ],
            [
              361425.20632217202,
              5001619.9592142543
            ],
            [
              361426.35021064087,
              5001616.1990880119
            ],
            [
              361427.41357936984,
              5001613.552009494
            ],
            [
              361428.48076882452,
              5001611.1069861325
            ],
            [
              361429.46368404716,
              5001609.5729540996
            ],
            [
              361430.03656661027,
              5001607.7433873406
            ],
            [
              361430.4034945693,
              5001605.7155568944
            ],
            [
              361430.49521875015,
              5001604.0320394337
            ],
            [
              361430.35845565551,
              5001602.1146312514
            ],
            [
              361430.38823006797,
              5001598.2741436437
            ],
            [
              361430.63716463273,
              5001595.3390447265
            ],
            [
              361431.10325025796,
              5001593.2083443161
            ],
            [
              361432.17607019533,
              5001591.0663345205
            ],
            [
              361433.4567226278,
              5001589.2236521589
            ],
            [
              361434.84598304232,
              5001587.7831329089
            ],
            [
              361435.83084116521,
              5001586.3500923896
            ],
            [
              361436.80437169183,
              5001584.3109906204
            ],
            [
              361438.36392414285,
              5001581.1494483985
            ],
            [
              361440.68924162531,
              5001575.6495113662
            ],
            [
              361442.54653061635,
              5001572.1792832986
            ],
            [
              361445.59941371856,
              5001567.2001499217
            ],
            [
              361450.11347658554,
              5001559.7395945163
            ],
            [
              361452.57180338405,
              5001555.9550450761
            ],
            [
              361454.14824895142,
              5001553.7026501177
            ],
            [
              361455.63485541166,
              5001552.0581961898
            ],
            [
              361457.52029942168,
              5001550.1032133736
            ],
            [
              361459.39260428166,
              5001547.4411044186
            ],
            [
              361460.85856474628,
              5001544.6855182899
            ],
            [
              361461.3453628936,
              5001543.6659491537
            ],
            [
              361461.32846999425,
              5001542.7568021789
            ],
            [
              361460.91092990967,
              5001542.0572257619
            ],
            [
              361459.89040867129,
              5001541.5709439218
            ],
            [
              361459.07778478978,
              5001541.3839172041
            ],
            [
              361458.06664826744,
              5001541.4027051693
            ],
            [
              361456.5481331824,
              5001541.3298932454
            ],
            [
              361455.53518569609,
              5001541.2476518061
            ],
            [
              361454.61764139362,
              5001540.8604840441
            ],
            [
              361453.89485663566,
              5001540.0655163145
            ],
            [
              361453.16644091561,
              5001538.9674995793
            ],
            [
              361452.61957936577,
              5001536.7545579243
            ],
            [
              361452.37420808763,
              5001534.4349512123
            ],
            [
              361452.6005531071,
              5001530.2876577592
            ],
            [
              361452.88203639962,
              5001528.4836259419
            ],
            [
              361453.36883526645,
              5001527.4640923394
            ],
            [
              361454.26003508695,
              5001526.4370090198
            ],
            [
              361457.03855627775,
              5001523.5559712136
            ],
            [
              361461.01164475002,
              5001519.6422133083
            ],
            [
              361463.09184782411,
              5001517.2793952171
            ],
            [
              361464.17774096929,
              5001515.8445132403
            ],
            [
              361464.6626626561,
              5001514.7239515139
            ],
            [
              361464.84797063528,
              5001513.8110474665
            ],
            [
              361464.52026913624,
              5001512.5034944983
            ],
            [
              361463.78246884857,
              5001510.9004079383
            ],
            [
              361462.62330724567,
              5001508.3956542574
            ],
            [
              361461.76751377148,
              5001505.8852992421
            ],
            [
              361461.43230416911,
              5001504.1736690877
            ],
            [
              361461.59696565178,
              5001502.1495972481
            ],
            [
              361462.18111059215,
              5001500.9261288177
            ],
            [
              361463.37004765705,
              5001499.5903952941
            ],
            [
              361465.06260952965,
              5001498.1442409204
            ],
            [
              361466.85258432064,
              5001496.4941859767
            ],
            [
              361469.04883724108,
              5001494.9376095422
            ],
            [
              361471.24321296025,
              5001493.280004954
            ],
            [
              361473.73344866553,
              5001491.2127219308
            ],
            [
              361475.92601409345,
              5001489.4541235231
            ],
            [
              361477.61857594602,
              5001488.0079692509
            ],
            [
              361479.20816086879,
              5001486.4627008885
            ],
            [
              361480.12133712083,
              5001485.55187805
            ],
            [
              361483.81170478417,
              5001482.7549253944
            ],
            [
              361487.18751008954,
              5001479.3575459914
            ],
            [
              361490.55211917375,
              5001475.3540319558
            ],
            [
              361492.52364829998,
              5001472.5890525011
            ],
            [
              361493.50468677306,
              5001470.9539929843
            ],
            [
              361494.1880556356,
              5001469.6276535643
            ],
            [
              361494.65796192008,
              5001467.6989732813
            ],
            [
              361494.92560051946,
              5001465.7740157517
            ],
            [
              361495.18392143614,
              5001463.3440227723
            ],
            [
              361495.33356764889,
              5001460.5118324244
            ],
            [
              361495.08444254886,
              5001457.9901696099
            ],
            [
              361494.62929738592,
              5001455.2702798108
            ],
            [
              361493.9757051646,
              5001452.7561678486
            ],
            [
              361492.92146509275,
              5001450.4515907699
            ],
            [
              361491.979520939,
              5001448.7512343172
            ],
            [
              361490.53019764472,
              5001446.9592422983
            ],
            [
              361488.17278173921,
              5001445.2851863615
            ],
            [
              361485.14501766075,
              5001443.4374920381
            ],
            [
              361482.90184106393,
              5001442.468683647
            ],
            [
              361481.87944285193,
              5001441.881373371
            ],
            [
              361481.25400441705,
              5001440.882470631
            ],
            [
              361480.92818038951,
              5001439.6759458277
            ],
            [
              361480.79517126823,
              5001437.9605231276
            ],
            [
              361480.84747165424,
              5001435.3322675331
            ],
            [
              361481.50080915861,
              5001432.3896550862
            ],
            [
              361482.17667015147,
              5001430.6592385499
            ],
            [
              361482.76269248722,
              5001429.5367984874
            ],
            [
              361483.04910107836,
              5001428.6220160136
            ],
            [
              361483.23072183825,
              5001427.5070900237
            ],
            [
              361483.3111759547,
              5001426.3940437799
            ],
            [
              361483.59007651644,
              5001425.0751841664
            ],
            [
              361485.81804948568,
              5001419.7791482238
            ],
            [
              361488.75560244982,
              5001414.5709909294
            ],
            [
              361490.93127562449,
              5001411.9032461839
            ],
            [
              361493.22862982936,
              5001410.3448274219
            ],
            [
              361494.93439638673,
              5001409.6057629371
            ],
            [
              361495.94171287975,
              5001409.3849557927
            ],
            [
              361497.05019601231,
              5001409.1622689115
            ],
            [
              361499.58547815192,
              5001409.5193427969
            ],
            [
              361501.41104853043,
              5001409.7885760879
            ],
            [
              361502.12632553023,
              5001410.1795024015
            ],
            [
              361502.63940108829,
              5001410.5741501981
            ],
            [
              361503.15623098583,
              5001411.1708543217
            ],
            [
              361503.98950092215,
              5001412.4690489816
            ],
            [
              361506.60361428704,
              5001417.0687726485
            ],
            [
              361508.18219202728,
              5001420.374130955
            ],
            [
              361509.43869887752,
              5001422.6749497661
            ],
            [
              361509.96866701916,
              5001423.9787443876
            ],
            [
              361510.28322990268,
              5001424.5792067405
            ],
            [
              361510.89552933845,
              5001424.8709833631
            ],
            [
              361511.40109752672,
              5001424.8615895528
            ],
            [
              361512.20996687381,
              5001424.846560196
            ],
            [
              361512.91961300711,
              5001424.9344375171
            ],
            [
              361513.63864368672,
              5001425.5273845689
            ],
            [
              361514.14984273206,
              5001425.8210397037
            ],
            [
              361514.55236710265,
              5001425.7124974802
            ],
            [
              361515.05230442405,
              5001425.4000547221
            ],
            [
              361515.94732499105,
              5001424.5750269406
            ],
            [
              361517.23917281459,
              5001423.3384092329
            ],
            [
              361518.93555468082,
              5001422.0942750089
            ],
            [
              361520.44280841132,
              5001421.5610251008
            ],
            [
              361521.55504514143,
              5001421.5403589765
            ],
            [
              361522.3695453236,
              5001421.8283785684
            ],
            [
              361523.19530722097,
              5001422.7224960336
            ],
            [
              361523.81323749188,
              5001423.3173215892
            ],
            [
              361524.2270898234,
              5001423.8148760144
            ],
            [
              361524.73828885204,
              5001424.1085311454
            ],
            [
              361525.44605778455,
              5001424.0953803044
            ],
            [
              361525.852335829,
              5001424.1888588639
            ],
            [
              361528.72101664922,
              5001426.1565686576
            ],
            [
              361531.37985681242,
              5001427.7239963589
            ],
            [
              361532.90594583488,
              5001428.2008846235
            ],
            [
              361534.42821490933,
              5001428.4757533427
            ],
            [
              361535.53857441992,
              5001428.3540590405
            ],
            [
              361536.94848137646,
              5001428.0247084191
            ],
            [
              361538.95942665142,
              5001427.3810720826
            ],
            [
              361544.84089404967,
              5001425.0223113401
            ],
            [
              361550.66770705191,
              5001422.8930333387
            ],
            [
              361554.68396527151,
              5001421.3026407985
            ],
            [
              361558.6964697701,
              5001419.5102275414
            ],
            [
              361561.10249747761,
              5001418.353970808
            ],
            [
              361563.10218026285,
              5001417.1042012302
            ],
            [
              361564.49707173061,
              5001415.9667321229
            ],
            [
              361566.29455376236,
              5001414.7207195964
            ],
            [
              361567.68193786533,
              5001413.1792089958
            ],
            [
              361568.76789736887,
              5001411.7443265542
            ],
            [
              361569.34641160449,
              5001410.2178097637
            ],
            [
              361569.81812837749,
              5001408.3901238199
            ],
            [
              361570.09146500868,
              5001406.7682499615
            ],
            [
              361570.36285822658,
              5001405.04534919
            ],
            [
              361570.2016957723,
              5001401.8147179689
            ],
            [
              361570.27814226405,
              5001395.0429281341
            ],
            [
              361570.24248010264,
              5001393.1236066027
            ],
            [
              361570.42597809684,
              5001392.1097090589
            ],
            [
              361570.80785625434,
              5001390.8899994474
            ],
            [
              361571.47493225435,
              5001388.3728912342
            ],
            [
              361571.83998419676,
              5001386.2440337073
            ],
            [
              361571.901668793,
              5001384.1208129292
            ],
            [
              361571.73112205468,
              5001380.385112064
            ],
            [
              361571.26659313362,
              5001377.1601531161
            ],
            [
              361570.20296933473,
              5001374.3505066717
            ],
            [
              361568.72549269418,
              5001371.0432702461
            ],
            [
              361566.43532695231,
              5001367.5490434868
            ],
            [
              361565.80807864416,
              5001366.4491470139
            ],
            [
              361565.56827315287,
              5001364.4325910732
            ],
            [
              361565.82096393028,
              5001361.6995497653
            ],
            [
              361566.0491881179,
              5001357.6532859365
            ],
            [
              361566.07896525547,
              5001353.8127998589
            ],
            [
              361566.36780262314,
              5001351.716107484
            ],
            [
              361567.15602581645,
              5001350.5899108415
            ],
            [
              361568.45544791227,
              5001349.7573694075
            ],
            [
              361569.4590761775,
              5001349.3345049676
            ],
            [
              361570.24729937245,
              5001348.208308341
            ],
            [
              361571.23403592041,
              5001346.8763327515
            ],
            [
              361572.11209790932,
              5001345.1421598513
            ],
            [
              361572.79734359257,
              5001343.9168135924
            ],
            [
              361573.78971093701,
              5001342.8878869191
            ],
            [
              361574.89249616262,
              5001342.3621170996
            ],
            [
              361575.90175586502,
              5001342.2423371216
            ],
            [
              361576.91657944029,
              5001342.4255717294
            ],
            [
              361580.1408608972,
              5001341.7593560796
            ],
            [
              361581.44966738898,
              5001341.4318843493
            ],
            [
              361582.148051786,
              5001340.9136640467
            ],
            [
              361582.34086809744,
              5001340.4048374305
            ],
            [
              361582.42138872511,
              5001339.2917902982
            ],
            [
              361582.90436742408,
              5001338.0702022957
            ],
            [
              361583.49602067436,
              5001337.2508116076
            ],
            [
              361584.29925907438,
              5001336.9327335712
            ],
            [
              361585.00702788844,
              5001336.9195829136
            ],
            [
              361585.81596331997,
              5001336.9045525379
            ],
            [
              361586.92813359282,
              5001336.8838879233
            ],
            [
              361588.13583969796,
              5001336.558294706
            ],
            [
              361589.64309259882,
              5001336.0250097448
            ],
            [
              361591.14102799172,
              5001334.9866894688
            ],
            [
              361592.02847440157,
              5001333.757586319
            ],
            [
              361592.71184360044,
              5001332.4312475501
            ],
            [
              361593.29223529319,
              5001331.005759134
            ],
            [
              361594.08421212429,
              5001330.0815833714
            ],
            [
              361594.97923271585,
              5001329.2565560294
            ],
            [
              361596.65302519593,
              5001326.8002282791
            ],
            [
              361597.62099229376,
              5001324.458078091
            ],
            [
              361597.97847110353,
              5001321.9251805516
            ],
            [
              361597.84170965286,
              5001320.0077731851
            ],
            [
              361597.49899320275,
              5001317.8920665141
            ],
            [
              361597.27044954611,
              5001316.4816084746
            ],
            [
              361597.76100246125,
              5001315.664096334
            ],
            [
              361598.75899992074,
              5001314.9381831596
            ],
            [
              361599.76631617016,
              5001314.7173763607
            ],
            [
              361601.28295409773,
              5001314.6891966565
            ],
            [
              361602.75329904893,
              5001314.7163011096
            ],
            [
              361603.66326854815,
              5001314.6993935322
            ],
            [
              361604.46650692244,
              5001314.3813155713
            ],
            [
              361605.26786881464,
              5001313.9622450117
            ],
            [
              361605.96437606454,
              5001313.3429966653
            ],
            [
              361607.16832850553,
              5001312.8153828168
            ],
            [
              361607.8742201337,
              5001312.7012040764
            ],
            [
              361608.78800949029,
              5001312.8863160079
            ],
            [
              361609.50140901637,
              5001313.1762142628
            ],
            [
              361610.1212160837,
              5001313.8720680028
            ],
            [
              361610.43953230354,
              5001314.6745510139
            ],
            [
              361610.76166905923,
              5001315.6790890554
            ],
            [
              361611.17920850002,
              5001316.3786654482
            ],
            [
              361611.79526128777,
              5001316.8724629106
            ],
            [
              361613.01610599406,
              5001317.253995643
            ],
            [
              361614.43727427017,
              5001317.530743272
            ],
            [
              361615.95954289666,
              5001317.8056124179
            ],
            [
              361617.06990218413,
              5001317.683918504
            ],
            [
              361618.17838498537,
              5001317.4612319954
            ],
            [
              361618.97974618152,
              5001317.0421259096
            ],
            [
              361619.67813054047,
              5001316.523905715
            ],
            [
              361620.16868342337,
              5001315.706393633
            ],
            [
              361620.75088536867,
              5001314.3818991845
            ],
            [
              361621.3444157507,
              5001313.6635367563
            ],
            [
              361621.94169975491,
              5001313.1471950533
            ],
            [
              361622.84422755387,
              5001312.7262092698
            ],
            [
              361623.84778945526,
              5001312.3033462353
            ],
            [
              361625.15659580735,
              5001311.9758746577
            ],
            [
              361627.07582481118,
              5001311.8391872179
            ],
            [
              361628.58495471935,
              5001311.4069305547
            ],
            [
              361629.89188457624,
              5001310.9784663925
            ],
            [
              361630.58839178196,
              5001310.3592180973
            ],
            [
              361630.8767439592,
              5001309.5454630125
            ],
            [
              361631.05642163818,
              5001308.3295106683
            ],
            [
              361631.24548369314,
              5001307.6186278872
            ],
            [
              361631.94199090247,
              5001306.9993796041
            ],
            [
              361632.94749617373,
              5001306.677543493
            ],
            [
              361633.75636524154,
              5001306.6625144184
            ],
            [
              361634.66633465857,
              5001306.645606867
            ],
            [
              361635.88530213415,
              5001306.9261114867
            ],
            [
              361636.80096855183,
              5001307.2122515412
            ],
            [
              361638.42058380489,
              5001307.2832215177
            ],
            [
              361640.04770628421,
              5001307.7582329204
            ],
            [
              361641.1674505297,
              5001308.1416441351
            ],
            [
              361641.97444243985,
              5001308.0255869338
            ],
            [
              361642.88072446972,
              5001307.8066574354
            ],
            [
              361643.89179421274,
              5001307.7878713999
            ],
            [
              361644.40111582418,
              5001307.9804984769
            ],
            [
              361644.81114797445,
              5001308.2760333885
            ],
            [
              361644.92169894808,
              5001308.7792232214
            ],
            [
              361645.14454553189,
              5001309.8866334697
            ],
            [
              361645.4609852311,
              5001310.5881238161
            ],
            [
              361645.97406112152,
              5001310.9828071352
            ],
            [
              361646.78293015837,
              5001310.9677780522
            ],
            [
              361648.29587985185,
              5001310.7375408914
            ],
            [
              361649.80501032871,
              5001310.3053197982
            ],
            [
              361650.90410799556,
              5001309.5775282634
            ],
            [
              361651.70171550842,
              5001308.9564015297
            ],
            [
              361652.6902622027,
              5001307.7254201854
            ],
            [
              361653.28754615958,
              5001307.2090785392
            ],
            [
              361653.791237664,
              5001307.0986923231
            ],
            [
              361654.29680564505,
              5001307.0892986907
            ],
            [
              361654.90722758695,
              5001307.2800472835
            ],
            [
              361656.2272290843,
              5001307.5586746335
            ],
            [
              361657.44056572852,
              5001307.536130406
            ],
            [
              361658.34684771817,
              5001307.3172009178
            ],
            [
              361659.34665590641,
              5001306.6923172446
            ],
            [
              361660.14050912944,
              5001305.8691342957
            ],
            [
              361660.62730836606,
              5001304.8496016208
            ],
            [
              361661.2862774012,
              5001302.2100397805
            ],
            [
              361662.87445250084,
              5001297.6640139399
            ],
            [
              361663.6589213821,
              5001296.3357615033
            ],
            [
              361664.75426542544,
              5001295.4059493449
            ],
            [
              361665.45828046656,
              5001295.1907780757
            ],
            [
              361666.16604911588,
              5001295.1776275095
            ],
            [
              361666.57427034678,
              5001295.3721330715
            ],
            [
              361666.78397890978,
              5001295.7724530483
            ],
            [
              361666.99744108232,
              5001296.3747937223
            ],
            [
              361667.51614701009,
              5001297.0724903177
            ],
            [
              361668.03297646175,
              5001297.6691943258
            ],
            [
              361669.04967687669,
              5001297.9534571348
            ],
            [
              361669.65634517738,
              5001297.9421850396
            ],
            [
              361670.16003666067,
              5001297.8317988431
            ],
            [
              361670.75731994159,
              5001297.3154217042
            ],
            [
              361671.35272674914,
              5001296.6980519881
            ],
            [
              361672.13719627453,
              5001295.3698351216
            ],
            [
              361672.32625899574,
              5001294.6589879701
            ],
            [
              361672.31499750953,
              5001294.052890351
            ],
            [
              361672.19888141606,
              5001293.2466149759
            ],
            [
              361672.38043626567,
              5001292.1316909008
            ],
            [
              361672.56581097754,
              5001291.2187862908
            ],
            [
              361673.06005186,
              5001290.6033318313
            ],
            [
              361673.45325799589,
              5001289.9897190817
            ],
            [
              361673.85021773662,
              5001289.5781270266
            ],
            [
              361674.34258148784,
              5001288.861644458
            ],
            [
              361674.63656375551,
              5001288.3509027651
            ],
            [
              361674.62342580891,
              5001287.6438125717
            ],
            [
              361675.014688564,
              5001286.9291729443
            ],
            [
              361676.02019372868,
              5001286.6073369365
            ],
            [
              361677.13242996542,
              5001286.5866712583
            ],
            [
              361678.04427640158,
              5001286.6707918746
            ],
            [
              361678.85877609992,
              5001286.9588116566
            ],
            [
              361679.37560551701,
              5001287.5555156609
            ],
            [
              361679.6976752156,
              5001288.5600192258
            ],
            [
              361679.71081382403,
              5001289.2671449436
            ],
            [
              361679.62472856947,
              5001290.0771417171
            ],
            [
              361679.4412965831,
              5001291.0910376543
            ],
            [
              361678.95074438624,
              5001291.9085850967
            ],
            [
              361678.86465913634,
              5001292.7185818702
            ],
            [
              361678.97326669021,
              5001293.120744775
            ],
            [
              361679.79152065882,
              5001293.6108207665
            ],
            [
              361681.82311052672,
              5001294.0783170434
            ],
            [
              361683.85094677453,
              5001294.3437926257
            ],
            [
              361684.75722870353,
              5001294.1248631878
            ],
            [
              361685.05865258042,
              5001294.0181996487
            ],
            [
              361685.15036856668,
              5001293.511251688
            ],
            [
              361685.13722994941,
              5001292.8041259786
            ],
            [
              361685.21768445778,
              5001291.6910804231
            ],
            [
              361685.40118269017,
              5001290.6771832695
            ],
            [
              361685.88603789121,
              5001289.5565882782
            ],
            [
              361686.77542760503,
              5001288.4285124671
            ],
            [
              361687.96811832738,
              5001287.2948012352
            ],
            [
              361688.6552411202,
              5001286.1704836134
            ],
            [
              361690.2091644507,
              5001282.705896263
            ],
            [
              361691.08347286179,
              5001280.7697034413
            ],
            [
              361692.86030894006,
              5001278.4125250205
            ],
            [
              361695.93281256402,
              5001275.0207495699
            ],
            [
              361698.72259536787,
              5001272.745813394
            ],
            [
              361702.11904640804,
              5001270.4596052086
            ],
            [
              361705.32268107968,
              5001268.6822234625
            ],
            [
              361707.32987141894,
              5001267.8365321634
            ],
            [
              361709.44191567029,
              5001267.1909830803
            ],
            [
              361710.95667599182,
              5001267.0617754925
            ],
            [
              361713.17927111295,
              5001266.9194161696
            ],
            [
              361715.39804637601,
              5001266.5750374058
            ],
            [
              361716.60762922256,
              5001266.3504726617
            ],
            [
              361717.40899091272,
              5001265.9314023517
            ],
            [
              361718.00439769664,
              5001265.3140327744
            ],
            [
              361719.09411093505,
              5001264.0811720937
            ],
            [
              361720.77541111887,
              5001262.0289224209
            ],
            [
              361721.86700148555,
              5001260.8970898595
            ],
            [
              361723.26752354152,
              5001260.0626706742
            ],
            [
              361724.07076168741,
              5001259.7445929516
            ],
            [
              361724.87963054259,
              5001259.7295639934
            ],
            [
              361725.48629874719,
              5001259.7182919681
            ],
            [
              361726.30086456152,
              5001260.0063105412
            ],
            [
              361727.41866509808,
              5001260.2886949489
            ],
            [
              361729.11691257166,
              5001260.3113873964
            ],
            [
              361730.33024896128,
              5001260.288843343
            ],
            [
              361731.83937848307,
              5001259.8565870374
            ],
            [
              361734.5562143228,
              5001259.0987734618
            ],
            [
              361736.46793424297,
              5001258.5579739772
            ],
            [
              361737.87033336924,
              5001257.8245829353
            ],
            [
              361738.96192369593,
              5001256.6927504465
            ],
            [
              361741.32853575068,
              5001253.4151542149
            ],
            [
              361742.01565854781,
              5001252.2908368362
            ],
            [
              361742.40504483791,
              5001251.4752048301
            ],
            [
              361742.88621275773,
              5001250.1525881952
            ],
            [
              361743.37864274526,
              5001249.4361045947
            ],
            [
              361744.95884257782,
              5001247.3857335988
            ],
            [
              361746.64583968651,
              5001245.6365316743
            ],
            [
              361747.84416038002,
              5001244.8058339991
            ],
            [
              361748.75225304038,
              5001244.6879340596
            ],
            [
              361749.56299829739,
              5001244.7738976954
            ],
            [
              361750.87931205495,
              5001244.8505033292
            ],
            [
              361752.39782644092,
              5001244.9233520404
            ],
            [
              361754.62229842291,
              5001244.8820209689
            ],
            [
              361756.84107344062,
              5001244.5376423895
            ],
            [
              361758.35395644308,
              5001244.3074068325
            ],
            [
              361760.77312186133,
              5001243.8582775574
            ],
            [
              361762.18865877012,
              5001243.83197664
            ],
            [
              361763.30464830849,
              5001244.0133317504
            ],
            [
              361764.12290134723,
              5001244.5033722036
            ],
            [
              361764.74458511529,
              5001245.3002538411
            ],
            [
              361765.27642930736,
              5001246.7050758582
            ],
            [
              361765.81196083402,
              5001248.3119197441
            ],
            [
              361766.25771970878,
              5001250.5267383093
            ],
            [
              361766.69409431017,
              5001252.2364875022
            ],
            [
              361766.91131058795,
              5001253.0408842051
            ],
            [
              361767.32697258878,
              5001253.6394322691
            ],
            [
              361767.73519433656,
              5001253.8339733565
            ],
            [
              361768.95416129677,
              5001254.1144780535
            ],
            [
              361770.07015081757,
              5001254.2958331276
            ],
            [
              361772.09610939311,
              5001254.4602807919
            ],
            [
              361773.31313362467,
              5001254.6397941653
            ],
            [
              361774.12769866217,
              5001254.9277771767
            ],
            [
              361775.25112974411,
              5001255.5132102808
            ],
            [
              361776.37462707562,
              5001256.0986421471
            ],
            [
              361777.79579462769,
              5001256.3753899019
            ],
            [
              361779.41540920548,
              5001256.4463600898
            ],
            [
              361781.5387807021,
              5001256.4069074132
            ],
            [
              361782.54797291738,
              5001256.287093455
            ],
            [
              361783.75380195142,
              5001255.8605081616
            ],
            [
              361784.95963031967,
              5001255.4338873327
            ],
            [
              361786.472513852,
              5001255.2036872795
            ],
            [
              361788.79801966628,
              5001255.1604789151
            ],
            [
              361791.84074439952,
              5001255.6091881655
            ],
            [
              361794.3462063715,
              5001256.1734574167
            ],
            [
              361796.58562682668,
              5001256.9402455324
            ],
            [
              361799.13404513948,
              5001258.0044457093
            ],
            [
              361801.17877279554,
              5001259.1790675074
            ],
            [
              361803.22537690523,
              5001260.4546818333
            ],
            [
              361804.45185157977,
              5001261.1392632499
            ],
            [
              361805.77379518002,
              5001261.5188819636
            ],
            [
              361806.78486444638,
              5001261.5000960752
            ],
            [
              361807.79412349669,
              5001261.3803164149
            ],
            [
              361809.09917495144,
              5001260.850789058
            ],
            [
              361810.99775723217,
              5001259.6029353039
            ],
            [
              361816.20288306032,
              5001256.6767802704
            ],
            [
              361819.71176100877,
              5001254.9947906956
            ],
            [
              361822.71500494285,
              5001253.3221959332
            ],
            [
              361825.02743823646,
              5001252.5718609309
            ],
            [
              361827.44653692201,
              5001252.1227329159
            ],
            [
              361829.56233404711,
              5001251.6792047601
            ],
            [
              361831.68007453275,
              5001251.3367034504
            ],
            [
              361832.4833124337,
              5001251.0186258489
            ],
            [
              361833.28091963218,
              5001250.3974995781
            ],
            [
              361834.8667497469,
              5001248.6501777824
            ],
            [
              361836.46390751278,
              5001247.5089521045
            ],
            [
              361837.96552942722,
              5001246.6726547927
            ],
            [
              361839.07213444536,
              5001246.3489405932
            ],
            [
              361840.38094004284,
              5001246.021469472
            ],
            [
              361841.58489176776,
              5001245.4938562233
            ],
            [
              361843.08463654603,
              5001244.5565308677
            ],
            [
              361844.28295756923,
              5001243.7258689674
            ],
            [
              361845.73212116241,
              5001242.381179193
            ],
            [
              361848.1174355612,
              5001240.1137593435
            ],
            [
              361850.00475566246,
              5001238.259773002
            ],
            [
              361851.40715436841,
              5001237.5263822861
            ],
            [
              361854.12774222629,
              5001236.9705542084
            ],
            [
              361856.44956026546,
              5001236.7253241576
            ],
            [
              361858.87623214733,
              5001236.6802362371
            ],
            [
              361860.59882308211,
              5001236.8503205711
            ],
            [
              361862.93565602729,
              5001237.4132083924
            ],
            [
              361865.16756884794,
              5001237.7759553138
            ],
            [
              361867.50058193802,
              5001238.1368237734
            ],
            [
              361869.82802991563,
              5001238.1946068257
            ],
            [
              361872.45690226619,
              5001238.1457619807
            ],
            [
              361875.58564531413,
              5001237.7844762094
            ],
            [
              361878.62273922784,
              5001237.9301724136
            ],
            [
              361880.74604386161,
              5001237.8907210939
            ],
            [
              361882.8787327446,
              5001238.3563389704
            ],
            [
              361886.02818759013,
              5001239.1062184162
            ],
            [
              361888.67958257929,
              5001240.2695680456
            ],
            [
              361890.72243252274,
              5001241.3431615913
            ],
            [
              361891.75039361691,
              5001242.2335216207
            ],
            [
              361892.6791971426,
              5001243.2267513862
            ],
            [
              361893.60793507862,
              5001244.2200179147
            ],
            [
              361894.73330846103,
              5001244.9064421318
            ],
            [
              361896.15635263192,
              5001245.2842178885
            ],
            [
              361897.98574060568,
              5001245.7554710815
            ],
            [
              361899.7739910215,
              5001246.2348825941
            ],
            [
              361902.02091858356,
              5001247.4057471985
            ],
            [
              361903.44583918661,
              5001247.8845154466
            ],
            [
              361904.97004984732,
              5001248.2604114898
            ],
            [
              361907.20008528803,
              5001248.52213025
            ],
            [
              361909.23167374945,
              5001248.9896264877
            ],
            [
              361911.56093231292,
              5001249.1484387349
            ],
            [
              361913.17498181394,
              5001248.9163591051
            ],
            [
              361914.47815642942,
              5001248.2858394496
            ],
            [
              361915.57531067368,
              5001247.4570574006
            ],
            [
              361918.36891171016,
              5001245.3841422265
            ],
            [
              361921.66425983922,
              5001243.0997787034
            ],
            [
              361924.06271220796,
              5001241.5394845726
            ],
            [
              361926.27216812311,
              5001240.6900358265
            ],
            [
              361928.28874150704,
              5001240.3494143682
            ],
            [
              361930.91754715837,
              5001240.300570746
            ],
            [
              361934.15871756827,
              5001240.5435024928
            ],
            [
              361936.58914247341,
              5001240.7004350955
            ],
            [
              361940.83762812911,
              5001240.7225604746
            ],
            [
              361946.29194155481,
              5001240.3180652838
            ],
            [
              361952.15259863675,
              5001240.0070475349
            ],
            [
              361958.62555476179,
              5001239.9878419098
            ],
            [
              361962.57637006772,
              5001240.3186512273
            ],
            [
              361964.9093818391,
              5001240.679484033
            ],
            [
              361966.63579272927,
              5001241.0516231228
            ],
            [
              361968.84346243594,
              5001241.8253284106
            ],
            [
              361976.06353418849,
              5001243.914277805
            ],
            [
              361982.77247385983,
              5001245.7095708763
            ],
            [
              361985.92192846671,
              5001246.4594855756
            ],
            [
              361990.18542781816,
              5001247.2896929169
            ],
            [
              361994.53883288562,
              5001247.5119590266
            ],
            [
              361999.08880769223,
              5001247.4274196904
            ],
            [
              362002.82421729265,
              5001247.0548619786
            ],
            [
              362005.24338098615,
              5001246.6057330081
            ],
            [
              362007.45464657358,
              5001245.8572781449
            ],
            [
              362009.46183520771,
              5001245.0115877306
            ],
            [
              362015.07900009159,
              5001242.4820303796
            ],
            [
              362019.79363739601,
              5001240.3734226786
            ],
            [
              362022.1041260254,
              5001239.5220614169
            ],
            [
              362025.92568692722,
              5001238.3395066466
            ],
            [
              362028.84284385014,
              5001237.4769089725
            ],
            [
              362034.5450714656,
              5001235.8265790725
            ],
            [
              362040.68838278583,
              5001234.3987957155
            ],
            [
              362045.42366540141,
              5001233.4013542049
            ],
            [
              362048.45137290232,
              5001233.0419458915
            ],
            [
              362058.7420672953,
              5001231.6381310495
            ],
            [
              362070.94829997123,
              5001229.8955722675
            ],
            [
              362080.22973610513,
              5001228.6115726149
            ],
            [
              362086.98916469916,
              5001227.6775852926
            ],
            [
              362090.82004290813,
              5001227.000101001
            ],
            [
              362093.33655200427,
              5001226.347037849
            ],
            [
              362095.34374018467,
              5001225.5013477616
            ],
            [
              362097.71121867228,
              5001224.2965409169
            ],
            [
              362101.92035388428,
              5001222.1973262364
            ],
            [
              362105.02094217221,
              5001220.3208342483
            ],
            [
              362106.71919833153,
              5001219.1776956161
            ],
            [
              362107.91001138266,
              5001217.9429935627
            ],
            [
              362108.79933399236,
              5001216.8149205549
            ],
            [
              362109.38535530574,
              5001215.6924473681
            ],
            [
              362110.6566223664,
              5001213.3446656391
            ],
            [
              362111.34562166716,
              5001212.3213775372
            ],
            [
              362113.13184026635,
              5001210.4692712193
            ],
            [
              362116.21741298819,
              5001207.7846265873
            ],
            [
              362118.41748354613,
              5001206.4301096871
            ],
            [
              362119.82363477832,
              5001205.8987401109
            ],
            [
              362121.64169394306,
              5001205.7638976108
            ],
            [
              362122.95988271089,
              5001205.9414957613
            ],
            [
              362124.28182568564,
              5001206.3211498782
            ],
            [
              362126.1093359454,
              5001206.6914105238
            ],
            [
              362127.93309198506,
              5001206.8596152002
            ],
            [
              362130.15749528958,
              5001206.8182856617
            ],
            [
              362131.77154379431,
              5001206.5862063803
            ],
            [
              362133.18144852197,
              5001206.2568572462
            ],
            [
              362134.38533237128,
              5001205.7292102817
            ],
            [
              362135.68850689003,
              5001205.0987267336
            ],
            [
              362139.90327240899,
              5001203.3025609236
            ],
            [
              362146.03156647366,
              5001201.0666258503
            ],
            [
              362150.31693395792,
              5001199.3178846082
            ],
            [
              362151.82049731654,
              5001198.5825794814
            ],
            [
              362152.62561162264,
              5001198.3655302776
            ],
            [
              362153.52994902415,
              5001198.0455746548
            ],
            [
              362154.02988562436,
              5001197.7331328113
            ],
            [
              362154.22645537561,
              5001197.4263275359
            ],
            [
              362154.21707118227,
              5001196.9212587355
            ],
            [
              362153.6984317876,
              5001196.2235262124
            ],
            [
              362153.27894989908,
              5001195.4229240324
            ],
            [
              362152.95118336281,
              5001194.1153390696
            ],
            [
              362153.03170433827,
              5001193.0022938196
            ],
            [
              362153.42859805201,
              5001192.5907392185
            ],
            [
              362154.33487814869,
              5001192.3717748178
            ],
            [
              362155.34970059636,
              5001192.5550451009
            ],
            [
              362156.26717572357,
              5001192.9421787038
            ],
            [
              362157.08549466491,
              5001193.4322530264
            ],
            [
              362159.03656060429,
              5001195.0127944844
            ],
            [
              362159.54775849212,
              5001195.3064494161
            ],
            [
              362160.0533257323,
              5001195.2970559672
            ],
            [
              362160.75733982463,
              5001195.0818852168
            ],
            [
              362162.66530393396,
              5001194.3390663592
            ],
            [
              362165.47572896822,
              5001193.1752994722
            ],
            [
              362168.68874391628,
              5001191.9029896352
            ],
            [
              362172.90907349001,
              5001190.4098737594
            ],
            [
              362176.03037302481,
              5001189.6445467183
            ],
            [
              362179.35387225862,
              5001188.8754272731
            ],
            [
              362182.88520248333,
              5001188.4055993175
            ],
            [
              362185.91478592128,
              5001188.1472195135
            ],
            [
              362190.15576075448,
              5001187.7652692171
            ],
            [
              362194.29938954744,
              5001187.5872532977
            ],
            [
              362197.2241184992,
              5001187.1286960253
            ],
            [
              362198.8324698123,
              5001186.5935697658
            ],
            [
              362200.23486654874,
              5001185.8601444736
            ],
            [
              362202.13344628684,
              5001184.6122570224
            ],
            [
              362204.02451894659,
              5001182.9603288183
            ],
            [
              362205.70957078814,
              5001181.1101028258
            ],
            [
              362207.69423590048,
              5001179.0522199199
            ],
            [
              362209.0741770108,
              5001177.1066356273
            ],
            [
              362211.04195024696,
              5001174.1396077545
            ],
            [
              362213.01347768801,
              5001171.3746358315
            ],
            [
              362215.00001925131,
              5001169.4177454459
            ],
            [
              362217.49393947277,
              5001167.5525263986
            ],
            [
              362219.57534999406,
              5001166.5639059553
            ],
            [
              362221.48331450071,
              5001165.8211229146
            ],
            [
              362223.09354216658,
              5001165.3869892126
            ],
            [
              362224.6083002644,
              5001165.2577822721
            ],
            [
              362226.02577861521,
              5001165.3325084466
            ],
            [
              362227.74649124255,
              5001165.4016005453
            ],
            [
              362229.36798011611,
              5001165.5735634938
            ],
            [
              362231.59807964106,
              5001165.8352813236
            ],
            [
              362233.2214455895,
              5001166.1082722172
            ],
            [
              362234.53400329006,
              5001165.9828221519
            ],
            [
              362235.33911742357,
              5001165.7657730747
            ],
            [
              362236.4438441486,
              5001165.3410315644
            ],
            [
              362237.54481800413,
              5001164.7143052267
            ],
            [
              362240.44320437731,
              5001162.8415358337
            ],
            [
              362243.54385733552,
              5001160.9650083762
            ],
            [
              362246.45162847341,
              5001159.5973432446
            ],
            [
              362249.2676835538,
              5001158.7366252337
            ],
            [
              362252.38335119776,
              5001157.6682148166
            ],
            [
              362255.20128332387,
              5001156.9085247787
            ],
            [
              362257.21973129315,
              5001156.6688965531
            ],
            [
              362258.33571983926,
              5001156.8502872279
            ],
            [
              362259.45170772023,
              5001157.0316423625
            ],
            [
              362261.07319644029,
              5001157.2036053361
            ],
            [
              362265.53145170928,
              5001157.6260143416
            ],
            [
              362269.17701956962,
              5001157.8614329081
            ],
            [
              362272.20472518029,
              5001157.5020254981
            ],
            [
              362274.6256972765,
              5001157.1538911508
            ],
            [
              362277.34440614906,
              5001156.4971075403
            ],
            [
              362279.15502305381,
              5001155.9581879191
            ],
            [
              362282.06091682171,
              5001154.4894950371
            ],
            [
              362286.14991048415,
              5001152.9761407152
            ],
            [
              362290.07062557322,
              5001151.6906826878
            ],
            [
              362293.0927004026,
              5001151.0282270908
            ],
            [
              362295.00629513949,
              5001150.5884925649
            ],
            [
              362298.03956488281,
              5001150.5321347397
            ],
            [
              362300.56733381725,
              5001150.4851690652
            ],
            [
              362304.21296755382,
              5001150.7205864573
            ],
            [
              362306.94856735569,
              5001150.9729121737
            ],
            [
              362309.682290047,
              5001151.1242099591
            ],
            [
              362316.46417050966,
              5001151.4024190065
            ],
            [
              362325.07355932036,
              5001152.0508532301
            ],
            [
              362334.86272889056,
              5001153.7173222369
            ],
            [
              362338.72738812468,
              5001154.8581286138
            ],
            [
              362340.66350436973,
              5001155.6305514816
            ],
            [
              362342.1989742085,
              5001156.6125439033
            ],
            [
              362343.94408511842,
              5001157.9948212896
            ],
            [
              362346.19851651334,
              5001159.5697255675
            ],
            [
              362349.47534305375,
              5001161.7319394648
            ],
            [
              362353.68465955823,
              5001165.0894390326
            ],
            [
              362357.38096829393,
              5001168.0522901081
            ],
            [
              362358.51009419037,
              5001168.9407694833
            ],
            [
              362360.14096659451,
              5001169.6178008514
            ],
            [
              362363.60122504359,
              5001170.7661207076
            ],
            [
              362368.99384605809,
              5001172.4848429635
            ],
            [
              362376.27116685756,
              5001175.0016489709
            ],
            [
              362382.16747751267,
              5001176.6099497732
            ],
            [
              362387.85213740374,
              5001177.7169401711
            ],
            [
              362391.29362662521,
              5001177.8550874181
            ],
            [
              362395.44107278163,
              5001177.8790907711
            ],
            [
              362398.87880839541,
              5001177.8152177418
            ],
            [
              362402.42710975988,
              5001173.423760172
            ],
            [
              362405.54791746225,
              5001166.8689584611
            ],
            [
              362415.77134524973,
              5001154.3055043705
            ],
            [
              362417.51273568807,
              5001144.409293402
            ],
            [
              362436.56587624951,
              5001128.6227085255
            ],
            [
              362447.33069442585,
              5001115.9025590066
            ],
            [
              362458.29410033714,
              5001104.3336368818
            ],
            [
              362464.01691202744,
              5001100.0931630386
            ],
            [
              362461.57156721299,
              5001093.5839858018
            ],
            [
              362343.28693819884,
              5000847.5335881561
            ],
            [
              360799.34830326465,
              4997634.6462095138
            ],
            [
              360702.96660294366,
              4997678.8719282569
            ],
            [
              360004.70536776114,
              4998083.997017689
            ],
            [
              359996.47984614968,
              4998206.6993100755
            ],
            [
              359797.80446027842,
              4998443.9835395887
            ],
            [
              359624.14007795526,
              4998939.6559926793
            ],
            [
              359604.47071552766,
              4998995.7963809744
            ],
            [
              359297.36911961227,
              4999670.2721029241
            ],
            [
              358322.60269338748,
              5001669.6897596959
            ],
            [
              358145.42189423076,
              5002067.6437528683
            ],
            [
              357730.25647653925,
              5002794.6542384243
            ],
            [
              357361.27737259137,
              5003476.7898972034
            ],
            [
              357100.12602588767,
              5003958.8408697424
            ],
            [
              356816.13000378886,
              5004557.3652667571
            ],
            [
              356431.99717292044,
              5005016.1936390903
            ],
            [
              355803.18460279744,
              5005659.1470935978
            ],
            [
              353715.43824233609,
              5007708.0198561633
            ],
            [
              352051.54721946415,
              5009340.9469781034
            ],
            [
              351866.24584761198,
              5009570.9913534112
            ],
            [
              351598.19351728441,
              5009870.104552011
            ],
            [
              351268.24767976528,
              5010176.3724055151
            ],
            [
              350808.24481159012,
              5010697.6525614141
            ],
            [
              350821.77419495507,
              5010806.4478185177
            ],
            [
              351002.88106197881,
              5010857.1991356472
            ],
            [
              351320.58903414971,
              5010946.229915875
            ],
            [
              351373.39362753701,
              5010988.2657910753
            ],
            [
              351387.734656966,
              5011060.0298814559
            ],
            [
              351358.96043834434,
              5011126.5937197097
            ],
            [
              351211.41430498549,
              5011369.4427283658
            ],
            [
              351140.25068794342,
              5011469.8097044891
            ],
            [
              351068.7334600109,
              5011551.1751158005
            ],
            [
              351082.53617436276,
              5011809.0294096014
            ],
            [
              351107.20352496614,
              5012005.4849438993
            ],
            [
              351120.12278579845,
              5012108.459117746
            ],
            [
              351267.19099890551,
              5012108.7231915081
            ],
            [
              351514.81632163405,
              5012092.5953823961
            ],
            [
              351680.98585514969,
              5012080.8841624884
            ],
            [
              351695.26531067438,
              5012080.5402854821
            ],
            [
              351694.58429476887,
              5012080.8871226041
            ],
            [
              351697.47495675395,
              5012079.69079779
            ],
            [
              351698.88445773505,
              5012079.3614286166
            ],
            [
              351700.80595499254,
              5012079.3256660914
            ],
            [
              351702.3226797409,
              5012079.2974371128
            ],
            [
              351703.94772683369,
              5012079.6713731438
            ],
            [
              351705.17066282261,
              5012080.1538384613
            ],
            [
              351706.50380894379,
              5012081.139898194
            ],
            [
              351707.33137235249,
              5012082.1349484511
            ],
            [
              351707.95921091666,
              5012083.2347612176
            ],
            [
              351708.38388182299,
              5012084.3383552991
            ],
            [
              351708.79053429782,
              5012084.4318320286
            ],
            [
              351710.4043014567,
              5012084.1997063514
            ],
            [
              351712.9171360879,
              5012083.3445757814
            ],
            [
              351716.02772950468,
              5012081.9730935125
            ],
            [
              351718.83058506629,
              5012080.4048290141
            ],
            [
              351722.3263696733,
              5012078.0157250259
            ],
            [
              351726.11217523273,
              5012074.9134871457
            ],
            [
              351728.83086785924,
              5012072.2671561623
            ],
            [
              351731.99823482503,
              5012068.5701570576
            ],
            [
              351733.96600710187,
              5012065.6028014319
            ],
            [
              351736.52214610175,
              5012061.6144619575
            ],
            [
              351739.14712013514,
              5012055.9066526825
            ],
            [
              351741.17244766414,
              5012050.6137657175
            ],
            [
              351742.99163689034,
              5012045.1230441518
            ],
            [
              351744.02353161277,
              5012040.7588926591
            ],
            [
              351745.11343412061,
              5012034.0935192723
            ],
            [
              351745.8338312594,
              5012029.330565013
            ],
            [
              351746.16527716536,
              5012025.3832118716
            ],
            [
              351746.08067087276,
              5012020.8373305928
            ],
            [
              351745.68911608233,
              5012016.0950716008
            ],
            [
              351745.08389535389,
              5012010.7505177073
            ],
            [
              351744.59263684246,
              5012006.1111595975
            ],
            [
              351743.72096495132,
              5011992.4141574288
            ],
            [
              351738.04379160731,
              5011991.7110370472
            ],
            [
              351738.20105886989,
              5011989.2830237858
            ],
            [
              351743.6683183526,
              5011989.5854507098
            ],
            [
              351744.12689430331,
              5011987.0507844165
            ],
            [
              351744.88677114563,
              5011984.4090459216
            ],
            [
              351745.65041571722,
              5011981.9697471093
            ],
            [
              351746.51939623023,
              5011979.7301590908
            ],
            [
              351747.87891198386,
              5011976.6730794171
            ],
            [
              351741.34896272683,
              5011973.6622085106
            ],
            [
              351747.72873351746,
              5011961.8419273775
            ],
            [
              351773.79590241605,
              5011914.8193618795
            ],
            [
              351780.03504788183,
              5011918.5433818679
            ],
            [
              351784.64206315222,
              5011910.6767358389
            ],
            [
              351788.14917976019,
              5011903.436412761
            ],
            [
              351794.37942170346,
              5011890.3858300708
            ],
            [
              351752.26303263166,
              5011851.9548851214
            ],
            [
              351745.20263264334,
              5011858.4529770724
            ],
            [
              351742.72667507798,
              5011855.8714614855
            ],
            [
              351749.28604935721,
              5011849.5852039903
            ],
            [
              351741.1060038436,
              5011842.0823220955
            ],
            [
              351736.67925210693,
              5011837.9203886176
            ],
            [
              351733.38137173263,
              5011834.6468528686
            ],
            [
              351728.09575863153,
              5011838.6864084536
            ],
            [
              351706.22681617405,
              5011815.8513243431
            ],
            [
              351701.48369324021,
              5011819.3449502075
            ],
            [
              351695.92431001685,
              5011814.0925973924
            ],
            [
              351697.41659604566,
              5011812.7512358669
            ],
            [
              351702.14465675288,
              5011816.8065172015
            ],
            [
              351709.83478380955,
              5011811.5096679563
            ],
            [
              351695.50875897397,
              5011797.2245127717
            ],
            [
              351687.72534856194,
              5011802.9272791101
            ],
            [
              351691.13343101501,
              5011806.7039910005
            ],
            [
              351689.54034251213,
              5011808.0472285589
            ],
            [
              351684.48459304153,
              5011808.1413211785
            ],
            [
              351680.84664584557,
              5011808.3100723419
            ],
            [
              351678.21906712017,
              5011808.4600195279
            ],
            [
              351675.38424380712,
              5011808.3106879042
            ],
            [
              351672.65100431355,
              5011808.159465705
            ],
            [
              351668.60076483217,
              5011807.9317089235
            ],
            [
              351665.36304879648,
              5011807.8909208197
            ],
            [
              351663.23430673213,
              5011807.6274030199
            ],
            [
              351659.4816161397,
              5011807.0909726862
            ],
            [
              351656.03399412317,
              5011806.6499099936
            ],
            [
              351652.5890333908,
              5011806.3098430485
            ],
            [
              351649.95957454428,
              5011806.3587799296
            ],
            [
              351647.42605201592,
              5011806.1023762021
            ],
            [
              351644.48666620749,
              5011805.752899983
            ],
            [
              351642.25446026103,
              5011805.3902624296
            ],
            [
              351639.71154596203,
              5011804.6292263083
            ],
            [
              351635.81447143358,
              5011803.4330284288
            ],
            [
              351632.96648845484,
              5011802.5766246188
            ],
            [
              351630.52061672899,
              5011801.6116918111
            ],
            [
              351628.37495533505,
              5011800.4390811911
            ],
            [
              351626.53624247393,
              5011799.4628485255
            ],
            [
              351623.47270601959,
              5011797.9027199848
            ],
            [
              351621.93342208094,
              5011796.7188240374
            ],
            [
              351620.0796699911,
              5011794.9345088666
            ],
            [
              351617.70639433939,
              5011792.4521261677
            ],
            [
              351616.55872370576,
              5011790.5536248023
            ],
            [
              351615.71047422918,
              5011788.4470410366
            ],
            [
              351615.15790172177,
              5011785.9311925219
            ],
            [
              351615.11465627968,
              5011783.607536261
            ],
            [
              351615.36896771408,
              5011780.9756255718
            ],
            [
              351615.73049491394,
              5011778.6444361182
            ],
            [
              351616.6005833557,
              5011776.5062917387
            ],
            [
              351617.87732431135,
              5011774.4616244985
            ],
            [
              351619.1623584624,
              5011772.8205648288
            ],
            [
              351620.75544742722,
              5011771.4773271559
            ],
            [
              351623.36532615527,
              5011770.4183020014
            ],
            [
              351625.58061315044,
              5011769.8718470372
            ],
            [
              351627.80153980968,
              5011769.628422996
            ],
            [
              351629.6195737563,
              5011769.4935424561
            ],
            [
              351631.03925619693,
              5011769.6692114668
            ],
            [
              351632.66430329747,
              5011770.0431490429
            ],
            [
              351634.59065922908,
              5011770.3104336914
            ],
            [
              351636.52343624522,
              5011770.8807347026
            ],
            [
              351638.25570685352,
              5011771.5558126085
            ],
            [
              351639.68024752516,
              5011772.0345270596
            ],
            [
              351640.79674119735,
              5011772.2158386745
            ],
            [
              351679.0539702996,
              5011775.9544411413
            ],
            [
              351678.72257539263,
              5011774.4445102587
            ],
            [
              351678.49199071858,
              5011772.9331224784
            ],
            [
              351678.46943199815,
              5011771.7209989801
            ],
            [
              351678.64361987112,
              5011770.2020780174
            ],
            [
              351678.87834093423,
              5011766.3915310847
            ],
            [
              351678.68165627267,
              5011761.2414631071
            ],
            [
              351678.24420624052,
              5011754.0749704158
            ],
            [
              351677.98243718472,
              5011750.8459739853
            ],
            [
              351677.65214865986,
              5011749.4374870416
            ],
            [
              351677.21995924058,
              5011747.9298512759
            ],
            [
              351676.43303501274,
              5011743.7001756215
            ],
            [
              351675.62731199729,
              5011738.4603970181
            ],
            [
              351674.81861071137,
              5011733.0185832623
            ],
            [
              351674.05259245669,
              5011730.1222322993
            ],
            [
              351673.2118708145,
              5011728.4201090401
            ],
            [
              351672.2752049769,
              5011727.0229072077
            ],
            [
              351671.1444460036,
              5011726.033079437
            ],
            [
              351669.71724423161,
              5011725.4533690875
            ],
            [
              351667.78336901049,
              5011724.7820430314
            ],
            [
              351666.1537809525,
              5011724.2060992336
            ],
            [
              351664.32258802454,
              5011723.6339074513
            ],
            [
              351662.48951520812,
              5011722.9607053669
            ],
            [
              351660.85506890458,
              5011722.0817161174
            ],
            [
              351659.01070900221,
              5011720.8020331021
            ],
            [
              351657.57034804131,
              5011719.5152506232
            ],
            [
              351656.52912000846,
              5011717.9179041144
            ],
            [
              351655.58680673625,
              5011716.2172521586
            ],
            [
              351654.94471164607,
              5011714.3093416169
            ],
            [
              351654.60016565496,
              5011712.0927576087
            ],
            [
              351654.46285636973,
              5011710.1750333477
            ],
            [
              351654.73486862454,
              5011708.4522011587
            ],
            [
              351655.31304793677,
              5011706.9257615525
            ],
            [
              351656.09736548475,
              5011705.5971568683
            ],
            [
              351657.28082377644,
              5011703.9584073154
            ],
            [
              351659.07065949735,
              5011702.3083726643
            ],
            [
              351662.46751699183,
              5011700.0217392556
            ],
            [
              351665.36366548802,
              5011698.0479795551
            ],
            [
              351667.77005937544,
              5011696.8916968079
            ],
            [
              351670.87798567105,
              5011695.4188029571
            ],
            [
              351673.58568835858,
              5011694.1558674015
            ],
            [
              351675.39244287362,
              5011693.4149255166
            ],
            [
              351676.78956664633,
              5011692.3784713121
            ],
            [
              351678.18214926339,
              5011691.1400110712
            ],
            [
              351679.67254818114,
              5011689.6972205834
            ],
            [
              351681.06027260394,
              5011688.1557149487
            ],
            [
              351682.44193395152,
              5011686.1204162063
            ],
            [
              351685.10451976728,
              5011682.4332338003
            ],
            [
              351686.57612002804,
              5011679.9803404827
            ],
            [
              351687.06477618578,
              5011679.0618388029
            ],
            [
              351687.24836379156,
              5011678.0479693683
            ],
            [
              351687.13174102793,
              5011677.2417775895
            ],
            [
              351682.9863389667,
              5011677.3189261472
            ],
            [
              351683.02321818384,
              5011673.8822811311
            ],
            [
              351687.6749766104,
              5011673.7957090056
            ],
            [
              351687.7563259044,
              5011661.870014051
            ],
            [
              351682.58660710492,
              5011661.2584893517
            ],
            [
              351682.71865712909,
              5011657.517356596
            ],
            [
              351685.75022700772,
              5011657.3598920591
            ],
            [
              351686.2031581045,
              5011654.5217757663
            ],
            [
              351686.74639391649,
              5011651.0757072093
            ],
            [
              351687.09962888167,
              5011648.3409108836
            ],
            [
              351687.35957306874,
              5011646.0116125783
            ],
            [
              351687.40852094366,
              5011643.1814339338
            ],
            [
              351687.37832343258,
              5011640.4667710261
            ],
            [
              351687.22299743659,
              5011637.5389294773
            ],
            [
              351687.47730993765,
              5011634.9070194419
            ],
            [
              351688.03370490758,
              5011632.1680229539
            ],
            [
              351688.79171254887,
              5011629.4256936405
            ],
            [
              351689.46394879144,
              5011627.4929035045
            ],
            [
              351690.63158712658,
              5011625.0460866103
            ],
            [
              351692.00991254923,
              5011622.9995298423
            ],
            [
              351693.08989962674,
              5011621.2612418495
            ],
            [
              351693.57291638566,
              5011620.0397094032
            ],
            [
              351693.85918643192,
              5011619.1249743281
            ],
            [
              351693.93256474816,
              5011617.6075103534
            ],
            [
              351689.6555013507,
              5011616.0703840833
            ],
            [
              351690.59332942101,
              5011612.1117457198
            ],
            [
              351694.46562806965,
              5011613.6568241613
            ],
            [
              351694.52396763972,
              5011611.3312778883
            ],
            [
              351694.2858643111,
              5011609.4158489136
            ],
            [
              351693.95447794901,
              5011607.9063370647
            ],
            [
              351693.30971447239,
              5011605.8970116545
            ],
            [
              351692.97268859303,
              5011604.0844689328
            ],
            [
              351692.91978637321,
              5011601.2838579658
            ],
            [
              351693.1767526035,
              5011598.7525246171
            ],
            [
              351693.95731859974,
              5011597.2223188756
            ],
            [
              351694.84431874927,
              5011595.9928488992
            ],
            [
              351695.92885485076,
              5011594.4569861684
            ],
            [
              351696.50891444326,
              5011593.0315572238
            ],
            [
              351696.78202567756,
              5011591.4097501663
            ],
            [
              351696.6360992993,
              5011588.9869601075
            ],
            [
              351696.30659286847,
              5011587.5784585364
            ],
            [
              351695.57231392345,
              5011586.1774899289
            ],
            [
              351695.03853360447,
              5011584.6713254154
            ],
            [
              351694.78727156081,
              5011582.048824409
            ],
            [
              351694.74214767053,
              5011579.6241583619
            ],
            [
              351695.10556379013,
              5011577.3943990692
            ],
            [
              351695.97940515884,
              5011575.4578571357
            ],
            [
              351697.26068810333,
              5011573.6151971342
            ],
            [
              351698.34820255166,
              5011572.2813696004
            ],
            [
              351699.12500118726,
              5011570.548724236
            ],
            [
              351699.9883527981,
              5011568.0065252138
            ],
            [
              351700.54772653285,
              5011565.4695640458
            ],
            [
              351701.01157756848,
              5011562.546131961
            ],
            [
              351702.28274519951,
              5011554.7415700695
            ],
            [
              351703.76866883505,
              5011547.6399091827
            ],
            [
              351705.10375086096,
              5011543.2696971055
            ],
            [
              351706.35417532682,
              5011539.8108872566
            ],
            [
              351707.03957861062,
              5011538.5855885167
            ],
            [
              351708.12520554115,
              5011537.1503317151
            ],
            [
              351709.12319662207,
              5011536.4244420007
            ],
            [
              351710.52596020466,
              5011535.6910193823
            ],
            [
              351711.7330754131,
              5011535.3654188998
            ],
            [
              351712.63966318779,
              5011535.1464565657
            ],
            [
              351714.39565482474,
              5011531.6778188366
            ],
            [
              351711.42995234457,
              5011529.9141963683
            ],
            [
              351713.01014456357,
              5011527.2898958977
            ],
            [
              351716.78727963107,
              5011529.1398819964
            ],
            [
              351719.3219577545,
              5011524.0400285944
            ],
            [
              351715.83563408337,
              5011521.4773135381
            ],
            [
              351717.5916258436,
              5011518.0086759105
            ],
            [
              351722.68685892189,
              5011520.036222579
            ],
            [
              351725.81365732633,
              5011514.1169877723
            ],
            [
              351722.2332692325,
              5011511.9602042753
            ],
            [
              351734.16065734898,
              5011483.7998481151
            ],
            [
              351734.54851155018,
              5011482.8832227774
            ],
            [
              351734.73585932516,
              5011482.0713741379
            ],
            [
              351734.72082086426,
              5011481.2632919233
            ],
            [
              351734.50527597254,
              5011480.5599864013
            ],
            [
              351734.29349069897,
              5011480.0587014379
            ],
            [
              351733.56296408543,
              5011478.8593341783
            ],
            [
              351733.24473697977,
              5011478.0568943135
            ],
            [
              351733.42644534732,
              5011476.9420148442
            ],
            [
              351733.72023496771,
              5011476.4313211003
            ],
            [
              351748.91161064629,
              5011458.5440934421
            ],
            [
              351767.19755244063,
              5011473.6649733372
            ],
            [
              351783.78335638705,
              5011455.3945750296
            ],
            [
              351788.83541180129,
              5011449.6416033842
            ],
            [
              351785.8426118053,
              5011446.4638514528
            ],
            [
              351788.1286161312,
              5011444.2989395913
            ],
            [
              351791.52165528917,
              5011447.2675718693
            ],
            [
              351826.17561656138,
              5011410.1537192166
            ],
            [
              351867.61782104796,
              5011364.1821493581
            ],
            [
              351881.44214681711,
              5011360.0365284076
            ],
            [
              351894.23313805438,
              5011357.0698517682
            ],
            [
              351892.87672455009,
              5011349.4148194427
            ],
            [
              351899.73546567472,
              5011348.3777735829
            ],
            [
              351900.77929177339,
              5011355.5333968578
            ],
            [
              351917.38727481506,
              5011351.1820998378
            ],
            [
              351916.57215952087,
              5011345.4372728309
            ],
            [
              351919.70343150216,
              5011345.1769104954
            ],
            [
              351919.93589389941,
              5011346.7893077387
            ],
            [
              351923.8582296393,
              5011345.6048171185
            ],
            [
              351924.08317296748,
              5011346.8131734487
            ],
            [
              351945.20197206241,
              5011341.9846527427
            ],
            [
              351945.4513601198,
              5011344.5065609422
            ],
            [
              351960.45309371123,
              5011340.7914278964
            ],
            [
              351965.89215267502,
              5011339.5787117919
            ],
            [
              351970.72294710414,
              5011338.2758508474
            ],
            [
              351975.04895614326,
              5011336.9828030886
            ],
            [
              351979.06833410502,
              5011335.5944164675
            ],
            [
              351981.88513851369,
              5011334.7336352626
            ],
            [
              351986.24872130476,
              5011333.1074035726
            ],
            [
              351998.19256932673,
              5011328.1355901519
            ],
            [
              351997.29732963041,
              5011323.5037525361
            ],
            [
              352011.34995206277,
              5011323.141193673
            ],
            [
              352023.40666944959,
              5011318.7739708172
            ],
            [
              352030.43395070551,
              5011315.914557077
            ],
            [
              352036.30678997579,
              5011313.2288237885
            ],
            [
              352039.51253883616,
              5011311.552024181
            ],
            [
              352042.11113729596,
              5011309.8869426837
            ],
            [
              352044.00553475652,
              5011308.4370564139
            ],
            [
              352047.08980395191,
              5011305.6510207215
            ],
            [
              352049.37393548765,
              5011303.3855203837
            ],
            [
              352050.06497039722,
              5011302.4628347633
            ],
            [
              352050.25607793505,
              5011301.8530073399
            ],
            [
              352050.24667917175,
              5011301.3479564097
            ],
            [
              352049.11138954473,
              5011300.1565416129
            ],
            [
              352049.08131351153,
              5011298.5403786357
            ],
            [
              352061.89485608012,
              5011296.7858279394
            ],
            [
              352065.62608283822,
              5011296.2111657821
            ],
            [
              352076.00504395756,
              5011294.098160265
            ],
            [
              352082.75734004664,
              5011292.7599614291
            ],
            [
              352096.09996727464,
              5011290.1171850273
            ],
            [
              352099.12025520438,
              5011289.3536629062
            ],
            [
              352100.32627095305,
              5011288.9270389453
            ],
            [
              352101.2245574579,
              5011288.3040513741
            ],
            [
              352101.81667754607,
              5011287.4846711
            ],
            [
              352102.20563019841,
              5011286.6690716743
            ],
            [
              352102.48625375691,
              5011285.4508883171
            ],
            [
              352102.55212227587,
              5011283.5298046591
            ],
            [
              352099.11766357382,
              5011283.7958084624
            ],
            [
              352098.39168713015,
              5011282.7988669835
            ],
            [
              352096.42882976419,
              5011280.6119820913
            ],
            [
              352094.06010980828,
              5011278.3320241803
            ],
            [
              352091.49275551038,
              5011276.2574337516
            ],
            [
              352088.42171437439,
              5011274.2936810618
            ],
            [
              352085.25551014213,
              5011272.6348347366
            ],
            [
              352082.19385988842,
              5011271.1757137328
            ],
            [
              352079.44104499294,
              5011270.0144001953
            ],
            [
              352075.31352501753,
              5011268.5319291065
            ],
            [
              352013.66620025469,
              5011248.7154216208
            ],
            [
              351937.14626901021,
              5011223.003533408
            ],
            [
              351868.95576667826,
              5011197.9537957804
            ],
            [
              351854.38744668523,
              5011192.3634395692
            ],
            [
              351852.62769288081,
              5011195.6300536804
            ],
            [
              351827.93291827448,
              5011183.9633380948
            ],
            [
              351822.93285194278,
              5011181.6308804462
            ],
            [
              351809.20030741306,
              5011176.4689817391
            ],
            [
              351731.18713331356,
              5011147.8067396637
            ],
            [
              351730.00089542283,
              5011147.2799835792
            ],
            [
              351729.48780167778,
              5011146.885350747
            ],
            [
              351728.86638667836,
              5011146.0885526286
            ],
            [
              351728.24309197057,
              5011145.1907442268
            ],
            [
              351727.62089555891,
              5011144.3939606445
            ],
            [
              351726.60034719348,
              5011143.9077258073
            ],
            [
              351725.57979882479,
              5011143.421490971
            ],
            [
              351724.05179583072,
              5011142.8436541269
            ],
            [
              351722.52943195542,
              5011142.5688481294
            ],
            [
              351720.80921485362,
              5011142.4993952475
            ],
            [
              351719.49221521866,
              5011142.4228581339
            ],
            [
              351718.38026295323,
              5011142.443550542
            ],
            [
              351717.46427639882,
              5011142.1574604148
            ],
            [
              351716.54907125531,
              5011141.871355745
            ],
            [
              351715.52100402856,
              5011140.981079746
            ],
            [
              351711.00207509316,
              5011137.3260787148
            ],
            [
              351708.02697605494,
              5011135.0574013833
            ],
            [
              351706.49333387881,
              5011134.1765336115
            ],
            [
              351704.96909022721,
              5011133.8007172765
            ],
            [
              351703.44484656816,
              5011133.424900935
            ],
            [
              351701.82355988974,
              5011133.2529810052
            ],
            [
              351700.40199834801,
              5011132.9762991136
            ],
            [
              351699.17529644381,
              5011132.2913907096
            ],
            [
              351697.33282642876,
              5011131.1131340852
            ],
            [
              351696.20395674609,
              5011130.2247338388
            ],
            [
              351694.96598440275,
              5011128.9341828041
            ],
            [
              351691.77157388063,
              5011125.7597597484
            ],
            [
              351688.5782616458,
              5011122.6863614721
            ],
            [
              351685.28415477974,
              5011119.6152580995
            ],
            [
              351681.17829843779,
              5011116.3567506922
            ],
            [
              351676.21280596516,
              5011112.7318613678
            ],
            [
              351669.2393902,
              5011107.6068558758
            ],
            [
              351663.80149636028,
              5011103.4637285015
            ],
            [
              351659.17989280523,
              5011099.7100110827
            ],
            [
              351655.27752090938,
              5011096.5487618428
            ],
            [
              351652.08686970291,
              5011093.5763587309
            ],
            [
              351647.04168877576,
              5011088.8196512535
            ],
            [
              351644.05265024054,
              5011085.8439154783
            ],
            [
              351641.67828156723,
              5011083.2605031896
            ],
            [
              351640.12772200623,
              5011081.4705422521
            ],
            [
              351638.7466649905,
              5011079.5059435442
            ],
            [
              351637.28829084389,
              5011077.2090406697
            ],
            [
              351636.03340128373,
              5011075.0093964422
            ],
            [
              351634.88008779183,
              5011072.8074427042
            ],
            [
              351633.31449075177,
              5011070.2093992075
            ],
            [
              351631.76016404608,
              5011068.216998416
            ],
            [
              351629.69275134261,
              5011065.8303836668
            ],
            [
              351627.7298926615,
              5011063.6434945678
            ],
            [
              351625.97694683063,
              5011061.8572996436
            ],
            [
              351624.53736959549,
              5011060.5704997284
            ],
            [
              351623.08948440704,
              5011058.8792539658
            ],
            [
              351621.12365557783,
              5011056.4907487435
            ],
            [
              351615.32351346244,
              5011049.2215376142
            ],
            [
              351611.80335799424,
              5011044.840631268
            ],
            [
              351609.81611756649,
              5011042.4776808405
            ],
            [
              351608.36071368674,
              5011040.3823936572
            ],
            [
              351607.31760896643,
              5011038.6840345357
            ],
            [
              351606.27073709568,
              5011036.7832356375
            ],
            [
              351605.63240502775,
              5011035.0773441717
            ],
            [
              351605.09111606522,
              5011033.1675562561
            ],
            [
              351604.34555331693,
              5011031.160105044
            ],
            [
              351603.61127643212,
              5011029.7591348635
            ],
            [
              351602.67351496947,
              5011028.2609059764
            ],
            [
              351601.63604151661,
              5011026.8651586166
            ],
            [
              351600.39055051951,
              5011025.1705655726
            ],
            [
              351598.83811139775,
              5011023.2795938365
            ],
            [
              351595.82462945645,
              5011018.9903037744
            ],
            [
              351587.99729211139,
              5011007.8411083193
            ],
            [
              351584.14309198834,
              5011001.8496924927
            ],
            [
              351580.30095133785,
              5010996.4643239481
            ],
            [
              351577.60757535329,
              5010993.0784844263
            ],
            [
              351575.13617157785,
              5010990.6993865771
            ],
            [
              351573.39074451284,
              5010989.3172323182
            ],
            [
              351572.47099056811,
              5010988.8287018789
            ],
            [
              351571.45686236554,
              5010988.6454828074
            ],
            [
              351570.8497033419,
              5010988.656781205
            ],
            [
              351569.54100323597,
              5010988.9842702486
            ],
            [
              351568.83304174489,
              5010988.9974444453
            ],
            [
              351568.22290480271,
              5010988.8067076476
            ],
            [
              351567.80873379618,
              5010988.3091882663
            ],
            [
              351567.58755081386,
              5010987.3028511051
            ],
            [
              351567.56311518641,
              5010985.9897168214
            ],
            [
              351567.46912425355,
              5010980.9387812149
            ],
            [
              351567.21864032542,
              5010978.3158450983
            ],
            [
              351566.67923127726,
              5010976.5070672091
            ],
            [
              351566.04387733794,
              5010975.0032106368
            ],
            [
              351565.31335211912,
              5010973.8038417092
            ],
            [
              351564.27588657296,
              5010972.4085131651
            ],
            [
              351562.59362739912,
              5010970.8503455715
            ],
            [
              351561.05246635439,
              5010969.5654353714
            ],
            [
              351559.81449412316,
              5010968.2748832516
            ],
            [
              351558.98128678865,
              5010966.9763797475
            ],
            [
              351558.65930139634,
              5010965.9719183333
            ],
            [
              351558.32603806118,
              5010964.361394925
            ],
            [
              351557.97397037118,
              5010961.740349059
            ],
            [
              351557.21525814285,
              5010959.0262442166
            ],
            [
              351556.6796006909,
              5010957.4190677796
            ],
            [
              351555.32390912925,
              5010955.2212983826
            ],
            [
              351554.08593692997,
              5010953.9307461847
            ],
            [
              351552.64369092631,
              5010952.5425306289
            ],
            [
              351550.59131576907,
              5010950.9639973138
            ],
            [
              351548.54645921831,
              5010949.7895053234
            ],
            [
              351546.1013706653,
              5010948.8245516689
            ],
            [
              351544.97547884245,
              5010948.1381856492
            ],
            [
              351544.5620814815,
              5010947.6402325155
            ],
            [
              351544.33901894948,
              5010946.5328849209
            ],
            [
              351544.42102490243,
              5010945.5209057722
            ],
            [
              351544.70619863062,
              5010944.5051459735
            ],
            [
              351545.49240682978,
              5010943.2779720491
            ],
            [
              351547.67949310498,
              5010941.2159481095
            ],
            [
              351548.86217400496,
              5010939.5772151919
            ],
            [
              351549.44865692127,
              5010938.4548031911
            ],
            [
              351549.82068637823,
              5010936.7296906961
            ],
            [
              351550.09380082058,
              5010935.1078834543
            ],
            [
              351550.06936539058,
              5010933.7947490988
            ],
            [
              351549.83391898894,
              5010931.9798954967
            ],
            [
              351549.59756664099,
              5010929.4862522734
            ],
            [
              351549.75108218443,
              5010926.8562174607
            ],
            [
              351550.39434655057,
              5010923.4082874134
            ],
            [
              351551.53268967691,
              5010919.344872864
            ],
            [
              351553.07768519648,
              5010915.3749364959
            ],
            [
              351554.53238252207,
              5010912.013371611
            ],
            [
              351556.77516001649,
              5010907.5252241557
            ],
            [
              351558.83810687787,
              5010904.2529668324
            ],
            [
              351561.11096646049,
              5010901.3814039249
            ],
            [
              351564.08802048262,
              5010898.2942272527
            ],
            [
              351570.29064843262,
              5010892.368910973
            ],
            [
              351575.96601072728,
              5010887.5137532325
            ],
            [
              351580.46166979021,
              5010884.4993634103
            ],
            [
              351583.36534221546,
              5010882.9296513768
            ],
            [
              351584.76998734864,
              5010882.2972414726
            ],
            [
              351586.57486446359,
              5010881.4552933183
            ],
            [
              351588.0765449811,
              5010880.6185678802
            ],
            [
              351589.97846527823,
              5010879.5727233356
            ],
            [
              351591.2804283002,
              5010878.8411788959
            ],
            [
              351592.17683724448,
              5010878.1171811372
            ],
            [
              351593.3753367574,
              5010877.2865166888
            ],
            [
              351595.17833420989,
              5010876.343558264
            ],
            [
              351596.38247260964,
              5010875.8159247916
            ],
            [
              351597.58848283754,
              5010875.3888825197
            ],
            [
              351599.10144890466,
              5010875.1586381905
            ],
            [
              351600.71897668199,
              5010875.1285387417
            ],
            [
              351602.0314360421,
              5010875.0030708034
            ],
            [
              351602.93802453257,
              5010874.7841101382
            ],
            [
              351603.84195197705,
              5010874.4641536912
            ],
            [
              351604.94340826472,
              5010873.8373856861
            ],
            [
              351606.84344885993,
              5010872.6905309111
            ],
            [
              351609.24420706794,
              5010871.2312227646
            ],
            [
              351612.59535304864,
              5010869.3022510158
            ],
            [
              351616.29839098378,
              5010867.2124379734
            ],
            [
              351618.70102097793,
              5010865.8537210878
            ],
            [
              351620.2064684481,
              5010865.2194355587
            ],
            [
              351622.71930680814,
              5010864.3643136444
            ],
            [
              351625.53345428436,
              5010863.4025396137
            ],
            [
              351628.04817223654,
              5010862.6484280424
            ],
            [
              351630.35862423829,
              5010861.797072229
            ],
            [
              351633.36951128719,
              5010860.5280821165
            ],
            [
              351635.87671068549,
              5010859.3699293425
            ],
            [
              351637.87645539216,
              5010858.1201741444
            ],
            [
              351640.27157457295,
              5010856.3578353021
            ],
            [
              351642.065165915,
              5010854.9094064431
            ],
            [
              351643.851246559,
              5010853.057355498
            ],
            [
              351645.72872372961,
              5010850.697958149
            ],
            [
              351648.00048498285,
              5010847.7253714725
            ],
            [
              351650.16235469159,
              5010844.3502293136
            ],
            [
              351651.36392783246,
              5010842.3405065415
            ],
            [
              351652.82050508162,
              5010839.07995323
            ],
            [
              351654.26579684421,
              5010835.2129189856
            ],
            [
              351655.30819567438,
              5010831.4544271585
            ],
            [
              351656.14633643249,
              5010827.599110214
            ],
            [
              351656.95704882266,
              5010822.2277859198
            ],
            [
              351657.65302794875,
              5010816.1516989414
            ],
            [
              351658.06461456424,
              5010811.0913567971
            ],
            [
              351658.18452714343,
              5010806.5714370925
            ],
            [
              351658.07737381512,
              5010800.8130114926
            ],
            [
              351657.66328043694,
              5010794.8586261664
            ],
            [
              351657.06371731393,
              5010789.8170995805
            ],
            [
              351656.39154588303,
              5010786.2926032869
            ],
            [
              351655.21756646381,
              5010782.9799544765
            ],
            [
              351652.16154420812,
              5010777.9166343994
            ],
            [
              351650.39434458356,
              5010775.3223411469
            ],
            [
              351648.3269262152,
              5010772.935305628
            ],
            [
              351645.65423634893,
              5010770.6609975304
            ],
            [
              351642.77805419284,
              5010768.2890114207
            ],
            [
              351639.29174313229,
              5010765.7267072452
            ],
            [
              351636.82519874425,
              5010763.6506536538
            ],
            [
              351634.75965997233,
              5010761.3646282833
            ],
            [
              351632.38154226466,
              5010758.5796121806
            ],
            [
              351630.61543306458,
              5010756.0859244009
            ],
            [
              351628.32009548874,
              5010752.2884956952
            ],
            [
              351625.17386164359,
              5010746.2839039918
            ],
            [
              351621.58772821369,
              5010739.7013512533
            ],
            [
              351618.55623551417,
              5010734.4019412687
            ],
            [
              351616.359039545,
              5010730.5016407883
            ],
            [
              351613.75299503567,
              5010726.3058121847
            ],
            [
              351611.77698130644,
              5010723.4118488058
            ],
            [
              351609.70205252158,
              5010720.6211905591
            ],
            [
              351606.79391747242,
              5010716.5320283482
            ],
            [
              351604.70770341402,
              5010713.1348889703
            ],
            [
              351603.76540274796,
              5010711.4346531136
            ],
            [
              351603.22223599418,
              5010709.4238543473
            ],
            [
              351602.87768861599,
              5010707.2068498377
            ],
            [
              351602.63959024841,
              5010705.2914197017
            ],
            [
              351602.48391189956,
              5010702.3862245297
            ],
            [
              351602.38804556133,
              5010697.2342790132
            ],
            [
              351602.38358387147,
              5010691.575406176
            ],
            [
              351602.50326104969,
              5010687.1267669518
            ],
            [
              351602.95056153525,
              5010683.9856202453
            ],
            [
              351603.70105862978,
              5010680.8392510181
            ],
            [
              351604.76491601177,
              5010678.191858083
            ],
            [
              351606.41557383927,
              5010674.5230932003
            ],
            [
              351609.15720013669,
              5010669.6219036719
            ],
            [
              351612.50371216319,
              5010664.0411342243
            ],
            [
              351616.33284122858,
              5010657.8052816242
            ],
            [
              351619.98214662896,
              5010652.7857380249
            ],
            [
              351624.43191624031,
              5010647.3471186245
            ],
            [
              351629.19771484175,
              5010642.508890545
            ],
            [
              351633.77726956917,
              5010638.5839550067
            ],
            [
              351638.91813281068,
              5010634.9982503522
            ],
            [
              351641.31889181217,
              5010633.5389439929
            ],
            [
              351644.0228395501,
              5010632.0739960438
            ],
            [
              351640.82201302407,
              5010628.5965514984
            ],
            [
              351643.11179039249,
              5010626.6340838755
            ],
            [
              351647.03936601355,
              5010631.1080389163
            ],
            [
              351650.45425276161,
              5010629.8319527134
            ],
            [
              351653.87743912835,
              5010628.9598932201
            ],
            [
              351657.29420537321,
              5010627.7848173678
            ],
            [
              351660.8125554432,
              5010626.6078513078
            ],
            [
              351663.62105681287,
              5010625.3426292874
            ],
            [
              351665.62456132803,
              5010624.29489615
            ],
            [
              351666.7278974356,
              5010623.7691393578
            ],
            [
              351667.83609079424,
              5010623.5464280192
            ],
            [
              351669.55332257209,
              5010623.4134292277
            ],
            [
              351672.08307718678,
              5010623.4674019162
            ],
            [
              351674.91414094885,
              5010623.4147226969
            ],
            [
              351677.94759097014,
              5010623.3582775621
            ],
            [
              351680.71741862089,
              5010622.9973128587
            ],
            [
              351683.23589581711,
              5010622.4452237561
            ],
            [
              351685.74872653052,
              5010621.5896846438
            ],
            [
              351689.3584809879,
              5010619.9057915779
            ],
            [
              351692.86367387505,
              5010618.0217536436
            ],
            [
              351696.96583857195,
              5010615.620961952
            ],
            [
              351702.36245599965,
              5010612.0850050775
            ],
            [
              351705.65209786635,
              5010609.4972420326
            ],
            [
              351709.42851916846,
              5010605.8899686327
            ],
            [
              351712.71065061487,
              5010602.898583672
            ],
            [
              351715.51511792169,
              5010599.7362179533
            ],
            [
              351719.36978706805,
              5010594.9149456359
            ],
            [
              351721.93721274921,
              5010591.5322594196
            ],
            [
              351723.90500565671,
              5010588.5653312178
            ],
            [
              351725.57148164767,
              5010585.7046357775
            ],
            [
              351726.72785002046,
              5010582.6517609237
            ],
            [
              351728.45831902511,
              5010577.7689696169
            ],
            [
              351729.39913661551,
              5010574.0123695787
            ],
            [
              351729.84831751493,
              5010570.9722344596
            ],
            [
              351730.08462416288,
              5010567.3302081088
            ],
            [
              351730.06308592652,
              5010561.8037267569
            ],
            [
              351729.58501146658,
              5010557.8718574038
            ],
            [
              351729.24046519405,
              5010555.6548535498
            ],
            [
              351728.39035180514,
              5010553.4476762358
            ],
            [
              351727.0301152147,
              5010551.047481169
            ],
            [
              351725.1613338461,
              5010548.4550775103
            ],
            [
              351721.44819726184,
              5010544.5829847129
            ],
            [
              351716.61856788612,
              5010540.5295762587
            ],
            [
              351712.51946187596,
              5010537.6755370433
            ],
            [
              351708.94095924182,
              5010535.6197535722
            ],
            [
              351706.39053007594,
              5010534.4546670299
            ],
            [
              351703.84761901502,
              5010533.693621642
            ],
            [
              351700.9007168088,
              5010532.9400934335
            ],
            [
              351698.1648092856,
              5010532.6874462292
            ],
            [
              351695.16594916396,
              5010532.8384223552
            ],
            [
              351691.63068335148,
              5010533.1062946729
            ],
            [
              351689.1028084206,
              5010533.1533316197
            ],
            [
              351687.37993811397,
              5010532.9832990579
            ],
            [
              351686.1588837328,
              5010532.6018385086
            ],
            [
              351684.43303576804,
              5010532.2297708057
            ],
            [
              351682.80720881437,
              5010531.8558419785
            ],
            [
              351680.37903813756,
              5010531.7999784183
            ],
            [
              351675.73291783751,
              5010532.1895660134
            ],
            [
              351671.39640620345,
              5010532.8765284624
            ],
            [
              351668.17184699437,
              5010533.5428004833
            ],
            [
              351665.35018081148,
              5010534.1005304866
            ],
            [
              351663.53590559709,
              5010534.4374251012
            ],
            [
              351662.52851460507,
              5010534.6582604973
            ],
            [
              351662.02857816883,
              5010534.9706988055
            ],
            [
              351661.83935638762,
              5010535.6819559345
            ],
            [
              351664.16060682089,
              5010546.2493563658
            ],
            [
              351658.39195683255,
              5010551.5104241436
            ],
            [
              351656.93843567383,
              5010549.5161454743
            ],
            [
              351660.7167454717,
              5010546.0103016524
            ],
            [
              351659.06467843469,
              5010538.7649436081
            ],
            [
              351650.59028195933,
              5010539.9330822714
            ],
            [
              351650.14791872486,
              5010537.920407841
            ],
            [
              351645.72376105812,
              5010539.3163182456
            ],
            [
              351640.99751270685,
              5010540.8188950727
            ],
            [
              351636.98487206799,
              5010542.6117487326
            ],
            [
              351634.98136713431,
              5010543.6594814826
            ],
            [
              351632.84529811691,
              5010545.060614123
            ],
            [
              351630.85307037079,
              5010546.7144087814
            ],
            [
              351628.86836076051,
              5010548.7722447226
            ],
            [
              351626.09369046002,
              5010551.8556519281
            ],
            [
              351623.82082837744,
              5010554.7272119988
            ],
            [
              351621.65441516577,
              5010557.9003465176
            ],
            [
              351619.69790644239,
              5010561.4737564605
            ],
            [
              351617.94001718651,
              5010564.840960796
            ],
            [
              351616.87506084511,
              5010567.3873284254
            ],
            [
              351616.10310637433,
              5010569.4225983275
            ],
            [
              351615.53808653197,
              5010571.6565275816
            ],
            [
              351614.88463183108,
              5010574.599000155
            ],
            [
              351614.33464836539,
              5010577.641011971
            ],
            [
              351613.9774916615,
              5010580.5435906611
            ],
            [
              351613.62048904557,
              5010583.0763658797
            ],
            [
              351613.35411755758,
              5010585.1026474973
            ],
            [
              351613.20513993461,
              5010587.9346877821
            ],
            [
              351613.36045704229,
              5010590.8625304783
            ],
            [
              351613.60796030279,
              5010593.2834313614
            ],
            [
              351613.85357623332,
              5010595.6029027924
            ],
            [
              351614.19811508269,
              5010597.8194882181
            ],
            [
              351614.76384356577,
              5010601.0428300481
            ],
            [
              351615.00758779614,
              5010603.2617102768
            ],
            [
              351614.94171539816,
              5010605.1827965444
            ],
            [
              351614.57719475636,
              5010607.3115305062
            ],
            [
              351614.00387553265,
              5010609.1414330946
            ],
            [
              351613.02279818332,
              5010610.7764132014
            ],
            [
              351611.74041161756,
              5010612.5180452364
            ],
            [
              351609.05902599072,
              5010615.1955361431
            ],
            [
              351605.62692752952,
              5010618.1092118127
            ],
            [
              351602.22442356759,
              5010620.0928039914
            ],
            [
              351600.21903911396,
              5010621.0395269757
            ],
            [
              351595.38634937379,
              5010622.2409498431
            ],
            [
              351589.83817987592,
              5010623.051504883
            ],
            [
              351585.49414912035,
              5010623.3344273446
            ],
            [
              351581.95512314216,
              5010623.4002800249
            ],
            [
              351579.82638143323,
              5010623.1367548332
            ],
            [
              351578.40106067312,
              5010622.658050077
            ],
            [
              351576.76849631005,
              5010621.8800657056
            ],
            [
              351574.8188048235,
              5010620.4006651649
            ],
            [
              351572.76078484644,
              5010618.5186798386
            ],
            [
              351569.15111706028,
              5010614.746125333
            ],
            [
              351567.90937927511,
              5010613.2531321626
            ],
            [
              351567.06490377279,
              5010611.3489849586
            ],
            [
              351566.41074809956,
              5010608.8346052524
            ],
            [
              351566.25355906278,
              5010605.8061709609
            ],
            [
              351566.38827376574,
              5010602.165613953
            ],
            [
              351567.02778225759,
              5010598.5156639973
            ],
            [
              351568.1661369708,
              5010594.4526698329
            ],
            [
              351570.41228362673,
              5010589.1791596776
            ],
            [
              351572.65772619913,
              5010584.7920103027
            ],
            [
              351577.67330656463,
              5010577.1198661001
            ],
            [
              351582.60875952069,
              5010570.5607114015
            ],
            [
              351586.16963945649,
              5010566.2501307288
            ],
            [
              351579.44372522045,
              5010563.5455948049
            ],
            [
              351580.8118783097,
              5010560.9940044889
            ],
            [
              351588.15246186848,
              5010564.0908649769
            ],
            [
              351593.19369486149,
              5010556.2429871075
            ],
            [
              351594.96740203182,
              5010553.6838506293
            ],
            [
              351596.64625426847,
              5010551.5302410871
            ],
            [
              351598.63284358248,
              5010549.5734152636
            ],
            [
              351600.52614077751,
              5010548.022087289
            ],
            [
              351603.91471482144,
              5010545.3318572259
            ],
            [
              351607.60537171707,
              5010542.5345416889
            ],
            [
              351611.70080904319,
              5010539.730113605
            ],
            [
              351614.593197804,
              5010537.5539235724
            ],
            [
              351616.28035738348,
              5010535.8047600975
            ],
            [
              351617.46570092993,
              5010534.2670246689
            ],
            [
              351618.43549365242,
              5010532.0255637616
            ],
            [
              351619.29807173222,
              5010529.4833812453
            ],
            [
              351620.74524664617,
              5010525.717358225
            ],
            [
              351622.19618064904,
              5010522.1533558667
            ],
            [
              351624.78140471847,
              5010517.5846282169
            ],
            [
              351626.64948667825,
              5010514.720180979
            ],
            [
              351629.11721729621,
              5010511.4408140834
            ],
            [
              351632.38806539431,
              5010507.8429483054
            ],
            [
              351636.56362262141,
              5010503.9251126107
            ],
            [
              351640.64888090425,
              5010500.6156482063
            ],
            [
              351646.43444764178,
              5010496.2636737907
            ],
            [
              351651.72007784771,
              5010492.2241377784
            ],
            [
              351655.51231852331,
              5010489.4249330126
            ],
            [
              351658.50066034257,
              5010486.9442417035
            ],
            [
              351660.71850762999,
              5010484.8128878912
            ],
            [
              351662.60241500201,
              5010482.756928158
            ],
            [
              351664.57208090223,
              5010479.8905912349
            ],
            [
              351665.54376116057,
              5010477.7505602203
            ],
            [
              351666.4100985509,
              5010475.4103987962
            ],
            [
              351667.18878230284,
              5010473.7787660249
            ],
            [
              351668.86090585677,
              5010471.2215207014
            ],
            [
              351670.93325376586,
              5010468.4543185569
            ],
            [
              351673.40850255056,
              5010465.5789936231
            ],
            [
              351676.08988884866,
              5010462.9015043816
            ],
            [
              351678.37778742559,
              5010460.8380277567
            ],
            [
              351680.76538254198,
              5010458.6712315502
            ],
            [
              351682.45442170813,
              5010457.0230790973
            ],
            [
              351683.53818153444,
              5010455.4872344155
            ],
            [
              351684.81868132675,
              5010453.6441738969
            ],
            [
              351686.67815518292,
              5010450.2750806008
            ],
            [
              351688.73358761513,
              5010446.5987860458
            ],
            [
              351691.12563352211,
              5010443.2428433578
            ],
            [
              351693.58881614666,
              5010439.7610524055
            ],
            [
              351698.74169235578,
              5010434.0062184352
            ],
            [
              351701.99750461493,
              5010429.6002717707
            ],
            [
              351706.91604314657,
              5010422.1320289169
            ],
            [
              351707.90275182034,
              5010420.7996615358
            ],
            [
              351708.28872939193,
              5010419.7820270229
            ],
            [
              351708.57688211818,
              5010418.9683032567
            ],
            [
              351711.84585094242,
              5010415.2694288958
            ],
            [
              351715.12422504358,
              5010412.0760251861
            ],
            [
              351721.60083743365,
              5010406.8017876158
            ],
            [
              351729.03847670957,
              5010401.1675401423
            ],
            [
              351732.73477971688,
              5010398.6736771557
            ],
            [
              351736.33889631572,
              5010396.6867558016
            ],
            [
              351741.84758694319,
              5010393.7545697046
            ],
            [
              351750.27200913709,
              5010389.8587237261
            ],
            [
              351755.87963032484,
              5010386.8240710869
            ],
            [
              351757.88501452439,
              5010385.8773498982
            ],
            [
              351759.09479157411,
              5010385.6527492246
            ],
            [
              351759.60036646406,
              5010385.6433420544
            ],
            [
              351760.10970032512,
              5010385.8359554373
            ],
            [
              351760.62091367168,
              5010386.129579097
            ],
            [
              351761.13400650426,
              5010386.5242130347
            ],
            [
              351761.75541969278,
              5010387.3210124569
            ],
            [
              351762.99150954024,
              5010388.510555652
            ],
            [
              351763.50460236991,
              5010388.9051895794
            ],
            [
              351764.01017725497,
              5010388.8957824074
            ],
            [
              351764.41307023942,
              5010388.7872405732
            ],
            [
              351764.80954334937,
              5010388.3756824555
            ],
            [
              351765.4054251365,
              5010387.7583234692
            ],
            [
              351766.39214157517,
              5010386.4263755409
            ],
            [
              351767.81956750166,
              5010384.4556379765
            ],
            [
              351769.41641240322,
              5010383.3140087053
            ],
            [
              351771.2231692716,
              5010382.5730738956
            ],
            [
              351773.33875322505,
              5010382.1295285216
            ],
            [
              351775.55780047277,
              5010381.7851032717
            ],
            [
              351777.98675187241,
              5010381.8409533389
            ],
            [
              351780.01093080221,
              5010381.9043349521
            ],
            [
              351782.74980732641,
              5010382.3585992493
            ],
            [
              351784.9857698768,
              5010382.9232664378
            ],
            [
              351786.71240639233,
              5010383.2957393723
            ],
            [
              351790.05844152754,
              5010383.7387063308
            ],
            [
              351795.23378719488,
              5010384.6528617172
            ],
            [
              351799.29530123837,
              5010385.4866967648
            ],
            [
              351805.9016047279,
              5010387.1825883212
            ],
            [
              351812.61811572505,
              5010389.382074628
            ],
            [
              351826.51616483351,
              5010394.8272896931
            ],
            [
              351834.8791681563,
              5010398.5113987075
            ],
            [
              351839.26643201936,
              5010400.5521342708
            ],
            [
              351844.17617821856,
              5010403.4921357334
            ],
            [
              351849.80517006171,
              5010407.0254447581
            ],
            [
              351858.52587460674,
              5010413.6336281402
            ],
            [
              351865.71670619416,
              5010419.5633802479
            ],
            [
              351870.75357529923,
              5010423.9160681358
            ],
            [
              351875.6315181636,
              5010428.7597488482
            ],
            [
              351877.81555256149,
              5010431.9529766431
            ],
            [
              351879.07983463182,
              5010434.6576729044
            ],
            [
              351879.51954384672,
              5010436.5697695604
            ],
            [
              351879.55337485234,
              5010438.3879539138
            ],
            [
              351879.49768829677,
              5010440.8144944785
            ],
            [
              351878.93187883002,
              5010443.0480172224
            ],
            [
              351877.96772476041,
              5010445.5925069079
            ],
            [
              351876.50174719997,
              5010448.3480052222
            ],
            [
              351875.1242047167,
              5010450.3949608132
            ],
            [
              351872.54925295908,
              5010453.3731843652
            ],
            [
              351867.39340206422,
              5010458.9259806741
            ],
            [
              351862.22737222683,
              5010463.9737404585
            ],
            [
              351857.96793046984,
              5010468.8025409579
            ],
            [
              351855.50410507614,
              5010472.1656876719
            ],
            [
              351853.83010291745,
              5010474.6219213586
            ],
            [
              351853.261316018,
              5010476.6534092855
            ],
            [
              351852.98742603278,
              5010478.275648416
            ],
            [
              351853.24807711568,
              5010481.4032003125
            ],
            [
              351853.81004507717,
              5010484.4245196078
            ],
            [
              351854.25052821724,
              5010486.3361826679
            ],
            [
              351855.40383722418,
              5010488.5381369879
            ],
            [
              351857.48628070869,
              5010491.7328356467
            ],
            [
              351858.52187170583,
              5010493.027573349
            ],
            [
              351862.13231652806,
              5010496.8001114437
            ],
            [
              351868.21417525876,
              5010502.9525871025
            ],
            [
              351873.86948101292,
              5010507.9000376547
            ],
            [
              351877.8917975658,
              5010511.1223701034
            ],
            [
              351884.0338827477,
              5010515.0503106248
            ],
            [
              351893.34217029664,
              5010520.6371052107
            ],
            [
              351900.80125412933,
              5010524.6415848276
            ],
            [
              351906.31549801113,
              5010527.4692907287
            ],
            [
              351917.57908599329,
              5010532.3585055368
            ],
            [
              351932.034523974,
              5010537.3442984438
            ],
            [
              351941.086699482,
              5010540.0055465093
            ],
            [
              351944.64827614912,
              5010541.1518174391
            ],
            [
              351946.57650894433,
              5010541.5201189546
            ],
            [
              351947.79568287899,
              5010541.8005689681
            ],
            [
              351948.50552314246,
              5010541.8884059293
            ],
            [
              351949.11377963459,
              5010541.9781330936
            ],
            [
              351949.72845613206,
              5010542.3708763774
            ],
            [
              351950.44691263168,
              5010542.963778981
            ],
            [
              351951.06158912694,
              5010543.3565222649
            ],
            [
              351951.67360467749,
              5010543.6482698666
            ],
            [
              351957.06637849408,
              5010545.3671574369
            ],
            [
              351974.13647243712,
              5010551.3570959764
            ],
            [
              351980.04233083536,
              5010553.4701976245
            ],
            [
              351985.03409111669,
              5010555.3986370666
            ],
            [
              351992.27496176475,
              5010558.5983944945
            ],
            [
              352005.1230501699,
              5010564.1193196457
            ],
            [
              352014.09807419439,
              5010568.0955892829
            ],
            [
              352029.61541296396,
              5010575.7902588742
            ],
            [
              352034.78837098309,
              5010578.6767226486
            ],
            [
              352044.06282371172,
              5010582.4453296168
            ],
            [
              352050.0683852933,
              5010584.4555299012
            ],
            [
              352057.28858766326,
              5010586.5445931712
            ],
            [
              352063.90052579576,
              5010588.5435101772
            ],
            [
              352068.67109576141,
              5010589.4651935576
            ],
            [
              352072.52913067775,
              5010590.2021858403
            ],
            [
              352075.97220651997,
              5010590.4412543839
            ],
            [
              352079.37305785116,
              5010590.595576616
            ],
            [
              352083.71708240482,
              5010590.3126550028
            ],
            [
              352087.35204832919,
              5010589.9418820282
            ],
            [
              352091.38646438433,
              5010589.3615860045
            ],
            [
              352095.31710040726,
              5010588.5811307719
            ],
            [
              352098.94376662676,
              5010587.8063316625
            ],
            [
              352102.56745513657,
              5010586.8294976829
            ],
            [
              352105.38159168401,
              5010585.86730815
            ],
            [
              352109.3001688908,
              5010584.4808064876
            ],
            [
              352118.73384910013,
              5010580.465134996
            ],
            [
              352125.9000431762,
              5010577.047194453
            ],
            [
              352132.51018118887,
              5010573.4882427426
            ],
            [
              352136.71691014915,
              5010571.2880184604
            ],
            [
              352141.3179074878,
              5010568.4737678217
            ],
            [
              352148.61830838048,
              5010563.9925662251
            ],
            [
              352155.40749627363,
              5010559.2177417492
            ],
            [
              352160.59420298674,
              5010555.2815157399
            ],
            [
              352168.4175907289,
              5010549.2061453275
            ],
            [
              352173.09307669691,
              5010544.9758774126
            ],
            [
              352176.07389552175,
              5010542.0911487592
            ],
            [
              352179.25145395234,
              5010538.8992047068
            ],
            [
              352181.63075339264,
              5010536.3288041921
            ],
            [
              352184.51076213043,
              5010533.445532213
            ],
            [
              352186.99540432513,
              5010531.075261876
            ],
            [
              352189.58460005547,
              5010528.9047170905
            ],
            [
              352192.47777325922,
              5010526.7289354168
            ],
            [
              352194.37028589123,
              5010525.1776248021
            ],
            [
              352197.77091284248,
              5010523.0934459809
            ],
            [
              352201.8749595285,
              5010520.7940882407
            ],
            [
              352207.32109114865,
              5010517.8190442519
            ],
            [
              352212.61314029089,
              5010514.0829495536
            ],
            [
              352215.60632646194,
              5010511.9048879081
            ],
            [
              352217.50150776602,
              5010510.4549922105
            ],
            [
              352217.89876162395,
              5010510.0434199572
            ],
            [
              352218.07969146804,
              5010508.9285570923
            ],
            [
              352217.65313892282,
              5010507.7235334013
            ],
            [
              352217.33867356629,
              5010507.123114042
            ],
            [
              352216.3025733211,
              5010505.4648763705
            ],
            [
              352221.39332680096,
              5010501.8331533531
            ],
            [
              352225.38341446227,
              5010498.8286006972
            ],
            [
              352229.37349430408,
              5010495.8236289807
            ],
            [
              352230.96282643994,
              5010494.2783793099
            ],
            [
              352231.65762999206,
              5010493.5581351984
            ],
            [
              352232.25084985717,
              5010492.8397812713
            ],
            [
              352232.95505097957,
              5010492.6245878544
            ],
            [
              352237.6116594795,
              5010492.8410768146
            ],
            [
              352238.31586059014,
              5010492.6258833995
            ],
            [
              352238.71310664649,
              5010492.2138920622
            ],
            [
              352239.10769957589,
              5010491.7013242561
            ],
            [
              352239.29316990782,
              5010490.7884672023
            ],
            [
              352239.28001331462,
              5010490.081396251
            ],
            [
              352239.05695271521,
              5010488.974050588
            ],
            [
              352238.63792611443,
              5010488.1734866193
            ],
            [
              352236.54795914085,
              5010484.5743317911
            ],
            [
              352234.92589387199,
              5010484.4024235969
            ],
            [
              352231.6907619696,
              5010489.9194754511
            ],
            [
              352228.85782324855,
              5010489.8711433262
            ],
            [
              352228.94249131798,
              5010488.9601618918
            ],
            [
              352229.0226189504,
              5010487.847174718
            ],
            [
              352229.49811314861,
              5010486.2211860633
            ],
            [
              352230.28244192281,
              5010484.8930054894
            ],
            [
              352231.06677070068,
              5010483.5648249192
            ],
            [
              352232.16069986083,
              5010482.5335996309
            ],
            [
              352232.86036055483,
              5010482.116400497
            ],
            [
              352240.25177415059,
              5010482.4845121549
            ],
            [
              352241.15945151635,
              5010482.3661584808
            ],
            [
              352241.659387163,
              5010482.0537207797
            ],
            [
              352241.95317783259,
              5010481.543028634
            ],
            [
              352244.19971553789,
              5010477.2569132121
            ],
            [
              352244.38894492481,
              5010476.5460764477
            ],
            [
              352244.47925155243,
              5010475.9381254585
            ],
            [
              352244.06508210272,
              5010475.4406064302
            ],
            [
              352240.79917894531,
              5010473.8846538011
            ],
            [
              352240.09200022294,
              5010473.8978123618
            ],
            [
              352239.78991009336,
              5010474.0044785021
            ],
            [
              352239.39155812113,
              5010474.3150260141
            ],
            [
              352236.83729168732,
              5010478.4047772111
            ],
            [
              352234.07585674274,
              5010476.7379734805
            ],
            [
              352238.90971858177,
              5010470.18072295
            ],
            [
              352244.69404164254,
              5010470.5095579205
            ],
            [
              352247.50560677779,
              5010463.9899367522
            ],
            [
              352248.56946386554,
              5010461.3425494442
            ],
            [
              352249.13449120481,
              5010459.1090436103
            ],
            [
              352249.2955202728,
              5010456.8826357396
            ],
            [
              352249.17514155834,
              5010455.8744245823
            ],
            [
              352248.76363305916,
              5010455.4779011309
            ],
            [
              352243.66466277075,
              5010453.2487405939
            ],
            [
              352243.05750491197,
              5010453.2600380238
            ],
            [
              352242.45676697086,
              5010453.5743513163
            ],
            [
              352241.76948136819,
              5010454.6986358687
            ],
            [
              352241.09347278741,
              5010456.428981239
            ],
            [
              352240.63191640406,
              5010458.7620262057
            ],
            [
              352240.28540913394,
              5010461.9008720377
            ],
            [
              352240.14693416492,
              5010465.3394015981
            ],
            [
              352236.54282322718,
              5010467.3263207348
            ],
            [
              352236.39237238123,
              5010464.7019473547
            ],
            [
              352236.6324310432,
              5010461.2615275793
            ],
            [
              352237.16361953353,
              5010457.2094200822
            ],
            [
              352238.10522628966,
              5010453.453230517
            ],
            [
              352239.15890456585,
              5010450.3008070262
            ],
            [
              352240.2321670886,
              5010448.1588895004
            ],
            [
              352250.19952916069,
              5010451.1058245199
            ],
            [
              352257.01253458619,
              5010436.7304371772
            ],
            [
              352249.14946901955,
              5010432.733476813
            ],
            [
              352241.010849685,
              5010446.5272595165
            ],
            [
              352237.73366962915,
              5010444.365245779
            ],
            [
              352240.55165522342,
              5010438.1486405563
            ],
            [
              352243.21282586473,
              5010433.8808053294
            ],
            [
              352246.76242375252,
              5010428.9641735656
            ],
            [
              352250.23189384019,
              5010425.160528996
            ],
            [
              352253.41697845102,
              5010422.3730463656
            ],
            [
              352255.90725159884,
              5010420.3053885698
            ],
            [
              352257.59362723521,
              5010418.5562434811
            ],
            [
              352258.77708962234,
              5010416.9175012
            ],
            [
              352259.5614185991,
              5010415.5893208943
            ],
            [
              352260.0425515704,
              5010414.2663628748
            ],
            [
              352260.41349262698,
              5010412.4406489581
            ],
            [
              352260.48986152111,
              5010411.125641644
            ],
            [
              352260.55948607484,
              5010409.4061601413
            ],
            [
              352260.73853673489,
              5010408.1902873358
            ],
            [
              352262.57356681139,
              5010403.5076491227
            ],
            [
              352265.41298172419,
              5010397.2244006237
            ],
            [
              352268.04549736605,
              5010391.9206532091
            ],
            [
              352270.39472556621,
              5010387.7340923324
            ],
            [
              352274.12414819788,
              5010381.6011550175
            ],
            [
              352278.74888886121,
              5010374.6436173022
            ],
            [
              352287.04572404298,
              5010362.0850107996
            ],
            [
              352292.07633484266,
              5010355.2209667629
            ],
            [
              352299.594241457,
              5010346.0872311546
            ],
            [
              352306.52864207118,
              5010338.2779392265
            ],
            [
              352314.05595394998,
              5010329.6496740719
            ],
            [
              352321.34075515083,
              5010322.0615289053
            ],
            [
              352328.99141509546,
              5010314.6430910826
            ],
            [
              352336.93775313866,
              5010306.8153909771
            ],
            [
              352345.87941437989,
              5010298.1603917442
            ],
            [
              352349.75553616666,
              5010294.4502282599
            ],
            [
              352352.35803329438,
              5010291.608180848
            ],
            [
              352354.53384946805,
              5010288.9405244011
            ],
            [
              352356.60619474662,
              5010286.1733288784
            ],
            [
              352358.17861217592,
              5010283.7189898267
            ],
            [
              352359.74992377736,
              5010281.1632070243
            ],
            [
              352361.60108870018,
              5010277.3896762813
            ],
            [
              352362.95498665486,
              5010274.0299975593
            ],
            [
              352364.19977539789,
              5010270.2677494492
            ],
            [
              352364.9653102294,
              5010267.9294688785
            ],
            [
              352365.92946426786,
              5010265.3849832881
            ],
            [
              352366.97264862451,
              5010261.6264864001
            ],
            [
              352368.89908515435,
              5010256.4369243328
            ],
            [
              352370.34438830556,
              5010252.5703196917
            ],
            [
              352371.00444659404,
              5010249.6465986762
            ],
            [
              352371.54581599322,
              5010246.0995290298
            ],
            [
              352371.97997126105,
              5010242.251738335
            ],
            [
              352372.108269808,
              5010238.3081742218
            ],
            [
              352371.8984523947,
              5010232.4510449413
            ],
            [
              352371.31769315386,
              5010228.4196287831
            ],
            [
              352370.55334242369,
              5010225.4020792721
            ],
            [
              352369.59491278103,
              5010222.7927404065
            ],
            [
              352368.43330806372,
              5010220.1867626123
            ],
            [
              352367.36953594157,
              5010217.3772936184
            ],
            [
              352366.82260663662,
              5010215.1640591873
            ],
            [
              352366.44893742044,
              5010211.9698536927
            ],
            [
              352366.27483740816,
              5010208.0323353363
            ],
            [
              352366.3832440495,
              5010202.9776455183
            ],
            [
              352366.70829065191,
              5010198.7272853898
            ],
            [
              352367.62921840517,
              5010193.8595677037
            ],
            [
              352368.8589726155,
              5010189.2892391318
            ],
            [
              352370.59508229379,
              5010184.7094891975
            ],
            [
              352372.13554241759,
              5010180.5375598911
            ],
            [
              352373.01377141738,
              5010177.6181651801
            ],
            [
              352373.57315434166,
              5010175.0812111888
            ],
            [
              352373.83765605727,
              5010172.9543433841
            ],
            [
              352374.0091898205,
              5010171.3344307048
            ],
            [
              352374.28308085207,
              5010169.7121942714
            ],
            [
              352374.86314522597,
              5010168.2867705105
            ],
            [
              352376.14177238924,
              5010166.3431238011
            ],
            [
              352378.12459279509,
              5010164.1838653181
            ],
            [
              352379.81175055547,
              5010162.4347075634
            ],
            [
              352381.10353364469,
              5010161.198131579
            ],
            [
              352381.69110790506,
              5010160.1763291191
            ],
            [
              352382.17037007544,
              5010158.7527809553
            ],
            [
              352382.35020297189,
              5010157.5368941668
            ],
            [
              352382.72678350675,
              5010156.01421146
            ],
            [
              352383.50922599976,
              5010154.5846029976
            ],
            [
              352384.98648025695,
              5010152.4351705667
            ],
            [
              352387.06822351692,
              5010150.1730265813
            ],
            [
              352388.25920439255,
              5010148.9383262116
            ],
            [
              352388.94649070723,
              5010147.8140427461
            ],
            [
              352389.42199409439,
              5010146.18847444
            ],
            [
              352389.68006853521,
              5010143.7581718843
            ],
            [
              352389.69523358892,
              5010139.2376954416
            ],
            [
              352389.52300602995,
              5010135.4007682763
            ],
            [
              352389.76604528743,
              5010132.1623849738
            ],
            [
              352390.12195245334,
              5010129.528591292
            ],
            [
              352390.78291934781,
              5010126.9897474265
            ],
            [
              352391.84600498056,
              5010124.3427959178
            ],
            [
              352393.30821636092,
              5010121.3848637929
            ],
            [
              352394.06999333558,
              5010118.8445635587
            ],
            [
              352394.54360965284,
              5010117.1175660938
            ],
            [
              352394.8016920918,
              5010114.6876827236
            ],
            [
              352395.26403202029,
              5010112.3546247138
            ],
            [
              352396.84413607506,
              5010107.9186542835
            ],
            [
              352397.71610456734,
              5010105.8811099054
            ],
            [
              352398.68778510852,
              5010103.7410845039
            ],
            [
              352399.96906556236,
              5010101.8980145901
            ],
            [
              352402.54699383239,
              5010099.1218329556
            ],
            [
              352405.52404784289,
              5010096.0346707711
            ],
            [
              352409.71464340726,
              5010092.9253487382
            ],
            [
              352414.51348670584,
              5010089.9053354962
            ],
            [
              352418.81991958834,
              5010087.6022187779
            ],
            [
              352422.22727536224,
              5010085.9216813855
            ],
            [
              352424.42752480309,
              5010084.5671578692
            ],
            [
              352426.52806999703,
              5010083.315534519
            ],
            [
              352430.12731662451,
              5010081.0251565864
            ],
            [
              352432.9395803386,
              5010079.9623811562
            ],
            [
              352435.55697575241,
              5010079.3074116539
            ],
            [
              352437.57551259326,
              5010079.0677647069
            ],
            [
              352439.99694171693,
              5010078.7195765069
            ],
            [
              352441.5099046338,
              5010078.4893362569
            ],
            [
              352443.56551592617,
              5010077.721552752
            ],
            [
              352445.57465576765,
              5010076.976855428
            ],
            [
              352446.67720881203,
              5010076.4511161288
            ],
            [
              352448.07432656776,
              5010075.4142516702
            ],
            [
              352449.66365959548,
              5010073.8690049211
            ],
            [
              352451.16158735746,
              5010072.8306840882
            ],
            [
              352452.36760340427,
              5010072.4040648276
            ],
            [
              352453.27419017808,
              5010072.1851068242
            ],
            [
              352454.2853379398,
              5010072.1662934516
            ],
            [
              352455.40104668733,
              5010072.347624707
            ],
            [
              352457.13143048796,
              5010072.9216995165
            ],
            [
              352458.45328383666,
              5010073.3012853358
            ],
            [
              352459.8684218339,
              5010073.2749553332
            ],
            [
              352461.58377049759,
              5010073.040949543
            ],
            [
              352464.09660472127,
              5010072.1858355245
            ],
            [
              352467.91249835171,
              5010070.7002066178
            ],
            [
              352473.96616916487,
              5010067.9444689136
            ],
            [
              352475.86698129756,
              5010066.7971878247
            ],
            [
              352477.76216306078,
              5010065.3472956726
            ],
            [
              352479.16226588417,
              5010064.5128851589
            ],
            [
              352480.57066766167,
              5010064.0825003833
            ],
            [
              352481.88312397897,
              5010063.9570358731
            ],
            [
              352482.69188583858,
              5010063.941988105
            ],
            [
              352484.72169873415,
              5010064.3084016796
            ],
            [
              352487.4726269719,
              5010065.3687134627
            ],
            [
              352491.24410757917,
              5010066.9156813249
            ],
            [
              352493.89721093659,
              5010068.179903253
            ],
            [
              352495.62759453611,
              5010068.7539780177
            ],
            [
              352497.45126277505,
              5010068.9221370071
            ],
            [
              352499.37197442545,
              5010068.8864003466
            ],
            [
              352501.19266528048,
              5010068.8525246689
            ],
            [
              352503.0069365502,
              5010068.5156333437
            ],
            [
              352504.71774481534,
              5010068.0796220247
            ],
            [
              352505.92939884303,
              5010067.9560330268
            ],
            [
              352507.1485704151,
              5010068.2364842752
            ],
            [
              352508.87707456102,
              5010068.7095489586
            ],
            [
              352511.42373743007,
              5010069.6726161502
            ],
            [
              352513.35760471888,
              5010070.3439498963
            ],
            [
              352515.28285646992,
              5010070.5102188084
            ],
            [
              352517.10276582779,
              5010070.4763576603
            ],
            [
              352518.81623482035,
              5010070.141341866
            ],
            [
              352520.42624081258,
              5010069.7072060797
            ],
            [
              352521.83088363981,
              5010069.0748012252
            ],
            [
              352523.22910688759,
              5010068.1393807484
            ],
            [
              352526.28408875066,
              5010065.92160198
            ],
            [
              352529.87504329748,
              5010063.2276183497
            ],
            [
              352532.27203197655,
              5010061.5658805436
            ],
            [
              352535.08053614764,
              5010060.3010854842
            ],
            [
              352536.18574972841,
              5010059.8763418905
            ],
            [
              352537.39662223606,
              5010059.7527674735
            ],
            [
              352538.90958473773,
              5010059.522527379
            ],
            [
              352541.63608052378,
              5010059.269708381
            ],
            [
              352543.95968410844,
              5010059.125430583
            ],
            [
              352545.47076719726,
              5010058.7941804463
            ],
            [
              352546.77570573182,
              5010058.2646757886
            ],
            [
              352547.47614771797,
              5010057.8474633591
            ],
            [
              352548.37521671498,
              5010057.2244652649
            ],
            [
              352549.77343208942,
              5010056.2886257768
            ],
            [
              352552.5706598371,
              5010054.4177705478
            ],
            [
              352555.57215273153,
              5010052.6441598283
            ],
            [
              352558.28361333068,
              5010051.5832605362
            ],
            [
              352560.09411775391,
              5010051.0439301431
            ],
            [
              352562.10889512027,
              5010050.6022633612
            ],
            [
              352564.22713544947,
              5010050.259716575
            ],
            [
              352566.44539653149,
              5010049.9153088173
            ],
            [
              352567.75675455696,
              5010049.6888198135
            ],
            [
              352568.86118663481,
              5010049.2640908323
            ],
            [
              352569.96185994626,
              5010048.6373417694
            ],
            [
              352570.86092890258,
              5010048.0143437386
            ],
            [
              352571.75733706279,
              5010047.2903502081
            ],
            [
              352572.65640601591,
              5010046.6673521847
            ],
            [
              352575.27380043286,
              5010046.0123832086
            ],
            [
              352578.29971731914,
              5010045.5514840605
            ],
            [
              352581.19438481593,
              5010045.3886150215
            ],
            [
              352583.7289893881,
              5010045.7456364976
            ],
            [
              352585.90052726312,
              5010046.2930551069
            ],
            [
              352588.1375820167,
              5010046.9587477595
            ],
            [
              352589.96689569514,
              5010047.4303560592
            ],
            [
              352591.79698297946,
              5010047.9015306802
            ],
            [
              352593.11131825909,
              5010047.877076311
            ],
            [
              352594.22138840094,
              5010047.7553774724
            ],
            [
              352595.63166911021,
              5010047.4260029439
            ],
            [
              352597.03740184335,
              5010046.8942037644
            ],
            [
              352597.83676653012,
              5010046.3741058744
            ],
            [
              352599.3459700158,
              5010045.941845811
            ],
            [
              352600.75437132624,
              5010045.5114612663
            ],
            [
              352601.96790440381,
              5010045.4888824187
            ],
            [
              352603.18519623874,
              5010045.6683236342
            ],
            [
              352604.30090448976,
              5010045.8496549008
            ],
            [
              352605.32144871115,
              5010046.335891773
            ],
            [
              352606.74865160015,
              5010046.9160266705
            ],
            [
              352608.07426310802,
              5010047.4976324905
            ],
            [
              352605.98806175153,
              5010044.1004988775
            ],
            [
              352608.46705836989,
              5010041.4267857559
            ],
            [
              352607.32049866096,
              5010039.6293085199
            ],
            [
              352616.62636629189,
              5010028.7445567613
            ],
            [
              352612.29045090888,
              5010013.161997728
            ],
            [
              352594.51137466653,
              5010020.1940456089
            ],
            [
              352592.40825915005,
              5010015.8878213763
            ],
            [
              352611.98279212706,
              5010007.8437826624
            ],
            [
              352612.57977109693,
              5010007.3274504254
            ],
            [
              352613.07030199695,
              5010006.5095444778
            ],
            [
              352613.84037822811,
              5010004.3732716991
            ],
            [
              352615.47788289556,
              5009999.9974505873
            ],
            [
              352616.93821504043,
              5009996.9385108836
            ],
            [
              352618.60657883756,
              5009994.1792548848
            ],
            [
              352619.79004201904,
              5009992.5405157767
            ],
            [
              352621.08181702567,
              5009991.3035221631
            ],
            [
              352622.18436964188,
              5009990.7777834097
            ],
            [
              352623.9956630363,
              5009990.2388580265
            ],
            [
              352623.97311067174,
              5009989.0267377123
            ],
            [
              352623.26139257429,
              5009988.8378897477
            ],
            [
              352622.44323422341,
              5009988.3478871984
            ],
            [
              352621.91134170437,
              5009986.9427342499
            ],
            [
              352621.87939253758,
              5009985.2255637879
            ],
            [
              352621.95952171512,
              5009984.112577999
            ],
            [
              352622.4481810935,
              5009983.1940812031
            ],
            [
              352623.33220734034,
              5009981.7625840977
            ],
            [
              352623.83995929651,
              5009980.5678498996
            ],
            [
              352624.23078603076,
              5009979.8528440725
            ],
            [
              352624.41625751992,
              5009978.9399883104
            ],
            [
              352624.59984965116,
              5009977.9261225229
            ],
            [
              352624.979408029,
              5009976.6054756735
            ],
            [
              352625.46994678292,
              5009975.7879889263
            ],
            [
              352626.27009292442,
              5009975.2678767033
            ],
            [
              352626.97539151745,
              5009975.1537091555
            ],
            [
              352627.88385725545,
              5009975.0357615473
            ],
            [
              352628.58805009961,
              5009974.8201503064
            ],
            [
              352629.18314973189,
              5009974.2028081296
            ],
            [
              352629.47130275151,
              5009973.3890869152
            ],
            [
              352630.03335534432,
              5009970.9535497567
            ],
            [
              352630.50321337016,
              5009969.0245336303
            ],
            [
              352631.18674107915,
              5009967.6982312659
            ],
            [
              352631.97482966637,
              5009966.5720734624
            ],
            [
              352632.95966591552,
              5009965.1391200777
            ],
            [
              352633.64052506932,
              5009963.7114031184
            ],
            [
              352634.12542577396,
              5009962.5908863386
            ],
            [
              352634.76947924733,
              5009959.1429543765
            ],
            [
              352635.24122448586,
              5009957.3153674472
            ],
            [
              352635.72424585564,
              5009956.0938406577
            ],
            [
              352636.31636821287,
              5009955.2744639367
            ],
            [
              352637.10444903135,
              5009954.1478870548
            ],
            [
              352637.48212813266,
              5009952.7262302544
            ],
            [
              352637.84743318689,
              5009950.5974887619
            ],
            [
              352638.1092675344,
              5009948.3692081422
            ],
            [
              352638.46685885271,
              5009945.6578195784
            ],
            [
              352639.12407573598,
              5009942.9173769802
            ],
            [
              352639.50739292969,
              5009941.7987502664
            ],
            [
              352640.69460719154,
              5009940.3616123525
            ],
            [
              352641.47705779976,
              5009938.9324245797
            ],
            [
              352641.94504444016,
              5009936.9028176665
            ],
            [
              352641.99399847427,
              5009934.0722279185
            ],
            [
              352641.74650842149,
              5009931.6517529003
            ],
            [
              352641.70703435037,
              5009929.5301233176
            ],
            [
              352641.77776697534,
              5009927.9120874926
            ],
            [
              352641.96135924774,
              5009926.8982217824
            ],
            [
              352642.35485462932,
              5009926.2846306674
            ],
            [
              352644.43847717775,
              5009924.1234997632
            ],
            [
              352644.62582814851,
              5009923.311654103
            ],
            [
              352644.61643142841,
              5009922.8066040007
            ],
            [
              352644.40652832744,
              5009922.4063294083
            ],
            [
              352642.74013462028,
              5009919.8097445788
            ],
            [
              352642.51519677945,
              5009918.6013898114
            ],
            [
              352642.70254776289,
              5009917.789544153
            ],
            [
              352643.19872465933,
              5009917.2750875922
            ],
            [
              352643.99996877945,
              5009916.8560000909
            ],
            [
              352644.89825636992,
              5009916.2330170786
            ],
            [
              352645.79167954758,
              5009915.3065703399
            ],
            [
              352646.98078103713,
              5009913.970861692
            ],
            [
              352648.36021079385,
              5009912.0253419885
            ],
            [
              352649.24235793186,
              5009910.4928351529
            ],
            [
              352650.06443291175,
              5009909.0926786931
            ],
            [
              352650.85628032783,
              5009908.168541126
            ],
            [
              352652.35232837981,
              5009907.0292116068
            ],
            [
              352655.34253734211,
              5009904.6491234517
            ],
            [
              352657.93737934768,
              5009902.7820355091
            ],
            [
              352660.02930851147,
              5009901.0253494978
            ],
            [
              352666.79029777524,
              5009894.7349758558
            ],
            [
              352672.45359880017,
              5009889.2738045128
            ],
            [
              352675.93999240448,
              5009886.3796793139
            ],
            [
              352679.84054627171,
              5009883.9826541627
            ],
            [
              352684.74959426193,
              5009881.4662399227
            ],
            [
              352689.060557455,
              5009879.3647139827
            ],
            [
              352692.07630330353,
              5009878.3992000865
            ],
            [
              352695.2038107455,
              5009877.9368317025
            ],
            [
              352697.77214026515,
              5009877.8798229089
            ],
            [
              352699.69473015313,
              5009877.945097344
            ],
            [
              352701.72078291274,
              5009878.1094917841
            ],
            [
              352703.54632930103,
              5009878.3786616921
            ],
            [
              352704.86144558137,
              5009878.3541934388
            ],
            [
              352705.87071328604,
              5009878.2343706507
            ],
            [
              352706.87810165848,
              5009878.0135378586
            ],
            [
              352707.77826848667,
              5009877.4915650645
            ],
            [
              352709.5793835494,
              5009876.4476049421
            ],
            [
              352711.08294877276,
              5009875.7123157624
            ],
            [
              352712.79641699459,
              5009875.3773011286
            ],
            [
              352713.80756400718,
              5009875.3584883548
            ],
            [
              352716.24026737892,
              5009875.6163618769
            ],
            [
              352718.76923279738,
              5009875.6703544715
            ],
            [
              352720.28031530028,
              5009875.3391053099
            ],
            [
              352721.48633065337,
              5009874.9124870794
            ],
            [
              352726.19188672688,
              5009872.298395074
            ],
            [
              352727.79625401896,
              5009871.5612304872
            ],
            [
              352728.90334654565,
              5009871.2374977348
            ],
            [
              352730.01529564318,
              5009871.2168095103
            ],
            [
              352731.43121395534,
              5009871.1904658265
            ],
            [
              352734.06176280353,
              5009871.2425684538
            ],
            [
              352735.27153676894,
              5009871.0179702425
            ],
            [
              352736.07466006023,
              5009870.6998929586
            ],
            [
              352738.94550491363,
              5009869.2146601854
            ],
            [
              352740.04805734142,
              5009868.6889220029
            ],
            [
              352741.05356629129,
              5009868.3670792691
            ],
            [
              352742.16629676096,
              5009868.3463765197
            ],
            [
              352742.76891369844,
              5009868.1330746952
            ],
            [
              352743.26884917991,
              5009867.8206383437
            ],
            [
              352743.8601901533,
              5009867.0012765713
            ],
            [
              352745.05117082072,
              5009865.7665784638
            ],
            [
              352745.95023185498,
              5009865.1431621006
            ],
            [
              352747.15436780505,
              5009864.6155339442
            ],
            [
              352748.56198732945,
              5009864.1851648632
            ],
            [
              352751.28660227958,
              5009863.8313375879
            ],
            [
              352753.4040602246,
              5009863.4888066826
            ],
            [
              352754.31064634252,
              5009863.2698494233
            ],
            [
              352755.51478226669,
              5009862.7422212893
            ],
            [
              352756.41494899004,
              5009862.220248607
            ],
            [
              352759.21217612707,
              5009860.3493960807
            ],
            [
              352760.41066626611,
              5009859.5183188748
            ],
            [
              352762.12225493352,
              5009859.0822943822
            ],
            [
              352763.13340180216,
              5009859.0634816587
            ],
            [
              352764.15018664248,
              5009859.3476988915
            ],
            [
              352764.96834447316,
              5009859.8377015479
            ],
            [
              352766.19128141005,
              5009860.3205924248
            ],
            [
              352767.40481391951,
              5009860.2980142431
            ],
            [
              352768.71538989717,
              5009860.0715406341
            ],
            [
              352769.91685724008,
              5009859.4424979575
            ],
            [
              352772.2130509222,
              5009857.783057319
            ],
            [
              352773.92197879759,
              5009857.2460374078
            ],
            [
              352774.73715936736,
              5009857.5340055525
            ],
            [
              352774.85111675347,
              5009858.2391999736
            ],
            [
              352774.86615134747,
              5009859.0472798375
            ],
            [
              352774.80106419645,
              5009860.9687641049
            ],
            [
              352774.92441821651,
              5009862.179008441
            ],
            [
              352775.44314690988,
              5009862.8766719531
            ],
            [
              352776.77063678479,
              5009863.5592881804
            ],
            [
              352777.9879279093,
              5009863.7387299556
            ],
            [
              352779.30038316821,
              5009863.6132663209
            ],
            [
              352780.30025403312,
              5009862.9883936979
            ],
            [
              352781.39340971137,
              5009861.9576056926
            ],
            [
              352782.79429321201,
              5009861.1231822055
            ],
            [
              352784.10298980435,
              5009860.795698625
            ],
            [
              352785.72505218693,
              5009860.9676094977
            ],
            [
              352786.84827705025,
              5009861.5529811857
            ],
            [
              352788.58993478649,
              5009862.7331163855
            ],
            [
              352789.71503897023,
              5009863.4194980441
            ],
            [
              352790.83074652246,
              5009863.6008298062
            ],
            [
              352792.23914728285,
              5009863.1704462487
            ],
            [
              352793.34357887402,
              5009862.7457181448
            ],
            [
              352794.40044324083,
              5009861.7810120806
            ],
            [
              352795.59518240293,
              5009860.7483341265
            ],
            [
              352796.59317390068,
              5009860.02245156
            ],
            [
              352797.70136413095,
              5009859.7997434251
            ],
            [
              352798.41308179795,
              5009859.988591562
            ],
            [
              352798.6229847307,
              5009860.3888660129
            ],
            [
              352798.64177797834,
              5009861.3989657732
            ],
            [
              352798.45819339243,
              5009862.4132501148
            ],
            [
              352798.48074529751,
              5009863.6253698291
            ],
            [
              352798.69440688641,
              5009864.2276642304
            ],
            [
              352799.10293680761,
              5009864.4221532717
            ],
            [
              352799.70931229816,
              5009864.4108714452
            ],
            [
              352800.71670040459,
              5009864.1900387527
            ],
            [
              352801.821131968,
              5009863.76531066
            ],
            [
              352803.12497192173,
              5009863.1347826226
            ],
            [
              352806.93333761662,
              5009861.2446984909
            ],
            [
              352811.24728444131,
              5009859.3456271654
            ],
            [
              352816.47345515213,
              5009857.5306286002
            ],
            [
              352820.39201945177,
              5009856.1437189952
            ],
            [
              352821.89558423572,
              5009855.4084301405
            ],
            [
              352822.89169634634,
              5009854.5815376807
            ],
            [
              352824.28882105084,
              5009853.5450943895
            ],
            [
              352825.19086693303,
              5009853.1241318043
            ],
            [
              352826.19825497252,
              5009852.9032991668
            ],
            [
              352827.40802862775,
              5009852.6787010804
            ],
            [
              352830.03669741791,
              5009852.6297938703
            ],
            [
              352831.54777945543,
              5009852.298544921
            ],
            [
              352832.14929833525,
              5009851.9842186775
            ],
            [
              352832.74439783819,
              5009851.3668770771
            ],
            [
              352833.34324818576,
              5009850.9511362817
            ],
            [
              352834.14903200074,
              5009850.7340545626
            ],
            [
              352835.05295718176,
              5009850.4141019657
            ],
            [
              352836.96693110274,
              5009849.9743121695
            ],
            [
              352838.06948324689,
              5009849.4485742012
            ],
            [
              352838.86884767661,
              5009848.9284771327
            ],
            [
              352839.65881550108,
              5009847.9033302488
            ],
            [
              352840.74147652736,
              5009846.2664681496
            ],
            [
              352841.53519519814,
              5009845.4429220743
            ],
            [
              352842.43614313699,
              5009844.920935032
            ],
            [
              352843.74296019779,
              5009844.4924416076
            ],
            [
              352846.1636053003,
              5009844.1442700196
            ],
            [
              352849.35376281518,
              5009843.9402668411
            ],
            [
              352850.46006576874,
              5009843.6161297048
            ],
            [
              352852.07382914051,
              5009843.3840153189
            ],
            [
              352852.58128176286,
              5009843.4756189436
            ],
            [
              352852.79119244235,
              5009843.8763124719
            ],
            [
              352852.80058903963,
              5009844.3813622734
            ],
            [
              352852.8137442774,
              5009845.0884319963
            ],
            [
              352852.51995317737,
              5009845.5991226928
            ],
            [
              352852.02753511333,
              5009846.3155987533
            ],
            [
              352851.33273144811,
              5009847.0358402636
            ],
            [
              352851.34400737053,
              5009847.6419000281
            ],
            [
              352851.55391025642,
              5009848.042174425
            ],
            [
              352852.36642990151,
              5009848.2291471064
            ],
            [
              352853.68076420453,
              5009848.2046935186
            ],
            [
              352855.79148566508,
              5009847.4581084382
            ],
            [
              352857.89578774275,
              5009846.4085080316
            ],
            [
              352859.49153929023,
              5009845.1662795171
            ],
            [
              352860.88412382541,
              5009843.9278309997
            ],
            [
              352862.06569978123,
              5009842.1876644772
            ],
            [
              352862.64576470124,
              5009840.7622432858
            ],
            [
              352862.73043434171,
              5009839.8512636833
            ],
            [
              352862.4020263069,
              5009838.5433705365
            ],
            [
              352861.98676061718,
              5009837.9448271301
            ],
            [
              352861.36832791718,
              5009837.350063703
            ],
            [
              352860.34966406593,
              5009836.9648365239
            ],
            [
              352859.63121068274,
              5009836.3719340013
            ],
            [
              352859.61993478023,
              5009835.7658742508
            ],
            [
              352859.91184657259,
              5009835.1541736145
            ],
            [
              352861.80060005205,
              5009833.4008498974
            ],
            [
              352862.69591000798,
              5009832.575833044
            ],
            [
              352863.29100949259,
              5009831.9584915321
            ],
            [
              352863.67510821525,
              5009830.8398511335
            ],
            [
              352864.0422931051,
              5009828.8121211212
            ],
            [
              352864.62047096575,
              5009827.28527088
            ],
            [
              352865.19865662977,
              5009825.7588397702
            ],
            [
              352866.18913074658,
              5009824.6289176289
            ],
            [
              352867.19088074379,
              5009824.1050552316
            ],
            [
              352871.00676319998,
              5009822.6190117029
            ],
            [
              352875.8270641198,
              5009820.7105203783
            ],
            [
              352879.6391956695,
              5009819.0228761518
            ],
            [
              352882.54661289061,
              5009817.6547798356
            ],
            [
              352887.66258410289,
              5009815.3366079777
            ],
            [
              352890.87695535651,
              5009814.1653098855
            ],
            [
              352896.20955731667,
              5009812.6510477019
            ],
            [
              352901.2389793417,
              5009811.1428455571
            ],
            [
              352904.86266165966,
              5009810.1660220623
            ],
            [
              352907.88482515462,
              5009809.5035246816
            ],
            [
              352911.87794130231,
              5009808.972643191
            ],
            [
              352915.30785307771,
              5009808.5046494165
            ],
            [
              352917.12102453125,
              5009808.0667354027
            ],
            [
              352918.2254557914,
              5009807.6420076303
            ],
            [
              352920.43322040624,
              5009806.691527619
            ],
            [
              352922.5393937247,
              5009805.7425184688
            ],
            [
              352924.15205888922,
              5009805.4093798371
            ],
            [
              352925.66799707169,
              5009805.3811755385
            ],
            [
              352927.49244431936,
              5009805.5493211849
            ],
            [
              352928.90758041228,
              5009805.5229923287
            ],
            [
              352930.11923304613,
              5009805.3994044149
            ],
            [
              352931.12474150263,
              5009805.0775620481
            ],
            [
              352932.22917272203,
              5009804.6528343037
            ],
            [
              352933.22904325119,
              5009804.0279621305
            ],
            [
              352935.4236526335,
              5009802.3704126291
            ],
            [
              352943.9056304915,
              5009796.1494908314
            ],
            [
              352953.40814328584,
              5009790.4143873621
            ],
            [
              352957.25128221285,
              5009787.8725262564
            ],
            [
              352958.65028587804,
              5009786.9370935736
            ],
            [
              352959.44025361567,
              5009785.911947187
            ],
            [
              352960.02595651173,
              5009784.7895562844
            ],
            [
              352961.00891328795,
              5009783.2555948598
            ],
            [
              352962.40227125044,
              5009782.0167132877
            ],
            [
              352964.70332051738,
              5009780.6603184175
            ],
            [
              352967.41289944696,
              5009779.4984126166
            ],
            [
              352970.62429289374,
              5009778.1250807438
            ],
            [
              352973.23979771131,
              5009777.3686855258
            ],
            [
              352976.16679650766,
              5009777.0110937711
            ],
            [
              352978.0875055255,
              5009776.9753588047
            ],
            [
              352979.70502694306,
              5009776.9452646663
            ],
            [
              352981.93534283614,
              5009777.2069040975
            ],
            [
              352983.86059183016,
              5009777.3731744345
            ],
            [
              352985.77566290833,
              5009777.0344097028
            ],
            [
              352986.98355675425,
              5009776.7088020677
            ],
            [
              352988.28849405586,
              5009776.1792991636
            ],
            [
              352989.79205828026,
              5009775.4440110214
            ],
            [
              352991.49049083079,
              5009774.3009178061
            ],
            [
              352993.08733990975,
              5009773.1597145703
            ],
            [
              352994.58337913261,
              5009772.0199676463
            ],
            [
              352996.68846200645,
              5009770.9703535866
            ],
            [
              352999.80578893673,
              5009770.0029515484
            ],
            [
              353004.89739442145,
              5009768.8960959669
            ],
            [
              353009.23764942837,
              5009768.4111657096
            ],
            [
              353012.37376921228,
              5009768.4538628701
            ],
            [
              353014.50516213442,
              5009768.8183876742
            ],
            [
              353016.94647837355,
              5009769.5813258355
            ],
            [
              353018.57903398311,
              5009770.3593110358
            ],
            [
              353019.60521447932,
              5009771.1485778829
            ],
            [
              353020.33385310898,
              5009772.2469344158
            ],
            [
              353021.06624253996,
              5009773.5468916399
            ],
            [
              353021.79299407959,
              5009774.5438191248
            ],
            [
              353023.02156007988,
              5009775.3293205546
            ],
            [
              353024.03646478435,
              5009775.5125279129
            ],
            [
              353025.24733568303,
              5009775.3889546795
            ],
            [
              353026.65573559579,
              5009774.9585717777
            ],
            [
              353027.65372660814,
              5009774.2326899525
            ],
            [
              353029.24869622261,
              5009772.9904769296
            ],
            [
              353032.24078215193,
              5009770.7114024619
            ],
            [
              353034.34586480027,
              5009769.6617885279
            ],
            [
              353035.75348327262,
              5009769.2314202022
            ],
            [
              353037.16564174357,
              5009769.0030571558
            ],
            [
              353038.47997534845,
              5009768.9786038892
            ],
            [
              353039.9007488768,
              5009769.2553049112
            ],
            [
              353041.21398458531,
              5009769.1298272004
            ],
            [
              353042.4248554263,
              5009769.0062539969
            ],
            [
              353043.3754074772,
              5009768.6715982817
            ],
            [
              353044.4787404972,
              5009768.1458463529
            ],
            [
              353045.69227203348,
              5009768.1232685205
            ],
            [
              353046.60371392057,
              5009768.207355951
            ],
            [
              353047.31543102273,
              5009768.3962041466
            ],
            [
              353048.23517929856,
              5009768.8847357808
            ],
            [
              353049.7518983492,
              5009768.8565171203
            ],
            [
              353050.75364010123,
              5009768.3322360376
            ],
            [
              353051.14635389147,
              5009767.7186603695
            ],
            [
              353051.32994642318,
              5009766.704795951
            ],
            [
              353051.29236052104,
              5009764.6845978983
            ],
            [
              353051.5767473033,
              5009763.6684389273
            ],
            [
              353052.37047348573,
              5009762.8453126783
            ],
            [
              353053.17171684513,
              5009762.4262260394
            ],
            [
              353054.08206082793,
              5009762.4092890415
            ],
            [
              353054.9980426908,
              5009762.695381755
            ],
            [
              353056.02234376589,
              5009763.3836386409
            ],
            [
              353056.95446566358,
              5009764.5792541215
            ],
            [
              353058.1924279895,
              5009765.8698050082
            ],
            [
              353059.11780632037,
              5009766.6609472092
            ],
            [
              353059.9340840223,
              5009767.0499397665
            ],
            [
              353060.94898861367,
              5009767.2331471341
            ],
            [
              353061.8566718683,
              5009767.1152147641
            ],
            [
              353062.86030068196,
              5009766.6923627341
            ],
            [
              353064.16147912137,
              5009765.960840188
            ],
            [
              353066.07545188011,
              5009765.5210511619
            ],
            [
              353067.29274193133,
              5009765.7004931355
            ],
            [
              353068.11089890724,
              5009766.1904955879
            ],
            [
              353069.25667423412,
              5009767.9879858773
            ],
            [
              353070.20085329824,
              5009769.7896461431
            ],
            [
              353071.43505700288,
              5009770.8781771697
            ],
            [
              353072.2513346803,
              5009771.2671697047
            ],
            [
              353073.2606013476,
              5009771.1473473646
            ],
            [
              353074.26423012215,
              5009770.7244953346
            ],
            [
              353075.26410030184,
              5009770.099623519
            ],
            [
              353076.25457400008,
              5009768.9697022298
            ],
            [
              353077.33832475031,
              5009767.4334468124
            ],
            [
              353078.42662330304,
              5009766.0996157844
            ],
            [
              353079.62433894939,
              5009765.268973344
            ],
            [
              353081.03461794835,
              5009764.9396004481
            ],
            [
              353082.34895138553,
              5009764.9151472012
            ],
            [
              353083.97586878849,
              5009765.3901026007
            ],
            [
              353085.70249032817,
              5009765.762158134
            ],
            [
              353086.9197803096,
              5009765.9416000964
            ],
            [
              353088.94207214849,
              5009765.90397524
            ],
            [
              353090.55395516218,
              5009765.5708514955
            ],
            [
              353092.52134445118,
              5009765.1451625228
            ],
            [
              353093.8420971391,
              5009765.4237244073
            ],
            [
              353094.55304052873,
              5009765.6130062472
            ],
            [
              353095.27603329968,
              5009766.4079137566
            ],
            [
              353096.00090536528,
              5009767.3038311526
            ],
            [
              353096.72953602421,
              5009768.401768324
            ],
            [
              353097.79331000138,
              5009771.2116519036
            ],
            [
              353099.25354852033,
              5009773.6095605278
            ],
            [
              353099.97466200218,
              5009774.3034581216
            ],
            [
              353100.8868851568,
              5009774.3875309844
            ],
            [
              353101.58278636081,
              5009773.7683145748
            ],
            [
              353102.07708351815,
              5009773.1528490242
            ],
            [
              353102.35850084852,
              5009771.9350750092
            ],
            [
              353102.83964293363,
              5009770.6125402479
            ],
            [
              353103.11463317723,
              5009769.0913319886
            ],
            [
              353103.49795042776,
              5009767.9727069782
            ],
            [
              353103.99788547837,
              5009767.6602710979
            ],
            [
              353104.60504184739,
              5009767.648974915
            ],
            [
              353105.41756085225,
              5009767.8359476486
            ],
            [
              353106.1386743093,
              5009768.5298452433
            ],
            [
              353107.08958904777,
              5009770.7355593992
            ],
            [
              353107.92168242257,
              5009771.9326164434
            ],
            [
              353108.73983930994,
              5009772.4226188241
            ],
            [
              353109.85178718757,
              5009772.4019309487
            ],
            [
              353110.5503490846,
              5009771.8837099029
            ],
            [
              353111.84401014977,
              5009770.748147903
            ],
            [
              353112.94844083261,
              5009770.323420492
            ],
            [
              353114.46139320754,
              5009770.0927629368
            ],
            [
              353118.92954820284,
              5009771.0205003433
            ],
            [
              353123.00044164056,
              5009772.3593889177
            ],
            [
              353124.33168858726,
              5009773.2440245757
            ],
            [
              353124.85793375928,
              5009774.3457270404
            ],
            [
              353125.38230743451,
              5009775.3468387499
            ],
            [
              353125.61100304587,
              5009776.7572116796
            ],
            [
              353125.74187383166,
              5009778.371494337
            ],
            [
              353126.16167910589,
              5009779.1720425813
            ],
            [
              353126.67101060605,
              5009779.3646561103
            ],
            [
              353127.07500008057,
              5009779.3571398491
            ],
            [
              353127.47601236874,
              5009779.147589284
            ],
            [
              353127.87514535867,
              5009778.8370288452
            ],
            [
              353128.67262992688,
              5009778.2159225009
            ],
            [
              353129.26209115837,
              5009777.295551924
            ],
            [
              353130.35226880613,
              5009776.0627309447
            ],
            [
              353131.95099653531,
              5009775.0225380417
            ],
            [
              353133.05917794543,
              5009774.7994112754
            ],
            [
              353135.18117363838,
              5009774.6588865248
            ],
            [
              353136.29312143143,
              5009774.6381986439
            ],
            [
              353137.29862931505,
              5009774.3163565621
            ],
            [
              353137.897487026,
              5009773.9010353992
            ],
            [
              353138.90299490682,
              5009773.5791933192
            ],
            [
              353139.61095322581,
              5009773.5660216985
            ],
            [
              353140.2181095329,
              5009773.5547255063
            ],
            [
              353140.82824302051,
              5009773.745463605
            ],
            [
              353141.54183922661,
              5009774.0353216128
            ],
            [
              353142.15573130659,
              5009774.4280794617
            ],
            [
              353144.20246148139,
              5009775.7040020656
            ],
            [
              353145.79212497413,
              5009776.2773410911
            ],
            [
              353147.20913947129,
              5009776.352022252
            ],
            [
              353148.41703275131,
              5009776.0264147734
            ],
            [
              353149.3228364762,
              5009775.8074725391
            ],
            [
              353150.12110241083,
              5009775.1863517063
            ],
            [
              353153.70264848141,
              5009771.9869075222
            ],
            [
              353155.99805862567,
              5009770.3274838673
            ],
            [
              353156.90088515298,
              5009769.906507371
            ],
            [
              353157.70400755585,
              5009769.5884307157
            ],
            [
              353160.02384852263,
              5009769.2421354102
            ],
            [
              353161.130158349,
              5009768.9184179408
            ],
            [
              353161.830599443,
              5009768.5012068478
            ],
            [
              353162.22785310762,
              5009768.0896365819
            ],
            [
              353163.31536218134,
              5009766.7554012891
            ],
            [
              353164.50822105928,
              5009765.6217149086
            ],
            [
              353166.11446583696,
              5009764.9855616353
            ],
            [
              353167.12185293849,
              5009764.7647294775
            ],
            [
              353168.0359552076,
              5009764.9498122195
            ],
            [
              353169.05461806717,
              5009765.335039272
            ],
            [
              353172.73578221136,
              5009767.4899565671
            ],
            [
              353174.06890824798,
              5009768.4756019842
            ],
            [
              353174.38415284728,
              5009769.076005823
            ],
            [
              353174.39542861923,
              5009769.6820650222
            ],
            [
              353174.00381285744,
              5009770.3966648756
            ],
            [
              353173.51139509416,
              5009771.1131401602
            ],
            [
              353172.92757180991,
              5009772.3365402101
            ],
            [
              353172.74022856209,
              5009773.1488036672
            ],
            [
              353172.95013116469,
              5009773.549077739
            ],
            [
              353173.76640860253,
              5009773.9380701538
            ],
            [
              353174.98369827674,
              5009774.1175120454
            ],
            [
              353176.19081005169,
              5009773.7919191401
            ],
            [
              353177.5973300538,
              5009773.2605265751
            ],
            [
              353180.20907471894,
              5009772.3021121249
            ],
            [
              353182.32167364407,
              5009771.6565380925
            ],
            [
              353184.14345880813,
              5009771.7236884721
            ],
            [
              353185.66581514786,
              5009771.9984994009
            ],
            [
              353186.48397964792,
              5009772.4889207995
            ],
            [
              353187.50640105334,
              5009773.0761675322
            ],
            [
              353188.31891986454,
              5009773.2631402109
            ],
            [
              353189.12768008036,
              5009773.2480931636
            ],
            [
              353190.23665051634,
              5009773.025371029
            ],
            [
              353191.34296024177,
              5009772.7016535737
            ],
            [
              353191.94181786128,
              5009772.2863324797
            ],
            [
              353192.86199999374,
              5009771.243670335
            ],
            [
              353194.8354217321,
              5009768.5793699613
            ],
            [
              353196.02374049608,
              5009767.2436784972
            ],
            [
              353197.42650183861,
              5009766.5102662705
            ],
            [
              353198.43013025768,
              5009766.087414409
            ],
            [
              353200.15299263562,
              5009766.2574501019
            ],
            [
              353201.16977610689,
              5009766.5416672565
            ],
            [
              353202.19219745847,
              5009767.1289139809
            ],
            [
              353203.52344408422,
              5009768.0135494582
            ],
            [
              353205.99483549985,
              5009770.3930640556
            ],
            [
              353207.22528012615,
              5009771.2795749363
            ],
            [
              353208.4425696797,
              5009771.4590168158
            ],
            [
              353209.38815795624,
              5009771.3617621846
            ],
            [
              353210.90487629652,
              5009771.3335435325
            ],
            [
              353212.01870314038,
              5009771.4138655216
            ],
            [
              353212.8331011876,
              5009771.7018480469
            ],
            [
              353213.24538915552,
              5009772.0983566754
            ],
            [
              353213.86491899315,
              5009772.794144012
            ],
            [
              353214.98140653432,
              5009772.9754613126
            ],
            [
              353216.19493746915,
              5009772.9528834764
            ],
            [
              353217.92077694222,
              5009773.3249533717
            ],
            [
              353218.94131895929,
              5009773.8111901972
            ],
            [
              353220.28572841204,
              5009775.403313701
            ],
            [
              353221.74298925098,
              5009777.5991872121
            ],
            [
              353223.80851194204,
              5009779.8852080246
            ],
            [
              353226.16593841836,
              5009781.5591097418
            ],
            [
              353228.81229927525,
              5009782.4192772601
            ],
            [
              353231.45224080555,
              5009782.9764297567
            ],
            [
              353234.69557985727,
              5009783.3202663418
            ],
            [
              353238.03032436693,
              5009783.157178252
            ],
            [
              353242.7708071571,
              5009782.4627122013
            ],
            [
              353246.18834199588,
              5009781.2876357036
            ],
            [
              353248.39234598941,
              5009780.1351370877
            ],
            [
              353249.58708382695,
              5009779.1024607839
            ],
            [
              353250.26794254576,
              5009777.6747467499
            ],
            [
              353250.93643540127,
              5009775.5403684061
            ],
            [
              353251.39313862048,
              5009772.9042871175
            ],
            [
              353252.05223502783,
              5009770.2648595721
            ],
            [
              353252.98256300547,
              5009765.902204752
            ],
            [
              353254.13219003752,
              5009762.4448731532
            ],
            [
              353254.99852835323,
              5009760.1047243262
            ],
            [
              353256.36181655171,
              5009757.2496864367
            ],
            [
              353257.70208165882,
              5009755.6780524626
            ],
            [
              353259.78758866474,
              5009753.6183554353
            ],
            [
              353261.98782628629,
              5009752.2634182321
            ],
            [
              353263.7945767091,
              5009751.5224899817
            ],
            [
              353265.50428372575,
              5009750.9854568373
            ],
            [
              353267.21774931421,
              5009750.6504433779
            ],
            [
              353269.13923812116,
              5009750.614694058
            ],
            [
              353270.65517478989,
              5009750.5864900379
            ],
            [
              353272.57478428818,
              5009750.4497308796
            ],
            [
              353274.59143717133,
              5009750.1090766266
            ],
            [
              353275.79745074228,
              5009749.6824595295
            ],
            [
              353277.50151983782,
              5009748.8423969066
            ],
            [
              353278.80833537126,
              5009748.413904395
            ],
            [
              353280.12266805436,
              5009748.3894512272
            ],
            [
              353281.63938608515,
              5009748.3612326812
            ],
            [
              353284.472316064,
              5009748.4095708057
            ],
            [
              353285.98527549946,
              5009748.1793325897
            ],
            [
              353286.8881017146,
              5009747.7583563151
            ],
            [
              353287.68745746149,
              5009747.237841038
            ],
            [
              353289.88394415373,
              5009745.6813034499
            ],
            [
              353290.58360360371,
              5009745.2641070997
            ],
            [
              353291.69633230596,
              5009745.2434047889
            ],
            [
              353292.81015888235,
              5009745.3237268478
            ],
            [
              353293.72426080791,
              5009745.5088095758
            ],
            [
              353294.34191115788,
              5009746.1035869652
            ],
            [
              353295.06302417343,
              5009746.7974842247
            ],
            [
              353296.70874122455,
              5009748.2829568554
            ],
            [
              353298.03810814687,
              5009749.0665823324
            ],
            [
              353299.35698074405,
              5009749.2441342864
            ],
            [
              353300.36624675384,
              5009749.1243120935
            ],
            [
              353301.47443543666,
              5009748.9016046468
            ],
            [
              353302.67669033812,
              5009748.272967929
            ],
            [
              353304.58047614602,
              5009747.3277255818
            ],
            [
              353305.88729157968,
              5009746.8992331093
            ],
            [
              353306.99626165751,
              5009746.6765111368
            ],
            [
              353308.20603369438,
              5009746.4519137461
            ],
            [
              353309.1080784428,
              5009746.030952041
            ],
            [
              353309.8980455022,
              5009745.0058067972
            ],
            [
              353311.51823087904,
              5009742.9342230735
            ],
            [
              353313.11319149839,
              5009741.6915920954
            ],
            [
              353314.11118175281,
              5009740.9657109734
            ],
            [
              353315.9292076425,
              5009740.8308418458
            ],
            [
              353317.14461751055,
              5009740.909273956
            ],
            [
              353318.05981724389,
              5009741.1953810453
            ],
            [
              353319.18758794578,
              5009741.9831764679
            ],
            [
              353321.74551962444,
              5009743.5523027824
            ],
            [
              353323.48529495642,
              5009744.6314269369
            ],
            [
              353325.32399295282,
              5009745.6076658377
            ],
            [
              353326.74476527912,
              5009745.8843667312
            ],
            [
              353329.37609097298,
              5009745.936455708
            ],
            [
              353332.74314565992,
              5009745.5773856808
            ],
            [
              353335.26725008088,
              5009745.3283351632
            ],
            [
              353336.79148501757,
              5009745.7041559108
            ],
            [
              353338.83632683975,
              5009746.8786490252
            ],
            [
              353341.08465184947,
              5009748.1504011014
            ],
            [
              353344.2422221874,
              5009749.3041918641
            ],
            [
              353346.78512720222,
              5009750.0656586131
            ],
            [
              353349.01090070716,
              5009750.1252928581
            ],
            [
              353350.52385985682,
              5009749.8950546812
            ],
            [
              353352.13087741093,
              5009749.2584681613
            ],
            [
              353354.24081462651,
              5009748.5118993213
            ],
            [
              353356.8582036845,
              5009747.8569341237
            ],
            [
              353359.07536209625,
              5009747.4115047809
            ],
            [
              353361.59946632205,
              5009747.162454268
            ],
            [
              353364.12732910417,
              5009747.1154233823
            ],
            [
              353366.75787310273,
              5009747.167526884
            ],
            [
              353369.99369369406,
              5009747.1073244428
            ],
            [
              353372.01598387328,
              5009747.0696997344
            ],
            [
              353374.53820871696,
              5009746.7196394252
            ],
            [
              353377.66492873314,
              5009746.2572877174
            ],
            [
              353384.98504584824,
              5009744.9794168547
            ],
            [
              353398.38612849108,
              5009742.2035514079
            ],
            [
              353401.81227694434,
              5009741.5335393846
            ],
            [
              353404.22430321365,
              5009740.6803055713
            ],
            [
              353405.93025098153,
              5009739.941253107
            ],
            [
              353407.32847154903,
              5009739.0058363834
            ],
            [
              353408.82262980723,
              5009737.7650811914
            ],
            [
              353410.41086206707,
              5009736.118815992
            ],
            [
              353416.14754755166,
              5009729.1406222181
            ],
            [
              353417.63606796187,
              5009727.5968377134
            ],
            [
              353418.63781650708,
              5009727.0729764448
            ],
            [
              353420.04543346475,
              5009726.6426087795
            ],
            [
              353422.05456838437,
              5009725.8979156418
            ],
            [
              353423.8594387449,
              5009725.0559780812
            ],
            [
              353425.25844069145,
              5009724.1205469305
            ],
            [
              353426.34594910993,
              5009722.7863128008
            ],
            [
              353427.23374075128,
              5009721.5572584243
            ],
            [
              353428.31186057633,
              5009719.7183944779
            ],
            [
              353429.19401438755,
              5009718.1863107402
            ],
            [
              353430.08101682999,
              5009716.9568517981
            ],
            [
              353431.07712752133,
              5009716.1299612029
            ],
            [
              353432.52535376739,
              5009715.1093404526
            ],
            [
              353433.92545357207,
              5009714.2749336902
            ],
            [
              353435.22287217207,
              5009713.3413925609
            ],
            [
              353436.81862153485,
              5009712.0991667593
            ],
            [
              353441.19201137056,
              5009707.9755909285
            ],
            [
              353445.4533236323,
              5009703.2478318894
            ],
            [
              353449.69960172649,
              5009697.7119946787
            ],
            [
              353450.9884053609,
              5009696.2733901832
            ],
            [
              353451.88292491279,
              5009695.447970517
            ],
            [
              353453.18410203862,
              5009694.7164490735
            ],
            [
              353454.979575957,
              5009693.3694628319
            ],
            [
              353456.47186270118,
              5009692.0281173522
            ],
            [
              353457.85770191543,
              5009690.3851988092
            ],
            [
              353458.94333889114,
              5009688.9503742559
            ],
            [
              353460.71704298147,
              5009686.391256134
            ],
            [
              353463.17536431708,
              5009682.6064446419
            ],
            [
              353464.26288057613,
              5009681.2726299195
            ],
            [
              353467.33697259711,
              5009677.9815859366
            ],
            [
              353471.01446181833,
              5009674.4772264492
            ],
            [
              353475.04650652449,
              5009671.2073524641
            ],
            [
              353477.84184961935,
              5009669.2354956437
            ],
            [
              353480.84332866961,
              5009667.4614739511
            ],
            [
              353485.44056481228,
              5009664.4456460122
            ],
            [
              353488.13995452947,
              5009662.7782894373
            ],
            [
              353490.83637491899,
              5009660.9093179284
            ],
            [
              353493.32665154617,
              5009658.8420922272
            ],
            [
              353496.01742633776,
              5009656.669672329
            ],
            [
              353498.10481176147,
              5009654.710987106
            ],
            [
              353500.19297079503,
              5009652.7518682424
            ],
            [
              353503.574016214,
              5009649.657622817
            ],
            [
              353506.65452737088,
              5009646.6695942637
            ],
            [
              353509.43859481375,
              5009644.0916792089
            ],
            [
              353511.82728029648,
              5009642.0259245392
            ],
            [
              353514.12080943555,
              5009640.265493717
            ],
            [
              353515.72031721071,
              5009639.2252880754
            ],
            [
              353519.97036705812,
              5009636.9592906618
            ],
            [
              353525.86689288059,
              5009633.1105135279
            ],
            [
              353532.15613214037,
              5009628.648161876
            ],
            [
              353537.64037044154,
              5009624.4028765867
            ],
            [
              353542.62468195573,
              5009620.4704458248
            ],
            [
              353546.60535805655,
              5009616.9604470395
            ],
            [
              353550.28018640232,
              5009613.3550937911
            ],
            [
              353556.86392339453,
              5009606.4873469379
            ],
            [
              353565.7028974499,
              5009597.7336814012
            ],
            [
              353570.17974957096,
              5009593.7092284113
            ],
            [
              353573.04957706732,
              5009590.3209417574
            ],
            [
              353577.00394378504,
              5009585.3968071388
            ],
            [
              353578.98301028932,
              5009583.035959186
            ],
            [
              353579.86515652994,
              5009581.5034573404
            ],
            [
              353580.83307856251,
              5009579.1614212748
            ],
            [
              353581.60613237502,
              5009577.2271894915
            ],
            [
              353582.46948627877,
              5009574.6845901934
            ],
            [
              353583.62772891286,
              5009571.7323258156
            ],
            [
              353584.20293746097,
              5009570.003864293
            ],
            [
              353585.32133058965,
              5009568.1868931232
            ],
            [
              353586.00672940462,
              5009566.9611858577
            ],
            [
              353587.19394978805,
              5009565.5244723242
            ],
            [
              353592.065394787,
              5009560.9874552842
            ],
            [
              353593.35607769591,
              5009559.6498616394
            ],
            [
              353593.93802180135,
              5009558.3254537014
            ],
            [
              353594.22428798157,
              5009557.410306247
            ],
            [
              353594.59711141337,
              5009555.6856100624
            ],
            [
              353595.52744120773,
              5009551.3229601542
            ],
            [
              353596.20079108147,
              5009549.4916281579
            ],
            [
              353596.98590180225,
              5009548.163440451
            ],
            [
              353597.67803616292,
              5009547.3417867748
            ],
            [
              353598.37659741373,
              5009546.8235669276
            ],
            [
              353599.28130228742,
              5009546.503601308
            ],
            [
              353600.09006155003,
              5009546.4885548158
            ],
            [
              353601.00040409976,
              5009546.471618427
            ],
            [
              353602.82406623522,
              5009546.6397796692
            ],
            [
              353604.64396990777,
              5009546.6059214259
            ],
            [
              353605.55165180884,
              5009546.4879898354
            ],
            [
              353606.25021304452,
              5009545.9697699957
            ],
            [
              353606.54400395299,
              5009545.4590806747
            ],
            [
              353606.83669704461,
              5009544.8473670743
            ],
            [
              353607.01762930892,
              5009543.7325092042
            ],
            [
              353607.9423215476,
              5009539.0668302672
            ],
            [
              353608.31702428148,
              5009537.4431439117
            ],
            [
              353608.8056756694,
              5009536.5242312588
            ],
            [
              353609.5023576903,
              5009535.9050017064
            ],
            [
              353610.10497331934,
              5009535.6917010108
            ],
            [
              353611.42118398024,
              5009535.7682584282
            ],
            [
              353612.94541746838,
              5009536.14407981
            ],
            [
              353614.6645192139,
              5009536.1120969802
            ],
            [
              353615.26979546709,
              5009535.9997914871
            ],
            [
              353615.56734482996,
              5009535.6911216658
            ],
            [
              353615.86113574466,
              5009535.1804323671
            ],
            [
              353616.15116821404,
              5009534.4677235922
            ],
            [
              353616.43368379102,
              5009533.3509758655
            ],
            [
              353617.2120740441,
              5009530.443094098
            ],
            [
              353617.86928460631,
              5009527.7022409504
            ],
            [
              353618.52273674699,
              5009524.7593683377
            ],
            [
              353619.69680265611,
              5009522.6155869262
            ],
            [
              353621.28049513383,
              5009520.7673187908
            ],
            [
              353623.1737859096,
              5009519.2160056271
            ],
            [
              353624.97677650984,
              5009518.2730599111
            ],
            [
              353627.38614115037,
              5009517.3188330224
            ],
            [
              353628.7889012766,
              5009516.5854227385
            ],
            [
              353630.19166139857,
              5009515.8520124601
            ],
            [
              353631.18965121632,
              5009515.1261326652
            ],
            [
              353632.18387480086,
              5009514.1978142792
            ],
            [
              353632.97196259996,
              5009513.0716607952
            ],
            [
              353634.35326991981,
              5009511.2271580137
            ],
            [
              353635.61951526167,
              5009508.5760190692
            ],
            [
              353636.99142652057,
              5009506.2264676578
            ],
            [
              353638.27192419517,
              5009504.3834211435
            ],
            [
              353638.86216670513,
              5009503.4630378634
            ],
            [
              353639.54491281859,
              5009502.1367548453
            ],
            [
              353640.54102342512,
              5009501.3098653797
            ],
            [
              353641.54277167033,
              5009500.7860051086
            ],
            [
              353643.04445442738,
              5009499.9497098438
            ],
            [
              353644.64395423478,
              5009498.9090861194
            ],
            [
              353646.44318635372,
              5009497.7641211087
            ],
            [
              353648.33194506011,
              5009496.0112223905
            ],
            [
              353650.07402437937,
              5009494.4832670167
            ],
            [
              353654.46135001845,
              5009491.0667500645
            ],
            [
              353658.9344438258,
              5009486.8402800495
            ],
            [
              353660.8175648938,
              5009484.7843522793
            ],
            [
              353662.50471206865,
              5009483.0347852577
            ],
            [
              353663.77770074137,
              5009480.7881191978
            ],
            [
              353664.8501757037,
              5009478.645808964
            ],
            [
              353665.52806565404,
              5009477.0164823895
            ],
            [
              353666.21347243252,
              5009475.7911947127
            ],
            [
              353667.08810210909,
              5009473.8546546372
            ],
            [
              353668.66615701863,
              5009471.7033578111
            ],
            [
              353669.9560585802,
              5009470.3657793403
            ],
            [
              353671.3513018192,
              5009469.2283304473
            ],
            [
              353673.16180132225,
              5009468.6890048869
            ],
            [
              353675.27925400314,
              5009468.3464773558
            ],
            [
              353679.11768730689,
              5009468.072977067
            ],
            [
              353681.64178939821,
              5009467.8239288442
            ],
            [
              353682.84592269635,
              5009467.2963035256
            ],
            [
              353683.84579166834,
              5009466.6714337012
            ],
            [
              353685.76164057746,
              5009466.3326568669
            ],
            [
              353688.18603856099,
              5009466.1865082504
            ],
            [
              353691.2081958605,
              5009465.5240154304
            ],
            [
              353693.62397920276,
              5009464.8728037076
            ],
            [
              353695.72639814432,
              5009463.7221984956
            ],
            [
              353697.77708974585,
              5009462.5222429419
            ],
            [
              353699.07262883842,
              5009461.4876937596
            ],
            [
              353700.26924528083,
              5009460.5560296327
            ],
            [
              353701.8574775345,
              5009458.909767108
            ],
            [
              353704.63777862192,
              5009456.1294164835
            ],
            [
              353707.52076855378,
              5009453.4486194123
            ],
            [
              353708.61657534388,
              5009452.5184115423
            ],
            [
              353709.81241036771,
              5009451.5867620083
            ],
            [
              353710.61631319002,
              5009451.2686720742
            ],
            [
              353711.92876505258,
              5009451.143210372
            ],
            [
              353713.54252466018,
              5009450.9110984281
            ],
            [
              353714.54991036514,
              5009450.690267548
            ],
            [
              353715.65324172942,
              5009450.164517669
            ],
            [
              353718.25747423636,
              5009448.8024874795
            ],
            [
              353719.85807163775,
              5009447.8628884647
            ],
            [
              353720.96516138385,
              5009447.539158022
            ],
            [
              353722.07146971632,
              5009447.2154421164
            ],
            [
              353723.18341563648,
              5009447.1947553391
            ],
            [
              353725.30352863926,
              5009446.9532231595
            ],
            [
              353728.1326965405,
              5009446.7995443065
            ],
            [
              353730.25202810409,
              5009446.5580266714
            ],
            [
              353732.36462427577,
              5009445.9124556808
            ],
            [
              353733.97274620383,
              5009445.3773146626
            ],
            [
              353735.07341690751,
              5009444.7505696649
            ],
            [
              353735.76258205558,
              5009443.7273017121
            ],
            [
              353736.5506620527,
              5009442.6007296024
            ],
            [
              353736.93476108083,
              5009441.4820924737
            ],
            [
              353737.20224330411,
              5009439.5572675867
            ],
            [
              353737.58634234191,
              5009438.4386304673
            ],
            [
              353738.48838634789,
              5009438.0176700968
            ],
            [
              353739.09554161067,
              5009438.0063745212
            ],
            [
              353739.60111362464,
              5009437.9969688095
            ],
            [
              353740.31392689823,
              5009438.2868415536
            ],
            [
              353740.83265327109,
              5009438.9845037591
            ],
            [
              353742.06497432612,
              5009439.9720241623
            ],
            [
              353742.87749164941,
              5009440.1589973336
            ],
            [
              353743.4846469044,
              5009440.1477017542
            ],
            [
              353743.98270213255,
              5009439.7342572352
            ],
            [
              353744.22880817967,
              5009439.3074712893
            ],
            [
              353744.8209298781,
              5009438.4880981026
            ],
            [
              353745.41602075793,
              5009437.8703397289
            ],
            [
              353746.11834026367,
              5009437.5541397259
            ],
            [
              353746.82629735098,
              5009437.5409688242
            ],
            [
              353747.73773730412,
              5009437.6250569709
            ],
            [
              353748.2470676924,
              5009437.817670661
            ],
            [
              353748.96254155168,
              5009438.2085385593
            ],
            [
              353749.98496851302,
              5009438.7962044645
            ],
            [
              353750.80124420603,
              5009439.185197033
            ],
            [
              353751.81426739332,
              5009439.2673953073
            ],
            [
              353753.22830234695,
              5009439.1400437998
            ],
            [
              353754.53699566267,
              5009438.8125627628
            ],
            [
              353756.14323050604,
              5009438.1759929871
            ],
            [
              353757.54599027883,
              5009437.4425833011
            ],
            [
              353758.35099078721,
              5009437.2255176799
            ],
            [
              353759.5626406403,
              5009437.1019313736
            ],
            [
              353760.87885070877,
              5009437.1784891365
            ],
            [
              353764.11654685624,
              5009437.2192993909
            ],
            [
              353767.34218640212,
              5009436.6540660141
            ],
            [
              353771.78025713842,
              5009435.9652318126
            ],
            [
              353777.62218529941,
              5009434.6440122295
            ],
            [
              353781.84659568872,
              5009433.3528851252
            ],
            [
              353784.25783086481,
              5009432.4992498308
            ],
            [
              353785.25769960706,
              5009431.8743802924
            ],
            [
              353785.95250226936,
              5009431.1541415807
            ],
            [
              353786.44679906423,
              5009430.5386777483
            ],
            [
              353786.83089807682,
              5009429.4200407965
            ],
            [
              353787.51066721376,
              5009427.8917245846
            ],
            [
              353788.20280148234,
              5009427.0700716069
            ],
            [
              353788.80056070554,
              5009426.55372761
            ],
            [
              353789.50773629552,
              5009426.5405712724
            ],
            [
              353790.01330823585,
              5009426.5311655793
            ],
            [
              353790.93304624286,
              5009427.0192779582
            ],
            [
              353793.0200034462,
              5009427.7686841795
            ],
            [
              353794.43779661751,
              5009427.8433521101
            ],
            [
              353795.75212735904,
              5009427.8189002136
            ],
            [
              353796.96001870249,
              5009427.4932945836
            ],
            [
              353797.9580004288,
              5009426.7669962719
            ],
            [
              353799.05303339154,
              5009425.8372223945
            ],
            [
              353801.74193588947,
              5009423.5642156964
            ],
            [
              353806.12284133577,
              5009419.8446866469
            ],
            [
              353807.72422761546,
              5009418.9054925991
            ],
            [
              353808.72973387805,
              5009418.5836521918
            ],
            [
              353809.73711932573,
              5009418.3628214672
            ],
            [
              353810.74450477154,
              5009418.1419907426
            ],
            [
              353811.34524093539,
              5009417.8276807005
            ],
            [
              353811.83953771769,
              5009417.2122169323
            ],
            [
              353812.12580409588,
              5009416.2970701698
            ],
            [
              353812.6107049436,
              5009415.1765579982
            ],
            [
              353814.39302378905,
              5009413.1225068588
            ],
            [
              353815.68856259383,
              5009412.0879582139
            ],
            [
              353817.99789799581,
              5009411.1351742567
            ],
            [
              353822.11884556938,
              5009409.7449277705
            ],
            [
              353825.13912261958,
              5009408.9814260285
            ],
            [
              353828.36742223561,
              5009408.5171881588
            ],
            [
              353830.79181924119,
              5009408.3710400173
            ],
            [
              353833.72256951517,
              5009408.2154716775
            ],
            [
              353835.06223208905,
              5009407.8299743645
            ],
            [
              353836.96867649705,
              5009406.9857305102
            ],
            [
              353838.2660944197,
              5009406.0521916328
            ],
            [
              353840.35535839241,
              5009404.1945195515
            ],
            [
              353842.04250510375,
              5009402.4449538449
            ],
            [
              353843.52539540268,
              5009400.5985627761
            ],
            [
              353844.30220800004,
              5009398.8663522108
            ],
            [
              353845.07713362615,
              5009397.0327128554
            ],
            [
              353846.16167284403,
              5009395.496866635
            ],
            [
              353847.05432071496,
              5009394.5708581535
            ],
            [
              353847.65583824605,
              5009394.2565336451
            ],
            [
              353848.46083857323,
              5009394.0394681832
            ],
            [
              353850.37778440997,
              5009393.8017161237
            ],
            [
              353851.88886242203,
              5009393.4704701873
            ],
            [
              353852.79356677434,
              5009393.1505052112
            ],
            [
              353853.18063498923,
              5009392.2334832652
            ],
            [
              353853.56097569346,
              5009390.9128272347
            ],
            [
              353854.53265662235,
              5009388.7728129029
            ],
            [
              353855.50245058583,
              5009386.5313697895
            ],
            [
              353856.58620840794,
              5009384.9955381854
            ],
            [
              353858.00824855431,
              5009383.7858961541
            ],
            [
              353859.60509492981,
              5009382.6446976168
            ],
            [
              353862.00771521515,
              5009381.2860000003
            ],
            [
              353864.00369408069,
              5009379.8342421763
            ],
            [
              353865.29171609663,
              5009378.3956551431
            ],
            [
              353866.17950784753,
              5009377.1666032225
            ],
            [
              353867.0571145172,
              5009375.4320983896
            ],
            [
              353867.43369692162,
              5009373.9094230989
            ],
            [
              353867.70869612211,
              5009372.3886376545
            ],
            [
              353868.48925935809,
              5009370.8580274796
            ],
            [
              353869.67835876759,
              5009369.5223254701
            ],
            [
              353871.97564527759,
              5009367.9639176261
            ],
            [
              353877.27440895286,
              5009364.6314928066
            ],
            [
              353882.27938943019,
              5009361.8101759711
            ],
            [
              353884.97767975903,
              5009360.0417995006
            ],
            [
              353887.77490050002,
              5009358.1709572235
            ],
            [
              353891.56446532835,
              5009355.2707880922
            ],
            [
              353897.23417380225,
              5009350.1126595521
            ],
            [
              353900.71868043946,
              5009347.1175404955
            ],
            [
              353902.01609828701,
              5009346.1840020148
            ],
            [
              353903.42371375236,
              5009345.7536365213
            ],
            [
              353904.83774032322,
              5009345.6258663302
            ],
            [
              353906.25365385209,
              5009345.5995249199
            ],
            [
              353909.19302573212,
              5009345.9494390851
            ],
            [
              353911.38704292156,
              5009346.1090341061
            ],
            [
              353913.60795662744,
              5009345.8656273875
            ],
            [
              353914.81506622728,
              5009345.5400366979
            ],
            [
              353915.91839711199,
              5009345.0142874634
            ],
            [
              353917.92564301583,
              5009344.1681688568
            ],
            [
              353919.53376423666,
              5009343.6330286078
            ],
            [
              353921.75467789144,
              5009343.3896219106
            ],
            [
              353925.08565764333,
              5009343.0245191408
            ],
            [
              353927.20122973999,
              5009342.5809829934
            ],
            [
              353929.52372653905,
              5009342.3356864713
            ],
            [
              353931.04153950553,
              5009342.4084939668
            ],
            [
              353932.45933209098,
              5009342.4831622206
            ],
            [
              353933.67849802651,
              5009342.7636148985
            ],
            [
              353934.79608076549,
              5009343.0459574135
            ],
            [
              353935.91444491048,
              5009343.3282853868
            ],
            [
              353936.72508255852,
              5009343.4142490346
            ],
            [
              353937.93861098349,
              5009343.3916727724
            ],
            [
              353938.95539192838,
              5009343.6758905761
            ],
            [
              353939.87058968033,
              5009343.9619982149
            ],
            [
              353940.48714821652,
              5009344.4561701631
            ],
            [
              353941.00133435632,
              5009344.9518273557
            ],
            [
              353946.66974351963,
              5009350.6063348753
            ],
            [
              353948.31998768717,
              5009352.2933922308
            ],
            [
              353949.53915357293,
              5009352.5738448612
            ],
            [
              353950.44495546626,
              5009352.3549042605
            ],
            [
              353951.23868057347,
              5009351.5317811137
            ],
            [
              353951.61902132822,
              5009350.2111255061
            ],
            [
              353951.6728361416,
              5009347.6835900499
            ],
            [
              353952.15022035566,
              5009346.1590398652
            ],
            [
              353952.84126453224,
              5009345.2367823943
            ],
            [
              353953.73845200293,
              5009344.5127790738
            ],
            [
              353954.74395790993,
              5009344.1909390232
            ],
            [
              353955.9116571488,
              5009344.352018035
            ],
            [
              353956.72041558486,
              5009344.3369720364
            ],
            [
              353958.04226237565,
              5009344.7165590301
            ],
            [
              353959.66619814088,
              5009344.9894814137
            ],
            [
              353960.67546236125,
              5009344.8696606429
            ],
            [
              353961.68096824718,
              5009344.5478205942
            ],
            [
              353962.48221000849,
              5009344.1287360471
            ],
            [
              353963.47267477424,
              5009342.9983997755
            ],
            [
              353966.04871837533,
              5009340.1212268267
            ],
            [
              353967.04670759622,
              5009339.3953482527
            ],
            [
              353968.54838153481,
              5009338.5586353624
            ],
            [
              353970.25808571005,
              5009338.0216053836
            ],
            [
              353972.07610830088,
              5009337.8867386542
            ],
            [
              353973.29715325573,
              5009338.2682009535
            ],
            [
              353974.41849418945,
              5009338.7525627194
            ],
            [
              353975.43904114695,
              5009339.2392189028
            ],
            [
              353976.45394281362,
              5009339.4224270564
            ],
            [
              353976.95763531904,
              5009339.312011865
            ],
            [
              353977.96314116294,
              5009338.9901718497
            ],
            [
              353979.48173532391,
              5009339.0629648129
            ],
            [
              353980.90140687389,
              5009339.2386427028
            ],
            [
              353982.42375934846,
              5009339.513454929
            ],
            [
              353983.64402285876,
              5009339.8949317466
            ],
            [
              353985.39131023356,
              5009341.3780944133
            ],
            [
              353987.12544345582,
              5009342.1541896444
            ],
            [
              353988.74859767227,
              5009342.4271265557
            ],
            [
              353990.36313746806,
              5009342.195000778
            ],
            [
              353991.7688734135,
              5009341.6636257805
            ],
            [
              353994.87569899292,
              5009340.090158158
            ],
            [
              353996.16747901606,
              5009338.8535910752
            ],
            [
              353996.65237206296,
              5009337.7326604836
            ],
            [
              353996.80401960836,
              5009335.0016354024
            ],
            [
              353996.9454821627,
              5009331.7651575925
            ],
            [
              353997.25919212186,
              5009330.0562379407
            ],
            [
              353997.63499317801,
              5009328.5335778128
            ],
            [
              353998.02660893108,
              5009327.8189801723
            ],
            [
              353998.72329054441,
              5009327.1997517422
            ],
            [
              354000.43675290822,
              5009326.8647411214
            ],
            [
              354001.95346780709,
              5009326.836524508
            ],
            [
              354003.57286362181,
              5009326.907442227
            ],
            [
              354005.50186501193,
              5009327.2757338686
            ],
            [
              354007.42632666224,
              5009327.4420207916
            ],
            [
              354008.9374040523,
              5009327.1107753031
            ],
            [
              354010.03995325533,
              5009326.5850408133
            ],
            [
              354013.33036427997,
              5009323.9972929303
            ],
            [
              354015.31882588082,
              5009322.1414975878
            ],
            [
              354015.80372675275,
              5009321.0209862106
            ],
            [
              354016.18139919121,
              5009319.5989166703
            ],
            [
              354016.25291584019,
              5009317.9808728844
            ],
            [
              354016.23224511388,
              5009316.8697670391
            ],
            [
              354016.40941959532,
              5009315.5528913699
            ],
            [
              354016.89619184594,
              5009314.5329704983
            ],
            [
              354017.68803773733,
              5009313.6088379789
            ],
            [
              354019.29130248231,
              5009312.7706546253
            ],
            [
              354021.09429158288,
              5009311.8277110821
            ],
            [
              354022.88600574707,
              5009310.2787098279
            ],
            [
              354025.17388793797,
              5009308.2148358505
            ],
            [
              354026.96262519952,
              5009306.4638008587
            ],
            [
              354028.44472601666,
              5009304.6170066083
            ],
            [
              354029.72413338086,
              5009302.6733579133
            ],
            [
              354031.86909107567,
              5009298.3891622582
            ],
            [
              354036.59787278454,
              5009289.3350556679
            ],
            [
              354039.73972199165,
              5009284.2239577677
            ],
            [
              354040.9242736501,
              5009282.6858329009
            ],
            [
              354041.52203265444,
              5009282.1694895374
            ],
            [
              354042.22435170092,
              5009281.8532901229
            ],
            [
              354042.92964763136,
              5009281.7391244806
            ],
            [
              354043.94079077634,
              5009281.7203135407
            ],
            [
              354045.76820873411,
              5009282.0904952101
            ],
            [
              354049.10936449451,
              5009282.2304266971
            ],
            [
              354051.02443014894,
              5009281.8916657893
            ],
            [
              354051.92349664425,
              5009281.2686724588
            ],
            [
              354052.51859507646,
              5009280.6513340361
            ],
            [
              354053.60125502007,
              5009279.0144794974
            ],
            [
              354055.67547742504,
              5009276.34831334
            ],
            [
              354056.66594994394,
              5009275.2183966963
            ],
            [
              354057.66205989599,
              5009274.3915088912
            ],
            [
              354060.05826819042,
              5009272.7302176524
            ],
            [
              354061.7472936433,
              5009271.081663318
            ],
            [
              354062.73964530526,
              5009270.0527563179
            ],
            [
              354063.32612943562,
              5009268.9303553551
            ],
            [
              354064.00510953943,
              5009267.4016360948
            ],
            [
              354064.87223062228,
              5009265.0614794809
            ],
            [
              354065.94095520762,
              5009262.7175723277
            ],
            [
              354066.83061820106,
              5009261.5891119037
            ],
            [
              354067.92001334118,
              5009260.3563104579
            ],
            [
              354069.11662905297,
              5009259.4246480353
            ],
            [
              354071.30559643795,
              5009257.4640786806
            ],
            [
              354073.39485201164,
              5009255.6059896387
            ],
            [
              354074.88337952393,
              5009254.0626292592
            ],
            [
              354076.8964748914,
              5009251.7242135257
            ],
            [
              354078.07993663783,
              5009250.0854839217
            ],
            [
              354078.5629585209,
              5009248.8639632445
            ],
            [
              354079.13549953629,
              5009247.0340905497
            ],
            [
              354079.89242330432,
              5009244.1907613361
            ],
            [
              354080.55824954441,
              5009241.9549749698
            ],
            [
              354081.2305024052,
              5009240.0226220135
            ],
            [
              354081.6164808473,
              5009239.0049958359
            ],
            [
              354083.97885696078,
              5009235.5251129773
            ],
            [
              354086.6376846038,
              5009231.6355368942
            ],
            [
              354087.32497055794,
              5009230.5112608075
            ],
            [
              354088.71755236748,
              5009229.272819207
            ],
            [
              354090.3132926728,
              5009228.0301788906
            ],
            [
              354092.41758912551,
              5009226.9805860603
            ],
            [
              354094.42108383164,
              5009225.932868504
            ],
            [
              354096.02810669568,
              5009225.2967049479
            ],
            [
              354096.62696341111,
              5009224.8813859029
            ],
            [
              354096.91887525178,
              5009224.2696879823
            ],
            [
              354096.99711964413,
              5009223.0552784037
            ],
            [
              354097.7540435713,
              5009220.2119493876
            ],
            [
              354098.71632144839,
              5009217.5664699357
            ],
            [
              354099.99306851212,
              5009215.5218268996
            ],
            [
              354101.37437557016,
              5009213.6773278164
            ],
            [
              354103.56333521509,
              5009211.7163398098
            ],
            [
              354105.70129017346,
              5009210.5852014581
            ],
            [
              354107.91014656838,
              5009209.7357527167
            ],
            [
              354109.71313548967,
              5009208.7928099586
            ],
            [
              354110.51140023436,
              5009208.1716921795
            ],
            [
              354111.10649869713,
              5009207.5543539887
            ],
            [
              354111.29196365608,
              5009206.6410834519
            ],
            [
              354111.46804079833,
              5009205.2231840705
            ],
            [
              354111.64333653735,
              5009203.8052992262
            ],
            [
              354112.0142742751,
              5009201.9791772906
            ],
            [
              354112.6959229089,
              5009200.5518725654
            ],
            [
              354114.27585662476,
              5009198.5015893774
            ],
            [
              354120.23183832405,
              5009192.4287401335
            ],
            [
              354124.20123744902,
              5009188.3126946529
            ],
            [
              354133.81403613283,
              5009177.6243928559
            ],
            [
              354141.59702831547,
              5009169.4207767555
            ],
            [
              354147.53609806387,
              5009162.4388422361
            ],
            [
              354154.06087079557,
              5009154.3345220173
            ],
            [
              354158.49073504336,
              5009147.7844230207
            ],
            [
              354161.14204668876,
              5009143.4908101279
            ],
            [
              354162.42631038203,
              5009141.8502060594
            ],
            [
              354163.72184870124,
              5009140.8156594858
            ],
            [
              354165.62454154214,
              5009139.7698178887
            ],
            [
              354167.83151082881,
              5009138.8189410446
            ],
            [
              354168.32956580911,
              5009138.4054974727
            ],
            [
              354168.72306086967,
              5009137.7919099815
            ],
            [
              354169.40470965841,
              5009136.3646056158
            ],
            [
              354170.58926933381,
              5009134.8269009637
            ],
            [
              354171.97510835814,
              5009133.1839880757
            ],
            [
              354173.9783103863,
              5009131.4482515194
            ],
            [
              354176.77177183516,
              5009129.3753935229
            ],
            [
              354178.36673848436,
              5009128.1331876479
            ],
            [
              354179.55582997715,
              5009126.7970685549
            ],
            [
              354180.83821458108,
              5009125.0554550597
            ],
            [
              354181.82117084478,
              5009123.5215009646
            ],
            [
              354183.28714150895,
              5009120.7656076234
            ],
            [
              354184.55825154582,
              5009118.4179367237
            ],
            [
              354185.73794757115,
              5009116.5767698176
            ],
            [
              354187.12191532261,
              5009114.8332666261
            ],
            [
              354189.30147951323,
              5009112.3672318719
            ],
            [
              354191.7477437632,
              5009109.5729770511
            ],
            [
              354204.71219275746,
              5009095.7230706504
            ],
            [
              354215.68374255626,
              5009081.9777420787
            ],
            [
              354218.45652622671,
              5009078.7933603972
            ],
            [
              354220.54657108517,
              5009076.9356778236
            ],
            [
              354222.43720789329,
              5009075.2837939076
            ],
            [
              354224.23267245363,
              5009073.9363947921
            ],
            [
              354225.32472826558,
              5009072.8045895305
            ],
            [
              354226.51382760867,
              5009071.468889934
            ],
            [
              354227.49678398267,
              5009069.934936191
            ],
            [
              354228.37814945693,
              5009068.4024530863
            ],
            [
              354229.1418092979,
              5009065.9631782742
            ],
            [
              354229.7245356511,
              5009064.6387590775
            ],
            [
              354230.80828594667,
              5009063.1025110278
            ],
            [
              354232.19413287193,
              5009061.4600178422
            ],
            [
              354235.50445086631,
              5009057.7994064409
            ],
            [
              354240.55760839611,
              5009052.1475350456
            ],
            [
              354245.21993129543,
              5009047.2102460861
            ],
            [
              354247.80724973424,
              5009044.9391350467
            ],
            [
              354249.80510687252,
              5009043.5883903606
            ],
            [
              354251.41024247959,
              5009042.8507992616
            ],
            [
              354253.32154910261,
              5009042.3100207848
            ],
            [
              354254.7318240017,
              5009041.9806519924
            ],
            [
              354256.04615302087,
              5009041.956201612
            ],
            [
              354257.36799833184,
              5009042.3357894439
            ],
            [
              354258.89410750073,
              5009042.8126218747
            ],
            [
              354260.61508515128,
              5009042.8816511389
            ],
            [
              354261.92753507383,
              5009042.7561912015
            ],
            [
              354263.03086531529,
              5009042.2304434748
            ],
            [
              354264.32640355884,
              5009041.1958975773
            ],
            [
              354265.1126123977,
              5009039.9687376963
            ],
            [
              354266.48905613302,
              5009037.8207778865
            ],
            [
              354267.37606666581,
              5009036.5917428127
            ],
            [
              354268.86459432973,
              5009035.0483842529
            ],
            [
              354273.33204859815,
              5009030.5188990738
            ],
            [
              354279.49294077506,
              5009023.0653550429
            ],
            [
              354291.34852497128,
              5009007.8889632346
            ],
            [
              354297.27631326934,
              5009000.3005581591
            ],
            [
              354301.34276402212,
              5008995.9810418608
            ],
            [
              354305.35140101163,
              5008991.7892212356
            ],
            [
              354309.01197681361,
              5008987.3757917769
            ],
            [
              354312.27154903108,
              5008983.1723304493
            ],
            [
              354316.90677597985,
              5008976.8205053546
            ],
            [
              354322.23947379756,
              5008969.8498579031
            ],
            [
              354329.45528444921,
              5008960.8228706308
            ],
            [
              354337.6197819353,
              5008951.5291882213
            ],
            [
              354341.5816652737,
              5008947.0091100018
            ],
            [
              354343.47308345418,
              5008945.3572128434
            ],
            [
              354344.67721525015,
              5008944.8295903746
            ],
            [
              354346.08670846245,
              5008944.50023661
            ],
            [
              354347.60154283314,
              5008944.3710121876
            ],
            [
              354348.48525979888,
              5008944.3545727059
            ],
            [
              354349.523025712,
              5008944.3352675065
            ],
            [
              354351.03786006669,
              5008944.2060430888
            ],
            [
              354355.77456339326,
              5008943.309152727
            ],
            [
              354358.99910059309,
              5008942.6429009121
            ],
            [
              354360.90930914105,
              5008942.0010991013
            ],
            [
              354362.51367084071,
              5008941.2639423767
            ],
            [
              354364.01347278763,
              5008940.3266417943
            ],
            [
              354365.10474719031,
              5008939.1948519526
            ],
            [
              354367.97159788612,
              5008935.6045450056
            ],
            [
              354369.16257631668,
              5008934.3698559608
            ],
            [
              354370.26136689034,
              5008933.6421042373
            ],
            [
              354371.26498513372,
              5008933.2188371345
            ],
            [
              354372.46911685157,
              5008932.6912147496
            ],
            [
              354373.37115982088,
              5008932.2702564877
            ],
            [
              354373.96703973698,
              5008931.6529047089
            ],
            [
              354374.75888584304,
              5008930.7287740279
            ],
            [
              354376.04502880079,
              5008929.1891812924
            ],
            [
              354376.94221605238,
              5008928.4651799472
            ],
            [
              354378.04851497727,
              5008928.1410472132
            ],
            [
              354378.75834982865,
              5008928.2288869051
            ],
            [
              354379.47195055609,
              5008928.5191647634
            ],
            [
              354380.49812506005,
              5008929.3084310321
            ],
            [
              354382.45452812564,
              5008931.1918818075
            ],
            [
              354383.68120830093,
              5008931.8763736561
            ],
            [
              354385.20355867554,
              5008932.1511873687
            ],
            [
              354386.92077759089,
              5008932.0181981316
            ],
            [
              354388.63611745765,
              5008931.7841993812
            ],
            [
              354390.69420593436,
              5008930.6038175467
            ],
            [
              354391.58951410459,
              5008929.7788067171
            ],
            [
              354392.58672155795,
              5008929.0529447682
            ],
            [
              354393.47827164637,
              5008928.0259149168
            ],
            [
              354395.84518827527,
              5008924.7480418356
            ],
            [
              354397.03162716253,
              5008923.3113484383
            ],
            [
              354397.83364983817,
              5008922.8922508638
            ],
            [
              354399.03887131246,
              5008922.4652334796
            ],
            [
              354400.4510247705,
              5008922.2368748356
            ],
            [
              354402.57411065354,
              5008922.1973799411
            ],
            [
              354405.61129506101,
              5008922.342969384
            ],
            [
              354407.93488657539,
              5008922.198700103
            ],
            [
              354409.44784164435,
              5008921.968466294
            ],
            [
              354411.15754429076,
              5008921.4314390812
            ],
            [
              354412.96616953774,
              5008920.7915271921
            ],
            [
              354415.76902551483,
              5008919.2237198874
            ],
            [
              354417.98054109613,
              5008918.4752687598
            ],
            [
              354419.19030978519,
              5008918.2506750198
            ],
            [
              354420.70702287991,
              5008918.2224602485
            ],
            [
              354422.02135127661,
              5008918.1980103562
            ],
            [
              354423.23863609805,
              5008918.3774546431
            ],
            [
              354424.35621690366,
              5008918.6597981388
            ],
            [
              354425.78340255859,
              5008919.2395155663
            ],
            [
              354430.37488056388,
              5008921.3770803185
            ],
            [
              354431.80129258643,
              5008921.9572313884
            ],
            [
              354432.91589669907,
              5008922.0375413252
            ],
            [
              354433.71713008714,
              5008921.6180392094
            ],
            [
              354434.11172286217,
              5008921.1054764763
            ],
            [
              354434.2990752465,
              5008920.2936355807
            ],
            [
              354434.27840583591,
              5008919.1825310541
            ],
            [
              354434.15881382552,
              5008918.174311202
            ],
            [
              354434.55152756913,
              5008917.5607389687
            ],
            [
              354435.25196711736,
              5008917.1435311651
            ],
            [
              354436.15478355339,
              5008916.7221393557
            ],
            [
              354437.06246348558,
              5008916.6042097202
            ],
            [
              354438.17895441299,
              5008916.7859482998
            ],
            [
              354438.89818432013,
              5008917.3788355105
            ],
            [
              354439.51958925521,
              5008918.1756314253
            ],
            [
              354440.7462692011,
              5008918.860123205
            ],
            [
              354442.06811366946,
              5008919.2397113089
            ],
            [
              354443.4821459903,
              5008919.1123622088
            ],
            [
              354444.69113318069,
              5008918.8877830207
            ],
            [
              354445.69475902221,
              5008918.4649351779
            ],
            [
              354446.78869368351,
              5008917.4341406366
            ],
            [
              354449.82003552833,
              5008913.90408477
            ],
            [
              354454.28561002587,
              5008909.2735940525
            ],
            [
              354457.57148049964,
              5008906.4838490542
            ],
            [
              354462.07276440581,
              5008903.7729580542
            ],
            [
              354464.4772692314,
              5008902.5156947924
            ],
            [
              354466.68501867523,
              5008901.5648057656
            ],
            [
              354468.39769772644,
              5008901.2298122672
            ],
            [
              354469.71280734881,
              5008901.2053479049
            ],
            [
              354470.82662992616,
              5008901.2856724374
            ],
            [
              354472.55136455497,
              5008901.556721353
            ],
            [
              354475.39555883332,
              5008902.2111232681
            ],
            [
              354477.31250153232,
              5008901.9733743966
            ],
            [
              354478.31988536316,
              5008901.7525456222
            ],
            [
              354479.42321513005,
              5008901.2267986769
            ],
            [
              354480.42308282974,
              5008900.6019319519
            ],
            [
              354482.51422469219,
              5008898.8452759022
            ],
            [
              354484.60347972478,
              5008896.9871912608
            ],
            [
              354486.48550221516,
              5008894.8302476322
            ],
            [
              354488.06167799351,
              5008892.5779491002
            ],
            [
              354488.99655969325,
              5008891.4851264618
            ],
            [
              354490.19881203584,
              5008890.8564949213
            ],
            [
              354491.70612967812,
              5008890.3232328482
            ],
            [
              354493.31988602213,
              5008890.0911240755
            ],
            [
              354494.63499555184,
              5008890.0666597579
            ],
            [
              354495.54831242358,
              5008890.2517586658
            ],
            [
              354496.77123405697,
              5008890.7342314692
            ],
            [
              354499.11737331329,
              5008891.8020762438
            ],
            [
              354500.74506423087,
              5008892.2770193126
            ],
            [
              354501.86076573451,
              5008892.4583533499
            ],
            [
              354502.86627047072,
              5008892.1365151368
            ],
            [
              354504.16932438285,
              5008891.5060084574
            ],
            [
              354505.46862023376,
              5008890.6734828223
            ],
            [
              354511.85191825329,
              5008885.8052298622
            ],
            [
              354518.83031457453,
              5008880.3196401941
            ],
            [
              354522.01272262447,
              5008877.4307762114
            ],
            [
              354525.87756126164,
              5008873.1145953806
            ],
            [
              354530.04182810901,
              5008868.5907556936
            ],
            [
              354532.63102461951,
              5008866.4206573535
            ],
            [
              354534.66762201022,
              5008865.0935118934
            ],
            [
              354536.46873063344,
              5008864.0495626424
            ],
            [
              354538.05805952504,
              5008862.5043308819
            ],
            [
              354539.53530489426,
              5008860.3544983342
            ],
            [
              354541.09910893644,
              5008857.3951194705
            ],
            [
              354542.35330050864,
              5008854.1379476199
            ],
            [
              354542.91081852739,
              5008851.5004214002
            ],
            [
              354543.05494585348,
              5008848.3649438415
            ],
            [
              354543.10579545458,
              5008845.6357984412
            ],
            [
              354543.28108466772,
              5008844.2174964268
            ],
            [
              354543.77350241953,
              5008843.5010253191
            ],
            [
              354544.37126121676,
              5008842.9846834755
            ],
            [
              354545.17626026826,
              5008842.7676197458
            ],
            [
              354546.18740196753,
              5008842.7488101283
            ],
            [
              354547.80491611717,
              5008842.7187205572
            ],
            [
              354549.12300206156,
              5008842.896289899
            ],
            [
              354550.13790177758,
              5008843.0794992195
            ],
            [
              354551.05387889879,
              5008843.3655931531
            ],
            [
              354551.75995534484,
              5008843.2514140466
            ],
            [
              354552.36256976175,
              5008843.0381151531
            ],
            [
              354553.65810752212,
              5008842.0035708798
            ],
            [
              354556.4391881724,
              5008839.2232175944
            ],
            [
              354559.20822114049,
              5008835.8372412184
            ],
            [
              354563.45262056479,
              5008830.2004231643
            ],
            [
              354568.10884806007,
              5008822.7924886104
            ],
            [
              354570.46073244535,
              5008818.7065422116
            ],
            [
              354571.14238177327,
              5008817.2792401435
            ],
            [
              354571.4248911147,
              5008816.1620769715
            ],
            [
              354571.60848566168,
              5008815.1482175272
            ],
            [
              354571.89663982351,
              5008814.3345018737
            ],
            [
              354573.18654058204,
              5008812.996929381
            ],
            [
              354574.47831254813,
              5008811.759947232
            ],
            [
              354575.18250995141,
              5008811.5447587334
            ],
            [
              354576.59763936437,
              5008811.5184341213
            ],
            [
              354578.31673637987,
              5008811.4864550252
            ],
            [
              354579.72888917819,
              5008811.2580969585
            ],
            [
              354580.63093175628,
              5008810.8371392703
            ],
            [
              354582.12697510445,
              5008809.6978209326
            ],
            [
              354583.61362343142,
              5008808.0534553081
            ],
            [
              354585.28385853139,
              5008805.3948111748
            ],
            [
              354587.33553341083,
              5008801.5165384915
            ],
            [
              354589.07850645925,
              5008797.0532549694
            ],
            [
              354589.56904524565,
              5008796.2357745674
            ],
            [
              354589.65857283998,
              5008795.6278426889
            ],
            [
              354589.55025524576,
              5008795.2256799908
            ],
            [
              354589.24143205612,
              5008794.9282915536
            ],
            [
              354586.49239571916,
              5008793.9685663283
            ],
            [
              354587.80871177104,
              5008788.5883068461
            ],
            [
              354592.6864698056,
              5008789.8115664339
            ],
            [
              354593.21767448093,
              5008785.7594891852
            ],
            [
              354593.78468957398,
              5008778.1697721798
            ],
            [
              354593.78777568333,
              5008772.9149861885
            ],
            [
              354593.19576344843,
              5008768.2775367573
            ],
            [
              354592.63382292318,
              5008765.2566577485
            ],
            [
              354591.33087883762,
              5008760.4303443059
            ],
            [
              354590.01902817533,
              5008756.1337363059
            ],
            [
              354589.17832060292,
              5008754.4312009756
            ],
            [
              354587.30393140478,
              5008751.5357806087
            ],
            [
              354584.91832667164,
              5008748.3463173714
            ],
            [
              354581.6129870232,
              5008744.6687410045
            ],
            [
              354579.13786241808,
              5008742.0876286803
            ],
            [
              354576.97640871379,
              5008740.1065286119
            ],
            [
              354573.69550365058,
              5008737.7424942208
            ],
            [
              354569.280099753,
              5008734.1866115071
            ],
            [
              354566.816249025,
              5008732.2115558004
            ],
            [
              354564.03339102521,
              5008729.4336596327
            ],
            [
              354561.34836587362,
              5008726.4522739658
            ],
            [
              354557.81129022286,
              5008723.0075108279
            ],
            [
              354556.56770027662,
              5008721.4139333693
            ],
            [
              354555.30045723339,
              5008718.5067991493
            ],
            [
              354553.92146270321,
              5008715.0969408704
            ],
            [
              354553.05257060676,
              5008711.8792632287
            ],
            [
              354552.27132732217,
              5008707.9526441768
            ],
            [
              354551.68495280721,
              5008703.6182224797
            ],
            [
              354551.32539740053,
              5008700.5935782511
            ],
            [
              354551.00154248037,
              5008699.4881139379
            ],
            [
              354550.38952531491,
              5008699.1959461039
            ],
            [
              354549.98475607316,
              5008699.2034755973
            ],
            [
              354549.57998683193,
              5008699.2110050898
            ],
            [
              354536.14766857954,
              5008705.7264652336
            ],
            [
              354529.43066152546,
              5008692.6133367708
            ],
            [
              354535.33391520625,
              5008689.1686395742
            ],
            [
              354535.30178258003,
              5008683.0290064802
            ],
            [
              354535.07242206199,
              5008676.1618327312
            ],
            [
              354534.79941038694,
              5008672.3268037727
            ],
            [
              354534.3345064944,
              5008669.1016109157
            ],
            [
              354534.09078468225,
              5008666.8831672007
            ],
            [
              354533.76504309505,
              5008665.6762741972
            ],
            [
              354533.25195637334,
              5008665.2816408472
            ],
            [
              354532.54400086805,
              5008665.2948101219
            ],
            [
              354531.93762838078,
              5008665.3060897635
            ],
            [
              354521.0738302457,
              5008668.5399285955
            ],
            [
              354519.31725717109,
              5008661.094477647
            ],
            [
              354530.68209477997,
              5008657.6496492093
            ],
            [
              354527.40505143028,
              5008650.0303930342
            ],
            [
              354518.23780283926,
              5008652.0201393571
            ],
            [
              354517.5407643253,
              5008647.9137630342
            ],
            [
              354525.08486182004,
              5008645.651077034
            ],
            [
              354519.27543269494,
              5008637.8772578686
            ],
            [
              354510.20975904592,
              5008639.8646951681
            ],
            [
              354507.0723137139,
              5008628.9083752595
            ],
            [
              354501.92077025218,
              5008623.8505173856
            ],
            [
              354500.06637291069,
              5008616.6089743776
            ],
            [
              354506.49101955671,
              5008613.9633541694
            ],
            [
              354505.40095417498,
              5008609.7393456819
            ],
            [
              354504.21196616458,
              5008605.6182217281
            ],
            [
              354503.33782462031,
              5008603.1268192716
            ],
            [
              354501.5330694252,
              5008598.5119273756
            ],
            [
              354500.47682516684,
              5008596.10608951
            ],
            [
              354499.33027933625,
              5008594.3086172761
            ],
            [
              354498.40005215909,
              5008593.2144322144
            ],
            [
              354496.96346484864,
              5008592.1292469958
            ],
            [
              354495.1210184904,
              5008590.9509862391
            ],
            [
              354493.78414306155,
              5008589.7633210309
            ],
            [
              354493.15819969471,
              5008588.7645202074
            ],
            [
              354492.93217023159,
              5008587.4551470177
            ],
            [
              354492.89834152989,
              5008585.636557119
            ],
            [
              354492.59636535612,
              5008580.286399832
            ],
            [
              354492.34888621158,
              5008577.8659368083
            ],
            [
              354491.81700236839,
              5008576.4607892735
            ],
            [
              354491.28700526146,
              5008575.1570703499
            ],
            [
              354490.34660203772,
              5008573.5578523055
            ],
            [
              354490.02086853201,
              5008572.3513781875
            ],
            [
              354489.89563295757,
              5008571.0397107787
            ],
            [
              354490.17063522449,
              5008569.5189287141
            ],
            [
              354490.71884348069,
              5008567.0111306673
            ],
            [
              354491.28089526173,
              5008564.5751898224
            ],
            [
              354492.04267917183,
              5008562.0349084903
            ],
            [
              354492.61334423453,
              5008560.1040296359
            ],
            [
              354492.97677706269,
              5008557.8742915783
            ],
            [
              354493.2404980078,
              5008555.7470334871
            ],
            [
              354493.29886565445,
              5008553.4219256761
            ],
            [
              354492.74819262238,
              5008551.0066832295
            ],
            [
              354491.90483328264,
              5008549.2035710867
            ],
            [
              354490.86362855573,
              5008547.6062280331
            ],
            [
              354489.50795618963,
              5008545.4080486279
            ],
            [
              354488.65222579398,
              5008542.897855496
            ],
            [
              354488.10718960554,
              5008540.7856414681
            ],
            [
              354488.17229164648,
              5008538.8645861149
            ],
            [
              354488.44055976562,
              5008536.9397515794
            ],
            [
              354489.207972862,
              5008534.7020796277
            ],
            [
              354490.55733253766,
              5008531.1400049236
            ],
            [
              354490.93579693564,
              5008529.7183427904
            ],
            [
              354491.10846187611,
              5008528.1168678971
            ],
            [
              354491.07652020577,
              5008526.3997065946
            ],
            [
              354490.84296749794,
              5008524.6858762754
            ],
            [
              354490.41001436958,
              5008523.1782632368
            ],
            [
              354488.6647255845,
              5008516.3392869281
            ],
            [
              354488.01434868999,
              5008514.0269434666
            ],
            [
              354488.18854958384,
              5008512.5080364905
            ],
            [
              354488.57452972932,
              5008511.4904123247
            ],
            [
              354489.36261887196,
              5008510.3642639332
            ],
            [
              354492.3246379068,
              5008506.4690583274
            ],
            [
              354493.20600522385,
              5008504.9365778351
            ],
            [
              354493.7849754687,
              5008503.4101416226
            ],
            [
              354494.05058346037,
              5008501.3843121901
            ],
            [
              354494.21350312821,
              5008499.258929193
            ],
            [
              354494.49226353545,
              5008497.9401661772
            ],
            [
              354495.06857348903,
              5008496.3127350304
            ],
            [
              354496.04588713957,
              5008494.475336534
            ],
            [
              354496.42998841807,
              5008493.3567029238
            ],
            [
              354496.61358395609,
              5008492.3428434264
            ],
            [
              354496.69183864369,
              5008491.1288545402
            ],
            [
              354496.87731310027,
              5008490.2160045337
            ],
            [
              354497.26062518795,
              5008489.0969663402
            ],
            [
              354498.05137470167,
              5008488.0718129799
            ],
            [
              354498.63895815489,
              5008487.0504387841
            ],
            [
              354499.62865783199,
              5008484.8283379544
            ],
            [
              354501.26883331063,
              5008480.5535429576
            ],
            [
              354502.62085381546,
              5008477.092463539
            ],
            [
              354503.10387785878,
              5008475.8709454434
            ],
            [
              354504.97196135175,
              5008473.00653324
            ],
            [
              354507.22038839752,
              5008468.8214685507
            ],
            [
              354508.78983267146,
              5008466.1651196256
            ],
            [
              354509.76715424046,
              5008464.3281404125
            ],
            [
              354510.34157762903,
              5008462.5992807811
            ],
            [
              354510.80769159371,
              5008460.4686773885
            ],
            [
              354510.97812711314,
              5008458.7473324435
            ],
            [
              354510.95370139554,
              5008457.434209168
            ],
            [
              354510.72501213546,
              5008456.0238410085
            ],
            [
              354510.70246532868,
              5008454.8117272127
            ],
            [
              354510.88417427702,
              5008453.6964391721
            ],
            [
              354511.16669264477,
              5008452.579695221
            ],
            [
              354511.94538743171,
              5008450.9484996265
            ],
            [
              354515.0251843697,
              5008445.8628885522
            ],
            [
              354519.17176551401,
              5008440.4299830766
            ],
            [
              354521.93703713181,
              5008436.84157144
            ],
            [
              354523.61291208997,
              5008434.4859570563
            ],
            [
              354524.49882664025,
              5008433.1559004365
            ],
            [
              354524.97997191647,
              5008431.8333730018
            ],
            [
              354525.2587325573,
              5008430.5146101564
            ],
            [
              354525.94413357676,
              5008429.2889084946
            ],
            [
              354527.22464154928,
              5008427.4462898644
            ],
            [
              354528.01273087837,
              5008426.3201417932
            ],
            [
              354528.39494565543,
              5008425.1000797572
            ],
            [
              354528.66806964675,
              5008423.4782885006
            ],
            [
              354529.25189545943,
              5008422.2548955502
            ],
            [
              354530.0493792537,
              5008421.6337948851
            ],
            [
              354531.1508307053,
              5008421.0070400806
            ],
            [
              354532.85301018495,
              5008420.0655585136
            ],
            [
              354534.66351462645,
              5008419.5266589606
            ],
            [
              354536.17834837403,
              5008419.3974369233
            ],
            [
              354537.59535687481,
              5008419.4721233966
            ],
            [
              354538.51321257249,
              5008419.859227947
            ],
            [
              354539.43593132746,
              5008420.5497945808
            ],
            [
              354541.48827705975,
              5008422.1283294158
            ],
            [
              354542.92109807214,
              5008423.0110771311
            ],
            [
              354544.03679919883,
              5008423.1924126707
            ],
            [
              354545.0460620388,
              5008423.0725948121
            ],
            [
              354546.25207283086,
              5008422.6459839521
            ],
            [
              354548.56251138676,
              5008421.7946518026
            ],
            [
              354550.26469857438,
              5008420.8535894053
            ],
            [
              354552.75684614759,
              5008418.8869693894
            ],
            [
              354554.04862741072,
              5008417.6504077641
            ],
            [
              354555.18298154784,
              5008415.97695872
            ],
            [
              354556.06889609125,
              5008414.6469022697
            ],
            [
              354556.94087701297,
              5008412.6097940747
            ],
            [
              354557.52171865333,
              5008411.1839486379
            ],
            [
              354558.51219258754,
              5008410.0540361274
            ],
            [
              354559.51206095645,
              5008409.4291709699
            ],
            [
              354560.41974074213,
              5008409.3112427136
            ],
            [
              354561.43464019947,
              5008409.4944533082
            ],
            [
              354562.14823231869,
              5008409.7843129672
            ],
            [
              354563.37491082301,
              5008410.4688063227
            ],
            [
              354564.19306231674,
              5008410.9588098899
            ],
            [
              354564.90101776988,
              5008410.9456411386
            ],
            [
              354565.70789593767,
              5008410.8295879019
            ],
            [
              354566.70776428381,
              5008410.2047227537
            ],
            [
              354570.12153297052,
              5008408.8276455104
            ],
            [
              354572.0347177225,
              5008408.3878805246
            ],
            [
              354575.16221012693,
              5008407.925528096
            ],
            [
              354580.42891593056,
              5008408.3327836851
            ],
            [
              354584.08076792938,
              5008408.8711217176
            ],
            [
              354586.01649924187,
              5008409.6434702845
            ],
            [
              354586.94187034731,
              5008410.4346126495
            ],
            [
              354587.55951606936,
              5008411.0293902233
            ],
            [
              354588.2385564759,
              5008413.2858561995
            ],
            [
              354589.09883265739,
              5008415.9984724438
            ],
            [
              354589.41704996413,
              5008416.8009085236
            ],
            [
              354590.13627869805,
              5008417.3937965231
            ],
            [
              354591.36405459867,
              5008418.1793137928
            ],
            [
              354592.38834841474,
              5008418.8675716408
            ],
            [
              354592.99925999215,
              5008419.0582968174
            ],
            [
              354593.70721538528,
              5008419.0451280512
            ],
            [
              354595.52335617685,
              5008418.8092569774
            ],
            [
              354597.04006854602,
              5008418.7810444273
            ],
            [
              354598.05496789987,
              5008418.9642549735
            ],
            [
              354598.67074252362,
              5008419.4584421795
            ],
            [
              354599.18758664641,
              5008420.0550947394
            ],
            [
              354600.82952615997,
              5008421.3381300997
            ],
            [
              354602.36690629611,
              5008422.4210215565
            ],
            [
              354603.28851960553,
              5008423.0101449378
            ],
            [
              354605.62902002415,
              5008423.7749642152
            ],
            [
              354607.55990377947,
              5008424.244688876
            ],
            [
              354608.77264850616,
              5008424.2221304439
            ],
            [
              354609.98053796386,
              5008423.8965291139
            ],
            [
              354611.28358454193,
              5008423.2656052774
            ],
            [
              354612.58366253792,
              5008422.4330671271
            ],
            [
              354615.17474014865,
              5008420.3639821075
            ],
            [
              354617.26399697515,
              5008418.5059010806
            ],
            [
              354619.55564617953,
              5008416.6444746209
            ],
            [
              354621.65132886841,
              5008415.089826582
            ],
            [
              354623.45055952464,
              5008413.9448704971
            ],
            [
              354626.65629859635,
              5008412.26811055
            ],
            [
              354629.36320884113,
              5008411.0052262601
            ],
            [
              354634.41787072207,
              5008408.1234687604
            ],
            [
              354642.828311363,
              5008403.5206527887
            ],
            [
              354651.63412657788,
              5008398.4052607007
            ],
            [
              354657.63709303393,
              5008394.8580899034
            ],
            [
              354663.43766706862,
              5008391.3142646691
            ],
            [
              354669.45898944413,
              5008387.1990593262
            ],
            [
              354673.45470403106,
              5008384.4975809157
            ],
            [
              354679.43699480442,
              5008379.8388876896
            ],
            [
              354685.11609942309,
              5008375.1858341862
            ],
            [
              354691.69348909386,
              5008369.9098055819
            ],
            [
              354695.77950549754,
              5008366.5999623016
            ],
            [
              354699.25571578898,
              5008363.2008369723
            ],
            [
              354702.63377699826,
              5008359.9041624889
            ],
            [
              354704.91415273125,
              5008357.4366803253
            ],
            [
              354707.03004605725,
              5008354.6993121235
            ],
            [
              354709.19646035851,
              5008351.526219924
            ],
            [
              354710.8704639232,
              5008349.0700169383
            ],
            [
              354712.23673446954,
              5008346.4170300784
            ],
            [
              354713.01542930963,
              5008344.785835675
            ],
            [
              354713.58688443637,
              5008342.8553630086
            ],
            [
              354714.25537560572,
              5008340.7205773722
            ],
            [
              354714.9080622581,
              5008337.7781500146
            ],
            [
              354715.78457457764,
              5008335.9426281415
            ],
            [
              354716.67612561013,
              5008334.9156007888
            ],
            [
              354717.47173026716,
              5008334.193491241
            ],
            [
              354719.17689318169,
              5008333.4544631224
            ],
            [
              354720.5871668653,
              5008333.1250976892
            ],
            [
              354722.60757043574,
              5008332.9864720525
            ],
            [
              354728.16321047879,
              5008332.5799992029
            ],
            [
              354733.41754322167,
              5008332.2801753124
            ],
            [
              354735.63391323807,
              5008331.8347714385
            ],
            [
              354737.14310954761,
              5008331.4025216149
            ],
            [
              354738.7482444286,
              5008330.6649348857
            ],
            [
              354740.35072683048,
              5008329.8267724095
            ],
            [
              354742.05479218677,
              5008328.9867204111
            ],
            [
              354743.56398847239,
              5008328.5544706052
            ],
            [
              354744.87643682439,
              5008328.4290135792
            ],
            [
              354746.69633460621,
              5008328.3951619184
            ],
            [
              354748.01066180825,
              5008328.3707143059
            ],
            [
              354749.78898967011,
              5008328.4973782152
            ],
            [
              354753.53036812838,
              5008328.4277853677
            ],
            [
              354755.74673802039,
              5008327.9823815431
            ],
            [
              354757.56178056746,
              5008327.6454871194
            ],
            [
              354758.96751496987,
              5008327.1141174613
            ],
            [
              354760.16600968904,
              5008326.2834695624
            ],
            [
              354762.36249348155,
              5008324.7269477183
            ],
            [
              354768.43806963711,
              5008319.662344113
            ],
            [
              354771.6287769578,
              5008317.1775104962
            ],
            [
              354773.1285788386,
              5008316.2402137462
            ],
            [
              354774.7366976887,
              5008315.7050796431
            ],
            [
              354776.15072887839,
              5008315.577733147
            ],
            [
              354777.3634730198,
              5008315.5551751107
            ],
            [
              354778.48183359444,
              5008315.8375057466
            ],
            [
              354780.31112548948,
              5008316.3087011855
            ],
            [
              354781.63296799728,
              5008316.6882912405
            ],
            [
              354782.84837238374,
              5008316.7667280706
            ],
            [
              354783.85763456172,
              5008316.6469106078
            ],
            [
              354784.96394056227,
              5008316.3231993532
            ],
            [
              354786.06351253565,
              5008315.5954359509
            ],
            [
              354787.2574680667,
              5008314.5627838867
            ],
            [
              354788.34575909836,
              5008313.2285449542
            ],
            [
              354790.1243218056,
              5008310.9724865369
            ],
            [
              354791.59969070141,
              5008308.7216485189
            ],
            [
              354793.29436158057,
              5008307.376549745
            ],
            [
              354795.21613654104,
              5008306.5080760783
            ],
            [
              354797.33765905589,
              5008306.367549601
            ],
            [
              354798.75697450124,
              5008306.5432781633
            ],
            [
              354799.98160203971,
              5008307.1268148823
            ],
            [
              354801.20998737303,
              5008307.9123751065
            ],
            [
              354810.04283307435,
              5008315.1249305652
            ],
            [
              354811.69253647345,
              5008316.8121274896
            ],
            [
              354812.31612430088,
              5008317.7100018561
            ],
            [
              354812.84800522716,
              5008319.114833313
            ],
            [
              354813.28254287952,
              5008320.7236043755
            ],
            [
              354813.71513505402,
              5008322.2313471492
            ],
            [
              354814.24513676082,
              5008323.5351490788
            ],
            [
              354815.68887888611,
              5008325.0240835985
            ],
            [
              354816.71881918766,
              5008326.0154639129
            ],
            [
              354817.43599249329,
              5008326.5073751388
            ],
            [
              354818.86094491038,
              5008326.9861566219
            ],
            [
              354819.47701647057,
              5008327.4799484229
            ],
            [
              354820.09872507618,
              5008328.2767932368
            ],
            [
              354820.51823085884,
              5008329.0774347372
            ],
            [
              354821.35341704328,
              5008330.4766244069
            ],
            [
              354822.29346344626,
              5008332.0759925125
            ],
            [
              354823.02949272527,
              5008333.5780554935
            ],
            [
              354824.1642263314,
              5008334.7695799023
            ],
            [
              354825.21773557208,
              5008335.5244439747
            ],
            [
              354826.44048382755,
              5008336.0069510341
            ],
            [
              354827.45727078471,
              5008336.2911957605
            ],
            [
              354828.77172590606,
              5008336.2667457405
            ],
            [
              354829.98132146802,
              5008336.0421528099
            ],
            [
              354830.98488882004,
              5008335.6192632541
            ],
            [
              354832.79176619352,
              5008334.8783091241
            ],
            [
              354833.69805586786,
              5008334.659322408
            ],
            [
              354834.911409227,
              5008334.6367529752
            ],
            [
              354835.82514959178,
              5008334.8218855383
            ],
            [
              354836.43934189522,
              5008335.2146477904
            ],
            [
              354837.2670124367,
              5008336.2097904095
            ],
            [
              354838.32513797301,
              5008338.7164355973
            ],
            [
              354838.65474921878,
              5008340.1250293124
            ],
            [
              354838.58934905805,
              5008342.0462573683
            ],
            [
              354838.30859207938,
              5008343.2641110951
            ],
            [
              354837.92666711548,
              5008344.4838466393
            ],
            [
              354837.14785084722,
              5008346.1151514892
            ],
            [
              354836.96437154489,
              5008347.1291023865
            ],
            [
              354836.98316197243,
              5008348.1392908832
            ],
            [
              354837.60487059323,
              5008348.9361356162
            ],
            [
              354838.42133263597,
              5008349.3251354415
            ],
            [
              354839.43617473432,
              5008349.5083873775
            ],
            [
              354841.15322376223,
              5008349.3753842264
            ],
            [
              354842.46392100846,
              5008349.1489106752
            ],
            [
              354843.77273900696,
              5008348.8214076161
            ],
            [
              354845.78369833273,
              5008348.1776862489
            ],
            [
              354846.89031285263,
              5008347.8539443659
            ],
            [
              354847.9014626969,
              5008347.8351360541
            ],
            [
              354848.6129989067,
              5008348.0239942092
            ],
            [
              354849.23094968544,
              5008348.6188154407
            ],
            [
              354849.55116607324,
              5008349.5223326366
            ],
            [
              354849.57371432986,
              5008350.7345445743
            ],
            [
              354849.44667196786,
              5008354.7790240478
            ],
            [
              354849.21094535204,
              5008358.4213028522
            ],
            [
              354848.76556524093,
              5008361.6632591169
            ],
            [
              354848.29569201695,
              5008363.5920107095
            ],
            [
              354847.71156366804,
              5008364.8155074166
            ],
            [
              354847.02069584967,
              5008365.7377962014
            ],
            [
              354846.13131746539,
              5008366.8659419473
            ],
            [
              354845.4423288969,
              5008367.8892602446
            ],
            [
              354845.05295461329,
              5008368.7049475983
            ],
            [
              354844.76837298629,
              5008369.7207435379
            ],
            [
              354844.18612390111,
              5008371.045269778
            ],
            [
              354843.49149891903,
              5008371.7655706378
            ],
            [
              354842.69201370532,
              5008372.2856930718
            ],
            [
              354841.4880552963,
              5008372.8133391095
            ],
            [
              354840.39647344942,
              5008373.945246079
            ],
            [
              354840.21581896517,
              5008375.0433589034
            ],
            [
              354840.543551768,
              5008376.3509586016
            ],
            [
              354841.07543288887,
              5008377.7557898508
            ],
            [
              354841.81327598525,
              5008379.3589189257
            ],
            [
              354842.75526740128,
              5008381.0592795471
            ],
            [
              354842.87140182051,
              5008381.8655639477
            ],
            [
              354842.98753558233,
              5008382.6718128109
            ],
            [
              354842.80788048572,
              5008383.88778596
            ],
            [
              354842.41280222958,
              5008384.4003860429
            ],
            [
              354841.40923497052,
              5008384.82327573
            ],
            [
              354840.10035074438,
              5008385.1507801609
            ],
            [
              354838.18485609599,
              5008385.4895681646
            ],
            [
              354837.0764285847,
              5008385.7122794325
            ],
            [
              354836.37992436771,
              5008386.3315508254
            ],
            [
              354836.19268733781,
              5008387.1434782604
            ],
            [
              354836.41374694934,
              5008388.1498687956
            ],
            [
              354836.53175998008,
              5008389.0571471825
            ],
            [
              354837.15534794977,
              5008389.9550213534
            ],
            [
              354837.68152577785,
              5008391.0568008358
            ],
            [
              354838.11042663333,
              5008392.362518697
            ],
            [
              354838.23783462582,
              5008393.7748735575
            ],
            [
              354837.96647344338,
              5008395.4978393381
            ],
            [
              354837.28875915514,
              5008397.1272637034
            ],
            [
              354836.70275231212,
              5008398.2497665361
            ],
            [
              354836.63359396416,
              5008399.9689355921
            ],
            [
              354836.67493298493,
              5008402.1913360637
            ],
            [
              354837.01393945719,
              5008404.1050062012
            ],
            [
              354837.4465982173,
              5008405.6127475332
            ],
            [
              354837.46726807463,
              5008406.7239655349
            ],
            [
              354837.49545298732,
              5008408.2391949669
            ],
            [
              354837.32041201188,
              5008409.9169998979
            ],
            [
              354836.9497615188,
              5008411.7428415054
            ],
            [
              354836.88248249836,
              5008413.563040074
            ],
            [
              354837.01552769943,
              5008415.2784479251
            ],
            [
              354837.25537802227,
              5008417.2950269282
            ],
            [
              354837.60002236319,
              5008419.5117855771
            ],
            [
              354837.63384512026,
              5008421.3301035371
            ],
            [
              354837.37187840929,
              5008423.5581102837
            ],
            [
              354837.10232972581,
              5008425.3820713004
            ],
            [
              354836.53135510639,
              5008427.3127036504
            ],
            [
              354835.76005562796,
              5008429.3480911991
            ],
            [
              354834.10313583101,
              5008432.7136481991
            ],
            [
              354831.27089012909,
              5008437.7755811326
            ],
            [
              354829.50723151106,
              5008440.8399658278
            ],
            [
              354827.73605715699,
              5008443.5003035776
            ],
            [
              354825.96488215291,
              5008446.1606058199
            ],
            [
              354823.98962440994,
              5008448.7236398049
            ],
            [
              354822.20905439119,
              5008450.8788656043
            ],
            [
              354820.63263338449,
              5008453.1313584764
            ],
            [
              354819.26022822718,
              5008455.4810853461
            ],
            [
              354818.48329237936,
              5008457.2134555876
            ],
            [
              354817.71763019869,
              5008459.5518963207
            ],
            [
              354817.34697994945,
              5008461.3777380856
            ],
            [
              354817.38080292998,
              5008463.196056149
            ],
            [
              354817.39959369914,
              5008464.2062446931
            ],
            [
              354817.63004860329,
              5008465.7177117337
            ],
            [
              354818.25363680738,
              5008466.6155858161
            ],
            [
              354819.28726914577,
              5008467.8089904198
            ],
            [
              354820.41831158724,
              5008468.798489674
            ],
            [
              354821.74779895373,
              5008469.582168649
            ],
            [
              354823.27197401255,
              5008469.9580393974
            ],
            [
              354824.48344812088,
              5008469.8344399724
            ],
            [
              354825.18746865826,
              5008469.6192509057
            ],
            [
              354826.28468806628,
              5008468.7904321235
            ],
            [
              354827.27704718098,
              5008467.7614349388
            ],
            [
              354828.16823830525,
              5008466.7343196161
            ],
            [
              354828.9470540199,
              5008465.1030144505
            ],
            [
              354829.22036038758,
              5008463.4810768506
            ],
            [
              354829.87552572857,
              5008460.6394403167
            ],
            [
              354830.4540166051,
              5008459.1128903842
            ],
            [
              354831.23846884893,
              5008457.78460271
            ],
            [
              354833.42914913635,
              5008455.9249062305
            ],
            [
              354835.13109969953,
              5008454.9837743044
            ],
            [
              354836.14037029917,
              5008454.8639361681
            ],
            [
              354836.95300898788,
              5008455.0509490278
            ],
            [
              354837.76759253821,
              5008455.3389546247
            ],
            [
              354838.89856797003,
              5008456.3284195634
            ],
            [
              354840.4547528702,
              5008458.4215775495
            ],
            [
              354841.0933738827,
              5008460.1276165815
            ],
            [
              354841.33127893688,
              5008462.0431672521
            ],
            [
              354841.27151662245,
              5008464.2674483014
            ],
            [
              354841.11816836998,
              5008466.8976569362
            ],
            [
              354840.47045329522,
              5008470.1433771178
            ],
            [
              354839.91263255459,
              5008472.781145012
            ],
            [
              354839.65066617873,
              5008475.009151781
            ],
            [
              354839.4063875209,
              5008477.3258075062
            ],
            [
              354839.4890659143,
              5008481.770572857
            ],
            [
              354839.83377692086,
              5008483.9873301899
            ],
            [
              354840.17090522376,
              5008485.8000062536
            ],
            [
              354840.6073222417,
              5008487.5097709494
            ],
            [
              354841.45754195849,
              5008489.7171251401
            ],
            [
              354841.78151662898,
              5008490.8226657063
            ],
            [
              354841.7029636493,
              5008492.0367582859
            ],
            [
              354841.32110546943,
              5008493.256492747
            ],
            [
              354840.93166456558,
              5008494.0721459482
            ],
            [
              354840.1378167239,
              5008494.8953216625
            ],
            [
              354839.14169981936,
              5008495.7222954016
            ],
            [
              354838.8534269064,
              5008496.5360667473
            ],
            [
              354838.96580302954,
              5008497.1402921025
            ],
            [
              354839.18116017274,
              5008497.8436663356
            ],
            [
              354839.49580602028,
              5008498.4441291969
            ],
            [
              354840.21485880885,
              5008499.0370695405
            ],
            [
              354841.13987297629,
              5008499.8282721043
            ],
            [
              354842.17169232719,
              5008500.9206457902
            ],
            [
              354842.59489014285,
              5008501.923311742
            ],
            [
              354842.71296972461,
              5008502.8305888399
            ],
            [
              354842.54076528695,
              5008504.4506457774
            ],
            [
              354842.06713427976,
              5008506.1773385806
            ],
            [
              354841.395058389,
              5008508.1098516686
            ],
            [
              354840.32797002775,
              5008510.5549282255
            ],
            [
              354839.44986651797,
              5008512.2891803496
            ],
            [
              354839.07170007273,
              5008513.7109395536
            ],
            [
              354838.8067934736,
              5008516.7107951576
            ],
            [
              354837.85426252184,
              5008525.3179623205
            ],
            [
              354836.48793034576,
              5008533.4276111517
            ],
            [
              354835.0175807061,
              5008541.1971114343
            ],
            [
              354834.46351843869,
              5008544.0369029678
            ],
            [
              354833.90945553267,
              5008546.8766589733
            ],
            [
              354833.34982232255,
              5008549.413396298
            ],
            [
              354832.88370807248,
              5008551.5441717124
            ],
            [
              354832.40256140556,
              5008552.8668176606
            ],
            [
              354831.9157768389,
              5008553.8863750771
            ],
            [
              354831.32976983773,
              5008555.0088426545
            ],
            [
              354830.84862318286,
              5008556.331488613
            ],
            [
              354830.00253031834,
              5008559.7830281388
            ],
            [
              354829.33609129518,
              5008562.0185588589
            ],
            [
              354828.9673209071,
              5008563.9454301791
            ],
            [
              354828.33710232563,
              5008568.0497487821
            ],
            [
              354827.58271609881,
              5008570.994331223
            ],
            [
              354826.92379371304,
              5008573.6339444928
            ],
            [
              354831.10694400436,
              5008575.5771718696
            ],
            [
              354826.61928103754,
              5008584.452227463
            ],
            [
              354829.37184205971,
              5008585.6136568589
            ],
            [
              354833.94000320393,
              5008575.6255369727
            ],
            [
              354847.70287394343,
              5008581.4326827722
            ],
            [
              354848.9966585447,
              5008580.2970138649
            ],
            [
              354850.97943173529,
              5008578.1380617991
            ],
            [
              354852.57846708858,
              5008577.0977792339
            ],
            [
              354854.68676970509,
              5008576.2501524258
            ],
            [
              354857.13266810932,
              5008574.7320215981
            ],
            [
              354859.22781687608,
              5008573.1772593716
            ],
            [
              354860.21823068411,
              5008572.0472692549
            ],
            [
              354861.10385040444,
              5008570.7170995567
            ],
            [
              354861.78720108065,
              5008569.3907279344
            ],
            [
              354862.35629555461,
              5008567.35906599
            ],
            [
              354863.21178412513,
              5008564.4126387406
            ],
            [
              354863.59934553312,
              5008563.4959560176
            ],
            [
              354864.28833348269,
              5008562.4726373823
            ],
            [
              354864.98483732558,
              5008561.8533656867
            ],
            [
              354866.70000683662,
              5008561.6193319857
            ],
            [
              354869.72963194316,
              5008561.3608829426
            ],
            [
              354872.35290456802,
              5008561.0089282319
            ],
            [
              354873.76094466919,
              5008560.5785142509
            ],
            [
              354875.16522682091,
              5008559.9460768141
            ],
            [
              354876.65940244979,
              5008558.7056515943
            ],
            [
              354879.33680014021,
              5008555.8263986716
            ],
            [
              354880.62306882447,
              5008554.2866830518
            ],
            [
              354881.60227374127,
              5008552.5505503882
            ],
            [
              354882.18640136364,
              5008551.3270534826
            ],
            [
              354882.5776551335,
              5008550.6124310456
            ],
            [
              354883.17493587866,
              5008550.0960340081
            ],
            [
              354884.07183076412,
              5008549.3720058929
            ],
            [
              354884.95926355821,
              5008548.1428670706
            ],
            [
              354885.5452704897,
              5008547.0203996813
            ],
            [
              354885.82985089929,
              5008546.0045681829
            ],
            [
              354886.01326333685,
              5008544.9906185577
            ],
            [
              354886.38015513477,
              5008542.9627536163
            ],
            [
              354886.56544687849,
              5008542.0498334896
            ],
            [
              354887.08797115198,
              5008541.0525757857
            ],
            [
              354887.88188502734,
              5008540.2293988466
            ],
            [
              354888.77871300717,
              5008539.5053364635
            ],
            [
              354889.48273336963,
              5008539.2901472934
            ],
            [
              354890.08940994315,
              5008539.2788622184
            ],
            [
              354891.00697379332,
              5008539.6659808923
            ],
            [
              354891.92259275366,
              5008539.9521068353
            ],
            [
              354893.04423999629,
              5008540.4365292517
            ],
            [
              354893.95797965449,
              5008540.6216257019
            ],
            [
              354894.4616752037,
              5008540.5111917714
            ],
            [
              354894.9616128194,
              5008540.1987343952
            ],
            [
              354895.25740210823,
              5008539.7890455769
            ],
            [
              354897.61458231945,
              5008536.0062405262
            ],
            [
              354898.20622649492,
              5008535.1868261341
            ],
            [
              354898.70240551844,
              5008534.6723097889
            ],
            [
              354899.50376965135,
              5008534.2532165525
            ],
            [
              354902.2018363073,
              5008532.4851107216
            ],
            [
              354903.39827773982,
              5008531.553381796
            ],
            [
              354903.88694090565,
              5008530.6348185744
            ],
            [
              354904.07599126175,
              5008529.9239574801
            ],
            [
              354904.06283752579,
              5008529.2168221259
            ],
            [
              354904.01022391906,
              5008526.3883517813
            ],
            [
              354904.19933987485,
              5008525.6774539184
            ],
            [
              354904.90148092207,
              5008525.361235301
            ],
            [
              354906.5079664425,
              5008524.7250367943
            ],
            [
              354909.6142647877,
              5008523.1514662765
            ],
            [
              354911.51284725656,
              5008521.903518796
            ],
            [
              354913.61369939899,
              5008520.6518088328
            ],
            [
              354914.80638290272,
              5008519.5180565426
            ],
            [
              354915.78928022733,
              5008517.9839843502
            ],
            [
              354916.37528654508,
              5008516.8614815762
            ],
            [
              354917.14470603678,
              5008514.7250648271
            ],
            [
              354918.02468928805,
              5008513.0918781124
            ],
            [
              354918.91024283791,
              5008511.7617100216
            ],
            [
              354919.90448084619,
              5008510.8337424779
            ],
            [
              354923.48441001592,
              5008507.5334797455
            ],
            [
              354924.67145632912,
              5008506.0966746192
            ],
            [
              354925.05331437284,
              5008504.8769404404
            ],
            [
              354925.13186728855,
              5008503.6628481504
            ],
            [
              354925.21048646321,
              5008502.4487546291
            ],
            [
              354925.49500071857,
              5008501.4329245156
            ],
            [
              354926.6322233234,
              5008500.3176227557
            ],
            [
              354927.73507952911,
              5008499.791857033
            ],
            [
              354928.63942396926,
              5008499.4718772313
            ],
            [
              354929.95199940488,
              5008499.3463971149
            ],
            [
              354931.67092720541,
              5008499.3144227332
            ],
            [
              354933.18382741994,
              5008499.0841874164
            ],
            [
              354934.28856223001,
              5008498.6594156502
            ],
            [
              354935.69284424983,
              5008498.0269785812
            ],
            [
              354937.08967670257,
              5008496.9904934336
            ],
            [
              354938.38158262608,
              5008495.7538311975
            ],
            [
              354939.56862827629,
              5008494.3169906391
            ],
            [
              354941.34725254326,
              5008492.0607726434
            ],
            [
              354944.89711647289,
              5008487.1442163438
            ],
            [
              354948.26356784784,
              5008483.2416095575
            ],
            [
              354951.24239078554,
              5008480.2556509404
            ],
            [
              354953.73261739878,
              5008478.188289688
            ],
            [
              354956.23035979731,
              5008476.5249752961
            ],
            [
              354957.82751635509,
              5008475.383699581
            ],
            [
              354958.62324259081,
              5008474.6615181351
            ],
            [
              354959.21676608664,
              5008473.9431334678
            ],
            [
              354959.90387417347,
              5008472.8187502893
            ],
            [
              354960.88307994063,
              5008471.0826537935
            ],
            [
              354961.47284488688,
              5008470.1622101897
            ],
            [
              354962.27045040129,
              5008469.5410582358
            ],
            [
              354963.6747323607,
              5008468.9086213363
            ],
            [
              354966.38601937226,
              5008467.84768588
            ],
            [
              354967.68732034578,
              5008467.1161001418
            ],
            [
              354968.28272245609,
              5008466.498709443
            ],
            [
              354968.87248740293,
              5008465.5782658709
            ],
            [
              354969.85350554046,
              5008463.9431646029
            ],
            [
              354971.43562987045,
              5008461.9937598407
            ],
            [
              354972.52526614629,
              5008460.760860323
            ],
            [
              354973.62060583517,
              5008459.8310124567
            ],
            [
              354974.61860171118,
              5008459.1050686147
            ],
            [
              354975.7176992803,
              5008458.3772441577
            ],
            [
              354976.51718405494,
              5008457.8571217237
            ],
            [
              354977.56915005908,
              5008456.9435087359
            ],
            [
              354979.05198505701,
              5008455.0969798267
            ],
            [
              354980.44875121099,
              5008454.0604962194
            ],
            [
              354981.64895046683,
              5008453.3307911782
            ],
            [
              354982.45601724996,
              5008453.2147143278
            ],
            [
              354983.26489704981,
              5008453.1996681811
            ],
            [
              354984.07753538981,
              5008453.3866809662
            ],
            [
              354984.68796968117,
              5008453.5774194412
            ],
            [
              354985.60177530284,
              5008453.7625148399
            ],
            [
              354986.20845171047,
              5008453.7512299214
            ],
            [
              354986.80761235469,
              5008453.3358982094
            ],
            [
              354987.29996724037,
              5008452.6193599477
            ],
            [
              354987.48720469535,
              5008451.8074683724
            ],
            [
              354987.57139493548,
              5008450.8964291764
            ],
            [
              354987.34281816118,
              5008449.4859212609
            ],
            [
              354987.31463242107,
              5008447.9706569565
            ],
            [
              354987.60485084669,
              5008447.257914233
            ],
            [
              354988.10478841356,
              5008446.9454570785
            ],
            [
              354988.90990966203,
              5008446.7283520149
            ],
            [
              354989.71878944658,
              5008446.7133058812
            ],
            [
              354991.33661526343,
              5008446.6832123818
            ],
            [
              354992.25223387999,
              5008446.9693384934
            ],
            [
              354993.37763854285,
              5008447.6557844644
            ],
            [
              354994.49552678777,
              5008447.938148113
            ],
            [
              354995.60777788103,
              5008447.9174589058
            ],
            [
              354996.81354799122,
              5008447.4908080045
            ],
            [
              354997.61121971667,
              5008446.8696549321
            ],
            [
              354998.00623145001,
              5008446.3570562415
            ],
            [
              354998.20098466642,
              5008445.9492114699
            ],
            [
              354998.29081206548,
              5008445.3412251556
            ],
            [
              354998.38815587776,
              5008445.1373211546
            ],
            [
              354999.28686304094,
              5008444.5142887132
            ],
            [
              354999.4722212393,
              5008443.6013677055
            ],
            [
              354999.25491944869,
              5008442.7970010713
            ],
            [
              354998.42537038535,
              5008441.7008652529
            ],
            [
              354998.20061844308,
              5008440.4924150696
            ],
            [
              354998.38027326058,
              5008439.2764424449
            ],
            [
              354999.06926065066,
              5008438.253088925
            ],
            [
              355000.06913576543,
              5008437.6281746598
            ],
            [
              355001.57827719027,
              5008437.1958807362
            ],
            [
              355003.49565014226,
              5008436.9581220709
            ],
            [
              355005.00861633784,
              5008436.7278858442
            ],
            [
              355006.11328474223,
              5008436.3031156007
            ],
            [
              355007.01393742004,
              5008435.7811114239
            ],
            [
              355007.70668267424,
              5008434.9597813208
            ],
            [
              355008.09048639698,
              5008433.8410757417
            ],
            [
              355008.35996865184,
              5008432.0171168577
            ],
            [
              355008.65206569777,
              5008431.4053681148
            ],
            [
              355009.25122566946,
              5008430.9900009353
            ],
            [
              355011.4549922453,
              5008429.8374419641
            ],
            [
              355012.65519144497,
              5008429.1077370718
            ],
            [
              355013.54450360429,
              5008427.979628481
            ],
            [
              355014.23167864926,
              5008426.855279875
            ],
            [
              355015.5927427258,
              5008423.8994500088
            ],
            [
              355016.18250770622,
              5008422.979006636
            ],
            [
              355016.88652722043,
              5008422.7637822526
            ],
            [
              355017.89948956954,
              5008422.8460044228
            ],
            [
              355018.81141648541,
              5008422.9301059647
            ],
            [
              355019.72327648435,
              5008423.0141732031
            ],
            [
              355020.93287131825,
              5008422.7895800974
            ],
            [
              355021.93650426099,
              5008422.3666892955
            ],
            [
              355022.82769567956,
              5008421.3396102069
            ],
            [
              355023.32011683437,
              5008420.6230708342
            ],
            [
              355023.84893116151,
              5008419.037582173
            ],
            [
              355024.74018817738,
              5008418.0104663307
            ],
            [
              355026.14259071817,
              5008417.2770003006
            ],
            [
              355027.85588092753,
              5008416.9419735167
            ],
            [
              355029.67215206882,
              5008416.7060955819
            ],
            [
              355032.20371692668,
              5008416.8610989349
            ],
            [
              355034.73152390652,
              5008416.8140789168
            ],
            [
              355036.648896689,
              5008416.5763203893
            ],
            [
              355037.85661220754,
              5008416.2506978661
            ],
            [
              355039.26277321816,
              5008415.7192907743
            ],
            [
              355039.76083147869,
              5008415.3058042582
            ],
            [
              355040.55279979471,
              5008414.3815997588
            ],
            [
              355041.94204944925,
              5008412.9410342369
            ],
            [
              355043.04678401753,
              5008412.5162628852
            ],
            [
              355044.05605402257,
              5008412.3964249482
            ],
            [
              355045.47160998406,
              5008412.3700940534
            ],
            [
              355047.18671310897,
              5008412.1360979928
            ],
            [
              355048.5966981962,
              5008411.8067130595
            ],
            [
              355050.10208158533,
              5008411.172395952
            ],
            [
              355051.40338302549,
              5008410.4408461442
            ],
            [
              355052.30403496296,
              5008409.9188065678
            ],
            [
              355053.71207518177,
              5008409.4884289708
            ],
            [
              355059.83672140614,
              5008407.050271499
            ],
            [
              355061.34210475575,
              5008406.4159544343
            ],
            [
              355062.84373024647,
              5008405.5796140255
            ],
            [
              355064.74794940947,
              5008404.6347205453
            ],
            [
              355067.54530477058,
              5008402.763740846
            ],
            [
              355068.74174598331,
              5008401.832012821
            ],
            [
              355069.53371426923,
              5008400.9078084296
            ],
            [
              355070.31816619769,
              5008399.5795218106
            ],
            [
              355070.8049508892,
              5008398.559965319
            ],
            [
              355071.18687540613,
              5008397.3402305404
            ],
            [
              355071.27106577193,
              5008396.4291915819
            ],
            [
              355071.32950284134,
              5008395.0138506088
            ],
            [
              355071.2278482308,
              5008394.1057987167
            ],
            [
              355067.46414059488,
              5008392.9631770216
            ],
            [
              355063.76431958872,
              5008395.2551299492
            ],
            [
              355022.11741503893,
              5008398.9423665283
            ],
            [
              355021.93927318655,
              5008394.802536943
            ],
            [
              355021.32695973996,
              5008394.5107689276
            ],
            [
              355020.60227041214,
              5008393.6147758393
            ],
            [
              355020.28574566095,
              5008392.9132837411
            ],
            [
              355020.17149052961,
              5008392.2080292134
            ],
            [
              355020.36241970077,
              5008391.5981623558
            ],
            [
              355021.26119315519,
              5008390.9751288611
            ],
            [
              355022.66547502059,
              5008390.3426923901
            ],
            [
              355024.77941415779,
              5008389.798119978
            ],
            [
              355027.20048242045,
              5008389.4499279261
            ],
            [
              355029.01863217627,
              5008389.3150440566
            ],
            [
              355029.93049280177,
              5008389.3991469191
            ],
            [
              355030.84617752011,
              5008389.6852719188
            ],
            [
              355031.65693646233,
              5008389.7712553749
            ],
            [
              355032.9695115466,
              5008389.6457757149
            ],
            [
              355034.38130971271,
              5008389.4174215412
            ],
            [
              355038.11683222424,
              5008389.0447793016
            ],
            [
              355041.65015134373,
              5008388.6758982576
            ],
            [
              355045.54379886895,
              5008388.7267901776
            ],
            [
              355047.97238333157,
              5008388.7826804128
            ],
            [
              355049.38800553285,
              5008388.7563483827
            ],
            [
              355050.50582729402,
              5008389.0387134105
            ],
            [
              355051.41775345424,
              5008389.1227794988
            ],
            [
              355051.92144948366,
              5008389.0123813786
            ],
            [
              355053.02423854708,
              5008388.4865819011
            ],
            [
              355054.3349349589,
              5008388.2601083452
            ],
            [
              355057.77091008279,
              5008388.0951312771
            ],
            [
              355059.59469743149,
              5008388.2633357607
            ],
            [
              355060.50843656604,
              5008388.4484325266
            ],
            [
              355061.11887068604,
              5008388.639171076
            ],
            [
              355061.83416497969,
              5008389.030087919
            ],
            [
              355062.44459909777,
              5008389.2208264666
            ],
            [
              355063.56624557322,
              5008389.7052491391
            ],
            [
              355064.3770037762,
              5008389.7911970476
            ],
            [
              355064.88257837872,
              5008389.7817928409
            ],
            [
              355065.88621189416,
              5008389.3589377226
            ],
            [
              355066.58835273766,
              5008389.0427195318
            ],
            [
              355067.39723234362,
              5008389.0276735397
            ],
            [
              355068.50948320568,
              5008389.0069845263
            ],
            [
              355072.04837285256,
              5008388.9411575384
            ],
            [
              355073.46017019876,
              5008388.712767859
            ],
            [
              355074.09159046353,
              5008388.5317052687
            ],
            [
              355074.58776945557,
              5008388.0171893965
            ],
            [
              355075.96198706172,
              5008385.7684952114
            ],
            [
              355077.71624933014,
              5008382.1990013346
            ],
            [
              355078.0962290577,
              5008380.8782739285
            ],
            [
              355077.96882055019,
              5008379.4658845132
            ],
            [
              355077.84141271008,
              5008378.0535306344
            ],
            [
              355077.91432895913,
              5008376.5363861285
            ],
            [
              355078.20266821102,
              5008375.7226142157
            ],
            [
              355078.80182812945,
              5008375.307247215
            ],
            [
              355079.70053520938,
              5008374.6842151014
            ],
            [
              355080.90261353686,
              5008374.055539988
            ],
            [
              355082.70385243552,
              5008373.0115334215
            ],
            [
              355084.40023117233,
              5008371.7673485819
            ],
            [
              355085.79323866131,
              5008370.5288067535
            ],
            [
              355086.98028433108,
              5008369.0919672437
            ],
            [
              355087.86020159262,
              5008367.4587829374
            ],
            [
              355088.53603562812,
              5008365.7282948457
            ],
            [
              355089.19871694566,
              5008363.2907073125
            ],
            [
              355089.96068761341,
              5008360.7502440717
            ],
            [
              355091.21877105761,
              5008357.6952662682
            ],
            [
              355091.50140702591,
              5008356.5784428623
            ],
            [
              355092.19039447739,
              5008355.5550897866
            ],
            [
              355093.87174128433,
              5008353.5027761953
            ],
            [
              355095.48653499543,
              5008351.6742697414
            ],
            [
              355097.56853063341,
              5008349.4124105247
            ],
            [
              355099.05888133094,
              5008347.9699293869
            ],
            [
              355099.94825978816,
              5008346.8418200538
            ],
            [
              355100.43685691914,
              5008345.9232588494
            ],
            [
              355100.60342492588,
              5008344.0001504403
            ],
            [
              355100.55832850595,
              5008341.5757283336
            ],
            [
              355100.81653708295,
              5008339.145664487
            ],
            [
              355101.1947039669,
              5008337.7239065524
            ],
            [
              355102.38175038231,
              5008336.2871027552
            ],
            [
              355104.87197677448,
              5008334.2197434548
            ],
            [
              355106.25558880367,
              5008332.4760902319
            ],
            [
              355107.13738476433,
              5008330.9439000068
            ],
            [
              355107.80952805281,
              5008329.0114230597
            ],
            [
              355108.27557706623,
              5008326.880650877
            ],
            [
              355108.70411166648,
              5008322.729502066
            ],
            [
              355108.96614508459,
              5008320.5014959192
            ],
            [
              355109.64010074554,
              5008318.67001413
            ],
            [
              355110.528340258,
              5008317.0477265129
            ],
            [
              355111.20524726243,
              5008315.8114182586
            ],
            [
              355112.57389442378,
              5008313.2596706161
            ],
            [
              355113.85407387512,
              5008311.5386662213
            ],
            [
              355115.0429995844,
              5008310.2028919943
            ],
            [
              355116.13451452105,
              5008309.070987368
            ],
            [
              355116.41721687908,
              5008307.95416285
            ],
            [
              355116.40218504361,
              5008307.1460340274
            ],
            [
              355116.18488298525,
              5008306.3416320421
            ],
            [
              355115.54820939084,
              5008304.7366578197
            ],
            [
              355115.01069131936,
              5008303.0287392866
            ],
            [
              355115.18470954761,
              5008301.5097143929
            ],
            [
              355115.87933479255,
              5008300.7894497821
            ],
            [
              355116.77998672979,
              5008300.2674105912
            ],
            [
              355118.18426837405,
              5008299.6349746669
            ],
            [
              355119.7888747587,
              5008298.8977837181
            ],
            [
              355121.28492949886,
              5008297.7583899396
            ],
            [
              355122.17799959908,
              5008296.8323053624
            ],
            [
              355122.95869402448,
              5008295.301995866
            ],
            [
              355123.63459460781,
              5008293.5715068588
            ],
            [
              355123.90783596569,
              5008291.9496075502
            ],
            [
              355124.16611112776,
              5008289.5195426708
            ],
            [
              355124.35516120231,
              5008288.8086466258
            ],
            [
              355125.35121219204,
              5008287.9817109397
            ],
            [
              355126.14512546762,
              5008287.1584996181
            ],
            [
              355127.54001231573,
              5008286.02098771
            ],
            [
              355128.03055504436,
              5008285.2034548605
            ],
            [
              355128.30567499687,
              5008283.6825494701
            ],
            [
              355128.28312699962,
              5008282.4703385076
            ],
            [
              355127.96103182953,
              5008281.4657925498
            ],
            [
              355127.43666776613,
              5008280.4650444863
            ],
            [
              355126.90666604816,
              5008279.1612081397
            ],
            [
              355126.88036029076,
              5008277.7469738601
            ],
            [
              355127.16494129831,
              5008276.731143293
            ],
            [
              355127.8614451288,
              5008276.111872647
            ],
            [
              355129.86112895055,
              5008274.8620467652
            ],
            [
              355131.50882433529,
              5008273.4412744753
            ],
            [
              355133.70319523982,
              5008271.7836053399
            ],
            [
              355134.60378158203,
              5008271.2616030201
            ],
            [
              355135.60929359827,
              5008270.9397422224
            ],
            [
              355137.21772398782,
              5008270.4045735234
            ],
            [
              355138.72122803697,
              5008269.669227777
            ],
            [
              355140.31462600973,
              5008268.325894786
            ],
            [
              355141.70199666242,
              5008266.7843008507
            ],
            [
              355142.19066020241,
              5008265.8657386405
            ],
            [
              355142.67549972219,
              5008264.7451543594
            ],
            [
              355143.34757640207,
              5008262.8126434349
            ],
            [
              355144.02911475004,
              5008261.3852428561
            ],
            [
              355144.52334902744,
              5008260.7697346183
            ],
            [
              355145.51758706645,
              5008259.8417684091
            ],
            [
              355146.41441563104,
              5008259.1177428337
            ],
            [
              355147.00605938252,
              5008258.2982939724
            ],
            [
              355147.38798431441,
              5008257.0785596212
            ],
            [
              355147.65934661397,
              5008255.355631032
            ],
            [
              355147.93641214754,
              5008253.9357539378
            ],
            [
              355148.42695426283,
              5008253.118185644
            ],
            [
              355149.42300526943,
              5008252.2912501181
            ],
            [
              355150.52398193436,
              5008251.6644561123
            ],
            [
              355152.13617004512,
              5008251.3313108319
            ],
            [
              355153.75580807769,
              5008251.4022489218
            ],
            [
              355156.89029030659,
              5008251.3439455815
            ],
            [
              355157.79657918186,
              5008251.1249592947
            ],
            [
              355159.40306403703,
              5008250.4887625407
            ],
            [
              355161.10507981008,
              5008249.5476312935
            ],
            [
              355162.50936136866,
              5008248.9151956579
            ],
            [
              355163.41565089166,
              5008248.6962449187
            ],
            [
              355164.73198394739,
              5008248.7728246795
            ],
            [
              355166.14753942814,
              5008248.7464944683
            ],
            [
              355167.6566803655,
              5008248.3142015832
            ],
            [
              355168.25584028277,
              5008247.898834913
            ],
            [
              355168.75014080253,
              5008247.2833255054
            ],
            [
              355169.03277706576,
              5008246.1665024394
            ],
            [
              355169.00834999507,
              5008244.8532622065
            ],
            [
              355168.52878436854,
              5008240.8201053692
            ],
            [
              355168.80578369374,
              5008239.400229604
            ],
            [
              355169.79062634823,
              5008237.9671519902
            ],
            [
              355171.77527839981,
              5008235.9091977859
            ],
            [
              355173.76556740835,
              5008234.154296306
            ],
            [
              355176.49472187465,
              5008231.2035219381
            ],
            [
              355179.97536239482,
              5008228.0061757658
            ],
            [
              355181.97504608997,
              5008226.7563503524
            ],
            [
              355183.08165964467,
              5008226.4326091819
            ],
            [
              355183.89053898526,
              5008226.4175635781
            ],
            [
              355184.5983166885,
              5008226.4043985186
            ],
            [
              355185.81730576773,
              5008226.6848821854
            ],
            [
              355187.33027064527,
              5008226.4546114719
            ],
            [
              355188.53422807361,
              5008225.92696648
            ],
            [
              355190.93086902675,
              5008224.265571137
            ],
            [
              355192.02614241157,
              5008223.3357258448
            ],
            [
              355193.11020816007,
              5008221.7997739566
            ],
            [
              355194.99187934113,
              5008219.6426711259
            ],
            [
              355196.08158203826,
              5008218.4097719453
            ],
            [
              355197.47834737075,
              5008217.3732544165
            ],
            [
              355198.57932394574,
              5008216.7464606091
            ],
            [
              355200.08470704331,
              5008216.1121446351
            ],
            [
              355201.68562108063,
              5008215.1728942115
            ],
            [
              355202.97940623219,
              5008214.0372633878
            ],
            [
              355203.96606184257,
              5008212.7052166276
            ],
            [
              355204.6532372142,
              5008211.580869073
            ],
            [
              355205.43768956955,
              5008210.2525834031
            ],
            [
              355206.52551306947,
              5008208.9186548879
            ],
            [
              355207.61702871218,
              5008207.7867864184
            ],
            [
              355208.61502510705,
              5008207.0608793357
            ],
            [
              355209.81334490021,
              5008206.2301462386
            ],
            [
              355211.20259475004,
              5008204.7895822683
            ],
            [
              355212.68543010484,
              5008202.9430556986
            ],
            [
              355213.86308138608,
              5008201.0011413628
            ],
            [
              355214.64196375106,
              5008199.3698373931
            ],
            [
              355215.1211658034,
              5008197.9461652394
            ],
            [
              355215.81946813711,
              5008195.9377687005
            ],
            [
              355216.58325204445,
              5008193.4983018572
            ],
            [
              355217.35830980144,
              5008191.6649403507
            ],
            [
              355218.33751626249,
              5008189.9288459495
            ],
            [
              355220.50746017491,
              5008186.9579377249
            ],
            [
              355221.29191325099,
              5008185.6296877125
            ],
            [
              355221.86852550862,
              5008184.0021105781
            ],
            [
              355222.13807518029,
              5008182.1781517882
            ],
            [
              355222.48242114403,
              5008178.9380785702
            ],
            [
              355222.95410783758,
              5008177.1103599546
            ],
            [
              355224.30396569689,
              5008173.5484263189
            ],
            [
              355224.88809409359,
              5008172.3249312742
            ],
            [
              355225.98148832604,
              5008171.2940568402
            ],
            [
              355227.2715151587,
              5008169.9563674685
            ],
            [
              355228.94722552487,
              5008167.6010029325
            ],
            [
              355230.42442410934,
              5008165.4514239654
            ],
            [
              355231.20706431422,
              5008164.0221434124
            ],
            [
              355231.68821192777,
              5008162.6994995391
            ],
            [
              355232.46326981502,
              5008160.8661381826
            ],
            [
              355232.74914337107,
              5008159.7808222398
            ],
            [
              355233.82569320704,
              5008157.8407886894
            ],
            [
              355236.2802192432,
              5008153.8540861914
            ],
            [
              355236.76512525068,
              5008152.7335011419
            ],
            [
              355237.32858535834,
              5008150.3988249814
            ],
            [
              355238.29463903519,
              5008147.9555961117
            ],
            [
              355238.87118519115,
              5008146.3280203529
            ],
            [
              355239.85039182124,
              5008144.5919261863
            ],
            [
              355240.63484435593,
              5008143.2636408089
            ],
            [
              355242.21133173804,
              5008141.0111508723
            ],
            [
              355243.19610900298,
              5008139.5781106148
            ],
            [
              355244.07985090575,
              5008138.1469496517
            ],
            [
              355244.76695952896,
              5008137.0225680703
            ],
            [
              355245.24622871261,
              5008135.5989304325
            ],
            [
              355246.49679821928,
              5008132.1399083314
            ],
            [
              355247.18578603229,
              5008131.1165561602
            ],
            [
              355247.67820747406,
              5008130.4000176713
            ],
            [
              355249.17231679527,
              5008129.1595968921
            ],
            [
              355252.71646155015,
              5008126.6897796318
            ],
            [
              355254.40532497695,
              5008125.0415501352
            ],
            [
              355254.59061763709,
              5008124.1286312062
            ],
            [
              355254.56807021232,
              5008122.9164206795
            ],
            [
              355254.55491732922,
              5008122.2092860239
            ],
            [
              355254.23282274627,
              5008121.2047402859
            ],
            [
              355253.17845842656,
              5008118.9001562223
            ],
            [
              355252.54171904858,
              5008117.2951479843
            ],
            [
              355252.62215243344,
              5008116.1820863253
            ],
            [
              355253.00971460331,
              5008115.2654051362
            ],
            [
              355254.28846785997,
              5008113.321610814
            ],
            [
              355256.37985863601,
              5008111.5648299512
            ],
            [
              355259.17157720373,
              5008109.390801047
            ],
            [
              355261.46899512579,
              5008107.832281217
            ],
            [
              355262.46316699003,
              5008106.904316999
            ],
            [
              355263.9460689043,
              5008105.0577898929
            ],
            [
              355266.9941190916,
              5008100.3527047383
            ],
            [
              355269.56853617291,
              5008097.3742742306
            ],
            [
              355270.95966540434,
              5008096.0347403316
            ],
            [
              355271.35098527459,
              5008095.3200824503
            ],
            [
              355271.74036039092,
              5008094.5044319518
            ],
            [
              355272.23466103763,
              5008093.8889229475
            ],
            [
              355273.52280949778,
              5008092.4502401976
            ],
            [
              355276.21336018719,
              5008090.2780932952
            ],
            [
              355277.20759830897,
              5008089.3501279354
            ],
            [
              355278.31045336538,
              5008088.8243285287
            ],
            [
              355279.80832102761,
              5008087.7859668024
            ],
            [
              355281.10586397065,
              5008086.8523599431
            ],
            [
              355282.10198058886,
              5008086.0253884504
            ],
            [
              355283.79829313635,
              5008084.7812069776
            ],
            [
              355285.19700393191,
              5008083.8457184052
            ],
            [
              355286.59940679272,
              5008083.1122898292
            ],
            [
              355287.80712175526,
              5008082.7866687169
            ],
            [
              355289.35580512782,
              5008082.402982506
            ],
            [
              355291.77499269124,
              5008081.9537635157
            ],
            [
              355295.00118082546,
              5008081.38850543
            ],
            [
              355297.11699728662,
              5008080.9449291928
            ],
            [
              355298.72542722168,
              5008080.4097616347
            ],
            [
              355301.22692737664,
              5008078.94851055
            ],
            [
              355302.92518460576,
              5008077.8053217893
            ],
            [
              355304.22084899194,
              5008076.7707211915
            ],
            [
              355305.50523908884,
              5008075.129979887
            ],
            [
              355307.38315292931,
              5008072.7708553495
            ],
            [
              355308.37356644269,
              5008071.6408326067
            ],
            [
              355309.87337877526,
              5008070.7034636522
            ],
            [
              355311.17467996449,
              5008069.9719156865
            ],
            [
              355313.58259377541,
              5008068.9165916461
            ],
            [
              355316.39491462667,
              5008067.8537811628
            ],
            [
              355318.50703957758,
              5008067.2081805998
            ],
            [
              355320.51987516059,
              5008066.6654556077
            ],
            [
              355322.22934035276,
              5008066.1284088902
            ],
            [
              355323.83401188423,
              5008065.391182702
            ],
            [
              355325.83557449566,
              5008064.2423881255
            ],
            [
              355327.32787081646,
              5008062.900937316
            ],
            [
              355328.71712079592,
              5008061.4603744131
            ],
            [
              355331.81963881705,
              5008057.2820656607
            ],
            [
              355333.79677507788,
              5008054.8200315395
            ],
            [
              355335.08492281177,
              5008053.3813136546
            ],
            [
              355336.76814939879,
              5008051.430032352
            ],
            [
              355340.52585555828,
              5008046.8127778126
            ],
            [
              355344.95000250259,
              5008039.9599614656
            ],
            [
              355347.80900172918,
              5008035.9657379035
            ],
            [
              355348.59345510148,
              5008034.6374887247
            ],
            [
              355349.07084542088,
              5008033.1128222933
            ],
            [
              355349.64182141644,
              5008031.1821934655
            ],
            [
              355350.40560620168,
              5008028.7427279335
            ],
            [
              355351.07204769889,
              5008026.5072016204
            ],
            [
              355352.04749633354,
              5008024.5690496387
            ],
            [
              355353.42735132604,
              5008022.6233757241
            ],
            [
              355354.70798387699,
              5008020.7806116734
            ],
            [
              355355.29962850292,
              5008019.9611992575
            ],
            [
              355355.79441965203,
              5008018.6594180921
            ],
            [
              355356.26617327164,
              5008016.8316991702
            ],
            [
              355356.72665338434,
              5008014.3978751656
            ],
            [
              355356.99056709564,
              5008012.2708646739
            ],
            [
              355356.95486742532,
              5008010.3515200485
            ],
            [
              355357.02966438147,
              5008008.9354062062
            ],
            [
              355357.40776610171,
              5008007.513650923
            ],
            [
              355357.69422685803,
              5008006.5988506153
            ],
            [
              355358.37569959863,
              5008005.171452662
            ],
            [
              355359.16015240521,
              5008003.8431680584
            ],
            [
              355360.24985535973,
              5008002.6102701481
            ],
            [
              355361.93496058701,
              5008000.7599830581
            ],
            [
              355364.71721840109,
              5007998.0808812371
            ],
            [
              355366.79921408911,
              5007995.8189903889
            ],
            [
              355367.78217872657,
              5007994.2849204745
            ],
            [
              355368.2670850702,
              5007993.164336076
            ],
            [
              355368.92788936867,
              5007990.6257218784
            ],
            [
              355369.59620954999,
              5007988.4911895785
            ],
            [
              355370.4743150273,
              5007986.7569769947
            ],
            [
              355371.25688940781,
              5007985.3276986592
            ],
            [
              355372.53752209287,
              5007983.4849348431
            ],
            [
              355375.70922081918,
              5007979.990146351
            ],
            [
              355378.99332896643,
              5007976.4065999538
            ],
            [
              355380.07927436283,
              5007974.97167903
            ],
            [
              355380.86372723727,
              5007973.6433945792
            ],
            [
              355381.54150872747,
              5007972.0139723839
            ],
            [
              355382.80710975896,
              5007969.3630449232
            ],
            [
              355383.67575473961,
              5007967.1237579947
            ],
            [
              355384.55761787819,
              5007965.5915687056
            ],
            [
              355385.84764492296,
              5007964.2538807187
            ],
            [
              355387.13955109188,
              5007963.0172220953
            ],
            [
              355389.63353527279,
              5007961.1518903729
            ],
            [
              355391.02654301637,
              5007959.9133513067
            ],
            [
              355391.7174101781,
              5007958.9910292625
            ],
            [
              355392.1049726131,
              5007958.074348622
            ],
            [
              355393.08035592618,
              5007956.1362338308
            ],
            [
              355394.06519912614,
              5007954.7031579614
            ],
            [
              355395.55555086891,
              5007953.2607153095
            ],
            [
              355398.1449999155,
              5007951.0904507311
            ],
            [
              355400.64462078077,
              5007949.5281716604
            ],
            [
              355402.95519100764,
              5007948.6767878989
            ],
            [
              355404.97178452782,
              5007948.336122429
            ],
            [
              355407.59881129971,
              5007948.1861961074
            ],
            [
              355410.70258884277,
              5007948.3158426201
            ],
            [
              355415.353724849,
              5007948.2293326808
            ],
            [
              355417.77479082538,
              5007947.8811441427
            ],
            [
              355419.68276753492,
              5007947.1383128613
            ],
            [
              355421.9877002826,
              5007945.9838411389
            ],
            [
              355423.18414151081,
              5007945.0521156602
            ],
            [
              355424.77371561603,
              5007943.5067633716
            ],
            [
              355426.26601264375,
              5007942.1653490067
            ],
            [
              355427.76575856633,
              5007941.2279821709
            ],
            [
              355429.36479318509,
              5007940.1877043108
            ],
            [
              355432.1639609969,
              5007938.4177609403
            ],
            [
              355435.06053837965,
              5007936.4438772351
            ],
            [
              355440.37462643039,
              5007932.255932318
            ],
            [
              355443.36103263794,
              5007929.6740988493
            ],
            [
              355446.95216908952,
              5007926.9799533486
            ],
            [
              355450.75872670265,
              5007924.9891444854
            ],
            [
              355455.86282702192,
              5007922.064729902
            ],
            [
              355459.16575589211,
              5007920.1843527472
            ],
            [
              355462.27581026853,
              5007918.8128488874
            ],
            [
              355464.98514904885,
              5007917.6508556791
            ],
            [
              355467.2957189228,
              5007916.799472292
            ],
            [
              355468.501554302,
              5007916.3728226097
            ],
            [
              355470.00130011223,
              5007915.4354559993
            ],
            [
              355471.39618680603,
              5007914.2979467362
            ],
            [
              355472.58699128218,
              5007913.0631690593
            ],
            [
              355473.37332329591,
              5007911.8359144339
            ],
            [
              355473.95181554178,
              5007910.3093682714
            ],
            [
              355475.12383159046,
              5007908.0644395044
            ],
            [
              355476.0038156524,
              5007906.4312214442
            ],
            [
              355477.31923925574,
              5007904.7165323645
            ],
            [
              355479.88614137127,
              5007901.3340589171
            ],
            [
              355481.65919569921,
              5007898.7747583203
            ],
            [
              355482.7338677948,
              5007896.7337331986
            ],
            [
              355483.31417294324,
              5007895.3082176605
            ],
            [
              355484.20167279785,
              5007894.0790814338
            ],
            [
              355485.58340710576,
              5007892.234438112
            ],
            [
              355487.36961386731,
              5007890.3822717927
            ],
            [
              355488.36385192379,
              5007889.4543075776
            ],
            [
              355491.85576511646,
              5007886.8630370805
            ],
            [
              355493.84605406906,
              5007885.1081392542
            ],
            [
              355495.23349127959,
              5007883.5665472141
            ],
            [
              355496.72008468577,
              5007881.9220466558
            ],
            [
              355499.18776443449,
              5007878.6424833313
            ],
            [
              355503.32551862724,
              5007872.7045064075
            ],
            [
              355508.97230239882,
              5007864.8602240868
            ],
            [
              355516.39976680587,
              5007856.334738371
            ],
            [
              355520.85585587862,
              5007851.1992130736
            ],
            [
              355523.9414844503,
              5007848.5144711658
            ],
            [
              355524.93196499615,
              5007847.3844840825
            ],
            [
              355525.82127775211,
              5007846.2563786712
            ],
            [
              355527.10378904227,
              5007844.514609972
            ],
            [
              355527.89206651691,
              5007843.3883837759
            ],
            [
              355529.09032049385,
              5007842.557689487
            ],
            [
              355530.49090938474,
              5007841.7231963398
            ],
            [
              355531.6967452977,
              5007841.2965825349
            ],
            [
              355533.20588515309,
              5007840.8642919008
            ],
            [
              355534.71126749011,
              5007840.2299781917
            ],
            [
              355535.6119196972,
              5007839.7079763794
            ],
            [
              355536.4569749189,
              5007839.2113593323
            ],
            [
              355537.64959227626,
              5007838.0776126916
            ],
            [
              355540.33832968835,
              5007835.8044389086
            ],
            [
              355541.72945885238,
              5007834.4649071181
            ],
            [
              355543.02700150263,
              5007833.5313019268
            ],
            [
              355544.8244814715,
              5007832.2852409855
            ],
            [
              355547.51691011962,
              5007830.2140916018
            ],
            [
              355550.3123862886,
              5007828.242125432
            ],
            [
              355552.79885435442,
              5007825.9727138951
            ],
            [
              355556.08104931103,
              5007822.9811592409
            ],
            [
              355558.55248670216,
              5007819.9036199702
            ],
            [
              355560.43415842514,
              5007817.7465216238
            ],
            [
              355561.71291223069,
              5007815.8027301421
            ],
            [
              355563.18447502423,
              5007813.3501023948
            ],
            [
              355566.61257356993,
              5007807.3242622428
            ],
            [
              355568.08789455629,
              5007805.0736931646
            ],
            [
              355569.17196099943,
              5007803.5377442716
            ],
            [
              355570.35712897102,
              5007801.9999149693
            ],
            [
              355572.24067915668,
              5007799.9438105552
            ],
            [
              355578.15269772435,
              5007794.4263629224
            ],
            [
              355582.43288825377,
              5007790.7088681702
            ],
            [
              355586.62700849876,
              5007787.8014172055
            ],
            [
              355591.1263116842,
              5007784.9893188244
            ],
            [
              355595.0358491232,
              5007783.0976612465
            ],
            [
              355597.9418207226,
              5007781.6288907044
            ],
            [
              355600.55381552642,
              5007780.670838031
            ],
            [
              355602.05919767963,
              5007780.0365247102
            ],
            [
              355603.15829469723,
              5007779.3087037522
            ],
            [
              355604.35285668325,
              5007778.2759500593
            ],
            [
              355606.83744628361,
              5007775.905545488
            ],
            [
              355608.63492613035,
              5007774.6594850775
            ],
            [
              355610.83869109274,
              5007773.5069323257
            ],
            [
              355617.00743518682,
              5007771.1238520229
            ],
            [
              355620.71852685837,
              5007769.4379785983
            ],
            [
              355624.92385363596,
              5007767.1366340742
            ],
            [
              355628.2173208255,
              5007764.7511496926
            ],
            [
              355630.6083244349,
              5007762.7867073799
            ],
            [
              355632.3982886122,
              5007761.1365655372
            ],
            [
              355634.1995258446,
              5007760.0925283246
            ],
            [
              355635.30425999028,
              5007759.6677953396
            ],
            [
              355636.31158317951,
              5007759.4469314842
            ],
            [
              355638.03050753538,
              5007759.4149608817
            ],
            [
              355639.13899913518,
              5007759.192250926
            ],
            [
              355639.74003789114,
              5007758.8779149558
            ],
            [
              355639.82986634178,
              5007758.2699299492
            ],
            [
              355639.89151216642,
              5007756.1466834433
            ],
            [
              355640.25089224894,
              5007753.714742193
            ],
            [
              355640.63275217963,
              5007752.4950113744
            ],
            [
              355641.51837374229,
              5007751.1648823349
            ],
            [
              355642.30282689113,
              5007749.8365994031
            ],
            [
              355642.69880433573,
              5007748.7416299032
            ],
            [
              355643.1704927524,
              5007746.913914104
            ],
            [
              355643.53738846938,
              5007744.886054459
            ],
            [
              355643.89301051194,
              5007742.2520546885
            ],
            [
              355644.22790000081,
              5007738.5069117844
            ],
            [
              355644.89065096626,
              5007736.0693281498
            ],
            [
              355645.6544371997,
              5007733.6298653604
            ],
            [
              355646.62982040661,
              5007731.6917170975
            ],
            [
              355649.37644623098,
              5007727.0932754073
            ],
            [
              355652.04069287999,
              5007723.5069015622
            ],
            [
              355654.01970875921,
              5007721.145900921
            ],
            [
              355655.41278255667,
              5007719.9073625477
            ],
            [
              355658.7100078857,
              5007717.7239373773
            ],
            [
              355660.82841300097,
              5007715.9016404375
            ],
            [
              355662.72323684586,
              5007714.4516771352
            ],
            [
              355664.30724065559,
              5007712.6032734765
            ],
            [
              355665.39694369707,
              5007711.3703775816
            ],
            [
              355666.18139694614,
              5007710.0420948304
            ],
            [
              355666.8534757357,
              5007708.1095879227
            ],
            [
              355667.51991871645,
              5007705.8740642834
            ],
            [
              355668.19575561973,
              5007704.1436159303
            ],
            [
              355668.88098644017,
              5007702.9182428718
            ],
            [
              355669.56621726131,
              5007701.6928698076
            ],
            [
              355670.26090824598,
              5007700.9725708012
            ],
            [
              355672.15385305585,
              5007699.4215783523
            ],
            [
              355673.74718455283,
              5007698.0782513199
            ],
            [
              355674.63468462619,
              5007696.8491162788
            ],
            [
              355675.91907582741,
              5007695.208413695
            ],
            [
              355676.69977168861,
              5007693.6781080216
            ],
            [
              355677.18467792234,
              5007692.5574895134
            ],
            [
              355677.6695185664,
              5007691.4369077794
            ],
            [
              355678.4427651866,
              5007689.5025193542
            ],
            [
              355679.12799603731,
              5007688.2771463534
            ],
            [
              355681.58628245664,
              5007684.4925108906
            ],
            [
              355688.67210762593,
              5007672.653625383
            ],
            [
              355689.65131538193,
              5007670.9175348319
            ],
            [
              355690.63985074963,
              5007669.6865207711
            ],
            [
              355692.02540910261,
              5007668.0439012153
            ],
            [
              355694.30585056974,
              5007665.57623077
            ],
            [
              355696.0883000695,
              5007663.522043759
            ],
            [
              355697.96433558705,
              5007661.0618948815
            ],
            [
              355699.14010991302,
              5007659.0189914042
            ],
            [
              355700.2204191963,
              5007657.2810205668
            ],
            [
              355701.28945495433,
              5007654.9369097119
            ],
            [
              355702.25168721337,
              5007652.291663764
            ],
            [
              355702.73847257748,
              5007651.2720746594
            ],
            [
              355703.62027006067,
              5007649.7398888292
            ],
            [
              355705.39520415117,
              5007647.2816204494
            ],
            [
              355706.27706789813,
              5007645.7494334113
            ],
            [
              355707.26861730608,
              5007643.2444140771
            ],
            [
              355709.51899771753,
              5007639.1604534397
            ],
            [
              355711.09924432699,
              5007637.1100273933
            ],
            [
              355712.29192800348,
              5007635.9762807963
            ],
            [
              355714.18863026908,
              5007634.6273118742
            ],
            [
              355715.78008346137,
              5007633.1829915829
            ],
            [
              355717.07011056721,
              5007631.8453061311
            ],
            [
              355718.15417732025,
              5007630.3093584459
            ],
            [
              355719.03604111046,
              5007628.7771715075
            ],
            [
              355720.39898831857,
              5007625.9223800935
            ],
            [
              355721.08609767695,
              5007624.7980010919
            ],
            [
              355722.07469937298,
              5007623.5669860439
            ],
            [
              355723.3609691094,
              5007622.0272776783
            ],
            [
              355725.15274625324,
              5007620.4781674985
            ],
            [
              355727.3377890173,
              5007618.315428962
            ],
            [
              355730.72672200896,
              5007615.6250496665
            ],
            [
              355733.72440088779,
              5007613.6493255673
            ],
            [
              355746.18564525089,
              5007606.4077662313
            ],
            [
              355750.29181473254,
              5007604.2092973813
            ],
            [
              355753.79312119301,
              5007602.1231069146
            ],
            [
              355757.09974075021,
              5007600.4447941892
            ],
            [
              355760.10305521003,
              5007598.7720870329
            ],
            [
              355765.01622356899,
              5007596.4575450281
            ],
            [
              355766.62465208309,
              5007595.9223804707
            ],
            [
              355767.5308741866,
              5007595.7034330731
            ],
            [
              355768.4408536558,
              5007595.6865086239
            ],
            [
              355769.65420377842,
              5007595.6639418714
            ],
            [
              355770.76457341539,
              5007595.5422262372
            ],
            [
              355772.18012646452,
              5007595.5158987725
            ],
            [
              355773.49833540805,
              5007595.6934746085
            ],
            [
              355774.41213914211,
              5007595.8786074091
            ],
            [
              355775.3315123397,
              5007596.3667580914
            ],
            [
              355776.04868260509,
              5007596.8586694831
            ],
            [
              355776.75645911956,
              5007596.8455057479
            ],
            [
              355777.76384891447,
              5007596.6246767649
            ],
            [
              355778.36676659191,
              5007596.411370445
            ],
            [
              355779.26171524316,
              5007595.5862831576
            ],
            [
              355781.18816772144,
              5007593.6988425637
            ],
            [
              355782.28162818652,
              5007592.667970405
            ],
            [
              355783.28143632511,
              5007592.0430612545
            ],
            [
              355784.68195920548,
              5007591.2086065132
            ],
            [
              355786.78656680265,
              5007590.1589283533
            ],
            [
              355788.27691838628,
              5007588.7164889304
            ],
            [
              355788.86856330303,
              5007587.8970782841
            ],
            [
              355789.05385710805,
              5007586.9841610612
            ],
            [
              355789.34407599049,
              5007586.2713851836
            ],
            [
              355790.13040845364,
              5007585.044132432
            ],
            [
              355791.3174556591,
              5007583.6073341034
            ],
            [
              355792.11506086489,
              5007582.9861857034
            ],
            [
              355793.124328973,
              5007582.866350472
            ],
            [
              355794.03430837515,
              5007582.8494260591
            ],
            [
              355794.64661976142,
              5007583.1411948716
            ],
            [
              355795.66716060071,
              5007583.6274639731
            ],
            [
              355796.69139320654,
              5007584.3157927701
            ],
            [
              355797.60519621166,
              5007584.5008900538
            ],
            [
              355798.41031678143,
              5007584.2838230589
            ],
            [
              355799.11245688045,
              5007583.9676071908
            ],
            [
              355799.50565623055,
              5007583.3539802153
            ],
            [
              355799.79393051192,
              5007582.5402119001
            ],
            [
              355800.38369641389,
              5007581.6197720626
            ],
            [
              355801.18512521317,
              5007581.2006453797
            ],
            [
              355801.98836676788,
              5007580.8825491657
            ],
            [
              355802.9995138566,
              5007580.8637431711
            ],
            [
              355803.60612260556,
              5007580.8524610559
            ],
            [
              355804.11921152472,
              5007581.2471394511
            ],
            [
              355804.93003404111,
              5007581.3330878615
            ],
            [
              355806.24260714563,
              5007581.2076471141
            ],
            [
              355807.14882849256,
              5007580.9886642443
            ],
            [
              355807.64688731573,
              5007580.5752153993
            ],
            [
              355808.55498766951,
              5007580.4572617635
            ],
            [
              355809.56801374833,
              5007580.5394850038
            ],
            [
              355810.47987145517,
              5007580.6235542931
            ],
            [
              355811.39179607294,
              5007580.7076578839
            ],
            [
              355812.10520888277,
              5007580.9975463375
            ],
            [
              355813.42529667454,
              5007581.2761514354
            ],
            [
              355814.73786908539,
              5007581.1506751571
            ],
            [
              355816.0448057857,
              5007580.7221822664
            ],
            [
              355817.4509642651,
              5007580.1907442631
            ],
            [
              355818.65867785009,
              5007579.865125427
            ],
            [
              355819.76716898655,
              5007579.6424161671
            ],
            [
              355820.77643701487,
              5007579.5225809533
            ],
            [
              355824.75956485438,
              5007579.2073751204
            ],
            [
              355826.27621913468,
              5007579.1791673768
            ],
            [
              355827.18619844917,
              5007579.162242976
            ],
            [
              355828.2048608379,
              5007579.5475183539
            ],
            [
              355828.81529315212,
              5007579.7382579194
            ],
            [
              355829.63544374052,
              5007580.3293181453
            ],
            [
              355830.86194539117,
              5007581.0138849448
            ],
            [
              355832.1782750932,
              5007581.0904315878
            ],
            [
              355832.98527459375,
              5007580.9743938465
            ],
            [
              355833.99078522553,
              5007580.6525357245
            ],
            [
              355837.3030398236,
              5007579.2772401925
            ],
            [
              355838.6043394141,
              5007578.5456596725
            ],
            [
              355839.20349964109,
              5007578.130330517
            ],
            [
              355839.57791085955,
              5007576.5065180175
            ],
            [
              355840.52893687302,
              5007573.2551678661
            ],
            [
              355842.28689548437,
              5007569.8877457175
            ],
            [
              355844.14790082414,
              5007566.6194712427
            ],
            [
              355845.32179621252,
              5007564.4755397402
            ],
            [
              355846.60055040166,
              5007562.5317508029
            ],
            [
              355849.17315575224,
              5007559.4523344263
            ],
            [
              355853.33714774839,
              5007554.9285999751
            ],
            [
              355856.34053156059,
              5007551.4850477502
            ],
            [
              355858.72026147722,
              5007548.9144684663
            ],
            [
              355861.20485083514,
              5007546.5440673055
            ],
            [
              355863.89727940742,
              5007544.4729576008
            ],
            [
              355852.95547373942,
              5007527.1944208723
            ],
            [
              355857.15335026098,
              5007524.4889983078
            ],
            [
              355868.51841500087,
              5007542.770162303
            ],
            [
              355875.11474282673,
              5007538.5043473244
            ],
            [
              355874.34684591152,
              5007535.2849670695
            ],
            [
              355873.89728331205,
              5007532.8680728702
            ],
            [
              355873.85219273274,
              5007530.4436560804
            ],
            [
              355874.11610905541,
              5007528.3166491473
            ],
            [
              355874.68145071081,
              5007526.0829719985
            ],
            [
              355875.51260722749,
              5007523.3851721594
            ],
            [
              355877.54206408968,
              5007518.2948237769
            ],
            [
              355878.695292763,
              5007515.0396779412
            ],
            [
              355879.47223165416,
              5007513.3073506923
            ],
            [
              355879.74735443509,
              5007511.7864492266
            ],
            [
              355879.6237077823,
              5007510.5761211822
            ],
            [
              355879.30349456432,
              5007509.6726058954
            ],
            [
              355878.37654022238,
              5007508.7803750541
            ],
            [
              355877.15191787254,
              5007508.1968373032
            ],
            [
              355875.8261940807,
              5007507.6151798712
            ],
            [
              355874.10170083586,
              5007507.3440958587
            ],
            [
              355872.07940706291,
              5007507.381707401
            ],
            [
              355870.16391727008,
              5007507.7204894815
            ],
            [
              355868.35516520921,
              5007508.3604433406
            ],
            [
              355866.75056125934,
              5007509.0976645248
            ],
            [
              355865.24887082033,
              5007509.9340002947
            ],
            [
              355844.17167117412,
              5007524.1701594517
            ],
            [
              355840.2505102632,
              5007519.9988898085
            ],
            [
              355840.73166017554,
              5007518.6762847072
            ],
            [
              355842.79486824322,
              5007515.4042142881
            ],
            [
              355843.38275592454,
              5007514.3827809952
            ],
            [
              355843.9800365704,
              5007513.8663872024
            ],
            [
              355845.28133693821,
              5007513.1348424805
            ],
            [
              355846.28496860876,
              5007512.7119553471
            ],
            [
              355848.28465105785,
              5007511.4621365173
            ],
            [
              355849.67953756551,
              5007510.3246301431
            ],
            [
              355850.77675538108,
              5007509.4957813527
            ],
            [
              355851.96943899704,
              5007508.3620356582
            ],
            [
              355853.86614099762,
              5007507.0130679877
            ],
            [
              355855.96692486695,
              5007505.7613688866
            ],
            [
              355858.47781704966,
              5007504.8051993661
            ],
            [
              355861.42599994753,
              5007503.4786940636
            ],
            [
              355864.03423556575,
              5007502.318585814
            ],
            [
              355865.94408910075,
              5007501.6767516499
            ],
            [
              355866.64059284312,
              5007501.0574839031
            ],
            [
              355867.23599514522,
              5007500.4400964901
            ],
            [
              355868.13288969448,
              5007499.7160731824
            ],
            [
              355871.6398318907,
              5007497.9329365958
            ],
            [
              355873.24638107803,
              5007497.2967434544
            ],
            [
              355874.253704403,
              5007497.0759160593
            ],
            [
              355875.06076942559,
              5007496.9598417617
            ],
            [
              355876.56239360163,
              5007496.123507305
            ],
            [
              355879.85773926374,
              5007493.839056476
            ],
            [
              355883.65678001574,
              5007491.4442091556
            ],
            [
              355886.74804445123,
              5007489.0625253962
            ],
            [
              355890.13516416476,
              5007486.2711181827
            ],
            [
              355893.51658133575,
              5007483.1766601754
            ],
            [
              355895.89819048753,
              5007480.7071108455
            ],
            [
              355897.86969138955,
              5007477.9420319898
            ],
            [
              355900.13892657537,
              5007474.8682588954
            ],
            [
              355902.40064718795,
              5007471.390440098
            ],
            [
              355904.27381440665,
              5007468.1524410881
            ],
            [
              355904.45089139225,
              5007455.9218432307
            ],
            [
              355882.93711987365,
              5007435.8083567657
            ],
            [
              355888.90188395331,
              5007429.1108339233
            ],
            [
              355908.05248142692,
              5007447.2472017705
            ],
            [
              355914.80223158118,
              5007440.3511878774
            ],
            [
              355916.18772388314,
              5007438.7085715476
            ],
            [
              355916.9722445333,
              5007437.3803246915
            ],
            [
              355917.65747581329,
              5007436.1549530402
            ],
            [
              355918.33519189438,
              5007434.5255001383
            ],
            [
              355919.12528187665,
              5007433.5002710531
            ],
            [
              355920.21874304919,
              5007432.4694353538
            ],
            [
              355921.61362886848,
              5007431.3318940019
            ],
            [
              355922.90741392196,
              5007430.1962685017
            ],
            [
              355924.19932001142,
              5007428.9596138131
            ],
            [
              355925.28902325319,
              5007427.7267197464
            ],
            [
              355926.57153584907,
              5007425.9849901665
            ],
            [
              355928.93999377213,
              5007422.8083081683
            ],
            [
              355931.25660537492,
              5007420.1651412519
            ],
            [
              355932.9398320014,
              5007418.2138308892
            ],
            [
              355936.02170236729,
              5007415.3270376949
            ],
            [
              355940.2026701154,
              5007411.7124610217
            ],
            [
              355941.79788046313,
              5007410.4701655582
            ],
            [
              355942.70041056251,
              5007410.0491591459
            ],
            [
              355943.60663232341,
              5007409.8302123547
            ],
            [
              355950.06435070309,
              5007403.5459448732
            ],
            [
              355952.82120052434,
              5007383.1831633095
            ],
            [
              355950.46327715932,
              5007380.9288113629
            ],
            [
              355952.83361428441,
              5007377.853159084
            ],
            [
              355956.11991650873,
              5007380.52045184
            ],
            [
              355956.93067302677,
              5007380.606437454
            ],
            [
              355957.53171157738,
              5007380.2921024375
            ],
            [
              355958.33119571762,
              5007379.7719839979
            ],
            [
              355961.11915536161,
              5007377.3959070966
            ],
            [
              355963.30788981129,
              5007375.435231193
            ],
            [
              355964.6877460478,
              5007373.4895633357
            ],
            [
              355965.47038789332,
              5007372.0602876255
            ],
            [
              355966.14246800757,
              5007370.1277830536
            ],
            [
              355966.68902373145,
              5007366.8839570498
            ],
            [
              355966.82734969648,
              5007363.445595067
            ],
            [
              355966.48459226132,
              5007361.3298717877
            ],
            [
              355966.13801136153,
              5007359.012126903
            ],
            [
              355966.30076101236,
              5007356.887002185
            ],
            [
              355966.97478640371,
              5007355.0555256009
            ],
            [
              355967.79332625895,
              5007353.778227183
            ],
            [
              355969.37726436491,
              5007351.9298280552
            ],
            [
              355971.56982237991,
              5007350.17117396
            ],
            [
              355973.06768861518,
              5007349.1327820104
            ],
            [
              355974.76970285882,
              5007348.1916574556
            ],
            [
              355976.17210383486,
              5007347.4581978209
            ],
            [
              355977.47904021695,
              5007347.0297060208
            ],
            [
              355980.09291230654,
              5007346.1726868926
            ],
            [
              355981.79680480197,
              5007345.3325560177
            ],
            [
              355983.09058991534,
              5007344.196931039
            ],
            [
              355984.27951574622,
              5007342.8611279912
            ],
            [
              355985.25872433459,
              5007341.1250398662
            ],
            [
              355986.42691825138,
              5007338.6780592371
            ],
            [
              355987.70010330289,
              5007336.4312187051
            ],
            [
              355989.07996032533,
              5007334.4855866749
            ],
            [
              355990.96914717864,
              5007332.732539325
            ],
            [
              355992.95943609497,
              5007330.9776472496
            ],
            [
              355995.15568502364,
              5007329.4210174587
            ],
            [
              355997.26217107556,
              5007328.4723707214
            ],
            [
              355999.07280173228,
              5007327.933447226
            ],
            [
              356000.88906852173,
              5007327.6975757265
            ],
            [
              356002.95257343777,
              5007327.6272763358
            ],
            [
              356005.98594672384,
              5007327.5708618667
            ],
            [
              356008.71970739815,
              5007327.7221478075
            ],
            [
              356011.55644772272,
              5007327.9725471074
            ],
            [
              356013.58625511278,
              5007328.3389822533
            ],
            [
              356015.21152505837,
              5007328.7129765069
            ],
            [
              356017.34807062784,
              5007329.3806188805
            ],
            [
              356020.19420423143,
              5007330.1360929348
            ],
            [
              356023.13211211137,
              5007330.3846107004
            ],
            [
              356025.76289232256,
              5007330.4367477205
            ],
            [
              356027.58291617577,
              5007330.4028990269
            ],
            [
              356029.19691311137,
              5007330.1707537854
            ],
            [
              356030.50384931348,
              5007329.7422621204
            ],
            [
              356032.10664020688,
              5007328.9040117254
            ],
            [
              356033.70372936345,
              5007327.7627461478
            ],
            [
              356050.05997078109,
              5007312.7699899524
            ],
            [
              356050.71702228306,
              5007310.0293948269
            ],
            [
              356054.49762397743,
              5007312.0811809953
            ],
            [
              356054.21275762212,
              5007307.6401560036
            ],
            [
              356067.42802267813,
              5007300.3207463343
            ],
            [
              356064.08348210435,
              5007294.521905154
            ],
            [
              356066.88264810998,
              5007292.7519703135
            ],
            [
              356070.42006367975,
              5007298.0419394551
            ],
            [
              356087.88098746474,
              5007283.6231174562
            ],
            [
              356096.2466756567,
              5007269.6645537531
            ],
            [
              356108.83118964697,
              5007266.4999713488
            ],
            [
              356109.95805285854,
              5007261.8306349721
            ],
            [
              356108.45795999758,
              5007257.3111825725
            ],
            [
              356103.96582620294,
              5007255.0705363797
            ],
            [
              356105.83059029566,
              5007252.004288841
            ],
            [
              356121.09507723758,
              5007256.9750968525
            ],
            [
              356124.67688495264,
              5007253.7758878311
            ],
            [
              356127.27760510886,
              5007252.211736884
            ],
            [
              356129.37650933507,
              5007250.8590111993
            ],
            [
              356131.3800145124,
              5007249.8112164317
            ],
            [
              356132.9864966998,
              5007249.1750261625
            ],
            [
              356134.99745058577,
              5007248.5313136578
            ],
            [
              356137.30613873428,
              5007247.5789072998
            ],
            [
              356139.31333544006,
              5007246.7331720749
            ],
            [
              356141.82422628533,
              5007245.7770052394
            ],
            [
              356143.82954406185,
              5007244.8302409044
            ],
            [
              356146.43214302178,
              5007243.3671192434
            ],
            [
              356148.3307896094,
              5007242.1191820931
            ],
            [
              356150.16712379153,
              5007240.5105922967
            ],
            [
              356151.65559637389,
              5007238.9671267783
            ],
            [
              356153.33882295951,
              5007237.015818553
            ],
            [
              356156.40942077659,
              5007233.5229251217
            ],
            [
              356159.06803263113,
              5007229.6335085286
            ],
            [
              356160.63888644316,
              5007227.0780124692
            ],
            [
              356162.49613553315,
              5007223.6076846104
            ],
            [
              356163.96018506371,
              5007220.7509826962
            ],
            [
              356165.13408152945,
              5007218.6070543611
            ],
            [
              356166.21627048979,
              5007216.9701163871
            ],
            [
              356167.3976812475,
              5007215.2302335175
            ],
            [
              356171.89093290531,
              5007210.04380525
            ],
            [
              356175.16749066784,
              5007206.7491744822
            ],
            [
              356177.15965806431,
              5007205.0953132026
            ],
            [
              356179.76037804631,
              5007203.5311628617
            ],
            [
              356181.26394610276,
              5007202.7958227843
            ],
            [
              356182.87042814615,
              5007202.1596328327
            ],
            [
              356184.27665263263,
              5007201.6282312488
            ],
            [
              356185.78203331586,
              5007200.9939215416
            ],
            [
              356187.28553509776,
              5007200.2585827205
            ],
            [
              356189.5885870076,
              5007199.0031250063
            ],
            [
              356192.69112278003,
              5007197.2275496433
            ],
            [
              356198.49735799734,
              5007193.98693748
            ],
            [
              356206.10295090586,
              5007189.6012993539
            ],
            [
              356211.31948662095,
              5007187.2811598126
            ],
            [
              356214.43329361989,
              5007186.111652744
            ],
            [
              356217.148265658,
              5007185.2527552051
            ],
            [
              356219.26407829212,
              5007184.8092212239
            ],
            [
              356221.286303817,
              5007184.7716128593
            ],
            [
              356223.71487915941,
              5007184.8274759017
            ],
            [
              356225.63976043911,
              5007184.9937704531
            ],
            [
              356227.22241811501,
              5007185.3430338772
            ],
            [
              356228.44140266324,
              5007185.6235204041
            ],
            [
              356229.46194231743,
              5007186.109825613
            ],
            [
              356230.48429394583,
              5007186.6971256258
            ],
            [
              356231.71267161652,
              5007187.4826866146
            ],
            [
              356232.7388466156,
              5007188.2720080679
            ],
            [
              356238.25110478251,
              5007185.5421483647
            ],
            [
              356238.41204274626,
              5007183.3159950702
            ],
            [
              356263.489007956,
              5007172.0370532377
            ],
            [
              356263.16392146476,
              5007164.3071318353
            ],
            [
              356282.35192365199,
              5007151.824021427
            ],
            [
              356286.55578021461,
              5007154.878470161
            ],
            [
              356294.120040963,
              5007148.2704427913
            ],
            [
              356294.11252610775,
              5007147.8663619636
            ],
            [
              356294.09186108428,
              5007146.7551841093
            ],
            [
              356293.44949220802,
              5007144.8471271619
            ],
            [
              356292.91949726251,
              5007143.5433289977
            ],
            [
              356292.08807658526,
              5007142.3461650461
            ],
            [
              356291.26229187957,
              5007141.452052827
            ],
            [
              356289.83734786022,
              5007140.9733039839
            ],
            [
              356288.62024178199,
              5007140.7938109105
            ],
            [
              356287.20650343917,
              5007140.9211667785
            ],
            [
              356286.09995864675,
              5007141.2449021749
            ],
            [
              356285.39781912969,
              5007141.5611166935
            ],
            [
              356284.29496517271,
              5007142.0868759779
            ],
            [
              356276.921634781,
              5007148.0850394564
            ],
            [
              356273.2252299065,
              5007145.1222173106
            ],
            [
              356284.60168076865,
              5007136.8264818806
            ],
            [
              356285.80375717639,
              5007136.1978133023
            ],
            [
              356287.61626483611,
              5007135.7598848324
            ],
            [
              356289.13298337272,
              5007135.7316778582
            ],
            [
              356290.48796069104,
              5007135.570044172
            ],
            [
              356292.70306163596,
              5007135.023600054
            ],
            [
              356294.10921952763,
              5007134.4922001502
            ],
            [
              356295.20449237351,
              5007133.5623613112
            ],
            [
              356296.69490927731,
              5007132.1198892286
            ],
            [
              356298.48863078881,
              5007130.6718120445
            ],
            [
              356299.68507119891,
              5007129.7400917821
            ],
            [
              356301.79336856649,
              5007128.8924774313
            ],
            [
              356303.60211906658,
              5007128.252526382
            ],
            [
              356305.31352533022,
              5007127.8164781947
            ],
            [
              356306.72344026581,
              5007127.4871009775
            ],
            [
              356308.54158378445,
              5007127.3522243053
            ],
            [
              356309.94962049567,
              5007126.9218535442
            ],
            [
              356310.95325129316,
              5007126.4989681141
            ],
            [
              356312.15526137449,
              5007125.8703008508
            ],
            [
              356313.45286931505,
              5007124.9366991939
            ],
            [
              356314.34593950247,
              5007124.0106208688
            ],
            [
              356315.04244302417,
              5007123.3913546959
            ],
            [
              356315.8400478558,
              5007122.7702083094
            ],
            [
              356316.67417384381,
              5007122.2169555444
            ],
            [
              356336.45047390362,
              5007114.169192329
            ],
            [
              356338.86436520104,
              5007118.8737406023
            ],
            [
              356344.56949805928,
              5007115.6350468937
            ],
            [
              356341.84660008166,
              5007110.6330533084
            ],
            [
              356367.32615340309,
              5007099.2456045924
            ],
            [
              356366.99971726042,
              5007097.1022077678
            ],
            [
              356366.65320414625,
              5007094.784428535
            ],
            [
              356367.03506628203,
              5007093.5647365572
            ],
            [
              356368.02366803918,
              5007092.3337253472
            ],
            [
              356369.3230878085,
              5007091.5011187354
            ],
            [
              356370.52328580362,
              5007090.7714569354
            ],
            [
              356372.42937958584,
              5007089.9275678759
            ],
            [
              356373.8355372214,
              5007089.3961682785
            ],
            [
              356375.033856344,
              5007088.5654774336
            ],
            [
              356376.32951903646,
              5007087.5308482517
            ],
            [
              356377.66798149562,
              5007086.1248313943
            ],
            [
              356380.03831833816,
              5007083.0491849696
            ],
            [
              356381.02309612528,
              5007081.6161169345
            ],
            [
              356381.81701007514,
              5007080.7929469384
            ],
            [
              356382.80930263951,
              5007079.7639596472
            ],
            [
              356383.90652046347,
              5007078.935149054
            ],
            [
              356385.00185942726,
              5007078.0053094039
            ],
            [
              356386.08961803053,
              5007076.6714258064
            ],
            [
              356389.5271143284,
              5007071.1506816316
            ],
            [
              356391.59408110584,
              5007068.0806772383
            ],
            [
              356392.58456107776,
              5007066.9506597165
            ],
            [
              356394.37640351249,
              5007065.4015542138
            ],
            [
              356396.37044851977,
              5007063.848688337
            ],
            [
              356398.56488415657,
              5007062.1910673231
            ],
            [
              356400.66378748533,
              5007060.8383438718
            ],
            [
              356402.56236708717,
              5007059.5904098852
            ],
            [
              356403.95913170924,
              5007058.5539362393
            ],
            [
              356405.85207540874,
              5007057.0029506357
            ],
            [
              356410.01425473107,
              5007052.378199297
            ],
            [
              356413.0867309983,
              5007048.9863391807
            ],
            [
              356414.66674730158,
              5007046.7524592252
            ],
            [
              356415.45120142651,
              5007045.4241808811
            ],
            [
              356416.0409677212,
              5007044.5037436085
            ],
            [
              356416.62321928871,
              5007043.1792256534
            ],
            [
              356417.39264463063,
              5007041.0428214902
            ],
            [
              356418.91277579236,
              5007035.7598635554
            ],
            [
              356419.19165729341,
              5007034.4409874259
            ],
            [
              356419.67844414752,
              5007033.42143685
            ],
            [
              356421.1349794601,
              5007030.1606571982
            ],
            [
              356421.42332078988,
              5007029.3468895815
            ],
            [
              356421.91574286186,
              5007028.6303551104
            ],
            [
              356421.99805835687,
              5007027.6183265299
            ],
            [
              356421.98490777978,
              5007026.9111942239
            ],
            [
              356421.67590182036,
              5007026.6137843877
            ],
            [
              356408.0724588951,
              5007023.9362678295
            ],
            [
              356405.43040991656,
              5007023.2780252295
            ],
            [
              356404.0054665333,
              5007022.7992760576
            ],
            [
              356403.28078369278,
              5007021.9032836547
            ],
            [
              356403.26387610589,
              5007020.9941287274
            ],
            [
              356403.55402939889,
              5007020.2813558308
            ],
            [
              356404.05208737642,
              5007019.8678729776
            ],
            [
              356404.85532871162,
              5007019.5498141712
            ],
            [
              356406.36822280986,
              5007019.3195508346
            ],
            [
              356408.49349363183,
              5007019.3810911058
            ],
            [
              356425.63024294231,
              5007021.6897423742
            ],
            [
              356426.44287644763,
              5007021.876722238
            ],
            [
              356427.04955037223,
              5007021.8654398993
            ],
            [
              356427.45214355848,
              5007021.7569244141
            ],
            [
              356427.90251953597,
              5007021.3881594632
            ],
            [
              356432.59240475582,
              5007012.5094051221
            ],
            [
              356434.95334901719,
              5007008.9286855133
            ],
            [
              356436.93424351193,
              5007006.668687867
            ],
            [
              356438.91896193434,
              5007004.6107471138
            ],
            [
              356440.11728103668,
              5007003.7800567197
            ],
            [
              356441.1170883454,
              5007003.1551504796
            ],
            [
              356442.22182018874,
              5007002.7303853957
            ],
            [
              356443.22350632801,
              5007002.206508209
            ],
            [
              356443.92195423518,
              5007001.688234794
            ],
            [
              356445.21198174974,
              5007000.35059008
            ],
            [
              356449.45647628646,
              5006994.7137759253
            ],
            [
              356450.54047734622,
              5006993.1778347492
            ],
            [
              356451.12272905413,
              5006991.8533170149
            ],
            [
              356452.0008368275,
              5006990.119112419
            ],
            [
              356452.58872503828,
              5006989.0976818306
            ],
            [
              356453.40372244117,
              5006988.0219045216
            ],
            [
              356455.67671537364,
              5006985.1501621725
            ],
            [
              356458.43642901583,
              5006981.2588707088
            ],
            [
              356459.41751626175,
              5006979.6237795101
            ],
            [
              356459.99788983923,
              5006978.1982683251
            ],
            [
              356460.58577741118,
              5006977.1768022459
            ],
            [
              356462.667773897,
              5006974.9149603155
            ],
            [
              356463.76311289478,
              5006973.985121212
            ],
            [
              356464.86784470145,
              5006973.5603562454
            ],
            [
              356465.77782236336,
              5006973.5434334874
            ],
            [
              356466.89570462902,
              5006973.825800742
            ],
            [
              356468.00982989738,
              5006973.9061454488
            ],
            [
              356470.03581097996,
              5006974.0705609024
            ],
            [
              356453.48024708719,
              5006960.5830490654
            ],
            [
              356455.35440471885,
              5006958.0218803892
            ],
            [
              356459.97400039661,
              5006961.6749112634
            ],
            [
              356460.42475055117,
              5006953.2792134611
            ],
            [
              356460.78406922799,
              5006950.8472800609
            ],
            [
              356462.0121024563,
              5006946.1760325544
            ],
            [
              356463.36378017784,
              5006942.7151112817
            ],
            [
              356465.40827142232,
              5006938.4329362623
            ],
            [
              356468.04246229905,
              5006933.2302900497
            ],
            [
              356469.49342883349,
              5006929.6664584577
            ],
            [
              356470.15024486778,
              5006927.2480821302
            ],
            [
              356470.32990429137,
              5006926.0321154781
            ],
            [
              356470.11267258035,
              5006925.2277145376
            ],
            [
              356469.69505155791,
              5006924.528139716
            ],
            [
              356468.77380235231,
              5006923.938959172
            ],
            [
              356467.44995997549,
              5006923.4582939837
            ],
            [
              356464.91464934539,
              5006923.101257694
            ],
            [
              356462.78562192299,
              5006922.8376942836
            ],
            [
              356459.55380700779,
              5006923.0998879531
            ],
            [
              356457.02976720891,
              5006923.3489193106
            ],
            [
              356454.10883689357,
              5006924.0095519368
            ],
            [
              356450.78524777357,
              5006924.778736704
            ],
            [
              356447.86243859486,
              5006925.3383403076
            ],
            [
              356445.64733897138,
              5006925.8848183667
            ],
            [
              356443.72621600586,
              5006925.9205450248
            ],
            [
              356442.40425234288,
              5006925.5409088591
            ],
            [
              356441.17963298463,
              5006924.9573699692
            ],
            [
              356439.64419520803,
              5006923.9753908822
            ],
            [
              356438.30156724394,
              5006922.4845773
            ],
            [
              356436.84650020505,
              5006920.3895418597
            ],
            [
              356435.79221382446,
              5006918.0849607615
            ],
            [
              356435.14045375463,
              5006915.6718299845
            ],
            [
              356435.09912523307,
              5006913.4494751552
            ],
            [
              356435.45099589642,
              5006910.6134597166
            ],
            [
              356436.29898025125,
              5006907.2629693486
            ],
            [
              356437.06464944343,
              5006904.9245430445
            ],
            [
              356437.93900008005,
              5006902.9882804966
            ],
            [
              356439.40868686995,
              5006900.4346338399
            ],
            [
              356441.38958271558,
              5006898.1746723847
            ],
            [
              356443.67572678433,
              5006896.0100627895
            ],
            [
              356446.27268922538,
              5006894.2438934427
            ],
            [
              356449.18241442117,
              5006892.9771566121
            ],
            [
              356452.80360544566,
              5006891.8992813388
            ],
            [
              356455.32388769364,
              5006891.4481921224
            ],
            [
              356457.94909505191,
              5006891.1972796163
            ],
            [
              356460.88129689207,
              5006891.1427504681
            ],
            [
              356463.00656735752,
              5006891.2042915244
            ],
            [
              356464.52885464003,
              5006891.4791384665
            ],
            [
              356466.04550616822,
              5006891.4509338196
            ],
            [
              356467.86364894331,
              5006891.3160584597
            ],
            [
              356470.32922215998,
              5006890.7171456944
            ],
            [
              356473.85119050491,
              5006889.7421796983
            ],
            [
              356476.36771565943,
              5006889.0890679806
            ],
            [
              356478.5846946091,
              5006888.6436547842
            ],
            [
              356480.39908037597,
              5006888.3067569043
            ],
            [
              356482.52240566665,
              5006888.2672701729
            ],
            [
              356485.04644464509,
              5006888.0182035565
            ],
            [
              356487.26711422793,
              5006887.7748141447
            ],
            [
              356489.17884426954,
              5006887.2340136012
            ],
            [
              356491.39018673496,
              5006886.4855133295
            ],
            [
              356493.49472593481,
              5006885.435842881
            ],
            [
              356496.19473436777,
              5006883.7688215254
            ],
            [
              356498.69247335615,
              5006882.10552618
            ],
            [
              356500.48424982477,
              5006880.5564233493
            ],
            [
              356502.667413558,
              5006878.2926653577
            ],
            [
              356504.65394491924,
              5006876.3357560001
            ],
            [
              356506.83898745058,
              5006874.1730270917
            ],
            [
              356509.22992317256,
              5006872.2085616868
            ],
            [
              356511.12662473129,
              5006870.8596356418
            ],
            [
              356516.87892872043,
              5006867.5523508284
            ],
            [
              356518.97977714584,
              5006866.3006568216
            ],
            [
              356520.88774945779,
              5006865.557798394
            ],
            [
              356523.09714738122,
              5006864.7083060751
            ],
            [
              356525.80460328469,
              5006863.4453312876
            ],
            [
              356528.30985667097,
              5006862.1861167923
            ],
            [
              356529.70856576686,
              5006861.2506365804
            ],
            [
              356531.2026741397,
              5006860.0102259396
            ],
            [
              356532.38784304674,
              5006858.4724041848
            ],
            [
              356533.86880185653,
              5006856.5248970166
            ],
            [
              356535.23557479912,
              5006853.8721011505
            ],
            [
              356538.25545241957,
              5006847.6517868116
            ],
            [
              356540.56373132195,
              5006841.2425779151
            ],
            [
              356541.8910551343,
              5006836.4684570311
            ],
            [
              356542.7238408337,
              5006831.6342135733
            ],
            [
              356543.0775908496,
              5006828.8992281202
            ],
            [
              356543.20277183014,
              5006824.7537400303
            ],
            [
              356543.03779993171,
              5006821.3210599152
            ],
            [
              356542.69685894583,
              5006819.3063698765
            ],
            [
              356542.05824925256,
              5006817.6003361912
            ],
            [
              356541.42527590535,
              5006816.1973895589
            ],
            [
              356540.39165447425,
              5006815.0039859181
            ],
            [
              356539.26062244287,
              5006814.0144861331
            ],
            [
              356530.75000839442,
              5006807.8064700933
            ],
            [
              356530.25758601457,
              5006808.5230040224
            ],
            [
              356527.27647623449,
              5006805.9510988761
            ],
            [
              356532.20808407612,
              5006799.1899492843
            ],
            [
              356542.98264005495,
              5006807.4779586112
            ],
            [
              356544.41321823705,
              5006808.2597600091
            ],
            [
              356546.44114382326,
              5006808.5252041882
            ],
            [
              356548.46148869628,
              5006808.3865690585
            ],
            [
              356550.77762350003,
              5006807.8382485704
            ],
            [
              356552.68371764774,
              5006806.9943970572
            ],
            [
              356554.68158605805,
              5006805.6435546549
            ],
            [
              356556.77297566767,
              5006803.8867883859
            ],
            [
              356559.54584000335,
              5006800.7025960125
            ],
            [
              356562.54311419843,
              5006797.0337945595
            ],
            [
              356564.59881107818,
              5006793.3576903418
            ],
            [
              356565.95431373786,
              5006790.0988274943
            ],
            [
              356566.62451719458,
              5006788.0652984092
            ],
            [
              356566.98571570253,
              5006785.7343949405
            ],
            [
              356567.13350670232,
              5006782.8011474665
            ],
            [
              356567.34442098642,
              5006772.3888469106
            ],
            [
              356567.50160460838,
              5006769.9606734365
            ],
            [
              356567.87407450954,
              5006768.235872997
            ],
            [
              356568.35146878049,
              5006766.7112137126
            ],
            [
              356569.04045869678,
              5006765.6879037404
            ],
            [
              356569.63210358442,
              5006764.868460834
            ],
            [
              356570.72744281305,
              5006763.9386226833
            ],
            [
              356572.22343012394,
              5006762.7992416248
            ],
            [
              356576.82013951225,
              5006759.7832609033
            ],
            [
              356581.43422705674,
              5006756.7074491633
            ],
            [
              356621.8197991061,
              5006769.0931758769
            ],
            [
              356624.01201582543,
              5006761.8777225735
            ],
            [
              356615.31235237466,
              5006756.3805988915
            ],
            [
              356619.44782776351,
              5006744.8848206075
            ],
            [
              356606.26731114567,
              5006737.7531140987
            ],
            [
              356605.24677390099,
              5006737.2668436207
            ],
            [
              356604.53899905045,
              5006737.2800055472
            ],
            [
              356604.13452788413,
              5006737.2875271766
            ],
            [
              356603.43238864117,
              5006737.6037405785
            ],
            [
              356594.16799199878,
              5006745.2538663121
            ],
            [
              356588.79406858905,
              5006744.5453961212
            ],
            [
              356589.23764926073,
              5006741.2024280559
            ],
            [
              356593.19602291187,
              5006741.9372221734
            ],
            [
              356603.6531032396,
              5006733.1533559747
            ],
            [
              356615.52561603632,
              5006739.2304944508
            ],
            [
              356617.05917374376,
              5006740.1114448691
            ],
            [
              356618.07219725096,
              5006740.1936704013
            ],
            [
              356618.77809331642,
              5006740.0794794681
            ],
            [
              356620.17861488403,
              5006739.2450302299
            ],
            [
              356621.28146838589,
              5006738.7192727504
            ],
            [
              356622.09034442471,
              5006738.704230723
            ],
            [
              356622.49481557001,
              5006738.6967090918
            ],
            [
              356623.71379835834,
              5006738.9771968853
            ],
            [
              356625.24172041909,
              5006739.55509583
            ],
            [
              356626.76970872679,
              5006740.1329935389
            ],
            [
              356628.50546882889,
              5006741.0101837199
            ],
            [
              356631.98463522777,
              5006743.1686065001
            ],
            [
              356634.845791217,
              5006744.73224496
            ],
            [
              356637.70889153966,
              5006746.3968756152
            ],
            [
              356640.15430513892,
              5006747.3619327825
            ],
            [
              356642.08669826289,
              5006747.9323100224
            ],
            [
              356644.51902809466,
              5006748.1902340744
            ],
            [
              356647.10573899181,
              5006748.0895905364
            ],
            [
              356649.32459512475,
              5006747.7451719251
            ],
            [
              356651.84299736941,
              5006747.1930550374
            ],
            [
              356654.0542735075,
              5006746.4445927925
            ],
            [
              356655.85926536174,
              5006745.6025864389
            ],
            [
              356657.76348032319,
              5006744.6577065419
            ],
            [
              356659.8717764584,
              5006743.8100954415
            ],
            [
              356661.88279447844,
              5006743.1663856534
            ],
            [
              356663.89562499308,
              5006742.6237060903
            ],
            [
              356666.51137149584,
              5006741.8676867317
            ],
            [
              356668.22089835693,
              5006741.3306474835
            ],
            [
              356669.72252093122,
              5006740.4943182608
            ],
            [
              356671.21857348207,
              5006739.354900863
            ],
            [
              356691.86096932384,
              5006720.8161051478
            ],
            [
              356732.48437991802,
              5006685.0240895441
            ],
            [
              356724.59131250333,
              5006673.9540913403
            ],
            [
              356729.94805525668,
              5006668.2966379942
            ],
            [
              356732.31685330288,
              5006670.5767731778
            ],
            [
              356735.58965412871,
              5006667.0801305249
            ],
            [
              356737.47132670006,
              5006664.9230458625
            ],
            [
              356739.25565555121,
              5006662.969899226
            ],
            [
              356742.10902375891,
              5006658.6726519288
            ],
            [
              356745.63628712232,
              5006652.5439360943
            ],
            [
              356749.36775136477,
              5006645.9344356088
            ],
            [
              356750.9235120946,
              5006642.5707872184
            ],
            [
              356752.17409056617,
              5006639.1117501101
            ],
            [
              356752.83678163949,
              5006636.6742131887
            ],
            [
              356753.28787673166,
              5006633.7352916179
            ],
            [
              356753.63035709149,
              5006630.3942053569
            ],
            [
              356753.65819665761,
              5006626.4526578505
            ],
            [
              356753.88260313653,
              5006622.2042988725
            ],
            [
              356754.14464636619,
              5006619.9763046335
            ],
            [
              356754.62204103998,
              5006618.4516463755
            ],
            [
              356755.3933469961,
              5006616.4162742151
            ],
            [
              356756.8555203565,
              5006613.4585508388
            ],
            [
              356757.92545874894,
              5006610.5390840191
            ],
            [
              356760.12331276893,
              5006603.6266503707
            ],
            [
              356761.27466979314,
              5006600.2705580452
            ],
            [
              356762.12453443796,
              5006597.0210650284
            ],
            [
              356763.18793934671,
              5006594.3739488898
            ],
            [
              356763.86941519892,
              5006592.9465595521
            ],
            [
              356766.82576331444,
              5006588.7484265026
            ],
            [
              356769.28217542928,
              5006584.8627818907
            ],
            [
              356773.13801272033,
              5006578.5619110074
            ],
            [
              356779.51488948328,
              5006567.9339280892
            ],
            [
              356783.65452910319,
              5006562.0969824176
            ],
            [
              356775.44535047625,
              5006555.7822954245
            ],
            [
              356773.96063490928,
              5006557.5278130965
            ],
            [
              356772.11619442678,
              5006556.24842335
            ],
            [
              356775.36269753706,
              5006551.3375545181
            ],
            [
              356786.13160448702,
              5006559.322514493
            ],
            [
              356789.37247197819,
              5006554.1085945228
            ],
            [
              356790.84786222788,
              5006551.8580375761
            ],
            [
              356792.26235705317,
              5006549.9573776415
            ],
            [
              356793.74331584154,
              5006548.0098377373
            ],
            [
              356795.72796871682,
              5006545.9519029949
            ],
            [
              356797.71644458064,
              5006544.0959894005
            ],
            [
              356800.11113691732,
              5006542.3335503107
            ],
            [
              356803.0058328383,
              5006540.2586928131
            ],
            [
              356805.09722230537,
              5006538.5019294284
            ],
            [
              356807.188678023,
              5006536.7451648358
            ],
            [
              356808.87559589167,
              5006534.9958873447
            ],
            [
              356810.75921384146,
              5006532.9398315735
            ],
            [
              356812.34127381624,
              5006530.9904118087
            ],
            [
              356814.02262318204,
              5006528.9381173868
            ],
            [
              356815.6009264398,
              5006526.7866753098
            ],
            [
              356817.66232637997,
              5006523.4136254648
            ],
            [
              356820.23595585051,
              5006519.1560285417
            ],
            [
              356825.14502354956,
              5006511.1826836932
            ],
            [
              356829.37261526991,
              5006504.6367279571
            ],
            [
              356832.12864092569,
              5006500.5434209937
            ],
            [
              356826.5821722261,
              5006495.9981554756
            ],
            [
              356828.96378206165,
              5006493.528619675
            ],
            [
              356834.20688094973,
              5006498.0795265166
            ],
            [
              356849.63019676029,
              5006482.6680860687
            ],
            [
              356848.18083577207,
              5006480.8761012852
            ],
            [
              356866.73746861186,
              5006461.6343295733
            ],
            [
              356868.78974590247,
              5006463.2130106874
            ],
            [
              356872.53806410241,
              5006458.0906849504
            ],
            [
              356874.79596573574,
              5006454.4108615741
            ],
            [
              356876.86487939645,
              5006451.4418573668
            ],
            [
              356878.93936282728,
              5006448.7759411959
            ],
            [
              356882.15365749382,
              5006445.3121347418
            ],
            [
              356877.22895775526,
              5006441.563746783
            ],
            [
              356879.50376489171,
              5006438.7930417331
            ],
            [
              356884.63636845897,
              5006442.8407196831
            ],
            [
              356887.51040157891,
              5006439.6546875434
            ],
            [
              356889.29848761117,
              5006437.9035652308
            ],
            [
              356892.98890949733,
              5006435.1065364853
            ],
            [
              356896.67550778453,
              5006432.1074511912
            ],
            [
              356899.86605925107,
              5006429.6228738157
            ],
            [
              356902.66334428935,
              5006427.7519214088
            ],
            [
              356905.5617967103,
              5006425.8790877908
            ],
            [
              356907.95461090532,
              5006424.0156566957
            ],
            [
              356909.04994998622,
              5006423.0858205454
            ],
            [
              356910.3362213592,
              5006421.5461575137
            ],
            [
              356911.92022642464,
              5006419.6977665061
            ],
            [
              356913.49477260018,
              5006417.3442677148
            ],
            [
              356914.86718341877,
              5006414.9945632564
            ],
            [
              356916.56596519687,
              5006412.151138139
            ],
            [
              356934.82386155246,
              5006376.8476622934
            ],
            [
              356937.65281262883,
              5006371.2372216936
            ],
            [
              356938.34575042309,
              5006369.324883664
            ],
            [
              356938.82120105904,
              5006367.6991988933
            ],
            [
              356939.10015096283,
              5006366.3803240554
            ],
            [
              356939.47262249485,
              5006364.6555259097
            ],
            [
              356939.84703827067,
              5006363.0317199063
            ],
            [
              356940.32819111808,
              5006361.7091206405
            ],
            [
              356941.01154557144,
              5006360.3827258674
            ],
            [
              356941.99639169528,
              5006358.9496965371
            ],
            [
              356943.57845205866,
              5006357.0002783164
            ],
            [
              356952.06745075475,
              5006345.7267178586
            ],
            [
              356953.26013441948,
              5006344.5929798279
            ],
            [
              356954.75048555381,
              5006343.1505151531
            ],
            [
              356955.93565532792,
              5006341.6126970695
            ],
            [
              356956.91862284968,
              5006340.0786389569
            ],
            [
              356958.10003601754,
              5006338.3387986254
            ],
            [
              356958.88261367817,
              5006336.9095306238
            ],
            [
              356959.65955609677,
              5006335.1772114625
            ],
            [
              356960.44025513186,
              5006333.6469145706
            ],
            [
              356963.67337124486,
              5006328.0588198742
            ],
            [
              356916.12817096891,
              5006296.353690017
            ],
            [
              356922.62688342546,
              5006286.8350114133
            ],
            [
              356973.11914787942,
              5006307.1350165373
            ],
            [
              356988.61457799084,
              5006287.0761578521
            ],
            [
              356941.38472842803,
              5006265.6219140906
            ],
            [
              356942.44625672838,
              5006262.8737714393
            ],
            [
              356992.86200889951,
              5006285.300172464
            ],
            [
              357004.31137540954,
              5006270.7003064658
            ],
            [
              357009.94700381364,
              5006263.7239552028
            ],
            [
              357023.9376507558,
              5006260.0280584488
            ],
            [
              357028.50081267528,
              5006260.6505473498
            ],
            [
              357030.71182164864,
              5006254.4452879811
            ],
            [
              357003.270739567,
              5006247.3594817063
            ],
            [
              356921.79877672816,
              5006231.3693808019
            ],
            [
              356915.03620545281,
              5006243.0150566883
            ],
            [
              356911.76682668971,
              5006241.256914054
            ],
            [
              356925.67049910745,
              5006215.5863497164
            ],
            [
              356929.54655067943,
              5006217.3332116241
            ],
            [
              356931.37596157059,
              5006217.8044424038
            ],
            [
              356934.42059717968,
              5006218.354140291
            ],
            [
              356938.0625141309,
              5006218.387483797
            ],
            [
              356941.60138505691,
              5006218.3216795763
            ],
            [
              356944.83131740853,
              5006217.958464005
            ],
            [
              356948.25976104074,
              5006217.3894295115
            ],
            [
              356952.19183234795,
              5006216.7100019436
            ],
            [
              356958.79866226396,
              5006215.9853527248
            ],
            [
              356961.21784058801,
              5006215.536149078
            ],
            [
              356963.33552786434,
              5006215.1936153974
            ],
            [
              356965.24725633668,
              5006214.6528193476
            ],
            [
              356967.05976161332,
              5006214.21489661
            ],
            [
              356969.37777250499,
              5006213.7675741613
            ],
            [
              356971.69960683404,
              5006213.5223082807
            ],
            [
              356974.02519768541,
              5006213.4790646648
            ],
            [
              356975.74404928141,
              5006213.4471031679
            ],
            [
              356978.37488832435,
              5006213.4992473871
            ],
            [
              357003.09192254854,
              5006214.5578389261
            ],
            [
              357023.59357508476,
              5006215.9047266897
            ],
            [
              357026.11411992187,
              5006220.9104798744
            ],
            [
              357027.4774045365,
              5006223.51246922
            ],
            [
              357028.72643981833,
              5006225.4092429355
            ],
            [
              357029.96796135121,
              5006226.9019366484
            ],
            [
              357031.81052062562,
              5006228.0802983344
            ],
            [
              357033.43960737943,
              5006228.6563176941
            ],
            [
              357035.06299272261,
              5006228.9292871803
            ],
            [
              357037.4878056444,
              5006228.7831346411
            ],
            [
              357039.60361383384,
              5006228.3395720813
            ],
            [
              357041.20828200568,
              5006227.6023936989
            ],
            [
              357042.50582340249,
              5006226.6687986422
            ],
            [
              357043.99805325194,
              5006225.3273637323
            ],
            [
              357045.78238236468,
              5006223.374185578
            ],
            [
              357052.10894650378,
              5006215.4755561398
            ],
            [
              357062.52239396767,
              5006202.3706383687
            ],
            [
              357072.11418147938,
              5006190.5713318223
            ],
            [
              357082.78259067523,
              5006176.8320077537
            ],
            [
              357090.8129126167,
              5006168.0932601569
            ],
            [
              357098.68074702244,
              5006159.4054534445
            ],
            [
              357102.7342497693,
              5006154.3785590585
            ],
            [
              357105.58762135229,
              5006150.0813205075
            ],
            [
              357107.94857002539,
              5006146.500613804
            ],
            [
              357109.11119985452,
              5006143.7505929293
            ],
            [
              357109.68406216503,
              5006141.9210059419
            ],
            [
              357110.04526383907,
              5006139.5900712786
            ],
            [
              357110.1856236544,
              5006136.0715577286
            ],
            [
              357110.31087972067,
              5006131.9261109931
            ],
            [
              357110.12337412557,
              5006127.2811964927
            ],
            [
              357109.65128349228,
              5006123.6521404684
            ],
            [
              357108.53870915802,
              5006118.2160233902
            ],
            [
              357107.02736561489,
              5006113.0904769618
            ],
            [
              357105.8437434808,
              5006109.2724882467
            ],
            [
              357104.25759627071,
              5006105.5630835416
            ],
            [
              357101.83433204895,
              5006100.3534297794
            ],
            [
              357099.7049342242,
              5006096.1703852303
            ],
            [
              357097.72370735992,
              5006092.9735389585
            ],
            [
              357093.9728486719,
              5006087.081230808
            ],
            [
              357090.62646065751,
              5006081.1814016821
            ],
            [
              357088.2144667395,
              5006076.5778496535
            ],
            [
              357086.73693380115,
              5006073.2706090976
            ],
            [
              357085.67326363077,
              5006070.4609564664
            ],
            [
              357084.60389155819,
              5006067.3482184559
            ],
            [
              357083.73866650305,
              5006064.3327482622
            ],
            [
              357083.37622854213,
              5006062.0456272829
            ],
            [
              357083.22629032272,
              5006059.4210409475
            ],
            [
              357083.48639215127,
              5006057.0920216264
            ],
            [
              357083.84578278748,
              5006054.660092311
            ],
            [
              357084.50096316973,
              5006051.8184433877
            ],
            [
              357085.27227175247,
              5006049.7830744386
            ],
            [
              357086.06055157183,
              5006048.6568574449
            ],
            [
              357087.44792439864,
              5006047.1152811721
            ],
            [
              357088.8409322162,
              5006045.8767559715
            ],
            [
              357091.03724626935,
              5006044.3201381182
            ],
            [
              357093.13614941185,
              5006042.9674235983
            ],
            [
              357095.13583000697,
              5006041.7176178535
            ],
            [
              357096.74049832358,
              5006040.980440462
            ],
            [
              357099.95728089608,
              5006039.9100957662
            ],
            [
              357103.40093280305,
              5006038.6192568485
            ],
            [
              357107.82918296248,
              5006037.4253577264
            ],
            [
              357112.05710878706,
              5006036.3362117382
            ],
            [
              357116.87480237405,
              5006034.3266321067
            ],
            [
              357120.48659769708,
              5006032.7436947776
            ],
            [
              357122.88511377043,
              5006031.1833173744
            ],
            [
              357125.78168862901,
              5006029.2094949801
            ],
            [
              357128.56770408206,
              5006026.7324450053
            ],
            [
              357131.35002935765,
              5006024.0533716734
            ],
            [
              357133.13435916736,
              5006022.1001950568
            ],
            [
              357135.0103986669,
              5006019.6400653906
            ],
            [
              357137.59745590662,
              5006015.8798670182
            ],
            [
              357145.58464650682,
              5006004.8177796453
            ],
            [
              357150.10427730193,
              5005997.6600945601
            ],
            [
              357151.07973233185,
              5005995.7219584417
            ],
            [
              357152.13562721066,
              5005992.6707672905
            ],
            [
              357153.98349212785,
              5005988.6953829117
            ],
            [
              357161.61929254403,
              5005973.619888234
            ],
            [
              357165.90103427862,
              5005964.5466643777
            ],
            [
              357168.03529791563,
              5005959.656487735
            ],
            [
              357168.69423597184,
              5005957.016897372
            ],
            [
              357169.03860103193,
              5005953.7768446356
            ],
            [
              357168.85673277982,
              5005949.4349818118
            ],
            [
              357168.38276558468,
              5005945.7048973162
            ],
            [
              357167.81708797393,
              5005942.4817302721
            ],
            [
              357167.27019425482,
              5005940.2687805723
            ],
            [
              357166.53236753633,
              5005938.6656570369
            ],
            [
              357165.25954376062,
              5005936.5836567013
            ],
            [
              357163.29522408353,
              5005934.295998631
            ],
            [
              357161.23355971137,
              5005932.2122425446
            ],
            [
              357159.38724510832,
              5005930.8318220228
            ],
            [
              357157.74688967341,
              5005929.6496994616
            ],
            [
              357155.60478590621,
              5005928.6790338699
            ],
            [
              357153.15555562946,
              5005927.5119513758
            ],
            [
              357151.21753184032,
              5005926.6385193542
            ],
            [
              357149.58475536847,
              5005925.8604398286
            ],
            [
              357148.35262747994,
              5005924.872854244
            ],
            [
              357147.11486404861,
              5005923.5821821084
            ],
            [
              357145.67114210298,
              5005922.0932475142
            ],
            [
              357142.72760990349,
              5005921.5416674782
            ],
            [
              357145.68829252367,
              5005895.8194391774
            ],
            [
              357152.26810446719,
              5005896.1013153316
            ],
            [
              357153.9462854868,
              5005904.7605653945
            ],
            [
              357155.96474933223,
              5005904.5209425231
            ],
            [
              357158.28658263013,
              5005904.2756788684
            ],
            [
              357161.31055413443,
              5005903.7141684983
            ],
            [
              357163.71846238832,
              5005902.6589008598
            ],
            [
              357169.33751747297,
              5005900.230203541
            ],
            [
              357174.46227127418,
              5005898.4170442848
            ],
            [
              357176.26350614545,
              5005897.373021516
            ],
            [
              357177.46175912302,
              5005896.5423379885
            ],
            [
              357178.81295908109,
              5005895.6394914603
            ],
            [
              357190.85618784354,
              5005890.565070739
            ],
            [
              357201.76838973828,
              5005884.5011849543
            ],
            [
              357205.87267447059,
              5005882.201717156
            ],
            [
              357208.572615307,
              5005880.5346728293
            ],
            [
              357211.16582089552,
              5005878.5664929561
            ],
            [
              357213.25721062563,
              5005876.8097001407
            ],
            [
              357216.33156988415,
              5005873.5188872553
            ],
            [
              357219.01091610012,
              5005870.740666857
            ],
            [
              357222.19388974475,
              5005867.852054012
            ],
            [
              357230.71558768867,
              5005861.0420240061
            ],
            [
              357240.68963842181,
              5005853.4797694441
            ],
            [
              357249.76498366246,
              5005846.5405724254
            ],
            [
              357253.35423923098,
              5005843.7454340141
            ],
            [
              357255.63662827003,
              5005841.3788144346
            ],
            [
              357257.5183032059,
              5005839.2217375441
            ],
            [
              357260.38670337404,
              5005835.7326274458
            ],
            [
              357262.5585333588,
              5005832.8627804918
            ],
            [
              357263.6426033263,
              5005831.3268453674
            ],
            [
              357265.40251654881,
              5005828.0604735948
            ],
            [
              357267.7390498498,
              5005823.166538327
            ],
            [
              357271.4774876599,
              5005815.2348870886
            ],
            [
              357272.54089703684,
              5005812.5877772663
            ],
            [
              357273.10812606517,
              5005810.4551407937
            ],
            [
              357273.27276251925,
              5005808.4310181243
            ],
            [
              357273.22017172648,
              5005805.602566747
            ],
            [
              357272.77820264123,
              5005803.5897585377
            ],
            [
              357268.92061435344,
              5005797.3962435797
            ],
            [
              357271.21045059914,
              5005795.4337053644
            ],
            [
              357273.81149353,
              5005799.3263666835
            ],
            [
              357274.80573210883,
              5005798.3984133303
            ],
            [
              357278.18715097214,
              5005795.3039843664
            ],
            [
              357280.37219543458,
              5005793.1412671804
            ],
            [
              357282.36436262197,
              5005791.4873838658
            ],
            [
              357284.36028675304,
              5005790.0355581874
            ],
            [
              357286.661458046,
              5005788.6790851159
            ],
            [
              357289.86133602838,
              5005786.6996262567
            ],
            [
              357292.85713279567,
              5005784.6228627209
            ],
            [
              357296.64865673654,
              5005781.8239644459
            ],
            [
              357299.03966017027,
              5005779.8595454469
            ],
            [
              357300.66520726314,
              5005777.9284127904
            ],
            [
              357301.84286583093,
              5005775.9865540527
            ],
            [
              357302.52246555837,
              5005774.4581397595
            ],
            [
              357302.80322966707,
              5005773.2402968248
            ],
            [
              357302.87803478318,
              5005771.8241559081
            ],
            [
              357302.85549581161,
              5005770.6119524082
            ],
            [
              357302.83483531134,
              5005769.5007777112
            ],
            [
              357300.63826331164,
              5005765.6005960237
            ],
            [
              357302.73716694169,
              5005764.2479195688
            ],
            [
              357306.78763022384,
              5005769.932529428
            ],
            [
              357307.26871859428,
              5005768.609933625
            ],
            [
              357308.04760715825,
              5005766.9786095293
            ],
            [
              357308.43322858802,
              5005765.9609088795
            ],
            [
              357308.72720691486,
              5005765.4501952808
            ],
            [
              357309.72325769794,
              5005764.6232721526
            ],
            [
              357310.62202997698,
              5005764.0002496736
            ],
            [
              357315.01271310175,
              5005760.7859920282
            ],
            [
              357320.48746698734,
              5005756.0358353658
            ],
            [
              357323.56551646389,
              5005752.9470478753
            ],
            [
              357325.53708941198,
              5005750.1819891445
            ],
            [
              357327.1172730212,
              5005748.1315470915
            ],
            [
              357328.28366222727,
              5005745.5835868986
            ],
            [
              357329.051215165,
              5005743.3461624803
            ],
            [
              357329.42977823998,
              5005741.143271747
            ],
            [
              357329.80044028867,
              5005739.3174461983
            ],
            [
              357329.95568683016,
              5005736.788286522
            ],
            [
              357330.01734487491,
              5005734.665050759
            ],
            [
              357329.87304397585,
              5005732.3435170054
            ],
            [
              357325.92750511103,
              5005726.8590481123
            ],
            [
              357327.82420603529,
              5005725.5100960499
            ],
            [
              357329.69117927278,
              5005728.001691618
            ],
            [
              357331.17220667977,
              5005726.0541570522
            ],
            [
              357332.56333688233,
              5005724.7146407692
            ],
            [
              357333.85900010209,
              5005723.6800196022
            ],
            [
              357335.96165994182,
              5005722.529365587
            ],
            [
              357337.66367194051,
              5005721.5882528191
            ],
            [
              357340.76620440342,
              5005719.8126611868
            ],
            [
              357343.8555896653,
              5005717.3299746485
            ],
            [
              357346.34018101578,
              5005714.9595969478
            ],
            [
              357348.22185625182,
              5005712.8025213107
            ],
            [
              357350.38617423741,
              5005709.5286317784
            ],
            [
              357352.05437808548,
              5005706.769214116
            ],
            [
              357353.70943413436,
              5005703.3026660327
            ],
            [
              357354.96377679263,
              5005700.0456959475
            ],
            [
              357355.92789732042,
              5005697.5014613951
            ],
            [
              357356.44166149007,
              5005696.1005498357
            ],
            [
              357356.70927869907,
              5005694.1756122429
            ],
            [
              357351.6439839626,
              5005688.3077438259
            ],
            [
              357353.43582765793,
              5005686.7586498177
            ],
            [
              357357.46375037433,
              5005691.2310564471
            ],
            [
              357358.11142079928,
              5005687.9853663892
            ],
            [
              357358.60190030647,
              5005687.1678437637
            ],
            [
              357359.59613894467,
              5005686.2398909628
            ],
            [
              357361.48908362066,
              5005684.6889172187
            ],
            [
              357364.98669547454,
              5005682.4007328153
            ],
            [
              357367.28216640331,
              5005680.741246732
            ],
            [
              357369.07213161071,
              5005679.091124054
            ],
            [
              357370.66177323699,
              5005677.54578974
            ],
            [
              357372.53969235881,
              5005675.1866924316
            ],
            [
              357373.72298525856,
              5005673.5478497287
            ],
            [
              357374.60478731955,
              5005672.0156763922
            ],
            [
              357375.37609750975,
              5005669.9803100061
            ],
            [
              357375.95271812018,
              5005668.3527472438
            ],
            [
              357376.71081401111,
              5005666.0486684162
            ],
            [
              357377.78549350885,
              5005664.0076614851
            ],
            [
              357378.77403125615,
              5005662.7766592242
            ],
            [
              357379.56412320008,
              5005661.7514385562
            ],
            [
              357380.45913927478,
              5005660.9263944458
            ],
            [
              357381.36160151294,
              5005660.5053956211
            ],
            [
              357382.15739435301,
              5005659.7832245398
            ],
            [
              357382.93433924118,
              5005658.0509090321
            ],
            [
              357377.84275075991,
              5005650.7688152511
            ],
            [
              357379.43427024334,
              5005649.3244743515
            ],
            [
              357388.16051471612,
              5005661.692668952
            ],
            [
              357403.06741729553,
              5005636.5567484573
            ],
            [
              357392.23125771998,
              5005630.3919583103
            ],
            [
              357380.11102586967,
              5005631.3246843843
            ],
            [
              357378.5101810926,
              5005632.2639155881
            ],
            [
              357378.47637338395,
              5005630.4456107225
            ],
            [
              357377.25363796996,
              5005629.9630975723
            ],
            [
              357367.26300395932,
              5005642.0729492027
            ],
            [
              357364.18268689694,
              5005639.603913161
            ],
            [
              357365.36973685119,
              5005638.1671281112
            ],
            [
              357365.34344216046,
              5005636.7529027844
            ],
            [
              357365.42576087621,
              5005635.7408417622
            ],
            [
              357365.49117639306,
              5005633.8196638068
            ],
            [
              357365.86183897528,
              5005631.9938385608
            ],
            [
              357366.33729251713,
              5005630.3681568205
            ],
            [
              357367.10115618299,
              5005627.9287096346
            ],
            [
              357368.26238818618,
              5005624.4973650714
            ],
            [
              357369.89115036867,
              5005619.616592234
            ],
            [
              357370.33661942551,
              5005616.3746619541
            ],
            [
              357370.28590799053,
              5005613.6472045891
            ],
            [
              357370.0629797288,
              5005612.5397908567
            ],
            [
              357369.74277289171,
              5005611.6362776924
            ],
            [
              357369.61349887965,
              5005610.1229033591
            ],
            [
              357369.47859027522,
              5005608.3064782061
            ],
            [
              357369.75948687311,
              5005590.1391860778
            ],
            [
              357370.01214493072,
              5005587.4060882013
            ],
            [
              357370.48578651767,
              5005585.6793765221
            ],
            [
              357371.26461021742,
              5005584.0480899047
            ],
            [
              357372.94784028473,
              5005582.096796684
            ],
            [
              357375.53541178128,
              5005579.8255691817
            ],
            [
              357381.72357443394,
              5005575.3652720032
            ],
            [
              357386.52993158408,
              5005572.7496000314
            ],
            [
              357392.74251079006,
              5005569.6025351016
            ],
            [
              357398.35593061283,
              5005566.8708295859
            ],
            [
              357401.7618332518,
              5005565.0896347174
            ],
            [
              357403.90095575986,
              5005563.6793704387
            ],
            [
              357405.99234659859,
              5005561.922616058
            ],
            [
              357408.67169386992,
              5005559.1443996122
            ],
            [
              357411.45019748551,
              5005556.2632759251
            ],
            [
              357413.8186625613,
              5005553.0866204873
            ],
            [
              357415.79204875685,
              5005550.4225936262
            ],
            [
              357417.26361986471,
              5005547.9699887568
            ],
            [
              357421.0717935331,
              5005540.6234514974
            ],
            [
              357424.88929239084,
              5005533.782023944
            ],
            [
              357426.35335065372,
              5005530.9253396718
            ],
            [
              357427.24085399805,
              5005529.6962164743
            ],
            [
              357429.11126090941,
              5005526.9330408219
            ],
            [
              357429.6953950274,
              5005525.7095580883
            ],
            [
              357430.0772609089,
              5005524.4898360688
            ],
            [
              357430.46294983494,
              5005523.4721703529
            ],
            [
              357430.89048023691,
              5005522.7943145344
            ],
            [
              357435.04891179246,
              5005517.9675735151
            ],
            [
              357442.05126332765,
              5005508.3385320455
            ],
            [
              357445.59363148332,
              5005503.0179626951
            ],
            [
              357448.05005108,
              5005499.1323334733
            ],
            [
              357449.80433203041,
              5005495.562914392
            ],
            [
              357451.05304115737,
              5005492.0028596837
            ],
            [
              357451.90399327117,
              5005488.8684255304
            ],
            [
              357452.36630000983,
              5005486.5356141319
            ],
            [
              357452.62835008645,
              5005484.3076252565
            ],
            [
              357452.67498393724,
              5005481.3762312708
            ],
            [
              357452.49500085338,
              5005477.1354360487
            ],
            [
              357452.22330674873,
              5005473.4015577445
            ],
            [
              357451.43665092293,
              5005469.1720065353
            ],
            [
              357450.7510961353,
              5005464.9405755838
            ],
            [
              357450.26774337539,
              5005460.7053851793
            ],
            [
              357449.98290280771,
              5005456.2644121107
            ],
            [
              357450.12500408752,
              5005453.0281231729
            ],
            [
              357450.4680864276,
              5005448.6102416599
            ],
            [
              357451.07444009878,
              5005443.1422044458
            ],
            [
              357451.48791549035,
              5005438.1829651101
            ],
            [
              357452.40327232302,
              5005433.0123027805
            ],
            [
              357453.4554152147,
              5005429.7590940287
            ],
            [
              357454.71163781226,
              5005426.6031191023
            ],
            [
              357456.3817217974,
              5005423.9447324052
            ],
            [
              357458.0723989057,
              5005422.3975211857
            ],
            [
              357460.98212102865,
              5005421.1308005452
            ],
            [
              357463.50052016566,
              5005420.5787302284
            ],
            [
              357466.03018748394,
              5005420.6327258553
            ],
            [
              357469.06354824879,
              5005420.5763284946
            ],
            [
              357471.80105258327,
              5005420.9296509782
            ],
            [
              357474.54231296293,
              5005421.4849954313
            ],
            [
              357477.28739564191,
              5005422.2423606161
            ],
            [
              357479.31524843647,
              5005422.5078135077
            ],
            [
              357482.70297253836,
              5005422.6148549579
            ],
            [
              357486.0415144533,
              5005422.6538472166
            ],
            [
              357489.37066517759,
              5005422.1877312548
            ],
            [
              357492.59120275901,
              5005421.3194510778
            ],
            [
              357495.40170132095,
              5005420.1556034964
            ],
            [
              357498.10539887036,
              5005418.6906216992
            ],
            [
              357501.40262128168,
              5005416.5072302986
            ],
            [
              357504.79725483904,
              5005414.1199360453
            ],
            [
              357507.39046022354,
              5005412.1517258435
            ],
            [
              357510.26443149225,
              5005408.9657093016
            ],
            [
              357512.83328708902,
              5005405.6842664061
            ],
            [
              357514.89462764893,
              5005402.3112313198
            ],
            [
              357515.9561611063,
              5005399.5630958965
            ],
            [
              357516.40520774957,
              5005397.2478080457
            ],
            [
              357516.65598954383,
              5005394.4137182636
            ],
            [
              357516.78870026686,
              5005390.6723217955
            ],
            [
              357516.62562087661,
              5005387.3406438576
            ],
            [
              357516.16292906465,
              5005384.2166637238
            ],
            [
              357515.49803468015,
              5005381.096407434
            ],
            [
              357514.62906069279,
              5005377.8789173104
            ],
            [
              357513.55781788012,
              5005374.6651522527
            ],
            [
              357512.05399994249,
              5005369.9436879866
            ],
            [
              357511.38910634012,
              5005366.8234671662
            ],
            [
              357511.3534217031,
              5005364.904134226
            ],
            [
              357511.62486293592,
              5005363.181218477
            ],
            [
              357512.29131698899,
              5005360.9456757847
            ],
            [
              357513.06638474757,
              5005359.1123328544
            ],
            [
              357514.04747673607,
              5005357.4772512782
            ],
            [
              357515.03225916653,
              5005356.0442284336
            ],
            [
              357516.96582835889,
              5005354.0689090081
            ],
            [
              357518.86065135972,
              5005352.6189310057
            ],
            [
              357520.96894569002,
              5005351.771330568
            ],
            [
              357523.49110052688,
              5005351.4212828018
            ],
            [
              357526.82776369777,
              5005351.3592470083
            ],
            [
              357530.16824911494,
              5005351.4992319262
            ],
            [
              357533.81760617025,
              5005351.9366652304
            ],
            [
              357536.75925578305,
              5005352.3872207925
            ],
            [
              357540.92364048108,
              5005353.3203257965
            ],
            [
              357545.69275255449,
              5005354.1411239225
            ],
            [
              357549.54437695892,
              5005354.5747610405
            ],
            [
              357552.98596311535,
              5005354.7128662318
            ],
            [
              357556.12230242882,
              5005354.7556185173
            ],
            [
              357559.75287775649,
              5005354.1828710521
            ],
            [
              357562.475422127,
              5005353.7280339729
            ],
            [
              357564.78779578069,
              5005352.9776674584
            ],
            [
              357567.95317972131,
              5005351.7522650044
            ],
            [
              357570.96587981324,
              5005350.5846588789
            ],
            [
              357574.27249301173,
              5005348.9063766878
            ],
            [
              357577.57159348327,
              5005346.8240151666
            ],
            [
              357580.85566853179,
              5005343.9334949581
            ],
            [
              357583.33838241961,
              5005341.4620928587
            ],
            [
              357585.31559156138,
              5005339.0000892021
            ],
            [
              357586.88826440991,
              5005336.5456067622
            ],
            [
              357588.63691199798,
              5005332.6731393188
            ],
            [
              357589.49805597588,
              5005330.0297940085
            ],
            [
              357589.95667385386,
              5005327.4949606135
            ],
            [
              357590.09390655317,
              5005324.6989929769
            ],
            [
              357589.81282387086,
              5005320.4600435011
            ],
            [
              357589.25466615526,
              5005317.6409942433
            ],
            [
              357588.70784272486,
              5005315.4280094756
            ],
            [
              357587.97183190455,
              5005313.9259164985
            ],
            [
              357586.42701349751,
              5005312.43886091
            ],
            [
              357584.37286808708,
              5005310.7591829943
            ],
            [
              357581.60719310556,
              5005308.8906065552
            ],
            [
              357577.40712537902,
              5005306.0382040767
            ],
            [
              357574.23322430725,
              5005303.9751255643
            ],
            [
              357571.36457065993,
              5005302.0074354913
            ],
            [
              357569.40964783652,
              5005300.2248490443
            ],
            [
              357567.9621081127,
              5005298.5338929528
            ],
            [
              357566.52661894553,
              5005296.5749634476
            ],
            [
              357565.16897779988,
              5005294.2760251183
            ],
            [
              357564.00421386253,
              5005291.4682180891
            ],
            [
              357563.35058969865,
              5005288.9540988971
            ],
            [
              357563.30739286885,
              5005286.6306868503
            ],
            [
              357563.5674995833,
              5005284.3016713429
            ],
            [
              357564.43810053711,
              5005282.1633973056
            ],
            [
              357565.7149836481,
              5005280.1186333541
            ],
            [
              357568.19769810518,
              5005277.6472316021
            ],
            [
              357570.39394635108,
              5005276.0906220563
            ],
            [
              357572.50224055699,
              5005275.2430222118
            ],
            [
              357574.01519697282,
              5005275.0127661154
            ],
            [
              357575.43449945922,
              5005275.1885058209
            ],
            [
              357577.15904870891,
              5005275.4595984956
            ],
            [
              357578.79369934736,
              5005276.338673343
            ],
            [
              357580.43217221351,
              5005277.4197688671
            ],
            [
              357582.07996976969,
              5005279.0059737004
            ],
            [
              357596.31273034733,
              5005292.1190142157
            ],
            [
              357601.36986929801,
              5005286.6692588264
            ],
            [
              357604.60813907563,
              5005284.4592411229
            ],
            [
              357605.7520432414,
              5005283.1613517562
            ],
            [
              357606.86473790149,
              5005281.952886438
            ],
            [
              357608.65816119447,
              5005279.9611532968
            ],
            [
              357610.79816855903,
              5005277.5845121769
            ],
            [
              357613.14059146884,
              5005274.9830732271
            ],
            [
              357616.04382007552,
              5005271.75881691
            ],
            [
              357618.81330294645,
              5005268.6830954719
            ],
            [
              357620.53727661812,
              5005266.7684915718
            ],
            [
              357622.52087091299,
              5005264.5655592466
            ],
            [
              357624.9426118292,
              5005261.8760318169
            ],
            [
              357627.70978947531,
              5005258.8028706824
            ],
            [
              357626.22515575943,
              5005257.1024587126
            ],
            [
              357634.85471471393,
              5005247.9484221945
            ],
            [
              357624.39455218147,
              5005234.8040605588
            ],
            [
              357628.68038005475,
              5005231.3897058982
            ],
            [
              357656.74909950711,
              5005266.0282941079
            ],
            [
              357685.79532343283,
              5005301.4994820822
            ],
            [
              357703.11327711429,
              5005288.7641922878
            ],
            [
              357762.99492809112,
              5005243.4538617106
            ],
            [
              357764.24542281585,
              5005242.5303468369
            ],
            [
              357750.81270050391,
              5005227.9404434981
            ],
            [
              357717.41705859057,
              5005191.1057627257
            ],
            [
              357706.78280439065,
              5005179.4804511871
            ],
            [
              357697.70282325556,
              5005169.8472303143
            ],
            [
              357693.99712679017,
              5005166.3793253917
            ],
            [
              357691.43741188501,
              5005164.7090465026
            ],
            [
              357688.06500053109,
              5005162.8517497061
            ],
            [
              357685.20573874819,
              5005161.389131505
            ],
            [
              357681.76792560751,
              5005159.8925142614
            ],
            [
              357678.10347980488,
              5005158.6469573602
            ],
            [
              357675.35839930276,
              5005157.8895905027
            ],
            [
              357672.31383816729,
              5005157.3398831496
            ],
            [
              357669.37594587408,
              5005157.0913479542
            ],
            [
              357666.43805355561,
              5005156.8428127542
            ],
            [
              357662.99277828884,
              5005156.5026469883
            ],
            [
              357603.87955779134,
              5005150.594352141
            ],
            [
              357551.24620673951,
              5005123.3555472568
            ],
            [
              357553.67633734498,
              5005118.0556973284
            ],
            [
              357560.00535686483,
              5005121.1716772933
            ],
            [
              357561.00892025855,
              5005120.7488004891
            ],
            [
              357561.8122262613,
              5005120.4307104228
            ],
            [
              357563.01993609237,
              5005120.1051018862
            ],
            [
              357564.53658295382,
              5005120.0769051574
            ],
            [
              357567.89202654647,
              5005121.0250523109
            ],
            [
              357574.09928648442,
              5005123.0317378426
            ],
            [
              357577.55583094392,
              5005123.9780053198
            ],
            [
              357585.53374713927,
              5005126.5648365058
            ],
            [
              357590.44471462513,
              5005118.6925579133
            ],
            [
              357607.40016320028,
              5005127.5730200028
            ],
            [
              357608.38682394155,
              5005126.2409913829
            ],
            [
              357609.78358953964,
              5005125.2045292184
            ],
            [
              357611.88819358021,
              5005124.1548717329
            ],
            [
              357613.9964879108,
              5005123.3072729195
            ],
            [
              357615.90821385058,
              5005122.7664484363
            ],
            [
              357618.32363312488,
              5005122.1152314562
            ],
            [
              357621.4505817676,
              5005121.6529133553
            ],
            [
              357622.76314821845,
              5005121.5274471473
            ],
            [
              357625.68601666816,
              5005120.967859582
            ],
            [
              357629.31283511157,
              5005120.1930572791
            ],
            [
              357633.55202646652,
              5005119.7100609606
            ],
            [
              357638.29303215147,
              5005119.0156079214
            ],
            [
              357642.02846383583,
              5005118.6430053487
            ],
            [
              357644.8558646944,
              5005118.3883125084
            ],
            [
              357647.65705226577,
              5005118.5381482318
            ],
            [
              357650.89449162612,
              5005118.5790230446
            ],
            [
              357655.35466467444,
              5005119.1024124445
            ],
            [
              357659.20816583931,
              5005119.6370807644
            ],
            [
              357663.36879188346,
              5005120.3681308581
            ],
            [
              357666.61937703501,
              5005121.1160999546
            ],
            [
              357670.38680202694,
              5005122.46080638
            ],
            [
              357673.64489955612,
              5005123.6128547015
            ],
            [
              357676.50040472532,
              5005124.8734158669
            ],
            [
              357680.98311411717,
              5005126.6090073669
            ],
            [
              357683.53531713103,
              5005127.8752428731
            ],
            [
              357685.67560400051,
              5005128.7449175958
            ],
            [
              357687.71096779214,
              5005129.414451303
            ],
            [
              357689.84555421554,
              5005129.9810766913
            ],
            [
              357692.2835096374,
              5005130.5420619771
            ],
            [
              357704.53394697508,
              5005133.2838288741
            ],
            [
              357707.17598240578,
              5005133.9420835627
            ],
            [
              357708.69645077811,
              5005134.1159073971
            ],
            [
              357710.31795380596,
              5005134.2878528405
            ],
            [
              357711.53693145019,
              5005134.5683454527
            ],
            [
              357715.5066240782,
              5005135.9092910839
            ],
            [
              357724.15364800842,
              5005138.5779550569
            ],
            [
              357738.08105253259,
              5005142.3611049801
            ],
            [
              357744.28267533635,
              5005144.0647386461
            ],
            [
              357747.84407494351,
              5005145.2111476464
            ],
            [
              357755.46687688545,
              5005147.1915145004
            ],
            [
              357760.54311035346,
              5005148.2086966923
            ],
            [
              357763.88728373701,
              5005148.550742073
            ],
            [
              357769.0217605771,
              5005148.8329128213
            ],
            [
              357771.65064888791,
              5005148.7840376487
            ],
            [
              357775.38051115797,
              5005148.1083832029
            ],
            [
              357778.40447850112,
              5005147.5468805041
            ],
            [
              357780.91724157968,
              5005146.6917622397
            ],
            [
              357783.41685799544,
              5005145.1295143906
            ],
            [
              357786.21226461715,
              5005143.1575485691
            ],
            [
              357788.40670071944,
              5005141.4999465104
            ],
            [
              357791.58404042921,
              5005138.3082592012
            ],
            [
              357795.15270398586,
              5005134.4019582029
            ],
            [
              357799.30543620669,
              5005129.2721777679
            ],
            [
              357805.05726688501,
              5005123.1021386608
            ],
            [
              357809.23065751401,
              5005119.0835316181
            ],
            [
              357812.7151226892,
              5005116.088261947
            ],
            [
              357816.80807076284,
              5005113.1827077772
            ],
            [
              357819.16021139006,
              5005111.4628796745
            ],
            [
              357823.66514162131,
              5005108.953885016
            ],
            [
              357828.46780655725,
              5005106.1361999325
            ],
            [
              357832.68445889704,
              5005104.4410023913
            ],
            [
              357836.6988425263,
              5005102.7495298069
            ],
            [
              357840.41931416682,
              5005101.5688038133
            ],
            [
              357844.43369772186,
              5005099.8773313044
            ],
            [
              357848.04555600969,
              5005098.2944059167
            ],
            [
              357850.65002890531,
              5005096.9323008796
            ],
            [
              357852.34640594886,
              5005095.6881422158
            ],
            [
              357853.84239325888,
              5005094.5487726573
            ],
            [
              357855.12866642169,
              5005093.0090832775
            ],
            [
              357856.41118371981,
              5005091.2673721565
            ],
            [
              357857.88657835691,
              5005089.016792912
            ],
            [
              357860.15394449286,
              5005085.8420599485
            ],
            [
              357862.52610046463,
              5005082.867434876
            ],
            [
              357864.90583574143,
              5005080.296923181
            ],
            [
              357867.94576817978,
              5005077.6532079242
            ],
            [
              357869.44175548182,
              5005076.5138384951
            ],
            [
              357870.84603189089,
              5005075.8814208163
            ],
            [
              357872.55555540737,
              5005075.3443917427
            ],
            [
              357874.97285057098,
              5005074.7941686921
            ],
            [
              357877.28897926188,
              5005074.2458620192
            ],
            [
              357880.00213152514,
              5005073.2859554635
            ],
            [
              357883.52221241914,
              5005072.2099834718
            ],
            [
              357888.44288002484,
              5005070.2995709097
            ],
            [
              357895.36886120093,
              5005067.4424116025
            ],
            [
              357899.5742446956,
              5005065.1411141418
            ],
            [
              357901.77236939355,
              5005063.6855005519
            ],
            [
              357903.06615487952,
              5005062.5498904968
            ],
            [
              357904.55281837628,
              5005060.9054479338
            ],
            [
              357905.14822156285,
              5005060.288068397
            ],
            [
              357906.54686375306,
              5005059.3526004925
            ],
            [
              357909.25055896305,
              5005057.8875877522
            ],
            [
              357911.84939811798,
              5005056.2224684469
            ],
            [
              357914.03819908324,
              5005054.2617816636
            ],
            [
              357917.05517345393,
              5005051.7093194928
            ],
            [
              357921.24365450838,
              5005048.4988713395
            ],
            [
              357931.92547543522,
              5005040.9234971572
            ],
            [
              357938.39815784071,
              5005035.4474340584
            ],
            [
              357941.28346277628,
              5005032.8674885454
            ],
            [
              357942.87116084486,
              5005031.2211679351
            ],
            [
              357943.96395857324,
              5005029.3525227048
            ],
            [
              357921.49503228208,
              5005001.3747683233
            ],
            [
              357923.88603520376,
              5004999.4103227807
            ],
            [
              357946.23338617716,
              5005027.6835623821
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 164,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354873.88444514974,
              5009087.6595053403
            ],
            [
              354926.33405567688,
              5009151.1244270774
            ],
            [
              354996.04796627566,
              5009093.5104380772
            ],
            [
              354943.59837701888,
              5009030.0455203243
            ],
            [
              354873.88444514974,
              5009087.6595053403
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 165,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355706.33218046936,
              5008631.7450825954
            ],
            [
              355682.17218529497,
              5008710.4535398362
            ],
            [
              355768.6305777267,
              5008736.9924042663
            ],
            [
              355792.79056083615,
              5008658.2839649916
            ],
            [
              355706.33218046936,
              5008631.7450825954
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 166,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358841.64589476824,
              5006244.9065714376
            ],
            [
              358877.15695233754,
              5006319.1868990455
            ],
            [
              358958.75119241019,
              5006280.179290141
            ],
            [
              358923.24015174218,
              5006205.8989760652
            ],
            [
              358841.64589476824,
              5006244.9065714376
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 167,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359307.347749329,
              5005712.5594278257
            ],
            [
              359309.18490278168,
              5005794.8710883763
            ],
            [
              359399.60127533175,
              5005792.8530314518
            ],
            [
              359397.7641229264,
              5005710.5413925303
            ],
            [
              359307.347749329,
              5005712.5594278257
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 168,
        "Layer" : "DRZAVNE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358973.70302998298,
              5005765.8167398097
            ],
            [
              358975.54019825987,
              5005848.1284798747
            ],
            [
              359065.95665815176,
              5005846.1104066661
            ],
            [
              359064.11949092319,
              5005763.7986882292
            ],
            [
              358973.70302998298,
              5005765.8167398097
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 169,
        "Layer" : "Javne funkcije",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355314.90465779282,
              5007686.5198788503
            ],
            [
              355461.25616362097,
              5007683.7978902832
            ],
            [
              355458.87633047608,
              5007555.8415322248
            ],
            [
              355312.52482238831,
              5007558.5634663878
            ],
            [
              355314.90465779282,
              5007686.5198788503
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 170,
        "Layer" : "Javne funkcije",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355494.2497406209,
              5007683.1842436371
            ],
            [
              355640.60117019323,
              5007680.4622582365
            ],
            [
              355638.22133981629,
              5007552.5059668515
            ],
            [
              355491.86990798585,
              5007555.2278978461
            ],
            [
              355494.2497406209,
              5007683.1842436371
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 171,
        "Layer" : "Javne funkcije",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359165.68172932795,
              5003569.5653767427
            ],
            [
              359312.03163620597,
              5003566.8451770423
            ],
            [
              359309.65336707624,
              5003438.8902169997
            ],
            [
              359163.30345797114,
              5003441.6103622587
            ],
            [
              359165.68172932795,
              5003569.5653767427
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 172,
        "Layer" : "Javne funkcije",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359335.75851214636,
              5003566.404166704
            ],
            [
              359482.10834666039,
              5003563.6839699596
            ],
            [
              359479.73008011578,
              5003435.7290731855
            ],
            [
              359333.38024337677,
              5003438.4492154876
            ],
            [
              359335.75851214636,
              5003566.404166704
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 173,
        "Layer" : "Javne funkcije",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359505.83516784216,
              5003563.242960901
            ],
            [
              359652.18492999295,
              5003560.5227671145
            ],
            [
              359649.80666603165,
              5003432.5679336088
            ],
            [
              359503.4569016578,
              5003435.2880729521
            ],
            [
              359505.83516784216,
              5003563.242960901
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 174,
        "Layer" : "Javne funkcije",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359408.15666437254,
              5003388.4799267147
            ],
            [
              359554.50646975607,
              5003385.7598063918
            ],
            [
              359552.12827002659,
              5003257.8049350893
            ],
            [
              359405.77846241876,
              5003260.525000968
            ],
            [
              359408.15666437254,
              5003388.4799267147
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 175,
        "Layer" : "Javne funkcije",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359586.1422533546,
              5003385.171810315
            ],
            [
              359732.49198300816,
              5003382.451693086
            ],
            [
              359730.11378598161,
              5003254.4968879931
            ],
            [
              359583.76405410544,
              5003257.2169507807
            ],
            [
              359586.1422533546,
              5003385.171810315
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 176,
        "Layer" : "Javne funkcije",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351678.97129051603,
              5010300.8997550271
            ],
            [
              351825.32431684207,
              5010298.1766186757
            ],
            [
              351822.94348019123,
              5010170.2189312056
            ],
            [
              351676.5904515725,
              5010172.9420131734
            ],
            [
              351678.97129051603,
              5010300.8997550271
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 177,
        "Layer" : "Javne funkcije",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351857.47401942307,
              5010297.5784213077
            ],
            [
              352003.82696988265,
              5010294.8552881535
            ],
            [
              352001.44613602589,
              5010166.8976670168
            ],
            [
              351855.0931832749,
              5010169.6207457855
            ],
            [
              351857.47401942307,
              5010297.5784213077
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 178,
        "Layer" : "Javne funkcije",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352038.38683082425,
              5010294.2122463025
            ],
            [
              352184.73970439442,
              5010291.4891163856
            ],
            [
              352182.35887336632,
              5010163.5315624746
            ],
            [
              352036.00599750754,
              5010166.2546380069
            ],
            [
              352038.38683082425,
              5010294.2122463025
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 179,
        "Layer" : "Javne funkcije",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356330.70589345653,
              5005962.5470806761
            ],
            [
              356477.05698388215,
              5005959.825835417
            ],
            [
              356474.6778006184,
              5005831.8698405623
            ],
            [
              356328.32670794165,
              5005834.5910314023
            ],
            [
              356330.70589345653,
              5005962.5470806761
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 180,
        "Layer" : "Javne funkcije",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356503.67673273204,
              5005959.3308692155
            ],
            [
              356650.02774959127,
              5005956.6096269973
            ],
            [
              356647.64856898587,
              5005828.6536964634
            ],
            [
              356501.29754987685,
              5005831.3748842608
            ],
            [
              356503.67673273204,
              5005959.3308692155
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 181,
        "Layer" : "Javne funkcije",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356678.42211107042,
              5005956.0816640705
            ],
            [
              356824.7730536068,
              5005953.3604249228
            ],
            [
              356822.39387568622,
              5005825.4045593711
            ],
            [
              356676.04293090169,
              5005828.1257440979
            ],
            [
              356678.42211107042,
              5005956.0816640705
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 182,
        "Layer" : "Javne funkcije",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356856.60882139462,
              5005952.7684730804
            ],
            [
              357002.95968814724,
              5005950.0472370638
            ],
            [
              357000.58051296143,
              5005822.0914377682
            ],
            [
              356854.22964396205,
              5005824.8126193639
            ],
            [
              356856.60882139462,
              5005952.7684730804
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 183,
        "Layer" : "Javne funkcije",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356500.77441635454,
              5005803.2396434536
            ],
            [
              356647.12543595704,
              5005800.5184676209
            ],
            [
              356644.7463133962,
              5005672.5625346908
            ],
            [
              356498.3952915445,
              5005675.2836560989
            ],
            [
              356500.77441635454,
              5005803.2396434536
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 184,
        "Layer" : "Javne funkcije",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356675.51979796711,
              5005799.9905175744
            ],
            [
              356821.87074324745,
              5005797.2693448151
            ],
            [
              356819.49162337143,
              5005669.313476867
            ],
            [
              356673.14067584317,
              5005672.0345952008
            ],
            [
              356675.51979796711,
              5005799.9905175744
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 185,
        "Layer" : "Javne funkcije",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356853.70651162992,
              5005796.6774074137
            ],
            [
              357000.05738112365,
              5005793.9562377846
            ],
            [
              356997.67826398317,
              5005666.0004360974
            ],
            [
              356851.32739224227,
              5005668.721551301
            ],
            [
              356853.70651162992,
              5005796.6774074137
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 186,
        "Layer" : "Javne funkcije",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356850.87133323605,
              5005644.1930988822
            ],
            [
              356997.22220540617,
              5005641.4719941085
            ],
            [
              356994.84314497001,
              5005513.5161900809
            ],
            [
              356848.49227055343,
              5005516.2372404318
            ],
            [
              356850.87133323605,
              5005644.1930988822
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 187,
        "Layer" : "Javne funkcije",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356672.68461631116,
              5005647.5061300807
            ],
            [
              356819.03556426929,
              5005644.7850221759
            ],
            [
              356816.65650109824,
              5005516.8291518865
            ],
            [
              356670.30555089127,
              5005519.5502053639
            ],
            [
              356672.68461631116,
              5005647.5061300807
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 188,
        "Layer" : "Javne funkcije",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356497.93923149683,
              5005650.7551785186
            ],
            [
              356644.29025378107,
              5005648.0340675451
            ],
            [
              356641.91118792439,
              5005520.0781322699
            ],
            [
              356495.56016339123,
              5005522.7991888197
            ],
            [
              356497.93923149683,
              5005650.7551785186
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 189,
        "Layer" : "Javne funkcije",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356327.80357383279,
              5005806.4557764502
            ],
            [
              356474.1546670039,
              5005803.7345975768
            ],
            [
              356471.77554178407,
              5005675.7786003249
            ],
            [
              356325.42444636178,
              5005678.4997247718
            ],
            [
              356327.80357383279,
              5005806.4557764502
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 190,
        "Layer" : "Javne funkcije",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357024.97344852763,
              5005488.6514373207
            ],
            [
              357171.32424811524,
              5005485.9304004144
            ],
            [
              357168.94524701283,
              5005357.9746598499
            ],
            [
              357022.59444518102,
              5005360.6956423325
            ],
            [
              357024.97344852763,
              5005488.6514373207
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 191,
        "Layer" : "Javne funkcije",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357203.15998480591,
              5005485.3384925639
            ],
            [
              357349.51070860209,
              5005482.6174587803
            ],
            [
              357347.1317102316,
              5005354.6617844803
            ],
            [
              357200.78098419181,
              5005357.3827638356
            ],
            [
              357203.15998480591,
              5005485.3384925639
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 192,
        "Layer" : "Javne funkcije",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357029.05795788596,
              5005640.8800714966
            ],
            [
              357175.40875426802,
              5005638.1589698521
            ],
            [
              357173.02969656582,
              5005510.2032320881
            ],
            [
              357026.67889793817,
              5005512.9242793079
            ],
            [
              357029.05795788596,
              5005640.8800714966
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 193,
        "Layer" : "Javne funkcije",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357031.7371975389,
              5005792.1968511483
            ],
            [
              357178.0879913221,
              5005789.4756851438
            ],
            [
              357175.70887734764,
              5005661.5199496495
            ],
            [
              357029.35808131943,
              5005664.2410612302
            ],
            [
              357031.7371975389,
              5005792.1968511483
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 194,
        "Layer" : "B03---BLOKOVI",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357553.81387729367,
              5005226.3433358818
            ],
            [
              357553.89063520188,
              5005227.0657408331
            ],
            [
              357554.09167129337,
              5005227.7638419596
            ],
            [
              357554.41087718838,
              5005228.4164278097
            ],
            [
              357554.83855397603,
              5005229.0036698971
            ],
            [
              357555.36170691351,
              5005229.5077251587
            ],
            [
              357555.96444026293,
              5005229.9132781364
            ],
            [
              357556.62844027445,
              5005230.2080063056
            ],
            [
              357557.33353164414,
              5005230.3829545053
            ],
            [
              357558.05829052679,
              5005230.432807019
            ],
            [
              357558.78069549025,
              5005230.3560491037
            ],
            [
              357559.4787966265,
              5005230.1550130155
            ],
            [
              357560.13138248411,
              5005229.8358071288
            ],
            [
              357560.71862457524,
              5005229.4081303589
            ],
            [
              357561.22267984506,
              5005228.884977445
            ],
            [
              357561.62823283079,
              5005228.2822441263
            ],
            [
              357561.92296100949,
              5005227.618244146
            ],
            [
              357562.09790921723,
              5005226.9131528074
            ],
            [
              357562.14776174066,
              5005226.188393957
            ],
            [
              357562.07100383658,
              5005225.4659890216
            ],
            [
              357561.86996775388,
              5005224.7678879099
            ],
            [
              357561.55076187296,
              5005224.1153020682
            ],
            [
              357561.12308510195,
              5005223.5280599892
            ],
            [
              357560.59993218241,
              5005223.0240047276
            ],
            [
              357559.99719884951,
              5005222.6184517425
            ],
            [
              357559.3331988501,
              5005222.3237235593
            ],
            [
              357558.62810748938,
              5005222.1487753484
            ],
            [
              357557.90334860893,
              5005222.098922818
            ],
            [
              357557.18094364123,
              5005222.1756807128
            ],
            [
              357556.48284249584,
              5005222.3767167861
            ],
            [
              357555.83025662421,
              5005222.6959226616
            ],
            [
              357555.24301451596,
              5005223.1235994259
            ],
            [
              357554.73895922903,
              5005223.6467523407
            ],
            [
              357554.33340622752,
              5005224.2494856669
            ],
            [
              357554.03867803537,
              5005224.9134856593
            ],
            [
              357553.86372981925,
              5005225.6185770128
            ],
            [
              357553.81387729367,
              5005226.3433358818
            ]
          ],
          [
            [
              357455.66251999041,
              5005274.5495827468
            ],
            [
              357630.6741003645,
              5005271.2957784124
            ],
            [
              357627.42034251499,
              5005096.2842459008
            ],
            [
              357452.40875847515,
              5005099.5379612111
            ],
            [
              357455.66251999041,
              5005274.5495827468
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 195,
        "Layer" : "B02209B-POLJOPRIVREDA_03",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353588.14913406677,
              5009879.6112532606
            ],
            [
              353593.41318528174,
              5009886.5708945794
            ],
            [
              353600.82868799841,
              5009905.6808814621
            ],
            [
              353617.93980982393,
              5009928.4600499319
            ],
            [
              353624.52879451477,
              5009937.6405894114
            ],
            [
              353631.259589769,
              5009937.1945958063
            ],
            [
              353633.25596902339,
              5009941.0070262682
            ],
            [
              353614.19306900928,
              5009951.3064842466
            ],
            [
              353482.686618281,
              5009989.3619200652
            ],
            [
              353483.04322451242,
              5009991.2800730942
            ],
            [
              353474.63824435923,
              5010005.2308157748
            ],
            [
              353466.0423313909,
              5010008.919520366
            ],
            [
              353448.34612572502,
              5010023.6847446645
            ],
            [
              353434.39452219935,
              5010039.7394585703
            ],
            [
              353390.97955264896,
              5010071.6966369366
            ],
            [
              353385.27686770057,
              5010075.9774149787
            ],
            [
              353389.21406155388,
              5010080.3999690143
            ],
            [
              353433.37706827623,
              5010105.9107318725
            ],
            [
              353454.23376496113,
              5010121.9001884786
            ],
            [
              353460.79963901144,
              5010129.485099718
            ],
            [
              353465.39097230736,
              5010134.5377100231
            ],
            [
              353456.27085341403,
              5010162.3243969874
            ],
            [
              353449.64065844793,
              5010168.5492023854
            ],
            [
              353442.09036463726,
              5010194.379913683
            ],
            [
              353415.33072524221,
              5010206.4384278283
            ],
            [
              353414.33240643464,
              5010206.8882934563
            ],
            [
              353422.20667785569,
              5010226.863880978
            ],
            [
              353423.82788065699,
              5010242.8922872329
            ],
            [
              353393.74765060219,
              5010254.7621123148
            ],
            [
              353391.7863661325,
              5010262.244185444
            ],
            [
              353384.67381806253,
              5010266.3480186919
            ],
            [
              353374.00753397081,
              5010282.8106287215
            ],
            [
              353364.11613029131,
              5010310.4167931406
            ],
            [
              353349.18371128349,
              5010321.2852448104
            ],
            [
              353346.79905115266,
              5010325.30106821
            ],
            [
              353360.72408184159,
              5010368.4690101808
            ],
            [
              353354.44501340308,
              5010389.227723741
            ],
            [
              353356.71014403977,
              5010413.3856146112
            ],
            [
              353352.14022697677,
              5010427.9906531796
            ],
            [
              353376.46116662252,
              5010439.7994014192
            ],
            [
              353383.40552564437,
              5010446.4877368733
            ],
            [
              353385.83220133622,
              5010444.5488314414
            ],
            [
              353379.48682840605,
              5010429.3274037084
            ],
            [
              353379.83300548414,
              5010417.3902201736
            ],
            [
              353387.46544499107,
              5010389.7886113534
            ],
            [
              353392.84727629676,
              5010373.5914758397
            ],
            [
              353404.67062042892,
              5010347.2375371028
            ],
            [
              353423.56820423371,
              5010323.4989688592
            ],
            [
              353442.04338162701,
              5010310.7448139992
            ],
            [
              353454.36668278469,
              5010300.2168653896
            ],
            [
              353454.20846856217,
              5010291.7137388401
            ],
            [
              353480.72766150493,
              5010255.8978457684
            ],
            [
              353488.0597777256,
              5010245.9953478118
            ],
            [
              353540.77601744031,
              5010231.5155370068
            ],
            [
              353560.0014680085,
              5010226.2347951215
            ],
            [
              353578.39767680381,
              5010221.2386696273
            ],
            [
              353621.03538670583,
              5010212.306043203
            ],
            [
              353633.19297145773,
              5010212.9469439592
            ],
            [
              353640.02812263585,
              5010213.2510879701
            ],
            [
              353645.35055578157,
              5010213.5878446735
            ],
            [
              353662.92131447583,
              5010225.4001124147
            ],
            [
              353663.05033589847,
              5010232.3343635108
            ],
            [
              353671.85180386814,
              5010239.1073063118
            ],
            [
              353696.8444170751,
              5010276.7940836707
            ],
            [
              353705.74265196634,
              5010288.7677123267
            ],
            [
              353733.68503530574,
              5010277.3042124324
            ],
            [
              353736.73778649879,
              5010276.0518063409
            ],
            [
              353741.87678890326,
              5010272.4878622564
            ],
            [
              353742.07032347895,
              5010282.8892914085
            ],
            [
              353750.80727810226,
              5010286.1950517893
            ],
            [
              353800.27632842743,
              5010333.8314204989
            ],
            [
              353798.17209285189,
              5010345.4681263613
            ],
            [
              353826.86458273907,
              5010336.7102503302
            ],
            [
              353820.33877088694,
              5010332.5343871498
            ],
            [
              353841.56260068057,
              5010311.6079913359
            ],
            [
              353844.47410799569,
              5010314.0605754666
            ],
            [
              353843.41751792358,
              5010315.0351785021
            ],
            [
              353846.08806023543,
              5010317.3728885297
            ],
            [
              353871.45011100039,
              5010302.2684122361
            ],
            [
              353889.32365040557,
              5010278.0852930462
            ],
            [
              353895.40807712544,
              5010276.8272488089
            ],
            [
              353907.21933037305,
              5010265.3500227826
            ],
            [
              353912.68739679415,
              5010258.3558344841
            ],
            [
              353921.57521564519,
              5010243.2975093769
            ],
            [
              353928.47644598782,
              5010234.3861256717
            ],
            [
              353954.78545730881,
              5010221.4858156005
            ],
            [
              353972.24840467144,
              5010215.6237672204
            ],
            [
              353975.8051024591,
              5010211.7389320917
            ],
            [
              353976.60794161097,
              5010213.8242663797
            ],
            [
              353971.74383886234,
              5010198.1764095053
            ],
            [
              353947.73020962911,
              5010180.7467760546
            ],
            [
              353944.14167560398,
              5010172.9718514802
            ],
            [
              353931.24622957892,
              5010179.1408708459
            ],
            [
              353913.11663176748,
              5010150.78903843
            ],
            [
              353897.3082740684,
              5010122.2807231965
            ],
            [
              353898.57454810839,
              5010119.5022806069
            ],
            [
              353879.2056678597,
              5010114.6459647352
            ],
            [
              353858.05531691253,
              5010113.203272583
            ],
            [
              353824.41458332597,
              5010115.0533112483
            ],
            [
              353806.21210402256,
              5010123.9609324355
            ],
            [
              353798.56697294483,
              5010124.4092077548
            ],
            [
              353798.21539132297,
              5010121.9674734492
            ],
            [
              353803.85924278392,
              5010112.6815013643
            ],
            [
              353821.10386101279,
              5010101.64949759
            ],
            [
              353876.93116832554,
              5010091.1237427648
            ],
            [
              353911.64233103173,
              5010080.9909186997
            ],
            [
              353926.07706678432,
              5010083.4766094368
            ],
            [
              353941.41710867587,
              5010068.8076557731
            ],
            [
              353976.19978059951,
              5010088.4250292694
            ],
            [
              354001.83424201445,
              5010099.5852715746
            ],
            [
              354003.58053469931,
              5010094.6528882207
            ],
            [
              354017.43541240878,
              5010098.3762482237
            ],
            [
              354017.0892638762,
              5010096.238998984
            ],
            [
              354022.89649253833,
              5010095.5184786906
            ],
            [
              354035.29284425924,
              5010086.7130738497
            ],
            [
              354038.75261731929,
              5010091.5485514188
            ],
            [
              354059.68385915895,
              5010080.440657828
            ],
            [
              354065.9611256895,
              5010072.0553412
            ],
            [
              354108.07903443446,
              5010038.6955560809
            ],
            [
              354164.38630584552,
              5009985.7599632079
            ],
            [
              354164.76657162787,
              5009979.8787822379
            ],
            [
              354187.76321364817,
              5009946.9679712737
            ],
            [
              354212.04105502437,
              5009916.7804067293
            ],
            [
              354246.77994850517,
              5009937.8995185038
            ],
            [
              354284.25640322914,
              5009974.2954677735
            ],
            [
              354322.64118061034,
              5009939.1440107599
            ],
            [
              354335.66901419702,
              5010001.541968178
            ],
            [
              354300.15981826076,
              5010073.494989397
            ],
            [
              354286.28259508678,
              5010086.3305421434
            ],
            [
              354279.44252592494,
              5010081.5495835571
            ],
            [
              354254.0794226111,
              5010103.8017915143
            ],
            [
              354256.97726591752,
              5010111.1102323942
            ],
            [
              354215.00461992907,
              5010147.7826988511
            ],
            [
              354206.72772173723,
              5010148.2434719391
            ],
            [
              354198.3024738981,
              5010140.731102054
            ],
            [
              354191.07572205033,
              5010131.6626144079
            ],
            [
              354181.16092890478,
              5010110.0667824214
            ],
            [
              354163.11318741023,
              5010113.1634439612
            ],
            [
              354135.13626802526,
              5010126.8748874236
            ],
            [
              354143.38030134136,
              5010141.1394475959
            ],
            [
              354124.17072150571,
              5010160.7171791522
            ],
            [
              354112.94976832811,
              5010168.2933548298
            ],
            [
              354108.78345255763,
              5010175.4312924538
            ],
            [
              354100.58650473016,
              5010180.4953771029
            ],
            [
              354096.551511731,
              5010194.6912902193
            ],
            [
              354093.8858093436,
              5010199.9594790423
            ],
            [
              354071.38103699585,
              5010211.7362428615
            ],
            [
              354069.74474139279,
              5010222.8177844416
            ],
            [
              354072.62741284183,
              5010229.2106107399
            ],
            [
              354079.03961260326,
              5010227.2494741725
            ],
            [
              354086.839580525,
              5010233.8577853711
            ],
            [
              354076.80604610121,
              5010260.1796042649
            ],
            [
              354060.36239540699,
              5010284.753112657
            ],
            [
              354066.06306046428,
              5010293.8625837341
            ],
            [
              354070.78508511191,
              5010299.9184167907
            ],
            [
              354079.68062191067,
              5010282.55060024
            ],
            [
              354092.95669385849,
              5010269.4018222811
            ],
            [
              354106.53425877279,
              5010255.9402509611
            ],
            [
              354102.1137291381,
              5010249.5716648046
            ],
            [
              354107.26727117685,
              5010245.7895598048
            ],
            [
              354112.94946384855,
              5010237.3898488553
            ],
            [
              354109.45620921749,
              5010231.311152719
            ],
            [
              354117.61302232614,
              5010223.7869935678
            ],
            [
              354139.78024799383,
              5010209.5512336558
            ],
            [
              354146.70973606495,
              5010218.6378399041
            ],
            [
              354161.59028768819,
              5010209.1454287916
            ],
            [
              354174.68485177198,
              5010202.7580950772
            ],
            [
              354188.35383421881,
              5010194.2098232331
            ],
            [
              354195.97056712053,
              5010190.6890737666
            ],
            [
              354203.65586287394,
              5010190.8532635858
            ],
            [
              354234.55006623187,
              5010165.8729289696
            ],
            [
              354286.66690149525,
              5010124.3272272358
            ],
            [
              354325.70311331801,
              5010090.4023777945
            ],
            [
              354354.73256948794,
              5010064.0411821157
            ],
            [
              354367.15003005165,
              5010054.0586585086
            ],
            [
              354396.10200509778,
              5010022.4520129869
            ],
            [
              354407.2201090873,
              5010008.0953550851
            ],
            [
              354425.66920694534,
              5009990.8339260658
            ],
            [
              354439.88626965572,
              5009977.6500418363
            ],
            [
              354458.02203236253,
              5009960.0868075462
            ],
            [
              354444.68334993074,
              5009952.6594176348
            ],
            [
              354438.65084654168,
              5009943.518320418
            ],
            [
              354453.8995938283,
              5009917.3252626099
            ],
            [
              354459.42288209585,
              5009915.6802629409
            ],
            [
              354466.89875047468,
              5009902.8949412797
            ],
            [
              354441.76433213608,
              5009878.6870034589
            ],
            [
              354431.6073151459,
              5009863.4537387071
            ],
            [
              354433.71781421959,
              5009827.634902535
            ],
            [
              354443.1894349978,
              5009805.4949792288
            ],
            [
              354454.51332785055,
              5009790.9901749846
            ],
            [
              354427.27014614787,
              5009791.110735503
            ],
            [
              354411.43354383722,
              5009781.1676726844
            ],
            [
              354399.67672116606,
              5009736.474095434
            ],
            [
              354410.81475954416,
              5009698.2646198813
            ],
            [
              354403.82743164949,
              5009657.171076227
            ],
            [
              354396.00617694901,
              5009657.7031794358
            ],
            [
              354385.54668071307,
              5009656.7380027231
            ],
            [
              354372.23106746242,
              5009635.4010269102
            ],
            [
              354345.35025654564,
              5009603.1389639517
            ],
            [
              354326.66065915191,
              5009579.0695359018
            ],
            [
              354300.21409756626,
              5009536.9088954441
            ],
            [
              354296.30974847631,
              5009526.4729187433
            ],
            [
              354333.65931763715,
              5009481.9375143079
            ],
            [
              354392.55226398743,
              5009437.8507764479
            ],
            [
              354427.89462361281,
              5009408.4294501496
            ],
            [
              354436.66896346287,
              5009405.1356806215
            ],
            [
              354479.31951827201,
              5009410.7713305783
            ],
            [
              354525.66179769445,
              5009428.7019801531
            ],
            [
              354539.61020644027,
              5009433.8824881501
            ],
            [
              354553.65984493907,
              5009444.5011584992
            ],
            [
              354565.91524708254,
              5009465.0441444088
            ],
            [
              354619.10335273296,
              5009425.4799170829
            ],
            [
              354582.15535331814,
              5009380.6689538797
            ],
            [
              354574.59917074075,
              5009373.391295583
            ],
            [
              354567.86658773973,
              5009357.1964929476
            ],
            [
              354598.19189516042,
              5009318.0570796141
            ],
            [
              354638.51706415432,
              5009276.217376546
            ],
            [
              354658.18320983759,
              5009295.1586355111
            ],
            [
              354678.44561109814,
              5009319.5344154565
            ],
            [
              354693.26039214863,
              5009317.2785550551
            ],
            [
              354716.08593543532,
              5009293.0913281376
            ],
            [
              354726.69170035963,
              5009277.5473227939
            ],
            [
              354705.19781794568,
              5009252.5168988407
            ],
            [
              354694.84231260087,
              5009248.4439685522
            ],
            [
              354687.29418956133,
              5009238.8898276892
            ],
            [
              354685.36527507385,
              5009229.0372971315
            ],
            [
              354676.40669591009,
              5009227.0711680427
            ],
            [
              354667.86027843511,
              5009215.9844892723
            ],
            [
              354668.49587849196,
              5009203.0178970182
            ],
            [
              354675.8498184784,
              5009191.4337644083
            ],
            [
              354751.97419244895,
              5009128.8516053706
            ],
            [
              354765.33353864512,
              5009099.8212166438
            ],
            [
              354800.49972740031,
              5009068.8964104373
            ],
            [
              354808.88578040298,
              5009062.8377913376
            ],
            [
              354791.32359287102,
              5009038.4814816425
            ],
            [
              354794.8500841455,
              5009029.4755976507
            ],
            [
              354793.21209498873,
              5009025.0359078581
            ],
            [
              354826.83640784718,
              5008994.5690189665
            ],
            [
              354849.95148953487,
              5008963.6002711477
            ],
            [
              354862.13812963926,
              5008948.4158165408
            ],
            [
              354867.85676102428,
              5008947.9555314677
            ],
            [
              354875.58695112885,
              5008921.3851185413
            ],
            [
              354898.03939538746,
              5008904.6207860857
            ],
            [
              354931.57517842465,
              5008878.489280791
            ],
            [
              354917.44493496238,
              5008874.1772314012
            ],
            [
              354918.09742794116,
              5008869.4811474886
            ],
            [
              354913.76260704506,
              5008868.4524485171
            ],
            [
              354867.36963711184,
              5008886.6954047689
            ],
            [
              354858.98725619953,
              5008891.5357798487
            ],
            [
              354855.02193954447,
              5008898.5381672187
            ],
            [
              354851.24271323491,
              5008898.6084703282
            ],
            [
              354854.1402358198,
              5008885.0123081952
            ],
            [
              354851.14768932032,
              5008876.5647217305
            ],
            [
              354840.85437812551,
              5008882.1101102578
            ],
            [
              354838.5971199238,
              5008879.3176547978
            ],
            [
              354831.45902056532,
              5008885.119291896
            ],
            [
              354829.52261263278,
              5008882.6358108157
            ],
            [
              354811.78212242154,
              5008894.303530179
            ],
            [
              354800.85446072172,
              5008916.55233155
            ],
            [
              354792.81840754097,
              5008924.8901776504
            ],
            [
              354786.5255526054,
              5008925.3221841389
            ],
            [
              354782.06506583019,
              5008939.5772694452
            ],
            [
              354769.59968898288,
              5008954.1666179737
            ],
            [
              354730.54444360133,
              5008997.1001936933
            ],
            [
              354757.80211899796,
              5009023.5833486924
            ],
            [
              354774.4313768766,
              5009052.9083629996
            ],
            [
              354748.19274155225,
              5009100.3700536303
            ],
            [
              354730.84793735272,
              5009118.7569031715
            ],
            [
              354718.44652594038,
              5009120.9619153738
            ],
            [
              354686.47110723151,
              5009099.87438061
            ],
            [
              354671.04151740624,
              5009085.0134809781
            ],
            [
              354650.84297384828,
              5009068.3477994325
            ],
            [
              354631.6779075723,
              5009039.3551763985
            ],
            [
              354613.7940834589,
              5009028.3268816546
            ],
            [
              354611.94165586424,
              5009030.5704021947
            ],
            [
              354620.92011673393,
              5009055.0188051295
            ],
            [
              354618.91190874687,
              5009082.8274217453
            ],
            [
              354612.14654428727,
              5009109.4622049192
            ],
            [
              354602.07278395665,
              5009127.9533789242
            ],
            [
              354598.69160704367,
              5009148.5987355467
            ],
            [
              354583.103043487,
              5009178.6506455112
            ],
            [
              354561.35811950156,
              5009203.2682911241
            ],
            [
              354547.44022237783,
              5009214.6248872876
            ],
            [
              354553.64354922419,
              5009222.5805097139
            ],
            [
              354535.5797364618,
              5009255.2007046184
            ],
            [
              354519.33730817004,
              5009268.8678431865
            ],
            [
              354498.05620467442,
              5009292.4917592322
            ],
            [
              354485.06826957577,
              5009300.3077475792
            ],
            [
              354461.63583879982,
              5009316.9022827139
            ],
            [
              354453.58476405736,
              5009318.5669359472
            ],
            [
              354438.12833915197,
              5009329.4585959548
            ],
            [
              354424.60725157527,
              5009335.7696323935
            ],
            [
              354414.05957195605,
              5009338.9955646424
            ],
            [
              354401.35117460694,
              5009334.6874101851
            ],
            [
              354396.37674867426,
              5009338.819574547
            ],
            [
              354384.10753491957,
              5009330.9685445884
            ],
            [
              354380.28174389974,
              5009315.3860672293
            ],
            [
              354380.47682476073,
              5009298.718829562
            ],
            [
              354369.57223583938,
              5009282.763085247
            ],
            [
              354371.57112917368,
              5009254.448300587
            ],
            [
              354384.72598238249,
              5009228.4507658221
            ],
            [
              354384.12715559138,
              5009223.4123691106
            ],
            [
              354392.52034133597,
              5009188.1360085877
            ],
            [
              354406.98396238434,
              5009150.474595624
            ],
            [
              354427.11465407821,
              5009116.5278578345
            ],
            [
              354473.9548874367,
              5009047.9370952081
            ],
            [
              354478.53793356841,
              5009037.8763330067
            ],
            [
              354465.26911395177,
              5009025.5840836754
            ],
            [
              354457.72501148237,
              5009013.9302479681
            ],
            [
              354474.51203289337,
              5008989.7568019414
            ],
            [
              354504.70004302874,
              5008958.7249612622
            ],
            [
              354559.2464041246,
              5008902.11232494
            ],
            [
              354581.65606830065,
              5008865.8206491759
            ],
            [
              354570.82469296316,
              5008877.8285927223
            ],
            [
              354536.61760010285,
              5008897.2597750658
            ],
            [
              354493.33387781942,
              5008915.095953756
            ],
            [
              354449.89971222827,
              5008928.378588384
            ],
            [
              354439.51958925521,
              5008918.1756314253
            ],
            [
              354438.89818432013,
              5008917.3788355105
            ],
            [
              354438.17895441299,
              5008916.7859482998
            ],
            [
              354437.06246348558,
              5008916.6042097202
            ],
            [
              354436.15478355339,
              5008916.7221393557
            ],
            [
              354435.25196711736,
              5008917.1435311651
            ],
            [
              354434.55152756913,
              5008917.5607389687
            ],
            [
              354434.15881382552,
              5008918.174311202
            ],
            [
              354434.27840583591,
              5008919.1825310541
            ],
            [
              354434.2990752465,
              5008920.2936355807
            ],
            [
              354434.11172286217,
              5008921.1054764763
            ],
            [
              354433.71713008714,
              5008921.6180392094
            ],
            [
              354432.91589669907,
              5008922.0375413252
            ],
            [
              354431.80129258643,
              5008921.9572313884
            ],
            [
              354430.37488056388,
              5008921.3770803185
            ],
            [
              354425.78340255859,
              5008919.2395155663
            ],
            [
              354424.35621690366,
              5008918.6597981388
            ],
            [
              354423.23863609805,
              5008918.3774546431
            ],
            [
              354422.02135127661,
              5008918.1980103562
            ],
            [
              354420.70702287991,
              5008918.2224602485
            ],
            [
              354419.19030978519,
              5008918.2506750198
            ],
            [
              354417.98054109613,
              5008918.4752687598
            ],
            [
              354415.76902551483,
              5008919.2237198874
            ],
            [
              354412.96616953774,
              5008920.7915271921
            ],
            [
              354411.15754429076,
              5008921.4314390812
            ],
            [
              354409.44784164435,
              5008921.968466294
            ],
            [
              354407.93488657539,
              5008922.198700103
            ],
            [
              354405.61129506101,
              5008922.342969384
            ],
            [
              354402.57411065354,
              5008922.1973799411
            ],
            [
              354400.4510247705,
              5008922.2368748356
            ],
            [
              354399.03887131246,
              5008922.4652334796
            ],
            [
              354397.83364983817,
              5008922.8922508638
            ],
            [
              354397.03162716253,
              5008923.3113484383
            ],
            [
              354395.84518827527,
              5008924.7480418356
            ],
            [
              354393.47827164637,
              5008928.0259149168
            ],
            [
              354392.58672155795,
              5008929.0529447682
            ],
            [
              354391.58951410459,
              5008929.7788067171
            ],
            [
              354390.69420593436,
              5008930.6038175467
            ],
            [
              354388.63611745765,
              5008931.7841993812
            ],
            [
              354386.92077759089,
              5008932.0181981316
            ],
            [
              354385.20355867554,
              5008932.1511873687
            ],
            [
              354383.68120830093,
              5008931.8763736561
            ],
            [
              354382.45452812564,
              5008931.1918818075
            ],
            [
              354380.49812506005,
              5008929.3084310321
            ],
            [
              354379.47195055609,
              5008928.5191647634
            ],
            [
              354378.75834982865,
              5008928.2288869051
            ],
            [
              354378.04851497727,
              5008928.1410472132
            ],
            [
              354376.94221605238,
              5008928.4651799472
            ],
            [
              354376.04502880079,
              5008929.1891812924
            ],
            [
              354374.75888584304,
              5008930.7287740279
            ],
            [
              354373.96703973698,
              5008931.6529047089
            ],
            [
              354373.37115982088,
              5008932.2702564877
            ],
            [
              354372.46911685157,
              5008932.6912147496
            ],
            [
              354371.26498513372,
              5008933.2188371345
            ],
            [
              354370.26136689034,
              5008933.6421042373
            ],
            [
              354369.16257631668,
              5008934.3698559608
            ],
            [
              354367.97159788612,
              5008935.6045450056
            ],
            [
              354365.10474719031,
              5008939.1948519526
            ],
            [
              354364.01347278763,
              5008940.3266417943
            ],
            [
              354362.51367084071,
              5008941.2639423767
            ],
            [
              354360.90930914105,
              5008942.0010991013
            ],
            [
              354358.99910059309,
              5008942.6429009121
            ],
            [
              354355.77456339326,
              5008943.309152727
            ],
            [
              354351.03786006669,
              5008944.2060430888
            ],
            [
              354349.523025712,
              5008944.3352675065
            ],
            [
              354348.48525979888,
              5008944.3545727059
            ],
            [
              354347.60154283314,
              5008944.3710121876
            ],
            [
              354346.08670846245,
              5008944.50023661
            ],
            [
              354344.67721525015,
              5008944.8295903746
            ],
            [
              354343.47308345418,
              5008945.3572128434
            ],
            [
              354341.5816652737,
              5008947.0091100018
            ],
            [
              354337.6197819353,
              5008951.5291882213
            ],
            [
              354329.45528444921,
              5008960.8228706308
            ],
            [
              354322.23947379756,
              5008969.8498579031
            ],
            [
              354316.90677597985,
              5008976.8205053546
            ],
            [
              354312.27154903108,
              5008983.1723304493
            ],
            [
              354309.01197681361,
              5008987.3757917769
            ],
            [
              354305.35140101163,
              5008991.7892212356
            ],
            [
              354301.34276402212,
              5008995.9810418608
            ],
            [
              354297.27631326934,
              5009000.3005581591
            ],
            [
              354291.34852497128,
              5009007.8889632346
            ],
            [
              354279.49294077506,
              5009023.0653550429
            ],
            [
              354273.33204859815,
              5009030.5188990738
            ],
            [
              354268.86459432973,
              5009035.0483842529
            ],
            [
              354267.37606666581,
              5009036.5917428127
            ],
            [
              354266.48905613302,
              5009037.8207778865
            ],
            [
              354265.1126123977,
              5009039.9687376963
            ],
            [
              354264.32640355884,
              5009041.1958975773
            ],
            [
              354263.03086531529,
              5009042.2304434748
            ],
            [
              354261.92753507383,
              5009042.7561912015
            ],
            [
              354260.61508515128,
              5009042.8816511389
            ],
            [
              354258.89410750073,
              5009042.8126218747
            ],
            [
              354257.36799833184,
              5009042.3357894439
            ],
            [
              354256.04615302087,
              5009041.956201612
            ],
            [
              354254.7318240017,
              5009041.9806519924
            ],
            [
              354253.32154910261,
              5009042.3100207848
            ],
            [
              354251.41024247959,
              5009042.8507992616
            ],
            [
              354249.80510687252,
              5009043.5883903606
            ],
            [
              354247.80724973424,
              5009044.9391350467
            ],
            [
              354245.21993129543,
              5009047.2102460861
            ],
            [
              354240.55760839611,
              5009052.1475350456
            ],
            [
              354235.50445086631,
              5009057.7994064409
            ],
            [
              354232.19413287193,
              5009061.4600178422
            ],
            [
              354230.80828594667,
              5009063.1025110278
            ],
            [
              354229.7245356511,
              5009064.6387590775
            ],
            [
              354229.1418092979,
              5009065.9631782742
            ],
            [
              354228.37814945693,
              5009068.4024530863
            ],
            [
              354227.49678398267,
              5009069.934936191
            ],
            [
              354226.51382760867,
              5009071.468889934
            ],
            [
              354225.32472826558,
              5009072.8045895305
            ],
            [
              354224.23267245363,
              5009073.9363947921
            ],
            [
              354222.43720789329,
              5009075.2837939076
            ],
            [
              354220.54657108517,
              5009076.9356778236
            ],
            [
              354218.45652622671,
              5009078.7933603972
            ],
            [
              354215.68374255626,
              5009081.9777420787
            ],
            [
              354204.71219275746,
              5009095.7230706504
            ],
            [
              354191.7477437632,
              5009109.5729770511
            ],
            [
              354189.30147951323,
              5009112.3672318719
            ],
            [
              354187.12191532261,
              5009114.8332666261
            ],
            [
              354185.73794757115,
              5009116.5767698176
            ],
            [
              354184.55825154582,
              5009118.4179367237
            ],
            [
              354183.28714150895,
              5009120.7656076234
            ],
            [
              354181.82117084478,
              5009123.5215009646
            ],
            [
              354180.83821458108,
              5009125.0554550597
            ],
            [
              354179.55582997715,
              5009126.7970685549
            ],
            [
              354178.36673848436,
              5009128.1331876479
            ],
            [
              354176.77177183516,
              5009129.3753935229
            ],
            [
              354173.9783103863,
              5009131.4482515194
            ],
            [
              354171.97510835814,
              5009133.1839880757
            ],
            [
              354170.58926933381,
              5009134.8269009637
            ],
            [
              354169.40470965841,
              5009136.3646056158
            ],
            [
              354168.72306086967,
              5009137.7919099815
            ],
            [
              354168.32956580911,
              5009138.4054974727
            ],
            [
              354167.83151082881,
              5009138.8189410446
            ],
            [
              354165.62454154214,
              5009139.7698178887
            ],
            [
              354163.72184870124,
              5009140.8156594858
            ],
            [
              354162.42631038203,
              5009141.8502060594
            ],
            [
              354161.14204668876,
              5009143.4908101279
            ],
            [
              354158.49073504336,
              5009147.7844230207
            ],
            [
              354154.06087079557,
              5009154.3345220173
            ],
            [
              354147.53609806387,
              5009162.4388422361
            ],
            [
              354141.59702831547,
              5009169.4207767555
            ],
            [
              354133.81403613283,
              5009177.6243928559
            ],
            [
              354124.20123744902,
              5009188.3126946529
            ],
            [
              354120.23183832405,
              5009192.4287401335
            ],
            [
              354114.27585662476,
              5009198.5015893774
            ],
            [
              354112.6959229089,
              5009200.5518725654
            ],
            [
              354112.0142742751,
              5009201.9791772906
            ],
            [
              354111.64333653735,
              5009203.8052992262
            ],
            [
              354111.46804079833,
              5009205.2231840705
            ],
            [
              354111.29196365608,
              5009206.6410834519
            ],
            [
              354111.10649869713,
              5009207.5543539887
            ],
            [
              354110.51140023436,
              5009208.1716921795
            ],
            [
              354109.71313548967,
              5009208.7928099586
            ],
            [
              354107.91014656838,
              5009209.7357527167
            ],
            [
              354105.70129017346,
              5009210.5852014581
            ],
            [
              354103.56333521509,
              5009211.7163398098
            ],
            [
              354101.37437557016,
              5009213.6773278164
            ],
            [
              354099.99306851212,
              5009215.5218268996
            ],
            [
              354098.71632144839,
              5009217.5664699357
            ],
            [
              354097.7540435713,
              5009220.2119493876
            ],
            [
              354096.99711964413,
              5009223.0552784037
            ],
            [
              354096.91887525178,
              5009224.2696879823
            ],
            [
              354096.62696341111,
              5009224.8813859029
            ],
            [
              354096.02810669568,
              5009225.2967049479
            ],
            [
              354094.42108383164,
              5009225.932868504
            ],
            [
              354092.41758912551,
              5009226.9805860603
            ],
            [
              354090.3132926728,
              5009228.0301788906
            ],
            [
              354088.71755236748,
              5009229.272819207
            ],
            [
              354087.32497055794,
              5009230.5112608075
            ],
            [
              354086.6376846038,
              5009231.6355368942
            ],
            [
              354083.97885696078,
              5009235.5251129773
            ],
            [
              354081.6164808473,
              5009239.0049958359
            ],
            [
              354081.2305024052,
              5009240.0226220135
            ],
            [
              354080.55824954441,
              5009241.9549749698
            ],
            [
              354079.89242330432,
              5009244.1907613361
            ],
            [
              354079.13549953629,
              5009247.0340905497
            ],
            [
              354078.5629585209,
              5009248.8639632445
            ],
            [
              354078.07993663783,
              5009250.0854839217
            ],
            [
              354076.8964748914,
              5009251.7242135257
            ],
            [
              354074.88337952393,
              5009254.0626292592
            ],
            [
              354073.39485201164,
              5009255.6059896387
            ],
            [
              354071.30559643795,
              5009257.4640786806
            ],
            [
              354069.11662905297,
              5009259.4246480353
            ],
            [
              354067.92001334118,
              5009260.3563104579
            ],
            [
              354066.83061820106,
              5009261.5891119037
            ],
            [
              354065.94095520762,
              5009262.7175723277
            ],
            [
              354064.87223062228,
              5009265.0614794809
            ],
            [
              354064.00510953943,
              5009267.4016360948
            ],
            [
              354063.32612943562,
              5009268.9303553551
            ],
            [
              354062.73964530526,
              5009270.0527563179
            ],
            [
              354061.7472936433,
              5009271.081663318
            ],
            [
              354060.05826819042,
              5009272.7302176524
            ],
            [
              354057.66205989599,
              5009274.3915088912
            ],
            [
              354056.66594994394,
              5009275.2183966963
            ],
            [
              354055.67547742504,
              5009276.34831334
            ],
            [
              354053.60125502007,
              5009279.0144794974
            ],
            [
              354052.51859507646,
              5009280.6513340361
            ],
            [
              354051.92349664425,
              5009281.2686724588
            ],
            [
              354051.02443014894,
              5009281.8916657893
            ],
            [
              354049.10936449451,
              5009282.2304266971
            ],
            [
              354045.76820873411,
              5009282.0904952101
            ],
            [
              354043.94079077634,
              5009281.7203135407
            ],
            [
              354042.92964763136,
              5009281.7391244806
            ],
            [
              354042.22435170092,
              5009281.8532901229
            ],
            [
              354041.52203265444,
              5009282.1694895374
            ],
            [
              354040.9242736501,
              5009282.6858329009
            ],
            [
              354039.73972199165,
              5009284.2239577677
            ],
            [
              354036.59787278454,
              5009289.3350556679
            ],
            [
              354031.86909107567,
              5009298.3891622582
            ],
            [
              354029.72413338086,
              5009302.6733579133
            ],
            [
              354028.44472601666,
              5009304.6170066083
            ],
            [
              354026.96262519952,
              5009306.4638008587
            ],
            [
              354025.17388793797,
              5009308.2148358505
            ],
            [
              354022.88600574707,
              5009310.2787098279
            ],
            [
              354021.09429158288,
              5009311.8277110821
            ],
            [
              354019.29130248231,
              5009312.7706546253
            ],
            [
              354017.68803773733,
              5009313.6088379789
            ],
            [
              354016.89619184594,
              5009314.5329704983
            ],
            [
              354016.40941959532,
              5009315.5528913699
            ],
            [
              354016.23224511388,
              5009316.8697670391
            ],
            [
              354016.25291584019,
              5009317.9808728844
            ],
            [
              354016.18139919121,
              5009319.5989166703
            ],
            [
              354015.80372675275,
              5009321.0209862106
            ],
            [
              354015.31882588082,
              5009322.1414975878
            ],
            [
              354013.33036427997,
              5009323.9972929303
            ],
            [
              354010.03995325533,
              5009326.5850408133
            ],
            [
              354008.9374040523,
              5009327.1107753031
            ],
            [
              354007.42632666224,
              5009327.4420207916
            ],
            [
              354005.50186501193,
              5009327.2757338686
            ],
            [
              354003.57286362181,
              5009326.907442227
            ],
            [
              354001.95346780709,
              5009326.836524508
            ],
            [
              354000.43675290822,
              5009326.8647411214
            ],
            [
              353998.72329054441,
              5009327.1997517422
            ],
            [
              353998.02660893108,
              5009327.8189801723
            ],
            [
              353997.63499317801,
              5009328.5335778128
            ],
            [
              353997.25919212186,
              5009330.0562379407
            ],
            [
              353996.9454821627,
              5009331.7651575925
            ],
            [
              353996.80401960836,
              5009335.0016354024
            ],
            [
              353996.65237206296,
              5009337.7326604836
            ],
            [
              353996.16747901606,
              5009338.8535910752
            ],
            [
              353994.87569899292,
              5009340.090158158
            ],
            [
              353991.7688734135,
              5009341.6636257805
            ],
            [
              353990.36313746806,
              5009342.195000778
            ],
            [
              353988.74859767227,
              5009342.4271265557
            ],
            [
              353987.12544345582,
              5009342.1541896444
            ],
            [
              353985.39131023356,
              5009341.3780944133
            ],
            [
              353983.64402285876,
              5009339.8949317466
            ],
            [
              353982.42375934846,
              5009339.513454929
            ],
            [
              353980.90140687389,
              5009339.2386427028
            ],
            [
              353979.48173532391,
              5009339.0629648129
            ],
            [
              353977.96314116294,
              5009338.9901718497
            ],
            [
              353976.95763531904,
              5009339.312011865
            ],
            [
              353976.45394281362,
              5009339.4224270564
            ],
            [
              353975.43904114695,
              5009339.2392189028
            ],
            [
              353974.41849418945,
              5009338.7525627194
            ],
            [
              353973.29715325573,
              5009338.2682009535
            ],
            [
              353972.07610830088,
              5009337.8867386542
            ],
            [
              353970.25808571005,
              5009338.0216053836
            ],
            [
              353968.54838153481,
              5009338.5586353624
            ],
            [
              353967.04670759622,
              5009339.3953482527
            ],
            [
              353966.04871837533,
              5009340.1212268267
            ],
            [
              353963.47267477424,
              5009342.9983997755
            ],
            [
              353962.48221000849,
              5009344.1287360471
            ],
            [
              353961.68096824718,
              5009344.5478205942
            ],
            [
              353960.67546236125,
              5009344.8696606429
            ],
            [
              353959.66619814088,
              5009344.9894814137
            ],
            [
              353958.04226237565,
              5009344.7165590301
            ],
            [
              353956.72041558486,
              5009344.3369720364
            ],
            [
              353955.9116571488,
              5009344.352018035
            ],
            [
              353954.74395790993,
              5009344.1909390232
            ],
            [
              353953.73845200293,
              5009344.5127790738
            ],
            [
              353952.84126453224,
              5009345.2367823943
            ],
            [
              353952.15022035566,
              5009346.1590398652
            ],
            [
              353951.6728361416,
              5009347.6835900499
            ],
            [
              353951.61902132822,
              5009350.2111255061
            ],
            [
              353951.23868057347,
              5009351.5317811137
            ],
            [
              353950.44495546626,
              5009352.3549042605
            ],
            [
              353949.53915357293,
              5009352.5738448612
            ],
            [
              353948.31998768717,
              5009352.2933922308
            ],
            [
              353946.66974351963,
              5009350.6063348753
            ],
            [
              353941.00133435632,
              5009344.9518273557
            ],
            [
              353940.48714821652,
              5009344.4561701631
            ],
            [
              353939.87058968033,
              5009343.9619982149
            ],
            [
              353938.95539192838,
              5009343.6758905761
            ],
            [
              353937.93861098349,
              5009343.3916727724
            ],
            [
              353936.72508255852,
              5009343.4142490346
            ],
            [
              353935.91444491048,
              5009343.3282853868
            ],
            [
              353934.79608076549,
              5009343.0459574135
            ],
            [
              353933.67849802651,
              5009342.7636148985
            ],
            [
              353932.45933209098,
              5009342.4831622206
            ],
            [
              353931.04153950553,
              5009342.4084939668
            ],
            [
              353929.52372653905,
              5009342.3356864713
            ],
            [
              353927.20122973999,
              5009342.5809829934
            ],
            [
              353925.08565764333,
              5009343.0245191408
            ],
            [
              353921.75467789144,
              5009343.3896219106
            ],
            [
              353919.53376423666,
              5009343.6330286078
            ],
            [
              353917.92564301583,
              5009344.1681688568
            ],
            [
              353915.91839711199,
              5009345.0142874634
            ],
            [
              353914.81506622728,
              5009345.5400366979
            ],
            [
              353913.60795662744,
              5009345.8656273875
            ],
            [
              353911.38704292156,
              5009346.1090341061
            ],
            [
              353909.19302573212,
              5009345.9494390851
            ],
            [
              353906.25365385209,
              5009345.5995249199
            ],
            [
              353904.83774032322,
              5009345.6258663302
            ],
            [
              353903.42371375236,
              5009345.7536365213
            ],
            [
              353902.01609828701,
              5009346.1840020148
            ],
            [
              353900.71868043946,
              5009347.1175404955
            ],
            [
              353897.23417380225,
              5009350.1126595521
            ],
            [
              353891.56446532835,
              5009355.2707880922
            ],
            [
              353887.77490050002,
              5009358.1709572235
            ],
            [
              353884.97767975903,
              5009360.0417995006
            ],
            [
              353882.27938943019,
              5009361.8101759711
            ],
            [
              353877.27440895286,
              5009364.6314928066
            ],
            [
              353871.97564527759,
              5009367.9639176261
            ],
            [
              353869.67835876759,
              5009369.5223254701
            ],
            [
              353868.48925935809,
              5009370.8580274796
            ],
            [
              353867.70869612211,
              5009372.3886376545
            ],
            [
              353867.43369692162,
              5009373.9094230989
            ],
            [
              353867.0571145172,
              5009375.4320983896
            ],
            [
              353866.17950784753,
              5009377.1666032225
            ],
            [
              353865.29171609663,
              5009378.3956551431
            ],
            [
              353864.00369408069,
              5009379.8342421763
            ],
            [
              353862.00771521515,
              5009381.2860000003
            ],
            [
              353859.60509492981,
              5009382.6446976168
            ],
            [
              353858.00824855431,
              5009383.7858961541
            ],
            [
              353856.58620840794,
              5009384.9955381854
            ],
            [
              353855.50245058583,
              5009386.5313697895
            ],
            [
              353854.53265662235,
              5009388.7728129029
            ],
            [
              353853.56097569346,
              5009390.9128272347
            ],
            [
              353853.18063498923,
              5009392.2334832652
            ],
            [
              353852.79356677434,
              5009393.1505052112
            ],
            [
              353851.88886242203,
              5009393.4704701873
            ],
            [
              353850.37778440997,
              5009393.8017161237
            ],
            [
              353848.46083857323,
              5009394.0394681832
            ],
            [
              353847.65583824605,
              5009394.2565336451
            ],
            [
              353847.05432071496,
              5009394.5708581535
            ],
            [
              353846.16167284403,
              5009395.496866635
            ],
            [
              353845.07713362615,
              5009397.0327128554
            ],
            [
              353844.30220800004,
              5009398.8663522108
            ],
            [
              353843.52539540268,
              5009400.5985627761
            ],
            [
              353842.04250510375,
              5009402.4449538449
            ],
            [
              353840.35535839241,
              5009404.1945195515
            ],
            [
              353838.2660944197,
              5009406.0521916328
            ],
            [
              353836.96867649705,
              5009406.9857305102
            ],
            [
              353835.06223208905,
              5009407.8299743645
            ],
            [
              353833.72256951517,
              5009408.2154716775
            ],
            [
              353830.79181924119,
              5009408.3710400173
            ],
            [
              353828.36742223561,
              5009408.5171881588
            ],
            [
              353825.13912261958,
              5009408.9814260285
            ],
            [
              353822.11884556938,
              5009409.7449277705
            ],
            [
              353817.99789799581,
              5009411.1351742567
            ],
            [
              353815.68856259383,
              5009412.0879582139
            ],
            [
              353814.39302378905,
              5009413.1225068588
            ],
            [
              353812.6107049436,
              5009415.1765579982
            ],
            [
              353812.12580409588,
              5009416.2970701698
            ],
            [
              353811.83953771769,
              5009417.2122169323
            ],
            [
              353811.34524093539,
              5009417.8276807005
            ],
            [
              353810.74450477154,
              5009418.1419907426
            ],
            [
              353809.73711932573,
              5009418.3628214672
            ],
            [
              353808.72973387805,
              5009418.5836521918
            ],
            [
              353807.72422761546,
              5009418.9054925991
            ],
            [
              353806.12284133577,
              5009419.8446866469
            ],
            [
              353801.74193588947,
              5009423.5642156964
            ],
            [
              353799.05303339154,
              5009425.8372223945
            ],
            [
              353797.9580004288,
              5009426.7669962719
            ],
            [
              353796.96001870249,
              5009427.4932945836
            ],
            [
              353795.75212735904,
              5009427.8189002136
            ],
            [
              353794.43779661751,
              5009427.8433521101
            ],
            [
              353793.0200034462,
              5009427.7686841795
            ],
            [
              353790.93304624286,
              5009427.0192779582
            ],
            [
              353790.01330823585,
              5009426.5311655793
            ],
            [
              353789.50773629552,
              5009426.5405712724
            ],
            [
              353788.80056070554,
              5009426.55372761
            ],
            [
              353788.20280148234,
              5009427.0700716069
            ],
            [
              353787.51066721376,
              5009427.8917245846
            ],
            [
              353786.83089807682,
              5009429.4200407965
            ],
            [
              353786.44679906423,
              5009430.5386777483
            ],
            [
              353785.95250226936,
              5009431.1541415807
            ],
            [
              353785.25769960706,
              5009431.8743802924
            ],
            [
              353784.25783086481,
              5009432.4992498308
            ],
            [
              353781.84659568872,
              5009433.3528851252
            ],
            [
              353777.62218529941,
              5009434.6440122295
            ],
            [
              353771.78025713842,
              5009435.9652318126
            ],
            [
              353767.34218640212,
              5009436.6540660141
            ],
            [
              353764.11654685624,
              5009437.2192993909
            ],
            [
              353760.87885070877,
              5009437.1784891365
            ],
            [
              353759.5626406403,
              5009437.1019313736
            ],
            [
              353758.35099078721,
              5009437.2255176799
            ],
            [
              353757.54599027883,
              5009437.4425833011
            ],
            [
              353756.14323050604,
              5009438.1759929871
            ],
            [
              353754.53699566267,
              5009438.8125627628
            ],
            [
              353753.22830234695,
              5009439.1400437998
            ],
            [
              353751.81426739332,
              5009439.2673953073
            ],
            [
              353750.80124420603,
              5009439.185197033
            ],
            [
              353749.98496851302,
              5009438.7962044645
            ],
            [
              353748.96254155168,
              5009438.2085385593
            ],
            [
              353748.2470676924,
              5009437.817670661
            ],
            [
              353747.73773730412,
              5009437.6250569709
            ],
            [
              353746.82629735098,
              5009437.5409688242
            ],
            [
              353746.11834026367,
              5009437.5541397259
            ],
            [
              353745.41602075793,
              5009437.8703397289
            ],
            [
              353744.8209298781,
              5009438.4880981026
            ],
            [
              353744.22880817967,
              5009439.3074712893
            ],
            [
              353743.98270213255,
              5009439.7342572352
            ],
            [
              353743.4846469044,
              5009440.1477017542
            ],
            [
              353742.87749164941,
              5009440.1589973336
            ],
            [
              353742.06497432612,
              5009439.9720241623
            ],
            [
              353740.83265327109,
              5009438.9845037591
            ],
            [
              353740.31392689823,
              5009438.2868415536
            ],
            [
              353739.60111362464,
              5009437.9969688095
            ],
            [
              353739.09554161067,
              5009438.0063745212
            ],
            [
              353738.48838634789,
              5009438.0176700968
            ],
            [
              353737.58634234191,
              5009438.4386304673
            ],
            [
              353737.20224330411,
              5009439.5572675867
            ],
            [
              353736.93476108083,
              5009441.4820924737
            ],
            [
              353736.5506620527,
              5009442.6007296024
            ],
            [
              353735.76258205558,
              5009443.7273017121
            ],
            [
              353735.07341690751,
              5009444.7505696649
            ],
            [
              353733.97274620383,
              5009445.3773146626
            ],
            [
              353732.36462427577,
              5009445.9124556808
            ],
            [
              353730.25202810409,
              5009446.5580266714
            ],
            [
              353728.1326965405,
              5009446.7995443065
            ],
            [
              353725.30352863926,
              5009446.9532231595
            ],
            [
              353723.18341563648,
              5009447.1947553391
            ],
            [
              353722.07146971632,
              5009447.2154421164
            ],
            [
              353720.96516138385,
              5009447.539158022
            ],
            [
              353719.85807163775,
              5009447.8628884647
            ],
            [
              353718.25747423636,
              5009448.8024874795
            ],
            [
              353715.65324172942,
              5009450.164517669
            ],
            [
              353714.54991036514,
              5009450.690267548
            ],
            [
              353713.54252466018,
              5009450.9110984281
            ],
            [
              353711.92876505258,
              5009451.143210372
            ],
            [
              353710.61631319002,
              5009451.2686720742
            ],
            [
              353709.81241036771,
              5009451.5867620083
            ],
            [
              353708.61657534388,
              5009452.5184115423
            ],
            [
              353707.52076855378,
              5009453.4486194123
            ],
            [
              353704.63777862192,
              5009456.1294164835
            ],
            [
              353701.8574775345,
              5009458.909767108
            ],
            [
              353700.26924528083,
              5009460.5560296327
            ],
            [
              353699.07262883842,
              5009461.4876937596
            ],
            [
              353697.77708974585,
              5009462.5222429419
            ],
            [
              353695.72639814432,
              5009463.7221984956
            ],
            [
              353693.62397920276,
              5009464.8728037076
            ],
            [
              353691.2081958605,
              5009465.5240154304
            ],
            [
              353688.18603856099,
              5009466.1865082504
            ],
            [
              353685.76164057746,
              5009466.3326568669
            ],
            [
              353683.84579166834,
              5009466.6714337012
            ],
            [
              353682.84592269635,
              5009467.2963035256
            ],
            [
              353681.64178939821,
              5009467.8239288442
            ],
            [
              353679.11768730689,
              5009468.072977067
            ],
            [
              353675.27925400314,
              5009468.3464773558
            ],
            [
              353673.16180132225,
              5009468.6890048869
            ],
            [
              353671.3513018192,
              5009469.2283304473
            ],
            [
              353669.9560585802,
              5009470.3657793403
            ],
            [
              353668.66615701863,
              5009471.7033578111
            ],
            [
              353667.08810210909,
              5009473.8546546372
            ],
            [
              353666.21347243252,
              5009475.7911947127
            ],
            [
              353665.52806565404,
              5009477.0164823895
            ],
            [
              353664.8501757037,
              5009478.645808964
            ],
            [
              353663.77770074137,
              5009480.7881191978
            ],
            [
              353662.50471206865,
              5009483.0347852577
            ],
            [
              353660.8175648938,
              5009484.7843522793
            ],
            [
              353658.9344438258,
              5009486.8402800495
            ],
            [
              353654.46135001845,
              5009491.0667500645
            ],
            [
              353650.07402437937,
              5009494.4832670167
            ],
            [
              353648.33194506011,
              5009496.0112223905
            ],
            [
              353646.44318635372,
              5009497.7641211087
            ],
            [
              353644.64395423478,
              5009498.9090861194
            ],
            [
              353643.04445442738,
              5009499.9497098438
            ],
            [
              353641.54277167033,
              5009500.7860051086
            ],
            [
              353640.54102342512,
              5009501.3098653797
            ],
            [
              353639.54491281859,
              5009502.1367548453
            ],
            [
              353638.86216670513,
              5009503.4630378634
            ],
            [
              353638.27192419517,
              5009504.3834211435
            ],
            [
              353636.99142652057,
              5009506.2264676578
            ],
            [
              353635.61951526167,
              5009508.5760190692
            ],
            [
              353634.35326991981,
              5009511.2271580137
            ],
            [
              353632.97196259996,
              5009513.0716607952
            ],
            [
              353632.18387480086,
              5009514.1978142792
            ],
            [
              353631.18965121632,
              5009515.1261326652
            ],
            [
              353630.19166139857,
              5009515.8520124601
            ],
            [
              353628.7889012766,
              5009516.5854227385
            ],
            [
              353627.38614115037,
              5009517.3188330224
            ],
            [
              353624.97677650984,
              5009518.2730599111
            ],
            [
              353623.1737859096,
              5009519.2160056271
            ],
            [
              353621.28049513383,
              5009520.7673187908
            ],
            [
              353619.69680265611,
              5009522.6155869262
            ],
            [
              353618.52273674699,
              5009524.7593683377
            ],
            [
              353617.86928460631,
              5009527.7022409504
            ],
            [
              353617.2120740441,
              5009530.443094098
            ],
            [
              353616.43368379102,
              5009533.3509758655
            ],
            [
              353616.15116821404,
              5009534.4677235922
            ],
            [
              353615.86113574466,
              5009535.1804323671
            ],
            [
              353615.56734482996,
              5009535.6911216658
            ],
            [
              353615.26979546709,
              5009535.9997914871
            ],
            [
              353614.6645192139,
              5009536.1120969802
            ],
            [
              353612.94541746838,
              5009536.14407981
            ],
            [
              353611.42118398024,
              5009535.7682584282
            ],
            [
              353610.10497331934,
              5009535.6917010108
            ],
            [
              353609.5023576903,
              5009535.9050017064
            ],
            [
              353608.8056756694,
              5009536.5242312588
            ],
            [
              353608.31702428148,
              5009537.4431439117
            ],
            [
              353607.9423215476,
              5009539.0668302672
            ],
            [
              353607.01762930892,
              5009543.7325092042
            ],
            [
              353606.83669704461,
              5009544.8473670743
            ],
            [
              353606.54400395299,
              5009545.4590806747
            ],
            [
              353606.25021304452,
              5009545.9697699957
            ],
            [
              353605.55165180884,
              5009546.4879898354
            ],
            [
              353604.64396990777,
              5009546.6059214259
            ],
            [
              353602.82406623522,
              5009546.6397796692
            ],
            [
              353601.00040409976,
              5009546.471618427
            ],
            [
              353600.09006155003,
              5009546.4885548158
            ],
            [
              353599.28130228742,
              5009546.503601308
            ],
            [
              353598.37659741373,
              5009546.8235669276
            ],
            [
              353597.67803616292,
              5009547.3417867748
            ],
            [
              353596.98590180225,
              5009548.163440451
            ],
            [
              353596.20079108147,
              5009549.4916281579
            ],
            [
              353595.52744120773,
              5009551.3229601542
            ],
            [
              353594.59711141337,
              5009555.6856100624
            ],
            [
              353594.22428798157,
              5009557.410306247
            ],
            [
              353593.93802180135,
              5009558.3254537014
            ],
            [
              353593.35607769591,
              5009559.6498616394
            ],
            [
              353592.065394787,
              5009560.9874552842
            ],
            [
              353587.19394978805,
              5009565.5244723242
            ],
            [
              353586.00672940462,
              5009566.9611858577
            ],
            [
              353585.32133058965,
              5009568.1868931232
            ],
            [
              353584.20293746097,
              5009570.003864293
            ],
            [
              353583.62772891286,
              5009571.7323258156
            ],
            [
              353582.46948627877,
              5009574.6845901934
            ],
            [
              353581.60613237502,
              5009577.2271894915
            ],
            [
              353580.83307856251,
              5009579.1614212748
            ],
            [
              353579.86515652994,
              5009581.5034573404
            ],
            [
              353578.98301028932,
              5009583.035959186
            ],
            [
              353577.00394378504,
              5009585.3968071388
            ],
            [
              353573.04957706732,
              5009590.3209417574
            ],
            [
              353570.17974957096,
              5009593.7092284113
            ],
            [
              353565.7028974499,
              5009597.7336814012
            ],
            [
              353556.86392339453,
              5009606.4873469379
            ],
            [
              353550.28018640232,
              5009613.3550937911
            ],
            [
              353546.60535805655,
              5009616.9604470395
            ],
            [
              353542.62468195573,
              5009620.4704458248
            ],
            [
              353537.64037044154,
              5009624.4028765867
            ],
            [
              353532.15613214037,
              5009628.648161876
            ],
            [
              353525.86689288059,
              5009633.1105135279
            ],
            [
              353519.97036705812,
              5009636.9592906618
            ],
            [
              353515.72031721071,
              5009639.2252880754
            ],
            [
              353514.12080943555,
              5009640.265493717
            ],
            [
              353511.82728029648,
              5009642.0259245392
            ],
            [
              353509.43859481375,
              5009644.0916792089
            ],
            [
              353506.65452737088,
              5009646.6695942637
            ],
            [
              353503.574016214,
              5009649.657622817
            ],
            [
              353522.92945222597,
              5009663.1709949225
            ],
            [
              353562.469370412,
              5009696.6552360756
            ],
            [
              353585.2544838498,
              5009683.4613572573
            ],
            [
              353598.66285100294,
              5009701.18872594
            ],
            [
              353616.74465474131,
              5009683.5997575726
            ],
            [
              353641.65308499226,
              5009639.7497906275
            ],
            [
              353666.89025881101,
              5009613.5696576051
            ],
            [
              353687.63455730537,
              5009605.1491687531
            ],
            [
              353714.1568117615,
              5009648.0423293384
            ],
            [
              353716.06292771298,
              5009664.0759639787
            ],
            [
              353705.17106644454,
              5009683.5614998657
            ],
            [
              353697.28444201878,
              5009691.742778155
            ],
            [
              353723.47795854282,
              5009716.9659964293
            ],
            [
              353730.75428950222,
              5009727.0433315933
            ],
            [
              353756.2198937434,
              5009748.4950268706
            ],
            [
              353787.50400270935,
              5009788.0857213549
            ],
            [
              353800.86973904801,
              5009815.1544872923
            ],
            [
              353773.90578482574,
              5009834.9390486144
            ],
            [
              353741.67199403525,
              5009830.7180704651
            ],
            [
              353665.97711195349,
              5009737.3186922036
            ],
            [
              353649.90500186553,
              5009737.6177132968
            ],
            [
              353622.70194213476,
              5009744.5514770979
            ],
            [
              353611.39175498829,
              5009741.5481045293
            ],
            [
              353605.49796150747,
              5009731.6705676736
            ],
            [
              353598.18254847947,
              5009702.0071522519
            ],
            [
              353566.17888184474,
              5009756.5407980205
            ],
            [
              353518.97143788199,
              5009811.3496754766
            ],
            [
              353536.34386755509,
              5009822.4662680198
            ],
            [
              353536.45025046362,
              5009828.1841730047
            ],
            [
              353538.5376524509,
              5009830.61127837
            ],
            [
              353526.63241544884,
              5009844.5942032589
            ],
            [
              353530.92968996015,
              5009851.8750153454
            ],
            [
              353517.43020854221,
              5009866.2076234259
            ],
            [
              353513.6047237771,
              5009874.58220773
            ],
            [
              353528.62018835166,
              5009884.79117708
            ],
            [
              353534.73102895624,
              5009888.945919103
            ],
            [
              353534.76682803273,
              5009890.8700406905
            ],
            [
              353544.50415559608,
              5009896.7840412455
            ],
            [
              353545.35315314197,
              5009890.6730777156
            ],
            [
              353572.53742030042,
              5009885.6761187371
            ],
            [
              353583.93069466099,
              5009874.0340228928
            ],
            [
              353588.14913406677,
              5009879.6112532606
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 196,
        "Layer" : "B02209B-POLJOPRIVREDA_03",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358696.25367639767,
              5007488.1690241406
            ],
            [
              358712.81192955724,
              5007495.6068625357
            ],
            [
              358723.57565176085,
              5007509.0399227859
            ],
            [
              358814.56427119783,
              5007501.1887696628
            ],
            [
              358841.57653634448,
              5007457.2218211191
            ],
            [
              358834.42600772687,
              5007440.5700789411
            ],
            [
              358813.82580135419,
              5007417.2407171866
            ],
            [
              358807.52178906748,
              5007397.2023771107
            ],
            [
              358800.84313266393,
              5007357.0154205058
            ],
            [
              358793.13295194809,
              5007325.1469852328
            ],
            [
              358796.00711427216,
              5007288.3392160442
            ],
            [
              358797.42042562756,
              5007247.4524938073
            ],
            [
              358797.79069233284,
              5007203.5775342155
            ],
            [
              358794.73573963525,
              5007166.8800330432
            ],
            [
              358781.00812295271,
              5007130.381014281
            ],
            [
              358762.93387849932,
              5007115.3040488958
            ],
            [
              358741.72094961797,
              5007122.8123108437
            ],
            [
              358727.71121987171,
              5007134.9290464614
            ],
            [
              358705.79738484399,
              5007168.5340582738
            ],
            [
              358705.20225403941,
              5007136.5332891596
            ],
            [
              358688.86052710464,
              5007087.0410453258
            ],
            [
              358672.71717903222,
              5007048.2156659653
            ],
            [
              358646.341991572,
              5007033.2930644974
            ],
            [
              358593.21706695837,
              5006983.2991698664
            ],
            [
              358587.60906531435,
              5006940.1469447203
            ],
            [
              358586.132316934,
              5006860.7376061911
            ],
            [
              358588.52171303518,
              5006797.8550580535
            ],
            [
              358580.63517608918,
              5006756.5048910268
            ],
            [
              358567.91717685893,
              5006710.5020785592
            ],
            [
              358554.15466757648,
              5006680.5723349405
            ],
            [
              358524.70254869387,
              5006627.7669386035
            ],
            [
              358492.08258995769,
              5006583.4650425129
            ],
            [
              358479.58175505273,
              5006598.9651771467
            ],
            [
              358418.96505005221,
              5006568.2391684419
            ],
            [
              358381.56715510722,
              5006537.1103326874
            ],
            [
              358367.18790303927,
              5006515.8806543797
            ],
            [
              358361.62961807387,
              5006488.3189205583
            ],
            [
              358388.94015975081,
              5006444.7431497844
            ],
            [
              358395.19005463237,
              5006425.0622508079
            ],
            [
              358379.4765345754,
              5006409.3485424081
            ],
            [
              358358.06952993886,
              5006388.8395344988
            ],
            [
              358334.83986240107,
              5006369.274505971
            ],
            [
              358319.00512607076,
              5006347.042069748
            ],
            [
              358317.07147635875,
              5006307.2559612943
            ],
            [
              358322.03861018817,
              5006267.2010836843
            ],
            [
              358321.43726543436,
              5006234.8616327848
            ],
            [
              358317.27685870888,
              5006215.9092360567
            ],
            [
              358306.98200313916,
              5006174.2351519866
            ],
            [
              358281.14209231228,
              5006115.7232997157
            ],
            [
              358253.64263989887,
              5006056.0921019567
            ],
            [
              358231.55101263901,
              5006050.5093670441
            ],
            [
              358228.71496491652,
              5006061.7319861259
            ],
            [
              358216.41798638896,
              5006050.3137503723
            ],
            [
              358193.41331329296,
              5006068.9909137515
            ],
            [
              358251.64522847079,
              5006140.3812557338
            ],
            [
              358273.48591960286,
              5006205.2371907625
            ],
            [
              358272.86000351823,
              5006220.8423955105
            ],
            [
              358282.65489654843,
              5006240.77678122
            ],
            [
              358274.55984845181,
              5006264.3490983164
            ],
            [
              358280.58235184551,
              5006285.0969086718
            ],
            [
              358274.4101771494,
              5006299.3435502658
            ],
            [
              358276.39062310383,
              5006330.5863729613
            ],
            [
              358275.71924484405,
              5006344.2197053535
            ],
            [
              358280.01928177848,
              5006351.4776272224
            ],
            [
              358286.03780181002,
              5006367.8622020455
            ],
            [
              358288.37873453501,
              5006381.7454006989
            ],
            [
              358296.94835057209,
              5006392.3959449604
            ],
            [
              358292.39243764075,
              5006397.0524587203
            ],
            [
              358271.7148816369,
              5006410.1174147204
            ],
            [
              358284.46349408029,
              5006434.8756225258
            ],
            [
              358292.04871934798,
              5006458.7979292739
            ],
            [
              358307.08964799112,
              5006481.4841248961
            ],
            [
              358322.16407938645,
              5006505.9590726234
            ],
            [
              358343.32121437782,
              5006540.8369590817
            ],
            [
              358318.0192477791,
              5006553.2453163834
            ],
            [
              358331.27770260919,
              5006575.1699944288
            ],
            [
              358362.03575567272,
              5006555.8414835539
            ],
            [
              358366.58214798186,
              5006566.2411508765
            ],
            [
              358362.27784721775,
              5006576.470574663
            ],
            [
              358403.81804599508,
              5006614.9315912677
            ],
            [
              358458.4585221188,
              5006633.77287973
            ],
            [
              358461.83707289957,
              5006642.3326658756
            ],
            [
              358467.60141911433,
              5006653.335786948
            ],
            [
              358400.24071173306,
              5006707.1940633422
            ],
            [
              358423.17310194665,
              5006735.8905916493
            ],
            [
              358439.58450078074,
              5006765.1872509671
            ],
            [
              358454.17591142113,
              5006810.3759519588
            ],
            [
              358465.53609750053,
              5006852.4530770192
            ],
            [
              358473.58652249636,
              5006887.1912608612
            ],
            [
              358489.23534303624,
              5006932.360233089
            ],
            [
              358494.83678733435,
              5006949.1714274827
            ],
            [
              358511.47168343299,
              5006981.9146071887
            ],
            [
              358519.21193848172,
              5006989.6820636028
            ],
            [
              358523.42331010121,
              5006987.5741711101
            ],
            [
              358550.98469141434,
              5007001.6546387263
            ],
            [
              358598.16075750574,
              5007049.5346900886
            ],
            [
              358613.78592774965,
              5007044.7265759064
            ],
            [
              358638.68473105616,
              5007055.57194144
            ],
            [
              358665.41348604026,
              5007075.7686688872
            ],
            [
              358670.61189670529,
              5007106.121406801
            ],
            [
              358693.44437170721,
              5007137.9127863823
            ],
            [
              358690.7921884245,
              5007152.3331682319
            ],
            [
              358694.26174446376,
              5007209.3347358145
            ],
            [
              358687.19241184078,
              5007259.4480930204
            ],
            [
              358673.90107429336,
              5007297.6815132257
            ],
            [
              358672.64482329466,
              5007337.6903755041
            ],
            [
              358675.9454033096,
              5007407.6036550859
            ],
            [
              358672.54076713574,
              5007439.6554070953
            ],
            [
              358652.81001604779,
              5007470.1431636317
            ],
            [
              358675.53082841495,
              5007478.7442235555
            ],
            [
              358696.25367639767,
              5007488.1690241406
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 197,
        "Layer" : "B08220A-OBALNO_PODRUCJE",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              362452.8940090363,
              5001107.8783042198
            ],
            [
              362449.59634662111,
              5001098.5656665275
            ],
            [
              360793.02925008099,
              4997652.6079473831
            ],
            [
              360708.92474710249,
              4997690.8939706562
            ],
            [
              360017.64444442576,
              4998092.2470295243
            ],
            [
              360009.10006179562,
              4998211.7939036721
            ],
            [
              359809.26488414628,
              4998450.977445961
            ],
            [
              359637.15981768613,
              4998944.1879356476
            ],
            [
              359616.99888162554,
              4999001.1459975783
            ],
            [
              359309.08367913199,
              4999675.7977097472
            ],
            [
              358334.84863760712,
              5001675.6609015437
            ],
            [
              358156.9864227979,
              5002073.5091132335
            ],
            [
              357742.07686052396,
              5002800.938433242
            ],
            [
              357111.67875407304,
              5003964.7054190012
            ],
            [
              356827.12547199347,
              5004564.4042757284
            ],
            [
              356442.11952545599,
              5005025.3786352985
            ],
            [
              355812.3582261196,
              5005668.7998481169
            ],
            [
              353724.4708760372,
              5007717.5587956561
            ],
            [
              352061.65830949269,
              5009349.6520935055
            ],
            [
              351876.1305852959,
              5009579.877742162
            ],
            [
              351607.95176237618,
              5009879.6820727894
            ],
            [
              351277.53820035869,
              5010185.9292933485
            ],
            [
              350822.29433743778,
              5010701.8628429417
            ],
            [
              350833.60470247437,
              5010796.3124525528
            ],
            [
              351326.60614425992,
              5010934.4654876888
            ],
            [
              351385.16597448598,
              5010981.082906683
            ],
            [
              351401.22763249394,
              5011061.4572155084
            ],
            [
              351370.49386388779,
              5011132.554112643
            ],
            [
              351222.24499936664,
              5011376.5597703494
            ],
            [
              351150.42503099696,
              5011477.8524488863
            ],
            [
              351081.94899070077,
              5011555.7578927735
            ],
            [
              351095.44461170887,
              5011807.8752244459
            ],
            [
              351131.55288331886,
              5012095.5279449774
            ],
            [
              351266.78129084595,
              5012095.770764431
            ],
            [
              351704.21167462593,
              5012067.2809982924
            ],
            [
              351708.41959227069,
              5012082.2422075327
            ],
            [
              351715.08031794493,
              5012101.3220695732
            ],
            [
              351722.64377459796,
              5012122.9918716969
            ],
            [
              351728.13470245508,
              5012139.738797076
            ],
            [
              351732.80908676825,
              5012152.6824135631
            ],
            [
              351739.09442903096,
              5012157.6428698953
            ],
            [
              351759.69732504972,
              5012174.1530736955
            ],
            [
              351779.06992044364,
              5012189.7612297721
            ],
            [
              351797.91065353871,
              5012205.2623299146
            ],
            [
              351816.84094268677,
              5012220.9183163447
            ],
            [
              351824.5654706874,
              5012227.2204282442
            ],
            [
              351840.88097209652,
              5012240.6320044063
            ],
            [
              351856.50852563948,
              5012253.3458889006
            ],
            [
              351870.04125702509,
              5012264.2188361278
            ],
            [
              351880.46107181121,
              5012272.2214917
            ],
            [
              351892.67858792696,
              5012282.4458528608
            ],
            [
              351901.22292791231,
              5012291.9752133805
            ],
            [
              351905.43914576614,
              5012296.7805241365
            ],
            [
              351917.54107571108,
              5012306.5188654335
            ],
            [
              351926.42670618271,
              5012313.7551079551
            ],
            [
              351930.95095208019,
              5012317.371550696
            ],
            [
              351936.0146241468,
              5012321.420742251
            ],
            [
              351936.4589231725,
              5012321.7458515726
            ],
            [
              351951.2011478651,
              5012330.1177035403
            ],
            [
              351964.36040279985,
              5012341.6825505253
            ],
            [
              351969.4768015049,
              5012346.1046562428
            ],
            [
              351975.39376113546,
              5012351.1789103374
            ],
            [
              351978.97368396749,
              5012354.9000571147
            ],
            [
              351987.39352703554,
              5012363.5784340994
            ],
            [
              351990.1963739816,
              5012366.5149215851
            ],
            [
              352000.26339603384,
              5012376.8468625695
            ],
            [
              352010.88263204455,
              5012387.8892953945
            ],
            [
              352018.77925354772,
              5012395.9758803863
            ],
            [
              352024.48152258346,
              5012403.2215567213
            ],
            [
              352028.09882953338,
              5012407.9780243365
            ],
            [
              352038.22242232069,
              5012419.7544160429
            ],
            [
              352051.69973798888,
              5012435.2398290662
            ],
            [
              352056.90583149315,
              5012441.0600201935
            ],
            [
              352066.20867826242,
              5012451.4685541037
            ],
            [
              352076.27139854949,
              5012462.8831274621
            ],
            [
              352082.26396783808,
              5012470.9604705246
            ],
            [
              352091.92447038041,
              5012483.7241284959
            ],
            [
              352092.27040482307,
              5012484.1013747873
            ],
            [
              352109.04110612342,
              5012497.3974862983
            ],
            [
              352115.49967934849,
              5012502.0096567739
            ],
            [
              352129.46455758234,
              5012512.1016158219
            ],
            [
              352131.17586449732,
              5012513.3746295162
            ],
            [
              352138.97480395512,
              5012505.2257336415
            ],
            [
              352156.23347233678,
              5012519.2436772827
            ],
            [
              352156.92154687643,
              5012519.7979774121
            ],
            [
              352157.98393527715,
              5012518.2357523367
            ],
            [
              352172.59503367887,
              5012526.2756108781
            ],
            [
              352180.64080908266,
              5012530.6556111164
            ],
            [
              352184.30898261769,
              5012532.6377188889
            ],
            [
              352188.69491163507,
              5012534.6351801548
            ],
            [
              352218.19411072374,
              5012547.6125160633
            ],
            [
              352225.63315750181,
              5012575.3813377591
            ],
            [
              352231.01548588689,
              5012594.7266787644
            ],
            [
              352236.85561570438,
              5012615.9903923478
            ],
            [
              352239.19644523895,
              5012624.5550691839
            ],
            [
              352243.61824312928,
              5012640.1437577223
            ],
            [
              352243.86487994244,
              5012640.8151178984
            ],
            [
              352254.34592097055,
              5012657.0105147157
            ],
            [
              352283.07380852971,
              5012677.6169530516
            ],
            [
              352281.45664121112,
              5012680.2967389459
            ],
            [
              352287.27015860035,
              5012684.7507171025
            ],
            [
              352310.27746505843,
              5012675.9674046217
            ],
            [
              352308.6330832899,
              5012708.3754048077
            ],
            [
              352307.59356917761,
              5012727.0373990955
            ],
            [
              352308.17828575248,
              5012731.9395148689
            ],
            [
              352310.7954747242,
              5012755.7034446644
            ],
            [
              352305.931554057,
              5012772.3354269294
            ],
            [
              352311.6266649735,
              5012778.193226059
            ],
            [
              352321.65277791471,
              5012788.5597315375
            ],
            [
              352330.79606802232,
              5012797.7666624906
            ],
            [
              352331.57638001506,
              5012798.5785199804
            ],
            [
              352331.66643996089,
              5012798.467961425
            ],
            [
              352346.79142573528,
              5012779.7779970802
            ],
            [
              352369.54868618096,
              5012751.3641278781
            ],
            [
              352391.06720626523,
              5012772.5677865818
            ],
            [
              352411.23402028665,
              5012792.2740928866
            ],
            [
              352422.01435063354,
              5012802.909867676
            ],
            [
              352441.41922019352,
              5012821.9848267119
            ],
            [
              352457.96984865871,
              5012838.170526485
            ],
            [
              352474.62646091869,
              5012854.650337345
            ],
            [
              352491.2378734799,
              5012871.3792692516
            ],
            [
              352503.76514981978,
              5012884.451992264
            ],
            [
              352513.68323706673,
              5012894.4941005167
            ],
            [
              352531.14769495482,
              5012912.2714383984
            ],
            [
              352539.86312696279,
              5012921.0148933968
            ],
            [
              352556.39367460989,
              5012937.7520624083
            ],
            [
              352578.17339768115,
              5012952.2143115159
            ],
            [
              352605.78793242195,
              5012970.3062461726
            ],
            [
              352621.5061794903,
              5012980.9677543426
            ],
            [
              352631.77600147814,
              5012987.8781593461
            ],
            [
              352639.52331333078,
              5012993.5184858851
            ],
            [
              352659.89012561017,
              5013007.2313116444
            ],
            [
              352679.73431304999,
              5013020.9329328081
            ],
            [
              352694.60093146603,
              5013030.8445523679
            ],
            [
              352699.85977235762,
              5013034.6626846641
            ],
            [
              352706.18094699812,
              5013038.9435585756
            ],
            [
              352712.376814975,
              5013043.3364555761
            ],
            [
              352722.03797406942,
              5013049.7704545464
            ],
            [
              352728.77761766012,
              5013054.4876414146
            ],
            [
              352743.83662402711,
              5013064.4641455011
            ],
            [
              352761.48736451747,
              5013076.3678745441
            ],
            [
              352774.27281991526,
              5013093.7334732357
            ],
            [
              352788.25819551578,
              5013112.9092876762
            ],
            [
              352794.81099673448,
              5013122.2829001928
            ],
            [
              352801.40585541085,
              5013131.6829268932
            ],
            [
              352807.57266300166,
              5013140.3617397621
            ],
            [
              352819.46367772977,
              5013157.1493047206
            ],
            [
              352833.43600680958,
              5013176.7170983739
            ],
            [
              352846.61072544853,
              5013195.4610491777
            ],
            [
              352859.6445734782,
              5013213.8634807058
            ],
            [
              352868.46004736068,
              5013226.2483559437
            ],
            [
              352880.01512792479,
              5013212.5221446482
            ],
            [
              352956.21558720322,
              5013093.7236366998
            ],
            [
              352973.59523021121,
              5013036.9691336425
            ],
            [
              353143.9206419668,
              5012839.033913726
            ],
            [
              353247.51411870512,
              5012689.9228205951
            ],
            [
              353387.97589124582,
              5012491.0987461405
            ],
            [
              353474.66178587696,
              5012357.8097459944
            ],
            [
              353584.70560520777,
              5012185.0599720683
            ],
            [
              353996.75907214894,
              5012047.3892812971
            ],
            [
              354046.2838387526,
              5012032.8197277291
            ],
            [
              354184.69758534193,
              5011543.3015723396
            ],
            [
              354559.46915967774,
              5011077.6701731244
            ],
            [
              354821.46713890129,
              5010630.7247636095
            ],
            [
              354920.9184092569,
              5010537.1645937674
            ],
            [
              354959.84950524342,
              5010496.2404641677
            ],
            [
              355118.43357020832,
              5010345.9674482811
            ],
            [
              355336.75247347064,
              5010139.090384216
            ],
            [
              355716.89103204157,
              5009834.9043000126
            ],
            [
              355859.8059855196,
              5009722.1911356701
            ],
            [
              355981.47098537505,
              5009642.6725065382
            ],
            [
              356191.62338133651,
              5009506.936533602
            ],
            [
              356471.19558016019,
              5009572.0789475143
            ],
            [
              356624.71312842547,
              5009160.427901241
            ],
            [
              356759.61815298203,
              5009006.5943320701
            ],
            [
              356835.48361562268,
              5008919.6416987181
            ],
            [
              356853.08682145941,
              5008899.9648540383
            ],
            [
              356861.94379558443,
              5008891.1079584453
            ],
            [
              356977.1418299012,
              5008783.1837039553
            ],
            [
              357243.33272277412,
              5008450.2935382985
            ],
            [
              357307.05527902493,
              5008369.7141680447
            ],
            [
              357322.47522656567,
              5008276.1832879875
            ],
            [
              357406.3244478344,
              5008178.016239211
            ],
            [
              357524.24257354432,
              5008061.4793739812
            ],
            [
              357635.51600650157,
              5007952.434009837
            ],
            [
              357674.8330143718,
              5007914.8953104448
            ],
            [
              357703.2377151011,
              5007575.7376180654
            ],
            [
              357730.67729305924,
              5007568.5653185816
            ],
            [
              357770.80623830605,
              5007561.3851702772
            ],
            [
              357806.53558876697,
              5007555.1054208381
            ],
            [
              357835.86316942604,
              5007550.0138448123
            ],
            [
              357860.05154374376,
              5007545.7764217975
            ],
            [
              357906.28368671372,
              5007537.4472109592
            ],
            [
              357934.6626626419,
              5007532.1704233037
            ],
            [
              357959.07115665299,
              5007528.0304769482
            ],
            [
              357984.08117890405,
              5007523.4054263933
            ],
            [
              358011.64941949589,
              5007518.8650302496
            ],
            [
              358031.41237020522,
              5007515.3610610915
            ],
            [
              358050.82479891274,
              5007512.3036947791
            ],
            [
              358087.61155893299,
              5007505.2482732469
            ],
            [
              358142.71987308207,
              5007495.466925594
            ],
            [
              358169.30776682921,
              5007489.4539639857
            ],
            [
              358175.57890521683,
              5007488.6383095328
            ],
            [
              358199.28009401629,
              5007483.9924062975
            ],
            [
              358217.01385672967,
              5007481.5918067377
            ],
            [
              358243.89825540513,
              5007476.8832818326
            ],
            [
              358265.70316617488,
              5007470.2249672059
            ],
            [
              358272.21032410307,
              5007450.6115870047
            ],
            [
              358279.69153589936,
              5007434.0115323346
            ],
            [
              358297.5777370233,
              5007427.0083708707
            ],
            [
              358310.62965367042,
              5007423.705579279
            ],
            [
              358328.90155603737,
              5007417.4617373636
            ],
            [
              358347.98232421721,
              5007410.4233426619
            ],
            [
              358389.10006780666,
              5007397.8842547396
            ],
            [
              358416.29878417344,
              5007387.4851185698
            ],
            [
              358452.66784366447,
              5007373.339811963
            ],
            [
              358472.96079558198,
              5007364.7536855536
            ],
            [
              358517.870145322,
              5007404.0595709896
            ],
            [
              358520.97738998261,
              5007407.204759052
            ],
            [
              358521.16709856672,
              5007407.5755150542
            ],
            [
              358539.72160446784,
              5007418.304704275
            ],
            [
              358540.46256234398,
              5007418.654550286
            ],
            [
              358565.78787309868,
              5007425.8932585334
            ],
            [
              358598.5526065898,
              5007438.2288999585
            ],
            [
              358614.66370283038,
              5007444.3880226342
            ],
            [
              358615.52288150199,
              5007444.7076467853
            ],
            [
              358643.85819404363,
              5007448.0336098475
            ],
            [
              358653.94488914247,
              5007453.3468216006
            ],
            [
              358657.15256068221,
              5007457.2128870431
            ],
            [
              358658.4932581319,
              5007458.217605399
            ],
            [
              358674.42432800255,
              5007463.6914623799
            ],
            [
              358678.46768100286,
              5007465.8519636597
            ],
            [
              358709.93436521635,
              5007480.1630876921
            ],
            [
              358720.94617222238,
              5007485.0462878346
            ],
            [
              358729.91007416922,
              5007496.233184061
            ],
            [
              358740.93576561933,
              5007496.4911336675
            ],
            [
              358764.9091406131,
              5007493.2127609858
            ],
            [
              358783.09266818786,
              5007494.6213035854
            ],
            [
              358803.74495672056,
              5007494.0661046514
            ],
            [
              358803.77209345868,
              5007494.0264155567
            ],
            [
              358817.93621737557,
              5007472.0308967698
            ],
            [
              358861.53870438272,
              5007443.5384894609
            ],
            [
              358913.13103295845,
              5007438.1982817119
            ],
            [
              358930.27766584739,
              5007431.1318373308
            ],
            [
              358943.45263945375,
              5007422.6131959939
            ],
            [
              358950.8080326487,
              5007418.7725701816
            ],
            [
              358963.90962870925,
              5007389.7231416227
            ],
            [
              358992.48274623911,
              5007357.3927110098
            ],
            [
              359027.78867383377,
              5007347.4114374099
            ],
            [
              359041.35444464703,
              5007340.5451074615
            ],
            [
              359054.49656531197,
              5007316.7671686281
            ],
            [
              359079.26845740795,
              5007303.3122636881
            ],
            [
              359093.62922875187,
              5007287.2007676372
            ],
            [
              359121.56925081485,
              5007278.5268982658
            ],
            [
              359133.99492454104,
              5007270.0143696461
            ],
            [
              359145.07821786799,
              5007239.9709975841
            ],
            [
              359157.16096712643,
              5007239.5234864494
            ],
            [
              359165.2725332987,
              5007241.4196459278
            ],
            [
              359180.22959417285,
              5007236.1302877087
            ],
            [
              359196.1510937523,
              5007221.754845975
            ],
            [
              359202.25809167651,
              5007202.8511326145
            ],
            [
              359203.67718223965,
              5007177.6616433105
            ],
            [
              359200.83344554802,
              5007170.5057733683
            ],
            [
              359202.97823904472,
              5007158.5332749737
            ],
            [
              359216.30602895917,
              5007130.493434011
            ],
            [
              359234.49426520657,
              5007110.0445503378
            ],
            [
              359253.83110723837,
              5007091.7129337182
            ],
            [
              359261.62881009543,
              5007078.4770967904
            ],
            [
              359259.46968073811,
              5007065.4795097401
            ],
            [
              359260.9742016953,
              5007029.1368453139
            ],
            [
              359275.26064396079,
              5006993.4238720499
            ],
            [
              359298.77508226823,
              5006976.4276112569
            ],
            [
              359326.39616715413,
              5006962.7618369656
            ],
            [
              359347.08252135728,
              5006942.1423967434
            ],
            [
              359349.7032969385,
              5006934.7865790678
            ],
            [
              359361.1291936104,
              5006913.7092615245
            ],
            [
              359362.5720552032,
              5006905.6245159078
            ],
            [
              359362.95279323298,
              5006892.2388851671
            ],
            [
              359368.67036229657,
              5006881.2022881908
            ],
            [
              359374.97855267144,
              5006865.5174215846
            ],
            [
              359381.41502878076,
              5006850.7651263848
            ],
            [
              359410.10107573902,
              5006863.9425398586
            ],
            [
              359410.12950899417,
              5006863.5095404806
            ],
            [
              359402.70380247623,
              5006847.9591728756
            ],
            [
              359400.04709442303,
              5006835.150287888
            ],
            [
              359400.14024993678,
              5006813.7139761979
            ],
            [
              359398.22248486121,
              5006793.3317538621
            ],
            [
              359395.63313267363,
              5006775.9147219965
            ],
            [
              359381.1961053859,
              5006746.6301987786
            ],
            [
              359389.01358526893,
              5006727.9002938354
            ],
            [
              359399.03023626684,
              5006721.6386176376
            ],
            [
              359410.84620083385,
              5006716.9322216865
            ],
            [
              359422.05950751866,
              5006712.9634375311
            ],
            [
              359428.93138118187,
              5006710.0404437184
            ],
            [
              359447.60380009498,
              5006698.9862236381
            ],
            [
              359459.27666530019,
              5006685.4136115965
            ],
            [
              359460.81204964576,
              5006676.4393699849
            ],
            [
              359480.53513405088,
              5006655.1575173987
            ],
            [
              359496.01437702408,
              5006643.304868944
            ],
            [
              359513.51177421241,
              5006637.0019832803
            ],
            [
              359535.60227372142,
              5006626.5071843043
            ],
            [
              359549.452628005,
              5006624.2146773832
            ],
            [
              359555.608517694,
              5006620.1943498636
            ],
            [
              359568.29678199039,
              5006608.4079640675
            ],
            [
              359569.54767567961,
              5006606.1068532746
            ],
            [
              359571.82053145621,
              5006585.3784255795
            ],
            [
              359573.98109431303,
              5006575.1147422493
            ],
            [
              359583.26813955355,
              5006566.649518053
            ],
            [
              359595.40242240799,
              5006563.1686416408
            ],
            [
              359607.27040980232,
              5006563.7103687618
            ],
            [
              359616.00512558763,
              5006558.3886218071
            ],
            [
              359624.91520509275,
              5006555.2649461757
            ],
            [
              359627.20077458333,
              5006545.7824923079
            ],
            [
              359629.73729194567,
              5006531.2891727872
            ],
            [
              359631.40413316857,
              5006515.7283641109
            ],
            [
              359641.74982410431,
              5006500.7338579232
            ],
            [
              359657.13699833333,
              5006479.2780943485
            ],
            [
              359657.93662733835,
              5006476.7228917358
            ],
            [
              359659.81843417324,
              5006462.327968929
            ],
            [
              359686.53554385866,
              5006449.838534927
            ],
            [
              359699.71784379293,
              5006436.90961417
            ],
            [
              359712.94458736037,
              5006419.6229242487
            ],
            [
              359719.80938234366,
              5006416.8628185987
            ],
            [
              359724.70609177771,
              5006408.8901153952
            ],
            [
              359748.40756646002,
              5006394.2094627703
            ],
            [
              359768.85141892685,
              5006402.8762644008
            ],
            [
              359780.73395551014,
              5006397.8096485035
            ],
            [
              359782.74827774445,
              5006392.7109485827
            ],
            [
              359799.55576995393,
              5006381.0162379052
            ],
            [
              359814.693832569,
              5006374.8961755103
            ],
            [
              359825.98814960284,
              5006373.9795848709
            ],
            [
              359827.4213480975,
              5006372.6775215399
            ],
            [
              359829.35077466309,
              5006366.112606274
            ],
            [
              359834.2580950135,
              5006354.2121820562
            ],
            [
              359846.9644175304,
              5006343.6281409897
            ],
            [
              359867.44633595855,
              5006340.6695828401
            ],
            [
              359893.62657195173,
              5006322.8956354856
            ],
            [
              359911.06729170179,
              5006341.2077481793
            ],
            [
              359915.10541483393,
              5006325.160840123
            ],
            [
              359935.61132642435,
              5006329.0482409056
            ],
            [
              359955.86226565723,
              5006339.5659969011
            ],
            [
              359964.39798848616,
              5006343.7261383943
            ],
            [
              359965.29547753389,
              5006343.1942446828
            ],
            [
              359967.24877591699,
              5006334.7658487409
            ],
            [
              359978.71468478738,
              5006322.5236188136
            ],
            [
              360004.08141759579,
              5006307.6135572251
            ],
            [
              360013.73447622667,
              5006305.879309522
            ],
            [
              360014.5776142095,
              5006296.9900225205
            ],
            [
              360012.09677829937,
              5006291.013619205
            ],
            [
              360012.68799521698,
              5006277.7363142241
            ],
            [
              360021.45821242064,
              5006269.1898050504
            ],
            [
              360053.19519580953,
              5006263.443253574
            ],
            [
              360080.14692954725,
              5006262.1503592888
            ],
            [
              360092.37822931231,
              5006262.6092951447
            ],
            [
              360096.83167487168,
              5006261.9578556223
            ],
            [
              360098.21925409645,
              5006260.5718339281
            ],
            [
              360098.84575982619,
              5006247.6770827938
            ],
            [
              360111.1934175975,
              5006231.8119129613
            ],
            [
              360125.95419412566,
              5006204.4582669763
            ],
            [
              360134.43766145798,
              5006199.7150642006
            ],
            [
              360153.85382551962,
              5006195.0537918322
            ],
            [
              360160.66428494448,
              5006195.5104549555
            ],
            [
              360172.79076035944,
              5006183.6453665169
            ],
            [
              360187.28030595084,
              5006181.1054589348
            ],
            [
              360190.24071838381,
              5006180.0637967307
            ],
            [
              360191.78610562527,
              5006178.4736801349
            ],
            [
              360194.03346621263,
              5006173.6379923765
            ],
            [
              360194.10564696899,
              5006165.5431389175
            ],
            [
              360206.54186695261,
              5006155.5936028119
            ],
            [
              360225.31353071315,
              5006144.9645947004
            ],
            [
              360238.48321217316,
              5006133.8222666783
            ],
            [
              360249.2150728766,
              5006120.7175972667
            ],
            [
              360265.35819880001,
              5006117.2909054896
            ],
            [
              360267.59829436726,
              5006114.1462918343
            ],
            [
              360276.64808991586,
              5006107.1748704379
            ],
            [
              360296.41642130393,
              5006104.9614502881
            ],
            [
              360317.58358954726,
              5006105.4575621923
            ],
            [
              360328.36931027664,
              5006102.3554368932
            ],
            [
              360335.7886985045,
              5006101.1451867577
            ],
            [
              360350.44594286603,
              5006094.0720249042
            ],
            [
              360362.02948557795,
              5006098.8364531659
            ],
            [
              360367.93899815786,
              5006099.0951602226
            ],
            [
              360370.00103212002,
              5006098.4522204325
            ],
            [
              360376.83861896064,
              5006094.7742623119
            ],
            [
              360379.37336233171,
              5006093.1378654279
            ],
            [
              360383.63972318493,
              5006089.3482002569
            ],
            [
              360386.61144169938,
              5006084.5745115383
            ],
            [
              360388.8025934188,
              5006080.5759725804
            ],
            [
              360392.96466336015,
              5006075.2540596034
            ],
            [
              360407.19067363447,
              5006062.8946309965
            ],
            [
              360421.46203032933,
              5006075.0748943007
            ],
            [
              360429.96284184285,
              5006075.4940102883
            ],
            [
              360434.13570624002,
              5006073.1360191954
            ],
            [
              360442.2537310503,
              5006069.6012955485
            ],
            [
              360446.4721376028,
              5006069.0259655258
            ],
            [
              360450.86870048236,
              5006067.1708986666
            ],
            [
              360458.45010321256,
              5006063.6336743077
            ],
            [
              360460.88738598855,
              5006061.9899373446
            ],
            [
              360467.74207955372,
              5006055.0630779434
            ],
            [
              360471.41983052361,
              5006051.9809703641
            ],
            [
              360471.56064757524,
              5006051.4102343572
            ],
            [
              360484.83585268067,
              5006037.9295864636
            ],
            [
              360486.99250653869,
              5006034.8875974491
            ],
            [
              360494.1741650817,
              5006024.1076743687
            ],
            [
              360511.74157288735,
              5006025.4384036418
            ],
            [
              360525.80788024177,
              5006012.0851732995
            ],
            [
              360539.09397304378,
              5006008.8357599778
            ],
            [
              360562.58110244136,
              5006014.2403812688
            ],
            [
              360580.28217370558,
              5006007.3530653697
            ],
            [
              360582.10849615157,
              5006006.5535059553
            ],
            [
              360595.89626151905,
              5005992.5291916067
            ],
            [
              360617.04228105961,
              5005983.543924802
            ],
            [
              360635.44584155158,
              5005968.8840353992
            ],
            [
              360653.89485237864,
              5005954.7716543609
            ],
            [
              360679.63676611794,
              5005935.9078858448
            ],
            [
              360694.3465431325,
              5005927.4542988241
            ],
            [
              360707.84352570697,
              5005922.2580190562
            ],
            [
              360719.08198695531,
              5005917.0780212134
            ],
            [
              360726.47332244768,
              5005913.6141115278
            ],
            [
              360747.0463967828,
              5005895.6467367578
            ],
            [
              360757.24704734009,
              5005883.0079258299
            ],
            [
              360771.1721148963,
              5005872.7350233989
            ],
            [
              360801.07935279346,
              5005857.9099860424
            ],
            [
              360834.41024255235,
              5005835.5544179622
            ],
            [
              360837.82904079912,
              5005840.0731021836
            ],
            [
              360849.62410726544,
              5005833.5419857316
            ],
            [
              360877.58866122115,
              5005821.108127648
            ],
            [
              360893.85081533511,
              5005824.7596827475
            ],
            [
              360895.70306314569,
              5005823.1571691427
            ],
            [
              360906.05051822693,
              5005815.1839096844
            ],
            [
              360913.25401524937,
              5005809.3715344882
            ],
            [
              360921.60414788092,
              5005801.6190721495
            ],
            [
              360929.55060129002,
              5005798.9313490856
            ],
            [
              360933.19871609157,
              5005782.1708096564
            ],
            [
              360940.11281230493,
              5005779.3583755679
            ],
            [
              360939.8925119887,
              5005779.0094538294
            ],
            [
              360923.42312133353,
              5005751.0000183014
            ],
            [
              360936.65621023794,
              5005739.617010667
            ],
            [
              360939.47888325405,
              5005737.1734919911
            ],
            [
              360929.3313405682,
              5005718.123708887
            ],
            [
              360923.21117534471,
              5005707.109377888
            ],
            [
              360914.94449382223,
              5005693.2990490207
            ],
            [
              360908.35983889137,
              5005683.6108830925
            ],
            [
              360899.74291725265,
              5005669.1396287484
            ],
            [
              360894.43009898864,
              5005661.5136588793
            ],
            [
              360888.45239508688,
              5005651.8360298658
            ],
            [
              360643.56128319266,
              5005271.0400842428
            ],
            [
              360659.20144281193,
              5005262.7605119692
            ],
            [
              361115.83016812563,
              5004882.3859287109
            ],
            [
              361314.89735424059,
              5004446.9301542183
            ],
            [
              361373.9710345334,
              5004310.4830632405
            ],
            [
              361463.23467063048,
              5004141.5082033239
            ],
            [
              361752.89873887139,
              5003600.4556066701
            ],
            [
              361884.46262701048,
              5003396.4404194038
            ],
            [
              362087.48250917171,
              5003087.4617222846
            ],
            [
              362118.84267895803,
              5003055.1124630012
            ],
            [
              362159.43243364018,
              5003018.8283985145
            ],
            [
              362253.51673225744,
              5002938.3282942483
            ],
            [
              362361.86619391158,
              5002848.3908959404
            ],
            [
              362764.64654981636,
              5002390.0719187967
            ],
            [
              363241.07189415157,
              5001855.6638254151
            ],
            [
              363224.64554729435,
              5001847.260359671
            ],
            [
              363102.05144536583,
              5001732.0955893397
            ],
            [
              362951.69538808044,
              5001637.9717500843
            ],
            [
              362807.82077123167,
              5001543.8568962449
            ],
            [
              362761.9878137593,
              5001467.250032125
            ],
            [
              362717.95298222621,
              5001398.1133231483
            ],
            [
              362639.24496393243,
              5001337.0469210781
            ],
            [
              362581.88979533006,
              5001334.5308286371
            ],
            [
              362538.85102793423,
              5001286.9922184171
            ],
            [
              362494.9009723104,
              5001203.0288166925
            ],
            [
              362460.12707082654,
              5001128.3045535227
            ],
            [
              362452.8940090363,
              5001107.8783042198
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 198,
        "Layer" : "B02210A-SUME_01",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              361336.69194211665,
              5003783.9403444128
            ],
            [
              361424.4177105063,
              5003741.0342061389
            ],
            [
              361438.07099680271,
              5003681.8155091936
            ],
            [
              361568.01614847587,
              5003529.03953043
            ],
            [
              361674.30869827414,
              5003373.7551260302
            ],
            [
              361761.59621031064,
              5003307.2713352283
            ],
            [
              361912.75062246964,
              5003189.7412333488
            ],
            [
              362003.447583234,
              5003117.5531326905
            ],
            [
              362012.04075765482,
              5003084.6966334516
            ],
            [
              362049.80563851941,
              5003054.4328141976
            ],
            [
              361992.45847751462,
              5003036.5041033989
            ],
            [
              361944.67087472387,
              5003032.3558477638
            ],
            [
              361945.57518116588,
              5002980.1119094715
            ],
            [
              361915.30298719252,
              5003032.2279702732
            ],
            [
              361821.66988805996,
              5003023.8079254534
            ],
            [
              361777.92360281642,
              5003026.9656059332
            ],
            [
              361674.69877612352,
              5003058.5867743185
            ],
            [
              361646.4756103055,
              5003091.3069048775
            ],
            [
              361553.10880348011,
              5003177.7675207341
            ],
            [
              361494.93557532836,
              5003241.5454568556
            ],
            [
              361498.7356936087,
              5003263.5034446958
            ],
            [
              361492.20604020398,
              5003277.1808175845
            ],
            [
              361475.12638465752,
              5003270.7202981589
            ],
            [
              361427.09388154146,
              5003332.615230659
            ],
            [
              361374.25863732316,
              5003409.8501057634
            ],
            [
              361303.84013492946,
              5003453.5216402421
            ],
            [
              361240.32893349236,
              5003503.8427764513
            ],
            [
              361189.97147825168,
              5003531.890893246
            ],
            [
              361173.80470837827,
              5003574.5541010583
            ],
            [
              361152.07971177174,
              5003591.9029567558
            ],
            [
              361150.82523404184,
              5003615.6493840823
            ],
            [
              361132.201782647,
              5003630.6181138474
            ],
            [
              361083.50810404244,
              5003656.9407754885
            ],
            [
              361041.75337734562,
              5003691.6070843656
            ],
            [
              360998.74418012885,
              5003750.0198399555
            ],
            [
              360953.88213573332,
              5003799.9944320321
            ],
            [
              360934.13578100369,
              5003832.5571277188
            ],
            [
              360889.93159270519,
              5003857.7061187103
            ],
            [
              360878.44178690953,
              5003878.2537873238
            ],
            [
              360847.39455243055,
              5003941.5277486416
            ],
            [
              360817.35003599577,
              5003967.5039095422
            ],
            [
              360773.86855086364,
              5004000.5078212479
            ],
            [
              360747.30933614739,
              5004031.5027121454
            ],
            [
              360724.51866178343,
              5004082.7616646681
            ],
            [
              360692.11748546461,
              5004073.1968540139
            ],
            [
              360664.92852139863,
              5004070.3132207859
            ],
            [
              360627.37825233227,
              5004057.4551734319
            ],
            [
              360606.78189561475,
              5004044.2818920547
            ],
            [
              360560.40845403558,
              5004012.9482036214
            ],
            [
              360540.03235453396,
              5004011.632485481
            ],
            [
              360519.71937811415,
              5004013.7045419458
            ],
            [
              360509.79889278434,
              5004027.4450841835
            ],
            [
              360515.73516441236,
              5004073.0864880271
            ],
            [
              360513.1631473974,
              5004117.191659254
            ],
            [
              360520.32253741421,
              5004137.3926966842
            ],
            [
              360531.12452377548,
              5004171.0820730841
            ],
            [
              360532.13214048994,
              5004225.2877186462
            ],
            [
              360517.91290802584,
              5004281.4709764821
            ],
            [
              360492.86003810988,
              5004302.2708066059
            ],
            [
              360470.97759272211,
              5004311.1501252949
            ],
            [
              360435.59504431114,
              5004323.6694355439
            ],
            [
              360443.06933697889,
              5004360.8097954663
            ],
            [
              360456.78985998972,
              5004369.027285683
            ],
            [
              360497.44745266478,
              5004366.5769300805
            ],
            [
              360497.26909359259,
              5004369.6286070654
            ],
            [
              360624.04492545157,
              5004290.5406849068
            ],
            [
              360703.32275782112,
              5004490.0564724291
            ],
            [
              360738.66505893477,
              5004486.4511341155
            ],
            [
              360758.82026981463,
              5004459.5421904158
            ],
            [
              360773.29511752835,
              5004444.5318513969
            ],
            [
              360784.6012102011,
              5004417.7874128073
            ],
            [
              360808.03490370425,
              5004408.5070400983
            ],
            [
              360825.34993408102,
              5004387.5474058324
            ],
            [
              360827.4230992634,
              5004340.3367618509
            ],
            [
              360876.74731360137,
              5004295.1961034173
            ],
            [
              360968.90212008083,
              5004331.8102823151
            ],
            [
              360982.71952757327,
              5004281.4330946226
            ],
            [
              360955.51429653441,
              5004246.5598122086
            ],
            [
              360995.82473158365,
              5004192.7419899786
            ],
            [
              360971.67883463396,
              5004163.708246721
            ],
            [
              361032.33609700337,
              5004078.5261926027
            ],
            [
              361104.2260541023,
              5004136.1549335318
            ],
            [
              361138.3630578033,
              5004067.7105732914
            ],
            [
              361084.99340812181,
              5004053.9613846885
            ],
            [
              361161.67861422623,
              5003893.3304403499
            ],
            [
              361254.75609710417,
              5003820.8423018716
            ],
            [
              361299.27645317174,
              5003834.7560074609
            ],
            [
              361336.69194211665,
              5003783.9403444128
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 199,
        "Layer" : "GRANICA_NA",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352549.02770795225,
              5011722.3168380847
            ],
            [
              352555.14248570934,
              5011727.3615195248
            ],
            [
              352545.53248041455,
              5011731.7522847252
            ],
            [
              352528.11941704142,
              5011746.6933715679
            ],
            [
              352507.76001580356,
              5011759.402327856
            ],
            [
              352520.48548929865,
              5011773.1873795073
            ],
            [
              352493.34116777981,
              5011792.3728648899
            ],
            [
              352486.26250841451,
              5011794.7557010008
            ],
            [
              352477.98629799153,
              5011805.9874718627
            ],
            [
              352470.23133889731,
              5011816.5662850626
            ],
            [
              352519.07450807054,
              5011862.5824212367
            ],
            [
              352549.61975347967,
              5011824.6317002317
            ],
            [
              352571.86502808082,
              5011841.6955985809
            ],
            [
              352608.30154259613,
              5011869.8779043658
            ],
            [
              352627.34974817425,
              5011865.5730272308
            ],
            [
              352652.36580605304,
              5011856.8103194945
            ],
            [
              352687.43207061518,
              5011844.0360923652
            ],
            [
              352699.87954570464,
              5011842.8320946852
            ],
            [
              352716.98688009713,
              5011842.4186604246
            ],
            [
              352731.16726293898,
              5011840.1476980997
            ],
            [
              352749.37897379772,
              5011829.0740354815
            ],
            [
              352747.43254737527,
              5011825.3154963348
            ],
            [
              352760.72783910629,
              5011818.4302934306
            ],
            [
              352758.46849652898,
              5011813.697934255
            ],
            [
              352766.24726115569,
              5011809.3991597388
            ],
            [
              352765.32865040953,
              5011804.6624819646
            ],
            [
              352773.98983664269,
              5011801.410052388
            ],
            [
              352777.06742014841,
              5011798.9243021393
            ],
            [
              352785.71716078493,
              5011807.8754150067
            ],
            [
              352791.47884620668,
              5011800.2797822477
            ],
            [
              352799.24400776456,
              5011788.7410181854
            ],
            [
              352792.81963947014,
              5011779.5577393835
            ],
            [
              352802.57749721711,
              5011773.4928798415
            ],
            [
              352784.14857494499,
              5011753.3441339564
            ],
            [
              352779.35607828241,
              5011760.0253169714
            ],
            [
              352778.84482158045,
              5011758.4432087624
            ],
            [
              352773.37642014318,
              5011752.1877117064
            ],
            [
              352763.43889937043,
              5011760.1748719774
            ],
            [
              352762.82016600051,
              5011759.3195739985
            ],
            [
              352756.22849852807,
              5011761.4153753594
            ],
            [
              352750.76433948585,
              5011763.9407648714
            ],
            [
              352749.63774523488,
              5011763.1355754966
            ],
            [
              352736.5401833817,
              5011755.8867871221
            ],
            [
              352729.32938681019,
              5011744.5981667507
            ],
            [
              352735.79563885374,
              5011740.3056346495
            ],
            [
              352732.88082110131,
              5011735.8626029538
            ],
            [
              352734.14513265778,
              5011734.8638443928
            ],
            [
              352747.90601806546,
              5011721.839825307
            ],
            [
              352737.37218766805,
              5011707.7200544151
            ],
            [
              352730.42674941599,
              5011704.6098074475
            ],
            [
              352722.18990193418,
              5011689.4222362861
            ],
            [
              352726.69020682212,
              5011686.4407907873
            ],
            [
              352716.60116104275,
              5011667.3022635225
            ],
            [
              352712.27476447326,
              5011663.4613665342
            ],
            [
              352716.99886472145,
              5011658.7176257214
            ],
            [
              352720.242756981,
              5011652.6871642536
            ],
            [
              352727.18209910736,
              5011664.1173421321
            ],
            [
              352743.36069421098,
              5011634.1626185458
            ],
            [
              352755.41486181063,
              5011625.2969864998
            ],
            [
              352762.30240441224,
              5011616.0460058013
            ],
            [
              352780.49312623515,
              5011592.8376308437
            ],
            [
              352810.846486361,
              5011555.6965497676
            ],
            [
              352828.32729808631,
              5011537.7929836493
            ],
            [
              352844.83533475269,
              5011527.3304243358
            ],
            [
              352853.23198050511,
              5011526.295298689
            ],
            [
              352860.42605147889,
              5011519.224805709
            ],
            [
              352845.68989217194,
              5011506.0080380021
            ],
            [
              352819.51408600464,
              5011513.7164468309
            ],
            [
              352802.20494435559,
              5011516.8254704364
            ],
            [
              352802.69589112891,
              5011515.0859586531
            ],
            [
              352800.58718370274,
              5011505.2094005374
            ],
            [
              352790.7390098474,
              5011499.3748852806
            ],
            [
              352781.61554441025,
              5011504.6760890875
            ],
            [
              352770.32853941427,
              5011496.575253427
            ],
            [
              352769.87967070146,
              5011499.1671686042
            ],
            [
              352768.05120538262,
              5011497.8682208741
            ],
            [
              352764.9332862866,
              5011509.7078628717
            ],
            [
              352764.60058216046,
              5011509.5756337317
            ],
            [
              352753.10607984423,
              5011517.726133354
            ],
            [
              352743.13180110947,
              5011533.1465954147
            ],
            [
              352740.81488213025,
              5011535.2956082709
            ],
            [
              352734.50713900448,
              5011533.1412334526
            ],
            [
              352721.96236619138,
              5011530.2775760591
            ],
            [
              352700.42286770209,
              5011522.6583110606
            ],
            [
              352694.86484238569,
              5011531.8445318798
            ],
            [
              352688.54505858477,
              5011529.9328695415
            ],
            [
              352672.96476599859,
              5011555.3321107039
            ],
            [
              352668.71882202226,
              5011562.9076148812
            ],
            [
              352659.7867998131,
              5011578.1270340905
            ],
            [
              352650.36568923591,
              5011591.2211372945
            ],
            [
              352635.73451566388,
              5011611.0965714641
            ],
            [
              352630.45961588289,
              5011619.4414941035
            ],
            [
              352624.46836459072,
              5011627.3106538458
            ],
            [
              352609.03222517733,
              5011646.7402509004
            ],
            [
              352600.2309553065,
              5011654.9427192798
            ],
            [
              352591.37070555473,
              5011661.7145262677
            ],
            [
              352587.80870815885,
              5011673.5179255912
            ],
            [
              352587.57413570181,
              5011674.1587782903
            ],
            [
              352577.03064797848,
              5011681.9490377214
            ],
            [
              352583.53663141432,
              5011688.5537447054
            ],
            [
              352581.61475147208,
              5011690.2497656047
            ],
            [
              352575.88487032015,
              5011684.91315642
            ],
            [
              352566.60565761535,
              5011705.5836527413
            ],
            [
              352549.02770795225,
              5011722.3168380847
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 200,
        "Layer" : "GRANICA_NA",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353576.58551033708,
              5011318.0390239963
            ],
            [
              353569.18569021171,
              5011331.9922663942
            ],
            [
              353561.40430872788,
              5011345.3125531841
            ],
            [
              353561.36700935784,
              5011345.3308044989
            ],
            [
              353551.6231657748,
              5011336.9830880696
            ],
            [
              353556.82636329945,
              5011328.4644435896
            ],
            [
              353564.55001523095,
              5011315.6224598121
            ],
            [
              353571.12490040268,
              5011308.1681311913
            ],
            [
              353578.38509898499,
              5011298.7315710951
            ],
            [
              353587.77216715022,
              5011286.4160925532
            ],
            [
              353602.81480988377,
              5011298.6193834497
            ],
            [
              353612.55146928731,
              5011291.600290521
            ],
            [
              353623.20334417635,
              5011284.6551561849
            ],
            [
              353619.53633695986,
              5011270.3655595854
            ],
            [
              353625.1281307062,
              5011265.7727950318
            ],
            [
              353636.81288213033,
              5011260.166144033
            ],
            [
              353652.85882430786,
              5011246.2478737822
            ],
            [
              353636.91136983025,
              5011223.9021364413
            ],
            [
              353639.73024581169,
              5011219.1557708513
            ],
            [
              353645.91070662317,
              5011210.3791956082
            ],
            [
              353638.6911072011,
              5011203.6396131674
            ],
            [
              353655.77503223513,
              5011190.7603294877
            ],
            [
              353659.4683090797,
              5011195.6914949752
            ],
            [
              353669.8241404352,
              5011187.4068559026
            ],
            [
              353680.64605915762,
              5011182.4884442743
            ],
            [
              353693.4943713565,
              5011176.7709035939
            ],
            [
              353664.94494681212,
              5011137.426891407
            ],
            [
              353662.24304079852,
              5011134.5649430957
            ],
            [
              353668.17179314356,
              5011128.2683906108
            ],
            [
              353673.98477673944,
              5011122.3119119136
            ],
            [
              353674.87743214151,
              5011121.4447197812
            ],
            [
              353680.36792785005,
              5011127.0964319278
            ],
            [
              353689.52445387014,
              5011115.9468892701
            ],
            [
              353701.79759388679,
              5011106.0603322098
            ],
            [
              353707.93571978185,
              5011098.7640485885
            ],
            [
              353741.94850458816,
              5011114.4868867313
            ],
            [
              353756.28478487296,
              5011098.0216493001
            ],
            [
              353770.98731099471,
              5011073.3588819774
            ],
            [
              353758.44901497423,
              5011068.7748711901
            ],
            [
              353759.02418973163,
              5011067.7894784901
            ],
            [
              353771.84957554558,
              5011050.7187748514
            ],
            [
              353788.21316425182,
              5011029.0282318881
            ],
            [
              353797.07867712679,
              5011015.3180144001
            ],
            [
              353801.59991013486,
              5011008.6546895318
            ],
            [
              353806.50122909126,
              5011000.209734547
            ],
            [
              353811.78958489042,
              5010991.4572847728
            ],
            [
              353817.02763425675,
              5010983.5816961937
            ],
            [
              353823.26553128514,
              5010976.7860331964
            ],
            [
              353826.20848817326,
              5010973.0749914218
            ],
            [
              353827.34710761678,
              5010974.8461359106
            ],
            [
              353829.72508328786,
              5010975.6778434534
            ],
            [
              353829.04578013305,
              5010977.6211552741
            ],
            [
              353847.97636015544,
              5010986.3922347045
            ],
            [
              353866.35613521852,
              5010964.8002380403
            ],
            [
              353860.62259708118,
              5010958.7594911419
            ],
            [
              353878.41555343196,
              5010942.7332577426
            ],
            [
              353860.27869538328,
              5010924.7303865133
            ],
            [
              353861.63688745222,
              5010921.0170393167
            ],
            [
              353863.53500462155,
              5010923.0052037975
            ],
            [
              353871.94303508411,
              5010917.2128565442
            ],
            [
              353873.39222993486,
              5010918.7539974758
            ],
            [
              353889.92480357335,
              5010912.7685721824
            ],
            [
              353897.18189455214,
              5010911.6316392301
            ],
            [
              353909.13966589869,
              5010918.3970676102
            ],
            [
              353924.98398237274,
              5010920.4505991964
            ],
            [
              353921.2502973979,
              5010892.1562564354
            ],
            [
              353916.93998646876,
              5010893.0330547942
            ],
            [
              353916.53052066691,
              5010892.4894746775
            ],
            [
              353902.57195182296,
              5010869.9721196312
            ],
            [
              353910.27323836257,
              5010863.941650115
            ],
            [
              353904.22204266192,
              5010853.4627767578
            ],
            [
              353917.56622392713,
              5010849.9849404311
            ],
            [
              353924.89624691784,
              5010849.3830921976
            ],
            [
              353949.34932611877,
              5010838.0649169656
            ],
            [
              353932.07138904015,
              5010821.1003789986
            ],
            [
              353939.13971534237,
              5010816.5593426963
            ],
            [
              353952.06855893502,
              5010807.0025601145
            ],
            [
              353940.64051223086,
              5010795.4178222939
            ],
            [
              353939.79080984386,
              5010794.7783371694
            ],
            [
              353956.78214676719,
              5010782.9784472445
            ],
            [
              353961.8755422447,
              5010779.5218502609
            ],
            [
              353968.87166864582,
              5010780.5075632697
            ],
            [
              353976.26056742639,
              5010767.0763948914
            ],
            [
              353981.64739459351,
              5010761.0728016747
            ],
            [
              354000.48831939592,
              5010773.151389109
            ],
            [
              354008.73565212882,
              5010761.9272698723
            ],
            [
              354014.34503383038,
              5010746.8564296328
            ],
            [
              353992.35869348428,
              5010729.3210285222
            ],
            [
              353993.60095250211,
              5010725.8100165436
            ],
            [
              354001.77263193339,
              5010715.5794863384
            ],
            [
              353994.80544430966,
              5010712.0704597142
            ],
            [
              353996.94030455715,
              5010709.1190682808
            ],
            [
              354012.2676983315,
              5010716.070673475
            ],
            [
              354028.85942482291,
              5010696.4707835503
            ],
            [
              354037.59047272307,
              5010702.7122971807
            ],
            [
              354043.0503362625,
              5010694.2867360227
            ],
            [
              354051.83213824901,
              5010684.9514710968
            ],
            [
              354043.88339148345,
              5010680.3205639841
            ],
            [
              354050.96617288259,
              5010671.6856296277
            ],
            [
              354068.53251572454,
              5010686.1742307534
            ],
            [
              354093.23768033978,
              5010682.6673095068
            ],
            [
              354105.51985644671,
              5010667.5180358635
            ],
            [
              354089.87739934155,
              5010652.5406975467
            ],
            [
              354092.2477755468,
              5010650.5751141347
            ],
            [
              354107.68471542932,
              5010635.9064648608
            ],
            [
              354087.59815279214,
              5010630.5774054779
            ],
            [
              354088.76386049256,
              5010629.4602915216
            ],
            [
              354091.48349923646,
              5010608.7357990015
            ],
            [
              354091.19602783845,
              5010599.6226730831
            ],
            [
              354095.98026650277,
              5010602.5281209918
            ],
            [
              354101.30370403623,
              5010593.7539912788
            ],
            [
              354118.68222658098,
              5010569.2405403461
            ],
            [
              354097.27952362847,
              5010573.5430149045
            ],
            [
              354084.7081909519,
              5010563.3548682798
            ],
            [
              354082.23735281773,
              5010569.6643449655
            ],
            [
              354075.40798356402,
              5010563.7285678396
            ],
            [
              354049.8016183874,
              5010546.8605675483
            ],
            [
              354037.29164846451,
              5010560.1650247211
            ],
            [
              354033.80540517735,
              5010565.4600452082
            ],
            [
              354030.57402382593,
              5010563.9097273918
            ],
            [
              354021.17001106596,
              5010584.6955569778
            ],
            [
              354011.01522366441,
              5010579.9037305396
            ],
            [
              354004.66027491668,
              5010586.4439720986
            ],
            [
              353990.65251176961,
              5010572.063869698
            ],
            [
              353982.49173024442,
              5010580.965998359
            ],
            [
              353979.39519708237,
              5010584.3317897851
            ],
            [
              353975.58906723902,
              5010589.7643180825
            ],
            [
              353960.00381792011,
              5010602.4745461056
            ],
            [
              353973.34488593118,
              5010611.1590949045
            ],
            [
              353972.51116374397,
              5010616.401800232
            ],
            [
              353955.60304148478,
              5010607.5218753899
            ],
            [
              353950.77145798627,
              5010622.2717025708
            ],
            [
              353947.47503828385,
              5010628.8519198783
            ],
            [
              353946.35980213078,
              5010630.3767270371
            ],
            [
              353942.78091861733,
              5010635.7797033479
            ],
            [
              353937.98038691602,
              5010643.6391297542
            ],
            [
              353936.6165027683,
              5010651.0673517855
            ],
            [
              353941.59723709588,
              5010655.5944537744
            ],
            [
              353940.15927493834,
              5010658.2904918939
            ],
            [
              353931.49155389733,
              5010668.6451451471
            ],
            [
              353935.419091983,
              5010673.1805343824
            ],
            [
              353929.47421312315,
              5010682.0465212306
            ],
            [
              353921.78567375714,
              5010677.4571331935
            ],
            [
              353910.26929737121,
              5010674.1630542651
            ],
            [
              353906.87828294985,
              5010683.0179160908
            ],
            [
              353903.17997673224,
              5010692.4486499662
            ],
            [
              353889.02572567161,
              5010674.5990823777
            ],
            [
              353874.29763811122,
              5010685.7910657674
            ],
            [
              353877.43492032267,
              5010693.6589814443
            ],
            [
              353860.34286907385,
              5010712.3815380344
            ],
            [
              353864.04664149921,
              5010721.3874137169
            ],
            [
              353857.17723894288,
              5010726.4216914279
            ],
            [
              353846.47332481836,
              5010734.8710684422
            ],
            [
              353840.44978295476,
              5010735.3091727737
            ],
            [
              353829.21751890256,
              5010722.0430275798
            ],
            [
              353804.27912562876,
              5010701.039083764
            ],
            [
              353780.32688430126,
              5010727.1889018817
            ],
            [
              353796.79207277013,
              5010743.3866440393
            ],
            [
              353810.69887706212,
              5010755.5913311802
            ],
            [
              353827.21731313557,
              5010767.7668286385
            ],
            [
              353835.13450042059,
              5010761.02115661
            ],
            [
              353838.97932152462,
              5010764.2540471517
            ],
            [
              353837.29651835089,
              5010765.8209234243
            ],
            [
              353834.51630968181,
              5010773.3315386241
            ],
            [
              353817.80599899322,
              5010789.3133105868
            ],
            [
              353830.23900701094,
              5010796.4744294127
            ],
            [
              353815.65446141578,
              5010817.5285965847
            ],
            [
              353782.52812832064,
              5010843.0604813211
            ],
            [
              353773.5452058331,
              5010854.7724254113
            ],
            [
              353791.90236116824,
              5010868.0220794687
            ],
            [
              353772.79832646268,
              5010889.0469495803
            ],
            [
              353771.61362539569,
              5010894.841184875
            ],
            [
              353755.26880100911,
              5010880.5628139814
            ],
            [
              353749.06776835519,
              5010890.5313084433
            ],
            [
              353745.36081268155,
              5010888.1205977909
            ],
            [
              353727.87704324425,
              5010911.3851905717
            ],
            [
              353743.90222271631,
              5010920.4689610256
            ],
            [
              353700.653305154,
              5010976.0597771667
            ],
            [
              353650.05014075077,
              5010941.4380113902
            ],
            [
              353637.92935142905,
              5010962.1771005522
            ],
            [
              353622.78518879466,
              5010988.5533766756
            ],
            [
              353657.3883095099,
              5011010.858334586
            ],
            [
              353651.73693309625,
              5011018.9902244294
            ],
            [
              353680.31985495333,
              5011041.1928570345
            ],
            [
              353676.79344167921,
              5011045.7236215444
            ],
            [
              353686.03017872159,
              5011053.6278496329
            ],
            [
              353686.31316560367,
              5011054.3499257993
            ],
            [
              353677.22065175237,
              5011064.4680122277
            ],
            [
              353670.54911290127,
              5011071.7406313866
            ],
            [
              353648.43902756437,
              5011091.9914036896
            ],
            [
              353634.8794262989,
              5011103.3093186421
            ],
            [
              353633.56416166847,
              5011104.4158278182
            ],
            [
              353607.24678199872,
              5011128.8389573852
            ],
            [
              353584.55149520002,
              5011157.6208127011
            ],
            [
              353579.4305056163,
              5011160.9767896552
            ],
            [
              353532.85290841927,
              5011195.9095913498
            ],
            [
              353542.68458601146,
              5011204.0648833262
            ],
            [
              353538.24095890153,
              5011209.7596848654
            ],
            [
              353518.74539246288,
              5011223.2161407592
            ],
            [
              353542.76194964704,
              5011241.1671904884
            ],
            [
              353554.88787106978,
              5011227.8179578213
            ],
            [
              353559.38250540668,
              5011231.5329775605
            ],
            [
              353546.04559108318,
              5011245.955127947
            ],
            [
              353539.60852070857,
              5011254.7174345562
            ],
            [
              353545.15971760708,
              5011257.1237414805
            ],
            [
              353535.27441149391,
              5011269.026662508
            ],
            [
              353523.07288124517,
              5011283.9936592355
            ],
            [
              353514.26164558955,
              5011295.8475084249
            ],
            [
              353514.5831510665,
              5011295.2723101284
            ],
            [
              353505.34461179812,
              5011287.734581952
            ],
            [
              353492.86305610946,
              5011278.3590297755
            ],
            [
              353472.40537652798,
              5011303.8781519514
            ],
            [
              353495.5517354997,
              5011320.4841177622
            ],
            [
              353496.21624105069,
              5011321.9498792151
            ],
            [
              353480.99313323875,
              5011344.7984550335
            ],
            [
              353477.66765548964,
              5011353.5782619836
            ],
            [
              353475.5694341801,
              5011357.9750913307
            ],
            [
              353475.11253782228,
              5011371.2272034902
            ],
            [
              353466.37989758229,
              5011377.8521663817
            ],
            [
              353460.41076861101,
              5011389.9224969968
            ],
            [
              353448.93964350066,
              5011411.821732888
            ],
            [
              353451.1680381131,
              5011414.0571511565
            ],
            [
              353442.95769209688,
              5011428.4403178403
            ],
            [
              353429.15241785656,
              5011481.3767439891
            ],
            [
              353450.74148632184,
              5011454.4792973734
            ],
            [
              353463.24935405818,
              5011446.2857265901
            ],
            [
              353470.98825742054,
              5011447.1785871228
            ],
            [
              353479.92144506407,
              5011432.8204203211
            ],
            [
              353493.58070791996,
              5011417.4798433222
            ],
            [
              353494.42558374483,
              5011416.8463202883
            ],
            [
              353501.01049559371,
              5011425.5881303893
            ],
            [
              353499.64161630796,
              5011430.1020391956
            ],
            [
              353523.88815088623,
              5011460.1152018923
            ],
            [
              353529.29821895348,
              5011454.0845596837
            ],
            [
              353535.49517755728,
              5011459.2319960799
            ],
            [
              353545.64770487422,
              5011465.0663980236
            ],
            [
              353553.61843497894,
              5011454.0217631077
            ],
            [
              353555.53665420791,
              5011451.1714545302
            ],
            [
              353565.44729809003,
              5011433.4806201924
            ],
            [
              353559.77273791103,
              5011430.5314247385
            ],
            [
              353570.40726909199,
              5011417.1518245777
            ],
            [
              353577.73358311661,
              5011404.8565641101
            ],
            [
              353584.96614495118,
              5011392.4942807117
            ],
            [
              353594.65517103154,
              5011379.8948437506
            ],
            [
              353590.78140616219,
              5011376.8565378301
            ],
            [
              353607.05022005033,
              5011363.9637705572
            ],
            [
              353619.32725954684,
              5011354.7079970976
            ],
            [
              353614.85420486523,
              5011352.1374305775
            ],
            [
              353617.95814317849,
              5011347.440043645
            ],
            [
              353610.75041549973,
              5011338.6311801514
            ],
            [
              353605.98303947592,
              5011333.8003254412
            ],
            [
              353601.38441565406,
              5011337.5919493446
            ],
            [
              353576.58551033708,
              5011318.0390239963
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 201,
        "Layer" : "GRANICA_NA",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358517.44184558548,
              5006255.9490439016
            ],
            [
              358517.8071427822,
              5006256.8747023456
            ],
            [
              358517.81236999767,
              5006256.8940662118
            ],
            [
              358518.87891828408,
              5006270.3399170404
            ],
            [
              358527.95716202137,
              5006272.2371851904
            ],
            [
              358542.44021095353,
              5006268.9271961562
            ],
            [
              358542.11110364675,
              5006257.6738417763
            ],
            [
              358540.52305268229,
              5006255.1137763672
            ],
            [
              358539.10071565741,
              5006246.5026287902
            ],
            [
              358527.98889289302,
              5006246.3042128561
            ],
            [
              358527.96512874117,
              5006245.0262099532
            ],
            [
              358526.82044061349,
              5006243.8144560708
            ],
            [
              358527.25547614397,
              5006239.5312728453
            ],
            [
              358527.32597046735,
              5006237.621820882
            ],
            [
              358528.82772719074,
              5006229.7900141831
            ],
            [
              358527.86826170719,
              5006217.7844903478
            ],
            [
              358527.92563454929,
              5006217.0969599197
            ],
            [
              358529.64558297402,
              5006217.5268740468
            ],
            [
              358533.39077854145,
              5006218.6430475879
            ],
            [
              358536.17143896566,
              5006220.1666409764
            ],
            [
              358540.37469427398,
              5006223.755160246
            ],
            [
              358540.18420229241,
              5006224.8423314793
            ],
            [
              358545.81691229565,
              5006232.0313966293
            ],
            [
              358550.71069920162,
              5006231.0776249636
            ],
            [
              358548.01302537671,
              5006243.3522337927
            ],
            [
              358566.89688914648,
              5006246.3575947396
            ],
            [
              358568.8590484446,
              5006247.5889247265
            ],
            [
              358570.55407072318,
              5006247.3686394179
            ],
            [
              358572.28034356498,
              5006248.4735460617
            ],
            [
              358573.94802385935,
              5006250.8048836654
            ],
            [
              358582.97728681337,
              5006252.6288877698
            ],
            [
              358599.23280857428,
              5006261.303206699
            ],
            [
              358611.05932593369,
              5006253.190130759
            ],
            [
              358616.8120729152,
              5006242.5159641961
            ],
            [
              358622.32938208431,
              5006235.3509997437
            ],
            [
              358610.32176508079,
              5006230.0654489566
            ],
            [
              358600.59409789176,
              5006225.1018857993
            ],
            [
              358595.76364846132,
              5006222.340051678
            ],
            [
              358594.17457128817,
              5006219.8526811339
            ],
            [
              358589.52480908827,
              5006215.7441254519
            ],
            [
              358586.82084271929,
              5006215.1566770021
            ],
            [
              358584.13087658159,
              5006213.4685984273
            ],
            [
              358576.35380217346,
              5006209.9124575052
            ],
            [
              358574.71550596616,
              5006209.0962176295
            ],
            [
              358575.28595979704,
              5006207.43198229
            ],
            [
              358580.85970987444,
              5006194.7493443424
            ],
            [
              358574.05682580307,
              5006190.7538277619
            ],
            [
              358567.46311815525,
              5006187.7968231374
            ],
            [
              358572.28236766515,
              5006184.2948085712
            ],
            [
              358559.22413853864,
              5006179.6976965135
            ],
            [
              358561.96973747091,
              5006172.0010718368
            ],
            [
              358570.40245070116,
              5006157.5133778779
            ],
            [
              358548.71673419088,
              5006146.3407165725
            ],
            [
              358539.51817301608,
              5006144.0747447107
            ],
            [
              358537.99563964159,
              5006143.8001135997
            ],
            [
              358542.58717164985,
              5006132.6057417281
            ],
            [
              358529.15359009453,
              5006126.4519455554
            ],
            [
              358520.04913080076,
              5006120.2694517039
            ],
            [
              358511.2111753794,
              5006115.6144857192
            ],
            [
              358508.89794508013,
              5006112.2190694427
            ],
            [
              358506.65574601881,
              5006106.7819919754
            ],
            [
              358504.30967231659,
              5006101.991443987
            ],
            [
              358495.65318568965,
              5006092.4293331178
            ],
            [
              358486.54660989129,
              5006089.4233788839
            ],
            [
              358481.6811852805,
              5006086.4033899661
            ],
            [
              358467.03308789612,
              5006094.6420580847
            ],
            [
              358471.22375528072,
              5006102.6441936232
            ],
            [
              358477.76636525657,
              5006111.836681949
            ],
            [
              358473.08300223429,
              5006114.6025970699
            ],
            [
              358475.90545760433,
              5006122.4487155145
            ],
            [
              358478.43325053382,
              5006126.3556168601
            ],
            [
              358481.05994836165,
              5006130.1206593169
            ],
            [
              358482.01475872658,
              5006131.5202934248
            ],
            [
              358489.19516463944,
              5006140.9133806108
            ],
            [
              358494.9567903069,
              5006138.3166158861
            ],
            [
              358496.53715680615,
              5006141.7411130331
            ],
            [
              358497.1164030566,
              5006148.170192102
            ],
            [
              358492.35013442888,
              5006153.341634823
            ],
            [
              358501.16725090338,
              5006158.5922383936
            ],
            [
              358504.78515465843,
              5006159.8891243301
            ],
            [
              358510.89053325745,
              5006160.740628141
            ],
            [
              358511.43245564552,
              5006162.2495800536
            ],
            [
              358512.15433437628,
              5006164.2931774799
            ],
            [
              358508.15030698903,
              5006166.2320588864
            ],
            [
              358507.8711545525,
              5006170.4435626864
            ],
            [
              358507.14427423239,
              5006178.0699960142
            ],
            [
              358506.73339496116,
              5006181.4467519158
            ],
            [
              358506.54845756921,
              5006183.6527852798
            ],
            [
              358506.43567063165,
              5006185.8596472703
            ],
            [
              358505.93006639689,
              5006191.0931237666
            ],
            [
              358505.58117507229,
              5006193.7362932013
            ],
            [
              358502.95195108809,
              5006211.7736989371
            ],
            [
              358503.43675437057,
              5006216.2406120524
            ],
            [
              358503.73702687479,
              5006229.7340600146
            ],
            [
              358503.59663041326,
              5006239.603907479
            ],
            [
              358502.75583028683,
              5006245.8373126658
            ],
            [
              358503.89643896307,
              5006256.6477941731
            ],
            [
              358514.0499821725,
              5006255.4153869124
            ],
            [
              358517.44184558548,
              5006255.9490439016
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 202,
        "Layer" : "B02210A-SUME_01",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360173.80496740894,
              5005310.11448255
            ],
            [
              360171.80985626037,
              5005299.3190569403
            ],
            [
              360163.89495074603,
              5005295.8055106346
            ],
            [
              360127.2668967552,
              5005288.6852554781
            ],
            [
              360124.64105474221,
              5005280.1427873159
            ],
            [
              360129.06085429748,
              5005272.5899433782
            ],
            [
              360135.77895990334,
              5005259.7367873732
            ],
            [
              360134.41442526784,
              5005258.7161755804
            ],
            [
              360144.89999625849,
              5005243.3320362605
            ],
            [
              360155.82126830565,
              5005231.6241320912
            ],
            [
              360159.63086085697,
              5005223.4102957053
            ],
            [
              360161.35578082624,
              5005218.1249981038
            ],
            [
              360171.31315697572,
              5005222.9452502215
            ],
            [
              360185.08956092515,
              5005212.0060301414
            ],
            [
              360185.10093877034,
              5005210.6828561006
            ],
            [
              360173.49317706953,
              5005210.1744056465
            ],
            [
              360162.69092457555,
              5005176.484943809
            ],
            [
              360150.44534517446,
              5005156.3784662578
            ],
            [
              360140.24161105714,
              5005154.8736936785
            ],
            [
              360108.34438643552,
              5005172.4118197672
            ],
            [
              360081.91104595916,
              5005210.182513251
            ],
            [
              360092.46120814333,
              5005230.3206000524
            ],
            [
              360094.78649610194,
              5005264.1675836872
            ],
            [
              360074.5994766014,
              5005273.0155388862
            ],
            [
              360052.55856296356,
              5005271.8145766174
            ],
            [
              360045.48486576747,
              5005284.4501047116
            ],
            [
              360037.19678435382,
              5005294.5653538154
            ],
            [
              360045.13978145755,
              5005301.6118489401
            ],
            [
              360050.40384197602,
              5005306.2817707658
            ],
            [
              360069.30747894273,
              5005323.0518099787
            ],
            [
              360100.97379144368,
              5005317.1504325941
            ],
            [
              360144.31535369466,
              5005356.7855528751
            ],
            [
              360158.63639791828,
              5005346.6018680194
            ],
            [
              360166.63619150268,
              5005334.5747904833
            ],
            [
              360170.79657716333,
              5005329.0185961239
            ],
            [
              360172.92871297902,
              5005323.0771785509
            ],
            [
              360173.80496740894,
              5005310.11448255
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 203,
        "Layer" : "B02210A-SUME_01",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360453.21589706891,
              5005467.7428209092
            ],
            [
              360490.59279849281,
              5005428.9831064716
            ],
            [
              360499.58325310756,
              5005400.267407516
            ],
            [
              360484.7267789123,
              5005369.615930642
            ],
            [
              360460.48190048052,
              5005346.2761814883
            ],
            [
              360421.91129789088,
              5005320.823799558
            ],
            [
              360398.65609173209,
              5005310.7497245781
            ],
            [
              360361.14445485588,
              5005304.543235857
            ],
            [
              360343.50125122041,
              5005304.7212105468
            ],
            [
              360313.25772446365,
              5005315.1580392765
            ],
            [
              360303.3661365966,
              5005324.3468303485
            ],
            [
              360291.43298810144,
              5005340.8526830832
            ],
            [
              360278.57982801466,
              5005361.2538712481
            ],
            [
              360271.80785990058,
              5005372.6358469399
            ],
            [
              360267.75618135609,
              5005380.8907234194
            ],
            [
              360261.76397403353,
              5005390.0346965585
            ],
            [
              360254.26231462607,
              5005402.5559870237
            ],
            [
              360245.54112592567,
              5005414.6204210017
            ],
            [
              360239.62685754168,
              5005423.6603229064
            ],
            [
              360236.81654362794,
              5005434.0682023903
            ],
            [
              360234.7585585069,
              5005448.5894191042
            ],
            [
              360235.58244978444,
              5005460.0651176302
            ],
            [
              360244.40404243796,
              5005495.5261765644
            ],
            [
              360260.24509280262,
              5005527.0899801077
            ],
            [
              360269.46733080427,
              5005538.9134726711
            ],
            [
              360275.04021477647,
              5005541.6885980247
            ],
            [
              360280.67761901289,
              5005541.4639007999
            ],
            [
              360264.87486913701,
              5005550.1541718431
            ],
            [
              360266.82770579384,
              5005545.4397937423
            ],
            [
              360266.15862020245,
              5005541.7338480288
            ],
            [
              360254.00725638558,
              5005520.8485971252
            ],
            [
              360240.8826498611,
              5005496.0038520461
            ],
            [
              360240.82689683948,
              5005493.0051769856
            ],
            [
              360239.62469009898,
              5005484.8532681521
            ],
            [
              360236.44709761284,
              5005479.4377027499
            ],
            [
              360223.80163046962,
              5005461.5097236084
            ],
            [
              360221.59846789291,
              5005464.1004097555
            ],
            [
              360209.15375426278,
              5005440.9686467294
            ],
            [
              360196.07007483329,
              5005423.4384699604
            ],
            [
              360182.04002411646,
              5005399.9676676197
            ],
            [
              360180.82275637047,
              5005395.0407432346
            ],
            [
              360180.78937551472,
              5005394.9900278961
            ],
            [
              360157.1303461105,
              5005414.8280358063
            ],
            [
              360139.77764837391,
              5005426.5757849673
            ],
            [
              360140.32080541732,
              5005433.9628745643
            ],
            [
              360152.17233858426,
              5005464.6521366993
            ],
            [
              360170.86206251103,
              5005494.2739189118
            ],
            [
              360181.6506154433,
              5005507.1845453484
            ],
            [
              360197.50659955887,
              5005514.5390161127
            ],
            [
              360189.60442748392,
              5005521.8373774672
            ],
            [
              360176.62720682076,
              5005523.76282747
            ],
            [
              360155.90197201172,
              5005526.8378622951
            ],
            [
              360156.48595628346,
              5005558.2472058469
            ],
            [
              360158.78337084415,
              5005581.4983605128
            ],
            [
              360252.93884549697,
              5005579.7477463074
            ],
            [
              360286.91185375286,
              5005551.1634595348
            ],
            [
              360453.21589706891,
              5005467.7428209092
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 204,
        "Layer" : "B02209B-POLJOPRIVREDA_03",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360398.65609173209,
              5005310.7497245781
            ],
            [
              360421.91129789088,
              5005320.823799558
            ],
            [
              360435.83923033142,
              5005301.5324551621
            ],
            [
              360459.46493053163,
              5005291.5769661302
            ],
            [
              360504.6899808328,
              5005290.7361438805
            ],
            [
              360533.47437556559,
              5005302.096283461
            ],
            [
              360557.45379677665,
              5005311.1666096896
            ],
            [
              360605.05921070807,
              5005310.2815291537
            ],
            [
              360676.37032481999,
              5005288.5562885301
            ],
            [
              360825.85252420319,
              5005089.100012958
            ],
            [
              360930.82620970794,
              5004949.0322400974
            ],
            [
              361015.99972975202,
              5004815.3359083692
            ],
            [
              361010.38956179115,
              5004817.5006883461
            ],
            [
              360995.5312050184,
              5004811.8804141451
            ],
            [
              360960.572467839,
              5004836.1163174789
            ],
            [
              360919.87843926047,
              5004869.3036002098
            ],
            [
              360893.33063623676,
              5004869.7971431818
            ],
            [
              360886.82842258009,
              5004837.4872659091
            ],
            [
              360883.22117732739,
              5004802.175317673
            ],
            [
              360826.73732944421,
              5004779.6392863179
            ],
            [
              360790.51839469787,
              5004736.0888399407
            ],
            [
              360731.41351980186,
              5004731.2911156872
            ],
            [
              360727.97065307194,
              5004704.820850932
            ],
            [
              360759.32219105569,
              5004645.2728955494
            ],
            [
              360731.6785942851,
              5004586.8217513347
            ],
            [
              360727.523487798,
              5004522.037362624
            ],
            [
              360703.32275782112,
              5004490.0564724291
            ],
            [
              360624.04492545157,
              5004290.5406849068
            ],
            [
              360497.26909359259,
              5004369.6286070654
            ],
            [
              360494.70772670367,
              5004413.4529744787
            ],
            [
              360483.15496249025,
              5004430.6128211878
            ],
            [
              360485.32272428874,
              5004455.9902413152
            ],
            [
              360488.99682203698,
              5004471.1725547621
            ],
            [
              360482.56166715111,
              5004489.9318299256
            ],
            [
              360471.32380170299,
              5004524.0309142452
            ],
            [
              360466.86748003087,
              5004558.0040194513
            ],
            [
              360472.48892357317,
              5004586.706185203
            ],
            [
              360452.55382801295,
              5004609.105461956
            ],
            [
              360412.11666086031,
              5004623.4132437361
            ],
            [
              360368.82397893659,
              5004666.5808754452
            ],
            [
              360374.22500165866,
              5004683.4256491521
            ],
            [
              360418.80878658901,
              5004709.7090255655
            ],
            [
              360444.52293652197,
              5004724.4815217471
            ],
            [
              360441.76190560561,
              5004758.4231163291
            ],
            [
              360432.6603639354,
              5004816.2057459811
            ],
            [
              360424.8762061359,
              5004853.6296751583
            ],
            [
              360394.13906429481,
              5004842.3395178216
            ],
            [
              360383.5887842378,
              5004822.2015256863
            ],
            [
              360374.76556989731,
              5004803.7259001024
            ],
            [
              360325.095653639,
              5004777.5370858693
            ],
            [
              360311.46974085021,
              5004774.4014476538
            ],
            [
              360233.89671014494,
              5004889.3759275367
            ],
            [
              360195.88883230143,
              5004991.4207984963
            ],
            [
              360195.12859183602,
              5004993.4619194577
            ],
            [
              360207.04383070831,
              5005007.9035501443
            ],
            [
              360218.94298764126,
              5005009.376893146
            ],
            [
              360224.24955204729,
              5005021.1398259355
            ],
            [
              360222.68009715946,
              5005027.9470788296
            ],
            [
              360209.18014383974,
              5005031.5870104423
            ],
            [
              360173.41968926403,
              5005023.7792887595
            ],
            [
              360163.56237053795,
              5005040.907738125
            ],
            [
              360165.76151962829,
              5005067.9790710574
            ],
            [
              360174.64788850414,
              5005089.8424789542
            ],
            [
              360180.26938287431,
              5005118.5447464371
            ],
            [
              360197.66406488692,
              5005141.9445316195
            ],
            [
              360198.41989146866,
              5005182.598759599
            ],
            [
              360199.75414675166,
              5005199.803387912
            ],
            [
              360208.28257454868,
              5005204.9259266183
            ],
            [
              360221.8435502869,
              5005206.5415499592
            ],
            [
              360226.72481638315,
              5005207.7208406031
            ],
            [
              360238.44588964398,
              5005201.0449626613
            ],
            [
              360253.63433357939,
              5005197.6995877987
            ],
            [
              360265.54954689491,
              5005187.1686161868
            ],
            [
              360274.37385325349,
              5005175.2756429184
            ],
            [
              360280.00234316231,
              5005160.2565638665
            ],
            [
              360283.07059132343,
              5005140.327599518
            ],
            [
              360281.51679328218,
              5005125.1610805281
            ],
            [
              360366.27790524712,
              5005164.6425012639
            ],
            [
              360349.09802264784,
              5005182.0360902902
            ],
            [
              360333.04795192491,
              5005207.3232664289
            ],
            [
              360322.20784785837,
              5005226.7710298272
            ],
            [
              360307.85291939805,
              5005254.3896741606
            ],
            [
              360300.68224345444,
              5005272.6080202656
            ],
            [
              360364.61397027789,
              5005297.2748850174
            ],
            [
              360399.57065360306,
              5005307.4308940014
            ],
            [
              360398.65609173209,
              5005310.7497245781
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 205,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359124.18584543705,
              5007148.2352372725
            ],
            [
              359163.59032199439,
              5007095.5272840401
            ],
            [
              359174.214664114,
              5007080.2476000832
            ],
            [
              359179.87694562756,
              5007059.4446931183
            ],
            [
              359194.59994184918,
              5007039.9122287873
            ],
            [
              359219.93957379396,
              5007017.1659164289
            ],
            [
              359236.50231805543,
              5007009.2008622009
            ],
            [
              359244.95884292916,
              5007014.0085082343
            ],
            [
              359246.21249289345,
              5007053.5814958448
            ],
            [
              359241.03893397964,
              5007081.6280445876
            ],
            [
              359235.13752411824,
              5007100.9736112235
            ],
            [
              359227.21055515809,
              5007137.7211067835
            ],
            [
              359243.80498312676,
              5007119.0641276985
            ],
            [
              359264.0645690696,
              5007099.8577350415
            ],
            [
              359275.17622652446,
              5007080.9967840761
            ],
            [
              359272.46533807646,
              5007064.6777025284
            ],
            [
              359273.822762069,
              5007031.8882571394
            ],
            [
              359283.82781813765,
              5007006.6610714821
            ],
            [
              359215.01234741835,
              5006811.7057633791
            ],
            [
              359113.89036426943,
              5006753.5618286086
            ],
            [
              358964.81097805343,
              5006806.3545311224
            ],
            [
              358866.66453848261,
              5006908.2205045437
            ],
            [
              358870.38416358846,
              5007108.2329399874
            ],
            [
              358911.50255897286,
              5007167.4927261332
            ],
            [
              359130.57614414481,
              5007220.4417371973
            ],
            [
              359161.24783568463,
              5007201.6379776206
            ],
            [
              359129.08931318467,
              5007162.2980155172
            ],
            [
              359124.18584543705,
              5007148.2352372725
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 206,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351904.49474216666,
              5012034.5113252867
            ],
            [
              351916.14772263088,
              5012045.5009206571
            ],
            [
              351930.14400005835,
              5012058.678027641
            ],
            [
              351958.78032213106,
              5012077.3282924257
            ],
            [
              351966.58493881748,
              5012069.2994135572
            ],
            [
              351970.6772880386,
              5012063.7473557899
            ],
            [
              351976.25502129289,
              5012056.5947819129
            ],
            [
              351984.76902445289,
              5012038.4768543029
            ],
            [
              351987.1367133669,
              5012031.1690314217
            ],
            [
              352003.1243329182,
              5012008.0843425235
            ],
            [
              352018.66366346349,
              5011985.26386052
            ],
            [
              352007.27517639211,
              5011981.6532305647
            ],
            [
              351966.89702679199,
              5011982.2518425826
            ],
            [
              351929.2410655765,
              5011989.3745973799
            ],
            [
              351939.69742796151,
              5012003.128220059
            ],
            [
              351918.66588678624,
              5012019.1177108381
            ],
            [
              351905.86631385249,
              5012032.481094718
            ],
            [
              351904.49474216666,
              5012034.5113252867
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 207,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351896.12110332976,
              5012216.3471950451
            ],
            [
              351891.59126966243,
              5012196.4543260224
            ],
            [
              351900.99878026062,
              5012177.8807226811
            ],
            [
              351907.1412945168,
              5012164.7019636882
            ],
            [
              351895.31242256809,
              5012155.1799962502
            ],
            [
              351873.42984411196,
              5012147.8447558498
            ],
            [
              351867.93323680217,
              5012145.3869352313
            ],
            [
              351858.61438053765,
              5012144.7079606783
            ],
            [
              351853.90898690658,
              5012141.1183227384
            ],
            [
              351850.06140053022,
              5012139.0483290134
            ],
            [
              351846.2138141265,
              5012136.9783352502
            ],
            [
              351844.71423596086,
              5012134.847487757
            ],
            [
              351842.80829445011,
              5012132.1392122898
            ],
            [
              351818.4565365031,
              5012145.3063684618
            ],
            [
              351814.5019485798,
              5012142.8027767465
            ],
            [
              351802.65786644077,
              5012160.2946635624
            ],
            [
              351795.53931175807,
              5012176.6071597505
            ],
            [
              351771.0509343532,
              5012170.140778265
            ],
            [
              351750.98694143497,
              5012129.8455414437
            ],
            [
              351743.86325188837,
              5012133.3926016111
            ],
            [
              351738.82765463181,
              5012137.7755348692
            ],
            [
              351756.8098620263,
              5012175.7819937095
            ],
            [
              351745.90571766387,
              5012179.6982853319
            ],
            [
              351757.19516646193,
              5012188.769409908
            ],
            [
              351808.11995253776,
              5012230.5129176769
            ],
            [
              351817.02114089328,
              5012220.9751704596
            ],
            [
              351819.6465707075,
              5012216.082265703
            ],
            [
              351851.15514948854,
              5012231.192552113
            ],
            [
              351853.36875363934,
              5012224.0946503812
            ],
            [
              351866.62135432195,
              5012228.1559779849
            ],
            [
              351869.16024379706,
              5012220.3453755351
            ],
            [
              351896.12110332976,
              5012216.3471950451
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 208,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352173.81318736629,
              5011778.1134791691
            ],
            [
              352196.74676702457,
              5011744.7089013681
            ],
            [
              352211.33337993309,
              5011751.4120201403
            ],
            [
              352217.9296351588,
              5011740.5384064149
            ],
            [
              352220.55548955611,
              5011737.358520681
            ],
            [
              352221.31911395007,
              5011735.1078920439
            ],
            [
              352215.98693450534,
              5011731.0432704221
            ],
            [
              352219.89339811861,
              5011724.7240624251
            ],
            [
              352207.35796848644,
              5011715.610496155
            ],
            [
              352186.30576125009,
              5011698.8632600904
            ],
            [
              352176.39880102855,
              5011708.3279416868
            ],
            [
              352162.20680781547,
              5011699.2223211536
            ],
            [
              352165.0640942126,
              5011694.5085448474
            ],
            [
              352162.25169715961,
              5011692.8438308034
            ],
            [
              352169.66841757571,
              5011682.5669179009
            ],
            [
              352162.49863641354,
              5011675.3415044416
            ],
            [
              352157.11536137486,
              5011680.5928872647
            ],
            [
              352142.40799220948,
              5011668.356555704
            ],
            [
              352143.92402474704,
              5011666.6967030242
            ],
            [
              352125.67725994129,
              5011654.2322030216
            ],
            [
              352112.59635478858,
              5011646.8731988575
            ],
            [
              352118.39091541612,
              5011639.7881870568
            ],
            [
              352105.8043461999,
              5011630.3039255198
            ],
            [
              352108.81324993976,
              5011624.0330622438
            ],
            [
              352089.04291036696,
              5011615.9284704188
            ],
            [
              352092.83335681679,
              5011611.1891048281
            ],
            [
              352077.98286935926,
              5011606.9629863743
            ],
            [
              352074.76319122122,
              5011602.0930810506
            ],
            [
              352079.68950890045,
              5011598.8361068489
            ],
            [
              352056.48999523564,
              5011578.1625475679
            ],
            [
              352051.97283885494,
              5011582.118928574
            ],
            [
              352044.22089947591,
              5011588.7027134029
            ],
            [
              352049.10993083147,
              5011592.3696655137
            ],
            [
              352046.7188101678,
              5011595.218135071
            ],
            [
              352064.46607366652,
              5011607.1176139778
            ],
            [
              352042.63738375762,
              5011635.3630966255
            ],
            [
              352033.3411330324,
              5011649.6752219917
            ],
            [
              352027.60587245668,
              5011658.7117153332
            ],
            [
              352027.44730453234,
              5011659.1782228574
            ],
            [
              352028.4667393745,
              5011661.9314510133
            ],
            [
              352029.51456529606,
              5011664.5340289101
            ],
            [
              352009.4686289833,
              5011694.6233675918
            ],
            [
              352015.30002562999,
              5011698.6279195631
            ],
            [
              352004.86935828585,
              5011713.1936770836
            ],
            [
              351993.52828760195,
              5011729.0307559231
            ],
            [
              351982.40138550772,
              5011745.9246252412
            ],
            [
              351961.45142590982,
              5011737.9215099635
            ],
            [
              351961.67282812466,
              5011749.8179328302
            ],
            [
              351956.16607295332,
              5011758.7627497492
            ],
            [
              351962.35032060521,
              5011759.1221038429
            ],
            [
              351956.68721972645,
              5011766.9691068074
            ],
            [
              351960.97411703831,
              5011769.5270981751
            ],
            [
              352009.36655957374,
              5011810.201232071
            ],
            [
              352008.44024887338,
              5011818.9001486888
            ],
            [
              352035.15399807331,
              5011830.218614582
            ],
            [
              352033.18860163091,
              5011836.60441064
            ],
            [
              352062.60994534224,
              5011845.6574762342
            ],
            [
              352067.83482093207,
              5011831.7023874195
            ],
            [
              352075.41659638379,
              5011826.0736492472
            ],
            [
              352104.47220542107,
              5011841.4470088333
            ],
            [
              352109.08553369151,
              5011853.3241456999
            ],
            [
              352107.99242318037,
              5011859.4906422338
            ],
            [
              352138.88961710111,
              5011873.5126511604
            ],
            [
              352140.25966119766,
              5011867.3270755652
            ],
            [
              352159.69784816692,
              5011829.7714133998
            ],
            [
              352166.84895461844,
              5011824.8063023584
            ],
            [
              352172.61124408763,
              5011815.624432818
            ],
            [
              352172.00724899239,
              5011815.0948344329
            ],
            [
              352175.33458512346,
              5011809.8476181924
            ],
            [
              352184.1847744892,
              5011815.4912176384
            ],
            [
              352188.40343989251,
              5011805.6095376052
            ],
            [
              352183.75550841045,
              5011801.6772885621
            ],
            [
              352189.69006593653,
              5011792.4221939771
            ],
            [
              352173.81318736629,
              5011778.1134791691
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 209,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351834.29674735945,
              5011750.5543169035
            ],
            [
              351851.05579254701,
              5011763.6488842517
            ],
            [
              351865.13510922407,
              5011735.594677953
            ],
            [
              351875.9997999905,
              5011718.5686333189
            ],
            [
              351887.59565721429,
              5011725.96820274
            ],
            [
              351889.34560008132,
              5011718.4507259736
            ],
            [
              351910.28427940962,
              5011733.4154536314
            ],
            [
              351913.43179510732,
              5011736.3198288875
            ],
            [
              351944.51981767995,
              5011673.7922496377
            ],
            [
              351940.17697767931,
              5011672.8949687732
            ],
            [
              351927.7882694967,
              5011666.6221625628
            ],
            [
              351924.99007947266,
              5011664.6071972884
            ],
            [
              351920.32828605804,
              5011660.2178439442
            ],
            [
              351903.00717425917,
              5011639.8043802073
            ],
            [
              351877.26521160483,
              5011658.5744284242
            ],
            [
              351851.45934466721,
              5011685.8717172453
            ],
            [
              351839.4661221212,
              5011706.3214280643
            ],
            [
              351835.54022299947,
              5011711.8666221155
            ],
            [
              351832.12238713499,
              5011716.6941948906
            ],
            [
              351824.65832865605,
              5011722.7447046703
            ],
            [
              351834.94400112837,
              5011731.5776187852
            ],
            [
              351847.4486068064,
              5011719.9961085422
            ],
            [
              351851.41175238678,
              5011727.1691750214
            ],
            [
              351834.29674735945,
              5011750.5543169035
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 210,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353054.79613850144,
              5011482.1745007047
            ],
            [
              353067.32849772181,
              5011460.5317628551
            ],
            [
              353059.0310509635,
              5011446.0214101607
            ],
            [
              353057.46112161834,
              5011443.5132741379
            ],
            [
              353052.74769907276,
              5011447.2106470587
            ],
            [
              353042.15647507069,
              5011442.6031006947
            ],
            [
              353037.30912109662,
              5011440.4943351019
            ],
            [
              353034.0080742155,
              5011433.4276315775
            ],
            [
              353039.86823164084,
              5011427.1475914186
            ],
            [
              353041.21919778973,
              5011418.5993235419
            ],
            [
              353049.35712999362,
              5011397.504647701
            ],
            [
              353047.00897486467,
              5011396.5987732522
            ],
            [
              353041.68620626227,
              5011394.3411692036
            ],
            [
              353031.92752353539,
              5011383.1293342784
            ],
            [
              353016.87328729633,
              5011378.6409952724
            ],
            [
              353006.30742831825,
              5011395.7886613468
            ],
            [
              353001.40649117337,
              5011401.1410441883
            ],
            [
              352999.88564348296,
              5011404.3298330801
            ],
            [
              353017.00469477917,
              5011412.0345737142
            ],
            [
              353027.22450087982,
              5011416.7109594876
            ],
            [
              353023.16415648808,
              5011424.8098448999
            ],
            [
              353014.54990307061,
              5011421.5503969332
            ],
            [
              353012.12876933959,
              5011425.8043817412
            ],
            [
              353006.29414689861,
              5011423.4138971707
            ],
            [
              352983.92234666966,
              5011409.2304376559
            ],
            [
              352977.76462327247,
              5011417.7629582221
            ],
            [
              352966.14616394747,
              5011408.640557345
            ],
            [
              352971.39230624406,
              5011398.1180392997
            ],
            [
              352972.09186762577,
              5011396.358092322
            ],
            [
              352971.08655545185,
              5011386.4037728142
            ],
            [
              352944.85440614686,
              5011404.7839191929
            ],
            [
              352940.22202841629,
              5011418.6141029727
            ],
            [
              352932.14579900645,
              5011428.6035741502
            ],
            [
              352940.26468922471,
              5011437.4025080893
            ],
            [
              352950.1927951242,
              5011441.1881534094
            ],
            [
              352969.60707623401,
              5011456.6833985038
            ],
            [
              352977.23237161542,
              5011462.8357411111
            ],
            [
              352981.36248850246,
              5011458.6898927605
            ],
            [
              352986.20950952882,
              5011463.3573442968
            ],
            [
              352997.97620978171,
              5011472.8605824448
            ],
            [
              352999.08909227181,
              5011471.4953035284
            ],
            [
              353004.16992078867,
              5011477.6064079823
            ],
            [
              353016.8488738375,
              5011491.6434494061
            ],
            [
              353027.68652843876,
              5011501.2674117442
            ],
            [
              353032.56443378428,
              5011499.7572041592
            ],
            [
              353037.53366291703,
              5011499.8715990549
            ],
            [
              353039.34802383877,
              5011502.8372088494
            ],
            [
              353043.16588798963,
              5011502.2490460156
            ],
            [
              353054.79613850144,
              5011482.1745007047
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 211,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353241.86435924732,
              5011226.1481536319
            ],
            [
              353227.09058323089,
              5011221.1407126095
            ],
            [
              353201.97865381115,
              5011194.3974604197
            ],
            [
              353205.29241139558,
              5011186.7356756181
            ],
            [
              353240.91777914175,
              5011206.6621420691
            ],
            [
              353251.56994380133,
              5011195.7533954913
            ],
            [
              353253.53077289107,
              5011193.3901218874
            ],
            [
              353245.46001534624,
              5011177.7845531031
            ],
            [
              353243.02528025344,
              5011177.8298614779
            ],
            [
              353242.57107766793,
              5011167.9656343013
            ],
            [
              353223.64613711979,
              5011176.2294869274
            ],
            [
              353223.60877912905,
              5011163.3145237714
            ],
            [
              353217.0535471717,
              5011163.7069790214
            ],
            [
              353218.29684559116,
              5011155.1291486453
            ],
            [
              353202.39304721338,
              5011151.2325934004
            ],
            [
              353196.94697349815,
              5011145.7889868049
            ],
            [
              353208.43878145557,
              5011138.0015524048
            ],
            [
              353213.34695580357,
              5011136.3549309429
            ],
            [
              353209.07719439355,
              5011134.0902309138
            ],
            [
              353206.05743830901,
              5011132.573416179
            ],
            [
              353201.30802244542,
              5011130.2453112071
            ],
            [
              353181.52740047965,
              5011126.1415779786
            ],
            [
              353173.34614248754,
              5011146.3940936225
            ],
            [
              353178.65383459727,
              5011149.9304862525
            ],
            [
              353175.9767914323,
              5011155.5399535736
            ],
            [
              353173.19487999595,
              5011161.258276674
            ],
            [
              353166.21646470012,
              5011183.3496870007
            ],
            [
              353149.74736002897,
              5011177.8827046547
            ],
            [
              353147.37991625938,
              5011185.4475674257
            ],
            [
              353163.66132632556,
              5011191.8456630399
            ],
            [
              353165.23023871385,
              5011188.157922877
            ],
            [
              353176.85594007216,
              5011191.1335092448
            ],
            [
              353175.88596469577,
              5011194.9232307989
            ],
            [
              353166.13192987494,
              5011219.2013951875
            ],
            [
              353158.81775167485,
              5011231.0880421745
            ],
            [
              353196.57783640741,
              5011264.4634147184
            ],
            [
              353211.37322426477,
              5011256.2020218
            ],
            [
              353232.36460083869,
              5011252.9128940115
            ],
            [
              353244.9387163354,
              5011233.5743226195
            ],
            [
              353241.86435924732,
              5011226.1481536319
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 212,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353280.88597792725,
              5011282.7274575783
            ],
            [
              353276.883498087,
              5011282.8424411882
            ],
            [
              353271.21377103409,
              5011289.0226631733
            ],
            [
              353261.37173844076,
              5011279.9935966227
            ],
            [
              353254.17932421033,
              5011287.3306359751
            ],
            [
              353247.24303398753,
              5011301.8661018945
            ],
            [
              353249.42184487701,
              5011304.5046061333
            ],
            [
              353240.08458868455,
              5011320.2143609663
            ],
            [
              353229.93976159248,
              5011316.0718486598
            ],
            [
              353214.78247872804,
              5011309.8825629363
            ],
            [
              353213.0215083551,
              5011314.1951000197
            ],
            [
              353203.4319882057,
              5011331.7875286667
            ],
            [
              353221.48063721001,
              5011341.3848130973
            ],
            [
              353240.99719615624,
              5011346.2540116953
            ],
            [
              353234.91955763201,
              5011357.972680252
            ],
            [
              353238.20697466907,
              5011364.1355906334
            ],
            [
              353252.44901771849,
              5011381.7360791778
            ],
            [
              353261.09279522818,
              5011373.3205248881
            ],
            [
              353262.00626997981,
              5011371.9990583174
            ],
            [
              353244.07003742241,
              5011362.207081682
            ],
            [
              353250.49977656378,
              5011348.435583245
            ],
            [
              353263.95207214577,
              5011353.3736003293
            ],
            [
              353267.93262386922,
              5011346.9035435254
            ],
            [
              353269.72838322696,
              5011342.0321110925
            ],
            [
              353270.30422648194,
              5011328.9013962531
            ],
            [
              353270.22807969473,
              5011323.0738755884
            ],
            [
              353276.59427241242,
              5011318.5472581694
            ],
            [
              353287.47275776585,
              5011304.8205862688
            ],
            [
              353285.97655294958,
              5011303.2194388406
            ],
            [
              353293.69743276614,
              5011290.6080219941
            ],
            [
              353296.94510264619,
              5011289.8341947943
            ],
            [
              353312.47528255783,
              5011273.585970629
            ],
            [
              353300.05073209316,
              5011260.7275574608
            ],
            [
              353294.0898382244,
              5011260.8384861201
            ],
            [
              353288.7629491557,
              5011267.1584231891
            ],
            [
              353280.88597792725,
              5011282.7274575783
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 213,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353190.3683920289,
              5011384.7189114979
            ],
            [
              353203.08177823765,
              5011367.1271357797
            ],
            [
              353191.48843546904,
              5011361.0235188054
            ],
            [
              353173.65589799633,
              5011348.9477721397
            ],
            [
              353169.57145168987,
              5011354.6753000459
            ],
            [
              353164.92965395976,
              5011361.9553026864
            ],
            [
              353156.94731105334,
              5011355.5989067135
            ],
            [
              353141.15967744665,
              5011373.5714681456
            ],
            [
              353130.17382785777,
              5011379.5434758402
            ],
            [
              353122.61069091002,
              5011378.6194483768
            ],
            [
              353121.34755801322,
              5011380.3234259272
            ],
            [
              353127.3548407575,
              5011386.1083497768
            ],
            [
              353128.84580814,
              5011393.5743372506
            ],
            [
              353122.63213342067,
              5011396.5154701751
            ],
            [
              353108.76592379279,
              5011391.1225218028
            ],
            [
              353106.86227655737,
              5011401.1086459793
            ],
            [
              353100.9235028798,
              5011399.0078919725
            ],
            [
              353090.52114483149,
              5011411.8914734079
            ],
            [
              353087.59569003148,
              5011416.8025060073
            ],
            [
              353088.83626928687,
              5011417.8448811136
            ],
            [
              353083.87023733481,
              5011426.7444734322
            ],
            [
              353082.61332020146,
              5011425.6901440518
            ],
            [
              353075.63762171363,
              5011439.5412339633
            ],
            [
              353080.31427471078,
              5011442.0758524295
            ],
            [
              353111.65422258567,
              5011459.0054556876
            ],
            [
              353114.77113795251,
              5011449.1981658908
            ],
            [
              353117.71792898915,
              5011437.9603711162
            ],
            [
              353120.14176375855,
              5011429.4563550744
            ],
            [
              353120.75210217288,
              5011423.7101343283
            ],
            [
              353122.67907675158,
              5011411.6310447715
            ],
            [
              353128.22846619523,
              5011401.4917892478
            ],
            [
              353137.98044626159,
              5011409.0523438752
            ],
            [
              353152.13581987453,
              5011421.9791148864
            ],
            [
              353171.44848669023,
              5011407.7319842065
            ],
            [
              353177.42289718572,
              5011398.4556758851
            ],
            [
              353182.26713137433,
              5011415.0843219748
            ],
            [
              353193.67530443397,
              5011399.966117017
            ],
            [
              353191.18639889063,
              5011396.1852395367
            ],
            [
              353190.3683920289,
              5011384.7189114979
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 214,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352707.65434583271,
              5010393.952221144
            ],
            [
              352711.65066119324,
              5010388.7749302816
            ],
            [
              352723.12981959386,
              5010393.1687237825
            ],
            [
              352728.74079356907,
              5010388.4569363492
            ],
            [
              352732.97339474555,
              5010373.6958623491
            ],
            [
              352716.02220890619,
              5010372.5489167366
            ],
            [
              352717.09181470529,
              5010365.4752479037
            ],
            [
              352718.45129913167,
              5010356.4845140222
            ],
            [
              352721.4781796211,
              5010345.9395510303
            ],
            [
              352708.12058270688,
              5010337.0332867811
            ],
            [
              352701.06447655242,
              5010332.3114859089
            ],
            [
              352693.21056477603,
              5010331.6628894033
            ],
            [
              352690.43737593835,
              5010329.5666527133
            ],
            [
              352686.74411087733,
              5010327.971445824
            ],
            [
              352676.34116632072,
              5010329.9564956985
            ],
            [
              352668.20659938286,
              5010328.2384715071
            ],
            [
              352671.91965712042,
              5010317.0931414645
            ],
            [
              352677.15105742583,
              5010305.7568314923
            ],
            [
              352672.64843451086,
              5010302.7488573976
            ],
            [
              352663.89832347876,
              5010310.447808817
            ],
            [
              352646.79778381874,
              5010310.9592168806
            ],
            [
              352635.69188652525,
              5010318.6222740812
            ],
            [
              352629.72256606433,
              5010325.1100411965
            ],
            [
              352621.42871220404,
              5010331.3512402624
            ],
            [
              352618.28940501675,
              5010345.0869037416
            ],
            [
              352613.99878639978,
              5010353.4757778551
            ],
            [
              352612.40994055831,
              5010356.4038703758
            ],
            [
              352607.08731905947,
              5010371.6717556724
            ],
            [
              352616.0482867618,
              5010381.088988618
            ],
            [
              352630.13624988537,
              5010398.864820471
            ],
            [
              352638.87769785995,
              5010406.5679693772
            ],
            [
              352642.23253699887,
              5010413.6125418013
            ],
            [
              352651.04204825911,
              5010422.4442683402
            ],
            [
              352658.16487682913,
              5010427.0601595147
            ],
            [
              352666.89170320536,
              5010427.8769189492
            ],
            [
              352670.2564899571,
              5010428.6388655426
            ],
            [
              352671.72324711044,
              5010429.9000085741
            ],
            [
              352667.91266601713,
              5010438.4226817824
            ],
            [
              352670.46066303924,
              5010444.6945008012
            ],
            [
              352679.3812785662,
              5010431.2881675139
            ],
            [
              352684.01139495522,
              5010435.0659784414
            ],
            [
              352703.03110697417,
              5010445.007819253
            ],
            [
              352704.14324186515,
              5010446.176022415
            ],
            [
              352705.15632318211,
              5010447.3461013343
            ],
            [
              352707.24049014045,
              5010449.3987218803
            ],
            [
              352712.03249826404,
              5010444.6202478465
            ],
            [
              352713.45151950495,
              5010443.0416173823
            ],
            [
              352715.47561120969,
              5010440.7898591291
            ],
            [
              352713.6422855914,
              5010437.0940478379
            ],
            [
              352717.19786734792,
              5010433.0963888206
            ],
            [
              352719.95808353141,
              5010436.0856190575
            ],
            [
              352721.26365701022,
              5010434.5797414752
            ],
            [
              352725.71828238509,
              5010438.4418351986
            ],
            [
              352729.06834316958,
              5010436.0917006694
            ],
            [
              352722.05477922532,
              5010429.1115882779
            ],
            [
              352721.08649505832,
              5010428.1479223678
            ],
            [
              352715.67020255059,
              5010428.2487032609
            ],
            [
              352713.63646579621,
              5010426.3325049262
            ],
            [
              352719.78043322224,
              5010425.3634465868
            ],
            [
              352718.34559535125,
              5010416.266370412
            ],
            [
              352717.66986252443,
              5010409.5679166326
            ],
            [
              352719.56829983002,
              5010406.5793412887
            ],
            [
              352728.61092596356,
              5010410.3018188346
            ],
            [
              352729.74592225783,
              5010406.402366831
            ],
            [
              352721.33984275616,
              5010403.955646418
            ],
            [
              352707.65434583271,
              5010393.952221144
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 215,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352694.38336987037,
              5010151.9033350321
            ],
            [
              352697.69227031007,
              5010161.4325895477
            ],
            [
              352694.73094645416,
              5010163.6607062742
            ],
            [
              352701.29462496488,
              5010171.4029807709
            ],
            [
              352708.10161029414,
              5010175.5189683195
            ],
            [
              352713.09498624079,
              5010176.7713434175
            ],
            [
              352723.60175710806,
              5010198.5879793717
            ],
            [
              352731.87300907,
              5010218.3662853483
            ],
            [
              352725.75407812215,
              5010220.6509284014
            ],
            [
              352730.4606664569,
              5010231.388000791
            ],
            [
              352736.46887429798,
              5010228.7543070624
            ],
            [
              352739.25312196504,
              5010241.7606109725
            ],
            [
              352734.01043840073,
              5010242.8829075349
            ],
            [
              352738.7863786901,
              5010265.1931764949
            ],
            [
              352744.88712056354,
              5010270.0787327923
            ],
            [
              352742.14341860719,
              5010273.5048762672
            ],
            [
              352744.89793038677,
              5010276.7182752425
            ],
            [
              352757.15048731276,
              5010263.0499948999
            ],
            [
              352756.98104842397,
              5010251.269585507
            ],
            [
              352764.87254586519,
              5010249.0962437093
            ],
            [
              352769.11776727921,
              5010243.5108537376
            ],
            [
              352774.95229058503,
              5010235.8344374904
            ],
            [
              352781.98930610617,
              5010227.1072994974
            ],
            [
              352787.78982457699,
              5010219.9136358527
            ],
            [
              352792.25187140895,
              5010212.2176918676
            ],
            [
              352795.97753921495,
              5010193.4518903699
            ],
            [
              352788.92695535353,
              5010190.6467396589
            ],
            [
              352782.44244617253,
              5010189.6163094481
            ],
            [
              352766.04786264425,
              5010180.4313080655
            ],
            [
              352760.12838534924,
              5010177.0021903617
            ],
            [
              352772.54141557822,
              5010155.5743344538
            ],
            [
              352787.59037670091,
              5010129.5962361749
            ],
            [
              352769.84762271208,
              5010122.1870633867
            ],
            [
              352758.21365424449,
              5010113.6074246671
            ],
            [
              352741.87100445927,
              5010097.4326785291
            ],
            [
              352733.54785591952,
              5010092.510522672
            ],
            [
              352724.22948994621,
              5010110.2741763638
            ],
            [
              352734.37261264742,
              5010117.5686037876
            ],
            [
              352725.9901508636,
              5010131.8518113941
            ],
            [
              352723.77221304883,
              5010136.9230559776
            ],
            [
              352721.87930962763,
              5010144.8431412447
            ],
            [
              352713.84645502694,
              5010137.1077360455
            ],
            [
              352694.38336987037,
              5010151.9033350321
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 216,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351658.12457084679,
              5010808.8313969467
            ],
            [
              351662.1990462314,
              5010809.3857947011
            ],
            [
              351664.54016580136,
              5010796.1908572093
            ],
            [
              351664.22078021854,
              5010791.4645064455
            ],
            [
              351660.38238604442,
              5010777.4698384777
            ],
            [
              351658.37945624429,
              5010772.3813044066
            ],
            [
              351655.31674985605,
              5010768.0277267518
            ],
            [
              351651.97576317593,
              5010761.5336841242
            ],
            [
              351645.20826104563,
              5010756.7722426513
            ],
            [
              351640.96221185225,
              5010752.9174866052
            ],
            [
              351636.35849235958,
              5010743.4210140239
            ],
            [
              351636.35849235859,
              5010743.4210140239
            ],
            [
              351636.07365370897,
              5010743.3600109629
            ],
            [
              351636.35849235859,
              5010743.4210140239
            ],
            [
              351680.66459692392,
              5010662.0559172789
            ],
            [
              351690.46274922002,
              5010658.2869881447
            ],
            [
              351693.80059615884,
              5010654.4589383686
            ],
            [
              351696.61698823766,
              5010651.5372519009
            ],
            [
              351710.58983699227,
              5010641.3698579036
            ],
            [
              351721.42513448797,
              5010633.9394189036
            ],
            [
              351730.18761834013,
              5010629.0402194709
            ],
            [
              351758.96275192319,
              5010612.8750481885
            ],
            [
              351778.84461549466,
              5010602.4096782031
            ],
            [
              351782.33604016359,
              5010600.2364981379
            ],
            [
              351786.14563776326,
              5010597.1595936092
            ],
            [
              351807.84739531396,
              5010586.8889234401
            ],
            [
              351810.74709476525,
              5010585.6175627559
            ],
            [
              351890.83390481223,
              5010629.7166946288
            ],
            [
              351902.32816934219,
              5010625.9663857436
            ],
            [
              351917.40121325437,
              5010624.3920818083
            ],
            [
              351928.7746950718,
              5010628.0619095815
            ],
            [
              351929.57841818052,
              5010629.5132525694
            ],
            [
              351936.65409917926,
              5010629.4678575406
            ],
            [
              351949.52941384102,
              5010635.0073364815
            ],
            [
              351957.4242511362,
              5010634.7071864307
            ],
            [
              351971.28027841687,
              5010637.2957544224
            ],
            [
              351967.36983910814,
              5010644.9924450656
            ],
            [
              351982.38390126178,
              5010644.8855688609
            ],
            [
              351989.5650889196,
              5010645.873274981
            ],
            [
              351996.28286230622,
              5010645.1444745045
            ],
            [
              351997.85116115998,
              5010651.8624010291
            ],
            [
              352006.32911519153,
              5010648.9872419499
            ],
            [
              352016.73374336289,
              5010648.8873230629
            ],
            [
              352017.50749897811,
              5010645.1247714246
            ],
            [
              352027.25782813557,
              5010645.130779664
            ],
            [
              352033.82517269131,
              5010645.3833890567
            ],
            [
              352048.24949740892,
              5010652.2471708627
            ],
            [
              352066.54264749394,
              5010657.9038040889
            ],
            [
              352080.76189774828,
              5010656.2336555161
            ],
            [
              352093.12027568539,
              5010650.2877951255
            ],
            [
              352090.57552623784,
              5010644.5255276253
            ],
            [
              352100.14495966834,
              5010639.8496861588
            ],
            [
              352110.16552679619,
              5010636.1704408797
            ],
            [
              352147.38848574477,
              5010621.2348904721
            ],
            [
              352147.48914765083,
              5010621.6078293752
            ],
            [
              352163.24719143164,
              5010611.9442463033
            ],
            [
              352160.42133764952,
              5010608.4838407487
            ],
            [
              352169.74412853591,
              5010600.6266890271
            ],
            [
              352171.33559292246,
              5010600.5033565257
            ],
            [
              352169.03753966879,
              5010597.9224338783
            ],
            [
              352193.95006001461,
              5010581.4355502222
            ],
            [
              352192.39651008515,
              5010578.5596490279
            ],
            [
              352181.52327797661,
              5010578.6682853298
            ],
            [
              352174.55348348443,
              5010566.7693427131
            ],
            [
              352163.90458392835,
              5010566.0165029466
            ],
            [
              352161.43407186068,
              5010561.3599000089
            ],
            [
              352158.43742346735,
              5010556.7363748588
            ],
            [
              352154.99050396454,
              5010559.5110117383
            ],
            [
              352148.61830838048,
              5010563.9925662251
            ],
            [
              352141.3179074878,
              5010568.4737678217
            ],
            [
              352136.71691014915,
              5010571.2880184604
            ],
            [
              352132.51018118887,
              5010573.4882427426
            ],
            [
              352125.9000431762,
              5010577.047194453
            ],
            [
              352118.73384910013,
              5010580.465134996
            ],
            [
              352109.3001688908,
              5010584.4808064876
            ],
            [
              352105.38159168401,
              5010585.86730815
            ],
            [
              352102.56745513657,
              5010586.8294976829
            ],
            [
              352098.94376662676,
              5010587.8063316625
            ],
            [
              352095.31710040726,
              5010588.5811307719
            ],
            [
              352091.38646438433,
              5010589.3615860045
            ],
            [
              352087.35204832919,
              5010589.9418820282
            ],
            [
              352083.71708240482,
              5010590.3126550028
            ],
            [
              352079.37305785116,
              5010590.595576616
            ],
            [
              352075.97220651997,
              5010590.4412543839
            ],
            [
              352072.52913067775,
              5010590.2021858403
            ],
            [
              352068.67109576141,
              5010589.4651935576
            ],
            [
              352063.90052579576,
              5010588.5435101772
            ],
            [
              352057.28858766326,
              5010586.5445931712
            ],
            [
              352050.0683852933,
              5010584.4555299012
            ],
            [
              352044.06282371172,
              5010582.4453296168
            ],
            [
              352034.78837098309,
              5010578.6767226486
            ],
            [
              352029.61541296396,
              5010575.7902588742
            ],
            [
              352014.09807419439,
              5010568.0955892829
            ],
            [
              352005.1230501699,
              5010564.1193196457
            ],
            [
              351992.27496176475,
              5010558.5983944945
            ],
            [
              351985.03409111669,
              5010555.3986370666
            ],
            [
              351980.04233083536,
              5010553.4701976245
            ],
            [
              351974.13647243712,
              5010551.3570959764
            ],
            [
              351957.06637849408,
              5010545.3671574369
            ],
            [
              351951.67360467749,
              5010543.6482698666
            ],
            [
              351951.06158912694,
              5010543.3565222649
            ],
            [
              351950.44691263168,
              5010542.963778981
            ],
            [
              351949.72845613206,
              5010542.3708763774
            ],
            [
              351949.11377963459,
              5010541.9781330936
            ],
            [
              351948.50552314246,
              5010541.8884059293
            ],
            [
              351947.79568287899,
              5010541.8005689681
            ],
            [
              351946.57650894433,
              5010541.5201189546
            ],
            [
              351944.64827614912,
              5010541.1518174391
            ],
            [
              351941.086699482,
              5010540.0055465093
            ],
            [
              351932.034523974,
              5010537.3442984438
            ],
            [
              351917.57908599329,
              5010532.3585055368
            ],
            [
              351906.31549801113,
              5010527.4692907287
            ],
            [
              351900.80125412933,
              5010524.6415848276
            ],
            [
              351893.34217029664,
              5010520.6371052107
            ],
            [
              351884.0338827477,
              5010515.0503106248
            ],
            [
              351877.8917975658,
              5010511.1223701034
            ],
            [
              351873.86948101292,
              5010507.9000376547
            ],
            [
              351868.21417525876,
              5010502.9525871025
            ],
            [
              351862.13231652806,
              5010496.8001114437
            ],
            [
              351858.52187170583,
              5010493.027573349
            ],
            [
              351857.48628070869,
              5010491.7328356467
            ],
            [
              351855.40383722418,
              5010488.5381369879
            ],
            [
              351854.25052821724,
              5010486.3361826679
            ],
            [
              351853.81004507717,
              5010484.4245196078
            ],
            [
              351853.24807711568,
              5010481.4032003125
            ],
            [
              351852.98742603278,
              5010478.275648416
            ],
            [
              351853.261316018,
              5010476.6534092855
            ],
            [
              351853.83010291745,
              5010474.6219213586
            ],
            [
              351855.50410507614,
              5010472.1656876719
            ],
            [
              351857.96793046984,
              5010468.8025409579
            ],
            [
              351862.22737222683,
              5010463.9737404585
            ],
            [
              351867.39340206422,
              5010458.9259806741
            ],
            [
              351872.54925295908,
              5010453.3731843652
            ],
            [
              351875.1242047167,
              5010450.3949608132
            ],
            [
              351876.50174719997,
              5010448.3480052222
            ],
            [
              351877.96772476041,
              5010445.5925069079
            ],
            [
              351878.93187883002,
              5010443.0480172224
            ],
            [
              351879.49768829677,
              5010440.8144944785
            ],
            [
              351879.55337485234,
              5010438.3879539138
            ],
            [
              351879.51954384672,
              5010436.5697695604
            ],
            [
              351879.07983463182,
              5010434.6576729044
            ],
            [
              351877.81555256149,
              5010431.9529766431
            ],
            [
              351875.6315181636,
              5010428.7597488482
            ],
            [
              351870.75357529923,
              5010423.9160681358
            ],
            [
              351865.71670619416,
              5010419.5633802479
            ],
            [
              351858.52587460674,
              5010413.6336281402
            ],
            [
              351849.80517006171,
              5010407.0254447581
            ],
            [
              351844.17617821856,
              5010403.4921357334
            ],
            [
              351839.26643201936,
              5010400.5521342708
            ],
            [
              351834.8791681563,
              5010398.5113987075
            ],
            [
              351826.51616483351,
              5010394.8272896931
            ],
            [
              351812.61811572505,
              5010389.382074628
            ],
            [
              351805.9016047279,
              5010387.1825883212
            ],
            [
              351799.29530123837,
              5010385.4866967648
            ],
            [
              351795.23378719488,
              5010384.6528617172
            ],
            [
              351790.05844152754,
              5010383.7387063308
            ],
            [
              351786.71240639233,
              5010383.2957393723
            ],
            [
              351784.9857698768,
              5010382.9232664378
            ],
            [
              351782.74980732641,
              5010382.3585992493
            ],
            [
              351780.01093080221,
              5010381.9043349521
            ],
            [
              351777.98675187241,
              5010381.8409533389
            ],
            [
              351775.55780047277,
              5010381.7851032717
            ],
            [
              351773.33875322505,
              5010382.1295285216
            ],
            [
              351771.2231692716,
              5010382.5730738956
            ],
            [
              351769.41641240322,
              5010383.3140087053
            ],
            [
              351767.81956750166,
              5010384.4556379765
            ],
            [
              351766.39214157517,
              5010386.4263755409
            ],
            [
              351765.4054251365,
              5010387.7583234692
            ],
            [
              351764.80954334937,
              5010388.3756824555
            ],
            [
              351764.41307023942,
              5010388.7872405732
            ],
            [
              351764.01017725497,
              5010388.8957824074
            ],
            [
              351763.50460236991,
              5010388.9051895794
            ],
            [
              351762.99150954024,
              5010388.510555652
            ],
            [
              351761.75541969278,
              5010387.3210124569
            ],
            [
              351761.13400650426,
              5010386.5242130347
            ],
            [
              351760.62091367168,
              5010386.129579097
            ],
            [
              351760.10970032512,
              5010385.8359554373
            ],
            [
              351759.60036646406,
              5010385.6433420544
            ],
            [
              351759.09479157411,
              5010385.6527492246
            ],
            [
              351757.88501452439,
              5010385.8773498982
            ],
            [
              351755.87963032484,
              5010386.8240710869
            ],
            [
              351750.27200913709,
              5010389.8587237261
            ],
            [
              351741.84758694319,
              5010393.7545697046
            ],
            [
              351736.33889631572,
              5010396.6867558016
            ],
            [
              351732.73477971688,
              5010398.6736771557
            ],
            [
              351729.03847670957,
              5010401.1675401423
            ],
            [
              351721.60083743365,
              5010406.8017876158
            ],
            [
              351715.12422504358,
              5010412.0760251861
            ],
            [
              351711.84585094242,
              5010415.2694288958
            ],
            [
              351708.57688211818,
              5010418.9683032567
            ],
            [
              351708.28872939193,
              5010419.7820270229
            ],
            [
              351707.90275182034,
              5010420.7996615358
            ],
            [
              351706.91604314657,
              5010422.1320289169
            ],
            [
              351701.99750461493,
              5010429.6002717707
            ],
            [
              351698.74169235578,
              5010434.0062184352
            ],
            [
              351693.58881614666,
              5010439.7610524055
            ],
            [
              351691.12563352211,
              5010443.2428433578
            ],
            [
              351688.73358761513,
              5010446.5987860458
            ],
            [
              351686.67815518292,
              5010450.2750806008
            ],
            [
              351684.81868132675,
              5010453.6441738969
            ],
            [
              351683.53818153444,
              5010455.4872344155
            ],
            [
              351682.45442170813,
              5010457.0230790973
            ],
            [
              351680.76538254198,
              5010458.6712315502
            ],
            [
              351678.37778742559,
              5010460.8380277567
            ],
            [
              351676.08988884866,
              5010462.9015043816
            ],
            [
              351673.40850255056,
              5010465.5789936231
            ],
            [
              351670.93325376586,
              5010468.4543185569
            ],
            [
              351668.86090585677,
              5010471.2215207014
            ],
            [
              351667.18878230284,
              5010473.7787660249
            ],
            [
              351666.4100985509,
              5010475.4103987962
            ],
            [
              351665.54376116057,
              5010477.7505602203
            ],
            [
              351664.57208090223,
              5010479.8905912349
            ],
            [
              351662.60241500201,
              5010482.756928158
            ],
            [
              351660.71850762999,
              5010484.8128878912
            ],
            [
              351658.50066034257,
              5010486.9442417035
            ],
            [
              351655.51231852331,
              5010489.4249330126
            ],
            [
              351651.72007784771,
              5010492.2241377784
            ],
            [
              351646.43444764178,
              5010496.2636737907
            ],
            [
              351640.64888090425,
              5010500.6156482063
            ],
            [
              351636.56362262141,
              5010503.9251126107
            ],
            [
              351632.38806539431,
              5010507.8429483054
            ],
            [
              351629.11721729621,
              5010511.4408140834
            ],
            [
              351626.64948667825,
              5010514.720180979
            ],
            [
              351624.78140471847,
              5010517.5846282169
            ],
            [
              351622.19618064904,
              5010522.1533558667
            ],
            [
              351620.74524664617,
              5010525.717358225
            ],
            [
              351619.29807173222,
              5010529.4833812453
            ],
            [
              351618.43549365242,
              5010532.0255637616
            ],
            [
              351617.46570092993,
              5010534.2670246689
            ],
            [
              351616.28035738348,
              5010535.8047600975
            ],
            [
              351614.593197804,
              5010537.5539235724
            ],
            [
              351611.70080904319,
              5010539.730113605
            ],
            [
              351607.60537171707,
              5010542.5345416889
            ],
            [
              351603.91471482144,
              5010545.3318572259
            ],
            [
              351600.52614077751,
              5010548.022087289
            ],
            [
              351598.63284358248,
              5010549.5734152636
            ],
            [
              351596.64625426847,
              5010551.5302410871
            ],
            [
              351594.96740203182,
              5010553.6838506293
            ],
            [
              351593.19369486149,
              5010556.2429871075
            ],
            [
              351588.15246186848,
              5010564.0908649769
            ],
            [
              351580.8118783097,
              5010560.9940044889
            ],
            [
              351579.44372522045,
              5010563.5455948049
            ],
            [
              351586.16963945649,
              5010566.2501307288
            ],
            [
              351582.60875952069,
              5010570.5607114015
            ],
            [
              351577.67330656463,
              5010577.1198661001
            ],
            [
              351572.65772619913,
              5010584.7920103027
            ],
            [
              351570.41228362673,
              5010589.1791596776
            ],
            [
              351568.1661369708,
              5010594.4526698329
            ],
            [
              351567.02778225759,
              5010598.5156639973
            ],
            [
              351566.38827376574,
              5010602.165613953
            ],
            [
              351566.25355906278,
              5010605.8061709609
            ],
            [
              351566.41074809956,
              5010608.8346052524
            ],
            [
              351567.06490377279,
              5010611.3489849586
            ],
            [
              351567.90937927511,
              5010613.2531321626
            ],
            [
              351569.15111706028,
              5010614.746125333
            ],
            [
              351572.76078484644,
              5010618.5186798386
            ],
            [
              351574.8188048235,
              5010620.4006651649
            ],
            [
              351576.76849631005,
              5010621.8800657056
            ],
            [
              351578.40106067312,
              5010622.658050077
            ],
            [
              351579.82638143323,
              5010623.1367548332
            ],
            [
              351581.95512314216,
              5010623.4002800249
            ],
            [
              351585.49414912035,
              5010623.3344273446
            ],
            [
              351589.83817987592,
              5010623.051504883
            ],
            [
              351595.38634937379,
              5010622.2409498431
            ],
            [
              351600.21903911396,
              5010621.0395269757
            ],
            [
              351602.22442356759,
              5010620.0928039914
            ],
            [
              351605.62692752952,
              5010618.1092118127
            ],
            [
              351609.05902599072,
              5010615.1955361431
            ],
            [
              351611.74041161756,
              5010612.5180452364
            ],
            [
              351613.02279818332,
              5010610.7764132014
            ],
            [
              351614.00387553265,
              5010609.1414330946
            ],
            [
              351614.57719475636,
              5010607.3115305062
            ],
            [
              351614.94171539816,
              5010605.1827965444
            ],
            [
              351615.00758779614,
              5010603.2617102768
            ],
            [
              351614.76384356577,
              5010601.0428300481
            ],
            [
              351614.19811508269,
              5010597.8194882181
            ],
            [
              351613.85357623332,
              5010595.6029027924
            ],
            [
              351613.60796030279,
              5010593.2834313614
            ],
            [
              351613.36045704229,
              5010590.8625304783
            ],
            [
              351613.20513993461,
              5010587.9346877821
            ],
            [
              351613.35411755758,
              5010585.1026474973
            ],
            [
              351613.62048904557,
              5010583.0763658797
            ],
            [
              351613.9774916615,
              5010580.5435906611
            ],
            [
              351614.33464836539,
              5010577.641011971
            ],
            [
              351614.88463183108,
              5010574.599000155
            ],
            [
              351615.53808653197,
              5010571.6565275816
            ],
            [
              351616.10310637433,
              5010569.4225983275
            ],
            [
              351616.87506084511,
              5010567.3873284254
            ],
            [
              351617.94001718651,
              5010564.840960796
            ],
            [
              351619.69790644239,
              5010561.4737564605
            ],
            [
              351621.65441516577,
              5010557.9003465176
            ],
            [
              351623.82082837744,
              5010554.7272119988
            ],
            [
              351626.09369046002,
              5010551.8556519281
            ],
            [
              351628.86836076051,
              5010548.7722447226
            ],
            [
              351630.85307037079,
              5010546.7144087814
            ],
            [
              351632.84529811691,
              5010545.060614123
            ],
            [
              351634.98136713431,
              5010543.6594814826
            ],
            [
              351636.98487206799,
              5010542.6117487326
            ],
            [
              351640.99751270685,
              5010540.8188950727
            ],
            [
              351645.72376105812,
              5010539.3163182456
            ],
            [
              351650.14791872486,
              5010537.920407841
            ],
            [
              351650.59028195933,
              5010539.9330822714
            ],
            [
              351659.06467843469,
              5010538.7649436081
            ],
            [
              351660.7167454717,
              5010546.0103016524
            ],
            [
              351656.93843567383,
              5010549.5161454743
            ],
            [
              351658.39195683255,
              5010551.5104241436
            ],
            [
              351664.16060682089,
              5010546.2493563658
            ],
            [
              351661.83935638762,
              5010535.6819559345
            ],
            [
              351662.02857816883,
              5010534.9706988055
            ],
            [
              351662.52851460507,
              5010534.6582604973
            ],
            [
              351663.53590559709,
              5010534.4374251012
            ],
            [
              351665.35018081148,
              5010534.1005304866
            ],
            [
              351668.17184699437,
              5010533.5428004833
            ],
            [
              351671.39640620345,
              5010532.8765284624
            ],
            [
              351675.73291783751,
              5010532.1895660134
            ],
            [
              351680.37903813756,
              5010531.7999784183
            ],
            [
              351682.80720881437,
              5010531.8558419785
            ],
            [
              351684.43303576804,
              5010532.2297708057
            ],
            [
              351686.1588837328,
              5010532.6018385086
            ],
            [
              351687.37993811397,
              5010532.9832990579
            ],
            [
              351689.1028084206,
              5010533.1533316197
            ],
            [
              351691.63068335148,
              5010533.1062946729
            ],
            [
              351695.16594916396,
              5010532.8384223552
            ],
            [
              351698.1648092856,
              5010532.6874462292
            ],
            [
              351700.9007168088,
              5010532.9400934335
            ],
            [
              351703.84761901502,
              5010533.693621642
            ],
            [
              351706.39053007594,
              5010534.4546670299
            ],
            [
              351708.94095924182,
              5010535.6197535722
            ],
            [
              351712.51946187596,
              5010537.6755370433
            ],
            [
              351716.61856788612,
              5010540.5295762587
            ],
            [
              351721.44819726184,
              5010544.5829847129
            ],
            [
              351725.1613338461,
              5010548.4550775103
            ],
            [
              351727.0301152147,
              5010551.047481169
            ],
            [
              351728.39035180514,
              5010553.4476762358
            ],
            [
              351729.24046519405,
              5010555.6548535498
            ],
            [
              351729.58501146658,
              5010557.8718574038
            ],
            [
              351730.06308592652,
              5010561.8037267569
            ],
            [
              351730.08462416288,
              5010567.3302081088
            ],
            [
              351729.84831751493,
              5010570.9722344596
            ],
            [
              351729.39913661551,
              5010574.0123695787
            ],
            [
              351728.45831902511,
              5010577.7689696169
            ],
            [
              351726.72785002046,
              5010582.6517609237
            ],
            [
              351725.57148164767,
              5010585.7046357775
            ],
            [
              351723.90500565671,
              5010588.5653312178
            ],
            [
              351721.93721274921,
              5010591.5322594196
            ],
            [
              351719.36978706805,
              5010594.9149456359
            ],
            [
              351715.51511792169,
              5010599.7362179533
            ],
            [
              351712.71065061487,
              5010602.898583672
            ],
            [
              351709.42851916846,
              5010605.8899686327
            ],
            [
              351705.65209786635,
              5010609.4972420326
            ],
            [
              351702.36245599965,
              5010612.0850050775
            ],
            [
              351696.96583857195,
              5010615.620961952
            ],
            [
              351692.86367387505,
              5010618.0217536436
            ],
            [
              351689.3584809879,
              5010619.9057915779
            ],
            [
              351685.74872653052,
              5010621.5896846438
            ],
            [
              351683.23589581711,
              5010622.4452237561
            ],
            [
              351680.71741862089,
              5010622.9973128587
            ],
            [
              351677.94759097014,
              5010623.3582775621
            ],
            [
              351674.91414094885,
              5010623.4147226969
            ],
            [
              351672.08307718678,
              5010623.4674019162
            ],
            [
              351669.55332257209,
              5010623.4134292277
            ],
            [
              351667.83609079424,
              5010623.5464280192
            ],
            [
              351666.7278974356,
              5010623.7691393578
            ],
            [
              351665.62456132803,
              5010624.29489615
            ],
            [
              351663.62105681287,
              5010625.3426292874
            ],
            [
              351660.8125554432,
              5010626.6078513078
            ],
            [
              351657.29420537321,
              5010627.7848173678
            ],
            [
              351653.87743912835,
              5010628.9598932201
            ],
            [
              351650.45425276161,
              5010629.8319527134
            ],
            [
              351647.03936601355,
              5010631.1080389163
            ],
            [
              351643.11179039249,
              5010626.6340838755
            ],
            [
              351640.82201302407,
              5010628.5965514984
            ],
            [
              351644.0228395501,
              5010632.0739960438
            ],
            [
              351641.31889181217,
              5010633.5389439929
            ],
            [
              351638.91813281068,
              5010634.9982503522
            ],
            [
              351633.77726956917,
              5010638.5839550067
            ],
            [
              351629.19771484175,
              5010642.508890545
            ],
            [
              351624.43191624031,
              5010647.3471186245
            ],
            [
              351619.98214662896,
              5010652.7857380249
            ],
            [
              351616.33284122858,
              5010657.8052816242
            ],
            [
              351612.50371216319,
              5010664.0411342243
            ],
            [
              351609.15720013669,
              5010669.6219036719
            ],
            [
              351606.41557383927,
              5010674.5230932003
            ],
            [
              351604.76491601177,
              5010678.191858083
            ],
            [
              351603.70105862978,
              5010680.8392510181
            ],
            [
              351602.95056153525,
              5010683.9856202453
            ],
            [
              351602.50326104969,
              5010687.1267669518
            ],
            [
              351602.38358387147,
              5010691.575406176
            ],
            [
              351602.38804556133,
              5010697.2342790132
            ],
            [
              351602.48391189956,
              5010702.3862245297
            ],
            [
              351602.63959024841,
              5010705.2914197017
            ],
            [
              351602.87768861599,
              5010707.2068498377
            ],
            [
              351603.22223599418,
              5010709.4238543473
            ],
            [
              351603.76540274796,
              5010711.4346531136
            ],
            [
              351604.70770341402,
              5010713.1348889703
            ],
            [
              351606.79391747242,
              5010716.5320283482
            ],
            [
              351609.70205252158,
              5010720.6211905591
            ],
            [
              351611.77698130644,
              5010723.4118488058
            ],
            [
              351613.75299503567,
              5010726.3058121847
            ],
            [
              351616.359039545,
              5010730.5016407883
            ],
            [
              351618.55623551417,
              5010734.4019412687
            ],
            [
              351621.58772821369,
              5010739.7013512533
            ],
            [
              351625.17386164359,
              5010746.2839039918
            ],
            [
              351627.24896046327,
              5010750.4707902288
            ],
            [
              351628.32009548874,
              5010752.2884956952
            ],
            [
              351630.61543306458,
              5010756.0859244009
            ],
            [
              351632.38154226466,
              5010758.5796121806
            ],
            [
              351634.75965997233,
              5010761.3646282833
            ],
            [
              351636.82519874425,
              5010763.6506536538
            ],
            [
              351639.29174313229,
              5010765.7267072452
            ],
            [
              351642.77805419284,
              5010768.2890114207
            ],
            [
              351645.65423634893,
              5010770.6609975304
            ],
            [
              351648.3269262152,
              5010772.935305628
            ],
            [
              351650.39434458356,
              5010775.3223411469
            ],
            [
              351652.16154420812,
              5010777.9166343994
            ],
            [
              351655.21756646381,
              5010782.9799544765
            ],
            [
              351656.39154588303,
              5010786.2926032869
            ],
            [
              351657.06371731393,
              5010789.8170995805
            ],
            [
              351657.66328043694,
              5010794.8586261664
            ],
            [
              351658.07737381512,
              5010800.8130114926
            ],
            [
              351658.18452714343,
              5010806.5714370925
            ],
            [
              351658.12457084679,
              5010808.8313969467
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 217,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351638.93209729297,
              5010857.5352665586
            ],
            [
              351638.82388042222,
              5010857.4230547929
            ],
            [
              351642.61608786992,
              5010861.3552540224
            ],
            [
              351652.06567912136,
              5010870.4707171312
            ],
            [
              351667.87082261214,
              5010882.6643457655
            ],
            [
              351673.19836491451,
              5010888.4929410117
            ],
            [
              351676.3886484488,
              5010894.2032227721
            ],
            [
              351678.45425928757,
              5010906.3204648467
            ],
            [
              351683.89571819763,
              5010922.796308483
            ],
            [
              351684.16725152003,
              5010923.2807412948
            ],
            [
              351693.20363310259,
              5010937.0209228089
            ],
            [
              351698.67781873379,
              5010946.978224298
            ],
            [
              351708.66440938221,
              5010956.9848620873
            ],
            [
              351716.37081829744,
              5010962.4761310508
            ],
            [
              351725.86334635346,
              5010965.3134175623
            ],
            [
              351735.39238464407,
              5010970.1155832671
            ],
            [
              351741.46758215345,
              5010972.4922598051
            ],
            [
              351745.17627219338,
              5010974.5198880406
            ],
            [
              351747.96273096971,
              5010976.3025770178
            ],
            [
              351764.31524610787,
              5010984.5000421852
            ],
            [
              351758.37343570514,
              5010989.7681879876
            ],
            [
              351742.35652598069,
              5010999.51716254
            ],
            [
              351727.71960440901,
              5011009.0933248866
            ],
            [
              351711.1745903609,
              5011022.0494755907
            ],
            [
              351723.62415932439,
              5011035.8390134927
            ],
            [
              351713.00196808885,
              5011049.7957877163
            ],
            [
              351715.54149589693,
              5011059.4454375878
            ],
            [
              351726.06655430165,
              5011075.4984464319
            ],
            [
              351732.02819137141,
              5011084.3808153095
            ],
            [
              351742.66184007318,
              5011092.1763179693
            ],
            [
              351747.85669401585,
              5011103.6110949097
            ],
            [
              351758.08795500209,
              5011117.9660775056
            ],
            [
              351764.64404647792,
              5011125.0512214033
            ],
            [
              351750.22297563223,
              5011132.1336117908
            ],
            [
              351742.63844828581,
              5011133.192039513
            ],
            [
              351728.97477329732,
              5011125.2747360431
            ],
            [
              351698.95482928905,
              5011103.1455535144
            ],
            [
              351690.28806200047,
              5011097.0594353648
            ],
            [
              351651.19703978155,
              5011061.7613994814
            ],
            [
              351616.67170091328,
              5011032.1309789373
            ],
            [
              351579.93116106157,
              5010985.3798618568
            ],
            [
              351573.89293813444,
              5010977.7410308765
            ],
            [
              351551.88567063032,
              5010943.005317146
            ],
            [
              351550.13774119946,
              5010933.5073151896
            ],
            [
              351549.83391898894,
              5010931.9798954967
            ],
            [
              351549.59756664099,
              5010929.4862522744
            ],
            [
              351549.75108218443,
              5010926.8562174616
            ],
            [
              351550.39434655063,
              5010923.4082874143
            ],
            [
              351551.5326896772,
              5010919.3448728649
            ],
            [
              351553.07768519648,
              5010915.3749364978
            ],
            [
              351554.53238252207,
              5010912.013371611
            ],
            [
              351556.77516001655,
              5010907.5252241557
            ],
            [
              351558.83810687793,
              5010904.2529668342
            ],
            [
              351561.11096646049,
              5010901.3814039249
            ],
            [
              351564.08802048262,
              5010898.2942272536
            ],
            [
              351570.29064843262,
              5010892.3689109739
            ],
            [
              351575.96601072728,
              5010887.5137532325
            ],
            [
              351580.46166979021,
              5010884.4993634103
            ],
            [
              351583.36534221546,
              5010882.9296513768
            ],
            [
              351584.76998734864,
              5010882.2972414726
            ],
            [
              351586.57486446359,
              5010881.4552933192
            ],
            [
              351588.0765449811,
              5010880.6185678802
            ],
            [
              351589.97846527828,
              5010879.5727233374
            ],
            [
              351591.28042830032,
              5010878.8411788978
            ],
            [
              351592.17683724448,
              5010878.1171811372
            ],
            [
              351593.3753367574,
              5010877.2865166888
            ],
            [
              351595.17833420995,
              5010876.3435582649
            ],
            [
              351596.38247260964,
              5010875.8159247926
            ],
            [
              351597.58848283754,
              5010875.3888825197
            ],
            [
              351599.10144890466,
              5010875.1586381905
            ],
            [
              351600.71897668199,
              5010875.1285387436
            ],
            [
              351602.03143604216,
              5010875.0030708052
            ],
            [
              351602.93802453257,
              5010874.7841101382
            ],
            [
              351603.84195197717,
              5010874.464153694
            ],
            [
              351604.94340826449,
              5010873.8373856861
            ],
            [
              351606.84344886005,
              5010872.690530912
            ],
            [
              351609.24420706823,
              5010871.2312227674
            ],
            [
              351612.5953530487,
              5010869.3022510177
            ],
            [
              351616.29839098378,
              5010867.2124379734
            ],
            [
              351618.70102097798,
              5010865.8537210897
            ],
            [
              351620.2064684481,
              5010865.2194355587
            ],
            [
              351622.71930680791,
              5010864.3643136462
            ],
            [
              351625.53345428436,
              5010863.4025396146
            ],
            [
              351628.04817223659,
              5010862.6484280434
            ],
            [
              351630.35862423829,
              5010861.797072229
            ],
            [
              351633.36951128725,
              5010860.5280821184
            ],
            [
              351635.87671068549,
              5010859.3699293435
            ],
            [
              351637.87645539216,
              5010858.1201741453
            ],
            [
              351638.93209729297,
              5010857.5352665586
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 218,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355984.49517141859,
              5007990.0710692499
            ],
            [
              355988.52048984048,
              5007983.5079470072
            ],
            [
              355976.96808025189,
              5007973.1793732587
            ],
            [
              355962.8855591637,
              5007953.4809291726
            ],
            [
              355972.93122678337,
              5007945.8145588096
            ],
            [
              355989.14093758835,
              5007939.9259394305
            ],
            [
              355998.2467243509,
              5007930.2044324931
            ],
            [
              355994.61524343787,
              5007923.1084465226
            ],
            [
              356006.30989664776,
              5007917.3137655025
            ],
            [
              356004.73203008604,
              5007915.0192641681
            ],
            [
              355995.3804051007,
              5007895.8885982111
            ],
            [
              355987.41500163049,
              5007881.5396928918
            ],
            [
              355977.86486684502,
              5007864.8116546031
            ],
            [
              355966.65124627302,
              5007848.9926782474
            ],
            [
              355961.88349092129,
              5007852.7293152818
            ],
            [
              355976.880885116,
              5007877.2002460724
            ],
            [
              355969.87265863217,
              5007880.7680739062
            ],
            [
              355965.40971927502,
              5007873.5768983988
            ],
            [
              355960.78299521533,
              5007878.08894586
            ],
            [
              355954.69491939747,
              5007884.2466515563
            ],
            [
              355956.84126501938,
              5007888.0138587831
            ],
            [
              355944.24251257797,
              5007901.3908653678
            ],
            [
              355941.23079847451,
              5007897.527876718
            ],
            [
              355929.70138477685,
              5007909.9975689603
            ],
            [
              355940.29407022853,
              5007922.3529651584
            ],
            [
              355934.34616682009,
              5007929.2239363985
            ],
            [
              355925.91498486389,
              5007939.5702159544
            ],
            [
              355917.04483011045,
              5007926.3125301776
            ],
            [
              355902.81355377927,
              5007939.1678291541
            ],
            [
              355892.91531456209,
              5007951.4531256976
            ],
            [
              355882.25455108139,
              5007963.621763872
            ],
            [
              355869.00005628838,
              5007978.7509958996
            ],
            [
              355875.04909278575,
              5007984.1955027496
            ],
            [
              355857.54350112355,
              5008003.5023151468
            ],
            [
              355864.05304599681,
              5008010.3011856191
            ],
            [
              355871.70727069426,
              5008017.0912600849
            ],
            [
              355870.68181959557,
              5008018.142305309
            ],
            [
              355887.01146052481,
              5008032.6832251707
            ],
            [
              355881.63694746018,
              5008037.598720598
            ],
            [
              355888.37883784162,
              5008047.6567705423
            ],
            [
              355897.70823762444,
              5008034.1741199465
            ],
            [
              355908.28662611108,
              5008023.9902737131
            ],
            [
              355905.19449419895,
              5008020.5045581264
            ],
            [
              355911.82239845349,
              5008012.4160106452
            ],
            [
              355919.30326329457,
              5007996.8393885139
            ],
            [
              355925.71806282119,
              5007988.4747898877
            ],
            [
              355943.39421997167,
              5008002.0306102037
            ],
            [
              355950.11828743754,
              5008008.3498180751
            ],
            [
              355954.02355811856,
              5008010.3451299453
            ],
            [
              355959.63160744059,
              5008017.3560133874
            ],
            [
              355945.82146522496,
              5008028.4028320909
            ],
            [
              355953.76947321946,
              5008039.2194358585
            ],
            [
              355929.19097957603,
              5008070.0551244337
            ],
            [
              355922.90300559707,
              5008067.355508741
            ],
            [
              355917.18406295584,
              5008058.6858846806
            ],
            [
              355911.86590309016,
              5008064.5976419952
            ],
            [
              355905.61621077411,
              5008071.2354251482
            ],
            [
              355889.58447910403,
              5008089.3438116638
            ],
            [
              355898.65128766891,
              5008097.7229933934
            ],
            [
              355908.90554641973,
              5008104.5218887608
            ],
            [
              355916.98372008343,
              5008092.6703300849
            ],
            [
              355927.40599673212,
              5008102.733967294
            ],
            [
              355933.51383980789,
              5008108.3258128958
            ],
            [
              355935.78755154164,
              5008105.7199536152
            ],
            [
              355945.66050730267,
              5008092.9688416356
            ],
            [
              355949.7585904189,
              5008084.910592583
            ],
            [
              355956.97837534075,
              5008075.6964374557
            ],
            [
              355956.97837537207,
              5008075.6964374892
            ],
            [
              355962.51346232201,
              5008069.1112781782
            ],
            [
              355968.2712779502,
              5008059.6511964221
            ],
            [
              355975.67656132713,
              5008055.6265157387
            ],
            [
              355994.650756369,
              5008074.6393039161
            ],
            [
              356005.97998476133,
              5008063.3330725972
            ],
            [
              356001.00230661844,
              5008058.345269829
            ],
            [
              355988.55496798409,
              5008045.8726126086
            ],
            [
              355999.10993789567,
              5008036.6264735805
            ],
            [
              355999.22939384152,
              5008036.7216029475
            ],
            [
              356005.77904505358,
              5008028.4970689416
            ],
            [
              356020.23244975036,
              5008039.2674500402
            ],
            [
              356029.21775361436,
              5008031.272499972
            ],
            [
              356034.38178628479,
              5008035.0954107326
            ],
            [
              356039.43928109732,
              5008030.3798583886
            ],
            [
              356020.49217455304,
              5008010.0588254798
            ],
            [
              356028.74883705395,
              5008010.6567915203
            ],
            [
              356027.15607166418,
              5008008.4116600072
            ],
            [
              356037.09981712821,
              5008000.1163852764
            ],
            [
              356026.57904382539,
              5007991.9313866394
            ],
            [
              355996.26402168075,
              5007992.6457318347
            ],
            [
              355991.34539238625,
              5007994.8098367639
            ],
            [
              355989.14541778411,
              5007992.8682464911
            ],
            [
              355984.49517141859,
              5007990.0710692499
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 219,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356313.03052487382,
              5007863.0940877134
            ],
            [
              356340.37177643826,
              5007833.3695979351
            ],
            [
              356351.61882600089,
              5007821.1953567369
            ],
            [
              356368.99527763348,
              5007801.9687515479
            ],
            [
              356374.09501221211,
              5007796.3260243544
            ],
            [
              356373.73591559724,
              5007792.1766162952
            ],
            [
              356370.41977040685,
              5007772.8453647429
            ],
            [
              356367.94311380718,
              5007773.8149108719
            ],
            [
              356343.04696833092,
              5007786.4677947136
            ],
            [
              356326.55419626978,
              5007798.8720352696
            ],
            [
              356314.25565083692,
              5007811.4173679156
            ],
            [
              356298.30662921706,
              5007798.4159937818
            ],
            [
              356289.97266928857,
              5007787.4893089021
            ],
            [
              356276.5824422866,
              5007797.8042176627
            ],
            [
              356268.9719073319,
              5007810.8743917132
            ],
            [
              356254.11069133139,
              5007796.7446372565
            ],
            [
              356240.91818712588,
              5007821.2614503559
            ],
            [
              356264.80042585166,
              5007848.7944877818
            ],
            [
              356263.01636240369,
              5007852.2558301566
            ],
            [
              356257.67240945855,
              5007855.3396034855
            ],
            [
              356253.96646063076,
              5007862.6706709405
            ],
            [
              356267.3423508156,
              5007874.9276237935
            ],
            [
              356275.02902347321,
              5007866.5392351281
            ],
            [
              356285.97309995134,
              5007878.0841671126
            ],
            [
              356289.00612811983,
              5007884.4937697612
            ],
            [
              356293.75614810659,
              5007882.8426813819
            ],
            [
              356294.48083887977,
              5007883.300707926
            ],
            [
              356307.88103814569,
              5007868.6986797545
            ],
            [
              356313.03052487382,
              5007863.0940877134
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 220,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356600.55790907756,
              5007447.156845971
            ],
            [
              356614.07542455051,
              5007432.7070063241
            ],
            [
              356588.01230483857,
              5007411.5119764842
            ],
            [
              356583.74885263963,
              5007415.1910494398
            ],
            [
              356571.19681850856,
              5007400.1285900306
            ],
            [
              356566.89697491087,
              5007404.5902179796
            ],
            [
              356557.50693193969,
              5007418.1487961169
            ],
            [
              356564.36323573592,
              5007426.3405468473
            ],
            [
              356553.81034959131,
              5007435.0669697141
            ],
            [
              356549.58116390818,
              5007438.5641801935
            ],
            [
              356538.4956704416,
              5007430.6159512866
            ],
            [
              356523.252098208,
              5007441.4494696204
            ],
            [
              356531.36892155051,
              5007449.6887301989
            ],
            [
              356533.99746198923,
              5007452.8878844995
            ],
            [
              356539.21697725944,
              5007448.5993429553
            ],
            [
              356555.17271985562,
              5007466.6137786498
            ],
            [
              356551.26614712406,
              5007471.3304575933
            ],
            [
              356540.86815835838,
              5007478.2796218023
            ],
            [
              356532.35800948233,
              5007484.1498196665
            ],
            [
              356542.73624937783,
              5007499.1953697074
            ],
            [
              356544.51018555491,
              5007501.5101609221
            ],
            [
              356548.17694291193,
              5007505.8903815253
            ],
            [
              356554.24787131639,
              5007493.2972004656
            ],
            [
              356569.89679856389,
              5007510.4291107552
            ],
            [
              356576.53077245952,
              5007505.9732337417
            ],
            [
              356601.9131683929,
              5007488.0782398675
            ],
            [
              356598.76840651658,
              5007483.5101338811
            ],
            [
              356606.4919758839,
              5007473.3575607836
            ],
            [
              356610.73670739913,
              5007462.2811847553
            ],
            [
              356612.48317987222,
              5007453.9549809573
            ],
            [
              356600.55790907756,
              5007447.156845971
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 221,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356511.19959767879,
              5007565.3143467251
            ],
            [
              356520.73793354351,
              5007553.0273840232
            ],
            [
              356524.59260972257,
              5007560.8639562028
            ],
            [
              356540.90070611303,
              5007546.9682807112
            ],
            [
              356530.92127490643,
              5007535.5385898314
            ],
            [
              356531.77045112796,
              5007534.4142231755
            ],
            [
              356518.92879154353,
              5007522.0037943507
            ],
            [
              356523.66600180621,
              5007517.4672725452
            ],
            [
              356510.67682775774,
              5007503.7457729075
            ],
            [
              356511.80001851689,
              5007502.785687454
            ],
            [
              356496.15543501126,
              5007486.6467062328
            ],
            [
              356501.08415475412,
              5007482.7440827498
            ],
            [
              356498.12118949951,
              5007478.1896934519
            ],
            [
              356486.85403368343,
              5007483.0725822821
            ],
            [
              356479.3340564323,
              5007491.5164340818
            ],
            [
              356472.03747199674,
              5007482.8138133362
            ],
            [
              356470.45706926688,
              5007484.1388786649
            ],
            [
              356466.80041843589,
              5007479.7775988551
            ],
            [
              356456.0807071426,
              5007484.4005963979
            ],
            [
              356448.6339142365,
              5007476.8460876374
            ],
            [
              356445.42466535536,
              5007471.4663270777
            ],
            [
              356456.94715742773,
              5007459.3541989559
            ],
            [
              356466.41137152666,
              5007466.7157145143
            ],
            [
              356466.26834518934,
              5007459.0254314924
            ],
            [
              356474.25355887943,
              5007451.1453156136
            ],
            [
              356462.57102308341,
              5007438.7269484475
            ],
            [
              356459.02607886068,
              5007435.8575545987
            ],
            [
              356461.62446994224,
              5007432.6474164231
            ],
            [
              356450.53752868628,
              5007423.6732829092
            ],
            [
              356469.41287977208,
              5007409.4368359335
            ],
            [
              356488.31370865827,
              5007388.6550441412
            ],
            [
              356456.24038557004,
              5007353.8750755796
            ],
            [
              356448.11637525173,
              5007358.7593651768
            ],
            [
              356446.53104436566,
              5007356.6637541028
            ],
            [
              356439.37690504867,
              5007362.7332654437
            ],
            [
              356450.45789187338,
              5007376.5335222436
            ],
            [
              356412.59693417407,
              5007404.7051485581
            ],
            [
              356409.33149895474,
              5007400.6122842114
            ],
            [
              356394.61841181829,
              5007409.7727158153
            ],
            [
              356392.83988756005,
              5007407.6806976805
            ],
            [
              356378.6347307837,
              5007416.1799124163
            ],
            [
              356391.89678310259,
              5007436.3971991306
            ],
            [
              356376.08126524097,
              5007440.5551829599
            ],
            [
              356367.00748939026,
              5007444.9541145843
            ],
            [
              356353.09392049513,
              5007451.7155468408
            ],
            [
              356356.90573400812,
              5007456.5102034695
            ],
            [
              356364.22071833635,
              5007463.8618697245
            ],
            [
              356367.91014518565,
              5007462.0760036185
            ],
            [
              356368.43528060935,
              5007467.21798384
            ],
            [
              356375.11766425829,
              5007472.3885644553
            ],
            [
              356384.84621758561,
              5007487.3767379671
            ],
            [
              356393.87774799042,
              5007503.379580711
            ],
            [
              356379.78139596822,
              5007515.134840765
            ],
            [
              356379.52974748594,
              5007516.9998849919
            ],
            [
              356391.85266733542,
              5007525.2138595311
            ],
            [
              356390.8720690629,
              5007526.376951701
            ],
            [
              356399.91368798184,
              5007535.2243473185
            ],
            [
              356402.61719424586,
              5007534.3154416839
            ],
            [
              356412.73648437212,
              5007521.3782461938
            ],
            [
              356422.87682058621,
              5007512.3171448745
            ],
            [
              356434.56230333116,
              5007501.6532060476
            ],
            [
              356448.87097363145,
              5007516.5561600188
            ],
            [
              356448.86050086567,
              5007523.3478160379
            ],
            [
              356452.68301286915,
              5007528.7146987179
            ],
            [
              356463.32032807329,
              5007538.6773022376
            ],
            [
              356474.61531126598,
              5007553.2069916297
            ],
            [
              356467.87278826296,
              5007560.201386855
            ],
            [
              356453.96642675571,
              5007574.6273919893
            ],
            [
              356442.10324108606,
              5007583.4343070621
            ],
            [
              356441.55398767715,
              5007590.1522284755
            ],
            [
              356451.26787152694,
              5007594.9083891679
            ],
            [
              356473.82076593401,
              5007619.3260557987
            ],
            [
              356485.0035746012,
              5007607.7254299931
            ],
            [
              356492.96616672626,
              5007613.6534187319
            ],
            [
              356495.66494026186,
              5007615.7551517095
            ],
            [
              356502.70128685242,
              5007619.5484403512
            ],
            [
              356514.44718167029,
              5007608.6570416326
            ],
            [
              356517.19542553689,
              5007606.1087284544
            ],
            [
              356528.98867432313,
              5007595.1734215571
            ],
            [
              356527.35246467794,
              5007593.4088412682
            ],
            [
              356533.15836458973,
              5007588.9187094094
            ],
            [
              356517.04039159132,
              5007573.1547711324
            ],
            [
              356518.12558090262,
              5007572.1052926881
            ],
            [
              356511.19959767879,
              5007565.3143467251
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 222,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356028.53435388027,
              5007778.7163583199
            ],
            [
              356031.51238642127,
              5007781.6482748985
            ],
            [
              356042.30153826001,
              5007791.011764613
            ],
            [
              356051.08915165422,
              5007797.7025720272
            ],
            [
              356051.97773926303,
              5007796.2123017032
            ],
            [
              356064.93738940329,
              5007803.9396135993
            ],
            [
              356080.62021048245,
              5007812.960087182
            ],
            [
              356098.01147174131,
              5007792.9402932655
            ],
            [
              356100.23594879388,
              5007788.1829011552
            ],
            [
              356111.67065648077,
              5007796.1076724129
            ],
            [
              356119.02627671656,
              5007788.6656687846
            ],
            [
              356132.82988174754,
              5007799.5979227573
            ],
            [
              356137.2963859064,
              5007796.0009389846
            ],
            [
              356142.46589027095,
              5007802.840113149
            ],
            [
              356145.86516761343,
              5007799.4057755135
            ],
            [
              356142.4476211649,
              5007795.0706971204
            ],
            [
              356153.86857092765,
              5007775.8531957772
            ],
            [
              356156.62638375437,
              5007770.2198672527
            ],
            [
              356160.2488972593,
              5007765.7864065962
            ],
            [
              356150.58740895306,
              5007757.8921511238
            ],
            [
              356141.75247555185,
              5007750.6732608005
            ],
            [
              356135.71364836342,
              5007756.6207388761
            ],
            [
              356127.57044237055,
              5007750.5838370724
            ],
            [
              356133.45435439458,
              5007741.1369012808
            ],
            [
              356151.4859923246,
              5007752.3676925711
            ],
            [
              356154.00898751261,
              5007744.0123930098
            ],
            [
              356121.93355132901,
              5007722.3298859503
            ],
            [
              356101.11492288654,
              5007743.7062312271
            ],
            [
              356095.00087000616,
              5007747.612286333
            ],
            [
              356090.77573673852,
              5007752.12226885
            ],
            [
              356075.2789564096,
              5007737.6042613285
            ],
            [
              356070.19060616026,
              5007740.8359511821
            ],
            [
              356066.82625478436,
              5007743.9878938822
            ],
            [
              356062.21475100837,
              5007748.3082499746
            ],
            [
              356060.12470792717,
              5007750.9632011428
            ],
            [
              356069.58727366681,
              5007759.339550578
            ],
            [
              356060.80281534808,
              5007777.5489835246
            ],
            [
              356051.07782628137,
              5007770.0903224712
            ],
            [
              356038.41681466624,
              5007760.832929696
            ],
            [
              356034.39168665832,
              5007764.7445094632
            ],
            [
              356038.07740643207,
              5007767.2238698667
            ],
            [
              356035.2130231277,
              5007770.5935828704
            ],
            [
              356033.39993803715,
              5007774.5290075382
            ],
            [
              356028.53435388027,
              5007778.7163583199
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 223,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356631.67071146989,
              5007704.4085992835
            ],
            [
              356642.86016531143,
              5007685.0754314493
            ],
            [
              356651.40264355286,
              5007671.6567718135
            ],
            [
              356660.59029023594,
              5007665.3106304109
            ],
            [
              356649.35828954558,
              5007649.6156637659
            ],
            [
              356640.54559320206,
              5007641.7428103453
            ],
            [
              356630.24487512285,
              5007634.9021951398
            ],
            [
              356625.68099453632,
              5007639.382216746
            ],
            [
              356616.5398518726,
              5007654.6286916528
            ],
            [
              356612.70420816768,
              5007652.3290018635
            ],
            [
              356588.35029133334,
              5007639.8135242295
            ],
            [
              356585.28705394646,
              5007646.0812511034
            ],
            [
              356592.32008368353,
              5007652.583117675
            ],
            [
              356580.44870083354,
              5007666.7447319934
            ],
            [
              356563.70050316403,
              5007679.7657189295
            ],
            [
              356567.29857200495,
              5007684.1346122101
            ],
            [
              356576.37583249091,
              5007678.0201496435
            ],
            [
              356583.33863728034,
              5007671.035543873
            ],
            [
              356600.22847895976,
              5007655.4353638897
            ],
            [
              356607.88081973133,
              5007657.365753429
            ],
            [
              356604.75601417158,
              5007661.9669796973
            ],
            [
              356599.42713944003,
              5007668.1564226169
            ],
            [
              356596.31224325771,
              5007670.6340705957
            ],
            [
              356589.3295151671,
              5007677.5181960789
            ],
            [
              356581.76229759009,
              5007684.9785578242
            ],
            [
              356574.32217228849,
              5007690.9235984012
            ],
            [
              356568.53894894634,
              5007694.3491191622
            ],
            [
              356570.45577301324,
              5007700.0199832022
            ],
            [
              356572.96792531427,
              5007703.5280200569
            ],
            [
              356603.67848175683,
              5007684.4677956635
            ],
            [
              356606.30300535174,
              5007688.6965275807
            ],
            [
              356583.22611983161,
              5007706.2610678179
            ],
            [
              356571.87024468969,
              5007711.6862383354
            ],
            [
              356566.05702535616,
              5007715.5006493405
            ],
            [
              356569.9625104572,
              5007720.4781706426
            ],
            [
              356575.45449319255,
              5007716.1429076511
            ],
            [
              356585.15484722628,
              5007709.5468420107
            ],
            [
              356599.18131181673,
              5007701.2862746064
            ],
            [
              356602.73047220829,
              5007710.0467824675
            ],
            [
              356622.54304363497,
              5007703.2445349479
            ],
            [
              356625.25926154904,
              5007705.7831882695
            ],
            [
              356627.47519522178,
              5007702.5250977129
            ],
            [
              356631.67071146989,
              5007704.4085992835
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 224,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357265.11227238626,
              5007097.9369269656
            ],
            [
              357271.0495496912,
              5007083.9766942328
            ],
            [
              357272.24416231521,
              5007078.7930749618
            ],
            [
              357274.92305513838,
              5007071.8989627147
            ],
            [
              357272.508466119,
              5007071.5013627186
            ],
            [
              357268.35816781264,
              5007070.6682860628
            ],
            [
              357256.98386976408,
              5007067.7644548258
            ],
            [
              357247.93998677243,
              5007063.3758742157
            ],
            [
              357239.83175124257,
              5007057.7617868232
            ],
            [
              357229.59010006778,
              5007069.1398410052
            ],
            [
              357227.08918210346,
              5007071.0141847469
            ],
            [
              357222.34143944457,
              5007072.5765101155
            ],
            [
              357212.49375226238,
              5007079.5584026659
            ],
            [
              357209.60515959008,
              5007079.6121223895
            ],
            [
              357203.64456015028,
              5007082.5531426128
            ],
            [
              357197.25521338294,
              5007087.8017289191
            ],
            [
              357193.10820081993,
              5007089.7643599818
            ],
            [
              357244.6018310049,
              5007139.0648107948
            ],
            [
              357245.68092188874,
              5007136.7910259422
            ],
            [
              357248.68231636821,
              5007131.5738047604
            ],
            [
              357251.31557802175,
              5007125.073052546
            ],
            [
              357256.56166459952,
              5007115.5989386709
            ],
            [
              357257.79146019043,
              5007112.3071756708
            ],
            [
              357260.53214096389,
              5007106.1943528419
            ],
            [
              357265.11227238626,
              5007097.9369269656
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 225,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357177.47181288683,
              5007282.8098308966
            ],
            [
              357186.78215654596,
              5007279.0715270052
            ],
            [
              357192.5854614742,
              5007276.1906826897
            ],
            [
              357203.51472739695,
              5007266.2837435836
            ],
            [
              357209.37167859694,
              5007261.5533151655
            ],
            [
              357214.32862142642,
              5007255.7833100166
            ],
            [
              357217.10834791051,
              5007253.7069387855
            ],
            [
              357219.09072711499,
              5007250.6336445007
            ],
            [
              357221.08034441178,
              5007246.3272804711
            ],
            [
              357225.4933622462,
              5007225.3061735705
            ],
            [
              357228.0261205245,
              5007212.3188572647
            ],
            [
              357229.64530452859,
              5007199.8971964419
            ],
            [
              357230.73961456073,
              5007192.4384320816
            ],
            [
              357232.30459394335,
              5007188.9761973415
            ],
            [
              357233.44430770882,
              5007183.6411398919
            ],
            [
              357238.04447833897,
              5007170.5273493752
            ],
            [
              357239.79478664225,
              5007162.6580852689
            ],
            [
              357240.89317714353,
              5007156.0540330866
            ],
            [
              357242.92005144391,
              5007153.6121682869
            ],
            [
              357243.86524783698,
              5007150.7096284637
            ],
            [
              357246.28909355978,
              5007146.6206306741
            ],
            [
              357246.25390930794,
              5007144.7287385631
            ],
            [
              357245.61644575809,
              5007142.8480827454
            ],
            [
              357243.52274013247,
              5007141.3385956585
            ],
            [
              357233.90145554417,
              5007149.5108620757
            ],
            [
              357229.05373310106,
              5007147.2850827435
            ],
            [
              357225.85128699563,
              5007143.2337466776
            ],
            [
              357221.46281248034,
              5007141.3414020529
            ],
            [
              357216.63862982235,
              5007140.3889137143
            ],
            [
              357212.52847842686,
              5007140.4653517324
            ],
            [
              357207.07974588074,
              5007143.2879275568
            ],
            [
              357199.55604655179,
              5007145.5662458278
            ],
            [
              357199.54330299731,
              5007144.8810122199
            ],
            [
              357194.19721470418,
              5007140.4500457207
            ],
            [
              357169.56431242859,
              5007116.9893045472
            ],
            [
              357160.54688627418,
              5007112.7550756345
            ],
            [
              357154.35011494305,
              5007109.8453152906
            ],
            [
              357163.36108581495,
              5007132.0535536259
            ],
            [
              357130.42066165328,
              5007146.5674392013
            ],
            [
              357118.78150666697,
              5007151.0234973431
            ],
            [
              357105.65160116117,
              5007156.7851796215
            ],
            [
              357083.08169951447,
              5007165.2949878527
            ],
            [
              357072.49926930881,
              5007173.2921868628
            ],
            [
              357078.8347607989,
              5007186.3926135022
            ],
            [
              357102.82743627694,
              5007213.9854977876
            ],
            [
              357107.10001546849,
              5007216.9237465495
            ],
            [
              357102.63477593363,
              5007224.6452061078
            ],
            [
              357099.65073972818,
              5007229.8053135742
            ],
            [
              357099.18173602608,
              5007237.8616932202
            ],
            [
              357098.48997029645,
              5007249.7446060777
            ],
            [
              357097.02155815356,
              5007250.1256892802
            ],
            [
              357094.7497123098,
              5007241.371679699
            ],
            [
              357092.41487542371,
              5007239.0515574822
            ],
            [
              357073.46131949982,
              5007225.2929373691
            ],
            [
              357069.46551450569,
              5007222.8232500078
            ],
            [
              357064.88462382089,
              5007222.0864807842
            ],
            [
              357055.97154826549,
              5007220.6529430058
            ],
            [
              357035.3573030961,
              5007229.015712521
            ],
            [
              357023.0347916758,
              5007205.1791329207
            ],
            [
              357003.8633818556,
              5007215.7022747127
            ],
            [
              357007.91604942578,
              5007223.8437488349
            ],
            [
              356991.39263299905,
              5007238.7936697053
            ],
            [
              356988.26393710886,
              5007234.5832065158
            ],
            [
              356986.02786062373,
              5007236.1928470507
            ],
            [
              356979.27023686672,
              5007243.1043909229
            ],
            [
              356968.31007679802,
              5007255.0941484757
            ],
            [
              356945.30384164635,
              5007278.7672717096
            ],
            [
              356924.65763832087,
              5007301.5494209835
            ],
            [
              356922.34947436076,
              5007303.688800849
            ],
            [
              356906.42394846334,
              5007318.3490058919
            ],
            [
              356933.37354364787,
              5007335.5944606913
            ],
            [
              356919.69622917305,
              5007356.9680988947
            ],
            [
              356914.41884562484,
              5007362.2698442787
            ],
            [
              356912.38121638127,
              5007360.2415781999
            ],
            [
              356902.55418570613,
              5007339.4801152106
            ],
            [
              356887.10834134778,
              5007347.1928464323
            ],
            [
              356880.82266244671,
              5007349.7443265831
            ],
            [
              356884.02227962069,
              5007358.9911657879
            ],
            [
              356883.84892146534,
              5007362.7679912448
            ],
            [
              356878.49467060558,
              5007369.5626804074
            ],
            [
              356872.89017214143,
              5007375.9968263907
            ],
            [
              356867.46353832528,
              5007381.4073137129
            ],
            [
              356857.0384003658,
              5007368.4475224037
            ],
            [
              356855.79934802424,
              5007370.0701700812
            ],
            [
              356843.00497702463,
              5007382.7363603879
            ],
            [
              356818.63192629983,
              5007406.6374538075
            ],
            [
              356838.51152379753,
              5007408.3497580765
            ],
            [
              356857.05906137702,
              5007408.004808085
            ],
            [
              356840.05979002378,
              5007462.9109704867
            ],
            [
              356817.35706578766,
              5007441.3151080515
            ],
            [
              356782.50482260744,
              5007425.4340534238
            ],
            [
              356760.90976827726,
              5007447.1825118344
            ],
            [
              356762.3462811113,
              5007448.6914735846
            ],
            [
              356751.26584706362,
              5007458.7515392173
            ],
            [
              356734.13674571388,
              5007474.0430843653
            ],
            [
              356745.83971849055,
              5007485.9330737749
            ],
            [
              356729.36013817129,
              5007501.7244221279
            ],
            [
              356735.79134524701,
              5007510.1790635409
            ],
            [
              356723.14882207109,
              5007518.8604765208
            ],
            [
              356710.64852991758,
              5007528.3070677249
            ],
            [
              356701.80428805662,
              5007534.9163668519
            ],
            [
              356695.05761776603,
              5007526.2957042567
            ],
            [
              356692.88185288006,
              5007528.1839273507
            ],
            [
              356707.5166056712,
              5007551.7416937081
            ],
            [
              356693.46028663352,
              5007555.9061517389
            ],
            [
              356685.54516083404,
              5007542.86394173
            ],
            [
              356682.27308976447,
              5007534.2021354735
            ],
            [
              356681.82613229804,
              5007534.473987367
            ],
            [
              356676.40081256971,
              5007538.7980152247
            ],
            [
              356680.74366396567,
              5007545.1159335459
            ],
            [
              356674.36590754992,
              5007548.8671412896
            ],
            [
              356667.67729353503,
              5007554.1105242409
            ],
            [
              356661.28277108475,
              5007561.3959656386
            ],
            [
              356655.03424174181,
              5007555.881345978
            ],
            [
              356643.38471203414,
              5007562.8806119878
            ],
            [
              356629.37990317284,
              5007580.929476331
            ],
            [
              356636.00856805785,
              5007584.1240761261
            ],
            [
              356642.48666212626,
              5007588.6964497855
            ],
            [
              356650.38069598784,
              5007595.4271177286
            ],
            [
              356646.86034724797,
              5007601.1034690477
            ],
            [
              356654.98645620357,
              5007607.2634420712
            ],
            [
              356666.3721238568,
              5007610.2881209785
            ],
            [
              356662.73072474025,
              5007615.1258703917
            ],
            [
              356682.7904121633,
              5007613.6127297506
            ],
            [
              356698.79070461827,
              5007608.479888076
            ],
            [
              356710.50741205766,
              5007602.2370219305
            ],
            [
              356726.86112100055,
              5007593.2301804675
            ],
            [
              356740.16078049707,
              5007588.0735913217
            ],
            [
              356755.94452572236,
              5007584.4328513965
            ],
            [
              356771.25698676385,
              5007579.4620104656
            ],
            [
              356793.23174913792,
              5007572.5820938759
            ],
            [
              356813.94336167612,
              5007563.4388619792
            ],
            [
              356827.04056762095,
              5007559.4018229367
            ],
            [
              356856.25173891598,
              5007545.4697747752
            ],
            [
              356865.42627534171,
              5007534.5881459815
            ],
            [
              356870.07908707223,
              5007520.6665828982
            ],
            [
              356868.12350672256,
              5007498.0052420208
            ],
            [
              356855.06606539339,
              5007480.1732799318
            ],
            [
              356906.19639452285,
              5007432.3617308903
            ],
            [
              356929.75404141541,
              5007403.4146305751
            ],
            [
              356937.34148456022,
              5007396.1899368186
            ],
            [
              356944.07606101519,
              5007388.1156720044
            ],
            [
              356953.25528019347,
              5007375.2819037242
            ],
            [
              356964.23650887975,
              5007360.9889224302
            ],
            [
              356967.20967443415,
              5007357.2608971642
            ],
            [
              356982.14133098751,
              5007340.4671420511
            ],
            [
              356989.17146569432,
              5007330.0392693207
            ],
            [
              356990.91916115145,
              5007326.8593552103
            ],
            [
              356998.15181619918,
              5007329.9238770697
            ],
            [
              357002.3357223061,
              5007330.0524532488
            ],
            [
              357010.62602963863,
              5007328.9179181391
            ],
            [
              357013.98133032193,
              5007328.907104685
            ],
            [
              357015.84777829883,
              5007329.3367570862
            ],
            [
              357026.68030456069,
              5007331.1165426793
            ],
            [
              357030.46047665802,
              5007330.8169309758
            ],
            [
              357037.17978323583,
              5007330.2333136741
            ],
            [
              357048.79676608817,
              5007325.6984031647
            ],
            [
              357052.75454476028,
              5007324.6692803949
            ],
            [
              357061.79831845575,
              5007322.4744935697
            ],
            [
              357070.66074455687,
              5007319.7533252053
            ],
            [
              357079.70089209575,
              5007314.0851690499
            ],
            [
              357083.36655981437,
              5007311.1508014286
            ],
            [
              357086.52657252591,
              5007309.207148293
            ],
            [
              357087.81200333149,
              5007307.4789879117
            ],
            [
              357091.69222570886,
              5007295.2448277874
            ],
            [
              357096.81747834414,
              5007291.6132105356
            ],
            [
              357100.4101838533,
              5007288.2954730634
            ],
            [
              357110.60733727872,
              5007281.2050873647
            ],
            [
              357113.0908137114,
              5007280.8950673966
            ],
            [
              357115.76823246526,
              5007280.8656754754
            ],
            [
              357123.89876456634,
              5007286.1814967841
            ],
            [
              357140.5532994665,
              5007293.750340811
            ],
            [
              357142.80323035363,
              5007297.7260925239
            ],
            [
              357150.52741449384,
              5007298.6827855241
            ],
            [
              357154.07945900661,
              5007300.2452643216
            ],
            [
              357167.41316629347,
              5007290.270118068
            ],
            [
              357177.47181288683,
              5007282.8098308966
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 226,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357072.24935817771,
              5006916.5205495907
            ],
            [
              357087.22281572566,
              5006894.0525822574
            ],
            [
              357068.37831952423,
              5006881.596929743
            ],
            [
              357055.78982076183,
              5006873.9564329172
            ],
            [
              357059.28652799729,
              5006863.5087711904
            ],
            [
              357060.83243646089,
              5006857.6261371635
            ],
            [
              357058.90427880344,
              5006858.3804996861
            ],
            [
              357053.75000267598,
              5006860.7029778883
            ],
            [
              357048.26552870858,
              5006862.8759823069
            ],
            [
              357042.30090681597,
              5006865.8153760303
            ],
            [
              357034.54936370702,
              5006868.494996652
            ],
            [
              357023.01515930513,
              5006873.5422670431
            ],
            [
              357015.57853467733,
              5006876.8342047445
            ],
            [
              356994.06545958243,
              5006887.1366027072
            ],
            [
              356996.3441772908,
              5006890.4959165715
            ],
            [
              357016.0603567572,
              5006916.5950706908
            ],
            [
              357026.16874801368,
              5006929.9759810446
            ],
            [
              357028.62402919488,
              5006922.0459283749
            ],
            [
              357030.34241117135,
              5006922.0139720719
            ],
            [
              357031.57017450134,
              5006921.3916327599
            ],
            [
              357035.93151355162,
              5006922.3741612192
            ],
            [
              357043.65232737234,
              5006928.0173977893
            ],
            [
              357055.16419332678,
              5006940.1019805372
            ],
            [
              357061.52254953427,
              5006930.3705873471
            ],
            [
              357064.3685530207,
              5006926.6959383162
            ],
            [
              357072.24935817771,
              5006916.5205495907
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 227,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357737.86232190026,
              5006754.9625623515
            ],
            [
              357744.69240255578,
              5006745.4446360422
            ],
            [
              357753.17899173498,
              5006730.802560633
            ],
            [
              357759.0622510984,
              5006713.1846927768
            ],
            [
              357761.79368393664,
              5006713.8696851823
            ],
            [
              357763.27726245107,
              5006709.9652794581
            ],
            [
              357737.58206847386,
              5006700.312765874
            ],
            [
              357726.0213305442,
              5006701.5543016912
            ],
            [
              357713.05826373462,
              5006699.1289369743
            ],
            [
              357708.23892656114,
              5006696.6924468521
            ],
            [
              357703.55785817612,
              5006701.6913321828
            ],
            [
              357704.99801586359,
              5006703.629292191
            ],
            [
              357702.76067502325,
              5006708.517397359
            ],
            [
              357696.5313975843,
              5006716.8918387853
            ],
            [
              357686.67491998838,
              5006730.2525913808
            ],
            [
              357680.39226477692,
              5006724.8167669354
            ],
            [
              357674.1328711441,
              5006720.6318256985
            ],
            [
              357671.44307984813,
              5006723.2626366457
            ],
            [
              357673.81880625332,
              5006724.7825847818
            ],
            [
              357667.92346226826,
              5006731.6265904596
            ],
            [
              357663.54580682615,
              5006736.0055317869
            ],
            [
              357660.12928595732,
              5006740.6849317225
            ],
            [
              357656.74990439747,
              5006743.1437786901
            ],
            [
              357649.31072350527,
              5006750.7852061335
            ],
            [
              357675.62543971097,
              5006769.8234195122
            ],
            [
              357679.81816979899,
              5006764.6213874109
            ],
            [
              357688.49388262682,
              5006771.6138317371
            ],
            [
              357695.73432417546,
              5006775.0763859656
            ],
            [
              357702.55682120199,
              5006777.6096446086
            ],
            [
              357712.09238409792,
              5006783.3688334133
            ],
            [
              357714.78098312556,
              5006782.3638396477
            ],
            [
              357725.0045096557,
              5006775.4886800824
            ],
            [
              357728.58322874323,
              5006770.965412857
            ],
            [
              357737.86232190026,
              5006754.9625623515
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 228,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357667.49471915915,
              5006155.881029496
            ],
            [
              357679.92864679935,
              5006132.8744373405
            ],
            [
              357683.22314136103,
              5006130.2737900931
            ],
            [
              357689.56918847415,
              5006119.1963804476
            ],
            [
              357700.82364419004,
              5006106.0228858246
            ],
            [
              357695.38747286255,
              5006101.3125127172
            ],
            [
              357688.46019581362,
              5006093.7788191354
            ],
            [
              357680.31385766482,
              5006102.694269713
            ],
            [
              357665.62288848776,
              5006092.7782566054
            ],
            [
              357654.91579613468,
              5006082.642867147
            ],
            [
              357648.85110905947,
              5006084.7053013779
            ],
            [
              357642.6932688207,
              5006092.2790968083
            ],
            [
              357631.72873120435,
              5006081.5480339928
            ],
            [
              357631.18884960091,
              5006079.8626945764
            ],
            [
              357624.99083882797,
              5006084.394051888
            ],
            [
              357604.48104920302,
              5006109.5486565689
            ],
            [
              357600.86142564518,
              5006114.8555970173
            ],
            [
              357598.23185456602,
              5006122.1638042936
            ],
            [
              357596.98822432128,
              5006140.3324747011
            ],
            [
              357595.93475230254,
              5006146.4379259907
            ],
            [
              357590.20471399795,
              5006156.3010695335
            ],
            [
              357586.02500654024,
              5006155.2484521121
            ],
            [
              357583.78344419721,
              5006159.963710201
            ],
            [
              357577.62785911321,
              5006182.3981938874
            ],
            [
              357589.32747506798,
              5006189.9919624738
            ],
            [
              357620.12976281886,
              5006209.7920410549
            ],
            [
              357623.4859551902,
              5006203.0831180029
            ],
            [
              357629.22155914857,
              5006193.1674747607
            ],
            [
              357631.08124144649,
              5006189.400353943
            ],
            [
              357624.73624734784,
              5006184.9849708974
            ],
            [
              357634.91379338573,
              5006174.1781494832
            ],
            [
              357641.92017990543,
              5006178.8794610919
            ],
            [
              357646.82430068526,
              5006169.9601217341
            ],
            [
              357650.08761208714,
              5006165.8429215411
            ],
            [
              357653.42594367702,
              5006161.6310712388
            ],
            [
              357657.31121478527,
              5006156.0468804501
            ],
            [
              357658.54210440791,
              5006149.2360582929
            ],
            [
              357664.72907599603,
              5006154.7509515397
            ],
            [
              357667.49471915915,
              5006155.881029496
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 229,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358843.11861666123,
              5005736.0187654402
            ],
            [
              358834.9538849435,
              5005732.0127095403
            ],
            [
              358834.90968219942,
              5005729.6353557622
            ],
            [
              358826.30050173984,
              5005724.8728584582
            ],
            [
              358815.17994764214,
              5005741.1091970783
            ],
            [
              358835.79782136233,
              5005762.965363319
            ],
            [
              358834.96287180425,
              5005768.6190578649
            ],
            [
              358835.064757502,
              5005774.0987361027
            ],
            [
              358838.05778326938,
              5005780.97066506
            ],
            [
              358831.89107712195,
              5005784.278414323
            ],
            [
              358799.50758936396,
              5005792.2081159111
            ],
            [
              358789.41660538933,
              5005797.7769155763
            ],
            [
              358796.50226864073,
              5005815.3916632207
            ],
            [
              358847.75765761052,
              5005801.2926974157
            ],
            [
              358861.06653425342,
              5005806.0711424518
            ],
            [
              358875.05222760112,
              5005813.7756485259
            ],
            [
              358898.18709286355,
              5005824.6900778245
            ],
            [
              358901.30346407229,
              5005830.3429859895
            ],
            [
              358906.76982691122,
              5005837.804404946
            ],
            [
              358915.60773656861,
              5005845.0126580866
            ],
            [
              358917.1994464756,
              5005843.0610912582
            ],
            [
              358933.07829016977,
              5005812.5722446404
            ],
            [
              358922.17046629061,
              5005811.8592074262
            ],
            [
              358909.17849687079,
              5005799.5063648727
            ],
            [
              358887.73395914439,
              5005788.4141822904
            ],
            [
              358880.99948703061,
              5005785.041955323
            ],
            [
              358884.47233909048,
              5005766.0925727822
            ],
            [
              358880.80563952838,
              5005757.0359954927
            ],
            [
              358882.27667871857,
              5005748.6310444856
            ],
            [
              358851.33698440279,
              5005724.0213731891
            ],
            [
              358843.11861666123,
              5005736.0187654402
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 230,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359113.01087001426,
              5005209.2225381527
            ],
            [
              359110.08627328282,
              5005200.6844554655
            ],
            [
              359109.56392885681,
              5005199.2732397523
            ],
            [
              359116.24129030091,
              5005193.8435434122
            ],
            [
              359115.08156956936,
              5005189.7988091512
            ],
            [
              359109.33767382993,
              5005191.3218829138
            ],
            [
              359107.16930220759,
              5005184.9711408811
            ],
            [
              359103.14250234584,
              5005186.1653630584
            ],
            [
              359099.11570244242,
              5005187.3595852628
            ],
            [
              359084.25520893949,
              5005194.3481373852
            ],
            [
              359083.60097478656,
              5005195.6764252195
            ],
            [
              359078.34113982908,
              5005203.1445310647
            ],
            [
              359070.59537477238,
              5005208.1987437904
            ],
            [
              359075.184065871,
              5005216.8508685511
            ],
            [
              359076.63392840762,
              5005222.191591504
            ],
            [
              359079.00462669454,
              5005227.4500172036
            ],
            [
              359089.19254243077,
              5005223.616022435
            ],
            [
              359098.34761291585,
              5005214.7004290838
            ],
            [
              359101.746919428,
              5005212.4334768765
            ],
            [
              359111.96165437065,
              5005230.0302714854
            ],
            [
              359121.58723008801,
              5005246.6121270619
            ],
            [
              359127.93247285124,
              5005242.7314628158
            ],
            [
              359134.27968830627,
              5005251.4025881039
            ],
            [
              359138.51676918933,
              5005247.8624258814
            ],
            [
              359136.32576881844,
              5005244.1609710641
            ],
            [
              359145.44386974623,
              5005236.1532053258
            ],
            [
              359141.35035352182,
              5005229.7478982564
            ],
            [
              359158.03979561268,
              5005228.3469584025
            ],
            [
              359150.33589645982,
              5005216.6075869668
            ],
            [
              359140.28995789163,
              5005208.955351945
            ],
            [
              359131.63270538539,
              5005211.5236654961
            ],
            [
              359124.97706335637,
              5005202.8081666278
            ],
            [
              359113.01087001426,
              5005209.2225381527
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 231,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359117.4279511802,
              5005052.6834892835
            ],
            [
              359122.34463279945,
              5005044.0369521175
            ],
            [
              359163.17176969053,
              5005055.0797049375
            ],
            [
              359164.69418244722,
              5005046.383977049
            ],
            [
              359151.78190217545,
              5005040.0656790044
            ],
            [
              359141.82495729096,
              5005037.4426386738
            ],
            [
              359129.94977498573,
              5005032.3591321707
            ],
            [
              359131.47453700838,
              5005026.5433661724
            ],
            [
              359144.66971616884,
              5005031.2903085174
            ],
            [
              359148.01986096147,
              5005015.5736669675
            ],
            [
              359157.62012493878,
              5005014.9191070432
            ],
            [
              359153.71924244694,
              5005034.6818235498
            ],
            [
              359172.7869631591,
              5005045.6870509144
            ],
            [
              359173.20052930521,
              5005035.0904504666
            ],
            [
              359172.94971177424,
              5005027.5720104165
            ],
            [
              359173.73725868581,
              5005005.5902972631
            ],
            [
              359139.22803841199,
              5005001.9361842368
            ],
            [
              359141.55367358791,
              5004995.5043964898
            ],
            [
              359144.19607435178,
              5004988.1965607749
            ],
            [
              359133.17743021942,
              5004982.2177805416
            ],
            [
              359124.07893447898,
              5004982.3869313495
            ],
            [
              359112.14481449634,
              5004979.5406375146
            ],
            [
              359102.21895841666,
              5004987.535690004
            ],
            [
              359092.06006437872,
              5004992.7263503913
            ],
            [
              359086.1375692478,
              5004990.3355583409
            ],
            [
              359094.26115898963,
              5004970.2117198259
            ],
            [
              359080.01087692939,
              5004965.7182323951
            ],
            [
              359077.40396497323,
              5004964.32658689
            ],
            [
              359064.61938599875,
              5004958.5840315511
            ],
            [
              359056.51656036137,
              5004976.6232504789
            ],
            [
              359065.71682410117,
              5004980.2730364352
            ],
            [
              359061.869307847,
              5004997.01715462
            ],
            [
              359060.94278338732,
              5005003.7175659584
            ],
            [
              359078.85785152327,
              5005018.1290586153
            ],
            [
              359077.09886151011,
              5005025.0000276845
            ],
            [
              359090.55588424456,
              5005033.877136698
            ],
            [
              359092.80363585945,
              5005030.4697161922
            ],
            [
              359092.71125732339,
              5005047.9181437027
            ],
            [
              359099.97548299102,
              5005049.1391602913
            ],
            [
              359109.8414326557,
              5005050.7974909199
            ],
            [
              359117.4279511802,
              5005052.6834892835
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 232,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359381.20980108,
              5005127.4460460786
            ],
            [
              359391.47098881524,
              5005120.2346016718
            ],
            [
              359377.13036129525,
              5005111.9575038217
            ],
            [
              359364.14143969695,
              5005106.7758354396
            ],
            [
              359363.33844254992,
              5005104.571658765
            ],
            [
              359361.71776608034,
              5005103.7125545517
            ],
            [
              359349.57989909168,
              5005098.3148986204
            ],
            [
              359335.44231427397,
              5005093.260724443
            ],
            [
              359329.56207185722,
              5005091.112246885
            ],
            [
              359314.28600804223,
              5005081.2472709222
            ],
            [
              359304.99233200477,
              5005077.8367390754
            ],
            [
              359295.2436819679,
              5005076.1491068341
            ],
            [
              359282.01038009999,
              5005071.9237583997
            ],
            [
              359283.66279093176,
              5005068.5902091768
            ],
            [
              359284.3364373091,
              5005063.7750696493
            ],
            [
              359284.57380977581,
              5005059.2281402675
            ],
            [
              359284.89734464849,
              5005057.164962789
            ],
            [
              359284.93405275419,
              5005055.2226755572
            ],
            [
              359286.18705310632,
              5005051.9062334262
            ],
            [
              359286.83313283924,
              5005050.0901029436
            ],
            [
              359289.95934906771,
              5005045.5161273358
            ],
            [
              359290.34821090457,
              5005044.0193041842
            ],
            [
              359295.13125252206,
              5005032.5459488984
            ],
            [
              359289.49182898487,
              5005030.0518605402
            ],
            [
              359281.90699476289,
              5005026.6292234659
            ],
            [
              359272.32164387655,
              5005022.3859415352
            ],
            [
              359273.82140264596,
              5005017.2706088601
            ],
            [
              359275.54641783837,
              5005012.2443950865
            ],
            [
              359264.57269838458,
              5005008.2923218198
            ],
            [
              359266.37956199487,
              5005000.8430980463
            ],
            [
              359264.94542620995,
              5004991.0672706589
            ],
            [
              359265.69464004372,
              5004982.5624982519
            ],
            [
              359265.59419295209,
              5004973.2536771372
            ],
            [
              359271.09985286376,
              5004968.9257402513
            ],
            [
              359276.99324026617,
              5004958.5154098384
            ],
            [
              359270.30326333269,
              5004955.9516936131
            ],
            [
              359277.42505971278,
              5004942.7293907162
            ],
            [
              359285.75693940721,
              5004946.0694086049
            ],
            [
              359286.61378863041,
              5004943.265206865
            ],
            [
              359288.16215529351,
              5004939.8214815315
            ],
            [
              359300.05283819622,
              5004931.5874677002
            ],
            [
              359303.43573994341,
              5004923.4956719754
            ],
            [
              359306.48947760178,
              5004916.6256809821
            ],
            [
              359307.9461005542,
              5004913.9633097183
            ],
            [
              359316.92307046085,
              5004907.4755719304
            ],
            [
              359327.36585706641,
              5004896.153068006
            ],
            [
              359321.34241893486,
              5004890.2218070775
            ],
            [
              359314.34805708489,
              5004885.58987909
            ],
            [
              359303.93456930411,
              5004880.7040371634
            ],
            [
              359296.81801974331,
              5004873.7727176389
            ],
            [
              359296.21381034324,
              5004872.6162729589
            ],
            [
              359303.01930623321,
              5004865.1277813995
            ],
            [
              359309.46897216409,
              5004857.3654184658
            ],
            [
              359303.10828913783,
              5004854.8193238955
            ],
            [
              359297.13204520365,
              5004854.0890361611
            ],
            [
              359291.57786877704,
              5004853.4210586185
            ],
            [
              359284.15062507335,
              5004851.0855033407
            ],
            [
              359272.60174428689,
              5004844.9197957534
            ],
            [
              359263.48768753553,
              5004842.2712727282
            ],
            [
              359262.36308316817,
              5004842.1519364351
            ],
            [
              359260.95800733677,
              5004842.0378142949
            ],
            [
              359254.48178902955,
              5004840.8260392249
            ],
            [
              359248.35097601806,
              5004839.3273910051
            ],
            [
              359241.59168039676,
              5004837.9806700833
            ],
            [
              359237.49440319819,
              5004836.0592143545
            ],
            [
              359239.26838707662,
              5004830.6743325274
            ],
            [
              359231.71907535201,
              5004828.1873035831
            ],
            [
              359224.87370500906,
              5004826.8532587364
            ],
            [
              359223.03638387675,
              5004826.4898753716
            ],
            [
              359220.01639552374,
              5004825.8925853707
            ],
            [
              359218.33881113084,
              5004825.5607945593
            ],
            [
              359214.71465858881,
              5004825.6281697918
            ],
            [
              359210.10763954784,
              5004825.7138171205
            ],
            [
              359207.31696056033,
              5004827.8843432982
            ],
            [
              359197.44580655399,
              5004823.6764273904
            ],
            [
              359189.83359114901,
              5004811.5468841111
            ],
            [
              359174.50658511725,
              5004833.2221948737
            ],
            [
              359182.04410520103,
              5004838.5521114999
            ],
            [
              359180.50398765132,
              5004840.7731637489
            ],
            [
              359178.96387010952,
              5004842.9942160193
            ],
            [
              359183.18240578467,
              5004846.4681627564
            ],
            [
              359168.17788910371,
              5004864.1421089387
            ],
            [
              359162.71663881739,
              5004858.4634047132
            ],
            [
              359160.92854931252,
              5004860.4400719935
            ],
            [
              359149.02282387723,
              5004869.8837609217
            ],
            [
              359139.22730061848,
              5004881.6554566147
            ],
            [
              359123.4949754916,
              5004868.1895628758
            ],
            [
              359116.93689189357,
              5004870.2952862699
            ],
            [
              359115.89321890584,
              5004875.5221261103
            ],
            [
              359113.63678589062,
              5004879.6343286475
            ],
            [
              359111.20110756502,
              5004886.2768713403
            ],
            [
              359111.77352292411,
              5004892.0402662382
            ],
            [
              359110.66991632024,
              5004898.9824308548
            ],
            [
              359120.14984587178,
              5004904.8135330388
            ],
            [
              359139.06715992157,
              5004914.7744782539
            ],
            [
              359141.18094893516,
              5004920.167334849
            ],
            [
              359141.76722397463,
              5004924.7620792286
            ],
            [
              359150.15772599162,
              5004930.5543680741
            ],
            [
              359158.10509117745,
              5004919.0421006074
            ],
            [
              359175.87984811299,
              5004930.7934074719
            ],
            [
              359175.49537261494,
              5004902.7532781148
            ],
            [
              359192.28189302463,
              5004906.8623996181
            ],
            [
              359197.56663494901,
              5004919.2312653428
            ],
            [
              359205.72643236635,
              5004924.1190686515
            ],
            [
              359208.50853008858,
              5004920.8406621851
            ],
            [
              359220.77385800821,
              5004935.8241702523
            ],
            [
              359230.26398019219,
              5004945.4171976848
            ],
            [
              359243.38319244055,
              5004949.4629756454
            ],
            [
              359242.7162375506,
              5004954.7453803681
            ],
            [
              359240.06082231947,
              5004959.7110788813
            ],
            [
              359240.14502302959,
              5004964.2401869809
            ],
            [
              359241.60630002496,
              5004970.237936629
            ],
            [
              359207.92802307691,
              5004954.3481480582
            ],
            [
              359205.65795392887,
              5004961.4315870376
            ],
            [
              359193.57363748783,
              5005014.8389373766
            ],
            [
              359193.80420809909,
              5005027.2410838567
            ],
            [
              359195.13234312279,
              5005038.8371379012
            ],
            [
              359188.09513467777,
              5005039.8539967816
            ],
            [
              359188.6149139058,
              5005067.8122217944
            ],
            [
              359185.06444177835,
              5005089.5414160602
            ],
            [
              359179.16676528711,
              5005091.1630460592
            ],
            [
              359181.36272875027,
              5005136.1216443647
            ],
            [
              359175.79376787879,
              5005136.2251798185
            ],
            [
              359168.4763671346,
              5005141.4937517727
            ],
            [
              359164.40261370857,
              5005147.8409992
            ],
            [
              359165.31446668954,
              5005151.0754035879
            ],
            [
              359163.86710233672,
              5005186.8634895431
            ],
            [
              359167.60157335404,
              5005199.9657694083
            ],
            [
              359176.66782766051,
              5005193.8299050797
            ],
            [
              359183.44030778919,
              5005211.1887167459
            ],
            [
              359197.1308253514,
              5005201.44165109
            ],
            [
              359206.24980057939,
              5005194.8030379955
            ],
            [
              359220.42028093856,
              5005187.2788448064
            ],
            [
              359224.99510947795,
              5005190.0397773189
            ],
            [
              359229.03074892302,
              5005201.6879320992
            ],
            [
              359236.95729112794,
              5005200.8300372995
            ],
            [
              359239.32733079512,
              5005199.1858581612
            ],
            [
              359248.93781126622,
              5005206.7761772946
            ],
            [
              359258.40659439354,
              5005193.1674031317
            ],
            [
              359261.68708409119,
              5005214.7685774798
            ],
            [
              359266.28997019574,
              5005221.0147890095
            ],
            [
              359280.18814701389,
              5005219.5038540028
            ],
            [
              359289.17567690148,
              5005223.5464011412
            ],
            [
              359308.92354261235,
              5005226.8994319597
            ],
            [
              359310.10524836514,
              5005232.5167501634
            ],
            [
              359341.559473634,
              5005228.3904715963
            ],
            [
              359322.94550600107,
              5005220.0447641807
            ],
            [
              359313.85030969331,
              5005217.3978656735
            ],
            [
              359305.80457047123,
              5005215.3100472167
            ],
            [
              359304.92589032411,
              5005208.2703686673
            ],
            [
              359302.45258155873,
              5005200.7836497622
            ],
            [
              359301.56978130975,
              5005190.2425033106
            ],
            [
              359329.04537867958,
              5005184.5809083348
            ],
            [
              359333.19176753395,
              5005183.7265075063
            ],
            [
              359339.53755795729,
              5005199.5810893057
            ],
            [
              359343.80069635849,
              5005199.5018303031
            ],
            [
              359348.10811081977,
              5005197.7759767696
            ],
            [
              359367.78840327932,
              5005237.3635020228
            ],
            [
              359382.66651946161,
              5005230.0080780685
            ],
            [
              359385.33899317333,
              5005228.8243626952
            ],
            [
              359365.99789866526,
              5005195.9241206814
            ],
            [
              359357.23294790619,
              5005186.8145089885
            ],
            [
              359366.16342978034,
              5005179.1737928884
            ],
            [
              359375.47877462988,
              5005171.0689092074
            ],
            [
              359379.49328633008,
              5005166.9008577969
            ],
            [
              359358.59147165768,
              5005148.0747336717
            ],
            [
              359381.20980108,
              5005127.4460460786
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 233,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359485.04320686724,
              5005267.7497160183
            ],
            [
              359477.58623401268,
              5005274.3307892643
            ],
            [
              359472.21993060951,
              5005274.6066173343
            ],
            [
              359468.4102474461,
              5005277.0247623287
            ],
            [
              359480.86108118406,
              5005300.7269859686
            ],
            [
              359476.53654176096,
              5005303.2591516264
            ],
            [
              359474.67010842951,
              5005300.5118003041
            ],
            [
              359471.50415371277,
              5005302.7907363931
            ],
            [
              359465.12880116125,
              5005290.3370349696
            ],
            [
              359460.26817157672,
              5005288.4929399602
            ],
            [
              359456.40146148187,
              5005284.8884961503
            ],
            [
              359453.68538574054,
              5005286.7701690597
            ],
            [
              359445.03867472359,
              5005290.6065232297
            ],
            [
              359437.59019643679,
              5005265.3450366622
            ],
            [
              359432.37967591244,
              5005254.5274658417
            ],
            [
              359426.37058490026,
              5005256.9898802685
            ],
            [
              359420.54289549944,
              5005258.4791571014
            ],
            [
              359409.56401299842,
              5005265.0787127521
            ],
            [
              359405.38588177174,
              5005259.1251198854
            ],
            [
              359394.54418695415,
              5005264.3623706028
            ],
            [
              359384.31980017159,
              5005268.7175049167
            ],
            [
              359386.25082520425,
              5005275.7811717698
            ],
            [
              359396.0223935906,
              5005287.0052354429
            ],
            [
              359404.73954290285,
              5005294.1210898869
            ],
            [
              359399.40463394439,
              5005296.70217676
            ],
            [
              359403.66102479451,
              5005305.5815020315
            ],
            [
              359406.02323111653,
              5005320.0146427322
            ],
            [
              359411.84185600112,
              5005318.1003197674
            ],
            [
              359413.86643742712,
              5005322.0361997401
            ],
            [
              359417.95379997941,
              5005320.2985377563
            ],
            [
              359419.89053471957,
              5005327.3798726937
            ],
            [
              359421.64417344303,
              5005328.8806070192
            ],
            [
              359424.52361008589,
              5005339.3688677736
            ],
            [
              359446.41796555842,
              5005326.6453133328
            ],
            [
              359472.22354702727,
              5005313.4701281795
            ],
            [
              359485.33964016649,
              5005308.6151724756
            ],
            [
              359491.23022768024,
              5005305.1889325567
            ],
            [
              359499.41856163129,
              5005302.3388062157
            ],
            [
              359505.97480050172,
              5005297.8449568721
            ],
            [
              359515.97035884345,
              5005314.4083737247
            ],
            [
              359522.78608665155,
              5005327.9768037861
            ],
            [
              359525.13381205063,
              5005331.918012742
            ],
            [
              359527.95391888148,
              5005327.4760413561
            ],
            [
              359536.24844045547,
              5005322.6960486565
            ],
            [
              359537.15883278177,
              5005322.1714049457
            ],
            [
              359539.65344854776,
              5005321.0744060045
            ],
            [
              359541.94795731484,
              5005318.5149778109
            ],
            [
              359550.3519802377,
              5005323.3337088777
            ],
            [
              359553.76010569365,
              5005327.1332820095
            ],
            [
              359556.38928088627,
              5005320.5291255629
            ],
            [
              359568.38387676195,
              5005309.3628101824
            ],
            [
              359575.41255990294,
              5005317.1601992752
            ],
            [
              359585.6215667114,
              5005326.9926390778
            ],
            [
              359587.17961123824,
              5005325.5777075598
            ],
            [
              359590.97169291571,
              5005320.8584749317
            ],
            [
              359582.55839737336,
              5005311.3991383789
            ],
            [
              359563.88254539383,
              5005286.6614935128
            ],
            [
              359558.56171372329,
              5005275.9308397043
            ],
            [
              359554.46062885429,
              5005267.6600790909
            ],
            [
              359531.00276216067,
              5005237.4447736274
            ],
            [
              359521.97605429648,
              5005243.8017543266
            ],
            [
              359513.3729467977,
              5005254.95775369
            ],
            [
              359508.90697966126,
              5005257.998310036
            ],
            [
              359504.38875064004,
              5005260.2826811317
            ],
            [
              359500.71488022601,
              5005261.2261845972
            ],
            [
              359496.22240414313,
              5005264.8504069103
            ],
            [
              359490.32166524621,
              5005263.0912680486
            ],
            [
              359485.04320686724,
              5005267.7497160183
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 234,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359785.07471424906,
              5005384.5327409506
            ],
            [
              359792.1644029647,
              5005380.0724112364
            ],
            [
              359789.5428802268,
              5005375.9472102076
            ],
            [
              359793.73501818744,
              5005369.0260560475
            ],
            [
              359795.02519880404,
              5005369.5732744271
            ],
            [
              359804.6674634064,
              5005359.4827621067
            ],
            [
              359819.28919040505,
              5005365.4719188502
            ],
            [
              359825.35645539616,
              5005360.5316869207
            ],
            [
              359820.46023822523,
              5005357.6007269789
            ],
            [
              359821.62810164812,
              5005352.8047446515
            ],
            [
              359819.51145536877,
              5005352.4391730763
            ],
            [
              359812.64597388584,
              5005345.4033146901
            ],
            [
              359806.47456195537,
              5005342.5705874255
            ],
            [
              359792.60044080199,
              5005326.6845209245
            ],
            [
              359798.55226246692,
              5005319.5634164382
            ],
            [
              359789.62951495155,
              5005309.3515259037
            ],
            [
              359781.65027015272,
              5005299.2288444517
            ],
            [
              359774.53546902054,
              5005306.8680988681
            ],
            [
              359776.65254838235,
              5005308.6150269471
            ],
            [
              359769.57554053044,
              5005317.1915602358
            ],
            [
              359775.15723158541,
              5005322.4017050397
            ],
            [
              359778.90260282828,
              5005327.1418212811
            ],
            [
              359769.3939640625,
              5005334.6550086383
            ],
            [
              359767.09418109781,
              5005328.9620967917
            ],
            [
              359761.35797832109,
              5005334.3801784758
            ],
            [
              359756.3889833999,
              5005337.6373716053
            ],
            [
              359759.4335325681,
              5005344.1080149049
            ],
            [
              359766.06174707279,
              5005354.6774506215
            ],
            [
              359773.44040096359,
              5005364.0968809659
            ],
            [
              359774.10812159668,
              5005367.0712943887
            ],
            [
              359778.1912536741,
              5005374.1849540025
            ],
            [
              359781.42846045952,
              5005381.8858011207
            ],
            [
              359785.07471424906,
              5005384.5327409506
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 235,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360848.30180566304,
              5003351.5110408291
            ],
            [
              360826.86644119961,
              5003332.0443384899
            ],
            [
              360854.9865505278,
              5003292.481570405
            ],
            [
              360856.12588906643,
              5003290.3822603822
            ],
            [
              360860.90174666373,
              5003284.2640779484
            ],
            [
              360869.37362803449,
              5003275.4754009619
            ],
            [
              360874.517199578,
              5003266.305977758
            ],
            [
              360874.67683815304,
              5003262.9833197361
            ],
            [
              360887.71862848237,
              5003244.0283788797
            ],
            [
              360839.97586162208,
              5003213.3253497696
            ],
            [
              360832.81434464845,
              5003221.8658960676
            ],
            [
              360826.20278484357,
              5003232.0312486207
            ],
            [
              360822.29164739576,
              5003238.0446696989
            ],
            [
              360817.1553647418,
              5003244.0149862505
            ],
            [
              360824.59361354372,
              5003251.9777238695
            ],
            [
              360805.16868170857,
              5003277.7599340407
            ],
            [
              360789.19254277262,
              5003295.741195526
            ],
            [
              360747.23691156186,
              5003358.6923878482
            ],
            [
              360732.09290060226,
              5003365.4266001796
            ],
            [
              360764.05872648821,
              5003389.5489146868
            ],
            [
              360791.94964726683,
              5003414.3128731577
            ],
            [
              360800.39685472124,
              5003404.1967591224
            ],
            [
              360791.32941046107,
              5003392.8570213206
            ],
            [
              360796.82217458793,
              5003389.1522329971
            ],
            [
              360813.64747224405,
              5003377.1099037472
            ],
            [
              360829.84160416567,
              5003366.8498041704
            ],
            [
              360833.87098380021,
              5003369.2093553562
            ],
            [
              360846.43319371785,
              5003354.1479106294
            ],
            [
              360848.30180566304,
              5003351.5110408291
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 236,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351742.63844828581,
              5011133.192039513
            ],
            [
              351728.97477329458,
              5011125.2747360431
            ],
            [
              351698.95482927706,
              5011103.1455535004
            ],
            [
              351690.28806200047,
              5011097.0594353648
            ],
            [
              351651.1970397635,
              5011061.7613994656
            ],
            [
              351616.67170091328,
              5011032.1309789373
            ],
            [
              351579.93116117537,
              5010985.379862031
            ],
            [
              351573.89293813909,
              5010977.7410308821
            ],
            [
              351551.88567063079,
              5010943.0053171478
            ],
            [
              351550.13774115738,
              5010933.5073149605
            ],
            [
              351550.09380082058,
              5010935.1078834524
            ],
            [
              351549.82068637869,
              5010936.7296906859
            ],
            [
              351549.44865692005,
              5010938.4548031921
            ],
            [
              351548.86217400705,
              5010939.5772151863
            ],
            [
              351547.67949311004,
              5010941.2159481114
            ],
            [
              351545.49240683293,
              5010943.2779720472
            ],
            [
              351544.70619863144,
              5010944.5051459763
            ],
            [
              351544.42102490197,
              5010945.520905775
            ],
            [
              351544.33901894948,
              5010946.5328849209
            ],
            [
              351544.56208148209,
              5010947.6402325192
            ],
            [
              351544.97547884245,
              5010948.1381856492
            ],
            [
              351546.10137066309,
              5010948.8245516689
            ],
            [
              351548.54645921756,
              5010949.7895053234
            ],
            [
              351550.59131576732,
              5010950.9639973128
            ],
            [
              351552.64369092177,
              5010952.5425306279
            ],
            [
              351554.08593692846,
              5010953.9307461847
            ],
            [
              351555.32390912925,
              5010955.2212983826
            ],
            [
              351556.6796006909,
              5010957.4190677796
            ],
            [
              351557.21525814122,
              5010959.0262442222
            ],
            [
              351557.97397037206,
              5010961.7403490627
            ],
            [
              351558.32603806228,
              5010964.3613949288
            ],
            [
              351558.65930139745,
              5010965.971918337
            ],
            [
              351558.98128679005,
              5010966.9763797494
            ],
            [
              351559.81449412362,
              5010968.2748832516
            ],
            [
              351561.05246635393,
              5010969.5654353732
            ],
            [
              351562.59362740471,
              5010970.8503455771
            ],
            [
              351564.27588657645,
              5010972.4085131688
            ],
            [
              351565.31335211801,
              5010973.8038417064
            ],
            [
              351566.043877338,
              5010975.0032106396
            ],
            [
              351566.67923128052,
              5010976.5070672128
            ],
            [
              351567.21864032623,
              5010978.3158450993
            ],
            [
              351567.46912425366,
              5010980.9387812195
            ],
            [
              351567.56311518617,
              5010985.9897168214
            ],
            [
              351567.58755081386,
              5010987.3028511051
            ],
            [
              351567.80873379565,
              5010988.3091882663
            ],
            [
              351568.22290480277,
              5010988.8067076476
            ],
            [
              351568.83304174594,
              5010988.9974444471
            ],
            [
              351569.5410032373,
              5010988.9842702504
            ],
            [
              351570.84970333998,
              5010988.6567812087
            ],
            [
              351571.45686236297,
              5010988.6454828074
            ],
            [
              351572.47099056869,
              5010988.8287018808
            ],
            [
              351573.39074450987,
              5010989.3172323182
            ],
            [
              351575.13617157406,
              5010990.6993865743
            ],
            [
              351577.6075753523,
              5010993.0784844263
            ],
            [
              351580.30095133214,
              5010996.4643239407
            ],
            [
              351584.14309198374,
              5011001.8496924872
            ],
            [
              351587.99729210831,
              5011007.8411083138
            ],
            [
              351595.82462945645,
              5011018.9903037744
            ],
            [
              351598.83811139496,
              5011023.2795938347
            ],
            [
              351600.3905505187,
              5011025.1705655698
            ],
            [
              351601.63604156947,
              5011026.8651586939
            ],
            [
              351602.67351497157,
              5011028.2609059811
            ],
            [
              351603.61127642746,
              5011029.7591348561
            ],
            [
              351604.3455533188,
              5011031.1601050496
            ],
            [
              351605.09111606173,
              5011033.1675562551
            ],
            [
              351605.6324050292,
              5011035.0773441717
            ],
            [
              351606.27073709498,
              5011036.7832356384
            ],
            [
              351607.31760897522,
              5011038.6840345487
            ],
            [
              351608.36071368068,
              5011040.3823936535
            ],
            [
              351609.81611757504,
              5011042.4776808508
            ],
            [
              351611.80335799273,
              5011044.840631268
            ],
            [
              351615.32351337856,
              5011049.2215375537
            ],
            [
              351621.12365558202,
              5011056.4907487342
            ],
            [
              351623.08948440716,
              5011058.8792539686
            ],
            [
              351624.53736959473,
              5011060.5704997284
            ],
            [
              351625.97694683552,
              5011061.8572996492
            ],
            [
              351627.72989265685,
              5011063.6434945604
            ],
            [
              351629.69275134074,
              5011065.830383664
            ],
            [
              351631.76016403944,
              5011068.2169984104
            ],
            [
              351633.31449075317,
              5011070.2093992112
            ],
            [
              351634.88008778897,
              5011072.8074426986
            ],
            [
              351636.03340128978,
              5011075.0093964571
            ],
            [
              351637.28829084395,
              5011077.2090406716
            ],
            [
              351638.74666499236,
              5011079.505943547
            ],
            [
              351640.12772201066,
              5011081.4705422567
            ],
            [
              351641.67828156526,
              5011083.2605031896
            ],
            [
              351644.05265024136,
              5011085.8439154802
            ],
            [
              351647.04168876249,
              5011088.8196512414
            ],
            [
              351652.08686964953,
              5011093.5763586843
            ],
            [
              351655.27752091328,
              5011096.5487618474
            ],
            [
              351659.17989252316,
              5011099.7100108396
            ],
            [
              351663.80149634654,
              5011103.4637284903
            ],
            [
              351669.23939022276,
              5011107.6068558963
            ],
            [
              351676.21280609554,
              5011112.731861474
            ],
            [
              351681.17829843779,
              5011116.3567506922
            ],
            [
              351685.28415478277,
              5011119.6152581023
            ],
            [
              351688.57826165628,
              5011122.6863614814
            ],
            [
              351691.77157383662,
              5011125.7597597064
            ],
            [
              351694.96598439262,
              5011128.9341827957
            ],
            [
              351696.20395674597,
              5011130.2247338425
            ],
            [
              351697.33282642596,
              5011131.1131340843
            ],
            [
              351699.17529644835,
              5011132.2913907114
            ],
            [
              351700.401998349,
              5011132.9762991136
            ],
            [
              351701.82355988858,
              5011133.2529810071
            ],
            [
              351703.44484656636,
              5011133.4249009341
            ],
            [
              351704.96909022727,
              5011133.8007172775
            ],
            [
              351706.4933338798,
              5011134.1765336124
            ],
            [
              351708.02697605622,
              5011135.0574013852
            ],
            [
              351711.00207509066,
              5011137.3260787148
            ],
            [
              351715.52100402472,
              5011140.9810797432
            ],
            [
              351716.54907125735,
              5011141.8713557459
            ],
            [
              351717.46427639882,
              5011142.1574604157
            ],
            [
              351718.38026295026,
              5011142.4435505429
            ],
            [
              351719.49221521587,
              5011142.4228581358
            ],
            [
              351720.80921483849,
              5011142.4993952522
            ],
            [
              351722.52943195618,
              5011142.5688481303
            ],
            [
              351724.05179583078,
              5011142.8436541278
            ],
            [
              351725.57979882479,
              5011143.421490971
            ],
            [
              351726.60034719354,
              5011143.9077258091
            ],
            [
              351727.62089555897,
              5011144.3939606482
            ],
            [
              351728.24309197062,
              5011145.1907442287
            ],
            [
              351728.86638667848,
              5011146.0885526314
            ],
            [
              351729.48780167784,
              5011146.885350748
            ],
            [
              351730.00089542283,
              5011147.279983581
            ],
            [
              351731.18713331356,
              5011147.8067396656
            ],
            [
              351778.81974298233,
              5011165.3070846042
            ],
            [
              351782.08359158825,
              5011153.0209695548
            ],
            [
              351775.20700719819,
              5011150.1522357576
            ],
            [
              351757.48440804769,
              5011142.2152869888
            ],
            [
              351742.63844828581,
              5011133.192039513
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 237,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352421.44422900275,
              5011578.5022212267
            ],
            [
              352422.88335101138,
              5011561.33920989
            ],
            [
              352411.87359070225,
              5011546.0115920575
            ],
            [
              352417.42892092373,
              5011526.9848046983
            ],
            [
              352412.80530877394,
              5011519.6327610873
            ],
            [
              352415.34291578637,
              5011514.1149311792
            ],
            [
              352433.4902943825,
              5011522.0165902814
            ],
            [
              352455.99123725103,
              5011528.8282830138
            ],
            [
              352469.09670363518,
              5011527.2127916217
            ],
            [
              352476.16641386476,
              5011526.4912199397
            ],
            [
              352494.69848973473,
              5011526.3823488988
            ],
            [
              352513.60874559858,
              5011527.5643876223
            ],
            [
              352528.45884720556,
              5011532.5979204979
            ],
            [
              352532.73140570015,
              5011533.8163877008
            ],
            [
              352537.03095128929,
              5011535.1766150538
            ],
            [
              352546.65345796657,
              5011524.3060559379
            ],
            [
              352558.0932508707,
              5011513.1641107844
            ],
            [
              352568.51261720416,
              5011506.7929076552
            ],
            [
              352575.76928226824,
              5011496.5207567802
            ],
            [
              352580.26455921883,
              5011489.9777975921
            ],
            [
              352593.38059667801,
              5011475.2408048809
            ],
            [
              352599.36001963192,
              5011468.7146434495
            ],
            [
              352607.71559795953,
              5011462.1442828756
            ],
            [
              352608.95493035245,
              5011460.1145946337
            ],
            [
              352624.18736084789,
              5011444.1503016874
            ],
            [
              352634.82403261826,
              5011422.5287592812
            ],
            [
              352631.47940252826,
              5011417.3640564
            ],
            [
              352650.68875805574,
              5011388.6950521758
            ],
            [
              352660.98328032717,
              5011362.8439412722
            ],
            [
              352665.2956524883,
              5011350.8968296852
            ],
            [
              352658.2637395467,
              5011347.7014455525
            ],
            [
              352663.93855270586,
              5011329.0639736876
            ],
            [
              352663.96957286418,
              5011328.7847748483
            ],
            [
              352661.86202979687,
              5011327.8016740223
            ],
            [
              352623.07463756081,
              5011300.5280748792
            ],
            [
              352615.0021366771,
              5011299.5943898391
            ],
            [
              352594.30411818431,
              5011311.1287128869
            ],
            [
              352592.20809286059,
              5011315.0389473662
            ],
            [
              352576.19490155991,
              5011336.5682251062
            ],
            [
              352572.73953956756,
              5011335.1225728663
            ],
            [
              352559.24586295133,
              5011357.6251846142
            ],
            [
              352558.38195677055,
              5011361.0407498749
            ],
            [
              352552.49515281769,
              5011378.1527560521
            ],
            [
              352547.37769039365,
              5011376.7060290165
            ],
            [
              352541.7151736673,
              5011383.1798402313
            ],
            [
              352541.59415264888,
              5011391.408887757
            ],
            [
              352542.33653341146,
              5011404.3372807167
            ],
            [
              352545.23614660872,
              5011413.0478236815
            ],
            [
              352544.82457212388,
              5011415.0415859735
            ],
            [
              352532.59334465122,
              5011413.6132951761
            ],
            [
              352526.32067288941,
              5011410.2259731144
            ],
            [
              352517.73160022433,
              5011410.6014397247
            ],
            [
              352493.94327543693,
              5011409.2169452887
            ],
            [
              352453.91113732342,
              5011400.6465968527
            ],
            [
              352449.53554556792,
              5011413.4194613239
            ],
            [
              352478.20293888886,
              5011423.2400296824
            ],
            [
              352473.69887079595,
              5011433.4098448763
            ],
            [
              352498.88100416493,
              5011444.2088671029
            ],
            [
              352525.85494805645,
              5011451.8217854993
            ],
            [
              352579.32407845784,
              5011476.5596572198
            ],
            [
              352574.54391222849,
              5011482.8911533011
            ],
            [
              352569.45048781758,
              5011494.406167049
            ],
            [
              352527.82796898833,
              5011475.995320946
            ],
            [
              352486.18286118831,
              5011460.8883213382
            ],
            [
              352471.53220472421,
              5011453.2869892707
            ],
            [
              352452.3959107658,
              5011443.3583328864
            ],
            [
              352446.37055786705,
              5011445.4791541947
            ],
            [
              352440.68460918817,
              5011449.6788945403
            ],
            [
              352446.51365672448,
              5011456.4998025177
            ],
            [
              352462.85772861791,
              5011465.9563897708
            ],
            [
              352486.7948189833,
              5011476.7602829468
            ],
            [
              352481.57523163484,
              5011488.3836479727
            ],
            [
              352470.56621837744,
              5011484.3696656022
            ],
            [
              352467.06868891761,
              5011490.1189555628
            ],
            [
              352408.74389220536,
              5011465.4936329303
            ],
            [
              352399.81856778718,
              5011477.125090153
            ],
            [
              352393.73837422469,
              5011485.2941981368
            ],
            [
              352391.59490981203,
              5011485.3340881513
            ],
            [
              352387.91829853191,
              5011482.0660832301
            ],
            [
              352382.92557030002,
              5011477.6282301256
            ],
            [
              352366.63692288107,
              5011500.4191948893
            ],
            [
              352362.99592480826,
              5011498.2290896783
            ],
            [
              352354.63118218858,
              5011494.9452802232
            ],
            [
              352356.7889905264,
              5011491.0929682218
            ],
            [
              352339.35835933132,
              5011481.3294909243
            ],
            [
              352310.33389654331,
              5011466.2272543497
            ],
            [
              352308.39180222165,
              5011465.5781473117
            ],
            [
              352284.70155964047,
              5011457.6601468474
            ],
            [
              352271.77681730717,
              5011454.5131392553
            ],
            [
              352257.14783821988,
              5011452.3739139335
            ],
            [
              352254.63769944262,
              5011454.6626181789
            ],
            [
              352247.81912620983,
              5011462.48968664
            ],
            [
              352235.46133131889,
              5011481.8346223999
            ],
            [
              352231.0217007249,
              5011476.71196164
            ],
            [
              352226.03530937323,
              5011467.753115912
            ],
            [
              352201.92646696541,
              5011507.3317355867
            ],
            [
              352175.8152919772,
              5011485.9099233337
            ],
            [
              352171.46632101276,
              5011499.8997719754
            ],
            [
              352165.81000610261,
              5011496.7908266978
            ],
            [
              352160.56178426446,
              5011506.3392721182
            ],
            [
              352154.9010447716,
              5011514.6282837801
            ],
            [
              352162.57672139484,
              5011519.8701634062
            ],
            [
              352161.04258097499,
              5011521.2876508627
            ],
            [
              352116.89202130592,
              5011496.9022450335
            ],
            [
              352101.67209822853,
              5011488.9479754735
            ],
            [
              352096.0880366948,
              5011502.4870569874
            ],
            [
              352091.09979778092,
              5011515.4202464307
            ],
            [
              352113.33824465045,
              5011531.5387899047
            ],
            [
              352124.88257889595,
              5011541.0115453424
            ],
            [
              352121.04778860131,
              5011545.6849585259
            ],
            [
              352111.69066893519,
              5011543.7568922965
            ],
            [
              352088.51801921555,
              5011526.3480977919
            ],
            [
              352086.8298530294,
              5011533.3678398812
            ],
            [
              352079.41127625667,
              5011541.0216895118
            ],
            [
              352093.16883072036,
              5011556.4467461221
            ],
            [
              352089.93007362366,
              5011558.7265818026
            ],
            [
              352108.13799216261,
              5011582.7405990642
            ],
            [
              352116.50709452241,
              5011573.782615874
            ],
            [
              352126.2074071971,
              5011585.2062907675
            ],
            [
              352135.3255856043,
              5011598.8867614632
            ],
            [
              352138.38566022256,
              5011602.3235465158
            ],
            [
              352146.82572888688,
              5011603.8019037014
            ],
            [
              352151.51125341334,
              5011607.6173449084
            ],
            [
              352154.8667788544,
              5011610.5635265931
            ],
            [
              352162.5209883324,
              5011619.7655208232
            ],
            [
              352176.20176157664,
              5011630.3990295446
            ],
            [
              352184.766868148,
              5011636.0849102736
            ],
            [
              352193.25545433856,
              5011641.6144578615
            ],
            [
              352190.86029155989,
              5011652.1482104072
            ],
            [
              352204.98951210355,
              5011661.6963670356
            ],
            [
              352211.93592869764,
              5011660.8388449484
            ],
            [
              352223.33763724164,
              5011680.5739292568
            ],
            [
              352236.88027053286,
              5011673.619996679
            ],
            [
              352238.71886421234,
              5011677.272671707
            ],
            [
              352257.19134819886,
              5011713.1396499984
            ],
            [
              352262.78951146099,
              5011708.7533055292
            ],
            [
              352273.37988842715,
              5011700.9521329692
            ],
            [
              352276.36537723243,
              5011704.7359897969
            ],
            [
              352289.07212373568,
              5011695.258922209
            ],
            [
              352283.94599893206,
              5011687.2353282291
            ],
            [
              352289.58799272304,
              5011683.7432257524
            ],
            [
              352291.6888304528,
              5011682.1740874462
            ],
            [
              352293.60927507241,
              5011680.2220206838
            ],
            [
              352300.6730423696,
              5011673.4941525487
            ],
            [
              352303.30215971504,
              5011675.4477171684
            ],
            [
              352311.61800340208,
              5011666.3406854784
            ],
            [
              352320.00228517299,
              5011675.3901931755
            ],
            [
              352329.44263568771,
              5011666.1025537187
            ],
            [
              352325.7709521823,
              5011660.7633474907
            ],
            [
              352333.73237419996,
              5011649.8001042344
            ],
            [
              352335.01218651031,
              5011658.2446770538
            ],
            [
              352346.86604238936,
              5011669.9614909468
            ],
            [
              352355.01351216651,
              5011677.4964349912
            ],
            [
              352363.79858639848,
              5011668.5366123924
            ],
            [
              352362.0894588813,
              5011663.7426780285
            ],
            [
              352366.54458014615,
              5011656.0970084947
            ],
            [
              352375.64488657349,
              5011645.8758533765
            ],
            [
              352387.59846300975,
              5011632.7754732007
            ],
            [
              352395.01597274782,
              5011643.622186468
            ],
            [
              352404.77548845456,
              5011633.7832275322
            ],
            [
              352406.75752891233,
              5011635.65201593
            ],
            [
              352430.48198165029,
              5011654.0079268226
            ],
            [
              352413.58478398569,
              5011671.5010290053
            ],
            [
              352408.57806459517,
              5011676.6950267153
            ],
            [
              352399.14732669585,
              5011680.4851540448
            ],
            [
              352391.4978900046,
              5011676.0642615585
            ],
            [
              352388.14768844022,
              5011678.4226868544
            ],
            [
              352396.18575560022,
              5011689.8160272939
            ],
            [
              352401.52109102282,
              5011691.8964336207
            ],
            [
              352394.97622808319,
              5011703.625077622
            ],
            [
              352401.00726322865,
              5011706.8148396444
            ],
            [
              352403.928384931,
              5011702.4740038635
            ],
            [
              352412.99043213815,
              5011707.2276293254
            ],
            [
              352405.79653097404,
              5011724.9708912987
            ],
            [
              352414.25461288128,
              5011728.190706607
            ],
            [
              352425.01026324515,
              5011727.0255816868
            ],
            [
              352432.51280160644,
              5011731.3379315957
            ],
            [
              352436.76526002993,
              5011729.7472761441
            ],
            [
              352437.45257889671,
              5011712.1435009474
            ],
            [
              352437.44902801269,
              5011703.7462376524
            ],
            [
              352437.07254607364,
              5011692.1789929057
            ],
            [
              352436.34319575218,
              5011681.3718991429
            ],
            [
              352433.70408475475,
              5011659.2055187877
            ],
            [
              352433.1507381681,
              5011655.7410893319
            ],
            [
              352432.35384092288,
              5011651.1411355361
            ],
            [
              352431.68508134392,
              5011647.9695095317
            ],
            [
              352430.32573450549,
              5011640.861368835
            ],
            [
              352428.94221899059,
              5011634.6995050097
            ],
            [
              352424.29290600488,
              5011617.1438482488
            ],
            [
              352423.40617395454,
              5011614.4005978657
            ],
            [
              352425.86730851413,
              5011610.3599639954
            ],
            [
              352426.94805621862,
              5011597.8701135805
            ],
            [
              352418.22544081061,
              5011581.9530129191
            ],
            [
              352419.20388656115,
              5011581.6066477718
            ],
            [
              352421.44422900275,
              5011578.5022212267
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 238,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354173.98100202245,
              5009920.6492586369
            ],
            [
              354369.21277309716,
              5009756.8132162113
            ],
            [
              354301.69920149649,
              5009676.36203642
            ],
            [
              354057.967847533,
              5009889.87542402
            ],
            [
              354053.60589417053,
              5009894.5942758257
            ],
            [
              354048.92404761171,
              5009898.9959247839
            ],
            [
              354043.94531779119,
              5009903.0587380249
            ],
            [
              354038.69417374016,
              5009906.7627479639
            ],
            [
              354008.15348232159,
              5009917.5481748646
            ],
            [
              353957.3582486923,
              5009929.2633877685
            ],
            [
              353945.44753494178,
              5009932.1920774849
            ],
            [
              353969.57184867881,
              5010017.3361398708
            ],
            [
              354010.87276478129,
              5010004.7163212737
            ],
            [
              354027.29696505127,
              5010000.4954013061
            ],
            [
              354043.51304767927,
              5009995.5346396249
            ],
            [
              354059.48770437227,
              5009989.84422589
            ],
            [
              354075.18812274403,
              5009983.435848482
            ],
            [
              354090.58205370727,
              5009976.3226704951
            ],
            [
              354105.63787771825,
              5009968.5193026988
            ],
            [
              354120.32466972148,
              5009960.0417735269
            ],
            [
              354134.61226267024,
              5009950.9074961562
            ],
            [
              354148.13719597447,
              5009941.3517987812
            ],
            [
              354161.26718048268,
              5009931.2602846082
            ],
            [
              354173.98100202245,
              5009920.6492586369
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 239,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352555.64629643114,
              5011061.2061697878
            ],
            [
              352571.71137722558,
              5011054.2972217146
            ],
            [
              352628.7484584474,
              5011081.584991442
            ],
            [
              352643.48405987216,
              5011060.2864256809
            ],
            [
              352644.86583507695,
              5011039.610190738
            ],
            [
              352659.50627904007,
              5011013.7906544171
            ],
            [
              352636.5213859544,
              5011012.0187340733
            ],
            [
              352630.46484138974,
              5011005.5078080157
            ],
            [
              352634.24021373858,
              5010996.6401734818
            ],
            [
              352641.76553906774,
              5010976.7476135958
            ],
            [
              352643.16821601626,
              5010969.8062962377
            ],
            [
              352642.05183886847,
              5010958.1400207365
            ],
            [
              352673.39649673452,
              5010932.0734896688
            ],
            [
              352692.86338830058,
              5010911.921323915
            ],
            [
              352684.30017471767,
              5010908.2481547697
            ],
            [
              352679.32185386436,
              5010913.3525637025
            ],
            [
              352660.2539060156,
              5010933.8439479806
            ],
            [
              352639.64669838437,
              5010954.1090727989
            ],
            [
              352625.51131682988,
              5010940.0673142457
            ],
            [
              352615.09344672778,
              5010942.5027418947
            ],
            [
              352608.31154528592,
              5010948.4823507322
            ],
            [
              352606.48086863873,
              5010958.2915973682
            ],
            [
              352605.60333378584,
              5010963.589799298
            ],
            [
              352597.26866711408,
              5010960.8127962099
            ],
            [
              352592.15076024394,
              5010970.8412048826
            ],
            [
              352566.98066202673,
              5010959.4362378186
            ],
            [
              352564.46392649895,
              5010967.2368900711
            ],
            [
              352560.81045981677,
              5010976.6890922962
            ],
            [
              352554.14061340736,
              5010993.7125573019
            ],
            [
              352550.50014075654,
              5010991.2752859239
            ],
            [
              352532.01356276817,
              5010998.9165514745
            ],
            [
              352518.39851549227,
              5011012.7106280681
            ],
            [
              352518.70464412257,
              5011029.1614496168
            ],
            [
              352531.62639364979,
              5011057.0292681968
            ],
            [
              352539.49576733477,
              5011068.1518661426
            ],
            [
              352555.64629643114,
              5011061.2061697878
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 240,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352909.4399967569,
              5011352.6695363019
            ],
            [
              352922.88117141457,
              5011360.6470061429
            ],
            [
              352925.31888809463,
              5011362.09381501
            ],
            [
              352935.84871909168,
              5011368.4706510073
            ],
            [
              352952.15295754658,
              5011378.6503875107
            ],
            [
              352955.57025619276,
              5011367.850111343
            ],
            [
              352960.10243542277,
              5011360.1847115969
            ],
            [
              352945.6292116681,
              5011351.6273961505
            ],
            [
              352929.81814962853,
              5011342.3209262798
            ],
            [
              352930.5590521961,
              5011337.0231089396
            ],
            [
              352943.28381677537,
              5011341.4777520513
            ],
            [
              352949.44530939643,
              5011345.0555899702
            ],
            [
              352963.81212343951,
              5011353.3744952716
            ],
            [
              352966.22499333101,
              5011349.2275667107
            ],
            [
              352981.73862564773,
              5011358.254096319
            ],
            [
              352987.26270978356,
              5011351.5830293186
            ],
            [
              353007.46481201547,
              5011334.6134826755
            ],
            [
              352965.71090763214,
              5011317.0441620145
            ],
            [
              352936.60217760503,
              5011301.0862287777
            ],
            [
              352925.44592519983,
              5011295.7207043376
            ],
            [
              352919.04777622939,
              5011309.0240381202
            ],
            [
              352907.77910551836,
              5011303.9949176479
            ],
            [
              352901.07732514857,
              5011310.5446295142
            ],
            [
              352900.05250177544,
              5011316.4886651728
            ],
            [
              352919.00506423408,
              5011325.2825545166
            ],
            [
              352909.4399967569,
              5011352.6695363019
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 241,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359104.63638914312,
              5004674.9495292613
            ],
            [
              359099.04443692457,
              5004680.114638783
            ],
            [
              359112.97542573925,
              5004696.1226207176
            ],
            [
              359147.57316990261,
              5004736.1673296494
            ],
            [
              359156.37399049674,
              5004748.9420355242
            ],
            [
              359161.84731838212,
              5004756.8867662074
            ],
            [
              359165.28454324399,
              5004764.4584532846
            ],
            [
              359152.23556571576,
              5004770.9909766233
            ],
            [
              359146.15723171004,
              5004775.1475382037
            ],
            [
              359139.22066281753,
              5004779.8909887215
            ],
            [
              359121.53331728122,
              5004795.6564690499
            ],
            [
              359122.62756090233,
              5004798.8365940126
            ],
            [
              359148.53151273017,
              5004820.1803163532
            ],
            [
              359153.64564686752,
              5004809.9148048321
            ],
            [
              359158.54804635589,
              5004801.9572551223
            ],
            [
              359176.78084299446,
              5004783.750194218
            ],
            [
              359186.04554651451,
              5004774.4985686382
            ],
            [
              359180.78628818254,
              5004764.4190367544
            ],
            [
              359179.28861582308,
              5004758.5277318088
            ],
            [
              359174.37765948277,
              5004749.9169457573
            ],
            [
              359157.68119168299,
              5004727.58534934
            ],
            [
              359123.16945813521,
              5004685.1887565404
            ],
            [
              359110.4576139428,
              5004669.5726480708
            ],
            [
              359104.63638914312,
              5004674.9495292613
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 242,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359282.37147796474,
              5004642.1746063558
            ],
            [
              359279.32929387275,
              5004638.9498720448
            ],
            [
              359277.3740727313,
              5004636.4520540731
            ],
            [
              359260.16359767487,
              5004620.0082338927
            ],
            [
              359249.03799482627,
              5004592.7831552392
            ],
            [
              359257.28623900207,
              5004587.8651694357
            ],
            [
              359258.34775858023,
              5004593.0201875465
            ],
            [
              359266.81204746739,
              5004615.043128904
            ],
            [
              359268.12190513901,
              5004618.4512020331
            ],
            [
              359279.03455256834,
              5004629.1485891221
            ],
            [
              359281.71232241398,
              5004631.7735378016
            ],
            [
              359284.03889371018,
              5004629.4001496509
            ],
            [
              359318.10059817141,
              5004602.5828334838
            ],
            [
              359345.66054085677,
              5004580.1008637631
            ],
            [
              359352.80974703946,
              5004573.4087863509
            ],
            [
              359338.54165288986,
              5004560.1680074781
            ],
            [
              359334.46999536373,
              5004557.6463799151
            ],
            [
              359307.76936604618,
              5004529.9661374586
            ],
            [
              359292.76123897225,
              5004537.5813667513
            ],
            [
              359252.53033319942,
              5004568.0242295349
            ],
            [
              359231.49654140131,
              5004579.3332910975
            ],
            [
              359199.93245488143,
              5004596.3040891606
            ],
            [
              359178.11027155688,
              5004613.13339487
            ],
            [
              359143.66025328235,
              5004640.9428486489
            ],
            [
              359152.95405049494,
              5004654.4484483553
            ],
            [
              359159.06539198424,
              5004647.6326494487
            ],
            [
              359166.13750415918,
              5004652.3702446753
            ],
            [
              359206.22024642624,
              5004695.4070963403
            ],
            [
              359214.11114030163,
              5004687.5329304356
            ],
            [
              359199.84133911267,
              5004672.2451483458
            ],
            [
              359195.91275559034,
              5004666.7343709692
            ],
            [
              359173.60824349843,
              5004644.9931526193
            ],
            [
              359164.13387648569,
              5004637.2578785559
            ],
            [
              359167.18020677584,
              5004632.7195742493
            ],
            [
              359173.14610542933,
              5004636.7241749736
            ],
            [
              359175.52978950884,
              5004634.0828840081
            ],
            [
              359170.3688287288,
              5004629.4306984814
            ],
            [
              359173.6352762506,
              5004625.8070239639
            ],
            [
              359176.77164921339,
              5004623.3153515402
            ],
            [
              359179.45069289161,
              5004617.8395245364
            ],
            [
              359191.4143027211,
              5004629.4046012089
            ],
            [
              359208.95899078314,
              5004645.597873562
            ],
            [
              359228.24986169703,
              5004669.866247545
            ],
            [
              359241.13720715285,
              5004654.5838161036
            ],
            [
              359243.35186553095,
              5004653.4991943948
            ],
            [
              359236.26152026141,
              5004639.0216190172
            ],
            [
              359244.73526700819,
              5004632.4724517465
            ],
            [
              359250.41704864328,
              5004641.4199796766
            ],
            [
              359261.70320881106,
              5004656.1939008124
            ],
            [
              359270.49013877992,
              5004664.9543107999
            ],
            [
              359273.34172522975,
              5004663.7114794115
            ],
            [
              359286.18291533279,
              5004655.9371286221
            ],
            [
              359291.61524824199,
              5004652.1473054495
            ],
            [
              359284.223363041,
              5004644.3278104635
            ],
            [
              359282.37147796474,
              5004642.1746063558
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 243,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359715.53859610989,
              5005256.249914174
            ],
            [
              359720.34143751982,
              5005250.5727423485
            ],
            [
              359716.12585753266,
              5005243.8365981784
            ],
            [
              359710.39873082313,
              5005227.9458651561
            ],
            [
              359725.4928520525,
              5005222.8573756879
            ],
            [
              359729.4890866761,
              5005229.0248415116
            ],
            [
              359733.13721362146,
              5005239.163177425
            ],
            [
              359737.12216874771,
              5005240.1855831854
            ],
            [
              359741.80824676348,
              5005238.0741248485
            ],
            [
              359751.2217067029,
              5005239.772405494
            ],
            [
              359754.56100745447,
              5005233.2998734992
            ],
            [
              359759.32395050203,
              5005235.3200002983
            ],
            [
              359766.78646460193,
              5005241.8447705768
            ],
            [
              359770.91786189127,
              5005237.1288189795
            ],
            [
              359775.66100930062,
              5005231.7155000381
            ],
            [
              359782.38293812814,
              5005237.6101116445
            ],
            [
              359787.57660279126,
              5005232.6010474861
            ],
            [
              359792.20316122408,
              5005227.2854548478
            ],
            [
              359795.53209902631,
              5005229.3322771573
            ],
            [
              359803.4953776382,
              5005221.0493949018
            ],
            [
              359805.43359323993,
              5005216.3742191317
            ],
            [
              359811.3379519523,
              5005211.7096488718
            ],
            [
              359817.05793959129,
              5005206.2050073491
            ],
            [
              359826.40951150301,
              5005196.3310797568
            ],
            [
              359830.97493033845,
              5005200.1950152321
            ],
            [
              359833.67388913594,
              5005195.5900044469
            ],
            [
              359831.87721755827,
              5005191.9529283959
            ],
            [
              359822.9208446393,
              5005182.7354864301
            ],
            [
              359822.84274803003,
              5005178.5348567786
            ],
            [
              359829.77937479893,
              5005167.5746109448
            ],
            [
              359823.99395256693,
              5005164.429514573
            ],
            [
              359810.92608542246,
              5005161.492731519
            ],
            [
              359799.57658540335,
              5005157.6567432657
            ],
            [
              359794.94368143473,
              5005165.1141484883
            ],
            [
              359789.26141723717,
              5005170.5675664181
            ],
            [
              359786.03632037173,
              5005175.9753367174
            ],
            [
              359781.70008008339,
              5005176.0559544964
            ],
            [
              359778.91281241144,
              5005176.0666161096
            ],
            [
              359775.36352160986,
              5005179.7489728145
            ],
            [
              359772.2746174123,
              5005183.8748120852
            ],
            [
              359766.38126955956,
              5005187.9623683663
            ],
            [
              359760.36753530806,
              5005195.3069132213
            ],
            [
              359746.6508552446,
              5005187.6967223398
            ],
            [
              359740.58891878469,
              5005187.254730924
            ],
            [
              359736.66281189542,
              5005187.7311304612
            ],
            [
              359725.89120007644,
              5005186.3176960303
            ],
            [
              359725.5986432393,
              5005188.2798637338
            ],
            [
              359722.27346908598,
              5005197.9564666944
            ],
            [
              359717.69574791606,
              5005206.0267279521
            ],
            [
              359708.37975587108,
              5005214.4399278499
            ],
            [
              359703.94986642146,
              5005212.0248411689
            ],
            [
              359700.49564577878,
              5005204.4948405297
            ],
            [
              359691.38959823333,
              5005214.1487727584
            ],
            [
              359679.49638617667,
              5005233.8594722962
            ],
            [
              359670.81578634254,
              5005244.8661640408
            ],
            [
              359659.82444462663,
              5005240.0832372047
            ],
            [
              359656.03003955929,
              5005254.6538203238
            ],
            [
              359653.50276036939,
              5005268.498534767
            ],
            [
              359647.98197491985,
              5005274.6967209345
            ],
            [
              359629.71807446243,
              5005287.8907247717
            ],
            [
              359625.26786088134,
              5005292.0371594056
            ],
            [
              359617.89475334383,
              5005297.9927527057
            ],
            [
              359624.78757727513,
              5005306.8382224059
            ],
            [
              359631.53062566341,
              5005297.6376960007
            ],
            [
              359634.95218676876,
              5005293.9953973619
            ],
            [
              359642.67150884023,
              5005303.4789386783
            ],
            [
              359648.78300071642,
              5005309.0469934139
            ],
            [
              359652.59198502271,
              5005302.0270055905
            ],
            [
              359654.20623884659,
              5005288.5840909518
            ],
            [
              359657.21925237466,
              5005282.2359885145
            ],
            [
              359660.91754892567,
              5005268.2177374903
            ],
            [
              359665.4059074957,
              5005260.2284652796
            ],
            [
              359683.12671816524,
              5005280.3689392051
            ],
            [
              359690.21922431135,
              5005272.233072279
            ],
            [
              359693.34477213334,
              5005271.48665604
            ],
            [
              359693.26272780809,
              5005271.1431031572
            ],
            [
              359697.81815510435,
              5005266.7279482977
            ],
            [
              359701.70162694092,
              5005265.5579235666
            ],
            [
              359707.49190155795,
              5005263.651299688
            ],
            [
              359715.53859610989,
              5005256.249914174
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 244,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359955.34343142546,
              5005291.1084246254
            ],
            [
              359958.85892571125,
              5005281.0659866324
            ],
            [
              359970.8609938912,
              5005289.3997449744
            ],
            [
              359978.98241457233,
              5005268.5068770917
            ],
            [
              359989.42639784788,
              5005252.0444474388
            ],
            [
              359992.67487733316,
              5005247.9870773647
            ],
            [
              359988.87122674391,
              5005245.775007654
            ],
            [
              359975.44942261983,
              5005248.3105096053
            ],
            [
              359965.06591292849,
              5005252.5679359138
            ],
            [
              359956.74644099706,
              5005247.2608362734
            ],
            [
              359984.28176760249,
              5005218.9731197879
            ],
            [
              359971.39071357256,
              5005206.2994341496
            ],
            [
              359955.06341460644,
              5005198.4524784479
            ],
            [
              359948.70951433078,
              5005211.6731501389
            ],
            [
              359945.25493206579,
              5005218.8611605698
            ],
            [
              359907.81381485116,
              5005188.193224147
            ],
            [
              359894.80452776124,
              5005180.6302245101
            ],
            [
              359887.52337654511,
              5005192.4641890191
            ],
            [
              359882.19870029722,
              5005200.5379960891
            ],
            [
              359877.41959102935,
              5005208.6744679399
            ],
            [
              359882.38049561746,
              5005211.1680809343
            ],
            [
              359896.12597793952,
              5005220.7289585993
            ],
            [
              359893.69955720066,
              5005224.2173750279
            ],
            [
              359892.4298261262,
              5005227.8876702506
            ],
            [
              359888.12207304145,
              5005235.7257383382
            ],
            [
              359885.31444808561,
              5005241.0069824653
            ],
            [
              359919.24136551109,
              5005265.2933803201
            ],
            [
              359913.98119556438,
              5005277.2555651683
            ],
            [
              359879.62781879847,
              5005250.7463239664
            ],
            [
              359875.01939392643,
              5005262.2195509439
            ],
            [
              359868.7051142446,
              5005257.5809898982
            ],
            [
              359862.38509273162,
              5005254.2813044004
            ],
            [
              359857.57871972781,
              5005255.107514523
            ],
            [
              359851.10696650029,
              5005253.2815534668
            ],
            [
              359844.32897200831,
              5005248.9195069419
            ],
            [
              359832.56374024908,
              5005240.3412555819
            ],
            [
              359823.32669794717,
              5005233.680757409
            ],
            [
              359823.30959408503,
              5005251.4197143838
            ],
            [
              359818.30079773423,
              5005263.4059242755
            ],
            [
              359816.1318691966,
              5005267.2508871472
            ],
            [
              359825.85401494469,
              5005274.0912164338
            ],
            [
              359812.80384277418,
              5005302.1747989357
            ],
            [
              359815.80026422651,
              5005304.1917299619
            ],
            [
              359809.43217045552,
              5005313.6523868134
            ],
            [
              359820.97716732294,
              5005317.3325973526
            ],
            [
              359830.43825367815,
              5005321.9890844226
            ],
            [
              359828.13925087615,
              5005322.8673024504
            ],
            [
              359814.3197335513,
              5005333.7886696728
            ],
            [
              359823.42789979599,
              5005346.9499196243
            ],
            [
              359836.85976364563,
              5005335.5089378515
            ],
            [
              359852.37384711154,
              5005345.6983659258
            ],
            [
              359854.45043217303,
              5005351.6240010681
            ],
            [
              359854.5294425927,
              5005355.8736673687
            ],
            [
              359858.45625984477,
              5005362.5110370656
            ],
            [
              359892.50978536025,
              5005342.9613597253
            ],
            [
              359897.83716427832,
              5005340.6607083417
            ],
            [
              359878.67156932392,
              5005317.05627653
            ],
            [
              359872.41729602421,
              5005313.0542878658
            ],
            [
              359855.756160538,
              5005303.9189078808
            ],
            [
              359885.22061703901,
              5005271.0018172301
            ],
            [
              359900.02703814046,
              5005283.2808496263
            ],
            [
              359890.95605785871,
              5005292.8670881344
            ],
            [
              359934.22348572401,
              5005333.6196399378
            ],
            [
              359934.59999076085,
              5005334.0451627839
            ],
            [
              359942.02956626524,
              5005315.4247861765
            ],
            [
              359940.82686799939,
              5005313.7671046564
            ],
            [
              359942.41735085467,
              5005311.0040659327
            ],
            [
              359955.34343142546,
              5005291.1084246254
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 245,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359294.97718359291,
              5005406.9018667908
            ],
            [
              359290.63729398343,
              5005402.6009951439
            ],
            [
              359292.49779424275,
              5005397.7805546718
            ],
            [
              359283.01991586195,
              5005400.6634296691
            ],
            [
              359280.05543613638,
              5005394.377584043
            ],
            [
              359271.36073537864,
              5005381.6836857786
            ],
            [
              359265.66579683538,
              5005377.155393593
            ],
            [
              359259.5675867729,
              5005364.4670392638
            ],
            [
              359246.66065138153,
              5005357.9529231647
            ],
            [
              359251.13206176664,
              5005340.1927540172
            ],
            [
              359243.25517314364,
              5005332.2616232904
            ],
            [
              359238.64748655044,
              5005318.270207122
            ],
            [
              359216.15980695991,
              5005317.3406096259
            ],
            [
              359201.45595307782,
              5005315.9745562812
            ],
            [
              359188.38186698093,
              5005313.2658341108
            ],
            [
              359185.62633141421,
              5005288.5638432121
            ],
            [
              359189.21303945256,
              5005285.9839182515
            ],
            [
              359189.48432535719,
              5005279.5666536391
            ],
            [
              359175.94062064792,
              5005278.9941017888
            ],
            [
              359164.88027068699,
              5005280.8114176607
            ],
            [
              359152.46439414989,
              5005282.8514589267
            ],
            [
              359142.19078360061,
              5005284.5395064913
            ],
            [
              359145.2189097667,
              5005295.7593108118
            ],
            [
              359138.44492046488,
              5005298.4441446131
            ],
            [
              359113.56684503856,
              5005312.7218266614
            ],
            [
              359116.73093371902,
              5005320.2251495803
            ],
            [
              359121.16739823128,
              5005327.0530826021
            ],
            [
              359117.69071754621,
              5005332.8427834781
            ],
            [
              359122.33330443274,
              5005342.9098705091
            ],
            [
              359122.51666382462,
              5005414.994118236
            ],
            [
              359150.90612702904,
              5005411.2668220056
            ],
            [
              359162.28499057732,
              5005411.05526252
            ],
            [
              359171.25444058125,
              5005417.3084865306
            ],
            [
              359205.66642580979,
              5005422.3983674692
            ],
            [
              359211.23112124408,
              5005421.9608178874
            ],
            [
              359217.20298124215,
              5005421.5156981749
            ],
            [
              359225.11889599438,
              5005421.368522713
            ],
            [
              359236.72915145225,
              5005417.0342885787
            ],
            [
              359241.67122800223,
              5005415.9126677169
            ],
            [
              359245.74981097772,
              5005422.2850557044
            ],
            [
              359247.26902205165,
              5005421.7255999604
            ],
            [
              359252.61293670075,
              5005431.2968614716
            ],
            [
              359260.33697073982,
              5005438.7359537845
            ],
            [
              359262.75783899258,
              5005440.5469172392
            ],
            [
              359266.64176928235,
              5005443.452344127
            ],
            [
              359267.95145839214,
              5005444.43207487
            ],
            [
              359268.64509564155,
              5005444.9509596415
            ],
            [
              359272.5682178514,
              5005447.4353883713
            ],
            [
              359277.94317453477,
              5005450.8392325109
            ],
            [
              359280.75772087224,
              5005445.9051827984
            ],
            [
              359283.57226725685,
              5005440.9711331697
            ],
            [
              359290.96252756641,
              5005439.1408069776
            ],
            [
              359290.10336335801,
              5005429.5423850492
            ],
            [
              359290.93272609083,
              5005420.8791119317
            ],
            [
              359289.4856686854,
              5005415.2313164342
            ],
            [
              359298.91277613287,
              5005411.4448816646
            ],
            [
              359294.97718359291,
              5005406.9018667908
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 246,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360593.1769592599,
              5003694.5698468266
            ],
            [
              360608.79784777609,
              5003660.24339577
            ],
            [
              360616.04369227652,
              5003642.073416939
            ],
            [
              360624.17578097427,
              5003629.3759867232
            ],
            [
              360633.6461556803,
              5003617.5943696797
            ],
            [
              360644.24806631129,
              5003604.3611205248
            ],
            [
              360661.25903425104,
              5003582.6179121444
            ],
            [
              360667.6568206065,
              5003574.0934447078
            ],
            [
              360681.28828856634,
              5003561.8165586004
            ],
            [
              360695.58697797172,
              5003544.4282763917
            ],
            [
              360716.37936873059,
              5003509.368608701
            ],
            [
              360725.20285381802,
              5003494.1564749917
            ],
            [
              360727.35370657389,
              5003486.3579664053
            ],
            [
              360733.43628738419,
              5003471.7870063251
            ],
            [
              360736.24782287673,
              5003463.4290933339
            ],
            [
              360742.11545485863,
              5003442.8983004056
            ],
            [
              360723.9062655734,
              5003427.6346546188
            ],
            [
              360734.84672979341,
              5003414.6239816109
            ],
            [
              360721.547719069,
              5003402.9794264166
            ],
            [
              360711.96789244364,
              5003417.9648863925
            ],
            [
              360691.48405551788,
              5003407.5324093876
            ],
            [
              360685.89280886168,
              5003407.6046009297
            ],
            [
              360671.46421592619,
              5003397.035032358
            ],
            [
              360688.55068115663,
              5003373.0089791538
            ],
            [
              360676.63419845706,
              5003359.1242409945
            ],
            [
              360656.95384741708,
              5003343.7771248044
            ],
            [
              360638.12968344882,
              5003363.5130349752
            ],
            [
              360646.07844620553,
              5003373.7725734403
            ],
            [
              360634.68390377663,
              5003388.5422114609
            ],
            [
              360619.51882686786,
              5003418.4133149441
            ],
            [
              360620.19934368,
              5003422.0738028139
            ],
            [
              360611.21251393913,
              5003432.8521584421
            ],
            [
              360603.11086631397,
              5003448.572709037
            ],
            [
              360593.73546894692,
              5003471.398056251
            ],
            [
              360593.1200435338,
              5003482.2248610146
            ],
            [
              360590.06627901894,
              5003482.6897777524
            ],
            [
              360586.17135747959,
              5003493.8451355295
            ],
            [
              360576.94565958634,
              5003491.7718853233
            ],
            [
              360576.17168093513,
              5003505.0504420931
            ],
            [
              360579.24124945031,
              5003516.4209563714
            ],
            [
              360585.76919913507,
              5003525.7668693401
            ],
            [
              360568.55177730677,
              5003539.9531084513
            ],
            [
              360551.33435520664,
              5003554.1393489856
            ],
            [
              360556.32035849564,
              5003569.7595811756
            ],
            [
              360558.77406894841,
              5003581.3834601426
            ],
            [
              360552.46381900454,
              5003604.3558857515
            ],
            [
              360549.86440999265,
              5003618.28053944
            ],
            [
              360490.423525461,
              5003566.4616529103
            ],
            [
              360478.74874587997,
              5003564.4339598902
            ],
            [
              360471.03542334115,
              5003572.7997985082
            ],
            [
              360432.87331622239,
              5003543.4387546489
            ],
            [
              360421.52925987158,
              5003559.9868955249
            ],
            [
              360414.87585452519,
              5003556.3906379566
            ],
            [
              360421.17195307318,
              5003547.4904961074
            ],
            [
              360415.34437346243,
              5003543.2161225723
            ],
            [
              360418.69037926977,
              5003536.3535885625
            ],
            [
              360404.37494517356,
              5003550.207225604
            ],
            [
              360408.29195187899,
              5003552.275314495
            ],
            [
              360403.73918722372,
              5003561.6105645541
            ],
            [
              360388.41935257125,
              5003594.137387
            ],
            [
              360368.11217771977,
              5003589.0051283911
            ],
            [
              360353.63323456829,
              5003589.8864501342
            ],
            [
              360352.72601611487,
              5003590.2181918724
            ],
            [
              360358.57422278274,
              5003596.8207488731
            ],
            [
              360382.49332227715,
              5003623.8251301022
            ],
            [
              360373.27075180312,
              5003637.3322287407
            ],
            [
              360374.88661632611,
              5003639.332259112
            ],
            [
              360366.87815972202,
              5003646.6138276001
            ],
            [
              360357.26943298744,
              5003652.312312765
            ],
            [
              360345.39117796061,
              5003662.4483273225
            ],
            [
              360322.30726580956,
              5003627.9857679978
            ],
            [
              360320.77115799428,
              5003628.9373531202
            ],
            [
              360315.38860058441,
              5003633.7441316759
            ],
            [
              360334.58703654405,
              5003666.3750442062
            ],
            [
              360326.30467139132,
              5003670.8347808234
            ],
            [
              360320.20050503412,
              5003660.2210861472
            ],
            [
              360304.69836202246,
              5003670.1970719397
            ],
            [
              360298.16207724059,
              5003656.1347785583
            ],
            [
              360296.1538640316,
              5003657.5848248536
            ],
            [
              360285.85406390217,
              5003670.7329468839
            ],
            [
              360296.02887090965,
              5003696.8652192652
            ],
            [
              360294.32406739582,
              5003697.5289980397
            ],
            [
              360278.21862734389,
              5003705.5145986648
            ],
            [
              360281.89714016096,
              5003741.3730403688
            ],
            [
              360282.60420313111,
              5003746.4614850292
            ],
            [
              360289.98449095112,
              5003767.3966251686
            ],
            [
              360306.67562906683,
              5003786.6765148556
            ],
            [
              360313.30193267536,
              5003802.6744233929
            ],
            [
              360337.40710120258,
              5003830.9149711346
            ],
            [
              360354.38853673637,
              5003812.8770311559
            ],
            [
              360397.09294192161,
              5003776.2510943124
            ],
            [
              360393.76345135347,
              5003771.5231916578
            ],
            [
              360402.20346341649,
              5003763.0546353096
            ],
            [
              360437.44207192544,
              5003801.140467721
            ],
            [
              360446.43716773967,
              5003790.3955545919
            ],
            [
              360505.06760177051,
              5003767.608948471
            ],
            [
              360518.71363995568,
              5003756.1159490468
            ],
            [
              360573.25784632651,
              5003720.294027634
            ],
            [
              360593.1769592599,
              5003694.5698468266
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 247,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360519.54675163858,
              5002506.0835223561
            ],
            [
              360517.36924837984,
              5002506.9817786627
            ],
            [
              360522.0366871524,
              5002515.7386637013
            ],
            [
              360522.34849962493,
              5002520.1143415188
            ],
            [
              360526.25874719635,
              5002533.0650237715
            ],
            [
              360531.62955809437,
              5002546.3756898278
            ],
            [
              360538.80238835531,
              5002545.8694512667
            ],
            [
              360545.06600026158,
              5002543.6137163294
            ],
            [
              360547.87053695426,
              5002548.9691988071
            ],
            [
              360548.98697524535,
              5002563.0046517467
            ],
            [
              360563.5865964861,
              5002557.3964039525
            ],
            [
              360563.97297902958,
              5002560.5092415838
            ],
            [
              360577.85661419627,
              5002558.2258750908
            ],
            [
              360577.1945930426,
              5002554.1955588087
            ],
            [
              360590.66553562979,
              5002552.4455327457
            ],
            [
              360590.24739955814,
              5002547.4978295872
            ],
            [
              360586.25367751654,
              5002532.2235483211
            ],
            [
              360614.9445639911,
              5002535.0809234511
            ],
            [
              360619.48480817646,
              5002546.4622284938
            ],
            [
              360633.90939953312,
              5002544.8996396642
            ],
            [
              360634.30941757176,
              5002546.9116674894
            ],
            [
              360651.75800773996,
              5002543.8400037298
            ],
            [
              360650.2702081349,
              5002535.743644149
            ],
            [
              360663.12781046628,
              5002533.2680826271
            ],
            [
              360673.92962113762,
              5002532.4520252803
            ],
            [
              360682.642801137,
              5002532.8175378609
            ],
            [
              360687.73601484584,
              5002518.2190721594
            ],
            [
              360688.0164028132,
              5002509.9714491833
            ],
            [
              360702.81124836177,
              5002496.7934257267
            ],
            [
              360713.93698336737,
              5002508.7380830674
            ],
            [
              360733.10008213483,
              5002477.3668160755
            ],
            [
              360755.48265680909,
              5002489.9835363785
            ],
            [
              360791.71805599466,
              5002421.0188403986
            ],
            [
              360890.31752332597,
              5002372.2457563421
            ],
            [
              360860.52689236548,
              5002339.170977083
            ],
            [
              360858.14558467729,
              5002333.7020889707
            ],
            [
              360855.37914954894,
              5002328.4866043702
            ],
            [
              360854.33280929993,
              5002328.7687057201
            ],
            [
              360850.81266346056,
              5002329.8446119698
            ],
            [
              360847.18772903417,
              5002330.7203746317
            ],
            [
              360844.47088923794,
              5002331.4782341821
            ],
            [
              360843.46162840346,
              5002331.598052864
            ],
            [
              360842.65651175793,
              5002331.8151055584
            ],
            [
              360841.75029453228,
              5002332.0340370396
            ],
            [
              360840.64931901527,
              5002332.6608052049
            ],
            [
              360838.15345214104,
              5002334.4250486037
            ],
            [
              360833.7004331699,
              5002337.6949364962
            ],
            [
              360829.5044301204,
              5002340.5013000295
            ],
            [
              360823.6009135865,
              5002343.9456678424
            ],
            [
              360820.20064057148,
              5002346.0298725329
            ],
            [
              360816.48962289322,
              5002347.7156345071
            ],
            [
              360812.77290790249,
              5002349.0983837387
            ],
            [
              360809.35392854136,
              5002350.1724103056
            ],
            [
              360804.92950936122,
              5002351.5683111381
            ],
            [
              360800.91130302969,
              5002353.057690898
            ],
            [
              360797.49795525678,
              5002354.4347671
            ],
            [
              360795.18746053195,
              5002355.2861033725
            ],
            [
              360793.1727590831,
              5002355.7277608775
            ],
            [
              360791.26103500737,
              5002356.268532428
            ],
            [
              360789.95034969924,
              5002356.4950159537
            ],
            [
              360788.74451856175,
              5002356.9216063479
            ],
            [
              360787.74470976595,
              5002357.5464946656
            ],
            [
              360786.8553212547,
              5002358.6745761903
            ],
            [
              360785.3856102241,
              5002361.2281499784
            ],
            [
              360782.65777600702,
              5002366.8366463436
            ],
            [
              360781.77220801712,
              5002368.1667478867
            ],
            [
              360780.97453416081,
              5002368.7878798805
            ],
            [
              360780.07019425282,
              5002369.1078398703
            ],
            [
              360778.35504027194,
              5002369.341839876
            ],
            [
              360773.95162166463,
              5002370.0061973985
            ],
            [
              360769.51030632283,
              5002370.492914346
            ],
            [
              360766.88705743576,
              5002370.8448176226
            ],
            [
              360765.7823274965,
              5002371.2695648354
            ],
            [
              360764.47915060743,
              5002371.9000908621
            ],
            [
              360762.48509772745,
              5002373.4528829576
            ],
            [
              360760.59965527692,
              5002375.4079097761
            ],
            [
              360759.81331106677,
              5002376.635139782
            ],
            [
              360759.44075524056,
              5002378.3599254005
            ],
            [
              360758.85480103508,
              5002379.4823682066
            ],
            [
              360758.16385946813,
              5002380.4046710618
            ],
            [
              360757.26702802908,
              5002381.1286735423
            ],
            [
              360756.36081060977,
              5002381.3476052089
            ],
            [
              360754.74487966305,
              5002381.4786981102
            ],
            [
              360753.73568483454,
              5002381.5985157406
            ],
            [
              360752.73205481115,
              5002382.0213486664
            ],
            [
              360751.62926855311,
              5002382.5471230811
            ],
            [
              360751.13308525895,
              5002383.061589146
            ],
            [
              360750.73424830864,
              5002383.3721551867
            ],
            [
              360750.3317226909,
              5002383.4806632437
            ],
            [
              360749.72693027096,
              5002383.592965669
            ],
            [
              360749.12589210912,
              5002383.9072893197
            ],
            [
              360748.22906131472,
              5002384.631327359
            ],
            [
              360747.64304085902,
              5002385.7537714457
            ],
            [
              360747.37346247229,
              5002387.5776711758
            ],
            [
              360747.50084424211,
              5002388.9900120106
            ],
            [
              360747.9372258131,
              5002390.6997648031
            ],
            [
              360748.070239325,
              5002392.4151552375
            ],
            [
              360748.29684491543,
              5002393.7246244205
            ],
            [
              360748.22014803503,
              5002395.0396944815
            ],
            [
              360748.0442280203,
              5002396.4576717308
            ],
            [
              360747.28979679657,
              5002399.4021710707
            ],
            [
              360745.38585175975,
              5002405.803757092
            ],
            [
              360744.6114151674,
              5002409.0126795955
            ],
            [
              360743.39083632984,
              5002414.0878859945
            ],
            [
              360742.34242320625,
              5002417.543093917
            ],
            [
              360741.67219749902,
              5002419.5765657164
            ],
            [
              360740.88960768632,
              5002421.0058171181
            ],
            [
              360739.80177299137,
              5002422.3397133537
            ],
            [
              360738.50422719342,
              5002423.273253696
            ],
            [
              360735.91101438407,
              5002425.2414338607
            ],
            [
              360734.41314483428,
              5002426.2797602387
            ],
            [
              360733.31029233406,
              5002426.8055360559
            ],
            [
              360732.10452733835,
              5002427.2321255384
            ],
            [
              360730.89494180138,
              5002427.4566950211
            ],
            [
              360728.8764857839,
              5002427.6963317981
            ],
            [
              360726.35433725716,
              5002428.0463567404
            ],
            [
              360723.92578009184,
              5002427.9904248593
            ],
            [
              360720.0798712598,
              5002427.859804783
            ],
            [
              360718.36096207227,
              5002427.8917483483
            ],
            [
              360716.64775088982,
              5002428.2267403109
            ],
            [
              360715.64036652859,
              5002428.4475521753
            ],
            [
              360714.73602650472,
              5002428.7675123848
            ],
            [
              360713.53770443128,
              5002429.598181149
            ],
            [
              360712.35440017103,
              5002431.236970434
            ],
            [
              360710.2798968915,
              5002433.902847616
            ],
            [
              360709.58902162529,
              5002434.8251494803
            ],
            [
              360708.78765968425,
              5002435.2442592839
            ],
            [
              360707.57807407004,
              5002435.4688288113
            ],
            [
              360705.15514853812,
              5002435.7159466213
            ],
            [
              360701.71927373635,
              5002435.8808609946
            ],
            [
              360700.3056100434,
              5002436.0081953062
            ],
            [
              360699.29822563933,
              5002436.2290072059
            ],
            [
              360697.88831622916,
              5002436.5583627727
            ],
            [
              360695.37937409262,
              5002437.6154787382
            ],
            [
              360693.22367790161,
              5002438.6440969296
            ],
            [
              360691.62658506207,
              5002439.7853307109
            ],
            [
              360690.04256699153,
              5002441.6336934408
            ],
            [
              360688.27511982311,
              5002444.4959556572
            ],
            [
              360687.01143146935,
              5002447.247828465
            ],
            [
              360686.25317933649,
              5002449.9902728759
            ],
            [
              360685.85843374813,
              5002455.9595961962
            ],
            [
              360685.87532910722,
              5002456.8687451649
            ],
            [
              360685.98769330367,
              5002457.4729656531
            ],
            [
              360686.30789053655,
              5002458.3764781486
            ],
            [
              360687.0306790325,
              5002459.1714457143
            ],
            [
              360687.75722249405,
              5002460.1684700642
            ],
            [
              360688.27968722972,
              5002461.0682236701
            ],
            [
              360688.39580574149,
              5002461.8744654115
            ],
            [
              360688.41270111635,
              5002462.7836143738
            ],
            [
              360688.02707082703,
              5002463.8012715075
            ],
            [
              360687.33431811194,
              5002464.6225450942
            ],
            [
              360686.43929789984,
              5002465.4475775706
            ],
            [
              360684.83657398378,
              5002466.285797378
            ],
            [
              360681.23034412455,
              5002468.1717049815
            ],
            [
              360678.62586754578,
              5002469.5337864859
            ],
            [
              360677.72340428975,
              5002469.9547397019
            ],
            [
              360677.02314294304,
              5002470.371970797
            ],
            [
              360676.02708834404,
              5002471.1988809127
            ],
            [
              360675.53278256149,
              5002471.8143756175
            ],
            [
              360675.03659929207,
              5002472.3288419265
            ],
            [
              360674.03296981181,
              5002472.7517107502
            ],
            [
              360671.9152896913,
              5002473.09421953
            ],
            [
              360669.58977650176,
              5002473.1374363024
            ],
            [
              360666.25500133267,
              5002473.3004367007
            ],
            [
              360664.33764559729,
              5002473.5381949674
            ],
            [
              360662.62624476611,
              5002473.9741813103
            ],
            [
              360659.90377202904,
              5002474.428992725
            ],
            [
              360657.88719245576,
              5002474.7696227022
            ],
            [
              360656.47728291369,
              5002475.0989784673
            ],
            [
              360654.76782578445,
              5002475.6359920027
            ],
            [
              360652.35053171561,
              5002476.1861598231
            ],
            [
              360649.66023338633,
              5002477.0741670281
            ],
            [
              360646.94151486811,
              5002477.7309997678
            ],
            [
              360644.62532204197,
              5002478.2793243174
            ],
            [
              360643.21728927776,
              5002478.7096729856
            ],
            [
              360642.00776971428,
              5002478.9342414802
            ],
            [
              360640.79630706558,
              5002479.057818329
            ],
            [
              360638.8751962143,
              5002479.0935198208
            ],
            [
              360635.93919215334,
              5002478.9459908018
            ],
            [
              360629.76019331237,
              5002478.4545111945
            ],
            [
              360627.83532808174,
              5002478.2881913893
            ],
            [
              360626.41603163473,
              5002478.1124406606
            ],
            [
              360625.09971397225,
              5002478.0358750531
            ],
            [
              360623.98754066823,
              5002478.0565434266
            ],
            [
              360622.67685414234,
              5002478.2829919904
            ],
            [
              360621.47102323297,
              5002478.70961856
            ],
            [
              360619.46946137247,
              5002479.8583693244
            ],
            [
              360618.37036224507,
              5002480.5861312523
            ],
            [
              360617.16453132313,
              5002481.012757835
            ],
            [
              360615.54866535292,
              5002481.1438144958
            ],
            [
              360614.438303256,
              5002481.2655125316
            ],
            [
              360613.42347624281,
              5002481.0822807839
            ],
            [
              360611.69142541935,
              5002480.4070967622
            ],
            [
              360606.08155110659,
              5002477.8840232585
            ],
            [
              360603.62858903082,
              5002476.5148999123
            ],
            [
              360602.40405139752,
              5002475.9313476551
            ],
            [
              360601.08210183901,
              5002475.5517322989
            ],
            [
              360599.66092845489,
              5002475.2749886597
            ],
            [
              360598.14046565228,
              5002475.1011535032
            ],
            [
              360596.42162211298,
              5002475.133096084
            ],
            [
              360594.70834411733,
              5002475.4680896364
            ],
            [
              360592.19745760929,
              5002476.424179011
            ],
            [
              360589.08935396158,
              5002477.8966479795
            ],
            [
              360583.07528231601,
              5002480.8378651021
            ],
            [
              360579.27435946482,
              5002483.1316092648
            ],
            [
              360576.9999029396,
              5002484.6312437747
            ],
            [
              360575.80540175858,
              5002485.6639687
            ],
            [
              360573.71393638005,
              5002487.4206992351
            ],
            [
              360572.61490403529,
              5002488.1484955875
            ],
            [
              360570.81178825087,
              5002489.0914320406
            ],
            [
              360568.70161682373,
              5002489.8380192202
            ],
            [
              360566.38730032317,
              5002490.4873368759
            ],
            [
              360564.37259705085,
              5002490.928959934
            ],
            [
              360562.6612626565,
              5002491.3649807982
            ],
            [
              360561.55277722614,
              5002491.5876717865
            ],
            [
              360560.14849912614,
              5002492.2200774811
            ],
            [
              360556.7445363382,
              5002494.1022626767
            ],
            [
              360551.53558083024,
              5002496.8263915582
            ],
            [
              360546.94080416305,
              5002499.9432889437
            ],
            [
              360545.04597443395,
              5002501.3932110267
            ],
            [
              360544.34758969228,
              5002501.9114352474
            ],
            [
              360543.34402611997,
              5002502.3343030876
            ],
            [
              360542.5370320449,
              5002502.4503634712
            ],
            [
              360541.02032330976,
              5002502.4785497496
            ],
            [
              360533.63361404539,
              5002502.3126687538
            ],
            [
              360532.62441795127,
              5002502.4324512817
            ],
            [
              360532.1169715228,
              5002502.3408538019
            ],
            [
              360531.70687076828,
              5002502.0453205975
            ],
            [
              360531.1976130929,
              5002501.8526934423
            ],
            [
              360530.38867524039,
              5002501.8677266147
            ],
            [
              360529.38323347067,
              5002502.1895304946
            ],
            [
              360527.17196114809,
              5002502.9379966827
            ],
            [
              360519.54675163858,
              5002506.0835223561
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 248,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360548.98697524407,
              5002563.0046517476
            ],
            [
              360552.0503715814,
              5002613.9005584102
            ],
            [
              360554.43012684101,
              5002625.6211058376
            ],
            [
              360550.41786767635,
              5002629.4571933653
            ],
            [
              360563.87119643559,
              5002646.8533959985
            ],
            [
              360587.51471602073,
              5002677.4357951209
            ],
            [
              360611.97453145852,
              5002708.630493396
            ],
            [
              360609.22503519832,
              5002711.4827043423
            ],
            [
              360618.35629151791,
              5002720.2766630007
            ],
            [
              360594.78633761063,
              5002740.0560674379
            ],
            [
              360610.06447781058,
              5002749.1153681679
            ],
            [
              360631.98274877697,
              5002764.0667538485
            ],
            [
              360648.2684721555,
              5002777.5226702699
            ],
            [
              360694.6630381748,
              5002826.9662700025
            ],
            [
              360731.48891127319,
              5002789.2240069322
            ],
            [
              360739.0282073135,
              5002784.1522607356
            ],
            [
              360758.65821057482,
              5002777.2489159219
            ],
            [
              360774.57806503383,
              5002772.3005553661
            ],
            [
              360789.70579640864,
              5002763.6222834606
            ],
            [
              360809.73968402616,
              5002749.0948331701
            ],
            [
              360815.8969227054,
              5002744.7931579156
            ],
            [
              360821.69847631734,
              5002738.320752725
            ],
            [
              360830.43830299418,
              5002730.6376749463
            ],
            [
              360842.52865233883,
              5002716.5885778554
            ],
            [
              360856.0618440102,
              5002702.5491903406
            ],
            [
              360862.76919022604,
              5002696.4407811258
            ],
            [
              360875.55758922169,
              5002681.6840543803
            ],
            [
              360894.5673564829,
              5002664.3047871944
            ],
            [
              360918.68358015042,
              5002679.8171670102
            ],
            [
              360929.89325162285,
              5002685.9790044753
            ],
            [
              360955.61497811659,
              5002696.2814492499
            ],
            [
              360989.45746601594,
              5002710.1174825113
            ],
            [
              361005.56232950196,
              5002717.1870947396
            ],
            [
              361028.7798818631,
              5002720.3428672822
            ],
            [
              361015.48737949273,
              5002747.0635109842
            ],
            [
              361025.90789968142,
              5002749.4626413183
            ],
            [
              361047.62906378234,
              5002764.8885039538
            ],
            [
              361058.89045461145,
              5002753.7623003479
            ],
            [
              361103.61528417235,
              5002771.5458430471
            ],
            [
              361124.38498957996,
              5002743.3453235039
            ],
            [
              361132.52855183464,
              5002733.4955346109
            ],
            [
              361144.41119105503,
              5002720.131860855
            ],
            [
              361160.95701899234,
              5002703.2543822946
            ],
            [
              361176.24357042805,
              5002689.2044571489
            ],
            [
              361190.45287946338,
              5002677.8340405179
            ],
            [
              361205.74846011511,
              5002665.8765079416
            ],
            [
              361201.59319201083,
              5002655.5223188214
            ],
            [
              361184.34971924248,
              5002632.9979396965
            ],
            [
              361174.83041231503,
              5002626.9457479836
            ],
            [
              361156.63425626891,
              5002615.883472967
            ],
            [
              361135.27809371409,
              5002600.7199233016
            ],
            [
              361140.5213677822,
              5002591.8077127691
            ],
            [
              361106.09878850396,
              5002562.7801217418
            ],
            [
              361110.01423130656,
              5002558.9137624875
            ],
            [
              361086.12291380047,
              5002532.4433655431
            ],
            [
              361088.31479940721,
              5002530.1695523066
            ],
            [
              361072.54126098595,
              5002518.904536033
            ],
            [
              361049.99811280431,
              5002508.0405832613
            ],
            [
              361026.63223853539,
              5002497.1918648975
            ],
            [
              361042.6257482465,
              5002473.104385077
            ],
            [
              361020.68220283434,
              5002451.485504074
            ],
            [
              360917.28353499959,
              5002402.0771350898
            ],
            [
              360890.31752332603,
              5002372.245756343
            ],
            [
              360791.71805599291,
              5002421.0188403996
            ],
            [
              360755.48265680909,
              5002489.9835363785
            ],
            [
              360733.10008213361,
              5002477.3668160755
            ],
            [
              360713.93698336784,
              5002508.7380830664
            ],
            [
              360702.81124836177,
              5002496.7934257267
            ],
            [
              360688.01640281343,
              5002509.9714491861
            ],
            [
              360687.73601484584,
              5002518.2190721603
            ],
            [
              360682.642801137,
              5002532.8175378609
            ],
            [
              360673.92962114239,
              5002532.4520252803
            ],
            [
              360663.12781046733,
              5002533.2680826271
            ],
            [
              360650.27020813333,
              5002535.7436441481
            ],
            [
              360651.75800773996,
              5002543.8400037307
            ],
            [
              360634.30941757176,
              5002546.9116674894
            ],
            [
              360633.90939953382,
              5002544.8996396642
            ],
            [
              360619.48480817646,
              5002546.4622284938
            ],
            [
              360614.9445639911,
              5002535.0809234511
            ],
            [
              360586.25367751654,
              5002532.2235483211
            ],
            [
              360590.24739955628,
              5002547.4978295853
            ],
            [
              360590.66553562903,
              5002552.4455327438
            ],
            [
              360577.1945930426,
              5002554.1955588087
            ],
            [
              360577.85661419551,
              5002558.2258750899
            ],
            [
              360563.9729790308,
              5002560.5092415838
            ],
            [
              360563.5865964861,
              5002557.3964039544
            ],
            [
              360548.98697524407,
              5002563.0046517476
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 249,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360671.94346418203,
              5003069.9793366147
            ],
            [
              360637.74402517505,
              5003047.1666663736
            ],
            [
              360605.63771667291,
              5003027.0289791161
            ],
            [
              360618.43160274596,
              5003012.2770105228
            ],
            [
              360620.75090413349,
              5003010.558066519
            ],
            [
              360621.82092777267,
              5003007.4643544359
            ],
            [
              360601.27999931149,
              5002999.8704923671
            ],
            [
              360591.68662929122,
              5002985.1925683599
            ],
            [
              360583.96631386026,
              5002990.1709084632
            ],
            [
              360570.25120270724,
              5002999.8183029555
            ],
            [
              360549.27488881681,
              5003011.3560005939
            ],
            [
              360513.6262417924,
              5003033.5710156495
            ],
            [
              360474.4766802786,
              5003069.2783713583
            ],
            [
              360473.17179122305,
              5003070.0261679124
            ],
            [
              360476.02555808082,
              5003071.9486791994
            ],
            [
              360504.0863415908,
              5003090.8525253003
            ],
            [
              360507.01389818458,
              5003091.5416972553
            ],
            [
              360490.96507674048,
              5003112.2932275375
            ],
            [
              360515.95143639139,
              5003130.856170875
            ],
            [
              360518.72997312917,
              5003128.5203518309
            ],
            [
              360538.34491558239,
              5003149.8740590671
            ],
            [
              360549.09635511308,
              5003160.9882256193
            ],
            [
              360552.7043229331,
              5003157.5406456646
            ],
            [
              360559.63141960162,
              5003162.4688518438
            ],
            [
              360568.65030447958,
              5003155.0133714145
            ],
            [
              360580.18344603077,
              5003143.0492798723
            ],
            [
              360586.02966065489,
              5003137.0673244111
            ],
            [
              360593.27096018038,
              5003128.0631488403
            ],
            [
              360595.3887239552,
              5003126.0574047854
            ],
            [
              360603.71466045914,
              5003130.8092547376
            ],
            [
              360608.47415971203,
              5003133.5933771962
            ],
            [
              360621.97995944513,
              5003125.5784996217
            ],
            [
              360631.85468150239,
              5003115.1077373894
            ],
            [
              360639.3540145912,
              5003109.1517731361
            ],
            [
              360647.42317708657,
              5003100.3763822364
            ],
            [
              360654.95993711887,
              5003085.8595192544
            ],
            [
              360671.94346418203,
              5003069.9793366147
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 250,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356815.31266967952,
              5007313.2107646819
            ],
            [
              356836.41398458689,
              5007289.0545464354
            ],
            [
              356852.48747027939,
              5007269.5926597444
            ],
            [
              356882.59890302294,
              5007232.3919856623
            ],
            [
              356891.89626297244,
              5007219.4730840689
            ],
            [
              356899.15240773908,
              5007208.9071209561
            ],
            [
              356908.13338692952,
              5007196.9026039634
            ],
            [
              356841.43906458776,
              5007217.1198138343
            ],
            [
              356838.69137141696,
              5007223.4848266253
            ],
            [
              356836.53298652743,
              5007239.6796647273
            ],
            [
              356797.09678027494,
              5007211.3568312312
            ],
            [
              356791.13166860258,
              5007205.497551362
            ],
            [
              356779.26238323504,
              5007197.0556599535
            ],
            [
              356775.95946772461,
              5007200.3968153065
            ],
            [
              356772.39678357105,
              5007203.870222155
            ],
            [
              356757.38927305158,
              5007191.0319957798
            ],
            [
              356757.59116626653,
              5007192.9803455053
            ],
            [
              356737.91176251974,
              5007180.2353007523
            ],
            [
              356725.74089727475,
              5007168.1700300155
            ],
            [
              356718.02845095115,
              5007168.8565928247
            ],
            [
              356716.28035476519,
              5007171.6191852195
            ],
            [
              356702.82766907796,
              5007159.2026135819
            ],
            [
              356683.1565155483,
              5007164.3678832529
            ],
            [
              356681.77064725413,
              5007164.8116433062
            ],
            [
              356677.17956065788,
              5007165.3150451733
            ],
            [
              356667.95431832393,
              5007179.0021979669
            ],
            [
              356660.6177233315,
              5007190.5790442564
            ],
            [
              356653.93061375269,
              5007198.0373322042
            ],
            [
              356649.41677200765,
              5007203.0674189758
            ],
            [
              356637.65395641612,
              5007222.0474049123
            ],
            [
              356620.17745767563,
              5007209.0690242695
            ],
            [
              356598.28476125264,
              5007238.2863652157
            ],
            [
              356592.42394284945,
              5007241.2379741622
            ],
            [
              356581.24228239211,
              5007247.3291410301
            ],
            [
              356579.84675875545,
              5007247.9923836095
            ],
            [
              356573.16107370605,
              5007251.4885017211
            ],
            [
              356563.94492239313,
              5007254.2393331518
            ],
            [
              356545.82326156896,
              5007263.9409816554
            ],
            [
              356543.52161439793,
              5007266.9795024628
            ],
            [
              356543.58051856875,
              5007270.1467759712
            ],
            [
              356538.48131621897,
              5007274.7326919101
            ],
            [
              356531.21564745461,
              5007281.2669976698
            ],
            [
              356524.46032935189,
              5007291.7173716128
            ],
            [
              356515.24695614836,
              5007298.9240881298
            ],
            [
              356510.38225984381,
              5007302.7292621527
            ],
            [
              356530.87316282489,
              5007328.6704293666
            ],
            [
              356533.84054862935,
              5007326.1443119915
            ],
            [
              356551.68543705629,
              5007343.1143339965
            ],
            [
              356581.95312161249,
              5007367.9398630466
            ],
            [
              356573.5952054839,
              5007376.4678485952
            ],
            [
              356567.13462970738,
              5007381.5595625378
            ],
            [
              356575.9376506366,
              5007389.752235923
            ],
            [
              356593.43832227995,
              5007408.1293056766
            ],
            [
              356629.96747478639,
              5007431.5683400454
            ],
            [
              356639.32208149647,
              5007436.066374355
            ],
            [
              356652.69501329964,
              5007454.8849853836
            ],
            [
              356669.75684379687,
              5007436.5715281973
            ],
            [
              356682.91072306089,
              5007432.9813825982
            ],
            [
              356690.16945444548,
              5007450.7714051399
            ],
            [
              356697.49339983548,
              5007444.9695504708
            ],
            [
              356723.18333383818,
              5007416.9519225201
            ],
            [
              356756.28190559481,
              5007380.1986595411
            ],
            [
              356765.62118422525,
              5007369.3220588751
            ],
            [
              356780.21233448508,
              5007354.096109556
            ],
            [
              356795.67672846402,
              5007335.4296211973
            ],
            [
              356815.31266967952,
              5007313.2107646819
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 251,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356916.58304866531,
              5007195.7234915048
            ],
            [
              356925.7287210981,
              5007182.7120016366
            ],
            [
              356892.67542956403,
              5007146.5082762372
            ],
            [
              356877.61053412012,
              5007158.3073712364
            ],
            [
              356845.56854152749,
              5007183.0516513642
            ],
            [
              356837.22788683191,
              5007189.5659749676
            ],
            [
              356827.54445726803,
              5007196.8966922434
            ],
            [
              356821.65932271112,
              5007192.1107544089
            ],
            [
              356815.84269093501,
              5007180.5470549185
            ],
            [
              356806.7691104518,
              5007169.2012755694
            ],
            [
              356803.1648859136,
              5007164.5084469654
            ],
            [
              356796.86937145592,
              5007164.5263434434
            ],
            [
              356792.39496566966,
              5007175.847106047
            ],
            [
              356786.43547648302,
              5007189.1854220126
            ],
            [
              356782.48932999792,
              5007199.3507884163
            ],
            [
              356791.13166860369,
              5007205.4975513648
            ],
            [
              356797.09678027488,
              5007211.3568312302
            ],
            [
              356836.53298652743,
              5007239.6796647273
            ],
            [
              356838.69137141813,
              5007223.4848266197
            ],
            [
              356841.43906458927,
              5007217.1198138371
            ],
            [
              356854.19458041043,
              5007212.8697534958
            ],
            [
              356874.78622722224,
              5007207.0112079149
            ],
            [
              356886.53378994548,
              5007203.4501411207
            ],
            [
              356908.13338692952,
              5007196.9026039634
            ],
            [
              356916.58304866531,
              5007195.7234915048
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 252,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356767.34023507894,
              5006903.933754757
            ],
            [
              356788.68345652946,
              5006892.1967439065
            ],
            [
              356793.01717475365,
              5006891.3939466476
            ],
            [
              356794.81958786777,
              5006884.0501946285
            ],
            [
              356789.40053023509,
              5006881.7612598734
            ],
            [
              356783.59461399133,
              5006877.8775681583
            ],
            [
              356778.22696835978,
              5006868.378915865
            ],
            [
              356776.86562740384,
              5006864.536306425
            ],
            [
              356776.85814813134,
              5006858.9971877327
            ],
            [
              356779.03329334111,
              5006852.6534836031
            ],
            [
              356781.82420285704,
              5006848.5903931065
            ],
            [
              356776.12274366786,
              5006834.3260898748
            ],
            [
              356771.69924607268,
              5006823.2590777837
            ],
            [
              356755.85382389283,
              5006797.9043719871
            ],
            [
              356748.95403584436,
              5006790.2802720461
            ],
            [
              356695.06399034412,
              5006829.355741906
            ],
            [
              356696.47190699267,
              5006831.0295853456
            ],
            [
              356699.85649575474,
              5006839.1821629759
            ],
            [
              356699.85649575392,
              5006839.1821629759
            ],
            [
              356690.14102946065,
              5006847.2364030834
            ],
            [
              356680.54508925992,
              5006854.0825780844
            ],
            [
              356658.92098641803,
              5006870.4577838564
            ],
            [
              356648.4908998959,
              5006883.6065317979
            ],
            [
              356671.9934671488,
              5006900.8671606518
            ],
            [
              356680.98221519851,
              5006908.5499975495
            ],
            [
              356690.74538795528,
              5006920.1439354066
            ],
            [
              356704.40458166908,
              5006933.6956662787
            ],
            [
              356722.13579365396,
              5006952.1819430813
            ],
            [
              356726.99098472093,
              5006956.165157387
            ],
            [
              356739.26140685653,
              5006940.0284178974
            ],
            [
              356757.2803931596,
              5006917.0853472678
            ],
            [
              356766.33699293324,
              5006905.7667225078
            ],
            [
              356767.34023507894,
              5006903.933754757
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 253,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355455.86282702198,
              5007922.0647299038
            ],
            [
              355456.38103305892,
              5007922.7559246114
            ],
            [
              355501.06887791952,
              5007966.5275691329
            ],
            [
              355503.9457283229,
              5007973.0690899715
            ],
            [
              355511.90626962954,
              5007974.561469757
            ],
            [
              355529.31996147806,
              5007984.2624058397
            ],
            [
              355546.20336049731,
              5007997.9252239475
            ],
            [
              355547.65233016846,
              5008007.1940250006
            ],
            [
              355555.25503232953,
              5008009.0575807691
            ],
            [
              355565.75017429248,
              5008024.264149446
            ],
            [
              355572.91508547525,
              5008017.2957480624
            ],
            [
              355581.3997675603,
              5008007.7510523619
            ],
            [
              355589.96820710407,
              5008012.5129792066
            ],
            [
              355591.9396246231,
              5008010.6536064073
            ],
            [
              355609.4846223045,
              5008018.4547410514
            ],
            [
              355612.25078894361,
              5008007.3732006233
            ],
            [
              355618.1248021822,
              5007986.0264657037
            ],
            [
              355617.94646829378,
              5007976.4385380847
            ],
            [
              355621.31804590049,
              5007963.5473752841
            ],
            [
              355622.29408621631,
              5007959.5741067557
            ],
            [
              355627.05104091397,
              5007950.4842836596
            ],
            [
              355632.83568949561,
              5007931.912862435
            ],
            [
              355638.5387800507,
              5007920.1568676131
            ],
            [
              355633.13114028133,
              5007916.7806186695
            ],
            [
              355639.03494016308,
              5007910.3030891567
            ],
            [
              355619.58077457262,
              5007892.3061991399
            ],
            [
              355604.54787783063,
              5007878.2995777447
            ],
            [
              355588.34287733486,
              5007863.1641911929
            ],
            [
              355570.39222660015,
              5007847.0065722857
            ],
            [
              355558.2149474193,
              5007832.6127085714
            ],
            [
              355552.68031172507,
              5007826.080908333
            ],
            [
              355550.3123862886,
              5007828.242125432
            ],
            [
              355547.51691011962,
              5007830.2140916027
            ],
            [
              355544.8244814715,
              5007832.2852409873
            ],
            [
              355543.02700150263,
              5007833.5313019278
            ],
            [
              355541.72945885215,
              5007834.46490712
            ],
            [
              355540.33832968835,
              5007835.8044389086
            ],
            [
              355537.64959227626,
              5007838.0776126916
            ],
            [
              355536.4569749189,
              5007839.2113593332
            ],
            [
              355535.6119196972,
              5007839.7079763794
            ],
            [
              355534.71126749011,
              5007840.2299781926
            ],
            [
              355533.20588515315,
              5007840.8642919026
            ],
            [
              355531.69674529776,
              5007841.2965825358
            ],
            [
              355530.49090938474,
              5007841.7231963407
            ],
            [
              355529.09032049391,
              5007842.5576894889
            ],
            [
              355527.8920665172,
              5007843.3883837778
            ],
            [
              355527.1037890421,
              5007844.5146099748
            ],
            [
              355525.82127775217,
              5007846.256378673
            ],
            [
              355524.93196499592,
              5007847.3844840843
            ],
            [
              355523.9414844503,
              5007848.5144711658
            ],
            [
              355520.85585587885,
              5007851.1992130736
            ],
            [
              355516.39976680587,
              5007856.334738371
            ],
            [
              355508.97230239888,
              5007864.8602240887
            ],
            [
              355503.32551862724,
              5007872.7045064084
            ],
            [
              355499.18776443449,
              5007878.6424833313
            ],
            [
              355496.72008468577,
              5007881.9220466558
            ],
            [
              355495.23349127959,
              5007883.5665472159
            ],
            [
              355493.84605406906,
              5007885.1081392551
            ],
            [
              355491.85576511652,
              5007886.8630370814
            ],
            [
              355488.3638519239,
              5007889.4543075785
            ],
            [
              355487.36961386737,
              5007890.3822717937
            ],
            [
              355485.58340710576,
              5007892.234438112
            ],
            [
              355484.20167279785,
              5007894.0790814348
            ],
            [
              355483.31417294324,
              5007895.3082176615
            ],
            [
              355482.7338677948,
              5007896.7337331986
            ],
            [
              355481.65919569944,
              5007898.7747583203
            ],
            [
              355479.88614137127,
              5007901.3340589171
            ],
            [
              355477.3192392558,
              5007904.7165323664
            ],
            [
              355476.00381565245,
              5007906.431221447
            ],
            [
              355475.12383159046,
              5007908.0644395044
            ],
            [
              355473.95181554183,
              5007910.3093682732
            ],
            [
              355473.37332329591,
              5007911.8359144339
            ],
            [
              355472.58699128224,
              5007913.0631690603
            ],
            [
              355471.39618680609,
              5007914.297946739
            ],
            [
              355470.00130011229,
              5007915.4354560012
            ],
            [
              355468.501554302,
              5007916.3728226107
            ],
            [
              355467.29571892286,
              5007916.7994722929
            ],
            [
              355464.98514904891,
              5007917.6508556819
            ],
            [
              355462.27581026853,
              5007918.8128488874
            ],
            [
              355459.16575589211,
              5007920.1843527481
            ],
            [
              355455.86282702198,
              5007922.0647299038
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 254,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352303.83185805642,
              5010875.2173126303
            ],
            [
              352294.78080403374,
              5010867.2518043313
            ],
            [
              352294.96720090922,
              5010833.5352777243
            ],
            [
              352298.72395723459,
              5010809.1514407424
            ],
            [
              352298.51032804057,
              5010799.3727164706
            ],
            [
              352296.28464273701,
              5010790.4362679347
            ],
            [
              352304.66977754398,
              5010760.5793922385
            ],
            [
              352305.51423189166,
              5010752.0391127365
            ],
            [
              352310.93013139849,
              5010735.4402731406
            ],
            [
              352315.42884718155,
              5010741.5042464491
            ],
            [
              352326.23261314846,
              5010745.9972170098
            ],
            [
              352330.22817014856,
              5010739.2575207772
            ],
            [
              352326.73428248795,
              5010733.8029032545
            ],
            [
              352333.05606871034,
              5010725.8789123874
            ],
            [
              352327.38033638633,
              5010721.8842167826
            ],
            [
              352339.05407398409,
              5010711.7702108277
            ],
            [
              352346.9322659889,
              5010698.4553198619
            ],
            [
              352302.15946517495,
              5010671.5889474396
            ],
            [
              352297.64584217564,
              5010666.7110757297
            ],
            [
              352292.66598002909,
              5010657.0952765029
            ],
            [
              352286.03509769973,
              5010650.0014256183
            ],
            [
              352284.02242789132,
              5010652.9979154151
            ],
            [
              352280.46804641961,
              5010658.1804679632
            ],
            [
              352264.68023371993,
              5010652.6982955681
            ],
            [
              352259.5042208627,
              5010659.7493531238
            ],
            [
              352253.43717031355,
              5010663.280664335
            ],
            [
              352248.81891090336,
              5010655.9403491979
            ],
            [
              352235.41910587484,
              5010679.4424079768
            ],
            [
              352225.21146230586,
              5010669.6128157815
            ],
            [
              352222.14225210203,
              5010675.7995035714
            ],
            [
              352218.44408221828,
              5010679.8761510383
            ],
            [
              352201.45689159847,
              5010692.333587409
            ],
            [
              352199.00079899619,
              5010693.4401781745
            ],
            [
              352194.33098600636,
              5010694.319421323
            ],
            [
              352182.89315824769,
              5010679.8701638402
            ],
            [
              352143.69535773067,
              5010723.4194775335
            ],
            [
              352133.94416464685,
              5010738.4884089893
            ],
            [
              352126.46838160529,
              5010739.7466074508
            ],
            [
              352119.30326167966,
              5010744.9491335629
            ],
            [
              352112.31204358855,
              5010752.4457886601
            ],
            [
              352095.9327432047,
              5010770.6579329409
            ],
            [
              352079.29668838735,
              5010799.0732683288
            ],
            [
              352076.32052036381,
              5010796.8575794399
            ],
            [
              352071.23418402189,
              5010793.1038500387
            ],
            [
              352060.46634366101,
              5010802.3998348275
            ],
            [
              352050.24030509248,
              5010812.8808411872
            ],
            [
              352027.61730508396,
              5010849.3732271604
            ],
            [
              352011.46982039115,
              5010879.1485762233
            ],
            [
              352003.57257971523,
              5010892.2304674853
            ],
            [
              352018.59681869583,
              5010935.9144252306
            ],
            [
              352025.04435639345,
              5010949.6431302521
            ],
            [
              352029.92419120279,
              5010953.457515534
            ],
            [
              352033.65384607011,
              5010976.2184058847
            ],
            [
              352043.26862980291,
              5010997.1151665468
            ],
            [
              352054.69542852609,
              5011013.5746714883
            ],
            [
              352069.57459763647,
              5011029.8197251065
            ],
            [
              352074.56215214363,
              5011023.2683513146
            ],
            [
              352094.11621676682,
              5011039.224195363
            ],
            [
              352117.96052656573,
              5011056.2935456419
            ],
            [
              352135.44685914816,
              5011061.7600140851
            ],
            [
              352148.62817152933,
              5011066.4039850282
            ],
            [
              352167.49773659406,
              5011069.3624736117
            ],
            [
              352187.23324306676,
              5011068.698859985
            ],
            [
              352199.90902882343,
              5011058.3084384613
            ],
            [
              352205.70541822765,
              5011050.3025557278
            ],
            [
              352206.86292573571,
              5011047.7988104066
            ],
            [
              352228.4415472119,
              5011028.483444592
            ],
            [
              352239.91519953142,
              5011038.4245055141
            ],
            [
              352255.38179484801,
              5011040.6119989958
            ],
            [
              352263.51101653016,
              5011039.2286437564
            ],
            [
              352283.34228748694,
              5011013.3356610332
            ],
            [
              352298.79843124159,
              5010987.8376490613
            ],
            [
              352302.45691909856,
              5010990.8024202697
            ],
            [
              352307.29718441592,
              5010975.3767410601
            ],
            [
              352307.47136531206,
              5010962.0612409161
            ],
            [
              352309.60593501182,
              5010937.5806584014
            ],
            [
              352307.5731060906,
              5010937.0782028427
            ],
            [
              352296.76738183148,
              5010930.0932302149
            ],
            [
              352303.7840630025,
              5010914.9372377163
            ],
            [
              352300.59968105116,
              5010913.5810612813
            ],
            [
              352306.40278110531,
              5010898.606422415
            ],
            [
              352301.19848507975,
              5010896.2512002755
            ],
            [
              352303.83185805642,
              5010875.2173126303
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 255,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352027.61730508815,
              5010849.3732271688
            ],
            [
              352050.24030509248,
              5010812.8808411872
            ],
            [
              352060.46634367655,
              5010802.3998348108
            ],
            [
              352043.38051207329,
              5010789.4277552189
            ],
            [
              352021.96111709153,
              5010775.8861796111
            ],
            [
              352012.8347678365,
              5010766.7145263292
            ],
            [
              352003.06365733111,
              5010762.5366143323
            ],
            [
              351993.21445160982,
              5010754.1618408319
            ],
            [
              351990.57574095565,
              5010742.584965881
            ],
            [
              351991.64345526934,
              5010742.7249144996
            ],
            [
              351976.27807636553,
              5010720.6396519439
            ],
            [
              351970.66227145557,
              5010696.4695865624
            ],
            [
              351961.54490744794,
              5010699.7197546158
            ],
            [
              351960.40077875991,
              5010693.4431144046
            ],
            [
              351954.24888047291,
              5010694.1052264972
            ],
            [
              351938.45606471761,
              5010699.3279120792
            ],
            [
              351931.04500822135,
              5010698.575889769
            ],
            [
              351927.1347315259,
              5010698.2379139261
            ],
            [
              351909.18539515254,
              5010702.1101048468
            ],
            [
              351904.17532287148,
              5010701.8582986956
            ],
            [
              351898.13811504049,
              5010702.0569041381
            ],
            [
              351890.23464276065,
              5010699.3575742701
            ],
            [
              351882.13498028443,
              5010700.0257909019
            ],
            [
              351881.67352673912,
              5010651.8703801716
            ],
            [
              351887.21586151415,
              5010653.0444102017
            ],
            [
              351893.89230750996,
              5010654.7315409472
            ],
            [
              351895.94199824828,
              5010653.6245355746
            ],
            [
              351896.87933888048,
              5010648.3455680246
            ],
            [
              351893.77935237071,
              5010639.4327972103
            ],
            [
              351890.83390481508,
              5010629.7166946316
            ],
            [
              351810.74709476455,
              5010585.6175627569
            ],
            [
              351807.84739520802,
              5010586.8889234951
            ],
            [
              351786.14563776634,
              5010597.1595936129
            ],
            [
              351782.33604016743,
              5010600.2364981407
            ],
            [
              351778.84461552306,
              5010602.40967819
            ],
            [
              351758.96275195788,
              5010612.8750482062
            ],
            [
              351730.18761798431,
              5010629.040219361
            ],
            [
              351721.42513448559,
              5010633.9394189073
            ],
            [
              351710.5898370035,
              5010641.3698579129
            ],
            [
              351696.61698822031,
              5010651.5372519176
            ],
            [
              351693.80059613037,
              5010654.4589384003
            ],
            [
              351690.46274921711,
              5010658.2869881457
            ],
            [
              351680.66459692386,
              5010662.055917277
            ],
            [
              351636.35849235958,
              5010743.4210140239
            ],
            [
              351640.96221185225,
              5010752.9174866071
            ],
            [
              351645.20826104644,
              5010756.7722426532
            ],
            [
              351651.97576317593,
              5010761.533684127
            ],
            [
              351655.31674985605,
              5010768.0277267518
            ],
            [
              351658.37945624435,
              5010772.3813044084
            ],
            [
              351660.38238604477,
              5010777.4698384814
            ],
            [
              351664.22078021866,
              5010791.4645064482
            ],
            [
              351664.54016580206,
              5010796.1908572074
            ],
            [
              351662.19904623012,
              5010809.3857947001
            ],
            [
              351658.12457084755,
              5010808.8313969467
            ],
            [
              351658.06461456389,
              5010811.0913567943
            ],
            [
              351657.65302794875,
              5010816.1516989293
            ],
            [
              351656.95704882586,
              5010822.2277859077
            ],
            [
              351656.14633643179,
              5010827.5991102261
            ],
            [
              351655.30819567951,
              5010831.4544271333
            ],
            [
              351654.265796847,
              5010835.2129189894
            ],
            [
              351652.8205050822,
              5010839.079953216
            ],
            [
              351651.36392783577,
              5010842.3405065453
            ],
            [
              351650.16235469462,
              5010844.3502293266
            ],
            [
              351648.00048497721,
              5010847.7253714763
            ],
            [
              351645.72872374271,
              5010850.6979581332
            ],
            [
              351643.85124655918,
              5010853.0573555063
            ],
            [
              351642.06516591803,
              5010854.9094064431
            ],
            [
              351640.27157456818,
              5010856.3578353133
            ],
            [
              351638.93209729373,
              5010857.5352665586
            ],
            [
              351642.61608788394,
              5010861.3552540364
            ],
            [
              351652.0656791206,
              5010870.4707171312
            ],
            [
              351667.8708226083,
              5010882.6643457608
            ],
            [
              351673.19836491451,
              5010888.4929410117
            ],
            [
              351676.3886484488,
              5010894.2032227749
            ],
            [
              351678.45425928931,
              5010906.3204648467
            ],
            [
              351683.89571819868,
              5010922.796308483
            ],
            [
              351684.16725152015,
              5010923.2807412967
            ],
            [
              351693.20363310463,
              5010937.0209228108
            ],
            [
              351698.67781873507,
              5010946.9782242989
            ],
            [
              351708.66440938198,
              5010956.9848620882
            ],
            [
              351716.37081830052,
              5010962.4761310536
            ],
            [
              351725.86334635521,
              5010965.3134175641
            ],
            [
              351735.39238464448,
              5010970.1155832643
            ],
            [
              351741.46758214972,
              5010972.4922598042
            ],
            [
              351745.17627217679,
              5010974.5198880285
            ],
            [
              351747.96273097553,
              5010976.3025770206
            ],
            [
              351764.31524610514,
              5010984.5000421843
            ],
            [
              351766.4335296264,
              5010982.4289803393
            ],
            [
              351778.38899165118,
              5010984.9546273183
            ],
            [
              351797.94920210162,
              5010982.3359132977
            ],
            [
              351808.76987282728,
              5010986.6713018538
            ],
            [
              351824.14151987369,
              5010990.7676777886
            ],
            [
              351823.30071984284,
              5010986.7644486139
            ],
            [
              351880.77908469102,
              5010962.4864410376
            ],
            [
              351914.81748182036,
              5010951.5381769706
            ],
            [
              351914.65706571593,
              5010955.5622005118
            ],
            [
              351924.25658550713,
              5010954.6802835679
            ],
            [
              351938.22246935876,
              5010936.3693771847
            ],
            [
              351964.3381981657,
              5010913.9113238119
            ],
            [
              352003.57257971674,
              5010892.2304674862
            ],
            [
              352011.46982041647,
              5010879.1485762047
            ],
            [
              352027.61730508815,
              5010849.3732271688
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 256,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352603.62660030025,
              5010733.6216326421
            ],
            [
              352623.75696379185,
              5010714.5565885054
            ],
            [
              352618.97136927833,
              5010708.6137100859
            ],
            [
              352630.42461272626,
              5010702.5945759621
            ],
            [
              352630.16421949182,
              5010693.1692488575
            ],
            [
              352639.28461728134,
              5010694.5287602199
            ],
            [
              352641.21925465483,
              5010684.2979617026
            ],
            [
              352640.11793432693,
              5010679.0996974371
            ],
            [
              352638.90946459927,
              5010659.837014379
            ],
            [
              352632.36877506407,
              5010646.3656771295
            ],
            [
              352641.1054781797,
              5010640.8303855965
            ],
            [
              352634.24650737963,
              5010632.6768914424
            ],
            [
              352638.52262528619,
              5010629.2454315033
            ],
            [
              352642.16683635337,
              5010623.6568511175
            ],
            [
              352634.42774977913,
              5010615.9140601801
            ],
            [
              352641.17802336981,
              5010601.7593398811
            ],
            [
              352636.51621544739,
              5010600.3673346415
            ],
            [
              352635.85818764759,
              5010591.5069165947
            ],
            [
              352638.24558686401,
              5010587.2912530331
            ],
            [
              352629.72050442017,
              5010569.113084428
            ],
            [
              352634.22644292546,
              5010562.7822197843
            ],
            [
              352641.89272223337,
              5010550.7108188514
            ],
            [
              352645.13052146678,
              5010544.9100011401
            ],
            [
              352619.92603988352,
              5010526.9505629633
            ],
            [
              352604.93808788975,
              5010518.3654474476
            ],
            [
              352602.2652952561,
              5010527.453707628
            ],
            [
              352593.5461053563,
              5010547.3162700376
            ],
            [
              352583.95689403993,
              5010561.6132533373
            ],
            [
              352573.0648669728,
              5010558.8608769318
            ],
            [
              352567.10488896206,
              5010556.3450884372
            ],
            [
              352556.7016363212,
              5010551.0643120389
            ],
            [
              352559.09101323172,
              5010534.7939060722
            ],
            [
              352562.19010938244,
              5010521.4988164632
            ],
            [
              352564.16517303913,
              5010518.0374732837
            ],
            [
              352541.30529840611,
              5010521.479728993
            ],
            [
              352524.12172110734,
              5010521.2150878729
            ],
            [
              352521.35804661579,
              5010521.7557352018
            ],
            [
              352501.59532252135,
              5010520.6557966014
            ],
            [
              352484.49294539978,
              5010526.2742128605
            ],
            [
              352461.21006299375,
              5010525.3946879152
            ],
            [
              352447.93800778623,
              5010528.0796202291
            ],
            [
              352370.5739799522,
              5010534.8184291031
            ],
            [
              352321.68976077012,
              5010589.7307421798
            ],
            [
              352336.06729540584,
              5010602.6866216883
            ],
            [
              352343.39511061285,
              5010606.8977066148
            ],
            [
              352342.85285562841,
              5010610.5808603885
            ],
            [
              352347.73811722751,
              5010611.3637339985
            ],
            [
              352347.7079330018,
              5010609.7415823694
            ],
            [
              352361.94626812753,
              5010609.7886911705
            ],
            [
              352365.387673777,
              5010610.1615432343
            ],
            [
              352366.17196912604,
              5010612.1931068674
            ],
            [
              352368.38046817464,
              5010630.0146799656
            ],
            [
              352377.54466704495,
              5010643.2616708884
            ],
            [
              352356.52495194727,
              5010664.0181251699
            ],
            [
              352353.08199959033,
              5010669.5868499577
            ],
            [
              352352.53582447616,
              5010676.3057707557
            ],
            [
              352356.59248950687,
              5010683.034887217
            ],
            [
              352346.9322655609,
              5010698.4553205865
            ],
            [
              352339.05407398479,
              5010711.7702108249
            ],
            [
              352327.38033638633,
              5010721.8842167826
            ],
            [
              352333.05606871034,
              5010725.8789123874
            ],
            [
              352326.73428248719,
              5010733.8029032545
            ],
            [
              352330.22817014856,
              5010739.2575207772
            ],
            [
              352326.23261314718,
              5010745.9972170088
            ],
            [
              352315.42884718155,
              5010741.5042464491
            ],
            [
              352310.93013139849,
              5010735.4402731406
            ],
            [
              352305.51423189486,
              5010752.0391127253
            ],
            [
              352304.66977754148,
              5010760.5793922488
            ],
            [
              352296.284642738,
              5010790.4362679366
            ],
            [
              352298.51032803941,
              5010799.372716465
            ],
            [
              352298.72395723307,
              5010809.1514407415
            ],
            [
              352294.96720090922,
              5010833.5352777243
            ],
            [
              352294.78080403374,
              5010867.2518043313
            ],
            [
              352303.83185805765,
              5010875.2173126303
            ],
            [
              352301.19848507975,
              5010896.2512002755
            ],
            [
              352306.40278110583,
              5010898.606422415
            ],
            [
              352325.53662026889,
              5010904.1010130579
            ],
            [
              352430.30398690043,
              5010939.5483579049
            ],
            [
              352437.57128359366,
              5010945.0582798971
            ],
            [
              352440.76879539364,
              5010946.9944729554
            ],
            [
              352461.6679041037,
              5010911.058156251
            ],
            [
              352488.61858538823,
              5010875.7926908648
            ],
            [
              352513.31786197051,
              5010849.9067260725
            ],
            [
              352525.4008348682,
              5010832.5479672737
            ],
            [
              352535.28808390786,
              5010829.4084118372
            ],
            [
              352546.19798640459,
              5010815.6786114797
            ],
            [
              352545.37025212392,
              5010812.3972349847
            ],
            [
              352558.64307078725,
              5010792.3109679949
            ],
            [
              352561.10034405341,
              5010791.0252855904
            ],
            [
              352571.65951993491,
              5010776.5952786803
            ],
            [
              352573.99168135534,
              5010778.590855143
            ],
            [
              352579.90927564516,
              5010772.2788947215
            ],
            [
              352590.78838811367,
              5010767.658708211
            ],
            [
              352587.42077647371,
              5010764.832846283
            ],
            [
              352593.7316090783,
              5010756.814462781
            ],
            [
              352602.01658533962,
              5010740.6884434791
            ],
            [
              352603.62660030025,
              5010733.6216326421
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 257,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352295.7805409102,
              5012656.7093664566
            ],
            [
              352304.41186569195,
              5012632.945728289
            ],
            [
              352324.76492036687,
              5012576.8881787378
            ],
            [
              352331.53323567781,
              5012565.5211458979
            ],
            [
              352338.35572231648,
              5012558.3436358189
            ],
            [
              352341.59136861429,
              5012554.4914235706
            ],
            [
              352360.9823578315,
              5012525.4255887661
            ],
            [
              352340.13115769689,
              5012514.9390029851
            ],
            [
              352322.376539185,
              5012501.1313087009
            ],
            [
              352307.5834945151,
              5012517.2276272997
            ],
            [
              352301.52778009186,
              5012512.6898874007
            ],
            [
              352291.4734110593,
              5012512.0085394215
            ],
            [
              352279.01236737851,
              5012505.0658344282
            ],
            [
              352265.80249717931,
              5012507.3353543105
            ],
            [
              352261.66838293109,
              5012514.9269442391
            ],
            [
              352260.31402188534,
              5012516.2149611553
            ],
            [
              352222.47342453955,
              5012543.8269037819
            ],
            [
              352207.29142669239,
              5012556.9657014506
            ],
            [
              352214.81709163619,
              5012584.8252153983
            ],
            [
              352224.7570146661,
              5012620.8495351747
            ],
            [
              352229.00569188205,
              5012636.0896905623
            ],
            [
              352231.74906237278,
              5012645.3940053629
            ],
            [
              352243.24782778003,
              5012662.1919412753
            ],
            [
              352245.79422082094,
              5012667.0989555996
            ],
            [
              352265.80358229537,
              5012681.1680770768
            ],
            [
              352264.37902158051,
              5012683.5286972485
            ],
            [
              352281.81981840875,
              5012696.7475582669
            ],
            [
              352283.67584888468,
              5012690.4614113644
            ],
            [
              352288.12072336482,
              5012679.0269080885
            ],
            [
              352295.7805409102,
              5012656.7093664566
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 258,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352808.38632215472,
              5012005.700525444
            ],
            [
              352829.11608391599,
              5011992.9597680625
            ],
            [
              352818.99776696385,
              5011976.4917356437
            ],
            [
              352810.92246264406,
              5011969.9365814915
            ],
            [
              352767.29355027183,
              5011944.3759319372
            ],
            [
              352736.79705070198,
              5011940.0067656627
            ],
            [
              352722.59806961782,
              5011935.7148440676
            ],
            [
              352705.32382128126,
              5011945.772415461
            ],
            [
              352711.85194826417,
              5011951.0763011947
            ],
            [
              352700.79073206085,
              5011966.4535234543
            ],
            [
              352739.31633775495,
              5012005.7090409799
            ],
            [
              352749.8551190315,
              5012018.6076958338
            ],
            [
              352753.85614117753,
              5012016.8918312173
            ],
            [
              352763.23047752923,
              5012027.8238983303
            ],
            [
              352771.49348780332,
              5012034.9055255847
            ],
            [
              352779.98577114689,
              5012024.7651121439
            ],
            [
              352786.02502007113,
              5012019.5173363043
            ],
            [
              352808.38632215472,
              5012005.700525444
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 259,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352980.36204052257,
              5011889.6692181407
            ],
            [
              353012.11868995766,
              5011867.5935277874
            ],
            [
              353020.67242707958,
              5011862.2172580119
            ],
            [
              353023.60901591653,
              5011857.6319844406
            ],
            [
              353030.26812178816,
              5011850.0943151657
            ],
            [
              353027.57335615432,
              5011847.0140966382
            ],
            [
              353037.19240143453,
              5011833.2399513032
            ],
            [
              353032.81364107929,
              5011818.3358982783
            ],
            [
              353023.28151975694,
              5011807.3153090086
            ],
            [
              353000.87636443606,
              5011792.653772492
            ],
            [
              352995.77963679587,
              5011795.7904501148
            ],
            [
              352989.50206068216,
              5011798.8870186703
            ],
            [
              352976.70670548663,
              5011805.5191927059
            ],
            [
              352932.8979193889,
              5011825.9113367293
            ],
            [
              352918.90759239375,
              5011834.8793857098
            ],
            [
              352894.88326600153,
              5011857.1975832945
            ],
            [
              352891.86870077183,
              5011855.5494508166
            ],
            [
              352885.30636578752,
              5011861.7784298519
            ],
            [
              352779.05962698412,
              5011918.6857320825
            ],
            [
              352760.15962440311,
              5011936.1438345956
            ],
            [
              352769.48076144868,
              5011939.2694534529
            ],
            [
              352796.99248368811,
              5011956.8085772684
            ],
            [
              352815.40610326157,
              5011965.5864444841
            ],
            [
              352824.12058196677,
              5011974.1648173239
            ],
            [
              352833.79629685666,
              5011988.4653120078
            ],
            [
              352956.00934679294,
              5011911.651349674
            ],
            [
              352980.36204052257,
              5011889.6692181407
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 260,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352693.16460637865,
              5011403.0446412992
            ],
            [
              352689.15869939036,
              5011405.2951783799
            ],
            [
              352667.55155769561,
              5011421.6861245949
            ],
            [
              352658.21999333374,
              5011436.3916474562
            ],
            [
              352689.7340934432,
              5011469.7993395803
            ],
            [
              352691.61411662475,
              5011468.8667696975
            ],
            [
              352706.72943219688,
              5011457.3481613807
            ],
            [
              352711.13752507052,
              5011460.7283199476
            ],
            [
              352721.99076174543,
              5011438.9055378148
            ],
            [
              352742.91778143594,
              5011395.4952447442
            ],
            [
              352746.01488388487,
              5011389.0707226647
            ],
            [
              352761.86894551484,
              5011356.1836109981
            ],
            [
              352779.38654464629,
              5011319.8457246106
            ],
            [
              352792.4692857152,
              5011292.7073569195
            ],
            [
              352783.07098939008,
              5011287.6038568225
            ],
            [
              352765.78144998517,
              5011282.9928809656
            ],
            [
              352744.78418585553,
              5011282.4064483549
            ],
            [
              352726.50192749233,
              5011277.1205166271
            ],
            [
              352709.61880789354,
              5011276.612340196
            ],
            [
              352699.78352930996,
              5011303.6191905541
            ],
            [
              352681.11735399399,
              5011340.6844614949
            ],
            [
              352715.74962841679,
              5011357.8492476596
            ],
            [
              352693.16460637865,
              5011403.0446412992
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 261,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              361778.51102542056,
              5002663.3708485728
            ],
            [
              361777.91323970695,
              5002660.7671651701
            ],
            [
              361754.54081816733,
              5002661.2015251061
            ],
            [
              361733.9706404708,
              5002666.7497790372
            ],
            [
              361724.40762251493,
              5002668.649517105
            ],
            [
              361707.13630334014,
              5002679.5484338151
            ],
            [
              361698.19329294428,
              5002688.3246002318
            ],
            [
              361670.72896581382,
              5002710.6335806614
            ],
            [
              361660.65925543703,
              5002715.7254025256
            ],
            [
              361695.97099042917,
              5002748.7012926573
            ],
            [
              361703.84190752479,
              5002757.3133937679
            ],
            [
              361713.66644985223,
              5002776.7495716875
            ],
            [
              361778.14461814292,
              5002715.3524332065
            ],
            [
              361777.95327189466,
              5002705.0563851632
            ],
            [
              361778.10733547068,
              5002701.5472494932
            ],
            [
              361782.89126742748,
              5002672.8701357879
            ],
            [
              361778.51102542056,
              5002663.3708485728
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 262,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              361177.82671648421,
              5002953.331178288
            ],
            [
              361183.06457131536,
              5002926.0810843846
            ],
            [
              361195.08743831405,
              5002926.4868249232
            ],
            [
              361194.40198210452,
              5002922.1647705529
            ],
            [
              361205.82457238168,
              5002918.1216685576
            ],
            [
              361212.71109798847,
              5002904.1979592042
            ],
            [
              361202.39361515228,
              5002896.4802980144
            ],
            [
              361197.30270746263,
              5002888.6654471066
            ],
            [
              361196.25349611539,
              5002885.4364621406
            ],
            [
              361179.55061461654,
              5002876.2838118449
            ],
            [
              361172.4025394058,
              5002894.6366091371
            ],
            [
              361148.9897422794,
              5002881.7951999418
            ],
            [
              361140.50425766892,
              5002898.0707111079
            ],
            [
              361107.6482383117,
              5002886.5347037176
            ],
            [
              361098.85753857729,
              5002907.7428125059
            ],
            [
              361098.73201666685,
              5002908.5925576901
            ],
            [
              361090.24651671492,
              5002915.8122627679
            ],
            [
              361084.92117418972,
              5002925.7980158543
            ],
            [
              361080.35295284324,
              5002924.5087632425
            ],
            [
              361075.98576403531,
              5002948.0357364397
            ],
            [
              361079.66637551872,
              5002948.3910379801
            ],
            [
              361078.31456036004,
              5002959.7633158546
            ],
            [
              361077.95417413098,
              5002970.7866963567
            ],
            [
              361061.80175432935,
              5002991.2893334441
            ],
            [
              361068.04786088405,
              5003015.6025700355
            ],
            [
              361075.70127552171,
              5003032.0437785434
            ],
            [
              361075.79311912594,
              5003036.9854892157
            ],
            [
              361079.47364696075,
              5003037.3407913605
            ],
            [
              361080.49391191028,
              5003039.0167077007
            ],
            [
              361088.15026805404,
              5003032.8010927811
            ],
            [
              361094.1219330536,
              5003027.1817660229
            ],
            [
              361106.19819914788,
              5003007.1540945955
            ],
            [
              361125.54124475003,
              5002983.4900638713
            ],
            [
              361146.55875097984,
              5002966.2918856777
            ],
            [
              361161.65182532865,
              5002957.2544996673
            ],
            [
              361167.27062646725,
              5002955.4551954092
            ],
            [
              361177.82671648421,
              5002953.331178288
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 263,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358678.18413779378,
              5005894.3804638451
            ],
            [
              358665.43447052187,
              5005893.6000563502
            ],
            [
              358654.23645753803,
              5005878.866447025
            ],
            [
              358644.90729665646,
              5005866.7841966888
            ],
            [
              358635.38317508932,
              5005856.0732127232
            ],
            [
              358635.38317508763,
              5005856.0732127232
            ],
            [
              358609.28807762073,
              5005839.0077245338
            ],
            [
              358587.32321097591,
              5005833.385998521
            ],
            [
              358564.31958836626,
              5005830.9174572201
            ],
            [
              358521.52589342097,
              5005826.7958238032
            ],
            [
              358511.61143491202,
              5005825.2138161464
            ],
            [
              358501.69796274736,
              5005831.8713230649
            ],
            [
              358489.88553729263,
              5005843.8988103056
            ],
            [
              358481.14692277001,
              5005853.4184190892
            ],
            [
              358487.17529618688,
              5005853.9747133134
            ],
            [
              358490.95981097262,
              5005865.7121977564
            ],
            [
              358488.64549197309,
              5005873.1072457414
            ],
            [
              358490.7377501775,
              5005877.7469465239
            ],
            [
              358487.37568387185,
              5005888.7261206936
            ],
            [
              358486.20856143651,
              5005897.8821807308
            ],
            [
              358481.77383968997,
              5005911.1092180153
            ],
            [
              358480.42943124945,
              5005922.7192603489
            ],
            [
              358481.45320541912,
              5005929.8294734173
            ],
            [
              358493.8282126073,
              5005930.7495529521
            ],
            [
              358499.84264581831,
              5005931.6991886059
            ],
            [
              358508.71079388185,
              5005933.9246378196
            ],
            [
              358522.06855626061,
              5005935.1755734021
            ],
            [
              358542.09578245523,
              5005941.6019003671
            ],
            [
              358552.21278057288,
              5005954.4902444156
            ],
            [
              358564.0471043553,
              5005967.0868613478
            ],
            [
              358589.73178525438,
              5006007.2663912755
            ],
            [
              358605.93408688309,
              5006024.4048277829
            ],
            [
              358618.49359095801,
              5006053.5545571363
            ],
            [
              358639.85691172379,
              5006069.6586718084
            ],
            [
              358646.11006206559,
              5006079.4258534759
            ],
            [
              358652.70787926443,
              5006084.3710207529
            ],
            [
              358664.40180636296,
              5006084.3574509276
            ],
            [
              358677.03188111505,
              5006079.841204674
            ],
            [
              358686.02443464036,
              5006074.3473209795
            ],
            [
              358689.43364113924,
              5006072.3484731093
            ],
            [
              358699.53129463713,
              5006075.1294937162
            ],
            [
              358691.85395377636,
              5006060.7970886212
            ],
            [
              358688.97766258736,
              5006056.0254774047
            ],
            [
              358686.62729614007,
              5006042.9206924373
            ],
            [
              358682.96244636463,
              5006028.649550559
            ],
            [
              358681.15215376398,
              5006011.6537159067
            ],
            [
              358678.93812483485,
              5005991.3073212784
            ],
            [
              358678.34677851381,
              5005966.6583596766
            ],
            [
              358676.62551540748,
              5005941.4498877572
            ],
            [
              358678.18413779378,
              5005894.3804638451
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 264,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360300.68224345444,
              5005272.6080202656
            ],
            [
              360307.85291939805,
              5005254.3896741606
            ],
            [
              360322.20784785837,
              5005226.7710298272
            ],
            [
              360333.04795192491,
              5005207.3232664289
            ],
            [
              360349.09802264784,
              5005182.0360902902
            ],
            [
              360366.27790524712,
              5005164.6425012639
            ],
            [
              360281.51679328218,
              5005125.1610805281
            ],
            [
              360283.07059132343,
              5005140.327599518
            ],
            [
              360280.00234316231,
              5005160.2565638665
            ],
            [
              360274.37385325349,
              5005175.2756429184
            ],
            [
              360265.54954689491,
              5005187.1686161868
            ],
            [
              360253.63433357939,
              5005197.6995877987
            ],
            [
              360238.44588964398,
              5005201.0449626613
            ],
            [
              360226.72481638315,
              5005207.7208406031
            ],
            [
              360221.8435502869,
              5005206.5415499592
            ],
            [
              360208.28257454868,
              5005204.9259266183
            ],
            [
              360185.60169256659,
              5005191.3028179025
            ],
            [
              360184.09753249976,
              5005190.8078700509
            ],
            [
              360185.20778002421,
              5005198.2578987852
            ],
            [
              360185.08956092515,
              5005212.0060301414
            ],
            [
              360171.31315697572,
              5005222.9452502215
            ],
            [
              360161.35578082624,
              5005218.1249981038
            ],
            [
              360159.63086085697,
              5005223.4102957053
            ],
            [
              360155.82126830565,
              5005231.6241320912
            ],
            [
              360144.89999625849,
              5005243.3320362605
            ],
            [
              360134.41442526784,
              5005258.7161755804
            ],
            [
              360135.77895990334,
              5005259.7367873732
            ],
            [
              360129.06085429748,
              5005272.5899433782
            ],
            [
              360124.64105474221,
              5005280.1427873159
            ],
            [
              360127.2668967552,
              5005288.6852554781
            ],
            [
              360163.89495074603,
              5005295.8055106346
            ],
            [
              360171.80985626037,
              5005299.3190569403
            ],
            [
              360173.80496740894,
              5005310.11448255
            ],
            [
              360172.92871297902,
              5005323.0771785509
            ],
            [
              360170.79657716333,
              5005329.0185961239
            ],
            [
              360166.63619150268,
              5005334.5747904833
            ],
            [
              360158.63639791828,
              5005346.6018680194
            ],
            [
              360144.39635926619,
              5005356.7279498689
            ],
            [
              360153.61158871651,
              5005365.6700412231
            ],
            [
              360161.33461684315,
              5005369.351195368
            ],
            [
              360169.83189873159,
              5005378.3423968675
            ],
            [
              360180.82275637047,
              5005395.0407432346
            ],
            [
              360182.04002411646,
              5005399.9676676197
            ],
            [
              360196.07007483329,
              5005423.4384699604
            ],
            [
              360209.15375426278,
              5005440.9686467294
            ],
            [
              360221.59846789291,
              5005464.1004097555
            ],
            [
              360223.80163046962,
              5005461.5097236084
            ],
            [
              360236.44709761284,
              5005479.4377027499
            ],
            [
              360239.62469009898,
              5005484.8532681521
            ],
            [
              360240.82689683948,
              5005493.0051769856
            ],
            [
              360240.8826498611,
              5005496.0038520461
            ],
            [
              360254.00725638558,
              5005520.8485971252
            ],
            [
              360266.15862020245,
              5005541.7338480288
            ],
            [
              360266.82770579384,
              5005545.4397937423
            ],
            [
              360264.87486913701,
              5005550.1541718431
            ],
            [
              360280.67761901289,
              5005541.4639007999
            ],
            [
              360275.04021477647,
              5005541.6885980247
            ],
            [
              360269.46733080427,
              5005538.9134726711
            ],
            [
              360260.24509280262,
              5005527.0899801077
            ],
            [
              360244.40404243796,
              5005495.5261765644
            ],
            [
              360235.58244978444,
              5005460.0651176302
            ],
            [
              360234.7585585069,
              5005448.5894191042
            ],
            [
              360236.81654362794,
              5005434.0682023903
            ],
            [
              360239.62685754168,
              5005423.6603229064
            ],
            [
              360245.54112592567,
              5005414.6204210017
            ],
            [
              360254.26231462607,
              5005402.5559870237
            ],
            [
              360261.76397403353,
              5005390.0346965585
            ],
            [
              360267.75618135609,
              5005380.8907234194
            ],
            [
              360271.80785990058,
              5005372.6358469399
            ],
            [
              360278.57982801466,
              5005361.2538712481
            ],
            [
              360291.43298810144,
              5005340.8526830832
            ],
            [
              360303.3661365966,
              5005324.3468303485
            ],
            [
              360313.25772446365,
              5005315.1580392765
            ],
            [
              360343.50125122041,
              5005304.7212105468
            ],
            [
              360361.14445485588,
              5005304.543235857
            ],
            [
              360398.65609173209,
              5005310.7497245781
            ],
            [
              360399.57065360306,
              5005307.4308940014
            ],
            [
              360364.61397027789,
              5005297.2748850174
            ],
            [
              360300.68224345444,
              5005272.6080202656
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 265,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357282.39465424244,
              5006044.9961632658
            ],
            [
              357282.28991509363,
              5006044.9527713954
            ],
            [
              357280.65605644794,
              5006046.4941349365
            ],
            [
              357264.68077160214,
              5006067.2583186459
            ],
            [
              357254.26134198386,
              5006061.2437329395
            ],
            [
              357237.62775447167,
              5006087.8553532166
            ],
            [
              357235.92009121814,
              5006090.8284039088
            ],
            [
              357220.20754095755,
              5006116.2755143037
            ],
            [
              357218.71793154028,
              5006118.5497733038
            ],
            [
              357204.04903075221,
              5006138.2383627398
            ],
            [
              357206.39050309028,
              5006139.879861285
            ],
            [
              357192.34431739344,
              5006157.3148315372
            ],
            [
              357191.942522189,
              5006157.0779475383
            ],
            [
              357191.18751788192,
              5006146.0654924158
            ],
            [
              357190.13446995424,
              5006140.0668153241
            ],
            [
              357188.49513275566,
              5006137.6166449906
            ],
            [
              357185.66977788456,
              5006129.8304370474
            ],
            [
              357183.51421679318,
              5006125.8380145114
            ],
            [
              357180.98369888065,
              5006123.1961145969
            ],
            [
              357178.11709526746,
              5006121.2607136499
            ],
            [
              357174.7023504235,
              5006119.5785672385
            ],
            [
              357169.42287924903,
              5006117.8003346836
            ],
            [
              357161.19910808041,
              5006117.0171863474
            ],
            [
              357155.86101127457,
              5006117.7144468054
            ],
            [
              357146.95968592487,
              5006114.4690432176
            ],
            [
              357152.786353642,
              5006096.2342601344
            ],
            [
              357156.21193262812,
              5006085.066379142
            ],
            [
              357158.39176394668,
              5006076.4808263658
            ],
            [
              357159.39002614352,
              5006070.4198432313
            ],
            [
              357182.37760058662,
              5006082.4532241207
            ],
            [
              357185.89327843749,
              5006072.3849435728
            ],
            [
              357193.42137910501,
              5006051.1573942611
            ],
            [
              357166.04643960606,
              5006039.7599356966
            ],
            [
              357165.73259479954,
              5006036.6256571868
            ],
            [
              357169.63384272455,
              5006021.6681712763
            ],
            [
              357176.51051902614,
              5006004.3959419411
            ],
            [
              357185.61592557369,
              5005986.6376212202
            ],
            [
              357199.39469670889,
              5005993.6989526488
            ],
            [
              357209.07575034181,
              5005999.0018830001
            ],
            [
              357228.86661075772,
              5006006.8819188261
            ],
            [
              357259.91440706654,
              5005981.4045035951
            ],
            [
              357261.33488492068,
              5005982.3614125866
            ],
            [
              357260.25837728387,
              5005984.8002598071
            ],
            [
              357253.39893470687,
              5005994.9242331628
            ],
            [
              357247.03168408741,
              5006008.0107044103
            ],
            [
              357243.26442260033,
              5006019.3082769625
            ],
            [
              357242.89427082945,
              5006020.688834955
            ],
            [
              357242.74003296142,
              5006022.1098076403
            ],
            [
              357242.80535191303,
              5006023.5376333073
            ],
            [
              357243.08868492639,
              5006024.9385883724
            ],
            [
              357243.58334000694,
              5006026.2795839207
            ],
            [
              357244.27763397794,
              5006027.5289471932
            ],
            [
              357245.15516842337,
              5006028.6571696931
            ],
            [
              357260.44186632748,
              5006048.0169162257
            ],
            [
              357269.54124400287,
              5006040.9959730422
            ],
            [
              357273.05013490346,
              5006034.6938903825
            ],
            [
              357275.34763018804,
              5006027.2322384473
            ],
            [
              357284.15772918012,
              5006029.0871158214
            ],
            [
              357304.63747512572,
              5005998.1079117525
            ],
            [
              357334.73696387961,
              5005960.1668605516
            ],
            [
              357351.16891097196,
              5005939.875086532
            ],
            [
              357359.91075736441,
              5005928.705011283
            ],
            [
              357380.7245867647,
              5005951.1644777944
            ],
            [
              357385.67015034065,
              5005941.1379371835
            ],
            [
              357399.096214847,
              5005923.1278981492
            ],
            [
              357411.09392914589,
              5005908.2407929124
            ],
            [
              357420.06978170905,
              5005895.9151547216
            ],
            [
              357426.49881437724,
              5005884.8225186486
            ],
            [
              357432.85779872577,
              5005871.0633277986
            ],
            [
              357436.38883742003,
              5005861.5354266884
            ],
            [
              357442.30264455796,
              5005840.8631417854
            ],
            [
              357448.2164528413,
              5005820.1908575865
            ],
            [
              357458.36865990749,
              5005785.9353372958
            ],
            [
              357468.74781957577,
              5005751.325235703
            ],
            [
              357460.50091270375,
              5005746.8375626355
            ],
            [
              357450.4465007172,
              5005736.624497328
            ],
            [
              357444.12840517145,
              5005737.335485735
            ],
            [
              357431.34408668469,
              5005720.6134553114
            ],
            [
              357423.57405566232,
              5005717.2409074334
            ],
            [
              357419.92661869171,
              5005719.3184446143
            ],
            [
              357421.78217978479,
              5005734.2220319072
            ],
            [
              357421.82323769655,
              5005736.4302439606
            ],
            [
              357412.40227867366,
              5005736.4399628146
            ],
            [
              357398.08751474571,
              5005715.8611731194
            ],
            [
              357389.04516489821,
              5005712.3120309608
            ],
            [
              357374.93252463866,
              5005706.7974765245
            ],
            [
              357360.22894471098,
              5005700.5717563285
            ],
            [
              357355.49231736432,
              5005698.6509209778
            ],
            [
              357354.96377678163,
              5005700.0456960034
            ],
            [
              357353.7094341311,
              5005703.3026660522
            ],
            [
              357352.05437808594,
              5005706.769214116
            ],
            [
              357350.38617423183,
              5005709.5286317654
            ],
            [
              357348.22185625549,
              5005712.8025213061
            ],
            [
              357346.34018101211,
              5005714.9595969599
            ],
            [
              357343.85558966995,
              5005717.3299746336
            ],
            [
              357340.766204399,
              5005719.8126611877
            ],
            [
              357337.66367192409,
              5005721.5882528443
            ],
            [
              357335.96165969811,
              5005722.5293657286
            ],
            [
              357333.85900010756,
              5005723.6800195985
            ],
            [
              357332.56333686813,
              5005724.7146407729
            ],
            [
              357331.17220667977,
              5005726.0541570522
            ],
            [
              357329.69117927278,
              5005728.001691618
            ],
            [
              357327.82420603535,
              5005725.5100960517
            ],
            [
              357325.92750511103,
              5005726.8590481123
            ],
            [
              357329.87304397585,
              5005732.3435170054
            ],
            [
              357330.01734487613,
              5005734.6650507674
            ],
            [
              357329.95568683109,
              5005736.7882865658
            ],
            [
              357329.80044028786,
              5005739.3174462076
            ],
            [
              357329.4297782369,
              5005741.1432717452
            ],
            [
              357329.05121516483,
              5005743.346162484
            ],
            [
              357328.2836622303,
              5005745.5835869005
            ],
            [
              357327.11727302411,
              5005748.1315470971
            ],
            [
              357325.53708940465,
              5005750.1819891511
            ],
            [
              357323.56551646383,
              5005752.9470478725
            ],
            [
              357320.48746698565,
              5005756.0358353676
            ],
            [
              357315.01271309151,
              5005760.785992031
            ],
            [
              357310.62202998583,
              5005764.0002496596
            ],
            [
              357309.72325771052,
              5005764.6232721433
            ],
            [
              357308.72720691207,
              5005765.4501952808
            ],
            [
              357308.43322858802,
              5005765.9609088795
            ],
            [
              357308.04760715569,
              5005766.9786095368
            ],
            [
              357307.2687185941,
              5005768.6099336306
            ],
            [
              357306.78763022384,
              5005769.932529429
            ],
            [
              357302.73716694169,
              5005764.2479195688
            ],
            [
              357300.63826331164,
              5005765.6005960256
            ],
            [
              357302.83483531134,
              5005769.5007777112
            ],
            [
              357302.85549581161,
              5005770.6119524101
            ],
            [
              357302.87803478324,
              5005771.824155923
            ],
            [
              357302.80322966597,
              5005773.2402968323
            ],
            [
              357302.52246555762,
              5005774.4581397614
            ],
            [
              357301.84286583465,
              5005775.9865540555
            ],
            [
              357300.66520725942,
              5005777.9284127951
            ],
            [
              357299.03966017178,
              5005779.8595454516
            ],
            [
              357296.64865673642,
              5005781.8239644431
            ],
            [
              357292.85713280441,
              5005784.6228627404
            ],
            [
              357289.86133603222,
              5005786.6996262614
            ],
            [
              357286.66145801736,
              5005788.6790851327
            ],
            [
              357284.36028674681,
              5005790.0355581967
            ],
            [
              357282.36436260788,
              5005791.487383862
            ],
            [
              357280.37219544215,
              5005793.1412671702
            ],
            [
              357278.18715092883,
              5005795.303984412
            ],
            [
              357274.8057321739,
              5005798.3984132232
            ],
            [
              357273.81149353,
              5005799.3263666835
            ],
            [
              357271.21045059792,
              5005795.4337053662
            ],
            [
              357268.92061435449,
              5005797.3962435825
            ],
            [
              357272.77820264042,
              5005803.5897585368
            ],
            [
              357273.22017172648,
              5005805.602566747
            ],
            [
              357273.27276251948,
              5005808.4310181346
            ],
            [
              357273.10812606476,
              5005810.4551407993
            ],
            [
              357272.54089703917,
              5005812.5877772579
            ],
            [
              357271.47748766665,
              5005815.2348870886
            ],
            [
              357267.7390495927,
              5005823.1665384695
            ],
            [
              357265.40251654811,
              5005828.0604735976
            ],
            [
              357263.64260332164,
              5005831.3268453721
            ],
            [
              357262.55853336881,
              5005832.8627804685
            ],
            [
              357260.3867033869,
              5005835.7326274412
            ],
            [
              357257.51830320648,
              5005839.2217375245
            ],
            [
              357255.63662827865,
              5005841.3788144179
            ],
            [
              357253.35423923185,
              5005843.7454340113
            ],
            [
              357249.7649835351,
              5005846.540572498
            ],
            [
              357240.68963829003,
              5005853.4797692699
            ],
            [
              357230.71558766911,
              5005861.0420240369
            ],
            [
              357222.19388973113,
              5005867.8520540297
            ],
            [
              357219.01091610751,
              5005870.7406668505
            ],
            [
              357216.33156987285,
              5005873.518887219
            ],
            [
              357213.25721062458,
              5005876.8097001379
            ],
            [
              357211.16582089179,
              5005878.5664929477
            ],
            [
              357208.57261531061,
              5005880.534672834
            ],
            [
              357205.87267447071,
              5005882.2017171597
            ],
            [
              357201.76839014411,
              5005884.50118485
            ],
            [
              357190.85618783341,
              5005890.5650707427
            ],
            [
              357178.81295908237,
              5005895.6394914594
            ],
            [
              357177.46175917209,
              5005896.5423379689
            ],
            [
              357176.26350615924,
              5005897.3730215067
            ],
            [
              357174.46227127546,
              5005898.4170442848
            ],
            [
              357169.33751745737,
              5005900.2302035484
            ],
            [
              357163.71846256539,
              5005902.658900803
            ],
            [
              357161.31055413344,
              5005903.7141685011
            ],
            [
              357158.28658264509,
              5005904.2756788684
            ],
            [
              357155.96474929689,
              5005904.5209425306
            ],
            [
              357153.94628548686,
              5005904.7605653955
            ],
            [
              357152.2681044662,
              5005896.1013153316
            ],
            [
              357145.68829252437,
              5005895.8194391783
            ],
            [
              357142.72760990349,
              5005921.5416674782
            ],
            [
              357145.67114210397,
              5005922.0932475161
            ],
            [
              357147.11486400705,
              5005923.582182073
            ],
            [
              357148.35262748093,
              5005924.8728542449
            ],
            [
              357149.58475536847,
              5005925.8604398286
            ],
            [
              357151.21753183176,
              5005926.6385193514
            ],
            [
              357153.15555561805,
              5005927.5119513795
            ],
            [
              357155.60478593619,
              5005928.6790338792
            ],
            [
              357157.7468896744,
              5005929.6496994616
            ],
            [
              357159.38724511123,
              5005930.8318220284
            ],
            [
              357161.23355970863,
              5005932.2122425446
            ],
            [
              357163.29522408714,
              5005934.2959986366
            ],
            [
              357165.25954376272,
              5005936.583656705
            ],
            [
              357166.53236753348,
              5005938.6656570313
            ],
            [
              357167.27019425487,
              5005940.2687805751
            ],
            [
              357167.81708797393,
              5005942.4817302721
            ],
            [
              357168.38276558346,
              5005945.7048973087
            ],
            [
              357168.85673278203,
              5005949.4349818192
            ],
            [
              357169.03860103205,
              5005953.7768446412
            ],
            [
              357168.69423597254,
              5005957.0168973794
            ],
            [
              357168.03529791301,
              5005959.6564877415
            ],
            [
              357165.90103425347,
              5005964.5466643935
            ],
            [
              357161.61929254717,
              5005973.6198882498
            ],
            [
              357153.98349212023,
              5005988.6953829052
            ],
            [
              357152.13562720775,
              5005992.670767284
            ],
            [
              357151.07973233127,
              5005995.7219584472
            ],
            [
              357150.10427730368,
              5005997.6600945583
            ],
            [
              357145.58464650559,
              5006004.8177796472
            ],
            [
              357137.59745591413,
              5006015.8798669614
            ],
            [
              357135.01039866213,
              5006019.6400653915
            ],
            [
              357133.1343591683,
              5006022.1001950679
            ],
            [
              357131.35002936207,
              5006024.0533716613
            ],
            [
              357128.56770408532,
              5006026.7324450277
            ],
            [
              357125.78168862796,
              5006029.2094949801
            ],
            [
              357122.8851137695,
              5006031.1833173893
            ],
            [
              357120.48659770231,
              5006032.7436947776
            ],
            [
              357116.87480238173,
              5006034.3266321244
            ],
            [
              357112.05710878706,
              5006036.3362117382
            ],
            [
              357107.82918301842,
              5006037.4253577003
            ],
            [
              357103.40093280951,
              5006038.6192568485
            ],
            [
              357099.95728090499,
              5006039.9100957643
            ],
            [
              357096.74049831828,
              5006040.980440462
            ],
            [
              357095.13583000324,
              5006041.7176178563
            ],
            [
              357093.13614941674,
              5006042.9674235648
            ],
            [
              357091.0372462619,
              5006044.320138122
            ],
            [
              357088.84093221481,
              5006045.8767559761
            ],
            [
              357087.44792439678,
              5006047.1152811684
            ],
            [
              357086.06055156683,
              5006048.6568574468
            ],
            [
              357085.27227175451,
              5006049.7830744404
            ],
            [
              357084.50096317177,
              5006051.8184433887
            ],
            [
              357083.84578278742,
              5006054.6600923073
            ],
            [
              357083.48639214964,
              5006057.0920216003
            ],
            [
              357083.22629032278,
              5006059.4210409531
            ],
            [
              357083.37622854108,
              5006062.045627282
            ],
            [
              357083.73866650433,
              5006064.3327482622
            ],
            [
              357084.60389155947,
              5006067.3482184689
            ],
            [
              357085.67326362617,
              5006070.4609564282
            ],
            [
              357086.73693380487,
              5006073.2706090985
            ],
            [
              357088.2144667409,
              5006076.5778496591
            ],
            [
              357090.62646066101,
              5006081.1814016914
            ],
            [
              357093.97284867038,
              5006087.0812308053
            ],
            [
              357097.72370740602,
              5006092.973539019
            ],
            [
              357099.70493422705,
              5006096.170385235
            ],
            [
              357101.83433204232,
              5006100.3534297738
            ],
            [
              357104.25759626291,
              5006105.5630835155
            ],
            [
              357105.84374348086,
              5006109.2724882504
            ],
            [
              357107.02736561757,
              5006113.0904770149
            ],
            [
              357108.53870915907,
              5006118.216023392
            ],
            [
              357109.65128348925,
              5006123.6521404563
            ],
            [
              357110.12337412668,
              5006127.2811964974
            ],
            [
              357110.31087972183,
              5006131.9261110025
            ],
            [
              357110.18562366499,
              5006136.0715575898
            ],
            [
              357110.04526384099,
              5006139.5900712758
            ],
            [
              357109.68406216387,
              5006141.9210059429
            ],
            [
              357109.11119985901,
              5006143.7505929293
            ],
            [
              357107.94857002218,
              5006146.5006138068
            ],
            [
              357105.58762134827,
              5006150.0813205373
            ],
            [
              357102.73424976639,
              5006154.3785590623
            ],
            [
              357096.66510594048,
              5006161.6311622048
            ],
            [
              357090.81291245995,
              5006168.0932603385
            ],
            [
              357082.78259068081,
              5006176.8320077667
            ],
            [
              357072.11418146786,
              5006190.5713318763
            ],
            [
              357062.52239207044,
              5006202.3706407696
            ],
            [
              357052.10894665832,
              5006215.4755556844
            ],
            [
              357045.78238236572,
              5006223.3741855687
            ],
            [
              357043.99805326376,
              5006225.327363723
            ],
            [
              357042.50582340598,
              5006226.6687986432
            ],
            [
              357041.20828201168,
              5006227.6023937007
            ],
            [
              357039.60361382941,
              5006228.3395720832
            ],
            [
              357037.48780563974,
              5006228.7831346439
            ],
            [
              357035.0629927229,
              5006228.9292871803
            ],
            [
              357033.43960737943,
              5006228.656317695
            ],
            [
              357031.81052062492,
              5006228.0802983362
            ],
            [
              357029.96796135174,
              5006226.9019366503
            ],
            [
              357028.72643981711,
              5006225.4092429355
            ],
            [
              357027.47740453656,
              5006223.5124692237
            ],
            [
              357026.1141199845,
              5006220.9104799796
            ],
            [
              357023.5935750858,
              5006215.9047266906
            ],
            [
              357003.09192255355,
              5006214.557838928
            ],
            [
              356978.37488829083,
              5006213.4992473861
            ],
            [
              356975.74404928146,
              5006213.4471031688
            ],
            [
              356974.02519768546,
              5006213.4790646657
            ],
            [
              356971.69960683404,
              5006213.5223082816
            ],
            [
              356969.37777251133,
              5006213.7675741632
            ],
            [
              356967.05976159032,
              5006214.2148966212
            ],
            [
              356965.24725636933,
              5006214.6528193429
            ],
            [
              356963.33552785806,
              5006215.1936154002
            ],
            [
              356961.21784051083,
              5006215.536149092
            ],
            [
              356958.79866228014,
              5006215.985352722
            ],
            [
              356952.19183227164,
              5006216.7100019539
            ],
            [
              356948.25976341387,
              5006217.3894291027
            ],
            [
              356944.83131745044,
              5006217.9584639994
            ],
            [
              356941.60138506436,
              5006218.3216795754
            ],
            [
              356938.06251412269,
              5006218.387483797
            ],
            [
              356934.42059718614,
              5006218.3541402901
            ],
            [
              356931.37596158375,
              5006217.8044424122
            ],
            [
              356929.54655068909,
              5006217.3332116287
            ],
            [
              356925.67049910821,
              5006215.5863497173
            ],
            [
              356911.76682668977,
              5006241.2569140559
            ],
            [
              356915.03620545176,
              5006243.0150566893
            ],
            [
              356921.79877672886,
              5006231.3693807991
            ],
            [
              357003.27073953155,
              5006247.3594816979
            ],
            [
              357030.7118216487,
              5006254.4452879829
            ],
            [
              357035.60277734505,
              5006253.8482988775
            ],
            [
              357037.20122588251,
              5006253.6531925621
            ],
            [
              357047.57127363415,
              5006246.0906209368
            ],
            [
              357047.57605832448,
              5006246.088354907
            ],
            [
              357051.05870594352,
              5006244.1212110594
            ],
            [
              357051.71050697961,
              5006244.130274226
            ],
            [
              357055.48619604827,
              5006244.1827744469
            ],
            [
              357082.91398689122,
              5006222.9926939709
            ],
            [
              357090.65506829793,
              5006215.1947116368
            ],
            [
              357117.47822128219,
              5006193.9385274258
            ],
            [
              357120.63420899835,
              5006190.1009429675
            ],
            [
              357123.79019672895,
              5006186.2633585716
            ],
            [
              357147.46723567735,
              5006204.5800415361
            ],
            [
              357124.93787222484,
              5006221.8161857789
            ],
            [
              357132.6063076639,
              5006227.762159803
            ],
            [
              357142.64079852239,
              5006238.8843208756
            ],
            [
              357155.26062253764,
              5006253.8422307018
            ],
            [
              357159.92557571485,
              5006258.8958337009
            ],
            [
              357166.75676426309,
              5006263.6806873884
            ],
            [
              357187.28391488182,
              5006273.6938982457
            ],
            [
              357176.36357719329,
              5006294.0700657433
            ],
            [
              357216.52287463675,
              5006325.157690797
            ],
            [
              357211.06010964134,
              5006327.7425553957
            ],
            [
              357188.5883758628,
              5006334.7490749154
            ],
            [
              357192.00217106834,
              5006346.5416218489
            ],
            [
              357194.86735499481,
              5006345.6201861445
            ],
            [
              357200.53313512419,
              5006343.8006113237
            ],
            [
              357206.1989151715,
              5006341.9810365625
            ],
            [
              357213.88668430567,
              5006339.7419995843
            ],
            [
              357214.45739712432,
              5006340.2417736808
            ],
            [
              357214.48185020173,
              5006340.2346994625
            ],
            [
              357214.90257608151,
              5006340.2271271478
            ],
            [
              357219.99570349435,
              5006343.3059687521
            ],
            [
              357221.19791891816,
              5006344.2135362159
            ],
            [
              357222.7823645692,
              5006345.8248874126
            ],
            [
              357224.74161335552,
              5006348.1799279973
            ],
            [
              357225.5697987731,
              5006349.3891279874
            ],
            [
              357226.12594764616,
              5006350.3531884216
            ],
            [
              357226.82594295841,
              5006351.8437923845
            ],
            [
              357227.45662578457,
              5006353.4267227761
            ],
            [
              357228.12877215975,
              5006355.4640699653
            ],
            [
              357228.62821068492,
              5006357.3595688092
            ],
            [
              357229.00102709286,
              5006359.2244085763
            ],
            [
              357229.19724487531,
              5006360.6843637927
            ],
            [
              357229.24700451718,
              5006362.3381212568
            ],
            [
              357229.17562383012,
              5006364.202217198
            ],
            [
              357228.99573795253,
              5006366.0953419507
            ],
            [
              357228.55568175291,
              5006367.931279012
            ],
            [
              357228.07882968959,
              5006369.5638160957
            ],
            [
              357227.33659003756,
              5006371.4503908847
            ],
            [
              357226.70652726473,
              5006372.6445949133
            ],
            [
              357225.89212356188,
              5006373.1789531037
            ],
            [
              357225.00949857821,
              5006375.9625074407
            ],
            [
              357224.00265053869,
              5006379.8128109649
            ],
            [
              357222.37927905697,
              5006385.939166232
            ],
            [
              357223.50140189455,
              5006386.6030442212
            ],
            [
              357229.31178969116,
              5006392.3478396786
            ],
            [
              357230.81069655111,
              5006393.4475837396
            ],
            [
              357237.95133690344,
              5006367.7300895145
            ],
            [
              357239.45583393128,
              5006362.427715851
            ],
            [
              357240.33495557035,
              5006359.9204324251
            ],
            [
              357241.07287448423,
              5006350.7946429625
            ],
            [
              357241.07828162354,
              5006345.3360621817
            ],
            [
              357240.64036523039,
              5006339.8950730562
            ],
            [
              357240.59412050608,
              5006338.5301637156
            ],
            [
              357240.78507626388,
              5006337.1778871221
            ],
            [
              357241.20745569194,
              5006335.8791524665
            ],
            [
              357241.84848093119,
              5006334.6732491767
            ],
            [
              357242.68875963066,
              5006333.5966583434
            ],
            [
              357243.7028716036,
              5006332.6819490846
            ],
            [
              357244.86013784917,
              5006331.9567932636
            ],
            [
              357246.12554864673,
              5006331.443128353
            ],
            [
              357246.12554864777,
              5006331.4431283539
            ],
            [
              357264.6161481663,
              5006325.3814676264
            ],
            [
              357267.43059905514,
              5006327.5023686169
            ],
            [
              357271.76842293568,
              5006323.2530397139
            ],
            [
              357276.22422041948,
              5006321.5042401766
            ],
            [
              357281.44508439564,
              5006318.9494694015
            ],
            [
              357286.56025280611,
              5006316.099774329
            ],
            [
              357290.62116892746,
              5006313.5468288604
            ],
            [
              357295.07956281927,
              5006310.4208423737
            ],
            [
              357295.97753234708,
              5006308.6456277687
            ],
            [
              357298.51830123534,
              5006309.1686174609
            ],
            [
              357300.10743768624,
              5006309.67818393
            ],
            [
              357300.98133494938,
              5006309.9575148774
            ],
            [
              357302.31683884765,
              5006305.6587485624
            ],
            [
              357302.96589042409,
              5006305.4114551451
            ],
            [
              357304.20469461096,
              5006305.1725965468
            ],
            [
              357304.93687346549,
              5006305.1462167287
            ],
            [
              357305.94561770582,
              5006305.2650785176
            ],
            [
              357306.87176869047,
              5006305.4510086514
            ],
            [
              357315.56576041615,
              5006297.6637045462
            ],
            [
              357321.79481755401,
              5006289.466222384
            ],
            [
              357323.40678519214,
              5006286.1136678327
            ],
            [
              357325.92123127024,
              5006280.8841476087
            ],
            [
              357332.06731273135,
              5006266.5412394069
            ],
            [
              357333.03062225803,
              5006263.2225105418
            ],
            [
              357334.56676814269,
              5006263.6499627568
            ],
            [
              357343.62196338229,
              5006266.6264937473
            ],
            [
              357344.4009489615,
              5006266.8754852321
            ],
            [
              357345.13657998916,
              5006267.2327838121
            ],
            [
              357345.8138867566,
              5006267.6911186483
            ],
            [
              357346.41908642044,
              5006268.2411628626
            ],
            [
              357353.74561014498,
              5006264.649326656
            ],
            [
              357352.04825481359,
              5006262.7134883357
            ],
            [
              357350.06675200124,
              5006261.0696720881
            ],
            [
              357347.85073031153,
              5006259.7590488521
            ],
            [
              357345.45569210203,
              5006258.8144444292
            ],
            [
              357344.489760247,
              5006258.5099219643
            ],
            [
              357337.17177081859,
              5006245.2402155632
            ],
            [
              357338.62534208241,
              5006242.8658635514
            ],
            [
              357339.41179845779,
              5006241.9339155732
            ],
            [
              357340.33489238675,
              5006241.137085435
            ],
            [
              357341.37170131441,
              5006240.4951602686
            ],
            [
              357342.49647888279,
              5006240.0240805484
            ],
            [
              357343.68129426736,
              5006239.7355442783
            ],
            [
              357344.89672576805,
              5006239.6367164757
            ],
            [
              357346.11259141762,
              5006239.7300512567
            ],
            [
              357347.29869846551,
              5006240.0132309049
            ],
            [
              357348.42559313832,
              5006240.4792234153
            ],
            [
              357349.46529204404,
              5006241.1164571159
            ],
            [
              357357.23760701116,
              5006252.7339564189
            ],
            [
              357361.49347471556,
              5006260.480324259
            ],
            [
              357365.78015235218,
              5006268.9223358007
            ],
            [
              357366.34150735097,
              5006268.6251164824
            ],
            [
              357367.83610488829,
              5006271.638310371
            ],
            [
              357367.31854486145,
              5006271.9211734599
            ],
            [
              357371.13511380664,
              5006279.580532725
            ],
            [
              357371.18900716183,
              5006279.553491381
            ],
            [
              357371.88607678277,
              5006281.4026644733
            ],
            [
              357372.11861502973,
              5006282.0327644488
            ],
            [
              357372.7262360396,
              5006283.7149131456
            ],
            [
              357382.92439124518,
              5006280.0613514362
            ],
            [
              357381.13030082028,
              5006275.0648889374
            ],
            [
              357376.5335032111,
              5006264.0948087955
            ],
            [
              357372.39554166113,
              5006255.4191456679
            ],
            [
              357366.43208770914,
              5006243.3680215497
            ],
            [
              357360.89799708757,
              5006232.29531975
            ],
            [
              357357.82395025005,
              5006226.0393783292
            ],
            [
              357355.65321010764,
              5006221.6217411142
            ],
            [
              357351.25427874952,
              5006212.6484527327
            ],
            [
              357347.3158311352,
              5006204.0731372712
            ],
            [
              357344.36073881917,
              5006195.5255395602
            ],
            [
              357342.82761872734,
              5006188.9893408632
            ],
            [
              357341.35688413231,
              5006180.1590367341
            ],
            [
              357340.44665757811,
              5006172.6348529272
            ],
            [
              357340.49338369526,
              5006164.6026728908
            ],
            [
              357341.6104747979,
              5006156.5125742722
            ],
            [
              357342.58816960786,
              5006149.9106801981
            ],
            [
              357343.88501069194,
              5006145.1797421835
            ],
            [
              357344.96030373912,
              5006141.2570221117
            ],
            [
              357345.42320938938,
              5006139.8428351497
            ],
            [
              357346.44427069306,
              5006137.2948064283
            ],
            [
              357349.12507203018,
              5006130.5770609751
            ],
            [
              357351.89905120258,
              5006124.0724267131
            ],
            [
              357356.61878321826,
              5006114.661364601
            ],
            [
              357358.16004696529,
              5006111.5113572637
            ],
            [
              357348.97056092363,
              5006106.7371756807
            ],
            [
              357347.67465729511,
              5006108.8224877128
            ],
            [
              357345.63864199765,
              5006111.1848818529
            ],
            [
              357342.52998100681,
              5006115.151933088
            ],
            [
              357335.22910993086,
              5006123.1762049049
            ],
            [
              357320.06396529375,
              5006112.7599604158
            ],
            [
              357322.20700748119,
              5006109.6398779843
            ],
            [
              357312.95280812221,
              5006102.5174598899
            ],
            [
              357323.01465409208,
              5006086.5040838681
            ],
            [
              357317.21796504728,
              5006080.9164317669
            ],
            [
              357305.97051918844,
              5006062.7128557777
            ],
            [
              357287.0070548259,
              5006046.9070121227
            ],
            [
              357282.39465424244,
              5006044.9961632658
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 266,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360548.98697524407,
              5002563.0046517476
            ],
            [
              360552.0503715814,
              5002613.9005584102
            ],
            [
              360554.43012684101,
              5002625.6211058376
            ],
            [
              360550.41786767635,
              5002629.4571933653
            ],
            [
              360563.87119643559,
              5002646.8533959985
            ],
            [
              360587.51471602073,
              5002677.4357951209
            ],
            [
              360611.97453145852,
              5002708.630493396
            ],
            [
              360609.22503519832,
              5002711.4827043423
            ],
            [
              360618.35629151791,
              5002720.2766630007
            ],
            [
              360594.78633761063,
              5002740.0560674379
            ],
            [
              360610.06447781058,
              5002749.1153681679
            ],
            [
              360631.98274877697,
              5002764.0667538485
            ],
            [
              360648.2684721555,
              5002777.5226702699
            ],
            [
              360694.6630381748,
              5002826.9662700025
            ],
            [
              360731.48891127319,
              5002789.2240069322
            ],
            [
              360739.0282073135,
              5002784.1522607356
            ],
            [
              360758.65821057482,
              5002777.2489159219
            ],
            [
              360774.57806503383,
              5002772.3005553661
            ],
            [
              360789.70579640864,
              5002763.6222834606
            ],
            [
              360809.73968402616,
              5002749.0948331701
            ],
            [
              360815.8969227054,
              5002744.7931579156
            ],
            [
              360821.69847631734,
              5002738.320752725
            ],
            [
              360830.43830299418,
              5002730.6376749463
            ],
            [
              360842.52865233883,
              5002716.5885778554
            ],
            [
              360856.0618440102,
              5002702.5491903406
            ],
            [
              360862.76919022604,
              5002696.4407811258
            ],
            [
              360875.55758922169,
              5002681.6840543803
            ],
            [
              360894.5673564829,
              5002664.3047871944
            ],
            [
              360918.68358015042,
              5002679.8171670102
            ],
            [
              360929.89325162285,
              5002685.9790044753
            ],
            [
              360955.61497811659,
              5002696.2814492499
            ],
            [
              360989.45746601594,
              5002710.1174825113
            ],
            [
              361005.56232950196,
              5002717.1870947396
            ],
            [
              361028.7798818631,
              5002720.3428672822
            ],
            [
              361015.48737949273,
              5002747.0635109842
            ],
            [
              361025.90789968142,
              5002749.4626413183
            ],
            [
              361047.62906378234,
              5002764.8885039538
            ],
            [
              361058.89045461145,
              5002753.7623003479
            ],
            [
              361103.61528417235,
              5002771.5458430471
            ],
            [
              361124.38498957996,
              5002743.3453235039
            ],
            [
              361132.52855183464,
              5002733.4955346109
            ],
            [
              361144.41119105503,
              5002720.131860855
            ],
            [
              361160.95701899234,
              5002703.2543822946
            ],
            [
              361176.24357042805,
              5002689.2044571489
            ],
            [
              361190.45287946338,
              5002677.8340405179
            ],
            [
              361205.74846011511,
              5002665.8765079416
            ],
            [
              361201.59319201083,
              5002655.5223188214
            ],
            [
              361184.34971924248,
              5002632.9979396965
            ],
            [
              361174.83041231503,
              5002626.9457479836
            ],
            [
              361156.63425626891,
              5002615.883472967
            ],
            [
              361135.27809371409,
              5002600.7199233016
            ],
            [
              361140.5213677822,
              5002591.8077127691
            ],
            [
              361106.09878850396,
              5002562.7801217418
            ],
            [
              361110.01423130656,
              5002558.9137624875
            ],
            [
              361086.12291380047,
              5002532.4433655431
            ],
            [
              361088.31479940721,
              5002530.1695523066
            ],
            [
              361072.54126098595,
              5002518.904536033
            ],
            [
              361049.99811280431,
              5002508.0405832613
            ],
            [
              361026.63223853539,
              5002497.1918648975
            ],
            [
              361042.6257482465,
              5002473.104385077
            ],
            [
              361020.68220283434,
              5002451.485504074
            ],
            [
              360917.28353499959,
              5002402.0771350898
            ],
            [
              360890.31752332603,
              5002372.245756343
            ],
            [
              360791.71805599291,
              5002421.0188403996
            ],
            [
              360755.48265680909,
              5002489.9835363785
            ],
            [
              360733.10008213361,
              5002477.3668160755
            ],
            [
              360713.93698336784,
              5002508.7380830664
            ],
            [
              360702.81124836177,
              5002496.7934257267
            ],
            [
              360688.01640281343,
              5002509.9714491861
            ],
            [
              360687.73601484584,
              5002518.2190721603
            ],
            [
              360682.642801137,
              5002532.8175378609
            ],
            [
              360673.92962114239,
              5002532.4520252803
            ],
            [
              360663.12781046733,
              5002533.2680826271
            ],
            [
              360650.27020813333,
              5002535.7436441481
            ],
            [
              360651.75800773996,
              5002543.8400037307
            ],
            [
              360634.30941757176,
              5002546.9116674894
            ],
            [
              360633.90939953382,
              5002544.8996396642
            ],
            [
              360619.48480817646,
              5002546.4622284938
            ],
            [
              360614.9445639911,
              5002535.0809234511
            ],
            [
              360586.25367751654,
              5002532.2235483211
            ],
            [
              360590.24739955628,
              5002547.4978295853
            ],
            [
              360590.66553562903,
              5002552.4455327438
            ],
            [
              360577.1945930426,
              5002554.1955588087
            ],
            [
              360577.85661419551,
              5002558.2258750899
            ],
            [
              360563.9729790308,
              5002560.5092415838
            ],
            [
              360563.5865964861,
              5002557.3964039544
            ],
            [
              360548.98697524407,
              5002563.0046517476
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 267,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360519.54675163858,
              5002506.0835223561
            ],
            [
              360517.36924837984,
              5002506.9817786627
            ],
            [
              360522.0366871524,
              5002515.7386637013
            ],
            [
              360522.34849962493,
              5002520.1143415188
            ],
            [
              360526.25874719635,
              5002533.0650237715
            ],
            [
              360531.62955809437,
              5002546.3756898278
            ],
            [
              360538.80238835531,
              5002545.8694512667
            ],
            [
              360545.06600026158,
              5002543.6137163294
            ],
            [
              360547.87053695426,
              5002548.9691988071
            ],
            [
              360548.98697524535,
              5002563.0046517467
            ],
            [
              360563.5865964861,
              5002557.3964039525
            ],
            [
              360563.97297902958,
              5002560.5092415838
            ],
            [
              360577.85661419627,
              5002558.2258750908
            ],
            [
              360577.1945930426,
              5002554.1955588087
            ],
            [
              360590.66553562979,
              5002552.4455327457
            ],
            [
              360590.24739955814,
              5002547.4978295872
            ],
            [
              360586.25367751654,
              5002532.2235483211
            ],
            [
              360614.9445639911,
              5002535.0809234511
            ],
            [
              360619.48480817646,
              5002546.4622284938
            ],
            [
              360633.90939953312,
              5002544.8996396642
            ],
            [
              360634.30941757176,
              5002546.9116674894
            ],
            [
              360651.75800773996,
              5002543.8400037298
            ],
            [
              360650.2702081349,
              5002535.743644149
            ],
            [
              360663.12781046628,
              5002533.2680826271
            ],
            [
              360673.92962113762,
              5002532.4520252803
            ],
            [
              360682.642801137,
              5002532.8175378609
            ],
            [
              360687.73601484584,
              5002518.2190721594
            ],
            [
              360688.0164028132,
              5002509.9714491833
            ],
            [
              360702.81124836177,
              5002496.7934257267
            ],
            [
              360713.93698336737,
              5002508.7380830674
            ],
            [
              360733.10008213483,
              5002477.3668160755
            ],
            [
              360755.48265680909,
              5002489.9835363785
            ],
            [
              360791.71805599466,
              5002421.0188403986
            ],
            [
              360890.31752332597,
              5002372.2457563421
            ],
            [
              360860.52689236548,
              5002339.170977083
            ],
            [
              360858.14558467729,
              5002333.7020889707
            ],
            [
              360855.37914954894,
              5002328.4866043702
            ],
            [
              360854.33280929993,
              5002328.7687057201
            ],
            [
              360850.81266346056,
              5002329.8446119698
            ],
            [
              360847.18772903417,
              5002330.7203746317
            ],
            [
              360844.47088923794,
              5002331.4782341821
            ],
            [
              360843.46162840346,
              5002331.598052864
            ],
            [
              360842.65651175793,
              5002331.8151055584
            ],
            [
              360841.75029453228,
              5002332.0340370396
            ],
            [
              360840.64931901527,
              5002332.6608052049
            ],
            [
              360838.15345214104,
              5002334.4250486037
            ],
            [
              360833.7004331699,
              5002337.6949364962
            ],
            [
              360829.5044301204,
              5002340.5013000295
            ],
            [
              360823.6009135865,
              5002343.9456678424
            ],
            [
              360820.20064057148,
              5002346.0298725329
            ],
            [
              360816.48962289322,
              5002347.7156345071
            ],
            [
              360812.77290790249,
              5002349.0983837387
            ],
            [
              360809.35392854136,
              5002350.1724103056
            ],
            [
              360804.92950936122,
              5002351.5683111381
            ],
            [
              360800.91130302969,
              5002353.057690898
            ],
            [
              360797.49795525678,
              5002354.4347671
            ],
            [
              360795.18746053195,
              5002355.2861033725
            ],
            [
              360793.1727590831,
              5002355.7277608775
            ],
            [
              360791.26103500737,
              5002356.268532428
            ],
            [
              360789.95034969924,
              5002356.4950159537
            ],
            [
              360788.74451856175,
              5002356.9216063479
            ],
            [
              360787.74470976595,
              5002357.5464946656
            ],
            [
              360786.8553212547,
              5002358.6745761903
            ],
            [
              360785.3856102241,
              5002361.2281499784
            ],
            [
              360782.65777600702,
              5002366.8366463436
            ],
            [
              360781.77220801712,
              5002368.1667478867
            ],
            [
              360780.97453416081,
              5002368.7878798805
            ],
            [
              360780.07019425282,
              5002369.1078398703
            ],
            [
              360778.35504027194,
              5002369.341839876
            ],
            [
              360773.95162166463,
              5002370.0061973985
            ],
            [
              360769.51030632283,
              5002370.492914346
            ],
            [
              360766.88705743576,
              5002370.8448176226
            ],
            [
              360765.7823274965,
              5002371.2695648354
            ],
            [
              360764.47915060743,
              5002371.9000908621
            ],
            [
              360762.48509772745,
              5002373.4528829576
            ],
            [
              360760.59965527692,
              5002375.4079097761
            ],
            [
              360759.81331106677,
              5002376.635139782
            ],
            [
              360759.44075524056,
              5002378.3599254005
            ],
            [
              360758.85480103508,
              5002379.4823682066
            ],
            [
              360758.16385946813,
              5002380.4046710618
            ],
            [
              360757.26702802908,
              5002381.1286735423
            ],
            [
              360756.36081060977,
              5002381.3476052089
            ],
            [
              360754.74487966305,
              5002381.4786981102
            ],
            [
              360753.73568483454,
              5002381.5985157406
            ],
            [
              360752.73205481115,
              5002382.0213486664
            ],
            [
              360751.62926855311,
              5002382.5471230811
            ],
            [
              360751.13308525895,
              5002383.061589146
            ],
            [
              360750.73424830864,
              5002383.3721551867
            ],
            [
              360750.3317226909,
              5002383.4806632437
            ],
            [
              360749.72693027096,
              5002383.592965669
            ],
            [
              360749.12589210912,
              5002383.9072893197
            ],
            [
              360748.22906131472,
              5002384.631327359
            ],
            [
              360747.64304085902,
              5002385.7537714457
            ],
            [
              360747.37346247229,
              5002387.5776711758
            ],
            [
              360747.50084424211,
              5002388.9900120106
            ],
            [
              360747.9372258131,
              5002390.6997648031
            ],
            [
              360748.070239325,
              5002392.4151552375
            ],
            [
              360748.29684491543,
              5002393.7246244205
            ],
            [
              360748.22014803503,
              5002395.0396944815
            ],
            [
              360748.0442280203,
              5002396.4576717308
            ],
            [
              360747.28979679657,
              5002399.4021710707
            ],
            [
              360745.38585175975,
              5002405.803757092
            ],
            [
              360744.6114151674,
              5002409.0126795955
            ],
            [
              360743.39083632984,
              5002414.0878859945
            ],
            [
              360742.34242320625,
              5002417.543093917
            ],
            [
              360741.67219749902,
              5002419.5765657164
            ],
            [
              360740.88960768632,
              5002421.0058171181
            ],
            [
              360739.80177299137,
              5002422.3397133537
            ],
            [
              360738.50422719342,
              5002423.273253696
            ],
            [
              360735.91101438407,
              5002425.2414338607
            ],
            [
              360734.41314483428,
              5002426.2797602387
            ],
            [
              360733.31029233406,
              5002426.8055360559
            ],
            [
              360732.10452733835,
              5002427.2321255384
            ],
            [
              360730.89494180138,
              5002427.4566950211
            ],
            [
              360728.8764857839,
              5002427.6963317981
            ],
            [
              360726.35433725716,
              5002428.0463567404
            ],
            [
              360723.92578009184,
              5002427.9904248593
            ],
            [
              360720.0798712598,
              5002427.859804783
            ],
            [
              360718.36096207227,
              5002427.8917483483
            ],
            [
              360716.64775088982,
              5002428.2267403109
            ],
            [
              360715.64036652859,
              5002428.4475521753
            ],
            [
              360714.73602650472,
              5002428.7675123848
            ],
            [
              360713.53770443128,
              5002429.598181149
            ],
            [
              360712.35440017103,
              5002431.236970434
            ],
            [
              360710.2798968915,
              5002433.902847616
            ],
            [
              360709.58902162529,
              5002434.8251494803
            ],
            [
              360708.78765968425,
              5002435.2442592839
            ],
            [
              360707.57807407004,
              5002435.4688288113
            ],
            [
              360705.15514853812,
              5002435.7159466213
            ],
            [
              360701.71927373635,
              5002435.8808609946
            ],
            [
              360700.3056100434,
              5002436.0081953062
            ],
            [
              360699.29822563933,
              5002436.2290072059
            ],
            [
              360697.88831622916,
              5002436.5583627727
            ],
            [
              360695.37937409262,
              5002437.6154787382
            ],
            [
              360693.22367790161,
              5002438.6440969296
            ],
            [
              360691.62658506207,
              5002439.7853307109
            ],
            [
              360690.04256699153,
              5002441.6336934408
            ],
            [
              360688.27511982311,
              5002444.4959556572
            ],
            [
              360687.01143146935,
              5002447.247828465
            ],
            [
              360686.25317933649,
              5002449.9902728759
            ],
            [
              360685.85843374813,
              5002455.9595961962
            ],
            [
              360685.87532910722,
              5002456.8687451649
            ],
            [
              360685.98769330367,
              5002457.4729656531
            ],
            [
              360686.30789053655,
              5002458.3764781486
            ],
            [
              360687.0306790325,
              5002459.1714457143
            ],
            [
              360687.75722249405,
              5002460.1684700642
            ],
            [
              360688.27968722972,
              5002461.0682236701
            ],
            [
              360688.39580574149,
              5002461.8744654115
            ],
            [
              360688.41270111635,
              5002462.7836143738
            ],
            [
              360688.02707082703,
              5002463.8012715075
            ],
            [
              360687.33431811194,
              5002464.6225450942
            ],
            [
              360686.43929789984,
              5002465.4475775706
            ],
            [
              360684.83657398378,
              5002466.285797378
            ],
            [
              360681.23034412455,
              5002468.1717049815
            ],
            [
              360678.62586754578,
              5002469.5337864859
            ],
            [
              360677.72340428975,
              5002469.9547397019
            ],
            [
              360677.02314294304,
              5002470.371970797
            ],
            [
              360676.02708834404,
              5002471.1988809127
            ],
            [
              360675.53278256149,
              5002471.8143756175
            ],
            [
              360675.03659929207,
              5002472.3288419265
            ],
            [
              360674.03296981181,
              5002472.7517107502
            ],
            [
              360671.9152896913,
              5002473.09421953
            ],
            [
              360669.58977650176,
              5002473.1374363024
            ],
            [
              360666.25500133267,
              5002473.3004367007
            ],
            [
              360664.33764559729,
              5002473.5381949674
            ],
            [
              360662.62624476611,
              5002473.9741813103
            ],
            [
              360659.90377202904,
              5002474.428992725
            ],
            [
              360657.88719245576,
              5002474.7696227022
            ],
            [
              360656.47728291369,
              5002475.0989784673
            ],
            [
              360654.76782578445,
              5002475.6359920027
            ],
            [
              360652.35053171561,
              5002476.1861598231
            ],
            [
              360649.66023338633,
              5002477.0741670281
            ],
            [
              360646.94151486811,
              5002477.7309997678
            ],
            [
              360644.62532204197,
              5002478.2793243174
            ],
            [
              360643.21728927776,
              5002478.7096729856
            ],
            [
              360642.00776971428,
              5002478.9342414802
            ],
            [
              360640.79630706558,
              5002479.057818329
            ],
            [
              360638.8751962143,
              5002479.0935198208
            ],
            [
              360635.93919215334,
              5002478.9459908018
            ],
            [
              360629.76019331237,
              5002478.4545111945
            ],
            [
              360627.83532808174,
              5002478.2881913893
            ],
            [
              360626.41603163473,
              5002478.1124406606
            ],
            [
              360625.09971397225,
              5002478.0358750531
            ],
            [
              360623.98754066823,
              5002478.0565434266
            ],
            [
              360622.67685414234,
              5002478.2829919904
            ],
            [
              360621.47102323297,
              5002478.70961856
            ],
            [
              360619.46946137247,
              5002479.8583693244
            ],
            [
              360618.37036224507,
              5002480.5861312523
            ],
            [
              360617.16453132313,
              5002481.012757835
            ],
            [
              360615.54866535292,
              5002481.1438144958
            ],
            [
              360614.438303256,
              5002481.2655125316
            ],
            [
              360613.42347624281,
              5002481.0822807839
            ],
            [
              360611.69142541935,
              5002480.4070967622
            ],
            [
              360606.08155110659,
              5002477.8840232585
            ],
            [
              360603.62858903082,
              5002476.5148999123
            ],
            [
              360602.40405139752,
              5002475.9313476551
            ],
            [
              360601.08210183901,
              5002475.5517322989
            ],
            [
              360599.66092845489,
              5002475.2749886597
            ],
            [
              360598.14046565228,
              5002475.1011535032
            ],
            [
              360596.42162211298,
              5002475.133096084
            ],
            [
              360594.70834411733,
              5002475.4680896364
            ],
            [
              360592.19745760929,
              5002476.424179011
            ],
            [
              360589.08935396158,
              5002477.8966479795
            ],
            [
              360583.07528231601,
              5002480.8378651021
            ],
            [
              360579.27435946482,
              5002483.1316092648
            ],
            [
              360576.9999029396,
              5002484.6312437747
            ],
            [
              360575.80540175858,
              5002485.6639687
            ],
            [
              360573.71393638005,
              5002487.4206992351
            ],
            [
              360572.61490403529,
              5002488.1484955875
            ],
            [
              360570.81178825087,
              5002489.0914320406
            ],
            [
              360568.70161682373,
              5002489.8380192202
            ],
            [
              360566.38730032317,
              5002490.4873368759
            ],
            [
              360564.37259705085,
              5002490.928959934
            ],
            [
              360562.6612626565,
              5002491.3649807982
            ],
            [
              360561.55277722614,
              5002491.5876717865
            ],
            [
              360560.14849912614,
              5002492.2200774811
            ],
            [
              360556.7445363382,
              5002494.1022626767
            ],
            [
              360551.53558083024,
              5002496.8263915582
            ],
            [
              360546.94080416305,
              5002499.9432889437
            ],
            [
              360545.04597443395,
              5002501.3932110267
            ],
            [
              360544.34758969228,
              5002501.9114352474
            ],
            [
              360543.34402611997,
              5002502.3343030876
            ],
            [
              360542.5370320449,
              5002502.4503634712
            ],
            [
              360541.02032330976,
              5002502.4785497496
            ],
            [
              360533.63361404539,
              5002502.3126687538
            ],
            [
              360532.62441795127,
              5002502.4324512817
            ],
            [
              360532.1169715228,
              5002502.3408538019
            ],
            [
              360531.70687076828,
              5002502.0453205975
            ],
            [
              360531.1976130929,
              5002501.8526934423
            ],
            [
              360530.38867524039,
              5002501.8677266147
            ],
            [
              360529.38323347067,
              5002502.1895304946
            ],
            [
              360527.17196114809,
              5002502.9379966827
            ],
            [
              360519.54675163858,
              5002506.0835223561
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 268,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              361778.51102542056,
              5002663.3708485728
            ],
            [
              361777.91323970695,
              5002660.7671651701
            ],
            [
              361754.54081816733,
              5002661.2015251061
            ],
            [
              361733.9706404708,
              5002666.7497790372
            ],
            [
              361724.40762251493,
              5002668.649517105
            ],
            [
              361707.13630334014,
              5002679.5484338151
            ],
            [
              361698.19329294428,
              5002688.3246002318
            ],
            [
              361670.72896581382,
              5002710.6335806614
            ],
            [
              361660.65925543703,
              5002715.7254025256
            ],
            [
              361695.97099042917,
              5002748.7012926573
            ],
            [
              361703.84190752479,
              5002757.3133937679
            ],
            [
              361713.66644985223,
              5002776.7495716875
            ],
            [
              361778.14461814292,
              5002715.3524332065
            ],
            [
              361777.95327189466,
              5002705.0563851632
            ],
            [
              361778.10733547068,
              5002701.5472494932
            ],
            [
              361782.89126742748,
              5002672.8701357879
            ],
            [
              361778.51102542056,
              5002663.3708485728
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 269,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356815.31266967952,
              5007313.2107646819
            ],
            [
              356836.41398458689,
              5007289.0545464354
            ],
            [
              356852.48747027939,
              5007269.5926597444
            ],
            [
              356882.59890302294,
              5007232.3919856623
            ],
            [
              356891.89626297244,
              5007219.4730840689
            ],
            [
              356899.15240773908,
              5007208.9071209561
            ],
            [
              356908.13338692952,
              5007196.9026039634
            ],
            [
              356841.43906458776,
              5007217.1198138343
            ],
            [
              356838.69137141696,
              5007223.4848266253
            ],
            [
              356836.53298652743,
              5007239.6796647273
            ],
            [
              356797.09678027494,
              5007211.3568312312
            ],
            [
              356791.13166860258,
              5007205.497551362
            ],
            [
              356779.26238323504,
              5007197.0556599535
            ],
            [
              356775.95946772461,
              5007200.3968153065
            ],
            [
              356772.39678357105,
              5007203.870222155
            ],
            [
              356757.38927305158,
              5007191.0319957798
            ],
            [
              356757.59116626653,
              5007192.9803455053
            ],
            [
              356737.91176251974,
              5007180.2353007523
            ],
            [
              356725.74089727475,
              5007168.1700300155
            ],
            [
              356718.02845095115,
              5007168.8565928247
            ],
            [
              356716.28035476519,
              5007171.6191852195
            ],
            [
              356702.82766907796,
              5007159.2026135819
            ],
            [
              356683.1565155483,
              5007164.3678832529
            ],
            [
              356681.77064725413,
              5007164.8116433062
            ],
            [
              356677.17956065788,
              5007165.3150451733
            ],
            [
              356667.95431832393,
              5007179.0021979669
            ],
            [
              356660.6177233315,
              5007190.5790442564
            ],
            [
              356653.93061375269,
              5007198.0373322042
            ],
            [
              356649.41677200765,
              5007203.0674189758
            ],
            [
              356637.65395641612,
              5007222.0474049123
            ],
            [
              356620.17745767563,
              5007209.0690242695
            ],
            [
              356598.28476125264,
              5007238.2863652157
            ],
            [
              356592.42394284945,
              5007241.2379741622
            ],
            [
              356581.24228239211,
              5007247.3291410301
            ],
            [
              356579.84675875545,
              5007247.9923836095
            ],
            [
              356573.16107370605,
              5007251.4885017211
            ],
            [
              356563.94492239313,
              5007254.2393331518
            ],
            [
              356545.82326156896,
              5007263.9409816554
            ],
            [
              356543.52161439793,
              5007266.9795024628
            ],
            [
              356543.58051856875,
              5007270.1467759712
            ],
            [
              356538.48131621897,
              5007274.7326919101
            ],
            [
              356531.21564745461,
              5007281.2669976698
            ],
            [
              356524.46032935189,
              5007291.7173716128
            ],
            [
              356515.24695614836,
              5007298.9240881298
            ],
            [
              356510.38225984381,
              5007302.7292621527
            ],
            [
              356530.87316282489,
              5007328.6704293666
            ],
            [
              356533.84054862935,
              5007326.1443119915
            ],
            [
              356551.68543705629,
              5007343.1143339965
            ],
            [
              356581.95312161249,
              5007367.9398630466
            ],
            [
              356573.5952054839,
              5007376.4678485952
            ],
            [
              356567.13462970738,
              5007381.5595625378
            ],
            [
              356575.9376506366,
              5007389.752235923
            ],
            [
              356593.43832227995,
              5007408.1293056766
            ],
            [
              356629.96747478639,
              5007431.5683400454
            ],
            [
              356639.32208149647,
              5007436.066374355
            ],
            [
              356652.69501329964,
              5007454.8849853836
            ],
            [
              356669.75684379687,
              5007436.5715281973
            ],
            [
              356682.91072306089,
              5007432.9813825982
            ],
            [
              356690.16945444548,
              5007450.7714051399
            ],
            [
              356697.49339983548,
              5007444.9695504708
            ],
            [
              356723.18333383818,
              5007416.9519225201
            ],
            [
              356756.28190559481,
              5007380.1986595411
            ],
            [
              356765.62118422525,
              5007369.3220588751
            ],
            [
              356780.21233448508,
              5007354.096109556
            ],
            [
              356795.67672846402,
              5007335.4296211973
            ],
            [
              356815.31266967952,
              5007313.2107646819
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 270,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355455.86282702198,
              5007922.0647299038
            ],
            [
              355456.38103305892,
              5007922.7559246114
            ],
            [
              355501.06887791952,
              5007966.5275691329
            ],
            [
              355503.9457283229,
              5007973.0690899715
            ],
            [
              355511.90626962954,
              5007974.561469757
            ],
            [
              355529.31996147806,
              5007984.2624058397
            ],
            [
              355546.20336049731,
              5007997.9252239475
            ],
            [
              355547.65233016846,
              5008007.1940250006
            ],
            [
              355555.25503232953,
              5008009.0575807691
            ],
            [
              355565.75017429248,
              5008024.264149446
            ],
            [
              355572.91508547525,
              5008017.2957480624
            ],
            [
              355581.3997675603,
              5008007.7510523619
            ],
            [
              355589.96820710407,
              5008012.5129792066
            ],
            [
              355591.9396246231,
              5008010.6536064073
            ],
            [
              355609.4846223045,
              5008018.4547410514
            ],
            [
              355612.25078894361,
              5008007.3732006233
            ],
            [
              355618.1248021822,
              5007986.0264657037
            ],
            [
              355617.94646829378,
              5007976.4385380847
            ],
            [
              355621.31804590049,
              5007963.5473752841
            ],
            [
              355622.29408621631,
              5007959.5741067557
            ],
            [
              355627.05104091397,
              5007950.4842836596
            ],
            [
              355632.83568949561,
              5007931.912862435
            ],
            [
              355638.5387800507,
              5007920.1568676131
            ],
            [
              355633.13114028133,
              5007916.7806186695
            ],
            [
              355639.03494016308,
              5007910.3030891567
            ],
            [
              355619.58077457262,
              5007892.3061991399
            ],
            [
              355604.54787783063,
              5007878.2995777447
            ],
            [
              355588.34287733486,
              5007863.1641911929
            ],
            [
              355570.39222660015,
              5007847.0065722857
            ],
            [
              355558.2149474193,
              5007832.6127085714
            ],
            [
              355552.68031172507,
              5007826.080908333
            ],
            [
              355550.3123862886,
              5007828.242125432
            ],
            [
              355547.51691011962,
              5007830.2140916027
            ],
            [
              355544.8244814715,
              5007832.2852409873
            ],
            [
              355543.02700150263,
              5007833.5313019278
            ],
            [
              355541.72945885215,
              5007834.46490712
            ],
            [
              355540.33832968835,
              5007835.8044389086
            ],
            [
              355537.64959227626,
              5007838.0776126916
            ],
            [
              355536.4569749189,
              5007839.2113593332
            ],
            [
              355535.6119196972,
              5007839.7079763794
            ],
            [
              355534.71126749011,
              5007840.2299781926
            ],
            [
              355533.20588515315,
              5007840.8642919026
            ],
            [
              355531.69674529776,
              5007841.2965825358
            ],
            [
              355530.49090938474,
              5007841.7231963407
            ],
            [
              355529.09032049391,
              5007842.5576894889
            ],
            [
              355527.8920665172,
              5007843.3883837778
            ],
            [
              355527.1037890421,
              5007844.5146099748
            ],
            [
              355525.82127775217,
              5007846.256378673
            ],
            [
              355524.93196499592,
              5007847.3844840843
            ],
            [
              355523.9414844503,
              5007848.5144711658
            ],
            [
              355520.85585587885,
              5007851.1992130736
            ],
            [
              355516.39976680587,
              5007856.334738371
            ],
            [
              355508.97230239888,
              5007864.8602240887
            ],
            [
              355503.32551862724,
              5007872.7045064084
            ],
            [
              355499.18776443449,
              5007878.6424833313
            ],
            [
              355496.72008468577,
              5007881.9220466558
            ],
            [
              355495.23349127959,
              5007883.5665472159
            ],
            [
              355493.84605406906,
              5007885.1081392551
            ],
            [
              355491.85576511652,
              5007886.8630370814
            ],
            [
              355488.3638519239,
              5007889.4543075785
            ],
            [
              355487.36961386737,
              5007890.3822717937
            ],
            [
              355485.58340710576,
              5007892.234438112
            ],
            [
              355484.20167279785,
              5007894.0790814348
            ],
            [
              355483.31417294324,
              5007895.3082176615
            ],
            [
              355482.7338677948,
              5007896.7337331986
            ],
            [
              355481.65919569944,
              5007898.7747583203
            ],
            [
              355479.88614137127,
              5007901.3340589171
            ],
            [
              355477.3192392558,
              5007904.7165323664
            ],
            [
              355476.00381565245,
              5007906.431221447
            ],
            [
              355475.12383159046,
              5007908.0644395044
            ],
            [
              355473.95181554183,
              5007910.3093682732
            ],
            [
              355473.37332329591,
              5007911.8359144339
            ],
            [
              355472.58699128224,
              5007913.0631690603
            ],
            [
              355471.39618680609,
              5007914.297946739
            ],
            [
              355470.00130011229,
              5007915.4354560012
            ],
            [
              355468.501554302,
              5007916.3728226107
            ],
            [
              355467.29571892286,
              5007916.7994722929
            ],
            [
              355464.98514904891,
              5007917.6508556819
            ],
            [
              355462.27581026853,
              5007918.8128488874
            ],
            [
              355459.16575589211,
              5007920.1843527481
            ],
            [
              355455.86282702198,
              5007922.0647299038
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 271,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356039.27263380488,
              5007717.5172225302
            ],
            [
              356049.69083459,
              5007703.692376107
            ],
            [
              356071.61361081927,
              5007674.6033990737
            ],
            [
              356056.83285705053,
              5007663.9532251079
            ],
            [
              356047.61528746365,
              5007675.6243145708
            ],
            [
              356051.53387611813,
              5007677.3093760321
            ],
            [
              356046.92979169899,
              5007689.5846219538
            ],
            [
              356042.00333868858,
              5007690.7112414436
            ],
            [
              356038.27892018243,
              5007689.4214209067
            ],
            [
              356017.58499906125,
              5007710.7084695436
            ],
            [
              356012.0148048214,
              5007698.5243484909
            ],
            [
              356010.68540275999,
              5007694.5070504537
            ],
            [
              356002.57416300132,
              5007689.0825692182
            ],
            [
              356000.15830619802,
              5007678.5643651588
            ],
            [
              355985.12784570031,
              5007691.9987372365
            ],
            [
              355983.77756486367,
              5007693.6338067306
            ],
            [
              355985.42627232574,
              5007695.6730947793
            ],
            [
              355998.03666280786,
              5007711.7680752017
            ],
            [
              355985.58340331627,
              5007723.5670379922
            ],
            [
              355990.90027815936,
              5007731.0579302814
            ],
            [
              355968.23908569227,
              5007749.8788502654
            ],
            [
              355979.42650344392,
              5007763.7003778741
            ],
            [
              355999.77023263631,
              5007744.0025669858
            ],
            [
              355996.80716668064,
              5007739.3428099258
            ],
            [
              356021.65542562137,
              5007716.076183578
            ],
            [
              356024.84122663125,
              5007713.6571925022
            ],
            [
              356031.60945886147,
              5007723.4670336638
            ],
            [
              356034.59836383181,
              5007724.6200863114
            ],
            [
              356039.27263380488,
              5007717.5172225302
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 272,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356916.58304866531,
              5007195.7234915048
            ],
            [
              356925.7287210981,
              5007182.7120016366
            ],
            [
              356892.67542956403,
              5007146.5082762372
            ],
            [
              356877.61053412012,
              5007158.3073712364
            ],
            [
              356845.56854152749,
              5007183.0516513642
            ],
            [
              356837.22788683191,
              5007189.5659749676
            ],
            [
              356827.54445726803,
              5007196.8966922434
            ],
            [
              356821.65932271112,
              5007192.1107544089
            ],
            [
              356815.84269093501,
              5007180.5470549185
            ],
            [
              356806.7691104518,
              5007169.2012755694
            ],
            [
              356803.1648859136,
              5007164.5084469654
            ],
            [
              356796.86937145592,
              5007164.5263434434
            ],
            [
              356792.39496566966,
              5007175.847106047
            ],
            [
              356786.43547648302,
              5007189.1854220126
            ],
            [
              356782.48932999792,
              5007199.3507884163
            ],
            [
              356791.13166860369,
              5007205.4975513648
            ],
            [
              356797.09678027488,
              5007211.3568312302
            ],
            [
              356836.53298652743,
              5007239.6796647273
            ],
            [
              356838.69137141813,
              5007223.4848266197
            ],
            [
              356841.43906458927,
              5007217.1198138371
            ],
            [
              356854.19458041043,
              5007212.8697534958
            ],
            [
              356874.78622722224,
              5007207.0112079149
            ],
            [
              356886.53378994548,
              5007203.4501411207
            ],
            [
              356908.13338692952,
              5007196.9026039634
            ],
            [
              356916.58304866531,
              5007195.7234915048
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 273,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356767.34023507894,
              5006903.933754757
            ],
            [
              356788.68345652946,
              5006892.1967439065
            ],
            [
              356793.01717475365,
              5006891.3939466476
            ],
            [
              356794.81958786777,
              5006884.0501946285
            ],
            [
              356789.40053023509,
              5006881.7612598734
            ],
            [
              356783.59461399133,
              5006877.8775681583
            ],
            [
              356778.22696835978,
              5006868.378915865
            ],
            [
              356776.86562740384,
              5006864.536306425
            ],
            [
              356776.85814813134,
              5006858.9971877327
            ],
            [
              356779.03329334111,
              5006852.6534836031
            ],
            [
              356781.82420285704,
              5006848.5903931065
            ],
            [
              356776.12274366786,
              5006834.3260898748
            ],
            [
              356771.69924607268,
              5006823.2590777837
            ],
            [
              356755.85382389283,
              5006797.9043719871
            ],
            [
              356748.95403584436,
              5006790.2802720461
            ],
            [
              356695.06399034412,
              5006829.355741906
            ],
            [
              356696.47190699267,
              5006831.0295853456
            ],
            [
              356699.85649575474,
              5006839.1821629759
            ],
            [
              356699.85649575392,
              5006839.1821629759
            ],
            [
              356690.14102946065,
              5006847.2364030834
            ],
            [
              356680.54508925992,
              5006854.0825780844
            ],
            [
              356658.92098641803,
              5006870.4577838564
            ],
            [
              356648.4908998959,
              5006883.6065317979
            ],
            [
              356671.9934671488,
              5006900.8671606518
            ],
            [
              356680.98221519851,
              5006908.5499975495
            ],
            [
              356690.74538795528,
              5006920.1439354066
            ],
            [
              356704.40458166908,
              5006933.6956662787
            ],
            [
              356722.13579365396,
              5006952.1819430813
            ],
            [
              356726.99098472093,
              5006956.165157387
            ],
            [
              356739.26140685653,
              5006940.0284178974
            ],
            [
              356757.2803931596,
              5006917.0853472678
            ],
            [
              356766.33699293324,
              5006905.7667225078
            ],
            [
              356767.34023507894,
              5006903.933754757
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 274,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359140.47160063166,
              5005266.3392815674
            ],
            [
              359150.73599737935,
              5005264.0979384026
            ],
            [
              359166.0766308548,
              5005263.8127269829
            ],
            [
              359182.68118661648,
              5005262.3099018326
            ],
            [
              359189.41085470066,
              5005263.4707546169
            ],
            [
              359182.97144033952,
              5005253.2107902886
            ],
            [
              359168.73319069628,
              5005228.7663343418
            ],
            [
              359165.02803517057,
              5005227.1817947384
            ],
            [
              359158.91268068022,
              5005229.4081902569
            ],
            [
              359154.92886569444,
              5005232.6053268742
            ],
            [
              359151.11754135886,
              5005245.8412559973
            ],
            [
              359147.03024834284,
              5005253.3575721961
            ],
            [
              359137.76977742912,
              5005259.4084975049
            ],
            [
              359140.47160063166,
              5005266.3392815674
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 275,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359713.39951425878,
              5005464.0089194514
            ],
            [
              359732.88286913716,
              5005450.9029586418
            ],
            [
              359738.58653759776,
              5005447.3091892665
            ],
            [
              359751.68103912607,
              5005464.9048736002
            ],
            [
              359768.63765089371,
              5005459.7195196152
            ],
            [
              359742.31669818697,
              5005419.8434086973
            ],
            [
              359725.14007522532,
              5005424.2180997403
            ],
            [
              359716.98925923795,
              5005426.1134873899
            ],
            [
              359698.45946190745,
              5005432.762793663
            ],
            [
              359707.81654321746,
              5005452.4421566948
            ],
            [
              359713.39951425878,
              5005464.0089194514
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 276,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359872.41729602421,
              5005313.0542878658
            ],
            [
              359878.67156932392,
              5005317.05627653
            ],
            [
              359897.83716427832,
              5005340.6607083417
            ],
            [
              359918.15667770576,
              5005355.8200143846
            ],
            [
              359914.84298601846,
              5005365.7280451721
            ],
            [
              359892.72046257538,
              5005377.4252503365
            ],
            [
              359901.65969194967,
              5005392.0699973283
            ],
            [
              359903.30437382549,
              5005394.0654228525
            ],
            [
              359909.094740631,
              5005397.2413440803
            ],
            [
              359913.18493655068,
              5005399.1913018618
            ],
            [
              359915.82072555105,
              5005394.402019076
            ],
            [
              359920.67245353904,
              5005388.4433162808
            ],
            [
              359934.07211778802,
              5005368.5626888629
            ],
            [
              359930.03521287028,
              5005362.464686635
            ],
            [
              359937.56961259752,
              5005362.3950081319
            ],
            [
              359950.32533304003,
              5005359.0606748266
            ],
            [
              359969.85122261546,
              5005352.4328557206
            ],
            [
              359963.71677335631,
              5005348.2531023677
            ],
            [
              359951.96122348023,
              5005353.6666817917
            ],
            [
              359934.22348572401,
              5005333.6196399378
            ],
            [
              359890.95605785871,
              5005292.8670881344
            ],
            [
              359872.41729602421,
              5005313.0542878658
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 277,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359942.41735085467,
              5005311.0040659327
            ],
            [
              359960.08817472321,
              5005324.7150582084
            ],
            [
              359984.87948468275,
              5005305.8067989722
            ],
            [
              359982.8630618545,
              5005297.7335027363
            ],
            [
              359958.85892571125,
              5005281.0659866324
            ],
            [
              359955.34343142546,
              5005291.1084246254
            ],
            [
              359942.41735085467,
              5005311.0040659327
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 278,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359879.30131091346,
              5005461.5091715418
            ],
            [
              359906.05066280667,
              5005480.7614930943
            ],
            [
              359910.05246787722,
              5005478.8130626837
            ],
            [
              359908.04969047039,
              5005471.9306867681
            ],
            [
              359912.67050233431,
              5005464.4926861105
            ],
            [
              359916.93654295424,
              5005461.2419158118
            ],
            [
              359922.09866266494,
              5005444.1352607189
            ],
            [
              359937.944019093,
              5005450.9043929875
            ],
            [
              359943.52246398566,
              5005441.3171658656
            ],
            [
              359950.05257406743,
              5005444.5907842768
            ],
            [
              359956.95552663103,
              5005432.367621202
            ],
            [
              359961.79480131285,
              5005436.360346945
            ],
            [
              359972.66188502655,
              5005441.1482637655
            ],
            [
              359967.71624832269,
              5005449.5571093559
            ],
            [
              359952.8880730138,
              5005475.803321518
            ],
            [
              359944.5436765714,
              5005496.6348789968
            ],
            [
              359954.56667121215,
              5005504.8407491483
            ],
            [
              359956.00731767848,
              5005502.9994384898
            ],
            [
              359962.90914073726,
              5005508.0879021762
            ],
            [
              359976.17318326345,
              5005489.2423184747
            ],
            [
              359986.63963985752,
              5005471.6603670372
            ],
            [
              359990.36080784822,
              5005464.3330457984
            ],
            [
              359993.20590673614,
              5005452.5990774482
            ],
            [
              359993.20344066987,
              5005448.8142099231
            ],
            [
              359996.21888047073,
              5005442.8425785443
            ],
            [
              360005.57804706151,
              5005450.1478387835
            ],
            [
              360020.17606351624,
              5005452.5789812775
            ],
            [
              360029.70173492952,
              5005450.7239918541
            ],
            [
              360043.72708195524,
              5005444.6692898385
            ],
            [
              360051.24224088975,
              5005442.54236768
            ],
            [
              360042.8228107079,
              5005429.0484465444
            ],
            [
              360028.85341769044,
              5005414.3478889642
            ],
            [
              360013.38626578887,
              5005400.2022394482
            ],
            [
              360022.53517382278,
              5005386.2247144375
            ],
            [
              360045.6008537921,
              5005407.6563295284
            ],
            [
              360052.65731106052,
              5005400.7927624909
            ],
            [
              360045.68100975809,
              5005393.8534936151
            ],
            [
              360054.80686227285,
              5005383.5852490971
            ],
            [
              360083.66401477047,
              5005375.1638281457
            ],
            [
              360097.06980270828,
              5005344.1508194078
            ],
            [
              360135.21928915189,
              5005360.7550875572
            ],
            [
              360137.19101192808,
              5005358.6722149979
            ],
            [
              360144.37179082207,
              5005356.8371636625
            ],
            [
              360102.97697367705,
              5005320.2211434059
            ],
            [
              360100.97379144368,
              5005317.1504325941
            ],
            [
              360069.30747887818,
              5005323.0518099219
            ],
            [
              360037.19678435382,
              5005294.5653538154
            ],
            [
              360045.48486576747,
              5005284.4501047116
            ],
            [
              360052.76197496202,
              5005271.4512280272
            ],
            [
              360044.15066409163,
              5005267.6888090251
            ],
            [
              360036.86098931188,
              5005261.283321307
            ],
            [
              360004.19741626881,
              5005298.4556401912
            ],
            [
              360009.30807666061,
              5005301.6879127091
            ],
            [
              360025.35760530154,
              5005309.0244274987
            ],
            [
              360034.61192380195,
              5005315.4026431395
            ],
            [
              360023.44151304598,
              5005335.2354627084
            ],
            [
              360021.60513842507,
              5005339.3263300015
            ],
            [
              360015.59145097114,
              5005350.4474689169
            ],
            [
              360010.72819014179,
              5005359.6347950418
            ],
            [
              360006.47416357679,
              5005357.0048517939
            ],
            [
              360003.05439078109,
              5005353.2524237279
            ],
            [
              360001.41135999252,
              5005348.6882404257
            ],
            [
              359994.37629423634,
              5005351.6226172792
            ],
            [
              359989.67997419048,
              5005356.9463536106
            ],
            [
              359985.17269472015,
              5005362.3836562019
            ],
            [
              359980.65477665135,
              5005360.0608419757
            ],
            [
              359976.20454280084,
              5005354.1017093817
            ],
            [
              359963.06846545514,
              5005372.7813937906
            ],
            [
              359958.04276059085,
              5005385.8880963381
            ],
            [
              359954.04707038664,
              5005388.1668236433
            ],
            [
              359962.33497866133,
              5005392.4646659903
            ],
            [
              359961.07968545327,
              5005395.1413031612
            ],
            [
              359955.7487621741,
              5005404.2112840442
            ],
            [
              359949.12032361008,
              5005407.6889166189
            ],
            [
              359939.49721442361,
              5005419.3218361484
            ],
            [
              359933.18485084519,
              5005428.1542235557
            ],
            [
              359929.57689186913,
              5005435.068801932
            ],
            [
              359903.37182463653,
              5005418.9974835757
            ],
            [
              359895.05765037343,
              5005415.5613753367
            ],
            [
              359890.39814740518,
              5005419.5075157536
            ],
            [
              359888.55412279087,
              5005417.0548532493
            ],
            [
              359883.13089323748,
              5005418.5876169186
            ],
            [
              359877.14063252235,
              5005404.771177534
            ],
            [
              359866.50786224927,
              5005389.0924042696
            ],
            [
              359833.50703423127,
              5005404.921213652
            ],
            [
              359827.49306438462,
              5005407.0812955657
            ],
            [
              359815.16158273834,
              5005410.1036659377
            ],
            [
              359801.41235720809,
              5005412.3942525983
            ],
            [
              359794.36280045129,
              5005398.9322013976
            ],
            [
              359785.07471424906,
              5005384.5327409506
            ],
            [
              359766.66125291045,
              5005393.5285557592
            ],
            [
              359778.47256556974,
              5005409.8772437414
            ],
            [
              359780.97766128281,
              5005414.864656073
            ],
            [
              359775.08512664569,
              5005415.6490603564
            ],
            [
              359792.99665151665,
              5005453.1067461055
            ],
            [
              359801.03548836877,
              5005449.7518336764
            ],
            [
              359818.4430362173,
              5005446.8465716494
            ],
            [
              359822.79143979849,
              5005445.2760618348
            ],
            [
              359826.24127918069,
              5005440.463665572
            ],
            [
              359842.02119171916,
              5005431.4061266733
            ],
            [
              359845.88396041654,
              5005438.7825855855
            ],
            [
              359851.8736518416,
              5005435.3194825901
            ],
            [
              359848.96450148762,
              5005429.1356531568
            ],
            [
              359857.71284881275,
              5005423.7591825426
            ],
            [
              359863.60355900397,
              5005435.0082830265
            ],
            [
              359865.77367290651,
              5005441.0768743064
            ],
            [
              359871.67667953204,
              5005459.3681007791
            ],
            [
              359880.57363956777,
              5005453.8032177212
            ],
            [
              359885.33727066766,
              5005455.4576556152
            ],
            [
              359885.24978676328,
              5005457.4512983179
            ],
            [
              359881.67146313848,
              5005459.2608335409
            ],
            [
              359879.30131091346,
              5005461.5091715418
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 279,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359812.31707750593,
              5005527.4618505239
            ],
            [
              359797.62832805124,
              5005505.4077984616
            ],
            [
              359779.84445098689,
              5005511.0936296452
            ],
            [
              359764.77585249231,
              5005521.9377913494
            ],
            [
              359783.4405339731,
              5005546.5761758219
            ],
            [
              359812.31707750593,
              5005527.4618505239
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 280,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359476.59154440014,
              5005691.0422865059
            ],
            [
              359468.95814697118,
              5005669.814754921
            ],
            [
              359443.63500359718,
              5005668.6625981284
            ],
            [
              359433.27332632564,
              5005675.3472558316
            ],
            [
              359432.02404287172,
              5005684.5674377168
            ],
            [
              359467.3113743533,
              5005690.4033380933
            ],
            [
              359476.59154440014,
              5005691.0422865059
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 281,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358375.37680217368,
              5005555.5972387493
            ],
            [
              358379.32439582952,
              5005533.2061261367
            ],
            [
              358377.35901598452,
              5005530.5278497785
            ],
            [
              358374.31978382415,
              5005515.8902472807
            ],
            [
              358368.56915324269,
              5005504.2418994652
            ],
            [
              358364.71052350447,
              5005492.0397711927
            ],
            [
              358361.07239005476,
              5005473.0914954478
            ],
            [
              358361.12890223297,
              5005464.7757556429
            ],
            [
              358361.65033281309,
              5005452.9671814088
            ],
            [
              358360.29802775569,
              5005439.5743687758
            ],
            [
              358341.80086093111,
              5005443.5944065433
            ],
            [
              358320.45013562188,
              5005432.5953097269
            ],
            [
              358303.42281716893,
              5005426.6624478167
            ],
            [
              358283.01053128223,
              5005414.267478276
            ],
            [
              358275.47858681547,
              5005430.3648568727
            ],
            [
              358268.32389703387,
              5005445.1505561341
            ],
            [
              358269.3862317623,
              5005447.1910316581
            ],
            [
              358285.03178299218,
              5005458.5072704274
            ],
            [
              358290.58298152854,
              5005482.8923041634
            ],
            [
              358297.43242558511,
              5005516.1709142104
            ],
            [
              358300.52667320397,
              5005544.7907016734
            ],
            [
              358299.71777927974,
              5005600.2935736664
            ],
            [
              358312.09129619115,
              5005602.7513009198
            ],
            [
              358320.63259095151,
              5005583.6351245977
            ],
            [
              358315.52131703461,
              5005577.8446014235
            ],
            [
              358333.94653055765,
              5005572.8325698394
            ],
            [
              358359.58454287908,
              5005565.8585112598
            ],
            [
              358367.35789337696,
              5005553.7098867781
            ],
            [
              358375.37680217368,
              5005555.5972387493
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 282,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358431.7623792591,
              5005559.1751255216
            ],
            [
              358426.63570499432,
              5005569.8948378637
            ],
            [
              358421.12231373618,
              5005581.3720533876
            ],
            [
              358417.92167363869,
              5005591.2228244524
            ],
            [
              358415.49872591434,
              5005595.0063705025
            ],
            [
              358415.55803242704,
              5005605.7309445227
            ],
            [
              358409.44270706683,
              5005612.0754621606
            ],
            [
              358422.24514703796,
              5005620.5605975781
            ],
            [
              358430.27977390011,
              5005621.6573524252
            ],
            [
              358442.70959200279,
              5005629.2592686722
            ],
            [
              358456.53719429934,
              5005635.4110380039
            ],
            [
              358462.95953894255,
              5005634.5311754504
            ],
            [
              358481.76684727118,
              5005616.3669999484
            ],
            [
              358496.63679044083,
              5005591.7199697876
            ],
            [
              358485.3764716381,
              5005582.1654284522
            ],
            [
              358475.06200169428,
              5005572.9688271862
            ],
            [
              358468.55895082239,
              5005566.7056438215
            ],
            [
              358471.15852382249,
              5005544.8762742179
            ],
            [
              358452.15681448265,
              5005523.0730034634
            ],
            [
              358452.69222726789,
              5005521.5609070016
            ],
            [
              358445.95346608275,
              5005534.2708997307
            ],
            [
              358438.40378284716,
              5005548.8709068932
            ],
            [
              358433.97880182654,
              5005553.9763008906
            ],
            [
              358431.7623792591,
              5005559.1751255216
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 283,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359252.59903503297,
              5005853.5931331664
            ],
            [
              359251.49255882134,
              5005852.7611916447
            ],
            [
              359239.48860742734,
              5005867.2009833381
            ],
            [
              359235.22121568333,
              5005872.0956415934
            ],
            [
              359232.92425916757,
              5005878.1001551934
            ],
            [
              359227.24470445421,
              5005881.0719911903
            ],
            [
              359214.38610536273,
              5005900.8085172521
            ],
            [
              359229.40038456157,
              5005908.854852967
            ],
            [
              359214.89723891474,
              5005918.5140653905
            ],
            [
              359211.4804037037,
              5005921.1301111123
            ],
            [
              359207.64217227715,
              5005926.704986955
            ],
            [
              359210.86545028683,
              5005933.2998070223
            ],
            [
              359202.03629752732,
              5005939.1159748333
            ],
            [
              359204.36118002748,
              5005941.534068983
            ],
            [
              359197.41992381698,
              5005946.451053896
            ],
            [
              359192.51333407313,
              5005955.2491205093
            ],
            [
              359188.56913000753,
              5005959.8708012309
            ],
            [
              359183.01999236929,
              5005969.0706667192
            ],
            [
              359178.32678798778,
              5005964.2755360454
            ],
            [
              359171.75410126609,
              5005972.3933615312
            ],
            [
              359168.21770109067,
              5005975.4719545292
            ],
            [
              359166.79454438837,
              5005980.2812361093
            ],
            [
              359162.36981613061,
              5005985.4621438505
            ],
            [
              359156.9236933983,
              5005991.8208352327
            ],
            [
              359153.0331702673,
              5005994.5584035702
            ],
            [
              359153.09347937518,
              5005997.8018710064
            ],
            [
              359148.4133356185,
              5006001.7128784591
            ],
            [
              359148.49733815738,
              5006006.2305902494
            ],
            [
              359147.99675433111,
              5006010.8958887542
            ],
            [
              359150.70734146406,
              5006013.2789295549
            ],
            [
              359161.40317274333,
              5006014.9340962144
            ],
            [
              359158.89664617088,
              5006023.3283633161
            ],
            [
              359164.73025432503,
              5006037.6061203135
            ],
            [
              359164.19922931143,
              5006051.5316266632
            ],
            [
              359171.40772497136,
              5006052.6722404156
            ],
            [
              359175.10960323509,
              5006052.255762524
            ],
            [
              359183.93831487704,
              5006053.2503914256
            ],
            [
              359189.92552618764,
              5006051.0532671912
            ],
            [
              359199.15532258648,
              5006048.6799567044
            ],
            [
              359200.7725873036,
              5006035.9032478612
            ],
            [
              359201.12449725822,
              5006023.3477529408
            ],
            [
              359211.3938022239,
              5006002.0669457139
            ],
            [
              359202.03650243318,
              5005991.3483790923
            ],
            [
              359206.8471347958,
              5005985.1323519219
            ],
            [
              359210.63975442626,
              5005983.4085817738
            ],
            [
              359215.08653300029,
              5005977.9528178331
            ],
            [
              359222.45090102107,
              5005962.6266185902
            ],
            [
              359228.43199479755,
              5005950.7359557366
            ],
            [
              359232.16838549066,
              5005952.3450277466
            ],
            [
              359251.02513003384,
              5005961.0234138109
            ],
            [
              359255.10780469724,
              5005954.6481844382
            ],
            [
              359264.88436357258,
              5005960.7657163441
            ],
            [
              359280.1830258391,
              5005970.1402473804
            ],
            [
              359294.81664000516,
              5005977.6373207998
            ],
            [
              359305.30698767497,
              5005964.8063175585
            ],
            [
              359312.8852954185,
              5005954.3764972761
            ],
            [
              359316.05385030457,
              5005944.0286718272
            ],
            [
              359314.05080059246,
              5005937.9765850715
            ],
            [
              359322.37951076194,
              5005915.7826441834
            ],
            [
              359309.07346066751,
              5005906.835926895
            ],
            [
              359301.39196005621,
              5005898.3938960796
            ],
            [
              359272.08984206861,
              5005884.2280990053
            ],
            [
              359273.2495640546,
              5005875.9318306707
            ],
            [
              359265.3513697079,
              5005875.1592637934
            ],
            [
              359269.97210281092,
              5005862.2015260151
            ],
            [
              359270.8764546052,
              5005854.304049517
            ],
            [
              359264.2312115776,
              5005857.3171797832
            ],
            [
              359258.45421016851,
              5005857.5559313474
            ],
            [
              359252.59903503297,
              5005853.5931331664
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 284,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359049.63808938791,
              5005416.9726599799
            ],
            [
              359024.07613840041,
              5005401.8731177822
            ],
            [
              358969.56778472895,
              5005368.2401102288
            ],
            [
              358954.28196034994,
              5005384.899699958
            ],
            [
              358936.40521168988,
              5005367.9396620393
            ],
            [
              358932.77857890184,
              5005364.0379298525
            ],
            [
              358892.35018337186,
              5005393.764741702
            ],
            [
              358892.47398337221,
              5005400.4234182797
            ],
            [
              358898.33991110924,
              5005410.6998160789
            ],
            [
              358913.75428986864,
              5005424.4012281504
            ],
            [
              358924.8289814734,
              5005428.6914703073
            ],
            [
              358939.4602002012,
              5005431.4907686422
            ],
            [
              358925.33364321571,
              5005476.3818082474
            ],
            [
              358909.64652557444,
              5005496.8206366803
            ],
            [
              358876.0912802837,
              5005474.8165334938
            ],
            [
              358858.37725186668,
              5005515.1937510222
            ],
            [
              358850.69758696429,
              5005523.6450372068
            ],
            [
              358865.22734145779,
              5005525.9861046681
            ],
            [
              358871.56818422506,
              5005547.7076407736
            ],
            [
              358885.73699810711,
              5005543.4086597078
            ],
            [
              358898.14693641668,
              5005533.9199185362
            ],
            [
              358916.92415231344,
              5005520.8276864998
            ],
            [
              358931.1309634705,
              5005530.7135279812
            ],
            [
              358943.52563241142,
              5005539.5834759176
            ],
            [
              358945.61753929628,
              5005532.5625244938
            ],
            [
              358952.8699639652,
              5005517.4325614236
            ],
            [
              358961.44714765082,
              5005500.6464840202
            ],
            [
              358980.00447688508,
              5005517.1814942779
            ],
            [
              358991.64914757229,
              5005523.3105658861
            ],
            [
              359005.68545192265,
              5005528.0341369351
            ],
            [
              359012.1047018111,
              5005527.6354823466
            ],
            [
              359019.27734269184,
              5005519.6374088814
            ],
            [
              359022.63273956784,
              5005512.2718285294
            ],
            [
              359025.93521606171,
              5005501.1135351937
            ],
            [
              358979.89494588465,
              5005472.9752721908
            ],
            [
              358976.49957835616,
              5005472.2339908592
            ],
            [
              358992.99653350899,
              5005458.5887922412
            ],
            [
              358997.09018764884,
              5005449.715000472
            ],
            [
              359007.15967983037,
              5005441.5814661952
            ],
            [
              359023.24725833634,
              5005436.4577856911
            ],
            [
              359037.33235870354,
              5005430.5199740604
            ],
            [
              359044.88977784786,
              5005424.7035279255
            ],
            [
              359049.63808938791,
              5005416.9726599799
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 285,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357509.02961455454,
              5006415.1773036132
            ],
            [
              357506.92414180824,
              5006419.7047435371
            ],
            [
              357500.95094653597,
              5006428.4306401825
            ],
            [
              357497.74276168505,
              5006433.1172926929
            ],
            [
              357493.84130983247,
              5006435.0982966637
            ],
            [
              357489.55773295625,
              5006446.1933739046
            ],
            [
              357484.21381436766,
              5006466.3210798763
            ],
            [
              357479.82358995354,
              5006464.3628208488
            ],
            [
              357472.36684728606,
              5006480.4126723148
            ],
            [
              357454.4676788316,
              5006498.2885824144
            ],
            [
              357458.18541286624,
              5006500.6673328839
            ],
            [
              357462.94577385735,
              5006500.5788115468
            ],
            [
              357468.99653955217,
              5006501.7429771433
            ],
            [
              357483.91722130601,
              5006519.7170843873
            ],
            [
              357486.46300303907,
              5006522.3020372503
            ],
            [
              357508.24257181067,
              5006543.0644927667
            ],
            [
              357510.90633944125,
              5006535.2391442675
            ],
            [
              357514.89829346613,
              5006529.1170561472
            ],
            [
              357521.25246895017,
              5006522.1950943461
            ],
            [
              357522.75109522691,
              5006515.1038253261
            ],
            [
              357532.22265340894,
              5006495.7041574046
            ],
            [
              357537.06910314917,
              5006494.858053416
            ],
            [
              357545.50156645029,
              5006489.3014122378
            ],
            [
              357547.38953371107,
              5006486.2423772309
            ],
            [
              357549.91498292383,
              5006480.7596973432
            ],
            [
              357556.32296382712,
              5006470.9207717963
            ],
            [
              357557.82908045262,
              5006470.5687727137
            ],
            [
              357578.15594877122,
              5006453.8832142092
            ],
            [
              357583.88213985943,
              5006448.0528716696
            ],
            [
              357589.79619045177,
              5006440.7070725216
            ],
            [
              357590.41248805274,
              5006438.2786508948
            ],
            [
              357588.50389195309,
              5006435.2643248457
            ],
            [
              357584.68507185159,
              5006435.0085496893
            ],
            [
              357566.51883255166,
              5006430.8805541489
            ],
            [
              357555.88326438103,
              5006427.3750036536
            ],
            [
              357531.85594519443,
              5006414.3878083741
            ],
            [
              357516.807128402,
              5006400.9142737845
            ],
            [
              357509.02961455454,
              5006415.1773036132
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 286,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357245.94553967426,
              5006693.299198525
            ],
            [
              357266.87597092026,
              5006705.8192566428
            ],
            [
              357248.85621521506,
              5006770.7747685481
            ],
            [
              357258.80453319807,
              5006773.5856153052
            ],
            [
              357280.21961313143,
              5006718.688456689
            ],
            [
              357289.14003494638,
              5006696.3470945088
            ],
            [
              357309.30284868187,
              5006650.8364444952
            ],
            [
              357316.77350255818,
              5006636.0931248721
            ],
            [
              357324.37591831817,
              5006622.3532212665
            ],
            [
              357303.65721177036,
              5006607.146996621
            ],
            [
              357294.19956159772,
              5006591.9008214353
            ],
            [
              357290.59809768508,
              5006588.8417012049
            ],
            [
              357279.07783473976,
              5006608.4376879716
            ],
            [
              357270.20517921407,
              5006624.6499358751
            ],
            [
              357277.19496580056,
              5006629.533889343
            ],
            [
              357259.86717906856,
              5006663.820541502
            ],
            [
              357245.94553967426,
              5006693.299198525
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 287,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357839.85390289617,
              5006248.4628494298
            ],
            [
              357839.8613166752,
              5006250.7607312463
            ],
            [
              357839.96564678889,
              5006252.1130214371
            ],
            [
              357840.21022413467,
              5006256.0012020813
            ],
            [
              357840.64279512217,
              5006262.8780194139
            ],
            [
              357841.08305092715,
              5006269.877004222
            ],
            [
              357840.63120834721,
              5006274.660050855
            ],
            [
              357840.17936583288,
              5006279.4430975001
            ],
            [
              357840.82567270339,
              5006292.3130522622
            ],
            [
              357841.038134242,
              5006296.5438119192
            ],
            [
              357840.44991689251,
              5006304.222787817
            ],
            [
              357839.36133877828,
              5006318.4338079616
            ],
            [
              357838.26874472311,
              5006332.6972626001
            ],
            [
              357836.54657128296,
              5006341.021984783
            ],
            [
              357828.05027772475,
              5006339.8524906719
            ],
            [
              357819.55398395978,
              5006338.6829965068
            ],
            [
              357812.93668409408,
              5006336.5303800907
            ],
            [
              357809.16267519433,
              5006344.4390112692
            ],
            [
              357799.62641174957,
              5006355.3626140635
            ],
            [
              357786.76811768411,
              5006352.5930234035
            ],
            [
              357784.57910585072,
              5006357.3115045661
            ],
            [
              357783.4510357824,
              5006364.6652588248
            ],
            [
              357799.21257999132,
              5006375.6241578879
            ],
            [
              357806.1673269989,
              5006382.3218644792
            ],
            [
              357803.44238185242,
              5006387.5274072634
            ],
            [
              357805.78020133363,
              5006389.2302354099
            ],
            [
              357806.91771398665,
              5006394.0288174897
            ],
            [
              357809.0466289639,
              5006399.9772639461
            ],
            [
              357815.7737290659,
              5006399.4996347334
            ],
            [
              357818.34048456396,
              5006400.7233109046
            ],
            [
              357821.69707567745,
              5006400.8920526588
            ],
            [
              357823.63645009877,
              5006399.4690049235
            ],
            [
              357828.02058460872,
              5006398.9251295151
            ],
            [
              357833.1773122478,
              5006396.4020072808
            ],
            [
              357835.20221057296,
              5006393.3591914624
            ],
            [
              357838.19270760292,
              5006392.4944936903
            ],
            [
              357841.58367539285,
              5006394.5119323358
            ],
            [
              357845.8110967027,
              5006377.6432171175
            ],
            [
              357854.96049959969,
              5006378.3977506207
            ],
            [
              357868.75521461363,
              5006380.221730018
            ],
            [
              357867.20692127669,
              5006386.0616562394
            ],
            [
              357864.53582242486,
              5006405.3870804245
            ],
            [
              357866.01925711869,
              5006420.1064560395
            ],
            [
              357857.97907434177,
              5006423.6841025427
            ],
            [
              357847.70033182343,
              5006431.9107836531
            ],
            [
              357839.22373249714,
              5006439.9635328436
            ],
            [
              357839.06418511918,
              5006442.5636062268
            ],
            [
              357841.35608830734,
              5006448.4423219291
            ],
            [
              357832.74173075915,
              5006458.873783933
            ],
            [
              357823.22960917122,
              5006446.8132869713
            ],
            [
              357815.07376519841,
              5006439.5803207904
            ],
            [
              357799.83857502212,
              5006454.9609935079
            ],
            [
              357795.44448517327,
              5006462.9959674692
            ],
            [
              357800.83266855741,
              5006473.1255051987
            ],
            [
              357805.23655270529,
              5006479.148449257
            ],
            [
              357808.81622478022,
              5006481.5503440127
            ],
            [
              357790.15580648551,
              5006498.3466533897
            ],
            [
              357786.06295894826,
              5006492.4507296523
            ],
            [
              357777.73566962325,
              5006475.0370583693
            ],
            [
              357772.6689666984,
              5006473.1406100402
            ],
            [
              357770.3072365516,
              5006470.146102217
            ],
            [
              357757.39835772914,
              5006458.0229859063
            ],
            [
              357755.63607768557,
              5006453.4457267206
            ],
            [
              357757.36486317514,
              5006450.5847198516
            ],
            [
              357757.31227771053,
              5006447.7568382323
            ],
            [
              357755.89357180049,
              5006444.7447942421
            ],
            [
              357756.67927860894,
              5006441.9013242843
            ],
            [
              357739.63196308556,
              5006438.3417407395
            ],
            [
              357728.69142503059,
              5006436.2401686925
            ],
            [
              357723.83311602526,
              5006445.5505353222
            ],
            [
              357698.87622437137,
              5006439.4139208812
            ],
            [
              357680.0485511974,
              5006433.1500538699
            ],
            [
              357675.69431324978,
              5006435.745568498
            ],
            [
              357655.12981476629,
              5006445.9766264269
            ],
            [
              357651.65633181686,
              5006445.2030232716
            ],
            [
              357641.7685837936,
              5006433.9332776451
            ],
            [
              357640.05709540827,
              5006438.532703138
            ],
            [
              357635.16846868023,
              5006446.3097307784
            ],
            [
              357627.49881936132,
              5006454.907067541
            ],
            [
              357622.8143614133,
              5006458.6276196055
            ],
            [
              357619.27913589886,
              5006465.2993323524
            ],
            [
              357606.86861565604,
              5006485.8634229871
            ],
            [
              357604.14869547734,
              5006489.9666808881
            ],
            [
              357603.77509970975,
              5006495.8332637809
            ],
            [
              357611.8704582082,
              5006495.5839881273
            ],
            [
              357623.45539579785,
              5006502.3787121465
            ],
            [
              357633.15861248138,
              5006508.912246854
            ],
            [
              357641.82106218219,
              5006521.4046411244
            ],
            [
              357644.42003269744,
              5006528.3664630102
            ],
            [
              357649.19878083869,
              5006535.682669404
            ],
            [
              357654.13464987342,
              5006540.8238125704
            ],
            [
              357652.94964489707,
              5006544.8335055979
            ],
            [
              357662.11325547,
              5006548.9086813498
            ],
            [
              357675.78653475962,
              5006556.4544411432
            ],
            [
              357679.7712813259,
              5006558.2563028084
            ],
            [
              357664.93321341847,
              5006579.10162016
            ],
            [
              357660.86804381345,
              5006578.2886038078
            ],
            [
              357647.86473454564,
              5006553.6492962027
            ],
            [
              357643.82932109135,
              5006559.7471738886
            ],
            [
              357640.89814445988,
              5006561.4801650867
            ],
            [
              357640.0644871252,
              5006564.4576816326
            ],
            [
              357636.1205087697,
              5006570.1589054316
            ],
            [
              357632.53843623516,
              5006574.0761420364
            ],
            [
              357618.5535290332,
              5006581.6425686395
            ],
            [
              357624.5884105002,
              5006587.4544089818
            ],
            [
              357632.02373478602,
              5006594.0676043816
            ],
            [
              357656.68645756197,
              5006618.8850138327
            ],
            [
              357658.78217112488,
              5006616.3840445941
            ],
            [
              357660.37221180025,
              5006619.7357969126
            ],
            [
              357661.2865920089,
              5006630.4525998393
            ],
            [
              357664.43441909651,
              5006632.1279352931
            ],
            [
              357665.85582569422,
              5006632.9594743261
            ],
            [
              357665.39013692166,
              5006634.6642476404
            ],
            [
              357664.79737476649,
              5006636.8342057485
            ],
            [
              357664.3901541598,
              5006638.5488038445
            ],
            [
              357662.03556127736,
              5006637.8988814857
            ],
            [
              357660.28875388287,
              5006637.4756469736
            ],
            [
              357657.88482388284,
              5006636.753181491
            ],
            [
              357654.97514732729,
              5006635.9500431241
            ],
            [
              357651.37949349108,
              5006634.9575590715
            ],
            [
              357647.90112519945,
              5006633.3066838337
            ],
            [
              357645.71439407673,
              5006632.2526498754
            ],
            [
              357639.86813837045,
              5006629.4941320308
            ],
            [
              357636.01353762875,
              5006637.6453852719
            ],
            [
              357635.30079379986,
              5006640.0259167748
            ],
            [
              357635.15261736349,
              5006645.0004998222
            ],
            [
              357637.62348505529,
              5006648.3856609436
            ],
            [
              357627.91628645634,
              5006657.9324238561
            ],
            [
              357608.26673490269,
              5006678.1851031566
            ],
            [
              357603.29961224686,
              5006673.401866694
            ],
            [
              357587.68740049197,
              5006689.8586083045
            ],
            [
              357587.57524194894,
              5006698.2360299304
            ],
            [
              357589.29164079227,
              5006707.6986582968
            ],
            [
              357596.34049985505,
              5006719.1193095753
            ],
            [
              357603.68834865373,
              5006737.549338609
            ],
            [
              357610.57407389855,
              5006747.4119428284
            ],
            [
              357606.52174053615,
              5006751.991525528
            ],
            [
              357602.36571191793,
              5006757.7317712381
            ],
            [
              357600.34583615034,
              5006761.9644705951
            ],
            [
              357600.2444666127,
              5006766.1425118344
            ],
            [
              357595.76395270257,
              5006768.9558218811
            ],
            [
              357598.04653120285,
              5006771.7975375727
            ],
            [
              357601.9683753071,
              5006778.1484023072
            ],
            [
              357607.26002984022,
              5006787.5102429418
            ],
            [
              357620.55175036384,
              5006776.4135469869
            ],
            [
              357643.88247651956,
              5006758.2158392519
            ],
            [
              357652.26191889081,
              5006772.0667937482
            ],
            [
              357657.46869538171,
              5006778.0183439404
            ],
            [
              357661.65515906311,
              5006780.4871816663
            ],
            [
              357667.08857691061,
              5006781.5003193561
            ],
            [
              357675.62543971097,
              5006769.8234195122
            ],
            [
              357649.31072350527,
              5006750.7852061335
            ],
            [
              357656.74990439747,
              5006743.1437786901
            ],
            [
              357660.33014970861,
              5006740.8078811476
            ],
            [
              357663.54580682615,
              5006736.0055317869
            ],
            [
              357667.92346226826,
              5006731.6265904596
            ],
            [
              357673.81880625332,
              5006724.7825847818
            ],
            [
              357671.44307984813,
              5006723.2626366457
            ],
            [
              357674.1328711441,
              5006720.6318256985
            ],
            [
              357680.39226477692,
              5006724.8167669354
            ],
            [
              357686.67491998838,
              5006730.2525913808
            ],
            [
              357696.5313975843,
              5006716.8918387853
            ],
            [
              357702.76067502325,
              5006708.517397359
            ],
            [
              357704.99801586359,
              5006703.629292191
            ],
            [
              357703.55785817612,
              5006701.6913321828
            ],
            [
              357708.23892656114,
              5006696.6924468521
            ],
            [
              357713.05826373462,
              5006699.1289369743
            ],
            [
              357726.0213305442,
              5006701.5543016912
            ],
            [
              357737.58206847386,
              5006700.312765874
            ],
            [
              357763.26247764414,
              5006709.9597254796
            ],
            [
              357769.08547454776,
              5006694.6795265106
            ],
            [
              357772.53200274572,
              5006689.7091900781
            ],
            [
              357775.7959248877,
              5006680.6054379605
            ],
            [
              357782.51120697451,
              5006667.2450697236
            ],
            [
              357784.88016990811,
              5006665.7177390186
            ],
            [
              357788.01341242203,
              5006661.4007587349
            ],
            [
              357779.79423363769,
              5006655.2781571755
            ],
            [
              357773.38184616715,
              5006648.0950557627
            ],
            [
              357765.48170631373,
              5006640.71143171
            ],
            [
              357768.75933957187,
              5006638.9390150812
            ],
            [
              357771.41340461472,
              5006634.3256810298
            ],
            [
              357764.8249374181,
              5006628.9573855987
            ],
            [
              357769.62811211875,
              5006623.6976108737
            ],
            [
              357774.52362557693,
              5006618.5268215658
            ],
            [
              357781.59726470744,
              5006623.1121896664
            ],
            [
              357788.24090594472,
              5006628.9754919643
            ],
            [
              357791.25344362174,
              5006629.9173022192
            ],
            [
              357792.54552810121,
              5006631.0725105722
            ],
            [
              357792.51552290248,
              5006634.3385989256
            ],
            [
              357791.10911266849,
              5006639.0091267172
            ],
            [
              357793.22740314581,
              5006639.7785446839
            ],
            [
              357797.34913042787,
              5006648.1943097534
            ],
            [
              357799.57059006591,
              5006645.8074905528
            ],
            [
              357803.88929036673,
              5006643.0581334932
            ],
            [
              357811.01903505326,
              5006639.9720618688
            ],
            [
              357813.67471959698,
              5006637.479268142
            ],
            [
              357816.62925324211,
              5006633.7591951964
            ],
            [
              357819.78334146453,
              5006628.1768512521
            ],
            [
              357823.85612893064,
              5006618.1258444749
            ],
            [
              357826.67208634393,
              5006612.4776040623
            ],
            [
              357828.73536655423,
              5006605.626879436
            ],
            [
              357832.48164853523,
              5006597.650000846
            ],
            [
              357839.43391927326,
              5006589.6848954223
            ],
            [
              357835.38206869765,
              5006586.9181904243
            ],
            [
              357834.47104164527,
              5006580.2779348968
            ],
            [
              357837.74579686485,
              5006579.6696272409
            ],
            [
              357845.11758617946,
              5006576.8562644795
            ],
            [
              357852.21847216779,
              5006572.1976251593
            ],
            [
              357851.16045378346,
              5006570.1594781196
            ],
            [
              357848.21819152619,
              5006564.4915583953
            ],
            [
              357845.27592933603,
              5006558.8236385705
            ],
            [
              357844.04754939553,
              5006551.6691495124
            ],
            [
              357848.92089596047,
              5006551.9434672184
            ],
            [
              357852.41412074497,
              5006550.6164667374
            ],
            [
              357849.13699381443,
              5006543.4653894175
            ],
            [
              357846.84830732684,
              5006538.4712054469
            ],
            [
              357844.85309673933,
              5006534.1174203604
            ],
            [
              357842.51949314389,
              5006529.0252215909
            ],
            [
              357845.06648209237,
              5006528.2564791227
            ],
            [
              357853.29274780123,
              5006523.1430215007
            ],
            [
              357857.64811764815,
              5006520.5918777585
            ],
            [
              357863.16164109187,
              5006517.4865300441
            ],
            [
              357859.97493835684,
              5006512.6080851313
            ],
            [
              357875.15463454457,
              5006497.5145272063
            ],
            [
              357874.2199821893,
              5006493.8911650563
            ],
            [
              357897.64935271785,
              5006485.3072266132
            ],
            [
              357893.83418617415,
              5006476.0163325928
            ],
            [
              357894.46969121305,
              5006472.8839138197
            ],
            [
              357894.83516337338,
              5006464.5554910172
            ],
            [
              357893.67237428209,
              5006457.9891386777
            ],
            [
              357889.41116801766,
              5006452.6939832475
            ],
            [
              357887.39429639111,
              5006446.8370223967
            ],
            [
              357890.3839231428,
              5006445.8021163205
            ],
            [
              357903.43872837978,
              5006439.9742799448
            ],
            [
              357906.78923864203,
              5006437.285556023
            ],
            [
              357912.93824733916,
              5006435.4717670353
            ],
            [
              357945.14268372016,
              5006430.0835452657
            ],
            [
              357964.49427339132,
              5006431.7321455209
            ],
            [
              357971.59332648863,
              5006431.1366321733
            ],
            [
              357956.21998387267,
              5006376.9239545763
            ],
            [
              357955.19756330835,
              5006363.5018652016
            ],
            [
              357952.03285204241,
              5006338.8129024887
            ],
            [
              357945.95750610001,
              5006339.3920427822
            ],
            [
              357929.80589571496,
              5006343.3055675877
            ],
            [
              357914.12412424717,
              5006348.1188463829
            ],
            [
              357914.71564272331,
              5006335.2895952091
            ],
            [
              357909.26586760231,
              5006325.8076994987
            ],
            [
              357899.09221116459,
              5006315.498618505
            ],
            [
              357897.0162714257,
              5006315.5055287592
            ],
            [
              357895.13773896225,
              5006317.5480620051
            ],
            [
              357892.24636317318,
              5006316.9375281371
            ],
            [
              357900.41970218293,
              5006291.8376960419
            ],
            [
              357907.31651654985,
              5006273.2955471259
            ],
            [
              357912.18104957585,
              5006260.8787819799
            ],
            [
              357913.44443961763,
              5006255.6540482892
            ],
            [
              357913.74795517972,
              5006243.9113580193
            ],
            [
              357907.94399998261,
              5006233.6914129127
            ],
            [
              357905.81878429587,
              5006224.7820230825
            ],
            [
              357906.42366575875,
              5006222.2277475446
            ],
            [
              357907.23167320999,
              5006209.5444715293
            ],
            [
              357912.59343574871,
              5006190.2462250544
            ],
            [
              357917.28279727319,
              5006196.6707224157
            ],
            [
              357930.48788843135,
              5006218.6094540125
            ],
            [
              357934.56028248725,
              5006220.1160013536
            ],
            [
              357937.78519097139,
              5006223.6300378982
            ],
            [
              357940.25268691097,
              5006226.3642651672
            ],
            [
              357945.98593646259,
              5006232.1256721662
            ],
            [
              357945.17159041017,
              5006240.72473964
            ],
            [
              357966.32318784745,
              5006237.6411633743
            ],
            [
              357969.91951292817,
              5006238.9813503744
            ],
            [
              357971.76302731474,
              5006235.9074199647
            ],
            [
              357970.84510125179,
              5006233.4281217363
            ],
            [
              357974.55446191924,
              5006215.8111526398
            ],
            [
              357976.12294289604,
              5006203.9075052207
            ],
            [
              357977.59583262104,
              5006200.9585586553
            ],
            [
              357979.95169651048,
              5006195.3878656486
            ],
            [
              357982.36931619258,
              5006190.0911665
            ],
            [
              357983.91823197686,
              5006185.9899430685
            ],
            [
              357988.60843235377,
              5006187.3750014408
            ],
            [
              357992.33478293102,
              5006183.1342428811
            ],
            [
              357992.57062666013,
              5006176.0137775969
            ],
            [
              357989.52533248131,
              5006170.6719727386
            ],
            [
              357996.12319271063,
              5006153.674218257
            ],
            [
              357987.31686865177,
              5006151.8892998099
            ],
            [
              357957.08350726869,
              5006153.1299943132
            ],
            [
              357929.10574276844,
              5006153.0656228093
            ],
            [
              357921.98893919436,
              5006158.2645347891
            ],
            [
              357911.599157119,
              5006163.1444816301
            ],
            [
              357905.15489921853,
              5006145.4678162904
            ],
            [
              357900.62429567584,
              5006134.9515199391
            ],
            [
              357886.13574744907,
              5006112.7164697628
            ],
            [
              357874.6721561086,
              5006080.9211636614
            ],
            [
              357872.05431019707,
              5006061.0016950471
            ],
            [
              357871.31381532981,
              5006048.0361518571
            ],
            [
              357870.72611971927,
              5006030.0147095937
            ],
            [
              357863.83853035711,
              5006002.0625359118
            ],
            [
              357860.08650480403,
              5005975.483346032
            ],
            [
              357857.37218538852,
              5005965.6542710792
            ],
            [
              357852.95431452908,
              5005968.7534684008
            ],
            [
              357839.68424191762,
              5005982.6357194437
            ],
            [
              357823.05733337253,
              5005993.2851253711
            ],
            [
              357815.25014624692,
              5006001.3843247099
            ],
            [
              357813.13189571514,
              5006010.4376968872
            ],
            [
              357810.44051765534,
              5006027.7566205263
            ],
            [
              357808.21943917911,
              5006043.3599807275
            ],
            [
              357804.92222566134,
              5006049.9997975985
            ],
            [
              357808.78488001187,
              5006057.8596110148
            ],
            [
              357804.91361437168,
              5006060.3411818016
            ],
            [
              357806.32570152305,
              5006071.4594032858
            ],
            [
              357811.5467710314,
              5006076.038105743
            ],
            [
              357824.32454510062,
              5006093.3706031945
            ],
            [
              357830.94840132241,
              5006098.4682739666
            ],
            [
              357838.61410156207,
              5006110.9762002509
            ],
            [
              357829.52681800496,
              5006119.0175165227
            ],
            [
              357834.62209639139,
              5006122.9387985421
            ],
            [
              357859.03953527991,
              5006155.8177148532
            ],
            [
              357864.58579244785,
              5006165.7019772446
            ],
            [
              357868.07411000913,
              5006164.2315065255
            ],
            [
              357890.43895715114,
              5006221.3892427478
            ],
            [
              357883.33958171488,
              5006219.5162980929
            ],
            [
              357881.28820427047,
              5006215.3274543593
            ],
            [
              357877.26027679106,
              5006214.7683093268
            ],
            [
              357875.27461424744,
              5006217.9050096842
            ],
            [
              357867.89770349953,
              5006222.9736502459
            ],
            [
              357862.4452001389,
              5006225.3998973947
            ],
            [
              357857.70903172396,
              5006228.4468327137
            ],
            [
              357858.57696439937,
              5006230.6366199786
            ],
            [
              357860.24701861507,
              5006229.4783548219
            ],
            [
              357861.2753264354,
              5006231.7136207195
            ],
            [
              357853.89841624181,
              5006236.7822967125
            ],
            [
              357846.90764439438,
              5006239.8711560378
            ],
            [
              357839.33739789738,
              5006242.1253998699
            ],
            [
              357839.65532774909,
              5006245.3907671357
            ],
            [
              357839.85390289617,
              5006248.4628494298
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 288,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359528.73384376225,
              5005594.6207299614
            ],
            [
              359521.08279271529,
              5005590.8096597185
            ],
            [
              359526.38613381248,
              5005582.7339536892
            ],
            [
              359490.94110454497,
              5005559.0845940858
            ],
            [
              359499.78173959948,
              5005549.4895626958
            ],
            [
              359512.38905948406,
              5005539.4699369129
            ],
            [
              359519.63371837506,
              5005532.9409632301
            ],
            [
              359526.45659995498,
              5005524.5790299838
            ],
            [
              359532.29801936093,
              5005515.5571769755
            ],
            [
              359537.2891776957,
              5005501.8698866079
            ],
            [
              359545.11693080905,
              5005495.4269241728
            ],
            [
              359562.35455670167,
              5005514.4018668262
            ],
            [
              359569.6961052905,
              5005507.8710582368
            ],
            [
              359568.30902109615,
              5005506.2498533735
            ],
            [
              359574.66910762229,
              5005499.0591250081
            ],
            [
              359563.82742486917,
              5005484.3482014025
            ],
            [
              359562.83043737343,
              5005481.9200573377
            ],
            [
              359567.68486481108,
              5005479.7229733886
            ],
            [
              359570.30293068948,
              5005475.9441983737
            ],
            [
              359588.1957108393,
              5005463.3031803891
            ],
            [
              359592.05146251625,
              5005459.1287336648
            ],
            [
              359611.39491024794,
              5005467.5069756247
            ],
            [
              359632.76341215969,
              5005483.1269517886
            ],
            [
              359638.50745378621,
              5005491.297541745
            ],
            [
              359649.16394740756,
              5005486.0469727265
            ],
            [
              359653.94768133463,
              5005483.0555705745
            ],
            [
              359649.17515168554,
              5005475.0819332041
            ],
            [
              359655.11747442099,
              5005470.7790038679
            ],
            [
              359642.2340526249,
              5005442.2907063058
            ],
            [
              359652.8845531377,
              5005436.71776246
            ],
            [
              359656.37824995507,
              5005433.7503458941
            ],
            [
              359647.07778422307,
              5005427.0007808888
            ],
            [
              359634.99481271935,
              5005414.9910881994
            ],
            [
              359633.05422079243,
              5005417.777504079
            ],
            [
              359612.1450195029,
              5005400.5260284161
            ],
            [
              359591.66480950016,
              5005378.7884302679
            ],
            [
              359606.97827350296,
              5005366.6181713194
            ],
            [
              359601.65396927594,
              5005359.2034036936
            ],
            [
              359589.84103910852,
              5005349.6328861052
            ],
            [
              359584.05673555989,
              5005347.4584861165
            ],
            [
              359596.96170474606,
              5005340.7803273583
            ],
            [
              359587.17961123824,
              5005325.5777075598
            ],
            [
              359585.6215667114,
              5005326.9926390778
            ],
            [
              359575.41255990294,
              5005317.1601992752
            ],
            [
              359568.38387676195,
              5005309.3628101824
            ],
            [
              359556.38928088627,
              5005320.5291255629
            ],
            [
              359553.76010569365,
              5005327.1332820095
            ],
            [
              359550.3519802377,
              5005323.3337088777
            ],
            [
              359541.94795731484,
              5005318.5149778109
            ],
            [
              359539.65344854776,
              5005321.0744060045
            ],
            [
              359537.15883278177,
              5005322.1714049457
            ],
            [
              359527.95391888148,
              5005327.4760413561
            ],
            [
              359525.13381205063,
              5005331.918012742
            ],
            [
              359527.85237351531,
              5005334.0250287214
            ],
            [
              359536.85034341185,
              5005345.6127551654
            ],
            [
              359539.24877015443,
              5005352.6876698919
            ],
            [
              359533.08407278283,
              5005356.9380617812
            ],
            [
              359526.99175932951,
              5005361.0367301311
            ],
            [
              359542.17943442269,
              5005381.640186701
            ],
            [
              359531.04422120197,
              5005391.1685494678
            ],
            [
              359520.18749427708,
              5005374.5614405368
            ],
            [
              359509.57815808133,
              5005363.7830597702
            ],
            [
              359502.28839697171,
              5005366.3635263667
            ],
            [
              359495.32985204685,
              5005370.3131082812
            ],
            [
              359489.03084632603,
              5005376.8481858755
            ],
            [
              359486.23809420608,
              5005382.8596734758
            ],
            [
              359481.73594164621,
              5005387.3748458056
            ],
            [
              359477.32697473076,
              5005388.6792680593
            ],
            [
              359469.99997967464,
              5005383.1607727325
            ],
            [
              359473.73246536299,
              5005370.5008238386
            ],
            [
              359478.0245573835,
              5005363.3435483063
            ],
            [
              359482.50021950243,
              5005356.5367582487
            ],
            [
              359489.89593786345,
              5005348.0317581873
            ],
            [
              359472.22354702727,
              5005313.4701281795
            ],
            [
              359446.41796555842,
              5005326.6453133328
            ],
            [
              359456.15297061432,
              5005343.0652899854
            ],
            [
              359457.23556991032,
              5005363.4446782107
            ],
            [
              359449.36067013448,
              5005371.6395244496
            ],
            [
              359453.98364931048,
              5005385.62219173
            ],
            [
              359453.16065296793,
              5005404.1358324308
            ],
            [
              359450.10796939459,
              5005405.4744001329
            ],
            [
              359448.10417146672,
              5005401.1534899259
            ],
            [
              359438.72069399775,
              5005406.8397355191
            ],
            [
              359438.30924866855,
              5005410.5811887858
            ],
            [
              359435.9532490095,
              5005412.8326902045
            ],
            [
              359426.05281701015,
              5005401.844665112
            ],
            [
              359423.56083573733,
              5005407.1485652458
            ],
            [
              359414.67943155969,
              5005419.2412770949
            ],
            [
              359413.01416914759,
              5005419.3157723621
            ],
            [
              359404.89102445677,
              5005408.1096303202
            ],
            [
              359390.85354411538,
              5005416.4725473765
            ],
            [
              359388.58244837349,
              5005411.1439306857
            ],
            [
              359385.13899722975,
              5005409.9199217251
            ],
            [
              359366.07030062954,
              5005381.7777896849
            ],
            [
              359373.51882295107,
              5005378.7073536301
            ],
            [
              359380.57756620896,
              5005370.9157134769
            ],
            [
              359388.76864104782,
              5005366.320390068
            ],
            [
              359397.60390716622,
              5005363.3983784076
            ],
            [
              359394.84293556231,
              5005356.9791954719
            ],
            [
              359407.85537279717,
              5005350.4122025305
            ],
            [
              359415.57737803966,
              5005348.064429773
            ],
            [
              359419.98030297592,
              5005342.5200136648
            ],
            [
              359424.52361008589,
              5005339.3688677736
            ],
            [
              359421.64417344303,
              5005328.8806070192
            ],
            [
              359419.89053471957,
              5005327.3798726937
            ],
            [
              359417.95379997941,
              5005320.2985377563
            ],
            [
              359413.86643742712,
              5005322.0361997401
            ],
            [
              359411.84185600112,
              5005318.1003197674
            ],
            [
              359406.02323111653,
              5005320.0146427322
            ],
            [
              359403.66102479451,
              5005305.5815020315
            ],
            [
              359399.40463394439,
              5005296.70217676
            ],
            [
              359404.73954290285,
              5005294.1210898869
            ],
            [
              359396.0223935906,
              5005287.0052354429
            ],
            [
              359386.25082520425,
              5005275.7811717698
            ],
            [
              359376.1756182012,
              5005265.7576706475
            ],
            [
              359369.24073326535,
              5005273.063238848
            ],
            [
              359364.86366624018,
              5005278.2970322231
            ],
            [
              359360.03522840637,
              5005273.9544714168
            ],
            [
              359354.33701045864,
              5005283.9660415463
            ],
            [
              359349.94601371739,
              5005287.7177549284
            ],
            [
              359346.90726716019,
              5005278.1206825897
            ],
            [
              359331.44719355309,
              5005284.9371199207
            ],
            [
              359328.82059980376,
              5005280.0161421625
            ],
            [
              359328.86900748563,
              5005272.0854230309
            ],
            [
              359327.1624454619,
              5005264.5788949067
            ],
            [
              359324.34255630668,
              5005255.1351117985
            ],
            [
              359312.39737284573,
              5005255.7151114782
            ],
            [
              359308.92354261235,
              5005226.8994319597
            ],
            [
              359289.17567690148,
              5005223.5464011412
            ],
            [
              359280.18814701389,
              5005219.5038540028
            ],
            [
              359266.28997019574,
              5005221.0147890095
            ],
            [
              359271.59887549089,
              5005231.7763977563
            ],
            [
              359263.92305161391,
              5005235.545930041
            ],
            [
              359269.31354853697,
              5005253.9210984278
            ],
            [
              359284.19930855324,
              5005252.835394742
            ],
            [
              359302.78990032332,
              5005252.4897610852
            ],
            [
              359304.32225096459,
              5005256.3459356157
            ],
            [
              359305.37766313064,
              5005290.404822005
            ],
            [
              359296.04400034033,
              5005293.6380426716
            ],
            [
              359285.38499466091,
              5005288.0333490772
            ],
            [
              359279.80167683709,
              5005284.1748117981
            ],
            [
              359270.80845509807,
              5005294.3651071331
            ],
            [
              359265.80506426463,
              5005303.4261670224
            ],
            [
              359258.64558779955,
              5005298.7059857892
            ],
            [
              359260.12875429663,
              5005294.2187447976
            ],
            [
              359256.05543665489,
              5005273.8262575855
            ],
            [
              359254.15063864348,
              5005259.8542231079
            ],
            [
              359231.46847027849,
              5005261.777541494
            ],
            [
              359231.67711434705,
              5005269.8668941259
            ],
            [
              359223.99643967935,
              5005270.3008054299
            ],
            [
              359210.24492069421,
              5005266.1978847673
            ],
            [
              359208.4969361978,
              5005266.7429752266
            ],
            [
              359207.87051295262,
              5005268.5120603163
            ],
            [
              359213.54385346279,
              5005278.1457970673
            ],
            [
              359215.20689145109,
              5005317.3012179276
            ],
            [
              359238.64748655044,
              5005318.270207122
            ],
            [
              359243.25517314364,
              5005332.2616232904
            ],
            [
              359251.13206176664,
              5005340.1927540172
            ],
            [
              359246.66065138153,
              5005357.9529231647
            ],
            [
              359259.5675867729,
              5005364.4670392638
            ],
            [
              359265.66579683538,
              5005377.155393593
            ],
            [
              359270.97031831002,
              5005381.1136932168
            ],
            [
              359280.05543613638,
              5005394.377584043
            ],
            [
              359293.27646082098,
              5005387.5506056109
            ],
            [
              359297.03792202676,
              5005386.128352548
            ],
            [
              359308.26741647098,
              5005383.7558955783
            ],
            [
              359317.52231421834,
              5005379.4414083408
            ],
            [
              359319.49780777754,
              5005409.5333183678
            ],
            [
              359314.45108716254,
              5005409.9867770337
            ],
            [
              359310.81307590479,
              5005410.1555299303
            ],
            [
              359298.91277613287,
              5005411.4448816646
            ],
            [
              359289.4856686854,
              5005415.2313164342
            ],
            [
              359290.93272609083,
              5005420.8791119317
            ],
            [
              359290.10336335801,
              5005429.5423850492
            ],
            [
              359290.96252756641,
              5005439.1408069776
            ],
            [
              359283.57226725685,
              5005440.9711331697
            ],
            [
              359277.94317453477,
              5005450.8392325109
            ],
            [
              359268.64509564155,
              5005444.9509596415
            ],
            [
              359260.33697073982,
              5005438.7359537845
            ],
            [
              359252.61293670075,
              5005431.2968614716
            ],
            [
              359225.47877446748,
              5005458.2984582633
            ],
            [
              359241.05165072117,
              5005463.569588528
            ],
            [
              359253.0184588632,
              5005470.7276038537
            ],
            [
              359259.36983792216,
              5005475.0580363115
            ],
            [
              359248.89707992936,
              5005495.215715155
            ],
            [
              359222.74620802241,
              5005547.3157683862
            ],
            [
              359238.27885389241,
              5005541.974425802
            ],
            [
              359248.02525966155,
              5005539.3772774078
            ],
            [
              359261.12230896944,
              5005532.2674944401
            ],
            [
              359265.85179791664,
              5005529.813272845
            ],
            [
              359274.66854631115,
              5005546.7258820031
            ],
            [
              359269.20814399078,
              5005548.8980035577
            ],
            [
              359273.18629517441,
              5005563.8545393823
            ],
            [
              359277.65498294425,
              5005578.3292628983
            ],
            [
              359280.90013460314,
              5005578.2689264212
            ],
            [
              359282.09703959193,
              5005581.9148971224
            ],
            [
              359286.46567265311,
              5005581.5515035028
            ],
            [
              359291.48100895568,
              5005600.7869658787
            ],
            [
              359298.12282720109,
              5005601.227812239
            ],
            [
              359298.58707051148,
              5005588.239328661
            ],
            [
              359301.20225055661,
              5005584.6635649409
            ],
            [
              359305.95955393015,
              5005574.8401982766
            ],
            [
              359306.80822185893,
              5005567.3468864458
            ],
            [
              359311.75957323256,
              5005567.9602481024
            ],
            [
              359323.11804789276,
              5005568.4506751588
            ],
            [
              359358.79871822434,
              5005566.9407673515
            ],
            [
              359363.87022035592,
              5005566.4232215248
            ],
            [
              359368.66738190548,
              5005566.3340290003
            ],
            [
              359369.46722383995,
              5005571.3982231002
            ],
            [
              359366.86877624755,
              5005591.0574461287
            ],
            [
              359362.23681781162,
              5005600.0319407443
            ],
            [
              359402.14246708591,
              5005598.0202228157
            ],
            [
              359404.5903453065,
              5005593.0366941132
            ],
            [
              359410.77955836017,
              5005584.3154152613
            ],
            [
              359414.51250112406,
              5005572.5359191876
            ],
            [
              359422.74977122381,
              5005565.3083976554
            ],
            [
              359431.18489770783,
              5005578.6957838098
            ],
            [
              359439.07658816129,
              5005593.221946544
            ],
            [
              359444.10660649271,
              5005598.0664392477
            ],
            [
              359445.85481417831,
              5005600.996737103
            ],
            [
              359446.5130534654,
              5005598.4449489536
            ],
            [
              359447.03545950109,
              5005596.1778546367
            ],
            [
              359456.76876085572,
              5005603.4744142331
            ],
            [
              359462.35438988992,
              5005590.8684030995
            ],
            [
              359465.34617739223,
              5005592.3647028068
            ],
            [
              359466.93699709343,
              5005586.832808583
            ],
            [
              359472.34047646465,
              5005588.9897237439
            ],
            [
              359475.03699711344,
              5005589.7860925933
            ],
            [
              359472.63107534993,
              5005597.0261863535
            ],
            [
              359494.31574156677,
              5005609.461801827
            ],
            [
              359500.90280213993,
              5005598.6092142519
            ],
            [
              359499.31927340716,
              5005596.9456470739
            ],
            [
              359505.14031862357,
              5005591.1939823981
            ],
            [
              359523.02196529804,
              5005606.477091671
            ],
            [
              359528.73384376225,
              5005594.6207299614
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 289,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358496.98964926036,
              5006976.1357827382
            ],
            [
              358508.25936378073,
              5006994.9001354203
            ],
            [
              358512.99917817221,
              5007002.3885213006
            ],
            [
              358520.81058372167,
              5007010.8121250141
            ],
            [
              358534.80090073345,
              5007027.6896908302
            ],
            [
              358543.93486860656,
              5007035.8846844425
            ],
            [
              358552.53457899095,
              5007042.0895606661
            ],
            [
              358554.84745082154,
              5007045.718891331
            ],
            [
              358572.00630373129,
              5007062.8435657546
            ],
            [
              358580.32927683223,
              5007070.5796678867
            ],
            [
              358583.12914774282,
              5007067.4672948681
            ],
            [
              358595.5678133957,
              5007077.7430194831
            ],
            [
              358630.7712413441,
              5007054.5755002862
            ],
            [
              358634.30238561385,
              5007052.2605622597
            ],
            [
              358617.87451194267,
              5007048.3787664883
            ],
            [
              358593.76919094007,
              5007037.5573352398
            ],
            [
              358573.07434573042,
              5007023.3588862401
            ],
            [
              358559.27220991917,
              5007014.825202629
            ],
            [
              358532.06286398193,
              5006989.7105143424
            ],
            [
              358505.41924015695,
              5006971.3532559359
            ],
            [
              358502.8913485689,
              5006968.8082491066
            ],
            [
              358496.98964926036,
              5006976.1357827382
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 290,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358595.5678133957,
              5007077.7430194831
            ],
            [
              358610.41863321839,
              5007090.1242525522
            ],
            [
              358613.51236286265,
              5007086.913273029
            ],
            [
              358627.5339862972,
              5007102.6234785216
            ],
            [
              358675.61507821362,
              5007106.5276653394
            ],
            [
              358666.86364680057,
              5007077.5213797223
            ],
            [
              358656.26571408549,
              5007064.7123777019
            ],
            [
              358645.89269262261,
              5007057.2026221072
            ],
            [
              358634.2161426766,
              5007052.3171011731
            ],
            [
              358595.5678133957,
              5007077.7430194831
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 291,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358086.37773084949,
              5005644.6150081968
            ],
            [
              358088.74916443211,
              5005650.9534899844
            ],
            [
              358101.97252576909,
              5005674.8518037256
            ],
            [
              358115.43218525493,
              5005694.6957388772
            ],
            [
              358131.30041313294,
              5005717.8551018536
            ],
            [
              358165.49996610387,
              5005770.4218401331
            ],
            [
              358191.19174422574,
              5005809.4185350053
            ],
            [
              358230.81453079829,
              5005819.8971651457
            ],
            [
              358244.5701705651,
              5005822.1336850878
            ],
            [
              358252.67117411172,
              5005821.9830588615
            ],
            [
              358262.43079047371,
              5005818.8419953277
            ],
            [
              358283.67705813842,
              5005796.4835212817
            ],
            [
              358290.5760404077,
              5005787.4206895493
            ],
            [
              358302.82080088981,
              5005758.6873212289
            ],
            [
              358304.06656182493,
              5005750.2526431885
            ],
            [
              358303.1971485074,
              5005745.5198331624
            ],
            [
              358298.64693771879,
              5005735.4794664234
            ],
            [
              358281.01518137072,
              5005709.1708145579
            ],
            [
              358273.59127535968,
              5005695.4454146409
            ],
            [
              358268.75972020061,
              5005679.4294840228
            ],
            [
              358265.3862621155,
              5005665.6287727226
            ],
            [
              358265.05915263406,
              5005648.5970859528
            ],
            [
              358262.60014781804,
              5005633.6889881399
            ],
            [
              358261.55430259794,
              5005610.9661705559
            ],
            [
              358263.25772710209,
              5005610.7296851361
            ],
            [
              358262.66421357781,
              5005578.8081107857
            ],
            [
              358261.06576321641,
              5005576.9397419598
            ],
            [
              358259.42930862936,
              5005576.4589239759
            ],
            [
              358250.08962354704,
              5005576.8184788143
            ],
            [
              358245.16574203025,
              5005577.5443538046
            ],
            [
              358219.59860921622,
              5005585.0352812493
            ],
            [
              358098.04812801554,
              5005628.1637164596
            ],
            [
              358094.36259310908,
              5005629.44561808
            ],
            [
              358092.24964788853,
              5005635.4153876686
            ],
            [
              358089.6376682815,
              5005640.1168304132
            ],
            [
              358086.12600238057,
              5005644.962352111
            ],
            [
              358086.37773084949,
              5005644.6150081968
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 292,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357949.43412671227,
              5006300.1564953122
            ],
            [
              357947.15015482891,
              5006296.1501682028
            ],
            [
              357953.94415780582,
              5006291.8523303187
            ],
            [
              357964.54190574004,
              5006286.9468856389
            ],
            [
              357959.13965817832,
              5006273.6740207421
            ],
            [
              357952.10529373569,
              5006251.8431527345
            ],
            [
              357953.95798862126,
              5006245.6175338933
            ],
            [
              357945.05250058777,
              5006241.9797104048
            ],
            [
              357945.56568429089,
              5006236.5717713526
            ],
            [
              357946.0006698955,
              5006231.9697983777
            ],
            [
              357940.25268691097,
              5006226.3642651672
            ],
            [
              357937.78519097139,
              5006223.6300378982
            ],
            [
              357934.56028248725,
              5006220.1160013536
            ],
            [
              357930.48788843135,
              5006218.6094540125
            ],
            [
              357917.28279727319,
              5006196.6707224157
            ],
            [
              357912.59343574871,
              5006190.2462250544
            ],
            [
              357907.23167320999,
              5006209.5444715293
            ],
            [
              357906.42366575875,
              5006222.2277475446
            ],
            [
              357905.81878429587,
              5006224.7820230825
            ],
            [
              357907.94399998261,
              5006233.6914129127
            ],
            [
              357913.74795517972,
              5006243.9113580193
            ],
            [
              357913.44443961763,
              5006255.6540482892
            ],
            [
              357912.18104957585,
              5006260.8787819799
            ],
            [
              357907.31651654985,
              5006273.2955471259
            ],
            [
              357900.41970218293,
              5006291.8376960419
            ],
            [
              357892.24636317318,
              5006316.9375281371
            ],
            [
              357895.13773896225,
              5006317.5480620051
            ],
            [
              357897.0162714257,
              5006315.5055287592
            ],
            [
              357899.09221116459,
              5006315.498618505
            ],
            [
              357909.26586760231,
              5006325.8076994987
            ],
            [
              357914.71564272331,
              5006335.2895952091
            ],
            [
              357914.12412424717,
              5006348.1188463829
            ],
            [
              357929.80589571496,
              5006343.3055675877
            ],
            [
              357944.53484672488,
              5006339.6955880029
            ],
            [
              357952.03285204241,
              5006338.8129024887
            ],
            [
              357949.43412671227,
              5006300.1564953122
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 293,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359568.35793599969,
              5005710.8988524899
            ],
            [
              359583.42081163061,
              5005716.3958189255
            ],
            [
              359587.67929096107,
              5005723.709229405
            ],
            [
              359592.29885502328,
              5005740.9305277728
            ],
            [
              359594.86125989695,
              5005743.5336081898
            ],
            [
              359598.37488555833,
              5005752.6590170711
            ],
            [
              359612.93326346035,
              5005752.0302409055
            ],
            [
              359615.69826784881,
              5005740.1621937295
            ],
            [
              359612.52419203479,
              5005717.0259078052
            ],
            [
              359611.53721321368,
              5005702.4823220447
            ],
            [
              359608.40131618438,
              5005687.9787148191
            ],
            [
              359602.85813369759,
              5005678.8910648227
            ],
            [
              359597.04996848153,
              5005674.8214523597
            ],
            [
              359592.73877888761,
              5005674.1854351554
            ],
            [
              359569.38785445463,
              5005709.3533867681
            ],
            [
              359568.35793599969,
              5005710.8988524899
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 294,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359854.44625754614,
              5003209.1907599978
            ],
            [
              359863.41530633706,
              5003196.898282242
            ],
            [
              359865.70628971694,
              5003196.2091704635
            ],
            [
              359870.46399596176,
              5003190.5661312444
            ],
            [
              359878.76648794499,
              5003178.2587516289
            ],
            [
              359895.20127704885,
              5003159.186657018
            ],
            [
              359915.77175621263,
              5003134.0286206501
            ],
            [
              359911.62729085982,
              5003130.4772042362
            ],
            [
              359932.98819396336,
              5003099.6208910951
            ],
            [
              359937.83618632919,
              5003095.6726903263
            ],
            [
              359950.04405458667,
              5003093.1309171254
            ],
            [
              359961.95687270991,
              5003085.0968112815
            ],
            [
              359971.69010969676,
              5003078.3168625059
            ],
            [
              359983.33147530729,
              5003083.8055387279
            ],
            [
              359991.18258984969,
              5003085.6439681612
            ],
            [
              360014.15879466955,
              5003080.1320347171
            ],
            [
              360026.30285090482,
              5003079.2862025434
            ],
            [
              360041.437543687,
              5003079.1289581321
            ],
            [
              360060.48095350096,
              5003080.7389412066
            ],
            [
              360076.99586581049,
              5003083.8564373637
            ],
            [
              360083.27600581356,
              5003074.5771315088
            ],
            [
              360096.08621778287,
              5003058.480072232
            ],
            [
              360099.73557389417,
              5003052.9774377998
            ],
            [
              360103.24431299634,
              5003047.7479810147
            ],
            [
              360105.24776757299,
              5003044.8168864101
            ],
            [
              360107.5067677332,
              5003041.9769736296
            ],
            [
              360110.92680523585,
              5003037.7943090936
            ],
            [
              360115.80895427748,
              5003031.0100519611
            ],
            [
              360092.1656635445,
              5003016.9970935266
            ],
            [
              360086.6324090577,
              5003014.6924245115
            ],
            [
              360073.84685251646,
              5003007.0011711763
            ],
            [
              360061.59801140666,
              5002994.8399285646
            ],
            [
              360060.11301621975,
              5002988.3014007881
            ],
            [
              360049.25701754773,
              5002977.7248089714
            ],
            [
              360043.02251002187,
              5002970.1310983412
            ],
            [
              360033.60768979357,
              5002963.7399474252
            ],
            [
              360019.86745457881,
              5002972.0229416527
            ],
            [
              360013.35958983161,
              5002961.9849791685
            ],
            [
              359999.57211870531,
              5002967.0728908749
            ],
            [
              359995.08543799748,
              5002952.4134821212
            ],
            [
              359986.44039744901,
              5002921.9761251034
            ],
            [
              359956.58429878723,
              5002915.5585097205
            ],
            [
              359953.77379534539,
              5002916.7222994398
            ],
            [
              359953.71239759913,
              5002924.3022436313
            ],
            [
              359910.24899102596,
              5002934.8248384912
            ],
            [
              359904.83970452467,
              5002937.6537302239
            ],
            [
              359901.5700742199,
              5002930.438811888
            ],
            [
              359896.85729597986,
              5002932.6484844554
            ],
            [
              359898.43965031265,
              5002936.1558729224
            ],
            [
              359895.11050012411,
              5002936.6219285484
            ],
            [
              359894.93340555124,
              5002946.5636069272
            ],
            [
              359893.3099609153,
              5002946.2906238576
            ],
            [
              359892.39998698427,
              5002946.3075358532
            ],
            [
              359891.18476660579,
              5002946.2290573725
            ],
            [
              359890.37589352246,
              5002946.2440903932
            ],
            [
              359889.3685733311,
              5002946.4649030818
            ],
            [
              359887.86124925507,
              5002946.9981989926
            ],
            [
              359886.66292556463,
              5002947.8288715687
            ],
            [
              359885.56952358439,
              5002948.8596856697
            ],
            [
              359884.58091147192,
              5002950.0906792916
            ],
            [
              359883.99676903622,
              5002951.31415579
            ],
            [
              359883.20666962542,
              5002952.3393329829
            ],
            [
              359882.60938574286,
              5002952.8557156799
            ],
            [
              359881.60394250625,
              5002953.1775214905
            ],
            [
              359880.69577992591,
              5002953.2954633674
            ],
            [
              359879.682827471,
              5002953.2132257279
            ],
            [
              359878.66605481465,
              5002952.9290031251
            ],
            [
              359878.05193979637,
              5002952.5361979883
            ],
            [
              359877.43963615986,
              5002952.2444227152
            ],
            [
              359876.72998626932,
              5002952.1565481424
            ],
            [
              359876.02214775869,
              5002952.1697034342
            ],
            [
              359875.11968365399,
              5002952.5906944405
            ],
            [
              359873.33721749258,
              5002954.6448436091
            ],
            [
              359871.75138706068,
              5002956.392183248
            ],
            [
              359870.25539300259,
              5002957.5315431943
            ],
            [
              359868.74994583469,
              5002958.1658322811
            ],
            [
              359865.63432785467,
              5002959.2342297146
            ],
            [
              359863.5203985236,
              5002959.7787995851
            ],
            [
              359860.09390243847,
              5002960.4487917433
            ],
            [
              359851.30283198104,
              5002960.9153304631
            ],
            [
              359850.59505966998,
              5002960.9284845451
            ],
            [
              359850.09518692869,
              5002961.2408942813
            ],
            [
              359849.49977997824,
              5002961.8582701534
            ],
            [
              359849.11408464453,
              5002962.8759670928
            ],
            [
              359844.90527788602,
              5002970.4319661055
            ],
            [
              359836.48525214213,
              5002989.0978344399
            ],
            [
              359835.32983050431,
              5002992.3678377764
            ],
            [
              359834.0735272328,
              5002995.523747704
            ],
            [
              359833.3995523394,
              5002997.3551939763
            ],
            [
              359832.51962223474,
              5002998.9883775972
            ],
            [
              359831.54040255601,
              5003000.72443398
            ],
            [
              359830.55373990472,
              5003002.0564478915
            ],
            [
              359828.56720554939,
              5003004.0133159515
            ],
            [
              359825.3767225531,
              5003006.4978391416
            ],
            [
              359821.68630401674,
              5003009.2948072245
            ],
            [
              359818.49582099507,
              5003011.7793305144
            ],
            [
              359816.9061786168,
              5003013.3246415034
            ],
            [
              359816.01873863972,
              5003014.5537484428
            ],
            [
              359815.23421036411,
              5003015.8820057213
            ],
            [
              359814.45162532554,
              5003017.3112543188
            ],
            [
              359814.07344152895,
              5003018.7329869885
            ],
            [
              359813.93507351336,
              5003022.1713089189
            ],
            [
              359814.6326258168,
              5003027.8952595713
            ],
            [
              359814.78817124909,
              5003030.8228396503
            ],
            [
              359814.50357789197,
              5003031.8386165425
            ],
            [
              359813.61238334194,
              5003032.8657029048
            ],
            [
              359810.04371669044,
              5003036.7719591735
            ],
            [
              359808.40532773163,
              5003035.6908586854
            ],
            [
              359808.12261203118,
              5003036.8076636838
            ],
            [
              359807.74824985693,
              5003038.4314513374
            ],
            [
              359807.25957890664,
              5003039.349993689
            ],
            [
              359805.58573315135,
              5003041.8063300177
            ],
            [
              359801.34684647451,
              5003047.746053651
            ],
            [
              359799.56257975131,
              5003049.6991984565
            ],
            [
              359796.48446172685,
              5003052.7879407434
            ],
            [
              359793.9942372932,
              5003054.855233591
            ],
            [
              359792.69481993822,
              5003055.6877811104
            ],
            [
              359790.47973559337,
              5003056.2342288671
            ],
            [
              359787.0513805208,
              5003056.8031902816
            ],
            [
              359781.29745308345,
              5003057.4153735572
            ],
            [
              359775.3902349319,
              5003057.6960767964
            ],
            [
              359772.25954903319,
              5003057.9563880442
            ],
            [
              359770.24109849305,
              5003058.1959923329
            ],
            [
              359768.52970355662,
              5003058.6319802916
            ],
            [
              359766.82581923297,
              5003059.4720806461
            ],
            [
              359764.03604830371,
              5003061.747031373
            ],
            [
              359761.35676531302,
              5003064.5252081016
            ],
            [
              359759.37961854186,
              5003066.9871818796
            ],
            [
              359758.59509034065,
              5003068.3154394953
            ],
            [
              359757.92487034522,
              5003070.3489069771
            ],
            [
              359757.66662789107,
              5003072.7789340969
            ],
            [
              359757.64062469901,
              5003076.8214396061
            ],
            [
              359758.20244953927,
              5003079.8425322352
            ],
            [
              359759.28492784867,
              5003083.6623455612
            ],
            [
              359760.67258483294,
              5003087.5775499446
            ],
            [
              359762.97396635462,
              5003091.6778982021
            ],
            [
              359764.96673119726,
              5003095.0378650185
            ],
            [
              359767.04901445535,
              5003098.2327893889
            ],
            [
              359769.00954571302,
              5003100.31842581
            ],
            [
              359771.49066485051,
              5003103.2028196221
            ],
            [
              359774.2882259941,
              5003106.7886308804
            ],
            [
              359777.69252223446,
              5003110.363201079
            ],
            [
              359781.08736519358,
              5003113.4327030489
            ],
            [
              359782.83630813,
              5003115.0169923967
            ],
            [
              359784.38486251124,
              5003116.7061046166
            ],
            [
              359785.83982594364,
              5003118.8011371298
            ],
            [
              359787.2049537829,
              5003121.5041461186
            ],
            [
              359788.46710364852,
              5003124.1080060098
            ],
            [
              359789.34854305879,
              5003126.6996390708
            ],
            [
              359789.58648180123,
              5003128.61520031
            ],
            [
              359789.53419510426,
              5003131.2434898568
            ],
            [
              359789.16352179722,
              5003133.0692995777
            ],
            [
              359788.00838687975,
              5003136.2233657259
            ],
            [
              359784.92304194102,
              5003144.364788386
            ],
            [
              359782.39555088372,
              5003149.8684897022
            ],
            [
              359778.77647642005,
              5003156.5040652575
            ],
            [
              359777.6044445071,
              5003158.7489853436
            ],
            [
              359777.02969301102,
              5003160.477525115
            ],
            [
              359776.7658188123,
              5003162.6045034202
            ],
            [
              359776.79585847718,
              5003164.2207752438
            ],
            [
              359777.1310761327,
              5003165.9324025391
            ],
            [
              359778.1741285334,
              5003167.6308741672
            ],
            [
              359737.88678690541,
              5003201.8174589621
            ],
            [
              359740.87722975278,
              5003204.8944765376
            ],
            [
              359803.4200240486,
              5003155.9095419869
            ],
            [
              359821.45876405161,
              5003171.5362570556
            ],
            [
              359822.08045231987,
              5003172.3331352174
            ],
            [
              359822.60291731165,
              5003173.2328849519
            ],
            [
              359823.3294599423,
              5003174.2298691655
            ],
            [
              359825.28435893072,
              5003176.0124561088
            ],
            [
              359826.30863698485,
              5003176.7007888183
            ],
            [
              359827.43025996321,
              5003177.1851863321
            ],
            [
              359828.86081625795,
              5003177.9669954507
            ],
            [
              359829.67907272716,
              5003178.4570668191
            ],
            [
              359851.56677610817,
              5003196.9468018757
            ],
            [
              359843.25456534821,
              5003205.6598643418
            ],
            [
              359842.66479459481,
              5003206.5802858705
            ],
            [
              359842.47949129494,
              5003207.4931900157
            ],
            [
              359842.50014399254,
              5003208.6043922249
            ],
            [
              359842.82228548644,
              5003209.6088924715
            ],
            [
              359843.44397380407,
              5003210.4057705151
            ],
            [
              359844.26222962438,
              5003210.895806252
            ],
            [
              359845.17783100338,
              5003211.1819423316
            ],
            [
              359846.18896649533,
              5003211.1631494565
            ],
            [
              359853.33777651918,
              5003209.4134525135
            ],
            [
              359853.94068981271,
              5003209.2001564316
            ],
            [
              359854.44625754614,
              5003209.1907599978
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 295,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              361177.82671648421,
              5002953.331178288
            ],
            [
              361183.06457131536,
              5002926.0810843846
            ],
            [
              361195.08743831405,
              5002926.4868249232
            ],
            [
              361194.40198210452,
              5002922.1647705529
            ],
            [
              361205.82457238168,
              5002918.1216685576
            ],
            [
              361212.71109798847,
              5002904.1979592042
            ],
            [
              361202.39361515228,
              5002896.4802980144
            ],
            [
              361197.30270746263,
              5002888.6654471066
            ],
            [
              361196.25349611539,
              5002885.4364621406
            ],
            [
              361179.55061461654,
              5002876.2838118449
            ],
            [
              361172.4025394058,
              5002894.6366091371
            ],
            [
              361148.9897422794,
              5002881.7951999418
            ],
            [
              361140.50425766892,
              5002898.0707111079
            ],
            [
              361107.6482383117,
              5002886.5347037176
            ],
            [
              361098.85753857729,
              5002907.7428125059
            ],
            [
              361098.73201666685,
              5002908.5925576901
            ],
            [
              361090.24651671492,
              5002915.8122627679
            ],
            [
              361084.92117418972,
              5002925.7980158543
            ],
            [
              361080.35295284324,
              5002924.5087632425
            ],
            [
              361075.98576403531,
              5002948.0357364397
            ],
            [
              361079.66637551872,
              5002948.3910379801
            ],
            [
              361078.31456036004,
              5002959.7633158546
            ],
            [
              361077.95417413098,
              5002970.7866963567
            ],
            [
              361061.80175432935,
              5002991.2893334441
            ],
            [
              361068.04786088405,
              5003015.6025700355
            ],
            [
              361075.70127552171,
              5003032.0437785434
            ],
            [
              361075.79311912594,
              5003036.9854892157
            ],
            [
              361079.47364696075,
              5003037.3407913605
            ],
            [
              361080.49391191028,
              5003039.0167077007
            ],
            [
              361088.15026805404,
              5003032.8010927811
            ],
            [
              361094.1219330536,
              5003027.1817660229
            ],
            [
              361106.19819914788,
              5003007.1540945955
            ],
            [
              361125.54124475003,
              5002983.4900638713
            ],
            [
              361146.55875097984,
              5002966.2918856777
            ],
            [
              361161.65182532865,
              5002957.2544996673
            ],
            [
              361167.27062646725,
              5002955.4551954092
            ],
            [
              361177.82671648421,
              5002953.331178288
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 296,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360119.77914884704,
              5003032.9713768717
            ],
            [
              360129.20415281301,
              5003039.992837457
            ],
            [
              360137.30524004874,
              5003033.3210267313
            ],
            [
              360153.82905865781,
              5003019.8636191897
            ],
            [
              360196.84395932249,
              5003054.894641757
            ],
            [
              360237.11338410218,
              5003088.6906109303
            ],
            [
              360279.29895522405,
              5003124.4716230202
            ],
            [
              360293.21598606242,
              5003135.0135270506
            ],
            [
              360316.80357063346,
              5003154.0922536692
            ],
            [
              360358.9246214772,
              5003185.2650684221
            ],
            [
              360364.52359052788,
              5003189.0812390884
            ],
            [
              360371.4478181231,
              5003192.9395267051
            ],
            [
              360377.68913665455,
              5003183.6323404815
            ],
            [
              360390.30729499197,
              5003163.9285037117
            ],
            [
              360402.34819143539,
              5003142.0599357523
            ],
            [
              360414.84191561892,
              5003125.2564057522
            ],
            [
              360433.78403165768,
              5003099.612403336
            ],
            [
              360447.49010533455,
              5003087.6071809223
            ],
            [
              360463.15218352125,
              5003073.7607430862
            ],
            [
              360470.25781068421,
              5003067.3300935403
            ],
            [
              360473.52566450689,
              5003070.3183642663
            ],
            [
              360513.6262417924,
              5003033.5710156495
            ],
            [
              360549.27488881518,
              5003011.3560005995
            ],
            [
              360570.25120270724,
              5002999.8183029555
            ],
            [
              360583.96631386026,
              5002990.1709084632
            ],
            [
              360591.68662929122,
              5002985.1925683599
            ],
            [
              360591.76337047579,
              5002982.2948959414
            ],
            [
              360590.31010687904,
              5002980.4041455658
            ],
            [
              360599.77714223211,
              5002973.630168777
            ],
            [
              360606.81266209006,
              5002965.8995202556
            ],
            [
              360616.37428437331,
              5002959.4488706365
            ],
            [
              360619.87926598219,
              5002950.5453198701
            ],
            [
              360619.98128047481,
              5002943.8316090126
            ],
            [
              360618.54083471722,
              5002936.4821702428
            ],
            [
              360615.24337755068,
              5002933.1284052636
            ],
            [
              360612.55090994801,
              5002931.2988832481
            ],
            [
              360605.2792256606,
              5002929.2217101231
            ],
            [
              360597.8085994724,
              5002927.6820225483
            ],
            [
              360591.26379129244,
              5002926.6329856878
            ],
            [
              360586.22765073885,
              5002925.875775015
            ],
            [
              360574.23917834507,
              5002922.0146963215
            ],
            [
              360551.97941494727,
              5002912.4463131838
            ],
            [
              360524.08790455904,
              5002899.8368951036
            ],
            [
              360506.26708114601,
              5002890.5564810149
            ],
            [
              360495.33221914078,
              5002876.883471841
            ],
            [
              360491.11181419942,
              5002874.1367294788
            ],
            [
              360471.37226481299,
              5002862.1446027039
            ],
            [
              360454.043850585,
              5002851.5294998437
            ],
            [
              360432.73350626248,
              5002839.8088727202
            ],
            [
              360417.35856182408,
              5002828.9181009689
            ],
            [
              360400.76966243639,
              5002816.867374531
            ],
            [
              360382.05054046138,
              5002804.0549185714
            ],
            [
              360356.36589500797,
              5002790.9499881435
            ],
            [
              360301.66639020108,
              5002757.17951544
            ],
            [
              360283.00330218428,
              5002747.3180726897
            ],
            [
              360257.63976252783,
              5002731.8955026576
            ],
            [
              360266.27446761495,
              5002722.7059056535
            ],
            [
              360245.19151259307,
              5002702.6213211995
            ],
            [
              360212.83176825364,
              5002713.9828787493
            ],
            [
              360210.43402456958,
              5002705.4231444625
            ],
            [
              360205.29371630913,
              5002706.8089703331
            ],
            [
              360199.75517169497,
              5002708.12448454
            ],
            [
              360197.1413059546,
              5002708.9814981539
            ],
            [
              360194.32711490366,
              5002709.9432270546
            ],
            [
              360191.91920511913,
              5002710.9985044384
            ],
            [
              360188.5171167985,
              5002712.9816868305
            ],
            [
              360186.01749270089,
              5002714.5439148005
            ],
            [
              360184.51398961473,
              5002715.2791940114
            ],
            [
              360183.11346551456,
              5002716.1136584068
            ],
            [
              360181.50698474882,
              5002716.7498235311
            ],
            [
              360179.09907423495,
              5002717.8050654652
            ],
            [
              360177.49259345268,
              5002718.4412306026
            ],
            [
              360175.49478428427,
              5002719.79200581
            ],
            [
              360174.10733486916,
              5002721.3335646912
            ],
            [
              360173.62060466403,
              5002722.3531380789
            ],
            [
              360173.53827688895,
              5002723.3651603032
            ],
            [
              360144.78253208619,
              5002735.2616196172
            ],
            [
              360146.0521937352,
              5002738.2695359709
            ],
            [
              360168.93458594347,
              5002728.6486417614
            ],
            [
              360170.24902850017,
              5002728.6242134972
            ],
            [
              360171.67208142602,
              5002729.0019849744
            ],
            [
              360173.30866555852,
              5002729.9820620744
            ],
            [
              360174.85159292683,
              5002731.3681334872
            ],
            [
              360176.29717333562,
              5002732.9580698144
            ],
            [
              360178.16036473255,
              5002735.2476180065
            ],
            [
              360179.92989806348,
              5002737.9430894163
            ],
            [
              360181.81374009169,
              5002741.3438093914
            ],
            [
              360183.06650616758,
              5002743.442610654
            ],
            [
              360184.62820660876,
              5002745.8388252528
            ],
            [
              360186.9108197075,
              5002748.928979394
            ],
            [
              360191.21002944378,
              5002753.751208039
            ],
            [
              360195.23783707799,
              5002758.2236386789
            ],
            [
              360199.05405663775,
              5002762.1947554294
            ],
            [
              360201.52016378712,
              5002764.270971491
            ],
            [
              360204.59475374542,
              5002766.4369780831
            ],
            [
              360207.99148469692,
              5002769.6074543279
            ],
            [
              360211.78142145067,
              5002772.1643489925
            ],
            [
              360215.67051577003,
              5002774.6183729153
            ],
            [
              360218.63468392327,
              5002776.2811498893
            ],
            [
              360220.78240383422,
              5002777.5548820803
            ],
            [
              360222.72792222042,
              5002778.8323720973
            ],
            [
              360224.57602875313,
              5002780.3137993217
            ],
            [
              360225.71079790627,
              5002781.5053289328
            ],
            [
              360227.77994537033,
              5002783.9931402048
            ],
            [
              360229.68542536936,
              5002786.5432874905
            ],
            [
              360231.66285934584,
              5002789.5380847361
            ],
            [
              360232.09548840421,
              5002791.0457825214
            ],
            [
              360232.172460109,
              5002795.1874546949
            ],
            [
              360240.01378161862,
              5002787.1597746266
            ],
            [
              360242.28325295699,
              5002789.542799389
            ],
            [
              360233.73979278206,
              5002797.8867187658
            ],
            [
              360237.16628546832,
              5002797.2167289713
            ],
            [
              360237.7729562383,
              5002797.2054541735
            ],
            [
              360238.79160528927,
              5002797.5907054683
            ],
            [
              360240.43563272612,
              5002798.97486185
            ],
            [
              360244.25560742593,
              5002803.148034676
            ],
            [
              360246.54385274352,
              5002806.5412379354
            ],
            [
              360249.04375245451,
              5002810.4357536025
            ],
            [
              360250.72344947775,
              5002813.7392030712
            ],
            [
              360251.9126701756,
              5002816.6231992831
            ],
            [
              360252.44827832648,
              5002818.2300820658
            ],
            [
              360252.98381957127,
              5002819.8369305395
            ],
            [
              360253.11314715183,
              5002821.3503005328
            ],
            [
              360253.1544489066,
              5002823.5726437364
            ],
            [
              360252.79128143174,
              5002825.8025039174
            ],
            [
              360251.86274568719,
              5002830.2660177005
            ],
            [
              360250.05434084183,
              5002836.3626851104
            ],
            [
              360246.85863845731,
              5002840.6604837803
            ],
            [
              360245.87760297587,
              5002842.2955531236
            ],
            [
              360244.09513806709,
              5002844.3496995019
            ],
            [
              360241.7228981604,
              5002847.3242353704
            ],
            [
              360238.95376565744,
              5002850.710401156
            ],
            [
              360236.57001147472,
              5002852.9477045024
            ],
            [
              360232.49394577369,
              5002856.7623421745
            ],
            [
              360227.0117243487,
              5002861.1083947625
            ],
            [
              360220.82923978049,
              5002865.8716088748
            ],
            [
              360215.53983242996,
              5002869.7087967284
            ],
            [
              360210.73715540598,
              5002872.5264467457
            ],
            [
              360207.02613375994,
              5002874.2122174073
            ],
            [
              360203.41426964465,
              5002875.7951173829
            ],
            [
              360199.89418512984,
              5002876.8710300578
            ],
            [
              360195.9250458887,
              5002878.1979046389
            ],
            [
              360192.19893896225,
              5002879.075554952
            ],
            [
              360187.8605952271,
              5002879.6614289135
            ],
            [
              360183.72633058549,
              5002880.3445735257
            ],
            [
              360179.38604354468,
              5002880.8294557426
            ],
            [
              360174.04031399952,
              5002881.6361432904
            ],
            [
              360170.70741250057,
              5002881.9002119852
            ],
            [
              360168.79005363386,
              5002882.1379373427
            ],
            [
              360166.67612538108,
              5002882.6824707184
            ],
            [
              360164.47236068972,
              5002883.8349832688
            ],
            [
              360162.27603952133,
              5002885.3915757248
            ],
            [
              360159.19609464216,
              5002888.3793008048
            ],
            [
              360152.6710938919,
              5002896.4835847076
            ],
            [
              360151.01198045636,
              5002894.2913078675
            ],
            [
              360133.98150902049,
              5002895.6227903897
            ],
            [
              360132.96883524308,
              5002900.9972984996
            ],
            [
              360118.45579979097,
              5002903.7933254261
            ],
            [
              360108.77464619436,
              5002889.0177062834
            ],
            [
              360098.88830289844,
              5002890.4140275232
            ],
            [
              360099.15058087051,
              5002893.6427928656
            ],
            [
              360104.50382068771,
              5002893.2401481401
            ],
            [
              360108.62925532047,
              5002902.9654239621
            ],
            [
              360089.84916923754,
              5002907.5874140309
            ],
            [
              360089.14515199309,
              5002907.8025895935
            ],
            [
              360088.23705619638,
              5002907.9205300473
            ],
            [
              360086.8195686746,
              5002907.8458106266
            ],
            [
              360085.09884566761,
              5002907.7767623942
            ],
            [
              360083.47727891809,
              5002907.6047723237
            ],
            [
              360082.06173524674,
              5002907.6310802428
            ],
            [
              360080.44023539609,
              5002907.4591244729
            ],
            [
              360078.51348927018,
              5002907.1917782435
            ],
            [
              360077.09794557869,
              5002907.2180861635
            ],
            [
              360075.77974810346,
              5002907.040493506
            ],
            [
              360074.35481696826,
              5002906.5616940856
            ],
            [
              360072.41868403746,
              5002905.7892760457
            ],
            [
              360070.27847187751,
              5002904.9195873393
            ],
            [
              360068.34233892808,
              5002904.1471692836
            ],
            [
              360066.40808358643,
              5002903.4757797932
            ],
            [
              360064.26974901179,
              5002902.7071196381
            ],
            [
              360062.33924756397,
              5002902.2377162129
            ],
            [
              360060.0062211664,
              5002901.8768927371
            ],
            [
              360056.45978567429,
              5002901.538584928
            ],
            [
              360051.29379402933,
              5002901.1293486515
            ],
            [
              360047.18333427433,
              5002900.9074568003
            ],
            [
              360043.9421449473,
              5002900.6645394424
            ],
            [
              360041.31513650803,
              5002900.8144258931
            ],
            [
              360038.59641400498,
              5002901.4712631628
            ],
            [
              360036.07989310351,
              5002902.1243425207
            ],
            [
              360034.27677631425,
              5002903.0673181592
            ],
            [
              360033.5840233395,
              5002903.8885941925
            ],
            [
              360033.30512663827,
              5002905.2074250178
            ],
            [
              360033.32202322403,
              5002906.11657569
            ],
            [
              360033.84073554631,
              5002906.8143086853
            ],
            [
              360034.96605208149,
              5002907.5007323734
            ],
            [
              360037.30853234214,
              5002908.3666619984
            ],
            [
              360038.94317441271,
              5002909.2457468025
            ],
            [
              360039.76143392501,
              5002909.7357857255
            ],
            [
              360040.28014624625,
              5002910.433518704
            ],
            [
              360040.4037751953,
              5002911.6438050717
            ],
            [
              360040.52740480792,
              5002912.8541269787
            ],
            [
              360039.95459316083,
              5002914.6837020591
            ],
            [
              360038.98663256405,
              5002917.0258673187
            ],
            [
              360037.51316792675,
              5002919.3774274131
            ],
            [
              360035.62209176057,
              5002921.0293755969
            ],
            [
              360033.52130479546,
              5002922.2810384547
            ],
            [
              360031.70880059031,
              5002922.7189068059
            ],
            [
              360029.48438395013,
              5002922.7602477511
            ],
            [
              360027.25426949415,
              5002922.4985752357
            ],
            [
              360025.32195710094,
              5002921.9281775905
            ],
            [
              360023.78647187422,
              5002920.9461864876
            ],
            [
              360022.85772469791,
              5002919.9529546546
            ],
            [
              360022.03007767868,
              5002918.9578083204
            ],
            [
              360021.70230319147,
              5002917.6502524605
            ],
            [
              360021.54675795027,
              5002914.7226576786
            ],
            [
              360021.52422913891,
              5002913.5104567427
            ],
            [
              360021.50545557303,
              5002912.5003129914
            ],
            [
              360021.28260275867,
              5002911.3928985698
            ],
            [
              360020.65527977055,
              5002910.2929656925
            ],
            [
              360019.32394072093,
              5002909.4082794962
            ],
            [
              360018.30904481391,
              5002909.2250143979
            ],
            [
              360015.98728250572,
              5002909.4702914022
            ],
            [
              360014.17853277159,
              5002910.1101813586
            ],
            [
              360009.06698486704,
              5002912.630419014
            ],
            [
              360002.65031426301,
              5002915.680158875
            ],
            [
              359999.43540790817,
              5002916.8514642967
            ],
            [
              359986.44039744901,
              5002921.9761251034
            ],
            [
              359995.08543799748,
              5002952.4134821212
            ],
            [
              359999.57211870531,
              5002967.0728908749
            ],
            [
              360013.35958983161,
              5002961.9849791685
            ],
            [
              360019.86745457881,
              5002972.0229416527
            ],
            [
              360033.60768979357,
              5002963.7399474252
            ],
            [
              360043.02251002187,
              5002970.1310983412
            ],
            [
              360049.25701754773,
              5002977.7248089714
            ],
            [
              360060.11301621975,
              5002988.3014007881
            ],
            [
              360061.59801140666,
              5002994.8399285646
            ],
            [
              360073.84685251646,
              5003007.0011711763
            ],
            [
              360086.6324090577,
              5003014.6924245115
            ],
            [
              360092.1656635445,
              5003016.9970935266
            ],
            [
              360115.8672861894,
              5003031.208949999
            ],
            [
              360119.77914884704,
              5003032.9713768717
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 297,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360734.53182384721,
              5003414.3482513605
            ],
            [
              360721.547719069,
              5003402.9794264166
            ],
            [
              360707.67657978513,
              5003392.6259230822
            ],
            [
              360706.19111468078,
              5003389.5925631411
            ],
            [
              360688.5288771147,
              5003372.9835737264
            ],
            [
              360676.63419845706,
              5003359.1242409945
            ],
            [
              360656.95384741708,
              5003343.7771248044
            ],
            [
              360638.12968344882,
              5003363.5130349752
            ],
            [
              360646.07844620553,
              5003373.7725734403
            ],
            [
              360634.68390377663,
              5003388.5422114609
            ],
            [
              360619.51882686786,
              5003418.4133149441
            ],
            [
              360620.19934368,
              5003422.0738028139
            ],
            [
              360611.21251393913,
              5003432.8521584421
            ],
            [
              360603.11086631397,
              5003448.572709037
            ],
            [
              360593.73546894692,
              5003471.398056251
            ],
            [
              360593.1200435338,
              5003482.2248610146
            ],
            [
              360590.06627901894,
              5003482.6897777524
            ],
            [
              360586.17135747959,
              5003493.8451355295
            ],
            [
              360576.94565958634,
              5003491.7718853233
            ],
            [
              360576.17168093513,
              5003505.0504420931
            ],
            [
              360579.24124945031,
              5003516.4209563714
            ],
            [
              360585.76919913507,
              5003525.7668693401
            ],
            [
              360568.55177730677,
              5003539.9531084513
            ],
            [
              360551.33435520664,
              5003554.1393489856
            ],
            [
              360556.32035849564,
              5003569.7595811756
            ],
            [
              360558.77406894841,
              5003581.3834601426
            ],
            [
              360552.46381900454,
              5003604.3558857515
            ],
            [
              360549.86440999265,
              5003618.28053944
            ],
            [
              360490.423525461,
              5003566.4616529103
            ],
            [
              360478.74874587997,
              5003564.4339598902
            ],
            [
              360459.34762132139,
              5003542.1434679097
            ],
            [
              360442.777889574,
              5003529.3913499238
            ],
            [
              360428.71784847073,
              5003519.8576342938
            ],
            [
              360417.50367303734,
              5003509.6587860715
            ],
            [
              360413.35428056057,
              5003517.0822218889
            ],
            [
              360394.47772433981,
              5003544.9817219153
            ],
            [
              360408.29195187899,
              5003552.275314495
            ],
            [
              360403.73918722372,
              5003561.6105645541
            ],
            [
              360388.41935257125,
              5003594.137387
            ],
            [
              360368.11217771977,
              5003589.0051283911
            ],
            [
              360353.63323456829,
              5003589.8864501342
            ],
            [
              360344.06150198466,
              5003593.3865368413
            ],
            [
              360324.33327690203,
              5003608.0821383838
            ],
            [
              360310.55512855086,
              5003615.4306809697
            ],
            [
              360296.6520430434,
              5003616.0575308399
            ],
            [
              360288.36964675057,
              5003625.8316579498
            ],
            [
              360286.81356273004,
              5003625.6537420247
            ],
            [
              360274.59116779402,
              5003639.5797802936
            ],
            [
              360259.69130641688,
              5003655.6326415874
            ],
            [
              360266.69748592575,
              5003670.9403660167
            ],
            [
              360278.15712789702,
              5003704.9150967486
            ],
            [
              360281.89714016096,
              5003741.3730403688
            ],
            [
              360282.60420313111,
              5003746.4614850292
            ],
            [
              360289.98449095112,
              5003767.3966251686
            ],
            [
              360306.67562906683,
              5003786.6765148556
            ],
            [
              360313.30193267536,
              5003802.6744233929
            ],
            [
              360337.40710120258,
              5003830.9149711346
            ],
            [
              360354.38853673637,
              5003812.8770311559
            ],
            [
              360397.09294192161,
              5003776.2510943124
            ],
            [
              360393.76345135347,
              5003771.5231916578
            ],
            [
              360402.20346341649,
              5003763.0546353096
            ],
            [
              360437.44207192544,
              5003801.140467721
            ],
            [
              360446.43716773967,
              5003790.3955545919
            ],
            [
              360505.06760177051,
              5003767.608948471
            ],
            [
              360518.71363995568,
              5003756.1159490468
            ],
            [
              360573.25784632651,
              5003720.294027634
            ],
            [
              360593.1769592599,
              5003694.5698468266
            ],
            [
              360608.79784777609,
              5003660.24339577
            ],
            [
              360616.04369227652,
              5003642.073416939
            ],
            [
              360624.17578097427,
              5003629.3759867232
            ],
            [
              360633.6461556803,
              5003617.5943696797
            ],
            [
              360644.24806631129,
              5003604.3611205248
            ],
            [
              360661.25903425104,
              5003582.6179121444
            ],
            [
              360667.6568206065,
              5003574.0934447078
            ],
            [
              360681.28828856634,
              5003561.8165586004
            ],
            [
              360695.58697797172,
              5003544.4282763917
            ],
            [
              360716.37936873059,
              5003509.368608701
            ],
            [
              360725.20285381802,
              5003494.1564749917
            ],
            [
              360727.35370657389,
              5003486.3579664053
            ],
            [
              360733.43628738419,
              5003471.7870063251
            ],
            [
              360736.24782287673,
              5003463.4290933339
            ],
            [
              360742.11545485863,
              5003442.8983004056
            ],
            [
              360723.9062655734,
              5003427.6346546188
            ],
            [
              360734.53182384721,
              5003414.3482513605
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 298,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360783.27074971359,
              5003425.9809626536
            ],
            [
              360791.94964726683,
              5003414.3128731577
            ],
            [
              360800.39685472124,
              5003404.1967591224
            ],
            [
              360791.32941046107,
              5003392.8570213206
            ],
            [
              360796.82217458793,
              5003389.1522329971
            ],
            [
              360813.64747224405,
              5003377.1099037472
            ],
            [
              360829.84160416567,
              5003366.8498041704
            ],
            [
              360833.87098380021,
              5003369.2093553562
            ],
            [
              360846.43319371785,
              5003354.1479106294
            ],
            [
              360848.30180566304,
              5003351.5110408291
            ],
            [
              360826.86644119961,
              5003332.0443384899
            ],
            [
              360854.9865505278,
              5003292.481570405
            ],
            [
              360856.12588906643,
              5003290.3822603822
            ],
            [
              360860.90174666373,
              5003284.2640779484
            ],
            [
              360869.37362803449,
              5003275.4754009619
            ],
            [
              360874.517199578,
              5003266.305977758
            ],
            [
              360874.67683815304,
              5003262.9833197361
            ],
            [
              360893.64901083917,
              5003235.4091606364
            ],
            [
              360907.42899518745,
              5003226.3005261794
            ],
            [
              360918.67620136379,
              5003223.8783475365
            ],
            [
              360941.08526522224,
              5003237.2674345169
            ],
            [
              360956.28666423948,
              5003248.0864757849
            ],
            [
              360960.14807983895,
              5003244.9740872765
            ],
            [
              360951.61388303695,
              5003232.4489447847
            ],
            [
              360962.80386499286,
              5003225.8140437836
            ],
            [
              360934.00229090406,
              5003204.014053721
            ],
            [
              360896.54781804414,
              5003157.5819327859
            ],
            [
              360909.43465438875,
              5003149.7662444869
            ],
            [
              360922.61556578131,
              5003146.5554319005
            ],
            [
              360933.93626825174,
              5003146.556146563
            ],
            [
              360955.68033390492,
              5003144.5374469357
            ],
            [
              360982.07012556714,
              5003141.3884751061
            ],
            [
              360980.92447684583,
              5003135.1529750079
            ],
            [
              360984.94521296438,
              5003134.8600621847
            ],
            [
              360997.29875038232,
              5003127.3214728134
            ],
            [
              361001.33083885553,
              5003123.3428837657
            ],
            [
              361004.20982510684,
              5003115.8762923265
            ],
            [
              361018.66031846235,
              5003109.1622852925
            ],
            [
              360955.84877021291,
              5003099.4143020734
            ],
            [
              360944.71299694519,
              5003099.1791218324
            ],
            [
              360939.3219741034,
              5003099.8124597212
            ],
            [
              360933.38846685848,
              5003101.0939176083
            ],
            [
              360923.61425768671,
              5003104.6616330501
            ],
            [
              360878.85494222428,
              5003119.8334294483
            ],
            [
              360839.70147162152,
              5003136.0820253612
            ],
            [
              360805.16898150247,
              5003148.4534427272
            ],
            [
              360768.53411651269,
              5003166.8393753348
            ],
            [
              360698.62897312111,
              5003194.0322823767
            ],
            [
              360736.36672385078,
              5003210.7005287213
            ],
            [
              360757.23164222261,
              5003217.773325325
            ],
            [
              360771.62758425187,
              5003218.8873204831
            ],
            [
              360789.155757175,
              5003224.9168728665
            ],
            [
              360817.1553647418,
              5003244.0149862505
            ],
            [
              360824.59361354372,
              5003251.9777238695
            ],
            [
              360805.16868170857,
              5003277.7599340407
            ],
            [
              360789.19254277262,
              5003295.741195526
            ],
            [
              360747.23691156186,
              5003358.6923878482
            ],
            [
              360732.09290060226,
              5003365.4266001796
            ],
            [
              360721.81231510156,
              5003377.4993435368
            ],
            [
              360783.27074971359,
              5003425.9809626536
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 299,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352458.84720352414,
              5011804.9762511663
            ],
            [
              352470.44573569362,
              5011789.5959327891
            ],
            [
              352474.11230956903,
              5011784.7338459957
            ],
            [
              352494.01964375487,
              5011758.7602354437
            ],
            [
              352487.65636576439,
              5011754.0162128638
            ],
            [
              352488.65229590156,
              5011748.9323925683
            ],
            [
              352481.08746402845,
              5011733.5550766764
            ],
            [
              352464.51147888601,
              5011721.6834977986
            ],
            [
              352452.75187791715,
              5011731.6784451241
            ],
            [
              352437.02125815238,
              5011744.5991804199
            ],
            [
              352435.60460163018,
              5011743.4718731157
            ],
            [
              352426.40007845202,
              5011754.5815685363
            ],
            [
              352400.1208071764,
              5011738.9696751628
            ],
            [
              352412.99043213885,
              5011707.2276293254
            ],
            [
              352403.92838493007,
              5011702.4740038663
            ],
            [
              352401.00726322987,
              5011706.8148396453
            ],
            [
              352394.97622808395,
              5011703.625077622
            ],
            [
              352401.52109102206,
              5011691.8964336207
            ],
            [
              352396.18575560022,
              5011689.8160272939
            ],
            [
              352388.14768843929,
              5011678.4226868553
            ],
            [
              352391.49789000436,
              5011676.0642615594
            ],
            [
              352399.14732669585,
              5011680.4851540448
            ],
            [
              352408.57806459517,
              5011676.6950267162
            ],
            [
              352413.58478354249,
              5011671.501029375
            ],
            [
              352430.48198165133,
              5011654.0079268226
            ],
            [
              352406.75752891641,
              5011635.6520159338
            ],
            [
              352404.77548845456,
              5011633.7832275322
            ],
            [
              352395.01597274706,
              5011643.622186468
            ],
            [
              352387.59846300981,
              5011632.7754732026
            ],
            [
              352375.64488646813,
              5011645.875853424
            ],
            [
              352366.54458014906,
              5011656.0970084909
            ],
            [
              352362.08945888252,
              5011663.7426780285
            ],
            [
              352363.79858639842,
              5011668.5366123915
            ],
            [
              352341.52173482225,
              5011691.2565850345
            ],
            [
              352334.52258734812,
              5011681.2275026217
            ],
            [
              352330.50000674761,
              5011686.7208246049
            ],
            [
              352311.61800340103,
              5011666.3406854793
            ],
            [
              352303.30215971504,
              5011675.4477171684
            ],
            [
              352300.6730423696,
              5011673.4941525487
            ],
            [
              352293.60927504924,
              5011680.2220207304
            ],
            [
              352291.68883045181,
              5011682.174087448
            ],
            [
              352289.58799273596,
              5011683.7432257496
            ],
            [
              352283.94599893276,
              5011687.2353282282
            ],
            [
              352289.07212373568,
              5011695.258922209
            ],
            [
              352276.36537723342,
              5011704.7359897979
            ],
            [
              352273.37988842843,
              5011700.9521329692
            ],
            [
              352262.78951145575,
              5011708.7533055292
            ],
            [
              352257.19134819886,
              5011713.1396499984
            ],
            [
              352238.71886426822,
              5011677.2726717796
            ],
            [
              352236.88027053408,
              5011673.619996679
            ],
            [
              352223.33763724292,
              5011680.5739292568
            ],
            [
              352211.93592869694,
              5011660.8388449466
            ],
            [
              352204.98951210553,
              5011661.6963670366
            ],
            [
              352190.86029156001,
              5011652.1482104091
            ],
            [
              352193.25545433752,
              5011641.6144578606
            ],
            [
              352184.76686824678,
              5011636.0849103304
            ],
            [
              352176.20176157478,
              5011630.3990295455
            ],
            [
              352162.52098833059,
              5011619.7655208223
            ],
            [
              352154.86677885085,
              5011610.5635265904
            ],
            [
              352151.51125339314,
              5011607.6173448889
            ],
            [
              352146.82572888787,
              5011603.8019037005
            ],
            [
              352138.38566022256,
              5011602.3235465158
            ],
            [
              352135.32558560529,
              5011598.8867614651
            ],
            [
              352126.2074071953,
              5011585.2062907666
            ],
            [
              352116.50709452311,
              5011573.7826158749
            ],
            [
              352099.76888983272,
              5011591.6985826837
            ],
            [
              352094.98817214789,
              5011595.6482223552
            ],
            [
              352108.93169351242,
              5011606.1902511418
            ],
            [
              352114.9123867703,
              5011611.3218379058
            ],
            [
              352105.80434619996,
              5011630.3039255207
            ],
            [
              352118.39091541694,
              5011639.7881870568
            ],
            [
              352112.59635478776,
              5011646.8731988575
            ],
            [
              352125.67725994816,
              5011654.2322030263
            ],
            [
              352143.92402474699,
              5011666.6967030233
            ],
            [
              352142.40799221053,
              5011668.356555704
            ],
            [
              352157.11536137387,
              5011680.5928872647
            ],
            [
              352162.49863641459,
              5011675.3415044425
            ],
            [
              352169.66841757501,
              5011682.5669179009
            ],
            [
              352162.2516971603,
              5011692.8438308025
            ],
            [
              352165.06409421255,
              5011694.5085448464
            ],
            [
              352162.20680781471,
              5011699.2223211536
            ],
            [
              352176.39880102855,
              5011708.327941685
            ],
            [
              352186.30576125009,
              5011698.8632600904
            ],
            [
              352207.35796849331,
              5011715.6104961596
            ],
            [
              352219.89339811658,
              5011724.724062426
            ],
            [
              352215.98693450534,
              5011731.0432704221
            ],
            [
              352221.31911394926,
              5011735.1078920439
            ],
            [
              352220.55548955611,
              5011737.358520681
            ],
            [
              352217.9296351567,
              5011740.5384064103
            ],
            [
              352211.33337993262,
              5011751.4120201403
            ],
            [
              352196.74676702457,
              5011744.7089013681
            ],
            [
              352173.81318736734,
              5011778.1134791691
            ],
            [
              352189.69006593525,
              5011792.4221939761
            ],
            [
              352183.75550841045,
              5011801.6772885621
            ],
            [
              352188.40343989246,
              5011805.6095376033
            ],
            [
              352184.18477448815,
              5011815.4912176374
            ],
            [
              352175.33458512346,
              5011809.8476181924
            ],
            [
              352172.00724899321,
              5011815.0948344339
            ],
            [
              352172.61124408757,
              5011815.6244328162
            ],
            [
              352166.84895461914,
              5011824.8063023565
            ],
            [
              352159.69784816616,
              5011829.7714133998
            ],
            [
              352140.25966119888,
              5011867.3270755652
            ],
            [
              352138.88961710205,
              5011873.5126511604
            ],
            [
              352107.99242317915,
              5011859.4906422338
            ],
            [
              352109.0855336925,
              5011853.3241456999
            ],
            [
              352104.47220542183,
              5011841.4470088352
            ],
            [
              352075.41659638303,
              5011826.0736492472
            ],
            [
              352067.83482093114,
              5011831.7023874223
            ],
            [
              352062.60994534224,
              5011845.6574762342
            ],
            [
              352033.18860163091,
              5011836.6044106409
            ],
            [
              352035.1539980736,
              5011830.2186145838
            ],
            [
              352008.44024887233,
              5011818.9001486888
            ],
            [
              352009.36655957293,
              5011810.2012320701
            ],
            [
              351960.97411703522,
              5011769.5270981723
            ],
            [
              351956.6872197265,
              5011766.9691068092
            ],
            [
              351962.35032060521,
              5011759.1221038429
            ],
            [
              351956.16607295332,
              5011758.7627497502
            ],
            [
              351937.21340087941,
              5011744.2068011444
            ],
            [
              351932.12383366999,
              5011742.4634843385
            ],
            [
              351929.13521378167,
              5011748.5953061804
            ],
            [
              351916.5789818315,
              5011769.2917230986
            ],
            [
              351913.3291688328,
              5011771.3666655989
            ],
            [
              351904.51702563762,
              5011773.2788107498
            ],
            [
              351898.3875161577,
              5011772.9557035863
            ],
            [
              351886.37150193867,
              5011772.7131217569
            ],
            [
              351861.95101156225,
              5011776.6650075316
            ],
            [
              351846.23740319844,
              5011778.414728825
            ],
            [
              351844.16688083287,
              5011777.375614333
            ],
            [
              351851.05579254823,
              5011763.6488842508
            ],
            [
              351834.29674734949,
              5011750.5543169174
            ],
            [
              351823.07036263426,
              5011763.5447452581
            ],
            [
              351815.80128873972,
              5011756.8309266614
            ],
            [
              351810.08394515392,
              5011747.3194683529
            ],
            [
              351807.3763329853,
              5011728.6568633299
            ],
            [
              351808.31275200529,
              5011715.6908880249
            ],
            [
              351812.07517978182,
              5011703.6683206791
            ],
            [
              351813.28833824192,
              5011690.9688411243
            ],
            [
              351838.51209250995,
              5011705.7619146286
            ],
            [
              351851.45934465429,
              5011685.8717172593
            ],
            [
              351877.26521160296,
              5011658.574428427
            ],
            [
              351903.00717425917,
              5011639.8043802073
            ],
            [
              351920.32828605419,
              5011660.2178439396
            ],
            [
              351924.47773004312,
              5011664.1247900967
            ],
            [
              351939.82152990095,
              5011652.4904576624
            ],
            [
              351946.05378912337,
              5011637.9936928861
            ],
            [
              351929.50817652233,
              5011624.5094666909
            ],
            [
              351932.15238660568,
              5011622.8129265485
            ],
            [
              351957.21081587119,
              5011585.7504779045
            ],
            [
              351967.66602029343,
              5011589.5544224354
            ],
            [
              351983.72023721755,
              5011588.955889252
            ],
            [
              351982.52876023948,
              5011613.9464180581
            ],
            [
              351979.96339835873,
              5011622.9839046076
            ],
            [
              351986.37535719085,
              5011613.5223201048
            ],
            [
              351988.53003252594,
              5011610.3428483577
            ],
            [
              351990.55162749422,
              5011607.359752086
            ],
            [
              351992.52417046187,
              5011604.4490377419
            ],
            [
              351994.61022720177,
              5011601.3708208129
            ],
            [
              351997.45204525167,
              5011597.1773911398
            ],
            [
              351999.98793288012,
              5011593.4353971165
            ],
            [
              352009.57923796389,
              5011599.5978109296
            ],
            [
              352019.17054288817,
              5011605.7602243964
            ],
            [
              352026.2491415324,
              5011596.7582016746
            ],
            [
              352033.32774026826,
              5011587.7561793197
            ],
            [
              352047.48493801488,
              5011569.7521357229
            ],
            [
              352050.32473329169,
              5011565.7586619761
            ],
            [
              352036.86390925158,
              5011559.7273589494
            ],
            [
              352011.15989142121,
              5011555.4488793872
            ],
            [
              352009.3246336283,
              5011549.961435996
            ],
            [
              352010.25796485419,
              5011544.5137688303
            ],
            [
              352020.96446527087,
              5011532.9039962143
            ],
            [
              352021.3024471947,
              5011531.1707134945
            ],
            [
              352026.50605500099,
              5011532.2457414055
            ],
            [
              352038.67001169809,
              5011525.6885452885
            ],
            [
              352048.41481242044,
              5011517.3465252221
            ],
            [
              352045.10254855122,
              5011513.773841165
            ],
            [
              352049.75338070421,
              5011507.8296460705
            ],
            [
              352037.82738134655,
              5011500.7294767238
            ],
            [
              352020.41406757617,
              5011481.4546279768
            ],
            [
              352022.11123613379,
              5011471.5768128391
            ],
            [
              352029.49851715582,
              5011473.8101706961
            ],
            [
              352036.16866795043,
              5011469.7000399623
            ],
            [
              352027.70123867854,
              5011456.6003646553
            ],
            [
              352034.67219713493,
              5011454.4802671792
            ],
            [
              352044.1275155659,
              5011449.2029235512
            ],
            [
              352053.01389243017,
              5011448.5143282283
            ],
            [
              352059.04213683255,
              5011455.9888349921
            ],
            [
              352069.96628321247,
              5011459.3172563501
            ],
            [
              352077.91797808273,
              5011464.6630866276
            ],
            [
              352082.0465538869,
              5011468.5104046548
            ],
            [
              352083.14447312016,
              5011466.4260870432
            ],
            [
              352095.90855476906,
              5011485.940710593
            ],
            [
              352101.37159319484,
              5011488.7909248676
            ],
            [
              352116.89202130359,
              5011496.9022450317
            ],
            [
              352125.50301915308,
              5011477.4498314075
            ],
            [
              352128.90242274926,
              5011469.309215907
            ],
            [
              352142.24167663319,
              5011478.7904913146
            ],
            [
              352150.48481372098,
              5011479.9190830244
            ],
            [
              352155.78996773489,
              5011478.7800920401
            ],
            [
              352165.37666040118,
              5011474.1346672308
            ],
            [
              352186.75400250475,
              5011463.8099073758
            ],
            [
              352206.91640805121,
              5011457.1282928586
            ],
            [
              352217.56477331714,
              5011454.9791174959
            ],
            [
              352228.82814726973,
              5011453.4150709407
            ],
            [
              352246.39591065852,
              5011451.9986115061
            ],
            [
              352261.03518027137,
              5011433.5864053667
            ],
            [
              352240.56445721752,
              5011420.0886020269
            ],
            [
              352263.61985440837,
              5011389.563321691
            ],
            [
              352282.99579970137,
              5011399.4677328328
            ],
            [
              352333.50110160589,
              5011439.7458245214
            ],
            [
              352360.14985662937,
              5011460.2531461297
            ],
            [
              352364.36048741086,
              5011462.6911833817
            ],
            [
              352369.9190412772,
              5011456.6037609605
            ],
            [
              352377.6904263508,
              5011450.8951132288
            ],
            [
              352381.69684426085,
              5011444.1635431396
            ],
            [
              352429.10254626541,
              5011464.1649783729
            ],
            [
              352440.68460918398,
              5011449.6788945431
            ],
            [
              352446.37055786885,
              5011445.4791541956
            ],
            [
              352411.09111173038,
              5011430.4439608064
            ],
            [
              352398.53940148995,
              5011430.3588327337
            ],
            [
              352390.5533510347,
              5011425.044748175
            ],
            [
              352401.70215472224,
              5011402.616155494
            ],
            [
              352408.58032149659,
              5011388.4205548335
            ],
            [
              352427.69799683284,
              5011367.7787913643
            ],
            [
              352430.57717917167,
              5011368.9428418558
            ],
            [
              352432.74774780514,
              5011358.3434873987
            ],
            [
              352433.20998414449,
              5011351.8730287235
            ],
            [
              352436.54670254647,
              5011343.7943570791
            ],
            [
              352449.60338531155,
              5011315.9513982041
            ],
            [
              352454.64007123472,
              5011301.1293283673
            ],
            [
              352460.1404154973,
              5011282.8071551174
            ],
            [
              352461.30876185908,
              5011275.2357661491
            ],
            [
              352465.27475552267,
              5011261.3518880224
            ],
            [
              352472.38725647441,
              5011259.3069301052
            ],
            [
              352517.6450464853,
              5011255.1276727328
            ],
            [
              352515.08978875272,
              5011230.6046196865
            ],
            [
              352512.19837699982,
              5011230.3233303595
            ],
            [
              352492.34279174218,
              5011232.011667477
            ],
            [
              352459.72806469171,
              5011216.3311339868
            ],
            [
              352463.67053002684,
              5011210.0651782556
            ],
            [
              352455.40375594347,
              5011196.3390900483
            ],
            [
              352444.32195542514,
              5011188.7384270346
            ],
            [
              352442.2252000068,
              5011190.9836605471
            ],
            [
              352438.70551158377,
              5011194.7525896598
            ],
            [
              352432.12653685024,
              5011201.7974434104
            ],
            [
              352419.93111834273,
              5011214.8564607119
            ],
            [
              352445.18147830362,
              5011230.1217621388
            ],
            [
              352424.16171356774,
              5011239.4290537015
            ],
            [
              352411.08646591916,
              5011239.9637159966
            ],
            [
              352400.76797449961,
              5011237.8574404633
            ],
            [
              352392.39597265993,
              5011229.7953618849
            ],
            [
              352377.76412385923,
              5011232.0970473541
            ],
            [
              352365.80877103901,
              5011220.9557959056
            ],
            [
              352361.04684627702,
              5011212.1601051074
            ],
            [
              352361.4055038045,
              5011210.5125751188
            ],
            [
              352354.14735953353,
              5011203.0924160667
            ],
            [
              352348.36683345883,
              5011196.6870925305
            ],
            [
              352345.02882547746,
              5011195.2544468446
            ],
            [
              352336.93882033328,
              5011191.0274859108
            ],
            [
              352334.95466049976,
              5011204.9443412106
            ],
            [
              352330.5151641508,
              5011216.3476034217
            ],
            [
              352325.80518667895,
              5011235.4058552124
            ],
            [
              352323.71630919882,
              5011243.5119915437
            ],
            [
              352327.118551269,
              5011247.4128373247
            ],
            [
              352307.4470334567,
              5011263.5023099827
            ],
            [
              352291.20171166625,
              5011250.5768388202
            ],
            [
              352289.80216200597,
              5011255.4451632528
            ],
            [
              352274.42452519765,
              5011244.3500304036
            ],
            [
              352265.72856141563,
              5011253.4804394674
            ],
            [
              352264.20279136155,
              5011263.3315576334
            ],
            [
              352238.538354302,
              5011263.9722436639
            ],
            [
              352234.2985537019,
              5011264.2572458154
            ],
            [
              352207.9051979818,
              5011276.713962066
            ],
            [
              352200.68733346026,
              5011273.4316730965
            ],
            [
              352192.31387886842,
              5011276.9163076216
            ],
            [
              352167.50197358226,
              5011284.1714118635
            ],
            [
              352172.08221384865,
              5011266.2557656085
            ],
            [
              352152.40103490255,
              5011265.7193735447
            ],
            [
              352132.47601949982,
              5011261.7611391749
            ],
            [
              352120.41908701352,
              5011253.4155722447
            ],
            [
              352108.64689753315,
              5011246.4811239643
            ],
            [
              352073.74206276884,
              5011230.5815104768
            ],
            [
              352067.97520665947,
              5011226.6147135524
            ],
            [
              352053.31345294073,
              5011220.4017964536
            ],
            [
              352040.31995329336,
              5011217.8929475956
            ],
            [
              352034.93855515664,
              5011232.0535797672
            ],
            [
              352016.18288956099,
              5011225.6858042963
            ],
            [
              352012.38962338777,
              5011232.7500762716
            ],
            [
              352009.41524156323,
              5011244.0723470412
            ],
            [
              352008.86119046522,
              5011247.100863711
            ],
            [
              352013.66620025475,
              5011248.7154216217
            ],
            [
              352075.31352501758,
              5011268.5319291065
            ],
            [
              352079.441044993,
              5011270.0144001963
            ],
            [
              352082.19385988865,
              5011271.1757137328
            ],
            [
              352085.25551014213,
              5011272.6348347375
            ],
            [
              352088.42171437445,
              5011274.2936810618
            ],
            [
              352091.49275551038,
              5011276.2574337525
            ],
            [
              352094.06010980828,
              5011278.3320241803
            ],
            [
              352096.42882976419,
              5011280.6119820913
            ],
            [
              352098.39168713015,
              5011282.7988669835
            ],
            [
              352099.11766357388,
              5011283.7958084652
            ],
            [
              352102.55212227587,
              5011283.5298046591
            ],
            [
              352102.48625375697,
              5011285.4508883189
            ],
            [
              352102.20563019841,
              5011286.6690716753
            ],
            [
              352101.81667754613,
              5011287.4846711019
            ],
            [
              352101.2245574579,
              5011288.3040513759
            ],
            [
              352100.32627095305,
              5011288.9270389453
            ],
            [
              352099.12025520462,
              5011289.3536629071
            ],
            [
              352096.0999672747,
              5011290.1171850301
            ],
            [
              352082.75734004664,
              5011292.7599614309
            ],
            [
              352076.00504395756,
              5011294.0981602659
            ],
            [
              352065.62608283822,
              5011296.2111657821
            ],
            [
              352061.89485608018,
              5011296.7858279403
            ],
            [
              352049.08131351153,
              5011298.5403786376
            ],
            [
              352049.11138954473,
              5011300.1565416148
            ],
            [
              352050.24667917175,
              5011301.3479564097
            ],
            [
              352050.25607793505,
              5011301.8530073399
            ],
            [
              352050.06497039722,
              5011302.4628347652
            ],
            [
              352049.37393548741,
              5011303.3855203865
            ],
            [
              352047.08980395197,
              5011305.6510207225
            ],
            [
              352044.00553475658,
              5011308.4370564157
            ],
            [
              352042.11113729601,
              5011309.8869426837
            ],
            [
              352039.51253883616,
              5011311.552024181
            ],
            [
              352036.30678997579,
              5011313.2288237885
            ],
            [
              352030.43395070528,
              5011315.9145570779
            ],
            [
              352023.40666944959,
              5011318.7739708181
            ],
            [
              352011.34995206277,
              5011323.141193673
            ],
            [
              351997.29732963041,
              5011323.5037525361
            ],
            [
              351998.19256932673,
              5011328.1355901519
            ],
            [
              351986.24872130499,
              5011333.1074035745
            ],
            [
              351981.88513851369,
              5011334.7336352635
            ],
            [
              351979.06833410502,
              5011335.5944164675
            ],
            [
              351975.04895614326,
              5011336.9828030886
            ],
            [
              351970.72294710443,
              5011338.2758508483
            ],
            [
              351965.89215267502,
              5011339.5787117919
            ],
            [
              351960.45309371123,
              5011340.7914278964
            ],
            [
              351945.45136011986,
              5011344.506560944
            ],
            [
              351945.20197206241,
              5011341.9846527455
            ],
            [
              351924.08317296748,
              5011346.8131734487
            ],
            [
              351923.85822963936,
              5011345.6048171204
            ],
            [
              351919.93589389941,
              5011346.7893077387
            ],
            [
              351919.70343150222,
              5011345.1769104972
            ],
            [
              351916.57215952099,
              5011345.4372728337
            ],
            [
              351917.38727481512,
              5011351.1820998397
            ],
            [
              351900.77929177316,
              5011355.5333968578
            ],
            [
              351899.73546567501,
              5011348.3777735839
            ],
            [
              351892.87672455009,
              5011349.4148194436
            ],
            [
              351894.23313805443,
              5011357.0698517701
            ],
            [
              351881.44214681711,
              5011360.0365284076
            ],
            [
              351867.61782104796,
              5011364.182149359
            ],
            [
              351826.17561656144,
              5011410.1537192184
            ],
            [
              351791.52165528917,
              5011447.2675718693
            ],
            [
              351788.12861613132,
              5011444.2989395922
            ],
            [
              351785.84261180536,
              5011446.4638514537
            ],
            [
              351788.83541180129,
              5011449.6416033851
            ],
            [
              351783.78335638711,
              5011455.3945750305
            ],
            [
              351767.19755244063,
              5011473.6649733372
            ],
            [
              351748.91161064635,
              5011458.5440934431
            ],
            [
              351733.72023496754,
              5011476.4313211022
            ],
            [
              351733.42644534732,
              5011476.9420148442
            ],
            [
              351733.24473698006,
              5011478.0568943154
            ],
            [
              351733.56296408543,
              5011478.8593341792
            ],
            [
              351734.29349069897,
              5011480.0587014379
            ],
            [
              351734.50527597254,
              5011480.5599864013
            ],
            [
              351734.72082086426,
              5011481.2632919243
            ],
            [
              351734.73585932521,
              5011482.0713741397
            ],
            [
              351734.54851155018,
              5011482.8832227774
            ],
            [
              351734.16065734898,
              5011483.799848116
            ],
            [
              351722.23326923262,
              5011511.9602042763
            ],
            [
              351725.81365732645,
              5011514.1169877751
            ],
            [
              351722.68685892189,
              5011520.036222579
            ],
            [
              351717.5916258436,
              5011518.0086759124
            ],
            [
              351715.83563408343,
              5011521.4773135399
            ],
            [
              351719.3219577545,
              5011524.0400285954
            ],
            [
              351716.78727963113,
              5011529.1398819974
            ],
            [
              351713.01014456357,
              5011527.2898959005
            ],
            [
              351711.42995234462,
              5011529.9141963702
            ],
            [
              351714.3956548245,
              5011531.6778188376
            ],
            [
              351712.63966318755,
              5011535.1464565666
            ],
            [
              351711.7330754131,
              5011535.3654188998
            ],
            [
              351710.52596020472,
              5011535.6910193833
            ],
            [
              351709.12319662207,
              5011536.4244420016
            ],
            [
              351708.1252055412,
              5011537.1503317161
            ],
            [
              351707.03957861068,
              5011538.5855885176
            ],
            [
              351706.35417532682,
              5011539.8108872576
            ],
            [
              351705.10375086096,
              5011543.2696971074
            ],
            [
              351703.76866883511,
              5011547.6399091836
            ],
            [
              351702.2827451998,
              5011554.7415700695
            ],
            [
              351701.01157756848,
              5011562.546131961
            ],
            [
              351700.54772653285,
              5011565.4695640467
            ],
            [
              351699.9883527981,
              5011568.0065252138
            ],
            [
              351699.12500118732,
              5011570.5487242378
            ],
            [
              351698.34820255166,
              5011572.2813696004
            ],
            [
              351697.26068810333,
              5011573.6151971351
            ],
            [
              351695.97940515884,
              5011575.4578571357
            ],
            [
              351695.10556379019,
              5011577.3943990702
            ],
            [
              351694.74214767059,
              5011579.6241583629
            ],
            [
              351694.78727156081,
              5011582.04882441
            ],
            [
              351695.03853360453,
              5011584.6713254163
            ],
            [
              351695.57231392345,
              5011586.1774899317
            ],
            [
              351696.30659286847,
              5011587.5784585364
            ],
            [
              351696.6360992993,
              5011588.9869601075
            ],
            [
              351696.78202567762,
              5011591.4097501673
            ],
            [
              351696.50891444332,
              5011593.0315572266
            ],
            [
              351695.92885485076,
              5011594.4569861684
            ],
            [
              351694.84431874932,
              5011595.992848902
            ],
            [
              351693.9573185998,
              5011597.2223188775
            ],
            [
              351693.17675260355,
              5011598.7525246171
            ],
            [
              351692.91978637321,
              5011601.2838579677
            ],
            [
              351692.97268859303,
              5011604.0844689347
            ],
            [
              351693.30971447245,
              5011605.8970116563
            ],
            [
              351693.95447794907,
              5011607.9063370656
            ],
            [
              351694.2858643111,
              5011609.4158489136
            ],
            [
              351694.52396763978,
              5011611.3312778892
            ],
            [
              351694.46562806965,
              5011613.6568241613
            ],
            [
              351690.59332942101,
              5011612.1117457198
            ],
            [
              351689.6555013507,
              5011616.0703840842
            ],
            [
              351693.93256474822,
              5011617.6075103544
            ],
            [
              351693.85918643192,
              5011619.1249743281
            ],
            [
              351693.57291638572,
              5011620.0397094041
            ],
            [
              351693.08989962674,
              5011621.2612418495
            ],
            [
              351692.00991254923,
              5011622.9995298423
            ],
            [
              351690.63158712658,
              5011625.0460866112
            ],
            [
              351689.4639487915,
              5011627.4929035055
            ],
            [
              351688.79171254893,
              5011629.4256936423
            ],
            [
              351688.03370490758,
              5011632.1680229539
            ],
            [
              351687.47730993765,
              5011634.9070194419
            ],
            [
              351687.22299743659,
              5011637.5389294783
            ],
            [
              351687.37832343258,
              5011640.4667710271
            ],
            [
              351687.40852094366,
              5011643.1814339356
            ],
            [
              351687.35957306874,
              5011646.0116125783
            ],
            [
              351687.09962888167,
              5011648.3409108846
            ],
            [
              351686.74639391649,
              5011651.0757072102
            ],
            [
              351686.20315810456,
              5011654.5217757672
            ],
            [
              351685.75022700772,
              5011657.35989206
            ],
            [
              351682.71865712909,
              5011657.5173565969
            ],
            [
              351682.58660710492,
              5011661.2584893527
            ],
            [
              351687.75632590445,
              5011661.8700140528
            ],
            [
              351687.6749766104,
              5011673.7957090065
            ],
            [
              351683.02321818384,
              5011673.882281133
            ],
            [
              351682.98633896676,
              5011677.3189261481
            ],
            [
              351687.13174102764,
              5011677.2417775895
            ],
            [
              351687.24836379156,
              5011678.0479693683
            ],
            [
              351687.06477618584,
              5011679.0618388047
            ],
            [
              351686.57612002804,
              5011679.9803404827
            ],
            [
              351685.10451976728,
              5011682.4332338013
            ],
            [
              351682.44193395152,
              5011686.1204162072
            ],
            [
              351681.06027260399,
              5011688.1557149496
            ],
            [
              351679.6725481812,
              5011689.6972205844
            ],
            [
              351678.18214926339,
              5011691.1400110722
            ],
            [
              351676.78956664639,
              5011692.378471313
            ],
            [
              351675.39244287339,
              5011693.4149255175
            ],
            [
              351673.58568835864,
              5011694.1558674024
            ],
            [
              351670.87798567105,
              5011695.4188029571
            ],
            [
              351667.77005937544,
              5011696.8916968079
            ],
            [
              351665.36366548808,
              5011698.047979557
            ],
            [
              351662.46751699183,
              5011700.0217392556
            ],
            [
              351659.07065949735,
              5011702.3083726661
            ],
            [
              351657.2808237765,
              5011703.9584073164
            ],
            [
              351656.09736548481,
              5011705.5971568692
            ],
            [
              351655.31304793683,
              5011706.9257615535
            ],
            [
              351654.73486862466,
              5011708.4522011597
            ],
            [
              351654.46285636973,
              5011710.1750333477
            ],
            [
              351654.60016565496,
              5011712.0927576097
            ],
            [
              351654.94471164612,
              5011714.3093416188
            ],
            [
              351655.5868067363,
              5011716.2172521595
            ],
            [
              351656.52912000846,
              5011717.9179041153
            ],
            [
              351657.57034804136,
              5011719.5152506242
            ],
            [
              351659.01070900221,
              5011720.8020331031
            ],
            [
              351660.85506890464,
              5011722.0817161193
            ],
            [
              351662.48951520812,
              5011722.9607053669
            ],
            [
              351664.32258802431,
              5011723.6339074522
            ],
            [
              351666.15378095227,
              5011724.2060992336
            ],
            [
              351667.78336901049,
              5011724.7820430323
            ],
            [
              351669.71724423161,
              5011725.4533690894
            ],
            [
              351671.14444600366,
              5011726.0330794379
            ],
            [
              351672.2752049769,
              5011727.0229072077
            ],
            [
              351673.2118708145,
              5011728.4201090401
            ],
            [
              351674.05259245675,
              5011730.1222323002
            ],
            [
              351674.81861071143,
              5011733.0185832642
            ],
            [
              351675.62731199735,
              5011738.4603970191
            ],
            [
              351676.43303501274,
              5011743.7001756215
            ],
            [
              351677.21995924058,
              5011747.9298512759
            ],
            [
              351677.65214865986,
              5011749.4374870416
            ],
            [
              351677.98243718478,
              5011750.8459739881
            ],
            [
              351678.24420624052,
              5011754.0749704158
            ],
            [
              351678.68165627267,
              5011761.2414631089
            ],
            [
              351678.87834093423,
              5011766.3915310856
            ],
            [
              351678.64361987112,
              5011770.2020780183
            ],
            [
              351678.46943199792,
              5011771.7209989801
            ],
            [
              351678.49199071864,
              5011772.9331224794
            ],
            [
              351678.72257539269,
              5011774.4445102587
            ],
            [
              351679.0539702996,
              5011775.9544411413
            ],
            [
              351640.79674119741,
              5011772.2158386763
            ],
            [
              351639.68024752528,
              5011772.0345270615
            ],
            [
              351638.25570685352,
              5011771.5558126085
            ],
            [
              351636.52343624528,
              5011770.8807347044
            ],
            [
              351634.59065922914,
              5011770.3104336923
            ],
            [
              351632.66430329747,
              5011770.0431490438
            ],
            [
              351631.03925619693,
              5011769.6692114668
            ],
            [
              351629.6195737563,
              5011769.4935424561
            ],
            [
              351627.80153980973,
              5011769.6284229988
            ],
            [
              351625.58061315044,
              5011769.8718470382
            ],
            [
              351623.36532615527,
              5011770.4183020024
            ],
            [
              351620.75544742745,
              5011771.4773271559
            ],
            [
              351619.1623584624,
              5011772.8205648288
            ],
            [
              351617.87732431135,
              5011774.4616244985
            ],
            [
              351616.6005833557,
              5011776.5062917396
            ],
            [
              351615.73049491405,
              5011778.6444361201
            ],
            [
              351615.36896771408,
              5011780.9756255718
            ],
            [
              351615.11465627968,
              5011783.6075362619
            ],
            [
              351615.15790172148,
              5011785.9311925219
            ],
            [
              351615.71047422889,
              5011788.4470410384
            ],
            [
              351616.55872370576,
              5011790.5536248032
            ],
            [
              351617.70639433939,
              5011792.4521261686
            ],
            [
              351620.0796699911,
              5011794.9345088666
            ],
            [
              351621.933422081,
              5011796.7188240374
            ],
            [
              351623.47270601959,
              5011797.9027199857
            ],
            [
              351626.53624247393,
              5011799.4628485255
            ],
            [
              351628.37495533511,
              5011800.4390811911
            ],
            [
              351630.52061672899,
              5011801.6116918111
            ],
            [
              351632.96648845484,
              5011802.5766246188
            ],
            [
              351635.81447143358,
              5011803.4330284288
            ],
            [
              351639.71154596208,
              5011804.6292263092
            ],
            [
              351642.25446026103,
              5011805.3902624305
            ],
            [
              351644.48666620749,
              5011805.7528999848
            ],
            [
              351647.42605201597,
              5011806.1023762031
            ],
            [
              351649.95957454428,
              5011806.3587799314
            ],
            [
              351652.5890333908,
              5011806.3098430485
            ],
            [
              351656.03399412317,
              5011806.6499099936
            ],
            [
              351659.4816161397,
              5011807.0909726862
            ],
            [
              351663.23430673213,
              5011807.6274030199
            ],
            [
              351665.36304879648,
              5011807.8909208197
            ],
            [
              351668.60076483217,
              5011807.9317089235
            ],
            [
              351672.65100431361,
              5011808.159465706
            ],
            [
              351675.38424380717,
              5011808.310687907
            ],
            [
              351678.21906712017,
              5011808.4600195279
            ],
            [
              351680.84664584562,
              5011808.3100723438
            ],
            [
              351684.48459304153,
              5011808.1413211795
            ],
            [
              351689.54034251213,
              5011808.0472285589
            ],
            [
              351691.13343101501,
              5011806.7039910005
            ],
            [
              351687.725348562,
              5011802.9272791119
            ],
            [
              351695.50875897409,
              5011797.2245127736
            ],
            [
              351709.83478380955,
              5011811.5096679572
            ],
            [
              351702.14465675288,
              5011816.8065172033
            ],
            [
              351697.41659604572,
              5011812.7512358688
            ],
            [
              351695.92431001685,
              5011814.0925973943
            ],
            [
              351701.48369324027,
              5011819.3449502094
            ],
            [
              351706.22681617417,
              5011815.851324345
            ],
            [
              351728.09575863153,
              5011838.6864084536
            ],
            [
              351733.38137173263,
              5011834.6468528705
            ],
            [
              351736.67925210693,
              5011837.9203886176
            ],
            [
              351741.10600384366,
              5011842.0823220974
            ],
            [
              351749.28604935721,
              5011849.5852039903
            ],
            [
              351742.72667507798,
              5011855.8714614874
            ],
            [
              351745.20263264334,
              5011858.4529770724
            ],
            [
              351752.26303263142,
              5011851.9548851224
            ],
            [
              351794.37942170346,
              5011890.3858300718
            ],
            [
              351788.14917976019,
              5011903.4364127619
            ],
            [
              351784.64206315222,
              5011910.6767358389
            ],
            [
              351780.03504788183,
              5011918.5433818679
            ],
            [
              351773.79590241605,
              5011914.8193618804
            ],
            [
              351747.72873351746,
              5011961.8419273794
            ],
            [
              351741.34896272683,
              5011973.6622085106
            ],
            [
              351747.87891198386,
              5011976.673079418
            ],
            [
              351746.51939623029,
              5011979.7301590927
            ],
            [
              351745.65041571722,
              5011981.9697471112
            ],
            [
              351744.88677114568,
              5011984.4090459226
            ],
            [
              351744.12689430331,
              5011987.0507844165
            ],
            [
              351743.6683183526,
              5011989.5854507107
            ],
            [
              351738.20105886995,
              5011989.2830237867
            ],
            [
              351738.04379160731,
              5011991.7110370472
            ],
            [
              351743.72096495132,
              5011992.4141574297
            ],
            [
              351744.59263684246,
              5012006.1111595994
            ],
            [
              351745.08389535389,
              5012010.7505177073
            ],
            [
              351745.68911608233,
              5012016.0950716026
            ],
            [
              351746.08067087276,
              5012020.8373305937
            ],
            [
              351746.16527716507,
              5012025.3832118716
            ],
            [
              351745.83383125922,
              5012029.3305650158
            ],
            [
              351745.11343412037,
              5012034.0935192723
            ],
            [
              351744.02353161288,
              5012040.75889266
            ],
            [
              351742.99163689039,
              5012045.1230441527
            ],
            [
              351741.17244766414,
              5012050.6137657175
            ],
            [
              351740.27085251355,
              5012052.9699481614
            ],
            [
              351757.00631401438,
              5012053.3782506147
            ],
            [
              351796.95640237257,
              5012089.3591911979
            ],
            [
              351818.22034633241,
              5012109.5414065998
            ],
            [
              351823.32833916222,
              5012118.4615778252
            ],
            [
              351837.85788604378,
              5012131.5706741503
            ],
            [
              351841.12763197027,
              5012129.7510503195
            ],
            [
              351846.21381412476,
              5012136.9783352502
            ],
            [
              351853.9089869028,
              5012141.1183227357
            ],
            [
              351858.61438054038,
              5012144.7079606783
            ],
            [
              351861.17776687612,
              5012144.8765387656
            ],
            [
              351866.12769360078,
              5012145.2505209092
            ],
            [
              351867.93323680217,
              5012145.3869352313
            ],
            [
              351869.85053926072,
              5012136.3797860006
            ],
            [
              351872.69971017278,
              5012127.4430424217
            ],
            [
              351884.29246198683,
              5012093.0599973379
            ],
            [
              351881.8633169097,
              5012091.7968553901
            ],
            [
              351893.05373887997,
              5012064.1924460419
            ],
            [
              351903.63716255064,
              5012051.1015489064
            ],
            [
              351897.83463732729,
              5012044.3697593091
            ],
            [
              351905.86631384079,
              5012032.481094731
            ],
            [
              351918.66588679265,
              5012019.1177108344
            ],
            [
              351939.33758225577,
              5012003.401797221
            ],
            [
              351929.2410655765,
              5011989.3745973799
            ],
            [
              351966.89702678449,
              5011982.2518425826
            ],
            [
              352007.27517639211,
              5011981.6532305647
            ],
            [
              352018.66366346425,
              5011985.263860519
            ],
            [
              352023.19463766919,
              5011978.0097219497
            ],
            [
              352033.44559357653,
              5011958.2587188249
            ],
            [
              352036.29533823353,
              5011952.4806595929
            ],
            [
              352024.89338635065,
              5011948.1409222754
            ],
            [
              352002.24912857357,
              5011948.042151127
            ],
            [
              351984.92621828959,
              5011947.1940596877
            ],
            [
              351969.8002753589,
              5011949.2184875123
            ],
            [
              351918.50969994027,
              5011962.1381400982
            ],
            [
              351884.77815122198,
              5011972.4786906494
            ],
            [
              351874.58905987086,
              5011972.5325346198
            ],
            [
              351862.81005217013,
              5011938.2609204315
            ],
            [
              351860.72028411791,
              5011931.3079266474
            ],
            [
              351864.23024073447,
              5011907.0163764991
            ],
            [
              351872.14633897267,
              5011907.4769653277
            ],
            [
              351895.39281091501,
              5011907.6376566421
            ],
            [
              351921.74302480544,
              5011915.0783844208
            ],
            [
              351927.36310939706,
              5011913.411579242
            ],
            [
              351932.77861772472,
              5011913.671316633
            ],
            [
              351938.58556640398,
              5011883.2806957532
            ],
            [
              351933.55666069809,
              5011881.5443088701
            ],
            [
              351935.92117078131,
              5011862.8651959486
            ],
            [
              351935.8072924093,
              5011857.6583864819
            ],
            [
              351953.37655275257,
              5011857.9123513969
            ],
            [
              351975.51136406168,
              5011862.0783620402
            ],
            [
              352000.80416714662,
              5011861.7023335528
            ],
            [
              352028.05879240477,
              5011870.0023936611
            ],
            [
              352031.02351444529,
              5011870.3518411573
            ],
            [
              352044.96014940407,
              5011870.7553640986
            ],
            [
              352028.29452305095,
              5011896.8244667649
            ],
            [
              352033.26770243113,
              5011899.288843492
            ],
            [
              352030.70178582607,
              5011903.9769987566
            ],
            [
              352035.88024322997,
              5011907.2898879573
            ],
            [
              352060.66737358418,
              5011879.7438337253
            ],
            [
              352076.55241566227,
              5011896.5073733227
            ],
            [
              352082.88453948527,
              5011905.558135014
            ],
            [
              352086.16749852174,
              5011902.52135533
            ],
            [
              352092.85136238323,
              5011910.3519258723
            ],
            [
              352083.54480457847,
              5011919.0633362196
            ],
            [
              352093.40221800911,
              5011930.1707874238
            ],
            [
              352086.90687506256,
              5011939.1704643695
            ],
            [
              352086.2787342738,
              5011947.538672585
            ],
            [
              352086.93535087182,
              5011961.1522074677
            ],
            [
              352089.06886214192,
              5011962.0341333542
            ],
            [
              352100.06429636659,
              5011969.2066145856
            ],
            [
              352109.2023709176,
              5011981.9301933376
            ],
            [
              352098.86690275738,
              5011992.4487449564
            ],
            [
              352097.50937428878,
              5011997.5820588646
            ],
            [
              352108.44048523536,
              5012016.8866776451
            ],
            [
              352110.5813441716,
              5012018.7811819771
            ],
            [
              352152.45424593781,
              5011973.2565450659
            ],
            [
              352161.92934831098,
              5011968.7662657574
            ],
            [
              352165.25311513676,
              5011968.2880785633
            ],
            [
              352170.39896914636,
              5011968.9610209595
            ],
            [
              352178.78502156533,
              5011972.3796737175
            ],
            [
              352189.0677213383,
              5011948.578457254
            ],
            [
              352200.79345362802,
              5011928.4478509547
            ],
            [
              352248.40784426703,
              5011952.6104048714
            ],
            [
              352262.78375557769,
              5011956.1735724462
            ],
            [
              352274.92975080147,
              5011961.5880017374
            ],
            [
              352280.70190088585,
              5011951.9705973519
            ],
            [
              352286.99253633904,
              5011957.8730318947
            ],
            [
              352290.01912952866,
              5011960.055897247
            ],
            [
              352288.55438819795,
              5011972.008529691
            ],
            [
              352292.58332505258,
              5011969.6233407436
            ],
            [
              352309.80111962411,
              5011961.0127600404
            ],
            [
              352317.88786046876,
              5011955.2007215256
            ],
            [
              352321.11432717281,
              5011934.3013748862
            ],
            [
              352318.57768440165,
              5011933.9872104274
            ],
            [
              352315.82198011206,
              5011939.7488770438
            ],
            [
              352286.17834413238,
              5011923.6736758249
            ],
            [
              352278.7787028724,
              5011918.4177112533
            ],
            [
              352271.97624129313,
              5011914.4124922147
            ],
            [
              352238.95103554515,
              5011896.7426972799
            ],
            [
              352241.87740042974,
              5011891.7788352193
            ],
            [
              352284.71813257638,
              5011906.8916833466
            ],
            [
              352283.1387587309,
              5011888.2820875403
            ],
            [
              352262.70206355705,
              5011875.0800695829
            ],
            [
              352265.06248594291,
              5011871.4424106823
            ],
            [
              352257.88497015863,
              5011871.217525403
            ],
            [
              352256.3640817618,
              5011866.5858203154
            ],
            [
              352256.40869889111,
              5011854.6231758539
            ],
            [
              352256.04356671212,
              5011848.1658557905
            ],
            [
              352261.75525266508,
              5011836.5025286879
            ],
            [
              352267.26246773871,
              5011825.6010321882
            ],
            [
              352272.6947761749,
              5011828.6045018015
            ],
            [
              352291.60649931501,
              5011833.6798565304
            ],
            [
              352292.05414542096,
              5011828.84123486
            ],
            [
              352301.2678860309,
              5011829.1564962268
            ],
            [
              352302.32715732604,
              5011823.6186536895
            ],
            [
              352310.16937746044,
              5011823.4146134267
            ],
            [
              352320.81327939982,
              5011823.8554579895
            ],
            [
              352349.26633497508,
              5011822.5127458535
            ],
            [
              352352.59235990688,
              5011817.0024430705
            ],
            [
              352358.61812635261,
              5011817.0784223936
            ],
            [
              352362.29279247351,
              5011812.2121091112
            ],
            [
              352368.2240965438,
              5011807.2096860921
            ],
            [
              352373.25296041416,
              5011802.2726997361
            ],
            [
              352375.17498578556,
              5011799.1989467014
            ],
            [
              352379.45213418495,
              5011793.5379645014
            ],
            [
              352381.11385532975,
              5011791.6701142481
            ],
            [
              352383.52413819084,
              5011789.7087676115
            ],
            [
              352393.71570445126,
              5011781.9340751301
            ],
            [
              352399.24107205519,
              5011776.8094411911
            ],
            [
              352438.03524079133,
              5011803.5333251143
            ],
            [
              352438.96189983294,
              5011809.3939901413
            ],
            [
              352449.6148997912,
              5011800.3797506923
            ],
            [
              352458.84720352414,
              5011804.9762511663
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 300,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353311.05734900356,
              5011391.6950104972
            ],
            [
              353313.47437660286,
              5011389.5665521137
            ],
            [
              353321.66702424834,
              5011381.4645407349
            ],
            [
              353328.72022899881,
              5011366.6031799987
            ],
            [
              353333.02339295886,
              5011358.0697113043
            ],
            [
              353334.60265515512,
              5011359.1314341407
            ],
            [
              353340.55911890068,
              5011348.1873771995
            ],
            [
              353350.98978030094,
              5011347.2138808975
            ],
            [
              353354.41769232188,
              5011342.8635541182
            ],
            [
              353365.01663039473,
              5011334.1711916039
            ],
            [
              353361.64889792475,
              5011331.6898191841
            ],
            [
              353387.06321999099,
              5011314.6163052516
            ],
            [
              353394.99044924363,
              5011309.0132219801
            ],
            [
              353408.8241016324,
              5011298.1563555542
            ],
            [
              353419.5391181673,
              5011291.2068297956
            ],
            [
              353426.03464572999,
              5011288.2802393353
            ],
            [
              353415.24367502646,
              5011278.271301005
            ],
            [
              353409.23080414202,
              5011274.1850295141
            ],
            [
              353413.12239724473,
              5011269.0363065917
            ],
            [
              353407.19507781608,
              5011264.7821280519
            ],
            [
              353394.90694874321,
              5011259.1440232657
            ],
            [
              353392.72252267034,
              5011262.916039167
            ],
            [
              353391.30752549687,
              5011264.2861483721
            ],
            [
              353385.9694429207,
              5011271.5844178358
            ],
            [
              353381.86153296864,
              5011279.2690337086
            ],
            [
              353375.98272276489,
              5011283.3138449602
            ],
            [
              353370.53036029689,
              5011284.4711546581
            ],
            [
              353361.77020459372,
              5011293.6568295313
            ],
            [
              353353.37973352167,
              5011302.0677139107
            ],
            [
              353350.21392761572,
              5011307.405815254
            ],
            [
              353347.45891279966,
              5011306.1255939892
            ],
            [
              353337.39631504676,
              5011302.0895110602
            ],
            [
              353330.03802929539,
              5011303.5161355734
            ],
            [
              353311.57121496747,
              5011318.36329435
            ],
            [
              353308.79825826222,
              5011320.1249290872
            ],
            [
              353300.35596275044,
              5011326.066295675
            ],
            [
              353284.22613370832,
              5011341.0995018044
            ],
            [
              353273.28736426373,
              5011355.679408432
            ],
            [
              353261.09279523173,
              5011373.3205248816
            ],
            [
              353252.44901771849,
              5011381.7360791778
            ],
            [
              353247.44561169954,
              5011386.0573355481
            ],
            [
              353289.53887287929,
              5011420.452753555
            ],
            [
              353291.77746283315,
              5011423.2216185564
            ],
            [
              353310.0804884836,
              5011432.9781723283
            ],
            [
              353332.87516442541,
              5011447.2455251515
            ],
            [
              353339.01814745495,
              5011452.7522862321
            ],
            [
              353342.57487961143,
              5011448.0018772939
            ],
            [
              353346.90168754611,
              5011443.0457808813
            ],
            [
              353334.43349512859,
              5011430.2491654987
            ],
            [
              353329.18934303051,
              5011405.8845907459
            ],
            [
              353326.90040389769,
              5011400.4101870675
            ],
            [
              353319.10135324101,
              5011395.4547163034
            ],
            [
              353318.88784644083,
              5011395.1025115708
            ],
            [
              353313.04878494929,
              5011393.328655269
            ],
            [
              353311.05734900356,
              5011391.6950104972
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 301,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353414.86447178986,
              5011052.8139103279
            ],
            [
              353419.23323090433,
              5011054.4626827342
            ],
            [
              353415.39615876222,
              5011084.8901731987
            ],
            [
              353411.41417722119,
              5011083.8841366507
            ],
            [
              353411.53030240402,
              5011072.2933972692
            ],
            [
              353387.98161084478,
              5011062.27894588
            ],
            [
              353383.71420896694,
              5011075.0242444975
            ],
            [
              353405.20970942394,
              5011095.4526037797
            ],
            [
              353436.59525653842,
              5011114.5274865823
            ],
            [
              353425.89898030012,
              5011122.9346148334
            ],
            [
              353429.47513578512,
              5011126.635228009
            ],
            [
              353449.99452417146,
              5011148.2749117799
            ],
            [
              353456.8480827079,
              5011143.3600801351
            ],
            [
              353493.36914063658,
              5011160.5053961715
            ],
            [
              353499.32884698961,
              5011145.5385422613
            ],
            [
              353507.60490031465,
              5011134.0378070818
            ],
            [
              353503.37368916249,
              5011129.728202696
            ],
            [
              353504.0573651634,
              5011123.571786263
            ],
            [
              353481.08008741011,
              5011115.7014095215
            ],
            [
              353461.49781755998,
              5011114.3902409039
            ],
            [
              353448.65814070654,
              5011106.490799034
            ],
            [
              353445.2830053473,
              5011104.3061133791
            ],
            [
              353446.88789318939,
              5011100.2783200992
            ],
            [
              353447.72227910178,
              5011098.1842585681
            ],
            [
              353450.66347103933,
              5011093.376441556
            ],
            [
              353453.63093190541,
              5011088.5026975181
            ],
            [
              353453.99009382742,
              5011081.9771895716
            ],
            [
              353458.24018095661,
              5011070.5759530198
            ],
            [
              353452.9837402894,
              5011068.4779598946
            ],
            [
              353446.32723942434,
              5011045.6748008076
            ],
            [
              353447.05860554718,
              5011035.1251416029
            ],
            [
              353459.68546158966,
              5011005.8338556783
            ],
            [
              353451.60032304918,
              5010999.3275980232
            ],
            [
              353442.36914861004,
              5010992.0665030014
            ],
            [
              353428.98553450621,
              5010983.2513787514
            ],
            [
              353400.86616583326,
              5010960.0160232363
            ],
            [
              353394.13838198833,
              5010969.3321872018
            ],
            [
              353370.02069661656,
              5011016.4899139414
            ],
            [
              353388.38513700751,
              5011010.5617012465
            ],
            [
              353401.47352142172,
              5011013.11140115
            ],
            [
              353394.73577659234,
              5011034.8066758895
            ],
            [
              353392.74201411376,
              5011044.4648806714
            ],
            [
              353414.86447178986,
              5011052.8139103279
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 302,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352801.60981054517,
              5010209.5897532115
            ],
            [
              352803.98134912323,
              5010197.8338708887
            ],
            [
              352804.03762839362,
              5010187.600469959
            ],
            [
              352804.50320026377,
              5010165.5581293264
            ],
            [
              352803.22608557815,
              5010156.5823353659
            ],
            [
              352806.11201165058,
              5010152.5836413167
            ],
            [
              352802.26386539108,
              5010144.6419420457
            ],
            [
              352797.6161351215,
              5010138.7407219494
            ],
            [
              352789.32722184929,
              5010130.3215229828
            ],
            [
              352769.84762271208,
              5010122.1870633867
            ],
            [
              352758.21365424449,
              5010113.6074246671
            ],
            [
              352741.87100445927,
              5010097.4326785291
            ],
            [
              352733.54785591952,
              5010092.510522672
            ],
            [
              352724.22948994621,
              5010110.2741763638
            ],
            [
              352734.37261264742,
              5010117.5686037876
            ],
            [
              352732.35561230057,
              5010120.7179554831
            ],
            [
              352725.9901508636,
              5010131.8518113941
            ],
            [
              352723.77221304883,
              5010136.9230559776
            ],
            [
              352721.87930962763,
              5010144.8431412447
            ],
            [
              352713.84645502694,
              5010137.1077360455
            ],
            [
              352677.36599895771,
              5010164.8397330884
            ],
            [
              352675.23461089109,
              5010166.1473941905
            ],
            [
              352683.83097986749,
              5010176.9039620571
            ],
            [
              352684.94933475839,
              5010178.7611235734
            ],
            [
              352691.99832496227,
              5010190.4668109585
            ],
            [
              352694.81614600791,
              5010190.5625135833
            ],
            [
              352702.94240795332,
              5010198.5093445834
            ],
            [
              352708.86136896134,
              5010201.7963796491
            ],
            [
              352711.65419902932,
              5010204.2019696394
            ],
            [
              352716.46682095836,
              5010213.6111102765
            ],
            [
              352719.16235309799,
              5010237.9917521616
            ],
            [
              352720.60581972502,
              5010253.3437315123
            ],
            [
              352721.647815665,
              5010258.8176867254
            ],
            [
              352726.14433332166,
              5010271.1662457632
            ],
            [
              352741.43595184787,
              5010295.352788995
            ],
            [
              352749.99298276199,
              5010295.901573224
            ],
            [
              352757.15911208099,
              5010282.8616724899
            ],
            [
              352765.99492301251,
              5010266.0452975286
            ],
            [
              352773.98616970895,
              5010258.0065789325
            ],
            [
              352774.63424713531,
              5010253.063264424
            ],
            [
              352787.14420642849,
              5010234.2686775103
            ],
            [
              352796.6993961939,
              5010224.1050754748
            ],
            [
              352801.60981054517,
              5010209.5897532115
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 303,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352295.7805409102,
              5012656.7093664566
            ],
            [
              352304.41186569195,
              5012632.945728289
            ],
            [
              352324.76492036687,
              5012576.8881787378
            ],
            [
              352331.53323567781,
              5012565.5211458979
            ],
            [
              352338.35572231648,
              5012558.3436358189
            ],
            [
              352341.59136861429,
              5012554.4914235706
            ],
            [
              352360.9823578315,
              5012525.4255887661
            ],
            [
              352340.13115769689,
              5012514.9390029851
            ],
            [
              352322.376539185,
              5012501.1313087009
            ],
            [
              352307.5834945151,
              5012517.2276272997
            ],
            [
              352301.52778009186,
              5012512.6898874007
            ],
            [
              352291.4734110593,
              5012512.0085394215
            ],
            [
              352279.01236737851,
              5012505.0658344282
            ],
            [
              352265.80249717931,
              5012507.3353543105
            ],
            [
              352261.66838293109,
              5012514.9269442391
            ],
            [
              352260.31402188534,
              5012516.2149611553
            ],
            [
              352222.47342453955,
              5012543.8269037819
            ],
            [
              352207.29142669239,
              5012556.9657014506
            ],
            [
              352214.81709163619,
              5012584.8252153983
            ],
            [
              352224.7570146661,
              5012620.8495351747
            ],
            [
              352229.00569188205,
              5012636.0896905623
            ],
            [
              352231.74906237278,
              5012645.3940053629
            ],
            [
              352243.24782778003,
              5012662.1919412753
            ],
            [
              352245.79422082094,
              5012667.0989555996
            ],
            [
              352265.80358229537,
              5012681.1680770768
            ],
            [
              352264.37902158051,
              5012683.5286972485
            ],
            [
              352281.81981840875,
              5012696.7475582669
            ],
            [
              352283.67584888468,
              5012690.4614113644
            ],
            [
              352288.12072336482,
              5012679.0269080885
            ],
            [
              352295.7805409102,
              5012656.7093664566
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 304,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352808.38632215472,
              5012005.700525444
            ],
            [
              352829.11608391599,
              5011992.9597680625
            ],
            [
              352818.99776696385,
              5011976.4917356437
            ],
            [
              352810.92246264406,
              5011969.9365814915
            ],
            [
              352767.29355027183,
              5011944.3759319372
            ],
            [
              352736.79705070198,
              5011940.0067656627
            ],
            [
              352722.59806961782,
              5011935.7148440676
            ],
            [
              352705.32382128126,
              5011945.772415461
            ],
            [
              352711.85194826417,
              5011951.0763011947
            ],
            [
              352700.79073206085,
              5011966.4535234543
            ],
            [
              352739.31633775495,
              5012005.7090409799
            ],
            [
              352749.8551190315,
              5012018.6076958338
            ],
            [
              352753.85614117753,
              5012016.8918312173
            ],
            [
              352763.23047752923,
              5012027.8238983303
            ],
            [
              352771.49348780332,
              5012034.9055255847
            ],
            [
              352779.98577114689,
              5012024.7651121439
            ],
            [
              352786.02502007113,
              5012019.5173363043
            ],
            [
              352808.38632215472,
              5012005.700525444
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 305,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352980.36204052257,
              5011889.6692181407
            ],
            [
              353012.11868995766,
              5011867.5935277874
            ],
            [
              353020.67242707958,
              5011862.2172580119
            ],
            [
              353023.60901591653,
              5011857.6319844406
            ],
            [
              353030.26812178816,
              5011850.0943151657
            ],
            [
              353027.57335615432,
              5011847.0140966382
            ],
            [
              353037.19240143453,
              5011833.2399513032
            ],
            [
              353032.81364107929,
              5011818.3358982783
            ],
            [
              353023.28151975694,
              5011807.3153090086
            ],
            [
              353000.87636443606,
              5011792.653772492
            ],
            [
              352995.77963679587,
              5011795.7904501148
            ],
            [
              352989.50206068216,
              5011798.8870186703
            ],
            [
              352976.70670548663,
              5011805.5191927059
            ],
            [
              352932.8979193889,
              5011825.9113367293
            ],
            [
              352918.90759239375,
              5011834.8793857098
            ],
            [
              352894.88326600153,
              5011857.1975832945
            ],
            [
              352891.86870077183,
              5011855.5494508166
            ],
            [
              352885.30636578752,
              5011861.7784298519
            ],
            [
              352779.05962698412,
              5011918.6857320825
            ],
            [
              352760.15962440311,
              5011936.1438345956
            ],
            [
              352769.48076144868,
              5011939.2694534529
            ],
            [
              352796.99248368811,
              5011956.8085772684
            ],
            [
              352815.40610326157,
              5011965.5864444841
            ],
            [
              352824.12058196677,
              5011974.1648173239
            ],
            [
              352833.79629685666,
              5011988.4653120078
            ],
            [
              352956.00934679294,
              5011911.651349674
            ],
            [
              352980.36204052257,
              5011889.6692181407
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 306,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353346.90168754611,
              5011443.0457808813
            ],
            [
              353342.53709554428,
              5011448.0523420917
            ],
            [
              353339.01814745495,
              5011452.7522862321
            ],
            [
              353332.87516442541,
              5011447.2455251515
            ],
            [
              353316.0651356483,
              5011436.724006474
            ],
            [
              353306.38890145096,
              5011431.0103465104
            ],
            [
              353291.77746283315,
              5011423.2216185564
            ],
            [
              353286.0878997448,
              5011431.0357495518
            ],
            [
              353275.67308150226,
              5011442.9065552773
            ],
            [
              353269.40829342545,
              5011449.7603450157
            ],
            [
              353266.18821083882,
              5011455.2273586551
            ],
            [
              353258.99424050102,
              5011466.4599429891
            ],
            [
              353253.01371894195,
              5011475.716238196
            ],
            [
              353283.30728924141,
              5011491.7964359084
            ],
            [
              353298.22092788591,
              5011498.5441807387
            ],
            [
              353321.93896754849,
              5011507.5492328769
            ],
            [
              353341.43478707812,
              5011517.3099640189
            ],
            [
              353355.4615672651,
              5011495.1365725556
            ],
            [
              353373.42537817161,
              5011468.3747696392
            ],
            [
              353369.59473392618,
              5011459.7172035621
            ],
            [
              353351.44467352057,
              5011445.7479678914
            ],
            [
              353348.88604977931,
              5011444.0763966674
            ],
            [
              353346.90168754611,
              5011443.0457808813
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 307,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353209.07719439355,
              5011134.0902309138
            ],
            [
              353206.05743830901,
              5011132.573416179
            ],
            [
              353201.30802244542,
              5011130.2453112071
            ],
            [
              353181.52740047965,
              5011126.1415779786
            ],
            [
              353173.34614248754,
              5011146.3940936225
            ],
            [
              353178.65383459727,
              5011149.9304862525
            ],
            [
              353175.9767914323,
              5011155.5399535736
            ],
            [
              353173.19487999595,
              5011161.258276674
            ],
            [
              353166.21646470012,
              5011183.3496870007
            ],
            [
              353149.74736002897,
              5011177.8827046547
            ],
            [
              353140.94973293471,
              5011205.9943960523
            ],
            [
              353139.8131396856,
              5011208.153877045
            ],
            [
              353110.72884915507,
              5011193.1916508367
            ],
            [
              353106.94608012791,
              5011214.1107544508
            ],
            [
              353112.42825530778,
              5011221.2790642902
            ],
            [
              353108.99359302042,
              5011226.4931207532
            ],
            [
              353131.16375659028,
              5011244.1765021058
            ],
            [
              353122.50492606329,
              5011257.7545677982
            ],
            [
              353147.06554348505,
              5011278.9963684296
            ],
            [
              353153.98966217198,
              5011282.3944828678
            ],
            [
              353159.82735915523,
              5011288.6398814097
            ],
            [
              353174.65673166659,
              5011277.6233242871
            ],
            [
              353196.57783617434,
              5011264.4634148497
            ],
            [
              353211.37322426477,
              5011256.2020218
            ],
            [
              353232.36460083869,
              5011252.9128940115
            ],
            [
              353244.9387163354,
              5011233.5743226195
            ],
            [
              353241.86435924732,
              5011226.1481536319
            ],
            [
              353227.09058323089,
              5011221.1407126095
            ],
            [
              353201.97865381115,
              5011194.3974604197
            ],
            [
              353205.29241139558,
              5011186.7356756181
            ],
            [
              353240.91777914175,
              5011206.6621420691
            ],
            [
              353251.56994380133,
              5011195.7533954913
            ],
            [
              353253.53077289107,
              5011193.3901218874
            ],
            [
              353245.46001534624,
              5011177.7845531031
            ],
            [
              353243.02528025344,
              5011177.8298614779
            ],
            [
              353242.57107766793,
              5011167.9656343013
            ],
            [
              353223.64613711979,
              5011176.2294869274
            ],
            [
              353223.60877912905,
              5011163.3145237714
            ],
            [
              353217.0535471717,
              5011163.7069790214
            ],
            [
              353218.29684559116,
              5011155.1291486453
            ],
            [
              353202.39304721338,
              5011151.2325934004
            ],
            [
              353196.94697349815,
              5011145.7889868049
            ],
            [
              353208.43878145557,
              5011138.0015524048
            ],
            [
              353213.34695580357,
              5011136.3549309429
            ],
            [
              353209.07719439355,
              5011134.0902309138
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 308,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353383.05956699845,
              5011166.2565569077
            ],
            [
              353386.67032159207,
              5011152.5592998406
            ],
            [
              353379.06413640111,
              5011153.462795672
            ],
            [
              353369.35116204357,
              5011152.3373250272
            ],
            [
              353358.10055301141,
              5011148.1538975108
            ],
            [
              353346.35088442091,
              5011139.0445544096
            ],
            [
              353344.40780017199,
              5011133.2844514763
            ],
            [
              353341.97935079277,
              5011120.2238865569
            ],
            [
              353342.63550865464,
              5011111.5958683845
            ],
            [
              353346.95111586648,
              5011093.7985469904
            ],
            [
              353342.34573330724,
              5011092.7141854744
            ],
            [
              353354.10545602132,
              5011058.3981317934
            ],
            [
              353346.84226546536,
              5011055.7831207393
            ],
            [
              353341.70894961286,
              5011054.0576993218
            ],
            [
              353327.67495825799,
              5011048.8560159653
            ],
            [
              353302.21888995654,
              5011038.9127577236
            ],
            [
              353297.39827275608,
              5011035.2882063594
            ],
            [
              353294.35326412664,
              5011029.5575094335
            ],
            [
              353297.22677804687,
              5011012.1419888474
            ],
            [
              353305.85275228898,
              5011013.2373598348
            ],
            [
              353305.01364161615,
              5011010.1295518968
            ],
            [
              353311.21144250879,
              5010998.9260523971
            ],
            [
              353306.85931195266,
              5010996.5550139071
            ],
            [
              353321.6201520394,
              5010968.4518986819
            ],
            [
              353320.5902661978,
              5010967.8883186914
            ],
            [
              353331.04486387077,
              5010954.6643957226
            ],
            [
              353339.54804978182,
              5010942.9411891149
            ],
            [
              353343.32071260124,
              5010939.3018632233
            ],
            [
              353364.65150500339,
              5010923.3489050558
            ],
            [
              353355.84997567063,
              5010913.8154044468
            ],
            [
              353352.85265312687,
              5010912.0529751517
            ],
            [
              353348.54082966276,
              5010912.0658565238
            ],
            [
              353343.98971054592,
              5010913.6993990783
            ],
            [
              353333.46198665316,
              5010920.0234643901
            ],
            [
              353323.87239613908,
              5010929.1501615429
            ],
            [
              353315.2300123247,
              5010938.973577898
            ],
            [
              353306.31375551963,
              5010950.5388606964
            ],
            [
              353290.21865273983,
              5010947.3769078525
            ],
            [
              353269.12210212427,
              5010945.7416756824
            ],
            [
              353261.07201566471,
              5010964.2863560896
            ],
            [
              353278.8572840016,
              5010976.3696790626
            ],
            [
              353271.90379833127,
              5010992.363218314
            ],
            [
              353262.08705255488,
              5010986.9239944043
            ],
            [
              353258.90320986533,
              5010985.279627271
            ],
            [
              353249.40368988557,
              5011002.5852789329
            ],
            [
              353255.23302512016,
              5011004.7909472324
            ],
            [
              353252.22840123833,
              5011010.7066858076
            ],
            [
              353225.24062125495,
              5011003.4228892354
            ],
            [
              353219.86598474521,
              5011024.8657220211
            ],
            [
              353212.24383197213,
              5011064.1260030614
            ],
            [
              353209.39005926909,
              5011083.1402774323
            ],
            [
              353212.04070311837,
              5011092.7160443896
            ],
            [
              353209.19596045557,
              5011100.0872934591
            ],
            [
              353200.47229084483,
              5011116.1731987745
            ],
            [
              353199.07633942895,
              5011124.8307866473
            ],
            [
              353201.30802244542,
              5011130.2453112071
            ],
            [
              353203.30308627937,
              5011131.0276187845
            ],
            [
              353213.34695580357,
              5011136.3549309429
            ],
            [
              353208.43878145859,
              5011138.0015524048
            ],
            [
              353196.94697349815,
              5011145.7889868049
            ],
            [
              353202.39304721163,
              5011151.2325934004
            ],
            [
              353218.29684559116,
              5011155.1291486453
            ],
            [
              353217.0535471717,
              5011163.7069790186
            ],
            [
              353223.60877912905,
              5011163.3145237695
            ],
            [
              353223.64613712073,
              5011176.2294869246
            ],
            [
              353242.57107766793,
              5011167.9656343013
            ],
            [
              353243.02528025344,
              5011177.8298614779
            ],
            [
              353245.46001534624,
              5011177.7845531031
            ],
            [
              353253.53077289113,
              5011193.3901218893
            ],
            [
              353251.5699438075,
              5011195.7533954866
            ],
            [
              353240.91777914303,
              5011206.6621420681
            ],
            [
              353205.29241139558,
              5011186.7356756181
            ],
            [
              353201.97865381109,
              5011194.3974604178
            ],
            [
              353227.09058323014,
              5011221.1407126095
            ],
            [
              353241.86435924633,
              5011226.1481536329
            ],
            [
              353244.9387163354,
              5011233.5743226195
            ],
            [
              353232.36460083944,
              5011252.9128940124
            ],
            [
              353211.37322426407,
              5011256.2020218
            ],
            [
              353196.57783614477,
              5011264.4634148562
            ],
            [
              353174.65673165821,
              5011277.6233242899
            ],
            [
              353159.82735915523,
              5011288.6398814097
            ],
            [
              353153.98966217303,
              5011282.3944828697
            ],
            [
              353147.06554348505,
              5011278.9963684296
            ],
            [
              353122.50492606452,
              5011257.7545677992
            ],
            [
              353131.16375659202,
              5011244.1765021067
            ],
            [
              353108.99359302042,
              5011226.4931207532
            ],
            [
              353083.89705633029,
              5011257.7811106434
            ],
            [
              353075.85305036657,
              5011251.6948711425
            ],
            [
              353057.95565197727,
              5011275.4549290603
            ],
            [
              353045.54168623436,
              5011291.4624952404
            ],
            [
              353033.77488488046,
              5011279.8983929222
            ],
            [
              353032.27798611607,
              5011280.2425799649
            ],
            [
              353019.6045638661,
              5011304.993538606
            ],
            [
              353040.69920261489,
              5011324.6084519476
            ],
            [
              353041.46768236434,
              5011325.6663470222
            ],
            [
              353044.279381848,
              5011329.5904878238
            ],
            [
              353036.52705853054,
              5011333.5419994853
            ],
            [
              353030.91645106522,
              5011336.5322988546
            ],
            [
              353016.81710997416,
              5011344.4375748951
            ],
            [
              353007.12095308822,
              5011350.0107065728
            ],
            [
              352996.26863522868,
              5011358.7484585959
            ],
            [
              352979.29022546939,
              5011375.5267218025
            ],
            [
              352973.50660739752,
              5011384.2411810393
            ],
            [
              352971.08655545185,
              5011386.4037728142
            ],
            [
              352972.09186762548,
              5011396.358092322
            ],
            [
              352971.39230624569,
              5011398.1180392867
            ],
            [
              352966.14616394852,
              5011408.6405573469
            ],
            [
              352977.76462327346,
              5011417.7629582221
            ],
            [
              352983.92234666884,
              5011409.2304376559
            ],
            [
              353006.29414689861,
              5011423.4138971707
            ],
            [
              353012.12876933854,
              5011425.8043817412
            ],
            [
              353014.54990306991,
              5011421.5503969332
            ],
            [
              353023.16415648808,
              5011424.8098448999
            ],
            [
              353027.22450087883,
              5011416.7109594876
            ],
            [
              353017.0046948184,
              5011412.0345737161
            ],
            [
              352999.88564348174,
              5011404.3298330801
            ],
            [
              353001.40649117256,
              5011401.1410441929
            ],
            [
              353006.30742832227,
              5011395.7886613486
            ],
            [
              353016.87328729726,
              5011378.6409952715
            ],
            [
              353031.92752353544,
              5011383.1293342793
            ],
            [
              353037.8185968466,
              5011359.3416558225
            ],
            [
              353039.73460591794,
              5011353.9767215699
            ],
            [
              353042.23245607544,
              5011347.0715898713
            ],
            [
              353058.75620618201,
              5011356.3323334679
            ],
            [
              353060.71649891027,
              5011352.7850555917
            ],
            [
              353068.52029225294,
              5011357.293945103
            ],
            [
              353065.09552337992,
              5011364.2164323926
            ],
            [
              353080.76646022539,
              5011370.0486420784
            ],
            [
              353081.22961561318,
              5011375.1840795428
            ],
            [
              353077.15117113659,
              5011384.3376543196
            ],
            [
              353071.06973057747,
              5011395.6303358525
            ],
            [
              353063.3479448228,
              5011393.9107690929
            ],
            [
              353056.84120176715,
              5011389.1545426697
            ],
            [
              353049.49256939552,
              5011397.1535695726
            ],
            [
              353041.21919778973,
              5011418.5993235419
            ],
            [
              353039.86823163618,
              5011427.1475914149
            ],
            [
              353034.21202290454,
              5011433.8642339669
            ],
            [
              353037.30912109662,
              5011440.4943351019
            ],
            [
              353052.74769907276,
              5011447.2106470587
            ],
            [
              353057.46112161834,
              5011443.5132741379
            ],
            [
              353067.06180474954,
              5011436.5698216707
            ],
            [
              353075.63762171363,
              5011439.5412339633
            ],
            [
              353077.88375743176,
              5011435.7288592272
            ],
            [
              353082.61332020146,
              5011425.6901440518
            ],
            [
              353083.8702373355,
              5011426.7444734331
            ],
            [
              353088.83626928582,
              5011417.8448811127
            ],
            [
              353087.59569003049,
              5011416.8025060054
            ],
            [
              353090.52114483708,
              5011411.8914733976
            ],
            [
              353100.92350288079,
              5011399.0078919725
            ],
            [
              353106.86227655836,
              5011401.1086459793
            ],
            [
              353108.76592379279,
              5011391.1225218028
            ],
            [
              353122.63213342067,
              5011396.5154701751
            ],
            [
              353128.84580814,
              5011393.5743372506
            ],
            [
              353127.3548407582,
              5011386.1083497768
            ],
            [
              353121.34755801322,
              5011380.3234259281
            ],
            [
              353122.61069091002,
              5011378.6194483768
            ],
            [
              353130.17382785672,
              5011379.5434758402
            ],
            [
              353141.15967744571,
              5011373.5714681484
            ],
            [
              353156.94731105189,
              5011355.5989067145
            ],
            [
              353164.929653959,
              5011361.9553026874
            ],
            [
              353169.57145169185,
              5011354.6753000459
            ],
            [
              353173.65589799109,
              5011348.9477721527
            ],
            [
              353175.41063183761,
              5011344.8905584924
            ],
            [
              353185.18288069597,
              5011325.8055956773
            ],
            [
              353191.87221329368,
              5011327.6074991021
            ],
            [
              353203.43198820675,
              5011331.7875286667
            ],
            [
              353213.0215083544,
              5011314.1951000215
            ],
            [
              353214.58186403092,
              5011309.8006444443
            ],
            [
              353229.93976154347,
              5011316.0718486374
            ],
            [
              353239.85649806354,
              5011320.5999468118
            ],
            [
              353247.23518197099,
              5011308.1263259342
            ],
            [
              353249.42184487701,
              5011304.5046061333
            ],
            [
              353247.24303398753,
              5011301.8661018945
            ],
            [
              353254.17932421097,
              5011287.3306359733
            ],
            [
              353261.3717384492,
              5011279.9935966097
            ],
            [
              353270.15784068429,
              5011265.982033994
            ],
            [
              353274.04295131977,
              5011265.9271662859
            ],
            [
              353279.68406250578,
              5011266.2554248786
            ],
            [
              353275.35603595193,
              5011258.7856045505
            ],
            [
              353276.65618465102,
              5011258.631167884
            ],
            [
              353285.46087634756,
              5011257.1779320184
            ],
            [
              353305.15937621117,
              5011244.247297992
            ],
            [
              353307.83674536675,
              5011242.1765617579
            ],
            [
              353311.7858600347,
              5011242.7087898636
            ],
            [
              353314.23180219607,
              5011242.5831237538
            ],
            [
              353320.12560750387,
              5011244.4925282281
            ],
            [
              353325.01388910419,
              5011252.0740740895
            ],
            [
              353331.3169473678,
              5011237.263094876
            ],
            [
              353332.71878403367,
              5011231.1797548346
            ],
            [
              353332.0824996942,
              5011224.12480071
            ],
            [
              353337.15327480884,
              5011222.7869867599
            ],
            [
              353341.71268823853,
              5011221.7668985911
            ],
            [
              353359.47390315781,
              5011199.3527768496
            ],
            [
              353365.09739104955,
              5011190.955129588
            ],
            [
              353369.54700189451,
              5011186.1907054046
            ],
            [
              353388.80423099734,
              5011194.1436211793
            ],
            [
              353404.87278323283,
              5011199.9059978165
            ],
            [
              353408.13345176115,
              5011188.6999174757
            ],
            [
              353407.59601962793,
              5011179.7936037099
            ],
            [
              353395.2165915111,
              5011173.7082552966
            ],
            [
              353383.05956699845,
              5011166.2565569077
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 309,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352621.44305283256,
              5011801.2733668508
            ],
            [
              352615.37306457612,
              5011805.6217279574
            ],
            [
              352574.82675480982,
              5011837.6636973927
            ],
            [
              352609.49747588101,
              5011864.4802275617
            ],
            [
              352625.96728971467,
              5011860.7580648735
            ],
            [
              352650.68313387677,
              5011852.1005173735
            ],
            [
              352621.44305283256,
              5011801.2733668508
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 310,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352681.22552356508,
              5011760.560408935
            ],
            [
              352695.5755232409,
              5011788.2798497435
            ],
            [
              352681.22552356508,
              5011760.560408935
            ],
            [
              352679.67102395702,
              5011757.20629548
            ],
            [
              352658.11668061343,
              5011768.6801990345
            ],
            [
              352649.37545271096,
              5011753.5639699204
            ],
            [
              352640.03427856887,
              5011742.4707871974
            ],
            [
              352652.13170252758,
              5011734.5724375295
            ],
            [
              352655.05284562177,
              5011729.6616069963
            ],
            [
              352629.40232839284,
              5011700.5767989652
            ],
            [
              352624.36532082717,
              5011701.4475901145
            ],
            [
              352615.39453516557,
              5011710.2590696765
            ],
            [
              352614.33321510424,
              5011710.6673297565
            ],
            [
              352604.3657093331,
              5011718.1375319026
            ],
            [
              352600.97331351478,
              5011714.9981906693
            ],
            [
              352581.52420999255,
              5011696.9998851484
            ],
            [
              352577.58716653596,
              5011693.3330619121
            ],
            [
              352570.76579397311,
              5011708.528441865
            ],
            [
              352556.56233442778,
              5011722.0493090553
            ],
            [
              352563.16148163844,
              5011727.49359449
            ],
            [
              352561.68933819706,
              5011729.868852688
            ],
            [
              352574.62818318902,
              5011731.716254266
            ],
            [
              352587.17143143952,
              5011729.4222696042
            ],
            [
              352588.9861609239,
              5011732.4382481035
            ],
            [
              352559.47287732741,
              5011765.4074848751
            ],
            [
              352537.26392896037,
              5011783.9404483652
            ],
            [
              352528.23881700216,
              5011774.2130817883
            ],
            [
              352496.46103369893,
              5011794.237618234
            ],
            [
              352494.74585611577,
              5011797.0457198629
            ],
            [
              352501.68592045823,
              5011800.7818232886
            ],
            [
              352512.20802480925,
              5011806.0066045411
            ],
            [
              352541.90713968448,
              5011814.7162665781
            ],
            [
              352543.78275066079,
              5011815.8576237764
            ],
            [
              352574.82675480982,
              5011837.6636973927
            ],
            [
              352578.14550148597,
              5011835.0410372894
            ],
            [
              352598.31160347682,
              5011819.1046512565
            ],
            [
              352621.44305283256,
              5011801.2733668508
            ],
            [
              352650.68313387677,
              5011852.1005173735
            ],
            [
              352686.31766405381,
              5011839.1192783415
            ],
            [
              352699.57798367745,
              5011837.836657444
            ],
            [
              352716.52895983914,
              5011837.4270021515
            ],
            [
              352695.5755232409,
              5011788.2798497435
            ],
            [
              352681.22552356508,
              5011760.560408935
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 311,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353075.63762171363,
              5011439.5412339633
            ],
            [
              353080.31427471078,
              5011442.0758524295
            ],
            [
              353111.65422258567,
              5011459.0054556876
            ],
            [
              353114.77113795251,
              5011449.1981658908
            ],
            [
              353117.71792898915,
              5011437.9603711162
            ],
            [
              353120.14176375855,
              5011429.4563550744
            ],
            [
              353120.75210217288,
              5011423.7101343283
            ],
            [
              353122.67907675158,
              5011411.6310447715
            ],
            [
              353128.22846619523,
              5011401.4917892478
            ],
            [
              353137.98044626159,
              5011409.0523438752
            ],
            [
              353152.13581987453,
              5011421.9791148864
            ],
            [
              353171.44848669023,
              5011407.7319842065
            ],
            [
              353177.42289718572,
              5011398.4556758851
            ],
            [
              353182.26713137433,
              5011415.0843219748
            ],
            [
              353193.67530443397,
              5011399.966117017
            ],
            [
              353191.18639889063,
              5011396.1852395367
            ],
            [
              353190.3683920289,
              5011384.7189114979
            ],
            [
              353203.08177823765,
              5011367.1271357797
            ],
            [
              353214.10747890052,
              5011372.7961837398
            ],
            [
              353232.8489488904,
              5011354.0909164185
            ],
            [
              353238.20697466907,
              5011364.1355906334
            ],
            [
              353252.75252741872,
              5011381.4405830912
            ],
            [
              353261.09279523249,
              5011373.3205248807
            ],
            [
              353273.28736423986,
              5011355.679408472
            ],
            [
              353284.22613371606,
              5011341.0995017905
            ],
            [
              353300.35596275667,
              5011326.066295662
            ],
            [
              353308.79825826728,
              5011320.1249290891
            ],
            [
              353311.5712149748,
              5011318.3632943435
            ],
            [
              353330.03802929813,
              5011303.5161355734
            ],
            [
              353337.39631504676,
              5011302.0895110602
            ],
            [
              353347.45891281328,
              5011306.1255939938
            ],
            [
              353350.21392761479,
              5011307.405815254
            ],
            [
              353353.37973352469,
              5011302.0677139107
            ],
            [
              353361.77020460909,
              5011293.6568294922
            ],
            [
              353370.53036029596,
              5011284.4711546591
            ],
            [
              353375.98272276489,
              5011283.3138449602
            ],
            [
              353381.8615329687,
              5011279.2690337105
            ],
            [
              353385.96944292274,
              5011271.5844178386
            ],
            [
              353391.30752549611,
              5011264.2861483721
            ],
            [
              353392.7225226686,
              5011262.916039167
            ],
            [
              353394.90694874321,
              5011259.1440232657
            ],
            [
              353407.19507781608,
              5011264.7821280519
            ],
            [
              353413.12239724275,
              5011269.0363065945
            ],
            [
              353418.25461771368,
              5011272.5378165012
            ],
            [
              353422.87734904216,
              5011269.6903927634
            ],
            [
              353429.05711911304,
              5011253.4317807248
            ],
            [
              353431.79884269816,
              5011246.5834680852
            ],
            [
              353437.87331815058,
              5011241.7972841784
            ],
            [
              353445.14191546926,
              5011228.2880208651
            ],
            [
              353437.89647792152,
              5011222.238488893
            ],
            [
              353439.83009834227,
              5011219.7642073305
            ],
            [
              353450.70301826164,
              5011209.2394813867
            ],
            [
              353456.08676487365,
              5011204.0213034665
            ],
            [
              353464.64572822809,
              5011196.1233776435
            ],
            [
              353465.48451936356,
              5011195.0981107121
            ],
            [
              353468.03848313639,
              5011192.2949072653
            ],
            [
              353471.04575022799,
              5011188.9521169597
            ],
            [
              353482.87425838603,
              5011174.6777937226
            ],
            [
              353490.29139881872,
              5011164.6742317807
            ],
            [
              353493.36914063658,
              5011160.5053961715
            ],
            [
              353456.84808270948,
              5011143.3600801351
            ],
            [
              353449.99452417146,
              5011148.2749117799
            ],
            [
              353429.47513578332,
              5011126.6352280043
            ],
            [
              353425.89898030012,
              5011122.9346148334
            ],
            [
              353436.39601586759,
              5011114.4063960621
            ],
            [
              353405.20970943029,
              5011095.4526037863
            ],
            [
              353383.71420896816,
              5011075.0242444975
            ],
            [
              353387.98161084612,
              5011062.2789458819
            ],
            [
              353411.53030240413,
              5011072.2933972832
            ],
            [
              353411.41417722119,
              5011083.8841366507
            ],
            [
              353415.39615874999,
              5011084.8901731949
            ],
            [
              353419.23323090508,
              5011054.4626827342
            ],
            [
              353392.74201411376,
              5011044.4648806714
            ],
            [
              353401.47352142172,
              5011013.11140115
            ],
            [
              353388.38513700751,
              5011010.5617012465
            ],
            [
              353370.02069661656,
              5011016.4899139414
            ],
            [
              353394.13838198833,
              5010969.3321872018
            ],
            [
              353397.5022738956,
              5010964.674105172
            ],
            [
              353400.86616583326,
              5010960.0160232363
            ],
            [
              353365.60546076199,
              5010922.8539393526
            ],
            [
              353364.65150500776,
              5010923.3489050502
            ],
            [
              353343.32071260171,
              5010939.301863214
            ],
            [
              353339.54804978421,
              5010942.9411891103
            ],
            [
              353331.04486387532,
              5010954.6643957039
            ],
            [
              353320.59026619903,
              5010967.8883186914
            ],
            [
              353321.6201520394,
              5010968.4518986819
            ],
            [
              353306.85931195266,
              5010996.5550139071
            ],
            [
              353311.21144250879,
              5010998.9260523971
            ],
            [
              353305.01364161615,
              5011010.1295518968
            ],
            [
              353305.85275228898,
              5011013.2373598348
            ],
            [
              353297.22677804687,
              5011012.1419888502
            ],
            [
              353294.35326412664,
              5011029.5575094344
            ],
            [
              353297.39827275608,
              5011035.2882063594
            ],
            [
              353302.21888995502,
              5011038.9127577217
            ],
            [
              353327.67495823873,
              5011048.8560159653
            ],
            [
              353341.70894960465,
              5011054.0576993227
            ],
            [
              353346.84226544847,
              5011055.7831207309
            ],
            [
              353354.10545602051,
              5011058.3981317924
            ],
            [
              353342.34573330724,
              5011092.7141854754
            ],
            [
              353346.95111586747,
              5011093.7985469904
            ],
            [
              353342.63550865528,
              5011111.5958683798
            ],
            [
              353341.97935079277,
              5011120.2238865569
            ],
            [
              353344.40780017199,
              5011133.2844514754
            ],
            [
              353346.35088441893,
              5011139.0445544114
            ],
            [
              353358.10055301437,
              5011148.1538975108
            ],
            [
              353369.35116205114,
              5011152.33732503
            ],
            [
              353379.06413640111,
              5011153.462795672
            ],
            [
              353386.67032159405,
              5011152.5592998406
            ],
            [
              353383.05956699845,
              5011166.2565569077
            ],
            [
              353394.16620078328,
              5011173.0644139741
            ],
            [
              353407.59601962793,
              5011179.7936037099
            ],
            [
              353408.13345176115,
              5011188.6999174757
            ],
            [
              353404.87278323283,
              5011199.9059978165
            ],
            [
              353385.9993285911,
              5011193.1562721021
            ],
            [
              353369.54700189451,
              5011186.1907054046
            ],
            [
              353367.32219647168,
              5011188.5729174828
            ],
            [
              353364.31415770686,
              5011192.1247448372
            ],
            [
              353358.05762934405,
              5011201.1400719108
            ],
            [
              353341.71268823853,
              5011221.7668985911
            ],
            [
              353332.08249969414,
              5011224.1248007081
            ],
            [
              353332.71878403361,
              5011231.1797548337
            ],
            [
              353331.3169473671,
              5011237.2630948797
            ],
            [
              353325.01388910291,
              5011252.0740740877
            ],
            [
              353320.12560750387,
              5011244.4925282281
            ],
            [
              353314.23180219455,
              5011242.5831237538
            ],
            [
              353311.78586003667,
              5011242.7087898636
            ],
            [
              353307.83674536779,
              5011242.1765617579
            ],
            [
              353305.15937621763,
              5011244.2472979883
            ],
            [
              353285.46087634651,
              5011257.1779320166
            ],
            [
              353276.65618465102,
              5011258.631167884
            ],
            [
              353275.35603595193,
              5011258.7856045505
            ],
            [
              353279.78123744234,
              5011266.4231408797
            ],
            [
              353274.04295131774,
              5011265.9271662859
            ],
            [
              353270.15784068429,
              5011265.982033994
            ],
            [
              353261.37173845054,
              5011279.9935966134
            ],
            [
              353254.17932421097,
              5011287.3306359733
            ],
            [
              353247.24303397723,
              5011301.8661018824
            ],
            [
              353249.42184487701,
              5011304.5046061352
            ],
            [
              353247.23518204136,
              5011308.1263258355
            ],
            [
              353239.85649806354,
              5011320.5999468118
            ],
            [
              353229.93976156396,
              5011316.0718486477
            ],
            [
              353214.58186403173,
              5011309.8006444471
            ],
            [
              353213.0215083551,
              5011314.1951000197
            ],
            [
              353203.4319882057,
              5011331.7875286667
            ],
            [
              353191.87221329141,
              5011327.6074991021
            ],
            [
              353185.1828806969,
              5011325.8055956755
            ],
            [
              353175.41063183144,
              5011344.8905584775
            ],
            [
              353173.65589799243,
              5011348.9477721443
            ],
            [
              353169.57145168987,
              5011354.6753000459
            ],
            [
              353164.92965395976,
              5011361.9553026864
            ],
            [
              353156.94731105334,
              5011355.5989067135
            ],
            [
              353141.15967744665,
              5011373.5714681456
            ],
            [
              353130.17382785777,
              5011379.5434758402
            ],
            [
              353122.61069091002,
              5011378.6194483768
            ],
            [
              353121.34755801322,
              5011380.3234259272
            ],
            [
              353127.3548407575,
              5011386.1083497768
            ],
            [
              353128.84580814,
              5011393.5743372506
            ],
            [
              353122.63213342067,
              5011396.5154701751
            ],
            [
              353108.76592379279,
              5011391.1225218028
            ],
            [
              353106.86227655737,
              5011401.1086459793
            ],
            [
              353100.9235028798,
              5011399.0078919725
            ],
            [
              353090.52114483149,
              5011411.8914734079
            ],
            [
              353087.59569003148,
              5011416.8025060073
            ],
            [
              353088.83626928687,
              5011417.8448811136
            ],
            [
              353083.87023733481,
              5011426.7444734322
            ],
            [
              353082.61332020146,
              5011425.6901440518
            ],
            [
              353075.63762171363,
              5011439.5412339633
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 312,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353037.30912109662,
              5011440.4943351019
            ],
            [
              353034.0080742155,
              5011433.4276315775
            ],
            [
              353039.86823164084,
              5011427.1475914186
            ],
            [
              353041.21919778973,
              5011418.5993235419
            ],
            [
              353049.49256939878,
              5011397.1535695642
            ],
            [
              353056.84120176715,
              5011389.1545426697
            ],
            [
              353063.34794482199,
              5011393.9107690929
            ],
            [
              353071.06973057747,
              5011395.6303358525
            ],
            [
              353077.15117113327,
              5011384.3376543242
            ],
            [
              353081.22961561242,
              5011375.1840795418
            ],
            [
              353080.7664602247,
              5011370.0486420784
            ],
            [
              353065.09552337992,
              5011364.2164323926
            ],
            [
              353068.52029225294,
              5011357.2939451057
            ],
            [
              353060.71649891027,
              5011352.7850555917
            ],
            [
              353058.75620618201,
              5011356.3323334679
            ],
            [
              353042.23245607544,
              5011347.0715898713
            ],
            [
              353039.73460591567,
              5011353.9767216016
            ],
            [
              353037.8185968459,
              5011359.3416558243
            ],
            [
              353031.92752353643,
              5011383.1293342784
            ],
            [
              353016.87328729633,
              5011378.6409952724
            ],
            [
              353006.30742831825,
              5011395.7886613468
            ],
            [
              353001.40649117337,
              5011401.1410441883
            ],
            [
              352999.88564348296,
              5011404.3298330801
            ],
            [
              353017.00469477917,
              5011412.0345737142
            ],
            [
              353027.22450087982,
              5011416.7109594876
            ],
            [
              353023.16415648808,
              5011424.8098448999
            ],
            [
              353014.54990307061,
              5011421.5503969332
            ],
            [
              353012.12876933959,
              5011425.8043817412
            ],
            [
              353006.29414689861,
              5011423.4138971707
            ],
            [
              352983.92234666966,
              5011409.2304376559
            ],
            [
              352977.76462327247,
              5011417.7629582221
            ],
            [
              352966.14616394747,
              5011408.640557345
            ],
            [
              352971.39230624406,
              5011398.1180392997
            ],
            [
              352972.09186762577,
              5011396.358092322
            ],
            [
              352971.08655545185,
              5011386.4037728142
            ],
            [
              352944.85440614686,
              5011404.7839191929
            ],
            [
              352940.22202841629,
              5011418.6141029727
            ],
            [
              352932.14579900645,
              5011428.6035741502
            ],
            [
              352940.26468922471,
              5011437.4025080893
            ],
            [
              352950.1927951242,
              5011441.1881534094
            ],
            [
              352969.60707623401,
              5011456.6833985038
            ],
            [
              352977.23237161542,
              5011462.8357411111
            ],
            [
              352981.36248850246,
              5011458.6898927605
            ],
            [
              352986.20950952882,
              5011463.3573442968
            ],
            [
              352997.97620978171,
              5011472.8605824448
            ],
            [
              352999.08909227181,
              5011471.4953035284
            ],
            [
              353004.16992078867,
              5011477.6064079823
            ],
            [
              353016.8488738375,
              5011491.6434494061
            ],
            [
              353027.68652843876,
              5011501.2674117442
            ],
            [
              353032.56443378428,
              5011499.7572041592
            ],
            [
              353037.53366291703,
              5011499.8715990549
            ],
            [
              353039.34802383877,
              5011502.8372088494
            ],
            [
              353043.16588798963,
              5011502.2490460156
            ],
            [
              353054.79613850144,
              5011482.1745007047
            ],
            [
              353067.32849772181,
              5011460.5317628551
            ],
            [
              353059.0310509635,
              5011446.0214101607
            ],
            [
              353057.46112161834,
              5011443.5132741379
            ],
            [
              353052.74769907276,
              5011447.2106470587
            ],
            [
              353042.15647507069,
              5011442.6031006947
            ],
            [
              353037.30912109662,
              5011440.4943351019
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 313,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352603.62660030025,
              5010733.6216326421
            ],
            [
              352623.75696379185,
              5010714.5565885054
            ],
            [
              352618.97136927833,
              5010708.6137100859
            ],
            [
              352630.42461272626,
              5010702.5945759621
            ],
            [
              352630.16421949182,
              5010693.1692488575
            ],
            [
              352639.28461728134,
              5010694.5287602199
            ],
            [
              352641.21925465483,
              5010684.2979617026
            ],
            [
              352640.11793432693,
              5010679.0996974371
            ],
            [
              352638.90946459927,
              5010659.837014379
            ],
            [
              352632.36877506407,
              5010646.3656771295
            ],
            [
              352641.1054781797,
              5010640.8303855965
            ],
            [
              352634.24650737963,
              5010632.6768914424
            ],
            [
              352638.52262528619,
              5010629.2454315033
            ],
            [
              352642.16683635337,
              5010623.6568511175
            ],
            [
              352634.42774977913,
              5010615.9140601801
            ],
            [
              352641.17802336981,
              5010601.7593398811
            ],
            [
              352636.51621544739,
              5010600.3673346415
            ],
            [
              352635.85818764759,
              5010591.5069165947
            ],
            [
              352638.24558686401,
              5010587.2912530331
            ],
            [
              352629.72050442017,
              5010569.113084428
            ],
            [
              352634.22644292546,
              5010562.7822197843
            ],
            [
              352641.89272223337,
              5010550.7108188514
            ],
            [
              352645.13052146678,
              5010544.9100011401
            ],
            [
              352619.92603988352,
              5010526.9505629633
            ],
            [
              352604.93808788975,
              5010518.3654474476
            ],
            [
              352602.2652952561,
              5010527.453707628
            ],
            [
              352593.5461053563,
              5010547.3162700376
            ],
            [
              352583.95689403993,
              5010561.6132533373
            ],
            [
              352573.0648669728,
              5010558.8608769318
            ],
            [
              352567.10488896206,
              5010556.3450884372
            ],
            [
              352556.7016363212,
              5010551.0643120389
            ],
            [
              352559.09101323172,
              5010534.7939060722
            ],
            [
              352562.19010938244,
              5010521.4988164632
            ],
            [
              352564.16517303913,
              5010518.0374732837
            ],
            [
              352541.30529840611,
              5010521.479728993
            ],
            [
              352524.12172110734,
              5010521.2150878729
            ],
            [
              352521.35804661579,
              5010521.7557352018
            ],
            [
              352501.59532252135,
              5010520.6557966014
            ],
            [
              352484.49294539978,
              5010526.2742128605
            ],
            [
              352461.21006299375,
              5010525.3946879152
            ],
            [
              352447.93800778623,
              5010528.0796202291
            ],
            [
              352370.5739799522,
              5010534.8184291031
            ],
            [
              352321.68976077012,
              5010589.7307421798
            ],
            [
              352336.06729540584,
              5010602.6866216883
            ],
            [
              352343.39511061285,
              5010606.8977066148
            ],
            [
              352342.85285562841,
              5010610.5808603885
            ],
            [
              352347.73811722751,
              5010611.3637339985
            ],
            [
              352347.7079330018,
              5010609.7415823694
            ],
            [
              352361.94626812753,
              5010609.7886911705
            ],
            [
              352365.387673777,
              5010610.1615432343
            ],
            [
              352366.17196912604,
              5010612.1931068674
            ],
            [
              352368.38046817464,
              5010630.0146799656
            ],
            [
              352377.54466704495,
              5010643.2616708884
            ],
            [
              352356.52495194727,
              5010664.0181251699
            ],
            [
              352353.08199959033,
              5010669.5868499577
            ],
            [
              352352.53582447616,
              5010676.3057707557
            ],
            [
              352356.59248950687,
              5010683.034887217
            ],
            [
              352346.9322655609,
              5010698.4553205865
            ],
            [
              352339.05407398479,
              5010711.7702108249
            ],
            [
              352327.38033638633,
              5010721.8842167826
            ],
            [
              352333.05606871034,
              5010725.8789123874
            ],
            [
              352326.73428248719,
              5010733.8029032545
            ],
            [
              352330.22817014856,
              5010739.2575207772
            ],
            [
              352326.23261314718,
              5010745.9972170088
            ],
            [
              352315.42884718155,
              5010741.5042464491
            ],
            [
              352310.93013139849,
              5010735.4402731406
            ],
            [
              352305.51423189486,
              5010752.0391127253
            ],
            [
              352304.66977754148,
              5010760.5793922488
            ],
            [
              352296.284642738,
              5010790.4362679366
            ],
            [
              352298.51032803941,
              5010799.372716465
            ],
            [
              352298.72395723307,
              5010809.1514407415
            ],
            [
              352294.96720090922,
              5010833.5352777243
            ],
            [
              352294.78080403374,
              5010867.2518043313
            ],
            [
              352303.83185805765,
              5010875.2173126303
            ],
            [
              352301.19848507975,
              5010896.2512002755
            ],
            [
              352306.40278110583,
              5010898.606422415
            ],
            [
              352325.53662026889,
              5010904.1010130579
            ],
            [
              352430.30398690043,
              5010939.5483579049
            ],
            [
              352437.57128359366,
              5010945.0582798971
            ],
            [
              352440.76879539364,
              5010946.9944729554
            ],
            [
              352461.6679041037,
              5010911.058156251
            ],
            [
              352488.61858538823,
              5010875.7926908648
            ],
            [
              352513.31786197051,
              5010849.9067260725
            ],
            [
              352525.4008348682,
              5010832.5479672737
            ],
            [
              352535.28808390786,
              5010829.4084118372
            ],
            [
              352546.19798640459,
              5010815.6786114797
            ],
            [
              352545.37025212392,
              5010812.3972349847
            ],
            [
              352558.64307078725,
              5010792.3109679949
            ],
            [
              352561.10034405341,
              5010791.0252855904
            ],
            [
              352571.65951993491,
              5010776.5952786803
            ],
            [
              352573.99168135534,
              5010778.590855143
            ],
            [
              352579.90927564516,
              5010772.2788947215
            ],
            [
              352590.78838811367,
              5010767.658708211
            ],
            [
              352587.42077647371,
              5010764.832846283
            ],
            [
              352593.7316090783,
              5010756.814462781
            ],
            [
              352602.01658533962,
              5010740.6884434791
            ],
            [
              352603.62660030025,
              5010733.6216326421
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 314,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352675.00322312035,
              5011650.7753352355
            ],
            [
              352686.92466101208,
              5011631.688762622
            ],
            [
              352690.62477120809,
              5011626.936301088
            ],
            [
              352690.97894342797,
              5011624.5380868455
            ],
            [
              352702.48007501097,
              5011602.0794835193
            ],
            [
              352707.73693212616,
              5011598.9055185523
            ],
            [
              352709.38746954215,
              5011597.4508778332
            ],
            [
              352707.94868251873,
              5011595.3103631111
            ],
            [
              352698.03795900819,
              5011581.442408287
            ],
            [
              352700.8368332031,
              5011573.9796398468
            ],
            [
              352694.92302986403,
              5011571.922406421
            ],
            [
              352677.07886887185,
              5011560.2295420067
            ],
            [
              352676.21436491125,
              5011559.5606272174
            ],
            [
              352674.58983013028,
              5011561.3370858002
            ],
            [
              352668.13872442133,
              5011572.0074108001
            ],
            [
              352663.98775225581,
              5011579.0191457598
            ],
            [
              352654.49609584629,
              5011592.6616320545
            ],
            [
              352647.56647947285,
              5011601.8717427189
            ],
            [
              352639.49000380788,
              5011613.2984467726
            ],
            [
              352632.82602538093,
              5011622.1226122184
            ],
            [
              352624.62285500776,
              5011632.7158274371
            ],
            [
              352612.68170618598,
              5011650.1866059871
            ],
            [
              352646.4642021766,
              5011682.6211674092
            ],
            [
              352651.76873320283,
              5011683.7182583204
            ],
            [
              352665.74976009241,
              5011663.5278467983
            ],
            [
              352675.00322312035,
              5011650.7753352355
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 315,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351658.12457084679,
              5010808.8313969467
            ],
            [
              351662.1990462314,
              5010809.385794702
            ],
            [
              351664.54016580136,
              5010796.1908572093
            ],
            [
              351664.22078021854,
              5010791.4645064464
            ],
            [
              351660.38238604442,
              5010777.4698384777
            ],
            [
              351658.37945624435,
              5010772.3813044075
            ],
            [
              351655.31674985611,
              5010768.0277267527
            ],
            [
              351651.97576317593,
              5010761.5336841252
            ],
            [
              351645.20826104569,
              5010756.7722426523
            ],
            [
              351640.96221185225,
              5010752.9174866071
            ],
            [
              351636.35849235958,
              5010743.4210140249
            ],
            [
              351636.35849235835,
              5010743.4210140249
            ],
            [
              351636.07365370897,
              5010743.3600109639
            ],
            [
              351636.35849235835,
              5010743.4210140249
            ],
            [
              351680.66459692398,
              5010662.0559172798
            ],
            [
              351690.46274922008,
              5010658.2869881457
            ],
            [
              351693.80059615889,
              5010654.4589383695
            ],
            [
              351696.61698823766,
              5010651.5372519018
            ],
            [
              351710.58983699227,
              5010641.3698579036
            ],
            [
              351721.42513448803,
              5010633.9394189045
            ],
            [
              351730.18761834013,
              5010629.0402194727
            ],
            [
              351758.96275192319,
              5010612.8750481894
            ],
            [
              351778.84461549466,
              5010602.4096782031
            ],
            [
              351782.33604016359,
              5010600.2364981389
            ],
            [
              351786.14563776326,
              5010597.159593611
            ],
            [
              351807.84739531402,
              5010586.8889234411
            ],
            [
              351810.74709476531,
              5010585.6175627569
            ],
            [
              351890.83390481223,
              5010629.7166946288
            ],
            [
              351902.32816934219,
              5010625.9663857436
            ],
            [
              351917.40121325443,
              5010624.3920818092
            ],
            [
              351928.7746950718,
              5010628.0619095825
            ],
            [
              351929.57841818052,
              5010629.5132525712
            ],
            [
              351936.65409917926,
              5010629.4678575406
            ],
            [
              351949.52941384102,
              5010635.0073364815
            ],
            [
              351957.4242511362,
              5010634.7071864316
            ],
            [
              351971.28027841687,
              5010637.2957544243
            ],
            [
              351967.36983910814,
              5010644.9924450656
            ],
            [
              351982.38390126178,
              5010644.8855688609
            ],
            [
              351989.56508891965,
              5010645.873274982
            ],
            [
              351996.28286230628,
              5010645.1444745054
            ],
            [
              351997.85116115998,
              5010651.8624010291
            ],
            [
              352006.32911519153,
              5010648.9872419508
            ],
            [
              352016.73374336289,
              5010648.8873230629
            ],
            [
              352017.50749897823,
              5010645.1247714264
            ],
            [
              352027.25782813557,
              5010645.130779664
            ],
            [
              352033.82517269131,
              5010645.3833890576
            ],
            [
              352048.24949740892,
              5010652.2471708637
            ],
            [
              352066.54264749394,
              5010657.9038040917
            ],
            [
              352080.76189774828,
              5010656.2336555161
            ],
            [
              352093.12027568539,
              5010650.2877951274
            ],
            [
              352090.57552623784,
              5010644.5255276253
            ],
            [
              352100.1449596684,
              5010639.8496861598
            ],
            [
              352110.16552679631,
              5010636.1704408815
            ],
            [
              352147.38848574454,
              5010621.234890474
            ],
            [
              352147.48914765083,
              5010621.6078293761
            ],
            [
              352163.24719143164,
              5010611.9442463033
            ],
            [
              352160.42133764952,
              5010608.4838407487
            ],
            [
              352169.74412853591,
              5010600.6266890271
            ],
            [
              352171.33559292252,
              5010600.5033565266
            ],
            [
              352169.03753966879,
              5010597.9224338792
            ],
            [
              352193.95006001461,
              5010581.4355502222
            ],
            [
              352192.39651008521,
              5010578.5596490288
            ],
            [
              352181.52327797661,
              5010578.6682853317
            ],
            [
              352174.55348348449,
              5010566.7693427149
            ],
            [
              352163.90458392847,
              5010566.0165029485
            ],
            [
              352161.43407186092,
              5010561.3599000098
            ],
            [
              352158.43742346764,
              5010556.7363748616
            ],
            [
              352154.99050396431,
              5010559.5110117402
            ],
            [
              352148.61830838054,
              5010563.9925662288
            ],
            [
              352141.31790748786,
              5010568.4737678235
            ],
            [
              352136.71691014903,
              5010571.2880184641
            ],
            [
              352132.51018118887,
              5010573.4882427435
            ],
            [
              352125.9000431762,
              5010577.047194453
            ],
            [
              352118.73384910013,
              5010580.4651349969
            ],
            [
              352109.3001688908,
              5010584.4808064895
            ],
            [
              352105.38159168401,
              5010585.867308151
            ],
            [
              352102.56745513657,
              5010586.8294976829
            ],
            [
              352098.94376662682,
              5010587.8063316634
            ],
            [
              352095.31710040726,
              5010588.5811307719
            ],
            [
              352091.38646438438,
              5010589.3615860054
            ],
            [
              352087.35204832919,
              5010589.9418820282
            ],
            [
              352083.71708240482,
              5010590.3126550037
            ],
            [
              352079.37305785116,
              5010590.5955766169
            ],
            [
              352075.97220652003,
              5010590.4412543848
            ],
            [
              352072.52913067775,
              5010590.2021858403
            ],
            [
              352068.67109576165,
              5010589.4651935585
            ],
            [
              352063.90052579582,
              5010588.5435101781
            ],
            [
              352057.28858766356,
              5010586.5445931721
            ],
            [
              352050.06838529336,
              5010584.4555299031
            ],
            [
              352044.06282371172,
              5010582.4453296168
            ],
            [
              352034.78837098315,
              5010578.6767226486
            ],
            [
              352029.61541296402,
              5010575.7902588751
            ],
            [
              352014.09807419439,
              5010568.0955892829
            ],
            [
              352005.12305016996,
              5010564.1193196476
            ],
            [
              351992.27496176475,
              5010558.5983944954
            ],
            [
              351985.03409111669,
              5010555.3986370675
            ],
            [
              351980.04233083513,
              5010553.4701976255
            ],
            [
              351974.13647243712,
              5010551.3570959764
            ],
            [
              351957.06637849414,
              5010545.3671574378
            ],
            [
              351951.67360467749,
              5010543.6482698666
            ],
            [
              351951.06158912694,
              5010543.3565222649
            ],
            [
              351950.44691263168,
              5010542.963778981
            ],
            [
              351949.72845613206,
              5010542.3708763784
            ],
            [
              351949.11377963459,
              5010541.9781330945
            ],
            [
              351948.50552314246,
              5010541.8884059303
            ],
            [
              351947.79568287899,
              5010541.8005689681
            ],
            [
              351946.57650894433,
              5010541.5201189555
            ],
            [
              351944.64827614912,
              5010541.1518174391
            ],
            [
              351941.08669948205,
              5010540.0055465121
            ],
            [
              351932.034523974,
              5010537.3442984438
            ],
            [
              351917.57908599282,
              5010532.3585055387
            ],
            [
              351906.31549801119,
              5010527.4692907305
            ],
            [
              351900.80125412944,
              5010524.6415848304
            ],
            [
              351893.34217029664,
              5010520.6371052116
            ],
            [
              351884.03388274781,
              5010515.0503106257
            ],
            [
              351877.89179756586,
              5010511.1223701052
            ],
            [
              351873.86948101298,
              5010507.9000376575
            ],
            [
              351868.21417525882,
              5010502.9525871044
            ],
            [
              351862.13231652812,
              5010496.8001114465
            ],
            [
              351858.52187170595,
              5010493.0275733499
            ],
            [
              351857.48628070875,
              5010491.7328356477
            ],
            [
              351855.40383722429,
              5010488.5381369898
            ],
            [
              351854.250528217,
              5010486.3361826707
            ],
            [
              351853.81004507717,
              5010484.4245196078
            ],
            [
              351853.24807711568,
              5010481.4032003144
            ],
            [
              351852.98742603284,
              5010478.2756484179
            ],
            [
              351853.261316018,
              5010476.6534092873
            ],
            [
              351853.83010291745,
              5010474.6219213586
            ],
            [
              351855.50410507619,
              5010472.1656876737
            ],
            [
              351857.96793046984,
              5010468.8025409589
            ],
            [
              351862.22737222683,
              5010463.9737404585
            ],
            [
              351867.39340206428,
              5010458.925980675
            ],
            [
              351872.54925295914,
              5010453.3731843662
            ],
            [
              351875.1242047167,
              5010450.3949608132
            ],
            [
              351876.50174720003,
              5010448.348005224
            ],
            [
              351877.96772476047,
              5010445.5925069097
            ],
            [
              351878.93187883002,
              5010443.0480172234
            ],
            [
              351879.49768829677,
              5010440.8144944785
            ],
            [
              351879.55337485234,
              5010438.3879539166
            ],
            [
              351879.51954384672,
              5010436.5697695622
            ],
            [
              351879.07983463182,
              5010434.6576729063
            ],
            [
              351877.81555256149,
              5010431.9529766431
            ],
            [
              351875.63151816366,
              5010428.7597488491
            ],
            [
              351870.75357529934,
              5010423.9160681376
            ],
            [
              351865.71670619416,
              5010419.5633802479
            ],
            [
              351858.52587460674,
              5010413.6336281402
            ],
            [
              351849.80517006182,
              5010407.0254447581
            ],
            [
              351844.17617821862,
              5010403.4921357334
            ],
            [
              351839.26643201936,
              5010400.5521342708
            ],
            [
              351834.8791681563,
              5010398.5113987084
            ],
            [
              351826.51616483351,
              5010394.827289694
            ],
            [
              351812.61811572505,
              5010389.3820746299
            ],
            [
              351805.9016047279,
              5010387.1825883212
            ],
            [
              351799.29530123842,
              5010385.4866967658
            ],
            [
              351795.23378719465,
              5010384.6528617181
            ],
            [
              351790.05844152754,
              5010383.7387063308
            ],
            [
              351786.71240639238,
              5010383.2957393741
            ],
            [
              351784.98576987692,
              5010382.9232664397
            ],
            [
              351782.74980732624,
              5010382.3585992521
            ],
            [
              351780.01093080227,
              5010381.904334954
            ],
            [
              351777.98675187241,
              5010381.8409533389
            ],
            [
              351775.55780047277,
              5010381.7851032726
            ],
            [
              351773.33875322511,
              5010382.1295285234
            ],
            [
              351771.22316927166,
              5010382.5730738966
            ],
            [
              351769.41641240322,
              5010383.3140087053
            ],
            [
              351767.81956750166,
              5010384.4556379784
            ],
            [
              351766.39214157517,
              5010386.4263755418
            ],
            [
              351765.4054251365,
              5010387.7583234692
            ],
            [
              351764.80954334937,
              5010388.3756824574
            ],
            [
              351764.41307023948,
              5010388.7872405741
            ],
            [
              351764.01017725497,
              5010388.8957824074
            ],
            [
              351763.50460237003,
              5010388.9051895812
            ],
            [
              351762.99150954024,
              5010388.510555652
            ],
            [
              351761.75541969284,
              5010387.3210124588
            ],
            [
              351761.13400650426,
              5010386.5242130375
            ],
            [
              351760.62091367174,
              5010386.1295790989
            ],
            [
              351760.10970032512,
              5010385.8359554382
            ],
            [
              351759.60036646418,
              5010385.6433420554
            ],
            [
              351759.09479157411,
              5010385.6527492246
            ],
            [
              351757.88501452439,
              5010385.8773498982
            ],
            [
              351755.87963032484,
              5010386.8240710869
            ],
            [
              351750.27200913709,
              5010389.8587237271
            ],
            [
              351741.84758694319,
              5010393.7545697046
            ],
            [
              351736.33889631578,
              5010396.6867558034
            ],
            [
              351732.73477971694,
              5010398.6736771567
            ],
            [
              351729.03847670963,
              5010401.1675401432
            ],
            [
              351721.60083743365,
              5010406.8017876158
            ],
            [
              351715.12422504358,
              5010412.076025187
            ],
            [
              351711.84585094248,
              5010415.2694288967
            ],
            [
              351708.57688211818,
              5010418.9683032567
            ],
            [
              351708.28872939193,
              5010419.7820270229
            ],
            [
              351707.90275182034,
              5010420.7996615358
            ],
            [
              351706.91604314657,
              5010422.1320289169
            ],
            [
              351701.99750461493,
              5010429.6002717707
            ],
            [
              351698.74169235589,
              5010434.0062184362
            ],
            [
              351693.58881614666,
              5010439.7610524055
            ],
            [
              351691.12563352188,
              5010443.2428433588
            ],
            [
              351688.73358761525,
              5010446.5987860467
            ],
            [
              351686.67815518292,
              5010450.2750806008
            ],
            [
              351684.81868132675,
              5010453.6441738969
            ],
            [
              351683.53818153444,
              5010455.4872344155
            ],
            [
              351682.45442170819,
              5010457.0230790973
            ],
            [
              351680.76538254152,
              5010458.671231552
            ],
            [
              351678.37778742559,
              5010460.8380277576
            ],
            [
              351676.08988884866,
              5010462.9015043816
            ],
            [
              351673.40850255056,
              5010465.5789936231
            ],
            [
              351670.93325376586,
              5010468.4543185569
            ],
            [
              351668.86090585677,
              5010471.2215207024
            ],
            [
              351667.18878230284,
              5010473.7787660258
            ],
            [
              351666.41009855102,
              5010475.4103987971
            ],
            [
              351665.54376116063,
              5010477.7505602213
            ],
            [
              351664.57208090223,
              5010479.8905912377
            ],
            [
              351662.60241500201,
              5010482.756928158
            ],
            [
              351660.71850762999,
              5010484.8128878912
            ],
            [
              351658.50066034263,
              5010486.9442417044
            ],
            [
              351655.51231852331,
              5010489.4249330126
            ],
            [
              351651.72007784777,
              5010492.2241377803
            ],
            [
              351646.43444764178,
              5010496.2636737907
            ],
            [
              351640.64888090431,
              5010500.6156482073
            ],
            [
              351636.56362262141,
              5010503.9251126116
            ],
            [
              351632.38806539431,
              5010507.8429483054
            ],
            [
              351629.11721729621,
              5010511.4408140853
            ],
            [
              351626.64948667825,
              5010514.720180979
            ],
            [
              351624.78140471847,
              5010517.5846282188
            ],
            [
              351622.1961806491,
              5010522.1533558676
            ],
            [
              351620.74524664617,
              5010525.717358225
            ],
            [
              351619.29807173222,
              5010529.4833812462
            ],
            [
              351618.43549365242,
              5010532.0255637635
            ],
            [
              351617.46570092993,
              5010534.2670246689
            ],
            [
              351616.28035738348,
              5010535.8047600975
            ],
            [
              351614.59319780406,
              5010537.5539235743
            ],
            [
              351611.70080904331,
              5010539.730113606
            ],
            [
              351607.60537171696,
              5010542.5345416907
            ],
            [
              351603.91471482144,
              5010545.3318572259
            ],
            [
              351600.52614077757,
              5010548.02208729
            ],
            [
              351598.63284358248,
              5010549.5734152636
            ],
            [
              351596.64625426853,
              5010551.530241088
            ],
            [
              351594.96740203182,
              5010553.6838506293
            ],
            [
              351593.19369486155,
              5010556.2429871084
            ],
            [
              351588.15246186854,
              5010564.0908649797
            ],
            [
              351580.8118783097,
              5010560.9940044899
            ],
            [
              351579.44372522045,
              5010563.5455948059
            ],
            [
              351586.16963945655,
              5010566.2501307297
            ],
            [
              351582.60875952075,
              5010570.5607114034
            ],
            [
              351577.67330656463,
              5010577.1198661011
            ],
            [
              351572.65772619913,
              5010584.7920103027
            ],
            [
              351570.41228362679,
              5010589.1791596785
            ],
            [
              351568.1661369708,
              5010594.4526698347
            ],
            [
              351567.02778225759,
              5010598.5156639973
            ],
            [
              351566.38827376574,
              5010602.165613954
            ],
            [
              351566.25355906278,
              5010605.8061709618
            ],
            [
              351566.41074809962,
              5010608.8346052533
            ],
            [
              351567.06490377279,
              5010611.3489849595
            ],
            [
              351567.90937927511,
              5010613.2531321635
            ],
            [
              351569.15111706028,
              5010614.746125333
            ],
            [
              351572.76078484644,
              5010618.5186798396
            ],
            [
              351574.8188048235,
              5010620.4006651649
            ],
            [
              351576.76849631016,
              5010621.8800657066
            ],
            [
              351578.40106067312,
              5010622.6580500789
            ],
            [
              351579.82638143335,
              5010623.136754835
            ],
            [
              351581.95512314216,
              5010623.4002800249
            ],
            [
              351585.49414912041,
              5010623.3344273465
            ],
            [
              351589.83817987592,
              5010623.0515048839
            ],
            [
              351595.3863493739,
              5010622.240949844
            ],
            [
              351600.21903911396,
              5010621.0395269766
            ],
            [
              351602.22442356759,
              5010620.0928039923
            ],
            [
              351605.62692752952,
              5010618.1092118127
            ],
            [
              351609.05902599072,
              5010615.1955361431
            ],
            [
              351611.74041161762,
              5010612.5180452382
            ],
            [
              351613.02279818332,
              5010610.7764132023
            ],
            [
              351614.00387553265,
              5010609.1414330946
            ],
            [
              351614.57719475642,
              5010607.3115305072
            ],
            [
              351614.94171539828,
              5010605.1827965453
            ],
            [
              351615.00758779619,
              5010603.2617102768
            ],
            [
              351614.76384356577,
              5010601.0428300491
            ],
            [
              351614.19811508275,
              5010597.819488219
            ],
            [
              351613.85357623332,
              5010595.6029027933
            ],
            [
              351613.60796030279,
              5010593.2834313614
            ],
            [
              351613.36045704241,
              5010590.8625304829
            ],
            [
              351613.20513993438,
              5010587.9346877839
            ],
            [
              351613.35411755764,
              5010585.1026474973
            ],
            [
              351613.62048904557,
              5010583.0763658797
            ],
            [
              351613.97749166156,
              5010580.543590663
            ],
            [
              351614.33464836545,
              5010577.641011972
            ],
            [
              351614.88463183108,
              5010574.599000155
            ],
            [
              351615.53808653197,
              5010571.6565275816
            ],
            [
              351616.10310637433,
              5010569.4225983303
            ],
            [
              351616.87506084511,
              5010567.3873284273
            ],
            [
              351617.94001718651,
              5010564.8409607979
            ],
            [
              351619.69790644239,
              5010561.4737564605
            ],
            [
              351621.65441516577,
              5010557.9003465176
            ],
            [
              351623.82082837721,
              5010554.7272119997
            ],
            [
              351626.09369046014,
              5010551.85565193
            ],
            [
              351628.86836076051,
              5010548.7722447226
            ],
            [
              351630.85307037085,
              5010546.7144087851
            ],
            [
              351632.84529811697,
              5010545.0606141239
            ],
            [
              351634.98136713437,
              5010543.6594814835
            ],
            [
              351636.98487206799,
              5010542.6117487326
            ],
            [
              351640.99751270685,
              5010540.8188950736
            ],
            [
              351645.72376105812,
              5010539.3163182465
            ],
            [
              351650.14791872486,
              5010537.920407841
            ],
            [
              351650.59028195933,
              5010539.9330822714
            ],
            [
              351659.06467843446,
              5010538.7649436099
            ],
            [
              351660.7167454717,
              5010546.0103016524
            ],
            [
              351656.93843567383,
              5010549.5161454752
            ],
            [
              351658.39195683255,
              5010551.5104241436
            ],
            [
              351664.16060682089,
              5010546.2493563658
            ],
            [
              351661.83935638762,
              5010535.6819559345
            ],
            [
              351662.02857816889,
              5010534.9706988065
            ],
            [
              351662.52851460507,
              5010534.6582604973
            ],
            [
              351663.53590559709,
              5010534.4374251012
            ],
            [
              351665.35018081154,
              5010534.1005304875
            ],
            [
              351668.17184699437,
              5010533.5428004842
            ],
            [
              351671.3964062038,
              5010532.8765284633
            ],
            [
              351675.73291783727,
              5010532.1895660153
            ],
            [
              351680.37903813756,
              5010531.7999784183
            ],
            [
              351682.80720881437,
              5010531.8558419785
            ],
            [
              351684.43303576804,
              5010532.2297708066
            ],
            [
              351686.15888373315,
              5010532.6018385105
            ],
            [
              351687.37993811397,
              5010532.9832990579
            ],
            [
              351689.1028084206,
              5010533.1533316206
            ],
            [
              351691.63068335148,
              5010533.1062946729
            ],
            [
              351695.16594916419,
              5010532.8384223562
            ],
            [
              351698.1648092856,
              5010532.6874462292
            ],
            [
              351700.9007168088,
              5010532.9400934335
            ],
            [
              351703.84761901508,
              5010533.6936216429
            ],
            [
              351706.39053007599,
              5010534.4546670299
            ],
            [
              351708.94095924159,
              5010535.6197535722
            ],
            [
              351712.51946187607,
              5010537.675537046
            ],
            [
              351716.61856788612,
              5010540.5295762597
            ],
            [
              351721.44819726184,
              5010544.5829847138
            ],
            [
              351725.1613338461,
              5010548.4550775113
            ],
            [
              351727.03011521482,
              5010551.0474811699
            ],
            [
              351728.3903518052,
              5010553.4476762377
            ],
            [
              351729.2404651941,
              5010555.6548535516
            ],
            [
              351729.58501146658,
              5010557.8718574066
            ],
            [
              351730.06308592652,
              5010561.8037267569
            ],
            [
              351730.08462416293,
              5010567.3302081106
            ],
            [
              351729.84831751493,
              5010570.9722344596
            ],
            [
              351729.39913661551,
              5010574.0123695787
            ],
            [
              351728.45831902517,
              5010577.7689696178
            ],
            [
              351726.72785002046,
              5010582.6517609237
            ],
            [
              351725.57148164767,
              5010585.7046357784
            ],
            [
              351723.90500565676,
              5010588.5653312188
            ],
            [
              351721.93721274921,
              5010591.5322594205
            ],
            [
              351719.36978706805,
              5010594.9149456359
            ],
            [
              351715.51511792169,
              5010599.7362179551
            ],
            [
              351712.71065061487,
              5010602.8985836739
            ],
            [
              351709.42851916846,
              5010605.8899686355
            ],
            [
              351705.65209786635,
              5010609.4972420326
            ],
            [
              351702.36245599965,
              5010612.0850050785
            ],
            [
              351696.96583857195,
              5010615.620961952
            ],
            [
              351692.86367387505,
              5010618.0217536455
            ],
            [
              351689.3584809879,
              5010619.9057915779
            ],
            [
              351685.74872653052,
              5010621.5896846447
            ],
            [
              351683.23589581688,
              5010622.445223758
            ],
            [
              351680.71741862094,
              5010622.9973128596
            ],
            [
              351677.94759097014,
              5010623.3582775621
            ],
            [
              351674.91414094885,
              5010623.4147226978
            ],
            [
              351672.08307718678,
              5010623.4674019162
            ],
            [
              351669.55332257214,
              5010623.4134292295
            ],
            [
              351667.83609079424,
              5010623.5464280192
            ],
            [
              351666.72789743572,
              5010623.7691393597
            ],
            [
              351665.62456132803,
              5010624.2948961509
            ],
            [
              351663.62105681287,
              5010625.3426292883
            ],
            [
              351660.81255544332,
              5010626.6078513106
            ],
            [
              351657.29420537321,
              5010627.7848173678
            ],
            [
              351653.87743912841,
              5010628.959893221
            ],
            [
              351650.45425276161,
              5010629.8319527134
            ],
            [
              351647.03936601355,
              5010631.1080389172
            ],
            [
              351643.11179039249,
              5010626.6340838755
            ],
            [
              351640.82201302412,
              5010628.5965514993
            ],
            [
              351644.0228395501,
              5010632.0739960456
            ],
            [
              351641.31889181217,
              5010633.5389439939
            ],
            [
              351638.91813281068,
              5010634.9982503522
            ],
            [
              351633.77726956917,
              5010638.5839550076
            ],
            [
              351629.19771484175,
              5010642.508890545
            ],
            [
              351624.43191624008,
              5010647.3471186254
            ],
            [
              351619.98214662902,
              5010652.7857380267
            ],
            [
              351616.33284122864,
              5010657.8052816261
            ],
            [
              351612.50371216319,
              5010664.0411342252
            ],
            [
              351609.15720013669,
              5010669.6219036719
            ],
            [
              351606.41557383951,
              5010674.5230932012
            ],
            [
              351604.76491601189,
              5010678.1918580858
            ],
            [
              351603.70105862978,
              5010680.83925102
            ],
            [
              351602.95056153525,
              5010683.9856202453
            ],
            [
              351602.50326104969,
              5010687.1267669518
            ],
            [
              351602.38358387147,
              5010691.575406177
            ],
            [
              351602.38804556138,
              5010697.2342790142
            ],
            [
              351602.48391189956,
              5010702.3862245297
            ],
            [
              351602.63959024841,
              5010705.2914197026
            ],
            [
              351602.87768861599,
              5010707.2068498377
            ],
            [
              351603.22223599424,
              5010709.4238543492
            ],
            [
              351603.76540274796,
              5010711.4346531145
            ],
            [
              351604.70770341408,
              5010713.1348889722
            ],
            [
              351606.79391747242,
              5010716.5320283491
            ],
            [
              351609.70205252169,
              5010720.621190561
            ],
            [
              351611.77698130644,
              5010723.4118488058
            ],
            [
              351613.75299503544,
              5010726.3058121856
            ],
            [
              351616.359039545,
              5010730.5016407892
            ],
            [
              351618.55623551417,
              5010734.4019412687
            ],
            [
              351621.58772821369,
              5010739.7013512533
            ],
            [
              351625.17386164382,
              5010746.2839039946
            ],
            [
              351627.24896046333,
              5010750.4707902297
            ],
            [
              351628.32009548874,
              5010752.2884956971
            ],
            [
              351630.61543306458,
              5010756.0859244019
            ],
            [
              351632.38154226466,
              5010758.5796121806
            ],
            [
              351634.75965997233,
              5010761.3646282833
            ],
            [
              351636.82519874425,
              5010763.6506536556
            ],
            [
              351639.29174313229,
              5010765.7267072462
            ],
            [
              351642.77805419284,
              5010768.2890114207
            ],
            [
              351645.65423634893,
              5010770.6609975304
            ],
            [
              351648.3269262152,
              5010772.935305628
            ],
            [
              351650.39434458356,
              5010775.3223411469
            ],
            [
              351652.16154420812,
              5010777.9166343994
            ],
            [
              351655.21756646381,
              5010782.9799544774
            ],
            [
              351656.39154588309,
              5010786.2926032897
            ],
            [
              351657.06371731375,
              5010789.8170995824
            ],
            [
              351657.663280437,
              5010794.8586261682
            ],
            [
              351658.07737381512,
              5010800.8130114926
            ],
            [
              351658.18452714349,
              5010806.5714370953
            ],
            [
              351658.12457084679,
              5010808.8313969467
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 316,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356053.84994326567,
              5008567.2835577987
            ],
            [
              356036.40603027988,
              5008577.3725342406
            ],
            [
              356029.20729530329,
              5008582.7167308126
            ],
            [
              356036.20490694512,
              5008590.7934158584
            ],
            [
              356035.41079434124,
              5008597.7778352657
            ],
            [
              356042.42163170158,
              5008611.429196286
            ],
            [
              356039.85889629397,
              5008621.8593717441
            ],
            [
              356038.90030493255,
              5008628.9474297501
            ],
            [
              356058.63043408544,
              5008628.8285213625
            ],
            [
              356071.74160154042,
              5008626.4759621099
            ],
            [
              356080.5839619452,
              5008621.4739094647
            ],
            [
              356075.85255136044,
              5008607.2973842407
            ],
            [
              356082.60035814048,
              5008605.0517048556
            ],
            [
              356086.65206725564,
              5008603.368685781
            ],
            [
              356087.8565274931,
              5008605.2584549552
            ],
            [
              356089.36302450811,
              5008606.917496467
            ],
            [
              356091.12822240457,
              5008608.2980863303
            ],
            [
              356093.10134349792,
              5008609.3605105104
            ],
            [
              356095.22562898597,
              5008610.0742073171
            ],
            [
              356097.43997167336,
              5008610.4186465535
            ],
            [
              356099.68067378335,
              5008610.3839200893
            ],
            [
              356101.8832792826,
              5008609.9710268723
            ],
            [
              356103.9844280337,
              5008609.1918441998
            ],
            [
              356105.92367839959,
              5008608.068786039
            ],
            [
              356107.64524591673,
              5008606.6341582816
            ],
            [
              356109.09960798558,
              5008604.929229429
            ],
            [
              356110.24492843851,
              5008603.0030434607
            ],
            [
              356111.04826099495,
              5008600.9110090258
            ],
            [
              356111.48649700003,
              5008598.7133055842
            ],
            [
              356111.54703016061,
              5008596.4731522566
            ],
            [
              356111.22811917966,
              5008594.2549892785
            ],
            [
              356104.82494199736,
              5008575.9480287861
            ],
            [
              356119.20870773186,
              5008570.9812076623
            ],
            [
              356114.32701756648,
              5008561.3024868136
            ],
            [
              356111.62347020529,
              5008562.8022994576
            ],
            [
              356107.6144477856,
              5008553.4783517634
            ],
            [
              356103.88731944136,
              5008546.0041264016
            ],
            [
              356098.50498727994,
              5008549.3195139058
            ],
            [
              356095.284051086,
              5008542.4541944675
            ],
            [
              356091.3613253815,
              5008545.3926928416
            ],
            [
              356074.1046471122,
              5008542.2510754308
            ],
            [
              356068.30805117876,
              5008549.9024568917
            ],
            [
              356063.32613170362,
              5008548.1401615832
            ],
            [
              356052.59813087096,
              5008553.7473786296
            ],
            [
              356057.9121905503,
              5008566.4439226072
            ],
            [
              356053.84994326567,
              5008567.2835577987
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 317,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354824.01752351347,
              5008569.1362361647
            ],
            [
              354818.03332371876,
              5008565.9506234629
            ],
            [
              354815.97975863493,
              5008530.3482080204
            ],
            [
              354812.06402794016,
              5008498.0342403809
            ],
            [
              354791.01404957287,
              5008481.8948393017
            ],
            [
              354796.42129186064,
              5008455.1659824532
            ],
            [
              354818.07882312906,
              5008385.4969690964
            ],
            [
              354793.91443390195,
              5008366.0005625905
            ],
            [
              354781.35007031,
              5008381.0097044399
            ],
            [
              354749.65622388909,
              5008354.4783408483
            ],
            [
              354728.11844050494,
              5008368.7603590349
            ],
            [
              354709.66933339293,
              5008383.1943439208
            ],
            [
              354665.56869567337,
              5008421.7470150394
            ],
            [
              354629.19350502203,
              5008444.7414990915
            ],
            [
              354580.02706438769,
              5008468.6461743796
            ],
            [
              354549.28274242446,
              5008487.9522789512
            ],
            [
              354510.80934569298,
              5008521.7766558528
            ],
            [
              354511.03444175137,
              5008588.6648161253
            ],
            [
              354518.72647765552,
              5008600.4061589772
            ],
            [
              354523.26811722893,
              5008616.2807750609
            ],
            [
              354532.86825923598,
              5008621.0257114461
            ],
            [
              354576.94475898281,
              5008619.4273896767
            ],
            [
              354576.77493711514,
              5008631.7025704291
            ],
            [
              354579.84256835532,
              5008649.659159408
            ],
            [
              354580.09204052371,
              5008652.1844961727
            ],
            [
              354589.14226194017,
              5008670.2665791344
            ],
            [
              354589.2509999327,
              5008691.4182696026
            ],
            [
              354630.46019427711,
              5008716.7491137814
            ],
            [
              354625.97895627253,
              5008721.5200541159
            ],
            [
              354650.13666176092,
              5008751.0059742592
            ],
            [
              354664.75767111179,
              5008728.882173378
            ],
            [
              354673.11658625904,
              5008713.5740995733
            ],
            [
              354676.9934624852,
              5008707.0919003785
            ],
            [
              354685.77432062157,
              5008709.3887214512
            ],
            [
              354693.8370837323,
              5008706.163510792
            ],
            [
              354701.30921650393,
              5008724.821845511
            ],
            [
              354709.7605045854,
              5008741.3456976907
            ],
            [
              354732.51318852603,
              5008732.7758594798
            ],
            [
              354751.86197369761,
              5008732.0019689575
            ],
            [
              354761.15715245629,
              5008736.9528508354
            ],
            [
              354787.52926953731,
              5008676.1919882465
            ],
            [
              354809.06704491895,
              5008631.684383898
            ],
            [
              354815.32333608781,
              5008621.0915214401
            ],
            [
              354817.95429270522,
              5008608.7683023429
            ],
            [
              354814.3073489535,
              5008600.4134925101
            ],
            [
              354818.76423268428,
              5008590.7203760631
            ],
            [
              354826.17685883812,
              5008575.4705125475
            ],
            [
              354826.92379371304,
              5008573.6339444937
            ],
            [
              354824.90430841054,
              5008569.5628449842
            ],
            [
              354824.01752351347,
              5008569.1362361647
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 318,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356257.72182264499,
              5008087.6290278146
            ],
            [
              356261.63154568849,
              5008080.9888146464
            ],
            [
              356265.37421772716,
              5008075.1450477857
            ],
            [
              356270.23713732383,
              5008064.7342107035
            ],
            [
              356272.93056545703,
              5008056.710326883
            ],
            [
              356274.79012060858,
              5008043.2943952689
            ],
            [
              356278.4120082361,
              5008023.774686154
            ],
            [
              356281.83379124774,
              5008013.157478095
            ],
            [
              356280.35615618207,
              5008002.7365395529
            ],
            [
              356269.96777242998,
              5007995.6292462368
            ],
            [
              356252.14194999845,
              5008010.9290573802
            ],
            [
              356241.53451842937,
              5008021.7273093471
            ],
            [
              356237.5373972379,
              5008025.2826417806
            ],
            [
              356223.82951350411,
              5008043.5227038804
            ],
            [
              356216.33837473841,
              5008052.7126301425
            ],
            [
              356221.02132794703,
              5008056.639664134
            ],
            [
              356220.08827703545,
              5008058.2856735913
            ],
            [
              356235.66461315315,
              5008070.2906176541
            ],
            [
              356244.09564528108,
              5008076.0500143664
            ],
            [
              356245.36767662922,
              5008074.8246089518
            ],
            [
              356249.3986772545,
              5008082.6995822899
            ],
            [
              356257.72182264499,
              5008087.6290278146
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 319,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356180.99588094518,
              5008007.0610670866
            ],
            [
              356183.1643571622,
              5008004.5911651468
            ],
            [
              356195.98899753863,
              5007990.7867986839
            ],
            [
              356208.28089811426,
              5007977.4327524649
            ],
            [
              356196.88227662223,
              5007966.1849642145
            ],
            [
              356193.14223164466,
              5007970.0639191661
            ],
            [
              356174.67578139936,
              5007981.3398142001
            ],
            [
              356160.63657899498,
              5007986.9624747615
            ],
            [
              356143.70173139614,
              5007999.2565967934
            ],
            [
              356142.40205563529,
              5007988.8135780254
            ],
            [
              356143.320767014,
              5007988.1568342149
            ],
            [
              356138.84922001429,
              5007979.2265481595
            ],
            [
              356132.34747668525,
              5007970.6248296862
            ],
            [
              356118.40723340499,
              5007980.6652975501
            ],
            [
              356114.31860055315,
              5007981.9345973087
            ],
            [
              356101.49019925314,
              5007984.0293877367
            ],
            [
              356101.16882059432,
              5007988.1454736069
            ],
            [
              356084.78958111646,
              5007991.8973340252
            ],
            [
              356074.18098471191,
              5007994.6348069701
            ],
            [
              356060.47960377799,
              5008013.9818157637
            ],
            [
              356055.14906675258,
              5008020.4169133725
            ],
            [
              356045.68777361483,
              5008030.4153951732
            ],
            [
              356035.67423974833,
              5008020.4187058322
            ],
            [
              356027.15607166418,
              5008008.4116600072
            ],
            [
              356037.09981712821,
              5008000.1163852764
            ],
            [
              356026.57904382539,
              5007991.9313866394
            ],
            [
              355996.26402168075,
              5007992.6457318347
            ],
            [
              355991.34539238625,
              5007994.8098367639
            ],
            [
              355989.14541778411,
              5007992.8682464911
            ],
            [
              355984.49517141859,
              5007990.0710692499
            ],
            [
              355988.52048984048,
              5007983.5079470072
            ],
            [
              355976.96808025189,
              5007973.1793732587
            ],
            [
              355962.8855591637,
              5007953.4809291726
            ],
            [
              355972.93122678337,
              5007945.8145588096
            ],
            [
              355989.14093758835,
              5007939.9259394305
            ],
            [
              355998.2467243509,
              5007930.2044324931
            ],
            [
              355994.61524343787,
              5007923.1084465226
            ],
            [
              356020.88061847573,
              5007910.0939976666
            ],
            [
              356023.21462153265,
              5007908.2191592762
            ],
            [
              356042.2542150474,
              5007889.6618772885
            ],
            [
              356057.45233946812,
              5007882.7106961738
            ],
            [
              356054.97203086672,
              5007878.9251476564
            ],
            [
              356057.18504840886,
              5007877.4545466872
            ],
            [
              356047.07742388832,
              5007866.2685899707
            ],
            [
              356053.79425122024,
              5007859.3008317323
            ],
            [
              356056.99176646589,
              5007857.1145171765
            ],
            [
              356063.19145684567,
              5007852.1907072673
            ],
            [
              356072.19157543703,
              5007848.6018793695
            ],
            [
              356079.6417016635,
              5007846.2440217789
            ],
            [
              356077.97749912797,
              5007845.0357299289
            ],
            [
              356083.22863539384,
              5007843.8284006258
            ],
            [
              356084.20813687774,
              5007841.7758201733
            ],
            [
              356086.86444532516,
              5007840.3393461844
            ],
            [
              356087.5713495161,
              5007833.5758172981
            ],
            [
              356079.05190385162,
              5007828.1860339893
            ],
            [
              356080.62021048245,
              5007812.960087182
            ],
            [
              356085.77334676537,
              5007806.4837483745
            ],
            [
              356090.75177430297,
              5007800.5654733181
            ],
            [
              356098.01147174131,
              5007792.9402932655
            ],
            [
              356100.23594879388,
              5007788.1829011552
            ],
            [
              356111.67065648077,
              5007796.1076724129
            ],
            [
              356119.02627671656,
              5007788.6656687846
            ],
            [
              356132.82988174754,
              5007799.5979227573
            ],
            [
              356137.2963859064,
              5007796.0009389846
            ],
            [
              356147.6353946926,
              5007809.6792871021
            ],
            [
              356151.29897270276,
              5007810.1387009695
            ],
            [
              356152.3074000756,
              5007804.6641532276
            ],
            [
              356155.42440967046,
              5007803.1266311109
            ],
            [
              356164.30689815222,
              5007783.265098827
            ],
            [
              356165.61856798473,
              5007779.1719791526
            ],
            [
              356165.68072404538,
              5007776.3449986922
            ],
            [
              356171.21375462186,
              5007765.4229858173
            ],
            [
              356175.22127659956,
              5007762.019499518
            ],
            [
              356177.71289064392,
              5007756.7023261907
            ],
            [
              356190.4258832889,
              5007748.7907623714
            ],
            [
              356194.04591870686,
              5007739.476358423
            ],
            [
              356199.59777822945,
              5007733.5990107944
            ],
            [
              356205.98486952082,
              5007738.7510474622
            ],
            [
              356208.0793957854,
              5007736.9551694393
            ],
            [
              356219.25140532717,
              5007735.7301991675
            ],
            [
              356219.96898369718,
              5007734.5147121986
            ],
            [
              356222.90102524991,
              5007732.9806641769
            ],
            [
              356226.61414384155,
              5007730.9653320899
            ],
            [
              356230.56606317247,
              5007728.6314833034
            ],
            [
              356234.57057746238,
              5007727.5631839661
            ],
            [
              356252.51218617294,
              5007720.4724500924
            ],
            [
              356258.74121826643,
              5007702.025439837
            ],
            [
              356276.19636511034,
              5007670.8433360271
            ],
            [
              356284.17192287208,
              5007664.1263358844
            ],
            [
              356292.36828415981,
              5007659.0110114124
            ],
            [
              356302.70972874068,
              5007653.3808034221
            ],
            [
              356310.48215634789,
              5007653.898425553
            ],
            [
              356301.22527616686,
              5007640.4679111075
            ],
            [
              356292.67885751353,
              5007632.330436592
            ],
            [
              356281.5024175478,
              5007645.0772546362
            ],
            [
              356277.72192848625,
              5007641.482510699
            ],
            [
              356266.36214201147,
              5007654.515578785
            ],
            [
              356260.28214557812,
              5007657.2684371797
            ],
            [
              356243.59947252605,
              5007663.0468196254
            ],
            [
              356236.25363502739,
              5007668.7458382538
            ],
            [
              356217.63544925745,
              5007688.4657946089
            ],
            [
              356212.78475090925,
              5007691.3843387794
            ],
            [
              356204.42658796132,
              5007684.4434944373
            ],
            [
              356200.69755840296,
              5007685.7130488027
            ],
            [
              356202.10052314511,
              5007700.614682341
            ],
            [
              356200.29629492911,
              5007708.5247070324
            ],
            [
              356194.67987058545,
              5007721.3064611619
            ],
            [
              356174.98076319654,
              5007695.1930235038
            ],
            [
              356139.6309621039,
              5007708.6684693731
            ],
            [
              356164.08395742445,
              5007728.1927646575
            ],
            [
              356154.00898751261,
              5007744.0123930098
            ],
            [
              356121.93355132901,
              5007722.3298859503
            ],
            [
              356101.11492288654,
              5007743.7062312271
            ],
            [
              356095.00087000226,
              5007747.6122863367
            ],
            [
              356090.77573673852,
              5007752.12226885
            ],
            [
              356075.27895641449,
              5007737.6042613341
            ],
            [
              356062.73862963455,
              5007726.1002177531
            ],
            [
              356056.8128531535,
              5007727.4815397374
            ],
            [
              356049.34576523019,
              5007737.5160579011
            ],
            [
              356009.32609504019,
              5007789.1150400834
            ],
            [
              356030.19957197685,
              5007812.6495114863
            ],
            [
              356019.6936183112,
              5007824.2396987146
            ],
            [
              356016.60358314245,
              5007826.2625681423
            ],
            [
              356013.80011363939,
              5007829.3758988418
            ],
            [
              356011.97419319046,
              5007831.4036342129
            ],
            [
              356012.09250931925,
              5007833.2175456416
            ],
            [
              356011.56000432547,
              5007835.3602519687
            ],
            [
              356005.66601271153,
              5007838.3949893517
            ],
            [
              355986.61004150455,
              5007838.0052401265
            ],
            [
              355973.15250664059,
              5007834.4934196956
            ],
            [
              355974.25909503811,
              5007841.5697650947
            ],
            [
              355961.88349092129,
              5007852.7293152818
            ],
            [
              355976.880885116,
              5007877.2002460724
            ],
            [
              355969.87265863217,
              5007880.7680739062
            ],
            [
              355965.60839775804,
              5007873.3831443042
            ],
            [
              355960.78299521533,
              5007878.08894586
            ],
            [
              355951.93054414954,
              5007867.4462291645
            ],
            [
              355950.18782684411,
              5007865.5837555602
            ],
            [
              355917.05689057295,
              5007906.618751213
            ],
            [
              355887.91085452907,
              5007943.413040597
            ],
            [
              355861.95809691882,
              5007975.4164223345
            ],
            [
              355840.15153906873,
              5008003.0732341381
            ],
            [
              355825.14540893614,
              5008021.9790849257
            ],
            [
              355817.27578062826,
              5008031.7216147371
            ],
            [
              355847.21336060896,
              5008056.4889400639
            ],
            [
              355857.64475924871,
              5008069.0176464356
            ],
            [
              355862.14614895702,
              5008073.1130285589
            ],
            [
              355866.25930934859,
              5008073.7359132161
            ],
            [
              355870.04478349019,
              5008074.3141959598
            ],
            [
              355896.57734379097,
              5008052.4431836493
            ],
            [
              355904.99566856079,
              5008043.7480178103
            ],
            [
              355918.47244588559,
              5008030.2992703943
            ],
            [
              355931.79252621991,
              5008042.2435867907
            ],
            [
              355945.82146522496,
              5008028.4028320909
            ],
            [
              355953.76947321946,
              5008039.2194358585
            ],
            [
              355929.19097957603,
              5008070.0551244337
            ],
            [
              355922.90300559707,
              5008067.355508741
            ],
            [
              355917.18406295642,
              5008058.6858846815
            ],
            [
              355911.56540146185,
              5008065.432537212
            ],
            [
              355909.38616008271,
              5008067.1633933662
            ],
            [
              355905.30711007764,
              5008071.6601064941
            ],
            [
              355894.47376175789,
              5008083.6938630054
            ],
            [
              355889.50996454811,
              5008089.1146841515
            ],
            [
              355898.65128766891,
              5008097.7229933934
            ],
            [
              355908.90554641973,
              5008104.5218887608
            ],
            [
              355916.98372008343,
              5008092.6703300849
            ],
            [
              355933.51383980742,
              5008108.3258128967
            ],
            [
              355945.66050730005,
              5008092.9688416384
            ],
            [
              355949.95736606146,
              5008084.7610418126
            ],
            [
              355956.97837534203,
              5008075.6964374576
            ],
            [
              355979.04296593199,
              5008098.5202681394
            ],
            [
              355989.94937410607,
              5008088.4473271146
            ],
            [
              356000.4635875285,
              5008078.5719885118
            ],
            [
              356005.83989650809,
              5008073.7711586747
            ],
            [
              356010.95128172112,
              5008067.9778024284
            ],
            [
              355988.55496798409,
              5008045.8726126086
            ],
            [
              355999.10993789567,
              5008036.6264735805
            ],
            [
              356022.28488795267,
              5008055.0819652416
            ],
            [
              356013.93122700654,
              5008064.594679554
            ],
            [
              356021.88001664059,
              5008068.2583192345
            ],
            [
              356038.25937332382,
              5008089.2631557547
            ],
            [
              356044.88838507398,
              5008099.3507753145
            ],
            [
              356048.65540005662,
              5008096.8667678656
            ],
            [
              356055.76575356087,
              5008089.6197446454
            ],
            [
              356060.66689181828,
              5008086.6064547133
            ],
            [
              356058.95126732514,
              5008083.2080321992
            ],
            [
              356066.15138897352,
              5008080.7872201549
            ],
            [
              356071.92780426826,
              5008076.9953422546
            ],
            [
              356073.75865064043,
              5008079.7563824076
            ],
            [
              356084.47960928152,
              5008072.5146308113
            ],
            [
              356098.09853536246,
              5008066.6711471658
            ],
            [
              356110.4231779293,
              5008059.5812421422
            ],
            [
              356117.3408297436,
              5008055.6410867171
            ],
            [
              356123.5711517802,
              5008054.3836966222
            ],
            [
              356133.2035394191,
              5008051.3984582806
            ],
            [
              356142.47286287241,
              5008048.8712922288
            ],
            [
              356150.0132892392,
              5008040.68743305
            ],
            [
              356165.42988373857,
              5008023.9553249339
            ],
            [
              356175.6792113976,
              5008012.8314126758
            ],
            [
              356180.99588094518,
              5008007.0610670866
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 320,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358654.24693286343,
              5005345.1793706156
            ],
            [
              358651.8463145335,
              5005348.7464471953
            ],
            [
              358643.89771972463,
              5005361.9272480514
            ],
            [
              358636.94374905067,
              5005372.5628210595
            ],
            [
              358646.43385957327,
              5005381.0007488914
            ],
            [
              358655.93247514992,
              5005391.7075053863
            ],
            [
              358664.51701750944,
              5005412.3069715882
            ],
            [
              358656.30622054084,
              5005414.4154189797
            ],
            [
              358646.27742555621,
              5005416.2390907304
            ],
            [
              358642.46534614085,
              5005417.3347906517
            ],
            [
              358646.82560334017,
              5005419.620508723
            ],
            [
              358651.18753973319,
              5005427.5885446817
            ],
            [
              358656.30855665618,
              5005433.7205158751
            ],
            [
              358662.35064312874,
              5005443.7449415326
            ],
            [
              358666.40570882591,
              5005449.8419012679
            ],
            [
              358676.56055976212,
              5005448.339841946
            ],
            [
              358683.82571824326,
              5005453.0206303131
            ],
            [
              358686.59416660183,
              5005460.5861091781
            ],
            [
              358695.43785934261,
              5005469.4382859962
            ],
            [
              358706.03240677848,
              5005477.4492142824
            ],
            [
              358692.82039749442,
              5005484.1298291301
            ],
            [
              358699.79859401629,
              5005491.2491645776
            ],
            [
              358718.19150075241,
              5005505.2874191543
            ],
            [
              358724.81347980362,
              5005504.5733426912
            ],
            [
              358733.93549193139,
              5005509.6161874812
            ],
            [
              358751.66517812287,
              5005516.2468513781
            ],
            [
              358755.90881779464,
              5005518.8218937339
            ],
            [
              358767.29047347186,
              5005522.2987904474
            ],
            [
              358774.35224073753,
              5005513.970815883
            ],
            [
              358769.31469047052,
              5005507.6710255081
            ],
            [
              358773.29293238715,
              5005500.6895339815
            ],
            [
              358782.44809100364,
              5005490.2734355349
            ],
            [
              358786.51779807865,
              5005492.7295133453
            ],
            [
              358794.40087563777,
              5005484.4682283169
            ],
            [
              358791.8912412994,
              5005481.8099490162
            ],
            [
              358805.51976175408,
              5005472.9264571015
            ],
            [
              358808.68464969698,
              5005466.72007271
            ],
            [
              358812.58858262311,
              5005456.1557333302
            ],
            [
              358818.80762371729,
              5005445.923182332
            ],
            [
              358821.5931144054,
              5005441.3631943595
            ],
            [
              358824.22067763412,
              5005437.1340447282
            ],
            [
              358825.58693031472,
              5005435.6332182065
            ],
            [
              358807.50959359022,
              5005420.4775449671
            ],
            [
              358815.50486053113,
              5005412.1026344365
            ],
            [
              358816.03286716132,
              5005410.8051112834
            ],
            [
              358812.57960869529,
              5005408.2019261774
            ],
            [
              358821.6913123579,
              5005398.3747383635
            ],
            [
              358792.29727781686,
              5005381.9593189796
            ],
            [
              358809.51108852826,
              5005364.6814560778
            ],
            [
              358794.65729107842,
              5005356.7750964751
            ],
            [
              358793.77611551661,
              5005356.1397766443
            ],
            [
              358785.99943740334,
              5005366.4944062205
            ],
            [
              358779.49199019041,
              5005371.8403791329
            ],
            [
              358772.7946595336,
              5005377.9026678689
            ],
            [
              358764.69868566765,
              5005386.6701835888
            ],
            [
              358756.37373170175,
              5005399.7387046432
            ],
            [
              358745.18405496358,
              5005413.5601292094
            ],
            [
              358736.47348120477,
              5005425.2572473986
            ],
            [
              358730.24230391282,
              5005431.9635531902
            ],
            [
              358735.85564816248,
              5005439.6066349009
            ],
            [
              358727.78978417674,
              5005444.4658478638
            ],
            [
              358717.79477937531,
              5005435.4864082886
            ],
            [
              358711.5377254698,
              5005437.0332723297
            ],
            [
              358706.71396313462,
              5005431.6796678221
            ],
            [
              358691.65757851035,
              5005406.1913730148
            ],
            [
              358696.38256795547,
              5005399.4344910309
            ],
            [
              358702.56148120033,
              5005393.929261528
            ],
            [
              358712.35239707649,
              5005390.28872268
            ],
            [
              358710.15642120317,
              5005387.056110044
            ],
            [
              358707.01929458539,
              5005382.9870514646
            ],
            [
              358692.52560739341,
              5005369.3087801132
            ],
            [
              358686.77550858649,
              5005363.4692082386
            ],
            [
              358670.09832509124,
              5005335.7414871203
            ],
            [
              358664.1662324777,
              5005338.4784235274
            ],
            [
              358658.7092214828,
              5005343.5113225207
            ],
            [
              358654.24693286343,
              5005345.1793706156
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 321,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360490.96507674147,
              5003112.2932275375
            ],
            [
              360477.97104071383,
              5003144.2672697278
            ],
            [
              360465.62915680784,
              5003151.0583730582
            ],
            [
              360454.75223522622,
              5003157.6937076561
            ],
            [
              360452.04982752417,
              5003160.3802984124
            ],
            [
              360451.28251020605,
              5003162.2858911213
            ],
            [
              360447.016600353,
              5003167.2367445081
            ],
            [
              360444.67423444282,
              5003169.9055702463
            ],
            [
              360441.44638651185,
              5003172.7166938633
            ],
            [
              360465.36427643773,
              5003199.0795478653
            ],
            [
              360484.57727734215,
              5003220.3143863799
            ],
            [
              360496.13657678169,
              5003214.3734274292
            ],
            [
              360517.56704034901,
              5003194.0099810017
            ],
            [
              360547.53740552306,
              5003172.7744370066
            ],
            [
              360558.11927164666,
              5003164.2849928765
            ],
            [
              360559.63141960162,
              5003162.4688518438
            ],
            [
              360552.70432293258,
              5003157.5406456646
            ],
            [
              360549.09635511308,
              5003160.9882256193
            ],
            [
              360538.34491557954,
              5003149.8740590615
            ],
            [
              360518.7299731294,
              5003128.520351829
            ],
            [
              360515.95143638941,
              5003130.856170875
            ],
            [
              360490.96507674147,
              5003112.2932275375
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 322,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360671.94346418203,
              5003069.9793366147
            ],
            [
              360637.74402517505,
              5003047.1666663736
            ],
            [
              360605.63771667291,
              5003027.0289791161
            ],
            [
              360618.43160274596,
              5003012.2770105228
            ],
            [
              360620.75090413349,
              5003010.558066519
            ],
            [
              360621.82092777267,
              5003007.4643544359
            ],
            [
              360601.27999931149,
              5002999.8704923671
            ],
            [
              360591.68662929122,
              5002985.1925683599
            ],
            [
              360583.96631386026,
              5002990.1709084632
            ],
            [
              360570.25120270724,
              5002999.8183029555
            ],
            [
              360549.27488881681,
              5003011.3560005939
            ],
            [
              360513.6262417924,
              5003033.5710156495
            ],
            [
              360474.4766802786,
              5003069.2783713583
            ],
            [
              360473.17179122305,
              5003070.0261679124
            ],
            [
              360476.02555808082,
              5003071.9486791994
            ],
            [
              360504.0863415908,
              5003090.8525253003
            ],
            [
              360507.01389818458,
              5003091.5416972553
            ],
            [
              360490.96507674048,
              5003112.2932275375
            ],
            [
              360515.95143639139,
              5003130.856170875
            ],
            [
              360518.72997312917,
              5003128.5203518309
            ],
            [
              360538.34491558239,
              5003149.8740590671
            ],
            [
              360549.09635511308,
              5003160.9882256193
            ],
            [
              360552.7043229331,
              5003157.5406456646
            ],
            [
              360559.63141960162,
              5003162.4688518438
            ],
            [
              360568.65030447958,
              5003155.0133714145
            ],
            [
              360580.18344603077,
              5003143.0492798723
            ],
            [
              360586.02966065489,
              5003137.0673244111
            ],
            [
              360593.27096018038,
              5003128.0631488403
            ],
            [
              360595.3887239552,
              5003126.0574047854
            ],
            [
              360603.71466045914,
              5003130.8092547376
            ],
            [
              360608.47415971203,
              5003133.5933771962
            ],
            [
              360621.97995944513,
              5003125.5784996217
            ],
            [
              360631.85468150239,
              5003115.1077373894
            ],
            [
              360639.3540145912,
              5003109.1517731361
            ],
            [
              360647.42317708657,
              5003100.3763822364
            ],
            [
              360654.95993711887,
              5003085.8595192544
            ],
            [
              360671.94346418203,
              5003069.9793366147
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 323,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356446.5447009097,
              5007049.1347640799
            ],
            [
              356438.84493558871,
              5007043.752661352
            ],
            [
              356419.05221654166,
              5007035.1004254939
            ],
            [
              356417.39264463069,
              5007041.0428214921
            ],
            [
              356416.62321928871,
              5007043.1792256562
            ],
            [
              356416.04096772132,
              5007044.5037436094
            ],
            [
              356415.45120142651,
              5007045.4241808821
            ],
            [
              356414.66674730164,
              5007046.7524592262
            ],
            [
              356413.0867309983,
              5007048.9863391817
            ],
            [
              356410.01425473113,
              5007052.3781992979
            ],
            [
              356405.85207540874,
              5007057.0029506367
            ],
            [
              356403.95913170924,
              5007058.5539362393
            ],
            [
              356402.56236708717,
              5007059.590409887
            ],
            [
              356400.66378748539,
              5007060.8383438736
            ],
            [
              356398.56488415663,
              5007062.1910673259
            ],
            [
              356396.37044851977,
              5007063.848688337
            ],
            [
              356394.37640351249,
              5007065.4015542157
            ],
            [
              356392.58456107811,
              5007066.9506597193
            ],
            [
              356391.5940811059,
              5007068.0806772392
            ],
            [
              356389.5271143284,
              5007071.1506816316
            ],
            [
              356386.08961803053,
              5007076.6714258064
            ],
            [
              356385.00185942731,
              5007078.0053094048
            ],
            [
              356383.90652046347,
              5007078.935149054
            ],
            [
              356382.80930263957,
              5007079.7639596472
            ],
            [
              356381.8170100752,
              5007080.7929469394
            ],
            [
              356381.02309612528,
              5007081.6161169354
            ],
            [
              356379.61834631703,
              5007083.5941224685
            ],
            [
              356379.61834631348,
              5007083.5941224657
            ],
            [
              356377.66798150301,
              5007086.1248313803
            ],
            [
              356376.32951903756,
              5007087.5308482563
            ],
            [
              356375.03385634307,
              5007088.5654774364
            ],
            [
              356373.83553722181,
              5007089.3961682841
            ],
            [
              356372.42937952408,
              5007089.9275679076
            ],
            [
              356370.52328578877,
              5007090.7714569429
            ],
            [
              356369.32308717171,
              5007091.5011191266
            ],
            [
              356368.02366804203,
              5007092.33372535
            ],
            [
              356367.03506628302,
              5007093.5647365563
            ],
            [
              356366.65320414625,
              5007094.7844285378
            ],
            [
              356366.99971721653,
              5007097.1022072984
            ],
            [
              356366.52421231376,
              5007099.6040120767
            ],
            [
              356389.92034663277,
              5007134.5896964893
            ],
            [
              356389.16521243885,
              5007135.6787606552
            ],
            [
              356372.40885922377,
              5007149.1537808543
            ],
            [
              356350.39031248394,
              5007162.6623215145
            ],
            [
              356342.45773159206,
              5007173.2773587061
            ],
            [
              356338.43045865541,
              5007178.2516894145
            ],
            [
              356338.4304586603,
              5007178.2516894061
            ],
            [
              356342.74760134507,
              5007180.7347085942
            ],
            [
              356346.23848114838,
              5007182.7424998656
            ],
            [
              356356.71887493151,
              5007189.3951598946
            ],
            [
              356365.86770792335,
              5007195.2814506432
            ],
            [
              356377.46111509664,
              5007205.6503647976
            ],
            [
              356384.85353458254,
              5007211.4703247352
            ],
            [
              356391.63651343103,
              5007219.2159230523
            ],
            [
              356393.21136290854,
              5007217.7508331724
            ],
            [
              356414.70919181308,
              5007197.6129857078
            ],
            [
              356425.49713670224,
              5007186.6487612231
            ],
            [
              356440.59634331457,
              5007168.3948779209
            ],
            [
              356458.76470189221,
              5007142.4692841386
            ],
            [
              356475.24613230483,
              5007118.6759577962
            ],
            [
              356480.67872395448,
              5007110.8332275162
            ],
            [
              356483.58021213394,
              5007106.654232542
            ],
            [
              356459.40424005472,
              5007058.1235260423
            ],
            [
              356457.21829624806,
              5007056.5955608571
            ],
            [
              356446.5447009097,
              5007049.1347640799
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 324,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352644.86583507695,
              5011039.610190738
            ],
            [
              352659.50627904007,
              5011013.7906544171
            ],
            [
              352636.5213859544,
              5011012.0187340733
            ],
            [
              352630.46484138974,
              5011005.5078080157
            ],
            [
              352634.24021373858,
              5010996.6401734818
            ],
            [
              352641.76553906774,
              5010976.7476135958
            ],
            [
              352643.16821601626,
              5010969.8062962377
            ],
            [
              352642.05183886847,
              5010958.1400207365
            ],
            [
              352673.39649673452,
              5010932.0734896688
            ],
            [
              352692.86338830058,
              5010911.921323915
            ],
            [
              352684.30017471767,
              5010908.2481547697
            ],
            [
              352679.32185386436,
              5010913.3525637025
            ],
            [
              352633.58274006634,
              5010896.0316263093
            ],
            [
              352618.26273020334,
              5010927.4369010134
            ],
            [
              352605.06560819194,
              5010950.3399082273
            ],
            [
              352601.29902612028,
              5010952.9154104963
            ],
            [
              352592.15076024394,
              5010970.8412048826
            ],
            [
              352566.98066202766,
              5010959.4362378186
            ],
            [
              352564.46392649895,
              5010967.2368900711
            ],
            [
              352530.29514432233,
              5010952.5136199454
            ],
            [
              352525.5383609556,
              5010960.4450997738
            ],
            [
              352497.03353922075,
              5010940.3558280421
            ],
            [
              352487.04598098627,
              5010934.5273377039
            ],
            [
              352466.10797891213,
              5010962.3403854631
            ],
            [
              352445.75813220529,
              5010993.5481588459
            ],
            [
              352420.19249743264,
              5011027.5218530959
            ],
            [
              352395.41492940247,
              5011060.5151244393
            ],
            [
              352370.72859337943,
              5011092.6082958197
            ],
            [
              352385.96720943559,
              5011102.754069509
            ],
            [
              352396.49108904548,
              5011090.2990431981
            ],
            [
              352436.62877741287,
              5011130.4084872594
            ],
            [
              352449.48590694979,
              5011141.4731118474
            ],
            [
              352452.07674834837,
              5011146.7894528816
            ],
            [
              352487.83045168448,
              5011090.3710538186
            ],
            [
              352571.71137722558,
              5011054.2972217146
            ],
            [
              352584.97481968231,
              5011060.7346087322
            ],
            [
              352628.7484584474,
              5011081.584991442
            ],
            [
              352643.48405987216,
              5011060.2864256809
            ],
            [
              352644.86583507695,
              5011039.610190738
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 325,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359399.00562910008,
              5005245.6495656855
            ],
            [
              359410.06517500122,
              5005243.2045954941
            ],
            [
              359416.947081936,
              5005240.8499782793
            ],
            [
              359425.30323930667,
              5005235.4311140068
            ],
            [
              359431.78097842843,
              5005232.9297522381
            ],
            [
              359433.37945826672,
              5005235.5933287926
            ],
            [
              359441.28793845559,
              5005248.9573877277
            ],
            [
              359432.37967591244,
              5005254.5274658417
            ],
            [
              359437.59019643679,
              5005265.3450366622
            ],
            [
              359445.03867472359,
              5005290.6065232297
            ],
            [
              359453.68538574054,
              5005286.7701690597
            ],
            [
              359456.40146148187,
              5005284.8884961503
            ],
            [
              359460.26817157672,
              5005288.4929399602
            ],
            [
              359465.12880116125,
              5005290.3370349696
            ],
            [
              359471.50415371277,
              5005302.7907363931
            ],
            [
              359474.67010842951,
              5005300.5118003041
            ],
            [
              359476.53654176096,
              5005303.2591516264
            ],
            [
              359480.86108118406,
              5005300.7269859686
            ],
            [
              359468.4102474461,
              5005277.0247623287
            ],
            [
              359472.21993060951,
              5005274.6066173343
            ],
            [
              359477.58623401268,
              5005274.3307892643
            ],
            [
              359490.32166524621,
              5005263.0912680486
            ],
            [
              359496.22240414313,
              5005264.8504069103
            ],
            [
              359500.71488022601,
              5005261.2261845972
            ],
            [
              359504.38875064004,
              5005260.2826811317
            ],
            [
              359508.90697966126,
              5005257.998310036
            ],
            [
              359501.06128375459,
              5005250.9383720402
            ],
            [
              359502.17310760095,
              5005245.812750048
            ],
            [
              359504.88423675485,
              5005232.1086963508
            ],
            [
              359498.70395154413,
              5005215.3968540002
            ],
            [
              359492.03294940462,
              5005207.7394506391
            ],
            [
              359500.33150113752,
              5005201.3858502191
            ],
            [
              359509.02146517154,
              5005192.2573764436
            ],
            [
              359509.9989437894,
              5005185.2649731496
            ],
            [
              359518.12048231927,
              5005199.173162614
            ],
            [
              359525.5519044039,
              5005193.8320078412
            ],
            [
              359528.97395578719,
              5005199.192787203
            ],
            [
              359539.89031255577,
              5005190.2238915982
            ],
            [
              359543.00618301571,
              5005185.0736576989
            ],
            [
              359543.26592137589,
              5005175.2163505713
            ],
            [
              359540.80732226168,
              5005168.0663861986
            ],
            [
              359551.49414406583,
              5005159.1222336274
            ],
            [
              359554.33083088155,
              5005154.170403895
            ],
            [
              359574.41050291114,
              5005161.9890877418
            ],
            [
              359581.02954249427,
              5005148.692450895
            ],
            [
              359589.4075819194,
              5005134.6989198029
            ],
            [
              359580.89749297226,
              5005135.6320495484
            ],
            [
              359566.71553068043,
              5005131.0705538141
            ],
            [
              359553.34211335186,
              5005126.5589774316
            ],
            [
              359559.71301027335,
              5005111.8278470952
            ],
            [
              359566.74609735928,
              5005096.973684255
            ],
            [
              359568.14585217874,
              5005088.8663862832
            ],
            [
              359567.40094079723,
              5005078.5849396028
            ],
            [
              359564.70037158037,
              5005070.332463095
            ],
            [
              359558.2200573021,
              5005073.2205020254
            ],
            [
              359550.70259815751,
              5005079.8917078227
            ],
            [
              359541.48133299389,
              5005075.8661740264
            ],
            [
              359524.72399742721,
              5005085.493925754
            ],
            [
              359519.77397228096,
              5005091.5773303825
            ],
            [
              359512.9684678879,
              5005095.0306712557
            ],
            [
              359517.38039033668,
              5005097.5662442055
            ],
            [
              359515.71920669038,
              5005101.1192335356
            ],
            [
              359511.66001204617,
              5005103.3571349513
            ],
            [
              359507.60081736901,
              5005105.595036421
            ],
            [
              359511.66001204617,
              5005103.3571349513
            ],
            [
              359507.60081736901,
              5005105.595036421
            ],
            [
              359503.22074693709,
              5005109.6786037115
            ],
            [
              359502.11529894412,
              5005116.2187616304
            ],
            [
              359506.66787241347,
              5005117.5444630729
            ],
            [
              359505.22956156515,
              5005120.7679043431
            ],
            [
              359504.30656772299,
              5005122.8364533735
            ],
            [
              359501.34780323697,
              5005127.0252900412
            ],
            [
              359498.43680358038,
              5005131.1070796493
            ],
            [
              359502.83554661879,
              5005134.5493807495
            ],
            [
              359501.6486381666,
              5005136.2319982043
            ],
            [
              359500.31228879251,
              5005141.7919665081
            ],
            [
              359494.67315904185,
              5005154.1847517006
            ],
            [
              359490.17281532305,
              5005150.3938475391
            ],
            [
              359481.76067769324,
              5005144.6830222048
            ],
            [
              359475.88868410204,
              5005138.5928402385
            ],
            [
              359466.82208140561,
              5005157.2486998765
            ],
            [
              359471.21131698083,
              5005161.0682585565
            ],
            [
              359477.36009775737,
              5005167.1532768356
            ],
            [
              359483.50887853064,
              5005173.2382948995
            ],
            [
              359477.09440063365,
              5005179.6675540134
            ],
            [
              359485.90216075402,
              5005188.9292572662
            ],
            [
              359489.84524409147,
              5005198.4870542502
            ],
            [
              359479.54978275124,
              5005204.6563716205
            ],
            [
              359474.61449431028,
              5005195.3384303376
            ],
            [
              359465.83319790877,
              5005187.6418216322
            ],
            [
              359447.86185466073,
              5005191.9612340424
            ],
            [
              359440.3421883714,
              5005195.4769145111
            ],
            [
              359432.03788765374,
              5005202.7613438033
            ],
            [
              359421.47925848752,
              5005209.9821184697
            ],
            [
              359405.38426880894,
              5005219.6918894453
            ],
            [
              359390.81917595438,
              5005225.864212065
            ],
            [
              359395.79413286812,
              5005235.9797416572
            ],
            [
              359399.00562910008,
              5005245.6495656855
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 326,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357733.48391925078,
              5006273.5553191835
            ],
            [
              357742.51437342627,
              5006260.0722817164
            ],
            [
              357734.72557670658,
              5006254.4580748854
            ],
            [
              357710.85856468003,
              5006239.2321946742
            ],
            [
              357697.67258366349,
              5006233.6656239647
            ],
            [
              357701.1276281678,
              5006228.4758039564
            ],
            [
              357696.8897379511,
              5006226.4020995367
            ],
            [
              357695.7008790822,
              5006225.5757898148
            ],
            [
              357703.46395318984,
              5006214.819178042
            ],
            [
              357720.00704171119,
              5006192.7687651878
            ],
            [
              357724.5154433776,
              5006188.0919138696
            ],
            [
              357734.25195591745,
              5006168.0431514131
            ],
            [
              357703.60818516015,
              5006144.9864129405
            ],
            [
              357702.73731707362,
              5006154.5936262729
            ],
            [
              357696.38218746247,
              5006171.6459128289
            ],
            [
              357667.49471915915,
              5006155.881029496
            ],
            [
              357679.92864679935,
              5006132.8744373405
            ],
            [
              357683.22314136103,
              5006130.2737900931
            ],
            [
              357689.56918847415,
              5006119.1963804476
            ],
            [
              357700.82364419004,
              5006106.0228858246
            ],
            [
              357695.38747286255,
              5006101.3125127172
            ],
            [
              357688.46019581362,
              5006093.7788191354
            ],
            [
              357697.77917370002,
              5006084.3835920068
            ],
            [
              357704.05612580531,
              5006076.7823907901
            ],
            [
              357720.20662504382,
              5006060.8448294578
            ],
            [
              357725.09614319826,
              5006072.1143182488
            ],
            [
              357738.7430018938,
              5006065.6777782338
            ],
            [
              357758.69179332472,
              5006093.9777263068
            ],
            [
              357764.14414712117,
              5006100.403745261
            ],
            [
              357788.19888073491,
              5006072.7769879038
            ],
            [
              357792.12061339215,
              5006068.7443532655
            ],
            [
              357804.92222566134,
              5006049.9997975985
            ],
            [
              357808.21943917911,
              5006043.3599807275
            ],
            [
              357809.94273507834,
              5006031.2536063157
            ],
            [
              357812.82036409999,
              5006012.4423916359
            ],
            [
              357815.25014624692,
              5006001.3843247099
            ],
            [
              357823.05733337253,
              5005993.2851253711
            ],
            [
              357839.68424191762,
              5005982.6357194437
            ],
            [
              357852.95431452908,
              5005968.7534684008
            ],
            [
              357857.37218538852,
              5005965.6542710792
            ],
            [
              357853.97258331178,
              5005951.5495811896
            ],
            [
              357844.63013735187,
              5005932.8237645347
            ],
            [
              357842.62466933375,
              5005934.9042679947
            ],
            [
              357833.3921144093,
              5005943.2629088368
            ],
            [
              357784.70994947717,
              5005941.5474783881
            ],
            [
              357783.87419553398,
              5005943.6012713481
            ],
            [
              357782.16480820678,
              5005953.4165793546
            ],
            [
              357775.44566146313,
              5005960.2387117874
            ],
            [
              357774.20686383941,
              5005958.2871521777
            ],
            [
              357753.87908308016,
              5005977.2580325399
            ],
            [
              357745.43662374228,
              5005982.2272695964
            ],
            [
              357732.19553548953,
              5005988.0513573261
            ],
            [
              357715.18666235422,
              5005991.3205847014
            ],
            [
              357709.52074456238,
              5005994.5789634753
            ],
            [
              357710.75604373636,
              5005996.39294024
            ],
            [
              357706.5843614859,
              5005999.2338120304
            ],
            [
              357703.54493532167,
              5006004.9664917095
            ],
            [
              357700.50550921861,
              5006010.6991715012
            ],
            [
              357696.41677805636,
              5006016.3536914103
            ],
            [
              357677.26668821159,
              5005998.5618122201
            ],
            [
              357662.69077637658,
              5005982.662268674
            ],
            [
              357664.98518343741,
              5005980.6563966936
            ],
            [
              357655.36213825364,
              5005970.5021187924
            ],
            [
              357651.17257171869,
              5005974.6277646851
            ],
            [
              357644.98084458878,
              5005966.9266047301
            ],
            [
              357642.67374227598,
              5005968.7815132076
            ],
            [
              357636.60924814636,
              5005960.8403717829
            ],
            [
              357625.72659249074,
              5005972.3286689734
            ],
            [
              357623.92849006312,
              5005964.1184820058
            ],
            [
              357617.01961041096,
              5005966.4183181291
            ],
            [
              357610.00126211444,
              5005967.5042321952
            ],
            [
              357601.92436690425,
              5005967.7412727484
            ],
            [
              357609.60298642266,
              5005973.7810762683
            ],
            [
              357612.81191253889,
              5005976.995047491
            ],
            [
              357618.8844010547,
              5005978.3930508876
            ],
            [
              357616.24949984445,
              5005983.7271027556
            ],
            [
              357613.27636530652,
              5005992.8708663713
            ],
            [
              357609.82725077588,
              5006000.5647996077
            ],
            [
              357601.94158143259,
              5006015.8975846944
            ],
            [
              357590.96141187771,
              5006029.4675373733
            ],
            [
              357585.07512176153,
              5006034.121333627
            ],
            [
              357582.89309656277,
              5006036.7704401277
            ],
            [
              357580.7740121347,
              5006035.5892539136
            ],
            [
              357576.33497707912,
              5006039.247071729
            ],
            [
              357570.29736124852,
              5006041.5759725804
            ],
            [
              357568.77692355035,
              5006040.6031969665
            ],
            [
              357566.10106717789,
              5006031.3572510406
            ],
            [
              357564.60273036716,
              5006023.8770664996
            ],
            [
              357563.07165929099,
              5006022.3324215943
            ],
            [
              357560.34075053775,
              5006021.6681518769
            ],
            [
              357558.45081035036,
              5006020.058653879
            ],
            [
              357557.26838465914,
              5006018.3698089626
            ],
            [
              357549.77545121429,
              5006032.290436483
            ],
            [
              357545.66856727621,
              5006039.9908735398
            ],
            [
              357544.33172916865,
              5006050.1448255237
            ],
            [
              357540.93277067086,
              5006054.8913595919
            ],
            [
              357537.0862911719,
              5006064.8741349624
            ],
            [
              357530.13886035391,
              5006078.7711090948
            ],
            [
              357525.94610925176,
              5006088.2810509345
            ],
            [
              357521.31113253807,
              5006102.7440155381
            ],
            [
              357517.16340394935,
              5006121.9956322573
            ],
            [
              357527.97670427553,
              5006129.9012034684
            ],
            [
              357529.64068006939,
              5006127.5770538328
            ],
            [
              357530.77156699984,
              5006122.9979894292
            ],
            [
              357530.08892078302,
              5006118.9844035544
            ],
            [
              357535.07429505995,
              5006113.1894597514
            ],
            [
              357565.82646695379,
              5006131.4115596609
            ],
            [
              357560.33424521593,
              5006150.1865112577
            ],
            [
              357559.05890312977,
              5006152.4334601676
            ],
            [
              357557.37095994892,
              5006168.5690220203
            ],
            [
              357557.46416585165,
              5006183.1989710433
            ],
            [
              357547.40763006487,
              5006176.0967979971
            ],
            [
              357538.44431051932,
              5006180.112521166
            ],
            [
              357529.05305874738,
              5006174.5316297635
            ],
            [
              357510.6049950184,
              5006160.2500244016
            ],
            [
              357507.6233224147,
              5006178.9878421286
            ],
            [
              357504.36956461362,
              5006212.3964822898
            ],
            [
              357502.16428922903,
              5006244.0042171553
            ],
            [
              357499.3169501865,
              5006269.5428902768
            ],
            [
              357494.02175678563,
              5006299.0102583067
            ],
            [
              357500.94195088081,
              5006308.8547953749
            ],
            [
              357500.52153723192,
              5006315.0106045203
            ],
            [
              357505.99445619032,
              5006314.5078722015
            ],
            [
              357513.12109551119,
              5006316.6474211486
            ],
            [
              357526.00541941851,
              5006319.2145123845
            ],
            [
              357529.71282031474,
              5006310.057264559
            ],
            [
              357533.49480787909,
              5006304.9081954109
            ],
            [
              357543.77184662997,
              5006296.6979639195
            ],
            [
              357543.2927601643,
              5006284.2476544268
            ],
            [
              357547.94285014988,
              5006268.2566383407
            ],
            [
              357549.76203161076,
              5006265.4161362788
            ],
            [
              357562.31733760366,
              5006271.8652344439
            ],
            [
              357577.52585808031,
              5006282.5152228167
            ],
            [
              357581.24319689197,
              5006273.8923982894
            ],
            [
              357579.21088281862,
              5006272.4600127432
            ],
            [
              357581.2700563657,
              5006268.1448721951
            ],
            [
              357587.9206864044,
              5006259.0665404722
            ],
            [
              357586.12831078045,
              5006256.1595521132
            ],
            [
              357595.40060622344,
              5006251.4429646898
            ],
            [
              357608.75810777972,
              5006270.8748110943
            ],
            [
              357616.8442693472,
              5006295.8509794967
            ],
            [
              357621.86204569711,
              5006299.633561004
            ],
            [
              357625.63973547635,
              5006305.7736120913
            ],
            [
              357627.01242254296,
              5006314.0224981392
            ],
            [
              357629.79297466605,
              5006322.052714074
            ],
            [
              357634.37696468591,
              5006330.4342867592
            ],
            [
              357639.32559057948,
              5006337.775363205
            ],
            [
              357647.732767871,
              5006330.8273918601
            ],
            [
              357655.93322080636,
              5006329.5708286194
            ],
            [
              357668.75984153914,
              5006323.1466582213
            ],
            [
              357676.40809698572,
              5006320.0900901305
            ],
            [
              357684.52238529961,
              5006314.8981803833
            ],
            [
              357687.26484830264,
              5006311.4692059159
            ],
            [
              357688.36912686325,
              5006311.4486719025
            ],
            [
              357704.38344599248,
              5006319.5196346315
            ],
            [
              357711.41067643184,
              5006323.8255069945
            ],
            [
              357718.43790677999,
              5006328.1313791806
            ],
            [
              357722.25940642133,
              5006320.6670447225
            ],
            [
              357723.17963096109,
              5006317.0394467721
            ],
            [
              357715.26411810378,
              5006311.7594843516
            ],
            [
              357706.61683739227,
              5006304.8401059657
            ],
            [
              357713.58904590021,
              5006294.797416009
            ],
            [
              357720.28969954356,
              5006290.2007996039
            ],
            [
              357725.21513484867,
              5006281.0215306235
            ],
            [
              357733.48391925078,
              5006273.5553191835
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 327,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357627.67891841801,
              5006771.3368166313
            ],
            [
              357627.67891841696,
              5006771.3368166303
            ],
            [
              357627.67891841801,
              5006771.3368166313
            ],
            [
              357607.26002984022,
              5006787.5102429418
            ],
            [
              357595.62500678713,
              5006802.312833285
            ],
            [
              357612.53662213002,
              5006821.0985025521
            ],
            [
              357602.7117860408,
              5006830.3886982491
            ],
            [
              357599.48995654454,
              5006836.9744714145
            ],
            [
              357603.08407061029,
              5006841.8418669105
            ],
            [
              357615.8026480836,
              5006849.2453835458
            ],
            [
              357619.36953169614,
              5006844.0856702421
            ],
            [
              357640.94106294779,
              5006847.9820491122
            ],
            [
              357651.02671843156,
              5006850.8186970605
            ],
            [
              357653.88041936321,
              5006850.1289642286
            ],
            [
              357659.51094524615,
              5006844.6125429906
            ],
            [
              357661.97876484325,
              5006848.8641838729
            ],
            [
              357666.69253991201,
              5006845.4339862466
            ],
            [
              357677.72333629784,
              5006830.5854083141
            ],
            [
              357684.66041974875,
              5006825.4658836611
            ],
            [
              357692.85822833161,
              5006812.42083357
            ],
            [
              357699.78535510669,
              5006799.5585976066
            ],
            [
              357699.05091875751,
              5006794.3197264895
            ],
            [
              357701.58033342124,
              5006793.3176579112
            ],
            [
              357712.09238409792,
              5006783.3688334133
            ],
            [
              357714.78098312556,
              5006782.3638396477
            ],
            [
              357725.0045096557,
              5006775.4886800824
            ],
            [
              357728.58322874323,
              5006770.965412857
            ],
            [
              357737.86232190026,
              5006754.9625623515
            ],
            [
              357744.69240255578,
              5006745.4446360422
            ],
            [
              357753.17899173498,
              5006730.802560633
            ],
            [
              357759.0622510984,
              5006713.1846927768
            ],
            [
              357761.79368393664,
              5006713.8696851823
            ],
            [
              357763.27726245107,
              5006709.9652794581
            ],
            [
              357737.58206847386,
              5006700.312765874
            ],
            [
              357726.0213305442,
              5006701.5543016912
            ],
            [
              357713.05826373462,
              5006699.1289369743
            ],
            [
              357708.23892656114,
              5006696.6924468521
            ],
            [
              357703.55785817612,
              5006701.6913321828
            ],
            [
              357704.99801586359,
              5006703.629292191
            ],
            [
              357702.76067502325,
              5006708.517397359
            ],
            [
              357696.5313975843,
              5006716.8918387853
            ],
            [
              357686.67491998838,
              5006730.2525913808
            ],
            [
              357680.39226477692,
              5006724.8167669354
            ],
            [
              357674.1328711441,
              5006720.6318256985
            ],
            [
              357671.44307984813,
              5006723.2626366457
            ],
            [
              357673.81880625332,
              5006724.7825847818
            ],
            [
              357667.92346226826,
              5006731.6265904596
            ],
            [
              357663.54580682615,
              5006736.0055317869
            ],
            [
              357660.12928595732,
              5006740.6849317225
            ],
            [
              357656.74990439747,
              5006743.1437786901
            ],
            [
              357649.31072350527,
              5006750.7852061335
            ],
            [
              357675.62543971097,
              5006769.8234195122
            ],
            [
              357667.08857691061,
              5006781.5003193561
            ],
            [
              357661.65515906311,
              5006780.4871816663
            ],
            [
              357657.46869538171,
              5006778.0183439404
            ],
            [
              357652.26191889081,
              5006772.0667937482
            ],
            [
              357643.88247651956,
              5006758.2158392519
            ],
            [
              357627.67891841801,
              5006771.3368166313
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 328,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353514.21595288621,
              5011085.1523823924
            ],
            [
              353506.14261833346,
              5011044.4654295696
            ],
            [
              353516.25599751744,
              5011042.3064203672
            ],
            [
              353522.36622998916,
              5011037.5004482446
            ],
            [
              353532.211531865,
              5011018.6002502898
            ],
            [
              353546.73220182379,
              5011000.380156396
            ],
            [
              353474.69311495934,
              5010953.7569830399
            ],
            [
              353457.77854094381,
              5010965.6788358446
            ],
            [
              353445.81651105551,
              5010981.7793247569
            ],
            [
              353461.39540043141,
              5010989.5377306454
            ],
            [
              353465.75498465489,
              5010998.4271088364
            ],
            [
              353459.6854615889,
              5011005.8338556783
            ],
            [
              353447.05860554642,
              5011035.1251416048
            ],
            [
              353446.3272394244,
              5011045.6748008095
            ],
            [
              353452.9837402894,
              5011068.4779598946
            ],
            [
              353458.24018095789,
              5011070.5759530198
            ],
            [
              353453.99009382742,
              5011081.9771895725
            ],
            [
              353453.63093190541,
              5011088.5026975181
            ],
            [
              353447.69601021748,
              5011098.2501856806
            ],
            [
              353446.88789318939,
              5011100.2783200992
            ],
            [
              353445.2830053473,
              5011104.3061133791
            ],
            [
              353448.65814082272,
              5011106.4907991095
            ],
            [
              353461.49781756348,
              5011114.3902409039
            ],
            [
              353481.08008741209,
              5011115.7014095206
            ],
            [
              353490.22572514211,
              5011118.8340528589
            ],
            [
              353493.73226613126,
              5011114.0463862028
            ],
            [
              353500.91867968364,
              5011106.7670667339
            ],
            [
              353508.2163378002,
              5011114.7238627821
            ],
            [
              353522.99441022222,
              5011103.4291381724
            ],
            [
              353514.21595288621,
              5011085.1523823924
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 329,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352559.47287732741,
              5011765.4074848751
            ],
            [
              352588.9861609239,
              5011732.4382481035
            ],
            [
              352587.17143143952,
              5011729.4222696042
            ],
            [
              352574.62818318902,
              5011731.716254266
            ],
            [
              352561.68933819706,
              5011729.868852688
            ],
            [
              352548.25324197224,
              5011736.0077405917
            ],
            [
              352531.08886324178,
              5011750.735446685
            ],
            [
              352515.53098857397,
              5011760.447144486
            ],
            [
              352528.23881700297,
              5011774.2130817901
            ],
            [
              352537.26392896037,
              5011783.9404483652
            ],
            [
              352559.47287732741,
              5011765.4074848751
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 330,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352514.13683529885,
              5011301.5396762965
            ],
            [
              352530.9289131533,
              5011306.2786491131
            ],
            [
              352534.68043883337,
              5011287.7577680526
            ],
            [
              352551.41171091824,
              5011263.1789349094
            ],
            [
              352552.24340607849,
              5011259.7718091849
            ],
            [
              352529.27024208393,
              5011254.1639535641
            ],
            [
              352526.8531996971,
              5011258.3518650122
            ],
            [
              352522.56431799696,
              5011271.212672675
            ],
            [
              352517.30653449701,
              5011283.1149429092
            ],
            [
              352514.82571321726,
              5011298.8753630407
            ],
            [
              352514.13683529885,
              5011301.5396762965
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 331,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352892.90760706813,
              5010610.6089860685
            ],
            [
              352893.06297625153,
              5010610.404255731
            ],
            [
              352905.46533536009,
              5010592.725657736
            ],
            [
              352892.74595407408,
              5010586.3730639908
            ],
            [
              352868.51885528234,
              5010580.484832501
            ],
            [
              352864.82500449562,
              5010579.3024624083
            ],
            [
              352862.80379385815,
              5010578.3819922768
            ],
            [
              352871.91439822543,
              5010565.7012072373
            ],
            [
              352885.49573500571,
              5010551.1022744821
            ],
            [
              352894.03827991756,
              5010543.7701944625
            ],
            [
              352900.61015708226,
              5010538.420055815
            ],
            [
              352898.37434201228,
              5010536.2670230037
            ],
            [
              352908.52326642832,
              5010522.9103002297
            ],
            [
              352899.05669133854,
              5010517.4061534218
            ],
            [
              352897.72618710244,
              5010518.7864461932
            ],
            [
              352886.98743376188,
              5010510.8531509424
            ],
            [
              352885.65030564083,
              5010512.2331971936
            ],
            [
              352878.56388699578,
              5010522.20965074
            ],
            [
              352852.91848955315,
              5010509.3459970634
            ],
            [
              352851.37040591903,
              5010507.3933548834
            ],
            [
              352842.87009329983,
              5010504.8217057781
            ],
            [
              352836.60815899406,
              5010502.9272462614
            ],
            [
              352835.65606645,
              5010504.6673432589
            ],
            [
              352826.32573609444,
              5010499.9668235807
            ],
            [
              352828.11879683024,
              5010493.1773777632
            ],
            [
              352811.59919155663,
              5010488.8972948501
            ],
            [
              352810.84921789961,
              5010479.9865512187
            ],
            [
              352807.96753911587,
              5010473.1172770346
            ],
            [
              352807.82201278373,
              5010470.1732740821
            ],
            [
              352799.84224574309,
              5010462.8984255241
            ],
            [
              352795.63229748729,
              5010460.891533576
            ],
            [
              352797.7144100662,
              5010456.181935777
            ],
            [
              352790.98630388238,
              5010453.387841206
            ],
            [
              352792.98187401809,
              5010448.5130093899
            ],
            [
              352796.65865177225,
              5010435.2917469507
            ],
            [
              352793.79127516819,
              5010433.6769187823
            ],
            [
              352800.8613243213,
              5010423.452953673
            ],
            [
              352790.63062340405,
              5010416.3033763757
            ],
            [
              352753.07479596551,
              5010390.6170416065
            ],
            [
              352761.82888286206,
              5010376.815776892
            ],
            [
              352772.07532501052,
              5010386.6074074972
            ],
            [
              352777.58091565158,
              5010382.1811026912
            ],
            [
              352775.80203659117,
              5010378.6109664934
            ],
            [
              352782.75711960526,
              5010369.7436723877
            ],
            [
              352794.07487068389,
              5010377.3701252118
            ],
            [
              352804.51486157061,
              5010362.0422689002
            ],
            [
              352800.13993974944,
              5010359.4212457119
            ],
            [
              352815.12281086936,
              5010335.2304793112
            ],
            [
              352817.03660626855,
              5010336.3659180002
            ],
            [
              352824.86922688741,
              5010326.2211916354
            ],
            [
              352825.89843597083,
              5010323.4095245237
            ],
            [
              352838.7000939576,
              5010307.083015848
            ],
            [
              352841.13776229479,
              5010307.3078983873
            ],
            [
              352845.44018262863,
              5010301.1924232645
            ],
            [
              352835.78737730155,
              5010291.0127126416
            ],
            [
              352841.93159908272,
              5010282.1605418967
            ],
            [
              352858.2952624454,
              5010275.1900797188
            ],
            [
              352873.38106120558,
              5010285.0657352069
            ],
            [
              352896.62710080168,
              5010299.2263245275
            ],
            [
              352908.19000103226,
              5010305.4969865242
            ],
            [
              352916.15418697632,
              5010292.7374806628
            ],
            [
              352933.34760127257,
              5010260.5882738726
            ],
            [
              352935.74165269086,
              5010258.4718727646
            ],
            [
              352952.95200401166,
              5010247.0413729381
            ],
            [
              352969.34247076215,
              5010241.5117027592
            ],
            [
              352975.08813466196,
              5010235.4594350792
            ],
            [
              352987.26048809598,
              5010226.0447192537
            ],
            [
              352997.16400017613,
              5010220.6357493615
            ],
            [
              352994.46760659356,
              5010215.8745041303
            ],
            [
              352986.58653025446,
              5010204.0403621653
            ],
            [
              352984.55249897129,
              5010201.2856923919
            ],
            [
              352984.43395018979,
              5010199.0147766257
            ],
            [
              352990.0865842466,
              5010197.6484637335
            ],
            [
              353004.11119606142,
              5010189.3600365007
            ],
            [
              353015.08580543339,
              5010183.3906603875
            ],
            [
              353031.5425463384,
              5010171.7342522088
            ],
            [
              353043.50117992336,
              5010160.5218676524
            ],
            [
              353058.74584944744,
              5010149.2049978329
            ],
            [
              353061.81209627632,
              5010144.463757528
            ],
            [
              353064.21787531918,
              5010142.9776760731
            ],
            [
              353091.20106039959,
              5010154.5464898907
            ],
            [
              353095.43262797722,
              5010159.1519452818
            ],
            [
              353096.9063759236,
              5010165.7004525186
            ],
            [
              353104.32482434623,
              5010172.0482278839
            ],
            [
              353123.67826560221,
              5010148.4345258651
            ],
            [
              353127.16224100807,
              5010151.612604537
            ],
            [
              353141.00785739784,
              5010140.0948751746
            ],
            [
              353146.56828517962,
              5010133.775852304
            ],
            [
              353153.49025517452,
              5010127.9719421435
            ],
            [
              353162.66760927689,
              5010117.4418805176
            ],
            [
              353166.27107908868,
              5010112.5104651256
            ],
            [
              353161.23935869714,
              5010102.1020264346
            ],
            [
              353162.62893409817,
              5010099.2836577669
            ],
            [
              353171.39935469354,
              5010091.1032951279
            ],
            [
              353202.85061596526,
              5010081.2397636017
            ],
            [
              353207.2113866093,
              5010073.4116871543
            ],
            [
              353210.43799840909,
              5010074.2168951975
            ],
            [
              353230.24708670075,
              5010068.3533915635
            ],
            [
              353230.0422842314,
              5010052.5029607108
            ],
            [
              353260.64506288356,
              5010010.6262920126
            ],
            [
              353248.48530309176,
              5009991.8047851315
            ],
            [
              353244.37530835171,
              5009984.0442247419
            ],
            [
              353237.73382375034,
              5009971.0159761449
            ],
            [
              353220.25625199795,
              5009985.9342622515
            ],
            [
              353192.0702498742,
              5009974.8902145512
            ],
            [
              353185.10934710299,
              5009973.7585893171
            ],
            [
              353180.4747942292,
              5009957.1798574002
            ],
            [
              353160.69211268553,
              5009959.6197811468
            ],
            [
              353149.63346828677,
              5009963.7710183924
            ],
            [
              353128.38709407125,
              5009960.2027591383
            ],
            [
              353120.80859873997,
              5009954.9389139665
            ],
            [
              353106.20626961061,
              5009954.8502694368
            ],
            [
              353096.53840318765,
              5009943.8601198606
            ],
            [
              353086.65559492557,
              5009938.7709812066
            ],
            [
              353061.57305282599,
              5009951.8490023809
            ],
            [
              353084.80852115742,
              5009988.7788649304
            ],
            [
              353062.91842837451,
              5009989.3662941344
            ],
            [
              353064.52305428096,
              5009995.0478114663
            ],
            [
              353067.60852400475,
              5010001.0258216588
            ],
            [
              353077.12801757304,
              5010013.730289422
            ],
            [
              353049.63378796651,
              5010037.6628857376
            ],
            [
              353034.60640352819,
              5010023.6160044177
            ],
            [
              353030.67255457054,
              5010026.1249876739
            ],
            [
              353017.04519825563,
              5010040.6586525775
            ],
            [
              353024.59226020478,
              5010049.0759274652
            ],
            [
              353036.88002734858,
              5010065.2420313908
            ],
            [
              353014.70343688695,
              5010089.672594103
            ],
            [
              352997.711574624,
              5010084.5561420647
            ],
            [
              352993.87603047653,
              5010092.8058138816
            ],
            [
              352969.99582254462,
              5010083.6072269445
            ],
            [
              352965.43285840965,
              5010082.1103415638
            ],
            [
              352940.79782415606,
              5010069.9334213547
            ],
            [
              352925.76139556273,
              5010056.2171670627
            ],
            [
              352923.13026522804,
              5010066.3743520044
            ],
            [
              352936.20843483531,
              5010084.5980023714
            ],
            [
              352943.4470932461,
              5010094.3461924056
            ],
            [
              352966.34765365586,
              5010102.1385219377
            ],
            [
              352959.30760540254,
              5010114.3104380183
            ],
            [
              352954.65350826253,
              5010126.2468619347
            ],
            [
              352950.96732390369,
              5010123.680411417
            ],
            [
              352947.08023041481,
              5010121.6307694744
            ],
            [
              352939.62907931057,
              5010146.4227596931
            ],
            [
              352927.61252106674,
              5010144.3515628781
            ],
            [
              352935.07915786828,
              5010115.1616475089
            ],
            [
              352922.93777641718,
              5010114.3478732854
            ],
            [
              352920.1152352271,
              5010140.3513367241
            ],
            [
              352912.29961085162,
              5010154.7221226953
            ],
            [
              352908.3804333937,
              5010153.1427168902
            ],
            [
              352900.6085419248,
              5010148.3304071175
            ],
            [
              352888.24199819384,
              5010136.994343875
            ],
            [
              352878.39803157037,
              5010126.5488627423
            ],
            [
              352881.45452239789,
              5010123.5761742676
            ],
            [
              352885.19653659285,
              5010115.6337829856
            ],
            [
              352873.31300900545,
              5010109.8920699023
            ],
            [
              352851.9253570314,
              5010120.5223949077
            ],
            [
              352850.82480390527,
              5010121.0359843215
            ],
            [
              352850.20827829774,
              5010121.0474554542
            ],
            [
              352849.3497392321,
              5010121.3100020709
            ],
            [
              352806.4854256438,
              5010139.5088367844
            ],
            [
              352802.26386539178,
              5010144.6419420457
            ],
            [
              352806.11201165058,
              5010152.5836413167
            ],
            [
              352803.2260855771,
              5010156.5823353641
            ],
            [
              352804.50320026453,
              5010165.5581293264
            ],
            [
              352804.03762839275,
              5010187.6004699413
            ],
            [
              352803.98134912318,
              5010197.833870885
            ],
            [
              352801.60981054412,
              5010209.5897532115
            ],
            [
              352796.69939619582,
              5010224.105075472
            ],
            [
              352787.14420643012,
              5010234.2686775047
            ],
            [
              352774.63424713659,
              5010253.063264424
            ],
            [
              352773.98616970918,
              5010258.0065789353
            ],
            [
              352765.99492301547,
              5010266.0452975286
            ],
            [
              352757.15911205887,
              5010282.8616725486
            ],
            [
              352750.22766886576,
              5010295.4153470611
            ],
            [
              352749.99298276199,
              5010295.9015732249
            ],
            [
              352741.43595184787,
              5010295.3527889941
            ],
            [
              352726.14433332178,
              5010271.1662457678
            ],
            [
              352721.64781566575,
              5010258.8176867273
            ],
            [
              352720.60581972421,
              5010253.3437315179
            ],
            [
              352719.18899625709,
              5010238.275115367
            ],
            [
              352717.33793510165,
              5010236.6356026893
            ],
            [
              352715.47208451637,
              5010235.7828310188
            ],
            [
              352713.54607090022,
              5010235.6707700519
            ],
            [
              352681.83316895127,
              5010246.1171269426
            ],
            [
              352673.84450681036,
              5010250.2594137928
            ],
            [
              352662.92588744505,
              5010256.0092430599
            ],
            [
              352660.47688376863,
              5010259.60469478
            ],
            [
              352661.67448057781,
              5010264.315614216
            ],
            [
              352662.97326384729,
              5010266.5101173278
            ],
            [
              352689.31703356031,
              5010284.0433057556
            ],
            [
              352686.80398379377,
              5010291.5277445149
            ],
            [
              352680.43439972628,
              5010300.3818253968
            ],
            [
              352677.15105742658,
              5010305.7568314923
            ],
            [
              352671.91965712042,
              5010317.0931414645
            ],
            [
              352676.77331823524,
              5010319.9638847876
            ],
            [
              352693.85261381947,
              5010326.6913785907
            ],
            [
              352707.6819545154,
              5010307.5200494491
            ],
            [
              352725.83661562199,
              5010324.8202515785
            ],
            [
              352732.70359078591,
              5010333.6896411171
            ],
            [
              352730.74840887351,
              5010337.523546081
            ],
            [
              352738.52453002351,
              5010339.6731244838
            ],
            [
              352739.87496705842,
              5010340.8139939671
            ],
            [
              352739.87496705964,
              5010340.8139939671
            ],
            [
              352739.87496705848,
              5010340.813993969
            ],
            [
              352734.40306542249,
              5010349.5722959209
            ],
            [
              352735.01624690933,
              5010360.0946998764
            ],
            [
              352735.98460006306,
              5010367.2730529625
            ],
            [
              352736.42945186753,
              5010374.3568363162
            ],
            [
              352737.31342409301,
              5010383.0565987499
            ],
            [
              352743.13645241701,
              5010393.1691650562
            ],
            [
              352738.6815166772,
              5010444.7125481917
            ],
            [
              352734.62655739632,
              5010452.1517373966
            ],
            [
              352725.48446230724,
              5010463.7116183061
            ],
            [
              352717.36395546753,
              5010458.8098575529
            ],
            [
              352709.59928135236,
              5010451.7218098762
            ],
            [
              352705.15632318391,
              5010447.346101339
            ],
            [
              352704.1432418577,
              5010446.1760224067
            ],
            [
              352703.03110697627,
              5010445.0078192549
            ],
            [
              352684.01139495528,
              5010435.0659784442
            ],
            [
              352679.38127856521,
              5010431.288167513
            ],
            [
              352670.31682401703,
              5010444.9106690651
            ],
            [
              352671.69135580875,
              5010448.6079523824
            ],
            [
              352643.93914766825,
              5010460.0753315035
            ],
            [
              352617.31777088088,
              5010445.7876814958
            ],
            [
              352625.28147440142,
              5010445.4492339678
            ],
            [
              352624.00886325288,
              5010415.5045382921
            ],
            [
              352615.67132974404,
              5010409.5153544201
            ],
            [
              352603.66194863286,
              5010396.3851038907
            ],
            [
              352599.60487262165,
              5010392.5766453082
            ],
            [
              352597.12045054726,
              5010395.2232511071
            ],
            [
              352597.28740620799,
              5010446.0000710525
            ],
            [
              352612.91151698184,
              5010446.0988798803
            ],
            [
              352597.96819363878,
              5010478.4350277325
            ],
            [
              352596.69561630132,
              5010480.9052457353
            ],
            [
              352596.47764499596,
              5010481.865910951
            ],
            [
              352605.62429345213,
              5010484.0765520614
            ],
            [
              352606.09154449654,
              5010487.1329370392
            ],
            [
              352599.19656955102,
              5010497.5459606573
            ],
            [
              352603.35161551571,
              5010514.3168576276
            ],
            [
              352604.93808789126,
              5010518.3654474495
            ],
            [
              352619.92603987671,
              5010526.9505629605
            ],
            [
              352645.13052146678,
              5010544.9100011401
            ],
            [
              352641.8927222534,
              5010550.7108188439
            ],
            [
              352634.22644293797,
              5010562.7822197601
            ],
            [
              352629.72050441912,
              5010569.113084428
            ],
            [
              352638.24558686296,
              5010587.2912530331
            ],
            [
              352635.85818764783,
              5010591.5069165938
            ],
            [
              352636.51621544739,
              5010600.3673346415
            ],
            [
              352641.17802337086,
              5010601.7593398821
            ],
            [
              352634.42774977983,
              5010615.9140601801
            ],
            [
              352642.16683635337,
              5010623.6568511175
            ],
            [
              352638.52262528549,
              5010629.2454315033
            ],
            [
              352634.24650738068,
              5010632.6768914424
            ],
            [
              352641.1054781797,
              5010640.8303855974
            ],
            [
              352632.36877506226,
              5010646.3656771295
            ],
            [
              352638.90946460032,
              5010659.8370143799
            ],
            [
              352640.1179343281,
              5010679.0996974334
            ],
            [
              352641.21925465547,
              5010684.2979617007
            ],
            [
              352639.28461728035,
              5010694.5287602199
            ],
            [
              352630.16421949182,
              5010693.1692488585
            ],
            [
              352630.42461272626,
              5010702.5945759639
            ],
            [
              352618.97136927833,
              5010708.6137100859
            ],
            [
              352623.75696379103,
              5010714.5565885063
            ],
            [
              352603.62660030037,
              5010733.6216326458
            ],
            [
              352602.01658534299,
              5010740.6884434745
            ],
            [
              352593.73160907818,
              5010756.8144627772
            ],
            [
              352587.42077647225,
              5010764.832846283
            ],
            [
              352590.78838811285,
              5010767.658708211
            ],
            [
              352579.90927564487,
              5010772.2788947197
            ],
            [
              352573.99168135558,
              5010778.590855143
            ],
            [
              352571.6595199359,
              5010776.5952786803
            ],
            [
              352561.10034405248,
              5010791.0252855914
            ],
            [
              352558.64307078725,
              5010792.3109679949
            ],
            [
              352545.37025212386,
              5010812.3972349819
            ],
            [
              352546.19798640587,
              5010815.6786114797
            ],
            [
              352535.2880839089,
              5010829.4084118381
            ],
            [
              352525.40083486884,
              5010832.54796727
            ],
            [
              352513.31786197453,
              5010849.9067260735
            ],
            [
              352488.61858541804,
              5010875.7926908201
            ],
            [
              352461.66790409974,
              5010911.0581562538
            ],
            [
              352440.76879539486,
              5010946.9944729563
            ],
            [
              352437.57128358778,
              5010945.0582798934
            ],
            [
              352380.89312114374,
              5011013.9908692027
            ],
            [
              352353.26843420422,
              5011055.0467402795
            ],
            [
              352334.33184303093,
              5011089.9508179799
            ],
            [
              352326.67646552686,
              5011098.8244034471
            ],
            [
              352315.33005860384,
              5011097.1230141642
            ],
            [
              352306.94128231303,
              5011102.3515016325
            ],
            [
              352300.87752473616,
              5011099.5555458395
            ],
            [
              352293.98535831092,
              5011100.2658617357
            ],
            [
              352284.58313426859,
              5011104.6816665623
            ],
            [
              352294.27693749452,
              5011124.8739287704
            ],
            [
              352290.69020919123,
              5011128.8489031671
            ],
            [
              352273.56080092973,
              5011111.4559254963
            ],
            [
              352277.85453379387,
              5011106.8240176989
            ],
            [
              352247.08765426994,
              5011076.8078747615
            ],
            [
              352238.07324103115,
              5011066.0864648614
            ],
            [
              352231.81818249851,
              5011054.6207322944
            ],
            [
              352219.54921865015,
              5011065.9361989973
            ],
            [
              352213.67172050133,
              5011074.7569256751
            ],
            [
              352220.55016002333,
              5011084.3896365454
            ],
            [
              352207.39148995455,
              5011098.8389180042
            ],
            [
              352199.48855340196,
              5011110.596874523
            ],
            [
              352202.57342481805,
              5011113.0502494536
            ],
            [
              352183.62558682793,
              5011129.2922660476
            ],
            [
              352181.22582611762,
              5011134.6053442871
            ],
            [
              352177.7854491084,
              5011143.6877057962
            ],
            [
              352197.34804379073,
              5011155.8634241764
            ],
            [
              352183.95354394283,
              5011171.2709177462
            ],
            [
              352201.59443748917,
              5011184.562030524
            ],
            [
              352216.73186225089,
              5011195.9128914205
            ],
            [
              352186.02181862399,
              5011255.5365611762
            ],
            [
              352172.64766473573,
              5011282.6667903326
            ],
            [
              352194.28444738913,
              5011276.0962504866
            ],
            [
              352198.07970943401,
              5011274.5168424891
            ],
            [
              352200.68733346026,
              5011273.4316730965
            ],
            [
              352228.10791881947,
              5011256.2257551393
            ],
            [
              352230.65881880914,
              5011245.4645082112
            ],
            [
              352270.77056319505,
              5011222.3999921428
            ],
            [
              352272.39409721835,
              5011215.4832500955
            ],
            [
              352280.65955557529,
              5011212.6272667227
            ],
            [
              352297.88573696016,
              5011197.6219611792
            ],
            [
              352312.20484998421,
              5011179.243348809
            ],
            [
              352317.48935161409,
              5011182.936341404
            ],
            [
              352326.16093897709,
              5011173.2966710832
            ],
            [
              352328.41268320312,
              5011158.4054415347
            ],
            [
              352336.97107623325,
              5011159.6679189634
            ],
            [
              352342.57537737663,
              5011138.0794863906
            ],
            [
              352356.11627022235,
              5011116.1053541433
            ],
            [
              352368.5570099075,
              5011095.4314368414
            ],
            [
              352395.41492934601,
              5011060.5151245045
            ],
            [
              352420.19249743264,
              5011027.5218530959
            ],
            [
              352445.75813220278,
              5010993.5481588682
            ],
            [
              352466.10797889763,
              5010962.3403854743
            ],
            [
              352487.04598098726,
              5010934.5273377039
            ],
            [
              352497.03353921941,
              5010940.3558280393
            ],
            [
              352525.53836095479,
              5010960.4450997738
            ],
            [
              352530.29514432233,
              5010952.5136199454
            ],
            [
              352564.46392649791,
              5010967.2368900711
            ],
            [
              352566.98066202673,
              5010959.4362378195
            ],
            [
              352592.15076024394,
              5010970.8412048826
            ],
            [
              352601.29902612133,
              5010952.9154104954
            ],
            [
              352605.06560819066,
              5010950.3399082273
            ],
            [
              352618.26273018966,
              5010927.4369010366
            ],
            [
              352633.51913216797,
              5010896.1620193915
            ],
            [
              352622.03985009441,
              5010880.8866262464
            ],
            [
              352655.1538108571,
              5010836.9947813582
            ],
            [
              352672.79722588795,
              5010811.9077669373
            ],
            [
              352674.39763012383,
              5010810.0471115131
            ],
            [
              352675.77789538674,
              5010807.5933255116
            ],
            [
              352682.59771435003,
              5010798.7520403843
            ],
            [
              352687.90641172073,
              5010791.057720446
            ],
            [
              352686.5010921451,
              5010790.3989051729
            ],
            [
              352682.06874543062,
              5010786.2557157632
            ],
            [
              352689.84985595773,
              5010780.7490231479
            ],
            [
              352685.13258696481,
              5010775.8483063681
            ],
            [
              352684.5388899002,
              5010767.6071615666
            ],
            [
              352690.05715582467,
              5010762.4431511415
            ],
            [
              352691.24879345571,
              5010755.4891240187
            ],
            [
              352690.41862683109,
              5010746.3721644515
            ],
            [
              352686.81930383242,
              5010742.2580287308
            ],
            [
              352688.21513887757,
              5010740.3615613151
            ],
            [
              352677.68439418805,
              5010730.5448609237
            ],
            [
              352679.39195450279,
              5010727.6523468755
            ],
            [
              352667.58462089475,
              5010714.7222426096
            ],
            [
              352663.11971111206,
              5010717.3360047275
            ],
            [
              352666.33063493081,
              5010706.4933838248
            ],
            [
              352677.22379838018,
              5010712.1222297214
            ],
            [
              352680.72440702189,
              5010705.0152393859
            ],
            [
              352681.24142909417,
              5010697.3035812471
            ],
            [
              352684.76297735318,
              5010697.2380530844
            ],
            [
              352690.9333986807,
              5010678.7956907786
            ],
            [
              352703.12273870595,
              5010659.4238134623
            ],
            [
              352725.9977904011,
              5010675.9426360037
            ],
            [
              352726.43955820886,
              5010670.1028728811
            ],
            [
              352733.79290947603,
              5010651.1510770507
            ],
            [
              352720.38548128895,
              5010652.3908035764
            ],
            [
              352716.00354148535,
              5010647.6310768006
            ],
            [
              352710.80285812909,
              5010634.3652650975
            ],
            [
              352698.68226657173,
              5010621.9374347553
            ],
            [
              352698.24125903373,
              5010621.4852483226
            ],
            [
              352695.52026218636,
              5010617.0152172744
            ],
            [
              352685.51231935003,
              5010614.998871766
            ],
            [
              352689.29568851861,
              5010605.5509875827
            ],
            [
              352686.88266525586,
              5010602.9495962569
            ],
            [
              352683.34061099688,
              5010601.5875867205
            ],
            [
              352687.44691857143,
              5010586.0235510496
            ],
            [
              352692.94507765089,
              5010574.3879326656
            ],
            [
              352701.22041932167,
              5010564.3482264588
            ],
            [
              352707.78798596474,
              5010568.8393783811
            ],
            [
              352707.25504497602,
              5010569.728006376
            ],
            [
              352730.0113118529,
              5010581.9363281829
            ],
            [
              352739.79545703874,
              5010588.0152147803
            ],
            [
              352742.80963250448,
              5010581.4808028499
            ],
            [
              352773.84792175685,
              5010605.7777392985
            ],
            [
              352784.72891437105,
              5010611.9976981394
            ],
            [
              352794.04660262022,
              5010619.4144924842
            ],
            [
              352815.45032819622,
              5010594.6609763466
            ],
            [
              352821.69598925259,
              5010591.5205769585
            ],
            [
              352846.6866374393,
              5010602.5659233546
            ],
            [
              352852.95812306902,
              5010606.6525647659
            ],
            [
              352876.9220188391,
              5010625.3071532845
            ],
            [
              352882.39869499061,
              5010619.194454154
            ],
            [
              352884.77582941268,
              5010621.3242489509
            ],
            [
              352887.46383211523,
              5010617.7822612775
            ],
            [
              352892.90760706813,
              5010610.6089860685
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 332,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351638.93209729297,
              5010857.5352665586
            ],
            [
              351638.82388042222,
              5010857.4230547929
            ],
            [
              351642.61608786992,
              5010861.3552540224
            ],
            [
              351652.06567912136,
              5010870.4707171312
            ],
            [
              351667.87082261214,
              5010882.6643457655
            ],
            [
              351673.19836491451,
              5010888.4929410117
            ],
            [
              351676.3886484488,
              5010894.2032227721
            ],
            [
              351678.45425928757,
              5010906.3204648467
            ],
            [
              351683.89571819763,
              5010922.796308483
            ],
            [
              351684.16725152003,
              5010923.2807412948
            ],
            [
              351693.20363310259,
              5010937.0209228089
            ],
            [
              351698.67781873379,
              5010946.978224298
            ],
            [
              351708.66440938221,
              5010956.9848620873
            ],
            [
              351716.37081829744,
              5010962.4761310508
            ],
            [
              351725.86334635346,
              5010965.3134175623
            ],
            [
              351735.39238464407,
              5010970.1155832671
            ],
            [
              351741.46758215345,
              5010972.4922598051
            ],
            [
              351745.17627219338,
              5010974.5198880406
            ],
            [
              351747.96273096971,
              5010976.3025770178
            ],
            [
              351764.31524610787,
              5010984.5000421852
            ],
            [
              351758.37343570514,
              5010989.7681879876
            ],
            [
              351742.35652598069,
              5010999.51716254
            ],
            [
              351727.71960440901,
              5011009.0933248866
            ],
            [
              351711.1745903609,
              5011022.0494755907
            ],
            [
              351723.62415932439,
              5011035.8390134927
            ],
            [
              351713.00196808885,
              5011049.7957877163
            ],
            [
              351715.54149589693,
              5011059.4454375878
            ],
            [
              351726.06655430165,
              5011075.4984464319
            ],
            [
              351732.02819137141,
              5011084.3808153095
            ],
            [
              351742.66184007318,
              5011092.1763179693
            ],
            [
              351747.85669401585,
              5011103.6110949097
            ],
            [
              351758.08795500209,
              5011117.9660775056
            ],
            [
              351764.64404647792,
              5011125.0512214033
            ],
            [
              351750.22297563223,
              5011132.1336117908
            ],
            [
              351742.63844828581,
              5011133.192039513
            ],
            [
              351728.97477329732,
              5011125.2747360431
            ],
            [
              351698.95482928905,
              5011103.1455535144
            ],
            [
              351690.28806200047,
              5011097.0594353648
            ],
            [
              351651.19703978155,
              5011061.7613994814
            ],
            [
              351616.67170091328,
              5011032.1309789373
            ],
            [
              351579.93116106157,
              5010985.3798618568
            ],
            [
              351573.89293813444,
              5010977.7410308765
            ],
            [
              351551.88567063032,
              5010943.005317146
            ],
            [
              351550.13774119946,
              5010933.5073151896
            ],
            [
              351549.83391898894,
              5010931.9798954967
            ],
            [
              351549.59756664099,
              5010929.4862522744
            ],
            [
              351549.75108218443,
              5010926.8562174616
            ],
            [
              351550.39434655063,
              5010923.4082874143
            ],
            [
              351551.5326896772,
              5010919.3448728649
            ],
            [
              351553.07768519648,
              5010915.3749364978
            ],
            [
              351554.53238252207,
              5010912.013371611
            ],
            [
              351556.77516001655,
              5010907.5252241557
            ],
            [
              351558.83810687793,
              5010904.2529668342
            ],
            [
              351561.11096646049,
              5010901.3814039249
            ],
            [
              351564.08802048262,
              5010898.2942272536
            ],
            [
              351570.29064843262,
              5010892.3689109739
            ],
            [
              351575.96601072728,
              5010887.5137532325
            ],
            [
              351580.46166979021,
              5010884.4993634103
            ],
            [
              351583.36534221546,
              5010882.9296513768
            ],
            [
              351584.76998734864,
              5010882.2972414726
            ],
            [
              351586.57486446359,
              5010881.4552933192
            ],
            [
              351588.0765449811,
              5010880.6185678802
            ],
            [
              351589.97846527828,
              5010879.5727233374
            ],
            [
              351591.28042830032,
              5010878.8411788978
            ],
            [
              351592.17683724448,
              5010878.1171811372
            ],
            [
              351593.3753367574,
              5010877.2865166888
            ],
            [
              351595.17833420995,
              5010876.3435582649
            ],
            [
              351596.38247260964,
              5010875.8159247926
            ],
            [
              351597.58848283754,
              5010875.3888825197
            ],
            [
              351599.10144890466,
              5010875.1586381905
            ],
            [
              351600.71897668199,
              5010875.1285387436
            ],
            [
              351602.03143604216,
              5010875.0030708052
            ],
            [
              351602.93802453257,
              5010874.7841101382
            ],
            [
              351603.84195197717,
              5010874.464153694
            ],
            [
              351604.94340826449,
              5010873.8373856861
            ],
            [
              351606.84344886005,
              5010872.690530912
            ],
            [
              351609.24420706823,
              5010871.2312227674
            ],
            [
              351612.5953530487,
              5010869.3022510177
            ],
            [
              351616.29839098378,
              5010867.2124379734
            ],
            [
              351618.70102097798,
              5010865.8537210897
            ],
            [
              351620.2064684481,
              5010865.2194355587
            ],
            [
              351622.71930680791,
              5010864.3643136462
            ],
            [
              351625.53345428436,
              5010863.4025396146
            ],
            [
              351628.04817223659,
              5010862.6484280434
            ],
            [
              351630.35862423829,
              5010861.797072229
            ],
            [
              351633.36951128725,
              5010860.5280821184
            ],
            [
              351635.87671068549,
              5010859.3699293435
            ],
            [
              351637.87645539216,
              5010858.1201741453
            ],
            [
              351638.93209729297,
              5010857.5352665586
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 333,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351723.88978258288,
              5012076.7346030334
            ],
            [
              351730.33994664351,
              5012087.6391579816
            ],
            [
              351729.79942730122,
              5012111.9154640986
            ],
            [
              351737.6068496019,
              5012115.5390351573
            ],
            [
              351739.1712132704,
              5012121.3946643258
            ],
            [
              351739.50632803753,
              5012124.2194608254
            ],
            [
              351737.31774024724,
              5012134.5842410224
            ],
            [
              351756.8098620263,
              5012175.7819937095
            ],
            [
              351745.90571766387,
              5012179.6982853319
            ],
            [
              351757.19516646193,
              5012188.769409908
            ],
            [
              351808.11995253776,
              5012230.5129176769
            ],
            [
              351817.02114089328,
              5012220.9751704596
            ],
            [
              351819.6465707075,
              5012216.082265703
            ],
            [
              351851.15514948854,
              5012231.192552113
            ],
            [
              351853.36875363934,
              5012224.0946503812
            ],
            [
              351866.62135432195,
              5012228.1559779849
            ],
            [
              351869.16024379706,
              5012220.3453755351
            ],
            [
              351896.12110332976,
              5012216.3471950451
            ],
            [
              351891.59126966243,
              5012196.4543260224
            ],
            [
              351900.99878026062,
              5012177.8807226811
            ],
            [
              351907.34003432631,
              5012164.2755676238
            ],
            [
              351922.39215548825,
              5012127.4384118458
            ],
            [
              351940.22290230478,
              5012104.2899937611
            ],
            [
              351956.31347940379,
              5012113.0157273989
            ],
            [
              351972.65477227391,
              5012126.8116954742
            ],
            [
              351988.69346489693,
              5012143.6509603066
            ],
            [
              351999.91975867283,
              5012152.6700688954
            ],
            [
              352012.73332566337,
              5012165.2088498622
            ],
            [
              352013.80363776034,
              5012162.7551570283
            ],
            [
              352019.62745677406,
              5012137.7006573258
            ],
            [
              352022.5273725568,
              5012123.8085032813
            ],
            [
              352028.73630121822,
              5012113.0147947697
            ],
            [
              352053.66804218391,
              5012087.7537308354
            ],
            [
              352070.2180252127,
              5012056.7649219548
            ],
            [
              352077.16305657156,
              5012046.7761347331
            ],
            [
              352087.10049095401,
              5012032.3993837787
            ],
            [
              352103.45267017133,
              5012008.0780702317
            ],
            [
              352097.50937428878,
              5011997.5820588665
            ],
            [
              352098.86690275901,
              5011992.4487449601
            ],
            [
              352109.20237091865,
              5011981.9301933376
            ],
            [
              352100.06429636938,
              5011969.2066145902
            ],
            [
              352089.06886214117,
              5011962.0341333542
            ],
            [
              352086.93535087135,
              5011961.1522074677
            ],
            [
              352086.27873427427,
              5011947.5386725944
            ],
            [
              352086.90687506256,
              5011939.1704643695
            ],
            [
              352093.40221801068,
              5011930.1707874257
            ],
            [
              352083.54480457847,
              5011919.0633362196
            ],
            [
              352092.85136238323,
              5011910.3519258732
            ],
            [
              352086.16749852046,
              5011902.5213553291
            ],
            [
              352082.88453948457,
              5011905.5581350168
            ],
            [
              352076.55241566227,
              5011896.5073733227
            ],
            [
              352060.66737358313,
              5011879.7438337244
            ],
            [
              352035.88024323102,
              5011907.2898879582
            ],
            [
              352030.70178582525,
              5011903.9769987548
            ],
            [
              352033.26770243212,
              5011899.288843493
            ],
            [
              352028.29452305095,
              5011896.8244667631
            ],
            [
              352044.96014940285,
              5011870.7553640986
            ],
            [
              352031.02351443499,
              5011870.3518411564
            ],
            [
              352028.05879240407,
              5011870.0023936629
            ],
            [
              352000.80416714569,
              5011861.7023335528
            ],
            [
              351975.51136406366,
              5011862.0783620402
            ],
            [
              351953.37655274855,
              5011857.9123513959
            ],
            [
              351935.80729240918,
              5011857.658386481
            ],
            [
              351935.92117078154,
              5011862.8651959579
            ],
            [
              351933.55666069925,
              5011881.5443088682
            ],
            [
              351938.58556640398,
              5011883.2806957532
            ],
            [
              351932.77861772472,
              5011913.671316633
            ],
            [
              351927.36310939479,
              5011913.4115792401
            ],
            [
              351921.74302480445,
              5011915.0783844199
            ],
            [
              351895.39281091501,
              5011907.6376566421
            ],
            [
              351872.1463389686,
              5011907.476965325
            ],
            [
              351864.23024073319,
              5011907.0163764982
            ],
            [
              351860.72028411791,
              5011931.3079266474
            ],
            [
              351862.81005217892,
              5011938.2609204547
            ],
            [
              351874.58905987011,
              5011972.5325346198
            ],
            [
              351884.77815121994,
              5011972.4786906494
            ],
            [
              351918.50969991583,
              5011962.1381401019
            ],
            [
              351969.80027536018,
              5011949.2184875123
            ],
            [
              351984.92621828959,
              5011947.1940596877
            ],
            [
              352002.24912856106,
              5011948.0421511261
            ],
            [
              352024.89338634966,
              5011948.1409222754
            ],
            [
              352036.29533823353,
              5011952.480659592
            ],
            [
              352033.44559355144,
              5011958.2587188417
            ],
            [
              352023.19463766523,
              5011978.0097219506
            ],
            [
              352018.66366346349,
              5011985.26386052
            ],
            [
              352007.27517638862,
              5011981.6532305656
            ],
            [
              351966.89702678594,
              5011982.2518425817
            ],
            [
              351929.24106557551,
              5011989.3745973799
            ],
            [
              351939.33758225577,
              5012003.401797221
            ],
            [
              351918.66588678968,
              5012019.1177108344
            ],
            [
              351905.86631383962,
              5012032.4810947366
            ],
            [
              351897.83463732857,
              5012044.3697593091
            ],
            [
              351903.63716255064,
              5012051.1015489064
            ],
            [
              351893.05373888096,
              5012064.1924460419
            ],
            [
              351881.8633169097,
              5012091.7968553901
            ],
            [
              351884.29246198683,
              5012093.0599973379
            ],
            [
              351872.69971016579,
              5012127.4430424105
            ],
            [
              351869.85053926212,
              5012136.3797859978
            ],
            [
              351867.93323680217,
              5012145.3869352313
            ],
            [
              351866.12769360078,
              5012145.2505209092
            ],
            [
              351861.17776693025,
              5012144.8765387721
            ],
            [
              351858.61438053922,
              5012144.7079606792
            ],
            [
              351853.9089869028,
              5012141.1183227347
            ],
            [
              351846.2138141258,
              5012136.9783352502
            ],
            [
              351841.12763197051,
              5012129.7510503186
            ],
            [
              351837.85788604378,
              5012131.5706741503
            ],
            [
              351823.32833916327,
              5012118.4615778271
            ],
            [
              351818.22034633241,
              5012109.5414065998
            ],
            [
              351796.95640235062,
              5012089.3591911802
            ],
            [
              351757.00631401275,
              5012053.3782506119
            ],
            [
              351740.27085251355,
              5012052.9699481614
            ],
            [
              351736.52214610187,
              5012061.6144619593
            ],
            [
              351733.96600710193,
              5012065.6028014328
            ],
            [
              351731.99823482503,
              5012068.5701570585
            ],
            [
              351728.83086785924,
              5012072.2671561623
            ],
            [
              351726.11217523302,
              5012074.9134871466
            ],
            [
              351723.88978258288,
              5012076.7346030334
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 334,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351694.58429476892,
              5012080.8871226059
            ],
            [
              351721.51026297669,
              5012160.264538032
            ],
            [
              351745.90571766387,
              5012179.6982853319
            ],
            [
              351756.80986202712,
              5012175.7819937114
            ],
            [
              351737.31774024625,
              5012134.5842410233
            ],
            [
              351739.50632803765,
              5012124.2194608273
            ],
            [
              351739.1712132704,
              5012121.3946643258
            ],
            [
              351737.6068496019,
              5012115.5390351573
            ],
            [
              351729.79942730122,
              5012111.9154640986
            ],
            [
              351730.33994664531,
              5012087.6391579844
            ],
            [
              351724.21927245526,
              5012076.4646060811
            ],
            [
              351722.3263696733,
              5012078.0157250268
            ],
            [
              351718.83058506629,
              5012080.404829015
            ],
            [
              351716.02772950468,
              5012081.9730935125
            ],
            [
              351712.9171360879,
              5012083.3445757823
            ],
            [
              351710.4043014567,
              5012084.1997063514
            ],
            [
              351708.79053429782,
              5012084.4318320286
            ],
            [
              351708.38388182299,
              5012084.3383553
            ],
            [
              351707.95921091666,
              5012083.2347612176
            ],
            [
              351707.33137235249,
              5012082.1349484529
            ],
            [
              351706.50380894379,
              5012081.139898194
            ],
            [
              351705.17066282238,
              5012080.1538384622
            ],
            [
              351703.94772683369,
              5012079.6713731457
            ],
            [
              351702.3226797409,
              5012079.2974371128
            ],
            [
              351700.8059549926,
              5012079.3256660923
            ],
            [
              351698.88445773505,
              5012079.3614286175
            ],
            [
              351697.47495675395,
              5012079.6907977918
            ],
            [
              351695.26531067473,
              5012080.5402854839
            ],
            [
              351694.58429476892,
              5012080.8871226059
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 335,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351742.63844828581,
              5011133.192039513
            ],
            [
              351742.63844828488,
              5011133.192039514
            ],
            [
              351728.97477329458,
              5011125.2747360431
            ],
            [
              351698.95482927706,
              5011103.1455535004
            ],
            [
              351690.28806200047,
              5011097.0594353648
            ],
            [
              351651.1970397635,
              5011061.7613994656
            ],
            [
              351616.67170091328,
              5011032.1309789373
            ],
            [
              351579.93116117537,
              5010985.379862031
            ],
            [
              351573.89293813909,
              5010977.7410308821
            ],
            [
              351551.88567063079,
              5010943.0053171478
            ],
            [
              351550.13774115738,
              5010933.5073149605
            ],
            [
              351550.09380082058,
              5010935.1078834524
            ],
            [
              351549.82068637869,
              5010936.7296906859
            ],
            [
              351549.44865692005,
              5010938.4548031921
            ],
            [
              351548.86217400705,
              5010939.5772151863
            ],
            [
              351547.67949311004,
              5010941.2159481114
            ],
            [
              351545.49240683293,
              5010943.2779720472
            ],
            [
              351544.70619863144,
              5010944.5051459763
            ],
            [
              351544.42102490197,
              5010945.520905775
            ],
            [
              351544.33901894948,
              5010946.5328849209
            ],
            [
              351544.56208148209,
              5010947.6402325192
            ],
            [
              351544.97547884245,
              5010948.1381856492
            ],
            [
              351546.10137066309,
              5010948.8245516689
            ],
            [
              351548.54645921756,
              5010949.7895053234
            ],
            [
              351550.59131576732,
              5010950.9639973128
            ],
            [
              351552.64369092177,
              5010952.5425306279
            ],
            [
              351554.08593692846,
              5010953.9307461847
            ],
            [
              351555.32390912925,
              5010955.2212983826
            ],
            [
              351556.6796006909,
              5010957.4190677796
            ],
            [
              351557.21525814122,
              5010959.0262442222
            ],
            [
              351557.97397037206,
              5010961.7403490627
            ],
            [
              351558.32603806228,
              5010964.3613949288
            ],
            [
              351558.65930139745,
              5010965.971918337
            ],
            [
              351558.98128679005,
              5010966.9763797494
            ],
            [
              351559.81449412362,
              5010968.2748832516
            ],
            [
              351561.05246635393,
              5010969.5654353732
            ],
            [
              351562.59362740471,
              5010970.8503455771
            ],
            [
              351564.27588657645,
              5010972.4085131688
            ],
            [
              351565.31335211801,
              5010973.8038417064
            ],
            [
              351566.043877338,
              5010975.0032106396
            ],
            [
              351566.67923128052,
              5010976.5070672128
            ],
            [
              351567.21864032623,
              5010978.3158450993
            ],
            [
              351567.46912425366,
              5010980.9387812195
            ],
            [
              351567.56311518617,
              5010985.9897168214
            ],
            [
              351567.58755081386,
              5010987.3028511051
            ],
            [
              351567.80873379565,
              5010988.3091882663
            ],
            [
              351568.22290480277,
              5010988.8067076476
            ],
            [
              351568.83304174594,
              5010988.9974444471
            ],
            [
              351569.5410032373,
              5010988.9842702504
            ],
            [
              351570.84970333998,
              5010988.6567812087
            ],
            [
              351571.45686236297,
              5010988.6454828074
            ],
            [
              351572.47099056869,
              5010988.8287018808
            ],
            [
              351573.39074450987,
              5010989.3172323182
            ],
            [
              351575.13617157406,
              5010990.6993865743
            ],
            [
              351577.6075753523,
              5010993.0784844263
            ],
            [
              351580.30095133214,
              5010996.4643239407
            ],
            [
              351584.14309198374,
              5011001.8496924872
            ],
            [
              351587.99729210831,
              5011007.8411083138
            ],
            [
              351595.82462945645,
              5011018.9903037744
            ],
            [
              351598.83811139496,
              5011023.2795938347
            ],
            [
              351600.3905505187,
              5011025.1705655698
            ],
            [
              351601.63604156947,
              5011026.8651586939
            ],
            [
              351602.67351497157,
              5011028.2609059811
            ],
            [
              351603.61127642746,
              5011029.7591348561
            ],
            [
              351604.3455533188,
              5011031.1601050496
            ],
            [
              351605.09111606173,
              5011033.1675562551
            ],
            [
              351605.6324050292,
              5011035.0773441717
            ],
            [
              351606.27073709498,
              5011036.7832356384
            ],
            [
              351607.31760897522,
              5011038.6840345487
            ],
            [
              351608.36071368068,
              5011040.3823936535
            ],
            [
              351609.81611757504,
              5011042.4776808508
            ],
            [
              351611.80335799273,
              5011044.840631268
            ],
            [
              351615.32351337856,
              5011049.2215375537
            ],
            [
              351621.12365558202,
              5011056.4907487342
            ],
            [
              351623.08948440716,
              5011058.8792539686
            ],
            [
              351624.53736959473,
              5011060.5704997284
            ],
            [
              351625.97694683552,
              5011061.8572996492
            ],
            [
              351627.72989265685,
              5011063.6434945604
            ],
            [
              351629.69275134074,
              5011065.830383664
            ],
            [
              351631.76016403944,
              5011068.2169984104
            ],
            [
              351633.31449075317,
              5011070.2093992112
            ],
            [
              351634.88008778897,
              5011072.8074426986
            ],
            [
              351636.03340128978,
              5011075.0093964571
            ],
            [
              351637.28829084395,
              5011077.2090406716
            ],
            [
              351638.74666499236,
              5011079.505943547
            ],
            [
              351640.12772201066,
              5011081.4705422567
            ],
            [
              351641.67828156526,
              5011083.2605031896
            ],
            [
              351644.05265024136,
              5011085.8439154802
            ],
            [
              351647.04168876249,
              5011088.8196512414
            ],
            [
              351652.08686964953,
              5011093.5763586843
            ],
            [
              351655.27752091328,
              5011096.5487618474
            ],
            [
              351659.17989252316,
              5011099.7100108396
            ],
            [
              351663.80149634654,
              5011103.4637284903
            ],
            [
              351669.23939022276,
              5011107.6068558963
            ],
            [
              351676.21280609554,
              5011112.731861474
            ],
            [
              351681.17829843779,
              5011116.3567506922
            ],
            [
              351685.28415478277,
              5011119.6152581023
            ],
            [
              351688.57826165628,
              5011122.6863614814
            ],
            [
              351691.77157383662,
              5011125.7597597064
            ],
            [
              351694.96598439262,
              5011128.9341827957
            ],
            [
              351696.20395674597,
              5011130.2247338425
            ],
            [
              351697.33282642596,
              5011131.1131340843
            ],
            [
              351699.17529644835,
              5011132.2913907114
            ],
            [
              351700.401998349,
              5011132.9762991136
            ],
            [
              351701.82355988858,
              5011133.2529810071
            ],
            [
              351703.44484656636,
              5011133.4249009341
            ],
            [
              351704.96909022727,
              5011133.8007172775
            ],
            [
              351706.4933338798,
              5011134.1765336124
            ],
            [
              351708.02697605622,
              5011135.0574013852
            ],
            [
              351711.00207509066,
              5011137.3260787148
            ],
            [
              351715.52100402472,
              5011140.9810797432
            ],
            [
              351716.54907125735,
              5011141.8713557459
            ],
            [
              351717.46427639882,
              5011142.1574604157
            ],
            [
              351718.38026295026,
              5011142.4435505429
            ],
            [
              351719.49221521587,
              5011142.4228581358
            ],
            [
              351720.80921483849,
              5011142.4993952522
            ],
            [
              351722.52943195618,
              5011142.5688481303
            ],
            [
              351724.05179583078,
              5011142.8436541278
            ],
            [
              351725.57979882479,
              5011143.421490971
            ],
            [
              351726.60034719354,
              5011143.9077258091
            ],
            [
              351727.62089555897,
              5011144.3939606482
            ],
            [
              351728.24309197062,
              5011145.1907442287
            ],
            [
              351728.86638667848,
              5011146.0885526314
            ],
            [
              351729.48780167784,
              5011146.885350748
            ],
            [
              351730.00089542283,
              5011147.279983581
            ],
            [
              351731.18713331356,
              5011147.8067396656
            ],
            [
              351778.81974298233,
              5011165.3070846042
            ],
            [
              351782.08359158825,
              5011153.0209695548
            ],
            [
              351775.20700719819,
              5011150.1522357576
            ],
            [
              351757.48440804769,
              5011142.2152869888
            ],
            [
              351742.63844828581,
              5011133.192039513
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 336,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354890.52571662288,
              5008646.98494526
            ],
            [
              354895.89735936298,
              5008639.409979105
            ],
            [
              354905.67084983451,
              5008626.2865215596
            ],
            [
              354901.87969888066,
              5008625.2756695636
            ],
            [
              354897.31580795086,
              5008626.5364221763
            ],
            [
              354890.56922292069,
              5008628.4001334244
            ],
            [
              354878.70826717798,
              5008620.3657495035
            ],
            [
              354876.07442333468,
              5008617.3703827132
            ],
            [
              354873.0911908609,
              5008614.8339112755
            ],
            [
              354858.95425580646,
              5008619.2782704625
            ],
            [
              354844.3361031785,
              5008618.3277645381
            ],
            [
              354835.69645748631,
              5008619.9973287983
            ],
            [
              354825.18950808427,
              5008625.0214609159
            ],
            [
              354815.32333608688,
              5008621.0915214401
            ],
            [
              354809.06704491697,
              5008631.684383899
            ],
            [
              354787.52926954458,
              5008676.1919882493
            ],
            [
              354761.15715245629,
              5008736.9528508354
            ],
            [
              354778.3627992869,
              5008746.2305557318
            ],
            [
              354807.18603988102,
              5008762.0599220796
            ],
            [
              354823.02709318843,
              5008750.967099363
            ],
            [
              354825.67074480728,
              5008741.1383726355
            ],
            [
              354840.53649911255,
              5008716.0847083498
            ],
            [
              354844.30578277359,
              5008712.9406251898
            ],
            [
              354841.38454127382,
              5008709.0871244082
            ],
            [
              354849.60465418187,
              5008702.1120260498
            ],
            [
              354854.30164689902,
              5008698.1168107837
            ],
            [
              354861.50471584481,
              5008678.5385858538
            ],
            [
              354860.80043850176,
              5008676.2996804845
            ],
            [
              354876.74487279769,
              5008660.6366353408
            ],
            [
              354875.21909606439,
              5008659.1173806898
            ],
            [
              354890.52571662288,
              5008646.98494526
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 337,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354650.13666176092,
              5008751.0059742592
            ],
            [
              354625.97895627253,
              5008721.5200541159
            ],
            [
              354630.46019427711,
              5008716.7491137814
            ],
            [
              354589.25099993142,
              5008691.4182696007
            ],
            [
              354589.14226194017,
              5008670.2665791344
            ],
            [
              354580.09204052505,
              5008652.1844961746
            ],
            [
              354579.84256835468,
              5008649.6591594126
            ],
            [
              354576.77493711404,
              5008631.7025704235
            ],
            [
              354576.94475898379,
              5008619.4273896767
            ],
            [
              354532.86825923523,
              5008621.0257114461
            ],
            [
              354523.26811722893,
              5008616.2807750618
            ],
            [
              354518.72647765552,
              5008600.4061589772
            ],
            [
              354511.03444175143,
              5008588.6648161272
            ],
            [
              354510.80934569298,
              5008521.7766558528
            ],
            [
              354549.28274242178,
              5008487.9522789549
            ],
            [
              354580.02706438961,
              5008468.6461743768
            ],
            [
              354629.1935050204,
              5008444.7414990952
            ],
            [
              354665.56869566813,
              5008421.7470150404
            ],
            [
              354709.66933340026,
              5008383.1943439152
            ],
            [
              354728.11844051414,
              5008368.7603590311
            ],
            [
              354749.65622388903,
              5008354.4783408465
            ],
            [
              354781.35007031,
              5008381.0097044399
            ],
            [
              354793.91443390137,
              5008366.0005625896
            ],
            [
              354818.07882313029,
              5008385.4969690964
            ],
            [
              354796.4212918604,
              5008455.1659824429
            ],
            [
              354791.01404957386,
              5008481.8948393026
            ],
            [
              354812.06402793917,
              5008498.0342403809
            ],
            [
              354815.97975863382,
              5008530.3482080074
            ],
            [
              354818.03332371975,
              5008565.9506234638
            ],
            [
              354824.01752350351,
              5008569.1362361573
            ],
            [
              354824.90430841054,
              5008569.5628449852
            ],
            [
              354826.92379371304,
              5008573.6339444956
            ],
            [
              354827.58271613799,
              5008570.9943311242
            ],
            [
              354828.33710232336,
              5008568.0497487802
            ],
            [
              354828.96732090577,
              5008563.9454301642
            ],
            [
              354829.33609129413,
              5008562.0185588561
            ],
            [
              354830.0025303142,
              5008559.7830281435
            ],
            [
              354830.84862318361,
              5008556.331488613
            ],
            [
              354831.3297698375,
              5008555.0088426461
            ],
            [
              354831.91577682568,
              5008553.8863751106
            ],
            [
              354832.40256140468,
              5008552.8668176541
            ],
            [
              354832.88370807021,
              5008551.5441717114
            ],
            [
              354833.34982226964,
              5008549.4133960642
            ],
            [
              354833.90945551632,
              5008546.8766590459
            ],
            [
              354834.46351843869,
              5008544.0369029678
            ],
            [
              354835.0175807036,
              5008541.1971114688
            ],
            [
              354836.48793034296,
              5008533.4276111629
            ],
            [
              354837.85426252376,
              5008525.317962327
            ],
            [
              354838.80679347488,
              5008516.7107951045
            ],
            [
              354839.07170007267,
              5008513.7109395517
            ],
            [
              354839.44986651471,
              5008512.2891803477
            ],
            [
              354840.32797003735,
              5008510.5549281975
            ],
            [
              354841.39505838079,
              5008508.1098516816
            ],
            [
              354842.06713428459,
              5008506.1773385722
            ],
            [
              354842.54076528642,
              5008504.4506457867
            ],
            [
              354842.71296972578,
              5008502.8305888465
            ],
            [
              354842.59489014361,
              5008501.923311743
            ],
            [
              354842.17169232649,
              5008500.9206457911
            ],
            [
              354841.13987297349,
              5008499.8282721043
            ],
            [
              354840.21485879441,
              5008499.0370695293
            ],
            [
              354839.49580601929,
              5008498.4441291969
            ],
            [
              354839.05010375142,
              5008497.5935612898
            ],
            [
              354838.76087866054,
              5008496.0384521699
            ],
            [
              354840.13781671628,
              5008494.8953216663
            ],
            [
              354840.93166456633,
              5008494.0721459482
            ],
            [
              354841.32110547292,
              5008493.2564927358
            ],
            [
              354841.70296364935,
              5008492.0367582878
            ],
            [
              354841.7815166277,
              5008490.8226657035
            ],
            [
              354841.45754195616,
              5008489.7171251383
            ],
            [
              354840.60732224252,
              5008487.509770954
            ],
            [
              354840.17090522166,
              5008485.8000062406
            ],
            [
              354839.83377692383,
              5008483.9873301974
            ],
            [
              354839.48906591436,
              5008481.7705728589
            ],
            [
              354839.4063875209,
              5008477.3258075071
            ],
            [
              354839.65066618426,
              5008475.009151781
            ],
            [
              354839.91263255588,
              5008472.7811450046
            ],
            [
              354840.47045329493,
              5008470.1433770955
            ],
            [
              354841.11816836888,
              5008466.8976569409
            ],
            [
              354841.27151662082,
              5008464.267448307
            ],
            [
              354841.33127893676,
              5008462.0431672558
            ],
            [
              354841.09337388276,
              5008460.1276165834
            ],
            [
              354840.45475287031,
              5008458.4215775514
            ],
            [
              354838.8985679709,
              5008456.3284195662
            ],
            [
              354837.76759253844,
              5008455.3389546275
            ],
            [
              354836.95300899557,
              5008455.0509490268
            ],
            [
              354836.14037029922,
              5008454.86393617
            ],
            [
              354835.13109969953,
              5008454.9837743044
            ],
            [
              354833.42914913647,
              5008455.9249062315
            ],
            [
              354831.23846884916,
              5008457.784602711
            ],
            [
              354830.45401660498,
              5008459.1128903888
            ],
            [
              354829.87552572781,
              5008460.6394403158
            ],
            [
              354829.22036039183,
              5008463.4810768412
            ],
            [
              354828.94705401902,
              5008465.1030144561
            ],
            [
              354828.16823830234,
              5008466.7343196189
            ],
            [
              354827.27704718715,
              5008467.7614349462
            ],
            [
              354826.28468806617,
              5008468.7904321207
            ],
            [
              354824.96804487979,
              5008469.7849994777
            ],
            [
              354823.27197401191,
              5008469.9580393992
            ],
            [
              354821.74779895507,
              5008469.5821686527
            ],
            [
              354820.41831158596,
              5008468.798489674
            ],
            [
              354819.28726914589,
              5008467.8089904226
            ],
            [
              354818.25363680738,
              5008466.6155858161
            ],
            [
              354817.63004860329,
              5008465.7177117337
            ],
            [
              354817.39959370013,
              5008464.206244695
            ],
            [
              354817.38080293004,
              5008463.1960561499
            ],
            [
              354817.34697995015,
              5008461.3777380828
            ],
            [
              354817.717630197,
              5008459.5518963272
            ],
            [
              354818.48329237656,
              5008457.213455596
            ],
            [
              354819.26022823033,
              5008455.4810853405
            ],
            [
              354820.63263338793,
              5008453.1313584726
            ],
            [
              354822.20905438747,
              5008450.8788656145
            ],
            [
              354823.98962440802,
              5008448.7236398188
            ],
            [
              354825.96488215064,
              5008446.1606058413
            ],
            [
              354827.73605715699,
              5008443.5003035786
            ],
            [
              354829.50723151205,
              5008440.8399658157
            ],
            [
              354831.27089011838,
              5008437.7755810795
            ],
            [
              354834.10313583695,
              5008432.7136482103
            ],
            [
              354835.76005562861,
              5008429.3480911925
            ],
            [
              354836.53135510546,
              5008427.3127036514
            ],
            [
              354837.10232972453,
              5008425.3820713004
            ],
            [
              354837.37187840109,
              5008423.5581102753
            ],
            [
              354837.63384512148,
              5008421.3301035361
            ],
            [
              354837.60002236319,
              5008419.511785578
            ],
            [
              354837.2553780278,
              5008417.2950269608
            ],
            [
              354837.01552769943,
              5008415.2784479139
            ],
            [
              354836.88248249743,
              5008413.5630400665
            ],
            [
              354836.94976151886,
              5008411.7428415194
            ],
            [
              354837.32041201205,
              5008409.9169998951
            ],
            [
              354837.49545298627,
              5008408.2391949669
            ],
            [
              354837.46726807469,
              5008406.7239655368
            ],
            [
              354837.4465982173,
              5008405.6127475323
            ],
            [
              354837.01393945556,
              5008404.1050061965
            ],
            [
              354836.67493298493,
              5008402.1913360637
            ],
            [
              354836.63359396457,
              5008399.9689355996
            ],
            [
              354836.70275231142,
              5008398.2497665389
            ],
            [
              354837.2887591577,
              5008397.127263695
            ],
            [
              354837.96647344349,
              5008395.4978393447
            ],
            [
              354838.23783462605,
              5008393.774873564
            ],
            [
              354838.11042663234,
              5008392.3625186998
            ],
            [
              354837.68152577651,
              5008391.056800832
            ],
            [
              354837.15534794779,
              5008389.9550213534
            ],
            [
              354836.53175998008,
              5008389.0571471825
            ],
            [
              354836.41374694806,
              5008388.1498687975
            ],
            [
              354836.19268733781,
              5008387.1434782604
            ],
            [
              354836.37992436701,
              5008386.3315508282
            ],
            [
              354837.07642858598,
              5008385.7122794334
            ],
            [
              354838.18485610798,
              5008385.4895681655
            ],
            [
              354840.10035073583,
              5008385.150780159
            ],
            [
              354841.40923497424,
              5008384.82327573
            ],
            [
              354842.41280222783,
              5008384.4003860448
            ],
            [
              354842.80788048584,
              5008383.8877859619
            ],
            [
              354842.98753558076,
              5008382.67181281
            ],
            [
              354842.87140182074,
              5008381.8655639486
            ],
            [
              354842.75526740268,
              5008381.0592795499
            ],
            [
              354841.81327598431,
              5008379.3589189285
            ],
            [
              354841.07543289493,
              5008377.7557898629
            ],
            [
              354840.54355176707,
              5008376.3509586044
            ],
            [
              354840.21581896394,
              5008375.0433589043
            ],
            [
              354840.39647344948,
              5008373.9452460827
            ],
            [
              354841.48805529514,
              5008372.8133391142
            ],
            [
              354842.69201370905,
              5008372.2856930718
            ],
            [
              354843.49149892275,
              5008371.7655706387
            ],
            [
              354844.18612390041,
              5008371.0452697817
            ],
            [
              354844.76837298373,
              5008369.7207435444
            ],
            [
              354845.16684559063,
              5008368.298421043
            ],
            [
              354846.13131747005,
              5008366.8659419445
            ],
            [
              354847.02069582889,
              5008365.7377962312
            ],
            [
              354847.711563666,
              5008364.8155074157
            ],
            [
              354848.29569201864,
              5008363.5920107067
            ],
            [
              354848.76556523883,
              5008361.6632591235
            ],
            [
              354849.21094535029,
              5008358.4213028634
            ],
            [
              354849.44667196489,
              5008354.7790240608
            ],
            [
              354849.57371432957,
              5008350.7345445631
            ],
            [
              354849.55116607324,
              5008349.5223326366
            ],
            [
              354849.23094968544,
              5008348.6188154407
            ],
            [
              354848.61299890571,
              5008348.0239942102
            ],
            [
              354847.90146269527,
              5008347.8351360569
            ],
            [
              354846.89031284984,
              5008347.8539443659
            ],
            [
              354845.7836983194,
              5008348.1776862685
            ],
            [
              354843.77273900999,
              5008348.8214076161
            ],
            [
              354842.4639209946,
              5008349.148910678
            ],
            [
              354841.15322375851,
              5008349.3753842264
            ],
            [
              354839.43617473316,
              5008349.5083873793
            ],
            [
              354838.42133263475,
              5008349.3251354415
            ],
            [
              354837.60487059219,
              5008348.9361356162
            ],
            [
              354836.98316197249,
              5008348.139290886
            ],
            [
              354836.96437154495,
              5008347.1291023875
            ],
            [
              354837.14785084856,
              5008346.1151514929
            ],
            [
              354837.9266671181,
              5008344.4838466346
            ],
            [
              354838.30859207868,
              5008343.2641111081
            ],
            [
              354838.58934905968,
              5008342.0462573636
            ],
            [
              354838.65474921878,
              5008340.1250293124
            ],
            [
              354838.32513797487,
              5008338.7164356029
            ],
            [
              354837.2670124367,
              5008336.2097904086
            ],
            [
              354836.43934189703,
              5008335.2146477932
            ],
            [
              354835.82514959184,
              5008334.8218855392
            ],
            [
              354834.91140923003,
              5008334.636752977
            ],
            [
              354833.69805586332,
              5008334.659322408
            ],
            [
              354832.79176619352,
              5008334.8783091251
            ],
            [
              354830.98488877993,
              5008335.6192632811
            ],
            [
              354829.9813214661,
              5008336.0421528118
            ],
            [
              354828.77172590856,
              5008336.2667457405
            ],
            [
              354827.45727078593,
              5008336.2911957605
            ],
            [
              354826.44048383029,
              5008336.0069510341
            ],
            [
              354825.21773557604,
              5008335.5244439766
            ],
            [
              354824.16422632762,
              5008334.7695799004
            ],
            [
              354823.02949272527,
              5008333.5780554935
            ],
            [
              354822.29346344032,
              5008332.075992506
            ],
            [
              354821.35341704846,
              5008330.4766244013
            ],
            [
              354820.51823085622,
              5008329.0774347335
            ],
            [
              354820.09872507415,
              5008328.2767932341
            ],
            [
              354819.47701647034,
              5008327.4799484229
            ],
            [
              354818.86094491038,
              5008326.9861566238
            ],
            [
              354817.43599249428,
              5008326.5073751407
            ],
            [
              354816.71881918842,
              5008326.0154639157
            ],
            [
              354815.68887888227,
              5008325.0240835948
            ],
            [
              354814.24513676181,
              5008323.5351490807
            ],
            [
              354813.71513505175,
              5008322.2313471464
            ],
            [
              354813.2825428893,
              5008320.7236044444
            ],
            [
              354812.84800522553,
              5008319.1148333075
            ],
            [
              354812.31612430193,
              5008317.710001857
            ],
            [
              354811.69253647176,
              5008316.8121274924
            ],
            [
              354810.04283307382,
              5008315.1249305652
            ],
            [
              354801.20998737606,
              5008307.9123751083
            ],
            [
              354799.98160203797,
              5008307.1268148804
            ],
            [
              354798.75697450124,
              5008306.5432781642
            ],
            [
              354797.3376590524,
              5008306.3675496001
            ],
            [
              354795.21613654186,
              5008306.5080760783
            ],
            [
              354793.29436157766,
              5008307.3765497496
            ],
            [
              354791.59969070245,
              5008308.7216485189
            ],
            [
              354790.12432180461,
              5008310.9724865267
            ],
            [
              354788.34575906344,
              5008313.2285449896
            ],
            [
              354787.25746806356,
              5008314.5627838885
            ],
            [
              354786.06351253705,
              5008315.5954359565
            ],
            [
              354784.96394056227,
              5008316.323199356
            ],
            [
              354783.8576345598,
              5008316.6469106106
            ],
            [
              354782.84837238176,
              5008316.7667280724
            ],
            [
              354781.6329679988,
              5008316.6882912405
            ],
            [
              354780.31112551282,
              5008316.3087011911
            ],
            [
              354778.48183362669,
              5008315.837505782
            ],
            [
              354777.36347302084,
              5008315.5551751126
            ],
            [
              354776.15072888014,
              5008315.5777331488
            ],
            [
              354774.7366976887,
              5008315.7050796431
            ],
            [
              354773.12857883965,
              5008316.2402137481
            ],
            [
              354771.62877694092,
              5008317.1775105
            ],
            [
              354768.438069642,
              5008319.6623440757
            ],
            [
              354762.362493489,
              5008324.7269477146
            ],
            [
              354760.1660096703,
              5008326.2834695606
            ],
            [
              354758.96751496912,
              5008327.1141174631
            ],
            [
              354757.56178057002,
              5008327.6454871222
            ],
            [
              354755.74673800083,
              5008327.9823815404
            ],
            [
              354753.53036813065,
              5008328.4277853705
            ],
            [
              354749.78898967517,
              5008328.4973782161
            ],
            [
              354748.0106618065,
              5008328.3707143059
            ],
            [
              354746.69633460621,
              5008328.3951619184
            ],
            [
              354744.87643681694,
              5008328.4290135792
            ],
            [
              354743.56398847111,
              5008328.5544706052
            ],
            [
              354742.05479218677,
              5008328.986720413
            ],
            [
              354740.3507268625,
              5008329.8267724207
            ],
            [
              354738.74824442138,
              5008330.6649348857
            ],
            [
              354737.14310954913,
              5008331.4025216149
            ],
            [
              354735.6339132344,
              5008331.8347714413
            ],
            [
              354733.41754323017,
              5008332.2801753143
            ],
            [
              354728.1632104945,
              5008332.5799992029
            ],
            [
              354722.60757048853,
              5008332.9864720432
            ],
            [
              354720.58716686635,
              5008333.1250976902
            ],
            [
              354719.17689317727,
              5008333.4544631271
            ],
            [
              354717.47173026943,
              5008334.1934912419
            ],
            [
              354716.67612561019,
              5008334.9156007897
            ],
            [
              354715.78457457974,
              5008335.9426281359
            ],
            [
              354714.90806225891,
              5008337.7781500183
            ],
            [
              354714.25537560758,
              5008340.7205773676
            ],
            [
              354713.58688442875,
              5008342.8553630039
            ],
            [
              354713.01542930945,
              5008344.7858356787
            ],
            [
              354712.23673447274,
              5008346.4170300523
            ],
            [
              354710.87046392419,
              5008349.0700169383
            ],
            [
              354709.19646035851,
              5008351.5262199249
            ],
            [
              354707.03004606219,
              5008354.6993121309
            ],
            [
              354704.91415272863,
              5008357.4366803309
            ],
            [
              354702.63377699751,
              5008359.9041624656
            ],
            [
              354699.25571578904,
              5008363.2008369751
            ],
            [
              354695.77950551227,
              5008366.5999622885
            ],
            [
              354691.69348907552,
              5008369.9098055363
            ],
            [
              354685.11609945766,
              5008375.1858342132
            ],
            [
              354679.43699484185,
              5008379.8388876878
            ],
            [
              354673.45470402634,
              5008384.4975809269
            ],
            [
              354669.45898951049,
              5008387.1990595069
            ],
            [
              354663.43766708655,
              5008391.3142646793
            ],
            [
              354657.63709304167,
              5008394.8580899127
            ],
            [
              354651.63412645995,
              5008398.4052607911
            ],
            [
              354642.82831134094,
              5008403.520652798
            ],
            [
              354634.41787076287,
              5008408.123468752
            ],
            [
              354629.3632088282,
              5008411.0052262638
            ],
            [
              354626.6562986002,
              5008412.2681105565
            ],
            [
              354623.45055952261,
              5008413.9448704943
            ],
            [
              354621.65132886596,
              5008415.089826582
            ],
            [
              354619.55564622226,
              5008416.6444746051
            ],
            [
              354617.26399696583,
              5008418.5059010778
            ],
            [
              354615.1747401348,
              5008420.3639821047
            ],
            [
              354612.58366253425,
              5008422.4330671309
            ],
            [
              354611.28358453099,
              5008423.2656052876
            ],
            [
              354609.98053796246,
              5008423.8965291157
            ],
            [
              354608.77264850406,
              5008424.2221304467
            ],
            [
              354607.55990377854,
              5008424.244688876
            ],
            [
              354605.62902001914,
              5008423.7749642143
            ],
            [
              354603.28851961036,
              5008423.0101449406
            ],
            [
              354602.36690629105,
              5008422.4210215556
            ],
            [
              354600.8295261559,
              5008421.3381300978
            ],
            [
              354599.18758664647,
              5008420.0550947404
            ],
            [
              354598.67074252362,
              5008419.4584421795
            ],
            [
              354598.05496790068,
              5008418.9642549763
            ],
            [
              354597.04006854835,
              5008418.7810444282
            ],
            [
              354595.52335617866,
              5008418.8092569793
            ],
            [
              354593.70721537783,
              5008419.0451280531
            ],
            [
              354592.68100379052,
              5008419.0642167414
            ],
            [
              354591.36405462126,
              5008418.1793138068
            ],
            [
              354590.13627869682,
              5008417.393796524
            ],
            [
              354589.41704996495,
              5008416.8009085264
            ],
            [
              354589.0988326575,
              5008415.9984724382
            ],
            [
              354588.23855647095,
              5008413.2858562013
            ],
            [
              354587.55951607012,
              5008411.0293902252
            ],
            [
              354586.94187033869,
              5008410.4346126439
            ],
            [
              354586.01649923983,
              5008409.6434702845
            ],
            [
              354584.08076793165,
              5008408.8711217204
            ],
            [
              354580.42891593202,
              5008408.3327836841
            ],
            [
              354575.16221012565,
              5008407.9255280979
            ],
            [
              354572.03471773473,
              5008408.3878805237
            ],
            [
              354570.12153296662,
              5008408.8276455142
            ],
            [
              354566.7077642862,
              5008410.2047227491
            ],
            [
              354565.70789593941,
              5008410.8295879019
            ],
            [
              354564.90101777291,
              5008410.9456411414
            ],
            [
              354564.19306231599,
              5008410.9588098908
            ],
            [
              354563.37491084036,
              5008410.4688063348
            ],
            [
              354562.14823232155,
              5008409.78431297
            ],
            [
              354561.43464019848,
              5008409.4944533082
            ],
            [
              354560.41974074213,
              5008409.3112427136
            ],
            [
              354559.5120609572,
              5008409.4291709717
            ],
            [
              354558.51219258475,
              5008410.0540361265
            ],
            [
              354557.5217186524,
              5008411.1839486398
            ],
            [
              354556.94087704091,
              5008412.6097940374
            ],
            [
              354556.06889609399,
              5008414.6469022697
            ],
            [
              354555.18298160512,
              5008415.9769586772
            ],
            [
              354554.04862741078,
              5008417.6504077669
            ],
            [
              354552.75684614124,
              5008418.8869693941
            ],
            [
              354550.26469857438,
              5008420.8535894053
            ],
            [
              354548.56251138676,
              5008421.7946518017
            ],
            [
              354546.25207269186,
              5008422.6459839847
            ],
            [
              354545.04606203403,
              5008423.0725948131
            ],
            [
              354544.03679920238,
              5008423.1924126726
            ],
            [
              354542.92109807086,
              5008423.0110771311
            ],
            [
              354541.48827706178,
              5008422.1283294177
            ],
            [
              354539.43593131454,
              5008420.5497945733
            ],
            [
              354538.51321257249,
              5008419.8592279479
            ],
            [
              354537.59535687376,
              5008419.4721233975
            ],
            [
              354536.17834837682,
              5008419.3974369224
            ],
            [
              354534.66351462371,
              5008419.5266589597
            ],
            [
              354532.85301018896,
              5008420.0655585136
            ],
            [
              354531.15083069174,
              5008421.007040089
            ],
            [
              354530.04937924654,
              5008421.6337948889
            ],
            [
              354529.25189545884,
              5008422.2548955474
            ],
            [
              354528.66806964675,
              5008423.4782884987
            ],
            [
              354528.39494565461,
              5008425.1000797618
            ],
            [
              354528.01273087895,
              5008426.3201417904
            ],
            [
              354527.22464140324,
              5008427.4462902555
            ],
            [
              354525.94413357408,
              5008429.2889085002
            ],
            [
              354525.25873255898,
              5008430.5146101555
            ],
            [
              354524.97997191706,
              5008431.8333730074
            ],
            [
              354524.49882663938,
              5008433.155900443
            ],
            [
              354523.61291209992,
              5008434.4859570423
            ],
            [
              354521.93703712017,
              5008436.8415714586
            ],
            [
              354519.17176566913,
              5008440.4299830496
            ],
            [
              354515.02518436831,
              5008445.8628885467
            ],
            [
              354511.94538742915,
              5008450.9484996255
            ],
            [
              354511.16669264477,
              5008452.579695221
            ],
            [
              354510.88417427812,
              5008453.6964391759
            ],
            [
              354510.70246532763,
              5008454.811727209
            ],
            [
              354510.72501213558,
              5008456.0238410141
            ],
            [
              354510.9537013963,
              5008457.434209168
            ],
            [
              354510.97812711279,
              5008458.747332437
            ],
            [
              354510.80769159453,
              5008460.4686773792
            ],
            [
              354510.34157763195,
              5008462.5992807886
            ],
            [
              354509.76715423982,
              5008464.3281404162
            ],
            [
              354508.78983266413,
              5008466.1651196349
            ],
            [
              354507.22038838203,
              5008468.8214685712
            ],
            [
              354504.97196135315,
              5008473.0065332558
            ],
            [
              354503.10387785867,
              5008475.8709454425
            ],
            [
              354502.6208537239,
              5008477.0924636768
            ],
            [
              354501.2688332565,
              5008480.5535431933
            ],
            [
              354499.62865784363,
              5008484.8283379283
            ],
            [
              354498.638958155,
              5008487.050438785
            ],
            [
              354498.05137470819,
              5008488.0718129696
            ],
            [
              354497.26062518795,
              5008489.0969663383
            ],
            [
              354496.87731310196,
              5008490.2160045309
            ],
            [
              354496.69183864369,
              5008491.1288545402
            ],
            [
              354496.6135839562,
              5008492.3428434283
            ],
            [
              354496.42998841766,
              5008493.3567029284
            ],
            [
              354496.04588714434,
              5008494.4753365265
            ],
            [
              354495.06857349054,
              5008496.3127350183
            ],
            [
              354494.49226353649,
              5008497.9401661791
            ],
            [
              354494.21350312914,
              5008499.2589291902
            ],
            [
              354494.05058345728,
              5008501.3843122171
            ],
            [
              354493.78497546742,
              5008503.4101416208
            ],
            [
              354493.20600522205,
              5008504.9365778426
            ],
            [
              354492.3246379114,
              5008506.469058332
            ],
            [
              354489.362618896,
              5008510.364263894
            ],
            [
              354488.57452972938,
              5008511.4904123293
            ],
            [
              354488.18854958378,
              5008512.5080364896
            ],
            [
              354488.01434868999,
              5008514.0269434666
            ],
            [
              354488.66472558706,
              5008516.3392869411
            ],
            [
              354490.4100143718,
              5008523.1782632554
            ],
            [
              354490.842967498,
              5008524.6858762773
            ],
            [
              354491.07652020588,
              5008526.3997066002
            ],
            [
              354491.10846187628,
              5008528.1168679027
            ],
            [
              354490.93579693401,
              5008529.718342796
            ],
            [
              354490.55733253772,
              5008531.1400049254
            ],
            [
              354489.20797285053,
              5008534.7020796295
            ],
            [
              354488.44055976457,
              5008536.9397515766
            ],
            [
              354488.17229164467,
              5008538.8645861223
            ],
            [
              354488.10718960554,
              5008540.7856414709
            ],
            [
              354488.65222579398,
              5008542.897855497
            ],
            [
              354489.50795618963,
              5008545.4080486279
            ],
            [
              354490.86362856749,
              5008547.6062280629
            ],
            [
              354491.90483328159,
              5008549.203571083
            ],
            [
              354492.74819262343,
              5008551.0066832304
            ],
            [
              354493.2988656534,
              5008553.4219256751
            ],
            [
              354493.24049800792,
              5008555.7470334899
            ],
            [
              354492.97677706671,
              5008557.8742915792
            ],
            [
              354492.61334423459,
              5008560.1040296368
            ],
            [
              354492.04267914832,
              5008562.0349085312
            ],
            [
              354491.28089525615,
              5008564.575189841
            ],
            [
              354490.71884347388,
              5008567.0111307101
            ],
            [
              354490.17063522857,
              5008569.5189286871
            ],
            [
              354489.89563295757,
              5008571.0397107787
            ],
            [
              354490.02086853195,
              5008572.3513781847
            ],
            [
              354490.34660203691,
              5008573.5578523055
            ],
            [
              354491.28700526321,
              5008575.1570703518
            ],
            [
              354491.81700236839,
              5008576.4607892735
            ],
            [
              354492.34888621286,
              5008577.8659368102
            ],
            [
              354492.59636535583,
              5008580.2863998432
            ],
            [
              354492.89834153006,
              5008585.6365571273
            ],
            [
              354492.93217023154,
              5008587.455147014
            ],
            [
              354493.158199695,
              5008588.7645202111
            ],
            [
              354493.78414306062,
              5008589.7633210309
            ],
            [
              354495.12101848971,
              5008590.9509862419
            ],
            [
              354496.96346485405,
              5008592.1292470004
            ],
            [
              354498.40005216066,
              5008593.2144322181
            ],
            [
              354499.33027933264,
              5008594.3086172733
            ],
            [
              354500.4768251669,
              5008596.106089511
            ],
            [
              354501.53306942765,
              5008598.5119273756
            ],
            [
              354503.33782462106,
              5008603.1268192613
            ],
            [
              354504.2119661647,
              5008605.6182217319
            ],
            [
              354505.40095416881,
              5008609.7393456651
            ],
            [
              354506.49101955624,
              5008613.9633541703
            ],
            [
              354500.06637291069,
              5008616.6089743776
            ],
            [
              354501.92077025241,
              5008623.8505173875
            ],
            [
              354507.07231371244,
              5008628.9083752595
            ],
            [
              354510.2097590465,
              5008639.864695169
            ],
            [
              354519.27543269494,
              5008637.8772578686
            ],
            [
              354525.08486182103,
              5008645.651077034
            ],
            [
              354517.54076432559,
              5008647.913763037
            ],
            [
              354518.23780284001,
              5008652.0201393599
            ],
            [
              354527.40505143034,
              5008650.030393037
            ],
            [
              354530.68209478102,
              5008657.6496492103
            ],
            [
              354519.31725717109,
              5008661.0944776479
            ],
            [
              354521.07383024629,
              5008668.5399285974
            ],
            [
              354531.93762837822,
              5008665.3060897682
            ],
            [
              354532.5440008681,
              5008665.2948101228
            ],
            [
              354533.65650009859,
              5008665.2741155894
            ],
            [
              354534.09078468202,
              5008666.8831671989
            ],
            [
              354534.33450649143,
              5008669.1016109306
            ],
            [
              354534.79941038793,
              5008672.326803783
            ],
            [
              354535.07242206333,
              5008676.161832748
            ],
            [
              354535.30178257957,
              5008683.0290064691
            ],
            [
              354535.33391520625,
              5008689.1686395742
            ],
            [
              354529.43066152447,
              5008692.6133367717
            ],
            [
              354536.14766858029,
              5008705.7264652355
            ],
            [
              354549.57998683251,
              5008699.2110050907
            ],
            [
              354549.98475607316,
              5008699.2034755982
            ],
            [
              354550.91309624841,
              5008699.1862066705
            ],
            [
              354551.32539739896,
              5008700.5935782492
            ],
            [
              354551.68495280569,
              5008703.6182224769
            ],
            [
              354552.27132732206,
              5008707.9526441833
            ],
            [
              354553.05257060571,
              5008711.8792632269
            ],
            [
              354553.92146270321,
              5008715.0969408704
            ],
            [
              354555.30045723281,
              5008718.5067991437
            ],
            [
              354556.56770027563,
              5008721.4139333693
            ],
            [
              354557.81129022397,
              5008723.0075108325
            ],
            [
              354561.34836587741,
              5008726.4522739686
            ],
            [
              354564.03339102061,
              5008729.4336596271
            ],
            [
              354566.81624902278,
              5008732.2115558004
            ],
            [
              354569.28009977133,
              5008734.1866114978
            ],
            [
              354573.69550366868,
              5008737.7424942357
            ],
            [
              354576.9764087082,
              5008740.1065286091
            ],
            [
              354579.13786241814,
              5008742.0876286812
            ],
            [
              354581.61298702046,
              5008744.6687410045
            ],
            [
              354584.91832666937,
              5008748.3463173714
            ],
            [
              354587.30393140583,
              5008751.5357806124
            ],
            [
              354589.1783206017,
              5008754.4312009774
            ],
            [
              354590.01902817382,
              5008756.1337363049
            ],
            [
              354591.33087883523,
              5008760.4303443246
            ],
            [
              354592.63382292417,
              5008765.2566577578
            ],
            [
              354593.19576345041,
              5008768.2775367564
            ],
            [
              354593.78777568339,
              5008772.9149861895
            ],
            [
              354593.78468957322,
              5008778.1697721919
            ],
            [
              354593.21767448093,
              5008785.7594891852
            ],
            [
              354592.6864698056,
              5008789.8115664339
            ],
            [
              354587.8087117718,
              5008788.588306848
            ],
            [
              354586.49239571841,
              5008793.9685663283
            ],
            [
              354589.49389638379,
              5008795.0164302159
            ],
            [
              354589.74385050434,
              5008795.9444624931
            ],
            [
              354589.07850645727,
              5008797.0532549703
            ],
            [
              354587.3355334106,
              5008801.5165384943
            ],
            [
              354585.28385853354,
              5008805.3948111804
            ],
            [
              354583.61362342961,
              5008808.0534553053
            ],
            [
              354582.12697510887,
              5008809.6978209289
            ],
            [
              354580.63093175984,
              5008810.8371392721
            ],
            [
              354579.72888918192,
              5008811.2580969576
            ],
            [
              354578.3167363854,
              5008811.4864550261
            ],
            [
              354576.59763936413,
              5008811.5184341222
            ],
            [
              354575.18250995368,
              5008811.5447587352
            ],
            [
              354574.47831254738,
              5008811.759947232
            ],
            [
              354573.18654058961,
              5008812.9969293829
            ],
            [
              354571.89663982554,
              5008814.3345018746
            ],
            [
              354571.60848566244,
              5008815.1482175272
            ],
            [
              354571.42489111121,
              5008816.1620769817
            ],
            [
              354571.14238177356,
              5008817.2792401472
            ],
            [
              354570.46073244297,
              5008818.7065422274
            ],
            [
              354568.10884803842,
              5008822.7924886234
            ],
            [
              354563.45262055658,
              5008830.2004231773
            ],
            [
              354559.20822113851,
              5008835.8372412082
            ],
            [
              354556.43918817199,
              5008839.2232175879
            ],
            [
              354553.65810752456,
              5008842.0035708789
            ],
            [
              354552.18065364537,
              5008843.183383232
            ],
            [
              354551.05387890182,
              5008843.3655931549
            ],
            [
              354550.13790178159,
              5008843.0794992233
            ],
            [
              354549.12300205679,
              5008842.896289899
            ],
            [
              354547.80491611821,
              5008842.7187205581
            ],
            [
              354546.18740196765,
              5008842.7488101311
            ],
            [
              354545.17626027024,
              5008842.7676197477
            ],
            [
              354544.37126121949,
              5008842.9846834755
            ],
            [
              354543.77350241755,
              5008843.5010253228
            ],
            [
              354543.28108466702,
              5008844.2174964286
            ],
            [
              354543.10579545371,
              5008845.6357984357
            ],
            [
              354543.05494585418,
              5008848.3649438722
            ],
            [
              354542.91081852739,
              5008851.5004213983
            ],
            [
              354542.35330051102,
              5008854.1379476143
            ],
            [
              354541.09910893202,
              5008857.3951194845
            ],
            [
              354539.53530489333,
              5008860.354498324
            ],
            [
              354538.05805952504,
              5008862.5043308819
            ],
            [
              354536.468730636,
              5008864.0495626433
            ],
            [
              354534.6676220161,
              5008865.093511899
            ],
            [
              354532.63102462015,
              5008866.4206573498
            ],
            [
              354530.04182810901,
              5008868.5907556936
            ],
            [
              354525.87756127055,
              5008873.1145953974
            ],
            [
              354522.01272262342,
              5008877.4307762189
            ],
            [
              354518.83031456923,
              5008880.3196402015
            ],
            [
              354511.85191825283,
              5008885.8052298436
            ],
            [
              354505.46862021578,
              5008890.6734828223
            ],
            [
              354504.16932437761,
              5008891.5060084611
            ],
            [
              354502.86627047142,
              5008892.1365151368
            ],
            [
              354501.86076573451,
              5008892.4583533499
            ],
            [
              354500.74506423221,
              5008892.2770193145
            ],
            [
              354499.11737331055,
              5008891.8020762438
            ],
            [
              354496.77123405418,
              5008890.7342314664
            ],
            [
              354495.54831242742,
              5008890.2517586686
            ],
            [
              354494.63499555242,
              5008890.0666597607
            ],
            [
              354493.3198860212,
              5008890.0911240764
            ],
            [
              354491.70612968208,
              5008890.3232328473
            ],
            [
              354490.19881202717,
              5008890.8564949259
            ],
            [
              354488.99655969325,
              5008891.4851264618
            ],
            [
              354488.06167799712,
              5008892.5779490927
            ],
            [
              354486.48550221708,
              5008894.8302476257
            ],
            [
              354484.60347972839,
              5008896.9871912561
            ],
            [
              354482.51422470651,
              5008898.8452759059
            ],
            [
              354480.42308282567,
              5008900.6019319529
            ],
            [
              354479.42321512831,
              5008901.2267986769
            ],
            [
              354478.31988536398,
              5008901.7525456231
            ],
            [
              354477.31250153988,
              5008901.9733743984
            ],
            [
              354475.39555883408,
              5008902.2111232681
            ],
            [
              354472.55136454542,
              5008901.5567213511
            ],
            [
              354470.82662992139,
              5008901.2856724365
            ],
            [
              354469.71280735609,
              5008901.2053479068
            ],
            [
              354468.39769772539,
              5008901.2298122672
            ],
            [
              354466.68501867895,
              5008901.5648057656
            ],
            [
              354464.47726924397,
              5008902.5156947859
            ],
            [
              354462.07276440697,
              5008903.7729580635
            ],
            [
              354457.57148049399,
              5008906.483849057
            ],
            [
              354454.28561003093,
              5008909.2735940563
            ],
            [
              354449.82003551658,
              5008913.9040847784
            ],
            [
              354446.78869367641,
              5008917.434140645
            ],
            [
              354445.69475902029,
              5008918.4649351807
            ],
            [
              354444.69113318104,
              5008918.8877830217
            ],
            [
              354443.48214599438,
              5008919.1123622116
            ],
            [
              354442.06811366946,
              5008919.2397113098
            ],
            [
              354440.74626920413,
              5008918.8601232078
            ],
            [
              354439.66736839566,
              5008918.2580927117
            ],
            [
              354449.89971222827,
              5008928.378588384
            ],
            [
              354493.33387781942,
              5008915.095953756
            ],
            [
              354535.90847485128,
              5008897.5411585709
            ],
            [
              354568.38794114621,
              5008880.8615312008
            ],
            [
              354588.20475247363,
              5008855.9334219396
            ],
            [
              354614.75666431623,
              5008815.6765828924
            ],
            [
              354628.05443348305,
              5008785.9168399284
            ],
            [
              354628.93960081867,
              5008784.5092173321
            ],
            [
              354638.02206240868,
              5008768.1874732235
            ],
            [
              354650.13666176092,
              5008751.0059742592
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 338,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358520.31558185077,
              5006249.9487424884
            ],
            [
              358520.24702962401,
              5006249.8124582591
            ],
            [
              358520.94154180639,
              5006251.1931728115
            ],
            [
              358522.56228836253,
              5006255.3001240557
            ],
            [
              358522.76130474237,
              5006256.037366773
            ],
            [
              358523.56827246613,
              5006266.2107164273
            ],
            [
              358527.90940306662,
              5006267.1179722389
            ],
            [
              358537.32107543445,
              5006264.9670072999
            ],
            [
              358537.15142144705,
              5006259.165930775
            ],
            [
              358535.75035141857,
              5006256.9072936438
            ],
            [
              358534.84541777137,
              5006251.4286225745
            ],
            [
              358524.53897902224,
              5006251.2445879588
            ],
            [
              358523.04586157756,
              5006249.4778962946
            ],
            [
              358523.00078777765,
              5006247.0538912276
            ],
            [
              358521.61364966392,
              5006245.5854825582
            ],
            [
              358522.26363611064,
              5006239.1859797351
            ],
            [
              358522.3422954678,
              5006237.0553647988
            ],
            [
              358523.78848837229,
              5006229.5133288456
            ],
            [
              358522.8504322989,
              5006217.7756949263
            ],
            [
              358523.43099901767,
              5006210.8184442734
            ],
            [
              358530.96683135367,
              5006212.7020827252
            ],
            [
              358535.32817377761,
              5006214.0018854132
            ],
            [
              358539.02667668561,
              5006216.0283877132
            ],
            [
              358545.79365060688,
              5006221.8056757851
            ],
            [
              358545.49479023163,
              5006223.5113244429
            ],
            [
              358547.86535337509,
              5006226.536889974
            ],
            [
              358551.34779467626,
              5006225.8581817197
            ],
            [
              358556.68920170359,
              5006226.089437685
            ],
            [
              358556.50883035234,
              5006227.9945326615
            ],
            [
              358554.03587668511,
              5006239.2466481375
            ],
            [
              358566.42964491167,
              5006241.2191126021
            ],
            [
              358567.47585187166,
              5006240.8165485794
            ],
            [
              358569.99585654942,
              5006242.3979479307
            ],
            [
              358571.71591587126,
              5006242.174408827
            ],
            [
              358575.78760597802,
              5006244.7805076279
            ],
            [
              358576.8694197805,
              5006246.2928318251
            ],
            [
              358584.68554979312,
              5006247.8717706874
            ],
            [
              358598.90797872981,
              5006255.4611849803
            ],
            [
              358607.23143257451,
              5006249.7512359591
            ],
            [
              358612.60203986481,
              5006239.7861261275
            ],
            [
              358614.47266865819,
              5006237.3568642586
            ],
            [
              358608.17604910437,
              5006234.5851984415
            ],
            [
              358598.21504869818,
              5006229.5025766715
            ],
            [
              358592.21215290733,
              5006226.0703904526
            ],
            [
              358590.33756021241,
              5006223.1361046787
            ],
            [
              358587.18973028008,
              5006220.3546647448
            ],
            [
              358584.90380972839,
              5006219.8580385987
            ],
            [
              358581.75154673815,
              5006217.879847358
            ],
            [
              358574.19822051027,
              5006214.4260175293
            ],
            [
              358568.56397698907,
              5006211.6188974939
            ],
            [
              358570.62301700271,
              5006205.6118782014
            ],
            [
              358574.49162115459,
              5006196.8091681432
            ],
            [
              358571.76032943564,
              5006195.2050071722
            ],
            [
              358562.98668217129,
              5006191.2703900915
            ],
            [
              358560.40987041785,
              5006186.7400424806
            ],
            [
              358561.6362008155,
              5006185.8489022916
            ],
            [
              358557.04136162321,
              5006184.2313024001
            ],
            [
              358553.16180458944,
              5006184.4396777777
            ],
            [
              358551.86525732529,
              5006184.3275498664
            ],
            [
              358545.53636774141,
              5006184.7177062212
            ],
            [
              358545.37478780909,
              5006183.3582633296
            ],
            [
              358544.40186526539,
              5006171.3754547713
            ],
            [
              358546.21417242166,
              5006169.9111900283
            ],
            [
              358555.84696902899,
              5006174.2798494212
            ],
            [
              358557.41472363804,
              5006169.8850274915
            ],
            [
              358563.43158745288,
              5006159.5478466647
            ],
            [
              358546.94923015632,
              5006151.0559994616
            ],
            [
              358538.4751342063,
              5006148.9684923543
            ],
            [
              358531.02884697082,
              5006147.6253480827
            ],
            [
              358533.00234235486,
              5006142.7950916942
            ],
            [
              358536.13724809285,
              5006135.1520434078
            ],
            [
              358526.69025382207,
              5006130.8244651388
            ],
            [
              358517.47099979135,
              5006124.5640186938
            ],
            [
              358507.7790062647,
              5006119.4592295475
            ],
            [
              358504.47244308569,
              5006114.6057749558
            ],
            [
              358502.09356114717,
              5006108.8372571757
            ],
            [
              358500.12852792215,
              5006104.8247720683
            ],
            [
              358492.83173835155,
              5006096.7646072013
            ],
            [
              358484.41433423845,
              5006093.9861390935
            ],
            [
              358481.55581737199,
              5006092.2118460638
            ],
            [
              358473.71527914144,
              5006096.6216743402
            ],
            [
              358475.49599653628,
              5006100.021978084
            ],
            [
              358484.99878710177,
              5006113.3735750709
            ],
            [
              358479.18990251661,
              5006116.8042042656
            ],
            [
              358480.41677134432,
              5006120.2147659464
            ],
            [
              358482.58480531542,
              5006123.5656317882
            ],
            [
              358485.17658559652,
              5006127.2806243794
            ],
            [
              358486.07004124561,
              5006128.5903200712
            ],
            [
              358490.75825636915,
              5006134.7232344439
            ],
            [
              358493.61601157096,
              5006133.4352442119
            ],
            [
              358497.61351499957,
              5006131.9431065582
            ],
            [
              358497.71264495346,
              5006132.3528805869
            ],
            [
              358501.44054571824,
              5006140.4308712967
            ],
            [
              358502.29634268204,
              5006149.9293985562
            ],
            [
              358500.20505471766,
              5006152.1984641068
            ],
            [
              358503.31035263301,
              5006154.0476728193
            ],
            [
              358505.98701818817,
              5006155.0071593905
            ],
            [
              358514.57553791901,
              5006156.2049815906
            ],
            [
              358516.14371959277,
              5006160.571493946
            ],
            [
              358517.20238604076,
              5006163.568518186
            ],
            [
              358518.35093847482,
              5006166.2298206296
            ],
            [
              358519.14453770191,
              5006167.1847105948
            ],
            [
              358516.1130102842,
              5006167.5643093977
            ],
            [
              358515.03054441401,
              5006168.4570950316
            ],
            [
              358512.94814137253,
              5006169.4654629203
            ],
            [
              358512.85661567497,
              5006170.8462886
            ],
            [
              358512.11670981755,
              5006178.6093860734
            ],
            [
              358511.70927065314,
              5006181.9578699665
            ],
            [
              358511.53896192968,
              5006183.9894046793
            ],
            [
              358511.42455679853,
              5006186.227929337
            ],
            [
              358510.8996706921,
              5006191.6609913073
            ],
            [
              358510.53492863342,
              5006194.4242445147
            ],
            [
              358507.99252321676,
              5006211.8660443285
            ],
            [
              358508.43191797991,
              5006215.9145693853
            ],
            [
              358508.73899929866,
              5006229.7139876978
            ],
            [
              358508.59303756052,
              5006239.9750734223
            ],
            [
              358507.7924658304,
              5006245.9102390287
            ],
            [
              358508.33696780476,
              5006251.0709300749
            ],
            [
              358514.139022535,
              5006250.3666937994
            ],
            [
              358517.95241298148,
              5006250.9666715739
            ],
            [
              358519.79897722142,
              5006250.0378327454
            ],
            [
              358520.31558185077,
              5006249.9487424884
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 339,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358678.18413779378,
              5005894.3804638451
            ],
            [
              358665.43447052187,
              5005893.6000563502
            ],
            [
              358654.23645753803,
              5005878.866447025
            ],
            [
              358644.90729665646,
              5005866.7841966888
            ],
            [
              358635.38317508932,
              5005856.0732127232
            ],
            [
              358635.38317508763,
              5005856.0732127232
            ],
            [
              358609.28807762073,
              5005839.0077245338
            ],
            [
              358587.32321097591,
              5005833.385998521
            ],
            [
              358564.31958836626,
              5005830.9174572201
            ],
            [
              358521.52589342097,
              5005826.7958238032
            ],
            [
              358511.61143491202,
              5005825.2138161464
            ],
            [
              358501.69796274736,
              5005831.8713230649
            ],
            [
              358489.88553729263,
              5005843.8988103056
            ],
            [
              358481.14692277001,
              5005853.4184190892
            ],
            [
              358487.17529618688,
              5005853.9747133134
            ],
            [
              358490.95981097262,
              5005865.7121977564
            ],
            [
              358488.64549197309,
              5005873.1072457414
            ],
            [
              358490.7377501775,
              5005877.7469465239
            ],
            [
              358487.37568387185,
              5005888.7261206936
            ],
            [
              358486.20856143651,
              5005897.8821807308
            ],
            [
              358481.77383968997,
              5005911.1092180153
            ],
            [
              358480.42943124945,
              5005922.7192603489
            ],
            [
              358481.45320541912,
              5005929.8294734173
            ],
            [
              358493.8282126073,
              5005930.7495529521
            ],
            [
              358499.84264581831,
              5005931.6991886059
            ],
            [
              358508.71079388185,
              5005933.9246378196
            ],
            [
              358522.06855626061,
              5005935.1755734021
            ],
            [
              358542.09578245523,
              5005941.6019003671
            ],
            [
              358552.21278057288,
              5005954.4902444156
            ],
            [
              358564.0471043553,
              5005967.0868613478
            ],
            [
              358589.73178525438,
              5006007.2663912755
            ],
            [
              358605.93408688309,
              5006024.4048277829
            ],
            [
              358618.49359095801,
              5006053.5545571363
            ],
            [
              358639.85691172379,
              5006069.6586718084
            ],
            [
              358646.11006206559,
              5006079.4258534759
            ],
            [
              358652.70787926443,
              5006084.3710207529
            ],
            [
              358664.40180636296,
              5006084.3574509276
            ],
            [
              358677.03188111505,
              5006079.841204674
            ],
            [
              358686.02443464036,
              5006074.3473209795
            ],
            [
              358689.43364113924,
              5006072.3484731093
            ],
            [
              358699.53129463713,
              5006075.1294937162
            ],
            [
              358691.85395377636,
              5006060.7970886212
            ],
            [
              358688.97766258736,
              5006056.0254774047
            ],
            [
              358686.62729614007,
              5006042.9206924373
            ],
            [
              358682.96244636463,
              5006028.649550559
            ],
            [
              358681.15215376398,
              5006011.6537159067
            ],
            [
              358678.93812483485,
              5005991.3073212784
            ],
            [
              358678.34677851381,
              5005966.6583596766
            ],
            [
              358676.62551540748,
              5005941.4498877572
            ],
            [
              358678.18413779378,
              5005894.3804638451
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 340,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355540.99668906775,
              5008300.5994027052
            ],
            [
              355550.54440720851,
              5008288.0350664081
            ],
            [
              355562.35646165849,
              5008276.8181849774
            ],
            [
              355568.43595056137,
              5008282.9399224641
            ],
            [
              355586.45253942587,
              5008270.3215043833
            ],
            [
              355608.00364944927,
              5008252.6235895734
            ],
            [
              355600.00373964262,
              5008243.3232296938
            ],
            [
              355615.35690818849,
              5008230.8119378164
            ],
            [
              355621.73714323889,
              5008238.3018557969
            ],
            [
              355639.45929770434,
              5008219.1719239764
            ],
            [
              355645.08334375895,
              5008213.464535607
            ],
            [
              355651.15258344536,
              5008206.9001940042
            ],
            [
              355623.58873254631,
              5008169.7576188473
            ],
            [
              355627.65384679061,
              5008168.7603655877
            ],
            [
              355637.1982614912,
              5008164.8963065762
            ],
            [
              355647.72066153487,
              5008156.8995644385
            ],
            [
              355655.42886764539,
              5008153.4646347761
            ],
            [
              355662.09339269076,
              5008150.5757838218
            ],
            [
              355670.947546507,
              5008145.0129420767
            ],
            [
              355683.86297246016,
              5008141.3475892777
            ],
            [
              355687.06011454022,
              5008143.2630275823
            ],
            [
              355728.77826215309,
              5008163.4752306538
            ],
            [
              355757.03425750759,
              5008195.3384614848
            ],
            [
              355741.82983519556,
              5008199.4394899588
            ],
            [
              355719.40645714634,
              5008178.4268081076
            ],
            [
              355708.35114487103,
              5008170.7040100452
            ],
            [
              355701.90460189007,
              5008173.9883093489
            ],
            [
              355682.8990006338,
              5008198.6819516551
            ],
            [
              355703.96119404846,
              5008217.1549673602
            ],
            [
              355697.21183935797,
              5008222.4460606007
            ],
            [
              355738.72895889013,
              5008268.7944815401
            ],
            [
              355731.93183682521,
              5008277.1620440204
            ],
            [
              355708.18659889576,
              5008289.7312238067
            ],
            [
              355699.28104101773,
              5008299.1340518212
            ],
            [
              355694.78119457926,
              5008304.7873654813
            ],
            [
              355704.25148288795,
              5008313.4558833316
            ],
            [
              355696.57019347028,
              5008327.9598111715
            ],
            [
              355698.41166394419,
              5008329.9989034515
            ],
            [
              355686.71724656649,
              5008345.7525267676
            ],
            [
              355682.57679731544,
              5008349.7793880897
            ],
            [
              355660.51207549957,
              5008370.3947565565
            ],
            [
              355660.7016761009,
              5008375.0391042056
            ],
            [
              355648.78398550214,
              5008385.5372051792
            ],
            [
              355629.97559829924,
              5008400.0600577658
            ],
            [
              355624.44432880305,
              5008393.053202834
            ],
            [
              355620.08699993975,
              5008399.5856720069
            ],
            [
              355605.29873376939,
              5008413.6291597243
            ],
            [
              355597.37662480562,
              5008417.2237664796
            ],
            [
              355584.1862542489,
              5008429.7033309871
            ],
            [
              355555.51417644409,
              5008443.2120324476
            ],
            [
              355533.00075354573,
              5008462.5696488284
            ],
            [
              355536.90642224782,
              5008468.0885079075
            ],
            [
              355541.48352796183,
              5008472.1433769604
            ],
            [
              355544.01244705456,
              5008474.6723472644
            ],
            [
              355553.99580652663,
              5008487.9390306259
            ],
            [
              355545.24877236958,
              5008489.8190888334
            ],
            [
              355541.67904776847,
              5008487.7865303336
            ],
            [
              355532.86148499156,
              5008485.6857892154
            ],
            [
              355523.4673372354,
              5008485.9697704734
            ],
            [
              355523.16008696822,
              5008483.2536261659
            ],
            [
              355519.1409618074,
              5008482.0997082032
            ],
            [
              355514.26376287098,
              5008481.6179687548
            ],
            [
              355506.5326429471,
              5008481.6664002296
            ],
            [
              355504.35407901154,
              5008476.1447201418
            ],
            [
              355500.24480476958,
              5008475.2585266829
            ],
            [
              355492.63812154985,
              5008471.8703982327
            ],
            [
              355486.0683183673,
              5008472.3929448631
            ],
            [
              355482.69235225773,
              5008473.2426958894
            ],
            [
              355480.83652185014,
              5008471.1584696379
            ],
            [
              355474.60385713266,
              5008474.7249783799
            ],
            [
              355466.84897895722,
              5008481.0281640338
            ],
            [
              355473.36592156516,
              5008486.6100262525
            ],
            [
              355466.68158456276,
              5008494.9097876204
            ],
            [
              355471.86426434939,
              5008500.019498935
            ],
            [
              355474.91636327584,
              5008501.2945190724
            ],
            [
              355469.27180894406,
              5008506.2030843645
            ],
            [
              355462.08422058634,
              5008511.4119068915
            ],
            [
              355449.68525450508,
              5008501.4176800558
            ],
            [
              355448.59278445446,
              5008502.7975459304
            ],
            [
              355439.12193252373,
              5008497.2463492183
            ],
            [
              355435.79787473351,
              5008493.3601667108
            ],
            [
              355424.76173365169,
              5008483.3881235253
            ],
            [
              355405.75295605697,
              5008505.3490346596
            ],
            [
              355404.59054626414,
              5008507.8946716189
            ],
            [
              355414.58892941725,
              5008512.7951262761
            ],
            [
              355417.52308761491,
              5008509.3496007305
            ],
            [
              355427.77774688747,
              5008514.8117971057
            ],
            [
              355436.941390583,
              5008520.3457732424
            ],
            [
              355438.52485217637,
              5008518.8125202246
            ],
            [
              355444.33901089319,
              5008522.3027546052
            ],
            [
              355448.36108967726,
              5008521.5049975011
            ],
            [
              355442.43024561199,
              5008531.6717558084
            ],
            [
              355437.67515335575,
              5008549.4021575348
            ],
            [
              355443.51461945783,
              5008551.6674338058
            ],
            [
              355444.96971049241,
              5008554.829994373
            ],
            [
              355439.16177841282,
              5008564.504312247
            ],
            [
              355438.71238591516,
              5008565.674583382
            ],
            [
              355436.43564362085,
              5008573.3312884923
            ],
            [
              355454.08178855991,
              5008583.5093028937
            ],
            [
              355438.85207509535,
              5008620.125552454
            ],
            [
              355435.77791530179,
              5008622.1662374875
            ],
            [
              355434.28929035767,
              5008626.8692635819
            ],
            [
              355434.41803579096,
              5008635.2316983817
            ],
            [
              355430.38146103307,
              5008637.4453526065
            ],
            [
              355429.82591094618,
              5008643.9773331415
            ],
            [
              355425.15033264359,
              5008647.8440103093
            ],
            [
              355423.21104661853,
              5008652.1731859557
            ],
            [
              355420.00116939651,
              5008662.8604443464
            ],
            [
              355417.43385658518,
              5008667.2576993741
            ],
            [
              355415.66258379875,
              5008669.9654620402
            ],
            [
              355413.77820945712,
              5008673.2110485807
            ],
            [
              355410.76735647069,
              5008679.8754213993
            ],
            [
              355406.0473380193,
              5008694.1724117026
            ],
            [
              355403.73795044998,
              5008703.8557296749
            ],
            [
              355403.37430026097,
              5008708.3564251503
            ],
            [
              355400.50476688717,
              5008722.269451947
            ],
            [
              355398.36110871873,
              5008721.2941204272
            ],
            [
              355393.62374163728,
              5008733.9012102457
            ],
            [
              355390.66818288428,
              5008745.4898411892
            ],
            [
              355389.92987649818,
              5008753.6608606009
            ],
            [
              355377.86402909184,
              5008766.4807561999
            ],
            [
              355373.69761087635,
              5008772.8293981552
            ],
            [
              355386.24528889742,
              5008782.8197691273
            ],
            [
              355387.37422032474,
              5008779.8895770721
            ],
            [
              355396.83982883923,
              5008788.0443176804
            ],
            [
              355403.52030997496,
              5008792.2902860241
            ],
            [
              355415.20269181899,
              5008800.1596663566
            ],
            [
              355417.92346370156,
              5008804.911504318
            ],
            [
              355417.35010714654,
              5008808.6917001493
            ],
            [
              355413.12518595473,
              5008817.215652626
            ],
            [
              355408.58065005363,
              5008837.5312260035
            ],
            [
              355401.7505084321,
              5008873.802097952
            ],
            [
              355405.07009991439,
              5008874.6081578638
            ],
            [
              355409.7218863747,
              5008864.6240714481
            ],
            [
              355431.13416230609,
              5008837.783040083
            ],
            [
              355442.23612233577,
              5008822.182141887
            ],
            [
              355448.66032209323,
              5008806.531434332
            ],
            [
              355446.81844877865,
              5008805.50100662
            ],
            [
              355455.6719926162,
              5008788.7621468781
            ],
            [
              355450.85782664019,
              5008786.1655948907
            ],
            [
              355459.54421817855,
              5008772.0960987173
            ],
            [
              355468.86734534107,
              5008766.0997436987
            ],
            [
              355479.74355645938,
              5008774.2769353129
            ],
            [
              355488.73022970342,
              5008753.429304814
            ],
            [
              355493.66088201135,
              5008758.4865791379
            ],
            [
              355522.23416050884,
              5008787.9796954729
            ],
            [
              355553.83107627399,
              5008754.5258195093
            ],
            [
              355553.91721469472,
              5008745.8924193699
            ],
            [
              355577.73480282637,
              5008712.9603259079
            ],
            [
              355575.58658619312,
              5008705.7251393553
            ],
            [
              355570.34681203711,
              5008699.3215753241
            ],
            [
              355586.0978417751,
              5008677.4217740055
            ],
            [
              355592.82312984881,
              5008674.8884346262
            ],
            [
              355596.1414693761,
              5008670.3295525685
            ],
            [
              355605.5335839956,
              5008665.675663135
            ],
            [
              355612.55778849835,
              5008658.0193766821
            ],
            [
              355636.62436091754,
              5008636.3039681949
            ],
            [
              355633.15388300462,
              5008631.9836375434
            ],
            [
              355656.77483306959,
              5008611.8196454616
            ],
            [
              355649.67961677985,
              5008605.9618867384
            ],
            [
              355652.42964253773,
              5008601.1627389546
            ],
            [
              355647.12559033505,
              5008596.3496851614
            ],
            [
              355715.64544559486,
              5008518.2754405485
            ],
            [
              355707.20339207404,
              5008514.9096380565
            ],
            [
              355700.05027163815,
              5008506.3625384197
            ],
            [
              355712.01749945566,
              5008495.2956282338
            ],
            [
              355701.68001168239,
              5008479.8560831817
            ],
            [
              355714.20616086113,
              5008471.9997447664
            ],
            [
              355719.87353742373,
              5008470.7651717858
            ],
            [
              355717.67791218479,
              5008458.1610313281
            ],
            [
              355743.03374954878,
              5008439.0961765666
            ],
            [
              355753.4968327643,
              5008425.4122987753
            ],
            [
              355765.02734011173,
              5008405.0961914985
            ],
            [
              355772.45513118536,
              5008411.7827205854
            ],
            [
              355779.490009339,
              5008412.9743391704
            ],
            [
              355801.16268664139,
              5008411.5132304421
            ],
            [
              355801.90960552741,
              5008408.9866379686
            ],
            [
              355795.18785531953,
              5008403.292767033
            ],
            [
              355791.64061578602,
              5008397.5398466587
            ],
            [
              355791.01266899711,
              5008385.1202826584
            ],
            [
              355793.62803095934,
              5008376.3432801543
            ],
            [
              355790.2862290857,
              5008373.1917831171
            ],
            [
              355795.3218053914,
              5008366.4857090721
            ],
            [
              355803.56877942075,
              5008361.703661914
            ],
            [
              355809.43728700181,
              5008357.098074507
            ],
            [
              355817.47640708828,
              5008353.5513703069
            ],
            [
              355818.25561122486,
              5008354.4709283477
            ],
            [
              355829.37135160778,
              5008349.3459643573
            ],
            [
              355839.23890011385,
              5008344.5092135631
            ],
            [
              355853.18083790905,
              5008340.0179884927
            ],
            [
              355865.27154407353,
              5008342.7025086042
            ],
            [
              355872.4147115174,
              5008339.5728774844
            ],
            [
              355889.4841286377,
              5008321.190387018
            ],
            [
              355881.66485533753,
              5008309.8955838876
            ],
            [
              355866.86005842168,
              5008318.6333822403
            ],
            [
              355853.28018291696,
              5008310.6304300325
            ],
            [
              355857.19482719456,
              5008305.5378281036
            ],
            [
              355870.11886221729,
              5008297.0109873433
            ],
            [
              355884.25621282827,
              5008293.3032801598
            ],
            [
              355909.28082731931,
              5008271.8615264511
            ],
            [
              355897.34085435938,
              5008259.1184361074
            ],
            [
              355899.42743070953,
              5008256.6815631539
            ],
            [
              355904.40514099965,
              5008250.8032271257
            ],
            [
              355916.68388070684,
              5008236.6364898141
            ],
            [
              355920.14734664734,
              5008238.938950534
            ],
            [
              355922.74381052604,
              5008241.7835119907
            ],
            [
              355936.00733996311,
              5008218.8737950632
            ],
            [
              355928.19518414332,
              5008209.1132062059
            ],
            [
              355942.51632918877,
              5008190.5831554774
            ],
            [
              355952.06900780584,
              5008199.6100491891
            ],
            [
              355963.15153666592,
              5008191.8649267415
            ],
            [
              355955.71620867786,
              5008183.4999371748
            ],
            [
              355964.1844042532,
              5008175.5091464454
            ],
            [
              355966.2498857358,
              5008178.1005975073
            ],
            [
              355978.26886816887,
              5008164.1140106628
            ],
            [
              355971.63726507669,
              5008156.5230651861
            ],
            [
              355977.75640602608,
              5008150.7111739954
            ],
            [
              355980.01358844439,
              5008154.1756936274
            ],
            [
              355986.3140048185,
              5008162.8247470204
            ],
            [
              355994.80858575541,
              5008147.3879529061
            ],
            [
              355999.87669541751,
              5008155.7969404217
            ],
            [
              356005.3530792158,
              5008153.152881573
            ],
            [
              355997.14039980806,
              5008135.5048337411
            ],
            [
              356004.600821322,
              5008131.0706032496
            ],
            [
              356001.89555241284,
              5008127.0884443382
            ],
            [
              356005.95118820359,
              5008123.5064718686
            ],
            [
              356012.08277888392,
              5008132.5093585253
            ],
            [
              356021.85999836779,
              5008125.3144925488
            ],
            [
              356027.42940630269,
              5008132.3627423346
            ],
            [
              356038.31865955645,
              5008123.6569442824
            ],
            [
              356028.064258022,
              5008109.909305214
            ],
            [
              356035.26160548214,
              5008105.4799688729
            ],
            [
              356044.97234211961,
              5008099.4259617347
            ],
            [
              356038.52242758061,
              5008089.596906648
            ],
            [
              356021.83691223082,
              5008068.4271066664
            ],
            [
              356013.93122700654,
              5008064.594679554
            ],
            [
              356010.95128172112,
              5008067.9778024284
            ],
            [
              356005.83989650529,
              5008073.7711586738
            ],
            [
              355979.06707143044,
              5008098.498004782
            ],
            [
              355956.97837534075,
              5008075.6964374557
            ],
            [
              355949.7585904189,
              5008084.910592583
            ],
            [
              355945.66050730267,
              5008092.9688416356
            ],
            [
              355935.78755154164,
              5008105.7199536152
            ],
            [
              355933.51383980789,
              5008108.3258128958
            ],
            [
              355927.40599673212,
              5008102.733967294
            ],
            [
              355916.98372008343,
              5008092.6703300849
            ],
            [
              355908.90554641973,
              5008104.5218887608
            ],
            [
              355898.65128766891,
              5008097.7229933934
            ],
            [
              355889.58447910403,
              5008089.3438116638
            ],
            [
              355909.38616008271,
              5008067.1633933662
            ],
            [
              355911.56540146185,
              5008065.432537212
            ],
            [
              355917.18406295642,
              5008058.6858846815
            ],
            [
              355922.90300559712,
              5008067.3555087419
            ],
            [
              355929.19097957708,
              5008070.0551244328
            ],
            [
              355953.76947322022,
              5008039.2194358613
            ],
            [
              355945.82146523503,
              5008028.4028320825
            ],
            [
              355931.79252622096,
              5008042.2435867926
            ],
            [
              355918.47244588635,
              5008030.2992703952
            ],
            [
              355896.57734379097,
              5008052.4431836493
            ],
            [
              355870.04478349019,
              5008074.3141959598
            ],
            [
              355866.25930934562,
              5008073.7359132171
            ],
            [
              355862.19790397317,
              5008073.0982998544
            ],
            [
              355857.64475924871,
              5008069.0176464356
            ],
            [
              355847.21336060896,
              5008056.4889400639
            ],
            [
              355809.79938424559,
              5008025.716625236
            ],
            [
              355819.16396898538,
              5008014.4521347685
            ],
            [
              355795.55407735071,
              5008001.6808603695
            ],
            [
              355787.77995791094,
              5007998.9308220372
            ],
            [
              355782.24832951877,
              5007999.2067237189
            ],
            [
              355775.85508843028,
              5008002.5278990837
            ],
            [
              355775.30082840304,
              5007998.2584754582
            ],
            [
              355772.69494470948,
              5007996.1307690199
            ],
            [
              355769.46086861944,
              5007993.6134708412
            ],
            [
              355763.88364439405,
              5007989.2723433319
            ],
            [
              355757.14714353398,
              5007984.0288729491
            ],
            [
              355753.14075319143,
              5007985.6235747002
            ],
            [
              355727.03554311016,
              5008014.6874723146
            ],
            [
              355726.99980231363,
              5008014.7217007913
            ],
            [
              355711.78607492795,
              5008031.6276780833
            ],
            [
              355710.93418804521,
              5008031.3053263957
            ],
            [
              355699.53021201916,
              5008046.9558975529
            ],
            [
              355686.93323139206,
              5008060.9720641961
            ],
            [
              355675.02410101116,
              5008073.4784282837
            ],
            [
              355654.70298131224,
              5008087.5748408809
            ],
            [
              355638.30271053553,
              5008094.1449507065
            ],
            [
              355618.93765158643,
              5008097.8223828776
            ],
            [
              355603.16929703974,
              5008100.6377570601
            ],
            [
              355600.24013777677,
              5008101.1888532797
            ],
            [
              355600.11535287014,
              5008101.1885164743
            ],
            [
              355599.22133197327,
              5008100.4354853854
            ],
            [
              355591.19540720631,
              5008093.6752731819
            ],
            [
              355583.30797207775,
              5008123.3564672647
            ],
            [
              355573.45429114142,
              5008159.5583949275
            ],
            [
              355565.61538755492,
              5008184.7216139045
            ],
            [
              355559.97193032049,
              5008203.0114139589
            ],
            [
              355558.58037853666,
              5008205.7117669936
            ],
            [
              355554.38300596998,
              5008211.466329501
            ],
            [
              355545.86318594014,
              5008223.165526961
            ],
            [
              355541.06497274479,
              5008227.1977093751
            ],
            [
              355534.8878323618,
              5008231.9513640618
            ],
            [
              355524.30275813019,
              5008238.5378435133
            ],
            [
              355508.58047107112,
              5008247.8758268766
            ],
            [
              355505.83449695428,
              5008250.542053299
            ],
            [
              355503.42915505782,
              5008252.0811869651
            ],
            [
              355499.25013577315,
              5008254.7552639283
            ],
            [
              355494.83266433509,
              5008257.5819220524
            ],
            [
              355488.00623891078,
              5008261.9500253033
            ],
            [
              355482.28841306164,
              5008265.4746258678
            ],
            [
              355468.04813950782,
              5008273.393463972
            ],
            [
              355455.54117466667,
              5008280.9321337314
            ],
            [
              355436.44642287161,
              5008294.9422010221
            ],
            [
              355428.7776722117,
              5008299.8492937852
            ],
            [
              355418.19885277021,
              5008306.6184863942
            ],
            [
              355408.34208914632,
              5008312.9256489892
            ],
            [
              355406.96991830971,
              5008313.8036760092
            ],
            [
              355412.605233357,
              5008323.5443550944
            ],
            [
              355421.9996078749,
              5008347.5257034218
            ],
            [
              355424.35114101204,
              5008346.8130364278
            ],
            [
              355433.8079283298,
              5008345.3204827039
            ],
            [
              355448.37284838874,
              5008337.9279639106
            ],
            [
              355456.1348639481,
              5008337.3886087155
            ],
            [
              355457.24225147866,
              5008340.2645588126
            ],
            [
              355460.75955139246,
              5008352.3120267792
            ],
            [
              355470.86258539924,
              5008350.1491607875
            ],
            [
              355473.37191839278,
              5008350.497492712
            ],
            [
              355478.85381889687,
              5008351.6941972934
            ],
            [
              355487.27208867721,
              5008342.8119475404
            ],
            [
              355486.30911535519,
              5008334.067389762
            ],
            [
              355492.34883965208,
              5008323.3235154524
            ],
            [
              355502.03435206559,
              5008330.8796226606
            ],
            [
              355511.94405656453,
              5008319.1099905418
            ],
            [
              355520.02598949353,
              5008329.3774279151
            ],
            [
              355534.71761415683,
              5008309.0747702001
            ],
            [
              355540.99668906775,
              5008300.5994027052
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 341,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355360.93163844658,
              5008393.1377119832
            ],
            [
              355358.68683981983,
              5008387.9838851197
            ],
            [
              355379.79744605307,
              5008382.7197163124
            ],
            [
              355388.18889773218,
              5008378.557908236
            ],
            [
              355383.01788467838,
              5008362.5913170371
            ],
            [
              355387.43152522878,
              5008359.0860038316
            ],
            [
              355389.2261152828,
              5008356.4193903748
            ],
            [
              355410.28964153596,
              5008350.8121781228
            ],
            [
              355416.03823626484,
              5008349.2818745533
            ],
            [
              355417.40858187358,
              5008348.9170819661
            ],
            [
              355421.99960787693,
              5008347.5257034246
            ],
            [
              355412.60523335543,
              5008323.5443550916
            ],
            [
              355406.96991830971,
              5008313.8036760092
            ],
            [
              355400.78181764606,
              5008317.7633284908
            ],
            [
              355393.5528935531,
              5008322.3889849856
            ],
            [
              355388.32225531642,
              5008325.7359749461
            ],
            [
              355384.79615846457,
              5008328.7003856292
            ],
            [
              355366.66352461796,
              5008339.5950022554
            ],
            [
              355361.09169631341,
              5008343.1603139117
            ],
            [
              355354.2731451203,
              5008347.5233809939
            ],
            [
              355342.98033969657,
              5008354.7494423715
            ],
            [
              355333.29529264051,
              5008360.9467285993
            ],
            [
              355326.04753142857,
              5008365.5844399147
            ],
            [
              355310.97570917412,
              5008375.2286268231
            ],
            [
              355298.55286745314,
              5008383.1777793746
            ],
            [
              355280.98222024093,
              5008394.4209205871
            ],
            [
              355277.16733245755,
              5008397.9411892779
            ],
            [
              355258.55287806393,
              5008408.7730545262
            ],
            [
              355235.07227280497,
              5008424.5406301077
            ],
            [
              355231.96975182352,
              5008427.0685052173
            ],
            [
              355228.49928062392,
              5008429.8961792132
            ],
            [
              355225.43972983665,
              5008432.3890431039
            ],
            [
              355216.16448592668,
              5008439.9463359658
            ],
            [
              355209.55242766062,
              5008443.9612402935
            ],
            [
              355206.0462997391,
              5008441.3395433947
            ],
            [
              355211.56993439194,
              5008435.8869432509
            ],
            [
              355211.56202088291,
              5008435.8947549891
            ],
            [
              355190.11137376039,
              5008417.7950419951
            ],
            [
              355183.36915683252,
              5008424.4485726533
            ],
            [
              355173.5616443613,
              5008432.6227249792
            ],
            [
              355161.31550725887,
              5008445.0581067773
            ],
            [
              355157.17522948288,
              5008454.2876564851
            ],
            [
              355136.77292125416,
              5008443.2562327273
            ],
            [
              355135.94355570438,
              5008442.8921740176
            ],
            [
              355110.55047102651,
              5008427.3469061591
            ],
            [
              355093.97215914627,
              5008413.2262866274
            ],
            [
              355081.01338596194,
              5008416.316734896
            ],
            [
              355075.40770204278,
              5008408.6155605139
            ],
            [
              355064.74794940959,
              5008404.6347205471
            ],
            [
              355062.84373023309,
              5008405.5796140227
            ],
            [
              355061.34210476867,
              5008406.4159544334
            ],
            [
              355059.83672136278,
              5008407.0502715157
            ],
            [
              355053.71207518177,
              5008409.4884289708
            ],
            [
              355052.30403496121,
              5008409.9188065697
            ],
            [
              355051.40338297677,
              5008410.4408461442
            ],
            [
              355050.10208159155,
              5008411.172395952
            ],
            [
              355048.59669819259,
              5008411.8067130633
            ],
            [
              355047.18671312119,
              5008412.1360979918
            ],
            [
              355045.47160999262,
              5008412.3700940553
            ],
            [
              355044.05605402536,
              5008412.3964249501
            ],
            [
              355043.04678401753,
              5008412.5162628852
            ],
            [
              355041.94204944762,
              5008412.9410342406
            ],
            [
              355040.55279978592,
              5008414.381599755
            ],
            [
              355039.47534730023,
              5008415.6389561798
            ],
            [
              355037.16399559955,
              5008416.5124469679
            ],
            [
              355034.73152390186,
              5008416.8140789187
            ],
            [
              355032.20371692569,
              5008416.8610989358
            ],
            [
              355029.67215206992,
              5008416.7060955856
            ],
            [
              355027.85588092008,
              5008416.9419735176
            ],
            [
              355026.1425907205,
              5008417.2770003015
            ],
            [
              355024.74018817669,
              5008418.0104663307
            ],
            [
              355023.84893116012,
              5008419.0375821767
            ],
            [
              355023.23937069043,
              5008420.8651635488
            ],
            [
              355021.93650425872,
              5008422.3666892955
            ],
            [
              355020.27839292685,
              5008423.065351136
            ],
            [
              355018.81141648541,
              5008422.9301059656
            ],
            [
              355017.89948945277,
              5008422.84600442
            ],
            [
              355016.88652721973,
              5008422.7637822535
            ],
            [
              355016.18250770628,
              5008422.9790066369
            ],
            [
              355015.59274272295,
              5008423.8994500143
            ],
            [
              355013.95194190036,
              5008427.4627856212
            ],
            [
              355012.65519143973,
              5008429.1077370755
            ],
            [
              355011.45499224769,
              5008429.8374419687
            ],
            [
              355009.25122566265,
              5008430.9900009343
            ],
            [
              355008.65206569951,
              5008431.4053681148
            ],
            [
              355008.35996865289,
              5008432.0171168623
            ],
            [
              355008.09048639605,
              5008433.8410757445
            ],
            [
              355007.52690125396,
              5008435.4838056276
            ],
            [
              355005.51276642369,
              5008436.6511666086
            ],
            [
              355003.49565013422,
              5008436.9581220681
            ],
            [
              355001.57827719202,
              5008437.1958807362
            ],
            [
              355000.06913576345,
              5008437.6281746617
            ],
            [
              354999.06926065061,
              5008438.2530889222
            ],
            [
              354998.38027326064,
              5008439.2764424458
            ],
            [
              354998.20061844302,
              5008440.4924150677
            ],
            [
              354998.42537038535,
              5008441.7008652538
            ],
            [
              354999.25491944881,
              5008442.7970010731
            ],
            [
              354999.65070697444,
              5008444.2620524112
            ],
            [
              354998.38815587928,
              5008445.1373211537
            ],
            [
              354998.29081206617,
              5008445.3412251547
            ],
            [
              354998.12398735184,
              5008446.4703588588
            ],
            [
              354996.28175124986,
              5008447.9049221929
            ],
            [
              354993.85994674725,
              5008447.9499706673
            ],
            [
              354992.25223387999,
              5008446.9693384934
            ],
            [
              354991.33661526424,
              5008446.6832123837
            ],
            [
              354989.71878944658,
              5008446.7133058812
            ],
            [
              354988.90990965936,
              5008446.7283520168
            ],
            [
              354988.10478841007,
              5008446.9454570785
            ],
            [
              354987.60485084669,
              5008447.257914233
            ],
            [
              354987.31463242107,
              5008447.9706569575
            ],
            [
              354987.34281816118,
              5008449.4859212618
            ],
            [
              354987.57139493647,
              5008450.8964291774
            ],
            [
              354987.42961265321,
              5008452.430683244
            ],
            [
              354986.52628791291,
              5008453.7453177813
            ],
            [
              354985.29840396688,
              5008453.7681579161
            ],
            [
              354984.07753538404,
              5008453.3866809634
            ],
            [
              354983.26489704911,
              5008453.1996681811
            ],
            [
              354982.456017252,
              5008453.2147143288
            ],
            [
              354981.64895046584,
              5008453.3307911782
            ],
            [
              354980.44875120843,
              5008454.0604962138
            ],
            [
              354979.05198505678,
              5008455.0969798286
            ],
            [
              354977.18368780595,
              5008457.4235130539
            ],
            [
              354975.7176993555,
              5008458.3772440804
            ],
            [
              354974.61860172154,
              5008459.1050686194
            ],
            [
              354973.62060581305,
              5008459.8310124716
            ],
            [
              354972.52526614629,
              5008460.760860323
            ],
            [
              354971.43562986032,
              5008461.9937598454
            ],
            [
              354969.85350553406,
              5008463.9431646075
            ],
            [
              354968.87248742214,
              5008465.5782658681
            ],
            [
              354967.99963959958,
              5008466.9405156234
            ],
            [
              354966.38601937343,
              5008467.8476858772
            ],
            [
              354963.67473236826,
              5008468.9086213298
            ],
            [
              354962.27045040118,
              5008469.541058233
            ],
            [
              354961.47284488782,
              5008470.1622101869
            ],
            [
              354960.88307994732,
              5008471.0826537674
            ],
            [
              354959.90387416823,
              5008472.8187502921
            ],
            [
              354959.21676608169,
              5008473.9431334706
            ],
            [
              354958.31348087371,
              5008475.0364452777
            ],
            [
              354956.23035976937,
              5008476.5249752998
            ],
            [
              354953.73261738749,
              5008478.1882896954
            ],
            [
              354951.24239078554,
              5008480.2556509422
            ],
            [
              354948.26356785232,
              5008483.2416095482
            ],
            [
              354944.89711647463,
              5008487.1442163438
            ],
            [
              354941.34725254663,
              5008492.0607726267
            ],
            [
              354939.56862827128,
              5008494.3169906614
            ],
            [
              354938.38158262899,
              5008495.753831204
            ],
            [
              354937.08967669884,
              5008496.9904934345
            ],
            [
              354935.69284424448,
              5008498.0269785849
            ],
            [
              354934.28856221127,
              5008498.659415653
            ],
            [
              354933.18382741796,
              5008499.0841874173
            ],
            [
              354931.67092720343,
              5008499.3144227332
            ],
            [
              354929.95199940662,
              5008499.3463971158
            ],
            [
              354928.63942396833,
              5008499.4718772322
            ],
            [
              354927.73507952713,
              5008499.791857034
            ],
            [
              354926.63222332211,
              5008500.3176227547
            ],
            [
              354925.49500072032,
              5008501.4329245156
            ],
            [
              354925.21048646374,
              5008502.4487546291
            ],
            [
              354925.13186728855,
              5008503.6628481522
            ],
            [
              354925.00013678696,
              5008505.6988386102
            ],
            [
              354923.48441002035,
              5008507.5334797427
            ],
            [
              354919.90448077826,
              5008510.8337425897
            ],
            [
              354918.91024283861,
              5008511.7617100216
            ],
            [
              354918.02468929201,
              5008513.0918781124
            ],
            [
              354917.14470603416,
              5008514.7250648309
            ],
            [
              354916.20431426348,
              5008517.3362135692
            ],
            [
              354914.80638290272,
              5008519.5180565407
            ],
            [
              354913.61369939632,
              5008520.6518088346
            ],
            [
              354911.51284724305,
              5008521.9035188165
            ],
            [
              354909.61426479509,
              5008523.1514662746
            ],
            [
              354906.50796643982,
              5008524.7250367953
            ],
            [
              354904.90148093161,
              5008525.361235301
            ],
            [
              354904.19933987485,
              5008525.6774539184
            ],
            [
              354904.010223919,
              5008526.3883517804
            ],
            [
              354904.06283752591,
              5008529.2168221278
            ],
            [
              354904.07599126169,
              5008529.9239574755
            ],
            [
              354903.70648424322,
              5008531.3133659447
            ],
            [
              354902.20183629444,
              5008532.4851107281
            ],
            [
              354899.50376964372,
              5008534.2532165488
            ],
            [
              354898.70240551943,
              5008534.6723097889
            ],
            [
              354898.20622649777,
              5008535.1868261276
            ],
            [
              354897.6145823276,
              5008536.0062405095
            ],
            [
              354894.86211955932,
              5008540.4233953981
            ],
            [
              354893.63618042966,
              5008540.6921793399
            ],
            [
              354891.92259275372,
              5008539.9521068372
            ],
            [
              354891.00697379903,
              5008539.6659808923
            ],
            [
              354890.08940994431,
              5008539.2788622212
            ],
            [
              354889.48273337417,
              5008539.2901472934
            ],
            [
              354888.77871300938,
              5008539.5053364625
            ],
            [
              354887.88188501669,
              5008540.2293988606
            ],
            [
              354887.08797115122,
              5008541.0525757857
            ],
            [
              354886.56544687931,
              5008542.0498334924
            ],
            [
              354886.38015514525,
              5008542.9627535939
            ],
            [
              354886.01326333691,
              5008544.9906185586
            ],
            [
              354885.8298508969,
              5008546.0045682006
            ],
            [
              354885.40298863029,
              5008547.5282856431
            ],
            [
              354884.07183076686,
              5008549.3720058929
            ],
            [
              354883.17493585899,
              5008550.0960340127
            ],
            [
              354882.57765513432,
              5008550.6124310466
            ],
            [
              354882.18640135723,
              5008551.3270534789
            ],
            [
              354881.60227373289,
              5008552.5505503928
            ],
            [
              354880.62306882156,
              5008554.2866830556
            ],
            [
              354879.33680012514,
              5008555.8263986586
            ],
            [
              354876.65940244979,
              5008558.7056515953
            ],
            [
              354875.16522681696,
              5008559.9460768159
            ],
            [
              354872.99717301782,
              5008560.9224891327
            ],
            [
              354869.72963195265,
              5008561.3608829435
            ],
            [
              354866.70000684186,
              5008561.6193319857
            ],
            [
              354864.98483732663,
              5008561.8533656867
            ],
            [
              354864.28833348042,
              5008562.4726373814
            ],
            [
              354863.59934553079,
              5008563.4959560139
            ],
            [
              354863.21178412891,
              5008564.4126387322
            ],
            [
              354862.35629555304,
              5008567.3590659229
            ],
            [
              354861.78720108065,
              5008569.3907279344
            ],
            [
              354861.10385040537,
              5008570.7170995558
            ],
            [
              354860.21823068644,
              5008572.0472692577
            ],
            [
              354859.2278168773,
              5008573.1772593725
            ],
            [
              354857.13266809995,
              5008574.7320215916
            ],
            [
              354854.68676970404,
              5008576.2501524268
            ],
            [
              354852.57846709056,
              5008577.0977792358
            ],
            [
              354850.97943173425,
              5008578.1380617991
            ],
            [
              354848.99665855494,
              5008580.2970138649
            ],
            [
              354847.98626862169,
              5008581.1839222591
            ],
            [
              354847.70287394448,
              5008581.432682775
            ],
            [
              354833.94000320393,
              5008575.6255369727
            ],
            [
              354829.37184206129,
              5008585.6136568589
            ],
            [
              354826.61928103626,
              5008584.4522274612
            ],
            [
              354831.10694400326,
              5008575.5771718733
            ],
            [
              354826.92379371304,
              5008573.6339444928
            ],
            [
              354826.17685884255,
              5008575.4705125475
            ],
            [
              354818.76423211809,
              5008590.7203772338
            ],
            [
              354814.3073489535,
              5008600.4134925101
            ],
            [
              354817.95429270604,
              5008608.7683023438
            ],
            [
              354815.32333608781,
              5008621.0915214401
            ],
            [
              354825.18950808333,
              5008625.0214609159
            ],
            [
              354835.69645748177,
              5008619.9973287992
            ],
            [
              354844.3361031785,
              5008618.3277645363
            ],
            [
              354858.95425580716,
              5008619.2782704625
            ],
            [
              354873.09119086218,
              5008614.8339112764
            ],
            [
              354876.0744233352,
              5008617.3703827141
            ],
            [
              354878.70826717897,
              5008620.3657495035
            ],
            [
              354890.56922292069,
              5008628.4001334244
            ],
            [
              354897.31580795086,
              5008626.5364221763
            ],
            [
              354901.87969888066,
              5008625.2756695664
            ],
            [
              354905.67084983626,
              5008626.2865215605
            ],
            [
              354895.89735911449,
              5008639.4099795064
            ],
            [
              354890.52571662283,
              5008646.9849452581
            ],
            [
              354875.21909606532,
              5008659.1173806898
            ],
            [
              354876.74487279769,
              5008660.6366353408
            ],
            [
              354860.80043850176,
              5008676.2996804854
            ],
            [
              354861.50471584481,
              5008678.5385858547
            ],
            [
              354854.30164689827,
              5008698.1168107837
            ],
            [
              354849.60465698718,
              5008702.1120243799
            ],
            [
              354841.38454127259,
              5008709.0871244082
            ],
            [
              354844.30578277359,
              5008712.9406251898
            ],
            [
              354840.5364991143,
              5008716.0847083479
            ],
            [
              354861.55575495638,
              5008726.4844419472
            ],
            [
              354870.15533019986,
              5008728.5900681037
            ],
            [
              354876.00806018262,
              5008734.9933504025
            ],
            [
              354879.19924943842,
              5008731.4174113097
            ],
            [
              354927.41792865045,
              5008685.3711705394
            ],
            [
              354964.62574278645,
              5008650.2761989841
            ],
            [
              354985.11686953495,
              5008668.197973731
            ],
            [
              354970.16414237738,
              5008678.8035804443
            ],
            [
              354995.0158981125,
              5008702.0214872416
            ],
            [
              354998.13225063961,
              5008698.7033284176
            ],
            [
              355013.87521246885,
              5008686.404138945
            ],
            [
              355027.90724954259,
              5008667.2114906879
            ],
            [
              355041.46374786046,
              5008653.3275256641
            ],
            [
              355046.26403323124,
              5008645.1246130737
            ],
            [
              355078.89112175902,
              5008665.231473784
            ],
            [
              355080.93136504776,
              5008666.7623667745
            ],
            [
              355095.21174752858,
              5008655.4619159363
            ],
            [
              355102.41298612911,
              5008661.0424183384
            ],
            [
              355109.71339437523,
              5008648.6471099947
            ],
            [
              355126.23420368694,
              5008630.6052580709
            ],
            [
              355141.16407401784,
              5008617.125171015
            ],
            [
              355135.70441089192,
              5008609.8253869405
            ],
            [
              355133.8815958919,
              5008610.9168088026
            ],
            [
              355118.20622052049,
              5008585.7040429832
            ],
            [
              355109.70728206,
              5008579.6180838095
            ],
            [
              355108.41692038742,
              5008578.0876262719
            ],
            [
              355127.81462642667,
              5008561.0390677731
            ],
            [
              355140.28552047879,
              5008551.944690451
            ],
            [
              355152.78252124437,
              5008564.5344208293
            ],
            [
              355149.13666859758,
              5008579.5742891794
            ],
            [
              355153.43228044314,
              5008585.9482839238
            ],
            [
              355193.32386739476,
              5008642.5478932671
            ],
            [
              355202.88016968517,
              5008636.9512191797
            ],
            [
              355204.18432825455,
              5008630.3946384704
            ],
            [
              355205.45791656786,
              5008622.6682856334
            ],
            [
              355205.38660733699,
              5008590.265205496
            ],
            [
              355211.15355546126,
              5008575.8610432269
            ],
            [
              355219.84179918322,
              5008581.0116541237
            ],
            [
              355282.06540653104,
              5008612.7630965775
            ],
            [
              355301.50196515577,
              5008575.7191456873
            ],
            [
              355298.66778607381,
              5008571.6718978155
            ],
            [
              355257.57304002816,
              5008502.4622497028
            ],
            [
              355277.34343289747,
              5008499.7241227217
            ],
            [
              355289.62748227076,
              5008500.7669323226
            ],
            [
              355298.30004941596,
              5008481.3932444006
            ],
            [
              355269.10557747987,
              5008468.708029625
            ],
            [
              355273.36151894944,
              5008442.5597817022
            ],
            [
              355295.71120366978,
              5008411.821013038
            ],
            [
              355302.77920648659,
              5008420.4910615711
            ],
            [
              355317.72708945832,
              5008409.6800495042
            ],
            [
              355327.00043372269,
              5008404.4832368139
            ],
            [
              355326.2961581698,
              5008412.9640202168
            ],
            [
              355319.23691559321,
              5008423.1678359974
            ],
            [
              355324.49790295243,
              5008426.6701518027
            ],
            [
              355326.54854379193,
              5008423.6037996123
            ],
            [
              355337.09321356966,
              5008427.9159099944
            ],
            [
              355346.56957990414,
              5008418.1414854005
            ],
            [
              355341.96898382169,
              5008413.8105315128
            ],
            [
              355360.93163844658,
              5008393.1377119832
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 342,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359634.79754673544,
              5004117.5527074449
            ],
            [
              359634.79754677188,
              5004117.5527074095
            ],
            [
              359634.79754601099,
              5004117.552708121
            ],
            [
              359625.29523121816,
              5004126.068788372
            ],
            [
              359623.73190120171,
              5004128.5850511314
            ],
            [
              359613.08757345093,
              5004133.8015676932
            ],
            [
              359587.34124897671,
              5004148.1243653372
            ],
            [
              359582.80993873719,
              5004150.4293143768
            ],
            [
              359574.14896570501,
              5004155.5542641236
            ],
            [
              359564.44040103472,
              5004160.5680580819
            ],
            [
              359543.94693823572,
              5004172.1146263666
            ],
            [
              359529.72648157243,
              5004180.4780527335
            ],
            [
              359519.89212689543,
              5004185.7554488266
            ],
            [
              359510.97228637512,
              5004191.0158455521
            ],
            [
              359497.78728326247,
              5004198.83750425
            ],
            [
              359483.43375369482,
              5004207.0727733569
            ],
            [
              359473.53952812304,
              5004213.058230673
            ],
            [
              359464.74062098277,
              5004217.7938593449
            ],
            [
              359442.49784863013,
              5004230.4865907682
            ],
            [
              359429.70477613207,
              5004238.3009669557
            ],
            [
              359411.63050518133,
              5004250.2630583066
            ],
            [
              359392.61535295023,
              5004263.3535891343
            ],
            [
              359335.84760457551,
              5004302.9448416121
            ],
            [
              359331.79791107535,
              5004305.9449320389
            ],
            [
              359327.53568676487,
              5004307.8894571997
            ],
            [
              359323.97335947392,
              5004304.0176182659
            ],
            [
              359318.54100940406,
              5004303.0531544602
            ],
            [
              359313.0037022669,
              5004297.4052611636
            ],
            [
              359306.4519866276,
              5004293.054148064
            ],
            [
              359298.65132681566,
              5004293.6541233826
            ],
            [
              359292.74149155041,
              5004294.0156760486
            ],
            [
              359274.75086336781,
              5004294.6018375168
            ],
            [
              359269.57501365658,
              5004294.5899434779
            ],
            [
              359262.43913884863,
              5004294.8401034297
            ],
            [
              359256.38064939261,
              5004294.9550471762
            ],
            [
              359247.41683404596,
              5004295.3594382973
            ],
            [
              359235.94362880179,
              5004295.712684351
            ],
            [
              359222.79634333804,
              5004297.5247647092
            ],
            [
              359215.88424786396,
              5004297.6390554812
            ],
            [
              359212.4415436511,
              5004298.5640144572
            ],
            [
              359216.54391079949,
              5004306.1490494274
            ],
            [
              359220.15644366172,
              5004312.2867980488
            ],
            [
              359226.77956292988,
              5004324.8573258612
            ],
            [
              359234.91143759788,
              5004336.899453897
            ],
            [
              359250.02755322674,
              5004356.7381817736
            ],
            [
              359258.38790317514,
              5004369.3378884764
            ],
            [
              359258.38790317636,
              5004369.3378884764
            ],
            [
              359262.47046885703,
              5004374.6624938417
            ],
            [
              359279.47267083795,
              5004399.0299443295
            ],
            [
              359286.15393954713,
              5004408.5162787093
            ],
            [
              359298.71409079444,
              5004414.3101013023
            ],
            [
              359302.9656935024,
              5004418.6123395655
            ],
            [
              359314.48354443145,
              5004422.0786011536
            ],
            [
              359317.35483468469,
              5004418.4987495458
            ],
            [
              359383.61151067406,
              5004385.755029331
            ],
            [
              359445.44055703189,
              5004346.1835796377
            ],
            [
              359466.65656632127,
              5004337.735061584
            ],
            [
              359519.62951878033,
              5004296.1659801388
            ],
            [
              359556.9852114594,
              5004268.7796009667
            ],
            [
              359617.89600468741,
              5004224.5680195019
            ],
            [
              359663.43994364556,
              5004195.1127925087
            ],
            [
              359661.78767030226,
              5004192.5640230244
            ],
            [
              359722.31918748521,
              5004140.7002423732
            ],
            [
              359742.74464576185,
              5004121.125762892
            ],
            [
              359771.9489492118,
              5004098.3601396866
            ],
            [
              359780.52364659338,
              5004092.9219083469
            ],
            [
              359811.09727754793,
              5004073.6891716309
            ],
            [
              359823.62196804467,
              5004064.7121551968
            ],
            [
              359843.13069416146,
              5004050.0205521686
            ],
            [
              359862.12410541944,
              5004035.7170229666
            ],
            [
              359890.83132246166,
              5004013.4389706608
            ],
            [
              359930.08950555726,
              5003981.3184028193
            ],
            [
              359948.7621625124,
              5003970.086187683
            ],
            [
              359969.97771900101,
              5003950.1390242213
            ],
            [
              359990.03856363543,
              5003929.299321265
            ],
            [
              360014.86357585696,
              5003902.3804841936
            ],
            [
              360033.51350638777,
              5003883.8373173503
            ],
            [
              360059.33043796982,
              5003858.997685153
            ],
            [
              360070.68159424706,
              5003847.3709168797
            ],
            [
              360100.32989606966,
              5003817.6430274928
            ],
            [
              360119.86369358096,
              5003797.2291209819
            ],
            [
              360120.67666363937,
              5003793.7014828967
            ],
            [
              360131.11165981006,
              5003786.7390600285
            ],
            [
              360166.89221536805,
              5003748.4107847204
            ],
            [
              360165.67503812391,
              5003745.9453650396
            ],
            [
              360174.66043735651,
              5003743.7502076896
            ],
            [
              360192.87542304606,
              5003725.3470707769
            ],
            [
              360214.43109361752,
              5003695.9180805925
            ],
            [
              360219.39537711936,
              5003697.0259287385
            ],
            [
              360240.80338536319,
              5003675.2273651604
            ],
            [
              360259.69130641688,
              5003655.6326415874
            ],
            [
              360274.59116779402,
              5003639.5797802936
            ],
            [
              360286.81356273004,
              5003625.6537420247
            ],
            [
              360288.36964675057,
              5003625.8316579498
            ],
            [
              360296.6520430434,
              5003616.0575308399
            ],
            [
              360310.55512855086,
              5003615.4306809697
            ],
            [
              360324.33327690203,
              5003608.0821383838
            ],
            [
              360344.06150198466,
              5003593.3865368413
            ],
            [
              360353.63323456829,
              5003589.8864501342
            ],
            [
              360368.11217771977,
              5003589.0051283911
            ],
            [
              360388.41935257125,
              5003594.137387
            ],
            [
              360408.29195187899,
              5003552.275314495
            ],
            [
              360394.47772433981,
              5003544.9817219153
            ],
            [
              360413.35428056057,
              5003517.0822218889
            ],
            [
              360417.50367303734,
              5003509.6587860715
            ],
            [
              360428.71784847073,
              5003519.8576342938
            ],
            [
              360459.34762132139,
              5003542.1434679097
            ],
            [
              360478.88016276999,
              5003564.7484919811
            ],
            [
              360490.423525461,
              5003566.4616529103
            ],
            [
              360549.86440999265,
              5003618.28053944
            ],
            [
              360552.46381900454,
              5003604.3558857515
            ],
            [
              360558.77406894841,
              5003581.3834601426
            ],
            [
              360556.32035849564,
              5003569.7595811756
            ],
            [
              360551.33435520664,
              5003554.1393489856
            ],
            [
              360585.76919913507,
              5003525.7668693401
            ],
            [
              360579.24124945031,
              5003516.4209563714
            ],
            [
              360576.17168093513,
              5003505.0504420931
            ],
            [
              360576.94565958634,
              5003491.7718853233
            ],
            [
              360586.17135747959,
              5003493.8451355295
            ],
            [
              360590.06627901894,
              5003482.6897777524
            ],
            [
              360593.1200435338,
              5003482.2248610146
            ],
            [
              360593.73546894692,
              5003471.398056251
            ],
            [
              360603.11086631397,
              5003448.572709037
            ],
            [
              360611.21251393913,
              5003432.8521584421
            ],
            [
              360620.19934368,
              5003422.0738028139
            ],
            [
              360619.51882686786,
              5003418.4133149441
            ],
            [
              360634.68390377663,
              5003388.5422114609
            ],
            [
              360646.07844620553,
              5003373.7725734403
            ],
            [
              360638.12968344882,
              5003363.5130349752
            ],
            [
              360656.95384741708,
              5003343.7771248044
            ],
            [
              360676.63419845706,
              5003359.1242409945
            ],
            [
              360688.06062726228,
              5003372.5432468839
            ],
            [
              360706.19111468078,
              5003389.5925631411
            ],
            [
              360707.67657978513,
              5003392.6259230822
            ],
            [
              360721.547719069,
              5003402.9794264166
            ],
            [
              360734.53182384721,
              5003414.3482513605
            ],
            [
              360723.9062655734,
              5003427.6346546188
            ],
            [
              360739.42762118456,
              5003440.7874717554
            ],
            [
              360744.10990400129,
              5003428.1627309164
            ],
            [
              360763.26481851598,
              5003435.6163379997
            ],
            [
              360768.66393771901,
              5003426.6210786253
            ],
            [
              360776.84814438055,
              5003433.8890612898
            ],
            [
              360783.27074971359,
              5003425.9809626536
            ],
            [
              360721.81231510156,
              5003377.4993435368
            ],
            [
              360732.09290060226,
              5003365.4266001796
            ],
            [
              360747.23691156186,
              5003358.6923878482
            ],
            [
              360789.19254277262,
              5003295.741195526
            ],
            [
              360805.16868170857,
              5003277.7599340407
            ],
            [
              360824.59361354372,
              5003251.9777238695
            ],
            [
              360817.1553647418,
              5003244.0149862505
            ],
            [
              360789.155757175,
              5003224.9168728665
            ],
            [
              360771.62758425187,
              5003218.8873204831
            ],
            [
              360757.23164222261,
              5003217.773325325
            ],
            [
              360736.36672385078,
              5003210.7005287213
            ],
            [
              360698.62897312111,
              5003194.0322823767
            ],
            [
              360688.59500179114,
              5003187.8463206561
            ],
            [
              360688.18377393298,
              5003186.6075693062
            ],
            [
              360682.26530509157,
              5003168.7792247757
            ],
            [
              360680.22980388685,
              5003164.2656211015
            ],
            [
              360689.97048873088,
              5003151.4733157912
            ],
            [
              360699.74944564607,
              5003143.8755958555
            ],
            [
              360705.97473949328,
              5003142.4266402395
            ],
            [
              360690.06792659062,
              5003130.0045670103
            ],
            [
              360682.48852527264,
              5003136.7372123925
            ],
            [
              360662.73948031652,
              5003122.1110207634
            ],
            [
              360669.32828849059,
              5003115.3284521634
            ],
            [
              360647.42317708931,
              5003100.3763822336
            ],
            [
              360639.35401459032,
              5003109.1517731305
            ],
            [
              360631.85468149773,
              5003115.1077373968
            ],
            [
              360621.97995944764,
              5003125.5784996217
            ],
            [
              360608.15109898231,
              5003133.7850942602
            ],
            [
              360602.55372176133,
              5003130.1466738479
            ],
            [
              360595.3887239552,
              5003126.0574047854
            ],
            [
              360593.27096018038,
              5003128.0631488403
            ],
            [
              360587.68331778998,
              5003135.3752717329
            ],
            [
              360580.18344598293,
              5003143.0492799049
            ],
            [
              360568.65030449681,
              5003155.0133714005
            ],
            [
              360559.63141960045,
              5003162.4688518476
            ],
            [
              360558.11927164753,
              5003164.2849928811
            ],
            [
              360547.45076613262,
              5003172.6483143382
            ],
            [
              360515.87652195658,
              5003195.6163288392
            ],
            [
              360513.08662318956,
              5003198.8349956349
            ],
            [
              360511.15196419536,
              5003200.3092737217
            ],
            [
              360496.13657678169,
              5003214.3734274292
            ],
            [
              360484.57727734215,
              5003220.3143863799
            ],
            [
              360465.49216959812,
              5003198.9650142659
            ],
            [
              360441.42745673266,
              5003172.6958290208
            ],
            [
              360444.67423444282,
              5003169.9055702463
            ],
            [
              360451.28251020605,
              5003162.2858911213
            ],
            [
              360452.04982752417,
              5003160.3802984124
            ],
            [
              360454.75223522622,
              5003157.6937076561
            ],
            [
              360465.64860932104,
              5003151.0476693409
            ],
            [
              360477.97104071383,
              5003144.2672697278
            ],
            [
              360490.96507674264,
              5003112.2932275366
            ],
            [
              360507.01389818458,
              5003091.5416972553
            ],
            [
              360504.0863415908,
              5003090.8525253003
            ],
            [
              360473.30221099843,
              5003070.114028451
            ],
            [
              360470.25781068421,
              5003067.3300935403
            ],
            [
              360463.15218352125,
              5003073.7607430862
            ],
            [
              360447.49010533455,
              5003087.6071809223
            ],
            [
              360433.78403165768,
              5003099.612403336
            ],
            [
              360414.84191561892,
              5003125.2564057522
            ],
            [
              360402.34819143539,
              5003142.0599357523
            ],
            [
              360390.30729499197,
              5003163.9285037117
            ],
            [
              360377.68913665455,
              5003183.6323404815
            ],
            [
              360371.39955030783,
              5003193.0937396893
            ],
            [
              360358.9246214772,
              5003185.2650684221
            ],
            [
              360316.80357063346,
              5003154.0922536692
            ],
            [
              360279.29895522405,
              5003124.4716230202
            ],
            [
              360237.11338410218,
              5003088.6906109303
            ],
            [
              360196.84395932249,
              5003054.894641757
            ],
            [
              360153.82905865781,
              5003019.8636191897
            ],
            [
              360137.30524004874,
              5003033.3210267313
            ],
            [
              360129.20415281301,
              5003039.992837457
            ],
            [
              360115.80895427748,
              5003031.0100519611
            ],
            [
              360110.92680523585,
              5003037.7943090936
            ],
            [
              360107.5067677332,
              5003041.9769736296
            ],
            [
              360105.24776757299,
              5003044.8168864101
            ],
            [
              360103.24431299634,
              5003047.7479810147
            ],
            [
              360099.73557389417,
              5003052.9774377998
            ],
            [
              360096.08621778287,
              5003058.480072232
            ],
            [
              360083.27600581356,
              5003074.5771315088
            ],
            [
              360076.99586581049,
              5003083.8564373637
            ],
            [
              360060.48095350096,
              5003080.7389412066
            ],
            [
              360041.437543687,
              5003079.1289581321
            ],
            [
              360026.30285090482,
              5003079.2862025434
            ],
            [
              360014.15879466955,
              5003080.1320347171
            ],
            [
              359991.18258984969,
              5003085.6439681612
            ],
            [
              359983.33147530729,
              5003083.8055387279
            ],
            [
              359971.69010969676,
              5003078.3168625059
            ],
            [
              359961.95687270991,
              5003085.0968112815
            ],
            [
              359950.04405458667,
              5003093.1309171254
            ],
            [
              359937.83618632919,
              5003095.6726903263
            ],
            [
              359932.98819396336,
              5003099.6208910951
            ],
            [
              359911.62729085982,
              5003130.4772042362
            ],
            [
              359915.77175621263,
              5003134.0286206501
            ],
            [
              359895.20127704885,
              5003159.186657018
            ],
            [
              359878.76648794499,
              5003178.2587516289
            ],
            [
              359870.46399596176,
              5003190.5661312444
            ],
            [
              359865.70628971694,
              5003196.2091704635
            ],
            [
              359863.87824147852,
              5003196.2638091985
            ],
            [
              359854.44625754614,
              5003209.1907599978
            ],
            [
              359878.76907994045,
              5003231.2171202609
            ],
            [
              359902.41316989408,
              5003251.6952153454
            ],
            [
              359908.95060357213,
              5003257.6054233341
            ],
            [
              359912.45224488905,
              5003260.9760554768
            ],
            [
              359916.26657494728,
              5003264.846155108
            ],
            [
              359921.32421472209,
              5003270.3099758606
            ],
            [
              359926.89687499247,
              5003276.2694323314
            ],
            [
              359930.92467060027,
              5003280.7418707246
            ],
            [
              359938.08635961934,
              5003289.4662562693
            ],
            [
              359942.97190478427,
              5003296.5500687072
            ],
            [
              359949.73558959021,
              5003306.7315336736
            ],
            [
              359956.20911721193,
              5003317.6257961998
            ],
            [
              359970.94418108708,
              5003341.3745833309
            ],
            [
              359980.76048517035,
              5003359.6827766625
            ],
            [
              359993.17438368406,
              5003390.8788862163
            ],
            [
              359997.20086810697,
              5003419.6668811962
            ],
            [
              360003.68484612083,
              5003462.9544755258
            ],
            [
              360004.73822200246,
              5003470.7030917164
            ],
            [
              360004.66153017821,
              5003472.0181930177
            ],
            [
              360004.28334847075,
              5003473.4399253903
            ],
            [
              359996.79289814463,
              5003494.9009020515
            ],
            [
              359994.63416554901,
              5003505.8243978266
            ],
            [
              359987.22979628871,
              5003526.4753434956
            ],
            [
              359978.55143346672,
              5003533.0028651105
            ],
            [
              359976.79310131021,
              5003530.9134741668
            ],
            [
              359974.10443613277,
              5003533.1865837155
            ],
            [
              359976.07060155337,
              5003535.5752649568
            ],
            [
              359933.12552817015,
              5003566.2509283032
            ],
            [
              359929.1260747023,
              5003561.510005651
            ],
            [
              359881.18809964129,
              5003594.4341813521
            ],
            [
              359880.48777506116,
              5003594.851379062
            ],
            [
              359879.88298490294,
              5003594.9636832802
            ],
            [
              359879.07787132397,
              5003595.180738329
            ],
            [
              359878.36634810275,
              5003594.9918730529
            ],
            [
              359875.50335622678,
              5003593.3272304526
            ],
            [
              359874.99027795246,
              5003592.9325507376
            ],
            [
              359874.47907748964,
              5003592.6388990851
            ],
            [
              359873.96975483734,
              5003592.4462754959
            ],
            [
              359873.26010875736,
              5003592.3584027356
            ],
            [
              359870.61997070356,
              5003591.8011683384
            ],
            [
              359864.40490982513,
              5003580.1049400559
            ],
            [
              359851.92557306663,
              5003612.2096592309
            ],
            [
              359850.07177227264,
              5003610.4251957573
            ],
            [
              359847.8848524795,
              5003612.4868548289
            ],
            [
              359850.14875351958,
              5003614.5668491051
            ],
            [
              359827.37405456579,
              5003636.5638934355
            ],
            [
              359814.63654995133,
              5003647.8152328078
            ],
            [
              359812.9774387356,
              5003645.6229342092
            ],
            [
              359809.88806055923,
              5003648.1055842787
            ],
            [
              359811.84547217638,
              5003650.7470328752
            ],
            [
              359770.63097155682,
              5003687.7347409567
            ],
            [
              359768.87827024999,
              5003685.9483982883
            ],
            [
              359766.19335950108,
              5003688.4235310797
            ],
            [
              359767.74198235595,
              5003690.1126040192
            ],
            [
              359755.68582123175,
              5003701.2890256364
            ],
            [
              359754.48601884284,
              5003702.4012783449
            ],
            [
              359759.22177974664,
              5003704.781902886
            ],
            [
              359768.3530714126,
              5003717.1310114386
            ],
            [
              359770.09625583905,
              5003719.0627721138
            ],
            [
              359771.3574955028,
              5003720.3641513456
            ],
            [
              359772.99337580608,
              5003722.806742019
            ],
            [
              359769.78943341743,
              5003731.0801023189
            ],
            [
              359755.52475788177,
              5003743.2685499424
            ],
            [
              359746.79294857039,
              5003762.5819762498
            ],
            [
              359747.26092866744,
              5003764.7140463293
            ],
            [
              359767.91666091816,
              5003778.2338920441
            ],
            [
              359799.1415488855,
              5003798.144764469
            ],
            [
              359800.87557291513,
              5003799.2542792903
            ],
            [
              359805.80277748237,
              5003803.1587980483
            ],
            [
              359822.48988813884,
              5003815.7540452983
            ],
            [
              359829.9555881074,
              5003821.38325842
            ],
            [
              359835.34166397445,
              5003823.6035519419
            ],
            [
              359838.08814543992,
              5003825.311655838
            ],
            [
              359835.04611611052,
              5003830.6487659635
            ],
            [
              359825.66950193321,
              5003849.2900726479
            ],
            [
              359817.2862562667,
              5003866.857503064
            ],
            [
              359810.31178843626,
              5003883.3997185109
            ],
            [
              359807.33605753857,
              5003892.3035481293
            ],
            [
              359795.48305877944,
              5003922.9228176195
            ],
            [
              359792.03626646317,
              5003931.5158026312
            ],
            [
              359789.30622570147,
              5003938.2742979033
            ],
            [
              359781.11080675508,
              5003958.2644458683
            ],
            [
              359777.26010961033,
              5003966.1855185013
            ],
            [
              359768.15524237562,
              5003983.3382076053
            ],
            [
              359764.8343133445,
              5003996.9396812823
            ],
            [
              359755.19111819629,
              5004005.7509851102
            ],
            [
              359748.73419682699,
              5004011.84111764
            ],
            [
              359748.56022942142,
              5004011.7057649409
            ],
            [
              359742.51314946654,
              5004005.0963181742
            ],
            [
              359730.45811732399,
              5003995.0562177403
            ],
            [
              359719.73760001996,
              5003985.8042531544
            ],
            [
              359711.90112009266,
              5003979.3796468033
            ],
            [
              359687.67887117836,
              5003962.3487301478
            ],
            [
              359682.47523767699,
              5003959.457750096
            ],
            [
              359680.90094225225,
              5003967.1151470738
            ],
            [
              359678.0661373568,
              5003976.9706614381
            ],
            [
              359672.25993619871,
              5003997.5206213137
            ],
            [
              359667.7055188879,
              5004011.6971394615
            ],
            [
              359664.80760286911,
              5004025.6788389925
            ],
            [
              359660.89088185638,
              5004046.4883110058
            ],
            [
              359657.35640218906,
              5004057.613247172
            ],
            [
              359648.94973188551,
              5004081.671047546
            ],
            [
              359645.47862292785,
              5004097.5389656499
            ],
            [
              359644.14575400495,
              5004102.3324916363
            ],
            [
              359641.17711067252,
              5004107.4813578362
            ],
            [
              359634.79754673544,
              5004117.5527074449
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 343,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359662.89673588501,
              5003810.3065391304
            ],
            [
              359667.24983589299,
              5003812.7882832689
            ],
            [
              359672.54650279199,
              5003816.3800490294
            ],
            [
              359678.23039536335,
              5003819.4768361272
            ],
            [
              359682.69320694526,
              5003821.4491557162
            ],
            [
              359685.77908712806,
              5003823.3321091672
            ],
            [
              359688.8727438582,
              5003826.16957541
            ],
            [
              359691.9190293905,
              5003829.4854372814
            ],
            [
              359692.37898185488,
              5003832.3256896939
            ],
            [
              359693.94022824225,
              5003836.5087000169
            ],
            [
              359694.77453074657,
              5003841.8641871065
            ],
            [
              359683.7526693503,
              5003863.4269509418
            ],
            [
              359682.48141929042,
              5003865.7747813938
            ],
            [
              359681.59961504169,
              5003867.3069398189
            ],
            [
              359680.71211086679,
              5003868.5360151334
            ],
            [
              359677.75018759567,
              5003872.4310392067
            ],
            [
              359671.10341742152,
              5003879.426056494
            ],
            [
              359667.1932858603,
              5003883.4165205639
            ],
            [
              359664.81167678873,
              5003885.8860179028
            ],
            [
              359662.42443468189,
              5003888.0524664428
            ],
            [
              359659.93233708368,
              5003890.0187735427
            ],
            [
              359656.43855791003,
              5003892.5089438818
            ],
            [
              359651.04426130984,
              5003896.1459920751
            ],
            [
              359641.5476391358,
              5003902.1834595036
            ],
            [
              359639.05178632069,
              5003903.9477461036
            ],
            [
              359635.26590582408,
              5003907.0496534202
            ],
            [
              359617.13410548505,
              5003921.9380225157
            ],
            [
              359610.10154851392,
              5003915.5161399664
            ],
            [
              359608.12252908008,
              5003917.8770915633
            ],
            [
              359615.32608798024,
              5003924.5135982893
            ],
            [
              359614.94603047235,
              5003925.8343401616
            ],
            [
              359613.97244794038,
              5003927.8734491421
            ],
            [
              359612.69744224095,
              5003930.0192240346
            ],
            [
              359611.99016341328,
              5003930.9180145087
            ],
            [
              359611.09897103399,
              5003931.9451038018
            ],
            [
              359610.60654637252,
              5003932.6615916658
            ],
            [
              359609.72662015253,
              5003934.2947787242
            ],
            [
              359608.48165713827,
              5003938.0567906639
            ],
            [
              359606.25747927424,
              5003943.554898358
            ],
            [
              359603.85551104008,
              5003950.369988312
            ],
            [
              359600.50249228725,
              5003960.436347519
            ],
            [
              359599.05908463598,
              5003964.4041744638
            ],
            [
              359598.48997000663,
              5003966.4357653558
            ],
            [
              359597.89191948186,
              5003967.9268175559
            ],
            [
              359597.20480583521,
              5003969.0511765964
            ],
            [
              359594.23154985154,
              5003972.3400703939
            ],
            [
              359585.78737342369,
              5003980.5811162079
            ],
            [
              359581.70194711775,
              5003983.8906834191
            ],
            [
              359579.30343880702,
              5003985.4510353543
            ],
            [
              359577.40298754681,
              5003986.597912671
            ],
            [
              359574.4970331699,
              5003988.066634085
            ],
            [
              359570.28603039024,
              5003990.0648937374
            ],
            [
              359566.57315089385,
              5003991.6496775439
            ],
            [
              359561.24615552375,
              5003993.466555452
            ],
            [
              359557.22239815444,
              5003994.6529368507
            ],
            [
              359554.14041705435,
              5003995.7900324073
            ],
            [
              359548.01776201016,
              5003998.3290351257
            ],
            [
              359531.2486277952,
              5004004.9059425332
            ],
            [
              359522.32675187808,
              5004009.2148726098
            ],
            [
              359511.89199262235,
              5004013.7540153638
            ],
            [
              359509.48221765814,
              5004014.7082698941
            ],
            [
              359506.77289671433,
              5004015.8701830804
            ],
            [
              359496.96163958462,
              5004021.3072330188
            ],
            [
              359485.57077287714,
              5004027.128486054
            ],
            [
              359481.76423643285,
              5004029.1191927996
            ],
            [
              359478.45763571071,
              5004030.7974884761
            ],
            [
              359475.04617867758,
              5004032.2756071165
            ],
            [
              359472.13459046517,
              5004033.4412803613
            ],
            [
              359468.51530006301,
              5004034.6201441614
            ],
            [
              359464.39225238003,
              5004035.9093995281
            ],
            [
              359460.26739295904,
              5004037.0976255098
            ],
            [
              359455.73048889998,
              5004037.8893294772
            ],
            [
              359452.90316768351,
              5004038.1439752737
            ],
            [
              359451.48575150763,
              5004038.0692595709
            ],
            [
              359449.88109049125,
              5004038.8064580783
            ],
            [
              359446.69248944899,
              5004041.392021778
            ],
            [
              359395.6228934059,
              5004070.9800388254
            ],
            [
              359396.87003023777,
              5004072.7757791756
            ],
            [
              359395.18867873558,
              5004074.8280461831
            ],
            [
              359391.68572785787,
              5004078.9926329013
            ],
            [
              359389.91266440792,
              5004081.5518492069
            ],
            [
              359388.72749284288,
              5004083.089648623
            ],
            [
              359387.33636516909,
              5004084.4291857854
            ],
            [
              359385.24685671827,
              5004086.2869492425
            ],
            [
              359382.64990190754,
              5004088.0530822659
            ],
            [
              359380.85618355795,
              5004089.5011305809
            ],
            [
              359378.86589822586,
              5004091.2560221329
            ],
            [
              359377.37735846161,
              5004092.7994252415
            ],
            [
              359376.09484239662,
              5004094.5411604689
            ],
            [
              359375.71109561046,
              5004095.6598458067
            ],
            [
              359375.63621709449,
              5004097.0759435426
            ],
            [
              359360.85337508103,
              5004096.2391822394
            ],
            [
              359360.62893896701,
              5004100.4875074821
            ],
            [
              359376.01469457272,
              5004101.1109706564
            ],
            [
              359376.43230631232,
              5004101.8105430296
            ],
            [
              359376.87050742557,
              5004103.6213202868
            ],
            [
              359376.90430682444,
              5004105.4396147672
            ],
            [
              359376.63668290508,
              5004107.3645753749
            ],
            [
              359376.25850314629,
              5004108.7863110388
            ],
            [
              359375.47404436191,
              5004110.1145712687
            ],
            [
              359374.78317480662,
              5004111.0368749071
            ],
            [
              359373.99489384115,
              5004112.1630799677
            ],
            [
              359373.70671367395,
              5004113.2531501064
            ],
            [
              359373.63002417563,
              5004114.568253953
            ],
            [
              359373.6826006737,
              5004117.39668837
            ],
            [
              359373.50668872759,
              5004118.8146997336
            ],
            [
              359373.22585416556,
              5004120.0325352326
            ],
            [
              359372.54625046882,
              5004121.5609015301
            ],
            [
              359370.98108826537,
              5004124.4194429582
            ],
            [
              359368.22317717149,
              5004128.4117080206
            ],
            [
              359364.45795079385,
              5004132.6247884119
            ],
            [
              359361.67569497606,
              5004135.3038277989
            ],
            [
              359359.57860981859,
              5004136.7575511662
            ],
            [
              359356.48178615602,
              5004138.8361303844
            ],
            [
              359346.72628326848,
              5004144.9409509031
            ],
            [
              359344.02252916817,
              5004146.4059515409
            ],
            [
              359341.91799829231,
              5004147.4555609589
            ],
            [
              359339.60744563729,
              5004148.3069449058
            ],
            [
              359337.39430370746,
              5004148.9543918716
            ],
            [
              359335.47882981849,
              5004149.2931517707
            ],
            [
              359333.5633559206,
              5004149.6319116754
            ],
            [
              359330.94011265226,
              5004149.9838280594
            ],
            [
              359329.4196512762,
              5004149.8099651309
            ],
            [
              359328.00411254709,
              5004149.8362780856
            ],
            [
              359326.08676129003,
              5004150.0740453126
            ],
            [
              359324.27426510386,
              5004150.511918596
            ],
            [
              359321.45633212646,
              5004151.2716709897
            ],
            [
              359318.4398872765,
              5004152.2372041754
            ],
            [
              359316.43270141224,
              5004153.082913477
            ],
            [
              359314.53224926558,
              5004154.2297926005
            ],
            [
              359314.03419202712,
              5004154.6432678001
            ],
            [
              359313.42940106097,
              5004154.7555731898
            ],
            [
              359312.41833031591,
              5004154.7743676454
            ],
            [
              359311.39968205796,
              5004154.3890859699
            ],
            [
              359310.88472498098,
              5004153.8934137654
            ],
            [
              359310.6693795459,
              5004153.1900459081
            ],
            [
              359310.64496912004,
              5004151.8768566689
            ],
            [
              359310.8321475109,
              5004151.0649432996
            ],
            [
              359310.82088096096,
              5004150.4588450268
            ],
            [
              359310.4052126109,
              5004149.860264034
            ],
            [
              359309.88837755995,
              5004149.2635635957
            ],
            [
              359309.07199711393,
              5004148.874557565
            ],
            [
              359308.46345018077,
              5004148.7848064955
            ],
            [
              359307.85678122455,
              5004148.7960836506
            ],
            [
              359306.45432068018,
              5004149.5295243412
            ],
            [
              359305.85328499455,
              5004149.8438506406
            ],
            [
              359305.44888318941,
              5004149.8513679253
            ],
            [
              359304.9414367014,
              5004149.7597375344
            ],
            [
              359303.81981065689,
              5004149.2753424682
            ],
            [
              359301.98290393932,
              5004148.4000265002
            ],
            [
              359300.35765293968,
              5004148.0260207197
            ],
            [
              359299.34651590383,
              5004148.044816385
            ],
            [
              359298.73984693171,
              5004148.056093541
            ],
            [
              359298.13881123153,
              5004148.3704198431
            ],
            [
              359297.84302112192,
              5004148.7801351845
            ],
            [
              359297.75506453373,
              5004149.4891054956
            ],
            [
              359298.07526571874,
              5004150.3926149784
            ],
            [
              359298.7980576547,
              5004151.1876132488
            ],
            [
              359299.5189709501,
              5004151.8815477556
            ],
            [
              359300.04331727821,
              5004152.882325585
            ],
            [
              359300.79051964782,
              5004154.9905131236
            ],
            [
              359301.65015529684,
              5004157.7029183907
            ],
            [
              359301.66893246351,
              5004158.7130585182
            ],
            [
              359301.68207700999,
              5004159.420185037
            ],
            [
              359301.39560924977,
              5004160.3349716747
            ],
            [
              359300.69535109453,
              5004160.7522055479
            ],
            [
              359299.98758167174,
              5004160.7653620495
            ],
            [
              359298.01190600608,
              5004160.3184558852
            ],
            [
              359296.89966851141,
              5004160.3391309148
            ],
            [
              359296.09267660818,
              5004160.4551949697
            ],
            [
              359295.39054046024,
              5004160.7714006193
            ],
            [
              359294.69967088144,
              5004161.6937045753
            ],
            [
              359293.91514520446,
              5004163.0219309358
            ],
            [
              359293.23742018588,
              5004164.6513614366
            ],
            [
              359292.74311823497,
              5004165.2668575998
            ],
            [
              359291.94551491836,
              5004165.8879917441
            ],
            [
              359290.83891069208,
              5004166.211715945
            ],
            [
              359289.22304821579,
              5004166.3427803162
            ],
            [
              359288.10517740384,
              5004166.060406209
            ],
            [
              359287.49475310609,
              5004165.8696624693
            ],
            [
              359287.08284000761,
              5004165.4731023815
            ],
            [
              359286.75693923206,
              5004164.2665449567
            ],
            [
              359286.42547208711,
              5004162.7569726752
            ],
            [
              359286.10144864192,
              5004161.6514079412
            ],
            [
              359285.68014695891,
              5004160.7497777622
            ],
            [
              359285.16518983361,
              5004160.254105526
            ],
            [
              359284.3524983669,
              5004160.0671216538
            ],
            [
              359283.54550577374,
              5004160.1831501741
            ],
            [
              359282.95010334213,
              5004160.8005256746
            ],
            [
              359281.79873148672,
              5004164.156620821
            ],
            [
              359281.81187604513,
              5004164.8637473797
            ],
            [
              359282.03479838622,
              5004165.9711559154
            ],
            [
              359282.1509207288,
              5004166.7773958407
            ],
            [
              359282.16969858407,
              5004167.7875715615
            ],
            [
              359281.98996458092,
              5004169.0035280976
            ],
            [
              359281.40213969193,
              5004170.0249797804
            ],
            [
              359280.30867934664,
              5004171.0557950279
            ],
            [
              359279.70952162053,
              5004171.4711496253
            ],
            [
              359278.90440700471,
              5004171.6882064072
            ],
            [
              359277.99437036796,
              5004171.7051228173
            ],
            [
              359275.96465179842,
              5004171.3386711972
            ],
            [
              359273.53609775665,
              5004171.2827517483
            ],
            [
              359271.51388978033,
              5004171.3203419913
            ],
            [
              359270.50463064609,
              5004171.4401659658
            ],
            [
              359269.80061647022,
              5004171.6553434264
            ],
            [
              359269.40184791753,
              5004171.9659099095
            ],
            [
              359267.91143072123,
              5004173.408321159
            ],
            [
              359267.7204963885,
              5004174.0181781659
            ],
            [
              359267.73176298215,
              5004174.6242765151
            ],
            [
              359267.84600802563,
              5004175.3295237739
            ],
            [
              359268.15688628826,
              5004175.7279264312
            ],
            [
              359268.76918861177,
              5004176.0196984001
            ],
            [
              359269.47883608384,
              5004176.1075701043
            ],
            [
              359271.70324499469,
              5004176.0662211729
            ],
            [
              359272.3099140132,
              5004176.0549439685
            ],
            [
              359272.81736055709,
              5004176.1465743287
            ],
            [
              359273.22746195103,
              5004176.4421049496
            ],
            [
              359273.33607303625,
              5004176.8442674922
            ],
            [
              359273.24248386105,
              5004177.2502242262
            ],
            [
              359272.94662751228,
              5004177.6599408491
            ],
            [
              359272.44669225608,
              5004177.9723878987
            ],
            [
              359270.73160722299,
              5004178.2063598996
            ],
            [
              359270.12493820145,
              5004178.2176371086
            ],
            [
              359269.42280201794,
              5004178.5338428207
            ],
            [
              359268.62513241911,
              5004179.1549782688
            ],
            [
              359267.8312843857,
              5004179.978133427
            ],
            [
              359267.34449371567,
              5004180.9977070708
            ],
            [
              359267.06184755324,
              5004182.1145135248
            ],
            [
              359266.97389168665,
              5004182.8235194441
            ],
            [
              359266.67803467956,
              5004183.2332005454
            ],
            [
              359266.37473329069,
              5004183.2388385404
            ],
            [
              359265.86353142059,
              5004182.9451872483
            ],
            [
              359265.44591937994,
              5004182.2456147494
            ],
            [
              359265.43653009966,
              5004181.7405090965
            ],
            [
              359265.62565334531,
              5004181.0296581499
            ],
            [
              359266.11988905497,
              5004180.4141631452
            ],
            [
              359266.50933523983,
              5004179.5985253025
            ],
            [
              359266.69651358842,
              5004178.7866118159
            ],
            [
              359266.57287992508,
              5004177.5762944426
            ],
            [
              359266.35753507481,
              5004176.8729620473
            ],
            [
              359265.94555568584,
              5004176.4764031824
            ],
            [
              359265.33325335605,
              5004176.1846312108
            ],
            [
              359264.82392947649,
              5004175.9920081478
            ],
            [
              359264.32030448626,
              5004176.102397494
            ],
            [
              359263.61816828715,
              5004176.4186032079
            ],
            [
              359263.22684476542,
              5004177.1332483524
            ],
            [
              359262.65209754417,
              5004178.8617923958
            ],
            [
              359261.98000584234,
              5004180.7942722552
            ],
            [
              359261.20493569743,
              5004182.6276032124
            ],
            [
              359260.23510901403,
              5004184.8687359579
            ],
            [
              359258.38535710186,
              5004188.7430936266
            ],
            [
              359258.10640002473,
              5004190.0619222922
            ],
            [
              359258.12893327052,
              5004191.2741190242
            ],
            [
              359258.45858948998,
              5004192.6826974684
            ],
            [
              359258.98481399025,
              5004193.7845035931
            ],
            [
              359260.0127848335,
              5004194.674856564
            ],
            [
              359264.59338192735,
              5004197.5919206319
            ],
            [
              359258.6337246449,
              5004203.4626275338
            ],
            [
              359257.71812088566,
              5004203.1764936168
            ],
            [
              359256.29507054907,
              5004202.7987293694
            ],
            [
              359254.77648747171,
              5004202.7259304123
            ],
            [
              359253.66619342525,
              5004202.8475970533
            ],
            [
              359252.45848864503,
              5004203.1732007498
            ],
            [
              359250.95680551982,
              5004204.0095493589
            ],
            [
              359249.36159883201,
              5004205.2518179826
            ],
            [
              359248.47597275273,
              5004206.5819239542
            ],
            [
              359247.50809043896,
              5004208.9240838597
            ],
            [
              359246.72550974629,
              5004210.35337306
            ],
            [
              359246.04208461783,
              5004211.6797203757
            ],
            [
              359245.14901413902,
              5004212.6057832465
            ],
            [
              359243.85335352988,
              5004213.6403929563
            ],
            [
              359242.15698012355,
              5004214.8845422212
            ],
            [
              359241.26390964375,
              5004215.8106051078
            ],
            [
              359240.47381697263,
              5004216.8357813703
            ],
            [
              359239.98890437215,
              5004217.9563833922
            ],
            [
              359239.40289064468,
              5004219.0788292242
            ],
            [
              359239.11454499309,
              5004219.892587834
            ],
            [
              359238.62218731473,
              5004220.6091111889
            ],
            [
              359237.13364819007,
              5004222.1525510233
            ],
            [
              359231.81848406064,
              5004226.6418957394
            ],
            [
              359230.42353472114,
              5004227.7794143669
            ],
            [
              359228.62424907554,
              5004228.924413613
            ],
            [
              359225.42994716286,
              5004231.2068972243
            ],
            [
              359223.63629551255,
              5004232.6549812555
            ],
            [
              359222.94348172902,
              5004233.4762585294
            ],
            [
              359222.55597987818,
              5004234.3929235535
            ],
            [
              359222.37631233211,
              5004235.6088791024
            ],
            [
              359222.39509039273,
              5004236.6190549927
            ],
            [
              359222.72280257539,
              5004237.9266065191
            ],
            [
              359223.04300406395,
              5004238.8301161155
            ],
            [
              359223.77149530768,
              5004239.9281268204
            ],
            [
              359217.32504750148,
              5004246.8184090378
            ],
            [
              359216.39174877276,
              5004247.8193731494
            ],
            [
              359216.08838108898,
              5004247.8250124343
            ],
            [
              359215.68022382521,
              5004247.6305088671
            ],
            [
              359215.270122302,
              5004247.3349782797
            ],
            [
              359214.45749687526,
              5004247.1479933439
            ],
            [
              359213.34338110877,
              5004247.0676404079
            ],
            [
              359212.02894238877,
              5004247.0920744203
            ],
            [
              359210.51599180832,
              5004247.3222893411
            ],
            [
              359208.09877093183,
              5004247.8725031745
            ],
            [
              359205.47546055721,
              5004248.2244216735
            ],
            [
              359204.16665517155,
              5004248.5519049214
            ],
            [
              359203.55998603516,
              5004248.5631822571
            ],
            [
              359202.7454825723,
              5004248.2751690615
            ],
            [
              359202.13130206265,
              5004247.882368912
            ],
            [
              359201.50961084227,
              5004247.0855268063
            ],
            [
              359200.6462844747,
              5004244.1710632332
            ],
            [
              359200.42155007477,
              5004242.9626250006
            ],
            [
              359200.10691565991,
              5004242.3621658124
            ],
            [
              359199.79422550433,
              5004241.8627336472
            ],
            [
              359199.28302348475,
              5004241.5690823868
            ],
            [
              359198.57525385747,
              5004241.5822390616
            ],
            [
              359197.06793657201,
              5004242.1155032013
            ],
            [
              359195.76476450701,
              5004242.7460356727
            ],
            [
              359194.96903911285,
              5004243.4681984289
            ],
            [
              359193.08924269542,
              5004245.7262826916
            ],
            [
              359192.40588384919,
              5004247.0526290573
            ],
            [
              359192.12692760525,
              5004248.3714935584
            ],
            [
              359191.7600812114,
              5004250.3993273843
            ],
            [
              359191.27892400924,
              5004251.7219505953
            ],
            [
              359190.70229969634,
              5004253.3495024014
            ],
            [
              359189.79083982768,
              5004254.9527348075
            ],
            [
              359188.93299253652,
              5004256.110777623
            ],
            [
              359187.14302925119,
              5004257.7608486153
            ],
            [
              359185.25008812739,
              5004259.3118062569
            ],
            [
              359183.74847043731,
              5004260.148118509
            ],
            [
              359182.14193064155,
              5004260.7842903687
            ],
            [
              359180.33131251333,
              5004261.3232282177
            ],
            [
              359178.61810509203,
              5004261.6582289608
            ],
            [
              359176.29816222604,
              5004262.004508297
            ],
            [
              359173.16183778777,
              5004261.9617461888
            ],
            [
              359169.668159494,
              5004261.9867340624
            ],
            [
              359167.24523806502,
              5004262.233864502
            ],
            [
              359164.92529512435,
              5004262.5801438559
            ],
            [
              359162.61480716034,
              5004263.4314922439
            ],
            [
              359160.20690846461,
              5004264.4867777703
            ],
            [
              359158.61163532536,
              5004265.7290482316
            ],
            [
              359158.30271898984,
              5004266.0057766018
            ],
            [
              359156.42290399264,
              5004267.6897216579
            ],
            [
              359154.54122951051,
              5004269.846778037
            ],
            [
              359153.26434624242,
              5004271.8915287917
            ],
            [
              359152.19154198823,
              5004274.033549116
            ],
            [
              359151.42586085747,
              5004276.3719511488
            ],
            [
              359150.87177015917,
              5004279.2117002904
            ],
            [
              359150.92622606939,
              5004282.1411647769
            ],
            [
              359151.40198418067,
              5004285.9722596444
            ],
            [
              359151.75041910919,
              5004288.3910145257
            ],
            [
              359151.87780858512,
              5004289.8033532677
            ],
            [
              359151.70001922024,
              5004291.1203373633
            ],
            [
              359151.3143292137,
              5004292.138032143
            ],
            [
              359150.22462486732,
              5004293.3709047129
            ],
            [
              359148.02838221187,
              5004294.9275008123
            ],
            [
              359145.42767130682,
              5004296.4916156214
            ],
            [
              359141.71659976436,
              5004298.1773986761
            ],
            [
              359133.97606728092,
              5004300.7465201681
            ],
            [
              359123.05109756981,
              5004304.1715055974
            ],
            [
              359120.83795454464,
              5004304.8189539798
            ],
            [
              359118.62286789011,
              5004305.3654108616
            ],
            [
              359116.40596878767,
              5004305.8108026991
            ],
            [
              359114.49237185234,
              5004306.2505919766
            ],
            [
              359112.07696150983,
              5004306.9018003596
            ],
            [
              359108.85455885978,
              5004307.6690732157
            ],
            [
              359104.02749347762,
              5004309.1735110618
            ],
            [
              359099.8052862378,
              5004310.5656775227
            ],
            [
              359092.68081105367,
              5004313.6285925228
            ],
            [
              359083.55672664446,
              5004317.9412948787
            ],
            [
              359079.65472102555,
              5004320.2369714268
            ],
            [
              359078.15115874348,
              5004320.9722571429
            ],
            [
              359077.04831003881,
              5004321.4980386309
            ],
            [
              359075.64028108638,
              5004321.9283944126
            ],
            [
              359073.3222818714,
              5004322.3757013222
            ],
            [
              359069.29094223294,
              5004323.158011863
            ],
            [
              359066.06102775485,
              5004323.5212073214
            ],
            [
              359062.61978270818,
              5004319.9530159831
            ],
            [
              359056.10760966624,
              5004323.3077033954
            ],
            [
              359060.36210816813,
              5004329.0896064965
            ],
            [
              359054.49228325096,
              5004334.3523410689
            ],
            [
              359056.36299242458,
              5004337.0459528165
            ],
            [
              359048.29504645587,
              5004343.7642561523
            ],
            [
              359047.05541842751,
              5004342.372592641
            ],
            [
              359042.97374465293,
              5004345.8841904141
            ],
            [
              359045.14587818139,
              5004348.4711712785
            ],
            [
              359038.65736716223,
              5004354.3037966946
            ],
            [
              359042.34456499381,
              5004362.2183240624
            ],
            [
              359041.94955177518,
              5004362.7309120409
            ],
            [
              359041.44961635181,
              5004363.0433595683
            ],
            [
              359040.94780288637,
              5004363.2547787968
            ],
            [
              359040.34113346663,
              5004363.266056343
            ],
            [
              359039.62773004902,
              5004362.9761638995
            ],
            [
              359038.49671394954,
              5004361.9866987132
            ],
            [
              359038.08480032621,
              5004361.5901385769
            ],
            [
              359037.68033195887,
              5004361.597657349
            ],
            [
              359037.48564240988,
              5004362.0054937946
            ],
            [
              359036.73116231139,
              5004364.9499967862
            ],
            [
              359036.33990451734,
              5004365.6646058476
            ],
            [
              359035.74450202979,
              5004366.2819821481
            ],
            [
              359034.64540868619,
              5004367.0097849183
            ],
            [
              359024.43161895638,
              5004372.555362694
            ],
            [
              359021.3872650527,
              5004374.3085232442
            ],
            [
              359018.78467550856,
              5004375.7716109231
            ],
            [
              359017.08266810392,
              5004376.7127124118
            ],
            [
              359014.87509176455,
              5004377.6632120907
            ],
            [
              359012.3642138057,
              5004378.61934995
            ],
            [
              359010.35890476668,
              5004379.5660896413
            ],
            [
              359008.15515008016,
              5004380.718609212
            ],
            [
              359006.55236578692,
              5004381.5568385888
            ],
            [
              359004.95528056571,
              5004382.6980806068
            ],
            [
              359003.25890659861,
              5004383.9422315704
            ],
            [
              359001.86777854705,
              5004385.2817714503
            ],
            [
              359000.67697286396,
              5004386.5164887095
            ],
            [
              358998.70734277467,
              5004389.3825532412
            ],
            [
              358997.04289171094,
              5004392.3439724017
            ],
            [
              358993.81703514338,
              5004398.3659940325
            ],
            [
              358992.63937453605,
              5004400.3078385089
            ],
            [
              358991.35873599892,
              5004402.1505696112
            ],
            [
              358990.07058590726,
              5004403.5892229993
            ],
            [
              358988.87227001024,
              5004404.4199337009
            ],
            [
              358987.46980805643,
              5004405.1533405818
            ],
            [
              358985.25854240748,
              5004405.9018181916
            ],
            [
              358984.05271493638,
              5004406.3284511752
            ],
            [
              358983.25323328708,
              5004406.8485581186
            ],
            [
              358982.25899585866,
              5004407.7765028346
            ],
            [
              358981.37531418935,
              5004409.2076374488
            ],
            [
              358980.8062018429,
              5004411.2392682554
            ],
            [
              358980.63022400223,
              5004412.6572470842
            ],
            [
              358980.14725532837,
              5004413.8788417801
            ],
            [
              358979.26726354373,
              5004415.5120342942
            ],
            [
              358977.97535800649,
              5004416.7486666748
            ],
            [
              358976.67781895114,
              5004417.6822496373
            ],
            [
              358974.53674904857,
              5004418.9090373488
            ],
            [
              358971.81984744366,
              5004419.6668790132
            ],
            [
              358970.61589800235,
              5004420.1945403852
            ],
            [
              358969.11240171181,
              5004420.9298255425
            ],
            [
              358968.10877529968,
              5004421.3526997929
            ],
            [
              358967.10145901836,
              5004421.573516164
            ],
            [
              358965.89187604183,
              5004421.7981281122
            ],
            [
              358963.97264423914,
              5004421.9348332556
            ],
            [
              358962.0045894655,
              5004419.4451573063
            ],
            [
              358959.81766891305,
              5004421.5068268534
            ],
            [
              358962.41117190087,
              5004424.9954017932
            ],
            [
              358961.92625893123,
              5004426.1159694791
            ],
            [
              358960.84225424711,
              5004427.651891361
            ],
            [
              358960.05209597648,
              5004428.6771057136
            ],
            [
              358959.36685997521,
              5004429.9024605472
            ],
            [
              358957.59379758633,
              5004432.4617174147
            ],
            [
              358955.42196587002,
              5004435.331505849
            ],
            [
              358953.5402913483,
              5004437.4885644047
            ],
            [
              358949.96412087308,
              5004440.9908027546
            ],
            [
              358945.98354751657,
              5004444.5005232813
            ],
            [
              358940.70912493014,
              5004449.1458849618
            ],
            [
              358935.18830669246,
              5004453.8775877943
            ],
            [
              358931.79562483798,
              5004456.3658897113
            ],
            [
              358928.30177615531,
              5004458.8560723225
            ],
            [
              358924.4989922152,
              5004461.048843761
            ],
            [
              358922.19782658521,
              5004462.4053014647
            ],
            [
              358920.49776326743,
              5004463.4474307494
            ],
            [
              358918.69841054193,
              5004464.5924334954
            ],
            [
              358917.10125893913,
              5004465.7336774236
            ],
            [
              358915.99653186748,
              5004466.158431286
            ],
            [
              358914.28895689483,
              5004466.7964827912
            ],
            [
              358912.8808612093,
              5004467.226840605
            ],
            [
              358912.07386851136,
              5004467.3429055093
            ],
            [
              358911.16953073419,
              5004467.662870978
            ],
            [
              358909.97121405805,
              5004468.4935465558
            ],
            [
              358908.87399860559,
              5004469.3223782619
            ],
            [
              358907.59336012916,
              5004471.1651100731
            ],
            [
              358906.31084358197,
              5004472.9068135554
            ],
            [
              358904.23635695688,
              5004475.5727021014
            ],
            [
              358899.96931684756,
              5004479.997247003
            ],
            [
              358898.13007366488,
              5004481.8057502825
            ],
            [
              358896.34386611608,
              5004483.6578805372
            ],
            [
              358894.94898186455,
              5004484.7953645531
            ],
            [
              358892.85377277219,
              5004486.3500848394
            ],
            [
              358891.55623358727,
              5004487.2836682983
            ],
            [
              358890.35979497019,
              5004488.2153723389
            ],
            [
              358888.76083214598,
              5004489.255622426
            ],
            [
              358887.05694633041,
              5004490.0956964875
            ],
            [
              358885.75565163087,
              5004490.8272588179
            ],
            [
              358884.35701185797,
              5004491.762721736
            ],
            [
              358883.1605732282,
              5004492.6944258148
            ],
            [
              358882.16633574531,
              5004493.6223710468
            ],
            [
              358881.57656614826,
              5004494.5427618204
            ],
            [
              358880.89320831018,
              5004495.8691454148
            ],
            [
              358873.85269298236,
              5004492.5643018335
            ],
            [
              358870.5144686086,
              5004497.9820714146
            ],
            [
              358874.37922239018,
              5004499.1228438932
            ],
            [
              358875.35837028339,
              5004497.3867441239
            ],
            [
              358878.32066425914,
              5004498.948510523
            ],
            [
              358875.81541923532,
              5004500.2076991629
            ],
            [
              358873.81574333279,
              5004501.457489457
            ],
            [
              358871.61567810219,
              5004502.8120682025
            ],
            [
              358870.11781515134,
              5004503.8504034542
            ],
            [
              358868.1218953997,
              5004505.3022504933
            ],
            [
              358861.6547893071,
              5004511.0813395306
            ],
            [
              358849.53897026606,
              5004523.1295853462
            ],
            [
              358833.57983068662,
              5004537.7605035873
            ],
            [
              358830.6982850128,
              5004540.5424594274
            ],
            [
              358829.60106948373,
              5004541.3712915536
            ],
            [
              358828.50204144599,
              5004542.0990585517
            ],
            [
              358827.70061595523,
              5004542.5181744527
            ],
            [
              358826.69517743768,
              5004542.8400196349
            ],
            [
              358825.08300287352,
              5004543.1731439233
            ],
            [
              358824.27413189894,
              5004543.1881806701
            ],
            [
              358823.46901645692,
              5004543.4052386172
            ],
            [
              358822.57400209195,
              5004544.2303118883
            ],
            [
              358821.28585196793,
              5004545.6689665131
            ],
            [
              358812.26129070908,
              5004555.3355423212
            ],
            [
              358811.27644303575,
              5004556.7685942352
            ],
            [
              358810.89638642158,
              5004558.0893043522
            ],
            [
              358810.42844152398,
              5004560.1190557349
            ],
            [
              358809.956674179,
              5004561.9467516141
            ],
            [
              358809.48490684427,
              5004563.7744475035
            ],
            [
              358809.10485025501,
              5004565.0951576363
            ],
            [
              358808.04338032176,
              5004567.8432789277
            ],
            [
              358806.24556492426,
              5004571.5879585724
            ],
            [
              358804.77768204123,
              5004574.2425717972
            ],
            [
              358803.20313116716,
              5004576.5960136848
            ],
            [
              358801.72585909354,
              5004578.745556172
            ],
            [
              358799.54276095255,
              5004581.0092832036
            ],
            [
              358796.66309279477,
              5004583.8922325196
            ],
            [
              358794.68219585397,
              5004586.152200711
            ],
            [
              358791.80816203088,
              5004589.3382352311
            ],
            [
              358789.33859620785,
              5004592.5167152192
            ],
            [
              358786.27557225717,
              5004596.4135995451
            ],
            [
              358784.20665396116,
              5004599.3825761275
            ],
            [
              358782.93728308711,
              5004601.8314082762
            ],
            [
              358776.29935463448,
              5004598.4179829657
            ],
            [
              358774.63302685221,
              5004601.2784122052
            ],
            [
              358781.89077005914,
              5004605.3876515273
            ],
            [
              358782.10531796335,
              5004606.6790719451
            ],
            [
              358782.12597459828,
              5004607.7902419455
            ],
            [
              358781.84701941215,
              5004609.1091083121
            ],
            [
              358781.27227357012,
              5004610.837655494
            ],
            [
              358780.20517022425,
              5004613.2827275535
            ],
            [
              358779.02751076681,
              5004615.2246095045
            ],
            [
              358777.06156999181,
              5004618.292664106
            ],
            [
              358772.80586381163,
              5004623.3233103883
            ],
            [
              358772.01570569567,
              5004624.3485257486
            ],
            [
              358771.63008184638,
              5004625.3661852581
            ],
            [
              358771.04970302427,
              5004626.7917184522
            ],
            [
              358769.48447539698,
              5004629.6502328701
            ],
            [
              358767.7170472354,
              5004632.5125417868
            ],
            [
              358765.54897224967,
              5004635.5843898281
            ],
            [
              358763.37895289954,
              5004638.5552107589
            ],
            [
              358760.98636356852,
              5004641.8066625763
            ],
            [
              358759.51472544385,
              5004644.2592552183
            ],
            [
              358758.14425419125,
              5004646.7099671811
            ],
            [
              358756.97598416795,
              5004649.156920216
            ],
            [
              358746.75249497528,
              5004648.7406672845
            ],
            [
              358746.80319851235,
              5004651.4681139085
            ],
            [
              358752.97663206531,
              5004651.6565034147
            ],
            [
              358753.58893570845,
              5004651.9482749682
            ],
            [
              358754.21626240626,
              5004653.0481669931
            ],
            [
              358754.55336661561,
              5004654.8608262837
            ],
            [
              358754.38872338313,
              5004656.8849041676
            ],
            [
              358753.62861146597,
              5004659.5263606049
            ],
            [
              358752.38553212344,
              5004663.3894484397
            ],
            [
              358749.18221234897,
              5004670.6236398425
            ],
            [
              358745.18316718302,
              5004678.5799960895
            ],
            [
              358740.26574737433,
              5004687.1279895594
            ],
            [
              358737.13160349493,
              5004692.6429970097
            ],
            [
              358733.11190176278,
              5004699.488183639
            ],
            [
              358730.94751653465,
              5004702.7620548857
            ],
            [
              358728.78319757781,
              5004706.035924945
            ],
            [
              358719.95991820114,
              5004699.3284646925
            ],
            [
              358716.80699618644,
              5004703.8333663074
            ],
            [
              358718.86113186006,
              5004705.5130423531
            ],
            [
              358720.73905034486,
              5004703.153959604
            ],
            [
              358726.17490787659,
              5004707.1960035097
            ],
            [
              358726.38656477368,
              5004707.6973141925
            ],
            [
              358726.3959541826,
              5004708.2023850819
            ],
            [
              358726.30987741443,
              5004709.0124206385
            ],
            [
              358725.32315133116,
              5004710.344409422
            ],
            [
              358721.67968336481,
              5004715.6668302957
            ],
            [
              358704.84950254794,
              5004737.1822443297
            ],
            [
              358703.95267577999,
              5004737.9062532233
            ],
            [
              358702.95468279457,
              5004738.6321783876
            ],
            [
              358701.8594110845,
              5004739.5620030854
            ],
            [
              358700.85766178544,
              5004740.085871459
            ],
            [
              358699.24736498139,
              5004740.5200251946
            ],
            [
              358687.31253308145,
              5004743.2183661815
            ],
            [
              358689.54471773055,
              5004745.9953195145
            ],
            [
              358696.13631629694,
              5004752.6823951993
            ],
            [
              358699.72552204138,
              5004751.1672677463
            ],
            [
              358712.7796480938,
              5004767.5161897969
            ],
            [
              358747.69760457013,
              5004797.4232748169
            ],
            [
              358737.44988077361,
              5004806.0394029943
            ],
            [
              358750.44731944351,
              5004815.2420940828
            ],
            [
              358756.2286995133,
              5004818.4409519192
            ],
            [
              358762.95278236677,
              5004822.503530967
            ],
            [
              358767.39397424337,
              5004826.244411706
            ],
            [
              358774.73029908899,
              5004833.8459506817
            ],
            [
              358778.9826424151,
              5004837.2262034584
            ],
            [
              358787.31726733787,
              5004842.8180229394
            ],
            [
              358801.21388019988,
              5004850.5707037523
            ],
            [
              358804.09040331014,
              5004853.4303313429
            ],
            [
              358811.37797661783,
              5004863.3086342467
            ],
            [
              358812.02806990367,
              5004864.2289403025
            ],
            [
              358819.57040744752,
              5004857.6840343736
            ],
            [
              358827.75205971271,
              5004847.865261198
            ],
            [
              358832.79822527571,
              5004838.10477925
            ],
            [
              358836.89830938389,
              5004826.6636852389
            ],
            [
              358842.32382648851,
              5004809.1889414433
            ],
            [
              358852.48296922026,
              5004771.0238747988
            ],
            [
              358858.88324155897,
              5004749.7427179264
            ],
            [
              358861.56170971139,
              5004743.3144569499
            ],
            [
              358866.99395003589,
              5004731.0168269444
            ],
            [
              358877.89938679995,
              5004711.1144133685
            ],
            [
              358888.77354687557,
              5004696.8609717842
            ],
            [
              358899.44348756579,
              5004683.8271813551
            ],
            [
              358899.42318027228,
              5004683.8151145494
            ],
            [
              358897.7272475615,
              5004681.2133573433
            ],
            [
              358896.81931444752,
              5004677.1217243401
            ],
            [
              358897.6785332319,
              5004674.1048414949
            ],
            [
              358898.72010098671,
              5004669.2345329253
            ],
            [
              358900.12034731352,
              5004661.814268373
            ],
            [
              358901.70277271309,
              5004653.4256735751
            ],
            [
              358904.04196430888,
              5004641.1026346143
            ],
            [
              358904.31882293947,
              5004633.7314613033
            ],
            [
              358904.30561850563,
              5004631.4153701868
            ],
            [
              358905.40121037286,
              5004625.2278378196
            ],
            [
              358906.07430434914,
              5004621.1231176704
            ],
            [
              358906.15955609485,
              5004619.5077105053
            ],
            [
              358907.68612707971,
              5004611.6983439792
            ],
            [
              358909.97681840294,
              5004598.2575517381
            ],
            [
              358911.89502006845,
              5004586.4472134598
            ],
            [
              358913.05142547895,
              5004580.4314862285
            ],
            [
              358914.72006693535,
              5004573.7672170531
            ],
            [
              358917.11311042629,
              5004568.7836997854
            ],
            [
              358922.59040807531,
              5004562.6441761889
            ],
            [
              358926.82044378849,
              5004558.6947652567
            ],
            [
              358937.11104500032,
              5004550.8143317997
            ],
            [
              358957.34989337437,
              5004536.341333596
            ],
            [
              358971.8345218451,
              5004526.54254275
            ],
            [
              358983.19107996108,
              5004518.0767439986
            ],
            [
              358985.9388542971,
              5004515.7476334218
            ],
            [
              358984.66861904477,
              5004513.7070128955
            ],
            [
              358981.80136961816,
              5004510.7477450455
            ],
            [
              358979.6499877001,
              5004508.4780641375
            ],
            [
              358977.12726920814,
              5004504.548475909
            ],
            [
              358972.75084458687,
              5004498.9695975604
            ],
            [
              358967.92501706671,
              5004492.196139236
            ],
            [
              358967.21176036348,
              5004491.0218431465
            ],
            [
              358962.9406870461,
              5004494.1309400937
            ],
            [
              358953.02296661644,
              5004483.8163084649
            ],
            [
              358952.88476022572,
              5004476.3817063039
            ],
            [
              358954.19101787865,
              5004470.0279443171
            ],
            [
              358956.01012960513,
              5004465.7217531577
            ],
            [
              358957.18758347636,
              5004460.9527269062
            ],
            [
              358957.50338244741,
              5004453.4021476982
            ],
            [
              358962.775151596,
              5004448.671361398
            ],
            [
              358967.8821699507,
              5004443.7944178684
            ],
            [
              358973.78891226457,
              5004438.4319559317
            ],
            [
              358978.20356082678,
              5004434.5231580082
            ],
            [
              358985.48272054893,
              5004428.5157686034
            ],
            [
              358992.90846903523,
              5004422.7632112149
            ],
            [
              358999.68149636424,
              5004417.2865112945
            ],
            [
              359003.4580868631,
              5004413.769476993
            ],
            [
              359008.45906059822,
              5004409.4538905062
            ],
            [
              359012.40614968963,
              5004405.4401570214
            ],
            [
              359019.57054214034,
              5004398.7971390923
            ],
            [
              359027.69662402576,
              5004393.7408539532
            ],
            [
              359030.40530896198,
              5004392.1599305347
            ],
            [
              359037.12159726716,
              5004388.1694060201
            ],
            [
              359042.28009695263,
              5004383.7759775054
            ],
            [
              359044.97227303794,
              5004381.3308369108
            ],
            [
              359050.89053176896,
              5004375.3208834575
            ],
            [
              359054.62354233389,
              5004371.4471413838
            ],
            [
              359057.03306527069,
              5004368.6433032183
            ],
            [
              359072.45858411357,
              5004350.0064569702
            ],
            [
              359076.7336620671,
              5004343.7292571813
            ],
            [
              359081.29530641565,
              5004338.8588005267
            ],
            [
              359087.35160264367,
              5004334.8102759393
            ],
            [
              359089.61510168569,
              5004333.4141043434
            ],
            [
              359110.22516616195,
              5004324.0568846976
            ],
            [
              359114.21671747835,
              5004322.5209006313
            ],
            [
              359126.62754228833,
              5004320.5814732974
            ],
            [
              359130.30363738217,
              5004319.8666576548
            ],
            [
              359140.14166830963,
              5004318.2974781729
            ],
            [
              359147.82119423599,
              5004316.1536526317
            ],
            [
              359154.78966897092,
              5004314.1519755935
            ],
            [
              359161.89296925109,
              5004310.8584484383
            ],
            [
              359166.12886930915,
              5004309.7108810088
            ],
            [
              359170.28632170946,
              5004308.7695305431
            ],
            [
              359180.17751948338,
              5004307.6360632665
            ],
            [
              359188.89942844724,
              5004305.1135119302
            ],
            [
              359203.60113915615,
              5004300.939185583
            ],
            [
              359212.4415436511,
              5004298.5640144572
            ],
            [
              359215.88424786396,
              5004297.6390554812
            ],
            [
              359222.79634333804,
              5004297.5247647092
            ],
            [
              359235.94362880179,
              5004295.712684351
            ],
            [
              359247.41683404596,
              5004295.3594382973
            ],
            [
              359256.38064939261,
              5004294.9550471762
            ],
            [
              359262.43913884863,
              5004294.8401034297
            ],
            [
              359269.57501365658,
              5004294.5899434779
            ],
            [
              359274.75086336781,
              5004294.6018375168
            ],
            [
              359292.74149155041,
              5004294.0156760486
            ],
            [
              359298.65132681566,
              5004293.6541233826
            ],
            [
              359306.4519866276,
              5004293.054148064
            ],
            [
              359313.0037022669,
              5004297.4052611636
            ],
            [
              359318.54100940406,
              5004303.0531544602
            ],
            [
              359323.97335947392,
              5004304.0176182659
            ],
            [
              359327.53568676487,
              5004307.8894571997
            ],
            [
              359331.79791107535,
              5004305.9449320389
            ],
            [
              359335.84760457551,
              5004302.9448416121
            ],
            [
              359392.61535295023,
              5004263.3535891343
            ],
            [
              359411.63050518133,
              5004250.2630583066
            ],
            [
              359429.70477613207,
              5004238.3009669557
            ],
            [
              359442.49784863013,
              5004230.4865907682
            ],
            [
              359464.74062098277,
              5004217.7938593449
            ],
            [
              359473.53952812304,
              5004213.058230673
            ],
            [
              359483.43375369482,
              5004207.0727733569
            ],
            [
              359497.78728326247,
              5004198.83750425
            ],
            [
              359510.97228637512,
              5004191.0158455521
            ],
            [
              359519.89212689543,
              5004185.7554488266
            ],
            [
              359529.72648157243,
              5004180.4780527335
            ],
            [
              359543.94693823572,
              5004172.1146263666
            ],
            [
              359564.44040103472,
              5004160.5680580819
            ],
            [
              359574.14896570501,
              5004155.5542641236
            ],
            [
              359582.80993873719,
              5004150.4293143768
            ],
            [
              359587.34124897671,
              5004148.1243653372
            ],
            [
              359613.08757345093,
              5004133.8015676932
            ],
            [
              359623.73190120171,
              5004128.5850511314
            ],
            [
              359625.29523121816,
              5004126.068788372
            ],
            [
              359634.79754601099,
              5004117.552708121
            ],
            [
              359634.79754677188,
              5004117.5527074095
            ],
            [
              359641.17711067252,
              5004107.4813578362
            ],
            [
              359644.14575400495,
              5004102.3324916363
            ],
            [
              359645.47862292785,
              5004097.5389656499
            ],
            [
              359648.94973188551,
              5004081.671047546
            ],
            [
              359657.35640218906,
              5004057.613247172
            ],
            [
              359660.89088185638,
              5004046.4883110058
            ],
            [
              359664.80760286911,
              5004025.6788389925
            ],
            [
              359667.7055188879,
              5004011.6971394615
            ],
            [
              359672.25993619871,
              5003997.5206213137
            ],
            [
              359678.0661373568,
              5003976.9706614381
            ],
            [
              359680.90094225225,
              5003967.1151470738
            ],
            [
              359682.47523767699,
              5003959.457750096
            ],
            [
              359687.67887117836,
              5003962.3487301478
            ],
            [
              359711.90112009266,
              5003979.3796468033
            ],
            [
              359719.73760001996,
              5003985.8042531544
            ],
            [
              359730.45811732399,
              5003995.0562177403
            ],
            [
              359742.51314946654,
              5004005.0963181742
            ],
            [
              359748.56022942142,
              5004011.7057649409
            ],
            [
              359748.73419682699,
              5004011.84111764
            ],
            [
              359755.19111819629,
              5004005.7509851102
            ],
            [
              359764.8343133445,
              5003996.9396812823
            ],
            [
              359768.15524237562,
              5003983.3382076053
            ],
            [
              359777.26010961033,
              5003966.1855185013
            ],
            [
              359781.11080675508,
              5003958.2644458683
            ],
            [
              359789.30622570147,
              5003938.2742979033
            ],
            [
              359792.03626646317,
              5003931.5158026312
            ],
            [
              359795.48305877944,
              5003922.9228176195
            ],
            [
              359807.33605753857,
              5003892.3035481293
            ],
            [
              359810.31178843626,
              5003883.3997185109
            ],
            [
              359817.2862562667,
              5003866.857503064
            ],
            [
              359825.66950193321,
              5003849.2900726479
            ],
            [
              359835.04611611052,
              5003830.6487659635
            ],
            [
              359838.08814543992,
              5003825.311655838
            ],
            [
              359835.34166397445,
              5003823.6035519419
            ],
            [
              359829.9555881074,
              5003821.38325842
            ],
            [
              359822.48988813884,
              5003815.7540452983
            ],
            [
              359805.80277748237,
              5003803.1587980483
            ],
            [
              359800.87557291513,
              5003799.2542792903
            ],
            [
              359799.1415488855,
              5003798.144764469
            ],
            [
              359767.91666091816,
              5003778.2338920441
            ],
            [
              359747.26092866744,
              5003764.7140463293
            ],
            [
              359746.79294857039,
              5003762.5819762498
            ],
            [
              359755.52475788177,
              5003743.2685499424
            ],
            [
              359769.78943341743,
              5003731.0801023189
            ],
            [
              359772.99337580608,
              5003722.806742019
            ],
            [
              359771.3574955028,
              5003720.3641513456
            ],
            [
              359770.09625583905,
              5003719.0627721138
            ],
            [
              359768.3530714126,
              5003717.1310114386
            ],
            [
              359759.22177974664,
              5003704.781902886
            ],
            [
              359754.48601884284,
              5003702.4012783449
            ],
            [
              359751.60794200405,
              5003701.2601767881
            ],
            [
              359748.72634108824,
              5003701.0261095632
            ],
            [
              359740.54042065347,
              5003699.2129420126
            ],
            [
              359732.3942294282,
              5003697.8291844632
            ],
            [
              359724.7441482314,
              5003697.3796392903
            ],
            [
              359719.04373846709,
              5003697.5931698913
            ],
            [
              359714.4630156224,
              5003698.1166080497
            ],
            [
              359710.17913670209,
              5003699.2288950505
            ],
            [
              359706.58534988266,
              5003700.4144228837
            ],
            [
              359700.25363821158,
              5003702.5114432033
            ],
            [
              359696.37587466504,
              5003704.8463686118
            ],
            [
              359690.64533527876,
              5003708.454217664
            ],
            [
              359686.93819164304,
              5003710.4549096664
            ],
            [
              359681.93882558018,
              5003714.4114067396
            ],
            [
              359676.84336822393,
              5003719.6977834012
            ],
            [
              359674.16831255151,
              5003722.7824136298
            ],
            [
              359672.04989180987,
              5003725.7798417415
            ],
            [
              359670.1092585107,
              5003728.5928460611
            ],
            [
              359668.188816079,
              5003732.4921143102
            ],
            [
              359665.36160069314,
              5003738.9326006221
            ],
            [
              359661.38416550396,
              5003750.4884163551
            ],
            [
              359657.30085727753,
              5003762.2522909641
            ],
            [
              359653.77092164027,
              5003774.1161960103
            ],
            [
              359652.61979329103,
              5003783.3997590179
            ],
            [
              359651.96466915053,
              5003789.6969790114
            ],
            [
              359652.64757545962,
              5003795.9526552176
            ],
            [
              359653.38122355641,
              5003800.4248217614
            ],
            [
              359654.86396433419,
              5003802.9599192897
            ],
            [
              359656.7513948619,
              5003805.1928998455
            ],
            [
              359662.89673588501,
              5003810.3065391304
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 344,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359150.35913999105,
              5005141.6985872081
            ],
            [
              359153.57907827949,
              5005153.0655774483
            ],
            [
              359142.33700838924,
              5005166.8023507455
            ],
            [
              359137.16236762964,
              5005171.2965206588
            ],
            [
              359127.4658717339,
              5005166.9563119281
            ],
            [
              359121.21447224147,
              5005175.9820432561
            ],
            [
              359100.12821040215,
              5005179.5212271102
            ],
            [
              359091.3980718411,
              5005179.8460248318
            ],
            [
              359077.34565088834,
              5005184.3113276586
            ],
            [
              359064.81034093525,
              5005192.0583756575
            ],
            [
              359062.41442772542,
              5005189.1171504427
            ],
            [
              359059.58223055722,
              5005188.766300776
            ],
            [
              359052.27184131084,
              5005193.70458923
            ],
            [
              359042.37533942133,
              5005207.5351173654
            ],
            [
              359030.40795895248,
              5005220.1247804929
            ],
            [
              359032.03261251462,
              5005221.847787261
            ],
            [
              359030.66788106289,
              5005228.3947982565
            ],
            [
              359018.17865882727,
              5005243.7542182775
            ],
            [
              359015.45368729095,
              5005245.1800508425
            ],
            [
              359009.03893972264,
              5005252.5843254896
            ],
            [
              359024.36558776844,
              5005261.6783739356
            ],
            [
              359031.00082705921,
              5005263.6163270539
            ],
            [
              359040.69620442536,
              5005269.3108241772
            ],
            [
              359049.33972457302,
              5005273.8911749115
            ],
            [
              359056.26409782196,
              5005273.2576076258
            ],
            [
              359066.7300910406,
              5005274.8018764919
            ],
            [
              359079.68035690655,
              5005276.2999927737
            ],
            [
              359095.38374590495,
              5005282.3054340137
            ],
            [
              359100.84141453658,
              5005280.5693022599
            ],
            [
              359116.07286417036,
              5005276.7832607804
            ],
            [
              359125.97209045809,
              5005275.1980853677
            ],
            [
              359138.44492046488,
              5005298.4441446131
            ],
            [
              359113.56684503856,
              5005312.7218266614
            ],
            [
              359111.40948604356,
              5005308.5649074521
            ],
            [
              359100.56724195625,
              5005323.1459021065
            ],
            [
              359066.18065966893,
              5005379.0272009354
            ],
            [
              359065.06620448938,
              5005385.7760840869
            ],
            [
              359055.96727131057,
              5005404.3012373848
            ],
            [
              359056.70984327019,
              5005404.9879613128
            ],
            [
              359080.44419598748,
              5005422.1840039715
            ],
            [
              359097.73822856118,
              5005421.5450799596
            ],
            [
              359110.63547504292,
              5005423.5271751257
            ],
            [
              359136.41521858581,
              5005426.6981362617
            ],
            [
              359161.27444418258,
              5005435.0702021793
            ],
            [
              359209.02226789878,
              5005452.2968623806
            ],
            [
              359225.47877446748,
              5005458.2984582633
            ],
            [
              359234.84304002131,
              5005461.4680887954
            ],
            [
              359222.09977002017,
              5005468.0764239933
            ],
            [
              359214.1432835735,
              5005475.7418599352
            ],
            [
              359205.94335087965,
              5005485.8694765298
            ],
            [
              359192.6999387726,
              5005513.0640549166
            ],
            [
              359188.73883408372,
              5005533.3771781325
            ],
            [
              359184.22852897999,
              5005547.48407098
            ],
            [
              359187.61569202453,
              5005565.8210246246
            ],
            [
              359178.68430241884,
              5005567.7218822064
            ],
            [
              359174.11701178714,
              5005570.9873109972
            ],
            [
              359199.85085518699,
              5005593.5471572587
            ],
            [
              359218.85837902775,
              5005612.4212152688
            ],
            [
              359221.97234162031,
              5005616.5558045926
            ],
            [
              359232.25115949701,
              5005624.8941732729
            ],
            [
              359246.6236566336,
              5005635.6140815513
            ],
            [
              359262.33903431502,
              5005622.2929081907
            ],
            [
              359298.67123094545,
              5005615.0203192607
            ],
            [
              359298.71354697383,
              5005605.1205780897
            ],
            [
              359306.90567889396,
              5005604.6497576274
            ],
            [
              359307.30297312717,
              5005602.644097155
            ],
            [
              359319.35913055454,
              5005601.2904962543
            ],
            [
              359319.16530412581,
              5005614.2394663161
            ],
            [
              359315.10812136979,
              5005625.0882766498
            ],
            [
              359318.79253268044,
              5005626.1764324456
            ],
            [
              359329.58257710392,
              5005626.8446450233
            ],
            [
              359354.94076039322,
              5005630.369743336
            ],
            [
              359374.41584547883,
              5005626.0110625103
            ],
            [
              359377.06802119111,
              5005621.4923032857
            ],
            [
              359397.21910072438,
              5005623.5215519648
            ],
            [
              359397.1744203167,
              5005621.1184664136
            ],
            [
              359419.9224878833,
              5005633.4799111849
            ],
            [
              359416.51747105288,
              5005644.360790859
            ],
            [
              359426.98773324682,
              5005647.0599846421
            ],
            [
              359434.41472539894,
              5005652.6038334379
            ],
            [
              359466.15448954044,
              5005648.8449274339
            ],
            [
              359468.57952075062,
              5005648.2538802251
            ],
            [
              359475.40447017195,
              5005668.4508908838
            ],
            [
              359482.0294358746,
              5005689.1288524074
            ],
            [
              359490.2180547273,
              5005706.2409934085
            ],
            [
              359500.09818066453,
              5005720.262169986
            ],
            [
              359504.35785422649,
              5005720.0737143541
            ],
            [
              359525.53061237565,
              5005688.8663374782
            ],
            [
              359528.41310823552,
              5005683.7541541345
            ],
            [
              359533.53394918767,
              5005671.09309211
            ],
            [
              359535.54071887251,
              5005661.4401537059
            ],
            [
              359534.24730190617,
              5005656.5471519381
            ],
            [
              359508.49608953594,
              5005641.4005465871
            ],
            [
              359507.15498097095,
              5005640.4996652883
            ],
            [
              359511.25014980976,
              5005631.4635291956
            ],
            [
              359513.82560843282,
              5005623.0019920347
            ],
            [
              359521.4516590953,
              5005609.8572630696
            ],
            [
              359523.02196529804,
              5005606.477091671
            ],
            [
              359528.73384376225,
              5005594.6207299614
            ],
            [
              359521.08279271529,
              5005590.8096597185
            ],
            [
              359523.54335361638,
              5005587.2498402987
            ],
            [
              359527.14083185623,
              5005581.5350812841
            ],
            [
              359530.99328533176,
              5005576.7000302337
            ],
            [
              359536.34435937292,
              5005572.2834977815
            ],
            [
              359542.75783612486,
              5005565.0620766049
            ],
            [
              359547.35312806064,
              5005557.4566938644
            ],
            [
              359557.18610645545,
              5005546.8294905499
            ],
            [
              359565.41776810144,
              5005539.9920321899
            ],
            [
              359579.63419144735,
              5005555.3246653574
            ],
            [
              359582.37629350426,
              5005551.482160775
            ],
            [
              359591.04647386755,
              5005545.7506177053
            ],
            [
              359594.45821034914,
              5005541.9272130243
            ],
            [
              359600.25303176726,
              5005538.8950340943
            ],
            [
              359595.28816287854,
              5005534.1133049587
            ],
            [
              359603.49135283817,
              5005525.7445420912
            ],
            [
              359610.75234738534,
              5005532.2589754872
            ],
            [
              359615.96678983612,
              5005527.9842523299
            ],
            [
              359617.99368423439,
              5005524.60436277
            ],
            [
              359625.40788901719,
              5005518.7569405539
            ],
            [
              359630.18381280464,
              5005513.3763379017
            ],
            [
              359635.68973875843,
              5005509.7924970752
            ],
            [
              359645.92808333592,
              5005505.9814012041
            ],
            [
              359656.74579387147,
              5005520.6973083774
            ],
            [
              359665.82216272288,
              5005514.4439466884
            ],
            [
              359667.64107333025,
              5005512.0507613346
            ],
            [
              359677.56138750719,
              5005504.4157302119
            ],
            [
              359687.25080958434,
              5005517.7708078185
            ],
            [
              359695.45006353396,
              5005531.1535908775
            ],
            [
              359697.40514435439,
              5005536.0842865175
            ],
            [
              359708.3457570555,
              5005532.2201282205
            ],
            [
              359718.70805283129,
              5005527.60204574
            ],
            [
              359714.67199996742,
              5005520.0653618043
            ],
            [
              359725.75887008238,
              5005516.3188849865
            ],
            [
              359731.07951630821,
              5005516.7509993995
            ],
            [
              359741.72409894806,
              5005517.7922033509
            ],
            [
              359749.51320605265,
              5005517.6473834058
            ],
            [
              359753.70920632972,
              5005514.7371017868
            ],
            [
              359756.84302045195,
              5005511.8465690501
            ],
            [
              359763.59247799293,
              5005520.4324405789
            ],
            [
              359803.34818585409,
              5005572.7984062769
            ],
            [
              359806.92226427776,
              5005584.0610552616
            ],
            [
              359811.91664408485,
              5005584.7005536892
            ],
            [
              359832.08773871878,
              5005583.7944771722
            ],
            [
              359849.91473546909,
              5005580.6307569314
            ],
            [
              359859.26156228391,
              5005588.0686948793
            ],
            [
              359878.93053687696,
              5005579.2061602725
            ],
            [
              359890.44971373217,
              5005570.1411513761
            ],
            [
              359903.61941687792,
              5005559.2694325205
            ],
            [
              359923.85235791601,
              5005552.1666028211
            ],
            [
              359928.75313690194,
              5005553.8433449985
            ],
            [
              359929.88039638026,
              5005545.6952370442
            ],
            [
              359932.32178848796,
              5005538.6182519924
            ],
            [
              359937.71073529549,
              5005542.4625933152
            ],
            [
              359949.93496167916,
              5005526.4571673386
            ],
            [
              359956.95507626754,
              5005516.5510487715
            ],
            [
              359963.07825791702,
              5005508.2575813429
            ],
            [
              359975.66824340838,
              5005489.9092309605
            ],
            [
              359986.55405524233,
              5005471.9910680475
            ],
            [
              359990.36080784822,
              5005464.3330457984
            ],
            [
              359993.02668723551,
              5005453.4238725863
            ],
            [
              359997.40892623557,
              5005457.8240952054
            ],
            [
              360028.05206688377,
              5005454.0592004564
            ],
            [
              360033.96848991094,
              5005454.7016165024
            ],
            [
              360037.17211949849,
              5005450.8415986784
            ],
            [
              360051.34579447744,
              5005445.8887752509
            ],
            [
              360062.20977445837,
              5005446.8591127172
            ],
            [
              360079.71212206915,
              5005447.5106329676
            ],
            [
              360090.77573809878,
              5005447.7718536984
            ],
            [
              360103.02954048349,
              5005445.4719300661
            ],
            [
              360113.77373252023,
              5005442.5021300213
            ],
            [
              360116.85697259998,
              5005441.1324728075
            ],
            [
              360123.87135111814,
              5005439.4134418378
            ],
            [
              360134.02773888764,
              5005435.7711302508
            ],
            [
              360145.55157606321,
              5005432.2757317945
            ],
            [
              360142.25146235881,
              5005428.0574401366
            ],
            [
              360161.67319329799,
              5005414.6544733113
            ],
            [
              360180.81152784365,
              5005394.9714532532
            ],
            [
              360168.8342399188,
              5005376.9355191234
            ],
            [
              360161.66590203601,
              5005369.2436717767
            ],
            [
              360153.16761790443,
              5005365.5894395513
            ],
            [
              360138.44072016556,
              5005362.1571903257
            ],
            [
              360135.21928915189,
              5005360.7550875572
            ],
            [
              360137.19101192808,
              5005358.6722149979
            ],
            [
              360144.37179082207,
              5005356.8371636625
            ],
            [
              360102.97697367606,
              5005320.2211434059
            ],
            [
              360100.97379144368,
              5005317.1504325941
            ],
            [
              360069.30747894273,
              5005323.0518099787
            ],
            [
              360037.19678435382,
              5005294.5653538154
            ],
            [
              360045.48486576747,
              5005284.4501047116
            ],
            [
              360052.76197496202,
              5005271.4512280272
            ],
            [
              360044.15066409163,
              5005267.6888090251
            ],
            [
              360036.3280688916,
              5005261.8898038138
            ],
            [
              360030.67289938044,
              5005257.6975431126
            ],
            [
              360009.28464921162,
              5005245.32409159
            ],
            [
              359984.65456114413,
              5005219.3321632855
            ],
            [
              359970.81514669652,
              5005206.0031986274
            ],
            [
              359955.27781572007,
              5005198.0063703144
            ],
            [
              359945.25493206579,
              5005218.8611605698
            ],
            [
              359907.81381485116,
              5005188.193224147
            ],
            [
              359894.80452776124,
              5005180.6302245101
            ],
            [
              359884.3276714767,
              5005177.0670998581
            ],
            [
              359873.59397699038,
              5005175.2431604546
            ],
            [
              359868.77933760686,
              5005175.4667685973
            ],
            [
              359842.956853004,
              5005170.888126892
            ],
            [
              359830.42095684796,
              5005167.9233905608
            ],
            [
              359823.99395256693,
              5005164.429514573
            ],
            [
              359810.92608542246,
              5005161.492731519
            ],
            [
              359799.57658540335,
              5005157.6567432657
            ],
            [
              359794.94368143473,
              5005165.1141484883
            ],
            [
              359789.26141723717,
              5005170.5675664181
            ],
            [
              359786.03632037173,
              5005175.9753367174
            ],
            [
              359781.70008008339,
              5005176.0559544964
            ],
            [
              359778.91281241144,
              5005176.0666161096
            ],
            [
              359775.36352160986,
              5005179.7489728145
            ],
            [
              359772.2746174123,
              5005183.8748120852
            ],
            [
              359766.38126955956,
              5005187.9623683663
            ],
            [
              359760.36753530806,
              5005195.3069132213
            ],
            [
              359746.6508552446,
              5005187.6967223398
            ],
            [
              359740.58891878469,
              5005187.254730924
            ],
            [
              359736.66281189542,
              5005187.7311304612
            ],
            [
              359725.89120007644,
              5005186.3176960303
            ],
            [
              359725.5986432393,
              5005188.2798637338
            ],
            [
              359722.27346908598,
              5005197.9564666944
            ],
            [
              359717.69574791606,
              5005206.0267279521
            ],
            [
              359708.37975587108,
              5005214.4399278499
            ],
            [
              359703.94986642146,
              5005212.0248411689
            ],
            [
              359700.49564577878,
              5005204.4948405297
            ],
            [
              359691.38959823333,
              5005214.1487727584
            ],
            [
              359679.49638617667,
              5005233.8594722962
            ],
            [
              359670.81578634254,
              5005244.8661640408
            ],
            [
              359659.82444462663,
              5005240.0832372047
            ],
            [
              359656.03003955929,
              5005254.6538203238
            ],
            [
              359653.50276036939,
              5005268.498534767
            ],
            [
              359647.98197491985,
              5005274.6967209345
            ],
            [
              359629.71807446243,
              5005287.8907247717
            ],
            [
              359625.26786088134,
              5005292.0371594056
            ],
            [
              359617.89475334383,
              5005297.9927527057
            ],
            [
              359610.32139617676,
              5005303.1207982786
            ],
            [
              359596.35759440128,
              5005273.4202752002
            ],
            [
              359585.21885414823,
              5005251.316059649
            ],
            [
              359581.81773095502,
              5005240.1607762994
            ],
            [
              359611.20586185885,
              5005213.8914279779
            ],
            [
              359603.18697014148,
              5005206.9329371499
            ],
            [
              359587.60128198063,
              5005193.4082658486
            ],
            [
              359578.27595870587,
              5005185.3779907003
            ],
            [
              359582.11562635569,
              5005183.182377452
            ],
            [
              359587.29649067851,
              5005183.5478467066
            ],
            [
              359592.13703819626,
              5005185.4897013316
            ],
            [
              359603.61027423252,
              5005194.4031512477
            ],
            [
              359606.85597981024,
              5005185.1071116691
            ],
            [
              359610.69879058091,
              5005178.108886457
            ],
            [
              359614.21850187826,
              5005173.6103416197
            ],
            [
              359621.88483936602,
              5005178.4550884999
            ],
            [
              359626.72195325012,
              5005180.2122838283
            ],
            [
              359635.25059254526,
              5005166.3645848101
            ],
            [
              359639.67506018624,
              5005160.8332605446
            ],
            [
              359650.56576169695,
              5005168.0994007578
            ],
            [
              359657.17943519383,
              5005161.0496618841
            ],
            [
              359659.95162231312,
              5005156.1032254845
            ],
            [
              359667.85392184841,
              5005150.8867169153
            ],
            [
              359670.97914054408,
              5005141.8758912338
            ],
            [
              359683.58757544699,
              5005147.0394308334
            ],
            [
              359686.66630350886,
              5005135.5279708328
            ],
            [
              359687.0088599709,
              5005127.5757961636
            ],
            [
              359680.2710289965,
              5005125.2167464672
            ],
            [
              359680.67536562873,
              5005118.6263551638
            ],
            [
              359684.42708654836,
              5005107.892321324
            ],
            [
              359692.07339375623,
              5005108.2768024113
            ],
            [
              359686.03213987203,
              5005096.7096090503
            ],
            [
              359686.44871240103,
              5005088.8191863848
            ],
            [
              359679.85512513324,
              5005087.6280255085
            ],
            [
              359670.28285212739,
              5005087.8059872231
            ],
            [
              359663.34173518216,
              5005088.1226899987
            ],
            [
              359656.47391934856,
              5005092.3793693613
            ],
            [
              359653.97459300671,
              5005089.2352671763
            ],
            [
              359657.79406513885,
              5005082.595359046
            ],
            [
              359657.79406513955,
              5005082.595359046
            ],
            [
              359687.24287469452,
              5005051.0182501916
            ],
            [
              359663.05244575866,
              5005012.2424209574
            ],
            [
              359651.58167585841,
              5005001.1947710421
            ],
            [
              359638.64077212912,
              5004991.8635473894
            ],
            [
              359628.22539050033,
              5004986.1184826214
            ],
            [
              359615.18800352089,
              5004969.7175347228
            ],
            [
              359606.46784027404,
              5004956.2021048274
            ],
            [
              359591.26252654655,
              5004929.510658321
            ],
            [
              359579.04979536973,
              5004935.1323335124
            ],
            [
              359575.29911278485,
              5004946.1303008422
            ],
            [
              359564.12242193503,
              5004943.3905551285
            ],
            [
              359560.67010685196,
              5004953.2547474038
            ],
            [
              359552.2882134653,
              5004960.2435060237
            ],
            [
              359547.147708504,
              5004957.7264622776
            ],
            [
              359539.06125852914,
              5004948.1633329587
            ],
            [
              359538.15838849096,
              5004949.3789950442
            ],
            [
              359536.36939493287,
              5004950.2391493069
            ],
            [
              359519.06750385801,
              5004950.0346151097
            ],
            [
              359512.36087019468,
              5004949.3324027881
            ],
            [
              359504.92725105624,
              5004945.937493735
            ],
            [
              359501.91128575528,
              5004941.4832450543
            ],
            [
              359497.66609313962,
              5004946.2013930092
            ],
            [
              359490.85633011622,
              5004951.7073219903
            ],
            [
              359483.58176892757,
              5004960.597173322
            ],
            [
              359481.76693712315,
              5004963.0803983761
            ],
            [
              359477.62890801678,
              5004973.2303877054
            ],
            [
              359477.3372793828,
              5004974.2189517459
            ],
            [
              359461.70675285201,
              5004994.3873052355
            ],
            [
              359454.6689272059,
              5005010.017820349
            ],
            [
              359452.00316500425,
              5005018.2399430787
            ],
            [
              359451.14767420694,
              5005019.2762240209
            ],
            [
              359460.73636366497,
              5005024.9143238459
            ],
            [
              359469.79086578038,
              5005008.5952688614
            ],
            [
              359477.50049742678,
              5005014.7172768647
            ],
            [
              359484.14295805246,
              5005020.2655218262
            ],
            [
              359492.41071659647,
              5005027.7377868351
            ],
            [
              359500.59336047602,
              5005034.0059287362
            ],
            [
              359516.71934691689,
              5005044.5104001351
            ],
            [
              359511.07146483852,
              5005053.4743223554
            ],
            [
              359519.61625261995,
              5005056.7238707626
            ],
            [
              359524.53821644874,
              5005051.0871233251
            ],
            [
              359527.56422589772,
              5005053.7791604688
            ],
            [
              359534.1907940731,
              5005059.0475468263
            ],
            [
              359542.33383826475,
              5005065.5611923998
            ],
            [
              359540.39776269341,
              5005072.3924771249
            ],
            [
              359541.48133299389,
              5005075.8661740264
            ],
            [
              359524.72399742721,
              5005085.493925754
            ],
            [
              359519.77397228096,
              5005091.5773303825
            ],
            [
              359512.9684678879,
              5005095.0306712557
            ],
            [
              359501.08934347512,
              5005091.0001237327
            ],
            [
              359494.30896746984,
              5005115.2577840649
            ],
            [
              359500.79531802551,
              5005120.5540611437
            ],
            [
              359502.2640386814,
              5005121.612730219
            ],
            [
              359497.82906445715,
              5005130.5412355848
            ],
            [
              359491.64411140111,
              5005127.6732879588
            ],
            [
              359490.14666521834,
              5005127.1524801403
            ],
            [
              359481.10954236001,
              5005132.5933908569
            ],
            [
              359478.65051900857,
              5005133.9996459745
            ],
            [
              359477.34903484321,
              5005135.7019188656
            ],
            [
              359475.88868410204,
              5005138.5928402385
            ],
            [
              359466.82208140561,
              5005157.2486998765
            ],
            [
              359456.32064021629,
              5005162.4265711382
            ],
            [
              359461.0376985133,
              5005169.0154969255
            ],
            [
              359466.57514867838,
              5005172.0151977865
            ],
            [
              359470.8010870248,
              5005173.9347293684
            ],
            [
              359477.09440063365,
              5005179.6675540134
            ],
            [
              359465.83319790877,
              5005187.6418216322
            ],
            [
              359447.86185466073,
              5005191.9612340424
            ],
            [
              359440.3421883714,
              5005195.4769145111
            ],
            [
              359432.03788765374,
              5005202.7613438033
            ],
            [
              359421.47925848752,
              5005209.9821184697
            ],
            [
              359405.38426880894,
              5005219.6918894453
            ],
            [
              359390.81917595438,
              5005225.864212065
            ],
            [
              359385.33899317333,
              5005228.8243626952
            ],
            [
              359365.99789866526,
              5005195.9241206814
            ],
            [
              359357.23294790619,
              5005186.8145089885
            ],
            [
              359366.16342978034,
              5005179.1737928884
            ],
            [
              359375.47877462988,
              5005171.0689092074
            ],
            [
              359379.49328633008,
              5005166.9008577969
            ],
            [
              359358.59147165768,
              5005148.0747336717
            ],
            [
              359381.20980108,
              5005127.4460460786
            ],
            [
              359391.47098881524,
              5005120.2346016718
            ],
            [
              359377.13036129525,
              5005111.9575038217
            ],
            [
              359364.14143969695,
              5005106.7758354396
            ],
            [
              359363.33844254992,
              5005104.571658765
            ],
            [
              359361.71776608034,
              5005103.7125545517
            ],
            [
              359349.57989909168,
              5005098.3148986204
            ],
            [
              359335.44231427397,
              5005093.260724443
            ],
            [
              359329.56207185722,
              5005091.112246885
            ],
            [
              359314.28600804223,
              5005081.2472709222
            ],
            [
              359304.99233200477,
              5005077.8367390754
            ],
            [
              359295.2436819679,
              5005076.1491068341
            ],
            [
              359282.01038009999,
              5005071.9237583997
            ],
            [
              359283.66279093176,
              5005068.5902091768
            ],
            [
              359284.3364373091,
              5005063.7750696493
            ],
            [
              359284.57380977581,
              5005059.2281402675
            ],
            [
              359284.89734464849,
              5005057.164962789
            ],
            [
              359284.93405275419,
              5005055.2226755572
            ],
            [
              359286.18705310632,
              5005051.9062334262
            ],
            [
              359286.83313283924,
              5005050.0901029436
            ],
            [
              359289.95934906771,
              5005045.5161273358
            ],
            [
              359290.34821090457,
              5005044.0193041842
            ],
            [
              359295.13125252206,
              5005032.5459488984
            ],
            [
              359289.49182898487,
              5005030.0518605402
            ],
            [
              359281.90699476289,
              5005026.6292234659
            ],
            [
              359272.32164387655,
              5005022.3859415352
            ],
            [
              359273.82140264596,
              5005017.2706088601
            ],
            [
              359275.54641783837,
              5005012.2443950865
            ],
            [
              359264.57269838458,
              5005008.2923218198
            ],
            [
              359266.37956199487,
              5005000.8430980463
            ],
            [
              359264.94542620995,
              5004991.0672706589
            ],
            [
              359265.69464004372,
              5004982.5624982519
            ],
            [
              359265.59419295209,
              5004973.2536771372
            ],
            [
              359271.09985286376,
              5004968.9257402513
            ],
            [
              359276.99324026617,
              5004958.5154098384
            ],
            [
              359270.30326333269,
              5004955.9516936131
            ],
            [
              359277.42505971278,
              5004942.7293907162
            ],
            [
              359285.75693940721,
              5004946.0694086049
            ],
            [
              359286.61378863041,
              5004943.265206865
            ],
            [
              359288.16215529351,
              5004939.8214815315
            ],
            [
              359300.05283819622,
              5004931.5874677002
            ],
            [
              359303.43573994341,
              5004923.4956719754
            ],
            [
              359306.48947760178,
              5004916.6256809821
            ],
            [
              359307.9461005542,
              5004913.9633097183
            ],
            [
              359316.92307046085,
              5004907.4755719304
            ],
            [
              359327.36585706641,
              5004896.153068006
            ],
            [
              359321.34241893486,
              5004890.2218070775
            ],
            [
              359314.34805708489,
              5004885.58987909
            ],
            [
              359303.93456930411,
              5004880.7040371634
            ],
            [
              359296.81801974331,
              5004873.7727176389
            ],
            [
              359296.21381034324,
              5004872.6162729589
            ],
            [
              359303.01930623321,
              5004865.1277813995
            ],
            [
              359309.46897216409,
              5004857.3654184658
            ],
            [
              359303.10828913783,
              5004854.8193238955
            ],
            [
              359297.13204520365,
              5004854.0890361611
            ],
            [
              359291.57786877704,
              5004853.4210586185
            ],
            [
              359284.15062507335,
              5004851.0855033407
            ],
            [
              359272.60174428689,
              5004844.9197957534
            ],
            [
              359263.48768753553,
              5004842.2712727282
            ],
            [
              359262.36308316817,
              5004842.1519364351
            ],
            [
              359260.95800733677,
              5004842.0378142949
            ],
            [
              359254.48178902955,
              5004840.8260392249
            ],
            [
              359248.35097601806,
              5004839.3273910051
            ],
            [
              359241.59168039676,
              5004837.9806700833
            ],
            [
              359237.49440319819,
              5004836.0592143545
            ],
            [
              359239.8950560693,
              5004828.7720952043
            ],
            [
              359239.72477532044,
              5004829.2889779536
            ],
            [
              359242.87293842697,
              5004825.0158132957
            ],
            [
              359261.70683322148,
              5004807.2598409019
            ],
            [
              359271.4311027021,
              5004800.0052696215
            ],
            [
              359289.97462880128,
              5004788.411078942
            ],
            [
              359284.93101846549,
              5004782.5602636319
            ],
            [
              359265.63481108256,
              5004766.2791579431
            ],
            [
              359233.0593179678,
              5004742.0422218069
            ],
            [
              359215.23056375032,
              5004729.0149303982
            ],
            [
              359216.05811057676,
              5004726.9435621649
            ],
            [
              359235.74485515879,
              5004705.1414359389
            ],
            [
              359234.34927846777,
              5004703.7302249791
            ],
            [
              359240.52774814708,
              5004696.8950268785
            ],
            [
              359259.87609725795,
              5004680.6581121264
            ],
            [
              359268.18824450759,
              5004673.9776540827
            ],
            [
              359306.21155539184,
              5004647.8455181103
            ],
            [
              359326.01598610607,
              5004629.3900398863
            ],
            [
              359332.56719746825,
              5004636.0909497328
            ],
            [
              359340.39029194572,
              5004643.4742303649
            ],
            [
              359350.20718683692,
              5004634.0728780394
            ],
            [
              359366.61303939647,
              5004623.5503302608
            ],
            [
              359348.44103046757,
              5004611.8396735666
            ],
            [
              359360.22561545169,
              5004601.827170562
            ],
            [
              359377.01821332326,
              5004587.3026069263
            ],
            [
              359379.53398555267,
              5004582.6536251782
            ],
            [
              359391.4399875825,
              5004590.6736058276
            ],
            [
              359396.14157842897,
              5004584.863090314
            ],
            [
              359419.21443124278,
              5004602.2140516471
            ],
            [
              359432.4344042482,
              5004610.3781233886
            ],
            [
              359440.48173412634,
              5004615.2761881221
            ],
            [
              359451.09316320182,
              5004623.4728781134
            ],
            [
              359459.84895033384,
              5004629.8914241819
            ],
            [
              359464.5211606684,
              5004633.0981929954
            ],
            [
              359467.12087869633,
              5004638.8726068176
            ],
            [
              359493.36370352376,
              5004656.6442786334
            ],
            [
              359493.99143430806,
              5004650.2976594325
            ],
            [
              359492.67531398538,
              5004629.6404446941
            ],
            [
              359490.69050971256,
              5004623.156062359
            ],
            [
              359492.96916622535,
              5004615.2279102271
            ],
            [
              359504.75335554901,
              5004597.308270826
            ],
            [
              359510.46056164574,
              5004583.4143638974
            ],
            [
              359518.79177045875,
              5004590.339722693
            ],
            [
              359527.84013947716,
              5004585.6997978026
            ],
            [
              359535.93941660662,
              5004580.1459003901
            ],
            [
              359545.8282333492,
              5004579.5097117042
            ],
            [
              359559.19270939904,
              5004556.3436970701
            ],
            [
              359526.31598976505,
              5004522.6716760332
            ],
            [
              359505.76742384542,
              5004537.9446776947
            ],
            [
              359503.71227669582,
              5004533.8259705584
            ],
            [
              359495.29772249277,
              5004527.9732444445
            ],
            [
              359472.84513090691,
              5004518.0034877211
            ],
            [
              359463.67557260307,
              5004516.1243840018
            ],
            [
              359465.39024522598,
              5004508.0806909045
            ],
            [
              359469.35854560597,
              5004500.9266852206
            ],
            [
              359462.45294641861,
              5004500.4961021207
            ],
            [
              359467.72572651593,
              5004493.3178466493
            ],
            [
              359470.39295326418,
              5004486.3743579453
            ],
            [
              359449.84181605076,
              5004483.9615813447
            ],
            [
              359443.09143294569,
              5004481.8512096917
            ],
            [
              359457.54507647204,
              5004464.2498223186
            ],
            [
              359462.40748452203,
              5004456.9664124893
            ],
            [
              359481.92045543744,
              5004444.7317552986
            ],
            [
              359471.02112321393,
              5004435.8900481835
            ],
            [
              359460.05646078318,
              5004427.0772831552
            ],
            [
              359451.13189309643,
              5004419.7039331282
            ],
            [
              359454.26691202697,
              5004416.9565945771
            ],
            [
              359459.3032204053,
              5004408.2712701214
            ],
            [
              359468.59588095325,
              5004400.8216516282
            ],
            [
              359476.49310029432,
              5004395.4798906604
            ],
            [
              359484.90813531616,
              5004385.7327448325
            ],
            [
              359490.11828706932,
              5004375.6455425136
            ],
            [
              359496.0498028635,
              5004361.3490234651
            ],
            [
              359499.20952023228,
              5004348.502642991
            ],
            [
              359483.93454696122,
              5004324.1766357999
            ],
            [
              359466.65656632127,
              5004337.735061585
            ],
            [
              359445.53302709589,
              5004346.1467567375
            ],
            [
              359414.87344312522,
              5004365.7469564583
            ],
            [
              359383.61151067476,
              5004385.755029331
            ],
            [
              359342.74546127353,
              5004405.9508270491
            ],
            [
              359317.35483468539,
              5004418.4987495421
            ],
            [
              359314.48354443075,
              5004422.0786011545
            ],
            [
              359331.12164097326,
              5004431.4699467057
            ],
            [
              359331.80536489841,
              5004434.3541443218
            ],
            [
              359357.12965190335,
              5004447.6121832021
            ],
            [
              359376.49564367492,
              5004458.9657635633
            ],
            [
              359358.89521323366,
              5004490.5018296242
            ],
            [
              359344.61640190921,
              5004510.7646402577
            ],
            [
              359315.38447043888,
              5004525.8818365736
            ],
            [
              359329.03538913053,
              5004538.1863839515
            ],
            [
              359336.86924636859,
              5004529.8383231275
            ],
            [
              359341.23161575652,
              5004534.4627596159
            ],
            [
              359351.27014446165,
              5004545.4113823315
            ],
            [
              359354.16613745264,
              5004548.1848438634
            ],
            [
              359368.13566011132,
              5004559.7477238989
            ],
            [
              359356.74198297696,
              5004575.413547948
            ],
            [
              359352.80974703946,
              5004573.4087863509
            ],
            [
              359338.54165288986,
              5004560.1680074781
            ],
            [
              359334.46999536373,
              5004557.6463799151
            ],
            [
              359308.02749869699,
              5004529.8351591397
            ],
            [
              359292.76123897225,
              5004537.5813667513
            ],
            [
              359252.53033319942,
              5004568.0242295349
            ],
            [
              359199.93245488143,
              5004596.3040891606
            ],
            [
              359178.11027155688,
              5004613.13339487
            ],
            [
              359126.15215056652,
              5004655.0761019429
            ],
            [
              359088.50930066401,
              5004689.8456115862
            ],
            [
              359099.12405056966,
              5004701.7509121699
            ],
            [
              359122.13237103721,
              5004725.4328357531
            ],
            [
              359135.18409841222,
              5004743.9163903212
            ],
            [
              359145.76365956332,
              5004757.5449610092
            ],
            [
              359148.71779592527,
              5004763.2698566439
            ],
            [
              359130.11834151432,
              5004774.2446531234
            ],
            [
              359116.10711485852,
              5004789.6845563464
            ],
            [
              359105.15631799394,
              5004779.710235632
            ],
            [
              359095.27604891226,
              5004770.6906980015
            ],
            [
              359060.27994859911,
              5004741.4866546998
            ],
            [
              359051.63563898916,
              5004734.4337761458
            ],
            [
              359024.90239672962,
              5004767.1361133913
            ],
            [
              359002.10090188537,
              5004798.1673537446
            ],
            [
              359035.89807854098,
              5004817.5309703154
            ],
            [
              359064.96609782637,
              5004832.4562129565
            ],
            [
              359068.2200953298,
              5004827.7598056737
            ],
            [
              359074.38975602464,
              5004818.8553003203
            ],
            [
              359083.8134145065,
              5004805.2543884274
            ],
            [
              359093.39188289811,
              5004811.8910178095
            ],
            [
              359102.97035114717,
              5004818.5276468163
            ],
            [
              359078.45299787814,
              5004847.1897584349
            ],
            [
              359063.00453207706,
              5004835.2872882308
            ],
            [
              359054.78206935769,
              5004847.9303525239
            ],
            [
              359052.08791125071,
              5004852.0729576293
            ],
            [
              359049.46329470357,
              5004853.5566700343
            ],
            [
              359039.35034584691,
              5004849.8572681071
            ],
            [
              359030.71057337656,
              5004845.7394224769
            ],
            [
              359023.27042181644,
              5004855.3949286435
            ],
            [
              359016.93984736054,
              5004859.7503470378
            ],
            [
              359012.54362475971,
              5004864.4789904887
            ],
            [
              359008.14740218624,
              5004869.2076340439
            ],
            [
              359040.12522512203,
              5004885.7777733319
            ],
            [
              359056.90877622314,
              5004891.839874587
            ],
            [
              359064.0476618298,
              5004878.7247923175
            ],
            [
              359070.03231578122,
              5004878.6135329725
            ],
            [
              359099.28024191485,
              5004891.139434007
            ],
            [
              359108.42968531465,
              5004876.9022562541
            ],
            [
              359113.63678589062,
              5004879.6343286475
            ],
            [
              359111.20110756502,
              5004886.2768713403
            ],
            [
              359111.77352292411,
              5004892.0402662382
            ],
            [
              359110.66991632024,
              5004898.9824308548
            ],
            [
              359120.14984587178,
              5004904.8135330388
            ],
            [
              359139.06715992157,
              5004914.7744782539
            ],
            [
              359141.18094893516,
              5004920.167334849
            ],
            [
              359141.76722397463,
              5004924.7620792286
            ],
            [
              359176.49392844172,
              5004947.5225028656
            ],
            [
              359177.42646251054,
              5004960.8696037512
            ],
            [
              359182.90248780185,
              5004962.7097989172
            ],
            [
              359175.58402296022,
              5004977.5881623505
            ],
            [
              359175.63829525193,
              5004985.5459404923
            ],
            [
              359161.30593551026,
              5004981.4714741856
            ],
            [
              359146.04545989993,
              5004972.0459888838
            ],
            [
              359112.25019589596,
              5004961.4801377235
            ],
            [
              359082.0797349725,
              5004950.6181402337
            ],
            [
              359079.23894760566,
              5004957.6387323588
            ],
            [
              359077.40396497323,
              5004964.32658689
            ],
            [
              359064.68314022681,
              5004958.4420963218
            ],
            [
              359056.51656036137,
              5004976.6232504789
            ],
            [
              359065.71682410117,
              5004980.2730364352
            ],
            [
              359061.869307847,
              5004997.01715462
            ],
            [
              359060.94278338732,
              5005003.7175659584
            ],
            [
              359050.80623820849,
              5005020.1774932435
            ],
            [
              359040.57481777354,
              5005051.4999453295
            ],
            [
              359036.97099613573,
              5005069.729640008
            ],
            [
              359049.31168629974,
              5005069.2944775606
            ],
            [
              359055.2347259901,
              5005074.1635011779
            ],
            [
              359063.43461804948,
              5005074.9624473276
            ],
            [
              359081.23596497817,
              5005075.5717788143
            ],
            [
              359093.58037352416,
              5005075.1707633603
            ],
            [
              359118.50507722801,
              5005078.7301837755
            ],
            [
              359152.68682685477,
              5005078.9366865596
            ],
            [
              359160.4197442545,
              5005080.5971694393
            ],
            [
              359172.1007137057,
              5005087.4766852977
            ],
            [
              359162.26130420913,
              5005101.9732486028
            ],
            [
              359153.69642076798,
              5005120.2951596333
            ],
            [
              359151.04701727198,
              5005133.3863109974
            ],
            [
              359150.35913999105,
              5005141.6985872081
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 345,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353372.87712547911,
              5010065.7996111335
            ],
            [
              353409.99997075251,
              5010031.0843043914
            ],
            [
              353390.10884671769,
              5010007.0393131748
            ],
            [
              353345.83769576444,
              5010049.7600629712
            ],
            [
              353338.20754801872,
              5010049.5127162868
            ],
            [
              353303.99841648253,
              5010012.3155129962
            ],
            [
              353288.78942389833,
              5010018.0912808646
            ],
            [
              353277.98487319512,
              5010021.7983075585
            ],
            [
              353271.69736752898,
              5010023.200860233
            ],
            [
              353268.97218798217,
              5010021.2648021104
            ],
            [
              353260.5071522962,
              5010010.9861661708
            ],
            [
              353230.0422842314,
              5010052.5029607108
            ],
            [
              353230.24708670075,
              5010068.3533915635
            ],
            [
              353237.64264165662,
              5010076.4254123839
            ],
            [
              353250.14137623773,
              5010076.5104633402
            ],
            [
              353267.96525327128,
              5010078.1901969546
            ],
            [
              353270.71805295924,
              5010078.2681136373
            ],
            [
              353271.52905148984,
              5010081.5049423026
            ],
            [
              353272.87296134146,
              5010086.4828860201
            ],
            [
              353274.42671988852,
              5010089.2889798833
            ],
            [
              353302.99167637079,
              5010114.8165442282
            ],
            [
              353327.79144726682,
              5010134.5659725042
            ],
            [
              353338.8030988613,
              5010142.7822878631
            ],
            [
              353380.61917154928,
              5010071.9262646046
            ],
            [
              353377.97745416226,
              5010069.5072190706
            ],
            [
              353372.87712547911,
              5010065.7996111335
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 346,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358331.27770260919,
              5006575.1699944288
            ],
            [
              358318.0192477791,
              5006553.2453163834
            ],
            [
              358337.02888236562,
              5006545.3463045657
            ],
            [
              358342.41890746815,
              5006542.3418968013
            ],
            [
              358344.23156986904,
              5006541.5878905859
            ],
            [
              358343.32121437782,
              5006540.8369590817
            ],
            [
              358335.7778223863,
              5006526.8016420091
            ],
            [
              358328.48765767936,
              5006514.3601148352
            ],
            [
              358321.15206720476,
              5006500.2625136273
            ],
            [
              358296.08225142362,
              5006455.9154885085
            ],
            [
              358283.47530619666,
              5006433.0969256321
            ],
            [
              358282.53008985339,
              5006431.4219598686
            ],
            [
              358271.90970550186,
              5006412.45064124
            ],
            [
              358271.71488163591,
              5006410.1174147213
            ],
            [
              358271.7148816369,
              5006410.1174147204
            ],
            [
              358299.32907468016,
              5006394.7951719016
            ],
            [
              358294.56762646529,
              5006389.9967179885
            ],
            [
              358289.90168498195,
              5006383.8704926223
            ],
            [
              358286.85578409513,
              5006379.6203087559
            ],
            [
              358283.48216557142,
              5006373.3439455871
            ],
            [
              358278.38137307036,
              5006364.9835795108
            ],
            [
              358274.96090052096,
              5006358.1642833715
            ],
            [
              358272.88380140421,
              5006353.6903348695
            ],
            [
              358270.71887975902,
              5006344.8248550119
            ],
            [
              358270.70699731313,
              5006336.0969132967
            ],
            [
              358272.05195604818,
              5006324.659202219
            ],
            [
              358272.49313512474,
              5006321.3658296391
            ],
            [
              358273.29577213485,
              5006312.1642860388
            ],
            [
              358274.35869565164,
              5006299.978785689
            ],
            [
              358275.08609765052,
              5006291.0032999404
            ],
            [
              358275.81349988387,
              5006282.0278142346
            ],
            [
              358277.15670768911,
              5006253.389861864
            ],
            [
              358278.02549597021,
              5006231.1707399115
            ],
            [
              358277.40362692362,
              5006223.7423433978
            ],
            [
              358276.78175803513,
              5006216.3139468534
            ],
            [
              358271.30076154595,
              5006197.8932430316
            ],
            [
              358265.74045929866,
              5006179.206002268
            ],
            [
              358260.53477512539,
              5006159.6591106849
            ],
            [
              358255.74745478394,
              5006148.1229792712
            ],
            [
              358244.88029968383,
              5006127.6144778449
            ],
            [
              358238.19320579153,
              5006117.8734119833
            ],
            [
              358231.50611204206,
              5006108.1323457453
            ],
            [
              358222.70721678581,
              5006097.6894049952
            ],
            [
              358213.90832161851,
              5006087.2464637114
            ],
            [
              358204.01986457239,
              5006077.9994370826
            ],
            [
              358194.13140748831,
              5006068.7524099201
            ],
            [
              358181.07225844881,
              5006058.6019889889
            ],
            [
              358181.07225844875,
              5006058.6019889861
            ],
            [
              358181.07225844881,
              5006058.6019889889
            ],
            [
              358168.01310920896,
              5006048.4515672894
            ],
            [
              358160.88624909328,
              5006041.3148231739
            ],
            [
              358156.23332306632,
              5006036.6554440046
            ],
            [
              358153.75938897714,
              5006034.1780787632
            ],
            [
              358140.17627988243,
              5006010.9940393241
            ],
            [
              358135.00108836748,
              5005984.4359602733
            ],
            [
              358134.28897833987,
              5005958.4276056895
            ],
            [
              358138.27251225157,
              5005953.7780325897
            ],
            [
              358139.52466498467,
              5005950.197274847
            ],
            [
              358146.03779296123,
              5005937.5081001557
            ],
            [
              358124.15614639292,
              5005937.1186877033
            ],
            [
              358116.66732504714,
              5005936.67794313
            ],
            [
              358116.3418614901,
              5005942.2079908717
            ],
            [
              358115.63848097908,
              5005949.5104130572
            ],
            [
              358114.39367667533,
              5005956.7402523765
            ],
            [
              358112.614350444,
              5005963.8574226759
            ],
            [
              358110.31036782655,
              5005970.8224625029
            ],
            [
              358107.49450334249,
              5005977.5967538916
            ],
            [
              358100.84930712217,
              5005992.041248966
            ],
            [
              358095.89230392844,
              5006003.6684524044
            ],
            [
              358094.73663612385,
              5006006.8403584016
            ],
            [
              358091.65209576424,
              5006018.2895195149
            ],
            [
              358089.3208729921,
              5006029.9154853551
            ],
            [
              358087.75289554079,
              5006041.6687457263
            ],
            [
              358086.95484079077,
              5006053.4992483398
            ],
            [
              358086.93010733294,
              5006065.3566119578
            ],
            [
              358087.67880049604,
              5006077.190340952
            ],
            [
              358089.19773190044,
              5006088.9500403497
            ],
            [
              358091.48043303227,
              5006100.5856304308
            ],
            [
              358094.51718279178,
              5006112.0475600241
            ],
            [
              358096.03945355868,
              5006117.7674261369
            ],
            [
              358103.8741611329,
              5006138.8054318754
            ],
            [
              358116.70033547876,
              5006166.9598100334
            ],
            [
              358136.73197950219,
              5006206.9418508317
            ],
            [
              358144.27281314501,
              5006222.0232677115
            ],
            [
              358148.72817630495,
              5006232.9629825391
            ],
            [
              358151.55798394623,
              5006242.1099350685
            ],
            [
              358153.28944397531,
              5006250.6894145934
            ],
            [
              358153.59003379283,
              5006262.5989208892
            ],
            [
              358149.56544400105,
              5006279.4934605556
            ],
            [
              358143.63865900628,
              5006299.5513715418
            ],
            [
              358138.76992319169,
              5006315.7069084672
            ],
            [
              358134.61628144718,
              5006337.1968890447
            ],
            [
              358132.99981369509,
              5006351.686373516
            ],
            [
              358132.76029145397,
              5006360.3343626764
            ],
            [
              358132.96033688606,
              5006365.0333948471
            ],
            [
              358133.34667612251,
              5006369.5929817874
            ],
            [
              358134.11405795865,
              5006374.1041031685
            ],
            [
              358134.78625345958,
              5006377.25044352
            ],
            [
              358135.96364396019,
              5006383.0655734697
            ],
            [
              358137.09986366279,
              5006387.6473856131
            ],
            [
              358138.62821705412,
              5006392.1137189539
            ],
            [
              358143.93362614262,
              5006405.6708761798
            ],
            [
              358145.01261796028,
              5006408.4280736828
            ],
            [
              358146.56224915659,
              5006412.0053889444
            ],
            [
              358148.36358822137,
              5006415.4628039915
            ],
            [
              358150.71692656452,
              5006419.6106419833
            ],
            [
              358154.97252534778,
              5006427.1087315297
            ],
            [
              358159.71446128044,
              5006434.187314136
            ],
            [
              358160.68189401878,
              5006435.5838935692
            ],
            [
              358167.72822680295,
              5006445.4247185029
            ],
            [
              358177.20893889887,
              5006457.3727621408
            ],
            [
              358177.78438858304,
              5006458.0855869148
            ],
            [
              358184.98377565766,
              5006466.8519128496
            ],
            [
              358193.75599542138,
              5006475.5572556099
            ],
            [
              358202.21732129558,
              5006482.4164457945
            ],
            [
              358208.80211034184,
              5006487.1180041172
            ],
            [
              358212.45502543077,
              5006490.0200173482
            ],
            [
              358227.68026732776,
              5006506.5767265251
            ],
            [
              358234.90956508572,
              5006517.8425784418
            ],
            [
              358242.23912353715,
              5006534.0827493276
            ],
            [
              358245.79769154853,
              5006543.3560991883
            ],
            [
              358250.78450423881,
              5006557.1275297571
            ],
            [
              358254.5861035671,
              5006566.2983162506
            ],
            [
              358255.20961706183,
              5006567.8828371912
            ],
            [
              358262.53619864833,
              5006582.8642013622
            ],
            [
              358266.5654059173,
              5006589.3578248406
            ],
            [
              358271.08731182775,
              5006596.4775443748
            ],
            [
              358275.49553823849,
              5006603.4182757596
            ],
            [
              358283.62565889902,
              5006612.6882220283
            ],
            [
              358292.78354676865,
              5006605.2814104287
            ],
            [
              358301.19331886002,
              5006614.9797503352
            ],
            [
              358322.13494981558,
              5006631.5587103441
            ],
            [
              358338.13213738258,
              5006644.4068996171
            ],
            [
              358343.7980207146,
              5006649.6308665089
            ],
            [
              358347.57978215296,
              5006655.4820048828
            ],
            [
              358352.69528943801,
              5006662.9663490942
            ],
            [
              358403.81804599508,
              5006614.9315912677
            ],
            [
              358360.19421350415,
              5006574.5413908232
            ],
            [
              358367.46821088548,
              5006564.1804093793
            ],
            [
              358362.03575567272,
              5006555.8414835539
            ],
            [
              358359.86558211182,
              5006556.8689393718
            ],
            [
              358331.27770260919,
              5006575.1699944288
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 347,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352487.86566217075,
              5011799.0712922765
            ],
            [
              352477.25761251856,
              5011816.1755208811
            ],
            [
              352519.16764177743,
              5011855.9318064461
            ],
            [
              352547.4693658505,
              5011818.4471933004
            ],
            [
              352541.90713968448,
              5011814.7162665781
            ],
            [
              352512.20802480925,
              5011806.0066045411
            ],
            [
              352494.74585611577,
              5011797.0457198629
            ],
            [
              352487.86566217075,
              5011799.0712922765
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 348,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358847.75765761052,
              5005801.2926974157
            ],
            [
              358861.06653425342,
              5005806.0711424518
            ],
            [
              358875.05222760112,
              5005813.7756485259
            ],
            [
              358898.18709286355,
              5005824.6900778245
            ],
            [
              358901.30346407229,
              5005830.3429859895
            ],
            [
              358906.76982690976,
              5005837.8044049479
            ],
            [
              358916.02108231251,
              5005845.3497853959
            ],
            [
              358944.2000938757,
              5005863.703144555
            ],
            [
              358952.35500807787,
              5005848.2717155227
            ],
            [
              358953.16596823873,
              5005841.0071320832
            ],
            [
              358951.81075230381,
              5005827.4788885089
            ],
            [
              358946.00187567319,
              5005820.337391139
            ],
            [
              358933.55292070651,
              5005812.4092635047
            ],
            [
              358929.09026609716,
              5005809.0966026261
            ],
            [
              358910.77620569605,
              5005791.6871210868
            ],
            [
              358905.12145782396,
              5005781.0052833194
            ],
            [
              358896.68540241098,
              5005765.9015195193
            ],
            [
              358882.87652509613,
              5005741.7298735874
            ],
            [
              358885.33917968097,
              5005740.3646923844
            ],
            [
              358880.08397226554,
              5005732.9355081664
            ],
            [
              358873.41475858499,
              5005724.8544646082
            ],
            [
              358860.67790941219,
              5005711.1607945599
            ],
            [
              358857.64283653355,
              5005708.0800372632
            ],
            [
              358852.97775998007,
              5005703.9033776466
            ],
            [
              358846.78221676388,
              5005700.3837482147
            ],
            [
              358816.69058916153,
              5005689.025147764
            ],
            [
              358796.65867753129,
              5005680.7903599357
            ],
            [
              358783.55024981807,
              5005673.1230205661
            ],
            [
              358790.87134153,
              5005660.3204130372
            ],
            [
              358797.76273676759,
              5005650.4443387687
            ],
            [
              358807.00073541474,
              5005639.9746237425
            ],
            [
              358804.78998872737,
              5005639.3484942121
            ],
            [
              358794.81313895865,
              5005631.4748108014
            ],
            [
              358790.54417164635,
              5005627.8646299476
            ],
            [
              358787.14758924377,
              5005624.7092728382
            ],
            [
              358776.457200701,
              5005618.6550397296
            ],
            [
              358768.16414255113,
              5005609.8209029781
            ],
            [
              358750.98954097478,
              5005593.4621346891
            ],
            [
              358736.0206968672,
              5005615.7936632587
            ],
            [
              358726.25951108686,
              5005629.9996405896
            ],
            [
              358723.30302565155,
              5005634.9516607607
            ],
            [
              358720.33844998985,
              5005639.4685560521
            ],
            [
              358713.1424634732,
              5005651.0521324556
            ],
            [
              358719.21779064962,
              5005656.0421967302
            ],
            [
              358725.33146622108,
              5005659.2569297338
            ],
            [
              358724.88040384086,
              5005667.8967327569
            ],
            [
              358720.61773058859,
              5005684.4549999088
            ],
            [
              358715.7041216853,
              5005703.6909389198
            ],
            [
              358726.31071725802,
              5005703.2011478469
            ],
            [
              358736.6857674098,
              5005705.0442539984
            ],
            [
              358747.2552758627,
              5005708.9197556451
            ],
            [
              358760.48153287434,
              5005712.432629793
            ],
            [
              358761.55881136627,
              5005714.6436064551
            ],
            [
              358777.34934607049,
              5005721.2411347851
            ],
            [
              358826.30050173984,
              5005724.8728584582
            ],
            [
              358815.17994764214,
              5005741.1091970783
            ],
            [
              358835.79782136233,
              5005762.965363319
            ],
            [
              358834.96287180425,
              5005768.6190578649
            ],
            [
              358835.064757502,
              5005774.0987361027
            ],
            [
              358837.52185566147,
              5005779.8478526734
            ],
            [
              358838.05778326938,
              5005780.97066506
            ],
            [
              358831.89107712195,
              5005784.278414323
            ],
            [
              358799.50758936396,
              5005792.2081159111
            ],
            [
              358789.41660538933,
              5005797.7769155763
            ],
            [
              358796.50226864073,
              5005815.3916632207
            ],
            [
              358847.75765761052,
              5005801.2926974157
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 349,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357656.17971321935,
              5006347.3557129558
            ],
            [
              357663.21212574939,
              5006353.0999121135
            ],
            [
              357668.13795553392,
              5006353.3907329664
            ],
            [
              357668.06426286814,
              5006365.7270558234
            ],
            [
              357669.72207668942,
              5006371.1450672895
            ],
            [
              357672.34871607024,
              5006375.0816536006
            ],
            [
              357675.27945292607,
              5006377.4116273057
            ],
            [
              357672.9207864264,
              5006384.576247517
            ],
            [
              357670.91835179977,
              5006396.7427290436
            ],
            [
              357671.68441678095,
              5006400.8640437294
            ],
            [
              357643.00355070265,
              5006396.8745356742
            ],
            [
              357642.79362218996,
              5006401.3025658112
            ],
            [
              357629.60147608799,
              5006395.5789534245
            ],
            [
              357614.61667267693,
              5006384.9749241397
            ],
            [
              357599.76564762858,
              5006376.1930853324
            ],
            [
              357595.98542533704,
              5006384.2758474452
            ],
            [
              357590.90033663239,
              5006395.2391514415
            ],
            [
              357583.84935046016,
              5006409.2859044503
            ],
            [
              357579.47883303015,
              5006414.0518701626
            ],
            [
              357578.19035747013,
              5006408.7596218372
            ],
            [
              357577.35535726295,
              5006402.7569795698
            ],
            [
              357572.99640053697,
              5006402.0235647876
            ],
            [
              357560.67198147991,
              5006389.4836411178
            ],
            [
              357536.93807418301,
              5006372.8214366939
            ],
            [
              357515.47821905446,
              5006361.6151157776
            ],
            [
              357510.1932577226,
              5006372.5535476012
            ],
            [
              357506.00347670994,
              5006378.8810840398
            ],
            [
              357501.87135251594,
              5006388.3092500698
            ],
            [
              357503.5682485557,
              5006389.4539658325
            ],
            [
              357516.54438718036,
              5006397.5627422109
            ],
            [
              357516.8071284027,
              5006400.9142737845
            ],
            [
              357531.85594519624,
              5006414.3878083769
            ],
            [
              357555.88326438481,
              5006427.3750036554
            ],
            [
              357566.51883254235,
              5006430.8805541471
            ],
            [
              357584.68507185159,
              5006435.0085496893
            ],
            [
              357588.50389195309,
              5006435.2643248457
            ],
            [
              357590.41248805274,
              5006438.2786508948
            ],
            [
              357589.79619045177,
              5006440.7070725216
            ],
            [
              357591.25022202422,
              5006442.4611737859
            ],
            [
              357587.85170700034,
              5006448.0150177991
            ],
            [
              357583.60856877378,
              5006457.8484191922
            ],
            [
              357580.81673988525,
              5006465.1725983424
            ],
            [
              357577.99901093222,
              5006473.3930596737
            ],
            [
              357577.46905843617,
              5006476.2990454389
            ],
            [
              357579.01932882325,
              5006478.385250398
            ],
            [
              357579.77758728649,
              5006478.5222264268
            ],
            [
              357584.57194624719,
              5006477.9438405242
            ],
            [
              357590.22367598582,
              5006477.6464195056
            ],
            [
              357597.08508459351,
              5006483.3100818824
            ],
            [
              357600.97353735392,
              5006491.0459986571
            ],
            [
              357604.14869547723,
              5006489.9666808853
            ],
            [
              357606.86861566658,
              5006485.8634229675
            ],
            [
              357619.27913589362,
              5006465.2993323626
            ],
            [
              357622.81436141604,
              5006458.6276196046
            ],
            [
              357627.49881935964,
              5006454.9070675457
            ],
            [
              357635.16846867587,
              5006446.309730785
            ],
            [
              357640.05709540501,
              5006438.5327031389
            ],
            [
              357640.86483271042,
              5006436.3620032864
            ],
            [
              357640.43257910683,
              5006436.2011577683
            ],
            [
              357638.38954401383,
              5006433.5743387584
            ],
            [
              357640.41122268973,
              5006432.3861998962
            ],
            [
              357651.65633181686,
              5006445.2030232716
            ],
            [
              357655.12981476629,
              5006445.9766264269
            ],
            [
              357675.69431324978,
              5006435.745568498
            ],
            [
              357680.0485511974,
              5006433.1500538699
            ],
            [
              357698.87622437137,
              5006439.4139208812
            ],
            [
              357723.83311602526,
              5006445.5505353222
            ],
            [
              357728.69142503059,
              5006436.2401686925
            ],
            [
              357739.63196308556,
              5006438.3417407395
            ],
            [
              357756.67927860894,
              5006441.9013242843
            ],
            [
              357755.89357180049,
              5006444.7447942421
            ],
            [
              357757.31227771053,
              5006447.7568382323
            ],
            [
              357757.36486317514,
              5006450.5847198516
            ],
            [
              357755.63607768557,
              5006453.4457267206
            ],
            [
              357757.39835772914,
              5006458.0229859063
            ],
            [
              357770.3072365516,
              5006470.146102217
            ],
            [
              357772.6689666984,
              5006473.1406100402
            ],
            [
              357777.73566962325,
              5006475.0370583693
            ],
            [
              357786.06295894826,
              5006492.4507296523
            ],
            [
              357790.15580648551,
              5006498.3466533897
            ],
            [
              357808.81622478022,
              5006481.5503440127
            ],
            [
              357805.23655270529,
              5006479.148449257
            ],
            [
              357800.83266855741,
              5006473.1255051987
            ],
            [
              357795.44448517327,
              5006462.9959674692
            ],
            [
              357799.83857502212,
              5006454.9609935079
            ],
            [
              357815.07376519841,
              5006439.5803207904
            ],
            [
              357823.22960917122,
              5006446.8132869713
            ],
            [
              357832.74173075915,
              5006458.873783933
            ],
            [
              357841.35608830734,
              5006448.4423219291
            ],
            [
              357839.06418511918,
              5006442.5636062268
            ],
            [
              357839.22373249714,
              5006439.9635328436
            ],
            [
              357847.70033182343,
              5006431.9107836531
            ],
            [
              357857.97907434177,
              5006423.6841025427
            ],
            [
              357866.01925711869,
              5006420.1064560395
            ],
            [
              357864.53582242486,
              5006405.3870804245
            ],
            [
              357867.20692127669,
              5006386.0616562394
            ],
            [
              357868.75521461363,
              5006380.221730018
            ],
            [
              357854.96049959969,
              5006378.3977506207
            ],
            [
              357845.8110967027,
              5006377.6432171175
            ],
            [
              357841.58367539285,
              5006394.5119323358
            ],
            [
              357838.19270760292,
              5006392.4944936903
            ],
            [
              357835.20221057296,
              5006393.3591914624
            ],
            [
              357833.1773122478,
              5006396.4020072808
            ],
            [
              357828.02058460872,
              5006398.9251295151
            ],
            [
              357823.63645009877,
              5006399.4690049235
            ],
            [
              357821.69707567745,
              5006400.8920526588
            ],
            [
              357818.34048456396,
              5006400.7233109046
            ],
            [
              357815.7737290659,
              5006399.4996347334
            ],
            [
              357809.0466289639,
              5006399.9772639461
            ],
            [
              357806.91771398665,
              5006394.0288174897
            ],
            [
              357805.78020133363,
              5006389.2302354099
            ],
            [
              357803.44238185242,
              5006387.5274072634
            ],
            [
              357806.1673269989,
              5006382.3218644792
            ],
            [
              357799.21257999132,
              5006375.6241578879
            ],
            [
              357782.92588923534,
              5006364.5285692215
            ],
            [
              357768.78792434226,
              5006379.8557768436
            ],
            [
              357764.32684171636,
              5006389.2505373675
            ],
            [
              357761.09869080759,
              5006389.7729170127
            ],
            [
              357760.53480463912,
              5006400.9979007049
            ],
            [
              357757.3501897885,
              5006411.173860047
            ],
            [
              357755.94306237518,
              5006419.2415476888
            ],
            [
              357751.97679668601,
              5006415.2945580091
            ],
            [
              357725.67403949349,
              5006396.458078716
            ],
            [
              357727.36714155698,
              5006389.8117837301
            ],
            [
              357717.6739992791,
              5006384.933658544
            ],
            [
              357698.09823427087,
              5006378.9422855172
            ],
            [
              357699.34377812553,
              5006397.0773899537
            ],
            [
              357691.98862245894,
              5006394.0727336602
            ],
            [
              357691.17174159893,
              5006392.0127043063
            ],
            [
              357683.23729220132,
              5006390.9929250423
            ],
            [
              357677.92800540925,
              5006384.6065694755
            ],
            [
              357678.4308385753,
              5006378.5891976152
            ],
            [
              357679.75568098982,
              5006367.6816966301
            ],
            [
              357689.84982507187,
              5006364.6053864826
            ],
            [
              357695.21141143556,
              5006365.114608584
            ],
            [
              357702.31750499055,
              5006363.2316787057
            ],
            [
              357705.41742999974,
              5006356.0361529933
            ],
            [
              357707.24549995136,
              5006352.9046149151
            ],
            [
              357709.65191876102,
              5006344.6446279064
            ],
            [
              357694.32917601086,
              5006339.4078228194
            ],
            [
              357687.76387941046,
              5006334.1428515334
            ],
            [
              357683.9674870185,
              5006332.866653665
            ],
            [
              357663.76572255226,
              5006338.9743940104
            ],
            [
              357656.32628671842,
              5006339.0370544354
            ],
            [
              357656.17971321935,
              5006347.3557129558
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 350,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352740.69158199092,
              5011823.1743108248
            ],
            [
              352754.12854797306,
              5011816.2157394979
            ],
            [
              352751.92487503396,
              5011811.5999843981
            ],
            [
              352760.64505115856,
              5011806.7809582306
            ],
            [
              352759.61430272477,
              5011801.466059152
            ],
            [
              352771.47560739552,
              5011797.0119282994
            ],
            [
              352777.48438318598,
              5011792.1586674843
            ],
            [
              352785.25892635656,
              5011800.2040897999
            ],
            [
              352787.40868685488,
              5011797.3700595554
            ],
            [
              352793.17879310279,
              5011788.7958794115
            ],
            [
              352785.69460166933,
              5011778.0976420613
            ],
            [
              352794.82245336234,
              5011772.4243539879
            ],
            [
              352784.61903940624,
              5011761.2687374083
            ],
            [
              352782.37814797001,
              5011764.3927471712
            ],
            [
              352776.27172105148,
              5011766.7452908242
            ],
            [
              352774.40225503274,
              5011760.9601394795
            ],
            [
              352772.7692842235,
              5011759.0921266926
            ],
            [
              352762.47080173856,
              5011767.3694058489
            ],
            [
              352760.8882521721,
              5011765.1817892538
            ],
            [
              352758.04237650387,
              5011766.0866271332
            ],
            [
              352750.22245966695,
              5011769.7007856118
            ],
            [
              352746.98854855337,
              5011771.8106168909
            ],
            [
              352743.75463742157,
              5011773.9204482054
            ],
            [
              352737.28681510838,
              5011778.140110963
            ],
            [
              352718.95361237013,
              5011788.2169533903
            ],
            [
              352708.94245765731,
              5011762.4192915224
            ],
            [
              352707.01711926301,
              5011757.4579022629
            ],
            [
              352702.39977951132,
              5011745.0393635798
            ],
            [
              352701.05566816736,
              5011739.9884109152
            ],
            [
              352697.41392713535,
              5011732.2858292926
            ],
            [
              352693.0365800432,
              5011726.8309326172
            ],
            [
              352710.15433544229,
              5011711.892164574
            ],
            [
              352703.26523495727,
              5011702.0160563216
            ],
            [
              352717.44564559561,
              5011691.1650190484
            ],
            [
              352715.9322815311,
              5011687.5686668931
            ],
            [
              352720.15603166848,
              5011684.77043855
            ],
            [
              352712.60094587866,
              5011670.438734862
            ],
            [
              352704.99368348072,
              5011663.6851443276
            ],
            [
              352712.94012718671,
              5011655.7056631418
            ],
            [
              352713.89442146692,
              5011653.630428683
            ],
            [
              352719.98034347204,
              5011642.6177589539
            ],
            [
              352726.92858008674,
              5011654.0625881916
            ],
            [
              352739.49351226317,
              5011630.7985748285
            ],
            [
              352751.84962423885,
              5011621.7108681006
            ],
            [
              352758.32790242409,
              5011613.0095897624
            ],
            [
              352776.58832772687,
              5011589.7122850297
            ],
            [
              352807.1137353243,
              5011552.3606833555
            ],
            [
              352825.15679995227,
              5011533.8812672505
            ],
            [
              352843.10762249847,
              5011522.5042910436
            ],
            [
              352850.93629065069,
              5011521.5391849177
            ],
            [
              352853.12149187672,
              5011519.3915205728
            ],
            [
              352844.42705899471,
              5011511.593539062
            ],
            [
              352820.66597291926,
              5011518.5908469893
            ],
            [
              352795.22011253278,
              5011523.1613675654
            ],
            [
              352797.54631016398,
              5011514.9192351094
            ],
            [
              352796.15350804757,
              5011508.3957645809
            ],
            [
              352790.71443387534,
              5011505.1734047048
            ],
            [
              352781.32926375646,
              5011510.6266726386
            ],
            [
              352773.89486731315,
              5011505.2909063194
            ],
            [
              352773.45329525921,
              5011507.8406885825
            ],
            [
              352771.04695852008,
              5011506.1312191989
            ],
            [
              352768.33206787612,
              5011516.4404463219
            ],
            [
              352765.27765335824,
              5011515.2265071282
            ],
            [
              352756.78324888292,
              5011521.2497033067
            ],
            [
              352746.99145916296,
              5011536.3880335223
            ],
            [
              352742.02557764156,
              5011540.9940398596
            ],
            [
              352733.13864083367,
              5011537.9587558387
            ],
            [
              352720.56727340253,
              5011535.0890277456
            ],
            [
              352702.593940972,
              5011528.7312392453
            ],
            [
              352697.133671769,
              5011537.7558905827
            ],
            [
              352690.79050640075,
              5011535.8371557062
            ],
            [
              352676.28663523716,
              5011559.4815982906
            ],
            [
              352694.92302986403,
              5011571.922406421
            ],
            [
              352700.8368332031,
              5011573.9796398468
            ],
            [
              352698.03795900819,
              5011581.442408287
            ],
            [
              352709.38746954215,
              5011597.4508778332
            ],
            [
              352706.5364409163,
              5011599.6303465124
            ],
            [
              352702.67358673882,
              5011601.7016074229
            ],
            [
              352690.62477120809,
              5011626.936301088
            ],
            [
              352673.33875593281,
              5011653.0691939034
            ],
            [
              352666.15120673296,
              5011662.7320183916
            ],
            [
              352651.76873320283,
              5011683.7182583204
            ],
            [
              352646.4642021766,
              5011682.6211674092
            ],
            [
              352612.7155271378,
              5011650.1440352695
            ],
            [
              352595.69177181233,
              5011664.706701111
            ],
            [
              352591.75432840706,
              5011677.2885042736
            ],
            [
              352584.63882040425,
              5011682.5459347768
            ],
            [
              352590.80715549627,
              5011688.807869833
            ],
            [
              352581.52420999255,
              5011696.9998851484
            ],
            [
              352604.3657093331,
              5011718.1375319026
            ],
            [
              352614.33321510424,
              5011710.6673297565
            ],
            [
              352615.39453516557,
              5011710.2590696765
            ],
            [
              352624.36532082717,
              5011701.4475901145
            ],
            [
              352629.40232839284,
              5011700.5767989652
            ],
            [
              352655.05284562177,
              5011729.6616069963
            ],
            [
              352652.13170252758,
              5011734.5724375295
            ],
            [
              352640.03427856887,
              5011742.4707871974
            ],
            [
              352649.37545271096,
              5011753.5639699204
            ],
            [
              352658.11668061343,
              5011768.6801990345
            ],
            [
              352679.67102395702,
              5011757.20629548
            ],
            [
              352681.22552356508,
              5011760.560408935
            ],
            [
              352695.5755232409,
              5011788.2798497435
            ],
            [
              352716.52895983914,
              5011837.4270021515
            ],
            [
              352729.40739011735,
              5011835.3645453323
            ],
            [
              352742.79034705862,
              5011827.2270151479
            ],
            [
              352740.69158199092,
              5011823.1743108248
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 351,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357160.38443998876,
              5007081.4332644846
            ],
            [
              357160.38443998079,
              5007081.4332644967
            ],
            [
              357153.91356979852,
              5007089.8332180781
            ],
            [
              357150.3145019798,
              5007093.5668474212
            ],
            [
              357138.23122787586,
              5007105.6195472972
            ],
            [
              357121.18521181511,
              5007114.8340934394
            ],
            [
              357118.2680042354,
              5007116.0380446911
            ],
            [
              357127.22410341079,
              5007140.1683684671
            ],
            [
              357130.42066163395,
              5007146.5674392115
            ],
            [
              357118.78150667023,
              5007151.0234973431
            ],
            [
              357118.78150667116,
              5007151.0234973505
            ],
            [
              357118.78150667349,
              5007151.0234973412
            ],
            [
              357118.78150667268,
              5007151.0234973421
            ],
            [
              357118.78150667349,
              5007151.023497344
            ],
            [
              357118.78150667221,
              5007151.0234973412
            ],
            [
              357105.65160114114,
              5007156.7851796299
            ],
            [
              357105.65160113963,
              5007156.7851796299
            ],
            [
              357083.08169951447,
              5007165.2949878527
            ],
            [
              357070.98910818662,
              5007174.4334237166
            ],
            [
              357052.27780156594,
              5007188.0213729246
            ],
            [
              357028.45907014055,
              5007204.6604352677
            ],
            [
              357023.0347916694,
              5007205.1791329244
            ],
            [
              357003.8633818556,
              5007215.7022747127
            ],
            [
              357007.91604942578,
              5007223.8437488349
            ],
            [
              356991.39263299905,
              5007238.7936697053
            ],
            [
              356988.26393710886,
              5007234.5832065158
            ],
            [
              356986.02786062373,
              5007236.1928470507
            ],
            [
              356979.27023686672,
              5007243.1043909229
            ],
            [
              356968.31007676868,
              5007255.0941485083
            ],
            [
              356968.31007676973,
              5007255.0941485092
            ],
            [
              356968.31007676799,
              5007255.0941485083
            ],
            [
              356945.30384164635,
              5007278.7672717096
            ],
            [
              356924.65763832087,
              5007301.5494209835
            ],
            [
              356922.34947436076,
              5007303.688800849
            ],
            [
              356906.42392628413,
              5007318.3490263065
            ],
            [
              356897.20539882651,
              5007326.9197660852
            ],
            [
              356899.63175045891,
              5007332.9610887766
            ],
            [
              356902.55418570613,
              5007339.4801152106
            ],
            [
              356887.10834134778,
              5007347.1928464323
            ],
            [
              356880.82266244671,
              5007349.7443265831
            ],
            [
              356884.02227962069,
              5007358.9911657879
            ],
            [
              356883.84892146534,
              5007362.7679912448
            ],
            [
              356878.49467060558,
              5007369.5626804074
            ],
            [
              356872.89017214143,
              5007375.9968263907
            ],
            [
              356867.46353832528,
              5007381.4073137129
            ],
            [
              356857.0384003658,
              5007368.4475224037
            ],
            [
              356855.79934802424,
              5007370.0701700812
            ],
            [
              356843.00497702463,
              5007382.7363603879
            ],
            [
              356818.63192630082,
              5007406.6374538075
            ],
            [
              356812.17014429771,
              5007406.270700871
            ],
            [
              356804.74870920758,
              5007403.1562921619
            ],
            [
              356793.76280094747,
              5007414.9248817218
            ],
            [
              356777.48057720723,
              5007423.5052662184
            ],
            [
              356782.50482260744,
              5007425.4340534238
            ],
            [
              356760.90976827726,
              5007447.1825118344
            ],
            [
              356762.3462811113,
              5007448.6914735846
            ],
            [
              356751.26584706362,
              5007458.7515392173
            ],
            [
              356734.13674571388,
              5007474.0430843653
            ],
            [
              356745.83971849055,
              5007485.9330737749
            ],
            [
              356729.36013817129,
              5007501.7244221279
            ],
            [
              356735.79134524701,
              5007510.1790635409
            ],
            [
              356723.14882207109,
              5007518.8604765208
            ],
            [
              356710.64852991758,
              5007528.3070677249
            ],
            [
              356701.80428805662,
              5007534.9163668519
            ],
            [
              356695.05761776603,
              5007526.2957042567
            ],
            [
              356693.10119877104,
              5007527.9935694747
            ],
            [
              356687.90329533297,
              5007530.7776887566
            ],
            [
              356681.82613229804,
              5007534.473987367
            ],
            [
              356676.40081256971,
              5007538.7980152247
            ],
            [
              356680.74366396567,
              5007545.1159335459
            ],
            [
              356674.36590754992,
              5007548.8671412896
            ],
            [
              356667.67729353503,
              5007554.1105242409
            ],
            [
              356661.28277108475,
              5007561.3959656386
            ],
            [
              356655.03424174181,
              5007555.881345978
            ],
            [
              356643.38471203414,
              5007562.8806119878
            ],
            [
              356629.3799032275,
              5007580.9294762611
            ],
            [
              356613.13025771495,
              5007597.9224504596
            ],
            [
              356608.327814024,
              5007594.4284965312
            ],
            [
              356600.62802113267,
              5007579.7267275285
            ],
            [
              356591.1515606473,
              5007579.647003009
            ],
            [
              356581.16886721802,
              5007593.6538794823
            ],
            [
              356564.84441274684,
              5007624.6712436751
            ],
            [
              356569.63501630822,
              5007627.5255322754
            ],
            [
              356577.73069738591,
              5007633.507539128
            ],
            [
              356584.197340324,
              5007645.0738370875
            ],
            [
              356592.32008368586,
              5007652.5831176769
            ],
            [
              356580.44870083808,
              5007666.7447319934
            ],
            [
              356563.70050316502,
              5007679.7657189304
            ],
            [
              356563.70050316578,
              5007679.7657189304
            ],
            [
              356567.29857200576,
              5007684.134612212
            ],
            [
              356556.38027354045,
              5007691.0787291527
            ],
            [
              356540.1961195594,
              5007704.1337894602
            ],
            [
              356547.73198167206,
              5007715.0308291679
            ],
            [
              356556.3068274043,
              5007711.1073258016
            ],
            [
              356564.41663387389,
              5007724.6543765636
            ],
            [
              356566.75793707365,
              5007725.5203176988
            ],
            [
              356570.96565923194,
              5007722.4128085747
            ],
            [
              356580.22875158908,
              5007726.6342881266
            ],
            [
              356599.8067572242,
              5007711.4411803177
            ],
            [
              356602.73047220829,
              5007710.0467824675
            ],
            [
              356622.54304363497,
              5007703.2445349479
            ],
            [
              356625.25926154904,
              5007705.7831882695
            ],
            [
              356627.47519522178,
              5007702.5250977129
            ],
            [
              356631.67071146989,
              5007704.4085992835
            ],
            [
              356642.86016531143,
              5007685.0754314493
            ],
            [
              356651.40264355286,
              5007671.6567718135
            ],
            [
              356665.48531816184,
              5007661.9295101417
            ],
            [
              356663.86314765265,
              5007649.9035893064
            ],
            [
              356652.54820113734,
              5007633.6897929376
            ],
            [
              356642.65373154945,
              5007620.3420195719
            ],
            [
              356648.75385164411,
              5007617.8746538879
            ],
            [
              356655.25865602918,
              5007615.6895028669
            ],
            [
              356682.7904121633,
              5007613.6127297506
            ],
            [
              356698.79070461827,
              5007608.479888076
            ],
            [
              356710.50741205766,
              5007602.2370219305
            ],
            [
              356726.86112100055,
              5007593.2301804675
            ],
            [
              356740.16078049707,
              5007588.0735913217
            ],
            [
              356755.94452572236,
              5007584.4328513965
            ],
            [
              356771.25698676385,
              5007579.4620104656
            ],
            [
              356793.23174913792,
              5007572.5820938759
            ],
            [
              356813.94336167612,
              5007563.4388619792
            ],
            [
              356827.04056762095,
              5007559.4018229367
            ],
            [
              356856.25173891598,
              5007545.4697747752
            ],
            [
              356865.42627534171,
              5007534.5881459815
            ],
            [
              356870.07908707223,
              5007520.6665828982
            ],
            [
              356868.12350672256,
              5007498.0052420208
            ],
            [
              356855.06606539339,
              5007480.1732799318
            ],
            [
              356906.19639452285,
              5007432.3617308903
            ],
            [
              356929.75404141541,
              5007403.4146305751
            ],
            [
              356937.34148456022,
              5007396.1899368186
            ],
            [
              356944.07606101519,
              5007388.1156720044
            ],
            [
              356953.25528019347,
              5007375.2819037242
            ],
            [
              356964.23650887975,
              5007360.9889224302
            ],
            [
              356967.20967443415,
              5007357.2608971642
            ],
            [
              356982.14133098751,
              5007340.4671420511
            ],
            [
              356989.17146569432,
              5007330.0392693207
            ],
            [
              356990.91916115145,
              5007326.8593552103
            ],
            [
              356998.15181619918,
              5007329.9238770697
            ],
            [
              357002.3357223061,
              5007330.0524532488
            ],
            [
              357010.62602963863,
              5007328.9179181391
            ],
            [
              357013.98133032193,
              5007328.907104685
            ],
            [
              357015.84777829883,
              5007329.3367570862
            ],
            [
              357026.68030456069,
              5007331.1165426793
            ],
            [
              357030.46047665802,
              5007330.8169309758
            ],
            [
              357037.17978323583,
              5007330.2333136741
            ],
            [
              357048.79676608817,
              5007325.6984031647
            ],
            [
              357052.75454476028,
              5007324.6692803949
            ],
            [
              357061.79831845575,
              5007322.4744935697
            ],
            [
              357070.66074455687,
              5007319.7533252053
            ],
            [
              357079.70089209575,
              5007314.0851690499
            ],
            [
              357083.36655981437,
              5007311.1508014286
            ],
            [
              357086.52657252591,
              5007309.207148293
            ],
            [
              357087.81200333149,
              5007307.4789879117
            ],
            [
              357091.69222570886,
              5007295.2448277874
            ],
            [
              357096.81747834414,
              5007291.6132105356
            ],
            [
              357100.4101838533,
              5007288.2954730634
            ],
            [
              357110.60733727872,
              5007281.2050873647
            ],
            [
              357113.0908137114,
              5007280.8950673966
            ],
            [
              357115.76823246526,
              5007280.8656754754
            ],
            [
              357123.89876456634,
              5007286.1814967841
            ],
            [
              357140.5532994665,
              5007293.750340811
            ],
            [
              357142.80323035363,
              5007297.7260925239
            ],
            [
              357150.52741449384,
              5007298.6827855241
            ],
            [
              357154.07945900661,
              5007300.2452643216
            ],
            [
              357167.41316629347,
              5007290.270118068
            ],
            [
              357177.47181288683,
              5007282.8098308966
            ],
            [
              357186.78215654596,
              5007279.0715270052
            ],
            [
              357192.5854614742,
              5007276.1906826897
            ],
            [
              357203.51472739695,
              5007266.2837435836
            ],
            [
              357209.37167859694,
              5007261.5533151655
            ],
            [
              357214.32862142642,
              5007255.7833100166
            ],
            [
              357217.10834791051,
              5007253.7069387855
            ],
            [
              357219.09072711499,
              5007250.6336445007
            ],
            [
              357221.08034441178,
              5007246.3272804711
            ],
            [
              357225.4933622462,
              5007225.3061735705
            ],
            [
              357228.0261205245,
              5007212.3188572647
            ],
            [
              357229.64530452859,
              5007199.8971964419
            ],
            [
              357230.73961456073,
              5007192.4384320816
            ],
            [
              357232.30459394335,
              5007188.9761973415
            ],
            [
              357233.44430770882,
              5007183.6411398919
            ],
            [
              357238.04447833897,
              5007170.5273493752
            ],
            [
              357239.79478664225,
              5007162.6580852689
            ],
            [
              357240.89317714353,
              5007156.0540330866
            ],
            [
              357242.92005144391,
              5007153.6121682869
            ],
            [
              357243.86524783698,
              5007150.7096284637
            ],
            [
              357246.28909355978,
              5007146.6206306741
            ],
            [
              357246.25390930794,
              5007144.7287385631
            ],
            [
              357245.61644575809,
              5007142.8480827454
            ],
            [
              357243.52274013247,
              5007141.3385956585
            ],
            [
              357245.68092188874,
              5007136.7910259422
            ],
            [
              357248.68231636821,
              5007131.5738047604
            ],
            [
              357251.31557802175,
              5007125.073052546
            ],
            [
              357256.56166459952,
              5007115.5989386709
            ],
            [
              357257.79146019043,
              5007112.3071756708
            ],
            [
              357260.53214096389,
              5007106.1943528419
            ],
            [
              357265.11227238626,
              5007097.9369269656
            ],
            [
              357271.0495496912,
              5007083.9766942328
            ],
            [
              357272.24416231521,
              5007078.7930749618
            ],
            [
              357274.88418686908,
              5007071.9989899304
            ],
            [
              357276.137971227,
              5007069.9971229378
            ],
            [
              357279.87083308824,
              5007067.0889361277
            ],
            [
              357281.9497932315,
              5007063.1792125003
            ],
            [
              357286.80565328366,
              5007055.862915338
            ],
            [
              357287.54482187476,
              5007053.9566581957
            ],
            [
              357292.34000998986,
              5007043.1527628526
            ],
            [
              357295.084573992,
              5007033.3810504917
            ],
            [
              357297.18533602316,
              5007021.3847140055
            ],
            [
              357296.77882232057,
              5007018.0373428501
            ],
            [
              357294.4880800728,
              5007012.4466909654
            ],
            [
              357269.37144003814,
              5006990.1175448839
            ],
            [
              357265.09322529455,
              5006986.8421751643
            ],
            [
              357261.60404926114,
              5006988.9716526801
            ],
            [
              357255.41129957471,
              5006993.8180961097
            ],
            [
              357237.69323856494,
              5007008.3415013887
            ],
            [
              357222.79521651095,
              5007019.702274085
            ],
            [
              357209.89149429847,
              5007024.6735582603
            ],
            [
              357199.69446892268,
              5007035.1856754897
            ],
            [
              357195.15485393436,
              5007039.8748911265
            ],
            [
              357189.48914809414,
              5007046.7383999927
            ],
            [
              357186.8916072938,
              5007047.1417532545
            ],
            [
              357182.94097566238,
              5007051.3981643301
            ],
            [
              357179.26448416943,
              5007055.8711170694
            ],
            [
              357174.85477199306,
              5007061.6469482146
            ],
            [
              357172.14032415225,
              5007065.3292612061
            ],
            [
              357169.8700516622,
              5007068.3433099072
            ],
            [
              357167.65508167719,
              5007071.0709913829
            ],
            [
              357166.27383224753,
              5007072.6918126214
            ],
            [
              357163.61556957779,
              5007076.3959339345
            ],
            [
              357161.9176681331,
              5007078.7618525177
            ],
            [
              357160.38443998876,
              5007081.4332644846
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 352,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353313.27321581793,
              5010867.3480037581
            ],
            [
              353302.39956619684,
              5010889.8015582561
            ],
            [
              353283.40890946845,
              5010915.582331053
            ],
            [
              353270.85948166245,
              5010899.1909290217
            ],
            [
              353257.34848857328,
              5010899.3510897467
            ],
            [
              353249.38302322623,
              5010893.2929789377
            ],
            [
              353245.28820564988,
              5010884.2422275916
            ],
            [
              353236.15352173185,
              5010916.2665667729
            ],
            [
              353229.01428405353,
              5010938.3768260842
            ],
            [
              353243.18244126107,
              5010939.9451742768
            ],
            [
              353244.44284318894,
              5010947.5403513676
            ],
            [
              353202.32070069126,
              5010947.7301137261
            ],
            [
              353197.84517849755,
              5010990.8462334936
            ],
            [
              353233.49770451774,
              5010996.7833606061
            ],
            [
              353239.88174491987,
              5010997.8464790229
            ],
            [
              353239.88174493547,
              5010997.8464790238
            ],
            [
              353239.88174491987,
              5010997.8464790229
            ],
            [
              353236.77941820817,
              5011006.537122855
            ],
            [
              353238.73451149283,
              5011007.0647876654
            ],
            [
              353252.22840123961,
              5011010.7066858085
            ],
            [
              353255.23302511935,
              5011004.7909472296
            ],
            [
              353249.40368988557,
              5011002.5852789329
            ],
            [
              353258.90320986538,
              5010985.279627271
            ],
            [
              353262.08705256204,
              5010986.9239944108
            ],
            [
              353271.90379833226,
              5010992.363218314
            ],
            [
              353277.75588423829,
              5010975.6213873699
            ],
            [
              353261.07201566477,
              5010964.2863560924
            ],
            [
              353269.122102122,
              5010945.7416756814
            ],
            [
              353290.21865273156,
              5010947.3769078497
            ],
            [
              353306.31375552039,
              5010950.5388606964
            ],
            [
              353315.2300123187,
              5010938.9735778999
            ],
            [
              353323.8723961385,
              5010929.1501615513
            ],
            [
              353333.46198664961,
              5010920.0234643864
            ],
            [
              353343.98971054616,
              5010913.6993990801
            ],
            [
              353348.54082966124,
              5010912.0658565238
            ],
            [
              353352.85265312769,
              5010912.0529751517
            ],
            [
              353353.07467578468,
              5010912.1835247446
            ],
            [
              353354.75819538516,
              5010909.3204022916
            ],
            [
              353348.65208972903,
              5010904.1079132361
            ],
            [
              353354.96158371359,
              5010899.8979568742
            ],
            [
              353365.78781591711,
              5010896.1872006031
            ],
            [
              353412.46914605849,
              5010849.8019369924
            ],
            [
              353419.65688582946,
              5010838.87736852
            ],
            [
              353363.6309319631,
              5010794.3231457956
            ],
            [
              353360.52814080339,
              5010793.3294690708
            ],
            [
              353285.47792519996,
              5010750.9130516257
            ],
            [
              353281.86999828374,
              5010766.8820182318
            ],
            [
              353284.76651969651,
              5010786.4657043461
            ],
            [
              353285.94305261853,
              5010805.8372309683
            ],
            [
              353286.91993493721,
              5010810.1264064265
            ],
            [
              353306.12041925354,
              5010852.3949715896
            ],
            [
              353315.87193212536,
              5010861.9817822697
            ],
            [
              353313.27321581793,
              5010867.3480037581
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 353,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356472.71403512015,
              5006975.4817223502
            ],
            [
              356470.03581097996,
              5006974.0705609033
            ],
            [
              356468.00982989738,
              5006973.9061454507
            ],
            [
              356466.89570462902,
              5006973.825800742
            ],
            [
              356465.77782236336,
              5006973.5434334874
            ],
            [
              356464.86784470145,
              5006973.5603562482
            ],
            [
              356463.76311289478,
              5006973.985121212
            ],
            [
              356462.66777389712,
              5006974.9149603182
            ],
            [
              356460.58577741118,
              5006977.1768022459
            ],
            [
              356459.99788983929,
              5006978.1982683251
            ],
            [
              356459.4175162618,
              5006979.6237795111
            ],
            [
              356458.43642901583,
              5006981.2588707088
            ],
            [
              356455.6767153737,
              5006985.1501621734
            ],
            [
              356453.40372244117,
              5006988.0219045216
            ],
            [
              356452.58872503828,
              5006989.0976818316
            ],
            [
              356452.00083682762,
              5006990.1191124199
            ],
            [
              356451.12272905413,
              5006991.8533170149
            ],
            [
              356450.54047734622,
              5006993.1778347502
            ],
            [
              356449.45647628646,
              5006994.7137759263
            ],
            [
              356445.21198174974,
              5007000.35059008
            ],
            [
              356443.92195423524,
              5007001.6882347949
            ],
            [
              356443.22350632807,
              5007002.2065082109
            ],
            [
              356442.2218201888,
              5007002.7303853976
            ],
            [
              356441.1170883454,
              5007003.1551504806
            ],
            [
              356440.11728103668,
              5007003.7800567206
            ],
            [
              356438.91896193434,
              5007004.6107471166
            ],
            [
              356436.93424351193,
              5007006.668687867
            ],
            [
              356434.95334901719,
              5007008.9286855133
            ],
            [
              356432.59240475582,
              5007012.5094051231
            ],
            [
              356427.90251953597,
              5007021.3881594632
            ],
            [
              356427.45214355848,
              5007021.756924415
            ],
            [
              356427.04955037223,
              5007021.8654399002
            ],
            [
              356426.44287644763,
              5007021.876722239
            ],
            [
              356425.63024294231,
              5007021.6897423752
            ],
            [
              356408.49349363183,
              5007019.3810911067
            ],
            [
              356406.36822280969,
              5007019.3195508365
            ],
            [
              356404.85532871162,
              5007019.549814173
            ],
            [
              356404.05208737642,
              5007019.8678729776
            ],
            [
              356403.55402939895,
              5007020.2813558318
            ],
            [
              356403.26387610595,
              5007020.9941287283
            ],
            [
              356403.28078369278,
              5007021.9032836556
            ],
            [
              356404.0054665333,
              5007022.7992760576
            ],
            [
              356405.43040991656,
              5007023.2780252295
            ],
            [
              356408.07245889545,
              5007023.9362678314
            ],
            [
              356421.67590182036,
              5007026.6137843896
            ],
            [
              356421.98490777978,
              5007026.9111942258
            ],
            [
              356421.99805835687,
              5007027.6183265317
            ],
            [
              356421.91574286198,
              5007028.6303551132
            ],
            [
              356421.42332079,
              5007029.3468895843
            ],
            [
              356421.13497946016,
              5007030.1606572
            ],
            [
              356419.67844414752,
              5007033.4214368509
            ],
            [
              356419.0494270596,
              5007034.7538647614
            ],
            [
              356425.4827182623,
              5007037.9114702391
            ],
            [
              356438.84493558871,
              5007043.752661352
            ],
            [
              356459.40424005472,
              5007058.1235260423
            ],
            [
              356483.58021213394,
              5007106.654232542
            ],
            [
              356475.24613230646,
              5007118.6759577906
            ],
            [
              356458.76470188925,
              5007142.4692841386
            ],
            [
              356440.59634331457,
              5007168.3948779209
            ],
            [
              356425.49713670154,
              5007186.6487612277
            ],
            [
              356414.70919180097,
              5007197.6129857022
            ],
            [
              356391.63651343103,
              5007219.2159230523
            ],
            [
              356389.63813986845,
              5007216.3662806163
            ],
            [
              356384.85353458254,
              5007211.4703247352
            ],
            [
              356384.8535345986,
              5007211.4703247501
            ],
            [
              356376.12086546019,
              5007204.5952029079
            ],
            [
              356365.86770792335,
              5007195.2814506432
            ],
            [
              356363.98687203327,
              5007194.045765725
            ],
            [
              356347.06474399258,
              5007183.2177277189
            ],
            [
              356338.43045866175,
              5007178.2516894061
            ],
            [
              356342.45773158007,
              5007173.2773586763
            ],
            [
              356350.39031249058,
              5007162.6623215107
            ],
            [
              356372.40885922563,
              5007149.1537808441
            ],
            [
              356389.1652124364,
              5007135.6787606608
            ],
            [
              356389.92034663277,
              5007134.5896964893
            ],
            [
              356366.52421231376,
              5007099.6040120767
            ],
            [
              356341.84660008171,
              5007110.6330533102
            ],
            [
              356344.56949805934,
              5007115.6350468947
            ],
            [
              356338.86436520185,
              5007118.8737406041
            ],
            [
              356336.45047390362,
              5007114.169192329
            ],
            [
              356316.67417384317,
              5007122.21695555
            ],
            [
              356315.84004784777,
              5007122.7702082973
            ],
            [
              356315.04244300589,
              5007123.3913547043
            ],
            [
              356314.34593951114,
              5007124.0106208632
            ],
            [
              356313.45286932046,
              5007124.9366991883
            ],
            [
              356312.15526138718,
              5007125.870300848
            ],
            [
              356310.95325136511,
              5007126.4989680788
            ],
            [
              356309.94962049765,
              5007126.9218535433
            ],
            [
              356308.54158378445,
              5007127.352224309
            ],
            [
              356306.72344026685,
              5007127.4871009775
            ],
            [
              356305.31352529145,
              5007127.816478204
            ],
            [
              356303.60211908346,
              5007128.2525263792
            ],
            [
              356301.79336852179,
              5007128.8924774425
            ],
            [
              356299.68507120054,
              5007129.7400917793
            ],
            [
              356298.48863048072,
              5007130.671812254
            ],
            [
              356296.69490928191,
              5007132.1198892305
            ],
            [
              356295.20449237333,
              5007133.5623613037
            ],
            [
              356294.10921953234,
              5007134.4922001502
            ],
            [
              356292.70306166826,
              5007135.0236000456
            ],
            [
              356290.48796069314,
              5007135.5700441757
            ],
            [
              356289.13298336248,
              5007135.7316778582
            ],
            [
              356287.61626483669,
              5007135.7598848362
            ],
            [
              356285.80375716992,
              5007136.1978133041
            ],
            [
              356284.60168076964,
              5007136.8264818788
            ],
            [
              356273.22522990726,
              5007145.1222173115
            ],
            [
              356276.921634781,
              5007148.0850394573
            ],
            [
              356284.29496517923,
              5007142.0868759742
            ],
            [
              356285.39781912765,
              5007141.5611166935
            ],
            [
              356286.09995862842,
              5007141.2449021842
            ],
            [
              356287.20650343841,
              5007140.9211667785
            ],
            [
              356288.62024178443,
              5007140.7938109096
            ],
            [
              356289.8373478562,
              5007140.9733039839
            ],
            [
              356291.26229188062,
              5007141.4520528289
            ],
            [
              356292.08807658608,
              5007142.3461650489
            ],
            [
              356292.91949726141,
              5007143.5433289949
            ],
            [
              356293.44949221105,
              5007144.847127174
            ],
            [
              356294.09186108428,
              5007146.7551841093
            ],
            [
              356294.11252610775,
              5007147.8663619636
            ],
            [
              356294.120040963,
              5007148.2704427913
            ],
            [
              356286.55578021466,
              5007154.8784701619
            ],
            [
              356282.35192365205,
              5007151.8240214298
            ],
            [
              356263.16392146476,
              5007164.3071318353
            ],
            [
              356263.48900795606,
              5007172.0370532405
            ],
            [
              356238.41204274527,
              5007183.3159950692
            ],
            [
              356238.25110478251,
              5007185.5421483656
            ],
            [
              356232.7388466156,
              5007188.2720080679
            ],
            [
              356231.71267161658,
              5007187.4826866155
            ],
            [
              356230.48429394583,
              5007186.6971256258
            ],
            [
              356229.46194231365,
              5007186.109825613
            ],
            [
              356228.44140266447,
              5007185.6235204041
            ],
            [
              356227.22241710691,
              5007185.343033663
            ],
            [
              356225.63976044388,
              5007184.9937704559
            ],
            [
              356223.71487916389,
              5007184.8274759008
            ],
            [
              356221.28630380647,
              5007184.7716128593
            ],
            [
              356218.20811871561,
              5007185.030580123
            ],
            [
              356217.67819218693,
              5007185.1416676641
            ],
            [
              356218.20811871567,
              5007185.0305801248
            ],
            [
              356217.148265658,
              5007185.2527552061
            ],
            [
              356214.43329361995,
              5007186.1116527468
            ],
            [
              356211.31948662095,
              5007187.2811598126
            ],
            [
              356206.10295090586,
              5007189.6012993539
            ],
            [
              356198.49735799734,
              5007193.98693748
            ],
            [
              356192.69112278009,
              5007197.2275496451
            ],
            [
              356189.58858700766,
              5007199.0031250063
            ],
            [
              356187.28553509776,
              5007200.2585827224
            ],
            [
              356185.78203331586,
              5007200.9939215425
            ],
            [
              356184.27665263263,
              5007201.6282312488
            ],
            [
              356182.87042814615,
              5007202.1596328327
            ],
            [
              356181.26394610276,
              5007202.7958227862
            ],
            [
              356179.76037804637,
              5007203.5311628636
            ],
            [
              356177.15965806431,
              5007205.0953132045
            ],
            [
              356175.16749066784,
              5007206.7491744822
            ],
            [
              356171.89093290531,
              5007210.0438052509
            ],
            [
              356167.3976812475,
              5007215.2302335184
            ],
            [
              356166.21627048979,
              5007216.9701163881
            ],
            [
              356165.13408152945,
              5007218.6070543611
            ],
            [
              356163.96018506377,
              5007220.7509826971
            ],
            [
              356162.49613553321,
              5007223.6076846123
            ],
            [
              356160.63888644293,
              5007227.0780124711
            ],
            [
              356159.06803263113,
              5007229.6335085304
            ],
            [
              356156.40942077659,
              5007233.5229251217
            ],
            [
              356153.33882295951,
              5007237.0158185549
            ],
            [
              356151.65559637389,
              5007238.9671267793
            ],
            [
              356150.16712379153,
              5007240.5105922967
            ],
            [
              356148.3307896094,
              5007242.1191820931
            ],
            [
              356146.43214302178,
              5007243.3671192434
            ],
            [
              356143.82954406185,
              5007244.8302409044
            ],
            [
              356141.82422628533,
              5007245.7770052394
            ],
            [
              356139.31333544006,
              5007246.7331720758
            ],
            [
              356137.30613873404,
              5007247.5789073007
            ],
            [
              356134.99745058577,
              5007248.5313136587
            ],
            [
              356132.9864966998,
              5007249.1750261635
            ],
            [
              356131.3800145124,
              5007249.8112164326
            ],
            [
              356129.37650933542,
              5007250.8590112031
            ],
            [
              356127.27760510886,
              5007252.211736884
            ],
            [
              356124.67688495264,
              5007253.7758878311
            ],
            [
              356121.09507723758,
              5007256.9750968525
            ],
            [
              356105.83059029566,
              5007252.0042888429
            ],
            [
              356103.96582620294,
              5007255.0705363797
            ],
            [
              356108.45795999764,
              5007257.3111825725
            ],
            [
              356109.95805285854,
              5007261.8306349721
            ],
            [
              356108.83118964703,
              5007266.4999713497
            ],
            [
              356096.24667565647,
              5007269.6645537531
            ],
            [
              356087.88098746474,
              5007283.6231174571
            ],
            [
              356070.42006367975,
              5007298.041939456
            ],
            [
              356066.88264811004,
              5007292.7519703154
            ],
            [
              356064.08348210441,
              5007294.5219051549
            ],
            [
              356067.42802267818,
              5007300.3207463343
            ],
            [
              356054.21275762218,
              5007307.6401560055
            ],
            [
              356054.49762397748,
              5007312.0811809991
            ],
            [
              356050.71702228312,
              5007310.0293948278
            ],
            [
              356050.05997078109,
              5007312.7699899524
            ],
            [
              356033.70372936345,
              5007327.7627461478
            ],
            [
              356032.10664020694,
              5007328.9040117264
            ],
            [
              356030.50384931348,
              5007329.7422621204
            ],
            [
              356029.19691311143,
              5007330.1707537863
            ],
            [
              356027.58291617577,
              5007330.4028990269
            ],
            [
              356025.76289232227,
              5007330.4367477205
            ],
            [
              356023.13211211137,
              5007330.3846107004
            ],
            [
              356020.19420423143,
              5007330.1360929357
            ],
            [
              356017.34807062784,
              5007329.3806188805
            ],
            [
              356015.21152505837,
              5007328.7129765078
            ],
            [
              356013.58625511284,
              5007328.3389822533
            ],
            [
              356011.55644772272,
              5007327.9725471074
            ],
            [
              356008.71970739815,
              5007327.7221478084
            ],
            [
              356005.98594672384,
              5007327.5708618676
            ],
            [
              356002.95257343777,
              5007327.6272763358
            ],
            [
              356000.88906852173,
              5007327.6975757284
            ],
            [
              355999.07280173228,
              5007327.9334472269
            ],
            [
              355997.26217107562,
              5007328.4723707223
            ],
            [
              355995.1556850237,
              5007329.4210174596
            ],
            [
              355992.95943609497,
              5007330.9776472496
            ],
            [
              355990.96914717864,
              5007332.732539326
            ],
            [
              355989.07996032533,
              5007334.4855866749
            ],
            [
              355987.70010330342,
              5007336.4312187051
            ],
            [
              355986.42691825173,
              5007338.6780592389
            ],
            [
              355985.25872433471,
              5007341.1250398681
            ],
            [
              355984.27951574622,
              5007342.8611279922
            ],
            [
              355983.09058991534,
              5007344.1969310408
            ],
            [
              355981.79680480197,
              5007345.3325560186
            ],
            [
              355980.0929123066,
              5007346.1726868944
            ],
            [
              355977.47904021695,
              5007347.0297060218
            ],
            [
              355976.17210383486,
              5007347.4581978209
            ],
            [
              355974.76970285882,
              5007348.1916574566
            ],
            [
              355973.06768861524,
              5007349.1327820122
            ],
            [
              355971.56982237997,
              5007350.1711739609
            ],
            [
              355969.37726436491,
              5007351.9298280571
            ],
            [
              355967.79332625895,
              5007353.778227184
            ],
            [
              355966.97478640371,
              5007355.0555256009
            ],
            [
              355966.30076101242,
              5007356.887002185
            ],
            [
              355966.13801136153,
              5007359.012126903
            ],
            [
              355966.48459226132,
              5007361.3298717896
            ],
            [
              355966.82734969654,
              5007363.4455950679
            ],
            [
              355966.68902373145,
              5007366.8839570517
            ],
            [
              355966.14246800757,
              5007370.1277830554
            ],
            [
              355965.47038789332,
              5007372.0602876274
            ],
            [
              355964.68774604786,
              5007373.4895633366
            ],
            [
              355963.30788981129,
              5007375.435231193
            ],
            [
              355961.11915536161,
              5007377.3959070975
            ],
            [
              355958.33119571768,
              5007379.7719839979
            ],
            [
              355957.53171157738,
              5007380.2921024375
            ],
            [
              355956.93067302689,
              5007380.6064374568
            ],
            [
              355956.11991650873,
              5007380.52045184
            ],
            [
              355952.83361428446,
              5007377.853159084
            ],
            [
              355950.46327715932,
              5007380.9288113629
            ],
            [
              355952.82120052434,
              5007383.1831633104
            ],
            [
              355950.06435070309,
              5007403.5459448732
            ],
            [
              355943.60663232341,
              5007409.8302123547
            ],
            [
              355942.70041056263,
              5007410.0491591468
            ],
            [
              355941.79788046319,
              5007410.47016556
            ],
            [
              355940.2026701154,
              5007411.7124610217
            ],
            [
              355936.02170236729,
              5007415.3270376967
            ],
            [
              355932.9398320014,
              5007418.2138308901
            ],
            [
              355931.25660537492,
              5007420.1651412528
            ],
            [
              355928.93999377213,
              5007422.8083081702
            ],
            [
              355926.57153584907,
              5007425.9849901684
            ],
            [
              355925.28902325325,
              5007427.7267197492
            ],
            [
              355924.19932001171,
              5007428.9596138149
            ],
            [
              355922.90741392196,
              5007430.1962685026
            ],
            [
              355921.61362886854,
              5007431.3318940038
            ],
            [
              355920.21874304919,
              5007432.4694353538
            ],
            [
              355919.12528187671,
              5007433.500271054
            ],
            [
              355918.33519189438,
              5007434.5255001383
            ],
            [
              355917.65747581335,
              5007436.1549530411
            ],
            [
              355916.9722445333,
              5007437.3803246925
            ],
            [
              355916.18772388314,
              5007438.7085715495
            ],
            [
              355914.80223158118,
              5007440.3511878774
            ],
            [
              355908.05248142692,
              5007447.2472017715
            ],
            [
              355888.90188395337,
              5007429.1108339233
            ],
            [
              355882.93711987365,
              5007435.8083567657
            ],
            [
              355904.45089139231,
              5007455.9218432317
            ],
            [
              355904.27381440665,
              5007468.152441089
            ],
            [
              355902.40064718801,
              5007471.3904400999
            ],
            [
              355900.13892657537,
              5007474.8682588954
            ],
            [
              355897.86969138961,
              5007477.9420319917
            ],
            [
              355895.89819048758,
              5007480.7071108464
            ],
            [
              355893.51658133575,
              5007483.1766601754
            ],
            [
              355890.135164165,
              5007486.2711181827
            ],
            [
              355886.74804445152,
              5007489.0625253972
            ],
            [
              355883.65678001574,
              5007491.4442091556
            ],
            [
              355879.8577392638,
              5007493.8390564779
            ],
            [
              355876.56239360193,
              5007496.123507306
            ],
            [
              355875.06076942565,
              5007496.9598417627
            ],
            [
              355874.253704403,
              5007497.0759160602
            ],
            [
              355873.24638107803,
              5007497.2967434553
            ],
            [
              355871.63983189076,
              5007497.9329365967
            ],
            [
              355868.13288969448,
              5007499.7160731833
            ],
            [
              355867.23599514534,
              5007500.440096491
            ],
            [
              355866.64059284341,
              5007501.0574839059
            ],
            [
              355865.94408910087,
              5007501.6767516527
            ],
            [
              355864.03423556581,
              5007502.3185858149
            ],
            [
              355861.42599994765,
              5007503.4786940655
            ],
            [
              355858.47781704966,
              5007504.8051993661
            ],
            [
              355855.9669248673,
              5007505.7613688884
            ],
            [
              355853.86614099768,
              5007507.0130679887
            ],
            [
              355851.96943899704,
              5007508.3620356591
            ],
            [
              355850.77675538108,
              5007509.4957813546
            ],
            [
              355849.67953756551,
              5007510.3246301431
            ],
            [
              355848.28465105791,
              5007511.4621365191
            ],
            [
              355846.28496860882,
              5007512.711955349
            ],
            [
              355845.28133693821,
              5007513.1348424815
            ],
            [
              355843.9800365704,
              5007513.8663872024
            ],
            [
              355843.38275592431,
              5007514.382780998
            ],
            [
              355842.79486824322,
              5007515.40421429
            ],
            [
              355840.73166017554,
              5007518.6762847081
            ],
            [
              355840.2505102632,
              5007519.9988898095
            ],
            [
              355844.17167117412,
              5007524.1701594517
            ],
            [
              355865.24887082033,
              5007509.9340002956
            ],
            [
              355866.75056125934,
              5007509.0976645257
            ],
            [
              355868.35516520921,
              5007508.3604433425
            ],
            [
              355870.16391727008,
              5007507.7204894833
            ],
            [
              355872.07940706291,
              5007507.381707401
            ],
            [
              355874.10170083592,
              5007507.3440958597
            ],
            [
              355875.82619408093,
              5007507.6151798731
            ],
            [
              355877.15191787254,
              5007508.1968373032
            ],
            [
              355878.3765402225,
              5007508.7803750569
            ],
            [
              355879.30349456432,
              5007509.6726058964
            ],
            [
              355879.6237077823,
              5007510.5761211831
            ],
            [
              355879.74735443509,
              5007511.7864492275
            ],
            [
              355879.47223165416,
              5007513.3073506933
            ],
            [
              355878.695292763,
              5007515.0396779412
            ],
            [
              355877.54206408968,
              5007518.2948237769
            ],
            [
              355875.51260722749,
              5007523.3851721603
            ],
            [
              355874.68145071086,
              5007526.0829720004
            ],
            [
              355874.11610905547,
              5007528.3166491482
            ],
            [
              355873.8521927328,
              5007530.4436560813
            ],
            [
              355873.89728331205,
              5007532.8680728702
            ],
            [
              355874.34684591152,
              5007535.2849670695
            ],
            [
              355875.11474282685,
              5007538.5043473253
            ],
            [
              355868.51841500093,
              5007542.7701623049
            ],
            [
              355857.15335026098,
              5007524.4889983078
            ],
            [
              355852.95547373942,
              5007527.1944208741
            ],
            [
              355863.89727940742,
              5007544.4729576008
            ],
            [
              355861.2048508352,
              5007546.5440673074
            ],
            [
              355858.72026147722,
              5007548.9144684663
            ],
            [
              355856.34053156059,
              5007551.4850477502
            ],
            [
              355853.33714774839,
              5007554.9285999751
            ],
            [
              355849.1731557523,
              5007559.4523344263
            ],
            [
              355846.60055040166,
              5007562.5317508029
            ],
            [
              355845.32179621252,
              5007564.4755397411
            ],
            [
              355844.14790082414,
              5007566.6194712454
            ],
            [
              355842.28689548437,
              5007569.8877457175
            ],
            [
              355840.52893687302,
              5007573.2551678671
            ],
            [
              355839.57791085955,
              5007576.5065180175
            ],
            [
              355839.20349964109,
              5007578.130330517
            ],
            [
              355838.60433941416,
              5007578.5456596762
            ],
            [
              355837.30303982366,
              5007579.2772401944
            ],
            [
              355833.99078522582,
              5007580.6525357263
            ],
            [
              355832.98527459375,
              5007580.9743938474
            ],
            [
              355832.1782750932,
              5007581.0904315878
            ],
            [
              355830.86194539123,
              5007581.0138849458
            ],
            [
              355829.63544374052,
              5007580.3293181453
            ],
            [
              355828.81529315212,
              5007579.7382579213
            ],
            [
              355828.20486083796,
              5007579.5475183558
            ],
            [
              355827.18619844917,
              5007579.162242976
            ],
            [
              355826.27621913451,
              5007579.1791673787
            ],
            [
              355824.75956485438,
              5007579.2073751222
            ],
            [
              355820.77643701487,
              5007579.5225809561
            ],
            [
              355819.76716898655,
              5007579.6424161689
            ],
            [
              355818.65867785009,
              5007579.865125427
            ],
            [
              355817.45096426515,
              5007580.190744265
            ],
            [
              355816.0448057857,
              5007580.7221822692
            ],
            [
              355814.73786908545,
              5007581.1506751589
            ],
            [
              355813.4252966746,
              5007581.2761514364
            ],
            [
              355812.10520888277,
              5007580.9975463385
            ],
            [
              355811.39179607294,
              5007580.7076578839
            ],
            [
              355810.47987145523,
              5007580.623554294
            ],
            [
              355809.56801374839,
              5007580.5394850038
            ],
            [
              355808.55498766951,
              5007580.4572617663
            ],
            [
              355807.64688731573,
              5007580.5752153993
            ],
            [
              355807.14882849256,
              5007580.9886642443
            ],
            [
              355806.24260714569,
              5007581.2076471159
            ],
            [
              355804.93003404111,
              5007581.3330878625
            ],
            [
              355804.11921152472,
              5007581.2471394511
            ],
            [
              355803.60612260562,
              5007580.8524610568
            ],
            [
              355802.99951385666,
              5007580.863743173
            ],
            [
              355801.98836676765,
              5007580.8825491657
            ],
            [
              355801.18512521317,
              5007581.2006453807
            ],
            [
              355800.38369641389,
              5007581.6197720626
            ],
            [
              355799.79393051192,
              5007582.5402119011
            ],
            [
              355799.50565623055,
              5007583.3539802181
            ],
            [
              355799.11245688051,
              5007583.9676071908
            ],
            [
              355798.41031678149,
              5007584.2838230608
            ],
            [
              355797.60519621166,
              5007584.5008900538
            ],
            [
              355796.69139320654,
              5007584.3157927711
            ],
            [
              355795.66716060083,
              5007583.6274639741
            ],
            [
              355794.64661976142,
              5007583.1411948726
            ],
            [
              355794.03430837515,
              5007582.8494260609
            ],
            [
              355793.124328973,
              5007582.866350472
            ],
            [
              355792.11506086489,
              5007582.9861857034
            ],
            [
              355791.3174556591,
              5007583.6073341053
            ],
            [
              355790.1304084537,
              5007585.0441324338
            ],
            [
              355789.34407599049,
              5007586.2713851845
            ],
            [
              355789.05385710805,
              5007586.9841610622
            ],
            [
              355788.86856330308,
              5007587.8970782859
            ],
            [
              355788.27691838628,
              5007588.7164889304
            ],
            [
              355786.78656680271,
              5007590.1589283552
            ],
            [
              355784.68195920554,
              5007591.2086065151
            ],
            [
              355783.28143632511,
              5007592.0430612555
            ],
            [
              355782.28162818652,
              5007592.6679704059
            ],
            [
              355781.18816772144,
              5007593.6988425637
            ],
            [
              355779.26171524316,
              5007595.5862831594
            ],
            [
              355778.36676659196,
              5007596.411370445
            ],
            [
              355777.76384891453,
              5007596.6246767659
            ],
            [
              355776.75645911938,
              5007596.8455057489
            ],
            [
              355776.04868260509,
              5007596.858669484
            ],
            [
              355775.3315123397,
              5007596.3667580923
            ],
            [
              355774.41213914216,
              5007595.8786074119
            ],
            [
              355773.49833540805,
              5007595.6934746085
            ],
            [
              355772.18012646452,
              5007595.5158987734
            ],
            [
              355770.76457341545,
              5007595.54222624
            ],
            [
              355769.65420377848,
              5007595.6639418732
            ],
            [
              355768.44085365586,
              5007595.6865086248
            ],
            [
              355767.5308741866,
              5007595.7034330741
            ],
            [
              355766.62465208309,
              5007595.9223804716
            ],
            [
              355765.01622356899,
              5007596.457545029
            ],
            [
              355760.10305521003,
              5007598.7720870338
            ],
            [
              355757.09974075021,
              5007600.4447941892
            ],
            [
              355753.79312119301,
              5007602.1231069155
            ],
            [
              355750.2918147326,
              5007604.2092973841
            ],
            [
              355746.18564525089,
              5007606.4077662313
            ],
            [
              355733.72440088779,
              5007613.6493255673
            ],
            [
              355730.72672200896,
              5007615.6250496665
            ],
            [
              355727.3377890173,
              5007618.3154289648
            ],
            [
              355725.1527462533,
              5007620.4781675013
            ],
            [
              355723.36096910917,
              5007622.0272776792
            ],
            [
              355722.07469937298,
              5007623.5669860449
            ],
            [
              355721.08609767695,
              5007624.7980010919
            ],
            [
              355720.39898831857,
              5007625.9223800935
            ],
            [
              355719.03604111046,
              5007628.7771715075
            ],
            [
              355718.15417732025,
              5007630.3093584459
            ],
            [
              355717.07011056721,
              5007631.845306132
            ],
            [
              355715.78008346143,
              5007633.1829915838
            ],
            [
              355714.18863026908,
              5007634.6273118751
            ],
            [
              355712.29192800348,
              5007635.9762807963
            ],
            [
              355711.09924432699,
              5007637.1100273933
            ],
            [
              355709.51899771753,
              5007639.1604534397
            ],
            [
              355707.26861730614,
              5007643.2444140799
            ],
            [
              355706.27706789813,
              5007645.7494334131
            ],
            [
              355705.39520415117,
              5007647.2816204512
            ],
            [
              355703.62027006067,
              5007649.7398888301
            ],
            [
              355702.73847257748,
              5007651.2720746594
            ],
            [
              355702.25168721337,
              5007652.2916637659
            ],
            [
              355701.28945495433,
              5007654.9369097119
            ],
            [
              355700.22041919635,
              5007657.2810205687
            ],
            [
              355699.14010991302,
              5007659.0189914061
            ],
            [
              355697.96433558711,
              5007661.0618948815
            ],
            [
              355696.08830006974,
              5007663.5220437618
            ],
            [
              355694.30585056951,
              5007665.5762307718
            ],
            [
              355692.02540910261,
              5007668.0439012153
            ],
            [
              355690.63985074963,
              5007669.6865207711
            ],
            [
              355689.65131538193,
              5007670.9175348319
            ],
            [
              355688.67210762593,
              5007672.653625383
            ],
            [
              355681.5862824567,
              5007684.4925108915
            ],
            [
              355679.12799603731,
              5007688.2771463552
            ],
            [
              355678.4427651866,
              5007689.5025193542
            ],
            [
              355677.6695185664,
              5007691.4369077804
            ],
            [
              355677.18467792211,
              5007692.5574895144
            ],
            [
              355676.69977168861,
              5007693.6781080216
            ],
            [
              355675.91907582775,
              5007695.2084136978
            ],
            [
              355674.63468462619,
              5007696.8491162797
            ],
            [
              355673.74718455289,
              5007698.0782513209
            ],
            [
              355672.1538530559,
              5007699.4215783542
            ],
            [
              355670.26090824598,
              5007700.9725708012
            ],
            [
              355669.56621726166,
              5007701.6928698085
            ],
            [
              355668.88098644017,
              5007702.9182428718
            ],
            [
              355668.19575561973,
              5007704.1436159313
            ],
            [
              355667.51991871645,
              5007705.8740642834
            ],
            [
              355666.8534757357,
              5007708.1095879236
            ],
            [
              355666.18139694614,
              5007710.0420948304
            ],
            [
              355665.39694369707,
              5007711.3703775816
            ],
            [
              355664.30724065559,
              5007712.6032734774
            ],
            [
              355662.72323684586,
              5007714.4516771361
            ],
            [
              355660.82841300097,
              5007715.9016404385
            ],
            [
              355658.71000788576,
              5007717.7239373783
            ],
            [
              355655.41278255667,
              5007719.9073625486
            ],
            [
              355654.01970875921,
              5007721.1459009238
            ],
            [
              355652.04069287999,
              5007723.5069015631
            ],
            [
              355649.37644623098,
              5007727.0932754073
            ],
            [
              355646.62982040661,
              5007731.6917170985
            ],
            [
              355645.65443719976,
              5007733.6298653632
            ],
            [
              355644.89065096626,
              5007736.0693281507
            ],
            [
              355644.22790000081,
              5007738.5069117853
            ],
            [
              355643.89301051194,
              5007742.2520546895
            ],
            [
              355643.53738846938,
              5007744.88605446
            ],
            [
              355643.1704927524,
              5007746.913914104
            ],
            [
              355642.69880433549,
              5007748.7416299041
            ],
            [
              355642.30282689113,
              5007749.836599404
            ],
            [
              355641.51837374229,
              5007751.1648823358
            ],
            [
              355640.63275217969,
              5007752.4950113753
            ],
            [
              355640.25089224894,
              5007753.714742193
            ],
            [
              355639.89151216642,
              5007756.1466834443
            ],
            [
              355639.82986634184,
              5007758.269929952
            ],
            [
              355639.7400378912,
              5007758.8779149568
            ],
            [
              355639.13899913518,
              5007759.192250927
            ],
            [
              355638.03050753538,
              5007759.4149608817
            ],
            [
              355636.31158317951,
              5007759.446931486
            ],
            [
              355635.30425999028,
              5007759.6677953396
            ],
            [
              355634.1995258446,
              5007760.0925283264
            ],
            [
              355632.3982886122,
              5007761.13656554
            ],
            [
              355630.6083244349,
              5007762.7867073799
            ],
            [
              355628.21732082532,
              5007764.7511496935
            ],
            [
              355624.92385363602,
              5007767.1366340751
            ],
            [
              355620.71852685837,
              5007769.4379785983
            ],
            [
              355617.00743518682,
              5007771.1238520239
            ],
            [
              355610.83869109274,
              5007773.5069323275
            ],
            [
              355608.63492613018,
              5007774.6594850793
            ],
            [
              355606.83744628361,
              5007775.905545488
            ],
            [
              355604.35285668325,
              5007778.2759500602
            ],
            [
              355603.15829469729,
              5007779.308703755
            ],
            [
              355602.05919767963,
              5007780.0365247112
            ],
            [
              355600.55381552648,
              5007780.6708380328
            ],
            [
              355597.94182072271,
              5007781.6288907062
            ],
            [
              355595.03584912349,
              5007783.0976612484
            ],
            [
              355591.1263116842,
              5007784.9893188253
            ],
            [
              355586.62700849853,
              5007787.8014172073
            ],
            [
              355582.43288825377,
              5007790.7088681702
            ],
            [
              355578.15269772435,
              5007794.4263629233
            ],
            [
              355572.24067915668,
              5007799.9438105561
            ],
            [
              355570.35712897108,
              5007801.9999149693
            ],
            [
              355569.17196099943,
              5007803.5377442716
            ],
            [
              355568.08789455629,
              5007805.0736931646
            ],
            [
              355566.61257356993,
              5007807.3242622428
            ],
            [
              355563.18447502429,
              5007813.3501023967
            ],
            [
              355561.71291223069,
              5007815.8027301431
            ],
            [
              355560.43415842514,
              5007817.7465216247
            ],
            [
              355558.55248670216,
              5007819.9036199711
            ],
            [
              355556.08104931103,
              5007822.9811592419
            ],
            [
              355552.79885435442,
              5007825.9727138951
            ],
            [
              355558.210439238,
              5007832.607379782
            ],
            [
              355570.39222660294,
              5007847.0065722885
            ],
            [
              355588.34287733689,
              5007863.1641911957
            ],
            [
              355604.54787798546,
              5007878.2995778937
            ],
            [
              355619.58077468793,
              5007892.3061992396
            ],
            [
              355639.03494016308,
              5007910.3030891567
            ],
            [
              355633.13114028133,
              5007916.7806186695
            ],
            [
              355638.5387800507,
              5007920.1568676131
            ],
            [
              355632.83568949561,
              5007931.912862435
            ],
            [
              355627.05104091874,
              5007950.4842836512
            ],
            [
              355622.29408621532,
              5007959.5741067575
            ],
            [
              355621.31804588524,
              5007963.5473753484
            ],
            [
              355617.94646829361,
              5007976.4385380913
            ],
            [
              355618.12480218121,
              5007986.0264657056
            ],
            [
              355612.25078895438,
              5008007.3732006233
            ],
            [
              355605.95106840582,
              5008032.6105138641
            ],
            [
              355603.53801557462,
              5008042.0810408853
            ],
            [
              355598.85713955836,
              5008061.5964796636
            ],
            [
              355596.80355931004,
              5008070.2422217689
            ],
            [
              355591.19540720637,
              5008093.6752731837
            ],
            [
              355600.11535287212,
              5008101.1885164753
            ],
            [
              355618.93765158643,
              5008097.8223828776
            ],
            [
              355638.30271053553,
              5008094.1449507065
            ],
            [
              355654.70298131759,
              5008087.5748408791
            ],
            [
              355675.0241010093,
              5008073.4784282874
            ],
            [
              355686.93323135423,
              5008060.9720642269
            ],
            [
              355699.53021202108,
              5008046.955897551
            ],
            [
              355710.93418804521,
              5008031.3053263957
            ],
            [
              355711.78607492795,
              5008031.6276780833
            ],
            [
              355724.87470569473,
              5008016.7568769529
            ],
            [
              355727.03554311482,
              5008014.6874723099
            ],
            [
              355753.14075319044,
              5007985.6235747021
            ],
            [
              355757.14714353479,
              5007984.0288729509
            ],
            [
              355761.75207015994,
              5007987.6131961746
            ],
            [
              355772.69494472985,
              5007996.1307690376
            ],
            [
              355778.10212822858,
              5007980.6945855208
            ],
            [
              355785.58852381114,
              5007983.4369385447
            ],
            [
              355790.24578092567,
              5007980.923708342
            ],
            [
              355793.86937420914,
              5007979.946324653
            ],
            [
              355822.39440950722,
              5007943.2844717028
            ],
            [
              355829.29206101206,
              5007950.9162058532
            ],
            [
              355830.85608129279,
              5007948.2668251488
            ],
            [
              355846.54040959472,
              5007962.0842847954
            ],
            [
              355845.75087616284,
              5007963.0059922114
            ],
            [
              355848.82536392385,
              5007965.6698412728
            ],
            [
              355854.25285059743,
              5007969.2183822338
            ],
            [
              355874.71121278696,
              5007943.4434083067
            ],
            [
              355888.60398235242,
              5007925.9402610064
            ],
            [
              355902.17029024503,
              5007908.8484162437
            ],
            [
              355916.63475010201,
              5007890.6250146907
            ],
            [
              355944.41728020139,
              5007855.2617930034
            ],
            [
              355948.33326071611,
              5007859.8120682938
            ],
            [
              355946.87416087394,
              5007861.3672735244
            ],
            [
              355960.78299521533,
              5007878.08894586
            ],
            [
              355965.60839775804,
              5007873.3831443042
            ],
            [
              355969.87265863217,
              5007880.7680739062
            ],
            [
              355976.880885116,
              5007877.2002460724
            ],
            [
              355961.88349092129,
              5007852.7293152818
            ],
            [
              355974.46253773494,
              5007842.8707282012
            ],
            [
              355973.15250664059,
              5007834.4934196956
            ],
            [
              355986.61004150455,
              5007838.0052401265
            ],
            [
              355992.44364886184,
              5007838.1534257429
            ],
            [
              356005.66601271153,
              5007838.3949893517
            ],
            [
              356011.56000432547,
              5007835.3602519687
            ],
            [
              356012.09250931925,
              5007833.2175456416
            ],
            [
              356011.97419319046,
              5007831.4036342129
            ],
            [
              356018.09096649807,
              5007824.6107876627
            ],
            [
              356019.6936183112,
              5007824.2396987146
            ],
            [
              356030.19957197685,
              5007812.6495114863
            ],
            [
              356002.61296673794,
              5007782.2283463525
            ],
            [
              356008.26673725364,
              5007774.9702699548
            ],
            [
              356042.1569216543,
              5007730.3098077755
            ],
            [
              356037.5872687794,
              5007725.7731389385
            ],
            [
              356031.60945886147,
              5007723.4670336638
            ],
            [
              356024.84122663125,
              5007713.6571925022
            ],
            [
              356021.65542562137,
              5007716.076183578
            ],
            [
              355996.80716668064,
              5007739.3428099258
            ],
            [
              355999.77023263631,
              5007744.0025669858
            ],
            [
              355979.42650344392,
              5007763.7003778741
            ],
            [
              355968.23908569227,
              5007749.8788502654
            ],
            [
              355990.90027815936,
              5007731.0579302814
            ],
            [
              355985.58340331627,
              5007723.5670379922
            ],
            [
              355998.03666280786,
              5007711.7680752017
            ],
            [
              355985.42627232574,
              5007695.6730947793
            ],
            [
              355983.77756486367,
              5007693.6338067306
            ],
            [
              355985.12784570031,
              5007691.9987372365
            ],
            [
              356000.15830619802,
              5007678.5643651588
            ],
            [
              356002.57416300214,
              5007689.0825692192
            ],
            [
              356010.68540275999,
              5007694.5070504537
            ],
            [
              356012.0148048228,
              5007698.5243484955
            ],
            [
              356017.58499903022,
              5007710.7084695743
            ],
            [
              356038.01496102224,
              5007689.6929456303
            ],
            [
              356042.00333868858,
              5007690.7112414436
            ],
            [
              356046.92979169899,
              5007689.5846219538
            ],
            [
              356051.53387611813,
              5007677.3093760321
            ],
            [
              356047.61528746365,
              5007675.6243145708
            ],
            [
              356056.83285705053,
              5007663.9532251079
            ],
            [
              356071.61361081927,
              5007674.6033990737
            ],
            [
              356078.47064189514,
              5007665.504481608
            ],
            [
              356080.27403784252,
              5007663.1114706751
            ],
            [
              356088.01794738433,
              5007669.969716398
            ],
            [
              356080.28036308102,
              5007679.7203906886
            ],
            [
              356076.5267418744,
              5007684.7014069688
            ],
            [
              356072.63872494671,
              5007689.8607651358
            ],
            [
              356059.19867187401,
              5007707.6955775078
            ],
            [
              356067.19381081825,
              5007714.6803679988
            ],
            [
              356056.8128531535,
              5007727.4815397374
            ],
            [
              356062.73862963455,
              5007726.1002177531
            ],
            [
              356075.2789564117,
              5007737.6042613313
            ],
            [
              356090.65387611871,
              5007752.2523450479
            ],
            [
              356095.00087001087,
              5007747.6122863302
            ],
            [
              356101.11492288654,
              5007743.7062312271
            ],
            [
              356121.93355132901,
              5007722.3298859503
            ],
            [
              356154.00898751261,
              5007744.0123930098
            ],
            [
              356164.08395742445,
              5007728.1927646575
            ],
            [
              356139.6309621039,
              5007708.6684693731
            ],
            [
              356174.98076319654,
              5007695.1930235038
            ],
            [
              356194.67987058545,
              5007721.3064611619
            ],
            [
              356200.29629492911,
              5007708.5247070324
            ],
            [
              356202.10052314511,
              5007700.614682341
            ],
            [
              356200.69755840296,
              5007685.7130488027
            ],
            [
              356204.42658796132,
              5007684.4434944373
            ],
            [
              356212.78475090925,
              5007691.3843387794
            ],
            [
              356217.63544925745,
              5007688.4657946089
            ],
            [
              356236.25363502739,
              5007668.7458382538
            ],
            [
              356243.59947252605,
              5007663.0468196254
            ],
            [
              356260.28214557812,
              5007657.2684371797
            ],
            [
              356266.36214201147,
              5007654.515578785
            ],
            [
              356277.72192848625,
              5007641.482510699
            ],
            [
              356281.5024175478,
              5007645.0772546362
            ],
            [
              356292.67885751353,
              5007632.330436592
            ],
            [
              356301.22527616686,
              5007640.4679111075
            ],
            [
              356310.48215634789,
              5007653.898425553
            ],
            [
              356302.70972874068,
              5007653.3808034221
            ],
            [
              356292.36828415981,
              5007659.0110114124
            ],
            [
              356284.17192287208,
              5007664.1263358844
            ],
            [
              356276.19636511034,
              5007670.8433360271
            ],
            [
              356258.74121826643,
              5007702.025439837
            ],
            [
              356252.51218617294,
              5007720.4724500924
            ],
            [
              356234.57057746238,
              5007727.5631839661
            ],
            [
              356230.56606317247,
              5007728.6314833034
            ],
            [
              356226.61414384155,
              5007730.9653320899
            ],
            [
              356222.90102524991,
              5007732.9806641769
            ],
            [
              356219.96898369718,
              5007734.5147121986
            ],
            [
              356219.25140532717,
              5007735.7301991675
            ],
            [
              356208.0793957854,
              5007736.9551694393
            ],
            [
              356205.98486952082,
              5007738.7510474622
            ],
            [
              356199.59777822945,
              5007733.5990107944
            ],
            [
              356194.04591870686,
              5007739.476358423
            ],
            [
              356190.4258832889,
              5007748.7907623714
            ],
            [
              356177.71289064392,
              5007756.7023261907
            ],
            [
              356175.22127659956,
              5007762.019499518
            ],
            [
              356171.21375462186,
              5007765.4229858173
            ],
            [
              356165.68072404538,
              5007776.3449986922
            ],
            [
              356165.61856798473,
              5007779.1719791526
            ],
            [
              356164.30689815222,
              5007783.265098827
            ],
            [
              356155.42440967046,
              5007803.1266311109
            ],
            [
              356152.3074000756,
              5007804.6641532276
            ],
            [
              356151.29897270276,
              5007810.1387009695
            ],
            [
              356147.6353946926,
              5007809.6792871021
            ],
            [
              356137.2963859064,
              5007796.0009389846
            ],
            [
              356132.82988174754,
              5007799.5979227573
            ],
            [
              356119.02627671656,
              5007788.6656687846
            ],
            [
              356111.67065648077,
              5007796.1076724129
            ],
            [
              356100.23594879388,
              5007788.1829011552
            ],
            [
              356098.01147174131,
              5007792.9402932655
            ],
            [
              356090.75177430297,
              5007800.5654733181
            ],
            [
              356085.77334676537,
              5007806.4837483745
            ],
            [
              356080.62021048245,
              5007812.960087182
            ],
            [
              356079.05190385162,
              5007828.1860339893
            ],
            [
              356087.5713495161,
              5007833.5758172981
            ],
            [
              356086.86444532516,
              5007840.3393461844
            ],
            [
              356084.20813687774,
              5007841.7758201733
            ],
            [
              356083.22863539384,
              5007843.8284006258
            ],
            [
              356077.97749912797,
              5007845.0357299289
            ],
            [
              356079.6417016635,
              5007846.2440217789
            ],
            [
              356072.19157543703,
              5007848.6018793695
            ],
            [
              356063.19145684567,
              5007852.1907072673
            ],
            [
              356056.99176646589,
              5007857.1145171765
            ],
            [
              356053.79425122024,
              5007859.3008317323
            ],
            [
              356047.07742388832,
              5007866.2685899707
            ],
            [
              356057.18504840886,
              5007877.4545466872
            ],
            [
              356054.97203086672,
              5007878.9251476564
            ],
            [
              356057.45233946812,
              5007882.7106961738
            ],
            [
              356042.2542150474,
              5007889.6618772885
            ],
            [
              356023.21462153265,
              5007908.2191592762
            ],
            [
              356020.88061847573,
              5007910.0939976666
            ],
            [
              355994.61524343787,
              5007923.1084465226
            ],
            [
              355998.2467243509,
              5007930.2044324931
            ],
            [
              355989.14093758835,
              5007939.9259394305
            ],
            [
              355972.93122678337,
              5007945.8145588096
            ],
            [
              355962.8855591637,
              5007953.4809291726
            ],
            [
              355976.96808025189,
              5007973.1793732587
            ],
            [
              355988.52048984048,
              5007983.5079470072
            ],
            [
              355984.49517141859,
              5007990.0710692499
            ],
            [
              355989.14541778411,
              5007992.8682464911
            ],
            [
              355991.34539238625,
              5007994.8098367639
            ],
            [
              355996.26402168075,
              5007992.6457318347
            ],
            [
              356026.57904382539,
              5007991.9313866394
            ],
            [
              356037.09981712821,
              5008000.1163852764
            ],
            [
              356027.15607166418,
              5008008.4116600072
            ],
            [
              356035.67423974833,
              5008020.4187058322
            ],
            [
              356045.68777361483,
              5008030.4153951732
            ],
            [
              356055.14906675258,
              5008020.4169133725
            ],
            [
              356060.47960377799,
              5008013.9818157637
            ],
            [
              356074.18098471191,
              5007994.6348069701
            ],
            [
              356084.78958111646,
              5007991.8973340252
            ],
            [
              356101.16882059432,
              5007988.1454736069
            ],
            [
              356101.49019925314,
              5007984.0293877367
            ],
            [
              356114.31860055315,
              5007981.9345973087
            ],
            [
              356118.40723340499,
              5007980.6652975501
            ],
            [
              356132.34747668525,
              5007970.6248296862
            ],
            [
              356138.84922001429,
              5007979.2265481595
            ],
            [
              356143.320767014,
              5007988.1568342149
            ],
            [
              356142.40205563529,
              5007988.8135780254
            ],
            [
              356143.70173139614,
              5007999.2565967934
            ],
            [
              356160.63657899498,
              5007986.9624747615
            ],
            [
              356174.67578139936,
              5007981.3398142001
            ],
            [
              356193.14223164466,
              5007970.0639191661
            ],
            [
              356196.88227662223,
              5007966.1849642145
            ],
            [
              356208.28393438424,
              5007977.4294538293
            ],
            [
              356215.22440617083,
              5007969.8892549155
            ],
            [
              356226.81061812054,
              5007957.3018775349
            ],
            [
              356255.99004663725,
              5007925.3266185503
            ],
            [
              356258.43976706517,
              5007922.6036486281
            ],
            [
              356250.4009036732,
              5007915.8880709177
            ],
            [
              356246.1449547924,
              5007910.6110640122
            ],
            [
              356263.51503377379,
              5007895.697127739
            ],
            [
              356243.52609487559,
              5007873.3195045935
            ],
            [
              356241.4988649236,
              5007871.0500198854
            ],
            [
              356240.76745432487,
              5007869.7692647958
            ],
            [
              356237.76816798543,
              5007867.4712064788
            ],
            [
              356238.34598115558,
              5007863.7665619878
            ],
            [
              356239.74484263215,
              5007859.4925568821
            ],
            [
              356240.86452399899,
              5007850.1446560239
            ],
            [
              356246.71185835201,
              5007841.6323061967
            ],
            [
              356237.66776109359,
              5007827.3020090489
            ],
            [
              356254.11069133139,
              5007796.7446372565
            ],
            [
              356268.9719073319,
              5007810.8743917132
            ],
            [
              356276.58244228613,
              5007797.8042176636
            ],
            [
              356289.97266928933,
              5007787.4893089021
            ],
            [
              356298.30662921787,
              5007798.4159937846
            ],
            [
              356314.25565083692,
              5007811.4173679175
            ],
            [
              356326.55419627373,
              5007798.8720352696
            ],
            [
              356343.0469683308,
              5007786.467794708
            ],
            [
              356367.94311381073,
              5007773.8149108719
            ],
            [
              356370.41977040685,
              5007772.8453647429
            ],
            [
              356373.73591559712,
              5007792.1766162897
            ],
            [
              356374.67744381895,
              5007803.0560960518
            ],
            [
              356416.91294854414,
              5007770.9581142543
            ],
            [
              356423.79452643014,
              5007778.3102614172
            ],
            [
              356429.62160046463,
              5007773.6768530281
            ],
            [
              356434.39566510991,
              5007777.0049098544
            ],
            [
              356442.82404538739,
              5007768.1675160136
            ],
            [
              356447.37765387562,
              5007774.5471308744
            ],
            [
              356452.9568504878,
              5007778.875516125
            ],
            [
              356446.08317811484,
              5007786.8528926466
            ],
            [
              356447.41576802247,
              5007788.9520832049
            ],
            [
              356438.59563484188,
              5007796.5962700238
            ],
            [
              356445.87682740827,
              5007805.3536313316
            ],
            [
              356449.86183179007,
              5007801.0315615078
            ],
            [
              356454.76598649594,
              5007806.3888256773
            ],
            [
              356461.02058096044,
              5007799.9005345628
            ],
            [
              356463.63093584409,
              5007802.0930984402
            ],
            [
              356480.09328922798,
              5007786.5113161607
            ],
            [
              356488.24698967068,
              5007779.2906559678
            ],
            [
              356492.02852858236,
              5007776.5158038652
            ],
            [
              356510.37206558179,
              5007764.4544529058
            ],
            [
              356519.94502201554,
              5007758.4681247938
            ],
            [
              356524.89679422061,
              5007755.2608452691
            ],
            [
              356550.98962679558,
              5007739.2541116392
            ],
            [
              356566.75793707365,
              5007725.5203176988
            ],
            [
              356564.41663387389,
              5007724.6543765636
            ],
            [
              356556.30682740512,
              5007711.1073258026
            ],
            [
              356547.73198167206,
              5007715.030829167
            ],
            [
              356540.19611955859,
              5007704.1337894592
            ],
            [
              356556.38027353963,
              5007691.0787291499
            ],
            [
              356567.29857200495,
              5007684.1346122101
            ],
            [
              356563.70050316403,
              5007679.7657189295
            ],
            [
              356580.44870083354,
              5007666.7447319934
            ],
            [
              356592.32008368353,
              5007652.583117675
            ],
            [
              356584.197340324,
              5007645.0738370875
            ],
            [
              356577.73069738591,
              5007633.507539128
            ],
            [
              356569.63501631276,
              5007627.5255322773
            ],
            [
              356564.84441274632,
              5007624.6712436751
            ],
            [
              356581.16886721988,
              5007593.653879487
            ],
            [
              356591.15156064648,
              5007579.6470030062
            ],
            [
              356600.62802113197,
              5007579.7267275294
            ],
            [
              356608.327814024,
              5007594.4284965312
            ],
            [
              356613.13025771413,
              5007597.9224504586
            ],
            [
              356629.37990323512,
              5007580.9294762649
            ],
            [
              356643.38471203315,
              5007562.8806119878
            ],
            [
              356655.03424174181,
              5007555.881345978
            ],
            [
              356661.28277108475,
              5007561.3959656386
            ],
            [
              356667.67729353305,
              5007554.1105242437
            ],
            [
              356674.36590755306,
              5007548.867141285
            ],
            [
              356680.74366396561,
              5007545.1159335449
            ],
            [
              356676.40081256971,
              5007538.7980152247
            ],
            [
              356681.82613230072,
              5007534.4739873623
            ],
            [
              356687.90329533245,
              5007530.7776887463
            ],
            [
              356693.10119877098,
              5007527.9935694737
            ],
            [
              356695.05761776405,
              5007526.2957042567
            ],
            [
              356701.80428805662,
              5007534.9163668519
            ],
            [
              356710.64852991758,
              5007528.3070677249
            ],
            [
              356723.14882206853,
              5007518.8604765292
            ],
            [
              356735.79134524579,
              5007510.1790635409
            ],
            [
              356729.36013817129,
              5007501.7244221279
            ],
            [
              356745.83971849055,
              5007485.9330737749
            ],
            [
              356734.13674571388,
              5007474.0430843653
            ],
            [
              356751.26584689942,
              5007458.7515394287
            ],
            [
              356762.34628111223,
              5007448.6914735846
            ],
            [
              356760.90976827726,
              5007447.1825118335
            ],
            [
              356782.50482260744,
              5007425.4340534238
            ],
            [
              356777.48057720618,
              5007423.5052662184
            ],
            [
              356793.76280094747,
              5007414.9248817228
            ],
            [
              356804.74870920903,
              5007403.1562921619
            ],
            [
              356812.17014429771,
              5007406.2707008701
            ],
            [
              356818.6319263053,
              5007406.6374538075
            ],
            [
              356843.00497702463,
              5007382.7363603879
            ],
            [
              356855.79934816936,
              5007370.0701700738
            ],
            [
              356857.03840036684,
              5007368.4475224055
            ],
            [
              356867.46353832603,
              5007381.4073137129
            ],
            [
              356872.8901721398,
              5007375.9968263935
            ],
            [
              356878.49467061617,
              5007369.5626804205
            ],
            [
              356883.84892146528,
              5007362.767991242
            ],
            [
              356884.02227962069,
              5007358.9911657879
            ],
            [
              356880.82266244723,
              5007349.744326585
            ],
            [
              356887.10834135691,
              5007347.1928464286
            ],
            [
              356902.55418570613,
              5007339.4801152097
            ],
            [
              356899.6317504567,
              5007332.961088758
            ],
            [
              356897.20539882651,
              5007326.9197660862
            ],
            [
              356906.42392632336,
              5007318.3490264742
            ],
            [
              356922.34947483847,
              5007303.6888006879
            ],
            [
              356924.65763831901,
              5007301.5494209919
            ],
            [
              356945.30384164531,
              5007278.7672717087
            ],
            [
              356968.31007676799,
              5007255.0941485083
            ],
            [
              356979.2702368694,
              5007243.1043909211
            ],
            [
              356986.02786062093,
              5007236.1928470507
            ],
            [
              356988.26393710886,
              5007234.5832065186
            ],
            [
              356991.39263300266,
              5007238.7936696997
            ],
            [
              356991.39263302274,
              5007238.7936696792
            ],
            [
              357007.91604942578,
              5007223.8437488349
            ],
            [
              357003.86338185548,
              5007215.7022747099
            ],
            [
              357023.0347916694,
              5007205.1791329226
            ],
            [
              357028.45907014055,
              5007204.6604352677
            ],
            [
              357052.27780156687,
              5007188.0213729208
            ],
            [
              357070.98910819192,
              5007174.4334237082
            ],
            [
              357083.08169951208,
              5007165.2949878564
            ],
            [
              357105.65160114266,
              5007156.785179629
            ],
            [
              357105.65160114161,
              5007156.7851796299
            ],
            [
              357118.78150667268,
              5007151.0234973421
            ],
            [
              357118.7815066693,
              5007151.0234973468
            ],
            [
              357118.78150667093,
              5007151.0234973421
            ],
            [
              357118.78150666924,
              5007151.0234973431
            ],
            [
              357118.78150667023,
              5007151.0234973431
            ],
            [
              357118.7815066672,
              5007151.0234973431
            ],
            [
              357118.78150665638,
              5007151.0234973505
            ],
            [
              357118.78150667268,
              5007151.0234973421
            ],
            [
              357130.42066163587,
              5007146.5674392087
            ],
            [
              357130.42066163395,
              5007146.5674392115
            ],
            [
              357127.2241034098,
              5007140.1683684671
            ],
            [
              357118.2680042354,
              5007116.0380446911
            ],
            [
              357121.18521180429,
              5007114.8340934431
            ],
            [
              357138.23122788087,
              5007105.6195472945
            ],
            [
              357150.31450195232,
              5007093.5668474222
            ],
            [
              357153.91356978961,
              5007089.83321808
            ],
            [
              357155.09558199241,
              5007088.6061328612
            ],
            [
              357150.22566589277,
              5007077.4060132736
            ],
            [
              357144.69711180782,
              5007064.6547495583
            ],
            [
              357139.09892078815,
              5007050.8057995876
            ],
            [
              357128.30498004996,
              5007045.5826478908
            ],
            [
              357108.49351485638,
              5007036.6069578528
            ],
            [
              357096.75811214198,
              5007041.9112475673
            ],
            [
              357089.38659783866,
              5007046.4247077629
            ],
            [
              357078.03910130652,
              5007053.4959697044
            ],
            [
              357070.81491406157,
              5007065.9314689077
            ],
            [
              357059.38076197979,
              5007087.4345167205
            ],
            [
              357056.77136933111,
              5007094.1517185979
            ],
            [
              357047.62406075851,
              5007111.3541934388
            ],
            [
              357041.12505636498,
              5007124.6041382048
            ],
            [
              357040.00802295754,
              5007128.173319011
            ],
            [
              357029.73987151083,
              5007149.2273631366
            ],
            [
              357028.5067487784,
              5007152.9169939831
            ],
            [
              357022.11790059024,
              5007156.9027416194
            ],
            [
              357017.27683915582,
              5007160.4696166562
            ],
            [
              357010.66465127672,
              5007165.6361708185
            ],
            [
              356984.0321443014,
              5007191.7983838804
            ],
            [
              356975.12888946838,
              5007200.4737868654
            ],
            [
              356966.05254367832,
              5007206.6001548758
            ],
            [
              356946.49053056521,
              5007223.6296231588
            ],
            [
              356950.96386144048,
              5007229.474644349
            ],
            [
              356928.60500513698,
              5007249.0711821849
            ],
            [
              356920.65748405521,
              5007256.3265185682
            ],
            [
              356910.62884126377,
              5007266.8095648075
            ],
            [
              356892.08090102818,
              5007286.1200385401
            ],
            [
              356881.08609559451,
              5007297.5545874061
            ],
            [
              356870.40290066291,
              5007308.5818748213
            ],
            [
              356853.62296301662,
              5007325.747889339
            ],
            [
              356846.41299977811,
              5007333.1142375255
            ],
            [
              356843.92908339779,
              5007330.4100474529
            ],
            [
              356829.52586176776,
              5007344.3592403876
            ],
            [
              356814.31007490249,
              5007359.7820816627
            ],
            [
              356801.57265825599,
              5007372.4994136477
            ],
            [
              356777.76828254526,
              5007395.3825785955
            ],
            [
              356763.10392190103,
              5007409.7772611743
            ],
            [
              356761.67732649733,
              5007410.7666499242
            ],
            [
              356745.92823554802,
              5007427.2676865067
            ],
            [
              356732.66176863218,
              5007441.569099132
            ],
            [
              356721.4292127543,
              5007453.6751311999
            ],
            [
              356712.67263712356,
              5007463.1126441928
            ],
            [
              356709.82684889814,
              5007466.1797294859
            ],
            [
              356697.61295708764,
              5007444.8748400705
            ],
            [
              356690.16945444548,
              5007450.7714051399
            ],
            [
              356686.05496400601,
              5007440.6109472206
            ],
            [
              356683.89897546853,
              5007432.7116545653
            ],
            [
              356669.75684379571,
              5007436.5715282001
            ],
            [
              356665.01728133165,
              5007440.984531871
            ],
            [
              356653.75790509611,
              5007453.7977630682
            ],
            [
              356652.06213567092,
              5007455.6828160342
            ],
            [
              356643.58225011878,
              5007473.4091620045
            ],
            [
              356639.9913065469,
              5007473.1052381126
            ],
            [
              356636.33833499392,
              5007469.4661809905
            ],
            [
              356638.63133169478,
              5007466.4579248317
            ],
            [
              356626.57157656812,
              5007456.1790210688
            ],
            [
              356620.29572012008,
              5007451.1059395969
            ],
            [
              356610.73670739587,
              5007462.2811847655
            ],
            [
              356606.49197588378,
              5007473.357560779
            ],
            [
              356598.76840651553,
              5007483.5101338811
            ],
            [
              356601.9131683929,
              5007488.0782398684
            ],
            [
              356576.5307724328,
              5007505.9732337436
            ],
            [
              356569.89679856464,
              5007510.4291107552
            ],
            [
              356554.24787131639,
              5007493.2972004656
            ],
            [
              356548.17694291117,
              5007505.8903815281
            ],
            [
              356544.51018556696,
              5007501.5101609342
            ],
            [
              356542.7362493758,
              5007499.1953697065
            ],
            [
              356534.11313219351,
              5007507.5111931898
            ],
            [
              356532.16328648274,
              5007509.0302628716
            ],
            [
              356521.02104312187,
              5007494.9037036467
            ],
            [
              356510.67682775774,
              5007503.7457729075
            ],
            [
              356523.66600180627,
              5007517.4672725471
            ],
            [
              356518.92879154271,
              5007522.0037943507
            ],
            [
              356531.7704511272,
              5007534.4142231774
            ],
            [
              356530.92127490824,
              5007535.5385898333
            ],
            [
              356540.90070611349,
              5007546.9682807112
            ],
            [
              356524.59260972281,
              5007560.8639562037
            ],
            [
              356520.73793354182,
              5007553.027384025
            ],
            [
              356511.19959768007,
              5007565.3143467261
            ],
            [
              356518.12558090262,
              5007572.1052926881
            ],
            [
              356517.0403915905,
              5007573.1547711315
            ],
            [
              356533.15836459078,
              5007588.9187094113
            ],
            [
              356547.05702578468,
              5007599.4561258601
            ],
            [
              356550.78977394983,
              5007602.6779180532
            ],
            [
              356523.99575581442,
              5007632.9237584705
            ],
            [
              356517.89792067179,
              5007639.8991842307
            ],
            [
              356512.89385613473,
              5007629.6122521637
            ],
            [
              356502.70128684858,
              5007619.5484403474
            ],
            [
              356495.66494026693,
              5007615.7551517105
            ],
            [
              356492.9661667248,
              5007613.6534187319
            ],
            [
              356485.0035746012,
              5007607.7254299931
            ],
            [
              356473.82076593273,
              5007619.3260557977
            ],
            [
              356451.26787152467,
              5007594.9083891669
            ],
            [
              356441.55398767797,
              5007590.1522284765
            ],
            [
              356442.10324108618,
              5007583.4343070658
            ],
            [
              356453.96642674686,
              5007574.6273919968
            ],
            [
              356467.87278826296,
              5007560.201386855
            ],
            [
              356474.61531126598,
              5007553.2069916297
            ],
            [
              356463.32032807329,
              5007538.6773022376
            ],
            [
              356452.68301286985,
              5007528.7146987179
            ],
            [
              356448.86050086567,
              5007523.3478160379
            ],
            [
              356448.87097363145,
              5007516.5561600188
            ],
            [
              356434.56230333116,
              5007501.6532060467
            ],
            [
              356422.8768206567,
              5007512.3171448521
            ],
            [
              356412.73648436478,
              5007521.3782462031
            ],
            [
              356402.61719424854,
              5007534.3154416811
            ],
            [
              356399.91368798265,
              5007535.2243473185
            ],
            [
              356390.8720690629,
              5007526.376951701
            ],
            [
              356391.85266733443,
              5007525.213859532
            ],
            [
              356379.52974748594,
              5007516.9998849919
            ],
            [
              356379.78139596822,
              5007515.134840765
            ],
            [
              356393.87774798943,
              5007503.379580711
            ],
            [
              356384.84621759324,
              5007487.3767379709
            ],
            [
              356375.11766425933,
              5007472.388564459
            ],
            [
              356368.43528060935,
              5007467.21798384
            ],
            [
              356367.9101451856,
              5007462.0760036157
            ],
            [
              356364.22071833635,
              5007463.8618697245
            ],
            [
              356356.9057340119,
              5007456.5102034742
            ],
            [
              356353.09392049519,
              5007451.7155468417
            ],
            [
              356370.54715289158,
              5007443.2339812117
            ],
            [
              356376.08126524102,
              5007440.5551829617
            ],
            [
              356391.89678310166,
              5007436.3971991297
            ],
            [
              356378.6347307837,
              5007416.1799124153
            ],
            [
              356392.83988755883,
              5007407.6806976786
            ],
            [
              356394.61841181829,
              5007409.7727158163
            ],
            [
              356409.33149895474,
              5007400.6122842114
            ],
            [
              356412.59693417308,
              5007404.7051485581
            ],
            [
              356450.45789187466,
              5007376.5335222455
            ],
            [
              356439.37690504867,
              5007362.7332654437
            ],
            [
              356446.53104436287,
              5007356.6637541
            ],
            [
              356448.11637525266,
              5007358.7593651759
            ],
            [
              356456.24038556928,
              5007353.8750755796
            ],
            [
              356488.31370865903,
              5007388.6550441412
            ],
            [
              356519.85087625514,
              5007422.8536045672
            ],
            [
              356521.15736336826,
              5007411.5679134745
            ],
            [
              356503.11582504917,
              5007373.8904376179
            ],
            [
              356506.77080498805,
              5007373.7403199347
            ],
            [
              356514.5548661502,
              5007383.9694295507
            ],
            [
              356522.79537995503,
              5007377.6124151684
            ],
            [
              356542.05470762862,
              5007406.4829369038
            ],
            [
              356550.21708413603,
              5007400.3754811464
            ],
            [
              356553.74345276452,
              5007394.188843064
            ],
            [
              356558.24755751208,
              5007391.6235925695
            ],
            [
              356564.52209478279,
              5007386.1049745334
            ],
            [
              356573.5952054839,
              5007376.4678485952
            ],
            [
              356581.95312161249,
              5007367.9398630466
            ],
            [
              356551.68543705629,
              5007343.1143339965
            ],
            [
              356533.84054862935,
              5007326.1443119915
            ],
            [
              356530.87316282489,
              5007328.6704293666
            ],
            [
              356520.62771124352,
              5007315.6998461457
            ],
            [
              356510.38225984381,
              5007302.7292621527
            ],
            [
              356514.39665581839,
              5007299.589194539
            ],
            [
              356519.49940123019,
              5007295.5978181642
            ],
            [
              356524.46032935189,
              5007291.7173716128
            ],
            [
              356531.21564745461,
              5007281.2669976698
            ],
            [
              356543.58051856875,
              5007270.1467759712
            ],
            [
              356543.52161439793,
              5007266.9795024628
            ],
            [
              356545.82326155441,
              5007263.9409816647
            ],
            [
              356563.59555795969,
              5007254.426369586
            ],
            [
              356573.16107370454,
              5007251.4885017211
            ],
            [
              356581.15571439604,
              5007247.3762986166
            ],
            [
              356592.42394284875,
              5007241.2379741641
            ],
            [
              356598.28476125217,
              5007238.2863652157
            ],
            [
              356620.17745767365,
              5007209.0690242695
            ],
            [
              356637.65395641519,
              5007222.0474049151
            ],
            [
              356649.41677200206,
              5007203.0674189823
            ],
            [
              356653.93061375269,
              5007198.0373322042
            ],
            [
              356660.61772333144,
              5007190.5790442526
            ],
            [
              356667.95431834896,
              5007179.0021979101
            ],
            [
              356677.17956065916,
              5007165.3150451742
            ],
            [
              356681.77064724761,
              5007164.8116433062
            ],
            [
              356683.15651554987,
              5007164.3678832557
            ],
            [
              356686.3514286665,
              5007163.7511178544
            ],
            [
              356702.82766907796,
              5007159.2026135819
            ],
            [
              356690.93765496596,
              5007148.4074774263
            ],
            [
              356681.85820631607,
              5007145.0711626587
            ],
            [
              356674.59014803468,
              5007140.365864777
            ],
            [
              356679.8672250536,
              5007132.7845407752
            ],
            [
              356688.67338071519,
              5007116.1943422202
            ],
            [
              356680.77051971125,
              5007101.023061458
            ],
            [
              356676.67378733557,
              5007093.2535546292
            ],
            [
              356672.57705508621,
              5007085.4840476178
            ],
            [
              356667.2088530566,
              5007076.1222282648
            ],
            [
              356676.17419175251,
              5007060.4405589681
            ],
            [
              356664.7776978382,
              5007051.6965409638
            ],
            [
              356651.84561390156,
              5007057.8704738254
            ],
            [
              356648.16284596763,
              5007055.9056711616
            ],
            [
              356643.5611120867,
              5007055.2477305569
            ],
            [
              356623.50666233851,
              5007041.2237860439
            ],
            [
              356618.1134366981,
              5007037.9875140218
            ],
            [
              356606.07602183829,
              5007030.4209493008
            ],
            [
              356603.37209640729,
              5007029.5168521497
            ],
            [
              356598.7963315532,
              5007026.1167621445
            ],
            [
              356595.58619964932,
              5007024.2357689487
            ],
            [
              356589.47834031872,
              5007019.1838690359
            ],
            [
              356583.06803570775,
              5007013.8342581922
            ],
            [
              356579.64827414806,
              5007011.1995142922
            ],
            [
              356577.61698121816,
              5007009.0201485706
            ],
            [
              356574.18909311853,
              5007006.7302955557
            ],
            [
              356570.91360758385,
              5007005.6828440977
            ],
            [
              356558.88476115419,
              5007001.899916294
            ],
            [
              356554.69327424077,
              5007000.7625969891
            ],
            [
              356543.94701010775,
              5006985.4843362989
            ],
            [
              356533.82602953102,
              5006978.9504501987
            ],
            [
              356526.37217326398,
              5006978.5788622126
            ],
            [
              356497.59270240663,
              5006947.8186384253
            ],
            [
              356475.01870546915,
              5006976.5353713036
            ],
            [
              356472.71403512015,
              5006975.4817223502
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 354,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355809.37258140545,
              5008009.6729480028
            ],
            [
              355846.14350558113,
              5007963.3461807556
            ],
            [
              355845.75087616284,
              5007963.0059922105
            ],
            [
              355846.54040959373,
              5007962.0842847945
            ],
            [
              355830.85608129174,
              5007948.2668251488
            ],
            [
              355829.29206100979,
              5007950.9162058514
            ],
            [
              355822.39440950571,
              5007943.2844717009
            ],
            [
              355793.86937421135,
              5007979.946324653
            ],
            [
              355790.24578092439,
              5007980.9237083402
            ],
            [
              355785.58852381114,
              5007983.4369385447
            ],
            [
              355778.10212822683,
              5007980.6945855198
            ],
            [
              355772.69641264022,
              5007996.1265785126
            ],
            [
              355775.30004583875,
              5007998.2524474198
            ],
            [
              355775.2978442529,
              5007998.2354887594
            ],
            [
              355775.99250022526,
              5008002.4565160954
            ],
            [
              355776.01895681903,
              5008002.4427723624
            ],
            [
              355776.73299237405,
              5008001.5314343525
            ],
            [
              355781.87718295085,
              5007999.3341621738
            ],
            [
              355782.01454412891,
              5007999.3281710828
            ],
            [
              355782.24832952005,
              5007999.2067237189
            ],
            [
              355787.77995791094,
              5007998.9308220372
            ],
            [
              355792.86632323469,
              5008000.7300868612
            ],
            [
              355795.51764447562,
              5008001.5371701121
            ],
            [
              355809.37258140545,
              5008009.6729480028
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 355,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355471.83935017843,
              5008365.8077965556
            ],
            [
              355493.73945694644,
              5008348.9160331171
            ],
            [
              355515.94953113585,
              5008332.4339563036
            ],
            [
              355520.02598949353,
              5008329.3774279151
            ],
            [
              355511.94405656558,
              5008319.1099905437
            ],
            [
              355502.03435206559,
              5008330.8796226606
            ],
            [
              355492.34883965307,
              5008323.3235154524
            ],
            [
              355486.30911535519,
              5008334.067389762
            ],
            [
              355487.27208867716,
              5008342.8119475385
            ],
            [
              355478.85381889687,
              5008351.6941972934
            ],
            [
              355473.37191838544,
              5008350.4974927101
            ],
            [
              355470.93185813416,
              5008350.1587768523
            ],
            [
              355460.85521126719,
              5008352.2915477408
            ],
            [
              355460.75955139246,
              5008352.3120267792
            ],
            [
              355459.55636688491,
              5008348.1908751708
            ],
            [
              355456.13486394909,
              5008337.3886087174
            ],
            [
              355448.372848386,
              5008337.9279639088
            ],
            [
              355433.80792833428,
              5008345.3204827029
            ],
            [
              355426.9814734839,
              5008346.3978941115
            ],
            [
              355417.14815031691,
              5008348.9864100935
            ],
            [
              355421.12743359403,
              5008352.1146066478
            ],
            [
              355423.16891855974,
              5008357.1630458301
            ],
            [
              355428.07747483574,
              5008364.355980671
            ],
            [
              355443.74547965353,
              5008378.7114907047
            ],
            [
              355450.55206809082,
              5008382.8622985445
            ],
            [
              355466.68048990396,
              5008369.882305908
            ],
            [
              355471.83935017843,
              5008365.8077965556
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 356,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355520.02598949452,
              5008329.3774279142
            ],
            [
              355538.03990293242,
              5008315.5419597868
            ],
            [
              355555.5895320329,
              5008301.1221220586
            ],
            [
              355572.65592829435,
              5008286.1334841102
            ],
            [
              355596.21632302826,
              5008264.2431329619
            ],
            [
              355568.43595056207,
              5008282.9399224641
            ],
            [
              355562.35646166053,
              5008276.8181849774
            ],
            [
              355550.54440719303,
              5008288.0350664221
            ],
            [
              355540.99668688723,
              5008300.5994056417
            ],
            [
              355534.71761415753,
              5008309.0747701973
            ],
            [
              355520.02598949452,
              5008329.3774279142
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 357,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355650.68887891906,
              5008206.2753474116
            ],
            [
              355666.45536516776,
              5008187.2120520063
            ],
            [
              355681.64248419227,
              5008167.6840546625
            ],
            [
              355696.23654728843,
              5008147.7089568377
            ],
            [
              355687.06011455995,
              5008143.2630275916
            ],
            [
              355683.86297245923,
              5008141.3475892777
            ],
            [
              355670.94754650601,
              5008145.0129420757
            ],
            [
              355662.09339270275,
              5008150.5757838218
            ],
            [
              355655.42886766646,
              5008153.4646347575
            ],
            [
              355647.72066153144,
              5008156.8995644394
            ],
            [
              355637.19826148922,
              5008164.8963065753
            ],
            [
              355627.65384678711,
              5008168.7603655895
            ],
            [
              355623.58873254631,
              5008169.7576188473
            ],
            [
              355650.68887891906,
              5008206.2753474116
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 358,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355757.03425750759,
              5008195.3384614848
            ],
            [
              355728.77826215565,
              5008163.4752306556
            ],
            [
              355714.5382520596,
              5008156.5760278329
            ],
            [
              355713.44057381578,
              5008158.0271780752
            ],
            [
              355701.90460188879,
              5008173.9883093489
            ],
            [
              355708.35114487103,
              5008170.7040100452
            ],
            [
              355719.4064571489,
              5008178.4268081076
            ],
            [
              355741.82983519556,
              5008199.4394899588
            ],
            [
              355757.03425750759,
              5008195.3384614848
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 359,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355608.00364945026,
              5008252.6235895744
            ],
            [
              355621.81540516397,
              5008238.3937294325
            ],
            [
              355615.35690818849,
              5008230.8119378164
            ],
            [
              355600.00373964262,
              5008243.3232296938
            ],
            [
              355608.00364945026,
              5008252.6235895744
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 360,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352734.40306542272,
              5010349.5722959228
            ],
            [
              352739.87496706046,
              5010340.8139939681
            ],
            [
              352738.52453002246,
              5010339.6731244829
            ],
            [
              352730.74840887258,
              5010337.5235460829
            ],
            [
              352732.70359078533,
              5010333.6896411143
            ],
            [
              352725.83661562175,
              5010324.8202515785
            ],
            [
              352707.58738915861,
              5010307.65114344
            ],
            [
              352693.85261381947,
              5010326.6913785907
            ],
            [
              352676.77331823524,
              5010319.9638847876
            ],
            [
              352671.91965712042,
              5010317.0931414645
            ],
            [
              352677.15105742583,
              5010305.7568314923
            ],
            [
              352672.64843451086,
              5010302.7488573976
            ],
            [
              352663.89832347876,
              5010310.447808817
            ],
            [
              352646.79778381874,
              5010310.9592168806
            ],
            [
              352635.69188652525,
              5010318.6222740812
            ],
            [
              352629.72256606433,
              5010325.1100411965
            ],
            [
              352621.42871220404,
              5010331.3512402624
            ],
            [
              352618.28940501675,
              5010345.0869037416
            ],
            [
              352613.99878639978,
              5010353.4757778551
            ],
            [
              352612.40994055831,
              5010356.4038703758
            ],
            [
              352607.08731906133,
              5010371.6717556687
            ],
            [
              352595.40449325641,
              5010383.8187062694
            ],
            [
              352600.38532127888,
              5010391.7452487685
            ],
            [
              352599.39319821907,
              5010392.8021378731
            ],
            [
              352603.66194863286,
              5010396.3851038907
            ],
            [
              352615.67132974404,
              5010409.5153544201
            ],
            [
              352624.00886325288,
              5010415.5045382921
            ],
            [
              352624.61677683057,
              5010429.8088266393
            ],
            [
              352625.28983309417,
              5010445.6459148368
            ],
            [
              352617.31777088088,
              5010445.7876814958
            ],
            [
              352643.93914766825,
              5010460.0753315035
            ],
            [
              352653.93481770571,
              5010455.945060472
            ],
            [
              352657.81525196898,
              5010454.3416417092
            ],
            [
              352661.98439158645,
              5010452.6189281866
            ],
            [
              352671.69135580875,
              5010448.6079523824
            ],
            [
              352670.31682401762,
              5010444.9106690614
            ],
            [
              352679.3812785662,
              5010431.2881675139
            ],
            [
              352684.01139495522,
              5010435.0659784414
            ],
            [
              352703.03110697417,
              5010445.007819253
            ],
            [
              352704.14324186515,
              5010446.176022415
            ],
            [
              352705.15632318211,
              5010447.3461013343
            ],
            [
              352709.59928136674,
              5010451.7218098911
            ],
            [
              352717.36395546753,
              5010458.8098575538
            ],
            [
              352725.48446230649,
              5010463.7116183061
            ],
            [
              352734.62655740924,
              5010452.1517373845
            ],
            [
              352738.68151667895,
              5010444.7125481917
            ],
            [
              352743.13645241602,
              5010393.1691650562
            ],
            [
              352737.3134240954,
              5010383.0565987546
            ],
            [
              352736.42945186904,
              5010374.3568363395
            ],
            [
              352735.98460006405,
              5010367.2730529709
            ],
            [
              352735.01624691108,
              5010360.0946998755
            ],
            [
              352734.40306542272,
              5010349.5722959228
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 361,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353106.69233898568,
              5010215.8724435009
            ],
            [
              353119.65215144266,
              5010187.8012250662
            ],
            [
              353107.66184059228,
              5010177.8374696635
            ],
            [
              353102.74499081407,
              5010173.862019307
            ],
            [
              353104.32482434547,
              5010172.0482278829
            ],
            [
              353096.9063759236,
              5010165.7004525186
            ],
            [
              353095.43262797716,
              5010159.1519452808
            ],
            [
              353091.20106039749,
              5010154.5464898879
            ],
            [
              353064.21787531796,
              5010142.9776760722
            ],
            [
              353061.81209627533,
              5010144.4637575271
            ],
            [
              353058.74584944599,
              5010149.2049978347
            ],
            [
              353043.50117993192,
              5010160.521867644
            ],
            [
              353031.54254634329,
              5010171.7342522042
            ],
            [
              353015.08580543374,
              5010183.3906603819
            ],
            [
              353004.11119609926,
              5010189.3600365017
            ],
            [
              352990.08658424212,
              5010197.6484637354
            ],
            [
              352984.43395018781,
              5010199.0147766257
            ],
            [
              352984.55249897134,
              5010201.2856923938
            ],
            [
              352986.58653025533,
              5010204.04036217
            ],
            [
              352994.46760659438,
              5010215.8745041313
            ],
            [
              352997.16400017613,
              5010220.6357493615
            ],
            [
              353000.06464220362,
              5010219.6096434165
            ],
            [
              353003.14037758805,
              5010226.1937555289
            ],
            [
              353010.48706595128,
              5010238.458513041
            ],
            [
              353015.95311163226,
              5010240.3614240531
            ],
            [
              353023.08128066658,
              5010243.7551428331
            ],
            [
              353035.81232683529,
              5010223.6512860879
            ],
            [
              353044.01966389245,
              5010213.0659782849
            ],
            [
              353056.45165853569,
              5010220.4451314649
            ],
            [
              353060.88239799917,
              5010223.1354296748
            ],
            [
              353078.89815276139,
              5010234.090863036
            ],
            [
              353088.54353594838,
              5010233.3720226549
            ],
            [
              353106.69233898568,
              5010215.8724435009
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 362,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356592.53156023787,
              5007407.2372795837
            ],
            [
              356590.90435254475,
              5007405.4684400018
            ],
            [
              356581.14858181507,
              5007395.2241201587
            ],
            [
              356573.9733891451,
              5007387.9241643222
            ],
            [
              356567.13462970738,
              5007381.5595625378
            ],
            [
              356564.52209478378,
              5007386.1049745344
            ],
            [
              356558.24755751871,
              5007391.6235925639
            ],
            [
              356553.74345276586,
              5007394.1888430659
            ],
            [
              356550.21708413336,
              5007400.3754811501
            ],
            [
              356542.05470762937,
              5007406.4829369029
            ],
            [
              356522.79537995608,
              5007377.6124151684
            ],
            [
              356514.5548661495,
              5007383.9694295544
            ],
            [
              356506.77080498874,
              5007373.7403199347
            ],
            [
              356503.11582505103,
              5007373.8904376226
            ],
            [
              356521.15736336826,
              5007411.5679134745
            ],
            [
              356519.85087625409,
              5007422.8536045672
            ],
            [
              356488.31370865827,
              5007388.6550441412
            ],
            [
              356456.24038557004,
              5007353.8750755796
            ],
            [
              356448.11637525173,
              5007358.7593651768
            ],
            [
              356446.53104436566,
              5007356.6637541028
            ],
            [
              356439.37690504867,
              5007362.7332654437
            ],
            [
              356450.45789187338,
              5007376.5335222436
            ],
            [
              356412.59693417407,
              5007404.7051485581
            ],
            [
              356409.33149895474,
              5007400.6122842114
            ],
            [
              356394.61841181829,
              5007409.7727158153
            ],
            [
              356392.83988756005,
              5007407.6806976805
            ],
            [
              356378.6347307837,
              5007416.1799124163
            ],
            [
              356391.89678310259,
              5007436.3971991306
            ],
            [
              356376.08126524097,
              5007440.5551829599
            ],
            [
              356367.00748939026,
              5007444.9541145843
            ],
            [
              356353.09392049513,
              5007451.7155468408
            ],
            [
              356356.90573400812,
              5007456.5102034695
            ],
            [
              356364.22071833635,
              5007463.8618697245
            ],
            [
              356367.91014518565,
              5007462.0760036185
            ],
            [
              356368.43528060935,
              5007467.21798384
            ],
            [
              356375.11766425829,
              5007472.3885644553
            ],
            [
              356384.84621758561,
              5007487.3767379671
            ],
            [
              356393.87774799042,
              5007503.379580711
            ],
            [
              356379.78139596822,
              5007515.134840765
            ],
            [
              356379.52974748594,
              5007516.9998849919
            ],
            [
              356391.85266733542,
              5007525.2138595311
            ],
            [
              356390.8720690629,
              5007526.376951701
            ],
            [
              356399.91368798184,
              5007535.2243473185
            ],
            [
              356402.61719424586,
              5007534.3154416839
            ],
            [
              356412.73648437212,
              5007521.3782461938
            ],
            [
              356422.87682058621,
              5007512.3171448745
            ],
            [
              356434.56230333116,
              5007501.6532060476
            ],
            [
              356448.87097363145,
              5007516.5561600188
            ],
            [
              356448.86050086567,
              5007523.3478160379
            ],
            [
              356452.68301286915,
              5007528.7146987179
            ],
            [
              356463.32032807329,
              5007538.6773022376
            ],
            [
              356474.61531126598,
              5007553.2069916297
            ],
            [
              356467.87278826296,
              5007560.201386855
            ],
            [
              356453.96642675571,
              5007574.6273919893
            ],
            [
              356442.10324108606,
              5007583.4343070621
            ],
            [
              356441.55398767715,
              5007590.1522284755
            ],
            [
              356451.26787152694,
              5007594.9083891679
            ],
            [
              356473.82076593401,
              5007619.3260557987
            ],
            [
              356485.0035746012,
              5007607.7254299931
            ],
            [
              356492.96616672626,
              5007613.6534187319
            ],
            [
              356495.66494026186,
              5007615.7551517095
            ],
            [
              356502.70128684834,
              5007619.5484403502
            ],
            [
              356512.89385613473,
              5007629.6122521628
            ],
            [
              356517.89792066999,
              5007639.8991842289
            ],
            [
              356523.99575574009,
              5007632.9237585347
            ],
            [
              356550.78977395082,
              5007602.6779180542
            ],
            [
              356547.05702579155,
              5007599.4561258666
            ],
            [
              356533.15836459381,
              5007588.9187094131
            ],
            [
              356517.04039159132,
              5007573.1547711324
            ],
            [
              356518.12558090262,
              5007572.1052926881
            ],
            [
              356511.19959767879,
              5007565.3143467251
            ],
            [
              356520.73793354351,
              5007553.0273840232
            ],
            [
              356524.59260972257,
              5007560.8639562028
            ],
            [
              356540.90070611303,
              5007546.9682807112
            ],
            [
              356530.92127490643,
              5007535.5385898314
            ],
            [
              356531.77045112796,
              5007534.4142231755
            ],
            [
              356518.92879154353,
              5007522.0037943507
            ],
            [
              356523.66600180621,
              5007517.4672725452
            ],
            [
              356510.67682775774,
              5007503.7457729075
            ],
            [
              356521.02104311989,
              5007494.9037036467
            ],
            [
              356532.16328648274,
              5007509.0302628716
            ],
            [
              356534.11313220288,
              5007507.5111931833
            ],
            [
              356542.73624937783,
              5007499.1953697074
            ],
            [
              356544.51018555491,
              5007501.5101609221
            ],
            [
              356548.17694291193,
              5007505.8903815253
            ],
            [
              356554.24787131639,
              5007493.2972004656
            ],
            [
              356569.89679856389,
              5007510.4291107552
            ],
            [
              356576.53077245952,
              5007505.9732337417
            ],
            [
              356601.9131683929,
              5007488.0782398675
            ],
            [
              356598.76840651658,
              5007483.5101338811
            ],
            [
              356606.4919758839,
              5007473.3575607836
            ],
            [
              356610.73670739745,
              5007462.281184759
            ],
            [
              356620.29572012095,
              5007451.1059396025
            ],
            [
              356626.57157657732,
              5007456.1790210791
            ],
            [
              356638.6313316928,
              5007466.4579248335
            ],
            [
              356636.33833499567,
              5007469.4661809895
            ],
            [
              356639.99130654812,
              5007473.1052381136
            ],
            [
              356643.58225011878,
              5007473.4091620035
            ],
            [
              356652.06213567086,
              5007455.6828160333
            ],
            [
              356652.69501329964,
              5007454.8849853836
            ],
            [
              356639.32208149647,
              5007436.066374355
            ],
            [
              356629.96747478639,
              5007431.5683400454
            ],
            [
              356593.43832227995,
              5007408.1293056766
            ],
            [
              356592.53156023787,
              5007407.2372795837
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 363,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356013.93122700654,
              5008064.594679554
            ],
            [
              356022.28488795267,
              5008055.0819652416
            ],
            [
              355999.10993789567,
              5008036.6264735805
            ],
            [
              355988.55496798409,
              5008045.8726126086
            ],
            [
              356001.00230661844,
              5008058.345269829
            ],
            [
              356010.95128172112,
              5008067.9778024284
            ],
            [
              356013.93122700654,
              5008064.594679554
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 364,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357015.40705979272,
              5007049.9260937143
            ],
            [
              356996.812038314,
              5007081.498523375
            ],
            [
              356981.18853410712,
              5007099.3950524814
            ],
            [
              356977.51008152537,
              5007102.0416069226
            ],
            [
              356972.85981075343,
              5007105.3013735218
            ],
            [
              356968.72961895354,
              5007109.1991434982
            ],
            [
              356972.8217050138,
              5007116.2328305999
            ],
            [
              356956.29987136793,
              5007139.5914320536
            ],
            [
              356952.85031155281,
              5007144.3540927684
            ],
            [
              356942.89412409073,
              5007158.1001786785
            ],
            [
              356932.6842053844,
              5007172.7614225037
            ],
            [
              356928.54057115823,
              5007178.711600692
            ],
            [
              356925.7287210981,
              5007182.7120016366
            ],
            [
              356916.58304866531,
              5007195.7234915048
            ],
            [
              356932.00968222698,
              5007207.7011973253
            ],
            [
              356943.46895700594,
              5007220.3059982266
            ],
            [
              356946.49053056521,
              5007223.6296231588
            ],
            [
              356946.49053056602,
              5007223.6296231607
            ],
            [
              356946.49053056521,
              5007223.6296231588
            ],
            [
              356966.05254367832,
              5007206.6001548758
            ],
            [
              356975.12888946838,
              5007200.4737868654
            ],
            [
              356984.0321443014,
              5007191.7983838804
            ],
            [
              356992.03274635703,
              5007183.9390611732
            ],
            [
              357004.84285526682,
              5007171.3551614722
            ],
            [
              357010.66465127672,
              5007165.6361708185
            ],
            [
              357022.11790059024,
              5007156.9027416194
            ],
            [
              357028.50674877682,
              5007152.9169939812
            ],
            [
              357029.73987150687,
              5007149.2273631385
            ],
            [
              357040.00802296126,
              5007128.1733189998
            ],
            [
              357041.12505636388,
              5007124.6041382002
            ],
            [
              357047.62406076031,
              5007111.3541934295
            ],
            [
              357056.77136932942,
              5007094.1517186044
            ],
            [
              357059.38076198025,
              5007087.4345167167
            ],
            [
              357061.31234470202,
              5007083.801985167
            ],
            [
              357065.09783789911,
              5007076.6829926362
            ],
            [
              357067.5421611651,
              5007072.0862023542
            ],
            [
              357072.903228254,
              5007062.3367073983
            ],
            [
              357078.03910130373,
              5007053.4959697034
            ],
            [
              357089.3865976769,
              5007046.4247077769
            ],
            [
              357096.75811214471,
              5007041.9112475673
            ],
            [
              357103.73370643466,
              5007038.7583456868
            ],
            [
              357107.92983274936,
              5007036.3515786529
            ],
            [
              357132.06508073973,
              5007028.1690684119
            ],
            [
              357160.72134150012,
              5007018.668585577
            ],
            [
              357175.92279044335,
              5007016.3834289666
            ],
            [
              357190.75025190884,
              5007012.7905882709
            ],
            [
              357202.02686255827,
              5007002.744067301
            ],
            [
              357210.8989584411,
              5006991.3370377338
            ],
            [
              357215.35532010515,
              5006968.5916365776
            ],
            [
              357214.30236612208,
              5006941.0465985062
            ],
            [
              357207.13880145055,
              5006916.2561771497
            ],
            [
              357203.75249381532,
              5006902.8070563981
            ],
            [
              357203.17233077827,
              5006900.0994938174
            ],
            [
              357199.82039439509,
              5006889.518448011
            ],
            [
              357196.71654086612,
              5006880.0511108963
            ],
            [
              357192.30922272353,
              5006850.5620968034
            ],
            [
              357187.95305907086,
              5006832.2974867551
            ],
            [
              357188.64264179143,
              5006817.4991953671
            ],
            [
              357188.82304996275,
              5006801.2639310965
            ],
            [
              357189.63805047824,
              5006793.2131939773
            ],
            [
              357190.33996885788,
              5006784.450001752
            ],
            [
              357183.80967913236,
              5006782.6831041714
            ],
            [
              357178.36656171759,
              5006779.7919321656
            ],
            [
              357152.29144955723,
              5006826.0689173359
            ],
            [
              357168.73835536488,
              5006832.5823832098
            ],
            [
              357169.14135075948,
              5006843.0984809175
            ],
            [
              357164.48625818238,
              5006866.6446559727
            ],
            [
              357159.26502068056,
              5006883.5618355013
            ],
            [
              357144.52051784814,
              5006913.5744175334
            ],
            [
              357135.9665949865,
              5006929.8896235013
            ],
            [
              357134.71661862527,
              5006934.117889923
            ],
            [
              357127.48337964038,
              5006947.7507203333
            ],
            [
              357123.80584380316,
              5006954.6819479046
            ],
            [
              357121.74089481652,
              5006958.6931586424
            ],
            [
              357119.79933799355,
              5006962.4646773804
            ],
            [
              357116.762621595,
              5006968.3635689588
            ],
            [
              357114.57045406487,
              5006972.621905054
            ],
            [
              357111.41837549891,
              5006978.7597853653
            ],
            [
              357097.1318546781,
              5006995.1010544123
            ],
            [
              357065.95676341356,
              5007030.3892390924
            ],
            [
              357040.6955750651,
              5007058.7292121416
            ],
            [
              357024.17706290743,
              5007051.9847660158
            ],
            [
              357019.74820686795,
              5007050.9528953284
            ],
            [
              357015.40705979272,
              5007049.9260937143
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 365,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352367.4344816794,
              5011864.6580062257
            ],
            [
              352378.59292839345,
              5011852.9808652569
            ],
            [
              352384.62272779905,
              5011845.4016175
            ],
            [
              352391.62932397967,
              5011835.2140985001
            ],
            [
              352401.27582127473,
              5011817.6648375709
            ],
            [
              352404.33654892776,
              5011810.7719650753
            ],
            [
              352408.80241555732,
              5011800.2627556007
            ],
            [
              352414.01823613775,
              5011787.9887334118
            ],
            [
              352399.24107205466,
              5011776.8094411911
            ],
            [
              352393.71570444567,
              5011781.9340751367
            ],
            [
              352383.52413819212,
              5011789.7087675994
            ],
            [
              352381.1138553269,
              5011791.6701142425
            ],
            [
              352379.4521341924,
              5011793.5379645014
            ],
            [
              352375.17498578137,
              5011799.1989467153
            ],
            [
              352373.25296041294,
              5011802.272699737
            ],
            [
              352368.22409653908,
              5011807.209686094
            ],
            [
              352362.29279247526,
              5011812.2121091112
            ],
            [
              352358.61812635133,
              5011817.0784223927
            ],
            [
              352352.59235990688,
              5011817.0024430705
            ],
            [
              352349.26633497438,
              5011822.5127458535
            ],
            [
              352320.8132794046,
              5011823.8554579895
            ],
            [
              352310.16937744693,
              5011823.4146134276
            ],
            [
              352302.32715732703,
              5011823.6186536886
            ],
            [
              352301.26788602985,
              5011829.1564962268
            ],
            [
              352292.05414542026,
              5011828.84123486
            ],
            [
              352291.60649931425,
              5011833.6798565304
            ],
            [
              352272.69477617572,
              5011828.6045018025
            ],
            [
              352267.26246773871,
              5011825.6010321882
            ],
            [
              352261.75525263563,
              5011836.502528701
            ],
            [
              352256.04356671212,
              5011848.1658557905
            ],
            [
              352256.40869889158,
              5011854.6231758818
            ],
            [
              352256.36408176058,
              5011866.5858203163
            ],
            [
              352257.88497015869,
              5011871.2175254039
            ],
            [
              352265.06248594215,
              5011871.4424106833
            ],
            [
              352262.70206355798,
              5011875.0800695829
            ],
            [
              352283.13875873102,
              5011888.282087543
            ],
            [
              352284.71813257737,
              5011906.8916833466
            ],
            [
              352241.87740042974,
              5011891.7788352193
            ],
            [
              352238.95103554515,
              5011896.7426972808
            ],
            [
              352271.9762412812,
              5011914.4124922054
            ],
            [
              352278.77870287636,
              5011918.4177112533
            ],
            [
              352286.17834413162,
              5011923.6736758221
            ],
            [
              352315.82198011206,
              5011939.7488770429
            ],
            [
              352318.57768440159,
              5011933.9872104255
            ],
            [
              352317.09675501968,
              5011914.6368316114
            ],
            [
              352317.80500302528,
              5011897.1352061899
            ],
            [
              352328.773102412,
              5011891.5719388165
            ],
            [
              352342.45915250387,
              5011883.322868783
            ],
            [
              352355.67404955311,
              5011874.0912830997
            ],
            [
              352367.4344816794,
              5011864.6580062257
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 366,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352303.83185805642,
              5010875.2173126303
            ],
            [
              352294.78080403374,
              5010867.2518043313
            ],
            [
              352294.96720090922,
              5010833.5352777243
            ],
            [
              352298.72395723459,
              5010809.1514407424
            ],
            [
              352298.51032804057,
              5010799.3727164706
            ],
            [
              352296.28464273701,
              5010790.4362679347
            ],
            [
              352304.66977754398,
              5010760.5793922385
            ],
            [
              352305.51423189166,
              5010752.0391127365
            ],
            [
              352310.93013139849,
              5010735.4402731406
            ],
            [
              352315.42884718155,
              5010741.5042464491
            ],
            [
              352326.23261314846,
              5010745.9972170098
            ],
            [
              352330.22817014856,
              5010739.2575207772
            ],
            [
              352326.73428248795,
              5010733.8029032545
            ],
            [
              352333.05606871034,
              5010725.8789123874
            ],
            [
              352327.38033638633,
              5010721.8842167826
            ],
            [
              352339.05407398409,
              5010711.7702108277
            ],
            [
              352346.9322659889,
              5010698.4553198619
            ],
            [
              352302.15946517495,
              5010671.5889474396
            ],
            [
              352297.64584217564,
              5010666.7110757297
            ],
            [
              352292.66598002909,
              5010657.0952765029
            ],
            [
              352286.03509769973,
              5010650.0014256183
            ],
            [
              352284.02242789132,
              5010652.9979154151
            ],
            [
              352280.46804641961,
              5010658.1804679632
            ],
            [
              352264.68023371993,
              5010652.6982955681
            ],
            [
              352259.5042208627,
              5010659.7493531238
            ],
            [
              352253.43717031355,
              5010663.280664335
            ],
            [
              352248.81891090336,
              5010655.9403491979
            ],
            [
              352235.41910587484,
              5010679.4424079768
            ],
            [
              352225.21146230586,
              5010669.6128157815
            ],
            [
              352222.14225210203,
              5010675.7995035714
            ],
            [
              352218.44408221828,
              5010679.8761510383
            ],
            [
              352201.45689159847,
              5010692.333587409
            ],
            [
              352199.00079899619,
              5010693.4401781745
            ],
            [
              352194.33098600636,
              5010694.319421323
            ],
            [
              352182.89315824769,
              5010679.8701638402
            ],
            [
              352143.69535773067,
              5010723.4194775335
            ],
            [
              352133.94416464685,
              5010738.4884089893
            ],
            [
              352126.46838160529,
              5010739.7466074508
            ],
            [
              352119.30326167966,
              5010744.9491335629
            ],
            [
              352112.31204358855,
              5010752.4457886601
            ],
            [
              352095.9327432047,
              5010770.6579329409
            ],
            [
              352079.29668838735,
              5010799.0732683288
            ],
            [
              352076.32052036381,
              5010796.8575794399
            ],
            [
              352071.23418402189,
              5010793.1038500387
            ],
            [
              352060.46634366101,
              5010802.3998348275
            ],
            [
              352050.24030509248,
              5010812.8808411872
            ],
            [
              352027.61730508396,
              5010849.3732271604
            ],
            [
              352011.46982039115,
              5010879.1485762233
            ],
            [
              352003.57257971523,
              5010892.2304674853
            ],
            [
              352018.59681869583,
              5010935.9144252306
            ],
            [
              352025.04435639345,
              5010949.6431302521
            ],
            [
              352029.92419120279,
              5010953.457515534
            ],
            [
              352033.65384607011,
              5010976.2184058847
            ],
            [
              352043.26862980291,
              5010997.1151665468
            ],
            [
              352054.69542852609,
              5011013.5746714883
            ],
            [
              352069.57459763647,
              5011029.8197251065
            ],
            [
              352074.56215214363,
              5011023.2683513146
            ],
            [
              352094.11621676682,
              5011039.224195363
            ],
            [
              352117.96052656573,
              5011056.2935456419
            ],
            [
              352135.44685914816,
              5011061.7600140851
            ],
            [
              352148.62817152933,
              5011066.4039850282
            ],
            [
              352167.49773659406,
              5011069.3624736117
            ],
            [
              352187.23324306676,
              5011068.698859985
            ],
            [
              352199.90902882343,
              5011058.3084384613
            ],
            [
              352205.70541822765,
              5011050.3025557278
            ],
            [
              352206.86292573571,
              5011047.7988104066
            ],
            [
              352228.4415472119,
              5011028.483444592
            ],
            [
              352239.91519953142,
              5011038.4245055141
            ],
            [
              352255.38179484801,
              5011040.6119989958
            ],
            [
              352263.51101653016,
              5011039.2286437564
            ],
            [
              352283.34228748694,
              5011013.3356610332
            ],
            [
              352298.79843124159,
              5010987.8376490613
            ],
            [
              352302.45691909856,
              5010990.8024202697
            ],
            [
              352307.29718441592,
              5010975.3767410601
            ],
            [
              352307.47136531206,
              5010962.0612409161
            ],
            [
              352309.60593501182,
              5010937.5806584014
            ],
            [
              352307.5731060906,
              5010937.0782028427
            ],
            [
              352296.76738183148,
              5010930.0932302149
            ],
            [
              352303.7840630025,
              5010914.9372377163
            ],
            [
              352300.59968105116,
              5010913.5810612813
            ],
            [
              352306.40278110531,
              5010898.606422415
            ],
            [
              352301.19848507975,
              5010896.2512002755
            ],
            [
              352303.83185805642,
              5010875.2173126303
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 367,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352027.61730508815,
              5010849.3732271688
            ],
            [
              352050.24030509248,
              5010812.8808411872
            ],
            [
              352060.46634367655,
              5010802.3998348108
            ],
            [
              352038.85450316832,
              5010786.5663630189
            ],
            [
              352021.96111709153,
              5010775.8861796111
            ],
            [
              352012.8347678365,
              5010766.7145263292
            ],
            [
              352003.06365733111,
              5010762.5366143323
            ],
            [
              351993.21445160982,
              5010754.1618408319
            ],
            [
              351990.57574095565,
              5010742.584965881
            ],
            [
              351991.64345526934,
              5010742.7249144996
            ],
            [
              351976.27807636553,
              5010720.6396519439
            ],
            [
              351970.66227145557,
              5010696.4695865624
            ],
            [
              351961.54490744794,
              5010699.7197546158
            ],
            [
              351960.40077875991,
              5010693.4431144046
            ],
            [
              351954.24888047291,
              5010694.1052264972
            ],
            [
              351938.45606471761,
              5010699.3279120792
            ],
            [
              351931.04500822135,
              5010698.575889769
            ],
            [
              351927.1347315259,
              5010698.2379139261
            ],
            [
              351909.18539515254,
              5010702.1101048468
            ],
            [
              351904.17532287148,
              5010701.8582986956
            ],
            [
              351898.13811504049,
              5010702.0569041381
            ],
            [
              351890.23464276065,
              5010699.3575742701
            ],
            [
              351882.13498028443,
              5010700.0257909019
            ],
            [
              351881.67352673912,
              5010651.8703801716
            ],
            [
              351887.21586151415,
              5010653.0444102017
            ],
            [
              351893.89230750996,
              5010654.7315409472
            ],
            [
              351895.94199824828,
              5010653.6245355746
            ],
            [
              351896.87933888048,
              5010648.3455680246
            ],
            [
              351893.77935237071,
              5010639.4327972103
            ],
            [
              351890.83390481508,
              5010629.7166946316
            ],
            [
              351810.74709476455,
              5010585.6175627569
            ],
            [
              351807.84739520802,
              5010586.8889234951
            ],
            [
              351786.14563776634,
              5010597.1595936129
            ],
            [
              351782.33604016743,
              5010600.2364981407
            ],
            [
              351778.84461552306,
              5010602.40967819
            ],
            [
              351758.96275195788,
              5010612.8750482062
            ],
            [
              351730.18761798431,
              5010629.040219361
            ],
            [
              351721.42513448559,
              5010633.9394189073
            ],
            [
              351710.5898370035,
              5010641.3698579129
            ],
            [
              351696.61698822031,
              5010651.5372519176
            ],
            [
              351693.80059613037,
              5010654.4589384003
            ],
            [
              351690.46274921711,
              5010658.2869881457
            ],
            [
              351680.66459692386,
              5010662.055917277
            ],
            [
              351636.35849235958,
              5010743.4210140239
            ],
            [
              351640.96221185225,
              5010752.9174866071
            ],
            [
              351645.20826104644,
              5010756.7722426532
            ],
            [
              351651.97576317593,
              5010761.533684127
            ],
            [
              351655.31674985605,
              5010768.0277267518
            ],
            [
              351658.37945624435,
              5010772.3813044084
            ],
            [
              351660.38238604477,
              5010777.4698384814
            ],
            [
              351664.22078021866,
              5010791.4645064482
            ],
            [
              351664.54016580206,
              5010796.1908572074
            ],
            [
              351662.19904623012,
              5010809.3857947001
            ],
            [
              351658.12457084755,
              5010808.8313969467
            ],
            [
              351658.06461456389,
              5010811.0913567943
            ],
            [
              351657.65302794875,
              5010816.1516989293
            ],
            [
              351656.95704882586,
              5010822.2277859077
            ],
            [
              351656.14633643179,
              5010827.5991102261
            ],
            [
              351655.30819567951,
              5010831.4544271333
            ],
            [
              351654.265796847,
              5010835.2129189894
            ],
            [
              351652.8205050822,
              5010839.079953216
            ],
            [
              351651.36392783577,
              5010842.3405065453
            ],
            [
              351650.16235469462,
              5010844.3502293266
            ],
            [
              351648.00048497721,
              5010847.7253714763
            ],
            [
              351645.72872374271,
              5010850.6979581332
            ],
            [
              351643.85124655918,
              5010853.0573555063
            ],
            [
              351642.06516591803,
              5010854.9094064431
            ],
            [
              351640.27157456818,
              5010856.3578353133
            ],
            [
              351638.93209729373,
              5010857.5352665586
            ],
            [
              351642.61608788394,
              5010861.3552540364
            ],
            [
              351652.0656791206,
              5010870.4707171312
            ],
            [
              351667.8708226083,
              5010882.6643457608
            ],
            [
              351673.19836491451,
              5010888.4929410117
            ],
            [
              351676.3886484488,
              5010894.2032227749
            ],
            [
              351678.45425928931,
              5010906.3204648467
            ],
            [
              351683.89571819868,
              5010922.796308483
            ],
            [
              351684.16725152015,
              5010923.2807412967
            ],
            [
              351693.20363310463,
              5010937.0209228108
            ],
            [
              351698.67781873507,
              5010946.9782242989
            ],
            [
              351708.66440938198,
              5010956.9848620882
            ],
            [
              351716.37081830052,
              5010962.4761310536
            ],
            [
              351725.86334635521,
              5010965.3134175641
            ],
            [
              351735.39238464448,
              5010970.1155832643
            ],
            [
              351741.46758214972,
              5010972.4922598042
            ],
            [
              351745.17627217679,
              5010974.5198880285
            ],
            [
              351747.96273097553,
              5010976.3025770206
            ],
            [
              351764.31524610514,
              5010984.5000421843
            ],
            [
              351766.4335296264,
              5010982.4289803393
            ],
            [
              351778.38899165118,
              5010984.9546273183
            ],
            [
              351797.94920210162,
              5010982.3359132977
            ],
            [
              351808.76987282728,
              5010986.6713018538
            ],
            [
              351824.14151987369,
              5010990.7676777886
            ],
            [
              351823.30071984284,
              5010986.7644486139
            ],
            [
              351880.77908469102,
              5010962.4864410376
            ],
            [
              351914.81748182036,
              5010951.5381769706
            ],
            [
              351914.65706571593,
              5010955.5622005118
            ],
            [
              351924.25658550713,
              5010954.6802835679
            ],
            [
              351938.22246935876,
              5010936.3693771847
            ],
            [
              351964.3381981657,
              5010913.9113238119
            ],
            [
              352003.57257971674,
              5010892.2304674862
            ],
            [
              352011.46982041647,
              5010879.1485762047
            ],
            [
              352027.61730508815,
              5010849.3732271688
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 368,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352754.2823792632,
              5011459.0171570508
            ],
            [
              352783.25333197525,
              5011435.9638753459
            ],
            [
              352785.73496741895,
              5011418.2955530575
            ],
            [
              352789.66467161803,
              5011409.7944207266
            ],
            [
              352783.05163132993,
              5011406.5618039817
            ],
            [
              352762.96661810944,
              5011399.9747280451
            ],
            [
              352740.0111703343,
              5011447.5927251764
            ],
            [
              352730.71485877974,
              5011466.3759904103
            ],
            [
              352748.59094834415,
              5011468.9557118677
            ],
            [
              352754.2823792632,
              5011459.0171570508
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 369,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352693.16460637865,
              5011403.0446412992
            ],
            [
              352689.15869939036,
              5011405.2951783799
            ],
            [
              352667.55155769561,
              5011421.6861245949
            ],
            [
              352658.21999333374,
              5011436.3916474562
            ],
            [
              352689.7340934432,
              5011469.7993395803
            ],
            [
              352691.61411662475,
              5011468.8667696975
            ],
            [
              352706.72943219688,
              5011457.3481613807
            ],
            [
              352711.13752507052,
              5011460.7283199476
            ],
            [
              352721.99076174543,
              5011438.9055378148
            ],
            [
              352742.91778143594,
              5011395.4952447442
            ],
            [
              352746.01488388487,
              5011389.0707226647
            ],
            [
              352761.86894551484,
              5011356.1836109981
            ],
            [
              352779.38654464629,
              5011319.8457246106
            ],
            [
              352792.4692857152,
              5011292.7073569195
            ],
            [
              352783.07098939008,
              5011287.6038568225
            ],
            [
              352765.78144998517,
              5011282.9928809656
            ],
            [
              352744.78418585553,
              5011282.4064483549
            ],
            [
              352726.50192749233,
              5011277.1205166271
            ],
            [
              352709.61880789354,
              5011276.612340196
            ],
            [
              352699.78352930996,
              5011303.6191905541
            ],
            [
              352681.11735399399,
              5011340.6844614949
            ],
            [
              352715.74962841679,
              5011357.8492476596
            ],
            [
              352693.16460637865,
              5011403.0446412992
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 370,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356227.46887961944,
              5007995.1162241502
            ],
            [
              356216.77705834684,
              5008003.2636214066
            ],
            [
              356194.76169938262,
              5008021.8265574267
            ],
            [
              356198.74118504015,
              5008023.7208367111
            ],
            [
              356204.07218449091,
              5008028.7950337138
            ],
            [
              356210.84144599619,
              5008033.6799906911
            ],
            [
              356223.82951350411,
              5008043.5227038804
            ],
            [
              356237.5373972379,
              5008025.2826417806
            ],
            [
              356252.14194999845,
              5008010.9290573802
            ],
            [
              356269.96777242998,
              5007995.6292462368
            ],
            [
              356280.35615618207,
              5008002.7365395529
            ],
            [
              356285.84163802379,
              5008001.411035832
            ],
            [
              356289.08258377895,
              5008002.4759216923
            ],
            [
              356297.73305738723,
              5007983.8857382769
            ],
            [
              356311.1077801112,
              5007964.2455192013
            ],
            [
              356292.719966078,
              5007948.2830095589
            ],
            [
              356290.852417766,
              5007921.2280427804
            ],
            [
              356290.65991016297,
              5007917.8000168279
            ],
            [
              356294.24727453454,
              5007920.672304824
            ],
            [
              356297.92868932727,
              5007905.5829372592
            ],
            [
              356302.57648174948,
              5007894.0702964291
            ],
            [
              356308.70512699796,
              5007900.3743278896
            ],
            [
              356325.34203926974,
              5007877.9690992795
            ],
            [
              356317.25832027034,
              5007868.4022450605
            ],
            [
              356330.47266712616,
              5007859.0988643477
            ],
            [
              356342.30855525396,
              5007847.6596475393
            ],
            [
              356348.27906217537,
              5007840.4994829306
            ],
            [
              356353.95861158607,
              5007846.8782725409
            ],
            [
              356374.08283008781,
              5007827.0507297069
            ],
            [
              356376.74049307819,
              5007826.7099986188
            ],
            [
              356391.43598170526,
              5007815.8003956759
            ],
            [
              356395.99239495647,
              5007820.9354721997
            ],
            [
              356394.32841884193,
              5007822.5537976958
            ],
            [
              356397.08285790653,
              5007827.6079911292
            ],
            [
              356413.95101935609,
              5007813.3607357685
            ],
            [
              356416.15774486441,
              5007817.5741956439
            ],
            [
              356421.07198316924,
              5007822.1755316798
            ],
            [
              356432.96406227269,
              5007811.8689017501
            ],
            [
              356435.38807841495,
              5007814.936593214
            ],
            [
              356445.87682740827,
              5007805.3536313316
            ],
            [
              356438.5956348406,
              5007796.5962700229
            ],
            [
              356447.41576802154,
              5007788.9520832049
            ],
            [
              356446.08317811409,
              5007786.8528926447
            ],
            [
              356452.9568504878,
              5007778.875516125
            ],
            [
              356447.37765387766,
              5007774.5471308744
            ],
            [
              356442.82404538739,
              5007768.1675160136
            ],
            [
              356434.39566510887,
              5007777.0049098525
            ],
            [
              356429.62160046463,
              5007773.6768530281
            ],
            [
              356423.79452643014,
              5007778.3102614181
            ],
            [
              356416.9129485442,
              5007770.9581142552
            ],
            [
              356374.67744381772,
              5007803.0560960518
            ],
            [
              356373.73591559724,
              5007792.1766162952
            ],
            [
              356370.41977040685,
              5007772.8453647429
            ],
            [
              356367.94311380718,
              5007773.8149108719
            ],
            [
              356343.04696833092,
              5007786.4677947136
            ],
            [
              356326.55419626978,
              5007798.8720352696
            ],
            [
              356314.25565083692,
              5007811.4173679156
            ],
            [
              356298.30662921706,
              5007798.4159937818
            ],
            [
              356289.97266928857,
              5007787.4893089021
            ],
            [
              356276.5824422866,
              5007797.8042176627
            ],
            [
              356268.9719073319,
              5007810.8743917132
            ],
            [
              356254.11069133139,
              5007796.7446372565
            ],
            [
              356237.66776109359,
              5007827.3020090489
            ],
            [
              356246.71185835201,
              5007841.6323061967
            ],
            [
              356240.86452399887,
              5007850.1446560211
            ],
            [
              356239.7448426325,
              5007859.4925568849
            ],
            [
              356238.34598115482,
              5007863.7665619887
            ],
            [
              356237.76816798543,
              5007867.4712064788
            ],
            [
              356240.76745432487,
              5007869.7692647958
            ],
            [
              356241.4988649218,
              5007871.0500198817
            ],
            [
              356243.52609487559,
              5007873.3195045935
            ],
            [
              356263.51503377018,
              5007895.6971277371
            ],
            [
              356246.1449547924,
              5007910.6110640122
            ],
            [
              356250.4009036732,
              5007915.8880709177
            ],
            [
              356258.43976706517,
              5007922.6036486281
            ],
            [
              356272.16174583766,
              5007907.4936686307
            ],
            [
              356287.77051231818,
              5007918.4324969044
            ],
            [
              356288.24614057515,
              5007932.8209248148
            ],
            [
              356285.28034788207,
              5007947.9194363691
            ],
            [
              356284.6256984644,
              5007956.6623298042
            ],
            [
              356277.47931048129,
              5007964.3051793519
            ],
            [
              356272.90370596066,
              5007967.9525517514
            ],
            [
              356257.96919311472,
              5007986.668972848
            ],
            [
              356259.70697929122,
              5007987.9345890433
            ],
            [
              356242.83321400994,
              5007997.689577641
            ],
            [
              356239.48451155151,
              5007991.3960758131
            ],
            [
              356234.95421360253,
              5007989.4122392563
            ],
            [
              356227.46887961944,
              5007995.1162241502
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 371,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354173.98100202245,
              5009920.6492586369
            ],
            [
              354369.21277309716,
              5009756.8132162113
            ],
            [
              354301.69920149649,
              5009676.36203642
            ],
            [
              354057.967847533,
              5009889.87542402
            ],
            [
              354053.60589417053,
              5009894.5942758257
            ],
            [
              354048.92404761171,
              5009898.9959247839
            ],
            [
              354043.94531779119,
              5009903.0587380249
            ],
            [
              354038.69417374016,
              5009906.7627479639
            ],
            [
              354008.15348232159,
              5009917.5481748646
            ],
            [
              353957.3582486923,
              5009929.2633877685
            ],
            [
              353945.44753494178,
              5009932.1920774849
            ],
            [
              353969.57184867881,
              5010017.3361398708
            ],
            [
              354010.87276478129,
              5010004.7163212737
            ],
            [
              354027.29696505127,
              5010000.4954013061
            ],
            [
              354043.51304767927,
              5009995.5346396249
            ],
            [
              354059.48770437227,
              5009989.84422589
            ],
            [
              354075.18812274403,
              5009983.435848482
            ],
            [
              354090.58205370727,
              5009976.3226704951
            ],
            [
              354105.63787771825,
              5009968.5193026988
            ],
            [
              354120.32466972148,
              5009960.0417735269
            ],
            [
              354134.61226267024,
              5009950.9074961562
            ],
            [
              354148.13719597447,
              5009941.3517987812
            ],
            [
              354161.26718048268,
              5009931.2602846082
            ],
            [
              354173.98100202245,
              5009920.6492586369
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 372,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359124.18584543705,
              5007148.2352372725
            ],
            [
              359163.59032199439,
              5007095.5272840401
            ],
            [
              359174.214664114,
              5007080.2476000832
            ],
            [
              359179.87694562756,
              5007059.4446931183
            ],
            [
              359194.59994184918,
              5007039.9122287873
            ],
            [
              359219.93957379396,
              5007017.1659164289
            ],
            [
              359236.50231805543,
              5007009.2008622009
            ],
            [
              359244.95884292916,
              5007014.0085082343
            ],
            [
              359246.21249289345,
              5007053.5814958448
            ],
            [
              359241.03893397964,
              5007081.6280445876
            ],
            [
              359235.13752411824,
              5007100.9736112235
            ],
            [
              359227.21055515809,
              5007137.7211067835
            ],
            [
              359243.80498312676,
              5007119.0641276985
            ],
            [
              359264.0645690696,
              5007099.8577350415
            ],
            [
              359275.17622652446,
              5007080.9967840761
            ],
            [
              359272.46533807646,
              5007064.6777025284
            ],
            [
              359273.822762069,
              5007031.8882571394
            ],
            [
              359283.82781813765,
              5007006.6610714821
            ],
            [
              359215.01234741835,
              5006811.7057633791
            ],
            [
              359113.89036426943,
              5006753.5618286086
            ],
            [
              358964.81097805343,
              5006806.3545311224
            ],
            [
              358866.66453848261,
              5006908.2205045437
            ],
            [
              358870.38416358846,
              5007108.2329399874
            ],
            [
              358911.50255897286,
              5007167.4927261332
            ],
            [
              359130.57614414481,
              5007220.4417371973
            ],
            [
              359161.24783568463,
              5007201.6379776206
            ],
            [
              359129.08931318467,
              5007162.2980155172
            ],
            [
              359124.18584543705,
              5007148.2352372725
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 373,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359215.42060017079,
              5007162.5255551338
            ],
            [
              359227.21055516013,
              5007137.7211067863
            ],
            [
              359235.13752411772,
              5007100.9736112235
            ],
            [
              359241.03893397999,
              5007081.6280445801
            ],
            [
              359246.21249289537,
              5007053.581495842
            ],
            [
              359244.95884292916,
              5007014.0085082343
            ],
            [
              359236.50231805444,
              5007009.2008622009
            ],
            [
              359219.93957379292,
              5007017.165916428
            ],
            [
              359194.59994185093,
              5007039.9122287845
            ],
            [
              359179.87694562488,
              5007059.4446931221
            ],
            [
              359174.21466411831,
              5007080.2476000749
            ],
            [
              359163.59032197384,
              5007095.5272840951
            ],
            [
              359124.18584543566,
              5007148.2352372697
            ],
            [
              359129.08931318537,
              5007162.2980155172
            ],
            [
              359161.24783568567,
              5007201.6379776224
            ],
            [
              359214.23305705684,
              5007169.1545655178
            ],
            [
              359215.42060017079,
              5007162.5255551338
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 374,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357484.40990060155,
              5005695.0642007682
            ],
            [
              357491.29342061834,
              5005671.3281738972
            ],
            [
              357499.19857051276,
              5005653.0077562844
            ],
            [
              357481.59444010613,
              5005642.9930447843
            ],
            [
              357479.48633062793,
              5005647.4108054647
            ],
            [
              357464.35012922593,
              5005638.9351441301
            ],
            [
              357460.78686001623,
              5005648.9378203731
            ],
            [
              357454.75531095546,
              5005658.2643160159
            ],
            [
              357431.80862351524,
              5005648.698843712
            ],
            [
              357404.23915126827,
              5005637.6603919221
            ],
            [
              357403.06741729629,
              5005636.5567484582
            ],
            [
              357388.16051471635,
              5005661.692668953
            ],
            [
              357379.43427024345,
              5005649.3244743543
            ],
            [
              357377.84275075991,
              5005650.768815252
            ],
            [
              357382.93433924014,
              5005658.0509090321
            ],
            [
              357382.157394354,
              5005659.7832245417
            ],
            [
              357381.36160151591,
              5005660.5053956201
            ],
            [
              357380.45913927647,
              5005660.9263944449
            ],
            [
              357379.5641231999,
              5005661.7514385628
            ],
            [
              357378.77403129201,
              5005662.7766592279
            ],
            [
              357377.78549351305,
              5005664.0076614805
            ],
            [
              357376.71081401419,
              5005666.048668419
            ],
            [
              357375.95271812181,
              5005668.3527472494
            ],
            [
              357375.37609750405,
              5005669.9803099968
            ],
            [
              357374.60478731967,
              5005672.0156763969
            ],
            [
              357373.72298525641,
              5005673.5478497334
            ],
            [
              357372.53969235433,
              5005675.1866924213
            ],
            [
              357370.66177323746,
              5005677.5457897363
            ],
            [
              357369.07213159319,
              5005679.091124055
            ],
            [
              357367.28216640331,
              5005680.741246731
            ],
            [
              357364.98669546552,
              5005682.4007328153
            ],
            [
              357361.48908362369,
              5005684.6889172196
            ],
            [
              357359.59613894532,
              5005686.2398909796
            ],
            [
              357358.60190030379,
              5005687.1678437674
            ],
            [
              357358.11142079934,
              5005687.9853663901
            ],
            [
              357357.46375037316,
              5005691.2310564481
            ],
            [
              357353.43582765717,
              5005686.7586498186
            ],
            [
              357351.6439839626,
              5005688.3077438269
            ],
            [
              357356.70927869907,
              5005694.1756122429
            ],
            [
              357356.44166148914,
              5005696.1005498385
            ],
            [
              357355.92789735162,
              5005697.501461314
            ],
            [
              357355.4923173603,
              5005698.650920975
            ],
            [
              357360.22894468188,
              5005700.5717563126
            ],
            [
              357374.93252454372,
              5005706.7974764854
            ],
            [
              357389.04516431532,
              5005712.3120305231
            ],
            [
              357398.08751474571,
              5005715.8611731194
            ],
            [
              357412.40227867366,
              5005736.4399628136
            ],
            [
              357421.82323769754,
              5005736.4302439606
            ],
            [
              357421.78217978479,
              5005734.2220319062
            ],
            [
              357419.92661869054,
              5005719.3184446171
            ],
            [
              357423.5740556636,
              5005717.2409074344
            ],
            [
              357431.34408668621,
              5005720.6134553114
            ],
            [
              357444.12840517145,
              5005737.335485735
            ],
            [
              357450.4465007165,
              5005736.6244973289
            ],
            [
              357463.42650214926,
              5005715.6165294871
            ],
            [
              357476.4677581549,
              5005723.8113819379
            ],
            [
              357486.57892089774,
              5005709.637761903
            ],
            [
              357480.50179890619,
              5005706.7766357642
            ],
            [
              357482.96235880401,
              5005700.3980421843
            ],
            [
              357484.40990060155,
              5005695.0642007682
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 375,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358441.6950037324,
              5006493.4255013661
            ],
            [
              358436.80382783461,
              5006488.6148475129
            ],
            [
              358433.17792208988,
              5006483.8879196709
            ],
            [
              358426.96776986559,
              5006476.6658437159
            ],
            [
              358422.95916293078,
              5006471.9722197251
            ],
            [
              358415.4618969562,
              5006463.2591997478
            ],
            [
              358407.24476398982,
              5006451.5845998237
            ],
            [
              358401.53107631969,
              5006446.0529786572
            ],
            [
              358395.26694151951,
              5006439.4833473116
            ],
            [
              358392.23239721678,
              5006444.1478157919
            ],
            [
              358381.36563621456,
              5006461.0147538222
            ],
            [
              358375.73043836438,
              5006470.3950176723
            ],
            [
              358369.79547653312,
              5006479.0526178489
            ],
            [
              358362.57711068506,
              5006490.0568151018
            ],
            [
              358360.68212546851,
              5006486.5810260046
            ],
            [
              358363.54838748096,
              5006509.5851272019
            ],
            [
              358370.16252370243,
              5006524.6953939656
            ],
            [
              358378.08647260466,
              5006535.1935204696
            ],
            [
              358385.63379471173,
              5006542.76016533
            ],
            [
              358401.1801823962,
              5006553.3816936361
            ],
            [
              358412.38676586101,
              5006563.2148787696
            ],
            [
              358432.6467322614,
              5006580.5650831573
            ],
            [
              358450.90391627705,
              5006590.7299612118
            ],
            [
              358488.14743616822,
              5006603.4961835993
            ],
            [
              358490.46718704019,
              5006604.6287847552
            ],
            [
              358499.02297276299,
              5006609.3237557793
            ],
            [
              358503.47900818754,
              5006606.8380136546
            ],
            [
              358506.89117414696,
              5006604.125223279
            ],
            [
              358502.13214507961,
              5006597.4111381248
            ],
            [
              358496.41898585373,
              5006589.4046924906
            ],
            [
              358493.67364166834,
              5006583.5549743138
            ],
            [
              358491.34935657674,
              5006580.3707780093
            ],
            [
              358487.70945924905,
              5006576.0748853823
            ],
            [
              358479.64120830334,
              5006563.3312267046
            ],
            [
              358475.94676426094,
              5006553.9193691732
            ],
            [
              358473.32053622481,
              5006547.2016512183
            ],
            [
              358462.34007118928,
              5006530.9210565006
            ],
            [
              358453.56981306017,
              5006517.999547122
            ],
            [
              358445.26180920057,
              5006501.1197148236
            ],
            [
              358441.6950037324,
              5006493.4255013661
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 376,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356558.85307399574,
              5007001.9350840291
            ],
            [
              356563.40055595542,
              5007003.579648057
            ],
            [
              356571.39723082603,
              5007005.8374996046
            ],
            [
              356577.61698121816,
              5007009.0201485706
            ],
            [
              356580.14566252782,
              5007011.5827255137
            ],
            [
              356583.06803570775,
              5007013.8342581922
            ],
            [
              356591.57392061799,
              5007020.9171542125
            ],
            [
              356599.17901633971,
              5007026.4011217598
            ],
            [
              356603.37209640729,
              5007029.5168521497
            ],
            [
              356606.76493660972,
              5007030.8539923076
            ],
            [
              356619.16751552123,
              5007038.6200271137
            ],
            [
              356643.5611120867,
              5007055.2477305569
            ],
            [
              356646.86453491362,
              5007055.1723527247
            ],
            [
              356651.84561390156,
              5007057.8704738254
            ],
            [
              356664.7776978382,
              5007051.6965409638
            ],
            [
              356676.17419175251,
              5007060.4405589681
            ],
            [
              356667.2088530566,
              5007076.1222282648
            ],
            [
              356672.57705508621,
              5007085.4840476178
            ],
            [
              356676.67378733557,
              5007093.2535546292
            ],
            [
              356680.77051971125,
              5007101.023061458
            ],
            [
              356688.67338071519,
              5007116.1943422202
            ],
            [
              356679.8672250536,
              5007132.7845407752
            ],
            [
              356674.5981257087,
              5007140.3544036346
            ],
            [
              356681.85820631607,
              5007145.0711626587
            ],
            [
              356690.93765496596,
              5007148.4074774263
            ],
            [
              356702.82766907796,
              5007159.2026135819
            ],
            [
              356716.28035476519,
              5007171.6191852195
            ],
            [
              356718.02845095115,
              5007168.8565928247
            ],
            [
              356725.74089727475,
              5007168.1700300155
            ],
            [
              356738.16602139076,
              5007180.3999673706
            ],
            [
              356757.59116626653,
              5007192.9803455053
            ],
            [
              356757.38927305158,
              5007191.0319957798
            ],
            [
              356772.39678357105,
              5007203.870222155
            ],
            [
              356779.07116927102,
              5007197.011799586
            ],
            [
              356782.48932999792,
              5007199.3507884163
            ],
            [
              356796.86937145592,
              5007164.5263434434
            ],
            [
              356803.1648859136,
              5007164.5084469654
            ],
            [
              356815.84269093501,
              5007180.5470549185
            ],
            [
              356821.65932271112,
              5007192.1107544089
            ],
            [
              356827.54445726803,
              5007196.8966922434
            ],
            [
              356892.67542956403,
              5007146.5082762372
            ],
            [
              356925.7287210981,
              5007182.7120016366
            ],
            [
              356928.54057115654,
              5007178.7116006929
            ],
            [
              356932.6842053844,
              5007172.7614225037
            ],
            [
              356942.89412410167,
              5007158.1001786646
            ],
            [
              356952.85031155281,
              5007144.3540927684
            ],
            [
              356956.29987135966,
              5007139.5914320629
            ],
            [
              356972.82170500938,
              5007116.2328306045
            ],
            [
              356968.72961895354,
              5007109.1991434982
            ],
            [
              356972.85981075931,
              5007105.3013734836
            ],
            [
              356977.5100814015,
              5007102.0416069524
            ],
            [
              356981.18853402621,
              5007099.3950526007
            ],
            [
              356996.81203823764,
              5007081.4985234579
            ],
            [
              357007.03736230178,
              5007064.636560088
            ],
            [
              357015.40705979272,
              5007049.9260937143
            ],
            [
              357010.21484834759,
              5007048.7317363424
            ],
            [
              357037.65010738379,
              5007007.3828380685
            ],
            [
              357038.14675410051,
              5007006.2849777676
            ],
            [
              357030.88960739528,
              5007004.8351309532
            ],
            [
              357025.37149480364,
              5007003.7327117193
            ],
            [
              357017.79509449581,
              5007004.3947096458
            ],
            [
              357011.88196680875,
              5007004.1572761061
            ],
            [
              357009.30598968692,
              5007002.6998259854
            ],
            [
              357004.71121703938,
              5006996.7075220784
            ],
            [
              356995.90546710562,
              5007000.9770566523
            ],
            [
              356990.7170490725,
              5007000.3524038782
            ],
            [
              356985.83547560341,
              5007000.2995091239
            ],
            [
              356979.03713263822,
              5006996.084904233
            ],
            [
              356977.4973503233,
              5006994.7553622071
            ],
            [
              356973.0436219938,
              5006990.7815748956
            ],
            [
              356968.62515584013,
              5006982.1294178553
            ],
            [
              356964.88668558589,
              5006978.1959591154
            ],
            [
              356957.95216077758,
              5006969.2460805122
            ],
            [
              356953.85526896088,
              5006963.958524839
            ],
            [
              356947.97187560564,
              5006959.0609274507
            ],
            [
              356941.02940281847,
              5006956.1388949249
            ],
            [
              356934.08692990337,
              5006953.2168622809
            ],
            [
              356927.06665784109,
              5006951.2543258509
            ],
            [
              356889.11958571349,
              5006942.128061532
            ],
            [
              356885.29996707966,
              5006941.2719917838
            ],
            [
              356873.89113834774,
              5006939.1019684663
            ],
            [
              356854.98756827781,
              5006934.3914967664
            ],
            [
              356851.78316946566,
              5006933.3602217603
            ],
            [
              356843.64887687023,
              5006931.5013317773
            ],
            [
              356834.6473030487,
              5006930.8680841718
            ],
            [
              356825.11204018578,
              5006929.3527638074
            ],
            [
              356812.86744421726,
              5006909.811527079
            ],
            [
              356807.03345663211,
              5006913.6953619374
            ],
            [
              356793.1812866492,
              5006891.3859867277
            ],
            [
              356788.68345653411,
              5006892.1967439149
            ],
            [
              356767.34023507894,
              5006903.933754757
            ],
            [
              356766.33699293324,
              5006905.7667225078
            ],
            [
              356755.02407938993,
              5006919.9582482278
            ],
            [
              356736.44903908583,
              5006943.7269413741
            ],
            [
              356726.99098472093,
              5006956.165157387
            ],
            [
              356722.79006989574,
              5006952.7187134102
            ],
            [
              356720.81051935855,
              5006950.8002334572
            ],
            [
              356715.86131737434,
              5006945.6402751254
            ],
            [
              356711.22032628756,
              5006940.8016523728
            ],
            [
              356708.26431946957,
              5006937.7197670797
            ],
            [
              356703.15205957438,
              5006932.4529983634
            ],
            [
              356690.996458376,
              5006920.3930305569
            ],
            [
              356680.98221519851,
              5006908.5499975495
            ],
            [
              356673.63571414939,
              5006902.2708173143
            ],
            [
              356648.4908998959,
              5006883.6065317979
            ],
            [
              356658.80982635397,
              5006870.1463135285
            ],
            [
              356657.53608032182,
              5006868.1292510284
            ],
            [
              356655.48727486591,
              5006866.4798979824
            ],
            [
              356652.1140240368,
              5006863.5665448783
            ],
            [
              356625.7815243298,
              5006833.1069955435
            ],
            [
              356609.87222724792,
              5006813.206468733
            ],
            [
              356634.58043088386,
              5006791.2932244698
            ],
            [
              356649.6326499792,
              5006775.8367676511
            ],
            [
              356647.13071147801,
              5006773.0339734172
            ],
            [
              356638.74707412097,
              5006763.1429062169
            ],
            [
              356622.85520813317,
              5006757.279610212
            ],
            [
              356618.42619199731,
              5006756.1116430042
            ],
            [
              356621.62819384196,
              5006742.9631317938
            ],
            [
              356618.07219725096,
              5006740.1936704013
            ],
            [
              356617.05917374376,
              5006740.1114448691
            ],
            [
              356615.52561603632,
              5006739.2304944517
            ],
            [
              356603.6531032396,
              5006733.1533559747
            ],
            [
              356593.19602291187,
              5006741.9372221734
            ],
            [
              356589.23764926073,
              5006741.2024280569
            ],
            [
              356588.79406858905,
              5006744.5453961222
            ],
            [
              356594.16799199878,
              5006745.2538663121
            ],
            [
              356603.43238864117,
              5006737.6037405785
            ],
            [
              356604.13452788419,
              5006737.2875271793
            ],
            [
              356604.53899905045,
              5006737.2800055491
            ],
            [
              356605.24677390099,
              5006737.2668436207
            ],
            [
              356606.26731114573,
              5006737.7531141005
            ],
            [
              356619.44782776356,
              5006744.8848206084
            ],
            [
              356615.31235237466,
              5006756.3805988915
            ],
            [
              356624.01201582543,
              5006761.8777225735
            ],
            [
              356621.8197991061,
              5006769.0931758778
            ],
            [
              356581.4342270568,
              5006756.7074491661
            ],
            [
              356576.82013951225,
              5006759.7832609043
            ],
            [
              356572.22343012394,
              5006762.7992416257
            ],
            [
              356570.72744281276,
              5006763.9386226842
            ],
            [
              356569.63210358442,
              5006764.868460835
            ],
            [
              356569.04045869678,
              5006765.6879037404
            ],
            [
              356568.35146878054,
              5006766.7112137135
            ],
            [
              356567.87407450954,
              5006768.2358729979
            ],
            [
              356567.50160460838,
              5006769.9606734365
            ],
            [
              356567.34442098642,
              5006772.3888469115
            ],
            [
              356567.13350670232,
              5006782.8011474665
            ],
            [
              356566.98571570253,
              5006785.7343949405
            ],
            [
              356566.62451719464,
              5006788.0652984111
            ],
            [
              356565.95431373786,
              5006790.0988274943
            ],
            [
              356564.59881107847,
              5006793.3576903427
            ],
            [
              356562.54311419843,
              5006797.0337945595
            ],
            [
              356559.54584000335,
              5006800.7025960144
            ],
            [
              356556.77297566744,
              5006803.8867883859
            ],
            [
              356554.68158605805,
              5006805.6435546558
            ],
            [
              356552.68371764745,
              5006806.9943970582
            ],
            [
              356550.77762350003,
              5006807.8382485714
            ],
            [
              356548.46148869634,
              5006808.3865690585
            ],
            [
              356546.44114382332,
              5006808.5252041901
            ],
            [
              356544.41321823705,
              5006808.259760011
            ],
            [
              356542.98264005472,
              5006807.4779586131
            ],
            [
              356532.20808407612,
              5006799.1899492852
            ],
            [
              356527.27647623455,
              5006805.951098877
            ],
            [
              356530.25758601457,
              5006808.5230040224
            ],
            [
              356530.75000839448,
              5006807.8064700952
            ],
            [
              356539.26062244287,
              5006814.0144861331
            ],
            [
              356540.39165447425,
              5006815.00398592
            ],
            [
              356541.42527590541,
              5006816.1973895598
            ],
            [
              356542.05824925256,
              5006817.6003361912
            ],
            [
              356542.69685894583,
              5006819.3063698765
            ],
            [
              356543.03779993177,
              5006821.321059918
            ],
            [
              356543.20277183014,
              5006824.7537400303
            ],
            [
              356543.07759084966,
              5006828.8992281221
            ],
            [
              356542.72384083382,
              5006831.6342135752
            ],
            [
              356541.89105513436,
              5006836.4684570329
            ],
            [
              356540.56373132195,
              5006841.242577916
            ],
            [
              356538.25545241963,
              5006847.6517868126
            ],
            [
              356535.23557479918,
              5006853.8721011532
            ],
            [
              356533.86880185653,
              5006856.5248970166
            ],
            [
              356532.38784304674,
              5006858.4724041848
            ],
            [
              356531.20267413947,
              5006860.0102259405
            ],
            [
              356529.70856576686,
              5006861.2506365832
            ],
            [
              356528.30985667097,
              5006862.1861167923
            ],
            [
              356525.80460328475,
              5006863.4453312876
            ],
            [
              356523.09714738128,
              5006864.7083060769
            ],
            [
              356520.88774945779,
              5006865.557798394
            ],
            [
              356518.97977714584,
              5006866.3006568225
            ],
            [
              356516.87892872043,
              5006867.5523508294
            ],
            [
              356511.12662473129,
              5006870.8596356437
            ],
            [
              356509.22992317262,
              5006872.2085616877
            ],
            [
              356506.83898745058,
              5006874.1730270926
            ],
            [
              356504.65394491924,
              5006876.3357560001
            ],
            [
              356502.66741355805,
              5006878.2926653577
            ],
            [
              356500.48424982477,
              5006880.5564233502
            ],
            [
              356498.69247335615,
              5006882.10552618
            ],
            [
              356496.19473436777,
              5006883.7688215254
            ],
            [
              356493.49472593481,
              5006885.435842881
            ],
            [
              356491.39018673496,
              5006886.4855133304
            ],
            [
              356489.17884426954,
              5006887.2340136012
            ],
            [
              356487.2671142277,
              5006887.7748141447
            ],
            [
              356485.04644464509,
              5006888.0182035575
            ],
            [
              356482.52240566671,
              5006888.2672701739
            ],
            [
              356480.39908037597,
              5006888.3067569043
            ],
            [
              356478.5846946091,
              5006888.6436547842
            ],
            [
              356476.36771565943,
              5006889.0890679806
            ],
            [
              356473.85119050491,
              5006889.7421796983
            ],
            [
              356470.32922215975,
              5006890.7171456963
            ],
            [
              356467.86364894331,
              5006891.3160584597
            ],
            [
              356466.04550616822,
              5006891.4509338206
            ],
            [
              356464.52885464008,
              5006891.4791384675
            ],
            [
              356463.00656735757,
              5006891.2042915262
            ],
            [
              356460.88129689213,
              5006891.142750469
            ],
            [
              356457.94909505191,
              5006891.1972796163
            ],
            [
              356455.32388769364,
              5006891.4481921224
            ],
            [
              356452.80360544566,
              5006891.8992813388
            ],
            [
              356449.18241442117,
              5006892.9771566121
            ],
            [
              356446.27268922544,
              5006894.2438934436
            ],
            [
              356443.67572678439,
              5006896.0100627914
            ],
            [
              356441.38958271558,
              5006898.1746723857
            ],
            [
              356439.40868686995,
              5006900.4346338417
            ],
            [
              356437.93900008005,
              5006902.9882804975
            ],
            [
              356437.0646494432,
              5006904.9245430445
            ],
            [
              356436.29898025125,
              5006907.2629693486
            ],
            [
              356435.45099589642,
              5006910.6134597166
            ],
            [
              356435.09912523313,
              5006913.4494751552
            ],
            [
              356435.14045375463,
              5006915.6718299855
            ],
            [
              356435.79221382452,
              5006918.0849607624
            ],
            [
              356436.84650020505,
              5006920.3895418607
            ],
            [
              356438.30156724394,
              5006922.484577301
            ],
            [
              356439.64419520803,
              5006923.9753908822
            ],
            [
              356441.17963298463,
              5006924.9573699692
            ],
            [
              356442.40425234294,
              5006925.54090886
            ],
            [
              356443.72621600592,
              5006925.9205450276
            ],
            [
              356445.64733897138,
              5006925.8848183667
            ],
            [
              356447.86243859498,
              5006925.3383403094
            ],
            [
              356450.78524777363,
              5006924.7787367059
            ],
            [
              356454.10883689357,
              5006924.0095519368
            ],
            [
              356457.02976720891,
              5006923.3489193106
            ],
            [
              356459.55380700779,
              5006923.0998879541
            ],
            [
              356462.78562192299,
              5006922.8376942845
            ],
            [
              356464.91464934539,
              5006923.1012576949
            ],
            [
              356467.44995997549,
              5006923.4582939846
            ],
            [
              356468.77380235237,
              5006923.938959172
            ],
            [
              356469.69505155791,
              5006924.528139716
            ],
            [
              356470.11267258035,
              5006925.2277145395
            ],
            [
              356470.32990429137,
              5006926.0321154799
            ],
            [
              356470.15024486778,
              5006927.2480821311
            ],
            [
              356469.49342883349,
              5006929.6664584596
            ],
            [
              356468.04246229917,
              5006933.2302900515
            ],
            [
              356465.40827142244,
              5006938.4329362642
            ],
            [
              356463.36378017796,
              5006942.7151112827
            ],
            [
              356462.0121024563,
              5006946.1760325544
            ],
            [
              356460.78406922828,
              5006950.8472800637
            ],
            [
              356460.42475055129,
              5006953.279213463
            ],
            [
              356459.97400039661,
              5006961.6749112634
            ],
            [
              356455.35440471891,
              5006958.0218803901
            ],
            [
              356453.48024708725,
              5006960.5830490673
            ],
            [
              356470.03581097996,
              5006974.0705609033
            ],
            [
              356475.01870546915,
              5006976.5353713036
            ],
            [
              356497.59270240663,
              5006947.8186384253
            ],
            [
              356526.37217326398,
              5006978.5788622126
            ],
            [
              356533.82602953102,
              5006978.9504501987
            ],
            [
              356543.94701010775,
              5006985.4843362989
            ],
            [
              356554.69327424077,
              5007000.7625969891
            ],
            [
              356558.85307399574,
              5007001.9350840291
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 377,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356292.57587033854,
              5007565.7235526377
            ],
            [
              356284.40920433466,
              5007557.5385848489
            ],
            [
              356279.40237521083,
              5007562.1246501785
            ],
            [
              356272.76869594975,
              5007568.2102543777
            ],
            [
              356269.49624050286,
              5007571.3017766671
            ],
            [
              356266.55173757829,
              5007574.0106530981
            ],
            [
              356253.67103720008,
              5007585.8622214934
            ],
            [
              356250.41630079527,
              5007588.8731512167
            ],
            [
              356248.2659377752,
              5007590.8171362914
            ],
            [
              356245.84129961749,
              5007593.1327448515
            ],
            [
              356243.4777110889,
              5007595.5142058348
            ],
            [
              356242.14254128851,
              5007596.9027849557
            ],
            [
              356240.81175215944,
              5007598.3235760285
            ],
            [
              356239.81320483971,
              5007599.406770125
            ],
            [
              356239.51818746148,
              5007599.8034796435
            ],
            [
              356238.92161955708,
              5007600.5957996752
            ],
            [
              356238.54060221981,
              5007600.923969538
            ],
            [
              356237.98088631878,
              5007601.7569913734
            ],
            [
              356237.12229611381,
              5007602.9961183434
            ],
            [
              356236.19955827203,
              5007604.1808652887
            ],
            [
              356234.96729285683,
              5007605.7588700932
            ],
            [
              356234.0416172017,
              5007606.9411106287
            ],
            [
              356233.04455585935,
              5007608.0627831342
            ],
            [
              356231.01227563707,
              5007610.2737641037
            ],
            [
              356228.97497379076,
              5007612.4804666787
            ],
            [
              356227.95442232589,
              5007613.582217643
            ],
            [
              356225.63054444344,
              5007616.2016495261
            ],
            [
              356224.55326205672,
              5007617.9032945074
            ],
            [
              356223.20219191263,
              5007619.9963121563
            ],
            [
              356221.18765709136,
              5007622.7617537864
            ],
            [
              356219.02109587746,
              5007625.9379725046
            ],
            [
              356217.01659693138,
              5007629.1273638224
            ],
            [
              356215.17776975135,
              5007632.3158347774
            ],
            [
              356213.31458477321,
              5007635.892249845
            ],
            [
              356211.5476449636,
              5007639.9153913194
            ],
            [
              356213.78056626866,
              5007642.4625254227
            ],
            [
              356217.7513152812,
              5007647.199773673
            ],
            [
              356221.48854103131,
              5007651.3788276426
            ],
            [
              356224.67663010204,
              5007654.9438239448
            ],
            [
              356236.06045271241,
              5007661.2928301822
            ],
            [
              356236.91827022721,
              5007661.4119289871
            ],
            [
              356256.50532824273,
              5007653.4327617148
            ],
            [
              356264.65588782768,
              5007647.860987749
            ],
            [
              356280.42205814656,
              5007631.0468737585
            ],
            [
              356285.89172822842,
              5007624.2227022927
            ],
            [
              356297.34633028175,
              5007606.95294875
            ],
            [
              356297.65304863226,
              5007603.646055867
            ],
            [
              356299.1424122763,
              5007589.9814463183
            ],
            [
              356298.87669459701,
              5007581.9315068172
            ],
            [
              356298.0257498867,
              5007576.2465010155
            ],
            [
              356292.57587033854,
              5007565.7235526377
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 378,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358249.06047154352,
              5005260.9552226281
            ],
            [
              358254.4527769335,
              5005247.2449152712
            ],
            [
              358253.50438052189,
              5005246.2418080904
            ],
            [
              358247.01306945167,
              5005239.4890261088
            ],
            [
              358236.1777075096,
              5005227.9954026137
            ],
            [
              358230.51105099061,
              5005221.3785047662
            ],
            [
              358215.53236811748,
              5005203.8880461231
            ],
            [
              358213.08139455545,
              5005205.1702841148
            ],
            [
              358206.65117829124,
              5005195.5917729558
            ],
            [
              358192.31902419968,
              5005206.8433132237
            ],
            [
              358185.61976966844,
              5005199.565380794
            ],
            [
              358175.95819648827,
              5005189.3895704923
            ],
            [
              358171.9849681051,
              5005185.7153942846
            ],
            [
              358169.06125600863,
              5005182.8830238581
            ],
            [
              358166.06000498263,
              5005179.4999039639
            ],
            [
              358157.66891332349,
              5005168.6313360231
            ],
            [
              358154.51441542729,
              5005164.6750736143
            ],
            [
              358153.94634293637,
              5005163.6560336985
            ],
            [
              358153.04654606775,
              5005164.1510858042
            ],
            [
              358147.85685756285,
              5005168.0740876207
            ],
            [
              358138.80237265222,
              5005175.1301657818
            ],
            [
              358138.05132780416,
              5005175.478971798
            ],
            [
              358135.7270942707,
              5005177.3429786749
            ],
            [
              358135.15096720064,
              5005178.3311907044
            ],
            [
              358134.68207040819,
              5005179.9306954648
            ],
            [
              358134.91773548274,
              5005180.5147370975
            ],
            [
              358138.32024721178,
              5005185.0169621352
            ],
            [
              358141.15891635854,
              5005188.5931610735
            ],
            [
              358145.84868328524,
              5005193.0324488711
            ],
            [
              358151.49967919983,
              5005196.2632015636
            ],
            [
              358161.56008044083,
              5005203.9584647696
            ],
            [
              358168.1075505034,
              5005209.6118770828
            ],
            [
              358169.26787682733,
              5005210.5871691331
            ],
            [
              358158.40127787931,
              5005219.419248173
            ],
            [
              358173.70432086108,
              5005235.7076306744
            ],
            [
              358186.01730646874,
              5005250.7344270032
            ],
            [
              358193.06589940708,
              5005260.9395441972
            ],
            [
              358200.62007277453,
              5005271.7119226772
            ],
            [
              358199.76624055271,
              5005273.7322014924
            ],
            [
              358198.5861279809,
              5005274.4089517687
            ],
            [
              358205.79225738026,
              5005283.5943783382
            ],
            [
              358210.94110745454,
              5005290.1574553298
            ],
            [
              358216.2909665886,
              5005288.3007547027
            ],
            [
              358221.27107615321,
              5005286.6684981352
            ],
            [
              358226.07607041154,
              5005293.2398394262
            ],
            [
              358238.58521682175,
              5005285.3364102663
            ],
            [
              358244.94004184072,
              5005281.3145973636
            ],
            [
              358245.00654398074,
              5005276.5326968012
            ],
            [
              358249.06047154352,
              5005260.9552226281
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 379,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352061.01154578646,
              5011574.4422819829
            ],
            [
              352059.30035769817,
              5011573.5923371175
            ],
            [
              352057.99465880991,
              5011573.0021873061
            ],
            [
              352055.23057009681,
              5011571.91107185
            ],
            [
              352053.82329281198,
              5011571.4337685378
            ],
            [
              352051.32574501907,
              5011570.7099200031
            ],
            [
              352049.00018890615,
              5011570.1364137894
            ],
            [
              352047.48493801488,
              5011569.7521357229
            ],
            [
              352019.17054288817,
              5011605.7602243964
            ],
            [
              351999.98793288012,
              5011593.4353971165
            ],
            [
              351977.07213262585,
              5011627.2503007213
            ],
            [
              351976.15548080427,
              5011630.6599568762
            ],
            [
              351975.56919868797,
              5011632.7096342091
            ],
            [
              351974.74344700103,
              5011635.3715555612
            ],
            [
              351973.49096954998,
              5011639.5837008692
            ],
            [
              351972.19377881766,
              5011643.8152765539
            ],
            [
              351971.44129136432,
              5011646.5288853049
            ],
            [
              351970.70041235554,
              5011649.2557293558
            ],
            [
              351969.76172563812,
              5011652.7761035627
            ],
            [
              351968.68663466745,
              5011656.7513775807
            ],
            [
              351967.68355731008,
              5011660.2814826407
            ],
            [
              351966.34900648118,
              5011664.9795677923
            ],
            [
              351965.11407509941,
              5011669.5963497637
            ],
            [
              351999.72143570258,
              5011701.6294888426
            ],
            [
              352009.4686289833,
              5011694.6233675918
            ],
            [
              352017.71819357999,
              5011681.8772547506
            ],
            [
              352029.42367386556,
              5011664.5158819277
            ],
            [
              352027.44730453234,
              5011659.1782228574
            ],
            [
              352028.05924166186,
              5011657.3779011741
            ],
            [
              352040.45539149392,
              5011638.0726492004
            ],
            [
              352046.00373832259,
              5011631.1828276832
            ],
            [
              352052.14228410699,
              5011623.1328495005
            ],
            [
              352060.97823907511,
              5011611.7677953104
            ],
            [
              352062.64506049408,
              5011609.4980020029
            ],
            [
              352064.46607366652,
              5011607.1176139778
            ],
            [
              352046.7188101678,
              5011595.218135071
            ],
            [
              352049.10993083147,
              5011592.3696655137
            ],
            [
              352044.22089947591,
              5011588.7027134029
            ],
            [
              352061.01154578646,
              5011574.4422819829
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 380,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352274.42452519765,
              5011244.3500304036
            ],
            [
              352276.01482173539,
              5011245.4974467522
            ],
            [
              352289.80216200597,
              5011255.4451632528
            ],
            [
              352292.29810074752,
              5011251.0358093008
            ],
            [
              352307.4470334567,
              5011263.5023099827
            ],
            [
              352315.86610739963,
              5011256.3174239937
            ],
            [
              352327.118551269,
              5011247.4128373247
            ],
            [
              352323.71630919882,
              5011243.5119915437
            ],
            [
              352325.64874992787,
              5011235.4087663963
            ],
            [
              352322.75457532011,
              5011235.4626250779
            ],
            [
              352299.8313006169,
              5011227.7629089961
            ],
            [
              352295.42639179347,
              5011235.8813179266
            ],
            [
              352289.49315294746,
              5011241.081451457
            ],
            [
              352274.42452519765,
              5011244.3500304036
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 381,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352419.93111834204,
              5011214.8564607129
            ],
            [
              352444.32195542584,
              5011188.7384270355
            ],
            [
              352442.85635286674,
              5011187.2920701113
            ],
            [
              352440.37186369149,
              5011186.0152260596
            ],
            [
              352429.94785204978,
              5011183.4285000805
            ],
            [
              352426.84528079658,
              5011182.3589316551
            ],
            [
              352412.80801715277,
              5011174.4802198093
            ],
            [
              352402.01931428793,
              5011176.0024832105
            ],
            [
              352381.15722673171,
              5011191.6478967937
            ],
            [
              352361.65696623077,
              5011209.4304508744
            ],
            [
              352361.24007602065,
              5011210.3434546907
            ],
            [
              352361.4055038045,
              5011210.5125751188
            ],
            [
              352361.04684627702,
              5011212.1601051074
            ],
            [
              352365.8087710382,
              5011220.9557959037
            ],
            [
              352377.76412385795,
              5011232.0970473522
            ],
            [
              352392.39597266092,
              5011229.7953618867
            ],
            [
              352400.76797449961,
              5011237.8574404633
            ],
            [
              352411.08646592294,
              5011239.9637159985
            ],
            [
              352418.58412492258,
              5011239.6658113739
            ],
            [
              352422.04662156111,
              5011239.5185427209
            ],
            [
              352423.37173201569,
              5011239.4938832773
            ],
            [
              352424.95169511688,
              5011239.3642241303
            ],
            [
              352445.18147830362,
              5011230.1217621388
            ],
            [
              352419.93111834204,
              5011214.8564607129
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 382,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352707.01711926301,
              5011757.4579022629
            ],
            [
              352718.95361237013,
              5011788.2169533903
            ],
            [
              352737.28681510838,
              5011778.140110963
            ],
            [
              352743.75463742157,
              5011773.9204482054
            ],
            [
              352750.22245966695,
              5011769.7007856118
            ],
            [
              352746.96398670238,
              5011767.3719190089
            ],
            [
              352733.00836946111,
              5011759.6482438399
            ],
            [
              352722.46932322526,
              5011743.1491941242
            ],
            [
              352728.88542858674,
              5011738.889951244
            ],
            [
              352726.18708910694,
              5011734.7768954895
            ],
            [
              352730.86959374376,
              5011731.077893056
            ],
            [
              352741.23756038042,
              5011721.2651092354
            ],
            [
              352734.14121851005,
              5011711.7530213669
            ],
            [
              352726.83755350864,
              5011708.4823564505
            ],
            [
              352717.44564559561,
              5011691.1650190484
            ],
            [
              352703.26523495727,
              5011702.0160563216
            ],
            [
              352710.15433544229,
              5011711.892164574
            ],
            [
              352693.0365800432,
              5011726.8309326172
            ],
            [
              352697.41392713535,
              5011732.2858292926
            ],
            [
              352701.05566816736,
              5011739.9884109152
            ],
            [
              352702.39977951132,
              5011745.0393635798
            ],
            [
              352707.01711926301,
              5011757.4579022629
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 383,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353479.6074887822,
              5011302.8899649754
            ],
            [
              353499.55428578838,
              5011317.2004590724
            ],
            [
              353501.91736560519,
              5011322.4129231917
            ],
            [
              353485.46941515396,
              5011347.0998827014
            ],
            [
              353482.27093863889,
              5011355.544385707
            ],
            [
              353480.53181682521,
              5011359.1887209797
            ],
            [
              353480.02892146038,
              5011373.7750198385
            ],
            [
              353470.34087692323,
              5011381.12479435
            ],
            [
              353464.86801397271,
              5011392.1916127866
            ],
            [
              353486.77181306353,
              5011411.0048804805
            ],
            [
              353490.17907296866,
              5011413.779439236
            ],
            [
              353495.41779674013,
              5011409.8512263959
            ],
            [
              353506.53380429681,
              5011424.6083000973
            ],
            [
              353505.19617324445,
              5011429.0191671327
            ],
            [
              353524.08750951145,
              5011452.4034887962
            ],
            [
              353528.79037532391,
              5011447.1611703066
            ],
            [
              353538.35996647569,
              5011455.1100475751
            ],
            [
              353544.23063899745,
              5011458.4837754527
            ],
            [
              353549.51503364806,
              5011451.1614590231
            ],
            [
              353551.27335261041,
              5011448.5487485146
            ],
            [
              353558.56187729863,
              5011435.5384855969
            ],
            [
              353551.37505222321,
              5011431.8033324024
            ],
            [
              353555.91219068534,
              5011427.3507944793
            ],
            [
              353566.28296053997,
              5011414.3030400276
            ],
            [
              353573.42696253437,
              5011402.3137418022
            ],
            [
              353580.80896910909,
              5011389.6960196448
            ],
            [
              353587.67087473575,
              5011380.7729198234
            ],
            [
              353582.70348087669,
              5011376.8768490413
            ],
            [
              353603.99127793143,
              5011360.0066203997
            ],
            [
              353610.27133069729,
              5011355.2720306693
            ],
            [
              353607.74702010688,
              5011353.8213646924
            ],
            [
              353611.75475037139,
              5011347.7562119747
            ],
            [
              353607.02717547904,
              5011341.9784463206
            ],
            [
              353605.63585589948,
              5011340.5686010495
            ],
            [
              353601.45458934957,
              5011344.016108701
            ],
            [
              353578.19568004692,
              5011325.6774081653
            ],
            [
              353573.55591485259,
              5011334.4262384903
            ],
            [
              353564.96023356757,
              5011349.1404563263
            ],
            [
              353560.61088943272,
              5011351.2686761823
            ],
            [
              353528.78276851424,
              5011324.000983391
            ],
            [
              353532.24792745797,
              5011318.5484778769
            ],
            [
              353525.99112592108,
              5011314.1894164588
            ],
            [
              353513.14559137152,
              5011305.7323687375
            ],
            [
              353509.20943529636,
              5011303.6316197626
            ],
            [
              353505.71448760043,
              5011300.8885445837
            ],
            [
              353508.17035301629,
              5011296.4948102953
            ],
            [
              353502.26067833393,
              5011291.673104248
            ],
            [
              353493.73406969762,
              5011285.2683207775
            ],
            [
              353479.6074887822,
              5011302.8899649754
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 384,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353539.13638929644,
              5011272.20442509
            ],
            [
              353548.72989719367,
              5011280.4871305237
            ],
            [
              353552.69177207997,
              5011275.99557932
            ],
            [
              353574.41437720956,
              5011295.690844289
            ],
            [
              353586.91961379192,
              5011279.2844471717
            ],
            [
              353602.99409579515,
              5011292.3248145562
            ],
            [
              353609.72182664572,
              5011287.4748383379
            ],
            [
              353617.46761003946,
              5011282.4245062927
            ],
            [
              353613.44794396398,
              5011266.7606678251
            ],
            [
              353616.42127521225,
              5011266.4521490829
            ],
            [
              353622.42032401176,
              5011261.5248897327
            ],
            [
              353634.04346074787,
              5011255.9478032608
            ],
            [
              353646.14549138537,
              5011245.4504869478
            ],
            [
              353630.94584676647,
              5011224.1525884457
            ],
            [
              353635.52934038854,
              5011216.4349959092
            ],
            [
              353606.36708857823,
              5011200.0879341494
            ],
            [
              353597.20635756006,
              5011207.273134646
            ],
            [
              353594.0315094656,
              5011209.2020885749
            ],
            [
              353598.01777429518,
              5011217.5793256024
            ],
            [
              353586.33073672961,
              5011226.4649510887
            ],
            [
              353565.62927683041,
              5011263.1636271747
            ],
            [
              353553.26857701066,
              5011255.1878235796
            ],
            [
              353539.13638929644,
              5011272.20442509
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 385,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353610.92761190323,
              5011166.1678581042
            ],
            [
              353619.67636190978,
              5011157.6334438808
            ],
            [
              353653.58995008253,
              5011127.1146840788
            ],
            [
              353636.87695789005,
              5011108.164523012
            ],
            [
              353610.93349795981,
              5011132.240647018
            ],
            [
              353587.97423111612,
              5011161.3572788686
            ],
            [
              353582.30440576776,
              5011165.0729286214
            ],
            [
              353540.92480847618,
              5011196.107274998
            ],
            [
              353548.96685364528,
              5011203.1798745859
            ],
            [
              353540.67005704792,
              5011212.4189089099
            ],
            [
              353526.46562020102,
              5011223.5982534252
            ],
            [
              353542.62793618481,
              5011235.2883420801
            ],
            [
              353554.82845546695,
              5011222.6684924327
            ],
            [
              353564.15069262352,
              5011231.615007462
            ],
            [
              353568.65952796204,
              5011224.0129627902
            ],
            [
              353570.88908061996,
              5011220.0629060622
            ],
            [
              353585.11997198808,
              5011197.7432143483
            ],
            [
              353589.58141203382,
              5011190.7497462016
            ],
            [
              353610.92761190323,
              5011166.1678581042
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 386,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353673.15778681793,
              5011112.4971534647
            ],
            [
              353680.05514377728,
              5011119.597036615
            ],
            [
              353685.98619242763,
              5011112.3750314955
            ],
            [
              353698.28261944855,
              5011102.4697159352
            ],
            [
              353706.56737940473,
              5011092.6217659777
            ],
            [
              353740.64081664773,
              5011108.3726422088
            ],
            [
              353752.21852821112,
              5011095.0756250666
            ],
            [
              353763.60033051827,
              5011075.9832092784
            ],
            [
              353728.82321391808,
              5011063.2686682222
            ],
            [
              353717.89626700082,
              5011052.7462715944
            ],
            [
              353709.99071253848,
              5011047.8802704774
            ],
            [
              353688.41107088607,
              5011085.9818899697
            ],
            [
              353673.15778681793,
              5011112.4971534647
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 387,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353846.52344070078,
              5010764.0631925408
            ],
            [
              353846.5234407013,
              5010764.0631925408
            ],
            [
              353841.57424204412,
              5010768.6714450829
            ],
            [
              353838.80628212466,
              5010776.1489709895
            ],
            [
              353826.0884724317,
              5010788.3123076996
            ],
            [
              353837.28653270745,
              5010794.7621258674
            ],
            [
              353834.53567141347,
              5010799.0545918178
            ],
            [
              353819.32310571271,
              5010821.0153656639
            ],
            [
              353828.11094075098,
              5010826.7054951638
            ],
            [
              353844.6425430227,
              5010831.1769145904
            ],
            [
              353835.22365871881,
              5010844.0644114707
            ],
            [
              353836.58512864442,
              5010845.2816121588
            ],
            [
              353817.06869424391,
              5010880.4374968279
            ],
            [
              353799.3680224886,
              5010867.2426878074
            ],
            [
              353777.42315582576,
              5010891.3940244429
            ],
            [
              353774.79272564285,
              5010904.2591540115
            ],
            [
              353788.99843694951,
              5010924.3338354807
            ],
            [
              353786.80989885557,
              5010928.2296532271
            ],
            [
              353779.12457295106,
              5010937.6119359899
            ],
            [
              353763.23777625378,
              5010925.9742754446
            ],
            [
              353751.40332512371,
              5010918.9720602473
            ],
            [
              353701.71192269708,
              5010982.8438474536
            ],
            [
              353682.91236556001,
              5010969.7710193601
            ],
            [
              353651.6582755163,
              5010948.5980528658
            ],
            [
              353642.25695739576,
              5010964.6840333911
            ],
            [
              353629.48788550688,
              5010986.9236629494
            ],
            [
              353664.45033355494,
              5011009.4602412591
            ],
            [
              353674.51897617965,
              5010995.427352692
            ],
            [
              353706.40383714199,
              5011016.1748657133
            ],
            [
              353711.50518788304,
              5011017.6646587327
            ],
            [
              353714.62444777321,
              5011020.5647575743
            ],
            [
              353724.0589977827,
              5011009.6957147708
            ],
            [
              353767.85406436044,
              5011047.7107172105
            ],
            [
              353784.11168479943,
              5011026.1606394928
            ],
            [
              353749.90766883449,
              5010987.0420173649
            ],
            [
              353746.83578547003,
              5010982.4545971043
            ],
            [
              353771.35753841506,
              5010948.9978655828
            ],
            [
              353776.46582585952,
              5010952.2936211582
            ],
            [
              353793.49843466893,
              5010931.1935332092
            ],
            [
              353796.9230387201,
              5010938.4928360693
            ],
            [
              353806.61999880947,
              5010945.3847506661
            ],
            [
              353815.92874724907,
              5010948.4109486118
            ],
            [
              353830.39465602761,
              5010956.8369239187
            ],
            [
              353826.63438539731,
              5010964.4890389498
            ],
            [
              353830.62313727284,
              5010970.6936196228
            ],
            [
              353836.09622683714,
              5010972.6078570476
            ],
            [
              353835.26351767656,
              5010974.9900239734
            ],
            [
              353846.63108164381,
              5010980.2569418224
            ],
            [
              353859.63346090011,
              5010964.9821452769
            ],
            [
              353853.46108986455,
              5010958.4790523704
            ],
            [
              353871.13859114522,
              5010942.556810136
            ],
            [
              353854.48091891763,
              5010926.0221972326
            ],
            [
              353858.30807460344,
              5010915.5586126521
            ],
            [
              353842.57194375311,
              5010900.1695911959
            ],
            [
              353855.29902486753,
              5010887.2112821424
            ],
            [
              353868.85289887164,
              5010902.4309514659
            ],
            [
              353867.02117715037,
              5010904.6780704819
            ],
            [
              353874.78244761488,
              5010912.9317654232
            ],
            [
              353888.6750352282,
              5010907.902116497
            ],
            [
              353898.1286422534,
              5010906.4210662628
            ],
            [
              353910.75175074098,
              5010913.5629271343
            ],
            [
              353919.1745438021,
              5010914.65457875
            ],
            [
              353916.9934346868,
              5010898.1258546663
            ],
            [
              353914.84419584152,
              5010898.5630503297
            ],
            [
              353912.39930265048,
              5010895.3173695318
            ],
            [
              353895.95970138046,
              5010868.7977212295
            ],
            [
              353903.77036926866,
              5010862.6816010177
            ],
            [
              353893.1885059679,
              5010844.3569566431
            ],
            [
              353895.65490376198,
              5010843.1737845279
            ],
            [
              353897.63911270612,
              5010845.9029904334
            ],
            [
              353901.27173504099,
              5010844.9597910447
            ],
            [
              353903.45459528471,
              5010848.4944795258
            ],
            [
              353916.72465016582,
              5010845.035962645
            ],
            [
              353923.6016588402,
              5010844.4713102812
            ],
            [
              353940.68327611877,
              5010836.5650367429
            ],
            [
              353924.09668021841,
              5010820.2792998301
            ],
            [
              353917.08430388354,
              5010823.9850943154
            ],
            [
              353903.92560010409,
              5010811.2420945568
            ],
            [
              353930.39635001478,
              5010793.9674427593
            ],
            [
              353934.10685955553,
              5010792.6366743138
            ],
            [
              353960.66322679969,
              5010774.3003992438
            ],
            [
              353966.15282651113,
              5010775.0738515994
            ],
            [
              353972.15508576372,
              5010764.1632470898
            ],
            [
              353980.76565725676,
              5010754.5668064421
            ],
            [
              353999.23681478977,
              5010766.4083430478
            ],
            [
              354004.2920539481,
              5010759.5284695979
            ],
            [
              354008.39413128118,
              5010748.5073347827
            ],
            [
              353986.45592631079,
              5010731.0103246477
            ],
            [
              353989.167253091,
              5010723.3472683607
            ],
            [
              353994.01843489311,
              5010717.273833612
            ],
            [
              353987.31227808987,
              5010713.8962752968
            ],
            [
              353995.28361197369,
              5010702.8761037095
            ],
            [
              354010.89202504366,
              5010709.955163083
            ],
            [
              354028.01937935787,
              5010689.7225340838
            ],
            [
              354036.24025017588,
              5010695.5993406288
            ],
            [
              354039.09854409745,
              5010691.1884740777
            ],
            [
              354043.87938119186,
              5010686.1063302672
            ],
            [
              354036.29401269363,
              5010681.6871258765
            ],
            [
              354050.27971127391,
              5010664.6365379291
            ],
            [
              354070.01328052703,
              5010680.9126533801
            ],
            [
              354090.58991406666,
              5010677.991781286
            ],
            [
              354098.73480722145,
              5010667.9455799805
            ],
            [
              354082.3657477771,
              5010652.2725345371
            ],
            [
              354088.92527077708,
              5010646.8331914358
            ],
            [
              354097.75219269289,
              5010638.4455820834
            ],
            [
              354077.70843729674,
              5010633.1278798245
            ],
            [
              354070.00838881813,
              5010628.6889725095
            ],
            [
              354060.74391819991,
              5010638.7307017138
            ],
            [
              354057.98729398148,
              5010634.6227697013
            ],
            [
              354047.42003300245,
              5010631.5061282488
            ],
            [
              354045.67771501059,
              5010625.0529903295
            ],
            [
              354043.90324716439,
              5010624.4515189882
            ],
            [
              354051.60758491984,
              5010613.204882659
            ],
            [
              354039.17380904179,
              5010605.2943111416
            ],
            [
              354053.41468102555,
              5010594.1064237049
            ],
            [
              354051.37586415495,
              5010591.7311421391
            ],
            [
              354058.14213755325,
              5010588.331487447
            ],
            [
              354065.2292712644,
              5010579.16155056
            ],
            [
              354072.37857127993,
              5010567.721821297
            ],
            [
              354050.56342356355,
              5010553.3512574444
            ],
            [
              354041.23118212086,
              5010563.2761741951
            ],
            [
              354035.58044085774,
              5010571.8587053604
            ],
            [
              354033.02226898092,
              5010570.6313728942
            ],
            [
              354023.32797854353,
              5010592.0588087291
            ],
            [
              354021.35660672735,
              5010597.0632924195
            ],
            [
              354001.42165554711,
              5010597.3412104836
            ],
            [
              353994.0817690136,
              5010603.3376496937
            ],
            [
              353985.87225311901,
              5010612.0119250147
            ],
            [
              353989.50557204627,
              5010615.6628016448
            ],
            [
              353988.58456141554,
              5010617.1661691023
            ],
            [
              353975.6364448049,
              5010646.237826894
            ],
            [
              353969.05416743032,
              5010640.5121743083
            ],
            [
              353967.09540395444,
              5010639.6168927755
            ],
            [
              353959.91732212348,
              5010651.4174260749
            ],
            [
              353952.73495433608,
              5010645.3395432634
            ],
            [
              353944.32765208627,
              5010661.1024106173
            ],
            [
              353938.05978794029,
              5010668.5901412535
            ],
            [
              353941.69996093289,
              5010672.7936909264
            ],
            [
              353930.98538628157,
              5010688.7730375286
            ],
            [
              353919.78276187892,
              5010682.0860471865
            ],
            [
              353913.30062304443,
              5010680.2319331001
            ],
            [
              353911.54161578085,
              5010684.8251800947
            ],
            [
              353905.56333711516,
              5010700.0698770098
            ],
            [
              353904.47054951283,
              5010702.1253848802
            ],
            [
              353888.15099607658,
              5010681.5452103158
            ],
            [
              353880.35197648482,
              5010687.4717435241
            ],
            [
              353883.24095476215,
              5010694.7169435676
            ],
            [
              353866.17423606047,
              5010713.4117511138
            ],
            [
              353870.1613900073,
              5010723.1066805637
            ],
            [
              353865.97682553699,
              5010726.3733087517
            ],
            [
              353860.20573505847,
              5010730.4027346633
            ],
            [
              353848.3671562153,
              5010739.7477852143
            ],
            [
              353838.27618963667,
              5010740.481721689
            ],
            [
              353825.67316531419,
              5010725.5966063216
            ],
            [
              353804.72672796989,
              5010707.9548210325
            ],
            [
              353787.27460679534,
              5010727.0081445696
            ],
            [
              353800.19794547657,
              5010739.7215678357
            ],
            [
              353813.83768886497,
              5010751.6918811388
            ],
            [
              353826.99241097789,
              5010761.3880345738
            ],
            [
              353835.11315028061,
              5010754.4689306151
            ],
            [
              353846.52344070078,
              5010764.0631925408
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 388,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353490.17907296866,
              5011413.779439236
            ],
            [
              353469.86803799256,
              5011396.4861552082
            ],
            [
              353464.86801397271,
              5011392.1916127866
            ],
            [
              353455.07320691441,
              5011410.8906322122
            ],
            [
              353457.40121353284,
              5011413.2259764625
            ],
            [
              353447.62978453323,
              5011430.3439020971
            ],
            [
              353439.89503266971,
              5011460.0028618574
            ],
            [
              353447.33759314351,
              5011450.7303053159
            ],
            [
              353462.0236203653,
              5011441.109880574
            ],
            [
              353468.41500661935,
              5011441.8472741097
            ],
            [
              353475.90133911831,
              5011429.814614309
            ],
            [
              353490.17907296866,
              5011413.779439236
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 389,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353513.14559137152,
              5011305.7323687375
            ],
            [
              353525.99112592108,
              5011314.1894164588
            ],
            [
              353540.73533121328,
              5011325.0090294378
            ],
            [
              353549.07361759339,
              5011331.5625999374
            ],
            [
              353552.54937078361,
              5011325.8721173396
            ],
            [
              353560.4985264084,
              5011312.6551926779
            ],
            [
              353562.62477090309,
              5011310.0584622836
            ],
            [
              353567.26327150263,
              5011304.9856089903
            ],
            [
              353574.41437720956,
              5011295.690844289
            ],
            [
              353552.69177207997,
              5011275.99557932
            ],
            [
              353548.72989719367,
              5011280.4871305237
            ],
            [
              353539.13638929644,
              5011272.20442509
            ],
            [
              353527.01995165535,
              5011287.0670433175
            ],
            [
              353513.14559137152,
              5011305.7323687375
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 390,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353872.64352143271,
              5010910.6571321674
            ],
            [
              353867.02117715037,
              5010904.6780704819
            ],
            [
              353868.85289887164,
              5010902.4309514659
            ],
            [
              353855.29902486876,
              5010887.2112821424
            ],
            [
              353842.57194375392,
              5010900.1695911959
            ],
            [
              353858.30807460344,
              5010915.5586126521
            ],
            [
              353859.73836338567,
              5010911.7859033719
            ],
            [
              353864.2084818384,
              5010916.4680859502
            ],
            [
              353872.64352143271,
              5010910.6571321674
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 391,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353936.29863004241,
              5010812.4401744334
            ],
            [
              353944.45786861767,
              5010806.4090033192
            ],
            [
              353937.3387953146,
              5010799.1923197415
            ],
            [
              353930.39635001478,
              5010793.9674427593
            ],
            [
              353903.92560010485,
              5010811.2420945568
            ],
            [
              353917.08430388354,
              5010823.9850943154
            ],
            [
              353924.09668021987,
              5010820.2792998273
            ],
            [
              353936.29863004241,
              5010812.4401744334
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 392,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354072.37857127993,
              5010567.721821297
            ],
            [
              354065.2292712644,
              5010579.16155056
            ],
            [
              354058.14213755325,
              5010588.331487447
            ],
            [
              354051.37586415495,
              5010591.7311421391
            ],
            [
              354053.41468102555,
              5010594.1064237049
            ],
            [
              354039.17380904179,
              5010605.2943111416
            ],
            [
              354051.60758491984,
              5010613.204882659
            ],
            [
              354043.90324716439,
              5010624.4515189882
            ],
            [
              354045.67771501059,
              5010625.0529903295
            ],
            [
              354047.42003300245,
              5010631.5061282488
            ],
            [
              354057.98729398148,
              5010634.6227697013
            ],
            [
              354060.74391819991,
              5010638.7307017138
            ],
            [
              354070.00838881813,
              5010628.6889725095
            ],
            [
              354077.70843729674,
              5010633.1278798245
            ],
            [
              354078.89174735005,
              5010632.2891738852
            ],
            [
              354084.03392511775,
              5010627.0660765693
            ],
            [
              354086.47193898255,
              5010608.4876537025
            ],
            [
              354086.16361943673,
              5010598.7136205053
            ],
            [
              354087.8842953805,
              5010591.7602117639
            ],
            [
              354094.29976935172,
              5010595.6563021662
            ],
            [
              354097.12049283256,
              5010591.0071646888
            ],
            [
              354107.31522146694,
              5010576.626886785
            ],
            [
              354095.9585245396,
              5010578.9098649854
            ],
            [
              354086.87168118899,
              5010571.5456426656
            ],
            [
              354084.30854459206,
              5010578.0908110952
            ],
            [
              354072.37857127993,
              5010567.721821297
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 393,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353635.52934038854,
              5011216.4349959092
            ],
            [
              353639.31304074515,
              5011211.0619449839
            ],
            [
              353630.924707464,
              5011203.2313355133
            ],
            [
              353655.3933111981,
              5011184.784867147
            ],
            [
              353656.92756134813,
              5011183.9563727882
            ],
            [
              353660.37599488447,
              5011188.560630165
            ],
            [
              353667.18696692708,
              5011183.1118698241
            ],
            [
              353678.59470694262,
              5011177.9272107417
            ],
            [
              353685.82082089299,
              5011174.7115666335
            ],
            [
              353661.08669842704,
              5011140.6254290761
            ],
            [
              353655.3694001814,
              5011134.5694775321
            ],
            [
              353670.45207199472,
              5011118.771156081
            ],
            [
              353674.97975843988,
              5011114.3726234315
            ],
            [
              353673.15778681793,
              5011112.4971534647
            ],
            [
              353680.78442867956,
              5011099.2395214234
            ],
            [
              353688.41107088607,
              5011085.9818899697
            ],
            [
              353709.99071253848,
              5011047.8802704774
            ],
            [
              353717.89626700082,
              5011052.7462715944
            ],
            [
              353728.82321391808,
              5011063.2686682222
            ],
            [
              353751.11499792401,
              5011071.4185620742
            ],
            [
              353754.85128661036,
              5011065.0175308045
            ],
            [
              353767.85406436044,
              5011047.7107172105
            ],
            [
              353784.11168479943,
              5011026.1606394928
            ],
            [
              353797.36266940756,
              5011005.9921328034
            ],
            [
              353802.19785865681,
              5010997.6611192124
            ],
            [
              353807.56516718661,
              5010988.7779995194
            ],
            [
              353813.07940756058,
              5010980.4871483492
            ],
            [
              353819.45908070571,
              5010973.5370323481
            ],
            [
              353826.63438539731,
              5010964.4890389498
            ],
            [
              353830.39465602761,
              5010956.8369239187
            ],
            [
              353815.92874724907,
              5010948.4109486118
            ],
            [
              353806.61999880947,
              5010945.3847506661
            ],
            [
              353796.9230387201,
              5010938.4928360693
            ],
            [
              353793.49843466893,
              5010931.1935332092
            ],
            [
              353776.46582585952,
              5010952.2936211582
            ],
            [
              353771.35753841506,
              5010948.9978655828
            ],
            [
              353746.83578547003,
              5010982.4545971043
            ],
            [
              353749.90766883449,
              5010987.0420173649
            ],
            [
              353784.11168479943,
              5011026.1606394928
            ],
            [
              353767.85406436044,
              5011047.7107172105
            ],
            [
              353723.96859501995,
              5011009.7998629771
            ],
            [
              353714.62444777321,
              5011020.5647575743
            ],
            [
              353711.50518788304,
              5011017.6646587327
            ],
            [
              353706.40383714199,
              5011016.1748657133
            ],
            [
              353674.51897617965,
              5010995.427352692
            ],
            [
              353664.45033355494,
              5011009.4602412591
            ],
            [
              353658.55032218614,
              5011017.9498973433
            ],
            [
              353687.34133597213,
              5011040.3141716607
            ],
            [
              353683.67254398041,
              5011045.0278654862
            ],
            [
              353690.2308548211,
              5011050.6400622055
            ],
            [
              353692.0954475833,
              5011055.3978021946
            ],
            [
              353680.92342578393,
              5011067.8299508234
            ],
            [
              353674.08748679835,
              5011075.2817820916
            ],
            [
              353651.73212773353,
              5011095.7572026476
            ],
            [
              353638.09164532367,
              5011107.1426276481
            ],
            [
              353636.9014600573,
              5011108.1923049921
            ],
            [
              353647.03749752039,
              5011119.6851331117
            ],
            [
              353653.58995008253,
              5011127.1146840788
            ],
            [
              353643.54113369813,
              5011136.157590203
            ],
            [
              353628.598775363,
              5011149.6041840557
            ],
            [
              353613.6013323479,
              5011163.5596411396
            ],
            [
              353610.92761190323,
              5011166.1678581042
            ],
            [
              353604.28968110058,
              5011173.8119757958
            ],
            [
              353589.58141203382,
              5011190.7497462016
            ],
            [
              353580.65853202803,
              5011204.7366826767
            ],
            [
              353571.35403576825,
              5011219.2658001678
            ],
            [
              353564.15069262352,
              5011231.615007462
            ],
            [
              353550.00348089769,
              5011249.0149797713
            ],
            [
              353547.35261892126,
              5011252.6234016363
            ],
            [
              353553.26857701066,
              5011255.1878235796
            ],
            [
              353565.62927683041,
              5011263.1636271747
            ],
            [
              353586.33073672961,
              5011226.4649510887
            ],
            [
              353598.01777429518,
              5011217.5793256024
            ],
            [
              353594.0315094656,
              5011209.2020885749
            ],
            [
              353606.36708857823,
              5011200.0879341494
            ],
            [
              353629.39366571209,
              5011212.9956096411
            ],
            [
              353635.52934038854,
              5011216.4349959092
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 394,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353975.6364448049,
              5010646.237826894
            ],
            [
              353988.58456141467,
              5010617.1661690967
            ],
            [
              353989.50557204627,
              5010615.6628016448
            ],
            [
              353985.87225311901,
              5010612.0119250147
            ],
            [
              353994.08176901907,
              5010603.3376496937
            ],
            [
              354001.42165554484,
              5010597.3412104808
            ],
            [
              354021.35660672653,
              5010597.0632924177
            ],
            [
              354023.32797853492,
              5010592.0588087225
            ],
            [
              354023.37727628875,
              5010591.9498451669
            ],
            [
              354017.93567732046,
              5010589.5644127289
            ],
            [
              354018.2992899688,
              5010588.8710218146
            ],
            [
              354012.11219019868,
              5010585.9514622083
            ],
            [
              354004.66476754291,
              5010593.6160315024
            ],
            [
              353990.76284176909,
              5010579.3445803272
            ],
            [
              353986.17531151819,
              5010584.3488542056
            ],
            [
              353983.30062292848,
              5010587.4735106789
            ],
            [
              353979.28720870125,
              5010593.2018982302
            ],
            [
              353968.47373129393,
              5010602.0206063511
            ],
            [
              353978.79388737067,
              5010608.7386517012
            ],
            [
              353976.35581953911,
              5010624.0699839154
            ],
            [
              353958.51446342206,
              5010614.6999373389
            ],
            [
              353955.40890331339,
              5010624.1805717805
            ],
            [
              353951.75901040825,
              5010631.4663821552
            ],
            [
              353950.46514491766,
              5010633.23542039
            ],
            [
              353947.00112257671,
              5010638.464992933
            ],
            [
              353942.73191134835,
              5010645.4545420194
            ],
            [
              353942.03755816497,
              5010649.236248564
            ],
            [
              353947.84247262025,
              5010654.5124665471
            ],
            [
              353952.73495433608,
              5010645.3395432634
            ],
            [
              353959.91732212476,
              5010651.4174260758
            ],
            [
              353967.09540395346,
              5010639.6168927755
            ],
            [
              353969.05416743114,
              5010640.5121743092
            ],
            [
              353975.6364448049,
              5010646.237826894
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 395,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353799.3680224886,
              5010867.2426878074
            ],
            [
              353817.06869424391,
              5010880.4374968279
            ],
            [
              353836.58512864442,
              5010845.2816121588
            ],
            [
              353835.22365871881,
              5010844.0644114707
            ],
            [
              353844.6425430227,
              5010831.1769145904
            ],
            [
              353828.11094075098,
              5010826.7054951638
            ],
            [
              353819.32310571271,
              5010821.0153656639
            ],
            [
              353786.0988146193,
              5010846.6227513645
            ],
            [
              353780.64721137675,
              5010853.7305576419
            ],
            [
              353799.3680224886,
              5010867.2426878074
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 396,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353788.99843694951,
              5010924.3338354807
            ],
            [
              353774.79272564378,
              5010904.2591540115
            ],
            [
              353756.40844430099,
              5010888.1991726775
            ],
            [
              353750.61905092531,
              5010897.5059352396
            ],
            [
              353746.55157164624,
              5010894.8607686749
            ],
            [
              353735.29397112911,
              5010909.8405759064
            ],
            [
              353737.39370235096,
              5010911.2267040983
            ],
            [
              353751.4033251236,
              5010918.9720602427
            ],
            [
              353763.23777625378,
              5010925.9742754446
            ],
            [
              353779.12457295111,
              5010937.6119359909
            ],
            [
              353786.80989885336,
              5010928.2296532365
            ],
            [
              353788.99843694951,
              5010924.3338354807
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 397,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352923.11806685646,
              5010654.9743326763
            ],
            [
              352921.33209823468,
              5010657.3113269908
            ],
            [
              352912.70035703521,
              5010666.4515356822
            ],
            [
              352899.04546455556,
              5010675.2665746631
            ],
            [
              352885.64829907916,
              5010687.2838662984
            ],
            [
              352917.88996397535,
              5010712.2674479522
            ],
            [
              352920.91778487992,
              5010708.9413827285
            ],
            [
              352935.93091244181,
              5010721.5686207004
            ],
            [
              352962.54043694009,
              5010676.807180943
            ],
            [
              352965.05136202864,
              5010672.5834074672
            ],
            [
              352961.36711254582,
              5010670.5789466165
            ],
            [
              352943.20846209879,
              5010662.84337366
            ],
            [
              352935.43609521521,
              5010655.635364267
            ],
            [
              352930.69322465762,
              5010653.5758339139
            ],
            [
              352943.7688588411,
              5010630.4082481535
            ],
            [
              352932.33574242593,
              5010623.1493503395
            ],
            [
              352935.8438656503,
              5010619.9426024901
            ],
            [
              352923.46395377425,
              5010612.0221113469
            ],
            [
              352927.6985110789,
              5010606.7641173583
            ],
            [
              352905.46533536009,
              5010592.725657736
            ],
            [
              352895.69069102971,
              5010606.9417098295
            ],
            [
              352884.77582941268,
              5010621.3242489509
            ],
            [
              352882.39869499061,
              5010619.194454154
            ],
            [
              352876.9220188391,
              5010625.3071532845
            ],
            [
              352889.56957841566,
              5010635.0452028746
            ],
            [
              352925.9030509069,
              5010651.7970576156
            ],
            [
              352923.11806685646,
              5010654.9743326763
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 398,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352936.2084348363,
              5010084.5980023732
            ],
            [
              352935.53403122124,
              5010086.1098654373
            ],
            [
              352922.93777641718,
              5010114.3478732882
            ],
            [
              352935.07915786828,
              5010115.1616475089
            ],
            [
              352927.61252106674,
              5010144.3515628781
            ],
            [
              352939.62907931057,
              5010146.4227596931
            ],
            [
              352947.08023041481,
              5010121.6307694744
            ],
            [
              352950.96732390369,
              5010123.680411417
            ],
            [
              352954.65350826253,
              5010126.2468619347
            ],
            [
              352959.30760540254,
              5010114.3104380183
            ],
            [
              352966.34765365586,
              5010102.1385219377
            ],
            [
              352943.4470932461,
              5010094.3461924056
            ],
            [
              352936.2084348363,
              5010084.5980023732
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 399,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353049.63378796651,
              5010037.6628857376
            ],
            [
              353077.12801757304,
              5010013.730289422
            ],
            [
              353067.60852400475,
              5010001.0258216588
            ],
            [
              353064.52305428096,
              5009995.0478114663
            ],
            [
              353062.91842837451,
              5009989.3662941344
            ],
            [
              353051.61337772774,
              5009993.2532304814
            ],
            [
              353038.22584449401,
              5009989.0031163823
            ],
            [
              353026.14279792091,
              5009986.4892930193
            ],
            [
              353023.27043632831,
              5009984.6354990695
            ],
            [
              353013.98006528005,
              5009991.2758509498
            ],
            [
              353008.02825715742,
              5010001.2583635729
            ],
            [
              352995.57895648922,
              5010022.1386257
            ],
            [
              353006.34962364903,
              5010031.2789232191
            ],
            [
              353017.04519825458,
              5010040.6586525757
            ],
            [
              353030.67255457054,
              5010026.1249876739
            ],
            [
              353035.03702223767,
              5010023.3413586114
            ],
            [
              353049.63378796651,
              5010037.6628857376
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 400,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355097.13350253104,
              5009305.6380794356
            ],
            [
              355109.59707719315,
              5009289.2192024672
            ],
            [
              355150.02297029604,
              5009238.1874101786
            ],
            [
              355151.868501372,
              5009236.6308565764
            ],
            [
              355160.15305671858,
              5009234.8374410765
            ],
            [
              355168.74192882952,
              5009224.9122843575
            ],
            [
              355149.14607183693,
              5009210.6400990831
            ],
            [
              355152.12350982515,
              5009206.9548012307
            ],
            [
              355123.57646281348,
              5009183.8328877697
            ],
            [
              355107.01915459282,
              5009200.6511539519
            ],
            [
              355104.52543243574,
              5009205.1471005203
            ],
            [
              355095.585307459,
              5009197.8506320799
            ],
            [
              355085.39908673038,
              5009204.4802992037
            ],
            [
              355079.177687574,
              5009210.7048719814
            ],
            [
              355077.34527433425,
              5009212.9637222914
            ],
            [
              355070.69327681279,
              5009208.0524499631
            ],
            [
              355055.84963596874,
              5009228.8200348523
            ],
            [
              355052.59168027475,
              5009232.1280038664
            ],
            [
              355050.0642079814,
              5009234.6942757759
            ],
            [
              355045.52311794873,
              5009239.3050768813
            ],
            [
              355038.23514086421,
              5009245.7497627903
            ],
            [
              355044.71475625178,
              5009251.9383230312
            ],
            [
              355052.84861106984,
              5009257.3139931755
            ],
            [
              355061.58339389862,
              5009267.8354522809
            ],
            [
              355079.58222012833,
              5009289.4256430781
            ],
            [
              355081.12987832126,
              5009291.4148895983
            ],
            [
              355097.13350253104,
              5009305.6380794356
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 401,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354975.02667108574,
              5009369.7316928199
            ],
            [
              354995.59936406469,
              5009395.7193749258
            ],
            [
              355000.20504026167,
              5009384.1217223434
            ],
            [
              355007.51322604256,
              5009373.812403067
            ],
            [
              355009.97787896689,
              5009376.7114669615
            ],
            [
              355010.85778121947,
              5009373.6163170133
            ],
            [
              355032.33950243081,
              5009354.8778897589
            ],
            [
              355034.96987013693,
              5009352.2855989812
            ],
            [
              355042.72949284123,
              5009344.6451030644
            ],
            [
              355065.14090409689,
              5009325.4877578383
            ],
            [
              355058.0944538443,
              5009318.0269059017
            ],
            [
              355050.63443735446,
              5009312.2730931602
            ],
            [
              355046.36163530801,
              5009306.9234081879
            ],
            [
              355015.1785309221,
              5009329.220132337
            ],
            [
              355007.158568224,
              5009339.1739562433
            ],
            [
              355001.54986362281,
              5009343.2031971086
            ],
            [
              354993.32298157038,
              5009356.473601508
            ],
            [
              354990.45928302238,
              5009358.0761608826
            ],
            [
              354975.02667108574,
              5009369.7316928199
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 402,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354939.35953183414,
              5009386.0445605135
            ],
            [
              354948.04349201504,
              5009391.192144528
            ],
            [
              354957.47362789401,
              5009411.4039545199
            ],
            [
              354969.27667618851,
              5009424.9145835489
            ],
            [
              354983.05807357136,
              5009416.164295339
            ],
            [
              354994.30361424846,
              5009398.8620322896
            ],
            [
              354995.59936406469,
              5009395.7193749258
            ],
            [
              354975.24260953511,
              5009370.0044689802
            ],
            [
              354987.22382469225,
              5009360.5197515693
            ],
            [
              354990.82830466639,
              5009357.869652031
            ],
            [
              354986.27076196176,
              5009349.7255369378
            ],
            [
              354979.80030864995,
              5009339.3125744816
            ],
            [
              354961.31934757956,
              5009352.6307608234
            ],
            [
              354969.46079562278,
              5009362.9999942668
            ],
            [
              354939.35953183414,
              5009386.0445605135
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 403,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354797.09939710144,
              5009684.3313691439
            ],
            [
              354801.24560455472,
              5009679.4793726318
            ],
            [
              354796.23357098177,
              5009675.1964078248
            ],
            [
              354781.92981664365,
              5009663.295869817
            ],
            [
              354767.09207520058,
              5009678.283233407
            ],
            [
              354772.18696228892,
              5009682.7211013064
            ],
            [
              354775.31213499774,
              5009679.641198528
            ],
            [
              354780.97856852069,
              5009684.8636195948
            ],
            [
              354776.65335045656,
              5009691.8623779221
            ],
            [
              354785.45228401414,
              5009699.3954830701
            ],
            [
              354797.09939710144,
              5009684.3313691439
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 404,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354801.24560455472,
              5009679.4793726318
            ],
            [
              354775.6320991987,
              5009712.0966901472
            ],
            [
              354756.57274523057,
              5009734.5116527528
            ],
            [
              354781.03336619562,
              5009747.941174929
            ],
            [
              354802.67432866641,
              5009752.0345280506
            ],
            [
              354814.26290441293,
              5009742.0335649662
            ],
            [
              354816.28113136825,
              5009743.8473111279
            ],
            [
              354827.34563969995,
              5009734.120574683
            ],
            [
              354829.65680955281,
              5009723.2343502948
            ],
            [
              354818.8646187337,
              5009708.8314858014
            ],
            [
              354827.24267618888,
              5009699.7499141507
            ],
            [
              354841.47208617203,
              5009682.9089038651
            ],
            [
              354857.46443233709,
              5009684.1398291113
            ],
            [
              354863.59029623884,
              5009684.2808912927
            ],
            [
              354874.32361569564,
              5009685.228766121
            ],
            [
              354882.67611866124,
              5009685.608140815
            ],
            [
              354925.59327284782,
              5009675.2835816713
            ],
            [
              354907.45004575473,
              5009636.4178553112
            ],
            [
              354925.57504406915,
              5009618.5383612281
            ],
            [
              354948.14491988957,
              5009601.220249515
            ],
            [
              354943.63576154347,
              5009595.2953595072
            ],
            [
              354945.644296813,
              5009592.9130928935
            ],
            [
              354941.72965944262,
              5009587.4168149186
            ],
            [
              354963.58802184218,
              5009567.5854638899
            ],
            [
              354968.70010785857,
              5009563.5455728015
            ],
            [
              354978.56619253027,
              5009554.4017030299
            ],
            [
              354988.92499660322,
              5009565.4880894059
            ],
            [
              355002.61296856269,
              5009580.2487545246
            ],
            [
              355022.2188507244,
              5009603.0773213655
            ],
            [
              355024.73661731248,
              5009601.421704405
            ],
            [
              355033.41682667163,
              5009592.1437445795
            ],
            [
              355039.72836126131,
              5009585.3230650481
            ],
            [
              355042.58303838113,
              5009580.2833290212
            ],
            [
              355043.32254832692,
              5009570.4660357852
            ],
            [
              355047.0366423541,
              5009553.8063243078
            ],
            [
              355061.4759141148,
              5009535.5529272351
            ],
            [
              355069.90194940526,
              5009501.7959997896
            ],
            [
              355072.37398419279,
              5009490.0380696282
            ],
            [
              355079.74452719191,
              5009477.9188090563
            ],
            [
              355085.60389451357,
              5009484.6806988511
            ],
            [
              355093.70104664814,
              5009485.7336838935
            ],
            [
              355102.037548216,
              5009478.7077573761
            ],
            [
              355119.64378898276,
              5009492.2894817572
            ],
            [
              355135.44049563393,
              5009485.2085200334
            ],
            [
              355120.31080053613,
              5009470.9309895942
            ],
            [
              355096.93536357488,
              5009449.1612570109
            ],
            [
              355104.34806736425,
              5009437.5440114615
            ],
            [
              355108.91648863308,
              5009430.7557322569
            ],
            [
              355113.18665431294,
              5009424.837720396
            ],
            [
              355101.51841568964,
              5009414.9998646537
            ],
            [
              355104.67266215297,
              5009404.2997305701
            ],
            [
              355122.29528259306,
              5009381.6420954475
            ],
            [
              355120.18613647955,
              5009380.9272032557
            ],
            [
              355138.45006480895,
              5009365.2194370441
            ],
            [
              355133.09247496439,
              5009361.1295789648
            ],
            [
              355143.73428001202,
              5009342.8327643238
            ],
            [
              355161.40910382842,
              5009328.2022655755
            ],
            [
              355172.43290518573,
              5009312.4120711973
            ],
            [
              355183.93929313315,
              5009295.5236150827
            ],
            [
              355197.12344632571,
              5009301.2274973309
            ],
            [
              355200.44292593113,
              5009299.4061161978
            ],
            [
              355211.45750951121,
              5009305.653124569
            ],
            [
              355224.72863239917,
              5009314.0435699644
            ],
            [
              355244.97234111826,
              5009334.0639704466
            ],
            [
              355270.00208058069,
              5009354.5673312265
            ],
            [
              355285.75131002819,
              5009350.7221929505
            ],
            [
              355295.25348665722,
              5009331.5243539596
            ],
            [
              355307.06086002773,
              5009312.2161061177
            ],
            [
              355311.47102257289,
              5009284.2899796609
            ],
            [
              355306.51415645686,
              5009269.8949356358
            ],
            [
              355312.46240636456,
              5009263.0238059787
            ],
            [
              355337.2329135242,
              5009254.1554859588
            ],
            [
              355388.2466339629,
              5009229.3728350587
            ],
            [
              355396.29197505512,
              5009222.8863124326
            ],
            [
              355431.76395199436,
              5009194.6115157865
            ],
            [
              355440.37598986266,
              5009182.1554239318
            ],
            [
              355413.64384479186,
              5009156.4128551455
            ],
            [
              355387.49156380637,
              5009131.2677769475
            ],
            [
              355399.06774578226,
              5009118.7918688934
            ],
            [
              355395.78804219194,
              5009108.8840185367
            ],
            [
              355394.08982271992,
              5009103.8738769498
            ],
            [
              355407.33007692313,
              5009094.57537416
            ],
            [
              355420.89482421521,
              5009090.1644901866
            ],
            [
              355417.94409129658,
              5009082.6667470848
            ],
            [
              355416.03162871068,
              5009083.7561501507
            ],
            [
              355339.9855834503,
              5009033.0330854123
            ],
            [
              355326.04747214861,
              5009050.251887559
            ],
            [
              355328.51735809201,
              5009052.6096842084
            ],
            [
              355320.90232247015,
              5009062.4262438323
            ],
            [
              355312.10770413803,
              5009073.87727453
            ],
            [
              355302.91739227733,
              5009085.738773955
            ],
            [
              355300.43054702488,
              5009083.5691334018
            ],
            [
              355291.78781768464,
              5009095.9579447722
            ],
            [
              355277.24967039534,
              5009116.6532584298
            ],
            [
              355264.53393315506,
              5009137.8650420569
            ],
            [
              355250.33357560786,
              5009158.2301145336
            ],
            [
              355260.3161254708,
              5009168.3297607694
            ],
            [
              355267.75046396983,
              5009171.4133511288
            ],
            [
              355271.46693341568,
              5009167.7119689379
            ],
            [
              355295.63659971027,
              5009132.2447963925
            ],
            [
              355303.9374714765,
              5009119.910345329
            ],
            [
              355310.83112648263,
              5009105.6228522453
            ],
            [
              355337.23605715617,
              5009132.9933967739
            ],
            [
              355333.29387642432,
              5009133.9802433662
            ],
            [
              355341.42168211308,
              5009145.0955578731
            ],
            [
              355329.16955783084,
              5009152.413119752
            ],
            [
              355299.9226040449,
              5009168.7912089461
            ],
            [
              355296.34159782965,
              5009172.8163359519
            ],
            [
              355283.35920829279,
              5009179.1478823489
            ],
            [
              355282.57235740422,
              5009177.7922724597
            ],
            [
              355267.58108801336,
              5009183.2167999176
            ],
            [
              355246.0995022541,
              5009195.0729804263
            ],
            [
              355224.23353085131,
              5009215.7694096854
            ],
            [
              355214.76551342913,
              5009222.5648799026
            ],
            [
              355199.69549204415,
              5009230.4109340152
            ],
            [
              355193.32330796198,
              5009235.7712546391
            ],
            [
              355189.16045613569,
              5009241.4183855532
            ],
            [
              355183.01377429214,
              5009241.2452532025
            ],
            [
              355158.67431667884,
              5009239.0417722175
            ],
            [
              355132.42777911812,
              5009285.3246973176
            ],
            [
              355122.46711922629,
              5009302.4279954406
            ],
            [
              355108.84936338261,
              5009316.5224931948
            ],
            [
              355116.73757760628,
              5009324.7116744239
            ],
            [
              355122.67399913265,
              5009325.4469237011
            ],
            [
              355125.67725524394,
              5009330.9483283535
            ],
            [
              355114.1977751918,
              5009341.4070827467
            ],
            [
              355101.31855692551,
              5009353.3074288201
            ],
            [
              355098.11778566375,
              5009356.428733875
            ],
            [
              355084.10258447798,
              5009370.281600425
            ],
            [
              355067.57186413632,
              5009386.8750467105
            ],
            [
              355066.03570252302,
              5009380.5000990918
            ],
            [
              355065.05485598644,
              5009374.753358976
            ],
            [
              355046.35135703901,
              5009355.8847780153
            ],
            [
              355034.15457921888,
              5009376.7257994162
            ],
            [
              355014.17666298692,
              5009400.0226017619
            ],
            [
              355004.38589896786,
              5009406.105972317
            ],
            [
              355008.24337261316,
              5009408.6992797526
            ],
            [
              355013.63594474993,
              5009406.7905042209
            ],
            [
              355023.07217594964,
              5009406.2551114503
            ],
            [
              355023.07217595138,
              5009406.2551114475
            ],
            [
              355006.09720121202,
              5009425.2104566386
            ],
            [
              354986.90342850174,
              5009446.643456433
            ],
            [
              354963.31216199993,
              5009471.3176290961
            ],
            [
              354960.81804268115,
              5009464.5354553377
            ],
            [
              354971.13240792468,
              5009453.7188649466
            ],
            [
              354957.78799484327,
              5009440.3603089582
            ],
            [
              354971.80194329057,
              5009428.2394633126
            ],
            [
              354969.27667618851,
              5009424.9145835489
            ],
            [
              354957.47362789401,
              5009411.4039545199
            ],
            [
              354948.04349201504,
              5009391.192144528
            ],
            [
              354939.35953183414,
              5009386.0445605135
            ],
            [
              354929.51183934679,
              5009371.3533836715
            ],
            [
              354908.69539561862,
              5009371.2205680367
            ],
            [
              354896.37233443419,
              5009385.7000902584
            ],
            [
              354876.27853976976,
              5009413.2222713418
            ],
            [
              354871.14806272689,
              5009416.3967966838
            ],
            [
              354903.36173895752,
              5009464.1652245745
            ],
            [
              354912.60236982378,
              5009485.6025257204
            ],
            [
              354892.92927627155,
              5009496.5782167539
            ],
            [
              354903.30103349214,
              5009511.467681366
            ],
            [
              354913.86164357269,
              5009523.9147720048
            ],
            [
              354928.84248219529,
              5009543.2358970549
            ],
            [
              354922.4194549282,
              5009550.8440599451
            ],
            [
              354905.24048636685,
              5009569.5039365971
            ],
            [
              354890.56553052244,
              5009584.7275242163
            ],
            [
              354879.94481640798,
              5009594.3905405672
            ],
            [
              354876.45250719838,
              5009597.7394366739
            ],
            [
              354837.10412620031,
              5009634.3775515445
            ],
            [
              354796.23357100465,
              5009675.1964078415
            ],
            [
              354801.24560455472,
              5009679.4793726318
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 405,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355399.7421775625,
              5009048.2314523365
            ],
            [
              355405.61540188716,
              5009042.1599671254
            ],
            [
              355392.14458095346,
              5009030.7415645383
            ],
            [
              355372.99533342378,
              5009018.9303587209
            ],
            [
              355357.75956805208,
              5009018.9572889265
            ],
            [
              355359.97184827464,
              5009014.1170674805
            ],
            [
              355353.69391197531,
              5009011.8281531753
            ],
            [
              355339.9855834503,
              5009033.0330854123
            ],
            [
              355385.10931283474,
              5009063.3581269579
            ],
            [
              355387.8930823165,
              5009060.4805350257
            ],
            [
              355399.7421775625,
              5009048.2314523365
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 406,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354994.92364651401,
              5008701.9353006203
            ],
            [
              354970.16414237738,
              5008678.8035804443
            ],
            [
              354985.11686953495,
              5008668.197973731
            ],
            [
              354964.62574278645,
              5008650.2761989841
            ],
            [
              354904.28045814577,
              5008707.1947853733
            ],
            [
              354879.19924943568,
              5008731.4174113097
            ],
            [
              354902.69570318126,
              5008737.3131656628
            ],
            [
              354909.55680562276,
              5008764.7877709251
            ],
            [
              354922.27392711205,
              5008750.4414346786
            ],
            [
              354936.24581370852,
              5008736.402766373
            ],
            [
              354952.88298329298,
              5008730.9038843215
            ],
            [
              354994.92364651401,
              5008701.9353006203
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 407,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357481.3189027817,
              5006402.1863219533
            ],
            [
              357473.62162711873,
              5006420.4019209985
            ],
            [
              357497.74276168505,
              5006433.1172926929
            ],
            [
              357506.87012328382,
              5006419.7836560849
            ],
            [
              357509.02961455454,
              5006415.1773036132
            ],
            [
              357481.3189027817,
              5006402.1863219533
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 408,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357841.038134242,
              5006296.5438119192
            ],
            [
              357840.17936583288,
              5006279.4430975001
            ],
            [
              357841.08305092715,
              5006269.877004222
            ],
            [
              357839.33739789884,
              5006242.1253998699
            ],
            [
              357839.49424044386,
              5006239.8822331298
            ],
            [
              357838.10206194845,
              5006235.3583573895
            ],
            [
              357837.05167791259,
              5006229.614340445
            ],
            [
              357833.01282038132,
              5006231.8858942455
            ],
            [
              357830.77248904895,
              5006232.3982281694
            ],
            [
              357824.57551939954,
              5006233.9777271198
            ],
            [
              357817.63564719574,
              5006234.9958148608
            ],
            [
              357815.52404656837,
              5006233.9891785625
            ],
            [
              357812.27753321087,
              5006237.4031396722
            ],
            [
              357808.62828775123,
              5006243.7465367243
            ],
            [
              357815.47968408046,
              5006250.0356004778
            ],
            [
              357817.42827733728,
              5006255.1457359996
            ],
            [
              357820.26747527946,
              5006261.0854914775
            ],
            [
              357820.54238822398,
              5006270.3191150734
            ],
            [
              357819.30413324485,
              5006276.2522268221
            ],
            [
              357818.98792833154,
              5006283.3805669388
            ],
            [
              357816.56380276527,
              5006288.0447925683
            ],
            [
              357815.28757499397,
              5006293.7995312884
            ],
            [
              357814.75906221598,
              5006298.6467516897
            ],
            [
              357814.6833256642,
              5006304.3584591579
            ],
            [
              357816.99264420837,
              5006305.2611717368
            ],
            [
              357818.96130781999,
              5006309.1701243352
            ],
            [
              357813.35566290084,
              5006326.3115671901
            ],
            [
              357810.4087433457,
              5006335.9018744184
            ],
            [
              357812.93668408791,
              5006336.530380087
            ],
            [
              357819.55398395978,
              5006338.6829965068
            ],
            [
              357836.54657128296,
              5006341.021984783
            ],
            [
              357838.26874472311,
              5006332.6972626001
            ],
            [
              357841.038134242,
              5006296.5438119192
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 409,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357622.64509521844,
              5006609.102580118
            ],
            [
              357618.64439575054,
              5006614.7975477232
            ],
            [
              357615.64928456116,
              5006620.7508642143
            ],
            [
              357618.24776677828,
              5006622.0148283327
            ],
            [
              357634.0988020653,
              5006632.4942110302
            ],
            [
              357636.44236652384,
              5006626.0835547317
            ],
            [
              357638.91137548321,
              5006627.502553707
            ],
            [
              357639.86813837045,
              5006629.4941320308
            ],
            [
              357651.37949349108,
              5006634.9575590715
            ],
            [
              357664.3901541598,
              5006638.5488038445
            ],
            [
              357664.70247042977,
              5006637.1816274505
            ],
            [
              357665.85582569422,
              5006632.9594743261
            ],
            [
              357656.59739014867,
              5006627.686003509
            ],
            [
              357658.04058865464,
              5006624.8342526434
            ],
            [
              357648.63823466114,
              5006619.8577893842
            ],
            [
              357647.82890665979,
              5006621.0360369282
            ],
            [
              357640.61328229634,
              5006617.4313660692
            ],
            [
              357639.70692893438,
              5006617.863639974
            ],
            [
              357625.2191805513,
              5006605.4383878214
            ],
            [
              357618.64439575054,
              5006614.7975477232
            ],
            [
              357622.64509521844,
              5006609.102580118
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 410,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357890.61864076066,
              5006552.8238701103
            ],
            [
              357883.21909682575,
              5006544.0232403539
            ],
            [
              357875.03295412235,
              5006533.5818218449
            ],
            [
              357877.07004033594,
              5006528.4716651505
            ],
            [
              357870.07099650137,
              5006523.5352554498
            ],
            [
              357865.14792138495,
              5006520.8986597359
            ],
            [
              357863.16164109187,
              5006517.4865300441
            ],
            [
              357854.97311623918,
              5006522.0985022811
            ],
            [
              357845.06648209237,
              5006528.2564791227
            ],
            [
              357842.51949314389,
              5006529.0252215909
            ],
            [
              357852.41412074497,
              5006550.6164667374
            ],
            [
              357848.92089596047,
              5006551.9434672184
            ],
            [
              357844.04754939553,
              5006551.6691495124
            ],
            [
              357845.27592933603,
              5006558.8236385705
            ],
            [
              357851.16045378346,
              5006570.1594781196
            ],
            [
              357854.87625403242,
              5006568.1118051335
            ],
            [
              357865.59082923044,
              5006562.2073043333
            ],
            [
              357869.2685809079,
              5006560.3287243573
            ],
            [
              357872.86038734921,
              5006560.3222752986
            ],
            [
              357876.927102577,
              5006559.884627495
            ],
            [
              357882.77248836384,
              5006561.5628886288
            ],
            [
              357886.08630790078,
              5006560.5618310701
            ],
            [
              357894.72218313167,
              5006562.0239134533
            ],
            [
              357896.23750646412,
              5006561.9530410413
            ],
            [
              357890.61864076066,
              5006552.8238701103
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 411,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357955.36784763221,
              5006005.5859192535
            ],
            [
              357962.22114842216,
              5005999.9062939184
            ],
            [
              357964.54084447859,
              5005996.6294826269
            ],
            [
              357964.25936728588,
              5005988.3100720122
            ],
            [
              357962.37408983539,
              5005982.1217970774
            ],
            [
              357959.85700930952,
              5005974.7860119222
            ],
            [
              357957.65091029258,
              5005971.04424877
            ],
            [
              357952.56046832108,
              5005967.8138969531
            ],
            [
              357936.97863449604,
              5005966.6873695971
            ],
            [
              357936.01168008713,
              5005976.6016874872
            ],
            [
              357908.92114236654,
              5005974.6888686074
            ],
            [
              357907.85410334339,
              5005985.4105732264
            ],
            [
              357880.88723120111,
              5005983.9557454176
            ],
            [
              357882.72162647307,
              5005960.8884788556
            ],
            [
              357880.78396226087,
              5005955.7461926658
            ],
            [
              357877.13108303846,
              5005957.4251639871
            ],
            [
              357857.37218538852,
              5005965.6542710792
            ],
            [
              357860.0865048031,
              5005975.4833460264
            ],
            [
              357863.83853035717,
              5006002.0625359146
            ],
            [
              357870.72611971927,
              5006030.0147095937
            ],
            [
              357870.95182594814,
              5006036.9359009536
            ],
            [
              357874.19353690644,
              5006036.8296614848
            ],
            [
              357884.27353454655,
              5006036.4993132297
            ],
            [
              357886.60303501814,
              5006035.7721676603
            ],
            [
              357895.92532531277,
              5006032.862246816
            ],
            [
              357903.65984344459,
              5006030.2047319468
            ],
            [
              357919.03722237429,
              5006023.688009277
            ],
            [
              357927.50011537864,
              5006019.7108866069
            ],
            [
              357933.63043560128,
              5006019.3946908955
            ],
            [
              357942.29281202075,
              5006014.7979572592
            ],
            [
              357945.4679669129,
              5006011.5662209354
            ],
            [
              357955.36784763221,
              5006005.5859192535
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 412,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360688.5567194515,
              5003131.3469450129
            ],
            [
              360690.14976313355,
              5003129.7219263129
            ],
            [
              360669.3282884896,
              5003115.3284521634
            ],
            [
              360662.73948031652,
              5003122.1110207634
            ],
            [
              360666.30432449881,
              5003124.7511532931
            ],
            [
              360674.99327280867,
              5003131.186209999
            ],
            [
              360682.48852527264,
              5003136.7372123925
            ],
            [
              360684.73111985263,
              5003134.7451560767
            ],
            [
              360686.38121791725,
              5003133.2794037452
            ],
            [
              360688.5567194515,
              5003131.3469450129
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 413,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352491.73066811351,
              5010300.642015174
            ],
            [
              352489.20707209274,
              5010304.388641729
            ],
            [
              352475.13235715503,
              5010325.3574445304
            ],
            [
              352475.80619809817,
              5010326.4641765663
            ],
            [
              352463.82510299166,
              5010344.5957851782
            ],
            [
              352472.57129298605,
              5010353.4994935887
            ],
            [
              352480.55095360446,
              5010361.6331579965
            ],
            [
              352488.42071521247,
              5010374.2442141837
            ],
            [
              352489.48114477453,
              5010375.5558061656
            ],
            [
              352509.15687924868,
              5010391.0176958544
            ],
            [
              352523.05895123241,
              5010397.4641474914
            ],
            [
              352523.85648642463,
              5010400.5557392696
            ],
            [
              352523.30815761012,
              5010430.7426531957
            ],
            [
              352526.69620846916,
              5010433.8600182608
            ],
            [
              352553.92436586315,
              5010433.6492187725
            ],
            [
              352553.9326213054,
              5010424.6225012299
            ],
            [
              352553.56980419875,
              5010400.9200855652
            ],
            [
              352555.52970183542,
              5010394.8926482322
            ],
            [
              352555.59691682435,
              5010378.6196215926
            ],
            [
              352555.37600900279,
              5010367.4052430997
            ],
            [
              352541.55354620347,
              5010348.3582023093
            ],
            [
              352551.59420787898,
              5010338.6646134621
            ],
            [
              352559.38994578266,
              5010316.1828398034
            ],
            [
              352566.91870052175,
              5010304.207131587
            ],
            [
              352570.14846049011,
              5010299.5207655486
            ],
            [
              352585.13499232667,
              5010283.0547086997
            ],
            [
              352590.08477760333,
              5010274.521408258
            ],
            [
              352590.06030369626,
              5010272.3863599729
            ],
            [
              352583.37217343587,
              5010271.4296485996
            ],
            [
              352576.37833064445,
              5010270.7430790477
            ],
            [
              352558.41276960023,
              5010269.9788436322
            ],
            [
              352558.64320384496,
              5010267.5944734542
            ],
            [
              352540.85224684089,
              5010266.3692783164
            ],
            [
              352534.54339327005,
              5010267.8708452126
            ],
            [
              352529.07383622497,
              5010269.2541851345
            ],
            [
              352528.48531916545,
              5010267.1596725034
            ],
            [
              352526.33051813667,
              5010269.4883196028
            ],
            [
              352517.10017055488,
              5010276.0028985944
            ],
            [
              352491.73066811351,
              5010300.642015174
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 414,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360300.68224345444,
              5005272.6080202656
            ],
            [
              360307.85291939805,
              5005254.3896741606
            ],
            [
              360322.20784785837,
              5005226.7710298272
            ],
            [
              360333.04795192491,
              5005207.3232664289
            ],
            [
              360349.09802264784,
              5005182.0360902902
            ],
            [
              360366.27790524712,
              5005164.6425012639
            ],
            [
              360281.51679328218,
              5005125.1610805281
            ],
            [
              360283.07059132343,
              5005140.327599518
            ],
            [
              360280.00234316231,
              5005160.2565638665
            ],
            [
              360274.37385325349,
              5005175.2756429184
            ],
            [
              360265.54954689491,
              5005187.1686161868
            ],
            [
              360253.63433357939,
              5005197.6995877987
            ],
            [
              360238.44588964398,
              5005201.0449626613
            ],
            [
              360226.72481638315,
              5005207.7208406031
            ],
            [
              360221.8435502869,
              5005206.5415499592
            ],
            [
              360208.28257454868,
              5005204.9259266183
            ],
            [
              360185.60169256659,
              5005191.3028179025
            ],
            [
              360184.09753249976,
              5005190.8078700509
            ],
            [
              360185.20778002421,
              5005198.2578987852
            ],
            [
              360185.08956092515,
              5005212.0060301414
            ],
            [
              360171.31315697572,
              5005222.9452502215
            ],
            [
              360161.35578082624,
              5005218.1249981038
            ],
            [
              360159.63086085697,
              5005223.4102957053
            ],
            [
              360155.82126830565,
              5005231.6241320912
            ],
            [
              360144.89999625849,
              5005243.3320362605
            ],
            [
              360134.41442526784,
              5005258.7161755804
            ],
            [
              360135.77895990334,
              5005259.7367873732
            ],
            [
              360129.06085429748,
              5005272.5899433782
            ],
            [
              360124.64105474221,
              5005280.1427873159
            ],
            [
              360127.2668967552,
              5005288.6852554781
            ],
            [
              360163.89495074603,
              5005295.8055106346
            ],
            [
              360171.80985626037,
              5005299.3190569403
            ],
            [
              360173.80496740894,
              5005310.11448255
            ],
            [
              360172.92871297902,
              5005323.0771785509
            ],
            [
              360170.79657716333,
              5005329.0185961239
            ],
            [
              360166.63619150268,
              5005334.5747904833
            ],
            [
              360158.63639791828,
              5005346.6018680194
            ],
            [
              360144.39635926619,
              5005356.7279498689
            ],
            [
              360153.61158871651,
              5005365.6700412231
            ],
            [
              360161.33461684315,
              5005369.351195368
            ],
            [
              360169.83189873159,
              5005378.3423968675
            ],
            [
              360180.82275637047,
              5005395.0407432346
            ],
            [
              360182.04002411646,
              5005399.9676676197
            ],
            [
              360196.07007483329,
              5005423.4384699604
            ],
            [
              360209.15375426278,
              5005440.9686467294
            ],
            [
              360221.59846789291,
              5005464.1004097555
            ],
            [
              360223.80163046962,
              5005461.5097236084
            ],
            [
              360236.44709761284,
              5005479.4377027499
            ],
            [
              360239.62469009898,
              5005484.8532681521
            ],
            [
              360240.82689683948,
              5005493.0051769856
            ],
            [
              360240.8826498611,
              5005496.0038520461
            ],
            [
              360254.00725638558,
              5005520.8485971252
            ],
            [
              360266.15862020245,
              5005541.7338480288
            ],
            [
              360266.82770579384,
              5005545.4397937423
            ],
            [
              360264.87486913701,
              5005550.1541718431
            ],
            [
              360280.67761901289,
              5005541.4639007999
            ],
            [
              360275.04021477647,
              5005541.6885980247
            ],
            [
              360269.46733080427,
              5005538.9134726711
            ],
            [
              360260.24509280262,
              5005527.0899801077
            ],
            [
              360244.40404243796,
              5005495.5261765644
            ],
            [
              360235.58244978444,
              5005460.0651176302
            ],
            [
              360234.7585585069,
              5005448.5894191042
            ],
            [
              360236.81654362794,
              5005434.0682023903
            ],
            [
              360239.62685754168,
              5005423.6603229064
            ],
            [
              360245.54112592567,
              5005414.6204210017
            ],
            [
              360254.26231462607,
              5005402.5559870237
            ],
            [
              360261.76397403353,
              5005390.0346965585
            ],
            [
              360267.75618135609,
              5005380.8907234194
            ],
            [
              360271.80785990058,
              5005372.6358469399
            ],
            [
              360278.57982801466,
              5005361.2538712481
            ],
            [
              360291.43298810144,
              5005340.8526830832
            ],
            [
              360303.3661365966,
              5005324.3468303485
            ],
            [
              360313.25772446365,
              5005315.1580392765
            ],
            [
              360343.50125122041,
              5005304.7212105468
            ],
            [
              360361.14445485588,
              5005304.543235857
            ],
            [
              360398.65609173209,
              5005310.7497245781
            ],
            [
              360399.57065360306,
              5005307.4308940014
            ],
            [
              360364.61397027789,
              5005297.2748850174
            ],
            [
              360300.68224345444,
              5005272.6080202656
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 415,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360300.68224345444,
              5005272.6080202656
            ],
            [
              360307.85291939805,
              5005254.3896741606
            ],
            [
              360322.20784785837,
              5005226.7710298272
            ],
            [
              360333.04795192491,
              5005207.3232664289
            ],
            [
              360349.09802264784,
              5005182.0360902902
            ],
            [
              360366.27790524712,
              5005164.6425012639
            ],
            [
              360281.51679328218,
              5005125.1610805281
            ],
            [
              360283.07059132343,
              5005140.327599518
            ],
            [
              360280.00234316231,
              5005160.2565638665
            ],
            [
              360274.37385325349,
              5005175.2756429184
            ],
            [
              360265.54954689491,
              5005187.1686161868
            ],
            [
              360253.63433357939,
              5005197.6995877987
            ],
            [
              360238.44588964398,
              5005201.0449626613
            ],
            [
              360226.72481638315,
              5005207.7208406031
            ],
            [
              360221.8435502869,
              5005206.5415499592
            ],
            [
              360208.28257454868,
              5005204.9259266183
            ],
            [
              360185.60169256659,
              5005191.3028179025
            ],
            [
              360184.09753249976,
              5005190.8078700509
            ],
            [
              360185.20778002421,
              5005198.2578987852
            ],
            [
              360185.08956092515,
              5005212.0060301414
            ],
            [
              360171.31315697572,
              5005222.9452502215
            ],
            [
              360161.35578082624,
              5005218.1249981038
            ],
            [
              360159.63086085697,
              5005223.4102957053
            ],
            [
              360155.82126830565,
              5005231.6241320912
            ],
            [
              360144.89999625849,
              5005243.3320362605
            ],
            [
              360134.41442526784,
              5005258.7161755804
            ],
            [
              360135.77895990334,
              5005259.7367873732
            ],
            [
              360129.06085429748,
              5005272.5899433782
            ],
            [
              360124.64105474221,
              5005280.1427873159
            ],
            [
              360127.2668967552,
              5005288.6852554781
            ],
            [
              360163.89495074603,
              5005295.8055106346
            ],
            [
              360171.80985626037,
              5005299.3190569403
            ],
            [
              360173.80496740894,
              5005310.11448255
            ],
            [
              360172.92871297902,
              5005323.0771785509
            ],
            [
              360170.79657716333,
              5005329.0185961239
            ],
            [
              360166.63619150268,
              5005334.5747904833
            ],
            [
              360158.63639791828,
              5005346.6018680194
            ],
            [
              360144.39635926619,
              5005356.7279498689
            ],
            [
              360153.61158871651,
              5005365.6700412231
            ],
            [
              360161.33461684315,
              5005369.351195368
            ],
            [
              360169.83189873159,
              5005378.3423968675
            ],
            [
              360180.82275637047,
              5005395.0407432346
            ],
            [
              360182.04002411646,
              5005399.9676676197
            ],
            [
              360196.07007483329,
              5005423.4384699604
            ],
            [
              360209.15375426278,
              5005440.9686467294
            ],
            [
              360221.59846789291,
              5005464.1004097555
            ],
            [
              360223.80163046962,
              5005461.5097236084
            ],
            [
              360236.44709761284,
              5005479.4377027499
            ],
            [
              360239.62469009898,
              5005484.8532681521
            ],
            [
              360240.82689683948,
              5005493.0051769856
            ],
            [
              360240.8826498611,
              5005496.0038520461
            ],
            [
              360254.00725638558,
              5005520.8485971252
            ],
            [
              360266.15862020245,
              5005541.7338480288
            ],
            [
              360266.82770579384,
              5005545.4397937423
            ],
            [
              360264.87486913701,
              5005550.1541718431
            ],
            [
              360280.67761901289,
              5005541.4639007999
            ],
            [
              360275.04021477647,
              5005541.6885980247
            ],
            [
              360269.46733080427,
              5005538.9134726711
            ],
            [
              360260.24509280262,
              5005527.0899801077
            ],
            [
              360244.40404243796,
              5005495.5261765644
            ],
            [
              360235.58244978444,
              5005460.0651176302
            ],
            [
              360234.7585585069,
              5005448.5894191042
            ],
            [
              360236.81654362794,
              5005434.0682023903
            ],
            [
              360239.62685754168,
              5005423.6603229064
            ],
            [
              360245.54112592567,
              5005414.6204210017
            ],
            [
              360254.26231462607,
              5005402.5559870237
            ],
            [
              360261.76397403353,
              5005390.0346965585
            ],
            [
              360267.75618135609,
              5005380.8907234194
            ],
            [
              360271.80785990058,
              5005372.6358469399
            ],
            [
              360278.57982801466,
              5005361.2538712481
            ],
            [
              360291.43298810144,
              5005340.8526830832
            ],
            [
              360303.3661365966,
              5005324.3468303485
            ],
            [
              360313.25772446365,
              5005315.1580392765
            ],
            [
              360343.50125122041,
              5005304.7212105468
            ],
            [
              360361.14445485588,
              5005304.543235857
            ],
            [
              360398.65609173209,
              5005310.7497245781
            ],
            [
              360399.57065360306,
              5005307.4308940014
            ],
            [
              360364.61397027789,
              5005297.2748850174
            ],
            [
              360300.68224345444,
              5005272.6080202656
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 416,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353044.27938184899,
              5011329.5904878238
            ],
            [
              353041.46768232563,
              5011325.6663469505
            ],
            [
              353040.69920261594,
              5011324.6084519494
            ],
            [
              353019.6045638654,
              5011304.993538606
            ],
            [
              353032.27798611508,
              5011280.2425799649
            ],
            [
              353033.77488488174,
              5011279.8983929222
            ],
            [
              353045.54168623436,
              5011291.4624952404
            ],
            [
              353057.95565199712,
              5011275.4549290221
            ],
            [
              353075.85305036657,
              5011251.6948711425
            ],
            [
              353060.14511988411,
              5011238.4649508931
            ],
            [
              353046.77607047587,
              5011254.2446078938
            ],
            [
              353031.0538813051,
              5011277.5662827045
            ],
            [
              353026.28357772605,
              5011276.0215299111
            ],
            [
              352989.50394219119,
              5011250.1226109918
            ],
            [
              352981.54592371295,
              5011263.2251585666
            ],
            [
              352976.27240846097,
              5011272.2223547772
            ],
            [
              352973.79187081387,
              5011273.0061907936
            ],
            [
              352987.88236783224,
              5011286.1870119823
            ],
            [
              353000.08858091623,
              5011293.7686952921
            ],
            [
              352999.80161648913,
              5011299.6059440281
            ],
            [
              352995.28390420735,
              5011306.6092243064
            ],
            [
              352981.05496844655,
              5011301.9317363221
            ],
            [
              352973.65374613414,
              5011308.0990845365
            ],
            [
              352965.71090763214,
              5011317.0441620145
            ],
            [
              352936.60217760503,
              5011301.0862287777
            ],
            [
              352925.33166830038,
              5011295.9582726695
            ],
            [
              352919.04777622939,
              5011309.0240381202
            ],
            [
              352907.77910551836,
              5011303.9949176479
            ],
            [
              352901.07732514857,
              5011310.5446295142
            ],
            [
              352900.05250177544,
              5011316.4886651728
            ],
            [
              352919.00506423408,
              5011325.2825545166
            ],
            [
              352909.4399967569,
              5011352.6695363019
            ],
            [
              352890.66416273115,
              5011341.972955199
            ],
            [
              352888.65137619205,
              5011347.0391269755
            ],
            [
              352911.57929873018,
              5011364.0515638413
            ],
            [
              352919.47560122341,
              5011366.8477844829
            ],
            [
              352929.56305444287,
              5011372.1583468094
            ],
            [
              352949.8713725686,
              5011383.9509201813
            ],
            [
              352954.84341664659,
              5011384.0512903649
            ],
            [
              352956.4814573155,
              5011381.3199218577
            ],
            [
              352960.28439312795,
              5011383.4195131185
            ],
            [
              352968.8903822648,
              5011384.3204352316
            ],
            [
              352971.08655545185,
              5011386.4037728142
            ],
            [
              352973.5066073988,
              5011384.2411810411
            ],
            [
              352979.29022546689,
              5011375.5267218025
            ],
            [
              352996.26863522868,
              5011358.7484585959
            ],
            [
              353007.12095309078,
              5011350.0107065737
            ],
            [
              353016.81710997649,
              5011344.4375749221
            ],
            [
              353030.9164510853,
              5011336.5322988369
            ],
            [
              353036.52705850662,
              5011333.5419994574
            ],
            [
              353044.27938184899,
              5011329.5904878238
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 417,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              353737.21565956442,
              5011054.6353137083
            ],
            [
              353744.90375483484,
              5011042.0963748908
            ],
            [
              353721.94981102651,
              5011023.0886828518
            ],
            [
              353716.01857439289,
              5011032.8285737466
            ],
            [
              353737.21565956442,
              5011054.6353137083
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 418,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356002.24192088476,
              5007874.9148360956
            ],
            [
              356012.7526623967,
              5007892.5797793977
            ],
            [
              356038.39380135166,
              5007874.7491620295
            ],
            [
              356037.71465385414,
              5007873.1673856508
            ],
            [
              356026.32037633081,
              5007861.4148294367
            ],
            [
              356023.21139987151,
              5007859.099482947
            ],
            [
              356002.24192088476,
              5007874.9148360956
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 419,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358086.37773084949,
              5005644.6150081968
            ],
            [
              358088.74916443211,
              5005650.9534899844
            ],
            [
              358101.97252576909,
              5005674.8518037256
            ],
            [
              358115.43218525493,
              5005694.6957388772
            ],
            [
              358131.30041313294,
              5005717.8551018536
            ],
            [
              358165.49996610387,
              5005770.4218401331
            ],
            [
              358191.19174422574,
              5005809.4185350053
            ],
            [
              358230.81453079829,
              5005819.8971651457
            ],
            [
              358244.5701705651,
              5005822.1336850878
            ],
            [
              358252.67117411172,
              5005821.9830588615
            ],
            [
              358262.43079047371,
              5005818.8419953277
            ],
            [
              358283.67705813842,
              5005796.4835212817
            ],
            [
              358290.5760404077,
              5005787.4206895493
            ],
            [
              358302.82080088981,
              5005758.6873212289
            ],
            [
              358304.06656182493,
              5005750.2526431885
            ],
            [
              358303.1971485074,
              5005745.5198331624
            ],
            [
              358298.64693771879,
              5005735.4794664234
            ],
            [
              358281.01518137072,
              5005709.1708145579
            ],
            [
              358273.59127535968,
              5005695.4454146409
            ],
            [
              358268.75972020061,
              5005679.4294840228
            ],
            [
              358265.3862621155,
              5005665.6287727226
            ],
            [
              358265.05915263406,
              5005648.5970859528
            ],
            [
              358262.60014781804,
              5005633.6889881399
            ],
            [
              358261.55430259794,
              5005610.9661705559
            ],
            [
              358263.25772710209,
              5005610.7296851361
            ],
            [
              358262.66421357781,
              5005578.8081107857
            ],
            [
              358261.06576321641,
              5005576.9397419598
            ],
            [
              358259.42930862936,
              5005576.4589239759
            ],
            [
              358250.08962354704,
              5005576.8184788143
            ],
            [
              358245.16574203025,
              5005577.5443538046
            ],
            [
              358219.59860921622,
              5005585.0352812493
            ],
            [
              358098.04812801554,
              5005628.1637164596
            ],
            [
              358094.36259310908,
              5005629.44561808
            ],
            [
              358092.24964788853,
              5005635.4153876686
            ],
            [
              358089.6376682815,
              5005640.1168304132
            ],
            [
              358086.12600238057,
              5005644.962352111
            ],
            [
              358086.37773084949,
              5005644.6150081968
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 420,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358654.24693286343,
              5005345.1793706156
            ],
            [
              358651.8463145335,
              5005348.7464471953
            ],
            [
              358643.89771972463,
              5005361.9272480514
            ],
            [
              358636.94374905067,
              5005372.5628210595
            ],
            [
              358646.43385957327,
              5005381.0007488914
            ],
            [
              358655.93247514992,
              5005391.7075053863
            ],
            [
              358664.51701750944,
              5005412.3069715882
            ],
            [
              358656.30622054084,
              5005414.4154189797
            ],
            [
              358646.27742555621,
              5005416.2390907304
            ],
            [
              358642.46534614085,
              5005417.3347906517
            ],
            [
              358646.82560334017,
              5005419.620508723
            ],
            [
              358651.18753973319,
              5005427.5885446817
            ],
            [
              358656.30855665618,
              5005433.7205158751
            ],
            [
              358662.35064312874,
              5005443.7449415326
            ],
            [
              358666.40570882591,
              5005449.8419012679
            ],
            [
              358676.56055976212,
              5005448.339841946
            ],
            [
              358683.82571824326,
              5005453.0206303131
            ],
            [
              358686.59416660183,
              5005460.5861091781
            ],
            [
              358695.43785934261,
              5005469.4382859962
            ],
            [
              358706.03240677848,
              5005477.4492142824
            ],
            [
              358692.82039749442,
              5005484.1298291301
            ],
            [
              358699.79859401629,
              5005491.2491645776
            ],
            [
              358718.19150075241,
              5005505.2874191543
            ],
            [
              358724.81347980362,
              5005504.5733426912
            ],
            [
              358733.93549193139,
              5005509.6161874812
            ],
            [
              358751.66517812287,
              5005516.2468513781
            ],
            [
              358755.90881779464,
              5005518.8218937339
            ],
            [
              358767.29047347186,
              5005522.2987904474
            ],
            [
              358774.35224073753,
              5005513.970815883
            ],
            [
              358769.31469047052,
              5005507.6710255081
            ],
            [
              358773.29293238715,
              5005500.6895339815
            ],
            [
              358782.44809100364,
              5005490.2734355349
            ],
            [
              358786.51779807865,
              5005492.7295133453
            ],
            [
              358794.40087563777,
              5005484.4682283169
            ],
            [
              358791.8912412994,
              5005481.8099490162
            ],
            [
              358805.51976175408,
              5005472.9264571015
            ],
            [
              358808.68464969698,
              5005466.72007271
            ],
            [
              358812.58858262311,
              5005456.1557333302
            ],
            [
              358818.80762371729,
              5005445.923182332
            ],
            [
              358821.5931144054,
              5005441.3631943595
            ],
            [
              358824.22067763412,
              5005437.1340447282
            ],
            [
              358825.58693031472,
              5005435.6332182065
            ],
            [
              358807.50959359022,
              5005420.4775449671
            ],
            [
              358815.50486053113,
              5005412.1026344365
            ],
            [
              358816.03286716132,
              5005410.8051112834
            ],
            [
              358812.57960869529,
              5005408.2019261774
            ],
            [
              358821.6913123579,
              5005398.3747383635
            ],
            [
              358792.29727781686,
              5005381.9593189796
            ],
            [
              358809.51108852826,
              5005364.6814560778
            ],
            [
              358794.65729107842,
              5005356.7750964751
            ],
            [
              358793.77611551661,
              5005356.1397766443
            ],
            [
              358785.99943740334,
              5005366.4944062205
            ],
            [
              358779.49199019041,
              5005371.8403791329
            ],
            [
              358772.7946595336,
              5005377.9026678689
            ],
            [
              358764.69868566765,
              5005386.6701835888
            ],
            [
              358756.37373170175,
              5005399.7387046432
            ],
            [
              358745.18405496358,
              5005413.5601292094
            ],
            [
              358736.47348120477,
              5005425.2572473986
            ],
            [
              358730.24230391282,
              5005431.9635531902
            ],
            [
              358735.85564816248,
              5005439.6066349009
            ],
            [
              358727.78978417674,
              5005444.4658478638
            ],
            [
              358717.79477937531,
              5005435.4864082886
            ],
            [
              358711.5377254698,
              5005437.0332723297
            ],
            [
              358706.71396313462,
              5005431.6796678221
            ],
            [
              358691.65757851035,
              5005406.1913730148
            ],
            [
              358696.38256795547,
              5005399.4344910309
            ],
            [
              358702.56148120033,
              5005393.929261528
            ],
            [
              358712.35239707649,
              5005390.28872268
            ],
            [
              358710.15642120317,
              5005387.056110044
            ],
            [
              358707.01929458539,
              5005382.9870514646
            ],
            [
              358692.52560739341,
              5005369.3087801132
            ],
            [
              358686.77550858649,
              5005363.4692082386
            ],
            [
              358670.09832509124,
              5005335.7414871203
            ],
            [
              358664.1662324777,
              5005338.4784235274
            ],
            [
              358658.7092214828,
              5005343.5113225207
            ],
            [
              358654.24693286343,
              5005345.1793706156
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 421,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357068.46627189906,
              5006950.91467747
            ],
            [
              357079.99277216085,
              5006929.8819145989
            ],
            [
              357095.357852721,
              5006901.5365637038
            ],
            [
              357103.53851161065,
              5006887.1349163819
            ],
            [
              357113.99901072815,
              5006868.6526799724
            ],
            [
              357082.89305634709,
              5006845.9698776826
            ],
            [
              357082.25528863817,
              5006846.6314993314
            ],
            [
              357077.99761974189,
              5006848.40003704
            ],
            [
              357069.11886258441,
              5006853.3733394584
            ],
            [
              357062.26448647206,
              5006857.0658692569
            ],
            [
              357058.90427880344,
              5006858.3804996861
            ],
            [
              357056.32714074786,
              5006859.5417387774
            ],
            [
              357048.26552870957,
              5006862.8759823069
            ],
            [
              357042.30090681597,
              5006865.8153760303
            ],
            [
              357034.54936370329,
              5006868.4949966539
            ],
            [
              357023.01515934645,
              5006873.5422670236
            ],
            [
              356993.70899965789,
              5006886.5150954891
            ],
            [
              356993.44071969297,
              5006886.6524846163
            ],
            [
              357026.37272826891,
              5006930.2459984329
            ],
            [
              357034.55813190696,
              5006935.5465677651
            ],
            [
              357045.44995173556,
              5006947.8704533931
            ],
            [
              357054.21565311163,
              5006956.0248659635
            ],
            [
              357057.96899898822,
              5006961.1460651299
            ],
            [
              357055.94642932178,
              5006963.4252162036
            ],
            [
              357060.09462454816,
              5006966.6803990183
            ],
            [
              357068.46627189906,
              5006950.91467747
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 422,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357038.14675410051,
              5007006.2849777676
            ],
            [
              357027.57462535211,
              5007022.6940391297
            ],
            [
              357010.22777373338,
              5007048.7347478047
            ],
            [
              357024.17706290743,
              5007051.9847660158
            ],
            [
              357040.6955750651,
              5007058.7292121416
            ],
            [
              357082.84533404646,
              5007011.4423248172
            ],
            [
              357111.41837549891,
              5006978.7597853653
            ],
            [
              357123.80584389274,
              5006954.6819478069
            ],
            [
              357134.71661862487,
              5006934.1178899249
            ],
            [
              357135.96659498016,
              5006929.8896235088
            ],
            [
              357144.52051782497,
              5006913.5744176041
            ],
            [
              357159.26502067916,
              5006883.561835506
            ],
            [
              357164.48625818186,
              5006866.6446559811
            ],
            [
              357169.14135075948,
              5006843.0984809175
            ],
            [
              357168.73835536488,
              5006832.5823832098
            ],
            [
              357152.29144955723,
              5006826.0689173359
            ],
            [
              357178.36656171759,
              5006779.7919321656
            ],
            [
              357183.80967913236,
              5006782.6831041714
            ],
            [
              357190.33996885788,
              5006784.450001752
            ],
            [
              357214.67639369803,
              5006811.5769741312
            ],
            [
              357216.74902232649,
              5006816.2782116421
            ],
            [
              357231.26931597537,
              5006814.2445466667
            ],
            [
              357235.56173417083,
              5006804.5456763515
            ],
            [
              357244.01595306426,
              5006776.989620911
            ],
            [
              357248.85621521506,
              5006770.7747685481
            ],
            [
              357266.87597092026,
              5006705.8192566428
            ],
            [
              357245.94553967426,
              5006693.299198525
            ],
            [
              357259.86717906856,
              5006663.820541502
            ],
            [
              357277.19496580056,
              5006629.533889343
            ],
            [
              357270.20517921407,
              5006624.6499358751
            ],
            [
              357290.59809768508,
              5006588.8417012049
            ],
            [
              357321.01725620584,
              5006534.7945646085
            ],
            [
              357338.09091408743,
              5006557.8239699816
            ],
            [
              357347.40538502199,
              5006545.7649505073
            ],
            [
              357362.71347769798,
              5006532.3323476575
            ],
            [
              357369.81847276021,
              5006522.4132468402
            ],
            [
              357372.38820125337,
              5006511.1354247807
            ],
            [
              357377.24140486267,
              5006500.0619925428
            ],
            [
              357373.32698366349,
              5006492.6303316997
            ],
            [
              357375.45555575815,
              5006492.0456665624
            ],
            [
              357379.61025050579,
              5006487.735636916
            ],
            [
              357384.17980850459,
              5006479.8585817795
            ],
            [
              357387.0451890172,
              5006474.2963896729
            ],
            [
              357395.24476452777,
              5006466.7539719101
            ],
            [
              357396.97475850623,
              5006467.640374247
            ],
            [
              357405.35543460777,
              5006458.6782198809
            ],
            [
              357423.18083814759,
              5006444.9400939289
            ],
            [
              357416.28279804351,
              5006438.1679254677
            ],
            [
              357412.26128743734,
              5006437.5855147699
            ],
            [
              357410.78341945342,
              5006435.6879671011
            ],
            [
              357411.96741911158,
              5006433.8393532345
            ],
            [
              357416.60445913154,
              5006427.5948956842
            ],
            [
              357419.57260409015,
              5006416.8067965107
            ],
            [
              357421.90226457635,
              5006409.5495540062
            ],
            [
              357421.52087955934,
              5006407.9731259942
            ],
            [
              357421.80741510802,
              5006404.4488140875
            ],
            [
              357423.39127228147,
              5006404.4193618968
            ],
            [
              357431.70840753888,
              5006395.7474746639
            ],
            [
              357451.94053511537,
              5006357.1902650474
            ],
            [
              357456.93019477598,
              5006360.4405108029
            ],
            [
              357468.43450895901,
              5006324.9679941004
            ],
            [
              357472.76671248826,
              5006292.8646444753
            ],
            [
              357477.51495622989,
              5006292.6004318772
            ],
            [
              357489.33028348844,
              5006292.7656534407
            ],
            [
              357494.02175678563,
              5006299.0102583067
            ],
            [
              357499.3169501865,
              5006269.5428902768
            ],
            [
              357502.16428922903,
              5006244.0042171553
            ],
            [
              357504.36956461362,
              5006212.3964822898
            ],
            [
              357507.6233224147,
              5006178.9878421286
            ],
            [
              357510.6049950184,
              5006160.2500244016
            ],
            [
              357529.05305874738,
              5006174.5316297635
            ],
            [
              357538.44431051932,
              5006180.112521166
            ],
            [
              357547.40763006487,
              5006176.0967979971
            ],
            [
              357557.46416585165,
              5006183.1989710433
            ],
            [
              357557.37095994892,
              5006168.5690220203
            ],
            [
              357559.05890312977,
              5006152.4334601676
            ],
            [
              357560.33424521593,
              5006150.1865112577
            ],
            [
              357565.82646695379,
              5006131.4115596609
            ],
            [
              357535.07429505995,
              5006113.1894597514
            ],
            [
              357530.08892078302,
              5006118.9844035544
            ],
            [
              357530.77156699984,
              5006122.9979894292
            ],
            [
              357529.64068006939,
              5006127.5770538328
            ],
            [
              357527.57880457031,
              5006129.610300201
            ],
            [
              357517.16340394935,
              5006121.9956322573
            ],
            [
              357521.31113253807,
              5006102.7440155381
            ],
            [
              357525.94610925176,
              5006088.2810509345
            ],
            [
              357530.13886035391,
              5006078.7711090948
            ],
            [
              357537.0862911719,
              5006064.8741349624
            ],
            [
              357540.93277067086,
              5006054.8913595919
            ],
            [
              357544.33172916865,
              5006050.1448255237
            ],
            [
              357545.66856727621,
              5006039.9908735398
            ],
            [
              357549.77545121429,
              5006032.290436483
            ],
            [
              357557.26838465914,
              5006018.3698089626
            ],
            [
              357558.45081035036,
              5006020.058653879
            ],
            [
              357560.34075053775,
              5006021.6681518769
            ],
            [
              357563.07165929099,
              5006022.3324215943
            ],
            [
              357564.60273036716,
              5006023.8770664996
            ],
            [
              357566.10106717789,
              5006031.3572510406
            ],
            [
              357568.77692355035,
              5006040.6031969665
            ],
            [
              357570.29736124852,
              5006041.5759725804
            ],
            [
              357576.33497707912,
              5006039.247071729
            ],
            [
              357580.7740121347,
              5006035.5892539136
            ],
            [
              357582.89309656277,
              5006036.7704401277
            ],
            [
              357585.07512176153,
              5006034.121333627
            ],
            [
              357590.96141187771,
              5006029.4675373733
            ],
            [
              357601.94158143259,
              5006015.8975846944
            ],
            [
              357609.82725077588,
              5006000.5647996077
            ],
            [
              357613.27636530652,
              5005992.8708663713
            ],
            [
              357616.24949984445,
              5005983.7271027556
            ],
            [
              357618.8844010547,
              5005978.3930508876
            ],
            [
              357612.81191253889,
              5005976.995047491
            ],
            [
              357609.60298642266,
              5005973.7810762683
            ],
            [
              357601.92436690425,
              5005967.7412727484
            ],
            [
              357610.00126211444,
              5005967.5042321952
            ],
            [
              357617.01961041096,
              5005966.4183181291
            ],
            [
              357623.92849006312,
              5005964.1184820058
            ],
            [
              357625.72659248975,
              5005972.3286689743
            ],
            [
              357636.60924814636,
              5005960.8403717829
            ],
            [
              357642.67374227551,
              5005968.7815132076
            ],
            [
              357644.69494553038,
              5005966.571008564
            ],
            [
              357651.17257171817,
              5005974.6277646851
            ],
            [
              357655.36213825364,
              5005970.5021187924
            ],
            [
              357659.85857678199,
              5005975.2467797846
            ],
            [
              357664.98518343671,
              5005980.6563966936
            ],
            [
              357662.69077637658,
              5005982.662268674
            ],
            [
              357677.26668821159,
              5005998.5618122201
            ],
            [
              357696.41677805543,
              5006016.3536914103
            ],
            [
              357700.50550922338,
              5006010.6991714928
            ],
            [
              357706.58436148678,
              5005999.2338120276
            ],
            [
              357710.58060910739,
              5005996.5124093806
            ],
            [
              357709.52074456238,
              5005994.5789634753
            ],
            [
              357715.18666235422,
              5005991.3205847014
            ],
            [
              357732.19553548953,
              5005988.0513573261
            ],
            [
              357745.43662374228,
              5005982.2272695964
            ],
            [
              357753.87908308016,
              5005977.2580325399
            ],
            [
              357774.20686383941,
              5005958.2871521777
            ],
            [
              357775.44566146313,
              5005960.2387117874
            ],
            [
              357782.16480820678,
              5005953.4165793546
            ],
            [
              357783.87419553398,
              5005943.6012713481
            ],
            [
              357784.70994947717,
              5005941.5474783881
            ],
            [
              357833.3921144093,
              5005943.2629088368
            ],
            [
              357844.63013735187,
              5005932.8237645347
            ],
            [
              357853.97258331178,
              5005951.5495811896
            ],
            [
              357857.37218538852,
              5005965.6542710792
            ],
            [
              357867.25163433043,
              5005961.5397174135
            ],
            [
              357877.13108303846,
              5005957.4251639871
            ],
            [
              357880.78396226087,
              5005955.7461926658
            ],
            [
              357882.72162647307,
              5005960.8884788556
            ],
            [
              357880.88723120111,
              5005983.9557454176
            ],
            [
              357907.85410334339,
              5005985.4105732264
            ],
            [
              357908.38762281259,
              5005980.0497209076
            ],
            [
              357908.92114236654,
              5005974.6888686074
            ],
            [
              357922.46641149215,
              5005975.6452780832
            ],
            [
              357936.01168008713,
              5005976.6016874872
            ],
            [
              357936.49515725666,
              5005971.6445285343
            ],
            [
              357936.97863449604,
              5005966.6873695971
            ],
            [
              357941.24971902499,
              5005966.9077114621
            ],
            [
              357943.05857741175,
              5005966.8851383058
            ],
            [
              357944.73547489598,
              5005967.0403799424
            ],
            [
              357947.6023916172,
              5005967.4554404961
            ],
            [
              357950.47117001831,
              5005967.6628459496
            ],
            [
              357952.56046832108,
              5005967.8138969531
            ],
            [
              357963.62685361056,
              5005968.6139687169
            ],
            [
              357964.77377370937,
              5005950.8810553448
            ],
            [
              357973.63673088874,
              5005951.0442681927
            ],
            [
              357974.42038254655,
              5005937.0502524115
            ],
            [
              357995.80587664136,
              5005931.24073123
            ],
            [
              357997.3942542547,
              5005928.4232885698
            ],
            [
              357997.0473496989,
              5005918.5899689104
            ],
            [
              358002.74215374055,
              5005916.024147098
            ],
            [
              358000.26302542753,
              5005906.230473672
            ],
            [
              358002.65977449942,
              5005902.7964512156
            ],
            [
              357998.45515267289,
              5005888.4429684542
            ],
            [
              358000.06726536684,
              5005878.0812436789
            ],
            [
              358000.07276581106,
              5005869.5533408802
            ],
            [
              358001.95563051588,
              5005864.9264389565
            ],
            [
              357999.52527533122,
              5005857.7557969186
            ],
            [
              358002.44347276579,
              5005847.0418126564
            ],
            [
              358003.33504535549,
              5005824.0024275323
            ],
            [
              358006.392811689,
              5005811.9738443913
            ],
            [
              358009.59271096409,
              5005789.9388792422
            ],
            [
              358009.0886542857,
              5005771.3152829511
            ],
            [
              358005.72601215035,
              5005766.9498833027
            ],
            [
              358000.68605679582,
              5005751.7919761091
            ],
            [
              357997.48324551503,
              5005729.5480486834
            ],
            [
              358000.72582406388,
              5005709.8082190668
            ],
            [
              357996.05961096968,
              5005697.1032955395
            ],
            [
              358010.23759039497,
              5005691.9197798185
            ],
            [
              358019.52119162661,
              5005714.7066299096
            ],
            [
              358022.59180098184,
              5005712.1896038586
            ],
            [
              358023.16553613183,
              5005707.7510489179
            ],
            [
              358024.75258217182,
              5005702.6544395592
            ],
            [
              358032.21507047198,
              5005698.0878009116
            ],
            [
              358038.08607702277,
              5005696.1746914433
            ],
            [
              358047.1527211768,
              5005698.4660472255
            ],
            [
              358059.16548233369,
              5005700.3746504132
            ],
            [
              358069.49110525061,
              5005726.2580401963
            ],
            [
              358078.42535180826,
              5005747.9033820219
            ],
            [
              358088.61815425003,
              5005774.3618579824
            ],
            [
              358094.53911720967,
              5005792.7833023723
            ],
            [
              358096.76278707583,
              5005797.6618576804
            ],
            [
              358101.10403329163,
              5005810.5368232764
            ],
            [
              358104.72266959859,
              5005828.6731000645
            ],
            [
              358108.75854907569,
              5005869.0995343244
            ],
            [
              358110.49075420655,
              5005886.4799311301
            ],
            [
              358112.4756705304,
              5005924.3410709668
            ],
            [
              358116.66732504714,
              5005936.67794313
            ],
            [
              358146.03779296123,
              5005937.5081001557
            ],
            [
              358139.52466498467,
              5005950.197274847
            ],
            [
              358138.19298448792,
              5005953.8708572416
            ],
            [
              358134.28897833987,
              5005958.4276056895
            ],
            [
              358135.00108836748,
              5005984.4359602733
            ],
            [
              358140.17627988243,
              5006010.9940393241
            ],
            [
              358153.75938897714,
              5006034.1780787632
            ],
            [
              358168.41146986937,
              5006048.7611991595
            ],
            [
              358187.6018329931,
              5006063.677199549
            ],
            [
              358191.55765097955,
              5006066.7519188272
            ],
            [
              358210.06488216302,
              5006052.6350786947
            ],
            [
              358234.08999123803,
              5006071.6055187806
            ],
            [
              358254.12021425978,
              5006064.1240550736
            ],
            [
              358253.47772520181,
              5006046.2537506968
            ],
            [
              358261.83855588915,
              5006017.0756955929
            ],
            [
              358293.93971606408,
              5006029.2660328681
            ],
            [
              358296.0334587004,
              5006012.2221145881
            ],
            [
              358274.15294029011,
              5006004.832886057
            ],
            [
              358266.94252079859,
              5006000.4710927084
            ],
            [
              358269.0749229209,
              5005993.3638688093
            ],
            [
              358272.32864765794,
              5005983.3742029909
            ],
            [
              358278.16322061018,
              5005985.5154073061
            ],
            [
              358300.14462029689,
              5005985.3722569831
            ],
            [
              358303.61188501253,
              5005965.8012193004
            ],
            [
              358305.97230094863,
              5005948.2654264532
            ],
            [
              358308.01808548282,
              5005931.8436369961
            ],
            [
              358306.56911979901,
              5005920.1929986663
            ],
            [
              358302.36192068888,
              5005901.9182633935
            ],
            [
              358294.0614081177,
              5005880.3607329605
            ],
            [
              358288.30189175886,
              5005868.1003482891
            ],
            [
              358295.50475067052,
              5005864.0379582867
            ],
            [
              358294.29950993502,
              5005854.0208940431
            ],
            [
              358288.19733387086,
              5005838.3924840121
            ],
            [
              358298.25100273162,
              5005831.0760602811
            ],
            [
              358305.65751497837,
              5005836.8015798321
            ],
            [
              358311.68341247161,
              5005844.2290114202
            ],
            [
              358317.24255425873,
              5005839.7575221164
            ],
            [
              358323.03468771966,
              5005830.93242257
            ],
            [
              358315.94156310329,
              5005821.4718473628
            ],
            [
              358321.23778661032,
              5005811.8811518
            ],
            [
              358324.40985358349,
              5005807.5667135781
            ],
            [
              358350.32363990223,
              5005813.2268876852
            ],
            [
              358353.35126276466,
              5005807.8102873079
            ],
            [
              358358.83792847098,
              5005810.454878578
            ],
            [
              358362.50959624903,
              5005804.6663594227
            ],
            [
              358367.85215667816,
              5005795.8247743193
            ],
            [
              358379.5524391089,
              5005803.7019101894
            ],
            [
              358382.75597152818,
              5005798.0464094635
            ],
            [
              358379.89700343454,
              5005793.8778261943
            ],
            [
              358388.08502172498,
              5005779.9277341589
            ],
            [
              358394.08857418399,
              5005781.4635968497
            ],
            [
              358407.97426178952,
              5005785.8390191728
            ],
            [
              358427.12326130894,
              5005791.7073956905
            ],
            [
              358462.19181417057,
              5005803.4029045487
            ],
            [
              358465.51409269526,
              5005795.2189522525
            ],
            [
              358461.50500321441,
              5005788.4271355728
            ],
            [
              358447.77611379378,
              5005788.9809233434
            ],
            [
              358413.93636930163,
              5005768.1154419445
            ],
            [
              358396.79280864511,
              5005761.7170980843
            ],
            [
              358389.15997843951,
              5005756.1489007631
            ],
            [
              358382.06976152607,
              5005745.0327472668
            ],
            [
              358372.3156511244,
              5005734.4667679518
            ],
            [
              358363.20307191042,
              5005726.2771655954
            ],
            [
              358355.22877764859,
              5005707.0205017775
            ],
            [
              358311.77674098749,
              5005696.3435603268
            ],
            [
              358319.66740310407,
              5005653.1653220952
            ],
            [
              358329.16900436889,
              5005628.6769624325
            ],
            [
              358338.16228052147,
              5005607.3320149397
            ],
            [
              358348.25996419549,
              5005581.8700606003
            ],
            [
              358359.58454288007,
              5005565.8585112579
            ],
            [
              358315.52131703461,
              5005577.8446014235
            ],
            [
              358320.63259095151,
              5005583.6351245977
            ],
            [
              358312.09129619115,
              5005602.7513009198
            ],
            [
              358299.71777927974,
              5005600.2935736664
            ],
            [
              358300.52667320397,
              5005544.7907016734
            ],
            [
              358297.43242558511,
              5005516.1709142104
            ],
            [
              358290.58298152854,
              5005482.8923041634
            ],
            [
              358285.03178299218,
              5005458.5072704274
            ],
            [
              358269.3862317623,
              5005447.1910316581
            ],
            [
              358268.32389703387,
              5005445.1505561341
            ],
            [
              358275.47858681547,
              5005430.3648568727
            ],
            [
              358283.01053128223,
              5005414.267478276
            ],
            [
              358303.42281716893,
              5005426.6624478167
            ],
            [
              358320.45013562188,
              5005432.5953097269
            ],
            [
              358341.80086093111,
              5005443.5944065433
            ],
            [
              358360.29802775569,
              5005439.5743687758
            ],
            [
              358361.65033281309,
              5005452.9671814088
            ],
            [
              358361.12890223297,
              5005464.7757556429
            ],
            [
              358361.07239005476,
              5005473.0914954478
            ],
            [
              358364.71052350447,
              5005492.0397711927
            ],
            [
              358368.56915324269,
              5005504.2418994652
            ],
            [
              358374.31978382415,
              5005515.8902472807
            ],
            [
              358377.35901598452,
              5005530.5278497785
            ],
            [
              358379.27389174496,
              5005533.522789361
            ],
            [
              358375.73965510569,
              5005555.6826407909
            ],
            [
              358404.67682846315,
              5005562.7655578917
            ],
            [
              358426.6357049931,
              5005569.8948378637
            ],
            [
              358431.7623792591,
              5005559.1751255216
            ],
            [
              358434.3245504364,
              5005553.8889853712
            ],
            [
              358438.33213481755,
              5005549.0078502726
            ],
            [
              358452.69222726789,
              5005521.5609070016
            ],
            [
              358452.15681448265,
              5005523.0730034634
            ],
            [
              358471.15852382249,
              5005544.8762742179
            ],
            [
              358468.55895082239,
              5005566.7056438215
            ],
            [
              358475.06200169428,
              5005572.9688271862
            ],
            [
              358485.3764716381,
              5005582.1654284522
            ],
            [
              358496.63679044083,
              5005591.7199697876
            ],
            [
              358481.76684727118,
              5005616.3669999484
            ],
            [
              358462.95953894255,
              5005634.5311754504
            ],
            [
              358468.43597540149,
              5005651.6869483776
            ],
            [
              358487.36408622406,
              5005658.5361988768
            ],
            [
              358504.82501480507,
              5005662.0755791636
            ],
            [
              358522.60783808207,
              5005664.0282543637
            ],
            [
              358542.19616251258,
              5005668.5819504661
            ],
            [
              358548.16816072568,
              5005668.4709126446
            ],
            [
              358564.81546561205,
              5005672.5484943697
            ],
            [
              358584.52716634242,
              5005674.2896305313
            ],
            [
              358607.58269582328,
              5005676.319908008
            ],
            [
              358626.76087467157,
              5005677.7197197648
            ],
            [
              358631.47726422601,
              5005676.2269126568
            ],
            [
              358641.25971209776,
              5005668.8935669512
            ],
            [
              358657.25340059813,
              5005669.1230781525
            ],
            [
              358665.14448452328,
              5005668.2738362309
            ],
            [
              358720.61773058859,
              5005684.4549999088
            ],
            [
              358724.88040384086,
              5005667.8967327569
            ],
            [
              358725.33146622108,
              5005659.2569297338
            ],
            [
              358719.21779064962,
              5005656.0421967302
            ],
            [
              358713.1424634732,
              5005651.0521324556
            ],
            [
              358720.33844998985,
              5005639.4685560521
            ],
            [
              358723.30302565155,
              5005634.9516607607
            ],
            [
              358726.25951108686,
              5005629.9996405896
            ],
            [
              358736.0206968672,
              5005615.7936632587
            ],
            [
              358750.98954097478,
              5005593.4621346891
            ],
            [
              358768.16414255113,
              5005609.8209029781
            ],
            [
              358776.457200701,
              5005618.6550397296
            ],
            [
              358787.14758924377,
              5005624.7092728382
            ],
            [
              358790.54417164635,
              5005627.8646299476
            ],
            [
              358794.81313895865,
              5005631.4748108014
            ],
            [
              358804.78998872737,
              5005639.3484942121
            ],
            [
              358807.00073541474,
              5005639.9746237425
            ],
            [
              358797.76273676759,
              5005650.4443387687
            ],
            [
              358790.87134153,
              5005660.3204130372
            ],
            [
              358783.55024981807,
              5005673.1230205661
            ],
            [
              358796.65867753129,
              5005680.7903599357
            ],
            [
              358816.69058916153,
              5005689.025147764
            ],
            [
              358846.78221676388,
              5005700.3837482147
            ],
            [
              358852.97775998007,
              5005703.9033776466
            ],
            [
              358857.64283653355,
              5005708.0800372632
            ],
            [
              358860.67790941219,
              5005711.1607945599
            ],
            [
              358873.41475858499,
              5005724.8544646082
            ],
            [
              358880.08397226554,
              5005732.9355081664
            ],
            [
              358885.33917968097,
              5005740.3646923844
            ],
            [
              358885.33917968202,
              5005740.3646923844
            ],
            [
              358897.03198372375,
              5005733.8827456413
            ],
            [
              358903.4762972786,
              5005726.5585726621
            ],
            [
              358911.24578450032,
              5005714.6678782701
            ],
            [
              358914.60362852964,
              5005701.4496948887
            ],
            [
              358919.40599708428,
              5005690.0840314338
            ],
            [
              358920.98513177276,
              5005665.4658809556
            ],
            [
              358923.00959486834,
              5005664.8017806904
            ],
            [
              358931.15866469214,
              5005690.1787421396
            ],
            [
              358955.71430954384,
              5005696.3000326175
            ],
            [
              358969.76858625788,
              5005695.5254629897
            ],
            [
              358985.19269454019,
              5005660.1866295515
            ],
            [
              358989.35937337397,
              5005644.0343138026
            ],
            [
              358987.77987046464,
              5005643.1706365217
            ],
            [
              358999.94538656954,
              5005600.7480344446
            ],
            [
              359000.53221739375,
              5005596.2718988555
            ],
            [
              359006.72164137056,
              5005592.8079012726
            ],
            [
              359008.43609621126,
              5005588.6902742665
            ],
            [
              359012.16871307598,
              5005573.2158495672
            ],
            [
              359015.99256968149,
              5005562.6514408421
            ],
            [
              359020.39495944692,
              5005547.1645638831
            ],
            [
              359019.26950145507,
              5005534.6828607013
            ],
            [
              359012.1047018111,
              5005527.6354823466
            ],
            [
              359005.68545192265,
              5005528.0341369351
            ],
            [
              358991.64914757229,
              5005523.3105658861
            ],
            [
              358980.00447688508,
              5005517.1814942779
            ],
            [
              358961.44714765082,
              5005500.6464840202
            ],
            [
              358945.61753929628,
              5005532.5625244938
            ],
            [
              358943.52563241142,
              5005539.5834759176
            ],
            [
              358931.1309634705,
              5005530.7135279812
            ],
            [
              358916.92415231344,
              5005520.8276864998
            ],
            [
              358898.14693641668,
              5005533.9199185362
            ],
            [
              358885.73699810711,
              5005543.4086597078
            ],
            [
              358871.56818422506,
              5005547.7076407736
            ],
            [
              358865.22734145779,
              5005525.9861046681
            ],
            [
              358850.69758696429,
              5005523.6450372068
            ],
            [
              358858.37725186668,
              5005515.1937510222
            ],
            [
              358876.0912802837,
              5005474.8165334938
            ],
            [
              358909.64652557444,
              5005496.8206366803
            ],
            [
              358925.33364321571,
              5005476.3818082474
            ],
            [
              358939.46195931389,
              5005431.4911052044
            ],
            [
              358924.8289814734,
              5005428.6914703073
            ],
            [
              358913.75428986864,
              5005424.4012281504
            ],
            [
              358898.33991110924,
              5005410.6998160789
            ],
            [
              358892.47398337221,
              5005400.4234182797
            ],
            [
              358892.35018337186,
              5005393.764741702
            ],
            [
              358932.77857890184,
              5005364.0379298525
            ],
            [
              358954.28196034994,
              5005384.899699958
            ],
            [
              358969.56778472895,
              5005368.2401102288
            ],
            [
              359049.63808938791,
              5005416.9726599799
            ],
            [
              359055.96727131057,
              5005404.3012373848
            ],
            [
              359065.06620448938,
              5005385.7760840869
            ],
            [
              359066.18065966893,
              5005379.0272009354
            ],
            [
              359100.56724195625,
              5005323.1459021065
            ],
            [
              359111.40948604356,
              5005308.5649074521
            ],
            [
              359113.56684503856,
              5005312.7218266614
            ],
            [
              359138.44492046488,
              5005298.4441446131
            ],
            [
              359125.97209045809,
              5005275.1980853677
            ],
            [
              359116.07286417036,
              5005276.7832607804
            ],
            [
              359100.84141453658,
              5005280.5693022599
            ],
            [
              359095.38374590495,
              5005282.3054340137
            ],
            [
              359079.68035690655,
              5005276.2999927737
            ],
            [
              359066.7300910406,
              5005274.8018764919
            ],
            [
              359056.26409782196,
              5005273.2576076258
            ],
            [
              359048.85384392733,
              5005274.3503623456
            ],
            [
              359048.98785486392,
              5005273.5058449535
            ],
            [
              359047.1158376807,
              5005272.415214655
            ],
            [
              359043.96003902104,
              5005270.7927557835
            ],
            [
              359038.76884681132,
              5005268.123860552
            ],
            [
              359030.42185579118,
              5005263.832506245
            ],
            [
              359024.23718420783,
              5005261.698534647
            ],
            [
              359009.23600382888,
              5005252.5209725834
            ],
            [
              359015.45368729095,
              5005245.1800508425
            ],
            [
              359018.17865882727,
              5005243.7542182775
            ],
            [
              359030.66788106289,
              5005228.3947982565
            ],
            [
              359032.03261251462,
              5005221.847787261
            ],
            [
              359030.40795895248,
              5005220.1247804929
            ],
            [
              359042.37533942133,
              5005207.5351173654
            ],
            [
              359052.27184131084,
              5005193.70458923
            ],
            [
              359059.58223055722,
              5005188.766300776
            ],
            [
              359062.41442772542,
              5005189.1171504427
            ],
            [
              359064.81034093525,
              5005192.0583756575
            ],
            [
              359077.34565088834,
              5005184.3113276586
            ],
            [
              359091.3980718411,
              5005179.8460248318
            ],
            [
              359100.12821040215,
              5005179.5212271102
            ],
            [
              359121.21447224147,
              5005175.9820432561
            ],
            [
              359127.01716642571,
              5005166.755468783
            ],
            [
              359137.16236762964,
              5005171.2965206588
            ],
            [
              359142.33700838924,
              5005166.8023507455
            ],
            [
              359147.45964546001,
              5005160.5165743539
            ],
            [
              359153.57907827949,
              5005153.0655774483
            ],
            [
              359150.35913999105,
              5005141.6985872081
            ],
            [
              359151.04701727198,
              5005133.3863109974
            ],
            [
              359153.69642076798,
              5005120.2951596333
            ],
            [
              359162.26130420913,
              5005101.9732486028
            ],
            [
              359172.1007137057,
              5005087.4766852977
            ],
            [
              359160.4197442545,
              5005080.5971694393
            ],
            [
              359152.68682685477,
              5005078.9366865596
            ],
            [
              359118.50507722801,
              5005078.7301837755
            ],
            [
              359093.58037352416,
              5005075.1707633603
            ],
            [
              359081.23596497817,
              5005075.5717788143
            ],
            [
              359063.43461804948,
              5005074.9624473276
            ],
            [
              359055.2347259901,
              5005074.1635011779
            ],
            [
              359049.31168629974,
              5005069.2944775606
            ],
            [
              359036.97099613573,
              5005069.729640008
            ],
            [
              359040.57481777354,
              5005051.4999453295
            ],
            [
              359050.80623820849,
              5005020.1774932435
            ],
            [
              359060.94278338732,
              5005003.7175659584
            ],
            [
              359061.869307847,
              5004997.01715462
            ],
            [
              359065.71682410117,
              5004980.2730364352
            ],
            [
              359056.51656036137,
              5004976.6232504789
            ],
            [
              359064.68314022681,
              5004958.4420963218
            ],
            [
              359077.40396497323,
              5004964.32658689
            ],
            [
              359079.23894760566,
              5004957.6387323588
            ],
            [
              359082.0797349725,
              5004950.6181402337
            ],
            [
              359112.25019589596,
              5004961.4801377235
            ],
            [
              359146.04545989993,
              5004972.0459888838
            ],
            [
              359161.30593551026,
              5004981.4714741856
            ],
            [
              359175.63829525193,
              5004985.5459404923
            ],
            [
              359175.58402296022,
              5004977.5881623505
            ],
            [
              359182.90248780185,
              5004962.7097989172
            ],
            [
              359177.42646251054,
              5004960.8696037512
            ],
            [
              359176.49392844172,
              5004947.5225028656
            ],
            [
              359141.76722397463,
              5004924.7620792286
            ],
            [
              359141.18094893516,
              5004920.167334849
            ],
            [
              359139.06715992157,
              5004914.7744782539
            ],
            [
              359120.14984587178,
              5004904.8135330388
            ],
            [
              359110.66991632024,
              5004898.9824308548
            ],
            [
              359111.77352292411,
              5004892.0402662382
            ],
            [
              359111.20110756502,
              5004886.2768713403
            ],
            [
              359113.63678589062,
              5004879.6343286475
            ],
            [
              359108.42968531465,
              5004876.9022562541
            ],
            [
              359099.28024191485,
              5004891.139434007
            ],
            [
              359070.03231578122,
              5004878.6135329725
            ],
            [
              359064.0476618298,
              5004878.7247923175
            ],
            [
              359056.90877622314,
              5004891.839874587
            ],
            [
              359040.12522512098,
              5004885.7777733291
            ],
            [
              359008.14740218624,
              5004869.2076340439
            ],
            [
              359016.93984734319,
              5004859.7503470574
            ],
            [
              359023.27042181644,
              5004855.3949286435
            ],
            [
              359030.71057337656,
              5004845.7394224769
            ],
            [
              359039.35034584691,
              5004849.8572681071
            ],
            [
              359049.46329470357,
              5004853.5566700343
            ],
            [
              359052.08791125071,
              5004852.0729576293
            ],
            [
              359063.0045321018,
              5004835.2872881955
            ],
            [
              359078.45299787814,
              5004847.1897584349
            ],
            [
              359102.97035114717,
              5004818.5276468163
            ],
            [
              359083.8134145065,
              5004805.2543884274
            ],
            [
              359064.96609782532,
              5004832.4562129555
            ],
            [
              359035.89807854098,
              5004817.5309703154
            ],
            [
              359029.03297227959,
              5004813.5977045316
            ],
            [
              359022.99934363569,
              5004810.1408220362
            ],
            [
              359012.79869444948,
              5004804.2965036631
            ],
            [
              359008.35877730761,
              5004801.7527155597
            ],
            [
              359005.566517131,
              5004800.1529293275
            ],
            [
              359001.97781786736,
              5004798.0968344864
            ],
            [
              359024.90239672962,
              5004767.1361133913
            ],
            [
              359051.63563898916,
              5004734.4337761458
            ],
            [
              359060.27994859911,
              5004741.4866546998
            ],
            [
              359095.27604891226,
              5004770.6906980015
            ],
            [
              359105.15631799394,
              5004779.710235632
            ],
            [
              359116.10711485852,
              5004789.6845563464
            ],
            [
              359130.11834151432,
              5004774.2446531234
            ],
            [
              359148.71779592527,
              5004763.2698566439
            ],
            [
              359145.76365956332,
              5004757.5449610092
            ],
            [
              359135.18409841222,
              5004743.9163903212
            ],
            [
              359122.13237103721,
              5004725.4328357531
            ],
            [
              359099.12405056966,
              5004701.7509121699
            ],
            [
              359088.50930066401,
              5004689.8456115862
            ],
            [
              359126.15215056652,
              5004655.0761019429
            ],
            [
              359178.11027155688,
              5004613.13339487
            ],
            [
              359199.93245488143,
              5004596.3040891606
            ],
            [
              359252.53033319942,
              5004568.0242295349
            ],
            [
              359292.76123897225,
              5004537.5813667513
            ],
            [
              359308.02749869699,
              5004529.8351591397
            ],
            [
              359313.88803663629,
              5004536.3092949139
            ],
            [
              359334.46999536373,
              5004557.6463799151
            ],
            [
              359338.54165288986,
              5004560.1680074781
            ],
            [
              359352.80974703946,
              5004573.4087863509
            ],
            [
              359356.74198297696,
              5004575.413547948
            ],
            [
              359368.13566011132,
              5004559.7477238989
            ],
            [
              359354.16613745264,
              5004548.1848438634
            ],
            [
              359336.89476328477,
              5004529.8618428167
            ],
            [
              359329.2663035806,
              5004538.1380904326
            ],
            [
              359321.84698755672,
              5004531.4407565203
            ],
            [
              359315.38447043888,
              5004525.8818365736
            ],
            [
              359344.61640190921,
              5004510.7646402577
            ],
            [
              359358.89521323366,
              5004490.5018296242
            ],
            [
              359376.49564367492,
              5004458.9657635633
            ],
            [
              359357.12965190335,
              5004447.6121832021
            ],
            [
              359331.80536489841,
              5004434.3541443218
            ],
            [
              359331.12164097326,
              5004431.4699467057
            ],
            [
              359314.48354443075,
              5004422.0786011545
            ],
            [
              359302.9656935024,
              5004418.6123395655
            ],
            [
              359298.71409079444,
              5004414.3101013023
            ],
            [
              359286.23687084875,
              5004408.6608415106
            ],
            [
              359279.45866806799,
              5004398.9648910733
            ],
            [
              359262.47046885703,
              5004374.6624938417
            ],
            [
              359261.03388215537,
              5004372.3454636242
            ],
            [
              359258.38790317514,
              5004369.3378884764
            ],
            [
              359250.02755322674,
              5004356.7381817736
            ],
            [
              359234.91143759788,
              5004336.899453897
            ],
            [
              359226.77956292988,
              5004324.8573258612
            ],
            [
              359220.15644366172,
              5004312.2867980488
            ],
            [
              359216.54391079949,
              5004306.1490494274
            ],
            [
              359212.4415436511,
              5004298.5640144572
            ],
            [
              359203.60113915615,
              5004300.939185583
            ],
            [
              359188.89942844724,
              5004305.1135119302
            ],
            [
              359180.17751948338,
              5004307.6360632665
            ],
            [
              359170.28632170946,
              5004308.7695305431
            ],
            [
              359166.12886930915,
              5004309.7108810088
            ],
            [
              359161.89296925109,
              5004310.8584484383
            ],
            [
              359154.78966897092,
              5004314.1519755935
            ],
            [
              359147.82119423599,
              5004316.1536526317
            ],
            [
              359140.14166830963,
              5004318.2974781729
            ],
            [
              359130.30363738217,
              5004319.8666576548
            ],
            [
              359126.62754228833,
              5004320.5814732974
            ],
            [
              359114.21671747835,
              5004322.5209006313
            ],
            [
              359110.22516616195,
              5004324.0568846976
            ],
            [
              359089.61510168569,
              5004333.4141043434
            ],
            [
              359087.35160264367,
              5004334.8102759393
            ],
            [
              359081.29530641565,
              5004338.8588005267
            ],
            [
              359076.7336620671,
              5004343.7292571813
            ],
            [
              359072.45858411357,
              5004350.0064569702
            ],
            [
              359057.03306527069,
              5004368.6433032183
            ],
            [
              359054.62354233389,
              5004371.4471413838
            ],
            [
              359050.89053176896,
              5004375.3208834575
            ],
            [
              359044.97227303794,
              5004381.3308369108
            ],
            [
              359042.28009695263,
              5004383.7759775054
            ],
            [
              359037.12159726716,
              5004388.1694060201
            ],
            [
              359030.40530896198,
              5004392.1599305347
            ],
            [
              359027.69662402576,
              5004393.7408539532
            ],
            [
              359019.57054214034,
              5004398.7971390923
            ],
            [
              359012.40614968963,
              5004405.4401570214
            ],
            [
              359008.45906059822,
              5004409.4538905062
            ],
            [
              359003.4580868631,
              5004413.769476993
            ],
            [
              358999.68149636424,
              5004417.2865112945
            ],
            [
              358992.90846903523,
              5004422.7632112149
            ],
            [
              358985.48272054893,
              5004428.5157686034
            ],
            [
              358978.20356082678,
              5004434.5231580082
            ],
            [
              358973.78891226457,
              5004438.4319559317
            ],
            [
              358967.8821699507,
              5004443.7944178684
            ],
            [
              358962.775151596,
              5004448.671361398
            ],
            [
              358957.50338244741,
              5004453.4021476982
            ],
            [
              358957.18758347636,
              5004460.9527269062
            ],
            [
              358956.01012960513,
              5004465.7217531577
            ],
            [
              358954.19101787865,
              5004470.0279443171
            ],
            [
              358952.88476022572,
              5004476.3817063039
            ],
            [
              358953.02296661644,
              5004483.8163084649
            ],
            [
              358962.9406870461,
              5004494.1309400937
            ],
            [
              358967.21176036348,
              5004491.0218431465
            ],
            [
              358967.92501706671,
              5004492.196139236
            ],
            [
              358972.75084458687,
              5004498.9695975604
            ],
            [
              358977.12726920814,
              5004504.548475909
            ],
            [
              358979.6499877001,
              5004508.4780641375
            ],
            [
              358981.80136961816,
              5004510.7477450455
            ],
            [
              358984.66861904477,
              5004513.7070128955
            ],
            [
              358985.9388542971,
              5004515.7476334218
            ],
            [
              358983.19107996108,
              5004518.0767439986
            ],
            [
              358971.8345218451,
              5004526.54254275
            ],
            [
              358957.34989337437,
              5004536.341333596
            ],
            [
              358937.11104500032,
              5004550.8143317997
            ],
            [
              358926.82044378849,
              5004558.6947652567
            ],
            [
              358922.59040807531,
              5004562.6441761889
            ],
            [
              358917.11311042629,
              5004568.7836997854
            ],
            [
              358914.72006693535,
              5004573.7672170531
            ],
            [
              358913.05142547895,
              5004580.4314862285
            ],
            [
              358911.89502006845,
              5004586.4472134598
            ],
            [
              358909.97681840294,
              5004598.2575517381
            ],
            [
              358907.68612707971,
              5004611.6983439792
            ],
            [
              358906.15955609485,
              5004619.5077105053
            ],
            [
              358906.07430434914,
              5004621.1231176704
            ],
            [
              358905.40121037286,
              5004625.2278378196
            ],
            [
              358904.30561850563,
              5004631.4153701868
            ],
            [
              358904.31882293947,
              5004633.7314613033
            ],
            [
              358904.04196430888,
              5004641.1026346143
            ],
            [
              358901.70277271309,
              5004653.4256735751
            ],
            [
              358900.12034731352,
              5004661.814268373
            ],
            [
              358898.72010098671,
              5004669.2345329253
            ],
            [
              358897.6785332319,
              5004674.1048414949
            ],
            [
              358896.81931444752,
              5004677.1217243401
            ],
            [
              358897.7272475615,
              5004681.2133573433
            ],
            [
              358899.42318027228,
              5004683.8151145494
            ],
            [
              358899.44348756579,
              5004683.8271813551
            ],
            [
              358888.77354687557,
              5004696.8609717842
            ],
            [
              358877.89938679995,
              5004711.1144133685
            ],
            [
              358866.99395003589,
              5004731.0168269444
            ],
            [
              358861.56170971139,
              5004743.3144569499
            ],
            [
              358858.88324155897,
              5004749.7427179264
            ],
            [
              358852.48296922026,
              5004771.0238747988
            ],
            [
              358842.32382648851,
              5004809.1889414433
            ],
            [
              358836.89830938389,
              5004826.6636852389
            ],
            [
              358832.79822527571,
              5004838.10477925
            ],
            [
              358827.75205971271,
              5004847.865261198
            ],
            [
              358819.57040744752,
              5004857.6840343736
            ],
            [
              358812.02806990367,
              5004864.2289403025
            ],
            [
              358811.37797661783,
              5004863.3086342467
            ],
            [
              358804.09040331014,
              5004853.4303313429
            ],
            [
              358801.21388019988,
              5004850.5707037523
            ],
            [
              358787.31726733787,
              5004842.8180229394
            ],
            [
              358778.9826424151,
              5004837.2262034584
            ],
            [
              358774.73029908899,
              5004833.8459506817
            ],
            [
              358767.39397424337,
              5004826.244411706
            ],
            [
              358762.95278236677,
              5004822.503530967
            ],
            [
              358756.2286995133,
              5004818.4409519192
            ],
            [
              358750.44731944351,
              5004815.2420940828
            ],
            [
              358737.44988077361,
              5004806.0394029943
            ],
            [
              358747.69760457013,
              5004797.4232748169
            ],
            [
              358712.7796480938,
              5004767.5161897969
            ],
            [
              358699.72552204138,
              5004751.1672677463
            ],
            [
              358696.13631629694,
              5004752.6823951993
            ],
            [
              358686.96597345272,
              5004743.5773144467
            ],
            [
              358679.77807176515,
              5004745.366647128
            ],
            [
              358660.94162700762,
              5004749.5567682972
            ],
            [
              358658.22291241965,
              5004750.213619167
            ],
            [
              358655.09972900542,
              5004750.8779892866
            ],
            [
              358651.86981110676,
              5004751.2411892116
            ],
            [
              358647.07066634035,
              5004751.7665117197
            ],
            [
              358640.00040459371,
              5004752.3021685593
            ],
            [
              358633.53117900726,
              5004752.5234974548
            ],
            [
              358625.34116537956,
              5004752.6757531771
            ],
            [
              358614.91547085706,
              5004752.2632621862
            ],
            [
              358601.34085059358,
              5004762.0144368187
            ],
            [
              358588.96360833856,
              5004763.2589012291
            ],
            [
              358589.24505792029,
              5004772.954612853
            ],
            [
              358583.90866209049,
              5004774.2664369745
            ],
            [
              358577.26352517394,
              5004775.9057457605
            ],
            [
              358565.36994684831,
              5004778.147871363
            ],
            [
              358554.58673047257,
              5004780.2683271272
            ],
            [
              358548.33472800796,
              5004781.2940185359
            ],
            [
              358543.45917825325,
              5004782.1265471019
            ],
            [
              358539.82485665096,
              5004782.4972654944
            ],
            [
              358536.18483491108,
              5004782.5649353135
            ],
            [
              358532.03548782802,
              5004782.439982567
            ],
            [
              358527.9779174393,
              5004781.8080423633
            ],
            [
              358523.92034766375,
              5004781.1761376746
            ],
            [
              358519.44328484737,
              5004779.7435956988
            ],
            [
              358516.23133890313,
              5004778.4568858985
            ],
            [
              358513.4750644911,
              5004777.0934169386
            ],
            [
              358510.10454146127,
              5004775.3371491926
            ],
            [
              358507.95493735472,
              5004773.9624018902
            ],
            [
              358505.49069660233,
              5004771.9871591264
            ],
            [
              358503.12943535444,
              5004770.1111008208
            ],
            [
              358501.17452059244,
              5004768.328516148
            ],
            [
              358499.02116012888,
              5004766.7517118501
            ],
            [
              358496.56630951533,
              5004765.2815757459
            ],
            [
              358493.81379066367,
              5004764.1201279853
            ],
            [
              358490.65680279391,
              5004762.9661994642
            ],
            [
              358487.91555156361,
              5004762.4108513677
            ],
            [
              358484.67436444614,
              5004762.167951881
            ],
            [
              358479.51763301011,
              5004762.2638177937
            ],
            [
              358476.2895916386,
              5004762.7280464806
            ],
            [
              358474.17942418548,
              5004763.4746480072
            ],
            [
              358469.6594759234,
              5004765.1754767001
            ],
            [
              358466.50172140374,
              5004766.9677928546
            ],
            [
              358459.59640703927,
              5004770.9361478975
            ],
            [
              358444.09127960599,
              5004780.2179708136
            ],
            [
              358418.48471932224,
              5004795.6496150168
            ],
            [
              358412.18795235502,
              5004799.7076858021
            ],
            [
              358406.65124852728,
              5004803.8168220464
            ],
            [
              358402.46277173195,
              5004807.0272982111
            ],
            [
              358400.18044937949,
              5004809.3939017998
            ],
            [
              358398.10589607916,
              5004812.0597975319
            ],
            [
              358396.53885704401,
              5004814.8173217149
            ],
            [
              358394.7808181041,
              5004818.1847060611
            ],
            [
              358393.32420315273,
              5004821.4454233814
            ],
            [
              358392.26836788713,
              5004824.4965987187
            ],
            [
              358391.70489092427,
              5004826.8312836224
            ],
            [
              358391.25378181424,
              5004829.7701533372
            ],
            [
              358391.01614194951,
              5004833.3113638768
            ],
            [
              358391.08750441839,
              5004837.1499845348
            ],
            [
              358391.56333670631,
              5004840.9811213771
            ],
            [
              358391.80192255159,
              5004842.774071754
            ],
            [
              358392.34692970902,
              5004844.886022401
            ],
            [
              358392.79083616397,
              5004846.9998525716
            ],
            [
              358393.04374276713,
              5004849.7235429045
            ],
            [
              358392.68997884868,
              5004852.4585116599
            ],
            [
              358391.93744600465,
              5004855.5040484965
            ],
            [
              358390.78232226311,
              5004858.658133205
            ],
            [
              358389.31826181803,
              5004861.5147710759
            ],
            [
              358387.3410538467,
              5004863.976766224
            ],
            [
              358385.15795497026,
              5004866.2404977987
            ],
            [
              358382.86805519898,
              5004868.2030602293
            ],
            [
              358380.76727706281,
              5004869.4547339994
            ],
            [
              358378.26202912902,
              5004870.7138916366
            ],
            [
              358375.34667877981,
              5004871.6775542693
            ],
            [
              358373.13165449403,
              5004872.2240147693
            ],
            [
              358371.11319704721,
              5004872.463630707
            ],
            [
              358367.16417119297,
              5004872.2338914182
            ],
            [
              358361.91015929263,
              5004872.5336587885
            ],
            [
              358342.92920242669,
              5004872.3166211629
            ],
            [
              358340.0962387764,
              5004872.2682246966
            ],
            [
              358334.92830360169,
              5004871.75799111
            ],
            [
              358329.14799817384,
              5004870.9560227562
            ],
            [
              358321.94282511662,
              5004869.6752266046
            ],
            [
              358315.6476254671,
              5004868.3775488539
            ],
            [
              358310.15573020186,
              5004866.761782282
            ],
            [
              358306.53166582139,
              5004865.3849766143
            ],
            [
              358300.11658519931,
              5004863.0789997065
            ],
            [
              358292.88887653657,
              5004860.5860387376
            ],
            [
              358287.89309436822,
              5004858.4557671892
            ],
            [
              358284.32224540948,
              5004856.8042866932
            ],
            [
              358282.38423070108,
              5004855.9308514744
            ],
            [
              358280.44809417665,
              5004855.1584447678
            ],
            [
              358278.41273442452,
              5004854.4889105745
            ],
            [
              358275.06293241336,
              5004853.8438127851
            ],
            [
              358270.69447891059,
              5004852.8134697648
            ],
            [
              358265.82421106362,
              5004851.9945471399
            ],
            [
              358261.96883891436,
              5004851.3588482067
            ],
            [
              358258.01800054038,
              5004851.0281143812
            ],
            [
              358251.37168173655,
              5004850.2299100608
            ],
            [
              358243.77518385166,
              5004849.6637612181
            ],
            [
              358238.51178041112,
              5004849.4584567556
            ],
            [
              358232.03503523854,
              5004849.275709155
            ],
            [
              358225.06023197371,
              5004849.5064390684
            ],
            [
              358218.5947543722,
              5004849.9297916321
            ],
            [
              358213.03924981732,
              5004850.3362271786
            ],
            [
              358207.28717754706,
              5004851.0494718999
            ],
            [
              358202.24287683336,
              5004851.7495586742
            ],
            [
              358189.16256842605,
              5004853.7244958002
            ],
            [
              358183.9216335651,
              5004854.7313930718
            ],
            [
              358176.57059514616,
              5004856.4848563531
            ],
            [
              358169.21385706699,
              5004857.9353063498
            ],
            [
              358159.74319328048,
              5004859.9303020975
            ],
            [
              358153.60355251725,
              5004861.5602169055
            ],
            [
              358147.16624321736,
              5004863.4988205368
            ],
            [
              358141.4348273878,
              5004865.3232376734
            ],
            [
              358135.00503000879,
              5004867.6659201654
            ],
            [
              358131.45593896642,
              5004869.0850064633
            ],
            [
              358128.55373258545,
              5004870.7557986788
            ],
            [
              358125.96052809595,
              5004872.7239657007
            ],
            [
              358123.77923957194,
              5004875.0887288563
            ],
            [
              358122.01368769881,
              5004878.052037444
            ],
            [
              358120.9522181722,
              5004880.800165195
            ],
            [
              358120.30078578828,
              5004883.8438249538
            ],
            [
              358120.04624446196,
              5004886.4758878015
            ],
            [
              358120.30290733179,
              5004889.4016018631
            ],
            [
              358121.15879600617,
              5004891.911992969
            ],
            [
              358122.61941125797,
              5004894.3100782083
            ],
            [
              358124.47510624136,
              5004896.1955722356
            ],
            [
              358126.42063344351,
              5004897.4730508225
            ],
            [
              358128.66570726631,
              5004898.5428691516
            ],
            [
              358130.598088992,
              5004899.1132543553
            ],
            [
              358132.8300835811,
              5004899.4759780793
            ],
            [
              358139.10087331414,
              5004899.4604622843
            ],
            [
              358148.70080800646,
              5004898.9788365113
            ],
            [
              358164.05844937492,
              5004898.0870152796
            ],
            [
              358221.58169293706,
              5004893.585504937
            ],
            [
              358293.36343075434,
              5004887.6060866499
            ],
            [
              358335.48666002939,
              5004913.8378783558
            ],
            [
              358337.22247312067,
              5004914.7150722193
            ],
            [
              358338.75789604214,
              5004915.6970193814
            ],
            [
              358340.60795600148,
              5004917.2794621317
            ],
            [
              358342.36067099025,
              5004919.0658058915
            ],
            [
              358343.6115718417,
              5004921.0635701073
            ],
            [
              358344.55534849811,
              5004922.8649527319
            ],
            [
              358345.29323419364,
              5004924.4680717541
            ],
            [
              358345.8325416976,
              5004926.2769738166
            ],
            [
              358346.11153709225,
              5004928.3719368111
            ],
            [
              358346.15473072341,
              5004930.6953080883
            ],
            [
              358345.99941309047,
              5004933.2244967166
            ],
            [
              358345.53898132668,
              5004935.6582942847
            ],
            [
              358345.06345891947,
              5004937.2839361411
            ],
            [
              358343.89143380412,
              5004939.5288716312
            ],
            [
              358342.91034217592,
              5004941.1639480675
            ],
            [
              358340.53818761214,
              5004944.1385324653
            ],
            [
              358317.81343984284,
              5004969.8571874369
            ],
            [
              358284.0182828643,
              5004937.5165050672
            ],
            [
              358266.23584213218,
              5004938.5544706117
            ],
            [
              358266.30532758916,
              5004942.2920641098
            ],
            [
              358281.35966200626,
              5004941.4059154876
            ],
            [
              358315.21125350666,
              5004972.3046392836
            ],
            [
              358313.13339906762,
              5004974.6538994517
            ],
            [
              358311.24421219103,
              5004976.406886477
            ],
            [
              358308.45256466116,
              5004978.5808694093
            ],
            [
              358304.66104527086,
              5004981.379750574
            ],
            [
              358301.36007196183,
              5004983.3611111892
            ],
            [
              358296.95249133394,
              5004985.6661640927
            ],
            [
              358293.34251546132,
              5004987.3501147265
            ],
            [
              358290.73428901104,
              5004988.5101601342
            ],
            [
              358288.52483215142,
              5004989.3596727764
            ],
            [
              358286.91459932958,
              5004989.7937914366
            ],
            [
              358284.39996197808,
              5004990.547914302
            ],
            [
              358281.07262966497,
              5004991.1150191985
            ],
            [
              358277.13681525941,
              5004991.5924085686
            ],
            [
              358271.98014703998,
              5004991.6882767575
            ],
            [
              358268.49199858721,
              5004991.8472213149
            ],
            [
              358266.16648255347,
              5004991.8904552432
            ],
            [
              358263.22859544988,
              5004991.6419190457
            ],
            [
              358259.57354695309,
              5004990.9014695305
            ],
            [
              358254.79881159874,
              5004989.7776179789
            ],
            [
              358251.14000655367,
              5004988.8351113666
            ],
            [
              358247.58230288996,
              5004987.8907607002
            ],
            [
              358243.82621906971,
              5004987.1521539185
            ],
            [
              358237.32874978171,
              5004985.8582384139
            ],
            [
              358225.25142808148,
              5004983.6575304819
            ],
            [
              358206.19630917581,
              5004979.4589178124
            ],
            [
              358206.12737988593,
              5004986.6348519158
            ],
            [
              358198.45043858071,
              5004987.1817929875
            ],
            [
              358197.9518234579,
              5004976.681706693
            ],
            [
              358181.95183053025,
              5004975.6654807571
            ],
            [
              358182.02695110044,
              5004979.7061608173
            ],
            [
              358175.64792074397,
              5004981.4605743811
            ],
            [
              358171.92745230772,
              5004982.641297915
            ],
            [
              358167.60782516416,
              5004984.2373788292
            ],
            [
              358162.6890392949,
              5004986.2488171523
            ],
            [
              358158.27394530509,
              5004988.1498278687
            ],
            [
              358154.06299666158,
              5004990.1480712714
            ],
            [
              358149.55988185771,
              5004992.7580917431
            ],
            [
              358144.85838750127,
              5004995.5738917897
            ],
            [
              358140.55941802199,
              5004998.2811450427
            ],
            [
              358135.86543587124,
              5005001.5010239603
            ],
            [
              358130.17714851297,
              5005005.648817407
            ],
            [
              358127.8489783727,
              5005007.6419186266
            ],
            [
              358123.86840133899,
              5005011.1516541159
            ],
            [
              358122.5085745856,
              5005003.2949427394
            ],
            [
              358116.68920464284,
              5005005.8283708291
            ],
            [
              358119.05666216847,
              5005018.9210463259
            ],
            [
              358112.95458166592,
              5005022.5712868264
            ],
            [
              358105.65988396452,
              5005027.3552914178
            ],
            [
              358099.42842428829,
              5005031.3579094196
            ],
            [
              358095.13321035722,
              5005034.2671850929
            ],
            [
              358091.04214316345,
              5005037.2737645376
            ],
            [
              358085.55236752355,
              5005041.2157770852
            ],
            [
              358081.06434247759,
              5005044.6339195445
            ],
            [
              358075.38363333262,
              5005049.1857919265
            ],
            [
              358071.20266650274,
              5005052.8003174402
            ],
            [
              358067.5216365229,
              5005056.1024291487
            ],
            [
              358063.72792391729,
              5005053.3435010239
            ],
            [
              358061.5617265387,
              5005056.5163865956
            ],
            [
              358065.45466174989,
              5005059.1724065244
            ],
            [
              358061.29167055961,
              5005061.8359328173
            ],
            [
              358058.39134278573,
              5005063.6077555856
            ],
            [
              358055.58466904098,
              5005064.9735832065
            ],
            [
              358051.97093517944,
              5005066.4555142261
            ],
            [
              358048.75228017819,
              5005067.4248182364
            ],
            [
              358045.23025644856,
              5005068.399762297
            ],
            [
              358042.10706809693,
              5005069.0641365098
            ],
            [
              358038.47267526673,
              5005069.4348597219
            ],
            [
              358034.32902178913,
              5005069.612923759
            ],
            [
              358029.57863149472,
              5005069.8023033962
            ],
            [
              358025.83374774706,
              5005069.6698343242
            ],
            [
              358022.0795395879,
              5005069.0322565688
            ],
            [
              358017.51069193013,
              5005068.106669032
            ],
            [
              357991.65553505073,
              5005033.8847385496
            ],
            [
              357990.00022826646,
              5005031.8944555288
            ],
            [
              357988.86357270891,
              5005030.60193926
            ],
            [
              357987.42361399235,
              5005029.3150262088
            ],
            [
              357984.91864947154,
              5005026.8856645711
            ],
            [
              357983.48056899646,
              5005025.699780101
            ],
            [
              357982.65660584375,
              5005024.9066969976
            ],
            [
              357981.10239990387,
              5005022.914534295
            ],
            [
              357973.22262726392,
              5005012.5516657867
            ],
            [
              357959.84424582525,
              5004995.6598964483
            ],
            [
              357955.70966991381,
              5004990.8862819383
            ],
            [
              357941.96514059923,
              5004975.1756528486
            ],
            [
              357939.25306833937,
              5004972.0476728147
            ],
            [
              357937.29439352197,
              5004970.0630646702
            ],
            [
              357935.64277704601,
              5004968.2748395046
            ],
            [
              357933.99491636601,
              5004966.6886360329
            ],
            [
              357932.86583870609,
              5004965.8001615293
            ],
            [
              357931.94265294599,
              5004965.1099863611
            ],
            [
              357931.1187554003,
              5004964.3168662265
            ],
            [
              357926.14894841146,
              5004958.1440668665
            ],
            [
              357922.76189431344,
              5004960.9354314292
            ],
            [
              357944.10730801133,
              5004988.3278010543
            ],
            [
              357950.13528019568,
              5004994.8686869638
            ],
            [
              357966.73019326542,
              5005016.1025328077
            ],
            [
              357963.62676781887,
              5005017.7022301741
            ],
            [
              357946.59561380598,
              5005027.7830926627
            ],
            [
              357924.03808534815,
              5004999.4270484988
            ],
            [
              357921.85433048249,
              5005001.4215595946
            ],
            [
              357944.00957578607,
              5005029.4105850756
            ],
            [
              357942.99415598245,
              5005030.9018349526
            ],
            [
              357942.10199665191,
              5005031.8381843464
            ],
            [
              357941.28346277628,
              5005032.8674885454
            ],
            [
              357938.39815784077,
              5005035.4474340593
            ],
            [
              357931.92547543522,
              5005040.9234971581
            ],
            [
              357921.24365450838,
              5005048.4988713404
            ],
            [
              357917.05517345399,
              5005051.7093194947
            ],
            [
              357914.0381990833,
              5005054.2617816655
            ],
            [
              357911.84939811804,
              5005056.2224684469
            ],
            [
              357909.2505589631,
              5005057.8875877531
            ],
            [
              357906.54686375306,
              5005059.3526004944
            ],
            [
              357905.14822156314,
              5005060.2880683988
            ],
            [
              357904.55281837634,
              5005060.9054479357
            ],
            [
              357903.06615487952,
              5005062.5498904977
            ],
            [
              357901.77236939361,
              5005063.6855005547
            ],
            [
              357899.5742446956,
              5005065.1411141427
            ],
            [
              357895.36886120093,
              5005067.4424116025
            ],
            [
              357888.4428800249,
              5005070.2995709116
            ],
            [
              357883.52221241914,
              5005072.2099834718
            ],
            [
              357880.00213152519,
              5005073.2859554645
            ],
            [
              357877.28897926194,
              5005074.245862022
            ],
            [
              357874.97285057098,
              5005074.794168693
            ],
            [
              357872.55555540766,
              5005075.3443917446
            ],
            [
              357870.84603189089,
              5005075.8814208163
            ],
            [
              357869.44175548188,
              5005076.5138384979
            ],
            [
              357867.94576817978,
              5005077.6532079242
            ],
            [
              357864.90583574143,
              5005080.296923181
            ],
            [
              357862.52610046469,
              5005082.8674348788
            ],
            [
              357860.15394449286,
              5005085.8420599494
            ],
            [
              357857.88657835691,
              5005089.0167929148
            ],
            [
              357856.41118371981,
              5005091.2673721565
            ],
            [
              357855.12866642169,
              5005093.0090832785
            ],
            [
              357853.84239325888,
              5005094.5487726573
            ],
            [
              357852.34640594892,
              5005095.6881422177
            ],
            [
              357850.65002890554,
              5005096.9323008806
            ],
            [
              357848.04555600981,
              5005098.2944059195
            ],
            [
              357844.43369772192,
              5005099.8773313053
            ],
            [
              357840.41931416682,
              5005101.5688038133
            ],
            [
              357836.69884252636,
              5005102.7495298088
            ],
            [
              357832.68445889704,
              5005104.4410023913
            ],
            [
              357828.46780655725,
              5005106.1361999325
            ],
            [
              357823.66514162131,
              5005108.953885016
            ],
            [
              357819.16021139006,
              5005111.4628796764
            ],
            [
              357816.80807076284,
              5005113.1827077782
            ],
            [
              357812.7151226892,
              5005116.088261947
            ],
            [
              357809.23065751407,
              5005119.08353162
            ],
            [
              357805.05726688501,
              5005123.1021386608
            ],
            [
              357799.30543620669,
              5005129.2721777679
            ],
            [
              357795.15270398586,
              5005134.4019582029
            ],
            [
              357791.58404042927,
              5005138.3082592022
            ],
            [
              357788.40670071979,
              5005141.4999465114
            ],
            [
              357786.21226461721,
              5005143.1575485701
            ],
            [
              357783.41685799573,
              5005145.1295143934
            ],
            [
              357780.91724157991,
              5005146.6917622415
            ],
            [
              357778.40447850112,
              5005147.546880505
            ],
            [
              357775.38051115797,
              5005148.1083832039
            ],
            [
              357771.65064888791,
              5005148.7840376515
            ],
            [
              357769.02176057716,
              5005148.8329128223
            ],
            [
              357763.88728373701,
              5005148.5507420739
            ],
            [
              357760.54311035346,
              5005148.2086966923
            ],
            [
              357755.4668768855,
              5005147.1915145013
            ],
            [
              357747.84407494351,
              5005145.2111476483
            ],
            [
              357744.28267533641,
              5005144.064738648
            ],
            [
              357738.08105253259,
              5005142.3611049801
            ],
            [
              357724.15364800848,
              5005138.5779550569
            ],
            [
              357715.50662407826,
              5005135.9092910849
            ],
            [
              357711.53693145019,
              5005134.5683454536
            ],
            [
              357710.31795380602,
              5005134.2878528424
            ],
            [
              357708.69645077811,
              5005134.1159073981
            ],
            [
              357707.17598240561,
              5005133.9420835646
            ],
            [
              357704.53394697508,
              5005133.2838288741
            ],
            [
              357692.28350963752,
              5005130.5420619799
            ],
            [
              357689.84555421554,
              5005129.9810766913
            ],
            [
              357687.71096779226,
              5005129.4144513048
            ],
            [
              357685.67560400057,
              5005128.7449175967
            ],
            [
              357683.53531713103,
              5005127.875242875
            ],
            [
              357680.98311411694,
              5005126.6090073669
            ],
            [
              357676.50040472538,
              5005124.8734158678
            ],
            [
              357673.64489955618,
              5005123.6128547024
            ],
            [
              357670.386802027,
              5005122.4608063828
            ],
            [
              357666.61937703501,
              5005121.1160999555
            ],
            [
              357663.36879188346,
              5005120.3681308581
            ],
            [
              357659.20816583937,
              5005119.6370807663
            ],
            [
              357655.35466467444,
              5005119.1024124464
            ],
            [
              357650.89449162612,
              5005118.5790230455
            ],
            [
              357647.65705226583,
              5005118.5381482337
            ],
            [
              357644.8558646944,
              5005118.3883125084
            ],
            [
              357642.02846383589,
              5005118.6430053506
            ],
            [
              357638.29303215124,
              5005119.0156079214
            ],
            [
              357633.55202646652,
              5005119.7100609615
            ],
            [
              357629.31283511157,
              5005120.1930572791
            ],
            [
              357625.68601666822,
              5005120.9678595839
            ],
            [
              357622.76314821845,
              5005121.5274471492
            ],
            [
              357621.4505817676,
              5005121.6529133553
            ],
            [
              357618.32363312488,
              5005122.1152314562
            ],
            [
              357615.90821385058,
              5005122.7664484363
            ],
            [
              357613.9964879108,
              5005123.3072729213
            ],
            [
              357611.88819358021,
              5005124.1548717329
            ],
            [
              357609.78358953964,
              5005125.2045292184
            ],
            [
              357608.38682394155,
              5005126.2409913829
            ],
            [
              357607.40016320028,
              5005127.5730200028
            ],
            [
              357590.44471462519,
              5005118.6925579142
            ],
            [
              357585.53374713927,
              5005126.5648365058
            ],
            [
              357577.55583094398,
              5005123.9780053217
            ],
            [
              357574.09928648447,
              5005123.0317378435
            ],
            [
              357567.89202654647,
              5005121.0250523109
            ],
            [
              357564.53658295388,
              5005120.0769051583
            ],
            [
              357563.01993609214,
              5005120.1051018871
            ],
            [
              357561.81222626136,
              5005120.4307104247
            ],
            [
              357561.00892025855,
              5005120.748800491
            ],
            [
              357560.00535686483,
              5005121.1716772933
            ],
            [
              357553.67633734475,
              5005118.0556973312
            ],
            [
              357551.24620673951,
              5005123.3555472577
            ],
            [
              357603.87955779134,
              5005150.594352142
            ],
            [
              357662.99277828884,
              5005156.5026469883
            ],
            [
              357666.43805355561,
              5005156.842812757
            ],
            [
              357669.3759458742,
              5005157.091347957
            ],
            [
              357672.31383816735,
              5005157.3398831515
            ],
            [
              357675.35839930276,
              5005157.8895905036
            ],
            [
              357678.10347980488,
              5005158.6469573602
            ],
            [
              357681.76792560756,
              5005159.8925142633
            ],
            [
              357685.20573874825,
              5005161.3891315078
            ],
            [
              357688.06500053109,
              5005162.8517497061
            ],
            [
              357691.43741188507,
              5005164.7090465063
            ],
            [
              357693.99712679017,
              5005166.3793253927
            ],
            [
              357697.70282325562,
              5005169.8472303152
            ],
            [
              357706.78280439065,
              5005179.4804511871
            ],
            [
              357717.41705859062,
              5005191.1057627276
            ],
            [
              357731.48017198796,
              5005206.6170778302
            ],
            [
              357750.81270050391,
              5005227.9404434999
            ],
            [
              357764.2454228159,
              5005242.5303468388
            ],
            [
              357762.99492809112,
              5005243.4538617106
            ],
            [
              357703.11327711429,
              5005288.7641922878
            ],
            [
              357685.79532343283,
              5005301.4994820822
            ],
            [
              357656.74909950717,
              5005266.0282941107
            ],
            [
              357628.68038005475,
              5005231.3897059001
            ],
            [
              357624.39455218147,
              5005234.8040605588
            ],
            [
              357634.85471471399,
              5005247.9484221973
            ],
            [
              357626.22515575943,
              5005257.1024587126
            ],
            [
              357627.75860914838,
              5005258.7486527264
            ],
            [
              357624.9426118292,
              5005261.8760318169
            ],
            [
              357622.52087091299,
              5005264.5655592466
            ],
            [
              357620.53727661812,
              5005266.7684915727
            ],
            [
              357618.81330294651,
              5005268.6830954738
            ],
            [
              357616.04382007552,
              5005271.7588169118
            ],
            [
              357613.14059146889,
              5005274.983073228
            ],
            [
              357610.79816855909,
              5005277.5845121797
            ],
            [
              357608.65816119447,
              5005279.9611532968
            ],
            [
              357606.86473790155,
              5005281.9528864389
            ],
            [
              357605.75204324146,
              5005283.1613517562
            ],
            [
              357604.60813907563,
              5005284.4592411248
            ],
            [
              357603.73945614573,
              5005285.4117776435
            ],
            [
              357602.03305277898,
              5005287.2800027179
            ],
            [
              357596.99664276006,
              5005292.7488479279
            ],
            [
              357582.07996976975,
              5005279.0059737032
            ],
            [
              357580.43217221351,
              5005277.4197688689
            ],
            [
              357578.79369934712,
              5005276.3386733467
            ],
            [
              357577.15904870868,
              5005275.4595984956
            ],
            [
              357575.43449945928,
              5005275.1885058237
            ],
            [
              357574.01519697282,
              5005275.0127661172
            ],
            [
              357572.50224055699,
              5005275.2430222128
            ],
            [
              357570.39394635108,
              5005276.0906220572
            ],
            [
              357568.19769810518,
              5005277.647231604
            ],
            [
              357565.71498364815,
              5005280.118633355
            ],
            [
              357564.43810053711,
              5005282.1633973075
            ],
            [
              357563.5674995833,
              5005284.3016713439
            ],
            [
              357563.30739286885,
              5005286.6306868521
            ],
            [
              357563.35058969865,
              5005288.9540988971
            ],
            [
              357564.00421386253,
              5005291.4682180909
            ],
            [
              357565.16897779988,
              5005294.2760251192
            ],
            [
              357566.52661894559,
              5005296.5749634476
            ],
            [
              357567.9621081127,
              5005298.5338929538
            ],
            [
              357569.40964783606,
              5005300.2248490443
            ],
            [
              357571.36457065999,
              5005302.0074354932
            ],
            [
              357574.23322430725,
              5005303.9751255652
            ],
            [
              357577.40712537902,
              5005306.0382040776
            ],
            [
              357581.60719310556,
              5005308.8906065552
            ],
            [
              357584.3728680872,
              5005310.7591829961
            ],
            [
              357586.42701349728,
              5005312.43886091
            ],
            [
              357587.97183190455,
              5005313.9259164985
            ],
            [
              357588.70784272515,
              5005315.4280094765
            ],
            [
              357589.25466615526,
              5005317.6409942433
            ],
            [
              357589.81282387086,
              5005320.460043502
            ],
            [
              357590.09390655317,
              5005324.6989929779
            ],
            [
              357589.95667385386,
              5005327.4949606135
            ],
            [
              357589.49805597594,
              5005330.0297940103
            ],
            [
              357588.63691199798,
              5005332.6731393198
            ],
            [
              357586.88826441002,
              5005336.5456067631
            ],
            [
              357585.31559156143,
              5005339.0000892049
            ],
            [
              357583.33838241961,
              5005341.4620928597
            ],
            [
              357580.85566853185,
              5005343.93349496
            ],
            [
              357577.57159348327,
              5005346.8240151694
            ],
            [
              357574.27249301173,
              5005348.9063766897
            ],
            [
              357570.96587981324,
              5005350.5846588789
            ],
            [
              357567.95317972131,
              5005351.7522650054
            ],
            [
              357564.78779578069,
              5005352.9776674584
            ],
            [
              357562.47542212706,
              5005353.7280339738
            ],
            [
              357559.75287775655,
              5005354.1828710549
            ],
            [
              357556.12230242882,
              5005354.7556185173
            ],
            [
              357552.98596311535,
              5005354.7128662318
            ],
            [
              357549.54437695892,
              5005354.5747610405
            ],
            [
              357545.69275255449,
              5005354.1411239225
            ],
            [
              357540.92364048114,
              5005353.3203257974
            ],
            [
              357536.75925578305,
              5005352.3872207934
            ],
            [
              357533.81760617025,
              5005351.9366652304
            ],
            [
              357530.16824911494,
              5005351.4992319262
            ],
            [
              357526.82776369777,
              5005351.3592470083
            ],
            [
              357523.49110052694,
              5005351.4212828018
            ],
            [
              357520.96894569002,
              5005351.7713305689
            ],
            [
              357518.86065135984,
              5005352.6189310085
            ],
            [
              357516.96582835895,
              5005354.0689090099
            ],
            [
              357515.03225916653,
              5005356.0442284336
            ],
            [
              357514.04747673613,
              5005357.4772512801
            ],
            [
              357513.06638474757,
              5005359.1123328554
            ],
            [
              357512.29131698905,
              5005360.9456757866
            ],
            [
              357511.62486293592,
              5005363.1812184788
            ],
            [
              357511.35342170316,
              5005364.904134227
            ],
            [
              357511.38910634018,
              5005366.823467169
            ],
            [
              357512.05399994255,
              5005369.9436879884
            ],
            [
              357513.55781787983,
              5005374.6651522527
            ],
            [
              357514.62906069279,
              5005377.8789173104
            ],
            [
              357515.49803468015,
              5005381.096407434
            ],
            [
              357516.16292906465,
              5005384.2166637247
            ],
            [
              357516.62562087661,
              5005387.3406438585
            ],
            [
              357516.78870026692,
              5005390.6723217964
            ],
            [
              357516.65598954383,
              5005394.4137182645
            ],
            [
              357516.40520774963,
              5005397.2478080466
            ],
            [
              357515.95616110606,
              5005399.5630958974
            ],
            [
              357514.89462764899,
              5005402.3112313217
            ],
            [
              357512.83328708907,
              5005405.684266408
            ],
            [
              357510.26443149225,
              5005408.9657093016
            ],
            [
              357507.39046022354,
              5005412.1517258445
            ],
            [
              357504.79725483886,
              5005414.1199360462
            ],
            [
              357501.40262128174,
              5005416.5072302995
            ],
            [
              357498.10539887036,
              5005418.6906217011
            ],
            [
              357495.40170132095,
              5005420.1556034964
            ],
            [
              357492.59120275901,
              5005421.3194510788
            ],
            [
              357489.37066517759,
              5005422.1877312548
            ],
            [
              357486.0415144533,
              5005422.6538472166
            ],
            [
              357482.70297253836,
              5005422.6148549598
            ],
            [
              357479.31524843647,
              5005422.5078135086
            ],
            [
              357477.28739564196,
              5005422.242360618
            ],
            [
              357474.54231296293,
              5005421.4849954322
            ],
            [
              357471.80105258332,
              5005420.92965098
            ],
            [
              357469.06354824879,
              5005420.5763284964
            ],
            [
              357466.03018748394,
              5005420.6327258553
            ],
            [
              357463.50052016566,
              5005420.5787302293
            ],
            [
              357460.98212102876,
              5005421.1308005471
            ],
            [
              357458.0723989057,
              5005422.3975211857
            ],
            [
              357456.38172179746,
              5005423.9447324052
            ],
            [
              357454.71163781226,
              5005426.6031191023
            ],
            [
              357453.4554152147,
              5005429.7590940297
            ],
            [
              357452.40327232302,
              5005433.0123027815
            ],
            [
              357451.48791549035,
              5005438.182965111
            ],
            [
              357451.07444009889,
              5005443.1422044476
            ],
            [
              357450.4680864276,
              5005448.6102416599
            ],
            [
              357450.12500408752,
              5005453.0281231729
            ],
            [
              357449.98290280771,
              5005456.2644121107
            ],
            [
              357450.26774337544,
              5005460.7053851802
            ],
            [
              357450.7510961353,
              5005464.9405755838
            ],
            [
              357451.43665092293,
              5005469.1720065363
            ],
            [
              357452.22330674873,
              5005473.4015577445
            ],
            [
              357452.49500085338,
              5005477.1354360487
            ],
            [
              357452.67498393724,
              5005481.3762312708
            ],
            [
              357452.62835008645,
              5005484.3076252574
            ],
            [
              357452.36630000983,
              5005486.5356141319
            ],
            [
              357451.90399327117,
              5005488.8684255322
            ],
            [
              357451.05304115737,
              5005492.0028596837
            ],
            [
              357449.80433203047,
              5005495.5629143929
            ],
            [
              357448.05005108,
              5005499.1323334733
            ],
            [
              357445.59363148332,
              5005503.0179626951
            ],
            [
              357442.05126332765,
              5005508.3385320464
            ],
            [
              357435.04891179252,
              5005517.9675735161
            ],
            [
              357430.89048023697,
              5005522.7943145363
            ],
            [
              357430.46294983494,
              5005523.4721703539
            ],
            [
              357430.07726090896,
              5005524.4898360707
            ],
            [
              357429.6953950274,
              5005525.7095580883
            ],
            [
              357429.11126090941,
              5005526.9330408219
            ],
            [
              357427.24085399805,
              5005529.6962164743
            ],
            [
              357426.35335065372,
              5005530.9253396718
            ],
            [
              357424.88929239096,
              5005533.7820239468
            ],
            [
              357421.0717935331,
              5005540.6234514974
            ],
            [
              357417.26361986477,
              5005547.9699887577
            ],
            [
              357415.79204875685,
              5005550.4225936262
            ],
            [
              357413.8186625613,
              5005553.0866204882
            ],
            [
              357411.45019748551,
              5005556.263275926
            ],
            [
              357408.67169386998,
              5005559.1443996141
            ],
            [
              357405.99234659859,
              5005561.922616059
            ],
            [
              357403.90095575986,
              5005563.6793704387
            ],
            [
              357401.7618332518,
              5005565.0896347184
            ],
            [
              357398.35593061236,
              5005566.8708295887
            ],
            [
              357392.74251079006,
              5005569.6025351016
            ],
            [
              357386.52993158414,
              5005572.7496000333
            ],
            [
              357381.723574434,
              5005575.365272006
            ],
            [
              357375.53541178134,
              5005579.8255691826
            ],
            [
              357372.94784028473,
              5005582.096796684
            ],
            [
              357371.26461021748,
              5005584.0480899056
            ],
            [
              357370.48578651767,
              5005585.6793765239
            ],
            [
              357370.01214493078,
              5005587.4060882023
            ],
            [
              357369.75948687311,
              5005590.1391860787
            ],
            [
              357369.47859027522,
              5005608.306478207
            ],
            [
              357369.61349888,
              5005610.1229033619
            ],
            [
              357369.74277289119,
              5005611.6362776943
            ],
            [
              357370.06297972886,
              5005612.5397908576
            ],
            [
              357370.28590799053,
              5005613.6472045891
            ],
            [
              357370.33661942557,
              5005616.3746619551
            ],
            [
              357369.89115036867,
              5005619.616592234
            ],
            [
              357368.26238818618,
              5005624.4973650724
            ],
            [
              357367.10115618305,
              5005627.9287096355
            ],
            [
              357366.33729251713,
              5005630.3681568215
            ],
            [
              357365.86183897528,
              5005631.9938385626
            ],
            [
              357365.49117639312,
              5005633.8196638077
            ],
            [
              357365.42576087621,
              5005635.7408417622
            ],
            [
              357365.34344216046,
              5005636.7529027862
            ],
            [
              357365.36973685125,
              5005638.1671281112
            ],
            [
              357364.18268689699,
              5005639.6039131619
            ],
            [
              357367.26300395938,
              5005642.0729492037
            ],
            [
              357377.25363797002,
              5005629.9630975733
            ],
            [
              357378.47637338401,
              5005630.4456107235
            ],
            [
              357378.51018109272,
              5005632.26391559
            ],
            [
              357380.11102586967,
              5005631.3246843852
            ],
            [
              357392.23125771998,
              5005630.3919583112
            ],
            [
              357403.06741729553,
              5005636.5567484582
            ],
            [
              357431.80862351647,
              5005648.698843712
            ],
            [
              357454.75531095552,
              5005658.2643160187
            ],
            [
              357460.78686001594,
              5005648.9378203712
            ],
            [
              357464.35012922465,
              5005638.9351441301
            ],
            [
              357479.48633062793,
              5005647.4108054647
            ],
            [
              357481.59444010514,
              5005642.9930447843
            ],
            [
              357499.19857051276,
              5005653.0077562844
            ],
            [
              357491.29342061642,
              5005671.3281738888
            ],
            [
              357484.40990058304,
              5005695.0642008232
            ],
            [
              357482.96235880029,
              5005700.3980421871
            ],
            [
              357480.50179890747,
              5005706.776635766
            ],
            [
              357486.57892089774,
              5005709.637761903
            ],
            [
              357476.4677581549,
              5005723.8113819379
            ],
            [
              357463.42650214926,
              5005715.6165294871
            ],
            [
              357450.44650071824,
              5005736.6244973289
            ],
            [
              357460.50091270119,
              5005746.8375626355
            ],
            [
              357468.74781957577,
              5005751.325235703
            ],
            [
              357458.36865992291,
              5005785.9353371812
            ],
            [
              357448.21645286167,
              5005820.190857525
            ],
            [
              357442.30264455802,
              5005840.8631417882
            ],
            [
              357436.38883741718,
              5005861.5354266865
            ],
            [
              357432.85779872577,
              5005871.0633277986
            ],
            [
              357426.49881437625,
              5005884.8225186579
            ],
            [
              357420.06978170649,
              5005895.9151547318
            ],
            [
              357411.09392914589,
              5005908.2407929124
            ],
            [
              357399.09621484502,
              5005923.1278981501
            ],
            [
              357385.67015034065,
              5005941.1379371835
            ],
            [
              357380.7245867647,
              5005951.1644777944
            ],
            [
              357380.72458675556,
              5005951.164477787
            ],
            [
              357359.91075736511,
              5005928.705011283
            ],
            [
              357351.16891095589,
              5005939.8750865096
            ],
            [
              357334.73696382419,
              5005960.1668604771
            ],
            [
              357304.63747511595,
              5005998.1079117628
            ],
            [
              357284.15772918012,
              5006029.0871158214
            ],
            [
              357275.34763018804,
              5006027.2322384473
            ],
            [
              357273.05013490346,
              5006034.6938903825
            ],
            [
              357269.54124400287,
              5006040.9959730422
            ],
            [
              357260.44186632748,
              5006048.0169162257
            ],
            [
              357245.15516842337,
              5006028.6571696931
            ],
            [
              357244.27763397794,
              5006027.5289471932
            ],
            [
              357243.58334000694,
              5006026.2795839207
            ],
            [
              357243.08868492639,
              5006024.9385883724
            ],
            [
              357242.80535191303,
              5006023.5376333073
            ],
            [
              357242.74003296142,
              5006022.1098076403
            ],
            [
              357242.89427082945,
              5006020.688834955
            ],
            [
              357243.26442260033,
              5006019.3082769625
            ],
            [
              357247.03168408741,
              5006008.0107044103
            ],
            [
              357253.39893470687,
              5005994.9242331628
            ],
            [
              357260.25837728387,
              5005984.8002598071
            ],
            [
              357261.33488492068,
              5005982.3614125866
            ],
            [
              357259.91440706654,
              5005981.4045035951
            ],
            [
              357228.86661075772,
              5006006.8819188261
            ],
            [
              357209.07575034181,
              5005999.0018830001
            ],
            [
              357199.39469670889,
              5005993.6989526488
            ],
            [
              357185.61592557369,
              5005986.6376212202
            ],
            [
              357176.51051902614,
              5006004.3959419411
            ],
            [
              357169.63384272455,
              5006021.6681712763
            ],
            [
              357165.73259479954,
              5006036.6256571868
            ],
            [
              357166.04643960606,
              5006039.7599356966
            ],
            [
              357193.42137910501,
              5006051.1573942611
            ],
            [
              357185.89327843749,
              5006072.3849435728
            ],
            [
              357182.37760058662,
              5006082.4532241207
            ],
            [
              357159.39002614352,
              5006070.4198432313
            ],
            [
              357158.39176394668,
              5006076.4808263658
            ],
            [
              357156.21193262812,
              5006085.066379142
            ],
            [
              357152.786353642,
              5006096.2342601344
            ],
            [
              357146.95968592487,
              5006114.4690432176
            ],
            [
              357155.86101127457,
              5006117.7144468054
            ],
            [
              357161.19910808041,
              5006117.0171863474
            ],
            [
              357169.42287924903,
              5006117.8003346836
            ],
            [
              357174.7023504235,
              5006119.5785672385
            ],
            [
              357178.11709526746,
              5006121.2607136499
            ],
            [
              357180.98369888065,
              5006123.1961145969
            ],
            [
              357183.51421679318,
              5006125.8380145114
            ],
            [
              357185.66977788456,
              5006129.8304370474
            ],
            [
              357188.49513275566,
              5006137.6166449906
            ],
            [
              357190.13446995424,
              5006140.0668153241
            ],
            [
              357191.18751788192,
              5006146.0654924158
            ],
            [
              357191.942522189,
              5006157.0779475383
            ],
            [
              357192.34431739344,
              5006157.3148315372
            ],
            [
              357206.39050309028,
              5006139.879861285
            ],
            [
              357204.04903075221,
              5006138.2383627398
            ],
            [
              357218.71793154028,
              5006118.5497733038
            ],
            [
              357220.20754095755,
              5006116.2755143037
            ],
            [
              357235.92009121814,
              5006090.8284039088
            ],
            [
              357237.62775447167,
              5006087.8553532166
            ],
            [
              357254.26134198386,
              5006061.2437329395
            ],
            [
              357264.68077160214,
              5006067.2583186459
            ],
            [
              357280.65605644794,
              5006046.4941349365
            ],
            [
              357282.28991509363,
              5006044.9527713954
            ],
            [
              357287.00705482438,
              5006046.9070121227
            ],
            [
              357305.97051918844,
              5006062.7128557768
            ],
            [
              357317.21796504781,
              5006080.9164317669
            ],
            [
              357323.01465409208,
              5006086.5040838681
            ],
            [
              357312.95280812326,
              5006102.5174598899
            ],
            [
              357322.20700748201,
              5006109.6398779862
            ],
            [
              357320.06396529474,
              5006112.7599604158
            ],
            [
              357335.22910993086,
              5006123.1762049049
            ],
            [
              357335.22910993086,
              5006123.1762049049
            ],
            [
              357342.52998100681,
              5006115.151933088
            ],
            [
              357345.63864199765,
              5006111.1848818529
            ],
            [
              357347.67465729511,
              5006108.8224877128
            ],
            [
              357348.97056092363,
              5006106.7371756807
            ],
            [
              357358.16004696529,
              5006111.5113572637
            ],
            [
              357356.61878321826,
              5006114.661364601
            ],
            [
              357351.89905120258,
              5006124.0724267131
            ],
            [
              357349.12507203018,
              5006130.5770609751
            ],
            [
              357346.44427069306,
              5006137.2948064283
            ],
            [
              357345.42320938938,
              5006139.8428351497
            ],
            [
              357344.96030373912,
              5006141.2570221117
            ],
            [
              357343.88501069194,
              5006145.1797421835
            ],
            [
              357342.58816960786,
              5006149.9106801981
            ],
            [
              357341.6104747979,
              5006156.5125742722
            ],
            [
              357340.49338369526,
              5006164.6026728908
            ],
            [
              357340.44665757811,
              5006172.6348529272
            ],
            [
              357341.35688413231,
              5006180.1590367341
            ],
            [
              357342.82761872734,
              5006188.9893408632
            ],
            [
              357344.36073881917,
              5006195.5255395602
            ],
            [
              357347.3158311352,
              5006204.0731372712
            ],
            [
              357351.25427874952,
              5006212.6484527327
            ],
            [
              357355.65321010764,
              5006221.6217411142
            ],
            [
              357357.82395025005,
              5006226.0393783292
            ],
            [
              357360.89799708757,
              5006232.29531975
            ],
            [
              357366.43208770914,
              5006243.3680215497
            ],
            [
              357372.39554166113,
              5006255.4191456679
            ],
            [
              357376.5335032111,
              5006264.0948087955
            ],
            [
              357381.13030082028,
              5006275.0648889374
            ],
            [
              357382.92439124518,
              5006280.0613514362
            ],
            [
              357372.7262360396,
              5006283.7149131456
            ],
            [
              357372.11861502973,
              5006282.0327644488
            ],
            [
              357371.88607678277,
              5006281.4026644733
            ],
            [
              357371.18900716183,
              5006279.553491381
            ],
            [
              357371.13511380664,
              5006279.580532725
            ],
            [
              357367.31854486145,
              5006271.9211734599
            ],
            [
              357367.83610488829,
              5006271.638310371
            ],
            [
              357366.34150735097,
              5006268.6251164824
            ],
            [
              357365.78015235218,
              5006268.9223358007
            ],
            [
              357361.49347471556,
              5006260.480324259
            ],
            [
              357357.23760701116,
              5006252.7339564189
            ],
            [
              357349.46529204404,
              5006241.1164571159
            ],
            [
              357348.42559313832,
              5006240.4792234153
            ],
            [
              357347.29869846551,
              5006240.0132309049
            ],
            [
              357346.11259141762,
              5006239.7300512567
            ],
            [
              357344.89672576805,
              5006239.6367164757
            ],
            [
              357343.68129426736,
              5006239.7355442783
            ],
            [
              357342.49647888279,
              5006240.0240805484
            ],
            [
              357341.37170131441,
              5006240.4951602686
            ],
            [
              357340.33489238675,
              5006241.137085435
            ],
            [
              357339.41179845779,
              5006241.9339155732
            ],
            [
              357338.62534208241,
              5006242.8658635514
            ],
            [
              357337.17177081859,
              5006245.2402155632
            ],
            [
              357344.489760247,
              5006258.5099219643
            ],
            [
              357345.45569210203,
              5006258.8144444292
            ],
            [
              357347.85073031153,
              5006259.7590488521
            ],
            [
              357350.06675200124,
              5006261.0696720881
            ],
            [
              357352.04825481359,
              5006262.7134883357
            ],
            [
              357353.74561014498,
              5006264.649326656
            ],
            [
              357346.41908642044,
              5006268.2411628626
            ],
            [
              357345.8138867566,
              5006267.6911186483
            ],
            [
              357345.13657998916,
              5006267.2327838121
            ],
            [
              357344.4009489615,
              5006266.8754852321
            ],
            [
              357343.62196338229,
              5006266.6264937473
            ],
            [
              357334.56676814269,
              5006263.6499627568
            ],
            [
              357333.03062225803,
              5006263.2225105418
            ],
            [
              357332.06731273135,
              5006266.5412394069
            ],
            [
              357325.92123127024,
              5006280.8841476087
            ],
            [
              357323.40678519214,
              5006286.1136678327
            ],
            [
              357321.79481755401,
              5006289.466222384
            ],
            [
              357315.56576041615,
              5006297.6637045462
            ],
            [
              357306.87176869047,
              5006305.4510086514
            ],
            [
              357305.94561770582,
              5006305.2650785176
            ],
            [
              357304.93687346549,
              5006305.1462167287
            ],
            [
              357304.20469461096,
              5006305.1725965468
            ],
            [
              357302.96589042409,
              5006305.4114551451
            ],
            [
              357302.31683884765,
              5006305.6587485624
            ],
            [
              357300.98133494938,
              5006309.9575148774
            ],
            [
              357300.10743768624,
              5006309.67818393
            ],
            [
              357298.51830123534,
              5006309.1686174609
            ],
            [
              357295.97753234708,
              5006308.6456277687
            ],
            [
              357295.07956281927,
              5006310.4208423737
            ],
            [
              357290.62116892746,
              5006313.5468288604
            ],
            [
              357286.56025280611,
              5006316.099774329
            ],
            [
              357281.44508439564,
              5006318.9494694015
            ],
            [
              357276.22422041948,
              5006321.5042401766
            ],
            [
              357271.76842293568,
              5006323.2530397139
            ],
            [
              357267.43059905514,
              5006327.5023686169
            ],
            [
              357264.6161481663,
              5006325.3814676264
            ],
            [
              357246.12554864777,
              5006331.4431283539
            ],
            [
              357246.12554864673,
              5006331.443128353
            ],
            [
              357244.86013784917,
              5006331.9567932636
            ],
            [
              357243.7028716036,
              5006332.6819490846
            ],
            [
              357242.68875963066,
              5006333.5966583434
            ],
            [
              357241.84848093119,
              5006334.6732491767
            ],
            [
              357241.20745569194,
              5006335.8791524665
            ],
            [
              357240.78507626388,
              5006337.1778871221
            ],
            [
              357240.59412050608,
              5006338.5301637156
            ],
            [
              357240.64036523039,
              5006339.8950730562
            ],
            [
              357241.07828162354,
              5006345.3360621817
            ],
            [
              357241.07287448423,
              5006350.7946429625
            ],
            [
              357240.33495557035,
              5006359.9204324251
            ],
            [
              357239.45583393128,
              5006362.427715851
            ],
            [
              357237.95133690344,
              5006367.7300895145
            ],
            [
              357230.81069655111,
              5006393.4475837396
            ],
            [
              357229.31178969116,
              5006392.3478396786
            ],
            [
              357223.50140189455,
              5006386.6030442212
            ],
            [
              357222.37927905697,
              5006385.939166232
            ],
            [
              357224.00265053869,
              5006379.8128109649
            ],
            [
              357225.00949857821,
              5006375.9625074407
            ],
            [
              357225.89212356188,
              5006373.1789531037
            ],
            [
              357226.70652726473,
              5006372.6445949133
            ],
            [
              357227.33659003756,
              5006371.4503908847
            ],
            [
              357228.07882968959,
              5006369.5638160957
            ],
            [
              357228.55568175291,
              5006367.931279012
            ],
            [
              357228.99573795253,
              5006366.0953419507
            ],
            [
              357229.17562383012,
              5006364.202217198
            ],
            [
              357229.24700451718,
              5006362.3381212568
            ],
            [
              357229.19724487531,
              5006360.6843637927
            ],
            [
              357229.00102709286,
              5006359.2244085763
            ],
            [
              357228.62821068492,
              5006357.3595688092
            ],
            [
              357228.12877215975,
              5006355.4640699653
            ],
            [
              357227.45662578457,
              5006353.4267227761
            ],
            [
              357226.82594295841,
              5006351.8437923845
            ],
            [
              357226.12594764616,
              5006350.3531884216
            ],
            [
              357225.5697987731,
              5006349.3891279874
            ],
            [
              357224.74161335552,
              5006348.1799279973
            ],
            [
              357222.7823645692,
              5006345.8248874126
            ],
            [
              357221.19791891816,
              5006344.2135362159
            ],
            [
              357219.99570349435,
              5006343.3059687521
            ],
            [
              357214.90257608151,
              5006340.2271271478
            ],
            [
              357214.48185020173,
              5006340.2346994625
            ],
            [
              357214.45739712432,
              5006340.2417736808
            ],
            [
              357213.88668430567,
              5006339.7419995843
            ],
            [
              357206.1989151715,
              5006341.9810365625
            ],
            [
              357200.53313512419,
              5006343.8006113237
            ],
            [
              357194.86735499481,
              5006345.6201861445
            ],
            [
              357192.00217106834,
              5006346.5416218489
            ],
            [
              357188.5883758628,
              5006334.7490749154
            ],
            [
              357211.06010964134,
              5006327.7425553957
            ],
            [
              357216.52287463675,
              5006325.157690797
            ],
            [
              357216.71205572161,
              5006325.3041373352
            ],
            [
              357176.36357719329,
              5006294.0700657442
            ],
            [
              357187.28391488182,
              5006273.6938982457
            ],
            [
              357166.7567642646,
              5006263.6806873912
            ],
            [
              357159.92557571275,
              5006258.8958337
            ],
            [
              357155.26062254427,
              5006253.8422307093
            ],
            [
              357142.6407985324,
              5006238.8843208868
            ],
            [
              357132.60630766215,
              5006227.7621598011
            ],
            [
              357124.93787222431,
              5006221.8161857789
            ],
            [
              357147.46723567636,
              5006204.5800415352
            ],
            [
              357123.79019672965,
              5006186.2633585725
            ],
            [
              357120.63420899835,
              5006190.1009429675
            ],
            [
              357117.47822128615,
              5006193.9385274248
            ],
            [
              357090.65506829921,
              5006215.1947116358
            ],
            [
              357082.91398689663,
              5006222.9926939672
            ],
            [
              357055.48619604821,
              5006244.182774446
            ],
            [
              357051.71050697961,
              5006244.130274226
            ],
            [
              357047.57127364457,
              5006246.0906209359
            ],
            [
              357037.20122588327,
              5006253.6531925621
            ],
            [
              357035.60277734505,
              5006253.8482988775
            ],
            [
              357035.60277721903,
              5006253.8482989119
            ],
            [
              357035.60277734505,
              5006253.8482988775
            ],
            [
              357035.60277740454,
              5006253.8482988654
            ],
            [
              357035.60277734505,
              5006253.8482988775
            ],
            [
              357035.6027772724,
              5006253.8482988849
            ],
            [
              357035.60277734505,
              5006253.8482988775
            ],
            [
              357030.71182164864,
              5006254.4452879811
            ],
            [
              357028.50081267534,
              5006260.6505473517
            ],
            [
              357023.9376507558,
              5006260.0280584488
            ],
            [
              357009.94700381369,
              5006263.7239552038
            ],
            [
              357004.31137540954,
              5006270.7003064668
            ],
            [
              356992.86200889951,
              5006285.300172464
            ],
            [
              356942.44625672838,
              5006262.8737714393
            ],
            [
              356941.38472842809,
              5006265.6219140925
            ],
            [
              356988.61457799084,
              5006287.0761578521
            ],
            [
              356973.11914787942,
              5006307.1350165373
            ],
            [
              356922.62688342546,
              5006286.8350114143
            ],
            [
              356916.12817096891,
              5006296.353690017
            ],
            [
              356963.67337124486,
              5006328.0588198761
            ],
            [
              356960.44025513186,
              5006333.6469145725
            ],
            [
              356959.65955609677,
              5006335.1772114625
            ],
            [
              356958.88261367817,
              5006336.9095306238
            ],
            [
              356958.10003601754,
              5006338.3387986254
            ],
            [
              356956.91862284974,
              5006340.0786389578
            ],
            [
              356955.93565532804,
              5006341.6126970705
            ],
            [
              356954.75048555387,
              5006343.150515154
            ],
            [
              356953.26013441948,
              5006344.5929798279
            ],
            [
              356952.06745075475,
              5006345.7267178586
            ],
            [
              356943.57845205866,
              5006357.0002783174
            ],
            [
              356941.99639169528,
              5006358.949696538
            ],
            [
              356941.01154557144,
              5006360.3827258674
            ],
            [
              356940.32819111814,
              5006361.7091206405
            ],
            [
              356939.84703827073,
              5006363.0317199072
            ],
            [
              356939.47262249485,
              5006364.6555259116
            ],
            [
              356939.10015096288,
              5006366.3803240564
            ],
            [
              356938.82120105909,
              5006367.6991988942
            ],
            [
              356938.34575042309,
              5006369.324883664
            ],
            [
              356937.65281262883,
              5006371.2372216955
            ],
            [
              356934.82386155246,
              5006376.8476622934
            ],
            [
              356916.5659651967,
              5006412.1511381408
            ],
            [
              356914.86718341883,
              5006414.9945632583
            ],
            [
              356913.49477260018,
              5006417.3442677166
            ],
            [
              356911.92022642464,
              5006419.6977665061
            ],
            [
              356910.33622135926,
              5006421.5461575156
            ],
            [
              356909.04994998622,
              5006423.0858205473
            ],
            [
              356907.95461090532,
              5006424.0156566957
            ],
            [
              356905.5617967103,
              5006425.8790877918
            ],
            [
              356902.66334428935,
              5006427.7519214088
            ],
            [
              356899.86605925119,
              5006429.6228738176
            ],
            [
              356896.67550778465,
              5006432.107451194
            ],
            [
              356892.98890949733,
              5006435.1065364853
            ],
            [
              356889.29848761117,
              5006437.9035652317
            ],
            [
              356887.51040157891,
              5006439.6546875443
            ],
            [
              356884.63636845897,
              5006442.8407196831
            ],
            [
              356879.50376489171,
              5006438.7930417331
            ],
            [
              356877.22895775526,
              5006441.563746783
            ],
            [
              356882.15365749382,
              5006445.3121347418
            ],
            [
              356878.93936282728,
              5006448.7759411959
            ],
            [
              356876.86487939645,
              5006451.4418573668
            ],
            [
              356874.79596573574,
              5006454.410861576
            ],
            [
              356872.53806410241,
              5006458.0906849504
            ],
            [
              356868.78974590224,
              5006463.2130106892
            ],
            [
              356866.73746861186,
              5006461.6343295733
            ],
            [
              356848.18083577213,
              5006480.8761012871
            ],
            [
              356849.63019676029,
              5006482.6680860687
            ],
            [
              356834.20688094973,
              5006498.0795265185
            ],
            [
              356828.96378206165,
              5006493.528619675
            ],
            [
              356826.5821722261,
              5006495.9981554756
            ],
            [
              356832.12864092569,
              5006500.5434209937
            ],
            [
              356829.37261526991,
              5006504.636727958
            ],
            [
              356825.14502354956,
              5006511.1826836932
            ],
            [
              356820.23595585057,
              5006519.1560285436
            ],
            [
              356817.66232637997,
              5006523.4136254648
            ],
            [
              356815.6009264398,
              5006526.7866753126
            ],
            [
              356814.02262318204,
              5006528.9381173868
            ],
            [
              356812.3412738163,
              5006530.9904118115
            ],
            [
              356810.75921384146,
              5006532.9398315754
            ],
            [
              356808.87559589173,
              5006534.9958873456
            ],
            [
              356807.188678023,
              5006536.7451648368
            ],
            [
              356805.09722230543,
              5006538.5019294303
            ],
            [
              356803.0058328383,
              5006540.2586928131
            ],
            [
              356800.11113691732,
              5006542.3335503116
            ],
            [
              356797.7164445807,
              5006544.0959894024
            ],
            [
              356795.72796871682,
              5006545.9519029958
            ],
            [
              356793.7433158416,
              5006548.0098377392
            ],
            [
              356792.26235705317,
              5006549.9573776433
            ],
            [
              356790.84786222788,
              5006551.8580375761
            ],
            [
              356789.37247197825,
              5006554.1085945237
            ],
            [
              356786.13160448702,
              5006559.3225144939
            ],
            [
              356775.36269753712,
              5006551.33755452
            ],
            [
              356772.11619442678,
              5006556.248423351
            ],
            [
              356773.96063490928,
              5006557.5278130975
            ],
            [
              356775.44535047625,
              5006555.7822954245
            ],
            [
              356783.65452910325,
              5006562.0969824186
            ],
            [
              356779.51488948305,
              5006567.9339280892
            ],
            [
              356773.13801272033,
              5006578.5619110074
            ],
            [
              356769.28217542905,
              5006584.8627818916
            ],
            [
              356766.8257633145,
              5006588.7484265054
            ],
            [
              356763.86941519898,
              5006592.946559553
            ],
            [
              356763.18793934671,
              5006594.3739488898
            ],
            [
              356762.12453443772,
              5006597.0210650284
            ],
            [
              356761.27466979314,
              5006600.2705580452
            ],
            [
              356760.12331276899,
              5006603.6266503725
            ],
            [
              356757.925458749,
              5006610.539084021
            ],
            [
              356756.85552035662,
              5006613.4585508397
            ],
            [
              356755.39334699616,
              5006616.416274216
            ],
            [
              356754.62204103981,
              5006618.4516463773
            ],
            [
              356754.14464636619,
              5006619.9763046335
            ],
            [
              356753.88260313653,
              5006622.2042988725
            ],
            [
              356753.65819665761,
              5006626.4526578514
            ],
            [
              356753.63035709149,
              5006630.3942053569
            ],
            [
              356753.28787673166,
              5006633.7352916179
            ],
            [
              356752.83678163931,
              5006636.6742131896
            ],
            [
              356752.174090566,
              5006639.1117501119
            ],
            [
              356750.92351209471,
              5006642.5707872203
            ],
            [
              356749.36775136477,
              5006645.9344356107
            ],
            [
              356745.63628712232,
              5006652.5439360952
            ],
            [
              356742.10902375891,
              5006658.6726519288
            ],
            [
              356739.25565555127,
              5006662.9698992278
            ],
            [
              356737.47132670012,
              5006664.9230458643
            ],
            [
              356735.58965412877,
              5006667.0801305249
            ],
            [
              356732.31685330288,
              5006670.5767731788
            ],
            [
              356729.94805525668,
              5006668.296637997
            ],
            [
              356724.59131250309,
              5006673.9540913412
            ],
            [
              356732.48437991802,
              5006685.024089545
            ],
            [
              356691.86096932384,
              5006720.8161051497
            ],
            [
              356671.21857348207,
              5006739.354900864
            ],
            [
              356669.72252093098,
              5006740.4943182617
            ],
            [
              356668.22089835693,
              5006741.3306474835
            ],
            [
              356666.51137149584,
              5006741.8676867327
            ],
            [
              356663.89562499308,
              5006742.6237060912
            ],
            [
              356661.8827944785,
              5006743.1663856544
            ],
            [
              356659.87177645846,
              5006743.8100954425
            ],
            [
              356657.76348032319,
              5006744.6577065429
            ],
            [
              356655.85926536174,
              5006745.6025864398
            ],
            [
              356654.05427350756,
              5006746.4445927953
            ],
            [
              356651.84299736941,
              5006747.1930550374
            ],
            [
              356649.32459512475,
              5006747.7451719269
            ],
            [
              356647.10573899181,
              5006748.0895905364
            ],
            [
              356644.51902809466,
              5006748.1902340753
            ],
            [
              356642.08669826289,
              5006747.9323100233
            ],
            [
              356640.15430513897,
              5006747.3619327853
            ],
            [
              356637.70889153978,
              5006746.3968756171
            ],
            [
              356634.845791217,
              5006744.73224496
            ],
            [
              356631.98463522783,
              5006743.1686065001
            ],
            [
              356628.50546882889,
              5006741.0101837199
            ],
            [
              356626.76970872679,
              5006740.1329935389
            ],
            [
              356625.24172041909,
              5006739.55509583
            ],
            [
              356623.71379835805,
              5006738.9771968862
            ],
            [
              356621.28146838589,
              5006738.7192727523
            ],
            [
              356618.77809331618,
              5006740.0794794681
            ],
            [
              356621.62819384196,
              5006742.9631317938
            ],
            [
              356618.48423023662,
              5006756.1269481555
            ],
            [
              356638.74707412097,
              5006763.1429062169
            ],
            [
              356647.13071147801,
              5006773.0339734172
            ],
            [
              356649.66040489526,
              5006775.8082673587
            ],
            [
              356634.58043087035,
              5006791.2932244819
            ],
            [
              356609.87222724798,
              5006813.206468734
            ],
            [
              356625.78152436705,
              5006833.1069955863
            ],
            [
              356652.1140240414,
              5006863.566544882
            ],
            [
              356658.80982635397,
              5006870.1463135285
            ],
            [
              356690.14102942077,
              5006847.2364031095
            ],
            [
              356699.85649575468,
              5006839.182162975
            ],
            [
              356695.06399034412,
              5006829.355741906
            ],
            [
              356748.95403584436,
              5006790.2802720461
            ],
            [
              356755.85382389283,
              5006797.9043719871
            ],
            [
              356771.69924607268,
              5006823.2590777837
            ],
            [
              356781.82420285704,
              5006848.5903931065
            ],
            [
              356779.03329334111,
              5006852.6534836031
            ],
            [
              356776.85814813134,
              5006858.9971877327
            ],
            [
              356776.86562740384,
              5006864.536306425
            ],
            [
              356777.91807459155,
              5006867.5070122564
            ],
            [
              356783.59461399133,
              5006877.8775681583
            ],
            [
              356792.07633432519,
              5006882.9445627173
            ],
            [
              356794.81958786777,
              5006884.0501946285
            ],
            [
              356793.88465655857,
              5006887.7609060863
            ],
            [
              356793.1812866492,
              5006891.3859867277
            ],
            [
              356807.03345663211,
              5006913.6953619374
            ],
            [
              356812.86744421726,
              5006909.811527079
            ],
            [
              356825.11204018578,
              5006929.3527638074
            ],
            [
              356834.6473030487,
              5006930.8680841718
            ],
            [
              356843.64887687023,
              5006931.5013317773
            ],
            [
              356851.78316946566,
              5006933.3602217603
            ],
            [
              356854.98756827781,
              5006934.3914967664
            ],
            [
              356873.89113834774,
              5006939.1019684663
            ],
            [
              356885.29996707966,
              5006941.2719917838
            ],
            [
              356889.11958571349,
              5006942.128061532
            ],
            [
              356910.65738592221,
              5006947.3209710559
            ],
            [
              356927.06665784109,
              5006951.2543258509
            ],
            [
              356934.08692990337,
              5006953.2168622809
            ],
            [
              356947.97187560564,
              5006959.0609274507
            ],
            [
              356953.85526896169,
              5006963.9585248418
            ],
            [
              356964.88668558589,
              5006978.1959591154
            ],
            [
              356968.62515584013,
              5006982.1294178553
            ],
            [
              356973.0436219938,
              5006990.7815748956
            ],
            [
              356978.26724148093,
              5006995.420133221
            ],
            [
              356985.83547560341,
              5007000.2995091239
            ],
            [
              356990.7170490725,
              5007000.3524038782
            ],
            [
              356995.90546710562,
              5007000.9770566523
            ],
            [
              357004.71121703938,
              5006996.7075220784
            ],
            [
              357009.30598968692,
              5007002.6998259854
            ],
            [
              357011.88196680875,
              5007004.1572761061
            ],
            [
              357017.79509449581,
              5007004.3947096458
            ],
            [
              357025.37149480364,
              5007003.7327117193
            ],
            [
              357038.14675410051,
              5007006.2849777676
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 423,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357642.97639918473,
              5006366.7771302583
            ],
            [
              357630.18851160532,
              5006366.7998904567
            ],
            [
              357621.06686103548,
              5006365.0627204962
            ],
            [
              357614.61667267693,
              5006384.9749241397
            ],
            [
              357629.60147608799,
              5006395.5789534245
            ],
            [
              357642.79362218996,
              5006401.3025658112
            ],
            [
              357643.00355070265,
              5006396.8745356742
            ],
            [
              357671.68441678095,
              5006400.8640437294
            ],
            [
              357670.91835179977,
              5006396.7427290436
            ],
            [
              357672.9207864264,
              5006384.576247517
            ],
            [
              357675.27945292607,
              5006377.4116273057
            ],
            [
              357672.34871607024,
              5006375.0816536006
            ],
            [
              357669.72207668942,
              5006371.1450672895
            ],
            [
              357668.06426286814,
              5006365.7270558234
            ],
            [
              357668.13795553392,
              5006353.3907329664
            ],
            [
              357663.21212574939,
              5006353.0999121135
            ],
            [
              357656.17971321935,
              5006347.3557129558
            ],
            [
              357651.01755084313,
              5006348.8784150071
            ],
            [
              357647.3637252567,
              5006347.7790710889
            ],
            [
              357643.15018155385,
              5006349.1907671709
            ],
            [
              357642.97639918473,
              5006366.7771302583
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 424,
        "Layer" : "GRANICA_NA",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 425,
        "Layer" : "GRANICA_NA",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359255.50516325707,
              5005849.5210841401
            ],
            [
              359250.69136271864,
              5005845.9016654538
            ],
            [
              359235.68034292629,
              5005863.9587189443
            ],
            [
              359230.86763094919,
              5005869.478854293
            ],
            [
              359228.9292022555,
              5005874.5461340025
            ],
            [
              359223.76792565588,
              5005877.246780091
            ],
            [
              359207.22184755706,
              5005902.6431780402
            ],
            [
              359219.68018394377,
              5005909.319752709
            ],
            [
              359211.98781295092,
              5005914.4429340111
            ],
            [
              359207.81067781261,
              5005917.6410912499
            ],
            [
              359201.86581851862,
              5005926.2757587247
            ],
            [
              359204.44045873755,
              5005931.5434672739
            ],
            [
              359194.26353199332,
              5005938.2474755589
            ],
            [
              359196.72893231193,
              5005940.8117213286
            ],
            [
              359193.60414556693,
              5005943.0252297502
            ],
            [
              359188.38711805688,
              5005952.379945958
            ],
            [
              359184.49859846773,
              5005956.9363774722
            ],
            [
              359182.07471151248,
              5005960.954917755
            ],
            [
              359177.9733100803,
              5005956.7644425277
            ],
            [
              359168.14154132706,
              5005968.907510302
            ],
            [
              359163.83538496023,
              5005972.6562100919
            ],
            [
              359162.30076462653,
              5005977.8421612168
            ],
            [
              359158.56911679899,
              5005982.2115420373
            ],
            [
              359153.53304916277,
              5005988.0914681433
            ],
            [
              359147.98349652492,
              5005991.9964132588
            ],
            [
              359148.04864441924,
              5005995.5001154812
            ],
            [
              359143.36850056803,
              5005999.41112296
            ],
            [
              359143.49118974205,
              5006006.0094287228
            ],
            [
              359142.74753286876,
              5006012.9400988827
            ],
            [
              359148.50083573133,
              5006017.9981746878
            ],
            [
              359154.96994668595,
              5006018.9992615208
            ],
            [
              359153.5999479703,
              5006023.5873379204
            ],
            [
              359159.69149839756,
              5006038.4964086022
            ],
            [
              359159.03251486615,
              5006055.7774799922
            ],
            [
              359171.29444178037,
              5006057.717707458
            ],
            [
              359175.1092206852,
              5006057.2885276824
            ],
            [
              359184.55228207447,
              5006058.3523681276
            ],
            [
              359191.41344220651,
              5006055.8345311731
            ],
            [
              359203.6903692084,
              5006052.6776943486
            ],
            [
              359205.7649313201,
              5006036.2882454721
            ],
            [
              359206.09372368007,
              5006024.5575425215
            ],
            [
              359217.33335270343,
              5006001.265958556
            ],
            [
              359208.50839886843,
              5005991.1571816634
            ],
            [
              359210.04413114389,
              5005989.1727952128
            ],
            [
              359213.77597941022,
              5005987.4766459931
            ],
            [
              359219.33760893327,
              5005980.6530684512
            ],
            [
              359226.93905488058,
              5005964.8334791921
            ],
            [
              359230.77430915256,
              5005957.2088341583
            ],
            [
              359252.88895780657,
              5005967.3865957567
            ],
            [
              359256.6503886829,
              5005961.5129987625
            ],
            [
              359262.25137733313,
              5005965.0177314561
            ],
            [
              359277.73278603441,
              5005974.5042434009
            ],
            [
              359296.13156014547,
              5005983.9302781047
            ],
            [
              359309.26908493391,
              5005967.861446728
            ],
            [
              359317.42256542662,
              5005956.6400315436
            ],
            [
              359321.30229093833,
              5005943.9696741477
            ],
            [
              359319.35333296558,
              5005938.0810221257
            ],
            [
              359328.45086974179,
              5005913.8383497726
            ],
            [
              359312.36566915078,
              5005903.0229880502
            ],
            [
              359304.43982599099,
              5005894.3124226145
            ],
            [
              359277.54734042566,
              5005881.3115363484
            ],
            [
              359278.92421066796,
              5005871.4618618423
            ],
            [
              359272.21357885917,
              5005870.8054570602
            ],
            [
              359274.87550424755,
              5005863.3407258401
            ],
            [
              359276.84924313071,
              5005846.1045627417
            ],
            [
              359263.05230316106,
              5005852.3604626562
            ],
            [
              359259.89334288897,
              5005852.4910159288
            ],
            [
              359255.50516325707,
              5005849.5210841401
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 426,
        "Layer" : "GRANICA_NA",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358517.44184558548,
              5006255.9490439016
            ],
            [
              358517.8071427822,
              5006256.8747023456
            ],
            [
              358517.81236999767,
              5006256.8940662118
            ],
            [
              358518.87891828408,
              5006270.3399170404
            ],
            [
              358527.95716202137,
              5006272.2371851904
            ],
            [
              358542.44021095353,
              5006268.9271961562
            ],
            [
              358542.11110364675,
              5006257.6738417763
            ],
            [
              358540.52305268229,
              5006255.1137763672
            ],
            [
              358539.10071565741,
              5006246.5026287902
            ],
            [
              358527.98889289302,
              5006246.3042128561
            ],
            [
              358527.96512874117,
              5006245.0262099532
            ],
            [
              358526.82044061349,
              5006243.8144560708
            ],
            [
              358527.25547614397,
              5006239.5312728453
            ],
            [
              358527.32597046735,
              5006237.621820882
            ],
            [
              358528.82772719074,
              5006229.7900141831
            ],
            [
              358527.86826170719,
              5006217.7844903478
            ],
            [
              358527.92563454929,
              5006217.0969599197
            ],
            [
              358529.64558297402,
              5006217.5268740468
            ],
            [
              358533.39077854145,
              5006218.6430475879
            ],
            [
              358536.17143896566,
              5006220.1666409764
            ],
            [
              358540.37469427398,
              5006223.755160246
            ],
            [
              358540.18420229241,
              5006224.8423314793
            ],
            [
              358545.81691229565,
              5006232.0313966293
            ],
            [
              358550.71069920162,
              5006231.0776249636
            ],
            [
              358548.01302537671,
              5006243.3522337927
            ],
            [
              358566.89688914648,
              5006246.3575947396
            ],
            [
              358568.8590484446,
              5006247.5889247265
            ],
            [
              358570.55407072318,
              5006247.3686394179
            ],
            [
              358572.28034356498,
              5006248.4735460617
            ],
            [
              358573.94802385935,
              5006250.8048836654
            ],
            [
              358582.97728681337,
              5006252.6288877698
            ],
            [
              358599.23280857428,
              5006261.303206699
            ],
            [
              358611.05932593369,
              5006253.190130759
            ],
            [
              358616.8120729152,
              5006242.5159641961
            ],
            [
              358622.32938208431,
              5006235.3509997437
            ],
            [
              358610.32176508079,
              5006230.0654489566
            ],
            [
              358600.59409789176,
              5006225.1018857993
            ],
            [
              358595.76364846132,
              5006222.340051678
            ],
            [
              358594.17457128817,
              5006219.8526811339
            ],
            [
              358589.52480908827,
              5006215.7441254519
            ],
            [
              358586.82084271929,
              5006215.1566770021
            ],
            [
              358584.13087658159,
              5006213.4685984273
            ],
            [
              358576.35380217346,
              5006209.9124575052
            ],
            [
              358574.71550596616,
              5006209.0962176295
            ],
            [
              358575.28595979704,
              5006207.43198229
            ],
            [
              358580.85970987444,
              5006194.7493443424
            ],
            [
              358574.05682580307,
              5006190.7538277619
            ],
            [
              358567.46311815525,
              5006187.7968231374
            ],
            [
              358572.28236766515,
              5006184.2948085712
            ],
            [
              358559.22413853864,
              5006179.6976965135
            ],
            [
              358561.96973747091,
              5006172.0010718368
            ],
            [
              358570.40245070116,
              5006157.5133778779
            ],
            [
              358548.71673419088,
              5006146.3407165725
            ],
            [
              358539.51817301608,
              5006144.0747447107
            ],
            [
              358537.99563964159,
              5006143.8001135997
            ],
            [
              358542.58717164985,
              5006132.6057417281
            ],
            [
              358529.15359009453,
              5006126.4519455554
            ],
            [
              358520.04913080076,
              5006120.2694517039
            ],
            [
              358511.2111753794,
              5006115.6144857192
            ],
            [
              358508.89794508013,
              5006112.2190694427
            ],
            [
              358506.65574601881,
              5006106.7819919754
            ],
            [
              358504.30967231659,
              5006101.991443987
            ],
            [
              358495.65318568965,
              5006092.4293331178
            ],
            [
              358486.54660989129,
              5006089.4233788839
            ],
            [
              358481.6811852805,
              5006086.4033899661
            ],
            [
              358467.03308789612,
              5006094.6420580847
            ],
            [
              358471.22375528072,
              5006102.6441936232
            ],
            [
              358477.76636525657,
              5006111.836681949
            ],
            [
              358473.08300223429,
              5006114.6025970699
            ],
            [
              358475.90545760433,
              5006122.4487155145
            ],
            [
              358478.43325053382,
              5006126.3556168601
            ],
            [
              358481.05994836165,
              5006130.1206593169
            ],
            [
              358482.01475872658,
              5006131.5202934248
            ],
            [
              358489.19516463944,
              5006140.9133806108
            ],
            [
              358494.9567903069,
              5006138.3166158861
            ],
            [
              358496.53715680615,
              5006141.7411130331
            ],
            [
              358497.1164030566,
              5006148.170192102
            ],
            [
              358492.35013442888,
              5006153.341634823
            ],
            [
              358501.16725090338,
              5006158.5922383936
            ],
            [
              358504.78515465843,
              5006159.8891243301
            ],
            [
              358510.89053325745,
              5006160.740628141
            ],
            [
              358511.43245564552,
              5006162.2495800536
            ],
            [
              358512.15433437628,
              5006164.2931774799
            ],
            [
              358508.15030698903,
              5006166.2320588864
            ],
            [
              358507.8711545525,
              5006170.4435626864
            ],
            [
              358507.14427423239,
              5006178.0699960142
            ],
            [
              358506.73339496116,
              5006181.4467519158
            ],
            [
              358506.54845756921,
              5006183.6527852798
            ],
            [
              358506.43567063165,
              5006185.8596472703
            ],
            [
              358505.93006639689,
              5006191.0931237666
            ],
            [
              358505.58117507229,
              5006193.7362932013
            ],
            [
              358502.95195108809,
              5006211.7736989371
            ],
            [
              358503.43675437057,
              5006216.2406120524
            ],
            [
              358503.73702687479,
              5006229.7340600146
            ],
            [
              358503.59663041326,
              5006239.603907479
            ],
            [
              358502.75583028683,
              5006245.8373126658
            ],
            [
              358503.89643896307,
              5006256.6477941731
            ],
            [
              358514.0499821725,
              5006255.4153869124
            ],
            [
              358517.44184558548,
              5006255.9490439016
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 427,
        "Layer" : "GRANICA_NA",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356021.89608157997,
              5008581.9156995108
            ],
            [
              356030.98774055199,
              5008592.4093464334
            ],
            [
              356030.27063756349,
              5008598.7164471904
            ],
            [
              356037.11884885706,
              5008612.0511459392
            ],
            [
              356034.93852517032,
              5008620.9249292044
            ],
            [
              356033.17251256696,
              5008633.9832571093
            ],
            [
              356059.09051506669,
              5008633.8270556293
            ],
            [
              356073.46295075328,
              5008631.2481846455
            ],
            [
              356086.63218191511,
              5008623.7984555969
            ],
            [
              356082.18118129851,
              5008610.4621112421
            ],
            [
              356084.35152912419,
              5008609.7398164701
            ],
            [
              356088.48145582667,
              5008608.0243069232
            ],
            [
              356117.56956856378,
              5008597.2373733195
            ],
            [
              356111.20348226465,
              5008579.036457778
            ],
            [
              356126.25199867826,
              5008573.8400931638
            ],
            [
              356116.44976962724,
              5008554.4056274612
            ],
            [
              356114.03343579714,
              5008555.7461062409
            ],
            [
              356112.15313171892,
              5008551.3730058447
            ],
            [
              356105.92183874716,
              5008538.8770343522
            ],
            [
              356100.65277877019,
              5008542.1226487607
            ],
            [
              356097.20242746238,
              5008534.7683381457
            ],
            [
              356090.10976281535,
              5008540.0814250875
            ],
            [
              356071.97512594267,
              5008536.7799730282
            ],
            [
              356066.52929986949,
              5008543.968345602
            ],
            [
              356062.94018352445,
              5008542.6987379184
            ],
            [
              356046.2117204137,
              5008551.4422235368
            ],
            [
              356051.09161081404,
              5008563.1014348008
            ],
            [
              356033.65455275535,
              5008573.1864462988
            ],
            [
              356021.89608157997,
              5008581.9156995108
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 428,
        "Layer" : "GRANICA_NA",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358659.7136024838,
              5007061.0343021937
            ],
            [
              358648.35998668114,
              5007052.8146255817
            ],
            [
              358633.81907141575,
              5007046.7042274224
            ],
            [
              358587.17866320879,
              5007077.2601992441
            ],
            [
              358610.79089868563,
              5007096.9458858687
            ],
            [
              358613.37212162436,
              5007094.2668365352
            ],
            [
              358625.1350361217,
              5007107.4463230809
            ],
            [
              358682.5219959871,
              5007112.1061459212
            ],
            [
              358671.36017861712,
              5007075.1107285991
            ],
            [
              358659.7136024838,
              5007061.0343021937
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 429,
        "Layer" : "GRANICA_NA",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              358445.85002566309,
              5006490.4973579254
            ],
            [
              358440.5595763991,
              5006485.2940037334
            ],
            [
              358437.06106442487,
              5006480.7331532491
            ],
            [
              358430.76528956083,
              5006473.4115025885
            ],
            [
              358426.75613912079,
              5006468.7172421236
            ],
            [
              358419.41156052414,
              5006460.1816690955
            ],
            [
              358411.06269228243,
              5006448.3199043125
            ],
            [
              358405.08158738987,
              5006442.5293870503
            ],
            [
              358394.52297862858,
              5006431.4558435166
            ],
            [
              358388.03421658621,
              5006441.4298699116
            ],
            [
              358377.11896529567,
              5006458.3720722785
            ],
            [
              358371.52062853595,
              5006467.6909775585
            ],
            [
              358365.64166082535,
              5006476.266896409
            ],
            [
              358362.97304995399,
              5006480.3351190323
            ],
            [
              358355.08635318797,
              5006482.1193272825
            ],
            [
              358358.67544480198,
              5006510.9247293472
            ],
            [
              358365.81670762243,
              5006527.2392382976
            ],
            [
              358374.30228715093,
              5006538.4814468091
            ],
            [
              358382.42464231455,
              5006546.624596756
            ],
            [
              358398.10908629076,
              5006557.3404469611
            ],
            [
              358409.11086656031,
              5006566.9939278867
            ],
            [
              358429.77513254917,
              5006584.6903654309
            ],
            [
              358448.86057283479,
              5006595.3163835043
            ],
            [
              358486.23214137158,
              5006608.1264974633
            ],
            [
              358488.16579275188,
              5006609.0705883773
            ],
            [
              358499.04285967583,
              5006615.0393578056
            ],
            [
              358506.27186042193,
              5006611.0067524565
            ],
            [
              358513.70831778069,
              5006605.0945106363
            ],
            [
              358506.20773993997,
              5006594.5126213674
            ],
            [
              358500.75357761391,
              5006586.8691349626
            ],
            [
              358497.99669614568,
              5006580.9948332869
            ],
            [
              358495.28209425724,
              5006577.2759153768
            ],
            [
              358491.74939591682,
              5006573.1065414483
            ],
            [
              358484.12212017417,
              5006561.059395045
            ],
            [
              358480.60339785967,
              5006552.0952008851
            ],
            [
              358477.77666257985,
              5006544.8645985071
            ],
            [
              358466.48224176146,
              5006528.1185053056
            ],
            [
              358457.90327962628,
              5006515.4788383851
            ],
            [
              358449.77466542978,
              5006498.9634817299
            ],
            [
              358445.85002566309,
              5006490.4973579254
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 430,
        "Layer" : "GRANICA_NA",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352306.58546118764,
              5011096.6801146939
            ],
            [
              352301.72757424769,
              5011094.4401772451
            ],
            [
              352292.62792507,
              5011095.3779991344
            ],
            [
              352279.57014150789,
              5011101.5106568597
            ],
            [
              352250.75682174083,
              5011073.4004045362
            ],
            [
              352242.22370870021,
              5011063.2514351103
            ],
            [
              352233.13904508797,
              5011046.5989406873
            ],
            [
              352215.71889180649,
              5011062.6652654689
            ],
            [
              352207.59641618095,
              5011074.8551692218
            ],
            [
              352214.13269272598,
              5011084.008707894
            ],
            [
              352203.44598109223,
              5011095.743579424
            ],
            [
              352192.7627852342,
              5011111.6379942046
            ],
            [
              352194.72023308609,
              5011113.1947376868
            ],
            [
              352179.5257598997,
              5011126.2193837566
            ],
            [
              352176.60392164189,
              5011132.6883433098
            ],
            [
              352171.65199026879,
              5011145.7611056287
            ],
            [
              352189.72471683385,
              5011157.0095330598
            ],
            [
              352176.66001324379,
              5011172.0376670025
            ],
            [
              352198.58948097128,
              5011188.5599040212
            ],
            [
              352210.35090076108,
              5011197.3792534061
            ],
            [
              352181.55547477596,
              5011253.2856853791
            ],
            [
              352164.25887800503,
              5011288.3728126865
            ],
            [
              352193.98148830427,
              5011281.6393842315
            ],
            [
              352196.20599088346,
              5011280.7136517549
            ],
            [
              352200.00125288713,
              5011279.1342437137
            ],
            [
              352202.99307117803,
              5011277.8891908517
            ],
            [
              352232.50173326442,
              5011259.3730423208
            ],
            [
              352235.02646125853,
              5011248.7222043481
            ],
            [
              352275.14386218699,
              5011225.6544352267
            ],
            [
              352276.63307067519,
              5011219.3099599704
            ],
            [
              352283.21160001558,
              5011217.0368650826
            ],
            [
              352301.5344551247,
              5011201.0762734972
            ],
            [
              352313.23190117028,
              5011186.0625758739
            ],
            [
              352318.24970958754,
              5011189.5691938791
            ],
            [
              352330.881834428,
              5011175.5268372735
            ],
            [
              352332.61268349941,
              5011164.0803894373
            ],
            [
              352340.68358545931,
              5011165.2709552757
            ],
            [
              352347.22737942357,
              5011140.0634838175
            ],
            [
              352360.38789296843,
              5011118.7066299813
            ],
            [
              352372.01443449844,
              5011099.3857399561
            ],
            [
              352386.89284661977,
              5011109.4076006291
            ],
            [
              352396.80199660204,
              5011097.6801072108
            ],
            [
              352433.22532483365,
              5011134.0778037105
            ],
            [
              352445.44190049585,
              5011144.5911784349
            ],
            [
              352451.50563229056,
              5011157.0338021265
            ],
            [
              352491.22703673766,
              5011094.354472694
            ],
            [
              352571.58569692925,
              5011059.7954247724
            ],
            [
              352582.80757061683,
              5011065.2419399992
            ],
            [
              352630.43994766899,
              5011087.9303268585
            ],
            [
              352648.38216694858,
              5011061.9969743127
            ],
            [
              352649.77980211558,
              5011041.0834169202
            ],
            [
              352667.73997264676,
              5011009.4092906527
            ],
            [
              352638.853068622,
              5011007.1823800579
            ],
            [
              352636.33846748044,
              5011004.479125388
            ],
            [
              352638.88180920767,
              5010998.5052974029
            ],
            [
              352646.5871670593,
              5010978.1368365092
            ],
            [
              352648.21747344307,
              5010970.0690671215
            ],
            [
              352647.28217582346,
              5010960.2950948309
            ],
            [
              352676.80404213298,
              5010935.7444150355
            ],
            [
              352701.49664477888,
              5010910.1825824007
            ],
            [
              352683.11674646777,
              5010902.2985705417
            ],
            [
              352678.02168437926,
              5010907.5226772819
            ],
            [
              352636.64143315027,
              5010891.9919610405
            ],
            [
              352628.3003395269,
              5010880.8925312031
            ],
            [
              352659.19661140937,
              5010839.9401850095
            ],
            [
              352676.74784549879,
              5010814.984241385
            ],
            [
              352678.51312307909,
              5010812.9319015527
            ],
            [
              352679.9592342985,
              5010810.3610569267
            ],
            [
              352686.63885110855,
              5010801.7015312016
            ],
            [
              352695.37713542342,
              5010789.0364364069
            ],
            [
              352690.21599972562,
              5010786.6168905962
            ],
            [
              352697.48684732273,
              5010781.4713110849
            ],
            [
              352689.99088385369,
              5010773.6838403782
            ],
            [
              352689.69871142297,
              5010769.6281772237
            ],
            [
              352694.70249711099,
              5010764.9456189442
            ],
            [
              352696.28886502545,
              5010755.6880682735
            ],
            [
              352695.25201963325,
              5010744.3013447886
            ],
            [
              352693.22802943975,
              5010741.9878623849
            ],
            [
              352694.88268882449,
              5010739.7397402944
            ],
            [
              352684.03410286072,
              5010729.626750024
            ],
            [
              352685.57857737702,
              5010727.0104944389
            ],
            [
              352677.03444118373,
              5010717.6538882162
            ],
            [
              352679.45746368758,
              5010718.9059413485
            ],
            [
              352685.64826471714,
              5010706.3372800415
            ],
            [
              352685.92440019723,
              5010702.2185724769
            ],
            [
              352688.38569462561,
              5010702.1727732392
            ],
            [
              352695.48589405505,
              5010680.951458225
            ],
            [
              352704.5166430432,
              5010666.5993631678
            ],
            [
              352730.31104878202,
              5010685.2263457542
            ],
            [
              352731.37059477356,
              5010671.220121799
            ],
            [
              352741.37836786924,
              5010645.4270838043
            ],
            [
              352722.38894749957,
              5010647.1829525465
            ],
            [
              352720.31772054819,
              5010644.9331556391
            ],
            [
              352715.0897648611,
              5010631.5977778435
            ],
            [
              352702.61633725587,
              5010618.8081673691
            ],
            [
              352698.58370362775,
              5010612.5306617711
            ],
            [
              352692.38793407811,
              5010611.2823719829
            ],
            [
              352695.11551354366,
              5010604.471021248
            ],
            [
              352689.76043037319,
              5010598.6979036201
            ],
            [
              352689.32007974799,
              5010598.5285776537
            ],
            [
              352692.16533850937,
              5010587.7442640802
            ],
            [
              352697.20068801683,
              5010577.0880779978
            ],
            [
              352701.24627701967,
              5010572.1799391061
            ],
            [
              352727.50694398565,
              5010586.2683072584
            ],
            [
              352741.96531929186,
              5010595.2512913542
            ],
            [
              352744.70366822445,
              5010589.3148416774
            ],
            [
              352771.05087324092,
              5010609.9395719152
            ],
            [
              352781.91413193528,
              5010616.1493932493
            ],
            [
              352794.68893191131,
              5010626.3180164667
            ],
            [
              352818.58405474905,
              5010598.6831859984
            ],
            [
              352821.83349439647,
              5010597.0493255379
            ],
            [
              352844.29500926781,
              5010606.9768472584
            ],
            [
              352850.05174203118,
              5010610.7280639997
            ],
            [
              352873.86037432082,
              5010629.2617878988
            ],
            [
              352886.96442152205,
              5010639.3513120906
            ],
            [
              352917.56700025167,
              5010653.8111502295
            ],
            [
              352909.4796030625,
              5010662.5778625263
            ],
            [
              352898.89498459181,
              5010669.4108582251
            ],
            [
              352877.84542621474,
              5010687.5645722225
            ],
            [
              352918.74802885827,
              5010719.2593837027
            ],
            [
              352921.37887005793,
              5010715.8642458301
            ],
            [
              352937.21980056056,
              5010729.1877315445
            ],
            [
              352972.00519507745,
              5010670.6731816689
            ],
            [
              352964.02239124646,
              5010665.5726365363
            ],
            [
              352946.50829334592,
              5010659.3473537974
            ],
            [
              352938.48874278326,
              5010651.0396126937
            ],
            [
              352950.45820582536,
              5010628.7312027542
            ],
            [
              352940.53460237326,
              5010622.4306962816
            ],
            [
              352944.02540203813,
              5010619.239783776
            ],
            [
              352930.85749803169,
              5010610.8151464462
            ],
            [
              352935.11309632676,
              5010605.5310261771
            ],
            [
              352907.92387269117,
              5010588.3632211825
            ],
            [
              352894.47559717187,
              5010581.6465867935
            ],
            [
              352870.75801549718,
              5010575.8821896305
            ],
            [
              352875.79154200357,
              5010568.8761705803
            ],
            [
              352888.96617248043,
              5010554.7144167526
            ],
            [
              352897.24622219458,
              5010547.6076366259
            ],
            [
              352908.14624796557,
              5010538.7339695767
            ],
            [
              352905.0480468903,
              5010535.7504810691
            ],
            [
              352915.92864702037,
              5010521.4308199221
            ],
            [
              352898.17877462349,
              5010511.1105187377
            ],
            [
              352897.16970446391,
              5010512.1573490733
            ],
            [
              352886.4400895639,
              5010504.2308045635
            ],
            [
              352881.79348663241,
              5010509.0265505109
            ],
            [
              352876.9711428766,
              5010515.81557744
            ],
            [
              352850.11147903505,
              5010502.3428507661
            ],
            [
              352843.98498504004,
              5010501.2945750877
            ],
            [
              352836.97979517822,
              5010499.0554405535
            ],
            [
              352831.93191171193,
              5010498.3255900946
            ],
            [
              352834.23729560908,
              5010489.5962267993
            ],
            [
              352816.28549862973,
              5010484.9450755361
            ],
            [
              352815.76662490604,
              5010478.7801241269
            ],
            [
              352812.91932455765,
              5010471.9928003363
            ],
            [
              352812.71536492259,
              5010467.8666891186
            ],
            [
              352802.66368745675,
              5010458.7029588558
            ],
            [
              352802.17175871338,
              5010458.4684552951
            ],
            [
              352804.35717034433,
              5010453.5252021262
            ],
            [
              352797.49991462537,
              5010450.6774733793
            ],
            [
              352797.72159986914,
              5010450.135934772
            ],
            [
              352802.52687384788,
              5010432.8567320807
            ],
            [
              352801.02423327585,
              5010432.010485949
            ],
            [
              352807.80541595566,
              5010422.2042493913
            ],
            [
              352793.47481272265,
              5010412.1895145187
            ],
            [
              352759.88579366874,
              5010389.2162807574
            ],
            [
              352762.78437402932,
              5010384.6465208102
            ],
            [
              352771.79087435309,
              5010393.2532468531
            ],
            [
              352783.85339229106,
              5010383.5553986384
            ],
            [
              352781.68826729379,
              5010379.2100829612
            ],
            [
              352783.82081835798,
              5010376.4912142064
            ],
            [
              352795.40698724525,
              5010384.2985397102
            ],
            [
              352811.63255479332,
              5010360.4763759924
            ],
            [
              352807.06435112568,
              5010357.7395572895
            ],
            [
              352816.7909311775,
              5010342.0353956558
            ],
            [
              352818.29073083273,
              5010342.9252141183
            ],
            [
              352829.30011154636,
              5010328.6659817081
            ],
            [
              352830.32273033081,
              5010325.8723183805
            ],
            [
              352840.95425889752,
              5010312.3134693997
            ],
            [
              352843.56190972711,
              5010312.5540333167
            ],
            [
              352851.88620531891,
              5010300.7218506299
            ],
            [
              352842.2152251745,
              5010290.5229730746
            ],
            [
              352845.21805049508,
              5010286.1967095239
            ],
            [
              352857.79416252178,
              5010280.8396379026
            ],
            [
              352870.7098134738,
              5010289.2946436461
            ],
            [
              352894.13250819355,
              5010303.5628440408
            ],
            [
              352909.94150383543,
              5010312.1362004178
            ],
            [
              352920.48544229212,
              5010295.2436454929
            ],
            [
              352937.3337423334,
              5010263.7397519993
            ],
            [
              352938.79581879784,
              5010262.4472399438
            ],
            [
              352955.17243593151,
              5010251.5704762554
            ],
            [
              352972.11597193888,
              5010245.8542162394
            ],
            [
              352978.45124089887,
              5010239.1808804618
            ],
            [
              352990.00622204196,
              5010230.2436714834
            ],
            [
              352997.57988579996,
              5010226.1071873484
            ],
            [
              352998.71790873044,
              5010228.5433105389
            ],
            [
              353007.12908498279,
              5010242.5851522246
            ],
            [
              353014.05033783481,
              5010244.9946688125
            ],
            [
              353024.93452141329,
              5010250.1766245905
            ],
            [
              353039.91041352373,
              5010226.5278847609
            ],
            [
              353045.26542146067,
              5010219.6213316955
            ],
            [
              353053.87738013547,
              5010224.733058475
            ],
            [
              353058.28385640722,
              5010227.408622955
            ],
            [
              353077.67018953466,
              5010239.1975086788
            ],
            [
              353090.71546334476,
              5010238.2252848474
            ],
            [
              353110.83955462585,
              5010218.8210775517
            ],
            [
              353125.80304405984,
              5010186.4098536801
            ],
            [
              353110.83241048141,
              5010173.9694978427
            ],
            [
              353109.86056524894,
              5010173.1837259158
            ],
            [
              353124.2036755798,
              5010155.6832753606
            ],
            [
              353127.01088271773,
              5010158.2440066319
            ],
            [
              353144.50540605368,
              5010143.690866814
            ],
            [
              353150.07230265538,
              5010137.3644924676
            ],
            [
              353157.00344941526,
              5010131.55288768
            ],
            [
              353166.57898825331,
              5010120.5659501674
            ],
            [
              353172.08058313507,
              5010113.0369152706
            ],
            [
              353166.83951258042,
              5010102.1954222666
            ],
            [
              353173.97988225723,
              5010095.5354435397
            ],
            [
              353206.24948656833,
              5010085.4152683718
            ],
            [
              353209.7165526895,
              5010079.1914923927
            ],
            [
              353210.55002863792,
              5010079.3994881297
            ],
            [
              353228.67037765338,
              5010074.0358521612
            ],
            [
              353235.42811644799,
              5010081.4117160253
            ],
            [
              353249.91117932892,
              5010081.5102696577
            ],
            [
              353266.73822673952,
              5010082.9476280967
            ],
            [
              353268.20307133644,
              5010088.3735231468
            ],
            [
              353270.46204330295,
              5010092.4532350665
            ],
            [
              353299.76499849261,
              5010118.6403241046
            ],
            [
              353324.73748123809,
              5010138.5272919098
            ],
            [
              353340.27820069966,
              5010150.1229591453
            ],
            [
              353386.98712558689,
              5010070.9761496726
            ],
            [
              353381.1466616976,
              5010065.627981713
            ],
            [
              353415.68405562319,
              5010031.0525025437
            ],
            [
              353392.06116748456,
              5010000.4877089327
            ],
            [
              353346.1164053781,
              5010044.7652152088
            ],
            [
              353340.46771424054,
              5010044.5821013702
            ],
            [
              353305.38785291259,
              5010006.4381112773
            ],
            [
              353287.08967407979,
              5010013.3870283663
            ],
            [
              353276.62472581363,
              5010016.9775377614
            ],
            [
              353272.78006987268,
              5010017.8351643607
            ],
            [
              353272.46193149575,
              5010017.6091482779
            ],
            [
              353264.72296183935,
              5010007.7220494924
            ],
            [
              353252.80365118082,
              5009989.2727219313
            ],
            [
              353248.81328255986,
              5009981.7380414372
            ],
            [
              353239.30972559558,
              5009963.0954163792
            ],
            [
              353219.28953143564,
              5009980.1840014858
            ],
            [
              353193.398700052,
              5009970.0392640913
            ],
            [
              353189.06569962698,
              5009969.3348535886
            ],
            [
              353184.13297962211,
              5009951.6895166198
            ],
            [
              353159.49048729433,
              5009954.7288313191
            ],
            [
              353149.1352084169,
              5009958.6160362903
            ],
            [
              353130.32013880956,
              5009955.4561064346
            ],
            [
              353122.38875850377,
              5009949.9471554672
            ],
            [
              353108.47976430185,
              5009949.8627196765
            ],
            [
              353099.6688520165,
              5009939.8467303934
            ],
            [
              353086.64127285144,
              5009933.1381960167
            ],
            [
              353054.45168856002,
              5009949.9218239263
            ],
            [
              353075.90225411119,
              5009984.0148081146
            ],
            [
              353062.01759060432,
              5009984.3874082929
            ],
            [
              353051.55080990243,
              5009987.9861279875
            ],
            [
              353039.49515560799,
              5009984.1588427061
            ],
            [
              353028.07274417119,
              5009981.7824613396
            ],
            [
              353023.12704362563,
              5009978.59055469
            ],
            [
              353010.22037438542,
              5009987.8156784661
            ],
            [
              353003.73257089983,
              5009998.6971749077
            ],
            [
              352989.11562044144,
              5010023.2130705211
            ],
            [
              353003.08262066002,
              5010035.0658679102
            ],
            [
              353013.52193925757,
              5010044.2208674913
            ],
            [
              353020.73328198958,
              5010052.2637129445
            ],
            [
              353030.38271010836,
              5010064.9587497665
            ],
            [
              353013.12232749519,
              5010083.9734334406
            ],
            [
              352995.00367139286,
              5010078.5176900271
            ],
            [
              352991.31169421971,
              5010086.4585707551
            ],
            [
              352971.67522184685,
              5010078.8946580999
            ],
            [
              352967.3299499455,
              5010077.4691868313
            ],
            [
              352943.64679470297,
              5010065.7627731925
            ],
            [
              352923.00092845497,
              5010046.9295801297
            ],
            [
              352917.74774982536,
              5010067.2088887831
            ],
            [
              352929.87894028268,
              5010086.5107098082
            ],
            [
              352918.05045215075,
              5010113.0275550755
            ],
            [
              352915.24976974179,
              5010138.82963729
            ],
            [
              352910.08924896322,
              5010148.3184169475
            ],
            [
              352903.64131679601,
              5010144.3258949202
            ],
            [
              352891.75626773731,
              5010133.4312040769
            ],
            [
              352885.41421143163,
              5010126.7016170435
            ],
            [
              352885.59200726071,
              5010126.5286959885
            ],
            [
              352891.83161468565,
              5010113.2851856453
            ],
            [
              352873.28187583637,
              5010104.3225875758
            ],
            [
              352849.75455578469,
              5010116.0163934147
            ],
            [
              352849.67113890819,
              5010116.0553211328
            ],
            [
              352849.41523769795,
              5010116.0600824542
            ],
            [
              352847.63650944544,
              5010116.6040286468
            ],
            [
              352803.40183845727,
              5010135.3846732005
            ],
            [
              352796.36609745189,
              5010143.9396141414
            ],
            [
              352800.30940586107,
              5010152.0777064972
            ],
            [
              352797.99064860324,
              5010155.2905402854
            ],
            [
              352799.49445441767,
              5010165.8595607569
            ],
            [
              352799.0366552046,
              5010187.5339079155
            ],
            [
              352798.98283135524,
              5010197.3208323354
            ],
            [
              352796.7702658769,
              5010208.2886737473
            ],
            [
              352792.31046980858,
              5010221.4719555099
            ],
            [
              352783.21320541832,
              5010231.148474304
            ],
            [
              352769.82657913357,
              5010251.2601390537
            ],
            [
              352769.24714567367,
              5010255.6798604941
            ],
            [
              352761.91757204785,
              5010263.052975243
            ],
            [
              352752.75355639803,
              5010280.4939913303
            ],
            [
              352747.14088941872,
              5010290.7071224377
            ],
            [
              352744.30061757326,
              5010290.5249685133
            ],
            [
              352730.65943856322,
              5010268.9489023285
            ],
            [
              352726.48536225612,
              5010257.4858474797
            ],
            [
              352725.56301873602,
              5010252.6404666118
            ],
            [
              352723.9331906843,
              5010235.306440671
            ],
            [
              352719.89746512129,
              5010232.3065540176
            ],
            [
              352716.69842808013,
              5010230.8444603803
            ],
            [
              352712.88585325639,
              5010230.6226338474
            ],
            [
              352679.8872660597,
              5010241.49250009
            ],
            [
              352671.52822883968,
              5010245.8268339569
            ],
            [
              352659.49354390503,
              5010252.1643917868
            ],
            [
              352655.07441190624,
              5010258.6522446545
            ],
            [
              352657.00445990922,
              5010266.2443662519
            ],
            [
              352659.25117726339,
              5010270.040556307
            ],
            [
              352683.35624470731,
              5010286.083768297
            ],
            [
              352682.32335717446,
              5010289.1599443294
            ],
            [
              352681.01104847569,
              5010290.8920977637
            ],
            [
              352676.25284877355,
              5010297.6332304096
            ],
            [
              352675.59823923896,
              5010298.7048610216
            ],
            [
              352672.23047217593,
              5010296.4550264943
            ],
            [
              352661.94755414536,
              5010305.5026469752
            ],
            [
              352645.17259480309,
              5010306.0043180017
            ],
            [
              352632.39062699751,
              5010314.823861815
            ],
            [
              352626.35095844808,
              5010321.3880867371
            ],
            [
              352616.96055159118,
              5010328.4544515871
            ],
            [
              352613.5563978384,
              5010343.3489190983
            ],
            [
              352607.80591689615,
              5010354.4176143091
            ],
            [
              352602.72473424656,
              5010368.9929344226
            ],
            [
              352589.08985252964,
              5010383.1694903234
            ],
            [
              352594.09654446103,
              5010391.1371929506
            ],
            [
              352592.11267083429,
              5010393.2505743476
            ],
            [
              352592.17612244346,
              5010412.5486400956
            ],
            [
              352592.30245395045,
              5010450.9699120959
            ],
            [
              352605.11357520992,
              5010451.0509310411
            ],
            [
              352593.40408620477,
              5010476.3893221673
            ],
            [
              352589.51998434495,
              5010485.3295873441
            ],
            [
              352599.66397675016,
              5010487.7812750544
            ],
            [
              352593.81379720272,
              5010496.616413434
            ],
            [
              352598.57577113266,
              5010515.8370368723
            ],
            [
              352600.92410574498,
              5010521.8298568837
            ],
            [
              352617.22541184589,
              5010531.1672628466
            ],
            [
              352638.58036138222,
              5010546.3837190093
            ],
            [
              352637.59482399974,
              5010548.149400671
            ],
            [
              352630.0753421429,
              5010559.9896526225
            ],
            [
              352623.95239362161,
              5010568.5924238032
            ],
            [
              352632.62034746958,
              5010587.0752394162
            ],
            [
              352630.75822228106,
              5010590.3633754021
            ],
            [
              352631.7838620221,
              5010604.1737286793
            ],
            [
              352634.14896664146,
              5010604.8799435096
            ],
            [
              352628.39258619986,
              5010616.9505589148
            ],
            [
              352635.76170122362,
              5010624.3232011748
            ],
            [
              352634.76958012726,
              5010625.8446686026
            ],
            [
              352627.12443893676,
              5010631.9796714233
            ],
            [
              352633.59323242557,
              5010639.6693484522
            ],
            [
              352625.91872480343,
              5010644.5316673461
            ],
            [
              352633.97975488234,
              5010661.1343289437
            ],
            [
              352635.14940098225,
              5010679.7781729056
            ],
            [
              352636.11880144407,
              5010684.3537715059
            ],
            [
              352635.26422839757,
              5010688.8729461972
            ],
            [
              352625.00008212595,
              5010687.3429443967
            ],
            [
              352625.33918540634,
              5010699.6173063349
            ],
            [
              352611.26277089183,
              5010707.0150208734
            ],
            [
              352616.9671307847,
              5010714.0988465995
            ],
            [
              352599.08700011409,
              5010731.0327423867
            ],
            [
              352597.28020455787,
              5010738.963278655
            ],
            [
              352589.50340705784,
              5010754.100169898
            ],
            [
              352580.4931104469,
              5010765.5484236907
            ],
            [
              352581.3230893595,
              5010766.2448833976
            ],
            [
              352576.98268721957,
              5010768.0881832996
            ],
            [
              352573.61190666846,
              5010771.683602605
            ],
            [
              352570.81048267626,
              5010769.2864889381
            ],
            [
              352557.75115242082,
              5010787.133167644
            ],
            [
              352555.18160550448,
              5010788.4775932794
            ],
            [
              352539.98118973844,
              5010811.4809667598
            ],
            [
              352540.74366882641,
              5010814.5036529629
            ],
            [
              352532.3211308163,
              5010825.1031760601
            ],
            [
              352522.26716060605,
              5010828.2956712982
            ],
            [
              352509.43334242137,
              5010846.7331163809
            ],
            [
              352484.81114474259,
              5010872.5382984933
            ],
            [
              352457.50292629533,
              5010908.2716072304
            ],
            [
              352439.00471077877,
              5010940.0795526858
            ],
            [
              352436.45807829354,
              5010938.5374872703
            ],
            [
              352376.87764218455,
              5011010.9998509204
            ],
            [
              352348.98604249553,
              5011052.4524075948
            ],
            [
              352330.19292031555,
              5011087.0920414431
            ],
            [
              352324.69099110726,
              5011093.4694980541
            ],
            [
              352314.24889917328,
              5011091.9037102861
            ],
            [
              352306.58546118764,
              5011096.6801146939
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 431,
        "Layer" : "GRANICA_NA",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355186.68677203381,
              5009236.3454974042
            ],
            [
              355183.30992804532,
              5009236.2503824709
            ],
            [
              355166.83488292556,
              5009234.7588761793
            ],
            [
              355176.08301754232,
              5009224.0718867816
            ],
            [
              355156.34073664574,
              5009209.6930569923
            ],
            [
              355159.15287133161,
              5009206.2123618759
            ],
            [
              355123.19697781425,
              5009177.0895921346
            ],
            [
              355103.08637410915,
              5009197.5171563243
            ],
            [
              355095.91813296458,
              5009191.6668077484
            ],
            [
              355082.23202528537,
              5009200.5743641034
            ],
            [
              355076.60715370759,
              5009206.2021048721
            ],
            [
              355069.57786534057,
              5009201.0122726792
            ],
            [
              355052.00994976168,
              5009225.5913614929
            ],
            [
              355042.08090885199,
              5009235.6728244741
            ],
            [
              355030.84568907821,
              5009245.6080178693
            ],
            [
              355041.5840301087,
              5009255.864007717
            ],
            [
              355049.4734896181,
              5009261.0781567069
            ],
            [
              355057.73867069901,
              5009271.0339580905
            ],
            [
              355075.68675008783,
              5009292.5632759538
            ],
            [
              355077.46444819,
              5009294.8481988879
            ],
            [
              355093.76992226677,
              5009309.3396569034
            ],
            [
              355105.34367882751,
              5009320.0920509482
            ],
            [
              355114.36466389283,
              5009329.457222539
            ],
            [
              355119.22693331289,
              5009330.0594337685
            ],
            [
              355110.81659458176,
              5009337.7219468402
            ],
            [
              355097.87506629707,
              5009349.6798668653
            ],
            [
              355094.6140353136,
              5009352.8599354988
            ],
            [
              355080.5731124634,
              5009366.7382254964
            ],
            [
              355070.48820724076,
              5009376.8613975709
            ],
            [
              355069.71898692072,
              5009372.3545666495
            ],
            [
              355046.00870565546,
              5009348.4350115061
            ],
            [
              355046.11318192194,
              5009348.3321159976
            ],
            [
              355072.38539590506,
              5009325.8745441223
            ],
            [
              355061.46032259753,
              5009314.3069672268
            ],
            [
              355054.16608965088,
              5009308.6810210319
            ],
            [
              355047.31007288018,
              5009300.0970682437
            ],
            [
              355011.71324462909,
              5009325.5497180065
            ],
            [
              355003.68972954713,
              5009335.5079506729
            ],
            [
              354997.82360882143,
              5009339.7221167805
            ],
            [
              354991.82166839525,
              5009349.4035700234
            ],
            [
              354990.57914695161,
              5009347.1832423853
            ],
            [
              354981.2214755803,
              5009332.1238517892
            ],
            [
              354954.16734557209,
              5009351.6202369835
            ],
            [
              354962.39200884075,
              5009362.0954563105
            ],
            [
              354944.24533957586,
              5009375.9087451613
            ],
            [
              354940.52552959503,
              5009378.8018714366
            ],
            [
              354932.19170880696,
              5009366.3691477459
            ],
            [
              354906.39707097475,
              5009366.2045692494
            ],
            [
              354892.44294992241,
              5009382.6005765451
            ],
            [
              354872.81571733608,
              5009409.4837137116
            ],
            [
              354864.09344855527,
              5009414.8806899749
            ],
            [
              354898.95399435743,
              5009466.5740593206
            ],
            [
              354906.22205723025,
              5009483.4352081576
            ],
            [
              354885.66722457891,
              5009494.9028245192
            ],
            [
              354899.33340049017,
              5009514.5216834471
            ],
            [
              354909.97679414664,
              5009527.0663453648
            ],
            [
              354922.41028064786,
              5009543.102092851
            ],
            [
              354918.66727684537,
              5009547.5357315633
            ],
            [
              354901.59996352287,
              5009566.0743274037
            ],
            [
              354887.07860016287,
              5009581.1385800913
            ],
            [
              354876.53063134931,
              5009590.735410572
            ],
            [
              354873.01751445263,
              5009594.104259816
            ],
            [
              354833.63192597899,
              5009630.7770185927
            ],
            [
              354795.9263139973,
              5009668.4349340154
            ],
            [
              354781.60261772905,
              5009656.5178041048
            ],
            [
              354759.78739520354,
              5009678.5530085117
            ],
            [
              354772.27642256155,
              5009689.43149345
            ],
            [
              354770.14755943086,
              5009692.8762674732
            ],
            [
              354778.58554972627,
              5009700.1003551837
            ],
            [
              354747.93103400554,
              5009736.2925242186
            ],
            [
              354778.33749132225,
              5009753.1808608547
            ],
            [
              354803.50933677569,
              5009758.2830056446
            ],
            [
              354815.2288038425,
              5009749.4482529592
            ],
            [
              354817.99530116428,
              5009749.3967823125
            ],
            [
              354830.27577430807,
              5009738.1050533205
            ],
            [
              354835.38110709452,
              5009722.9698399911
            ],
            [
              354825.032181405,
              5009709.2841128679
            ],
            [
              354830.99250891869,
              5009703.0609532259
            ],
            [
              354843.6403218669,
              5009688.091818626
            ],
            [
              354857.21478057757,
              5009689.1366397301
            ],
            [
              354863.31245819834,
              5009689.2770527648
            ],
            [
              354873.99002456357,
              5009690.2200038023
            ],
            [
              354883.15700400475,
              5009690.6363722757
            ],
            [
              354932.71418250859,
              5009678.7144250339
            ],
            [
              354913.47455174301,
              5009637.5000254074
            ],
            [
              354928.86606100068,
              5009622.3169948887
            ],
            [
              354955.1415688101,
              5009602.1555110784
            ],
            [
              354950.04242027138,
              5009595.4553928282
            ],
            [
              354951.96823333186,
              5009593.1712406762
            ],
            [
              354948.38120086532,
              5009588.1349306358
            ],
            [
              354966.82245503075,
              5009571.4038058203
            ],
            [
              354971.9553852363,
              5009567.3474422563
            ],
            [
              354978.31253755209,
              5009561.4556445787
            ],
            [
              354985.26426968124,
              5009568.8956526592
            ],
            [
              354998.88101597078,
              5009583.5795100322
            ],
            [
              355021.29098893626,
              5009609.6730847396
            ],
            [
              355027.9819306758,
              5009605.2732976144
            ],
            [
              355037.07827876188,
              5009595.5505420491
            ],
            [
              355043.79366636684,
              5009588.2934309654
            ],
            [
              355047.48609764763,
              5009581.7746975143
            ],
            [
              355048.28252438171,
              5009571.2018104689
            ],
            [
              355051.66901765246,
              5009556.011564469
            ],
            [
              355066.06716734561,
              5009537.8101515966
            ],
            [
              355074.77694451925,
              5009502.916478347
            ],
            [
              355077.09201926796,
              5009491.9051091289
            ],
            [
              355080.4549020073,
              5009486.3755771015
            ],
            [
              355083.07101858291,
              5009489.3946558395
            ],
            [
              355095.24003579299,
              5009490.9771618173
            ],
            [
              355102.17640458216,
              5009485.1312540686
            ],
            [
              355118.9586222787,
              5009498.0773139307
            ],
            [
              355144.31823805958,
              5009486.7097246191
            ],
            [
              355123.73131349625,
              5009467.2823374839
            ],
            [
              355103.39193247951,
              5009448.340107983
            ],
            [
              355108.51056337409,
              5009440.3181508118
            ],
            [
              355119.89493368886,
              5009423.9520592112
            ],
            [
              355107.2389876832,
              5009413.2814365029
            ],
            [
              355109.20195693494,
              5009406.6224659681
            ],
            [
              355130.55965880805,
              5009379.1625874899
            ],
            [
              355130.09209631244,
              5009379.0041078152
            ],
            [
              355146.3890851427,
              5009364.9879945116
            ],
            [
              355139.63344528939,
              5009359.8308979133
            ],
            [
              355147.60955834383,
              5009346.1172974128
            ],
            [
              355165.12116649881,
              5009331.6219016481
            ],
            [
              355176.54996861488,
              5009315.2515948405
            ],
            [
              355185.74583980819,
              5009301.7543903207
            ],
            [
              355197.38371631334,
              5009306.7893040609
            ],
            [
              355200.40260843595,
              5009305.1328536449
            ],
            [
              355208.88609499304,
              5009309.9443301177
            ],
            [
              355221.59756379313,
              5009317.9809433669
            ],
            [
              355241.62203427113,
              5009337.784523583
            ],
            [
              355268.76362186635,
              5009360.0178252198
            ],
            [
              355289.2002938065,
              5009355.0282582957
            ],
            [
              355299.63666371844,
              5009333.943009939
            ],
            [
              355311.84588206845,
              5009313.9776366362
            ],
            [
              355316.60535853793,
              5009283.8395766569
            ],
            [
              355312.18078102026,
              5009270.9903314887
            ],
            [
              355315.38427624648,
              5009267.2898091944
            ],
            [
              355339.17418102588,
              5009258.772563424
            ],
            [
              355390.94322826603,
              5009233.622971192
            ],
            [
              355399.42018576391,
              5009226.7884594789
            ],
            [
              355435.45814728393,
              5009198.0625145622
            ],
            [
              355446.90775875695,
              5009181.502274625
            ],
            [
              355417.11293881573,
              5009152.8104053633
            ],
            [
              355394.49954433867,
              5009131.0679182038
            ],
            [
              355404.74458118115,
              5009120.0266147526
            ],
            [
              355400.53029862844,
              5009107.2954395656
            ],
            [
              355400.02837244514,
              5009105.814640834
            ],
            [
              355413.28242027626,
              5009096.4425423369
            ],
            [
              355426.22649445938,
              5009092.9144799113
            ],
            [
              355420.2664682605,
              5009077.7700615032
            ],
            [
              355416.21626111254,
              5009079.441911485
            ],
            [
              355392.49441759108,
              5009063.3861808144
            ],
            [
              355412.97646560502,
              5009042.3544863015
            ],
            [
              355396.2682990692,
              5009026.9362621233
            ],
            [
              355374.35407241154,
              5009014.7378602158
            ],
            [
              355365.81198738026,
              5009012.2012237003
            ],
            [
              355351.56093472632,
              5009007.2099264199
            ],
            [
              355336.77287809242,
              5009031.3409181749
            ],
            [
              355329.79174710531,
              5009040.231390683
            ],
            [
              355321.4827564556,
              5009050.2248060768
            ],
            [
              355320.89401960169,
              5009054.8182705948
            ],
            [
              355308.14776755922,
              5009070.822558729
            ],
            [
              355302.2107454369,
              5009078.485191226
            ],
            [
              355299.51810531178,
              5009076.1360054631
            ],
            [
              355287.69074632676,
              5009093.0897834897
            ],
            [
              355273.05322373833,
              5009113.9265593123
            ],
            [
              355260.33299736684,
              5009135.1458311155
            ],
            [
              355243.849835684,
              5009158.7847274551
            ],
            [
              355257.46826638322,
              5009172.5629036054
            ],
            [
              355268.91589556128,
              5009177.3111132151
            ],
            [
              355275.33245864318,
              5009170.9205984315
            ],
            [
              355299.77761804289,
              5009135.0491597457
            ],
            [
              355308.28634165652,
              5009122.405856763
            ],
            [
              355312.21592177759,
              5009114.2615775587
            ],
            [
              355327.63248276146,
              5009130.2419134593
            ],
            [
              355324.87027619459,
              5009130.9333769493
            ],
            [
              355334.08011786122,
              5009143.52844775
            ],
            [
              355296.73090990284,
              5009164.8545548469
            ],
            [
              355293.26157368097,
              5009168.7541623246
            ],
            [
              355285.34887969343,
              5009172.613204373
            ],
            [
              355284.80002911453,
              5009171.6676283879
            ],
            [
              355265.50915408583,
              5009178.6479496276
            ],
            [
              355243.12463539519,
              5009191.0024791937
            ],
            [
              355221.04187874973,
              5009211.9040980563
            ],
            [
              355212.13934058236,
              5009218.2937072795
            ],
            [
              355196.89746162732,
              5009226.2292370582
            ],
            [
              355189.64975555678,
              5009232.326052051
            ],
            [
              355186.68677203381,
              5009236.3454974042
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 432,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357847.70033182343,
              5006431.9107836531
            ],
            [
              357857.97907434177,
              5006423.6841025427
            ],
            [
              357853.30773825588,
              5006418.7509779958
            ],
            [
              357850.47564112896,
              5006413.2159936493
            ],
            [
              357851.33473364246,
              5006407.9597506505
            ],
            [
              357854.16685230017,
              5006405.3480364596
            ],
            [
              357853.25365390285,
              5006403.7588783382
            ],
            [
              357851.36197179504,
              5006400.4669518061
            ],
            [
              357850.57246286829,
              5006398.2773629148
            ],
            [
              357848.00548514508,
              5006396.7030995972
            ],
            [
              357841.58367539285,
              5006394.5119323358
            ],
            [
              357838.19270760292,
              5006392.4944936903
            ],
            [
              357835.20221057296,
              5006393.3591914624
            ],
            [
              357833.1773122478,
              5006396.4020072808
            ],
            [
              357828.02058460872,
              5006398.9251295151
            ],
            [
              357823.63645009877,
              5006399.4690049235
            ],
            [
              357821.69707567745,
              5006400.8920526588
            ],
            [
              357818.34048456396,
              5006400.7233109046
            ],
            [
              357815.7737290659,
              5006399.4996347334
            ],
            [
              357809.0466289639,
              5006399.9772639461
            ],
            [
              357806.91771398665,
              5006394.0288174897
            ],
            [
              357805.78020133363,
              5006389.2302354099
            ],
            [
              357803.44238185242,
              5006387.5274072634
            ],
            [
              357806.1673269989,
              5006382.3218644792
            ],
            [
              357799.21257999132,
              5006375.6241578879
            ],
            [
              357782.92588923534,
              5006364.5285692215
            ],
            [
              357768.78792434226,
              5006379.8557768436
            ],
            [
              357764.32684171636,
              5006389.2505373675
            ],
            [
              357761.09869080759,
              5006389.7729170127
            ],
            [
              357760.53480463912,
              5006400.9979007049
            ],
            [
              357757.3501897885,
              5006411.173860047
            ],
            [
              357755.94306237518,
              5006419.2415476888
            ],
            [
              357751.97679668601,
              5006415.2945580091
            ],
            [
              357725.67403949349,
              5006396.458078716
            ],
            [
              357727.36714155698,
              5006389.8117837301
            ],
            [
              357717.6739992791,
              5006384.933658544
            ],
            [
              357698.09823427087,
              5006378.9422855172
            ],
            [
              357699.34377812553,
              5006397.0773899537
            ],
            [
              357691.98862245894,
              5006394.0727336602
            ],
            [
              357691.17174159893,
              5006392.0127043063
            ],
            [
              357683.23729220132,
              5006390.9929250423
            ],
            [
              357672.9207864264,
              5006384.576247517
            ],
            [
              357670.91835179977,
              5006396.7427290436
            ],
            [
              357671.68441678095,
              5006400.8640437294
            ],
            [
              357673.27098575339,
              5006404.4673121991
            ],
            [
              357674.21257724747,
              5006412.6783188526
            ],
            [
              357672.21142699319,
              5006418.6269070813
            ],
            [
              357672.30498007493,
              5006425.6413818216
            ],
            [
              357673.85555663868,
              5006429.0805634717
            ],
            [
              357680.0485511974,
              5006433.1500538699
            ],
            [
              357698.87622437137,
              5006439.4139208812
            ],
            [
              357723.83311602526,
              5006445.5505353222
            ],
            [
              357728.69142503059,
              5006436.2401686925
            ],
            [
              357739.63196308556,
              5006438.3417407395
            ],
            [
              357756.67927860894,
              5006441.9013242843
            ],
            [
              357755.89357180049,
              5006444.7447942421
            ],
            [
              357757.31227771053,
              5006447.7568382323
            ],
            [
              357757.36486317514,
              5006450.5847198516
            ],
            [
              357755.63607768557,
              5006453.4457267206
            ],
            [
              357757.39835772914,
              5006458.0229859063
            ],
            [
              357770.3072365516,
              5006470.146102217
            ],
            [
              357772.6689666984,
              5006473.1406100402
            ],
            [
              357777.73566962325,
              5006475.0370583693
            ],
            [
              357786.06295894826,
              5006492.4507296523
            ],
            [
              357790.15580648551,
              5006498.3466533897
            ],
            [
              357808.81622478022,
              5006481.5503440127
            ],
            [
              357805.23655270529,
              5006479.148449257
            ],
            [
              357800.83266855741,
              5006473.1255051987
            ],
            [
              357795.44448517327,
              5006462.9959674692
            ],
            [
              357799.83857502212,
              5006454.9609935079
            ],
            [
              357815.07376519841,
              5006439.5803207904
            ],
            [
              357823.22960917122,
              5006446.8132869713
            ],
            [
              357832.74173075915,
              5006458.873783933
            ],
            [
              357841.35608830734,
              5006448.4423219291
            ],
            [
              357839.06418511918,
              5006442.5636062268
            ],
            [
              357839.22373249714,
              5006439.9635328436
            ],
            [
              357847.70033182343,
              5006431.9107836531
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 433,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359498.01650820492,
              5004945.9390856521
            ],
            [
              359490.85633011622,
              5004951.7073219903
            ],
            [
              359486.52369138767,
              5004957.0020075049
            ],
            [
              359483.58176892757,
              5004960.597173322
            ],
            [
              359498.34826346632,
              5004973.458100806
            ],
            [
              359496.73235010606,
              5004976.2246286534
            ],
            [
              359507.90055870498,
              5004985.1360136308
            ],
            [
              359520.8548803677,
              5004996.3722058227
            ],
            [
              359527.77460389613,
              5005002.0248046294
            ],
            [
              359532.04084988387,
              5005005.132291249
            ],
            [
              359541.80466232001,
              5005011.6520335143
            ],
            [
              359547.77234156604,
              5005002.7149782646
            ],
            [
              359557.5453358824,
              5005007.8877518699
            ],
            [
              359558.75605146762,
              5005005.6003299141
            ],
            [
              359563.17855468969,
              5004999.5668262001
            ],
            [
              359565.56421442481,
              5004992.9372945027
            ],
            [
              359567.04201008292,
              5004988.830634811
            ],
            [
              359567.94319527462,
              5004982.5335270008
            ],
            [
              359567.75996149873,
              5004972.6775091449
            ],
            [
              359576.56654316868,
              5004975.6667690855
            ],
            [
              359579.43341549474,
              5004975.5449236529
            ],
            [
              359581.36466074933,
              5004980.3437639177
            ],
            [
              359594.42771831999,
              5005005.1272715917
            ],
            [
              359588.86799457547,
              5005010.5359878782
            ],
            [
              359594.42803704808,
              5005016.3995386511
            ],
            [
              359592.15667373146,
              5005023.1702711536
            ],
            [
              359590.48648245778,
              5005025.8014881676
            ],
            [
              359583.50124931952,
              5005021.3675419232
            ],
            [
              359581.46414602484,
              5005025.8111973349
            ],
            [
              359580.91814052087,
              5005029.4464048436
            ],
            [
              359588.10888255871,
              5005029.5223915
            ],
            [
              359586.57756249432,
              5005037.5484206742
            ],
            [
              359594.93125379103,
              5005040.0807020962
            ],
            [
              359584.00897904875,
              5005063.5215377072
            ],
            [
              359595.76152640878,
              5005067.3407178866
            ],
            [
              359609.28803218011,
              5005079.9106956553
            ],
            [
              359618.73815385654,
              5005084.9477760633
            ],
            [
              359633.2746036647,
              5005096.7879413189
            ],
            [
              359637.12047679111,
              5005092.8320597205
            ],
            [
              359604.20558461529,
              5005060.8324910356
            ],
            [
              359607.67603545438,
              5005058.9255247898
            ],
            [
              359640.45464035409,
              5005088.0396288792
            ],
            [
              359644.48145061382,
              5005080.5688470434
            ],
            [
              359655.8843290626,
              5005085.9153130911
            ],
            [
              359657.79406513594,
              5005082.5953590507
            ],
            [
              359687.24287469452,
              5005051.0182501916
            ],
            [
              359663.05244575866,
              5005012.2424209574
            ],
            [
              359651.58167585841,
              5005001.1947710421
            ],
            [
              359638.64077212912,
              5004991.8635473894
            ],
            [
              359628.22539050033,
              5004986.1184826214
            ],
            [
              359615.18800352089,
              5004969.7175347228
            ],
            [
              359606.46784027404,
              5004956.2021048274
            ],
            [
              359591.26252654655,
              5004929.510658321
            ],
            [
              359587.84156370797,
              5004931.0853709187
            ],
            [
              359585.15616100078,
              5004932.3214958673
            ],
            [
              359583.12747969315,
              5004933.255323628
            ],
            [
              359580.99660078663,
              5004934.2361942492
            ],
            [
              359579.04979536973,
              5004935.1323335124
            ],
            [
              359575.29911278485,
              5004946.1303008422
            ],
            [
              359564.12242193503,
              5004943.3905551285
            ],
            [
              359560.67010685196,
              5004953.2547474038
            ],
            [
              359552.2882134653,
              5004960.2435060237
            ],
            [
              359547.147708504,
              5004957.7264622776
            ],
            [
              359539.53111063608,
              5004948.7189855129
            ],
            [
              359536.36939493287,
              5004950.2391493069
            ],
            [
              359512.36087019468,
              5004949.3324027881
            ],
            [
              359504.92725105624,
              5004945.937493735
            ],
            [
              359501.91128575528,
              5004941.4832450543
            ],
            [
              359498.01650820492,
              5004945.9390856521
            ],
            [
              359490.85633011622,
              5004951.7073219903
            ],
            [
              359498.01650820492,
              5004945.9390856521
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 434,
        "Layer" : "C00006-GRADJ_PODR_NEIZGR-neuredjen",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355579.51157501183,
              5008414.4205143796
            ],
            [
              355584.10081496043,
              5008430.2869317951
            ],
            [
              355597.3766248108,
              5008417.2237664778
            ],
            [
              355605.29873377114,
              5008413.6291597234
            ],
            [
              355620.08699994505,
              5008399.5856719958
            ],
            [
              355624.44432880229,
              5008393.053202834
            ],
            [
              355630.1334849507,
              5008400.2600640813
            ],
            [
              355648.78398545511,
              5008385.5372052193
            ],
            [
              355654.74283081497,
              5008380.2881546021
            ],
            [
              355649.01513524953,
              5008372.468631112
            ],
            [
              355644.73183382984,
              5008366.6032311488
            ],
            [
              355637.03828676691,
              5008372.2215656694
            ],
            [
              355633.65998723666,
              5008367.5954429787
            ],
            [
              355627.47816048044,
              5008372.1098198304
            ],
            [
              355622.00206979999,
              5008362.7628497165
            ],
            [
              355644.28311375825,
              5008349.0589242857
            ],
            [
              355638.46185436862,
              5008341.405851542
            ],
            [
              355660.30628913949,
              5008319.2705550641
            ],
            [
              355664.92903651716,
              5008300.8774683662
            ],
            [
              355661.73739256687,
              5008272.7061899472
            ],
            [
              355665.50014891813,
              5008268.2639811598
            ],
            [
              355666.41573019995,
              5008259.2431290559
            ],
            [
              355673.68670723675,
              5008251.0441739149
            ],
            [
              355679.93598896003,
              5008239.9612996606
            ],
            [
              355687.30660892889,
              5008230.2111733053
            ],
            [
              355697.58692240948,
              5008222.8647917099
            ],
            [
              355703.96119404741,
              5008217.1549673602
            ],
            [
              355682.89900059253,
              5008198.6819517054
            ],
            [
              355674.45215883077,
              5008208.9133744901
            ],
            [
              355659.01619389531,
              5008227.2400378138
            ],
            [
              355636.14944677171,
              5008252.3179243477
            ],
            [
              355614.63266243757,
              5008274.1034656325
            ],
            [
              355588.38593358663,
              5008298.8977218708
            ],
            [
              355570.47777457151,
              5008314.5689266659
            ],
            [
              355551.46500590857,
              5008330.266435639
            ],
            [
              355539.52973387373,
              5008339.6557797752
            ],
            [
              355520.06754094135,
              5008354.2169397986
            ],
            [
              355495.76517483662,
              5008372.5371146584
            ],
            [
              355474.40365632682,
              5008390.3807733925
            ],
            [
              355478.16118769482,
              5008410.2530120686
            ],
            [
              355478.21221509611,
              5008416.7894782806
            ],
            [
              355479.5017600953,
              5008422.1194306388
            ],
            [
              355485.36225078895,
              5008429.6420564903
            ],
            [
              355499.13786254876,
              5008436.4130877443
            ],
            [
              355507.37163372885,
              5008442.1121555185
            ],
            [
              355520.79770185571,
              5008446.4360409472
            ],
            [
              355545.42808569223,
              5008422.5545989266
            ],
            [
              355570.78747173928,
              5008407.9359030807
            ],
            [
              355575.89127937256,
              5008415.5139876409
            ],
            [
              355579.51157501183,
              5008414.4205143796
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 435,
        "Layer" : "B02209B-POLJOPRIVREDA_03",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              362348.13748516317,
              5002085.2620614367
            ],
            [
              362379.21618607541,
              5002042.6133896569
            ],
            [
              362409.71506384754,
              5001999.54817387
            ],
            [
              362439.628536448,
              5001956.0742963832
            ],
            [
              362468.95112898661,
              5001912.199714289
            ],
            [
              362497.67747472558,
              5001867.9324580245
            ],
            [
              362525.80231605459,
              5001823.2806298779
            ],
            [
              362538.70615578897,
              5001803.7845808472
            ],
            [
              362552.26424984139,
              5001784.7377162091
            ],
            [
              362566.46097605157,
              5001766.161982337
            ],
            [
              362581.27997640433,
              5001748.07878274
            ],
            [
              362596.70417588757,
              5001730.5089534149
            ],
            [
              362612.71580215025,
              5001713.4727388136
            ],
            [
              362629.29640600184,
              5001696.9897685423
            ],
            [
              362646.42688265198,
              5001681.0790347308
            ],
            [
              362664.08749373531,
              5001665.7588701518
            ],
            [
              362682.25789005106,
              5001651.0469270973
            ],
            [
              362700.91713501484,
              5001636.9601570331
            ],
            [
              362720.04372877604,
              5001623.5147910835
            ],
            [
              362739.61563299637,
              5001610.7263213042
            ],
            [
              362759.6102962463,
              5001598.6094828648
            ],
            [
              362780.00467998139,
              5001587.1782370377
            ],
            [
              362800.77528509678,
              5001576.4457551362
            ],
            [
              362821.89817899972,
              5001566.4244033257
            ],
            [
              362843.34902318852,
              5001557.1257283827
            ],
            [
              362817.40464204823,
              5001534.6500555547
            ],
            [
              362727.66867040476,
              5001389.2591441795
            ],
            [
              362685.79871537554,
              5001356.7739224462
            ],
            [
              362671.75189803005,
              5001361.6052279873
            ],
            [
              362532.49248803069,
              5001424.8361834977
            ],
            [
              362376.99338602304,
              5001500.4976201095
            ],
            [
              362339.29842640623,
              5001524.5891273394
            ],
            [
              362278.81720963278,
              5001582.0247275839
            ],
            [
              362248.26411673968,
              5001617.2458486073
            ],
            [
              362112.46593001735,
              5001823.7829025881
            ],
            [
              362035.98886199371,
              5001953.4217892895
            ],
            [
              362021.03795346251,
              5001979.8112460952
            ],
            [
              361982.38309763151,
              5002035.677689584
            ],
            [
              361998.24924856093,
              5002045.8333027074
            ],
            [
              362002.78199699335,
              5002049.2314803656
            ],
            [
              362006.84074100218,
              5002053.1836969312
            ],
            [
              362010.35824602249,
              5002057.6244824668
            ],
            [
              362013.27624331549,
              5002062.4802737264
            ],
            [
              362015.54639520438,
              5002067.6706327349
            ],
            [
              362017.13109580573,
              5002073.1095792996
            ],
            [
              362018.00409398793,
              5002078.7070152806
            ],
            [
              362018.15092822252,
              5002084.3702171138
            ],
            [
              362017.56916614826,
              5002090.0053717922
            ],
            [
              362016.2684448607,
              5002095.519130921
            ],
            [
              362014.27031127742,
              5002100.8201570697
            ],
            [
              362011.6078651959,
              5002105.8206368005
            ],
            [
              361862.80709647544,
              5002346.5405734936
            ],
            [
              361861.03454587888,
              5002359.0529427808
            ],
            [
              361859.98260832619,
              5002371.646383509
            ],
            [
              361859.65474758501,
              5002384.2794286581
            ],
            [
              361860.0520432216,
              5002396.910480815
            ],
            [
              361860.43897434132,
              5002398.489554693
            ],
            [
              361861.08290257136,
              5002399.9823863823
            ],
            [
              361861.96592559543,
              5002401.3474725923
            ],
            [
              361863.06349385623,
              5002402.5468615834
            ],
            [
              361864.34509306616,
              5002403.5472082756
            ],
            [
              361865.77509256284,
              5002404.3207013207
            ],
            [
              361867.31373589864,
              5002404.845836279
            ],
            [
              361868.9182461467,
              5002405.1080135023
            ],
            [
              361870.54401516315,
              5002405.0999440206
            ],
            [
              361872.14584377763,
              5002404.8218521783
            ],
            [
              361873.67919840507,
              5002404.2814694121
            ],
            [
              361875.10144915455,
              5002403.4938192917
            ],
            [
              361876.37305501808,
              5002402.4807998491
            ],
            [
              361877.45866316895,
              5002401.2705747625
            ],
            [
              361878.3280918416,
              5002399.8967903685
            ],
            [
              361878.95716942917,
              5002398.3976402311
            ],
            [
              361906.68220407557,
              5002343.4979485283
            ],
            [
              361954.55725432251,
              5002292.32384938
            ],
            [
              362132.43419726245,
              5002119.874210855
            ],
            [
              362138.17072183447,
              5002113.4723757925
            ],
            [
              362144.47458350845,
              5002107.6283590086
            ],
            [
              362151.29177649244,
              5002102.3922267007
            ],
            [
              362158.56389724597,
              5002097.8088372499
            ],
            [
              362166.22864482307,
              5002093.9174569324
            ],
            [
              362174.22035461845,
              5002090.7514234977
            ],
            [
              362182.47056091944,
              5002088.3378605936
            ],
            [
              362190.90858346003,
              5002086.6974453721
            ],
            [
              362199.46213294327,
              5002085.8442313522
            ],
            [
              362208.05793035816,
              5002085.7855280424
            ],
            [
              362216.62233475951,
              5002086.5218382925
            ],
            [
              362225.08197416103,
              5002088.0468540154
            ],
            [
              362233.36437411682,
              5002090.3475102112
            ],
            [
              362241.39857861859,
              5002093.4040969042
            ],
            [
              362270.10800582124,
              5002104.9307128312
            ],
            [
              362312.83088939154,
              5002113.5226777382
            ],
            [
              362348.13748516317,
              5002085.2620614367
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 436,
        "Layer" : "B02209B-POLJOPRIVREDA_03",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              361598.89079793898,
              5001442.499933376
            ],
            [
              361592.37818017986,
              5001446.7012121798
            ],
            [
              361585.20248045999,
              5001440.4959808318
            ],
            [
              361576.5895906139,
              5001442.9767078282
            ],
            [
              361557.44024026708,
              5001444.7042729594
            ],
            [
              361546.40524268948,
              5001444.851408747
            ],
            [
              361528.08408090594,
              5001440.4158178754
            ],
            [
              361507.31725726259,
              5001432.8264041543
            ],
            [
              361504.14407479268,
              5001426.0801533936
            ],
            [
              361496.16032194457,
              5001423.2105347374
            ],
            [
              361490.92087495694,
              5001426.2962586284
            ],
            [
              361489.42192903423,
              5001436.448353501
            ],
            [
              361494.33017118199,
              5001444.1414722214
            ],
            [
              361500.52219546429,
              5001446.6731932815
            ],
            [
              361510.99973862333,
              5001465.7447138336
            ],
            [
              361523.2954237443,
              5001468.9236425674
            ],
            [
              361526.20635526784,
              5001471.321902073
            ],
            [
              361517.80454415543,
              5001481.9375405917
            ],
            [
              361527.62418114865,
              5001489.827098107
            ],
            [
              361537.33657696331,
              5001478.1825375212
            ],
            [
              361547.3277515517,
              5001489.4905381277
            ],
            [
              361557.3122839572,
              5001502.3432177845
            ],
            [
              361585.41983958503,
              5001533.8634055294
            ],
            [
              361603.50612701668,
              5001552.4642411945
            ],
            [
              361609.77309127216,
              5001544.8083797796
            ],
            [
              361615.79644230247,
              5001548.3907652376
            ],
            [
              361641.74645987427,
              5001565.2492135493
            ],
            [
              361644.94449559686,
              5001566.9238614505
            ],
            [
              361657.76135916205,
              5001546.5360762188
            ],
            [
              361666.92850561783,
              5001532.6440600334
            ],
            [
              361684.54909190221,
              5001494.7510345411
            ],
            [
              361659.10080464859,
              5001488.6646109931
            ],
            [
              361644.51308804564,
              5001483.0487286728
            ],
            [
              361635.83198632993,
              5001478.460186258
            ],
            [
              361627.42014182144,
              5001472.1326108295
            ],
            [
              361612.30876154476,
              5001458.5357065303
            ],
            [
              361598.89079793898,
              5001442.499933376
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 437,
        "Layer" : "B02209B-POLJOPRIVREDA_03",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              361449.3738901043,
              5002172.7178475503
            ],
            [
              361415.82441959198,
              5002145.7684441926
            ],
            [
              361382.373010804,
              5002129.5202821773
            ],
            [
              361379.29454681632,
              5002127.1971577927
            ],
            [
              361376.26019157196,
              5002125.6468335781
            ],
            [
              361368.62703335873,
              5002129.7611929523
            ],
            [
              361258.90546984301,
              5001971.5574128591
            ],
            [
              361247.49125215731,
              5001942.7103694938
            ],
            [
              361242.45159853826,
              5001921.274100136
            ],
            [
              361239.46402405429,
              5001916.0071336757
            ],
            [
              361229.7287471926,
              5001902.8660856839
            ],
            [
              361215.5457005903,
              5001878.7577610323
            ],
            [
              361213.83422563801,
              5001880.5324066961
            ],
            [
              361212.93309501751,
              5001881.5874511357
            ],
            [
              361210.27763761213,
              5001885.0582951512
            ],
            [
              361207.90351936821,
              5001887.9318233319
            ],
            [
              361206.31393566594,
              5001889.4771303022
            ],
            [
              361205.26890803856,
              5001890.5606875336
            ],
            [
              361199.68640349444,
              5001897.0966887306
            ],
            [
              361198.92206726858,
              5001899.9216464171
            ],
            [
              361196.44147940009,
              5001907.9507332724
            ],
            [
              361194.50199171371,
              5001913.6169104194
            ],
            [
              361192.18425287172,
              5001919.5209299056
            ],
            [
              361190.83628867596,
              5001923.1837887838
            ],
            [
              361190.34385996818,
              5001923.9003100349
            ],
            [
              361189.64172167535,
              5001924.2165111434
            ],
            [
              361189.24288481678,
              5001924.5270762565
            ],
            [
              361188.95084599685,
              5001925.138810697
            ],
            [
              361188.96961658594,
              5001926.1489510862
            ],
            [
              361188.78424330457,
              5001927.0618573176
            ],
            [
              361188.1008762673,
              5001928.3882343611
            ],
            [
              361186.23426979966,
              5001931.353359947
            ],
            [
              361184.35445845855,
              5001933.6114311572
            ],
            [
              361181.86797732621,
              5001935.8807400987
            ],
            [
              361180.17159566836,
              5001937.1248780778
            ],
            [
              361179.27101039659,
              5001937.6468575317
            ],
            [
              361178.06518009899,
              5001938.0734459748
            ],
            [
              361176.95676319965,
              5001938.2961690752
            ],
            [
              361175.54303435696,
              5001938.4234668557
            ],
            [
              361174.12374110054,
              5001938.2477497058
            ],
            [
              361172.59959336265,
              5001937.8718902422
            ],
            [
              361170.97434447409,
              5001937.4978739079
            ],
            [
              361168.64125968301,
              5001937.1370107131
            ],
            [
              361167.22947504476,
              5001937.3653710568
            ],
            [
              361165.92066755379,
              5001937.6928454563
            ],
            [
              361164.71671453572,
              5001938.2204621742
            ],
            [
              361164.43698148004,
              5001938.3667486524
            ],
            [
              361162.82468506211,
              5001940.2544272142
            ],
            [
              361162.54861764441,
              5001941.2922535185
            ],
            [
              361162.3109485,
              5001944.8334534569
            ],
            [
              361162.10217791062,
              5001946.6271778997
            ],
            [
              361161.71648036607,
              5001947.6448342837
            ],
            [
              361161.23538057989,
              5001948.967452893
            ],
            [
              361160.54450425133,
              5001949.8897170387
            ],
            [
              361159.04844684334,
              5001951.0291050924
            ],
            [
              361157.54682369251,
              5001951.8654071372
            ],
            [
              361155.63885500043,
              5001952.6081968527
            ],
            [
              361153.7271329569,
              5001953.1490011178
            ],
            [
              361151.61884059961,
              5001953.996576461
            ],
            [
              361150.36834510689,
              5001954.8383255443
            ],
            [
              361148.20885611017,
              5001956.6689526923
            ],
            [
              361146.70858690504,
              5001958.3280672878
            ],
            [
              361145.58891883347,
              5001960.434084109
            ],
            [
              361145.18555165845,
              5001961.5938703064
            ],
            [
              361144.03791137651,
              5001965.1519442322
            ],
            [
              361142.81176294078,
              5001969.924128213
            ],
            [
              361141.31033669371,
              5001976.2171615195
            ],
            [
              361140.64454367995,
              5001980.0264579924
            ],
            [
              361140.08292534231,
              5001982.4621254373
            ],
            [
              361139.51192097721,
              5001984.3926870897
            ],
            [
              361138.74622434116,
              5001986.7310836492
            ],
            [
              361137.38136655482,
              5001989.4847955545
            ],
            [
              361135.90790024138,
              5001991.8363441676
            ],
            [
              361134.62537218462,
              5001993.5780368866
            ],
            [
              361132.92899050447,
              5001994.8221753202
            ],
            [
              361130.82257546711,
              5001995.7707792372
            ],
            [
              361128.51208208333,
              5001996.6221122686
            ],
            [
              361126.50489017559,
              5001997.4678092552
            ],
            [
              361124.50708432245,
              5001998.818612067
            ],
            [
              361122.61976370693,
              5002000.6725709643
            ],
            [
              361119.67872794182,
              5002004.0928513585
            ],
            [
              361118.24215125927,
              5002006.5192041881
            ],
            [
              361117.79483033513,
              5002007.7347345268
            ],
            [
              361116.96369831188,
              5002011.9942984255
            ],
            [
              361116.19687272998,
              5002015.1243361272
            ],
            [
              361115.62211446872,
              5002016.8528769556
            ],
            [
              361114.93686962273,
              5002018.0781906461
            ],
            [
              361113.75168808084,
              5002019.6159838634
            ],
            [
              361112.07032322354,
              5002021.6682420941
            ],
            [
              361110.98630844092,
              5002023.2041554125
            ],
            [
              361110.404041462,
              5002024.528618712
            ],
            [
              361109.83303794538,
              5002026.4592161374
            ],
            [
              361109.87245708599,
              5002028.5805256413
            ],
            [
              361111.04120702232,
              5002037.0470820442
            ],
            [
              361111.61435203865,
              5002040.6742432602
            ],
            [
              361111.7511177116,
              5002042.5916530378
            ],
            [
              361111.66878881032,
              5002043.6037078816
            ],
            [
              361111.28309149743,
              5002044.6213645218
            ],
            [
              361110.50050095952,
              5002046.0506135356
            ],
            [
              361108.81920249743,
              5002048.102870685
            ],
            [
              361107.29960519751,
              5002049.4539150177
            ],
            [
              361106.12113509345,
              5002049.870868044
            ],
            [
              361105.01077442319,
              5002049.9925645078
            ],
            [
              361103.89853638929,
              5002050.0132326949
            ],
            [
              361102.38752775913,
              5002050.3444649884
            ],
            [
              361100.67800592334,
              5002050.8814769452
            ],
            [
              361098.77198074356,
              5002051.7252943777
            ],
            [
              361097.37702391198,
              5002052.8628043756
            ],
            [
              361096.58880195272,
              5002053.9890041659
            ],
            [
              361096.20504826732,
              5002055.1076879101
            ],
            [
              361096.11139333475,
              5002057.2079019798
            ],
            [
              361096.45036011742,
              5002059.1215544259
            ],
            [
              361096.99722626148,
              5002061.3345330665
            ],
            [
              361097.13774612366,
              5002063.4539639363
            ],
            [
              361097.16214921809,
              5002064.7671894226
            ],
            [
              361096.88325014361,
              5002066.0860154955
            ],
            [
              361096.59302261117,
              5002066.7987797325
            ],
            [
              361096.09871604393,
              5002067.4142375691
            ],
            [
              361095.09515354183,
              5002067.8371034581
            ],
            [
              361094.18518274027,
              5002067.8540130584
            ],
            [
              361092.1592197952,
              5002067.6895698775
            ],
            [
              361090.12950210675,
              5002067.323070135
            ],
            [
              361088.4068406602,
              5002067.1529908245
            ],
            [
              361086.89207790844,
              5002067.2822021814
            ],
            [
              361084.97660027485,
              5002067.6209150953
            ],
            [
              361083.16410116554,
              5002068.0588131212
            ],
            [
              361082.26539320586,
              5002068.6818213528
            ],
            [
              361081.57264033513,
              5002069.5030932007
            ],
            [
              361080.60655424703,
              5002071.9462772785
            ],
            [
              361080.24532636872,
              5002074.2771583628
            ],
            [
              361080.3858463178,
              5002076.3965893313
            ],
            [
              361080.92144970741,
              5002078.0034694122
            ],
            [
              361081.65174464748,
              5002079.2024789322
            ],
            [
              361083.09356414375,
              5002080.5903928047
            ],
            [
              361083.92308354721,
              5002081.6865663873
            ],
            [
              361084.44554602762,
              5002082.5862839986
            ],
            [
              361084.76386519201,
              5002083.3888030555
            ],
            [
              361084.88561413018,
              5002084.4980933089
            ],
            [
              361084.31648762024,
              5002086.5296837203
            ],
            [
              361083.1557750621,
              5002089.3806660818
            ],
            [
              361082.36936430959,
              5002090.6078955121
            ],
            [
              361081.676611492,
              5002091.4291674076
            ],
            [
              361080.18061980471,
              5002092.5685195709
            ],
            [
              361078.16546720784,
              5002094.105704084
            ],
            [
              361076.9709003325,
              5002095.138427374
            ],
            [
              361075.88306476787,
              5002096.4722857382
            ],
            [
              361074.99749714124,
              5002097.8024209831
            ],
            [
              361073.71309202205,
              5002099.4430861203
            ],
            [
              361073.02033920825,
              5002100.2643580521
            ],
            [
              361072.02797335823,
              5002101.2933239434
            ],
            [
              361071.22848856641,
              5002101.8134252774
            ],
            [
              361070.52635019476,
              5002102.1296268553
            ],
            [
              361069.31676563161,
              5002102.3541949717
            ],
            [
              361068.30569425045,
              5002102.3729833821
            ],
            [
              361066.89015456138,
              5002102.3992878944
            ],
            [
              361065.67681589402,
              5002102.4218349718
            ],
            [
              361064.77053285606,
              5002102.6407669382
            ],
            [
              361063.56664665305,
              5002103.1684186943
            ],
            [
              361062.57615750021,
              5002104.298377376
            ],
            [
              361061.79544447683,
              5002105.8286550166
            ],
            [
              361061.42101023498,
              5002107.4524105415
            ],
            [
              361061.27512327326,
              5002110.4866257692
            ],
            [
              361061.22095193894,
              5002113.0139274402
            ],
            [
              361061.14237667236,
              5002114.227967957
            ],
            [
              361060.75480279344,
              5002115.1446320741
            ],
            [
              361059.86923519935,
              5002116.4747674242
            ],
            [
              361058.87311459915,
              5002117.3016768238
            ],
            [
              361057.77220563375,
              5002117.92844267
            ],
            [
              361056.36792886438,
              5002118.5608459199
            ],
            [
              361054.66403839045,
              5002119.4009076273
            ],
            [
              361052.76733245887,
              5002120.7497971728
            ],
            [
              361049.38402105455,
              5002123.7431450617
            ],
            [
              361044.80426537123,
              5002127.6681882152
            ],
            [
              361040.83680988353,
              5002131.884971696
            ],
            [
              361037.86540942785,
              5002135.2748825355
            ],
            [
              361034.65226211329,
              5002139.5612581251
            ],
            [
              361032.17892165919,
              5002142.5376965553
            ],
            [
              361030.39082511252,
              5002144.2887852443
            ],
            [
              361027.80887524772,
              5002146.8630248196
            ],
            [
              361023.73468842503,
              5002150.7786736349
            ],
            [
              361020.44309122983,
              5002153.2650373671
            ],
            [
              361017.34813047404,
              5002155.4446286159
            ],
            [
              361015.05071089807,
              5002157.0030912822
            ],
            [
              361013.15775902249,
              5002158.5540022627
            ],
            [
              361009.77444755909,
              5002161.5473508677
            ],
            [
              361007.18311201979,
              5002163.6165202707
            ],
            [
              361005.6965065627,
              5002165.2609789856
            ],
            [
              361001.72905108641,
              5002169.4777633818
            ],
            [
              360998.25777887221,
              5002173.1800824795
            ],
            [
              360995.37246179074,
              5002175.759995495
            ],
            [
              360993.37465522072,
              5002177.1107643293
            ],
            [
              360989.88836579118,
              5002180.0049637202
            ],
            [
              360985.55655261985,
              5002183.5131852413
            ],
            [
              360981.57595730986,
              5002187.0228785155
            ],
            [
              360979.78786072746,
              5002188.7739676945
            ],
            [
              360977.50927868782,
              5002191.3425705153
            ],
            [
              360974.0511469699,
              5002195.7520172251
            ],
            [
              360971.98596288153,
              5002198.9229626916
            ],
            [
              360970.90758000856,
              5002200.7619266035
            ],
            [
              360970.03133131238,
              5002202.5970987258
            ],
            [
              360968.95107104577,
              5002204.3350343434
            ],
            [
              360968.06543730991,
              5002205.6651715077
            ],
            [
              360967.18362332293,
              5002207.1972930124
            ],
            [
              360966.40478795109,
              5002208.8285996281
            ],
            [
              360964.93695397628,
              5002211.4831997603
            ],
            [
              360963.97462207143,
              5002214.1283706278
            ],
            [
              360962.41882813483,
              5002217.4919767082
            ],
            [
              360960.76186676783,
              5002220.8574272702
            ],
            [
              360959.48684821179,
              5002223.0031994684
            ],
            [
              360957.19264774298,
              5002226.2541895732
            ],
            [
              360955.90824346122,
              5002227.8948912891
            ],
            [
              360953.42739421362,
              5002230.4672532892
            ],
            [
              360951.54382874473,
              5002232.5232710224
            ],
            [
              360949.55916273169,
              5002234.5811675349
            ],
            [
              360947.78045198548,
              5002236.837327661
            ],
            [
              360946.60090244113,
              5002238.6781718107
            ],
            [
              360945.32588394423,
              5002240.8239441644
            ],
            [
              360943.45927886671,
              5002243.789073539
            ],
            [
              360941.39597245835,
              5002247.0610478986
            ],
            [
              360940.0123444263,
              5002248.8046213174
            ],
            [
              360938.62496221828,
              5002250.3461736338
            ],
            [
              360936.03738086164,
              5002252.6173652504
            ],
            [
              360933.84863635566,
              5002254.5779911717
            ],
            [
              360930.35483861127,
              5002257.0681496141
            ],
            [
              360925.96226591803,
              5002260.1812828174
            ],
            [
              360923.32225784706,
              5002262.1277572513
            ],
            [
              360921.82438927767,
              5002263.1661178907
            ],
            [
              360920.32269960258,
              5002264.0024231058
            ],
            [
              360918.42042814189,
              5002265.0482632918
            ],
            [
              360916.31582371664,
              5002266.0978990216
            ],
            [
              360913.40992320236,
              5002267.5666071605
            ],
            [
              360909.80751574266,
              5002269.6545668561
            ],
            [
              360907.00828111527,
              5002271.4244469814
            ],
            [
              360904.91125127731,
              5002272.8781238161
            ],
            [
              360903.31415886688,
              5002274.0193561269
            ],
            [
              360901.82755283144,
              5002275.6637802813
            ],
            [
              360899.85039584414,
              5002278.1257551489
            ],
            [
              360897.87317261199,
              5002280.5877312729
            ],
            [
              360896.10766818427,
              5002283.5509824986
            ],
            [
              360895.02546507487,
              5002285.1879271735
            ],
            [
              360894.14928288991,
              5002287.0230986848
            ],
            [
              360892.99038253905,
              5002289.9751128638
            ],
            [
              360892.12170944415,
              5002292.2143622134
            ],
            [
              360891.24921590439,
              5002294.2515916647
            ],
            [
              360889.88060606259,
              5002296.8033211641
            ],
            [
              360888.02332143154,
              5002300.273558707
            ],
            [
              360886.42482527159,
              5002303.1117410557
            ],
            [
              360885.44566581852,
              5002304.8477986334
            ],
            [
              360884.65550051478,
              5002305.8729725815
            ],
            [
              360883.46475407365,
              5002307.1077171033
            ],
            [
              360882.3656560813,
              5002307.8355134521
            ],
            [
              360881.2646806484,
              5002308.4622814637
            ],
            [
              360879.86040351231,
              5002309.0946858218
            ],
            [
              360878.26143366849,
              5002310.1348900376
            ],
            [
              360876.06511444022,
              5002311.6914755888
            ],
            [
              360873.36704657663,
              5002313.4594762167
            ],
            [
              360871.4797262905,
              5002315.3134381957
            ],
            [
              360869.59435035533,
              5002317.2684628414
            ],
            [
              360867.41492542956,
              5002319.7341614403
            ],
            [
              360865.43964600348,
              5002322.2971651526
            ],
            [
              360864.05226316577,
              5002323.8386825649
            ],
            [
              360862.46080240275,
              5002325.2829647511
            ],
            [
              360860.76254380593,
              5002326.4261129973
            ],
            [
              360859.15981970046,
              5002327.2642961945
            ],
            [
              360857.15074959834,
              5002328.0089674238
            ],
            [
              360855.37914954842,
              5002328.4866043683
            ],
            [
              360858.14558467729,
              5002333.7020889707
            ],
            [
              360860.52689236548,
              5002339.170977083
            ],
            [
              360890.31752355554,
              5002372.2457565963
            ],
            [
              360917.28353499959,
              5002402.0771350898
            ],
            [
              361020.68220283434,
              5002451.485504074
            ],
            [
              361042.6257482465,
              5002473.104385077
            ],
            [
              361026.63223853539,
              5002497.1918648975
            ],
            [
              361049.99811280431,
              5002508.0405832613
            ],
            [
              361072.54126098595,
              5002518.904536033
            ],
            [
              361088.31479940721,
              5002530.1695523066
            ],
            [
              361086.12291380047,
              5002532.4433655431
            ],
            [
              361110.01423130656,
              5002558.9137624875
            ],
            [
              361106.09878850396,
              5002562.7801217418
            ],
            [
              361140.5213677822,
              5002591.8077127691
            ],
            [
              361135.27809371409,
              5002600.7199233016
            ],
            [
              361156.63425626891,
              5002615.883472967
            ],
            [
              361174.83041231503,
              5002626.9457479836
            ],
            [
              361184.34971924248,
              5002632.9979396965
            ],
            [
              361201.59319201083,
              5002655.5223188214
            ],
            [
              361205.74846011511,
              5002665.8765079416
            ],
            [
              361211.58005877043,
              5002675.2548359791
            ],
            [
              361252.67933839367,
              5002644.490093518
            ],
            [
              361322.88358580001,
              5002591.9210766694
            ],
            [
              361393.13303393911,
              5002539.2227186989
            ],
            [
              361448.70804334542,
              5002498.2267267527
            ],
            [
              361456.94925908541,
              5002492.878892188
            ],
            [
              361465.58787310508,
              5002488.2000766452
            ],
            [
              361474.56968989247,
              5002484.219633244
            ],
            [
              361487.09186310676,
              5002480.021414076
            ],
            [
              361499.83433389087,
              5002476.5487649376
            ],
            [
              361512.75509849569,
              5002473.8131328458
            ],
            [
              361523.30752547999,
              5002472.3129695328
            ],
            [
              361533.931709508,
              5002471.4580356088
            ],
            [
              361544.58823499567,
              5002471.2515028063
            ],
            [
              361555.2375663789,
              5002471.6941373041
            ],
            [
              361595.84671526687,
              5002477.2920051524
            ],
            [
              361603.62439023092,
              5002440.1355467923
            ],
            [
              361600.37656088005,
              5002435.594591096
            ],
            [
              361595.95854292647,
              5002410.1235235911
            ],
            [
              361594.4327063915,
              5002389.1078634514
            ],
            [
              361580.26270197652,
              5002363.3414674569
            ],
            [
              361569.36191651126,
              5002339.34558021
            ],
            [
              361544.62544932571,
              5002298.9609638648
            ],
            [
              361537.63823537464,
              5002286.6207392672
            ],
            [
              361516.07964277803,
              5002257.7536201943
            ],
            [
              361488.84523836843,
              5002217.148100541
            ],
            [
              361478.18094826414,
              5002205.9341781959
            ],
            [
              361464.24315663154,
              5002188.7761964686
            ],
            [
              361449.3738901043,
              5002172.7178475503
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 438,
        "Layer" : "C00006-GRADJ_PODR_NEIZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              355682.8990006338,
              5008198.6819516551
            ],
            [
              355666.00531712803,
              5008219.1447978327
            ],
            [
              355659.0161939867,
              5008227.2400377104
            ],
            [
              355638.44900605932,
              5008249.9159057634
            ],
            [
              355628.17824000627,
              5008260.6442969255
            ],
            [
              355621.10296587407,
              5008267.8196206111
            ],
            [
              355602.1294829938,
              5008286.246334496
            ],
            [
              355588.38593360683,
              5008298.897721855
            ],
            [
              355573.39014600671,
              5008312.0805568434
            ],
            [
              355557.6129475538,
              5008325.2922614049
            ],
            [
              355539.52973394078,
              5008339.6557797231
            ],
            [
              355520.06754094135,
              5008354.2169397986
            ],
            [
              355510.49101403839,
              5008361.3420241019
            ],
            [
              355501.52398370317,
              5008368.1241932977
            ],
            [
              355495.76517497544,
              5008372.5371145504
            ],
            [
              355480.94393208373,
              5008384.1045593014
            ],
            [
              355474.40365632682,
              5008390.3807733925
            ],
            [
              355478.16118769487,
              5008410.2530120714
            ],
            [
              355478.21221509611,
              5008416.7894782806
            ],
            [
              355479.5017600953,
              5008422.1194306388
            ],
            [
              355485.3622507882,
              5008429.6420564884
            ],
            [
              355499.13786254398,
              5008436.4130877443
            ],
            [
              355507.37163373089,
              5008442.1121555204
            ],
            [
              355520.36553941434,
              5008445.8646806106
            ],
            [
              355533.00075354573,
              5008462.5696488274
            ],
            [
              355555.51417644561,
              5008443.2120324466
            ],
            [
              355584.1862542489,
              5008429.7033309871
            ],
            [
              355597.33873128117,
              5008417.2596179862
            ],
            [
              355605.29873377114,
              5008413.6291597234
            ],
            [
              355620.086999939,
              5008399.5856720069
            ],
            [
              355624.44432880502,
              5008393.0532028321
            ],
            [
              355629.97559829924,
              5008400.0600577658
            ],
            [
              355648.78398550645,
              5008385.5372051802
            ],
            [
              355660.7016761009,
              5008375.0391042056
            ],
            [
              355660.51207549981,
              5008370.3947565565
            ],
            [
              355682.57679732022,
              5008349.779388058
            ],
            [
              355686.71724656556,
              5008345.7525267703
            ],
            [
              355698.41166394448,
              5008329.9989034506
            ],
            [
              355696.57019347028,
              5008327.9598111706
            ],
            [
              355704.25148288795,
              5008313.4558833316
            ],
            [
              355694.78119458025,
              5008304.7873654813
            ],
            [
              355699.28104103025,
              5008299.1340518231
            ],
            [
              355708.18659889576,
              5008289.7312238067
            ],
            [
              355731.93183683004,
              5008277.1620440241
            ],
            [
              355738.72895889013,
              5008268.7944815401
            ],
            [
              355697.21183935978,
              5008222.4460606026
            ],
            [
              355703.96119404741,
              5008217.1549673602
            ],
            [
              355682.8990006338,
              5008198.6819516551
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 439,
        "Layer" : "C00005-GRADJ_PODR_IZGRADJENO",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              359933.97282061836,
              5003626.7626057938
            ],
            [
              359958.01590821106,
              5003647.9452254903
            ],
            [
              359983.9784396569,
              5003621.4270936437
            ],
            [
              359977.41832636466,
              5003613.5381197538
            ],
            [
              359961.24899511196,
              5003606.2282754816
            ],
            [
              359933.97282061836,
              5003626.7626057938
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 440,
        "Layer" : "B02209B-POLJOPRIVREDA_03",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              361936.19867816503,
              5001701.9041056829
            ],
            [
              361943.89268669015,
              5001717.2846866241
            ],
            [
              361952.92159080721,
              5001730.6085839048
            ],
            [
              361980.88607253792,
              5001744.9109864263
            ],
            [
              361998.69583567942,
              5001750.2320272215
            ],
            [
              362010.07949063333,
              5001753.5898341164
            ],
            [
              362005.65986750525,
              5001766.0186111564
            ],
            [
              362021.5249111483,
              5001773.5538454102
            ],
            [
              362040.23415933416,
              5001785.2321745856
            ],
            [
              362034.43186322675,
              5001793.3344055889
            ],
            [
              362047.3034680924,
              5001800.4595633596
            ],
            [
              362048.06931818771,
              5001799.5117491074
            ],
            [
              362069.11147184443,
              5001813.9507779814
            ],
            [
              362083.12785811705,
              5001823.5059663327
            ],
            [
              362084.78393702541,
              5001820.1422242774
            ],
            [
              362077.51300862944,
              5001811.690849592
            ],
            [
              362086.65133930702,
              5001791.422692175
            ],
            [
              362097.61634371156,
              5001757.6145270783
            ],
            [
              362103.13551571977,
              5001744.8559985785
            ],
            [
              362086.25252140168,
              5001732.4070949545
            ],
            [
              362063.44896358397,
              5001717.2947332328
            ],
            [
              362045.39386575698,
              5001705.0688006766
            ],
            [
              362031.21985666262,
              5001697.9398864703
            ],
            [
              362029.87099564669,
              5001699.9499168545
            ],
            [
              362017.01637672784,
              5001719.1054681158
            ],
            [
              361992.79717106133,
              5001692.511290906
            ],
            [
              361975.12471200002,
              5001682.102576931
            ],
            [
              361960.27832935454,
              5001689.9512347896
            ],
            [
              361944.57524105592,
              5001698.2527973559
            ],
            [
              361939.40903631662,
              5001700.5047241338
            ],
            [
              361936.19867816503,
              5001701.9041056829
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 441,
        "Layer" : "PLOVNIPUT",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              362113.40127740806,
              5000652.768838278
            ],
            [
              362200.84341052687,
              5000549.1224082625
            ],
            [
              362076.25612718792,
              5000579.6525349217
            ],
            [
              362113.40127740806,
              5000652.768838278
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 442,
        "Layer" : "PLOVNIPUT",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              350926.49647258804,
              5011860.9810549775
            ],
            [
              350902.90901908214,
              5011970.5263967719
            ],
            [
              351000.16920138063,
              5011899.7271835022
            ],
            [
              350926.49647258804,
              5011860.9810549775
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 443,
        "Layer" : "B03---BLOKOVI",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357040.42345056665,
              5005598.0513437074
            ],
            [
              357394.00790837005,
              5005111.9170002975
            ],
            [
              357070.71697067498,
              5004876.7743948549
            ],
            [
              356717.15205387113,
              5005362.881176943
            ],
            [
              357040.42345056665,
              5005598.0513437074
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 444,
        "Layer" : "B03---BLOKOVI",
        "DocPath" : "F:\\E_investicije\\Crikvenica\\112.  ppu grada crikvenice\\2_GRAFICKI DIO\\II_KARTA1.dwg"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357075.3552308717,
              5005256.8934938125
            ],
            [
              357075.44734057115,
              5005257.7603809526
            ],
            [
              357075.68858429609,
              5005258.598103446
            ],
            [
              357076.07163197803,
              5005259.3812075192
            ],
            [
              357076.5848449042,
              5005260.0858989498
            ],
            [
              357077.21262936055,
              5005260.6907660449
            ],
            [
              357077.9359104308,
              5005261.1774302255
            ],
            [
              357078.73271158576,
              5005261.5311044436
            ],
            [
              357079.57882242516,
              5005261.741042492
            ],
            [
              357080.44853429758,
              5005261.8008655058
            ],
            [
              357081.31542144838,
              5005261.7087557977
            ],
            [
              357082.15314395062,
              5005261.4675120749
            ],
            [
              357082.93624802987,
              5005261.0844644057
            ],
            [
              357083.64093946642,
              5005260.5712515032
            ],
            [
              357084.24580656871,
              5005259.9434670769
            ],
            [
              357084.73247075634,
              5005259.2201860454
            ],
            [
              357085.08614498627,
              5005258.42338493
            ],
            [
              357085.29608304647,
              5005257.5772741307
            ],
            [
              357085.35590607562,
              5005256.7075623013
            ],
            [
              357085.26379638037,
              5005255.8406751845
            ],
            [
              357085.02255266876,
              5005255.0029527117
            ],
            [
              357084.63950500713,
              5005254.2198486533
            ],
            [
              357084.12629210489,
              5005253.5151572293
            ],
            [
              357083.49850767403,
              5005252.9102901332
            ],
            [
              357082.77522662759,
              5005252.4236259451
            ],
            [
              357081.97842549079,
              5005252.0699517084
            ],
            [
              357081.13231466297,
              5005251.8600136396
            ],
            [
              357080.26260279416,
              5005251.8001905987
            ],
            [
              357079.39571563766,
              5005251.8923002826
            ],
            [
              357078.55799312197,
              5005252.1335439831
            ],
            [
              357077.77488902252,
              5005252.5165916374
            ],
            [
              357077.07019756269,
              5005253.0298045343
            ],
            [
              357076.46533043467,
              5005253.6575889597
            ],
            [
              357075.97866622382,
              5005254.3808700023
            ],
            [
              357075.62499197491,
              5005255.1776711335
            ],
            [
              357075.41505390342,
              5005256.0237819524
            ],
            [
              357075.3552308717,
              5005256.8934938125
            ],
            [
              357075.35523087048,
              5005256.8934938125
            ]
          ]
        ]
      }
    }
  ]
};