import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileWMS from 'ol/source/TileWMS';
import XYZ from 'ol/source/XYZ';
import EsriJSON from 'ol/format/EsriJSON';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {Vector as VectorSource} from 'ol/source';

import {defaults as defaultControls, OverviewMap, FullScreen, MousePosition} from 'ol/control';
import Projection from 'ol/proj/Projection';
import {Fill, Stroke, Style} from 'ol/style';
import {register} from 'ol/proj/proj4';
import proj4 from 'proj4';
import {toLonLat} from 'ol/proj';
import {createStringXY} from 'ol/coordinate';

// var wmsSource = new TileWMS({
//   url: 'https://geoportal.dgu.hr/services/inspire/orthophoto_2018/wms?',
//   params: {'LAYERS': 'OI.OrthoImagery'},
// });

// var wmsSource = new TileWMS({
//     url: 'https://oss.uredjenazemlja.hr/OssWebServices/inspireService/wms',
//     params: {'LAYERS': 'CP.CadastralParcel', 'token': 'b776470d4b33ed929e27e688ea48729cce403725cdc6605da48bc051fc2582d7'},
//   });

//   var wmsSource = new TileWMS({
//     url: 'http://gis.pgz.hr/ArcGIS/services/MGIPU/WMS_PPUOG_01/MapServer/WMSServer',
//     params: {'LAYERS': 'HR-ISPU-PPGO-00531-R07-KN-1-1/HR-ISPU-PPGO-00531-R07-IS-1-1/HR-ISPU-PPGO-00531-R07-IS-3-1/HR-ISPU-PPGO-00531-R07-IS-4-1/HR-ISPU-PPGO-00531-R07-IS-4-2/HR-ISPU-PPGO-00531-R07-ZP-1-1/HR-ISPU-PPGO-00531-R07-ZP-2-1/HR-ISPU-PPGO-00531-R07-ZP-2-4/Crikvenica_Maska', 'token': 'b776470d4b33ed929e27e688ea48729cce403725cdc6605da48bc051fc2582d7'},
//   });

// var Layer1 = new TileLayer({
//   source: wmsSource
// });

var styleArray = [
    {name: 'plan_naziv', value: 'PPUG CRIKVENICA - IV. ID', fill: 'red', stroke: '#E600A9'},
    {name: 'PLAN_NAZIV', value: 'PPUG Crikvenica - ID', fill: '#C9F2D0', stroke: '#6E6E6E'},
    {name: 'Layer', value: '0', fill: '#F0A999', stroke: '#6E6E6E'},
    {name: 'Layer', value: 'AGEO_Spojnica', fill: '#B09F41', stroke: '#6E6E6E'},
    {name: 'Layer', value: 'AUTOCESTA', fill: '#94C458', stroke: '#6E6E6E'},
    {name: 'Layer', value: 'B00011-OBUHVAT_PROSTORNOG_PLANA', fill: null, stroke: null},
    {name: 'Layer', value: 'B02208B-POLJOPRIVREDA_02', fill: '#775AFA', stroke: '#6E6E6E'},
    {name: 'Layer', value: 'B02209B-POLJOPRIVREDA_03', fill: '#EBF05B', stroke: '#6E6E6E'},
    {name: 'Layer', value: 'B02210A-SUME_01', fill: '#006600', stroke: '#6E6E6E'},
    {name: 'Layer', value: 'B02211A-SUME_02', fill: '#33990F', stroke: '#6E6E6E'},
    {name: 'Layer', value: 'B02212A-SUME_03', fill: '#88D888', stroke: '#6E6E6E'},
    {name: 'Layer', value: 'B03---BLOKOVI', fill: null, stroke: null},
    {name: 'Layer', value: 'B08220A-OBALNO_PODRUCJE', fill: null, stroke: null},
    {name: 'Layer', value: 'C00005-GRADJ_PODR_IZGRADJENO', fill: '#FFF901', stroke: '#6E6E6E'},
    {name: 'Layer', value: 'C00006-GRADJ_PODR_NEIZGRADJENO', fill: '#FFAA00', stroke: '#6E6E6E'},
    {name: 'Layer', value: 'C00006-GRADJ_PODR_NEIZGR-neuredjen', fill: '#FFEBAF', stroke: '#6E6E6E'},
    {name: 'Layer', value: 'DRZAVNE', fill: '#C2A0FA', stroke: '#6E6E6E'},
    {name: 'Layer', value: 'Javne funkcije', fill: null, stroke: null},
    {name: 'Layer', value: 'GRANICA_NA', fill: '#86EBB8', stroke: '#6E6E6E'},
    {name: 'Layer', value: 'LOKALNE', fill: '#F081E1', stroke: '#6E6E6E'},
    {name: 'Layer', value: 'MORE', fill: '#BEE8FF', stroke: '#6E6E6E'},
    {name: 'Layer', value: 'NATEXT', fill: '#63854D', stroke: '#6E6E6E'},
    {name: 'Layer', value: 'PLOVNIPUT', fill: '#83FA75', stroke: '#6E6E6E'},
    {name: 'Layer', value: 'STARO_GP', fill: null, stroke: '#6E6E6E'},
    // {name: '', value: '', fill: '', stroke: '#6E6E6E'},
]

function styleFunction(feature, resolution) {
    var stroke = '#CCCCCC';
    var fill = '#BBBBBB';
    styleArray.forEach(element => {
        if (feature.get(element.name) == element.value){
            stroke = element.stroke;
            fill = element.fill;
        }
    });
    if (stroke == '#CCCCCC' && fill == '#BBBBBB') console.log(feature, feature.get('Layer'));

    if (fill != null && stroke != null)
    return [
        new Style({
            stroke: new Stroke({
                color: stroke,
                width: 1
            }),
            fill: new Fill({
                color: fill
            })
        })
    ];
    if (fill != null && stroke == null)
    return [
        new Style({
            fill: new Fill({
                color: fill
            })
        })
    ];
    if (fill == null && stroke != null)
    return [
        new Style({
            stroke: new Stroke({
                color: stroke,
                width: 1
            })
        })
    ];
    if (fill == null && stroke == null)
    return [];
}

// Katastar
proj4.defs("EPSG:3768", "+proj=utm +zone=34 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
// Crikvenica
proj4.defs("EPSG:3768X", "+proj=tmerc +lat_0=0 +lon_0=16.5 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
register(proj4);
// var Extent = [-100000, 4650000, 380000, 5200000]; // HRVATSKA
var extent = [-11000, 5010000, 21000, 5035000]; // CRIKVENICA

var KatastarLayer = new TileLayer({
    source: new TileWMS({
        url: 'https://oss.uredjenazemlja.hr/OssWebServices/inspireService/wms',
        params: {
            'LAYERS': 'CP.CadastralParcel',
            'token': 'b776470d4b33ed929e27e688ea48729cce403725cdc6605da48bc051fc2582d7'
        },
    })
});

var OrthoImageryLayer = new TileLayer({
    source: new TileWMS({
        url: 'https://geoportal.dgu.hr/services/inspire/orthophoto_2018/wms?authKey=665d24c6-e79c-4835-9b69-713e4e814d96',
        params: {'LAYERS': 'OI.OrthoImagery'},
    })
});


var OSMLayer = new TileLayer({
    source: new XYZ({
        url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    })
})

var OSMLayer2 = new TileLayer({
    source: new XYZ({
        url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    })
})

var projection = new Projection({
  code: 'EPSG:3768',
  extent: extent
});

var mapView = new View({
    projection: projection,
    center: [10000, 5023000],// fromLonLat([8.23, 46.86], projection),
    extent: extent,
    zoom: 1
});

var overviewView = new View({projection: projection});

var overview = new OverviewMap({
    layers: [OSMLayer2],
    view: overviewView
});

var mousePositionControl = new MousePosition({
    coordinateFormat: createStringXY(4),
    projection: 'EPSG:4326',
    className: 'custom-mouse-position',
    target: document.getElementById('LatLng'),
    undefinedHTML: '&nbsp;'
});

var map = new Map({
    controls: defaultControls().extend([
        overview,
        mousePositionControl,
        new FullScreen()
    ]),
    layers: [OSMLayer],
    target: 'map',
    view: mapView
});

import data1 from './II_KARTA1_dwg_Polygon_HTRS.json';
var dataLayer1 = new VectorLayer({
    source: new VectorSource({
        features: (new EsriJSON()).readFeatures(data1, {
            dataProjection: "EPSG:3768X",
            featureProjection: "EPSG:3768"
        }),
    }),
    style: styleFunction
});

import data2 from './PPZ_2015_PŠ_Polygon_HTRS.json';
var dataLayer2 = new VectorLayer({
    source: new VectorSource({
        features: (new EsriJSON()).readFeatures(data2, {
            dataProjection: "EPSG:3768X",
            featureProjection: "EPSG:3768"
        })
    }),
    style: styleFunction
});

import data3 from './GPIN_Crikvenica_Turizam.json';
import { Feature } from 'ol';
var dataLayer3 = new VectorLayer({
    name: 'data',
    source: new VectorSource({
        features: (new EsriJSON()).readFeatures(data3, {
            dataProjection: "EPSG:3768X",
            featureProjection: "EPSG:3768"
        })
    }),
    style: styleFunction
});

map.addLayer(OrthoImageryLayer);
map.addLayer(dataLayer1);
map.addLayer(dataLayer2);
map.addLayer(dataLayer3);
OrthoImageryLayer.setVisible(false);

map.addLayer(KatastarLayer);

window.map = map;
window.OSMLayer = OSMLayer;
window.dataLayer1 = dataLayer1;
window.dataLayer2 = dataLayer2;
window.dataLayer3 = dataLayer3;
window.KatastarLayer = KatastarLayer;
window.OrthoImageryLayer = OrthoImageryLayer;

window.Togglelayer = function Togglelayer(n){
    if (n == 1){
        window.OSMLayer.setVisible(!window.OSMLayer.getVisible());
    }
    if (n == 2){
        window.dataLayer1.setVisible(!window.dataLayer1.getVisible());
        window.dataLayer2.setVisible(!window.dataLayer2.getVisible());
    }
    if (n == 3){
        window.dataLayer3.setVisible(!window.dataLayer3.getVisible());
    }
    if (n == 4){
        window.KatastarLayer.setVisible(!window.KatastarLayer.getVisible());
    }
    if (n == 5){
        window.OrthoImageryLayer.setVisible(!window.OrthoImageryLayer.getVisible());
    }
}


var lastFeature = null;

window.infoBoxHide = function infoBoxHide(){
    $('#infoBox').hide();
    if (lastFeature != null) lastFeature.setStyle(styleFunction);
}

map.on('singleclick', function (evt) {
    infoBoxHide();
    map.forEachFeatureAtPixel(evt.pixel, function(feature){
        if (feature.get('plan_naziv') == "PPUG CRIKVENICA - IV. ID"){
            featureClick(feature);
        }
    })
});

window.featureFromId = function featureFromId(id){
    var feature = null;
    map.getLayers().forEach(x=> {if (x.get('name')=='data'){x.getSource().getFeatures().forEach(y=> {if (y.get('FID') == id) feature = y})}});
    return feature;
}

window.featureClick = function featureClick(feature){
    infoBoxHide();
    lastFeature = feature;
    feature.setStyle([
        new Style({
            stroke: new Stroke({
                color: 'black',
                width: 1
            }),
            fill: new Fill({
                color: '#F4516C'
            })
        })
    ]);
    $('#infoBoxTable').html(
                "<tr><td>zup_rb</td><td>" + feature.get('zup_rb') + "</td></tr>" +
                "<tr><td>jls_mb</td><td>" + feature.get('jls_mb') + "</td></tr>" +
                "<tr><td>jls_st</td><td>" + feature.get('jls_st') + "</td></tr>" +
                "<tr><td>jls_ime</td><td>" + feature.get('jls_ime') + "</td></tr>" +
                "<tr><td>plan_naziv</td><td>" + feature.get('plan_naziv') + "</td></tr>" +
                "<tr><td>ozn_ispu</td><td>" + feature.get('ozn_ispu') + "</td></tr>" +
                "<tr><td>izvor</td><td>" + feature.get('izvor') + "</td></tr>" +
                "<tr><td>mj_izvora</td><td>" + feature.get('mj_izvora') + "</td></tr>" +
                "<tr><td>namjena</td><td>" + feature.get('namjena') + "</td></tr>" +
                "<tr><td>ozn_namjen</td><td>" + feature.get('ozn_namjen') + "</td></tr>" +
                "<tr><td>naz_vl</td><td>" + feature.get('naz_vl') + "</td></tr>" +
                "<tr><td>az_oznaka</td><td>" + feature.get('az_oznaka') + "</td></tr>" +
                "<tr><td>obveza_UPU</td><td>" + feature.get('obveza_UPU') + "</td></tr>" +
                "<tr><td>pov</td><td>" + feature.get('pov') + "</td></tr>" +
                "<tr><td>shape_area</td><td>" + feature.get('shape_area') + "</td></tr>" +
                "<tr><td>shape_leng</td><td>" + feature.get('shape_leng') + "</td></tr>" +
                "<tr><td>max_kap</td><td>" + feature.get('max_kap') + "</td></tr>"
    );
    $('#infoBox').show();
}

window.featureZoom = function featureZoom(Feature){
        var extent = this.array_[0].getGeometry().getExtent();
        map.getView().fitExtent(extent,map.getSize());
}
var stringList = "";
data3.features.forEach(element => {
    stringList +=  `<li class="main_list_item">
                        <span class="list_item_with_btns">
                            <span class="list_item_with_btns_text">` + element.attributes.naz_vl + `</span>
                            <div class="filter_list_btns">
                                <button class="list_btn">
                                    <i id="info_close" class="las la-map-marked-alt" onclick="map.getView().fit(featureFromId(` + element.attributes.FID + `).getGeometry())"></i>
                                </button>
                                <button class="list_btn">
                                    <i id="info_close" class="las la-info-circle" onclick="featureClick(featureFromId(` + element.attributes.FID + `))"></i>
                                </button>
                            </div>
                        </span>
                    </li>`;
});
$('#lista').html(stringList);
