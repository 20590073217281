module.exports = {
  "displayFieldName" : "",
  "fieldAliases" : {
    "FID" : "FID",
    "zup_rb" : "zup_rb",
    "jls_mb" : "jls_mb",
    "jls_st" : "jls_st",
    "jls_ime" : "jls_ime",
    "plan_naziv" : "plan_naziv",
    "ozn_ispu" : "ozn_ispu",
    "izvor" : "izvor",
    "mj_izvora" : "mj_izvora",
    "namjena" : "namjena",
    "ozn_namjen" : "ozn_namjen",
    "naz_vl" : "naz_vl",
    "az_oznaka" : "az_oznaka",
    "pov" : "pov",
    "shape_area" : "shape_area",
    "shape_leng" : "shape_leng",
    "obveza_UPU" : "obveza_UPU",
    "odr_link" : "odr_link",
    "max_kap" : "max_kap",
    "url" : "url"
  },
  "geometryType" : "esriGeometryPolygon",
  "spatialReference" : {
    "wkid" : 3765,
    "latestWkid" : 3765
  },
  "fields" : [
    {
      "name" : "FID",
      "type" : "esriFieldTypeOID",
      "alias" : "FID"
    },
    {
      "name" : "zup_rb",
      "type" : "esriFieldTypeString",
      "alias" : "zup_rb",
      "length" : 254
    },
    {
      "name" : "jls_mb",
      "type" : "esriFieldTypeString",
      "alias" : "jls_mb",
      "length" : 254
    },
    {
      "name" : "jls_st",
      "type" : "esriFieldTypeString",
      "alias" : "jls_st",
      "length" : 254
    },
    {
      "name" : "jls_ime",
      "type" : "esriFieldTypeString",
      "alias" : "jls_ime",
      "length" : 254
    },
    {
      "name" : "plan_naziv",
      "type" : "esriFieldTypeString",
      "alias" : "plan_naziv",
      "length" : 254
    },
    {
      "name" : "ozn_ispu",
      "type" : "esriFieldTypeString",
      "alias" : "ozn_ispu",
      "length" : 254
    },
    {
      "name" : "izvor",
      "type" : "esriFieldTypeString",
      "alias" : "izvor",
      "length" : 254
    },
    {
      "name" : "mj_izvora",
      "type" : "esriFieldTypeString",
      "alias" : "mj_izvora",
      "length" : 254
    },
    {
      "name" : "namjena",
      "type" : "esriFieldTypeString",
      "alias" : "namjena",
      "length" : 254
    },
    {
      "name" : "ozn_namjen",
      "type" : "esriFieldTypeString",
      "alias" : "ozn_namjen",
      "length" : 254
    },
    {
      "name" : "naz_vl",
      "type" : "esriFieldTypeString",
      "alias" : "naz_vl",
      "length" : 254
    },
    {
      "name" : "az_oznaka",
      "type" : "esriFieldTypeString",
      "alias" : "az_oznaka",
      "length" : 254
    },
    {
      "name" : "pov",
      "type" : "esriFieldTypeDouble",
      "alias" : "pov"
    },
    {
      "name" : "shape_area",
      "type" : "esriFieldTypeDouble",
      "alias" : "shape_area"
    },
    {
      "name" : "shape_leng",
      "type" : "esriFieldTypeDouble",
      "alias" : "shape_leng"
    },
    {
      "name" : "obveza_UPU",
      "type" : "esriFieldTypeString",
      "alias" : "obveza_UPU",
      "length" : 50
    },
    {
      "name" : "odr_link",
      "type" : "esriFieldTypeString",
      "alias" : "odr_link",
      "length" : 150
    },
    {
      "name" : "max_kap",
      "type" : "esriFieldTypeSmallInteger",
      "alias" : "max_kap"
    },
    {
      "name" : "url",
      "type" : "esriFieldTypeString",
      "alias" : "url",
      "length" : 150
    }
  ],
  "features" : [
    {
      "attributes" : {
        "FID" : 0,
        "zup_rb" : "00008",
        "jls_mb" : "00531",
        "jls_st" : "GR",
        "jls_ime" : "CRIKVENICA",
        "plan_naziv" : "PPUG CRIKVENICA - IV. ID",
        "ozn_ispu" : "HR-ISPU-PPGO-00531-R07",
        "izvor" : "1.",
        "mj_izvora" : "1:25.000",
        "namjena" : "GOSPODARSKA - UGOSTITELJSKO TURISTIÈKA (KAMP)",
        "ozn_namjen" : "T3",
        "naz_vl" : "UVALA SLANA (T3-3)",
        "az_oznaka" : "T",
        "pov" : 130353.2,
        "shape_area" : 130412.08,
        "shape_leng" : 2159.2399999999998,
        "obveza_UPU" : "UPU na snazi",
        "odr_link" : "F:\\E_investicije\\Crikvenica\\Crikvenica_UPU_T1_T3/UPU_Uvala_Slana_T1_T3_KN.jpg",
        "max_kap" : 1500,
        "url" : "https://oss.uredjenazemlja.hr/public/cadServices.jsp?action=dkpViewerPublic"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360453.07500000001,
              5003078.2599999998
            ],
            [
              360460.18099999998,
              5003071.8289999999
            ],
            [
              360463.48300000001,
              5003074.7860000003
            ],
            [
              360503.55099999998,
              5003038.0700000003
            ],
            [
              360539.201,
              5003015.8540000003
            ],
            [
              360560.179,
              5003004.3159999996
            ],
            [
              360573.89399999997,
              5002994.6689999998
            ],
            [
              360581.61499999999,
              5002989.6900000004
            ],
            [
              360581.69199999998,
              5002986.7920000004
            ],
            [
              360580.239,
              5002984.9019999998
            ],
            [
              360589.70600000001,
              5002978.1279999996
            ],
            [
              360596.74200000003,
              5002970.3969999999
            ],
            [
              360606.304,
              5002963.9460000005
            ],
            [
              360609.80900000001,
              5002955.0429999996
            ],
            [
              360609.91200000001,
              5002948.3289999999
            ],
            [
              360608.47200000001,
              5002940.9800000004
            ],
            [
              360605.174,
              5002937.6260000002
            ],
            [
              360602.48200000002,
              5002935.7970000003
            ],
            [
              360595.21000000002,
              5002933.7199999997
            ],
            [
              360581.19500000001,
              5002931.1310000001
            ],
            [
              360576.15899999999,
              5002930.3739999998
            ],
            [
              360564.16999999998,
              5002926.5140000004
            ],
            [
              360541.91100000002,
              5002916.9460000005
            ],
            [
              360514.02000000002,
              5002904.3370000003
            ],
            [
              360496.19900000002,
              5002895.0580000002
            ],
            [
              360485.26500000001,
              5002881.3849999998
            ],
            [
              360481.04499999998,
              5002878.6390000004
            ],
            [
              360461.30499999999,
              5002866.6469999999
            ],
            [
              360443.97700000001,
              5002856.0329999998
            ],
            [
              360422.66800000001,
              5002844.3130000001
            ],
            [
              360407.29300000001,
              5002833.4230000004
            ],
            [
              360390.70500000002,
              5002821.3729999997
            ],
            [
              360371.98599999998,
              5002808.5609999998
            ],
            [
              360346.30200000003,
              5002795.4570000004
            ],
            [
              360291.60399999999,
              5002761.6890000002
            ],
            [
              360272.94099999999,
              5002751.8279999997
            ],
            [
              360247.57799999998,
              5002736.4060000004
            ],
            [
              360256.21299999999,
              5002727.216
            ],
            [
              360235.13099999999,
              5002707.1330000004
            ],
            [
              360202.77100000001,
              5002718.4950000001
            ],
            [
              360200.37300000002,
              5002709.9359999998
            ],
            [
              360189.69400000002,
              5002712.6370000001
            ],
            [
              360184.266,
              5002714.4560000002
            ],
            [
              360181.85800000001,
              5002715.5109999999
            ],
            [
              360175.95600000001,
              5002719.057
            ],
            [
              360174.45299999998,
              5002719.7920000004
            ],
            [
              360173.05200000003,
              5002720.6270000003
            ],
            [
              360167.43099999998,
              5002722.9539999999
            ],
            [
              360165.43300000002,
              5002724.3049999997
            ],
            [
              360164.04599999997,
              5002725.8470000001
            ],
            [
              360163.55900000001,
              5002726.8660000004
            ],
            [
              360163.47600000002,
              5002727.8779999996
            ],
            [
              360134.71999999997,
              5002739.7750000004
            ],
            [
              360135.989,
              5002742.7829999998
            ],
            [
              360158.87199999997,
              5002733.1619999995
            ],
            [
              360160.18699999998,
              5002733.1370000001
            ],
            [
              360161.60999999999,
              5002733.5149999997
            ],
            [
              360163.24599999998,
              5002734.4950000001
            ],
            [
              360164.78899999999,
              5002735.8810000001
            ],
            [
              360168.098,
              5002739.7599999998
            ],
            [
              360173.00400000002,
              5002747.9550000001
            ],
            [
              360176.848,
              5002753.4409999996
            ],
            [
              360185.174,
              5002762.7359999996
            ],
            [
              360188.98999999999,
              5002766.7070000004
            ],
            [
              360191.45600000001,
              5002768.7829999998
            ],
            [
              360194.53100000002,
              5002770.9479999999
            ],
            [
              360183.87300000002,
              5002785.2939999998
            ],
            [
              360187.26199999999,
              5002788.0599999996
            ],
            [
              360197.92800000001,
              5002774.1189999999
            ],
            [
              360205.60600000003,
              5002779.1289999997
            ],
            [
              360212.66399999999,
              5002783.3430000003
            ],
            [
              360214.51199999999,
              5002784.824
            ],
            [
              360217.71500000003,
              5002788.5039999997
            ],
            [
              360221.598,
              5002794.0480000004
            ],
            [
              360222.03100000002,
              5002795.5559999999
            ],
            [
              360222.10700000002,
              5002799.6979999999
            ],
            [
              360229.94900000002,
              5002791.6699999999
            ],
            [
              360232.21899999998,
              5002794.0530000003
            ],
            [
              360223.67499999999,
              5002802.3969999999
            ],
            [
              360227.10100000002,
              5002801.727
            ],
            [
              360227.70799999998,
              5002801.7149999999
            ],
            [
              360228.72600000002,
              5002802.1009999998
            ],
            [
              360230.37,
              5002803.4850000003
            ],
            [
              360234.19,
              5002807.6579999998
            ],
            [
              360238.978,
              5002814.9450000003
            ],
            [
              360240.65700000001,
              5002818.2489999998
            ],
            [
              360242.91800000001,
              5002824.3459999999
            ],
            [
              360243.08799999999,
              5002828.0820000004
            ],
            [
              360241.79599999997,
              5002834.7750000004
            ],
            [
              360239.98700000002,
              5002840.8720000004
            ],
            [
              360197.74699999997,
              5002834.1739999996
            ],
            [
              360197.12400000001,
              5002838.733
            ],
            [
              360236.79100000003,
              5002845.1689999998
            ],
            [
              360235.81,
              5002846.8049999997
            ],
            [
              360228.886,
              5002855.2189999996
            ],
            [
              360222.42599999998,
              5002861.2709999997
            ],
            [
              360210.76000000001,
              5002870.3810000001
            ],
            [
              360205.47100000002,
              5002874.2180000003
            ],
            [
              360200.66800000001,
              5002877.0360000003
            ],
            [
              360193.34499999997,
              5002880.3049999997
            ],
            [
              360185.85499999998,
              5002882.7070000004
            ],
            [
              360182.12900000002,
              5002883.585
            ],
            [
              360173.65700000001,
              5002884.8540000003
            ],
            [
              360163.96999999997,
              5002886.1459999997
            ],
            [
              360158.71999999997,
              5002886.648
            ],
            [
              360156.60600000003,
              5002887.193
            ],
            [
              360154.402,
              5002888.3449999997
            ],
            [
              360152.20600000001,
              5002889.9019999998
            ],
            [
              360149.12599999999,
              5002892.8899999997
            ],
            [
              360142.59999999998,
              5002900.9939999999
            ],
            [
              360140.94099999999,
              5002898.8020000001
            ],
            [
              360123.91100000002,
              5002900.1339999996
            ],
            [
              360122.89799999999,
              5002905.5080000004
            ],
            [
              360108.38400000002,
              5002908.3039999995
            ],
            [
              360098.70400000003,
              5002893.5290000001
            ],
            [
              360088.81699999998,
              5002894.926
            ],
            [
              360089.08000000002,
              5002898.1550000003
            ],
            [
              360094.43300000002,
              5002897.7520000003
            ],
            [
              360098.55800000002,
              5002907.477
            ],
            [
              360079.777,
              5002912.0990000004
            ],
            [
              360079.07299999997,
              5002912.3140000002
            ],
            [
              360078.16499999998,
              5002912.432
            ],
            [
              360073.40500000003,
              5002912.1169999996
            ],
            [
              360071.98999999999,
              5002912.1430000002
            ],
            [
              360068.44199999998,
              5002911.7039999999
            ],
            [
              360067.02600000001,
              5002911.7300000004
            ],
            [
              360065.70799999998,
              5002911.5530000003
            ],
            [
              360058.27100000001,
              5002908.6600000001
            ],
            [
              360054.19799999997,
              5002907.2199999997
            ],
            [
              360052.26699999999,
              5002906.75
            ],
            [
              360046.38799999998,
              5002906.051
            ],
            [
              360033.87,
              5002905.1770000001
            ],
            [
              360031.24300000002,
              5002905.3269999996
            ],
            [
              360026.00799999997,
              5002906.6370000001
            ],
            [
              360024.20500000002,
              5002907.5800000001
            ],
            [
              360023.51199999999,
              5002908.4009999996
            ],
            [
              360023.23300000001,
              5002909.7199999997
            ],
            [
              360023.25,
              5002910.6289999997
            ],
            [
              360023.76799999998,
              5002911.3269999996
            ],
            [
              360024.89399999997,
              5002912.0130000003
            ],
            [
              360027.23599999998,
              5002912.8789999997
            ],
            [
              360029.68900000001,
              5002914.2479999997
            ],
            [
              360030.20799999998,
              5002914.9460000005
            ],
            [
              360030.45500000002,
              5002917.3669999996
            ],
            [
              360029.88199999998,
              5002919.1960000005
            ],
            [
              360028.91399999999,
              5002921.5379999997
            ],
            [
              360027.44,
              5002923.8899999997
            ],
            [
              360025.549,
              5002925.5420000004
            ],
            [
              360023.44799999997,
              5002926.7929999996
            ],
            [
              360021.636,
              5002927.2309999997
            ],
            [
              360019.41100000002,
              5002927.2719999999
            ],
            [
              360017.18099999998,
              5002927.0109999999
            ],
            [
              360015.24900000001,
              5002926.4400000004
            ],
            [
              360013.71299999999,
              5002925.4579999996
            ],
            [
              360011.95699999999,
              5002923.4699999997
            ],
            [
              360011.62900000002,
              5002922.1629999997
            ],
            [
              360011.43300000002,
              5002917.0130000003
            ],
            [
              360011.21000000002,
              5002915.9050000003
            ],
            [
              360010.58299999998,
              5002914.8049999997
            ],
            [
              360009.25099999999,
              5002913.9210000001
            ],
            [
              360008.23700000002,
              5002913.7379999999
            ],
            [
              360005.91499999998,
              5002913.983
            ],
            [
              360004.10600000003,
              5002914.6229999997
            ],
            [
              359992.57699999999,
              5002920.193
            ],
            [
              359989.36200000002,
              5002921.3640000001
            ],
            [
              359976.36700000003,
              5002926.4890000001
            ],
            [
              359985.01000000001,
              5002956.9249999998
            ],
            [
              359989.49599999998,
              5002971.5839999998
            ],
            [
              360003.28399999999,
              5002966.4960000003
            ],
            [
              360009.79200000002,
              5002976.534
            ],
            [
              360023.533,
              5002968.2510000002
            ],
            [
              360032.94699999999,
              5002974.642
            ],
            [
              360039.18099999998,
              5002982.2359999996
            ],
            [
              360050.03700000001,
              5002992.8119999999
            ],
            [
              360051.52100000001,
              5002999.3509999998
            ],
            [
              360063.77000000002,
              5003011.5109999999
            ],
            [
              360076.55499999999,
              5003019.2019999996
            ],
            [
              360082.08799999999,
              5003021.5070000002
            ],
            [
              360105.64899999998,
              5003035.6330000004
            ],
            [
              360105.78899999999,
              5003035.7180000003
            ],
            [
              360109.701,
              5003037.4800000004
            ],
            [
              360119.12599999999,
              5003044.5010000002
            ],
            [
              360127.22700000001,
              5003037.8289999999
            ],
            [
              360143.75199999998,
              5003024.3720000004
            ],
            [
              360186.76500000001,
              5003059.4009999996
            ],
            [
              360227.03399999999,
              5003093.1950000003
            ],
            [
              360269.21799999999,
              5003128.9740000004
            ],
            [
              360283.13400000002,
              5003139.5159999998
            ],
            [
              360306.72100000002,
              5003158.5939999996
            ],
            [
              360348.84100000001,
              5003189.7649999997
            ],
            [
              360354.44,
              5003193.5810000002
            ],
            [
              360361.364,
              5003197.4390000002
            ],
            [
              360367.60600000003,
              5003188.1320000002
            ],
            [
              360380.22499999998,
              5003168.4280000003
            ],
            [
              360392.26699999999,
              5003146.5599999996
            ],
            [
              360404.76199999999,
              5003129.7560000001
            ],
            [
              360423.70500000002,
              5003104.1119999997
            ],
            [
              360437.41200000001,
              5003092.1069999998
            ],
            [
              360453.07500000001,
              5003078.2599999998
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 1,
        "zup_rb" : "00008",
        "jls_mb" : "00531",
        "jls_st" : "GR",
        "jls_ime" : "CRIKVENICA",
        "plan_naziv" : "PPUG CRIKVENICA - IV. ID",
        "ozn_ispu" : "HR-ISPU-PPGO-00531-R07",
        "izvor" : "1.",
        "mj_izvora" : "1:25.000",
        "namjena" : "GOSPODARSKA - UGOSTITELJSKO TURISTIÈKA (KAMP)",
        "ozn_namjen" : "T3",
        "naz_vl" : "JASENOVA (T3-4)",
        "az_oznaka" : "T",
        "pov" : 157068.5,
        "shape_area" : 157140.81,
        "shape_leng" : 2017.1199999999999,
        "obveza_UPU" : "obveza izrade UPU",
        "odr_link" : "F:\\E_investicije\\Crikvenica\\Crikvenica_ISPU_SHP/UPU Jasenova.pdf",
        "max_kap" : 1800,
        "url" : "https://oss.uredjenazemlja.hr/public/cadServices.jsp?action=publicCadastreParcel&institutionID=83&cadastreMunicipalityId=2398&possessionSheetNr=367"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360865.50199999998,
              5002686.1780000003
            ],
            [
              360884.51299999998,
              5002668.7989999996
            ],
            [
              360908.62800000003,
              5002684.3099999996
            ],
            [
              360919.83799999999,
              5002690.4720000001
            ],
            [
              360945.55900000001,
              5002700.773
            ],
            [
              360979.40100000001,
              5002714.608
            ],
            [
              360995.50599999999,
              5002721.6770000001
            ],
            [
              361018.72399999999,
              5002724.8320000004
            ],
            [
              361005.42999999999,
              5002751.5530000003
            ],
            [
              361015.84999999998,
              5002753.9519999996
            ],
            [
              361037.571,
              5002769.3770000003
            ],
            [
              361048.83299999998,
              5002758.2510000002
            ],
            [
              361093.55699999997,
              5002776.0329999998
            ],
            [
              361114.32799999998,
              5002747.8320000004
            ],
            [
              361122.47200000001,
              5002737.9819999998
            ],
            [
              361134.35600000003,
              5002724.6179999998
            ],
            [
              361150.902,
              5002707.7400000002
            ],
            [
              361166.19,
              5002693.6900000004
            ],
            [
              361180.40000000002,
              5002682.3190000001
            ],
            [
              361195.696,
              5002670.3619999997
            ],
            [
              361191.54100000003,
              5002660.0080000004
            ],
            [
              361174.29800000001,
              5002637.4840000002
            ],
            [
              361164.77899999998,
              5002631.432
            ],
            [
              361146.58399999997,
              5002620.3700000001
            ],
            [
              361125.228,
              5002605.2079999996
            ],
            [
              361130.47100000002,
              5002596.2949999999
            ],
            [
              361096.049,
              5002567.2690000003
            ],
            [
              361099.96399999998,
              5002563.4019999998
            ],
            [
              361076.07299999997,
              5002536.932
            ],
            [
              361078.26500000001,
              5002534.6579999998
            ],
            [
              361062.49099999998,
              5002523.3940000003
            ],
            [
              361039.94799999997,
              5002512.5310000004
            ],
            [
              361016.58199999999,
              5002501.682
            ],
            [
              361032.576,
              5002477.5939999996
            ],
            [
              361010.63199999998,
              5002455.9759999998
            ],
            [
              360907.23300000001,
              5002406.5700000003
            ],
            [
              360880.26699999999,
              5002376.7400000002
            ],
            [
              360781.66700000002,
              5002425.5159999998
            ],
            [
              360745.43099999998,
              5002494.4819999998
            ],
            [
              360723.04800000001,
              5002481.8650000002
            ],
            [
              360703.88400000002,
              5002513.2369999997
            ],
            [
              360692.75900000002,
              5002501.2929999996
            ],
            [
              360677.96399999998,
              5002514.4709999999
            ],
            [
              360677.68300000002,
              5002522.7189999996
            ],
            [
              360672.59000000003,
              5002537.318
            ],
            [
              360663.87699999998,
              5002536.9519999996
            ],
            [
              360653.07500000001,
              5002537.7690000003
            ],
            [
              360640.217,
              5002540.2450000001
            ],
            [
              360641.70500000002,
              5002548.341
            ],
            [
              360624.25599999999,
              5002551.4129999997
            ],
            [
              360623.85600000003,
              5002549.4009999996
            ],
            [
              360609.43099999998,
              5002550.9639999997
            ],
            [
              360604.891,
              5002539.5829999996
            ],
            [
              360576.20000000001,
              5002536.7259999998
            ],
            [
              360580.19400000002,
              5002552.0010000002
            ],
            [
              360580.61200000002,
              5002556.9479999999
            ],
            [
              360567.141,
              5002558.699
            ],
            [
              360567.80300000001,
              5002562.7290000003
            ],
            [
              360553.91899999999,
              5002565.0130000003
            ],
            [
              360553.533,
              5002561.9000000004
            ],
            [
              360538.93300000002,
              5002567.5089999996
            ],
            [
              360539.04999999999,
              5002569.4529999997
            ],
            [
              360541.99599999998,
              5002618.4050000003
            ],
            [
              360544.375,
              5002630.125
            ],
            [
              360540.36300000001,
              5002633.9610000001
            ],
            [
              360553.81599999999,
              5002651.3569999998
            ],
            [
              360577.45799999998,
              5002681.9380000001
            ],
            [
              360601.91600000003,
              5002713.1320000002
            ],
            [
              360599.16600000003,
              5002715.9840000002
            ],
            [
              360608.29700000002,
              5002724.7769999998
            ],
            [
              360584.72600000002,
              5002744.557
            ],
            [
              360600.00400000002,
              5002753.6160000004
            ],
            [
              360621.92200000002,
              5002768.5669999998
            ],
            [
              360638.20699999999,
              5002782.0219999999
            ],
            [
              360684.59999999998,
              5002831.4630000005
            ],
            [
              360721.42700000003,
              5002793.7209999999
            ],
            [
              360728.967,
              5002788.6490000002
            ],
            [
              360748.59700000001,
              5002781.7450000001
            ],
            [
              360764.51799999998,
              5002776.7960000001
            ],
            [
              360779.64600000001,
              5002768.1179999998
            ],
            [
              360799.68099999998,
              5002753.5899999999
            ],
            [
              360805.83799999999,
              5002749.2879999997
            ],
            [
              360811.64000000001,
              5002742.8159999996
            ],
            [
              360820.38,
              5002735.1320000002
            ],
            [
              360832.47100000002,
              5002721.0829999996
            ],
            [
              360846.005,
              5002707.0439999998
            ],
            [
              360852.71299999999,
              5002700.9349999996
            ],
            [
              360865.50199999998,
              5002686.1780000003
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 2,
        "zup_rb" : "00008",
        "jls_mb" : "00531",
        "jls_st" : "GR",
        "jls_ime" : "CRIKVENICA",
        "plan_naziv" : "PPUG CRIKVENICA - IV. ID",
        "ozn_ispu" : "HR-ISPU-PPGO-00531-R07",
        "izvor" : "1.",
        "mj_izvora" : "1:25.000",
        "namjena" : "GOSPODARSKA - UGOSTITELJSKO TURISTIÈKA (HOTEL)",
        "ozn_namjen" : "T1",
        "naz_vl" : "JADRANOVO SJEVER (T1-9)",
        "az_oznaka" : "T",
        "pov" : 15862.4,
        "shape_area" : 15869,
        "shape_leng" : 547.73000000000002,
        "obveza_UPU" : "obveza izrade UPU",
        "odr_link" : "F:\\E_investicije\\Crikvenica\\Crikvenica_ISPU_SHP/UPU Jadranovo_sjever.pdf",
        "max_kap" : 240,
        "url" : "https://oss.uredjenazemlja.hr/public/cadServices.jsp?action=dkpViewerPublic"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352350.81900000002,
              5012529.8059999999
            ],
            [
              352329.96799999999,
              5012519.3200000003
            ],
            [
              352312.21399999998,
              5012505.5130000003
            ],
            [
              352297.42099999997,
              5012521.608
            ],
            [
              352291.36599999998,
              5012517.0700000003
            ],
            [
              352281.31099999999,
              5012516.3890000004
            ],
            [
              352268.85100000002,
              5012509.4460000005
            ],
            [
              352255.641,
              5012511.716
            ],
            [
              352251.50699999998,
              5012519.307
            ],
            [
              352250.15299999999,
              5012520.5939999996
            ],
            [
              352212.31300000002,
              5012548.2039999999
            ],
            [
              352197.13099999999,
              5012561.341
            ],
            [
              352202.97899999999,
              5012583.1670000004
            ],
            [
              352208.37199999997,
              5012602.5499999998
            ],
            [
              352214.20400000003,
              5012623.784
            ],
            [
              352216.55900000001,
              5012632.4000000004
            ],
            [
              352221.13199999998,
              5012648.5190000003
            ],
            [
              352222.04599999997,
              5012651.0080000004
            ],
            [
              352233.72999999998,
              5012669.0619999999
            ],
            [
              352235.11499999999,
              5012670.8119999999
            ],
            [
              352255.64299999998,
              5012685.5360000003
            ],
            [
              352254.21799999999,
              5012687.8959999997
            ],
            [
              352271.62400000001,
              5012701.2309999997
            ],
            [
              352273.51500000001,
              5012694.8289999999
            ],
            [
              352277.95899999997,
              5012683.3949999996
            ],
            [
              352285.61900000001,
              5012661.0789999999
            ],
            [
              352294.25,
              5012637.3169999998
            ],
            [
              352314.603,
              5012581.2640000004
            ],
            [
              352321.37099999998,
              5012569.898
            ],
            [
              352331.429,
              5012558.8689999999
            ],
            [
              352350.81900000002,
              5012529.8059999999
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 3,
        "zup_rb" : "00008",
        "jls_mb" : "00531",
        "jls_st" : "GR",
        "jls_ime" : "CRIKVENICA",
        "plan_naziv" : "PPUG CRIKVENICA - IV. ID",
        "ozn_ispu" : "HR-ISPU-PPGO-00531-R07",
        "izvor" : "1.",
        "mj_izvora" : "1:25.000",
        "namjena" : "GOSPODARSKA - UGOSTITELJSKO TURISTIÈKA (HOTEL)",
        "ozn_namjen" : "T1",
        "naz_vl" : "HAVIŠĆE II (T1-2)",
        "az_oznaka" : "T",
        "pov" : 89603,
        "shape_area" : 89646.210000000006,
        "shape_leng" : 1336.71,
        "obveza_UPU" : "obveza izrade UPU",
        "odr_link" : "F:\\E_investicije\\Crikvenica\\Crikvenica_ISPU_SHP/UPU Havišæe II.pdf",
        "max_kap" : 600,
        "url" : "https://oss.uredjenazemlja.hr/public/cadServices.jsp?action=dkpViewerPublic"
      },
      "geometry" : {
        "rings" : [
          [
            [
              352296.25099999999,
              5010903.0460000001
            ],
            [
              352291.04700000002,
              5010900.6909999996
            ],
            [
              352293.68099999998,
              5010879.6579999998
            ],
            [
              352284.63,
              5010871.6919999998
            ],
            [
              352284.81699999998,
              5010837.9759999998
            ],
            [
              352288.57400000002,
              5010813.5930000003
            ],
            [
              352288.35999999999,
              5010803.8150000004
            ],
            [
              352286.13400000002,
              5010794.8779999996
            ],
            [
              352294.52000000002,
              5010765.0219999999
            ],
            [
              352295.364,
              5010756.4819999998
            ],
            [
              352300.78000000003,
              5010739.8839999996
            ],
            [
              352305.27899999998,
              5010745.9479999999
            ],
            [
              352316.08299999998,
              5010750.4409999996
            ],
            [
              352320.07799999998,
              5010743.7019999996
            ],
            [
              352316.58500000002,
              5010738.2470000004
            ],
            [
              352322.90600000002,
              5010730.324
            ],
            [
              352317.23100000003,
              5010726.3289999999
            ],
            [
              352328.90500000003,
              5010716.216
            ],
            [
              352336.783,
              5010702.9009999996
            ],
            [
              352292.011,
              5010676.034
            ],
            [
              352287.49699999997,
              5010671.1560000004
            ],
            [
              352282.51699999999,
              5010661.54
            ],
            [
              352275.886,
              5010654.4460000005
            ],
            [
              352270.31900000002,
              5010662.625
            ],
            [
              352254.53200000001,
              5010657.142
            ],
            [
              352249.35600000003,
              5010664.193
            ],
            [
              352243.288,
              5010667.7240000004
            ],
            [
              352238.66999999998,
              5010660.3839999996
            ],
            [
              352225.27000000002,
              5010683.8849999998
            ],
            [
              352215.06300000002,
              5010674.0549999997
            ],
            [
              352211.99400000001,
              5010680.2410000004
            ],
            [
              352208.29499999998,
              5010684.318
            ],
            [
              352191.30800000002,
              5010696.7740000002
            ],
            [
              352188.85200000001,
              5010697.8810000001
            ],
            [
              352184.18199999997,
              5010698.7599999998
            ],
            [
              352172.745,
              5010684.3099999996
            ],
            [
              352133.54599999997,
              5010727.8569999998
            ],
            [
              352123.79499999998,
              5010742.926
            ],
            [
              352117.05900000001,
              5010744.0590000004
            ],
            [
              352116.60399999999,
              5010743.977
            ],
            [
              352109.15399999998,
              5010749.3859999999
            ],
            [
              352102.163,
              5010756.8820000002
            ],
            [
              352085.78399999999,
              5010775.0930000003
            ],
            [
              352069.14799999999,
              5010803.5070000002
            ],
            [
              352061.08500000002,
              5010797.5369999995
            ],
            [
              352050.31699999998,
              5010806.8329999996
            ],
            [
              352040.09100000001,
              5010817.3130000001
            ],
            [
              352017.46799999999,
              5010853.8039999995
            ],
            [
              352001.32000000001,
              5010883.5779999997
            ],
            [
              351993.42300000001,
              5010896.6600000001
            ],
            [
              352008.44699999999,
              5010940.3430000003
            ],
            [
              352014.89399999997,
              5010954.0719999997
            ],
            [
              352019.77399999998,
              5010957.8859999999
            ],
            [
              352019.91700000002,
              5010958.7560000001
            ],
            [
              352023.50400000002,
              5010980.6469999999
            ],
            [
              352033.11800000002,
              5011001.5439999998
            ],
            [
              352044.54499999998,
              5011018.0029999996
            ],
            [
              352059.424,
              5011034.2479999997
            ],
            [
              352064.41100000002,
              5011027.6969999997
            ],
            [
              352081.96600000001,
              5011042.023
            ],
            [
              352083.96500000003,
              5011043.6529999999
            ],
            [
              352107.80900000001,
              5011060.7230000002
            ],
            [
              352125.29499999998,
              5011066.1900000004
            ],
            [
              352138.47600000002,
              5011070.835
            ],
            [
              352157.34600000002,
              5011073.7939999998
            ],
            [
              352177.08100000001,
              5011073.1310000001
            ],
            [
              352189.75699999998,
              5011062.7410000004
            ],
            [
              352195.55300000001,
              5011054.7350000003
            ],
            [
              352196.71100000001,
              5011052.2319999998
            ],
            [
              352218.28899999999,
              5011032.9179999996
            ],
            [
              352229.76299999998,
              5011042.8590000002
            ],
            [
              352245.22899999999,
              5011045.0470000003
            ],
            [
              352253.35800000001,
              5011043.6639999999
            ],
            [
              352273.19,
              5011017.7719999999
            ],
            [
              352288.64600000001,
              5010992.2750000004
            ],
            [
              352292.304,
              5010995.2400000002
            ],
            [
              352297.14500000002,
              5010979.8150000004
            ],
            [
              352297.31900000002,
              5010966.5
            ],
            [
              352299.45400000003,
              5010942.0199999996
            ],
            [
              352297.42099999997,
              5010941.517
            ],
            [
              352286.61599999998,
              5010934.5319999997
            ],
            [
              352293.63199999998,
              5010919.3770000003
            ],
            [
              352290.44799999997,
              5010918.0199999996
            ],
            [
              352296.25099999999,
              5010903.0460000001
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 4,
        "zup_rb" : "00008",
        "jls_mb" : "00531",
        "jls_st" : "GR",
        "jls_ime" : "CRIKVENICA",
        "plan_naziv" : "PPUG CRIKVENICA - IV. ID",
        "ozn_ispu" : "HR-ISPU-PPGO-00531-R07",
        "izvor" : "1.",
        "mj_izvora" : "1:25.000",
        "namjena" : "GOSPODARSKA - UGOSTITELJSKO TURISTIÈKA (KAMP)",
        "ozn_namjen" : "T3",
        "naz_vl" : "KAČJAK (T3-2)",
        "az_oznaka" : "T",
        "pov" : 9973.2000000000007,
        "shape_area" : 9977.6399999999994,
        "shape_leng" : 457.06,
        "obveza_UPU" : "obveza izrade UPU",
        "odr_link" : "F:\\E_investicije\\Crikvenica\\Crikvenica_ISPU_SHP/UPU Kaèjak (Dramalj) - T13_ T32.pdf",
        "max_kap" : 240,
        "url" : "https://oss.uredjenazemlja.hr/public/cadServices.jsp?action=dkpViewerPublic"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354880.42300000001,
              5008651.4299999997
            ],
            [
              354885.79499999998,
              5008643.8559999997
            ],
            [
              354895.56800000003,
              5008630.7319999998
            ],
            [
              354891.777,
              5008629.7220000001
            ],
            [
              354880.46600000001,
              5008632.8459999999
            ],
            [
              354868.60600000003,
              5008624.8119999999
            ],
            [
              354865.97200000001,
              5008621.8169999998
            ],
            [
              354862.989,
              5008619.2810000004
            ],
            [
              354848.85200000001,
              5008623.7249999996
            ],
            [
              354834.234,
              5008622.7750000004
            ],
            [
              354825.59499999997,
              5008624.4450000003
            ],
            [
              354815.08799999999,
              5008629.4689999996
            ],
            [
              354805.22200000001,
              5008625.5389999999
            ],
            [
              354798.96600000001,
              5008636.1320000002
            ],
            [
              354777.42999999999,
              5008680.6390000004
            ],
            [
              354751.05900000001,
              5008741.3990000002
            ],
            [
              354768.26400000002,
              5008750.676
            ],
            [
              354797.087,
              5008766.5039999997
            ],
            [
              354812.92700000003,
              5008755.4110000003
            ],
            [
              354815.571,
              5008745.5829999996
            ],
            [
              354830.43599999999,
              5008720.5300000003
            ],
            [
              354834.20500000002,
              5008717.3849999998
            ],
            [
              354831.28399999999,
              5008713.5319999997
            ],
            [
              354839.50400000002,
              5008706.557
            ],
            [
              354844.20000000001,
              5008702.5619999999
            ],
            [
              354851.40299999999,
              5008682.9840000002
            ],
            [
              354850.69900000002,
              5008680.7450000001
            ],
            [
              354866.64299999998,
              5008665.0820000004
            ],
            [
              354865.11700000003,
              5008663.5630000001
            ],
            [
              354880.42300000001,
              5008651.4299999997
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 5,
        "zup_rb" : "00008",
        "jls_mb" : "00531",
        "jls_st" : "GR",
        "jls_ime" : "CRIKVENICA",
        "plan_naziv" : "PPUG CRIKVENICA - IV. ID",
        "ozn_ispu" : "HR-ISPU-PPGO-00531-R07",
        "izvor" : "1.",
        "mj_izvora" : "1:25.000",
        "namjena" : "GOSPODARSKA - UGOSTITELJSKO TURISTIÈKA (HOTEL)",
        "ozn_namjen" : "T1",
        "naz_vl" : "KAČJAK (T1-3)",
        "az_oznaka" : "T",
        "pov" : 79533.5,
        "shape_area" : 79569.089999999997,
        "shape_leng" : 1253.4200000000001,
        "obveza_UPU" : "obveza izrade UPU",
        "odr_link" : "F:\\E_investicije\\Crikvenica\\Crikvenica_ISPU_SHP/UPU Kaèjak (Dramalj) - T13_ T32.pdf",
        "max_kap" : 900,
        "url" : "https://oss.uredjenazemlja.hr/public/cadServices.jsp?action=dkpViewerPublic"
      },
      "geometry" : {
        "rings" : [
          [
            [
              354751.05900000001,
              5008741.3990000002
            ],
            [
              354777.42999999999,
              5008680.6390000004
            ],
            [
              354798.96600000001,
              5008636.1320000002
            ],
            [
              354805.22200000001,
              5008625.5389999999
            ],
            [
              354807.853,
              5008613.2170000002
            ],
            [
              354804.20600000001,
              5008604.8619999997
            ],
            [
              354808.663,
              5008595.1689999998
            ],
            [
              354816.07500000001,
              5008579.9189999998
            ],
            [
              354816.82199999999,
              5008578.0829999996
            ],
            [
              354814.80300000001,
              5008574.0120000001
            ],
            [
              354807.93199999997,
              5008570.4000000004
            ],
            [
              354805.87800000003,
              5008534.7989999996
            ],
            [
              354801.962,
              5008502.4850000003
            ],
            [
              354780.91200000001,
              5008486.3470000001
            ],
            [
              354786.31900000002,
              5008459.6189999999
            ],
            [
              354807.97499999998,
              5008389.9510000004
            ],
            [
              354783.81099999999,
              5008370.4560000002
            ],
            [
              354771.24699999997,
              5008385.4649999999
            ],
            [
              354739.554,
              5008358.9340000004
            ],
            [
              354718.01699999999,
              5008373.216
            ],
            [
              354699.56900000002,
              5008387.6500000004
            ],
            [
              354655.46899999998,
              5008426.2029999997
            ],
            [
              354619.09499999997,
              5008449.1969999997
            ],
            [
              354569.92999999999,
              5008473.102
            ],
            [
              354539.18699999998,
              5008492.409
            ],
            [
              354500.71500000003,
              5008526.233
            ],
            [
              354500.94099999999,
              5008593.1200000001
            ],
            [
              354508.63299999997,
              5008604.8609999996
            ],
            [
              354513.174,
              5008620.7350000003
            ],
            [
              354522.77399999998,
              5008625.4800000004
            ],
            [
              354566.84999999998,
              5008623.8810000001
            ],
            [
              354566.67999999999,
              5008636.1560000004
            ],
            [
              354569.74800000002,
              5008654.1119999997
            ],
            [
              354569.99699999997,
              5008656.6370000001
            ],
            [
              354579.04700000002,
              5008674.7189999996
            ],
            [
              354579.15600000002,
              5008695.8700000001
            ],
            [
              354620.36499999999,
              5008721.199
            ],
            [
              354615.88400000002,
              5008725.9699999997
            ],
            [
              354640.04100000003,
              5008755.4550000001
            ],
            [
              354654.66100000002,
              5008733.3310000002
            ],
            [
              354663.02000000002,
              5008718.023
            ],
            [
              354666.897,
              5008711.5410000002
            ],
            [
              354675.67700000003,
              5008713.8380000005
            ],
            [
              354683.73999999999,
              5008710.6119999997
            ],
            [
              354691.212,
              5008729.2699999996
            ],
            [
              354699.663,
              5008745.7929999996
            ],
            [
              354722.41499999998,
              5008737.2230000002
            ],
            [
              354741.76400000002,
              5008736.449
            ],
            [
              354751.05900000001,
              5008741.3990000002
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 6,
        "zup_rb" : "00008",
        "jls_mb" : "00531",
        "jls_st" : "GR",
        "jls_ime" : "CRIKVENICA",
        "plan_naziv" : "PPUG CRIKVENICA - IV. ID",
        "ozn_ispu" : "HR-ISPU-PPGO-00531-R07",
        "izvor" : "1.",
        "mj_izvora" : "1:25.000",
        "namjena" : "GOSPODARSKA - UGOSTITELJSKO TURISTIÈKA (HOTEL)",
        "ozn_namjen" : "T1",
        "naz_vl" : "OMORIKA (T1-5)",
        "az_oznaka" : "T",
        "pov" : 129267.8,
        "shape_area" : 129323.12,
        "shape_leng" : 2170.8299999999999,
        "obveza_UPU" : "obveza izrade UPU",
        "odr_link" : "F:\\E_investicije\\Crikvenica\\Crikvenica_ISPU_SHP/UPU Omorika (Dramalj - Crikvenica).pdf",
        "max_kap" : 1900,
        "url" : "https://oss.uredjenazemlja.hr/public/cadServices.jsp?action=dkpViewerPublic"
      },
      "geometry" : {
        "rings" : [
          [
            [
              356772.34700000001,
              5007203.835
            ],
            [
              356780.98999999999,
              5007209.9819999998
            ],
            [
              356786.95500000002,
              5007215.841
            ],
            [
              356826.39000000001,
              5007244.1619999995
            ],
            [
              356828.549,
              5007227.9680000003
            ],
            [
              356831.29599999997,
              5007221.6030000001
            ],
            [
              356897.98999999999,
              5007201.3870000001
            ],
            [
              356906.44,
              5007200.2079999996
            ],
            [
              356915.58500000002,
              5007187.1969999997
            ],
            [
              356922.54100000003,
              5007177.2470000004
            ],
            [
              356932.75099999999,
              5007162.5860000001
            ],
            [
              356942.70699999999,
              5007148.841
            ],
            [
              356946.15600000002,
              5007144.0779999997
            ],
            [
              356962.67800000001,
              5007120.7209999999
            ],
            [
              356958.58600000001,
              5007113.6869999999
            ],
            [
              356962.71600000001,
              5007109.79
            ],
            [
              356971.04499999998,
              5007103.8839999996
            ],
            [
              356986.66800000001,
              5007085.9879999999
            ],
            [
              357005.25599999999,
              5007054.4309999999
            ],
            [
              357000.08399999997,
              5007053.2259999998
            ],
            [
              357017.43099999998,
              5007027.1859999998
            ],
            [
              357028.723,
              5007010.9220000003
            ],
            [
              357015.228,
              5007008.2259999998
            ],
            [
              357007.652,
              5007008.8880000003
            ],
            [
              357001.739,
              5007008.6500000004
            ],
            [
              356999.163,
              5007007.193
            ],
            [
              356994.56800000003,
              5007001.2010000004
            ],
            [
              356985.76199999999,
              5007005.4699999997
            ],
            [
              356974.67099999997,
              5007004.6890000002
            ],
            [
              356968.89399999997,
              5007000.5779999997
            ],
            [
              356963.18400000001,
              5006995.6469999999
            ],
            [
              356959.20600000001,
              5006987.4960000003
            ],
            [
              356955.78499999997,
              5006983.3689999999
            ],
            [
              356943.71299999999,
              5006968.4529999997
            ],
            [
              356937.83000000002,
              5006963.5559999999
            ],
            [
              356923.94500000001,
              5006957.7120000003
            ],
            [
              356916.92499999999,
              5006955.75
            ],
            [
              356900.516,
              5006951.8159999996
            ],
            [
              356878.978,
              5006946.6239999998
            ],
            [
              356875.158,
              5006945.7680000002
            ],
            [
              356863.75,
              5006943.5980000002
            ],
            [
              356844.84600000002,
              5006938.8870000001
            ],
            [
              356841.64199999999,
              5006937.8559999997
            ],
            [
              356833.50799999997,
              5006935.9979999997
            ],
            [
              356824.50599999999,
              5006935.3640000001
            ],
            [
              356814.97100000002,
              5006933.8490000004
            ],
            [
              356802.72700000001,
              5006914.3090000004
            ],
            [
              356796.89299999998,
              5006918.1919999998
            ],
            [
              356783.04100000003,
              5006895.8839999996
            ],
            [
              356784.679,
              5006888.5480000004
            ],
            [
              356781.93599999999,
              5006887.443
            ],
            [
              356773.45400000003,
              5006882.3760000002
            ],
            [
              356767.77799999999,
              5006872.0060000001
            ],
            [
              356766.72600000002,
              5006869.0350000001
            ],
            [
              356766.71799999999,
              5006863.4970000004
            ],
            [
              356768.89299999998,
              5006857.1529999999
            ],
            [
              356771.68400000001,
              5006853.0899999999
            ],
            [
              356761.56,
              5006827.7599999998
            ],
            [
              356745.71399999998,
              5006802.4060000004
            ],
            [
              356738.815,
              5006794.7829999998
            ],
            [
              356684.92499999999,
              5006833.8559999997
            ],
            [
              356689.717,
              5006843.682
            ],
            [
              356680.00199999998,
              5006851.7359999996
            ],
            [
              356648.67099999997,
              5006874.6449999996
            ],
            [
              356641.97499999998,
              5006868.0659999996
            ],
            [
              356615.64299999998,
              5006837.608
            ],
            [
              356599.734,
              5006817.7079999996
            ],
            [
              356624.44199999998,
              5006795.7960000001
            ],
            [
              356639.522,
              5006780.3109999998
            ],
            [
              356636.99200000003,
              5006777.5369999995
            ],
            [
              356628.609,
              5006767.6469999999
            ],
            [
              356608.34600000002,
              5006760.6310000001
            ],
            [
              356611.48999999999,
              5006747.4680000003
            ],
            [
              356608.64000000001,
              5006744.5839999998
            ],
            [
              356607.93400000001,
              5006744.6979999999
            ],
            [
              356606.92099999997,
              5006744.6160000004
            ],
            [
              356605.38799999998,
              5006743.7350000003
            ],
            [
              356593.51500000001,
              5006737.6579999998
            ],
            [
              356583.05800000002,
              5006746.4419999998
            ],
            [
              356579.09999999998,
              5006745.7070000004
            ],
            [
              356578.65700000001,
              5006749.0499999998
            ],
            [
              356584.03000000003,
              5006749.7580000004
            ],
            [
              356593.29499999998,
              5006742.1090000002
            ],
            [
              356593.99699999997,
              5006741.7920000004
            ],
            [
              356595.109,
              5006741.7719999999
            ],
            [
              356596.13,
              5006742.2580000004
            ],
            [
              356609.31,
              5006749.3890000004
            ],
            [
              356605.17499999999,
              5006760.8849999998
            ],
            [
              356613.87400000001,
              5006766.3810000001
            ],
            [
              356611.68199999997,
              5006773.5970000001
            ],
            [
              356571.29700000002,
              5006761.2110000001
            ],
            [
              356562.08600000001,
              5006767.3030000003
            ],
            [
              356559.495,
              5006769.3720000004
            ],
            [
              356558.21399999998,
              5006771.2149999999
            ],
            [
              356557.73700000002,
              5006772.7390000001
            ],
            [
              356557.364,
              5006774.4639999997
            ],
            [
              356556.99599999998,
              5006787.3039999995
            ],
            [
              356556.848,
              5006790.2369999997
            ],
            [
              356556.48700000002,
              5006792.568
            ],
            [
              356555.81699999998,
              5006794.6009999998
            ],
            [
              356554.46100000001,
              5006797.8600000003
            ],
            [
              356552.40600000002,
              5006801.5360000003
            ],
            [
              356546.63500000001,
              5006808.3890000004
            ],
            [
              356544.54399999999,
              5006810.1449999996
            ],
            [
              356542.54599999997,
              5006811.4960000003
            ],
            [
              356540.64000000001,
              5006812.3399999999
            ],
            [
              356538.32400000002,
              5006812.8880000003
            ],
            [
              356536.304,
              5006813.0269999998
            ],
            [
              356534.27600000001,
              5006812.7620000001
            ],
            [
              356532.84499999997,
              5006811.9800000004
            ],
            [
              356522.071,
              5006803.6919999998
            ],
            [
              356517.13900000002,
              5006810.4529999997
            ],
            [
              356520.12,
              5006813.0250000004
            ],
            [
              356520.61300000001,
              5006812.3080000002
            ],
            [
              356529.12300000002,
              5006818.5159999998
            ],
            [
              356530.25400000002,
              5006819.5049999999
            ],
            [
              356531.288,
              5006820.699
            ],
            [
              356532.55900000001,
              5006823.8080000002
            ],
            [
              356532.90000000002,
              5006825.8219999997
            ],
            [
              356533.065,
              5006829.2549999999
            ],
            [
              356532.94,
              5006833.4000000004
            ],
            [
              356531.75400000002,
              5006840.9689999996
            ],
            [
              356530.42599999998,
              5006845.7429999998
            ],
            [
              356528.11800000002,
              5006852.1519999998
            ],
            [
              356523.73100000003,
              5006861.0250000004
            ],
            [
              356521.065,
              5006864.5099999998
            ],
            [
              356519.571,
              5006865.75
            ],
            [
              356518.17200000002,
              5006866.6849999996
            ],
            [
              356512.96000000002,
              5006869.2079999996
            ],
            [
              356508.842,
              5006870.7999999998
            ],
            [
              356500.989,
              5006875.3590000002
            ],
            [
              356496.70199999999,
              5006878.6720000003
            ],
            [
              356490.34700000001,
              5006885.0549999997
            ],
            [
              356488.55499999999,
              5006886.6040000003
            ],
            [
              356483.35700000002,
              5006889.9340000004
            ],
            [
              356481.25300000003,
              5006890.9840000002
            ],
            [
              356477.13,
              5006892.273
            ],
            [
              356472.38500000001,
              5006892.7649999997
            ],
            [
              356470.26199999999,
              5006892.8049999997
            ],
            [
              356466.23100000003,
              5006893.5870000003
            ],
            [
              356457.72700000001,
              5006895.8140000002
            ],
            [
              356454.39199999999,
              5006895.977
            ],
            [
              356452.87,
              5006895.7019999996
            ],
            [
              356447.81199999998,
              5006895.6950000003
            ],
            [
              356445.18699999998,
              5006895.9460000005
            ],
            [
              356442.66700000002,
              5006896.3969999999
            ],
            [
              356439.04599999997,
              5006897.4749999996
            ],
            [
              356436.136,
              5006898.7419999996
            ],
            [
              356433.53899999999,
              5006900.5080000004
            ],
            [
              356431.25300000003,
              5006902.6720000003
            ],
            [
              356429.272,
              5006904.932
            ],
            [
              356427.80200000003,
              5006907.4859999996
            ],
            [
              356426.92800000001,
              5006909.4220000003
            ],
            [
              356425.31400000001,
              5006915.1109999996
            ],
            [
              356424.962,
              5006917.9469999997
            ],
            [
              356425.00400000002,
              5006920.1689999998
            ],
            [
              356425.65500000003,
              5006922.5820000004
            ],
            [
              356426.71000000002,
              5006924.8859999999
            ],
            [
              356428.16499999998,
              5006926.9809999997
            ],
            [
              356429.50699999998,
              5006928.4720000001
            ],
            [
              356431.04300000001,
              5006929.4539999999
            ],
            [
              356432.26699999999,
              5006930.0369999995
            ],
            [
              356433.58899999998,
              5006930.4170000004
            ],
            [
              356435.51000000001,
              5006930.3810000001
            ],
            [
              356446.89299999998,
              5006927.8459999999
            ],
            [
              356452.64799999999,
              5006927.3339999998
            ],
            [
              356457.31300000002,
              5006927.9550000001
            ],
            [
              356458.63699999999,
              5006928.4359999998
            ],
            [
              356459.55800000002,
              5006929.0250000004
            ],
            [
              356459.97499999998,
              5006929.7240000004
            ],
            [
              356460.19300000003,
              5006930.5290000001
            ],
            [
              356460.01299999998,
              5006931.7450000001
            ],
            [
              356459.35600000003,
              5006934.1629999997
            ],
            [
              356457.90500000003,
              5006937.727
            ],
            [
              356453.22600000002,
              5006947.2110000001
            ],
            [
              356451.875,
              5006950.6720000003
            ],
            [
              356450.647,
              5006955.3430000003
            ],
            [
              356450.28700000001,
              5006957.7750000004
            ],
            [
              356449.837,
              5006966.1699999999
            ],
            [
              356445.217,
              5006962.517
            ],
            [
              356443.34299999999,
              5006965.0779999997
            ],
            [
              356459.89799999999,
              5006978.5650000004
            ],
            [
              356464.88099999999,
              5006981.0300000003
            ],
            [
              356487.45500000002,
              5006952.3140000002
            ],
            [
              356516.234,
              5006983.0729999999
            ],
            [
              356523.68800000002,
              5006983.4450000003
            ],
            [
              356533.80900000001,
              5006989.9780000001
            ],
            [
              356544.55499999999,
              5007005.2560000001
            ],
            [
              356548.71399999998,
              5007006.4280000003
            ],
            [
              356553.26199999999,
              5007008.0729999999
            ],
            [
              356561.25799999997,
              5007010.3300000001
            ],
            [
              356567.478,
              5007013.5130000003
            ],
            [
              356570.00699999998,
              5007016.0750000002
            ],
            [
              356581.435,
              5007025.409
            ],
            [
              356593.23300000001,
              5007034.0089999996
            ],
            [
              356596.62599999999,
              5007035.3459999999
            ],
            [
              356609.02799999999,
              5007043.1119999997
            ],
            [
              356633.42099999997,
              5007059.7379999999
            ],
            [
              356636.72499999998,
              5007059.6629999997
            ],
            [
              356641.70600000001,
              5007062.3609999996
            ],
            [
              356654.63799999998,
              5007056.1869999999
            ],
            [
              356666.03399999999,
              5007064.9309999999
            ],
            [
              356657.06900000002,
              5007080.6119999997
            ],
            [
              356662.43699999998,
              5007089.9730000002
            ],
            [
              356666.53399999999,
              5007097.7429999998
            ],
            [
              356670.63,
              5007105.5120000001
            ],
            [
              356678.533,
              5007120.682
            ],
            [
              356669.72700000001,
              5007137.2719999999
            ],
            [
              356664.45799999998,
              5007144.841
            ],
            [
              356671.71799999999,
              5007149.5580000002
            ],
            [
              356680.79700000002,
              5007152.8940000003
            ],
            [
              356692.68699999998,
              5007163.6890000002
            ],
            [
              356706.13900000002,
              5007176.1050000004
            ],
            [
              356707.88699999999,
              5007173.3420000002
            ],
            [
              356715.59999999998,
              5007172.6560000004
            ],
            [
              356727.77000000002,
              5007184.7209999999
            ],
            [
              356747.44900000002,
              5007197.4649999999
            ],
            [
              356747.24800000002,
              5007195.517
            ],
            [
              356762.255,
              5007208.3540000003
            ],
            [
              356769.12,
              5007201.54
            ],
            [
              356772.34700000001,
              5007203.835
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 7,
        "zup_rb" : "00008",
        "jls_mb" : "00531",
        "jls_st" : "GR",
        "jls_ime" : "CRIKVENICA",
        "plan_naziv" : "PPUG CRIKVENICA - IV. ID",
        "ozn_ispu" : "HR-ISPU-PPGO-00531-R07",
        "izvor" : "1.",
        "mj_izvora" : "1:25.000",
        "namjena" : "GOSPODARSKA - UGOSTITELJSKO TURISTIÈKA (KAMP)",
        "ozn_namjen" : "T3",
        "naz_vl" : "SV. JAKOV (T3-1)",
        "az_oznaka" : "T",
        "pov" : 27477.599999999999,
        "shape_area" : 27490.98,
        "shape_leng" : 813.49000000000001,
        "obveza_UPU" : "obveza izrade UPU",
        "odr_link" : "F:\\E_investicije\\Crikvenica\\Crikvenica_ISPU_SHP/UPU Sveti_Jakov_Jadranovo.pdf",
        "max_kap" : 300,
        "url" : "https://oss.uredjenazemlja.hr/public/cadServices.jsp?action=dkpViewerPublic"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351754.16700000002,
              5010988.9189999998
            ],
            [
              351737.815,
              5010980.7209999999
            ],
            [
              351731.32000000001,
              5010976.9100000001
            ],
            [
              351725.24400000001,
              5010974.534
            ],
            [
              351715.71500000003,
              5010969.7309999997
            ],
            [
              351706.223,
              5010966.8940000003
            ],
            [
              351698.51699999999,
              5010961.4019999998
            ],
            [
              351688.53000000003,
              5010951.3949999996
            ],
            [
              351683.05599999998,
              5010941.4380000001
            ],
            [
              351674.02000000002,
              5010927.6979999999
            ],
            [
              351673.74800000002,
              5010927.2130000005
            ],
            [
              351668.30699999997,
              5010910.7379999999
            ],
            [
              351666.24099999998,
              5010898.6200000001
            ],
            [
              351663.05099999998,
              5010892.9100000001
            ],
            [
              351657.72399999999,
              5010887.0810000002
            ],
            [
              351641.91899999999,
              5010874.8870000001
            ],
            [
              351632.46899999998,
              5010865.7719999999
            ],
            [
              351628.67700000003,
              5010861.8399999999
            ],
            [
              351627.72899999999,
              5010862.5369999995
            ],
            [
              351625.72999999998,
              5010863.7860000003
            ],
            [
              351620.212,
              5010866.2130000005
            ],
            [
              351610.06,
              5010869.6349999998
            ],
            [
              351608.554,
              5010870.2690000003
            ],
            [
              351596.69699999999,
              5010877.1059999997
            ],
            [
              351593.69500000001,
              5010878.8789999997
            ],
            [
              351592.79100000003,
              5010879.199
            ],
            [
              351591.88500000001,
              5010879.4179999996
            ],
            [
              351590.57199999999,
              5010879.5429999996
            ],
            [
              351588.95500000002,
              5010879.5729999999
            ],
            [
              351587.44199999998,
              5010879.8039999995
            ],
            [
              351586.23599999998,
              5010880.2300000004
            ],
            [
              351583.228,
              5010881.7010000004
            ],
            [
              351582.03000000003,
              5010882.5319999997
            ],
            [
              351581.13400000002,
              5010883.2560000001
            ],
            [
              351576.42800000001,
              5010885.8689999999
            ],
            [
              351570.315,
              5010888.9129999997
            ],
            [
              351565.81900000002,
              5010891.9270000001
            ],
            [
              351560.14399999997,
              5010896.7819999997
            ],
            [
              351550.96399999998,
              5010905.7939999998
            ],
            [
              351548.69099999999,
              5010908.6660000002
            ],
            [
              351546.62900000002,
              5010911.9380000001
            ],
            [
              351541.386,
              5010923.7570000002
            ],
            [
              351540.24800000002,
              5010927.8200000003
            ],
            [
              351539.60499999998,
              5010931.2680000002
            ],
            [
              351539.451,
              5010933.898
            ],
            [
              351539.92300000001,
              5010938.2070000004
            ],
            [
              351539.94699999999,
              5010939.5199999996
            ],
            [
              351539.30200000003,
              5010942.8669999996
            ],
            [
              351539.10100000002,
              5010943.2520000003
            ],
            [
              351563.74599999998,
              5010982.1529999999
            ],
            [
              351569.78399999999,
              5010989.7920000004
            ],
            [
              351606.52399999998,
              5011036.5439999998
            ],
            [
              351641.04999999999,
              5011066.1749999998
            ],
            [
              351680.14000000001,
              5011101.4730000002
            ],
            [
              351688.80699999997,
              5011107.5599999996
            ],
            [
              351718.826,
              5011129.6900000004
            ],
            [
              351732.48999999999,
              5011137.6069999998
            ],
            [
              351740.07400000002,
              5011136.5489999996
            ],
            [
              351754.495,
              5011129.4670000002
            ],
            [
              351747.93900000001,
              5011122.3820000002
            ],
            [
              351737.70799999998,
              5011108.0269999998
            ],
            [
              351732.51299999998,
              5011096.5920000002
            ],
            [
              351721.88,
              5011088.7970000003
            ],
            [
              351715.91800000001,
              5011079.9139999999
            ],
            [
              351705.39299999998,
              5011063.8609999996
            ],
            [
              351702.85399999999,
              5011054.2120000003
            ],
            [
              351713.47600000002,
              5011040.2549999999
            ],
            [
              351708.39399999997,
              5011034.6260000002
            ],
            [
              351701.027,
              5011026.466
            ],
            [
              351717.57199999999,
              5011013.5099999998
            ],
            [
              351732.20799999998,
              5011003.9349999996
            ],
            [
              351741.90600000002,
              5010998.0329999998
            ],
            [
              351748.22499999998,
              5010994.1869999999
            ],
            [
              351754.16700000002,
              5010988.9189999998
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 8,
        "zup_rb" : "00008",
        "jls_mb" : "00531",
        "jls_st" : "GR",
        "jls_ime" : "CRIKVENICA",
        "plan_naziv" : "PPUG CRIKVENICA - IV. ID",
        "ozn_ispu" : "HR-ISPU-PPGO-00531-R07",
        "izvor" : "1.",
        "mj_izvora" : "1:25.000",
        "namjena" : "GOSPODARSKA - UGOSTITELJSKO TURISTIÈKA (HOTEL)",
        "ozn_namjen" : "T1",
        "naz_vl" : "HAVIŠĆE I (T1-1)",
        "az_oznaka" : "T",
        "pov" : 108048.7,
        "shape_area" : 108101.24000000001,
        "shape_leng" : 1376.5699999999999,
        "obveza_UPU" : "obveza izrade UPU",
        "odr_link" : "F:\\E_investicije\\Crikvenica\\Crikvenica_ISPU_SHP/UPU Havišæe I.pdf",
        "max_kap" : 700,
        "url" : "https://oss.uredjenazemlja.hr/public/cadServices.jsp?action=dkpViewerPublic"
      },
      "geometry" : {
        "rings" : [
          [
            [
              351993.42300000001,
              5010896.6600000001
            ],
            [
              352001.32000000001,
              5010883.5779999997
            ],
            [
              352017.46799999999,
              5010853.8039999995
            ],
            [
              352040.09100000001,
              5010817.3130000001
            ],
            [
              352050.31699999998,
              5010806.8329999996
            ],
            [
              352037.75799999997,
              5010796.7220000001
            ],
            [
              352009.61599999998,
              5010778.9299999997
            ],
            [
              352002.68599999999,
              5010771.1469999999
            ],
            [
              351992.91499999998,
              5010766.9680000003
            ],
            [
              351983.06599999999,
              5010758.5930000003
            ],
            [
              351980.42700000003,
              5010747.017
            ],
            [
              351981.495,
              5010747.1569999997
            ],
            [
              351966.13,
              5010725.0710000005
            ],
            [
              351960.51400000002,
              5010700.9009999996
            ],
            [
              351951.397,
              5010704.1509999996
            ],
            [
              351950.25300000003,
              5010697.875
            ],
            [
              351944.10100000002,
              5010698.5369999995
            ],
            [
              351928.30800000002,
              5010703.7589999996
            ],
            [
              351916.98700000002,
              5010702.6679999996
            ],
            [
              351899.038,
              5010706.54
            ],
            [
              351894.02799999999,
              5010706.2879999997
            ],
            [
              351887.98999999999,
              5010706.4859999996
            ],
            [
              351880.087,
              5010703.7860000003
            ],
            [
              351871.98700000002,
              5010704.4539999999
            ],
            [
              351871.52600000001,
              5010656.2999999998
            ],
            [
              351883.745,
              5010659.1610000003
            ],
            [
              351885.79499999998,
              5010658.0539999995
            ],
            [
              351886.73200000002,
              5010652.7759999996
            ],
            [
              351883.63199999998,
              5010643.8629999999
            ],
            [
              351880.68699999998,
              5010634.1469999999
            ],
            [
              351800.59999999998,
              5010590.0460000001
            ],
            [
              351797.701,
              5010591.3169999998
            ],
            [
              351775.99900000001,
              5010601.5860000001
            ],
            [
              351772.18900000001,
              5010604.6629999997
            ],
            [
              351768.69799999997,
              5010606.8360000001
            ],
            [
              351748.81599999999,
              5010617.2999999998
            ],
            [
              351720.04100000003,
              5010633.4639999997
            ],
            [
              351711.27799999999,
              5010638.3629999999
            ],
            [
              351700.44300000003,
              5010645.7929999996
            ],
            [
              351686.46999999997,
              5010655.96
            ],
            [
              351680.31599999999,
              5010662.7089999998
            ],
            [
              351670.51799999998,
              5010666.477
            ],
            [
              351626.212,
              5010747.8389999997
            ],
            [
              351630.815,
              5010757.3360000001
            ],
            [
              351635.06099999999,
              5010761.1909999996
            ],
            [
              351641.82900000003,
              5010765.9519999996
            ],
            [
              351645.16999999998,
              5010772.4460000005
            ],
            [
              351648.23200000002,
              5010776.7999999998
            ],
            [
              351650.23499999999,
              5010781.8890000004
            ],
            [
              351654.07400000002,
              5010795.8830000004
            ],
            [
              351654.39299999998,
              5010800.6090000002
            ],
            [
              351652.05200000003,
              5010813.8039999995
            ],
            [
              351647.978,
              5010813.2489999998
            ],
            [
              351647.50599999999,
              5010820.5700000003
            ],
            [
              351645.99900000001,
              5010832.017
            ],
            [
              351644.11900000001,
              5010839.6299999999
            ],
            [
              351641.217,
              5010846.7580000004
            ],
            [
              351637.853,
              5010852.142
            ],
            [
              351633.70400000003,
              5010857.4740000004
            ],
            [
              351631.91800000001,
              5010859.3260000004
            ],
            [
              351628.67700000003,
              5010861.8399999999
            ],
            [
              351632.46899999998,
              5010865.7719999999
            ],
            [
              351641.91899999999,
              5010874.8870000001
            ],
            [
              351657.72399999999,
              5010887.0810000002
            ],
            [
              351663.05099999998,
              5010892.9100000001
            ],
            [
              351666.24099999998,
              5010898.6200000001
            ],
            [
              351668.30699999997,
              5010910.7379999999
            ],
            [
              351673.74800000002,
              5010927.2130000005
            ],
            [
              351674.02000000002,
              5010927.6979999999
            ],
            [
              351683.05599999998,
              5010941.4380000001
            ],
            [
              351688.53000000003,
              5010951.3949999996
            ],
            [
              351698.51699999999,
              5010961.4019999998
            ],
            [
              351706.223,
              5010966.8940000003
            ],
            [
              351715.71500000003,
              5010969.7309999997
            ],
            [
              351725.24400000001,
              5010974.534
            ],
            [
              351731.32000000001,
              5010976.9100000001
            ],
            [
              351737.815,
              5010980.7209999999
            ],
            [
              351754.16700000002,
              5010988.9189999998
            ],
            [
              351756.28499999997,
              5010986.8480000002
            ],
            [
              351768.24099999998,
              5010989.3739999998
            ],
            [
              351787.80099999998,
              5010986.7560000001
            ],
            [
              351798.62099999998,
              5010991.0920000002
            ],
            [
              351813.99300000002,
              5010995.1880000001
            ],
            [
              351813.152,
              5010991.1849999996
            ],
            [
              351870.63,
              5010966.9100000001
            ],
            [
              351904.66800000001,
              5010955.9630000005
            ],
            [
              351904.50799999997,
              5010959.9869999997
            ],
            [
              351914.10700000002,
              5010959.1050000004
            ],
            [
              351928.07299999997,
              5010940.7949999999
            ],
            [
              351954.18900000001,
              5010918.3389999997
            ],
            [
              351993.42300000001,
              5010896.6600000001
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 9,
        "zup_rb" : "00008",
        "jls_mb" : "00531",
        "jls_st" : "GR",
        "jls_ime" : "CRIKVENICA",
        "plan_naziv" : "PPUG CRIKVENICA - IV. ID",
        "ozn_ispu" : "HR-ISPU-PPGO-00531-R07",
        "izvor" : "1.",
        "mj_izvora" : "1:25.000",
        "namjena" : "GOSPODARSKA - UGOSTITELJSKO TURISTIÈKA (HOTEL)",
        "ozn_namjen" : "T1",
        "naz_vl" : "MIRAMARE (T1-6)",
        "az_oznaka" : "T",
        "pov" : 112698.3,
        "shape_area" : 112750.06,
        "shape_leng" : 3309.0999999999999,
        "obveza_UPU" : "UPU na snazi",
        "odr_link" : "F:\\E_investicije\\Crikvenica\\Crikvenica_UPU_T1_T3/UPU_Miramare_KN.jpg",
        "max_kap" : 1300,
        "url" : "https://oss.uredjenazemlja.hr/public/cadServices.jsp?action=dkpViewerPublic"
      },
      "geometry" : {
        "rings" : [
          [
            [
              357230.5,
              5006344.415
            ],
            [
              357230.446,
              5006343.5999999996
            ],
            [
              357230.44300000003,
              5006343.5630000001
            ],
            [
              357230.47999999998,
              5006342.7000000002
            ],
            [
              357230.58100000001,
              5006342
            ],
            [
              357230.81099999999,
              5006341.0930000003
            ],
            [
              357231.09899999999,
              5006340.3260000004
            ],
            [
              357231.43199999997,
              5006339.6519999998
            ],
            [
              357231.83100000001,
              5006339.0099999998
            ],
            [
              357232.33600000001,
              5006338.3550000004
            ],
            [
              357232.91700000002,
              5006337.7470000004
            ],
            [
              357233.728,
              5006337.0800000001
            ],
            [
              357234.20699999999,
              5006336.7640000004
            ],
            [
              357234.69300000003,
              5006336.4900000002
            ],
            [
              357235.28600000002,
              5006336.2149999999
            ],
            [
              357235.98499999999,
              5006335.9630000005
            ],
            [
              357254.47600000002,
              5006329.9009999996
            ],
            [
              357257.28999999998,
              5006332.0219999999
            ],
            [
              357261.62800000003,
              5006327.773
            ],
            [
              357266.08399999997,
              5006326.0240000002
            ],
            [
              357268.47999999998,
              5006324.852
            ],
            [
              357272.27299999999,
              5006322.9299999997
            ],
            [
              357276.41999999998,
              5006320.6200000001
            ],
            [
              357284.93900000001,
              5006314.9409999996
            ],
            [
              357285.837,
              5006313.1660000002
            ],
            [
              357288.37800000003,
              5006313.6880000001
            ],
            [
              357290.84100000001,
              5006314.477
            ],
            [
              357292.17700000003,
              5006310.1789999995
            ],
            [
              357292.826,
              5006309.9309999999
            ],
            [
              357294.06400000001,
              5006309.6919999998
            ],
            [
              357294.79700000002,
              5006309.6660000002
            ],
            [
              357295.80499999999,
              5006309.7850000001
            ],
            [
              357296.73100000003,
              5006309.9709999999
            ],
            [
              357305.42599999998,
              5006302.1840000004
            ],
            [
              357311.65500000003,
              5006293.9859999996
            ],
            [
              357313.26699999999,
              5006290.6339999996
            ],
            [
              357315.78100000002,
              5006285.4040000001
            ],
            [
              357321.92700000003,
              5006271.0609999998
            ],
            [
              357322.891,
              5006267.7429999998
            ],
            [
              357324.42700000003,
              5006268.1699999999
            ],
            [
              357333.48200000002,
              5006271.1469999999
            ],
            [
              357334.15000000002,
              5006271.3530000001
            ],
            [
              357334.91899999999,
              5006271.7089999998
            ],
            [
              357335.52000000002,
              5006272.0939999996
            ],
            [
              357336.27899999998,
              5006272.7609999999
            ],
            [
              357343.56900000002,
              5006269.1880000001
            ],
            [
              357343.60600000003,
              5006269.1699999999
            ],
            [
              357342.97899999999,
              5006268.3810000001
            ],
            [
              357342.19500000001,
              5006267.5199999996
            ],
            [
              357341.299,
              5006266.6710000001
            ],
            [
              357339.90299999999,
              5006265.5729999999
            ],
            [
              357338.40600000002,
              5006264.6409999998
            ],
            [
              357336.788,
              5006263.8629999999
            ],
            [
              357335.31599999999,
              5006263.335
            ],
            [
              357334.34999999998,
              5006263.0300000003
            ],
            [
              357327.05200000003,
              5006249.7960000001
            ],
            [
              357327.03200000001,
              5006249.7609999999
            ],
            [
              357328.48599999998,
              5006247.3859999999
            ],
            [
              357329.20299999998,
              5006246.5250000004
            ],
            [
              357330.098,
              5006245.7300000004
            ],
            [
              357331.28100000002,
              5006244.9910000004
            ],
            [
              357332.37400000001,
              5006244.5389999999
            ],
            [
              357333.59499999997,
              5006244.2479999997
            ],
            [
              357334.94,
              5006244.159
            ],
            [
              357336.34000000003,
              5006244.3169999998
            ],
            [
              357337.81800000003,
              5006244.7810000004
            ],
            [
              357338.70400000003,
              5006245.2290000003
            ],
            [
              357339.326,
              5006245.6370000001
            ],
            [
              357347.098,
              5006257.2539999997
            ],
            [
              357351.02399999998,
              5006264.4000000004
            ],
            [
              357355.64000000001,
              5006273.4419999998
            ],
            [
              357356.201,
              5006273.1449999996
            ],
            [
              357357.696,
              5006276.1579999998
            ],
            [
              357357.67099999997,
              5006276.1720000003
            ],
            [
              357357.17800000001,
              5006276.4409999996
            ],
            [
              357357.20500000002,
              5006276.4939999999
            ],
            [
              357361.049,
              5006284.074
            ],
            [
              357362.58600000001,
              5006288.2350000003
            ],
            [
              357372.77399999998,
              5006284.585
            ],
            [
              357370.72499999998,
              5006278.9529999997
            ],
            [
              357366.39299999998,
              5006268.6150000002
            ],
            [
              357362.255,
              5006259.9390000002
            ],
            [
              357352.299,
              5006239.915
            ],
            [
              357350.75799999997,
              5006236.8159999996
            ],
            [
              357347.68400000001,
              5006230.5599999996
            ],
            [
              357345.51400000002,
              5006226.142
            ],
            [
              357341.11499999999,
              5006217.1689999998
            ],
            [
              357337.17700000003,
              5006208.5939999996
            ],
            [
              357334.22200000001,
              5006200.0460000001
            ],
            [
              357332.68900000001,
              5006193.5099999998
            ],
            [
              357331.337,
              5006185.3940000003
            ],
            [
              357331.21799999999,
              5006184.6799999997
            ],
            [
              357330.30900000001,
              5006177.1624999996
            ],
            [
              357330.35499999998,
              5006169.1239999998
            ],
            [
              357331.47200000001,
              5006161.034
            ],
            [
              357332.45000000001,
              5006154.432
            ],
            [
              357333.74699999997,
              5006149.7010000004
            ],
            [
              357334.82199999999,
              5006145.7779999999
            ],
            [
              357335.28499999997,
              5006144.3640000001
            ],
            [
              357336.30599999998,
              5006141.8159999996
            ],
            [
              357338.98700000002,
              5006135.0980000002
            ],
            [
              357341.761,
              5006128.5939999996
            ],
            [
              357346.48100000003,
              5006119.1830000002
            ],
            [
              357347.99699999997,
              5006116.085
            ],
            [
              357348.022,
              5006116.0329999998
            ],
            [
              357338.83299999998,
              5006111.2589999996
            ],
            [
              357337.53700000001,
              5006113.3439999996
            ],
            [
              357332.39199999999,
              5006119.6739999996
            ],
            [
              357325.092,
              5006127.6979999999
            ],
            [
              357309.95799999998,
              5006117.3030000003
            ],
            [
              357309.92700000003,
              5006117.2819999997
            ],
            [
              357312.03999999998,
              5006114.2039999999
            ],
            [
              357312.07000000001,
              5006114.1619999995
            ],
            [
              357302.84499999997,
              5006107.0609999998
            ],
            [
              357302.81599999999,
              5006107.0389999999
            ],
            [
              357312.85200000001,
              5006091.0659999996
            ],
            [
              357312.87800000003,
              5006091.0259999996
            ],
            [
              357307.08100000001,
              5006085.4380000001
            ],
            [
              357295.83399999997,
              5006067.2350000003
            ],
            [
              357276.87099999998,
              5006051.4289999995
            ],
            [
              357272.15399999998,
              5006049.4749999996
            ],
            [
              357270.52000000002,
              5006051.0159999998
            ],
            [
              357254.54499999998,
              5006071.7800000003
            ],
            [
              357244.12599999999,
              5006065.7659999998
            ],
            [
              357231.26000000001,
              5006086.3490000004
            ],
            [
              357227.49200000003,
              5006092.3770000003
            ],
            [
              357210.071,
              5006120.7970000003
            ],
            [
              357208.58199999999,
              5006123.0719999997
            ],
            [
              357193.913,
              5006142.7599999998
            ],
            [
              357193.946,
              5006142.7829999998
            ],
            [
              357196.25400000002,
              5006144.4009999996
            ],
            [
              357196.21999999997,
              5006144.443
            ],
            [
              357182.20799999998,
              5006161.8360000001
            ],
            [
              357181.80599999998,
              5006161.5990000004
            ],
            [
              357181.05099999998,
              5006150.5870000003
            ],
            [
              357179.99800000002,
              5006144.5880000005
            ],
            [
              357178.359,
              5006142.1380000003
            ],
            [
              357175.53399999999,
              5006134.352
            ],
            [
              357173.37800000003,
              5006130.3600000003
            ],
            [
              357170.848,
              5006127.7180000003
            ],
            [
              357167.98100000003,
              5006125.7819999997
            ],
            [
              357164.56699999998,
              5006124.0999999996
            ],
            [
              357159.28700000001,
              5006122.3219999997
            ],
            [
              357151.06300000002,
              5006121.5389999999
            ],
            [
              357145.72499999998,
              5006122.2359999996
            ],
            [
              357136.83399999997,
              5006118.9945
            ],
            [
              357142.65100000001,
              5006100.7560000001
            ],
            [
              357146.07699999999,
              5006089.5889999997
            ],
            [
              357148.25699999998,
              5006081.0029999996
            ],
            [
              357149.255,
              5006074.9419999998
            ],
            [
              357172.24200000003,
              5006086.9749999996
            ],
            [
              357175.75799999997,
              5006076.9069999997
            ],
            [
              357183.28700000001,
              5006055.6799999997
            ],
            [
              357155.91200000001,
              5006044.2829999998
            ],
            [
              357155.603,
              5006041.199
            ],
            [
              357155.598,
              5006041.148
            ],
            [
              357159.5,
              5006026.1909999996
            ],
            [
              357166.37699999998,
              5006008.9189999998
            ],
            [
              357175.48200000002,
              5005991.1610000003
            ],
            [
              357189.261,
              5005998.2220000001
            ],
            [
              357198.94199999998,
              5006003.5250000004
            ],
            [
              357218.73200000002,
              5006011.4050000003
            ],
            [
              357249.78000000003,
              5005985.9270000001
            ],
            [
              357251.20000000001,
              5005986.8839999996
            ],
            [
              357251.17800000001,
              5005986.9349999996
            ],
            [
              357250.12400000001,
              5005989.3229999999
            ],
            [
              357243.26400000002,
              5005999.4469999997
            ],
            [
              357236.897,
              5006012.5329999998
            ],
            [
              357233.13,
              5006023.8310000002
            ],
            [
              357232.90399999998,
              5006024.5669999998
            ],
            [
              357232.74200000003,
              5006025.307
            ],
            [
              357232.64000000001,
              5006026.0719999997
            ],
            [
              357232.603,
              5006027.1169999996
            ],
            [
              357232.60399999999,
              5006027.1775000002
            ],
            [
              357232.614,
              5006027.4160000002
            ],
            [
              357232.62900000002,
              5006027.6490000002
            ],
            [
              357232.65000000002,
              5006027.8810000001
            ],
            [
              357232.65899999999,
              5006027.9680000003
            ],
            [
              357232.67300000001,
              5006028.0810000002
            ],
            [
              357232.68800000002,
              5006028.1940000001
            ],
            [
              357232.70400000003,
              5006028.307
            ],
            [
              357232.73100000003,
              5006028.4759999998
            ],
            [
              357232.75099999999,
              5006028.5880000005
            ],
            [
              357232.772,
              5006028.7010000004
            ],
            [
              357232.79499999998,
              5006028.8119999999
            ],
            [
              357232.859,
              5006029.0990000004
            ],
            [
              357232.93199999997,
              5006029.3830000004
            ],
            [
              357233.01400000002,
              5006029.665
            ],
            [
              357233.09600000002,
              5006029.9160000002
            ],
            [
              357233.18400000001,
              5006030.1639999999
            ],
            [
              357233.28000000003,
              5006030.4115000004
            ],
            [
              357233.38299999997,
              5006030.6569999997
            ],
            [
              357233.49300000002,
              5006030.898
            ],
            [
              357233.60999999999,
              5006031.1359999999
            ],
            [
              357233.745,
              5006031.3930000002
            ],
            [
              357233.88900000002,
              5006031.6459999997
            ],
            [
              357234.04100000003,
              5006031.8940000003
            ],
            [
              357234.20299999998,
              5006032.142
            ],
            [
              357234.37400000001,
              5006032.3849999998
            ],
            [
              357234.55200000003,
              5006032.6229999997
            ],
            [
              357234.65999999997,
              5006032.7589999996
            ],
            [
              357234.77100000001,
              5006032.8940000003
            ],
            [
              357234.88400000002,
              5006033.0259999996
            ],
            [
              357235.27399999998,
              5006033.5010000002
            ],
            [
              357235.99699999997,
              5006034.4170000004
            ],
            [
              357250.30599999998,
              5006052.5389999999
            ],
            [
              357259.40600000002,
              5006045.5180000002
            ],
            [
              357262.91499999998,
              5006039.216
            ],
            [
              357265.212,
              5006031.7549999999
            ],
            [
              357274.022,
              5006033.6100000003
            ],
            [
              357294.50199999998,
              5006002.6310000001
            ],
            [
              357324.60200000001,
              5005964.6900000004
            ],
            [
              357341.03399999999,
              5005944.398
            ],
            [
              357349.77600000001,
              5005933.2280000001
            ],
            [
              357370.58899999998,
              5005955.6869999999
            ],
            [
              357375.53499999997,
              5005945.6610000003
            ],
            [
              357388.96100000001,
              5005927.6509999996
            ],
            [
              357400.95899999997,
              5005912.7640000004
            ],
            [
              357409.935,
              5005900.4380000001
            ],
            [
              357416.364,
              5005889.3459999999
            ],
            [
              357422.723,
              5005875.5870000003
            ],
            [
              357426.25400000002,
              5005866.0590000004
            ],
            [
              357431.76899999997,
              5005846.7829999998
            ],
            [
              357438.08199999999,
              5005824.7149999999
            ],
            [
              357442.891,
              5005808.4890000001
            ],
            [
              357448.23499999999,
              5005790.4589999998
            ],
            [
              357455.69300000003,
              5005765.5930000003
            ],
            [
              357458.641,
              5005755.767
            ],
            [
              357450.36800000002,
              5005751.3619999997
            ],
            [
              357440.31400000001,
              5005741.1490000002
            ],
            [
              357433.99599999998,
              5005741.8600000003
            ],
            [
              357421.212,
              5005725.1380000003
            ],
            [
              357413.44199999998,
              5005721.7659999998
            ],
            [
              357409.79499999998,
              5005723.8430000003
            ],
            [
              357409.80200000003,
              5005723.9050000003
            ],
            [
              357411.65000000002,
              5005738.7470000004
            ],
            [
              357411.65100000001,
              5005738.8130000001
            ],
            [
              357411.69099999999,
              5005740.9550000001
            ],
            [
              357402.27000000002,
              5005740.9649999999
            ],
            [
              357387.95600000001,
              5005720.3859999999
            ],
            [
              357378.91399999999,
              5005716.8370000003
            ],
            [
              357364.80099999998,
              5005711.3219999997
            ],
            [
              357350.098,
              5005705.0970000001
            ],
            [
              357345.36099999998,
              5005703.176
            ],
            [
              357343.57799999998,
              5005707.8279999997
            ],
            [
              357341.92300000001,
              5005711.2939999998
            ],
            [
              357338.09100000001,
              5005717.3279999997
            ],
            [
              357336.20899999997,
              5005719.4850000003
            ],
            [
              357333.72499999998,
              5005721.8550000004
            ],
            [
              357330.63500000001,
              5005724.3380000005
            ],
            [
              357323.728,
              5005728.2050000001
            ],
            [
              357322.43199999997,
              5005729.2400000002
            ],
            [
              357321.04100000003,
              5005730.5789999999
            ],
            [
              357319.56,
              5005732.5269999998
            ],
            [
              357317.69300000003,
              5005730.0350000001
            ],
            [
              357315.79700000002,
              5005731.3839999996
            ],
            [
              357315.82799999998,
              5005731.4280000003
            ],
            [
              357319.74200000003,
              5005736.8679999998
            ],
            [
              357319.88549999997,
              5005739.2029999997
            ],
            [
              357319.66899999999,
              5005743.8420000002
            ],
            [
              357318.91999999998,
              5005747.8710000003
            ],
            [
              357318.152,
              5005750.108
            ],
            [
              357316.98599999998,
              5005752.6560000004
            ],
            [
              357313.43400000001,
              5005757.4720000001
            ],
            [
              357310.35600000003,
              5005760.5609999998
            ],
            [
              357304.88099999999,
              5005765.3109999998
            ],
            [
              357298.59600000002,
              5005769.9749999996
            ],
            [
              357298.30200000003,
              5005770.4859999996
            ],
            [
              357297.91600000003,
              5005771.5029999996
            ],
            [
              357297.13699999999,
              5005773.1349999998
            ],
            [
              357296.65600000002,
              5005774.4570000004
            ],
            [
              357292.60600000003,
              5005768.773
            ],
            [
              357290.50699999998,
              5005770.125
            ],
            [
              357290.53499999997,
              5005770.1739999996
            ],
            [
              357292.70299999998,
              5005774.0250000004
            ],
            [
              357292.70500000002,
              5005774.0920000002
            ],
            [
              357292.74699999997,
              5005776.3490000004
            ],
            [
              357292.67200000002,
              5005777.7649999997
            ],
            [
              357292.391,
              5005778.983
            ],
            [
              357291.71100000001,
              5005780.5109999999
            ],
            [
              357290.53399999999,
              5005782.4529999997
            ],
            [
              357288.908,
              5005784.3839999996
            ],
            [
              357282.72600000002,
              5005789.1469999999
            ],
            [
              357274.22899999999,
              5005794.5599999996
            ],
            [
              357270.24099999998,
              5005797.6660000002
            ],
            [
              357263.67999999999,
              5005803.8509999998
            ],
            [
              357261.07900000003,
              5005799.9579999996
            ],
            [
              357258.78899999999,
              5005801.9210000001
            ],
            [
              357258.81599999999,
              5005801.9649999999
            ],
            [
              357262.647,
              5005808.1140000001
            ],
            [
              357263.08899999998,
              5005810.1270000003
            ],
            [
              357263.09000000003,
              5005810.193
            ],
            [
              357263.141,
              5005812.9550000001
            ],
            [
              357262.97600000002,
              5005814.9800000004
            ],
            [
              357262.40899999999,
              5005817.1119999997
            ],
            [
              357257.60700000002,
              5005827.6909999996
            ],
            [
              357253.511,
              5005835.8509999998
            ],
            [
              357245.505,
              5005845.9029999999
            ],
            [
              357243.22200000001,
              5005848.2699999996
            ],
            [
              357239.63299999997,
              5005851.0650000004
            ],
            [
              357230.55800000002,
              5005858.0039999997
            ],
            [
              357220.58299999998,
              5005865.5659999996
            ],
            [
              357212.06199999998,
              5005872.3760000002
            ],
            [
              357203.125,
              5005881.3339999998
            ],
            [
              357198.44,
              5005885.0590000004
            ],
            [
              357191.636,
              5005889.0250000004
            ],
            [
              357180.72399999999,
              5005895.0889999997
            ],
            [
              357168.68099999998,
              5005900.1629999997
            ],
            [
              357166.13099999999,
              5005901.8969999999
            ],
            [
              357164.33000000002,
              5005902.9409999996
            ],
            [
              357151.179,
              5005908.2379999999
            ],
            [
              357148.15500000003,
              5005908.7999999998
            ],
            [
              357143.81400000001,
              5005909.284
            ],
            [
              357142.136,
              5005900.625
            ],
            [
              357135.55699999997,
              5005900.3430000003
            ],
            [
              357132.59950000001,
              5005926.0659999996
            ],
            [
              357135.53899999999,
              5005926.6169999996
            ],
            [
              357138.21999999997,
              5005929.3969999999
            ],
            [
              357139.45299999998,
              5005930.3839999996
            ],
            [
              357147.614,
              5005934.1730000004
            ],
            [
              357151.10100000002,
              5005936.7359999996
            ],
            [
              357153.163,
              5005938.8200000003
            ],
            [
              357155.12699999998,
              5005941.1069999998
            ],
            [
              357156.40000000002,
              5005943.1890000002
            ],
            [
              357157.13699999999,
              5005944.7920000004
            ],
            [
              357158.25,
              5005950.2280000001
            ],
            [
              357158.72399999999,
              5005953.9579999996
            ],
            [
              357158.90549999999,
              5005958.3054999998
            ],
            [
              357158.56099999999,
              5005961.54
            ],
            [
              357157.902,
              5005964.1799999997
            ],
            [
              357155.76799999998,
              5005969.0700000003
            ],
            [
              357151.48599999998,
              5005978.1430000002
            ],
            [
              357143.84999999998,
              5005993.2189999996
            ],
            [
              357142.00199999998,
              5005997.1940000001
            ],
            [
              357140.946,
              5006000.2450000001
            ],
            [
              357139.97100000002,
              5006002.1830000002
            ],
            [
              357135.451,
              5006009.341
            ],
            [
              357127.46399999998,
              5006020.4029999999
            ],
            [
              357123.00099999999,
              5006026.6229999997
            ],
            [
              357115.64799999999,
              5006033.7319999998
            ],
            [
              357110.353,
              5006037.2659999998
            ],
            [
              357101.92300000001,
              5006040.8590000002
            ],
            [
              357093.26699999999,
              5006043.142
            ],
            [
              357086.60700000002,
              5006045.5029999996
            ],
            [
              357085.00199999998,
              5006046.2400000002
            ],
            [
              357078.70699999999,
              5006050.3990000002
            ],
            [
              357077.31400000001,
              5006051.6380000003
            ],
            [
              357075.92700000003,
              5006053.1789999995
            ],
            [
              357075.13900000002,
              5006054.3059999999
            ],
            [
              357074.36700000003,
              5006056.341
            ],
            [
              357073.712,
              5006059.1830000002
            ],
            [
              357073.092,
              5006063.9440000001
            ],
            [
              357073.09499999997,
              5006063.9960000003
            ],
            [
              357073.24200000003,
              5006066.568
            ],
            [
              357073.60499999998,
              5006068.8550000004
            ],
            [
              357075.53899999999,
              5006074.983
            ],
            [
              357078.08000000002,
              5006081.0999999996
            ],
            [
              357083.83799999999,
              5006091.6040000003
            ],
            [
              357089.57000000001,
              5006100.693
            ],
            [
              357095.70899999997,
              5006113.7939999998
            ],
            [
              357098.40399999998,
              5006122.7379999999
            ],
            [
              357099.516,
              5006128.1739999996
            ],
            [
              357099.98800000001,
              5006131.8030000003
            ],
            [
              357099.98999999999,
              5006131.8689999999
            ],
            [
              357100.17499999999,
              5006136.4479999999
            ],
            [
              357099.90999999997,
              5006144.1119999997
            ],
            [
              357099.54800000001,
              5006146.443
            ],
            [
              357098.97600000002,
              5006148.2719999999
            ],
            [
              357097.81300000002,
              5006151.0219999999
            ],
            [
              357092.59899999999,
              5006158.9000000004
            ],
            [
              357088.54499999998,
              5006163.9270000001
            ],
            [
              357080.67700000003,
              5006172.6150000002
            ],
            [
              357072.647,
              5006181.3530000001
            ],
            [
              357061.978,
              5006195.0930000003
            ],
            [
              357052.386,
              5006206.892
            ],
            [
              357041.973,
              5006219.9970000004
            ],
            [
              357033.86200000002,
              5006229.8480000002
            ],
            [
              357032.36900000001,
              5006231.1900000004
            ],
            [
              357031.07199999999,
              5006232.1229999997
            ],
            [
              357029.467,
              5006232.8609999996
            ],
            [
              357027.35100000002,
              5006233.3039999995
            ],
            [
              357024.92700000003,
              5006233.4500000002
            ],
            [
              357023.30300000001,
              5006233.1770000001
            ],
            [
              357021.674,
              5006232.6009999998
            ],
            [
              357019.83199999999,
              5006231.4230000004
            ],
            [
              357018.59000000003,
              5006229.9299999997
            ],
            [
              357017.34100000001,
              5006228.034
            ],
            [
              357013.45799999998,
              5006220.426
            ],
            [
              356992.95600000001,
              5006219.0789999999
            ],
            [
              356968.239,
              5006218.0199999996
            ],
            [
              356961.56400000001,
              5006218.0439999998
            ],
            [
              356959.24200000003,
              5006218.2889999999
            ],
            [
              356953.20000000001,
              5006219.7149999999
            ],
            [
              356948.663,
              5006220.5070000002
            ],
            [
              356938.12400000001,
              5006221.9110000003
            ],
            [
              356931.46600000001,
              5006222.8430000003
            ],
            [
              356924.28499999997,
              5006222.875
            ],
            [
              356921.24099999998,
              5006222.3260000004
            ],
            [
              356919.41100000002,
              5006221.8540000003
            ],
            [
              356915.53499999997,
              5006220.108
            ],
            [
              356901.63099999999,
              5006245.7779999999
            ],
            [
              356901.65999999997,
              5006245.7929999996
            ],
            [
              356904.90100000001,
              5006247.5360000003
            ],
            [
              356911.663,
              5006235.8899999997
            ],
            [
              356993.13400000002,
              5006251.8799999999
            ],
            [
              357020.57500000001,
              5006258.966
            ],
            [
              357025.46600000001,
              5006258.3689999999
            ],
            [
              357027.06400000001,
              5006258.1739999996
            ],
            [
              357037.43400000001,
              5006250.6109999996
            ],
            [
              357041.57400000002,
              5006248.6509999996
            ],
            [
              357045.34899999999,
              5006248.7039999999
            ],
            [
              357072.777,
              5006227.5140000004
            ],
            [
              357080.51799999998,
              5006219.716
            ],
            [
              357107.342,
              5006198.46
            ],
            [
              357113.65399999998,
              5006190.7850000001
            ],
            [
              357137.33000000002,
              5006209.1009999998
            ],
            [
              357137.288,
              5006209.1330000004
            ],
            [
              357114.80099999998,
              5006226.3370000003
            ],
            [
              357114.84399999998,
              5006226.3710000003
            ],
            [
              357122.46899999998,
              5006232.2829999998
            ],
            [
              357132.50300000003,
              5006243.4050000003
            ],
            [
              357145.12300000002,
              5006258.3629999999
            ],
            [
              357149.78700000001,
              5006263.4160000002
            ],
            [
              357156.61800000002,
              5006268.2010000004
            ],
            [
              357177.14500000002,
              5006278.2139999997
            ],
            [
              357177.11700000003,
              5006278.2659999998
            ],
            [
              357166.22499999998,
              5006298.5899999999
            ],
            [
              357166.25099999999,
              5006298.6100000003
            ],
            [
              357206.51799999998,
              5006329.8470000001
            ],
            [
              357200.91999999998,
              5006332.2620000001
            ],
            [
              357178.614,
              5006339.2170000002
            ],
            [
              357178.44900000002,
              5006339.2690000003
            ],
            [
              357178.46600000001,
              5006339.3300000001
            ],
            [
              357178.62699999998,
              5006339.8839999996
            ],
            [
              357181.86200000002,
              5006351.0609999998
            ],
            [
              357196.46600000001,
              5006346.3820000002
            ],
            [
              357203.74699999997,
              5006344.2620000001
            ],
            [
              357204.342,
              5006344.7539999997
            ],
            [
              357204.76299999998,
              5006344.7470000004
            ],
            [
              357209.85600000003,
              5006347.8260000004
            ],
            [
              357211.05800000002,
              5006348.733
            ],
            [
              357212.64199999999,
              5006350.3439999996
            ],
            [
              357214.35399999999,
              5006352.4029999999
            ],
            [
              357214.60100000002,
              5006352.699
            ],
            [
              357214.64299999998,
              5006352.7599999998
            ],
            [
              357215.17200000002,
              5006353.5319999997
            ],
            [
              357215.42999999999,
              5006353.909
            ],
            [
              357215.98599999998,
              5006354.8729999997
            ],
            [
              357216.68599999999,
              5006356.3629999999
            ],
            [
              357217.31599999999,
              5006357.9460000005
            ],
            [
              357217.98800000001,
              5006359.9840000002
            ],
            [
              357218.48800000001,
              5006361.8789999997
            ],
            [
              357218.86099999998,
              5006363.7439999999
            ],
            [
              357219.05699999997,
              5006365.2039999999
            ],
            [
              357219.05800000002,
              5006365.2699999996
            ],
            [
              357219.10600000003,
              5006366.858
            ],
            [
              357219.03499999997,
              5006368.7220000001
            ],
            [
              357218.85499999998,
              5006370.6150000002
            ],
            [
              357218.41499999998,
              5006372.4510000004
            ],
            [
              357217.93800000002,
              5006374.0829999996
            ],
            [
              357217.196,
              5006375.9699999997
            ],
            [
              357216.56599999999,
              5006377.1639999999
            ],
            [
              357215.75099999999,
              5006377.6979999999
            ],
            [
              357214.86900000001,
              5006380.4819999998
            ],
            [
              357212.24650000001,
              5006390.4630000005
            ],
            [
              357213.36099999998,
              5006391.1220000004
            ],
            [
              357219.17099999997,
              5006396.8669999996
            ],
            [
              357220.66999999998,
              5006397.9670000002
            ],
            [
              357227.81099999999,
              5006372.25
            ],
            [
              357229.315,
              5006366.9469999997
            ],
            [
              357230.19400000002,
              5006364.4400000004
            ],
            [
              357230.93199999997,
              5006355.3140000002
            ],
            [
              357230.984,
              5006353.5109999999
            ],
            [
              357230.98300000001,
              5006351.523
            ],
            [
              357230.98200000002,
              5006351.4560000002
            ],
            [
              357230.902,
              5006349.0949999997
            ],
            [
              357230.90000000002,
              5006349.0290000001
            ],
            [
              357230.76000000001,
              5006346.9620000003
            ],
            [
              357230.5,
              5006344.415
            ]
          ]
        ]
      }
    },
    {
      "attributes" : {
        "FID" : 10,
        "zup_rb" : "00008",
        "jls_mb" : "00531",
        "jls_st" : "GR",
        "jls_ime" : "CRIKVENICA",
        "plan_naziv" : "PPUG CRIKVENICA - IV. ID",
        "ozn_ispu" : "HR-ISPU-PPGO-00531-R07",
        "izvor" : "1.",
        "mj_izvora" : "1:25.000",
        "namjena" : "GOSPODARSKA - UGOSTITELJSKO TURISTIÈKA (HOTEL)",
        "ozn_namjen" : "T1",
        "naz_vl" : "UVALA SLANA (T1-8)",
        "az_oznaka" : "T",
        "pov" : 49673.199999999997,
        "shape_area" : 49695.760000000002,
        "shape_leng" : 1227.02,
        "obveza_UPU" : "UPU na snazi",
        "odr_link" : "F:\\E_investicije\\Crikvenica\\Crikvenica_UPU_T1_T3/UPU_Uvala_Slana_T1_T3_KN.jpg",
        "max_kap" : 500,
        "url" : "https://oss.uredjenazemlja.hr/public/cadServices.jsp?action=dkpViewerPublic"
      },
      "geometry" : {
        "rings" : [
          [
            [
              360105.64899999998,
              5003035.6330000004
            ],
            [
              360082.08799999999,
              5003021.5070000002
            ],
            [
              360076.55499999999,
              5003019.2019999996
            ],
            [
              360063.77000000002,
              5003011.5109999999
            ],
            [
              360051.52100000001,
              5002999.3509999998
            ],
            [
              360050.03700000001,
              5002992.8119999999
            ],
            [
              360039.18099999998,
              5002982.2359999996
            ],
            [
              360032.94699999999,
              5002974.642
            ],
            [
              360023.533,
              5002968.2510000002
            ],
            [
              360009.79200000002,
              5002976.534
            ],
            [
              360003.28399999999,
              5002966.4960000003
            ],
            [
              359989.49599999998,
              5002971.5839999998
            ],
            [
              359985.01000000001,
              5002956.9249999998
            ],
            [
              359976.36700000003,
              5002926.4890000001
            ],
            [
              359946.511,
              5002920.0710000005
            ],
            [
              359943.70000000001,
              5002921.2350000003
            ],
            [
              359943.63799999998,
              5002928.8150000004
            ],
            [
              359900.174,
              5002939.3370000003
            ],
            [
              359894.76500000001,
              5002942.1660000002
            ],
            [
              359891.495,
              5002934.9510000004
            ],
            [
              359886.78200000001,
              5002937.1610000003
            ],
            [
              359888.36499999999,
              5002940.6679999996
            ],
            [
              359885.03499999997,
              5002941.1349999998
            ],
            [
              359884.85800000001,
              5002951.0760000004
            ],
            [
              359883.234,
              5002950.8030000003
            ],
            [
              359882.32400000002,
              5002950.8200000003
            ],
            [
              359881.109,
              5002950.7419999996
            ],
            [
              359880.29999999999,
              5002950.7570000002
            ],
            [
              359879.29300000001,
              5002950.977
            ],
            [
              359877.78600000002,
              5002951.5109999999
            ],
            [
              359876.587,
              5002952.341
            ],
            [
              359875.49400000001,
              5002953.3720000004
            ],
            [
              359874.505,
              5002954.6030000001
            ],
            [
              359873.92099999997,
              5002955.8269999996
            ],
            [
              359873.13099999999,
              5002956.852
            ],
            [
              359872.533,
              5002957.3679999998
            ],
            [
              359871.52799999999,
              5002957.6900000004
            ],
            [
              359870.62,
              5002957.8080000002
            ],
            [
              359869.60700000002,
              5002957.7259999998
            ],
            [
              359868.59000000003,
              5002957.4409999996
            ],
            [
              359867.97600000002,
              5002957.0489999996
            ],
            [
              359867.364,
              5002956.7570000002
            ],
            [
              359866.65399999998,
              5002956.6689999998
            ],
            [
              359865.946,
              5002956.682
            ],
            [
              359865.04399999999,
              5002957.1030000001
            ],
            [
              359861.67499999999,
              5002960.9050000003
            ],
            [
              359860.179,
              5002962.0439999998
            ],
            [
              359855.55800000002,
              5002963.7470000004
            ],
            [
              359850.01699999999,
              5002964.9610000001
            ],
            [
              359840.51799999998,
              5002965.4409999996
            ],
            [
              359840.01799999998,
              5002965.7529999996
            ],
            [
              359839.42300000001,
              5002966.3710000003
            ],
            [
              359839.03700000001,
              5002967.3880000003
            ],
            [
              359834.82799999998,
              5002974.9440000001
            ],
            [
              359826.40700000001,
              5002993.6100000003
            ],
            [
              359823.321,
              5003001.8669999996
            ],
            [
              359821.462,
              5003005.2359999996
            ],
            [
              359820.47499999998,
              5003006.568
            ],
            [
              359818.48800000001,
              5003008.5250000004
            ],
            [
              359808.41600000003,
              5003016.2910000002
            ],
            [
              359806.82699999999,
              5003017.8360000001
            ],
            [
              359805.93900000001,
              5003019.0650000004
            ],
            [
              359804.37199999997,
              5003021.8229999999
            ],
            [
              359803.99400000001,
              5003023.2450000001
            ],
            [
              359803.85499999998,
              5003026.6830000002
            ],
            [
              359804.70799999998,
              5003035.3339999998
            ],
            [
              359804.42300000001,
              5003036.3499999996
            ],
            [
              359799.96299999999,
              5003041.2829999998
            ],
            [
              359798.32500000001,
              5003040.2019999996
            ],
            [
              359797.66700000002,
              5003042.943
            ],
            [
              359797.179,
              5003043.8609999996
            ],
            [
              359791.266,
              5003052.2570000002
            ],
            [
              359786.40299999999,
              5003057.2989999996
            ],
            [
              359783.913,
              5003059.3660000004
            ],
            [
              359782.61300000001,
              5003060.199
            ],
            [
              359780.39799999999,
              5003060.7450000001
            ],
            [
              359771.21600000001,
              5003061.926
            ],
            [
              359760.15899999999,
              5003062.7070000004
            ],
            [
              359758.44799999997,
              5003063.1430000002
            ],
            [
              359756.74400000001,
              5003063.983
            ],
            [
              359753.95400000003,
              5003066.2580000004
            ],
            [
              359751.27399999998,
              5003069.0360000003
            ],
            [
              359749.29700000002,
              5003071.4979999997
            ],
            [
              359748.51299999998,
              5003072.8269999996
            ],
            [
              359747.842,
              5003074.8600000003
            ],
            [
              359747.58399999997,
              5003077.29
            ],
            [
              359747.55800000002,
              5003081.3320000004
            ],
            [
              359748.11900000001,
              5003084.3530000001
            ],
            [
              359750.58899999998,
              5003092.0880000005
            ],
            [
              359756.96500000003,
              5003102.7429999998
            ],
            [
              359764.20400000003,
              5003111.2989999996
            ],
            [
              359767.60800000001,
              5003114.8729999997
            ],
            [
              359772.75199999998,
              5003119.5269999998
            ],
            [
              359774.29999999999,
              5003121.216
            ],
            [
              359775.755,
              5003123.3109999998
            ],
            [
              359778.38199999998,
              5003128.6179999998
            ],
            [
              359779.26299999998,
              5003131.21
            ],
            [
              359779.50099999999,
              5003133.125
            ],
            [
              359779.44900000002,
              5003135.7529999996
            ],
            [
              359779.07799999998,
              5003137.5789999999
            ],
            [
              359774.837,
              5003148.8739999998
            ],
            [
              359768.69,
              5003161.0130000003
            ],
            [
              359767.51799999998,
              5003163.2580000004
            ],
            [
              359766.94300000003,
              5003164.9869999997
            ],
            [
              359766.679,
              5003167.1140000001
            ],
            [
              359766.70899999997,
              5003168.7300000004
            ],
            [
              359767.04399999999,
              5003170.4419999998
            ],
            [
              359768.087,
              5003172.1399999997
            ],
            [
              359727.79800000001,
              5003206.3260000004
            ],
            [
              359730.788,
              5003209.4029999999
            ],
            [
              359793.33399999997,
              5003160.4189999998
            ],
            [
              359811.37199999997,
              5003176.0449999999
            ],
            [
              359811.99300000002,
              5003176.8420000002
            ],
            [
              359812.516,
              5003177.7419999996
            ],
            [
              359813.24200000003,
              5003178.7390000001
            ],
            [
              359815.19699999999,
              5003180.5209999997
            ],
            [
              359816.22100000002,
              5003181.2089999998
            ],
            [
              359817.34299999999,
              5003181.6940000001
            ],
            [
              359819.592,
              5003182.966
            ],
            [
              359841.47899999999,
              5003201.4550000001
            ],
            [
              359833.16600000003,
              5003210.1679999996
            ],
            [
              359832.576,
              5003211.0880000005
            ],
            [
              359832.391,
              5003212.0010000002
            ],
            [
              359832.41200000001,
              5003213.1119999997
            ],
            [
              359832.734,
              5003214.1169999996
            ],
            [
              359833.35499999998,
              5003214.9139999999
            ],
            [
              359834.174,
              5003215.4040000001
            ],
            [
              359835.08899999998,
              5003215.6900000004
            ],
            [
              359836.09999999998,
              5003215.6710000001
            ],
            [
              359843.24900000001,
              5003213.9210000001
            ],
            [
              359843.85200000001,
              5003213.7079999996
            ],
            [
              359844.35800000001,
              5003213.699
            ],
            [
              359853.32699999999,
              5003201.4060000004
            ],
            [
              359855.61800000002,
              5003200.7170000002
            ],
            [
              359860.37599999999,
              5003195.074
            ],
            [
              359868.67999999999,
              5003182.767
            ],
            [
              359885.11499999999,
              5003163.6950000003
            ],
            [
              359905.68699999998,
              5003138.5369999995
            ],
            [
              359901.54300000001,
              5003134.9859999996
            ],
            [
              359922.90500000003,
              5003104.1299999999
            ],
            [
              359927.75400000002,
              5003100.182
            ],
            [
              359939.962,
              5003097.6399999997
            ],
            [
              359951.875,
              5003089.6059999997
            ],
            [
              359961.609,
              5003082.8260000004
            ],
            [
              359973.25,
              5003088.3150000004
            ],
            [
              359981.10100000002,
              5003090.1529999999
            ],
            [
              360004.07799999998,
              5003084.6409999998
            ],
            [
              360016.22200000001,
              5003083.7949999999
            ],
            [
              360031.35700000002,
              5003083.6380000003
            ],
            [
              360050.40000000002,
              5003085.2479999997
            ],
            [
              360066.91499999998,
              5003088.3650000002
            ],
            [
              360073.196,
              5003079.0860000001
            ],
            [
              360086.00699999998,
              5003062.9890000001
            ],
            [
              360089.65600000002,
              5003057.4859999996
            ],
            [
              360095.16899999999,
              5003049.3260000004
            ],
            [
              360097.42800000001,
              5003046.4859999996
            ],
            [
              360105.64899999998,
              5003035.6330000004
            ]
          ]
        ]
      }
    }
  ]
};